import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ScheduleManagerIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 17C17 16.448 17.448 16 18 16C18.552 16 19 16.448 19 17C19 17.552 18.552 18 18 18C17.448 18 17 17.552 17 17Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 6C7 5.448 7.448 5 8 5C8.552 5 9 5.448 9 6V7H19V6C19 5.448 19.448 5 20 5C20.552 5 21 5.448 21 6V7C22.105 7 23 7.895 23 9V21C23 22.105 22.105 23 21 23H7C5.895 23 5 22.105 5 21V9C5 7.895 5.895 7 7 7V6ZM7 12C7 11.448 7.448 11 8 11H20C20.552 11 21 11.448 21 12V20C21 20.552 20.552 21 20 21H8C7.448 21 7 20.552 7 20V12Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ScheduleManagerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ScheduleManagerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ScheduleManagerIcon)
