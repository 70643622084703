import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import {
  REPEAT_INTEGRATION,
  updatedScheme,
} from '@/store/actions/integrations'
import request from '@/helpers/axios'
import { generateRefreshIntegrationUrl } from '@/constants/apiRoutes'
import { getIntegrationSchemeById } from '@/store/selectors/integrations'

function* repeatIntegrationSaga({ payload }) {
  try {
    const schemeByID = yield select(getIntegrationSchemeById)
    const newScheme = {
      ...schemeByID,
      children: [{
        ...schemeByID.children[0],
        isSync: true,
        integrationOption: true,
      }],
    }
    yield put(updatedScheme(newScheme))
    const { id, type, form } = payload
    if (Object.keys(form || {}).length > 0) {
      form.setSubmitting(true)
    }

    const dataJson = {
      aliasId: id,
    }

    yield request({
      url: generateRefreshIntegrationUrl(type.toLowerCase(), id),
      method: 'post',
      body: dataJson,
    })
  } catch (error) {
    console.log('TCL: function*repeatIntegrationSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(REPEAT_INTEGRATION.REQUEST, repeatIntegrationSaga)
}
