import React, { Component } from 'react'
import pt from 'prop-types'
import qs from 'query-string'
import get from 'lodash/get'
import Widget from '@/components/widgets/Widget'
import CustomApplicationWidget from '@/components/widgets/CustomApplicationWidget'
import WidgetSideBarContainer from '@/containers/regions/WidgetSideBarContainer'
import AnalyticsAlarmsWidget from '@/components/widgets/AnalyticsAlarmsWidget'
import TyumenOurHomeWidget from '@/components/widgets/TyumenOurHomeWidget'
import ServesDescWidget from '@/components/widgets/ServesDescWidget'
import {
  ANALYTICS_ALARMS,
  TYUMEN_OUR_HOME,
  SUPPORT,
} from '@/constants/widgetsShop/names'
import { isUserHasPermission, isSuperAdmin } from '@/helpers/userPermissions'
import WIDGETSHOP_APP_ITEMS from '@/constants/widgetShopApp'
import {
  WidgetsWrapper,
  StaticWidgetsContainer,
  StaticWidgetsWrapper,
  CustomWidgetsContainer,
  CustomWidgetWrapper,
} from './styles'
import data from './config'

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openSettingsForId: null,
      selectedWidgetType: null,
      widgetType: null,
      widgetData: null,
    }
  }

  static getDerivedStateFromProps = () => {
    if (qs.parse(window.location.search).closeThisWindow) {
      window.close()
    }
    return null
  }

  componentDidMount() {
    const { onGetMapObjects, requestGetCurrentUser, user, requestGetWidgetSettings } = this.props
    document.title = 'SLS24: Рабочий стол'
    onGetMapObjects()
    requestGetCurrentUser({ tenantId: user.tenantId, id: user.id })
    requestGetWidgetSettings()
  }

  onOpenSettings = (id, type, widgetType, widgetData) => {
    const { onToggleAlarmsWidgetSettings } = this.props
    onToggleAlarmsWidgetSettings(true)
    this.setState({
      openSettingsForId: id,
      selectedWidgetType: type,
      widgetType,
      widgetData,
    })
  }

  onCloseSettings = () => {
    const { onToggleAlarmsWidgetSettings } = this.props
    this.setState({
      openSettingsForId: null,
    })
    onToggleAlarmsWidgetSettings(false)
  }

  renderWidgetSettingsByType = (props) => {
    const {
      onGetAnalyticWidgetData,
      onGetTyumenWidgetData,
      user,
      widgetsData,
      onDeleteWidget,
      requestGetInfoWidget,
      serviceDeskStatus,
      userApplications,
    } = this.props
    switch (props.type) {
      case ANALYTICS_ALARMS: {
        // TODO: change
        if (!isUserHasPermission(user, 8)) {
          return null
        }
        return (
          <CustomWidgetWrapper>
            <AnalyticsAlarmsWidget
              {...props}
              onOpenSettings={this.onOpenSettings}
              onGetData={onGetAnalyticWidgetData}
              data={widgetsData[props.id]}
            />
          </CustomWidgetWrapper>
        )
      }
      case TYUMEN_OUR_HOME: {
        if (!isUserHasPermission(user, 25)) {
          return null
        }
        return (
          <CustomWidgetWrapper>
            <TyumenOurHomeWidget
              {...props}
              onOpenSettings={this.onOpenSettings}
              onGetData={onGetTyumenWidgetData}
              data={widgetsData[props.id]}
            />
          </CustomWidgetWrapper>
        )
      }
      case SUPPORT: {
        if (!isUserHasPermission(user, 26)) {
          return null
        }
        let isValidSettings = true
        const statusIsArray = serviceDeskStatus instanceof Array
        const currentServiceDeskStatus = (statusIsArray ? serviceDeskStatus : []).find(
          (status) => (
            user.roles.isSuperAdmin
              ? props.id === status.temporaryId
              : props.id === status.widgetId
          ),
        )
        if (!isSuperAdmin(user.role)) {
          const sdConfig = get(user, 'permissions.applications', []).find(({ id }) => id === 26) || {}
          const parsedSettings = JSON.parse(sdConfig.settings || '[]') || []
          const isSdHasInSettings = !!parsedSettings
            .find(({ temporaryId }) => (
              currentServiceDeskStatus
                && temporaryId === currentServiceDeskStatus.temporaryId
            ))
          if (currentServiceDeskStatus && !isSdHasInSettings) {
            onDeleteWidget(props.id, 'widget', get(currentServiceDeskStatus, 'id', null))
            return null
          }
          isValidSettings = parsedSettings
            .find(({ id }) => currentServiceDeskStatus && id === currentServiceDeskStatus.id)
        }
        return (
          <ServesDescWidget
            {...props}
            isSettingSet={isValidSettings ? props.isSettingSet : false}
            onOpenSettings={this.onOpenSettings}
            data={isValidSettings ? widgetsData[props.id] : undefined}
            onGetData={requestGetInfoWidget}
            serviceDeskStatus={isValidSettings ? currentServiceDeskStatus : undefined}
            userApplications={userApplications}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  renderWidgetByType = (application) => {
    const { user } = this.props
    const applicationFromConfig = WIDGETSHOP_APP_ITEMS
      .find((widget) => widget.id === application.type)
    const isUserHasPermissions = isUserHasPermission(user, applicationFromConfig.applicationId)

    if (!isUserHasPermissions) {
      return null
    }

    return (
      <CustomApplicationWidget
        key={application.icon}
        {...applicationFromConfig}
        {...application}
        onOpenSettings={this.onOpenSettings}
      />
    )
  }

  render() {
    const {
      tree, widgetsData, widgets, applications, isWidgetSettingsOpen, user, ...values
    } = this.props
    const {
      openSettingsForId, selectedWidgetType, widgetType, widgetData,
    } = this.state
    return (
      <WidgetsWrapper>
        <StaticWidgetsContainer>
          <StaticWidgetsWrapper>
            {data.map((row) => (
              <>
                {row.map((widget) => {
                  const isUserHasPermissions = isUserHasPermission(user, widget.applicationId)
                  if (widget.applicationId && !isUserHasPermissions) {
                    return null
                  }
                  const count = (values[widget.countSelector] || []) || widget.count
                  let valueList = null
                  if (widget.valueList && widget.valueList.length > 0) {
                    valueList = widget.valueList.map((element) => ({
                      title: typeof element.title === 'function'
                        ? element.title(values[element.countSelector] || 0)
                        : element.title,
                      value: values[element.countSelector] || 0,
                      highLight: element.highLight || false,
                    }))
                  }
                  let descriptionFields = null
                  if (widget.descriptionFields && widget.descriptionFields.length > 0) {
                    descriptionFields = widget.descriptionFields.map((element) => ({
                      value: values[element.selector] || '',
                      highLight: element.highLight || false,
                    }))
                  }
                  return (
                    <Widget
                      key={widget.name}
                      {...widget}
                      valueList={valueList || []}
                      descriptionFields={descriptionFields || []}
                      count={count}
                      title={widget.getTitle ? widget.getTitle(count) : widget.title}
                    />
                  )
                })}
              </>
            ))}
          </StaticWidgetsWrapper>
          <StaticWidgetsWrapper>
            {applications.map(this.renderWidgetByType)}
          </StaticWidgetsWrapper>
        </StaticWidgetsContainer>
        <CustomWidgetsContainer>
          {widgets.map(this.renderWidgetSettingsByType)}
        </CustomWidgetsContainer>
        <WidgetSideBarContainer
          widgetType={widgetType}
          widgetId={openSettingsForId}
          isOpen={isWidgetSettingsOpen}
          onClose={this.onCloseSettings}
          selectedWidgetType={selectedWidgetType}
          data={widgetData}
        />
      </WidgetsWrapper>
    )
  }
}

Dashboard.propTypes = {
  widgets: pt.arrayOf(pt.shape({
    id: pt.string,
  })),
  applications: pt.arrayOf(pt.shape({
    id: pt.string,
  })),
  onGetAlarmsCount: pt.func.isRequired,
  onGetAnalyticWidgetData: pt.func.isRequired,
  onGetTyumenWidgetData: pt.func.isRequired,
  widgetsData: pt.shape({}).isRequired,
  onToggleAlarmsWidgetSettings: pt.func.isRequired,
  isWidgetSettingsOpen: pt.bool.isRequired,
  user: pt.shape({
    modules: pt.arrayOf(pt.number),
    roles: pt.shape({
      isSuperAdmin: pt.bool,
    }),
  }).isRequired,
}
Dashboard.defaultProps = {
  widgets: [],
  applications: [],
}

export default Dashboard
