import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 99%;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  padding: 0 15px;
`

export const IconContainer = styled.div`
  width: 100%;
  height: 48px;
  color: ${({ theme }) => theme.colors.colors.default};
  .svgFill {
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.default};
  }
  ${({ isActive, theme }) => isActive && `
    background: ${theme.colors.colors.default};
    color: ${theme.colors.backgrounds.default};
    .svgFill {
      fill: ${theme.colors.backgrounds.default};
    }
  `}
  ${({ isAction, theme }) => isAction && `
    background: ${theme.colors.scheme.backgrounds.default};
    color: ${theme.colors.white};
    .svgFill {
      fill: ${theme.colors.white};
    }
  `}

  cursor: pointer;
  padding: 0 15px;
`
