import { connect } from 'react-redux'
import {
  getTreeData,
  getRegions,
  getPins,
  getTelemetry,
  getLoading,
  getZones,
} from '@/store/selectors/maps/socketEvents'
import { getNode, getPinnedNode, getUserApplications } from '@/store/selectors/appSettings'
import { getSystems } from '@/store/selectors/integrations'
import { getImage } from '@/store/selectors/maps/objectImage'

import { setSelectedNode } from '@/store/actions/appSettings'
import { requestObjectImage } from '@/store/actions/maps/getObjectImage'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import { refreshWidget } from '@/store/actions/maps/widgetSettings'
import Maps from './component'

const mapStateToProps = (state) => ({
  treeData: getTreeData(state),
  regions: getRegions(state),
  pins: getPins(state),
  telemetry: getTelemetry(state),
  loading: getLoading(state),
  selectedNode: getNode(state),
  systems: getSystems(state),
  zones: getZones(state),
  pinnedNode: getPinnedNode(state),
  objectImage: getImage(state),
  userApplications: getUserApplications(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetMapObjects: () => dispatch(requestGetMapObjects()),
  requestObjectImage: (payload) => dispatch(requestObjectImage(payload)),
  refreshIntegration: (payload) => dispatch(refreshWidget(payload)),
  setNode: (payload, withoutPinned) => dispatch(setSelectedNode(payload, withoutPinned)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Maps)
