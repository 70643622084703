import styled from 'styled-components'

export const PinContainer = styled.div`
  position: relative;
  top: -8px;
  left: -8px;
  width: 22px;
  height: 22px;
  z-index: 120;
`

export const OverLay = styled.div`
  box-shadow: ${({ theme }) => theme.colors.shadows.pin};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  position: absolute;
  top: 0px;
  left: 0px;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
  svg {
    height: 18px;
    width: 18px;
  }
`
