import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import { GET_LIVE_TIME_REPORT_BAR_DATA } from '@/store/actions/lifeTimeReport'
import { GET_LIVE_TIME_REPORT_BAR_DATA_URL } from '@/constants/apiRoutes'
import {
  successLiveTimeReportBarData,
  errorLiveTimeReportBarData,
} from '@/store/actions/lifeTimeReport/getBar'
import { getSelectedNode } from '@/store/selectors/appSettings'
import formatLiveTimeReportBar from '@/helpers/formaters/formatLiveTimeReportBar'
import { LIGHT } from '@/constants/objectTypes'

function* getLiveTimeReportBarDataSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.isHasInstallations && !node.internalId) {
      yield put(successLiveTimeReportBarData(formatLiveTimeReportBar(
        {},
        {},
      )))
      return null
    }

    const response = yield request({
      url: qs.stringifyUrl({
        url: GET_LIVE_TIME_REPORT_BAR_DATA_URL,
        query: {
          installationId: (node && node.internalId) ? node.internalId : null,
          addressId: (node && node.type === LIGHT) ? node.id : null,
        },
      }, { skipNull: true }),
      method: 'get',
    })
    const {
      graphPercent,
      graphYear,
    } = response.data
    yield put(successLiveTimeReportBarData(formatLiveTimeReportBar(
      graphPercent,
      graphYear,
    )))
  } catch (error) {
    yield put(errorLiveTimeReportBarData(error))
  }
}

export default function* root() {
  yield takeLatest(GET_LIVE_TIME_REPORT_BAR_DATA.REQUEST, getLiveTimeReportBarDataSaga)
}
