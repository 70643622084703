import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getMapsState } from '@/store/selectors/maps/socketEvents'

export const getWidgets = createSelector(
  getMapsState,
  (maps) => get(maps, 'widgets', {}),
)

export const getWidgetsSettings = createSelector(
  getWidgets,
  (maps) => get(maps, 'settings', {}),
)

export const getACYWidgetsSettings = createSelector(
  getWidgets,
  (maps) => get(maps, 'acsSettings', {}),
)
