import React from 'react'

import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import AppealCard from '../../components/AppealCard'

import { Container, Column, Title } from './styles'

const OK_APPEALS = [
  {
    number: '213,494-36,211',
    description: `Адрес: ул Союзная, д 115
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
  {
    number: '131,643-10,314',
    description: `Адрес: ул Союзная, д 69
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
  {
    number: '291,423-48,613',
    description: `Адрес: ул Союзная, д 176
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
  {
    number: '283,787-15,323',
    description: `Адрес: ул Союзная, д 125
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
  {
    number: '293,449-12,961',
    description: `Адрес: ул Союзная, д 43
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
]

const WARNING_APPEALS = [
  {
    number: '172,656-49,053',
    description: `Адрес: ул Союзная, д 196
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
  {
    number: '422,002-11,944',
    description: `Адрес: ул Союзная, д 74
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
]

const ERROR_APPEALS = [
  {
    number: '381,298-42,817',
    description: `Адрес: ул Союзная, д 80
Не работает наружное освещение,
Вдоль автомобильной дороги`,
  },
]

const Widget = () => (
  <Container>
    <Column>
      <Title>Соблюден</Title>
      {OK_APPEALS.map((data) => <AppealCard type={OK} {...data} />)}
    </Column>
    <Column disabled>
      <Title>Истекает</Title>
      {WARNING_APPEALS.map((data) => <AppealCard type={WARNING} {...data} />)}
    </Column>
    <Column disabled>
      <Title>Нарушен</Title>
      {ERROR_APPEALS.map((data) => <AppealCard type={ERROR} {...data} />)}
    </Column>
  </Container>
)

export default Widget
