import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import {
  IndicatorWrapper,
} from '@/components/widgets/StateIndicator/styles'
import CoreIcons from '@/components/icons/core'
import {
  Container,
  Text,
} from './styles'

const LightIndicator = ({
  className,
  value,
  valueHeight,
}) => {
  return (
    <IndicatorWrapper
      column
      small
      className={className}
      withoutSvgStyle
    >
      <Container>
        <Container widthValue>
          <Text>
            <Lang id="mapsPage.widgets.tiltAngel" />
          </Text>
          <Text value>
            X:
            {' '}
            {(value.x || 0).toFixed(3)}
          </Text>
          <Text value>
            Y:
            {' '}
            {(value.y || 0).toFixed(3)}
          </Text>
          <Text value>
            Z:
            {' '}
            {(value.z || 0).toFixed(3)}
          </Text>
        </Container>
        <Container>
          <Text>
            Высота (М)
          </Text>
          <Text value>
            {valueHeight}
          </Text>
        </Container>
      </Container>
      <Container>
        <CoreIcons.BigLightIcon />
      </Container>
    </IndicatorWrapper>
  )
}

LightIndicator.propTypes = {
}
LightIndicator.defaultProps = {
}

export default memoWithName(LightIndicator)
