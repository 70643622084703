import { INTEGRATION_SYSTEM, INTEGRATION_ZONE } from '@/constants/viewTree'

import {
  COMMON,
  LIGHTNING,
  HEAT_SUPPLY,
  WATER_SUPPLY,
  POWER,
  INTEGRATION_SCHEMA_ID,
  INTEGRATION_SCHEMA_CHILD_ID,
  CONNECTION_SIGNAL,
} from '@/constants/syncModule'

import {
  KULON,
  BRIZ,
  ATM,
  MESH,
} from '@/constants/integrations'
import {
  LIGHT,
} from '@/constants/objectTypes'


const integrationTypes = [
  {
    id: 1000,
    tag: COMMON,
    name: 'Универсальные',
    type: LIGHT,
    isSync: true,
  },
  {
    id: 1001,
    tag: LIGHTNING,
    name: 'Освещение',
    type: LIGHT,
    isSync: true,
  },
  {
    id: 1002,
    tag: HEAT_SUPPLY,
    name: 'Теплоснабжение',
    type: LIGHT,
    isSync: true,
  },
  {
    id: 1003,
    tag: WATER_SUPPLY,
    name: 'Водоснабжение',
    type: LIGHT,
    isSync: true,
  },
  {
    id: 1004,
    tag: POWER,
    name: 'Электроэнергия',
    type: LIGHT,
    isSync: true,
  },
]

const integrationScheme = {
  id: INTEGRATION_SCHEMA_ID,
  name: 'СИСТЕМА',
  type: INTEGRATION_SYSTEM,
  toggled: true,
  status: 'ok',
  isSync: true,
  children: [
    {
      id: INTEGRATION_SCHEMA_CHILD_ID,
      name: 'Новая интеграция',
      type: INTEGRATION_ZONE,
      status: 'ok',
      isSync: true,
      syncStatus: CONNECTION_SIGNAL,
    },
  ],
}

const integrationOptions = [
  {
    id: KULON,
    name: 'КУЛОН',
    type: KULON,
    tags: [COMMON, LIGHTNING],
    toggled: true,
    isSync: true,
    controlType: 'АСУНО',
    isIntegrationOption: true,
  },
  {
    id: MESH,
    name: 'МЭШ СЕТЬ',
    type: MESH,
    tags: [COMMON, LIGHTNING],
    toggled: true,
    isSync: true,
    controlType: 'АСУ',
    isIntegrationOption: true,
  },
  {
    id: BRIZ,
    name: 'БРИЗ',
    type: BRIZ,
    tags: [COMMON, LIGHTNING, POWER],
    toggled: true,
    isSync: true,
    controlType: 'АСУ',
    isIntegrationOption: true,
  },
  {
    id: ATM,
    name: '1SiM',
    type: ATM,
    tags: [COMMON, WATER_SUPPLY, HEAT_SUPPLY],
    toggled: true,
    isSync: true,
    controlType: 'АСУ',
    isIntegrationOption: true,
  },
]

const connectedIntegration = []

export default {
  integrationScheme,
  integrationTypes,
  integrationOptions,
  connectedIntegration,
}
