import {
  LIGHT,
  SHUO,
  CONTROLLER,
  GROUP_OBJECT,
} from '@/constants/objectTypes'

export const LIGHTS = [
  {
    id: 'svd',
    title: 'СВД',
    value: 5000,
    objectsType: LIGHT,
  },
  {
    id: 'shuo',
    title: 'ШУО',
    value: 5000,
    objectsType: SHUO,
  },
  {
    id: 'bs',
    title: 'БС',
    value: 5000,
    objectsType: CONTROLLER,
  },
  {
    id: 'ahp',
    title: 'АХП',
    value: 2000,
    disabled: true,
  },
  {
    id: 'jku',
    title: 'ЖКУ',
    value: 2000,
    disabled: true,
  },
  {
    id: 'drl',
    title: 'ДРЛ',
    value: 1000,
    disabled: true,
  },
]

export const SOCIALS = [
  {
    id: 'school',
    title: 'Школы',
    value: 5000,
    objectsType: GROUP_OBJECT,
  },
  {
    id: 'dou',
    title: 'ДОУ',
    value: 2500,
    disabled: true,
  },
  {
    id: 'medical',
    title: 'Больницы',
    value: 2500,
    disabled: true,
  },
]

export const ROADS = [
  {
    id: 'chargeStations',
    title: 'Зарядные автостанции',
    value: 10000,
    disabled: true,
  },
  {
    id: 'svetofor',
    title: 'Светофор',
    value: 5000,
    disabled: true,
  },
  {
    id: 'infoTable',
    title: 'Инфотабло',
    value: 5000,
    disabled: true,
  },
]
