import {
  TEXT,
  ARIA,
  SELECT,
  CUSTOMS_FIELDS,
  ADD_CUSTOMS_FIELDS,
} from '@/constants/semanticNames'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'

export const projectConfig = [
  {
    title: 'Название проекта*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.NAME,
  },
  {
    title: 'Родительская геозона',
    type: SELECT,
    selector: CREATE_REGION_NAMES.PARENT,
  },
  {
    type: CUSTOMS_FIELDS,
    selector: 'customFields',
  },
  {
    selector: 'customFieldsButtons',
    type: ADD_CUSTOMS_FIELDS,
  },
  {
    title: 'Заметка',
    type: ARIA,
    selector: CREATE_REGION_NAMES.MARK,
    charLimit: 300,
  },
]

export default projectConfig
