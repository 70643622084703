import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generatePutPassport } from '@/constants/apiRoutes'
import { PUT_PASSPORT_AND_CARD_DATA } from '@/store/actions/passportization'
import {
  responseUpdatePassport,
  errorUpdatePassport,
} from '@/store/actions/passportization/updatePassport'
import {
  requestGetPassportPhoto,
} from '@/store/actions/passportization/getPassportPhoto'
import toastErrorHelper from '@/helpers/toastHelper'
import createNotifications from '@/helpers/notification'

function* putPassportSage({ payload }) {
  const {
    type,
    values,
    form,
    name,
  } = payload
  const toast = createNotifications()
  const copy = { ...values }
  const { generalInfo } = copy
  delete copy.generalInfo
  const card = {
    location: copy.location,
    objectCharacteristics: copy.objectCharacteristics,
    objectService: copy.objectService,
    expertiseData: copy.expertiseData,
  }
  delete copy.location
  delete copy.objectCharacteristics
  delete copy.objectService
  delete copy.expertiseData
  const formatedData = {
    ...generalInfo,
    ...copy,
    card,
  }

  try {
    const response = yield request({
      url: generatePutPassport(type),
      method: 'put',
      body: formatedData,
    })

    if (response && response.data) {
      const data = { ...response.data }
      const { card } = data
      delete data.card
      const passportData = Object.keys(data)
        .reduce((accumulator, element) => {
          if (typeof data[element] !== 'object' || data[element] === null) {
            return {
              ...accumulator,
              generalInfo: {
                ...accumulator.generalInfo,
                [element]: data[element],
              },
            }
          }
          return {
            ...accumulator,
            [element]: data[element],
          }
        }, { generalInfo: {} })
      passportData.inner = {
        photo: data.photo,
        file: data.file,
      }
      yield put(responseUpdatePassport({
        ...card,
        ...passportData,
      }))
      if (response.data.photo.uid) {
        yield put(requestGetPassportPhoto({ id: response.data.photo.uid }))
      }
    }
    toast({
      title: 'Успешная операция!',
      description: `Паспорт объекта "${name}" успешно отредактирован.`,
    })
    form.setSubmitting(false)
  } catch (error) {
    yield put(errorUpdatePassport(error))
    form.setSubmitting(false)
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при редактированье паспорта! Повторите попытку позже. Код ошибки: ${toastErrorHelper(error)}`,
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(PUT_PASSPORT_AND_CARD_DATA.REQUEST, putPassportSage)
}
