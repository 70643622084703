import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import { MARKS_COLORS, MAPS_ICONS } from '@/constants/styles/mapsMarks'
import PieChart from '../PieChart'
import ClusterToolTip from '../ClusterToolTip'
import {
  Container, PieChartContainer, PieChartOverlay, BackDrop,
} from './styles'

const ControllerIconHover = ({
  name, statistic, type, theme, count, status,
}) => {
  const Icon = MAPS_ICONS[type]
  return (
    <Container theme={theme}>
      <PieChartContainer theme={theme}>
        {count === 0
          ? <BackDrop />
          : <PieChart size="small" statistic={statistic} theme={theme} />}
      </PieChartContainer>
      <PieChartOverlay theme={theme}>
        <Icon color={MARKS_COLORS[status] || theme.colors.colors.default} />
      </PieChartOverlay>
      <ClusterToolTip data={statistic} name={name} theme={theme} type="small" icon={Icon} />
    </Container>
  )
}

ControllerIconHover.propTypes = {
  count: mapTypes.markCount.isRequired,
  theme: mapTypes.theme.isRequired,
  type: mapTypes.markType.isRequired,
  name: mapTypes.markName.isRequired,
  statistic: mapTypes.markStatistic.isRequired,
}

export default ControllerIconHover
