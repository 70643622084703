import styled from 'styled-components'

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 5px;
  transition: color 100ms ease-in-out;
`

export const FieldStyled = styled.textarea`
  outline: none;
  width: 100%;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  transition: background 100ms ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.borders.textField};
  color: ${({ theme }) => theme.colors.colors.textField};
  -webkit-text-fill-color: ${({ theme }) => theme.colors.colors.textField};
  resize: vertical;
  padding-right: 30px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.colors.textField};
  }
  :disabled {
    border: ${({ theme }) => `1px solid ${theme.colors.backgrounds.textField}`};
    background: ${({ theme }) => theme.colors.backgrounds.textField};
  }
`

export const ControlsContainer = styled.div`
  margin: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  div {
    align-items: flex-start;

  }
`

export const TextFieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  ${Label} {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    margin: 0;

    svg {
      & > .svgFill {
        fill: ${({ theme }) => theme.colors.backgrounds.disabled};
      }
      & > .svgStroke {
        stroke: ${({ theme }) => theme.colors.backgrounds.disabled};
      }
    }
  }

  ${FieldStyled} {
    padding-left: 10px;
    ${({ dark, theme }) => dark && `
      background: ${theme.colors.backgrounds.default};
      border-color: ${theme.colors.borders.default};
    `}
  }

  ${({ focused, filled, theme }) => (focused || filled) && `
    ${FieldStyled} {
      background: ${theme.colors.backgrounds.textFieldActive};
      -webkit-text-fill-color: ${theme.colors.colors.textFieldActive};
      color: ${theme.colors.colors.textFieldActive};
    }
  `}

  ${ControlsContainer} {
      height: calc(100% - 20px);
      align-items: flex-start;
      position: absolute;
      right: 0px;
      top: 0px;
      svg {
        & > .svgFill {
          fill: ${({ theme }) => theme.colors.colors.redisign.dark};
        }
        & > .svgStroke {
          stroke: ${({ theme }) => theme.colors.colors.redisign.dark};
        }
      }
    }
`
