import { DELETE_TENANT } from '../index'

export const requestDeleteTenant = (payload) => ({
  type: DELETE_TENANT.REQUEST,
  payload,
})

export const responseDeleteTenant = (payload) => ({
  type: DELETE_TENANT.SUCCESS,
  payload,
})

export const errorDeleteTenant = (payload) => ({
  type: DELETE_TENANT.ERROR,
  payload,
})
