import { GET_TYUMEN_DATE_GRAPH } from './index'

export const requestGetTyumenDateGraph = (payload) => ({
  type: GET_TYUMEN_DATE_GRAPH.REQUEST,
  payload,
})

export const successGetTyumenDateGraph = (payload) => ({
  type: GET_TYUMEN_DATE_GRAPH.SUCCESS,
  payload,
})

export const errorGetTyumenDateGraph = (payload) => ({
  type: GET_TYUMEN_DATE_GRAPH.ERROR,
  payload,
})
