import { all } from 'redux-saga/effects'
import getSettingsRoot from './getSettings'
import putSettingsRoot from './putSettings'
import getWidgetSettingsRoot from './getWidgetSettings'
import putWidgetSettingsRoot from './putWidgetSettings'

export default function* root() {
  yield all([
    getSettingsRoot(),
    putSettingsRoot(),
    getWidgetSettingsRoot(),
    putWidgetSettingsRoot(),
  ])
}
