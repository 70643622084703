import { DELETE_SERVICE_DESK_INTEGRATION } from './index'

export const requestDeleteIntegration = (payload) => ({
  type: DELETE_SERVICE_DESK_INTEGRATION.REQUEST,
  payload,
})

export const successDeleteIntegration = (payload) => ({
  type: DELETE_SERVICE_DESK_INTEGRATION.SUCCESS,
  payload,
})

export const errorDeleteIntegration = (payload) => ({
  type: DELETE_SERVICE_DESK_INTEGRATION.ERROR,
  payload,
})
