import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const FirstIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.19209e-07C0.447715 1.19209e-07 0 0.447716 0 1V17C0 17.5523 0.447715 18 1 18H2C2.55228 18 3 17.5523 3 17V1C3 0.447715 2.55229 1.19209e-07 2 1.19209e-07H1Z" fill={fill} className="svgFill" />
      <path d="M13.5469 17.6981L5 9L13.5469 0.301895C13.9424 -0.100632 14.5756 -0.100632 14.9711 0.301896L15.695 1.03857C16.1014 1.45215 16.1017 2.13134 15.6958 2.54538L9.36716 9L15.6958 15.4546C16.1017 15.8687 16.1014 16.5479 15.695 16.9614L14.9711 17.6981C14.5756 18.1006 13.9424 18.1006 13.5469 17.6981Z" fill={fill} className="svgFill" />
    </svg>
  )
}

FirstIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

FirstIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(FirstIcon)
