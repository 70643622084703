import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CrossIcon = ({ onClick }) => (
  <svg onClick={onClick} enableBackground="new 0 0 100 100" id="Layer_1" version="1.1" viewBox="0 0 100 100" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
    <polygon points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " />
  </svg>
)

CrossIcon.propTypes = {
  onClick: pt.func,
}
CrossIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(CrossIcon)
