import styled from 'styled-components'

export const Row = styled.div`
  display: grid;
  grid-template-columns: 26% 35% 35%;
  grid-template-rows: 30px 55px 55px 55px;
  gap: 10px 10px;
  padding: 10px;
`

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;

  ${({ disabled }) => disabled && 'color: #7C7C7C;'}

  i {
    width: 24px;
    font-size: 20px;
  }

  span {
    text-align: left;
    margin-left: 10px;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #3D3D3D;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.3px;
`
