import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
`

export const Statistic = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Counts = styled.div`
  display: flex;
  align-items: center;
`

export const Dynamic = styled.div`
  margin-left: 5px;

  ${({ color }) => `color: ${color || 'red'};`}
`

export const Percent = styled.div``

export const Line = styled.div`
  position: relative;
  background: #DADADA;
  height: 10px;
  border-radius: 20px;
  margin-top: 10px;
`

export const ActiveLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  ${({ color }) => `background: ${color || 'red'};`}
  ${({ width }) => width && `width: ${width}%;`}
  ${({ width }) => width >= 95 && `
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  `}
`
