import React, { useState, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import MapsIcons from '@/components/icons/maps'
import ACYWidget from '@/components/widgets/ACYWidget'
import { isZone } from '@/helpers/maps/isDefiniteElement'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { BRIZ } from '@/constants/integrations'
import ObjectState from '../ObjectState'
import ObjectInformation from '../ObjectInformation'
import {
  ObjectPassportizationStyled,
  ContentContainer,
  ObjectTitle,
  ObjectNameRow,
  CloseIcon,
  TabRow,
} from './styles'

const INFORMATION_TAB = {
  title: 'Информация по объекту',
  icon: MapsIcons.InformationIcon,
  value: 'information',
  component: (props) => (<ObjectInformation {...props} />),
}
const MONITARING_TAB = {
  title: 'Мониторинг состояния',
  icon: MapsIcons.CircleChartIcon,
  value: 'monitoring',
  component: (props) => (<ObjectState {...props} />),
}
const STATISTICS_TAB = {
  title: 'Статистика и аналитика',
  icon: MapsIcons.GraphicIcon,
  component: () => (React.Fragment),
}

const TABS = [
  INFORMATION_TAB,
  MONITARING_TAB,
  STATISTICS_TAB,
]

const ObjectPassportization = ({
  node,
  updateACYWidget,
  ACYWidgetSettings,
  connectedIntegrations,
  telemetry,
  refreshIntegration,
  image,
}) => {
  const [activeTab, setActiveTab] = useState(MONITARING_TAB)
  const [listView, setListView] = useState(false)

  const handleChangeTab = useCallback((tab) => () => {
    if (tab.value) {
      setActiveTab(tab)
    }
    setListView(false)
  }, [setActiveTab, setListView])
  const handleListView = useCallback(() => {
    setListView(!listView)
  }, [listView, setListView])

  const renderACYWidget = useCallback((data) => {
    const ACTWidgets = Object.keys(connectedIntegrations).map((connectedSystems) => (
      <ACYWidget
        systemAlias={connectedSystems}
        node={data}
        telemetry={telemetry}
        onSubmit={updateACYWidget}
        settings={ACYWidgetSettings[connectedSystems]}
        system={connectedIntegrations[connectedSystems]}
      />
    ))
    return (
      <>
        {ACTWidgets}
      </>
    )
  }, [ACYWidgetSettings, connectedIntegrations, telemetry, updateACYWidget])

  const renderListView = useCallback(() => (
    TABS.map((tab) => {
      const Icon = tab.icon || React.Fragment
      return (
        <TabRow onClick={handleChangeTab(tab)}>
          <Icon />
          {tab.title}
        </TabRow>
      )
    })
  ), [handleChangeTab])

  const renderActiveTabView = useCallback((data) => {
    if (isZone(data)) {
      return (
        <>
          {renderACYWidget(data)}
        </>
      )
    }
    const telemetryByCombineTypeAndId = node.system === BRIZ
      ? telemetry[`${node.type}-${node.aliasId}-${node.brizApiId}`] || {}
      : telemetry[`${node.type}-${node.aliasId}-${node.id}`] || {}
    const Component = activeTab.component
    return (
      <Component
        {...node}
        image={image}
        telemetry={telemetryByCombineTypeAndId}
        refreshIntegration={refreshIntegration}
      />
    )
  }, [node, telemetry, activeTab, renderACYWidget, refreshIntegration, image])

  return (
    <ObjectPassportizationStyled>
      <ObjectNameRow>
        <ObjectTitle>
          {node.name}
        </ObjectTitle>
        <PortalTooltip
          title={(<Lang id="tooltip.options" />)}
          renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
            <CloseIcon
              ref={wrapperRef}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onClick={isZone(node) ? noop : handleListView}
            >
              <MapsIcons.DotsIcon />
            </CloseIcon>
          )}
        />
      </ObjectNameRow>
      <ContentContainer>
        {listView
          ? (renderListView())
          : (renderActiveTabView(node))}
      </ContentContainer>
    </ObjectPassportizationStyled>
  )
}

ObjectPassportization.propTypes = {
  node: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    brizApiId: pt.oneOfType([pt.string, pt.number]),
    aliasId: pt.oneOfType([pt.string, pt.number]),
    name: pt.oneOfType([pt.string, pt.element]),
    type: pt.oneOfType([pt.string, pt.element]),
    system: pt.string,
  }).isRequired,
  updateACYWidget: pt.func,
  refreshIntegration: pt.func,
  ACYWidgetSettings: pt.objectOf(pt.object),
  telemetry: pt.objectOf(pt.object).isRequired,
  connectedIntegrations: pt.arrayOf(pt.array),
  treeData: pt.objectOf(pt.object),
  image: pt.string,
}
ObjectPassportization.defaultProps = {
  updateACYWidget: noop,
  refreshIntegration: noop,
  ACYWidgetSettings: {},
  connectedIntegrations: [],
  treeData: {},
  image: null,
}

export default ObjectPassportization
