import moment from 'moment'
import {
  HOURLY,
  DAILY,
  WEEKLY,
  MONTHLY,
} from '@/constants/forms/reportManager'

const timetable = {
  [HOURLY]: 'Каждые',
  [DAILY]: 'Ежедневно',
  [WEEKLY]: 'Еженедельно',
  [MONTHLY]: 'Ежемесячно',
}

const timetableMonth = {
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
  7: 'Вс',
}

export default ({ type, time, value, timeZone = 0, timeZoneConfig }) => {
  if (!time) {
    return ''
  }
  if (type === HOURLY) {
    return `${timetable[type]} ${value}ч. ${timeZoneConfig ? timeZoneConfig[timeZone / 60] : ''}`
  }
  if (type === DAILY) {
    return `${timetable[type]} ${moment.utc(time).utcOffset(timeZone).format('HH:mm')} ${timeZoneConfig ? timeZoneConfig[timeZone / 60] : ''}`
  }
  if (type === WEEKLY) {
    return `${timetable[type]} ${moment.utc(time).utcOffset(timeZone).format('HH:mm')} ${timetableMonth[value]} ${timeZoneConfig ? timeZoneConfig[timeZone / 60] : ''}`
  }
  if (type === MONTHLY) {
    return `${timetable[type]} ${moment.utc(time).utcOffset(timeZone).format('HH:mm')} ${value}-го ${timeZoneConfig ? timeZoneConfig[timeZone / 60] : ''}`
  }
  return ''
}
