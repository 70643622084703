import get from 'lodash/get'
import {
  INFO,
} from '@/constants/objectStatuses'
import {
  COLD_WATER_DEVICE,
  HEATING_DEVICE, CONTROLLER,
} from '@/constants/objectTypes'
import { ATM } from '@/constants/integrations'
import {
  HEAT_SUPPLY,
} from '@/constants/names'

const getObjectDevices = (
  object = {},
  integration = {},
  permissions = {},
  objectTypes = [],
) => get(object, 'devices', [])
  .map((device) => {
    // TODO change to normal type from backend
    const isWater = device.serial === '1312316'
    const hasWaterFilter = objectTypes.length > 0
      ? objectTypes.includes(COLD_WATER_DEVICE)
      : true
    const hasHeatFilter = objectTypes.length > 0
      ? objectTypes.includes(HEATING_DEVICE)
      : true
    const deviceObject = ({
      id: device.objectId,
      internalId: device.objectId,
      parentId: object.objectId,
      alias: integration.alias,
      aliasId: integration.aliasId,
      status: INFO,
      system: ATM,
      type: isWater ? COLD_WATER_DEVICE : HEATING_DEVICE,
      name: device.name || `Девайс ID${device.objectId}`,
      integrationTypes: isWater ? [] : [HEAT_SUPPLY],
      location: [
        object.latitude,
        object.longitude,
      ],
    })

    if (
      (isWater && permissions.water && hasWaterFilter)
      || (!isWater && permissions.heat && hasHeatFilter)
    ) {
      return deviceObject
    }

    return null
  })
  .filter((device) => !!device)

const buildOneSimTree = (data, permissions = {}) => {
  const { integrationsIds, objectTypes } = permissions
  return data.reduce((accumulator, integration) => {
    if (
      integrationsIds
      && integrationsIds.length > 0
      && !integrationsIds.includes(integration.aliasId)) {
      return accumulator
    }
    return [
      ...accumulator,
      ...integration.companyObjects.map((object) => ({
        id: object.objectId,
        internalId: object.objectId,
        alias: integration.alias,
        aliasId: integration.aliasId,
        name: object.name || `Геозона ID${object.externalGroupId}`,
        type: CONTROLLER,
        status: INFO,
        system: ATM,
        location: [object.latitude, object.longitude],
        integrationTypes: [HEAT_SUPPLY],
        children: [...getObjectDevices(object, integration, permissions, objectTypes)],
      })),
    ]
  }, [])
}

export default buildOneSimTree
