import {
  OUTDOOR_LIGHTNING,
  ROAD_REPAIRING,
  TRANSPORT_STATION,
} from '@/constants/tyumenOurHomeProccesses'
import {
  ALL_CHARTS,
  APPEAL_TOPICS,
  APPEAL_STATUSES,
  APPEAL_STATISTIC,
  APPEAL_TIME,

  ALL_PERIOD,
  YEAR,
  QUARTER,
  MONTH,
  WEEK,
  DAY,

  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from '@/constants/tyumenOurHome'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'

export const urbanProcesses = [
  { value: OUTDOOR_LIGHTNING, title: 'Наружное освещение' },
  { value: ROAD_REPAIRING, title: 'Ремонт и реконструкция дорог' },
  { value: TRANSPORT_STATION, title: 'Остановки общественного транспорта' },
]

export const chartTypeOptions = [
  { value: ALL_CHARTS, title: 'Все типы графиков' },
  { value: APPEAL_TOPICS, title: 'Тематика обращений' },
  { value: APPEAL_STATUSES, title: 'Статусы решений по обращениям' },
  { value: APPEAL_STATISTIC, title: 'Статистика по обращениям' },
  { value: APPEAL_TIME, title: 'Среднее вермя принятия и рассмотрения решений' },
]

export const selectOptions = [
  {
    title: 'Все статусы',
    value: null,
  },
  {
    title: 'В процессе решения',
    value: WARNING,
  },
  {
    title: 'Отказ или проблема не обнаружена',
    value: ERROR,
  },
  {
    title: 'Успешно решены',
    value: OK,
  },
]

export const periodOptions = [
  { value: ALL_PERIOD, title: 'Весь период' },
  { value: YEAR, title: 'Год' },
  { value: QUARTER, title: 'Квартал' },
  { value: MONTH, title: 'Месяц' },
  { value: WEEK, title: 'Неделя' },
  { value: DAY, title: 'День' },
]

export const intervalOptions = [
  { value: ANNUALLY, title: 'Ежегодно' },
  { value: MONTHLY, title: 'Ежемесячно' },
  { value: WEEKLY, title: 'Еженедельно' },
  { value: DAILY, title: 'Ежедневно' },
]
