import { fork, takeLatest, all } from 'redux-saga/effects'
import { GET_ALARMS_COUNT } from '@/store/actions/alarmManager'
import getEventCountSaga from './getEventCount'


function* watchStateRequest() {
  yield takeLatest(GET_ALARMS_COUNT.EVENTS, getEventCountSaga)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
