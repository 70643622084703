import React, { useCallback, useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import { telemetryArrayProps } from '@/propTypes/maps'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import useReduxTable from '@/hooks/useReduxTable'
import Loader from '@/components/blocks/Loader'
import UsersIcons from '@/components/icons/users'
import TextControl from '@/components/controls/TextControl'
import PageSidebarContainer from '@/containers/regions/PageSidebarContainer'
import DutyTable from '@/components/blocks/DutyTable'
import { REQUEST_STATUSES } from '@/constants/requests'
import { ALARM_MANAGER_TABLE } from '@/store/actions/alarmManager'
import { getTableParameters } from '@/store/selectors/alarmManager'
import {
  fields,
  exportOptions,
  eventsOptions,
  settingsOptions,
} from '@/constants/tablesConfig/alarmManager'
import AlarmSideBar from './components/AlarmSideBar'
import {
  Main,
  Content,
} from './styles'

const AlarmsManager = ({
  tree,
  data,
  node,
  tableStatus,
  exportFileStatus,
  eventTypes,
  eventStatus,
  selectedElement,
  aliasId,
  eventsCount,
  eventData,
  dates,
  telemetry,
  loadingStatistic,
  loadingTable,
  isAlarms,

  setEventTypes,
  setStatusTypes,
  setNode,
  setSelectedElement,
  setDatesRange,
  requestAlarmManagerTable,
  requestAlarmManagerFile,
  requestCheckAllAlarms,
  setSelectedAllElements,
}) => {
  const [treeQuery, setTreeQuery] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const [isDirty, setIsDirty] = useState(false)
  useHtmlTitle('Журнал неисправностей')
  useEffect(() => {
    if (Object.keys(tree).length && !isDirty && !(node && node.id)) {
      setNode(tree)
      setIsDirty(true)
    }
  }, [tree, isDirty, setNode, node])

  useEffect(() => {
    requestAlarmManagerTable()
    requestCheckAllAlarms({ id: node.internalId })
  }, [node, eventsCount, requestAlarmManagerTable, requestCheckAllAlarms])

  const tableProps = useReduxTable(ALARM_MANAGER_TABLE, getTableParameters)

  const handleReadAllAlarms = useCallback(() => {
    setSelectedAllElements({ id: node.internalId })
  }, [node.internalId, setSelectedAllElements])

  const getObjectTelemetry = (object) => telemetry[`${object.type}-${object.aliasId}-${object.id}`] || {}

  const onSelectNode = useCallback((selectedNode, event) => {
    if (event === 'click') {
      setNode(selectedNode)
    }
  },
  [setNode])

  const handleGetData = useCallback((datesRange) => {
    setDatesRange(datesRange)
    requestAlarmManagerTable()
  }, [requestAlarmManagerTable, setDatesRange])

  return (
    <Main>
      <Content>
        <PageSidebarContainer
          onIconClick={noop}
          title="Журнал неисправностей"
          treeData={tree}
          searchQuery={treeQuery}
          onSelect={onSelectNode}
          getTelemetry={getObjectTelemetry}
          headerContent={(
            <Lang id="usersPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={setTreeQuery}
                />
              )}
            </Lang>
          )}
        />
        <AlarmSideBar
          selectedNodeName={node.name}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          setEventTypes={setEventTypes}
          eventsOptions={eventsOptions}
          isOpen={isOpen}
          toggleIsOpen={setIsOpen}
          eventTypes={eventTypes}
          requestAlarmManagerTable={handleGetData}
          aliasId={aliasId}
          eventsCount={tableProps.values.resultCount}
          eventData={eventData}
          isTableLoaded={tableStatus === REQUEST_STATUSES.IDLE}
          dates={dates}
          loadingStatistic={loadingStatistic}
        />
        {loadingTable
          ? <Loader center />
          : (
            <DutyTable
              withDates
              fields={fields}
              dates={dates}
              exportOptions={exportOptions}
              eventsOptions={eventsOptions}
              settingsOptions={settingsOptions}
              getTableData={handleGetData}
              handleReadAllAlarms={handleReadAllAlarms}
              isAlarms={isAlarms}

              {...tableProps}

              eventStatus={eventStatus}
              eventTypes={eventTypes}

              data={data}
              rowSelector={setSelectedElement}

              setEventTypes={setEventTypes}
              setStatusTypes={setStatusTypes}
              onFileSelect={requestAlarmManagerFile}
              onUpdateData={requestAlarmManagerTable}
              fileStatus={exportFileStatus}
              headerHeight={42}
            />
          )}
      </Content>
    </Main>
  )
}

AlarmsManager.propTypes = {
  tree: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),
  eventsCount: pt.number,
  eventData: pt.arrayOf(pt.object),
  tableStatus: pt.string,
  exportFileStatus: pt.string,
  eventTypes: pt.arrayOf(pt.string),
  eventStatus: pt.arrayOf(pt.string),
  aliasId: pt.string,
  selectedElement: pt.objectOf(pt.object),
  dates: pt.arrayOf(pt.string),
  node: pt.shape({
    name: pt.string,
  }),
  telemetry: telemetryArrayProps,

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  setNode: pt.func,
  setDatesRange: pt.func,
  requestAlarmManagerTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsManager.defaultProps = {
  tree: {},
  data: [],
  eventsCount: 0,
  eventData: [],
  selectedElement: null,
  tableStatus: '',
  exportFileStatus: '',
  eventTypes: [],
  eventStatus: [],
  aliasId: null,
  dates: {},
  node: {},
  telemetry: [],

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  setNode: noop,
  setDatesRange: noop,
  requestAlarmManagerTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsManager
