import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TrafficLightIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 28 28">
      <path d="M22 12h-3v-1.14c1.72-.45 3-2 3-3.86h-3V6c0-.55-.45-1-1-1h-8c-.55 0-1 .45-1 1v1H6c0 1.86 1.28 3.41 3 3.86V12H6c0 1.86 1.28 3.41 3 3.86V17H6c0 1.86 1.28 3.41 3 3.86V22c0 .55.45 1 1 1h8c.55 0 1-.45 1-1v-1.14c1.72-.45 3-2 3-3.86h-3v-1.14c1.72-.45 3-2 3-3.86zm-8 9a2 2 0 11-.09-3.998A2 2 0 0114 21zm0-5a2 2 0 11-.09-3.998A2 2 0 0114 16zm0-5a2 2 0 01-2-2c0-1.11.89-2 2-2a2 2 0 010 4z" fill={fill} className="svgFill" />
    </svg>
  )
}

TrafficLightIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrafficLightIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrafficLightIcon)
