import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HighBuildingIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2598 5.94415H9.40046V6.69636H10.2598V5.94415Z" fill={fill} className="svgFill" />
      <path d="M8.57806 5.94415H7.71871V6.69636H8.57806V5.94415Z" fill={fill} className="svgFill" />
      <path d="M6.85935 13.4903H7.71871V12.7381H6.85935V13.4903Z" fill={fill} className="svgFill" />
      <path d="M6.85935 11.9829H7.71871V11.2307H6.85935V11.9829Z" fill={fill} className="svgFill" />
      <path d="M6.85935 10.4649H7.71871V9.71272H6.85935V10.4649Z" fill={fill} className="svgFill" />
      <path d="M6.85935 8.98232H7.71871V8.23011H6.85935V8.98232Z" fill={fill} className="svgFill" />
      <path d="M8.57806 13.4723H9.43741V12.7201H8.57806V13.4723Z" fill={fill} className="svgFill" />
      <path d="M8.57806 11.9716H9.43741V11.2194H8.57806V11.9716Z" fill={fill} className="svgFill" />
      <path d="M8.57806 10.4725H9.43741V9.72024H8.57806V10.4725Z" fill={fill} className="svgFill" />
      <path d="M8.57806 8.96352H9.43741V8.21131H8.57806V8.96352Z" fill={fill} className="svgFill" />
      <path d="M10.2968 13.4647H11.1561V12.7125H10.2968V13.4647Z" fill={fill} className="svgFill" />
      <path d="M10.2968 11.9618H11.1561V11.2096H10.2968V11.9618Z" fill={fill} className="svgFill" />
      <path d="M10.2968 10.4589H11.1561V9.7067H10.2968V10.4589Z" fill={fill} className="svgFill" />
      <path d="M10.2968 8.94847H11.1561V8.19626H10.2968V8.94847Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10.2315 5.25813H11.1226V6.76105H12V15H6V6.76105H6.88943V5.25813H7.73074V4.50818H8.61845V3H9.41593V4.50818H10.2315V5.25813Z" fill={fill} className="svgFill" />
    </svg>
  )
}

HighBuildingIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HighBuildingIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HighBuildingIcon)
