export default (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const numberModule = Math.abs(number)

  return titles[
    numberModule % 100 > 4 && numberModule % 100 < 20
      ? 2
      : cases[numberModule % 10 < 5 ? numberModule % 10 : 5]
  ]
}
