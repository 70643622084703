import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HandicappedIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M43.923 45.9449L40.6624 31.8155C40.2989 30.3614 38.9976 29.3461 37.4989 29.3461H31.1634C30.2671 27.8745 29.0985 26.5842 27.717 25.5463V16.3034C27.717 13.846 25.085 12.3432 22.9979 13.3866L16.4766 16.6473C15.3648 17.2029 14.6852 18.3206 14.6852 19.5641V23.7345C14.665 23.7414 14.6445 23.7436 14.6245 23.7515C9.37322 25.8025 5.97986 30.7716 5.97986 36.4108C5.97986 43.9023 12.0745 49.9969 19.566 49.9969C27.108 49.9969 33.4423 43.7518 33.128 35.8674H34.9533L37.5962 47.5275C38.0316 49.266 39.7818 50.3447 41.551 49.8998C43.2942 49.464 44.3583 47.6899 43.923 45.9449ZM19.5658 46.7363C13.8723 46.7363 9.24041 42.1043 9.24041 36.4109C9.24041 32.5469 11.3481 29.1134 14.6851 27.3292V31.5199C14.6851 33.318 16.1369 34.7806 17.9349 34.7806C19.733 34.7806 21.1956 33.318 21.1956 31.5199V26.1833C21.2762 26.2118 21.3575 26.2393 21.4445 26.2554C26.3386 27.1543 29.8913 31.4255 29.8913 36.411C29.8912 42.1043 25.2593 46.7363 19.5658 46.7363Z" fill={fill} className="svgFill" />
        <path d="M25.0002 0C22.3032 0 20.1092 2.19395 20.1092 4.89102C20.1092 7.58809 22.3031 9.78203 25.0002 9.78203C27.6973 9.78203 29.8912 7.58809 29.8912 4.89102C29.8912 2.19395 27.6972 0 25.0002 0Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H50V50H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

HandicappedIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HandicappedIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HandicappedIcon)
