import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import hash from 'hash-sum'
import { useTreeState } from 'react-hyper-tree'
import TreeWrapped from './components/TreeWrapped'

const useMemoTreeState = (params) => {
  const tree = useTreeState(params)

  const memoRequired = useMemo(() => tree.required, [tree.required])
  const memoHandlers = useMemo(() => tree.handlers, [tree.handlers])

  return useMemo(() => ({
    required: memoRequired,
    handlers: memoHandlers,
  }), [memoRequired, memoHandlers])
}

const SidebarTree = ({
  id,
  data,
  telemetryHash,
  searchQuery,
  getTelemetry,
  onSelectNode,
  withSort,
}) => {
  const filterTree = useCallback(({
    data: { name },
  }) => (name || '').toLowerCase().includes((searchQuery || '').toLowerCase()), [searchQuery])
  const sortTree = useCallback((a, b) => {
    if (a.name > b.name) { return 1 }
    if (a.name < b.name) { return -1 }
    return 0
  }, [])
  const sort = useMemo(() => (withSort ? sortTree : undefined), [withSort, sortTree])
  const filter = useMemo(() => (searchQuery ? filterTree : null), [searchQuery, filterTree])

  const [localData, setLocalData] = useState(data)
  useEffect(() => {
    const hashFormattedData = hash(data)
    const hashLocalData = hash(localData)

    if (hashFormattedData !== hashLocalData) {
      setLocalData(data)
    }
  }, [data, localData])

  const tree = useMemoTreeState({
    id,
    data: localData,
    sort,
    filter,
    multipleSelect: false,
  })

  const node = useMemo(() => ({
    onSelectNode,
    getTelemetry,
    telemetryHash,
  }), [onSelectNode, getTelemetry, telemetryHash])

  return (
    <TreeWrapped
      id={id}
      node={node}
      tree={tree}
    />
  )
}

SidebarTree.propTypes = {
  id: pt.string,
  withSort: pt.bool,
  searchQuery: pt.string,
  getTelemetry: pt.func,
  onSelectNode: pt.func,
}
SidebarTree.defaultProps = {
  id: 'objects-tree',
  withSort: false,
  searchQuery: '',
  getTelemetry: noop,
  onSelectNode: noop,
}

export default React.memo(SidebarTree)
