import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UnionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg className="svgFill" onClick={onClick} width="40" height="45" viewBox="0 0 40 45" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M26.3832 37.761C34.2989 35.179 40 27.9271 40 19.3846C40 8.67879 31.0457 0 20 0C8.95431 0 0 8.67879 0 19.3846C0 28.2292 6.11144 35.6903 14.4646 38.0172L20.3466 45L26.3832 37.761Z" fill={fill} />
    </svg>
  )
}

UnionIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
UnionIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(UnionIcon)
