import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TrashIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  // fill="#7C7C7C"

  return (
    <svg onClick={onClick} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" fillRule="evenodd" clipRule="evenodd" d="M8.0001 0.100006C6.39847 0.100006 5.1001 1.39838 5.1001 3.00001V3.10001H1.0001C0.503041 3.10001 0.100098 3.50295 0.100098 4.00001C0.100098 4.49706 0.503041 4.90001 1.0001 4.90001H2.30894L3.13436 17.1715L3.13448 17.1735C3.226 18.6219 4.42747 19.75 5.879 19.75H12.1212C13.5727 19.75 14.7741 18.6219 14.8657 17.1736L14.8658 17.1715L15.6913 4.90001H17.0001C17.4972 4.90001 17.9001 4.49706 17.9001 4.00001C17.9001 3.50295 17.4972 3.10001 17.0001 3.10001H12.9001V3.00001C12.9001 1.39838 11.6017 0.100006 10.0001 0.100006H8.0001ZM11.1001 3.10001H6.9001V3.00001C6.9001 2.39249 7.39258 1.90001 8.0001 1.90001H10.0001C10.6076 1.90001 11.1001 2.39249 11.1001 3.00001V3.10001ZM7.59936 7.96156C7.57808 7.46496 7.15825 7.07964 6.66165 7.10092C6.16505 7.12221 5.77973 7.54203 5.80101 8.03863L6.10101 15.0386C6.1223 15.5352 6.54212 15.9206 7.03872 15.8993C7.53533 15.878 7.92065 15.4582 7.89936 14.9616L7.59936 7.96156ZM12.1994 8.03863C12.2206 7.54203 11.8353 7.12221 11.3387 7.10092C10.8421 7.07964 10.4223 7.46496 10.401 7.96156L10.101 14.9616C10.0797 15.4582 10.4651 15.878 10.9617 15.8993C11.4583 15.9206 11.8781 15.5352 11.8994 15.0386L12.1994 8.03863Z"/>
    </svg>
  )
}

TrashIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrashIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrashIcon)
