import { UPDATE_TENANT } from '../index'

export const requestUpdateTenant = (payload) => ({
  type: UPDATE_TENANT.REQUEST,
  payload,
})

export const responseUpdateTenant = (payload) => ({
  type: UPDATE_TENANT.SUCCESS,
  payload,
})

export const errorUpdateTenant = (payload) => ({
  type: UPDATE_TENANT.ERROR,
  payload,
})
