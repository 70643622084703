import React from 'react'
import pt from 'prop-types'

import {
  Container,
  Header,
  Content,
} from './styles'

const BaseWidget = ({
  title, colors, children, style, onWidgetClick,
}) => (
  <Container style={style}>
    <Header colors={colors}>{title}</Header>
    <Content onClick={onWidgetClick}>{children}</Content>
  </Container>
)

BaseWidget.propTypes = {
  title: pt.oneOfType([pt.string, pt.node]).isRequired,
  colors: pt.shape({
    color: pt.string,
    bgColor: pt.string,
  }),
  children: pt.oneOfType([pt.string, pt.node]).isRequired,
}
BaseWidget.defaultProps = {
  colors: {},
}

export default BaseWidget
