import React from 'react'
import noop from 'lodash/noop'
import pt from 'prop-types'
import get from 'lodash/get'
import HeaderIcons from '@/components/icons/header'
import Button from '@/components/blocks/Button'
import Icons from '@/components/icons/users'
import localConfig from './config'
import {
  ContentContainer,
  Container,
  Circle,
  PrimaryTitle,
  SecondaryTitle,
  ButtomsContainer,
  IconContainer,
} from './styles'

const GlobalPopupContent = (props) => {
  const {
    type,
    title,
    message,
    onClose,
    config,
    shoodBeFixByArtem,
  } = props
  const Icon = get(config, `${type}.icon`, React.Fragment)
  return (
    <Container>
      <Circle type={type}>
        <Icon />
      </Circle>
      <HeaderIcons.PopupHeaderIcon />
      <IconContainer onClick={onClose}>
        <Icons.CrossIcon/>
      </IconContainer>
      <ContentContainer>
        <PrimaryTitle>
          {title}
        </PrimaryTitle>
        <SecondaryTitle>
          {message}
        </SecondaryTitle>
        <ButtomsContainer>
          {!shoodBeFixByArtem
            ? (
              <>
                {get(config, `${type}.buttons`, []).map((element) => (
                  <Button
                    onClick={element.onClickSelector || noop}
                    styleType={element.statusType}
                  >
                    {element.title}
                  </Button>
                ))}
              </>
            )
            : (
              <>
                {get(config, `${type}.buttons`, []).map((element) => (
                  <Button
                    onClick={get(props, element.onClickSelector, noop)}
                    styleType={element.statusType}
                  >
                    {element.title}
                  </Button>
                ))}
              </>
            )}
        </ButtomsContainer>
      </ContentContainer>
    </Container>
  )
}

GlobalPopupContent.defaultProps = {
  type: 'success',
  title: 'Внимание!',
  message: 'Вы действительно хотите\nудалить объект из системы?',
  onClose: noop,
  primaryAction: noop,
  secondaryAction: noop,
  config: localConfig,
  shoodBeFixByArtem: false,
}

GlobalPopupContent.propTypes = {
  type: pt.oneOf(['success', 'warning', 'error', 'info', 'critical']),
  title: pt.string,
  message: pt.string,
  onClose: pt.func,
  primaryAction: pt.func,
  secondaryAction: pt.func,
  config: pt.objectOf(pt.object),
  shoodBeFixByArtem: pt.bool,
}

export default GlobalPopupContent
