import { GET_GROUP } from '../index'

export const requestGetTenantGroup = (payload) => ({
  type: GET_GROUP.REQUEST,
  payload,
})

export const responseGetTenantGroup = (payload) => ({
  type: GET_GROUP.SUCCESS,
  payload,
})

export const errorGetTenantGroup = (payload) => ({
  type: GET_GROUP.ERROR,
  payload,
})
