import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReverseCrossIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" fillRule="evenodd" clipRule="evenodd" d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM4.82289 5.88192C4.39237 5.4514 4.39237 4.75341 4.82289 4.32289C5.25341 3.89237 5.9514 3.89237 6.38192 4.32289L9.49999 7.44092L12.6181 4.32289C13.0486 3.89237 13.7466 3.89237 14.1771 4.32289C14.6076 4.75341 14.6076 5.4514 14.1771 5.88192L11.0591 8.99999L14.1771 12.1181C14.6076 12.5486 14.6076 13.2466 14.1771 13.6771C13.7466 14.1076 13.0486 14.1076 12.6181 13.6771L9.49999 10.5591L6.38192 13.6771C5.9514 14.1076 5.2534 14.1076 4.82289 13.6771C4.39237 13.2466 4.39237 12.5486 4.82289 12.1181L7.94092 8.99999L4.82289 5.88192Z"/>
    </svg>
  )
}

ReverseCrossIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReverseCrossIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReverseCrossIcon)
