import { GET_ALARMS_COUNT } from './index'

export const eventsGetAlarmsCount = (data) => ({
  type: GET_ALARMS_COUNT.EVENTS,
  payload: data,
})

export const requestGetAlarmsCount = (payload) => ({
  type: GET_ALARMS_COUNT.REQUEST,
  payload,
})

export const successGetAlarmsCount = (payload) => ({
  type: GET_ALARMS_COUNT.SUCCESS,
  payload,
})

export const beginGetAlarmsCountNotification = () => ({
  type: GET_ALARMS_COUNT.BEGIN,
})

export const endGetAlarmsCountNotification = () => ({
  type: GET_ALARMS_COUNT.END,
})

export const errorGetAlarmsCount = (payload) => ({
  type: GET_ALARMS_COUNT.ERROR,
  payload,
})
