import {
  COUNTRY,
  CITY,
  CONTROLLER,
  LIGHT,
} from '@/constants/objectTypes'
import {
  INFO, OK,
} from '@/constants/objectStatuses'
import { KULON } from '@/constants/integrations'

export const config = {
  id: 1,
  isHasInstallations: true,
  name: 'Россия',
  type: COUNTRY,
  location: [60, 90],
  children: [],
}

export const mockElements = [
  {
    id: 86,
    name: 'Волоколамск',
    location: [56.0360129, 35.9583358],
    type: CITY,
    children: [
      {
        id: 186,
        type: CONTROLLER,
        geoZoneId: 86,
        name: '18/7',
        system: KULON,
        location: [56.03257398897064, 35.9538071278687],
        children: [
          {
            id: 2100,
            geoZoneId: 86,
            parentId: 186,
            type: LIGHT,
            name: '44.1',
            system: KULON,
            status: OK,
            location: [56.03276020684353, 35.9536971572991],
          },
          {
            id: 2101,
            geoZoneId: 86,
            parentId: 186,
            type: LIGHT,
            name: '44.1',
            system: KULON,
            status: INFO,
            location: [56.03269412963706, 35.95399488249975],
          },
          {
            id: 2102,
            geoZoneId: 86,
            parentId: 186,
            type: LIGHT,
            system: KULON,
            name: '44.1',
            status: INFO,
            location: [56.03257248720993, 35.954324794208574],
          },
          {
            id: 2103,
            geoZoneId: 86,
            parentId: 186,
            system: KULON,
            type: LIGHT,
            name: '44.2',
            status: INFO,
            location: [56.032883742022285, 35.953710499074006],
          },
          {
            id: 2104,
            geoZoneId: 86,
            system: KULON,
            parentId: 186,
            type: LIGHT,
            name: '44.456',
            status: INFO,
            location: [56.032829679035565, 35.95382583406165],
          },
          {
            id: 2105,
            geoZoneId: 86,
            system: KULON,
            parentId: 186,
            type: LIGHT,
            name: '4234.3',
            status: INFO,
            location: [56.03269301948006, 35.954370322491684],
          },
          {
            id: 2106,
            geoZoneId: 86,
            system: KULON,
            parentId: 186,
            type: LIGHT,
            name: '455.98',
            status: INFO,
            location: [56.03245273776476, 35.954241576458976],
          },
          {
            id: 2107,
            geoZoneId: 86,
            parentId: 186,
            system: KULON,
            type: LIGHT,
            name: '45.7456',
            status: INFO,
            location: [56.032320582181086, 35.95415038135245],
          },
          {
            id: 2108,
            geoZoneId: 86,
            system: KULON,
            parentId: 186,
            type: LIGHT,
            name: '456.478',
            status: INFO,
            location: [56.0320097144144, 35.95390093591407],
          },
          {
            id: 2109,
            geoZoneId: 86,
            system: KULON,
            parentId: 186,
            type: LIGHT,
            name: '4569.478',
            status: INFO,
            location: [56.03221545809688, 35.954056504036934],
          },
        ],
      },
    ],
  },
  {
    id: 108,
    name: 'Благовещенск',
    location: [50.290527, 127.527161],
    type: CITY,
    zone: 9,
    children: [
      {
        id: 204,
        type: CONTROLLER,
        system: KULON,
        geoZoneId: 108,
        name: '7/1',
        location: [50.279179910361094, 127.53748062105645],
        children: [
          {
            id: 2200,
            geoZoneId: 108,
            parentId: 204,
            system: KULON,
            type: LIGHT,
            name: '0',
            status: INFO,
            location: [50.27907334611882, 127.53744843454828],
          },
          {
            id: 2201,
            geoZoneId: 108,
            parentId: 204,
            type: LIGHT,
            system: KULON,
            name: '1',
            status: INFO,
            location: [50.278970219204375, 127.5374323412942],
          },
          {
            id: 2202,
            geoZoneId: 108,
            parentId: 204,
            type: LIGHT,
            system: KULON,
            name: '2',
            status: INFO,
            location: [50.27910600292823, 127.5371828958558],
          },
          {
            id: 2203,
            geoZoneId: 108,
            parentId: 204,
            system: KULON,
            type: LIGHT,
            name: '3',
            status: INFO,
            location: [50.27896850042057, 127.5371614381837],
          },
          {
            id: 2204,
            geoZoneId: 108,
            parentId: 204,
            system: KULON,
            type: LIGHT,
            name: '4',
            status: INFO,
            location: [50.27913006582603, 127.53700855226981],
          },
          {
            id: 2205,
            geoZoneId: 108,
            parentId: 204,
            type: LIGHT,
            system: KULON,
            name: '5',
            status: INFO,
            location: [50.27902006390772, 127.53780248613823],
          },
          {
            id: 2206,
            geoZoneId: 108,
            parentId: 204,
            system: KULON,
            type: LIGHT,
            name: '6',
            status: INFO,
            location: [50.27892553080501, 127.53777298183905],
          },
          {
            id: 2207,
            geoZoneId: 108,
            parentId: 204,
            type: LIGHT,
            system: KULON,
            name: '7',
            status: INFO,
            location: [50.27898912582217, 127.53698977680675],
          },
          {
            id: 2208,
            geoZoneId: 108,
            parentId: 204,
            type: LIGHT,
            system: KULON,
            name: '8',
            status: INFO,
            location: [50.2791592850426, 127.53681811542981],
          },
          {
            id: 2209,
            geoZoneId: 108,
            parentId: 204,
            system: KULON,
            type: LIGHT,
            name: '9',
            status: INFO,
            location: [50.27900459486747, 127.53676178904045],
          },
        ],
      },
    ],
  },
]

export const getDefaultTree = () => config

export default config
