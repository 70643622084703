import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UpperArrow = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 0.5L18.1981 9.04691C18.6006 9.44244 18.6006 10.0756 18.1981 10.4711L17.4614 11.195C17.0479 11.6014 16.3687 11.6017 15.9546 11.1958L9.5 4.86716L3.04538 11.1958C2.63134 11.6017 1.95215 11.6014 1.53857 11.195L0.801896 10.4711C0.399368 10.0756 0.399368 9.44244 0.801895 9.04691L9.5 0.5Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UpperArrow.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UpperArrow.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UpperArrow)
