import {
  takeLatest, put,
} from 'redux-saga/effects'
import get from 'lodash/get'
import noop from 'lodash/noop'
import request from '@/helpers/axios'
import { SERVICE_DESK_INTEGRATION_URL } from '@/constants/apiRoutes'
import {
  startSetWidgetSettings,
} from '@/store/actions/appSettings'
import { ADD_SERVICE_DESK_INTEGRATION } from '@/store/actions/serviceDeskMyCity'
import createNotifications from '@/helpers/notification'
import {
  URL,
} from '@/constants/forms/integration'

const ERRORS = {
  'bad_argument.url:Invalid url': URL,
}

const ERRORS_TO_MESSAGES = {
  'bad_argument.url:Invalid url': 'Ошибка обработки url. Проверьте данные',
}

function* addIntegrationSaga({ payload }) {
  const {
    id, values, widgetType, formicForm,
  } = payload
  try {
    const formData = new window.FormData()
    formData.append('propertyConfigFile', values.file)
    formData.append('temporaryId', id || null)
    formData.append('clientId', values.clientID)
    formData.append('clientSecret', values.secretID)
    formData.append('name', values.name)
    formData.append('url', values.url)
    formData.append('isUserIntegration', values.isUserIntegration || false)
    const response = yield request({
      url: SERVICE_DESK_INTEGRATION_URL,
      method: 'post',
      body: formData,
      headers: { 'content-type': 'multipart/form-data' },
    })
    yield put(startSetWidgetSettings(id, values, widgetType))
    window.open(response.data.link)
  } catch (error) {
    const { errorCode } = error.response.data
    const toast = createNotifications()
    toast({
      title: 'Ошибка синхронизации!',
      description: `Не удается подключиться к Сервис Деск “Мой город”.\nКод ошибки: ${errorCode}`,
      type: 'error',
    })
    const setErrors = get(formicForm, 'current.setErrors', noop)
    setErrors({
      [`${ERRORS[errorCode]}-${id}`]: ERRORS_TO_MESSAGES[errorCode] || 'Неверный формат данных',
    })
    console.log('TCL: function*addIntegrationSaga -> error', error)
  }
  return null
}

export default function* root() {
  yield takeLatest(ADD_SERVICE_DESK_INTEGRATION.REQUEST, addIntegrationSaga)
}
