import { ADD_PASSPORT_PHOTO } from './index'

export const requestAddPassportPhoto = (payload) => ({
  type: ADD_PASSPORT_PHOTO.REQUEST,
  payload,
})

export const responseAddPassportPhoto = (payload) => ({
  type: ADD_PASSPORT_PHOTO.SUCCESS,
  payload,
})

export const errorAddPassportPhoto = (payload) => ({
  type: ADD_PASSPORT_PHOTO.ERROR,
  payload,

})
