import React, {
  useMemo,
  useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import get from 'lodash/get'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import TextAreaField from '@/components/fields/TextAreaField'
import CoreIcons from '@/components/icons/core'
import MapIcons from '@/components/icons/maps'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import SelectField from '@/components/fields/SelectField'
import { createProjectValidator } from '@/constants/validationFields'
import CREATE_PROJECT_NAMES from '@/constants/forms/createProject'
import Loader from '@/components/blocks/Loader'
import { CHAR_LIMITS } from '@/constants/sizes'
import { DEBOUNCE_DELAY_LARGE } from '@/constants/time'
import {
  TEXT,
  ARIA,
  SELECT,
  CUSTOMS_FIELDS,
  ADD_CUSTOMS_FIELDS,
} from '@/constants/semanticNames'
import { GEOZONE } from '@/constants/objectTypes'
import { projectConfig } from './config'
import {
  InputAndLabelContainer,
  CustomFieldContainer,
  AddFieldButton,
  IconContainer,
  Container,
  Header,
  HeaderTitle,
  FieldsContainer,
  StyledButton,
  CancelButton,
  CustomFieldBlock,
  StyledForm,
} from './styles'

const ProjectForm = ({
  selectedNode,
  setUrlFormValues,
  submit,
  submitError,
  title,
  onCancel,
  formValues,
  getParentZoneOptions,
  parentElements,
  edit,
}) => {
  const localParentElements = useMemo(() => parentElements.filter(
    (element) => element.value !== formValues.rootId,
  ), [parentElements, formValues])

  useEffect(() => {
    getParentZoneOptions({ id: formValues.rootId, type: GEOZONE })
  }, [formValues.rootId, getParentZoneOptions])

  const formattedConfig = useMemo(() => projectConfig.map((element) => {
    if (element.selector === CREATE_PROJECT_NAMES.PARENT) {
      return {
        ...element,
        options: localParentElements,
      }
    }
    return element
  }), [localParentElements])

  const calcLocalValue = useMemo(() => {
    const initialValues = projectConfig.reduce((accumulator, field) => ({
      ...accumulator,
      [field.selector]: get(selectedNode, field.selector, ''),
    }), {})
    initialValues.id = selectedNode.id
    return initialValues
  }, [selectedNode])

  const debounceSubmitHandler = (values, setSubmitting) => debounce(() => {
    submit({
      formValues,
      values,
      setSubmitting,
      setUrlFormValues,
    })
  }, DEBOUNCE_DELAY_LARGE)

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const createCustomFieldHandler = (values, setFieldValue) => () => {
    const fields = values.customFields || []
    setFieldValue(
      'customFields',
      [
        ...fields,
        {},
      ],
    )
  }

  const setCustomField = (setField, selector, value) => () => {
    setField(selector, value)
  }

  const renderOption = (touched, errors, isSubmitting, values, setFieldValue) => (element) => {
    const {
      selector,
      type,
      options,
      format,
      mask,
      immutable,
      charLimit,
    } = element
    return (
      <InputAndLabelContainer>
        {type === TEXT && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.projectForm.${selector}`} />)}
            />
            <TextField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (immutable && !isSubmitting),
                format,
                onlyRead: isSubmitting,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || isSubmitting || immutable)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
        {type === SELECT && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.projectForm.${selector}`} />)}
            />
            <SelectField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              withSearch
              placeholder="Выбрать"
              options={options || []}
              light
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting,
                format,
                onlyRead: isSubmitting,
              }}
            />
          </>
        )}
        {type === ARIA && !isSubmitting && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.projectForm.${selector}`} />)}
            />
            <TextAreaField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              rows="5"
              charLimit={charLimit}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (immutable && !isSubmitting),
                format,
                onlyRead: isSubmitting,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || isSubmitting || immutable)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
        {type === CUSTOMS_FIELDS && (
          <>
            {(values.customFields || []).map((customField, index) => {
              const localSelector = `${selector}[${index}]`
              const localSelectorValue = `${localSelector}.value`
              const localSelectorName = `${localSelector}.nameField`
              const nameTouch = get(touched, localSelectorName, null)
              const nameError = get(errors, localSelectorName, null)
              const valueTouch = get(touched, localSelectorValue, null)
              const valueError = get(errors, localSelectorValue, null)
              const value = get(values, localSelectorName, null) || 'Новое поле'
              const previewValue = `"${value.length <= CHAR_LIMITS.medium ? value : `${value.substr(0, CHAR_LIMITS.medium)}...`}" удалено`
              return (
                <CustomFieldContainer remove={customField.remove}>
                  {!customField.remove
                    ? (
                      <>
                        <CustomFieldBlock>
                          <LabelWithIcon
                            isError={nameTouch && nameError}
                            title="Название:"
                          />
                          <IconContainer onClick={setCustomField(setFieldValue, `${localSelector}.remove`, true)}>
                            <UsersIcons.TrashIcon />
                          </IconContainer>
                          <TextField
                            error={nameTouch && nameError}
                            errorMessage={nameError}
                            name={localSelectorName}
                            mask={mask}
                            fieldProps={{
                              autoComplete: 'off',
                              disabled: isSubmitting || (immutable && !isSubmitting),
                              format,
                              onlyRead: isSubmitting,
                            }}
                          />
                        </CustomFieldBlock>
                        <CustomFieldBlock>
                          <LabelWithIcon
                            isError={valueTouch && valueError}
                            title="Содержимое:"
                          />
                          <TextField
                            error={valueTouch && valueError}
                            errorMessage={valueError}
                            name={localSelectorValue}
                            mask={mask}
                            fieldProps={{
                              autoComplete: 'off',
                              disabled: isSubmitting || (immutable && !isSubmitting),
                              format,
                              onlyRead: isSubmitting,
                            }}
                          />
                        </CustomFieldBlock>
                      </>
                    )
                    : (
                      <>
                        {previewValue}
                        <CancelButton
                          onClick={setCustomField(setFieldValue, `${localSelector}.remove`, false)}
                        >
                          Отменить
                        </CancelButton>
                      </>
                    )}
                </CustomFieldContainer>
              )
            })}
          </>
        )}
        {type === ADD_CUSTOMS_FIELDS && (
          <AddFieldButton styleType="primary" onClick={createCustomFieldHandler(values, setFieldValue)}>
            <MapIcons.InvertPlusIcon />
            Добавить произвольное поле
          </AddFieldButton>
        )}
      </InputAndLabelContainer>
    )
  }

  const preverntDefaultBehaviour = (event) => event.preventDefault()

  return (
    <Formik
      key={JSON.stringify(calcLocalValue)}
      enableReinitialize
      validateOnMount
      onSubmit={noop}
      isInitialValid={edit}
      initialValues={calcLocalValue}
      validationSchema={createProjectValidator}
      render={({
        handleSubmit, touched, errors, isSubmitting, values, setFieldValue, handleChange, setSubmitting, isValid, ...ownProps
      }) => (
        <StyledForm onSubmit={preverntDefaultBehaviour} id="ProjectForm" name="ProjectForm">
          <Container>
            <Header>
              <HeaderTitle>
                {title}
              </HeaderTitle>
              <StyledButton type="green" onClick={isValid ? debounceSubmitHandler(values, setSubmitting) : submitError}>
                <Lang id="installation.projectForm.save" />
              </StyledButton>
              <StyledButton type="red" onClick={onCancel}>
                <Lang id="installation.projectForm.cancel" />
              </StyledButton>
            </Header>
            {(edit && (!calcLocalValue[CREATE_PROJECT_NAMES.NAME] || !calcLocalValue[CREATE_PROJECT_NAMES.PARENT] || parentElements.length === 0))
              || (parentElements.length === 0 || !calcLocalValue[CREATE_PROJECT_NAMES.PARENT]) || isSubmitting
              ? (
                <>
                  <Loader />
                </>
              )
              : (
                <FieldsContainer>
                  {formattedConfig.map(renderOption(touched, errors, isSubmitting, values, setFieldValue, handleChange))}
                </FieldsContainer>
              )}
          </Container>
        </StyledForm>
      )}
    />
  )
}

ProjectForm.propTypes = {
  selectedNode: {},
  setUrlFormValues: noop,
  submit: noop,
  submitError: noop,
  onCancel: noop,
  title: '',
  formValues: pt.objectOf(pt.string),
  getParentZoneOptions: pt.func,
  parentElements: pt.arrayOf(pt.object),
  edit: pt.bool,
}
ProjectForm.defaultProps = {
  selectedNode: pt.objectOf(pt.object),
  setUrlFormValues: pt.func,
  submit: pt.func,
  submitError: pt.func,
  onCancel: pt.func,
  title: pt.string,
  formValues: {},
  getParentZoneOptions: noop,
  parentElements: [],
  edit: false,
}

export default ProjectForm
