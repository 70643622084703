import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HeatIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" d="M17.4436 5.7787C17.1358 5.7787 16.8861 5.9906 16.8861 6.2514V8.0999H16.263V7.6786C16.263 6.6311 15.2578 5.7787 14.0228 5.7787C13.3518 5.7787 12.7509 6.0315 12.3396 6.4288C11.9284 6.0315 11.3274 5.7787 10.6568 5.7787C9.9858 5.7787 9.3845 6.0315 8.9736 6.4288C8.5625 6.0315 7.9613 5.7787 7.2905 5.7787C6.6198 5.7787 6.0185 6.0315 5.6075 6.4288C5.1965 6.0315 4.59517 5.7787 3.92436 5.7787C2.68918 5.7787 1.684 6.6312 1.684 7.6786V8.0999H0.55737C0.24984 8.0999 0 8.3116 0 8.5725C0 8.8334 0.24984 9.0453 0.55737 9.0453H1.68315V16.1001C1.68315 17.1476 2.6884 18 3.9235 18C4.59432 18 5.1956 17.7473 5.6066 17.3501C6.0177 17.7473 6.6188 18 7.2896 18C7.9605 18 8.5616 17.7473 8.9726 17.3501C9.3836 17.7473 9.985 18 10.6556 18C11.3263 18 11.9279 17.7473 12.3388 17.3501C12.7496 17.7473 13.3512 18 14.0219 18C15.2572 18 16.2624 17.1476 16.2624 16.1001V9.0456H17.4426C17.7503 9.0456 18 8.8337 18 8.5729V6.2517C18.001 5.9906 17.7509 5.7787 17.4436 5.7787ZM15.1482 16.1004C15.1482 16.6269 14.6433 17.0551 14.0228 17.0551C13.4022 17.0551 12.8971 16.627 12.8971 16.1004V7.679C12.8971 7.1526 13.4022 6.7243 14.0228 6.7243C14.6433 6.7243 15.1482 7.1525 15.1482 7.679V16.1004ZM11.7819 16.1001C11.7819 16.6266 11.2767 17.0547 10.6562 17.0547C10.0356 17.0547 9.5304 16.6267 9.5304 16.1001V7.679C9.5304 7.1526 10.0355 6.7243 10.6562 6.7243C11.2767 6.7243 11.7819 7.1525 11.7819 7.679V16.1001ZM8.4158 16.1001C8.4158 16.6266 7.9108 17.0547 7.2903 17.0547C6.6698 17.0547 6.1647 16.6267 6.1647 16.1001V7.679C6.1647 7.1526 6.6698 6.7243 7.2903 6.7243C7.9108 6.7243 8.4158 7.1525 8.4158 7.679V16.1001ZM5.0495 16.1001C5.0495 16.6266 4.54432 17.0547 3.92381 17.0547C3.30323 17.0547 2.79827 16.6267 2.79827 16.1001V7.679C2.79827 7.1526 3.30323 6.7243 3.92381 6.7243C4.54432 6.7243 5.0495 7.1525 5.0495 7.679V16.1001Z" />
      <path fill={fill} className="svgFill" d="M5.2658 0.158562C4.76344 0.666802 4.55668 1.20498 4.80566 1.8511C5.0514 2.48852 5.806 3.07312 5.2038 3.72986C4.7681 4.20467 5.5511 4.87908 5.9919 4.39826C6.4879 3.85738 6.7024 3.30568 6.452 2.63444C6.2261 2.0288 5.4531 1.43458 6.0539 0.826972C6.5126 0.363032 5.7283 -0.309328 5.2658 0.158562Z" />
      <path fill={fill} className="svgFill" d="M9.3367 4.3982C9.8332 3.85731 10.0472 3.30561 9.7969 2.63437C9.5711 2.02874 8.7979 1.43451 9.3987 0.826902C9.8574 0.362962 9.0729 -0.309398 8.6107 0.158492C8.1083 0.666742 7.9016 1.20492 8.1506 1.85104C8.3963 2.48845 9.1509 3.07305 8.5487 3.72979C8.1126 4.20467 8.8956 4.87901 9.3367 4.3982Z" />
      <path fill={fill} className="svgFill" d="M11.9546 0.158562C11.4524 0.666802 11.2456 1.20498 11.4945 1.8511C11.7405 2.48852 12.495 3.07312 11.893 3.72986C11.4571 4.20467 12.2399 4.87908 12.6808 4.39826C13.1769 3.85738 13.3914 3.30568 13.141 2.63444C12.9149 2.0288 12.1419 1.43458 12.7429 0.826972C13.2016 0.363032 12.4174 -0.309328 11.9546 0.158562Z" />
    </svg>
  )
}

HeatIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HeatIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HeatIcon)
