import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ShuoIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM11.025 12.9375H9.62227L11.7 8.2125H8.10352L9.675 2.7H8.325L6.3 9.7875H9.76992L8.27227 12.9375H6.975L9 15.3L11.025 12.9375Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ShuoIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ShuoIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ShuoIcon)
