import { UPDATE_GROUP } from '../index'

export const requestUpdateTenantGroup = (payload) => ({
  type: UPDATE_GROUP.REQUEST,
  payload,
})

export const responseUpdateTenantGroup = (payload) => ({
  type: UPDATE_GROUP.SUCCESS,
  payload,
})

export const errorUpdateTenantGroup = (payload) => ({
  type: UPDATE_GROUP.ERROR,
  payload,
})
