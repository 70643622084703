import { combineReducers } from 'redux'

import authorization from './authorization'
import restoration from './restoration'
import setPassword from './setPassword'
import refreshToken from './refreshToken'
import forgotPassword from './forgotPassword'
import resetPassword from './resetPassword'

export default combineReducers({
  authorization,
  restoration,
  setPassword,
  refreshToken,
  forgotPassword,
  resetPassword,
})
