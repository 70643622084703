import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PrintIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.54393 1.09524L4.26778 3.14286H13.7322L13.4561 1.09524C13.4561 0.47619 12.9289 0 12.2762 0H5.69874C5.04603 0 4.54393 0.47619 4.54393 1.09524ZM1.15481 4.2619H16.8452C17.4979 4.2619 18 4.7381 18 5.35714V11.881C18 12.5 17.4979 12.9762 16.8452 12.9762H14.7113V16H3.2887V12.9762H1.15481C0.502092 12.9762 0 12.5 0 11.881V5.35714C0 4.7381 0.502092 4.2619 1.15481 4.2619ZM13.5565 14.9048V7.83333H4.44351V14.9048H13.5565ZM12.2008 9.88095H5.67364C5.52301 9.88095 5.39749 9.7381 5.39749 9.59524V9.2619C5.39749 9.11905 5.52301 9 5.67364 9H12.2008C12.3515 9 12.5021 9.11905 12.5021 9.2619V9.59524C12.5021 9.7381 12.3515 9.88095 12.2008 9.88095ZM12.2008 11.8571H5.67364C5.52301 11.8571 5.39749 11.7143 5.39749 11.5714V11.2381C5.39749 11.0952 5.52301 10.9762 5.67364 10.9762H12.2008C12.3515 10.9762 12.5021 11.0952 12.5021 11.2381V11.5714C12.5021 11.7143 12.3515 11.8571 12.2008 11.8571ZM12.2008 13.8333H5.67364C5.52301 13.8333 5.39749 13.6905 5.39749 13.5476V13.2143C5.39749 13.0714 5.52301 12.9524 5.67364 12.9524H12.2008C12.3515 12.9524 12.5021 13.0714 12.5021 13.2143V13.5476C12.5021 13.6905 12.3515 13.8333 12.2008 13.8333Z" fill={fill} className="svgFill" />
    </svg>

  )
}

PrintIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PrintIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PrintIcon)
