import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PinIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 23C12.1663 23 8 15.9191 8 11.7629C8 7.60662 10.6863 5 14 5C17.3137 5 20 7.75021 20 11.7629C20 15.7755 15.8337 23 14 23ZM14.4496 20.5897C15.0007 19.9607 15.6223 19.0408 16.2036 17.9852C17.4574 15.7083 18.2857 13.2552 18.2857 11.7629C18.2857 8.82364 16.438 6.8 14 6.8C11.5087 6.8 9.71429 8.73553 9.71429 11.7629C9.71429 13.3195 10.5374 15.7705 11.7935 18.0337C12.3714 19.0749 12.9909 19.9809 13.5416 20.5998C13.7086 20.7875 13.862 20.9404 13.9942 21.0545C14.127 20.9379 14.2814 20.7816 14.4496 20.5897Z" fill={fill} className="svgFill" />
      <path d="M16 11C16 12.1046 15.1046 13 14 13C12.8954 13 12 12.1046 12 11C12 9.89543 12.8954 9 14 9C15.1046 9 16 9.89543 16 11Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PinIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PinIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PinIcon)
