import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const RoadIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M17.9122 15.1113L13.5438 1.77792C13.3885 1.30375 13.05 1 12.6776 1H9.6279L9.7044 1.965C9.7201 2.16167 9.6041 2.33333 9.4557 2.33333H8.5445C8.396 2.33333 8.2801 2.16167 8.2957 1.965L8.3723 1H5.3226C4.94979 1 4.61136 1.30375 4.45605 1.77792L0.0876411 15.1113C-0.201419 15.9942 0.259832 17 0.954512 17H7.1057L7.4279 12.93C7.4548 12.5908 7.6691 12.3333 7.9251 12.3333H10.0751C10.331 12.3333 10.5454 12.5908 10.5723 12.93L10.8944 17H17.0456C17.7403 17 18.2016 15.9942 17.9122 15.1113ZM8.1376 3.965C8.1441 3.88308 8.1731 3.80726 8.219 3.75217C8.2649 3.69708 8.3245 3.66662 8.3863 3.66667H9.6141C9.7419 3.66667 9.8494 3.79542 9.8629 3.965L10.0066 5.7808C10.0301 6.0763 9.8563 6.3333 9.6338 6.3333H8.367C8.1441 6.3333 7.9707 6.0763 7.9941 5.7808L8.1376 3.965ZM9.8638 11H8.136C7.8391 11 7.6076 10.6571 7.6388 10.2633L7.7973 8.2633C7.8241 7.9242 8.0385 7.6667 8.2945 7.6667H9.7054C9.9613 7.6667 10.1757 7.9242 10.2026 8.2633L10.361 10.2633C10.3923 10.6571 10.1607 11 9.8638 11Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H18V18H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

RoadIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

RoadIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(RoadIcon)
