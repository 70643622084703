import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { GRAPH_PERCENT, GRAPH_YEAR } from './config'
import {
  Container,
  Title,
  CheckBoxContainer,
  RadioButtonStyled,
  GraphContainer,
  CheckBoxStyled,
  GraphResult,
  GraphLine,
} from './styles'

const Graph = ({
  graphPercent,
  graphYear,
  graphType,
  setGraphType,
  selectedGraphPercentOptions,
  setSelectedGraphPercentOptions,
  selectedGraphYearOptions,
  setSelectedGraphYearOptions,
}) => {
  const onChangeHandler = (item) => () => {
    const collection = graphType === GRAPH_PERCENT
      ? selectedGraphPercentOptions
      : selectedGraphYearOptions
    const setCollection = graphType === GRAPH_PERCENT
      ? setSelectedGraphPercentOptions
      : setSelectedGraphYearOptions

    if (collection.findIndex((element) => item.id === element) === -1) {
      const newCollection = [item.id].concat(collection)
      setCollection(newCollection)
      return null
    }
    const newCollection = collection.filter((element) => element !== item.id)
    setCollection(newCollection)
  }
  const handleSetCheckBox = useCallback(
    (value) => () => {
      setGraphType(value)
    },
    [setGraphType],
  )
  return (
    <Container>
      <Title main>
        Выбор графика
      </Title>
      <CheckBoxContainer>
        <RadioButtonStyled
          active={graphType === GRAPH_PERCENT}
          onChange={handleSetCheckBox(GRAPH_PERCENT)}
          label="Относительно наработки часов (%)"
        />
      </CheckBoxContainer>
      <CheckBoxContainer marginBottom>
        <RadioButtonStyled
          active={graphType === GRAPH_YEAR}
          onChange={handleSetCheckBox(GRAPH_YEAR)}
          label="Относительно срока эксплуатации по регламенту (годы)"
        />
      </CheckBoxContainer>
      {(graphType === GRAPH_PERCENT ? graphPercent : graphYear)
        .map((item) => {
          const isActive = (graphType === GRAPH_PERCENT
            ? selectedGraphPercentOptions
            : selectedGraphYearOptions).findIndex((element) => element === item.id) !== -1
          return (
            <GraphContainer key={item.id}>
              <CheckBoxStyled
                active={isActive}
                onChange={onChangeHandler(item)}
              />
              <GraphResult
                graphType={graphType}
                color={item.mainColor}
                onClick={onChangeHandler(item)}
              >
                <Title graphTitle>
                  {item.text}
                </Title>
                <GraphLine
                  active={isActive}
                  uniqueKey={`${item.id}-${item.indicatorWidth}`}
                  widthValue={item.indicatorWidth}
                  color={item.secondaryColor}
                />
              </GraphResult>
            </GraphContainer>
          )
        })}

    </Container>
  )
}

Graph.propTypes = {
  graphPercent: pt.arrayOf(pt.object),
  graphYear: pt.arrayOf(pt.object),
  graphType: pt.string,
  setGraphType: pt.func,
  setSelectedGraphPercentOptions: pt.func,
  setSelectedGraphYearOptions: pt.func,
  selectedGraphPercentOptions: pt.arrayOf(pt.object),
  selectedGraphYearOptions: pt.arrayOf(pt.object),
}

Graph.defaultProps = {
  graphPercent: [],
  graphYear: [],
  selectedGraphPercentOptions: [],
  selectedGraphYearOptions: [],
  graphType: '',
  setGraphType: noop,
  setSelectedGraphPercentOptions: noop,
  setSelectedGraphYearOptions: noop,
}

export default Graph
