import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const GraphContainer = styled.div`
  width: 100%;
  min-width: 910px;
  height: 485px;
  max-height: 485px;
  min-height: 485px;
  height: calc(50vh - 40px);
  margin-bottom: 10px;
  ${({ color }) => color && `
    background: ${color};
  `}
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
          background: ${theme.colors.backgrounds.redisign.default};
        `
    }
  }}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 15px;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  margin-left: 10px;
  ${({ graph }) => graph && `
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    justify-content: flex-start;
    margin-left: 32px;
  `}
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
