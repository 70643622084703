export const ANALYTICS_ALARMS = 'ANALYTICS_ALARMS'
export const TYUMEN_OUR_HOME = 'TYUMEN_OUR_HOME'
export const INSTALLATIONS = 'INSTALLATIONS'
export const HEATING = 'HEATING'
export const ACTIONS_LOG = 'ACTIONS_LOG'
export const BEUTYFUL_LIGHTS = 'BEUTYFUL_LIGHTS'
export const TRASH = 'TRASH'
export const WATER = 'WATER'
export const CHARG_STATIONS = 'CHARG_STATIONS'
export const ROADS_MONITOR = 'ROADS_MONITOR'
export const TRAFFICLIGHTS_CONTROL = 'TRAFFICLIGHTS_CONTROL'
export const INFOTABLE_POSITION = 'INFOTABLE_POSITION'
export const EMPLOYMENT_DISABLED_PEOPLE = 'EMPLOYMENT_DISABLED_PEOPLE'
export const USERS = 'USERS'
export const SEARCH = 'SEARCH'
export const LOG = 'LOG'
export const SUPPORT = 'SUPPORT'
