import { ADD_SERVICE_DESK_INTEGRATION } from './index'

export const eventsAddIntegration = () => ({
  type: ADD_SERVICE_DESK_INTEGRATION.EVENTS,
})

export const requestAddIntegration = (payload) => ({
  type: ADD_SERVICE_DESK_INTEGRATION.REQUEST,
  payload,
})

export const successAddIntegration = (payload) => ({
  type: ADD_SERVICE_DESK_INTEGRATION.SUCCESS,
  payload,
})

export const beginAddIntegration = () => ({
  type: ADD_SERVICE_DESK_INTEGRATION.BEGIN,
})

export const endGetAddIntegration = () => ({
  type: ADD_SERVICE_DESK_INTEGRATION.END,
})

export const errorAddIntegration = (payload) => ({
  type: ADD_SERVICE_DESK_INTEGRATION.ERROR,
  payload,
})
