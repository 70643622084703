import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import RadioGroup from '@/components/controls/RadioGroup'
import CollapsedBlock from '@/components/blocks/CollapsedBlock'
import CoreIcons from '@/components/icons/core'
import WIDGETSHOP_ITEMS from '@/constants/widgetsShop'
import WIDGETSHOP_APP_ITEMS from '@/constants/widgetShopApp'
import IntegrationListContainer from '@/containers/blocks/IntegrationListContainer'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'

import {
  LIGHT_THEME_NAME,
  DARK_THEME_NAME,
  SKY_SCHEME_NAME,
  SPRING_SCHEME_NAME,
  SUMMER_SCHEME_NAME,
  WIDGET_SHOP_APP,
  WIDGET_SHOP_WIDGET,
} from '@/constants/names'

import WidgetsShop from './components/WidgetsShop'
import {
  SettingsWrapper,
  BlockTitle,
  Title,
  ControlsBlock,
  StyledSidebar,
} from './styles'

const SettingsSidebar = ({
  isOpen,
  theme,
  scheme,
  largeFont,
  setTheme,
  setScheme,
  setFontSize,
  onClose,
  widgetsActions,
  setWidgetShopValue,
  widgetShopValue,
  user,
  addedWidgets,
  addedApplications,
}) => {
  const ref = React.createRef()
  useOutsideClickHandler({
    ref,
    callback: onClose,
    opened: isOpen,
    exception: true,
  })
  return (
    <StyledSidebar ref={ref} opened={isOpen} onClose={onClose} over position="right">
      {isOpen && (
        <SettingsWrapper>
          <BlockTitle><Lang id="settingsSidebar.title" /></BlockTitle>
          <CollapsedBlock
            icon={CoreIcons.ThemeIcon}
            title={<Lang id="settingsSidebar.appearanceTitle" />}
          >
            <Title><Lang id="settingsSidebar.themeTitle" /></Title>
            <ControlsBlock>
              <RadioGroup
                options={[
                  {
                    value: DARK_THEME_NAME,
                    label: (<Lang id="settingsSidebar.darkThemeName" />),
                  },
                  {
                    value: LIGHT_THEME_NAME,
                    label: (<Lang id="settingsSidebar.lightThemeName" />),
                  },
                ]}
                value={theme}
                onChange={setTheme}
              />
            </ControlsBlock>

            <Title><Lang id="settingsSidebar.colorTitle" /></Title>
            <ControlsBlock>
              <RadioGroup
                options={[
                  {
                    value: SKY_SCHEME_NAME,
                    label: (<Lang id="settingsSidebar.clearSkyTitle" />),
                  },
                  {
                    value: SPRING_SCHEME_NAME,
                    label: (<Lang id="settingsSidebar.springMeadowTitle" />),
                  },
                  {
                    value: SUMMER_SCHEME_NAME,
                    label: (<Lang id="settingsSidebar.summerEvening" />),
                  },
                ]}
                value={scheme}
                onChange={setScheme}
              />
            </ControlsBlock>

            <Title><Lang id="settingsSidebar.fontSizeTitle" /></Title>
            <ControlsBlock>
              <RadioGroup
                options={[
                  {
                    value: false,
                    label: (<Lang id="settingsSidebar.fontSizeDefault" />),
                  },
                  {
                    value: true,
                    label: (<Lang id="settingsSidebar.fontSizeLarge" />),
                  },
                ]}
                value={largeFont}
                onChange={setFontSize}
              />
            </ControlsBlock>
          </CollapsedBlock>
          <CollapsedBlock
            icon={CoreIcons.NotificationsIcon}
            title={<Lang id="settingsSidebar.notificationsTitle" />}
          />
          <CollapsedBlock
            icon={CoreIcons.SecurityIcon}
            title={<Lang id="settingsSidebar.securityTitle" />}
          />
          <CollapsedBlock
            icon={CoreIcons.MarketIcon}
            title={<Lang id="settingsSidebar.widgetShop" />}
          >
            <ControlsBlock control>
              <RadioGroup
                options={[
                  {
                    value: WIDGET_SHOP_APP,
                    label: (<Lang id="settingsSidebar.widgetShopApp" />),
                  },
                  {
                    value: WIDGET_SHOP_WIDGET,
                    label: (<Lang id="settingsSidebar.widgetShopWidget" />),
                  },
                ]}
                value={widgetShopValue}
                onChange={setWidgetShopValue}
              />
            </ControlsBlock>
            {widgetShopValue === WIDGET_SHOP_APP && (
              <WidgetsShop
                {...widgetsActions}
                user={user}
                type={WIDGET_SHOP_APP}
                widgets={WIDGETSHOP_APP_ITEMS}
                addedWidgets={addedApplications}
              />
            )}
            {widgetShopValue === WIDGET_SHOP_WIDGET && (
              <WidgetsShop
                {...widgetsActions}
                user={user}
                type={WIDGET_SHOP_WIDGET}
                widgets={WIDGETSHOP_ITEMS}
                addedWidgets={addedWidgets}
              />
            )}
          </CollapsedBlock>
          <CollapsedBlock
            icon={CoreIcons.SyncIcon}
            title={<Lang id="settingsSidebar.syncTitle" />}
          >
            <IntegrationListContainer />
          </CollapsedBlock>
        </SettingsWrapper>
      )}
    </StyledSidebar>
  )
}

SettingsSidebar.propTypes = {
  addedWidgets: pt.arrayOf(pt.object).isRequired,
  addedApplications: pt.arrayOf(pt.object).isRequired,
  isOpen: pt.bool.isRequired,
  largeFont: pt.bool.isRequired,
  theme: pt.string.isRequired,
  scheme: pt.string.isRequired,
  setTheme: pt.func.isRequired,
  setScheme: pt.func.isRequired,
  setFontSize: pt.func.isRequired,
  onClose: pt.func.isRequired,
  setWidgetShopValue: pt.func.isRequired,
  widgetShopValue: pt.string.isRequired,
  widgetsActions: pt.shape({
    onAddWidget: pt.func,
  }).isRequired,
}

export default memoWithName(SettingsSidebar)
