import React, { useState, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { NUMBER_WITHOUT_FIRST_ZERO } from '@/constants/validationFields'
import UsersIcons from '@/components/icons/users'
import {
  TextFieldStyled,
  Label,
  FieldStyled,
  Error,
  ControlsContainer,
  EnumerationContainer,
} from './styles'

const HoursControl = ({
  dark,
  className,
  icon,
  label,
  type,
  format,
  onChange,
  onBlur,
  name,
  disabled,
  fieldIcon,
  placeholder,
  value,
  error,
  pattern,
  mask,
  customError,
  controls: Controls,
  charLimit,
  errorMessage,
  resetButton,
  resetField,
  ...ownProps
}) => {
  const [focused, setFocused] = useState(false)
  const [filled, setFilled] = useState(!!value)
  const Icon = useMemo(() => icon, [icon])
  const isError = useMemo(() => !!error, [error])

  const handleChangeWithCharsLimit = (fieldValue) => {
    if (!charLimit || fieldValue.length <= charLimit) {
      onChange(fieldValue)
    }
  }

  const handleChange = (event) => {
    const { value: newValue } = event.target
    const valueByPattern = pattern
      ? ((newValue || '').match(new RegExp(pattern, 'g')) || []).join('')
      : newValue
    const valueForSave = (valueByPattern.match(NUMBER_WITHOUT_FIRST_ZERO) || []).join('')
    if (Number(valueForSave) <= 23) {
      setFilled(!!(valueForSave))
      handleChangeWithCharsLimit(valueForSave)
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur(event)
  }

  const changeEnumeration = (enumerationType) => (e) => {
    e.stopPropagation()
    if (enumerationType === 'increase') {
      if (value === '23' || !value) {
        handleChangeWithCharsLimit('1')
      } else {
        handleChangeWithCharsLimit(`${Number(value) + 1}`)
      }
    }
    if (enumerationType === 'decrease') {
      if (value === '1' || !value) {
        handleChangeWithCharsLimit('23')
      } else {
        handleChangeWithCharsLimit(`${Number(value) - 1}`)
      }
    }
  }

  return (
    <TextFieldStyled
      dark={dark}
      className={className}
      fieldIcon={fieldIcon || !!icon}
      focused={focused}
      filled={filled}
      disabled={disabled}
      isError={isError || errorMessage}
      resetButton={resetButton}
    >
      <Label htmlFor={name}>
        {icon && <Icon />}
      </Label>
      {Controls
      && (
        <ControlsContainer>
          {Controls}
        </ControlsContainer>
      )}
      <FieldStyled
        name={name}
        {...ownProps}
        mask={mask}
        type={type}
        value={value}
        isError={isError || errorMessage}
        disabled={disabled}
        placeholder={placeholder || label}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      />
      <Label hours>
        ч.
        <EnumerationContainer>
          <UsersIcons.UpIcon onClick={changeEnumeration('increase')} />
          <UsersIcons.DownIcon onClick={changeEnumeration('decrease')} />
        </EnumerationContainer>
      </Label>
      {customError
        ? null
        : (!!error || !!errorMessage) && (
          <Error>
            {error || errorMessage}
          </Error>
        )}
    </TextFieldStyled>
  )
}

HoursControl.propTypes = {
  className: pt.string,
  error: pt.string,
  pattern: pt.string,
  errorMessage: pt.string,
  mask: pt.string,
  icon: pt.oneOfType([pt.element, pt.object, pt.string]),
  label: pt.oneOfType([pt.string, pt.element]),
  placeholder: pt.oneOfType([pt.string, pt.element]),
  value: pt.oneOfType([pt.string, pt.number]),
  type: pt.string,
  format: pt.string,
  name: pt.string.isRequired,
  disabled: pt.bool,
  fieldIcon: pt.bool,
  customError: pt.bool,
  dark: pt.bool,
  onChange: pt.func,
  onBlur: pt.func,
  controls: pt.element,
  charLimit: pt.number,
  resetButton: pt.bool,
  resetField: pt.func,
}
HoursControl.defaultProps = {
  className: '',
  pattern: '',
  errorMessage: '',
  mask: null,
  icon: null,
  label: null,
  placeholder: null,
  value: undefined,
  type: 'text',
  format: 'text',
  disabled: false,
  fieldIcon: false,
  customError: false,
  dark: false,
  error: null,
  onBlur: noop,
  onChange: noop,
  controls: null,
  charLimit: 256,
  resetButton: false,
  resetField: noop,
}

export default React.memo(HoursControl)
