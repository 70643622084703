import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HandicappedIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="28" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2301 14.7383H17.0468L15.9837 11.1523C15.8126 10.6601 15.5132 10.2617 15.0855 9.95701C14.6578 9.65234 14.1996 9.5 13.7108 9.5H10.888C9.7882 9.5 9.055 8.99609 8.6884 7.98828H12.5377C13.0509 7.98828 13.3075 7.74216 13.3075 7.25C13.3075 6.75784 13.0509 6.51172 12.5377 6.51172H8.1385L7.5886 5H7.7719C8.4318 5 8.9878 4.7832 9.4399 4.34961C9.8921 3.91601 10.1181 3.38281 10.1181 2.75C10.1181 2.11719 9.8921 1.58399 9.4399 1.15039C8.9878 0.716796 8.4318 0.5 7.7719 0.5C7.0876 0.5 6.5255 0.716796 6.0855 1.15039C5.6456 1.58399 5.4257 2.11719 5.4257 2.75C5.4257 2.82031 5.4379 2.91992 5.4623 3.04883C5.4868 3.17774 5.499 3.27734 5.499 3.34766V3.73437L7.112 8.44529C7.3809 9.19533 7.8574 9.81055 8.5418 10.2911C9.2261 10.7715 9.9837 11.0117 10.8147 11.0117H13.7108C14.053 11.0117 14.3096 11.1875 14.4807 11.539L15.8737 16.25H17.2301C17.4501 16.25 17.6334 16.1797 17.78 16.039C17.9267 15.8984 18 15.7227 18 15.5117C18 15.2773 17.9267 15.0899 17.78 14.9493C17.6334 14.8086 17.4501 14.7383 17.2301 14.7383ZM7.7719 2.01172C8.2851 2.01172 8.5418 2.25781 8.5418 2.75C8.5418 3.24219 8.2851 3.48828 7.7719 3.48828C7.2342 3.48828 6.9654 3.24219 6.9654 2.75C6.9654 2.53906 7.0448 2.36328 7.2037 2.22266C7.3625 2.08203 7.5519 2.01172 7.7719 2.01172ZM7.0387 18.5C8.7984 18.5 10.3564 17.9317 11.7128 16.7949C13.0692 15.6582 13.833 14.2461 14.0041 12.5586L12.4644 12.418C12.2933 13.7305 11.6884 14.8203 10.6497 15.6875C9.611 16.5547 8.4073 16.9883 7.0387 16.9883C5.5234 16.9883 4.23422 16.4785 3.17108 15.4589C2.10794 14.4395 1.57637 13.2032 1.57637 11.75C1.57637 10.6719 1.88187 9.69336 2.49287 8.81448C3.10387 7.93552 3.94705 7.28515 5.0224 6.86328L4.39919 5.52731C3.05499 6.01956 1.98574 6.83401 1.19145 7.97066C0.39715 9.10741 0 10.3672 0 11.75C0 13.625 0.68432 15.2188 2.05295 16.5313C3.42159 17.8438 5.0835 18.5 7.0387 18.5Z" fill={fill} className="svgFill" />
    </svg>
  )
}

HandicappedIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HandicappedIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HandicappedIcon)
