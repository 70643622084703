import createAction from '@/helpers/redux/createAction'

export const SET_SETTINGS = createAction('@/SET_SETTINGS')
export const GET_SETTINGS = createAction('@/GET_SETTINGS')
export const GET_WIDGET_SETTINGS = createAction('@/GET_WIDGET_SETTINGS')
export const SET_WIDGET_SETTINGS = createAction('@/SET_WIDGET_SETTINGS')

export const USER_REDIRECT = createAction('@/USER_REDIRECT')

export const USER_PING = createAction('@/USER_PING')
export const USER_LOGIN = createAction('@/USER_LOGIN')
export const USER_REFRESH_TOKEN = createAction('@/USER_REFRESH_TOKEN')
export const USER_SET_PASSWORD = createAction('@/USER_SET_PASSWORD')
export const USER_RESET_PASSWORD = createAction('@/USER_RESET_PASSWORD')
export const USER_RESTORATION = createAction('@/USER_RESTORATION')
export const USER_LOGOUT = createAction('@/USER_LOGOUT')
export const USER_FORGOT_PASSWORD = createAction('@/USER_FORGOT_PASSWORD')
