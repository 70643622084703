import styled from 'styled-components'

export default styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: flex-start;
  white-space: pre;
  ${({ text }) => text && `
    min-width: 250px
    text-align: left;
    white-space: normal;
  `}
  ${({ date }) => date && `
    justify-content: center;
  `}
`
