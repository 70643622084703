import React, { useMemo, useState } from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import Icons from '@/components/icons/maps'
import { REQUEST_STATUSES } from '@/constants/requests'
import Loader from '@/components/blocks/Loader'

import {
  Container,
  PrimaryTitle,
  SecondaryTitle,
  ElementContainer,
  IconContainer,
  PartContainer,
  TitleContainer,
  ContentContainer,
  Block,
  Indicator,
  LoaderContainer,
} from './styles'

const PresentationWidgetBig = ({
  data,
  view,
  preview,
  main,
  requestState,
}) => {
  const { title, icon: Icon } = view
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = (value) => () => {
    setIsExpanded(value)
  }
  const showLoader = requestState === REQUEST_STATUSES.PENDING

  const Content = useMemo(() => (
    <>
      {!isExpanded
        ? (
          <PartContainer isExpanded={isExpanded}>
            {preview.map((element) => (
              <ElementContainer pt={10}>
                <SecondaryTitle flex>
                  {element.name}:
                </SecondaryTitle>
                <SecondaryTitle value >
                  {get(data, element.selector, null) || (element.disabled ? '' : 0)}
                </SecondaryTitle>
              </ElementContainer>
            ))}
          </PartContainer>
        )
        : (
          <>
            {main.map((mainBlock) => (
              <PartContainer isExpanded={isExpanded}>
                {mainBlock.map((block) => (
                  <Block>
                    {block.map((element) => (
                      <>
                        {element.type === 'progressBar' && (
                          <ElementContainer progressBar >
                            <TitleContainer>
                              <SecondaryTitle flex progressBar>
                                {element.name}
                              </SecondaryTitle>
                              <SecondaryTitle value progressBar>
                                {get(data, element.valueSelector, 0)}
                              </SecondaryTitle>
                            </TitleContainer>
                            <Indicator
                              value={(get(data, element.valueSelector, 0) / get(data, element.totalSelector, 0)) * 100}
                              messageType={element.message}
                            />
                          </ElementContainer>
                        )}
                        {!element.type && (
                          <ElementContainer disabled={element.disabled}>
                            <SecondaryTitle flex>
                              {element.name}:
                            </SecondaryTitle>
                            <SecondaryTitle value>
                              {get(data, element.selector, null) || (element.disabled ? '' : 0)}
                            </SecondaryTitle>
                          </ElementContainer>
                        )}
                      </>
                    ))}
                  </Block>
                ))}
              </PartContainer>
            ))}
          </>
        )}
    </>
  ), [isExpanded, preview, main, data])


  return (
    <Container isExpanded={isExpanded}>
      <TitleContainer>
        <PrimaryTitle>
          {Icon && (
            <IconContainer primaryInfo>
              <Icon />
            </IconContainer>
          )}
          {title}
        </PrimaryTitle>
        <IconContainer
          toggle
          onClick={toggleIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
        >
          <Icons.ArrowIcon/>
        </IconContainer>
      </TitleContainer>
      {showLoader
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <ContentContainer>
            {Content}
          </ContentContainer>
        )}
    </Container>
  )
}

PresentationWidgetBig.defaultProps = {
  view: {
    title: 'Настройки виджета',
    icon: React.Fragment,
  },
  main: [],
  preview: [],
  data: {},
  requestState: REQUEST_STATUSES.NOT_REQUESTED,
}

PresentationWidgetBig.propTypes = {
  view: pt.shape({
    name: pt.string,
    selector: pt.string,
  }),
  main: pt.arrayOf(pt.arrayOf(pt.arrayOf(pt.shape({
    name: pt.string,
    valueSelector: pt.string,
    totalSelector: pt.string,
    type: pt.string,
    message: pt.string,
  })))),
  preview: pt.arrayOf(pt.shape({
    name: pt.string,
    selector: pt.string,
  })),
  data: pt.objectOf(pt.oneOfType(pt.string, pt.number)),
  requestState: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
  ]),
}

export default React.memo(PresentationWidgetBig)
