import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  CORRECT as CORRECT_ID,
  FAULTY as FAULTY_ID,
  WARNING as WARNING_ID,
  UNDEFINED as UNDEFINED_ID,
} from '@/constants/widgetsDashboard'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'

export default [
  {
    id: CORRECT_ID,
    name: <Lang id="widgetsDashboard.correct" />,
    value: 0,
    selector: OK,
  },
  {
    id: FAULTY_ID,
    name: <Lang id="widgetsDashboard.faulty" />,
    value: 0,
    selector: ERROR,
  },
  {
    id: WARNING_ID,
    name: <Lang id="widgetsDashboard.warning" />,
    value: 0,
    selector: WARNING,
  },
  {
    id: UNDEFINED_ID,
    name: <Lang id="widgetsDashboard.undefined" />,
    value: 0,
    selector: INFO,
  },
]
