import LiveTime from '@/components/icons/livetime'
import numberWithSpaces from '@/helpers/numberWithSpaces'

export const liveTimeFields = [
  {
    id: 'description',
    name: 'Объект',
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'address',
    name: 'Адрес',
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'workTime',
    name: 'Часы работы',
    value: 'ASC',
    customCell: (props) => (numberWithSpaces(props.value)),
    type: 'number',
  },
  {
    id: 'currentLife',
    name: '% выработки ресурса',
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'startDateExploitation',
    name: 'Дата начала эксплуатации',
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'dateEndExploitation',
    name: 'Дата окончания эксплуатации',
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'currentDateExploitation',
    name: 'Текущее время эксплуатации',
    value: 'ASC',
    type: 'number',
  },
]

export const liveTimeExportOptions = [
  {
    name: 'EXCEL',
    value: 'excel',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]
