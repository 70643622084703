import generateWidget from './generateWidget'

export const ACTIVE_CONTROLLER = generateWidget({
  config: {
    id: 'ACTIVE_CONTROLLER',
    title: 'Доступность контроллера',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'активен',
      false: 'не активен',
    },
  },
  selectorTemplate: 'active',
})

export const GSM_LEVEL = generateWidget({
  config: {
    id: 'GSM_LEVEL',
    title: 'Уровень GSM сети',
    type: 'smallWidget',
  },
  selectorTemplate: 'gsmLevel',
})

export const GSM_TYPE = generateWidget({
  config: {
    id: 'GSM_TYPE',
    title: 'Тип GSM сети',
    type: 'smallWidget',
  },
  selectorTemplate: 'gsmType',
})

export const HAS_CONNECTION = generateWidget({
  config: {
    id: 'HAS_CONNECTION',
    title: 'Состояние подключения',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'на связи',
      false: 'нет связи',
    },
  },
  selectorTemplate: 'hasConnection',
})

export const ALARM_STATE = generateWidget({
  config: {
    id: 'ALARM_STATE',
    title: 'Состояние аварии',
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: 'отсутствует',
      1: 'активно не сквитировано',
      2: 'не активно не сквитировано',
      3: 'активно сквитировано',
    },
  },
  selectorTemplate: 'alarmState',
})

export const ACTIVE_LIGHT = generateWidget({
  config: {
    id: 'ACTIVE_LIGHT',
    title: 'Состояние светильника',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'активен',
      false: 'не активен',
    },
  },
  selectorTemplate: 'active',
})

export const FIRE_STATE = generateWidget({
  config: {
    id: 'FIRE_STATE',
    title: 'Состояние датчика пожара',
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: 'норма',
      1: 'обрыв датчика',
      2: 'сработал тепловой извещатель',
      3: 'сработал дымовой извещатель',
      4: 'короткое замыкание',
    },
  },
  selectorTemplate: 'state',
})

export const CONTACTOR_ON = generateWidget({
  config: {
    id: 'CONTACTOR_ON',
    title: 'Контактор выкл./вкл.',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'включен',
      false: 'выключен',
    },
  },
  selectorTemplate: 'isOn',
})

export const ACTIVE_COMMAND = generateWidget({
  config: {
    id: 'CONTACTOR_ON',
    title: 'Отправлена команда на включение контактора',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'команда активна',
      false: 'команда неактивна',
    },
  },
  selectorTemplate: 'isActiveCommandOn',
})

export const MODE = generateWidget({
  config: {
    id: 'MODE',
    title: 'Режим управления',
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: 'местный',
      1: 'дистанционный',
    },
  },
  selectorTemplate: 'mode',
})

export const CONTROL_TYPE = generateWidget({
  config: {
    id: 'CONTROL_TYPE',
    title: 'Тип управления',
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: 'ручной',
      1: 'автономный',
      2: 'каскадный',
    },
  },
  selectorTemplate: 'controlType',
})

export const SWITCH_TIME = generateWidget({
  config: {
    id: 'SWITCH_TIME',
    title: 'Дата и время планового включения контактора',
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'switchTime',
})

export const WILL_SWITCH = generateWidget({
  config: {
    id: 'WILL_SWITCH',
    title: 'Будет произведено плановое включение контактора',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'да',
      false: 'нет',
    },
  },
  selectorTemplate: 'willSwitchOn',
})

export const CIRCUIT_STATE = generateWidget({
  config: {
    id: 'CIRCUIT_STATE',
    title: 'Состояние вставки/автомата включения',
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: 'не определено',
      1: 'включено',
      2: 'отключено',
      3: 'авария',
    },
  },
  selectorTemplate: 'state',
})

export const CHECK_POINT_STATE = generateWidget({
  config: {
    id: 'CHECK_POINT_STATE',
    title: 'Состояние контрольной точки',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'да',
      false: 'нет',
    },
  },
  selectorTemplate: 'state',
})

export const CURRENT_VALUE = generateWidget({
  config: {
    id: 'CURRENT_VALUE',
    title: 'Значение датчика тока',
    type: 'smallWidget',
  },
  selectorTemplate: 'value',
})

export const DMX_ID = generateWidget({
  config: {
    id: 'DMX_ID',
    title: 'Идентификатор DMX-модуля',
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'brizApiId',
})
export const ACTIVE_SCRIPT = generateWidget({
  config: {
    id: 'ACTIVE_SCRIPT',
    title: 'Активен ли сценарий',
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: 'да',
      false: 'нет',
    },
  },
  selectorTemplate: 'isActivated',
})
export const SCRIPT_ACTIVATION_TIME = generateWidget({
  config: {
    id: 'SCRIPT_ACTIVATION_TIME',
    title: 'Время активации сценария',
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'activationTime',
})
export const CURRENT_SCRIPT_NUMBER = generateWidget({
  config: {
    id: 'CURRENT_SCRIPT_NUMBER',
    title: 'Номер текущего сценария',
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'scenarioNumber',
})
export const DEFERRED_SCRIPT_NUMBER = generateWidget({
  config: {
    id: 'DEFERRED_SCRIPT_NUMBER',
    title: 'Номер отложенного сценария',
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'deferredScenarioNumber',
})
export const DMX_ERROR_CODE = generateWidget({
  config: {
    id: 'DEFERRED_SCRIPT_NUMBER',
    title: 'Код неисправности модуля DMX',
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'errorCode',
})
