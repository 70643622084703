import styled from 'styled-components'
import { Form } from 'formik'
import {
  Label as FieldLabel,
  TextFieldStyled,
} from '@/components/controls/TextControl/styles'
import {
  Label as AreaLabel,
  TextFieldStyled as AreaTextFieldStyled,
} from '@/components/controls/TextAreaControl/styles'

export const FormWrapper = styled(Form)`
  overflow: auto;
  padding: 0 15px;
  height: 100%;
`
export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;

  ${FieldLabel}, ${AreaLabel} {
    color: ${({ theme }) => theme.colors.colors.default};
  }

  ${TextFieldStyled}, ${AreaTextFieldStyled} {
    margin-bottom: 10px;
  }
`

export const InputAndLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 100%;
    margin-top: 5px;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  padding: 5px 0;
`
export const StyledLabelWithIcon = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.option};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
`

export const ButtonContainer = styled.div`
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  label {
    margin: 0 auto;
    padding-right: 20px;
    font-size: 1rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
`

export const MessageContainer = styled.div`
  padding: 1.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.colors.subTitle};
`

export const InputFile = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

export const LabelFile = styled.label`
  cursor: pointer;
  text-decoration: none;
  outline: none;
  display: inline-block;
  background: none;
  color: ${({ theme }) => theme.colors.colors.default};
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.borders.disabled}`};
  padding: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 100ms ease-in-out

  &:hover {
    color: ${({ theme }) => theme.colors.colors.light};
    background: ${({ theme }) => theme.colors.scheme.backgrounds.hover};
    border-color: transparent;
  }
`
