import styled from 'styled-components'

export const Container = styled.div`
  float: right;
  display: flex;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-left: -5px;
  position: relative;
  top: 0px;
  left: 0px;
`

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -7px 0 0 -7px;
  position: absolute;
  left: 50%;
  top: 50%;
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.colors.default};
    transform: rotate(180deg);
    transition: transform 200ms linear;
  }

  ${({ opened }) => opened && `
    svg {
      transform: rotate(0deg);
    }
  `}
`
