import {
  put, takeLatest, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import updateNodeCount from '@/helpers/updateNodeCount'
import request from '@/helpers/axios'
import {
  INTEGRATION,
} from '@/constants/objectTypes'
import { CREATE_INTEGRATION } from '@/store/actions/installation'
import { INSTALLATIONS_INTEGRATION_ENDPOINT, generateGetDeleteProjectCard } from '@/constants/apiRoutes'
import {
  successCreateIntegration,
} from '@/store/actions/installation/createIntegration'

function* createIntegrationSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    mark, name, integrationType, projectId,
  } = values
  try {
    const { data: parentProjectData } = yield request({
      url: generateGetDeleteProjectCard(projectId),
      method: 'get',
    })
    const body = {
      parentTreeElementId: parentProjectData.treeElementId,
      integrationName: name,
      note: mark,
      integrationType,
      type: INTEGRATION,
      projectId: parentProjectData.projectId,
      asunoDtos: [],
    }
    const { data } = yield request({
      url: INSTALLATIONS_INTEGRATION_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      rootId, FILTER, path,
    } = formValues

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentTreeElementId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.treeElementId)
    tree.handlers.setSelected(node, true)
    updateNodeCount(tree, path, 'add')
    tree.handlers.rerender()
    setUrlFormValues({
      id: data.treeElementId,
      type: data.type,
      parentGeoZoneId: parentProjectData.parentTreeElementId,
      rootId,
      parentTreeId: data.parentTreeElementId,
      path: `${path}/${data.treeElementId}`,
      FILTER,
    })
    yield put(successCreateIntegration())
  } catch (error) {
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*createIntegrationSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(CREATE_INTEGRATION.REQUEST, createIntegrationSaga)
}
