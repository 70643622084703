import {
  APPEAL_CHANNEL,
  OBJECT_TYPE,
  PROJECT,
  TOWN,
  SVT_STATUS,
  APPEAL_CATEGORY,
  WORK,
  OBJECT,
  TYPE_OF_WORK,
  VIEW_OF_WORK,
  MUNICIPALITY,
  DISTRICT,
  REGION,
  REACTION_TIME_HOURS,
  MODEL,
  MAC_ADDRESS,
  ENTITY,
  INDIVIDUAL,
  TELEPHONE,
  CONTACT_NUMBER,
  EMAIL,
  DOCUMENT,
  INDIVIDUAL_NUMBER,
  COORDINATES,
  LOCATION,
  WORKING_HOURS,
} from '@/constants/serviceDeskConfig/nameConfig'

export default (serviceDeskConfig) => [
  {
    id: 'applicant',
    title: 'Заявитель',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[ENTITY].id}`,
    paragraph: 'Юридическое лицо',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[INDIVIDUAL].id}`,
    paragraph: 'Физическое лицо',
    text: '',
  },
  {
    id: 'contactDetails',
    title: 'Контактные данные',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[TELEPHONE].id}`,
    paragraph: 'Телефон',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[CONTACT_NUMBER].id}`,
    paragraph: 'Контактный телефон',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[EMAIL].id}`,
    paragraph: 'Email',
    text: '',
  },
  {
    id: 'source',
    title: 'Источник',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[APPEAL_CHANNEL].id}`,
    paragraph: 'Канал поступления обращения',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[DOCUMENT].id}`,
    paragraph: 'Документ',
    text: '',
  },
  {
    id: 'object',
    title: 'Объект',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[OBJECT_TYPE].id}`,
    paragraph: 'Тип объекта',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[INDIVIDUAL_NUMBER].id}`,
    paragraph: 'Индивидуальный номер',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[COORDINATES].id}`,
    paragraph: 'Координаты',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[MODEL].id}`,
    paragraph: 'Модель',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[MAC_ADDRESS].id}`,
    paragraph: 'MAC-адрес',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[LOCATION].id}`,
    paragraph: 'Местонахождение',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[PROJECT].id}`,
    paragraph: 'Проект',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[MUNICIPALITY].id}`,
    paragraph: 'Муниципальное образование',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[TOWN].id}`,
    paragraph: 'Город',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[DISTRICT].id}`,
    paragraph: 'Район',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[REGION].id}`,
    paragraph: 'Регион',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[OBJECT].id}`,
    paragraph: 'Ссылка на объект',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[SVT_STATUS].id}`,
    paragraph: 'Статус состояния CBT',
    text: '',
  },
  {
    id: 'priority',
    paragraph: 'Очередность',
    text: `${Math.floor(Math.random() * Math.floor(5)) + 1}`,
  },
  {
    id: 'appeal',
    title: 'Обращение',
  },
  {
    id: 'DETAIL_TEXT',
    paragraph: 'Суть обращения',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[APPEAL_CATEGORY].id}`,
    paragraph: 'Категория обращения',
    text: '',
  },
  {
    id: 'jobsTitle',
    title: 'Работы',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[VIEW_OF_WORK].id}`,
    paragraph: 'Вид работ',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[TYPE_OF_WORK].id}`,
    paragraph: 'Тип работ',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[WORK].id}`,
    paragraph: 'Работы',
    text: '',
  },
  {
    id: 'SLA',
    title: 'SLA',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[REACTION_TIME_HOURS].id}`,
    paragraph: 'Время взятия в работу',
    text: '',
  },
  {
    id: `PROPERTY_${serviceDeskConfig[WORKING_HOURS].id}`,
    paragraph: 'Время предоставления',
    text: '',
  },
]
