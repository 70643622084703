import {
  TEXT,
  ARIA,
  SELECT,
} from '@/constants/semanticNames'
import setSixDigitsAfterDot from '@/helpers/setSixDigitsAfterDot'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'

export const geoZoneConfig = [
  {
    title: 'Название геозоны*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.NAME,
  },
  {
    title: 'Родительская геозона*',
    type: SELECT,
    selector: CREATE_REGION_NAMES.PARENT,
  },
  {
    title: 'Географическая широта*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.LATITUDE,
    formatter: setSixDigitsAfterDot,
    pattern: '[0-9.]',
  },
  {
    title: 'Географическая долгота*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.LONGITUDE,
    formatter: setSixDigitsAfterDot,
    pattern: '[0-9.]',
  },
  {
    title: 'Регион',
    type: TEXT,
    selector: CREATE_REGION_NAMES.REGION,
  },
  {
    title: 'Город',
    type: TEXT,
    selector: CREATE_REGION_NAMES.CITY,
  },
  {
    title: 'Заметка',
    type: ARIA,
    selector: CREATE_REGION_NAMES.MARK,
    charLimit: 300,
  },
]

export default geoZoneConfig
