import styled from 'styled-components'
import Button from '@/components/blocks/Button'
import TextField from '@/components/fields/TextField'

export const CustomButton = styled(Button)`
  ${({ disconnect, theme }) => disconnect && `
    background: ${theme.colors.backgrounds.redisign.error};
    &:hover {
      background: ${theme.colors.backgrounds.redisign.error};
    }
  `};
`

export const StyledTextField = styled(TextField)`
  margin-top: 5px;
  margin-bottom: 5px;
`

export const InputAndLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`

export const FormWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  button {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
  }
`

export const MessageTitle = styled.div`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  white-space: pre-wrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
`
