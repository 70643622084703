export default [
  {
    passportParameter: 'Температура атмосферы',
    showInDashboard: true,
    selector: 'atmosphereTemperature',
  },
  {
    passportParameter: 'Атмосферное давление',
    showInDashboard: true,
    selector: 'atmospherePressure',
  },
  {
    passportParameter: 'Условный проход трубы в подающем трубопроводе',
    showInDashboard: true,
    selector: 'conventionalStraitPipeline',
  },
  {
    passportParameter: 'Условный проход трубы в обратном трубопроводе',
    showInDashboard: true,
    selector: 'conventionalReversPipeline',
  },
  {
    passportParameter: 'Температура в подающем трубопроводе',
    showInDashboard: true,
    selector: 'temperatureInPipe',
  },
  {
    passportParameter: 'Температура в обратном трубопроводе',
    showInDashboard: true,
    selector: 'temperatureInReversePipe',
  },
  {
    passportParameter: 'Масса в подающем трубопроводе',
    showInDashboard: true,
    selector: 'totalMassInPipe',
  },
  {
    passportParameter: 'Масса в обратном трубопроводе',
    showInDashboard: true,
    selector: 'totalMassInReversePipe',
  },
  {
    passportParameter: 'Массовый расход в подающем трубопроводе',
    showInDashboard: true,
    selector: 'massFlowInPipe',
  },
  {
    passportParameter: 'Массовый расход в обратном трубопроводе',
    showInDashboard: true,
    selector: '​​​​​​massFlowInReversePipe',
  },
  {
    passportParameter: 'Объем в подающем трубопроводе',
    showInDashboard: true,
    selector: 'volumetricFlowInPipe',
  },
  {
    passportParameter: 'Объем в обратном трубопроводе',
    showInDashboard: true,
    selector: 'volumetricFlowInReversePipe',
  },
  {
    passportParameter: 'Объемный расход в подающем трубопроводе',
    showInDashboard: true,
    selector: 'straitPipelineVolumeConsumption',
  },
  {
    passportParameter: 'Объемный расход в обратном трубопроводе',
    showInDashboard: true,
    selector: 'reversePipelineVolumeConsumption',
  },
  {
    passportParameter: 'Объем потребленного ресурса',
    showInDashboard: true,
    selector: 'consumedVolume',
  },
  {
    passportParameter: 'Тепловая энергия',
    showInDashboard: true,
    selector: 'energy',
  },
  {
    passportParameter: 'Расходы на теплоснабжение',
    showInDashboard: true,
    selector: 'heatCosts',
  },
  {
    passportParameter: 'Экономическое значение перетопа',
    showInDashboard: true,
    selector: 'economicImportanceOfOverheat',
  },
  {
    passportParameter: 'Выявление недотопа',
    showInDashboard: true,
    selector: 'detectionOfUnderheat',
  },
  {
    passportParameter: 'Выявление перетопа',
    showInDashboard: true,
    selector: 'detectionOfOverheat',
  },
  {
    passportParameter: 'Время работы',
    showInDashboard: true,
    selector: 'workTime',
  },
]
