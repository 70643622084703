import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import {
  INTEGRATION_TYPES,
} from '@/constants/forms/analyticExpenses'

export const getConsumptionAnalysis = createSelector(
  getState,
  (state) => get(state, 'consumptionAnalysis', {}),
)

export const getData = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'data'),
)

export const getGraphType = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'graphType'),
)

export const getConsumptionForm = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'form'),
)

export const getForm = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'form'),
)

export const getReportType = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'type'),
)

export const getRange = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'range'),
)

export const getSavings = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'saving'),
)

export const getCalculatorValue = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'calculatorValue', null),
)

export const getStatusTable = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'statusTable'),
)

export const getStatusGraph = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'statusGraph'),
)

export const getTableParameters = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, 'table', {}),
)

export const getIntegrationType = createSelector(
  getConsumptionAnalysis,
  (settings) => get(settings, INTEGRATION_TYPES, null),
)
