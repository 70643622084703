import { connect } from 'react-redux'
import { getTreeData } from '@/store/selectors/maps/socketEvents'
import {
  getExportFileStatus,
  getSelector,
  getSelectedElement,
} from '@/store/selectors/analyticsAlarms'

import AlarmsManager from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  selectedElement: getSelectedElement(state),
})
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsManager)
