import {
    CITY, CONTROLLER,
    GROUP_OBJECT,
    HEATING_DEVICE,
} from '@/constants/objectTypes'

export const generateDataFromObject = (devicesByAliasIds = {}) => Object.keys(devicesByAliasIds)
  .map((aliasId) => ({
    aliasId,
    objectIds: devicesByAliasIds[aliasId],
  }))

export const getObjectData = (object = {}, initialValues = {}) => (object.children || [])
  .reduce((accumulator, device) => {
    if (device.type === HEATING_DEVICE) {
      return ({
        ...accumulator,
        [device.aliasId]: [...new Set([...(accumulator[device.aliasId] || []), device.id])],
      })
    }
    return accumulator
  }, initialValues)


export const getOneSimIntegrationsInformation = (node = {}) => {
    switch (node.type) {
        case HEATING_DEVICE: {
            return [{
                aliasId: node.aliasId,
                objectIds: [node.id],
            }]
        }
        case CITY: {
            const devicesByAliasIds = (node.children || [])
                .reduce((accumulator, object) => {
                    if (object.type === GROUP_OBJECT || CONTROLLER) {
                        return {
                            ...accumulator,
                            ...getObjectData(object, accumulator),
                        }
                    }
                    return accumulator
                }, {})
            return generateDataFromObject(devicesByAliasIds)
        }
        case CONTROLLER: {
            const devicesByAliasIds = getObjectData(node)
            return generateDataFromObject(devicesByAliasIds)
        }
        case GROUP_OBJECT: {
            const devicesByAliasIds = getObjectData(node)
            return generateDataFromObject(devicesByAliasIds)
        }
        default: {
            return []
        }
    }
}
