import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getAlarmManager = createSelector(
  getState,
  (state) => get(state, 'actionLog', {}),
)

export const getExportFileStatus = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'fileExportStatus', []),
)

export const getLoading = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'loading'),
)

export const getTableData = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'data', []),
)

export const getSelector = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'selector', []),
)

export const getEventTypes = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'eventTypes'),
)

export const getEventStatus = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'eventStatus'),
)

export const getSelectedElement = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'selectedElement'),
)

export const getEventData = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'eventData'),
)

export const getTableParameters = createSelector(
  getAlarmManager,
  (settings) => get(settings, 'table', {}),
)
