export default [
  {
    dateStep: '12.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 50,
  },
  {
    dateStep: '12.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 55,
  },
  {
    dateStep: '13.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 73,
  },
  {
    dateStep: '14.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 98,
  },
  {
    dateStep: '15.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 99,
  },
  {
    dateStep: '16.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 93,
  },
  {
    dateStep: '17.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 71.5,
  },
  {
    dateStep: '18.12.2020',
    geozone: 'qqqq',
    project: 'QWER',
    objectInstalled: 535,
    OKObject: 495,
    persents: 95,
  },
]