import { takeLatest, select, put } from 'redux-saga/effects'
import { getWidgetsSettings } from '@/store/selectors/maps/widgetSettings'
import { UPDATE_WIDGET_SETTINGS } from '@/store/actions/maps'
import { setWidgetSettings } from '@/store/actions/maps/widgetSettings'

function* updateWidgetSettings({ payload }) {
  try {
    const { id, data } = payload
    const settings = yield select(getWidgetsSettings)

    const updatedSettings = {
      ...settings,
      [id]: data,
    }

    yield put(setWidgetSettings(updatedSettings))
  } catch (error) {
    console.log('TCL: updateWidgetSettings -> error', error)
  }
}

export default function* root() {
  yield takeLatest(UPDATE_WIDGET_SETTINGS, updateWidgetSettings)
}
