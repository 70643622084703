import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WaterSupplyIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.5 0C49.3284 0 50 0.67157 50 1.5V48.5C50 49.3284 49.3284 50 48.5 50C47.6716 50 47 49.3284 47 48.5V1.5C47 0.67157 47.6716 0 48.5 0Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2192 3C19.4021 3 18.7397 3.67157 18.7397 4.5C18.7397 5.32843 19.4021 6 20.2192 6H22.1918V12.1313C20.9189 12.8268 19.8227 13.812 18.9894 15H13.3151C12.5849 15 11.9474 15.4022 11.6064 16H9.36987C4.19504 16 0 20.2533 0 25.5V33.5H8.87671V25.5C8.87671 25.2238 9.0975 25 9.36987 25H11.6064C11.9474 25.5978 12.5849 26 13.3151 26H18.9894C20.6876 28.4208 23.477 30 26.6301 30C30.1991 30 33.3021 27.9769 34.8842 25H39C40.1046 25 41 25.8954 41 27V48.5C41 49.3284 41.6716 50 42.5 50C43.3284 50 44 49.3284 44 48.5V26C44 23.7909 42.2091 22 40 22H35.8839C35.9603 21.5114 36 21.0104 36 20.5C36 19.9896 35.9603 19.4886 35.8839 19H40C42.2091 19 44 17.2091 44 15V1.5C44 0.67157 43.3284 0 42.5 0C41.6716 0 41 0.67157 41 1.5V14C41 15.1046 40.1046 16 39 16H34.8842C34.0139 14.3625 32.6835 13.0136 31.0685 12.1313V6H33.0411C33.8582 6 34.5205 5.32843 34.5205 4.5C34.5205 3.67157 33.8582 3 33.0411 3H20.2192ZM28.1096 6H25.1507V11.1177C25.6326 11.0402 26.1267 11 26.6301 11C27.1335 11 27.6277 11.0402 28.1096 11.1177V6ZM11.3425 19H9.36987C5.82919 19 2.95889 21.9102 2.95889 25.5V30.5H5.91782V25.5C5.91782 23.567 7.46335 22 9.36987 22H11.3425V19ZM17.2603 20.5C17.2603 19.264 17.4931 18.0831 17.9167 17H14.3014C13.7567 17 13.3151 17.4477 13.3151 18V23C13.3151 23.5523 13.7567 24 14.3014 24H17.9167C17.4931 22.9169 17.2603 21.736 17.2603 20.5ZM26.6301 27C30.1708 27 33.0411 24.0898 33.0411 20.5C33.0411 16.9102 30.1708 14 26.6301 14C23.0895 14 20.2192 16.9102 20.2192 20.5C20.2192 24.0898 23.0895 27 26.6301 27Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.31955 36.503C2.54031 37.4898 0 40.8575 0 42.7602C0 43.8846 0.467621 44.9631 1.29996 45.7582C2.13232 46.5533 3.26123 47 4.43835 47C5.61548 47 6.74438 46.5533 7.57675 45.7582C8.40909 44.9631 8.87671 43.8846 8.87671 42.7602C8.87671 40.8583 6.3364 37.4898 5.55716 36.503C5.42093 36.3456 5.25 36.219 5.05661 36.132C4.86319 36.0451 4.6521 36 4.43835 36C4.22461 36 4.01352 36.0451 3.8201 36.132C3.62671 36.219 3.45578 36.3456 3.31955 36.503ZM4.44043 44.3224C4.00696 44.3219 3.59137 44.1572 3.28485 43.8644C2.97827 43.5715 2.80582 43.1744 2.80542 42.7602C3.15128 41.6364 3.70486 40.5809 4.43997 39.6434C5.17178 40.583 5.72556 41.6379 6.07611 42.7602C6.07568 43.1748 5.90292 43.5722 5.59579 43.8652C5.28915 44.1577 4.87375 44.3221 4.44043 44.3224Z" fill={fill} className="svgFill" />
    </svg>
  )
}

WaterSupplyIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WaterSupplyIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WaterSupplyIcon)
