import React from 'react'

import { Table } from './styles'

const DATA = [
  {
    id: '1',
    number: '1',
    FIO: 'МЕТШИН Ильсур\nРаисович',
    city: 'Казань',
    subject: 'Республика Татарстан',
    changes: '↑1',
    positive: true,
  },
  {
    id: '2',
    number: '2',
    FIO: 'СОБЯНИН\nСергей Семенович',
    city: 'Москва',
    subject: 'город федерального значения\nМосква',
    changes: '↓1',
  },
  {
    id: '3',
    number: '3',
    FIO: 'ГАЛДУН Юрий\nВладимирович',
    city: 'Белгород',
    subject: 'Белгородская область',
    changes: '↑3',
    positive: true,
  },
  {
    id: '4',
    number: '4',
    FIO: 'РЯШИН Максим\nПавлович',
    city: 'Ханты-Мансийск',
    subject: 'Ханты-Мансийский\nавтономный округ',
    changes: '',
  },
  {
    id: '5',
    number: '5',
    FIO: 'КУХАРУК\nРуслан Николаевич',
    city: 'Тюмень',
    subject: 'Тюменская область',
    changes: '↓2',
    active: true,
  },
  {
    id: '6',
    number: '6',
    FIO: 'БЕГЛОВ Александр\nДмитриевич',
    city: 'Санкт-Петербург',
    subject: 'город федерального значения\nСанкт-Петербург',
    changes: '↓1',
  },
  {
    id: '7',
    number: '7',
    FIO: 'МАГДЕЕВ\nНаиль Гамбарович',
    city: 'Набережные Челны',
    subject: 'Республика Татарстан',
    changes: '',
  },
  {
    id: '8',
    number: '8',
    FIO: 'ХАДЖИМУРАДОВ\nИса Нажадиевич',
    city: 'Грозный',
    subject: 'Чеченская Республика',
    changes: '↑1',
    positive: true,
  },
  {
    id: '9',
    number: '9',
    FIO: 'ТИТОВСКИЙ\nАлексей Леонидович',
    city: 'Салехард',
    subject: 'Ямало-Ненецкий\nавтономный округ',
    changes: '↑3',
    positive: true,
  },
  {
    id: '10',
    number: '10',
    FIO: 'ДЕНИСОВ\nДмитрий Александрович',
    city: 'Калуга',
    subject: 'Калужская область',
    changes: '↑1',
    positive: true,
  },
]

const FIELDS = [
  {
    id: 'number',
    name: '№',
  },
  {
    id: 'FIO',
    name: 'ФИО мэра',
  },
  {
    id: 'city',
    name: 'Город',
  },
  {
    id: 'subject',
    name: 'Субъект',
  },
  {
    id: 'changes',
    name: 'Изменение',
    getStyles: (row) => (row.positive ? { color: 'green' } : { color: 'red' }),
  },
]

const ACTIVE_STYLES = {
  background: '#90C737',
  color: '#fff',
}

const Widget = () => {
  const getProps = (header, row) => {
    const defaultStyles = row.active ? ACTIVE_STYLES : {}
    const style = header.getStyles ? { ...defaultStyles, ...header.getStyles(row) } : defaultStyles

    return { style }
  }
  return (
    <Table id="DataTables_Table_0" className="dataTable no-footer" role="grid">
      <thead>
        <tr role="row">
          {FIELDS.map((header) => (
            <th className="sorting_disabled" rowSpan="1" colSpan="1" style={{ width: '0px' }}>{header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {DATA.map((row, index) => (
          <tr role="row" className={index % 2 === 0 ? 'odd' : 'even'}>
            {FIELDS.map((header) => (
              <td {...getProps(header, row)}>{row[header.id]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default Widget
