import React, { useMemo, useCallback } from 'react'
import { Formik } from 'formik'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage as Lang } from 'react-intl'
import SelectControl from '@/components/controls/SelectControl'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import ViewTree from '@/components/blocks/ViewTree'
import {
  Container,
  FormWrapper,
  TypeWrapper,
  IntegrationViewContainer,
} from './styles'

const AccessSettingsForm = ({
  zoneProcessOptions,
  saveFormProps,
  initialValues,
  systems,
  selectedAccess,
  isFormModifying,
  editMode,
  initialAccessSettings,
}) => {
  // const [search, setSearch] = useState(null)
  // const [isValid, setIsValid] = useState(true)

  const formattedZoneOption = useMemo(() => (zoneProcessOptions.map((element) => ({
    id: element.id,
    value: element.id,
    title: element.name,
  }))), [zoneProcessOptions])

  const handleInitialValues = useMemo(() => {
    const sortedInitialValues = uniqBy(initialValues, 'type').map((element) => element.type)
    if (!editMode) {
      return {}
    }
    if (initialValues && !isFormModifying) {
      const accessArray = sortedInitialValues.map((element) => ({
        id: element,
        name: systems[element].name,
        children: [],
        type: 'integrationSystem',
      }))
      initialValues.forEach((integration) => {
        const accessIndex = accessArray.findIndex((accessValue) => (
          integration.type === accessValue.id
        ))
        const systemChildrenIntegration = get(systems, `${[integration.type]}.children`, []).find((element) => (
          element.id === integration.id
        ))
        accessArray[accessIndex].children.push({
          id: integration.id,
          type: 'integrationZone',
          checkBoxValue: false,
          dateOfSynchronization: null,
          status: null,
          name: !isEmpty(systemChildrenIntegration) && systemChildrenIntegration.name,
        })
      })
      saveFormProps(accessArray)
    }
    const cityProcessId = zoneProcessOptions.filter((options) => (
      options.integrationTypes.some((integrationType) => (
        sortedInitialValues.map((initialValue) => (integrationType.includes(initialValue)))
          .includes(true)
      ))
    ))
    if (initialValues && isFormModifying) {
      saveFormProps(initialAccessSettings)
    }
    return { cityProcessId: cityProcessId.map((cityProcess) => cityProcess.id) }
  },
  [
    initialAccessSettings,
    initialValues,
    isFormModifying,
    saveFormProps,
    systems,
    zoneProcessOptions,
    editMode,
  ])

  const validateForm = (values) => {
    const { cityProcessId, modules, integrations } = values
    if (!!cityProcessId && modules.length !== 0 && integrations.length !== 0) {
      // setIsValid(true)
    } else {
      // setIsValid(false)
    }
  }

  const selectCityProcess = (formValues, setField) => (value) => {
    setField('modules', [])
    setField('integrations', [])
    setField('cityProcessId', value)
    const selectedIntegrations = value.map((id) => (
      zoneProcessOptions.find((option) => option.id === id)
    ))
    const integrationsWithChildren = selectedIntegrations.map((integration) => (
      integration.integrationTypes.map((type) => ({
        ...systems[type],
        toggled: true,
        children: systems[type] && systems[type].children && systems[type].children.map((element) => {
          const access = !isEmpty(selectedAccess) && selectedAccess.find(
            (accessElement) => accessElement.id === type,
          )
          const currentAccess = access
            ? access.children.find(
              (accessChildren) => accessChildren.id === element.id,
            )
            : {}
          const checkBoxValue = currentAccess ? currentAccess.checkBoxValue : false
          return {
            ...element,
            type: 'checkBox',
            checkBoxValue,
            dateOfSynchronization: null,
            status: null,
          }
        }),
      }))
        .filter((integrationType) => integrationType.children !== undefined)
    ))
    saveFormProps([].concat(...integrationsWithChildren).filter((integration) => (
      integration.children.length > 0
    )))
  }

  const selectIntegration = useCallback((node, event, allData) => () => {
    if (event === 'click') {
      const toggledIntegration = allData.children.map((integration) => {
        if (integration.id === node.id) {
          return {
            ...integration,
            checkBoxValue: !integration.checkBoxValue,
          }
        }
        return integration
      })
      const newToggledIntegrations = selectedAccess.map((integration) => {
        if (integration.id === allData.id) {
          return {
            ...integration,
            children: toggledIntegration,
          }
        }
        return integration
      })
      saveFormProps(newToggledIntegrations)
    }
  }, [saveFormProps, selectedAccess])

  const renderIntegrations = useCallback(() => (
    <IntegrationViewContainer>
      {selectedAccess.length > 0 && (
      <LabelWithIcon
        integration
        title={<Lang id="usersPage.access.integration" />}
      />
      )}
      {selectedAccess.map(((tenant) => {
        if (tenant.name) {
          return (
            <ViewTree
              key={JSON.stringify(tenant)}
              treeData={tenant}
              onSelect={selectIntegration}
            />
          )
        }
        return null
      }))}
    </IntegrationViewContainer>
  ), [selectedAccess, selectIntegration])

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={handleInitialValues}
        validate={validateForm}
        validateOnChange
        onSubmit={noop}
        render={(formProps) => {
          const { handleSubmit, values, setFieldValue } = formProps
          return (
            <FormWrapper onSubmit={handleSubmit} id="access" name="access">
              <TypeWrapper>
                <LabelWithIcon
                  title={(<Lang id="usersPage.access.typeTitle" />)}
                />
                <SelectControl
                  multiselect
                  name="cityProcessId"
                  placeholder={<Lang id="usersPage.access.notSelected" />}
                  value={values && values.cityProcessId}
                  options={formattedZoneOption}
                  onChange={selectCityProcess(values, setFieldValue)}
                  userDisabled={!isFormModifying}
                  selectAll={{
                    value: 'ALL',
                    title: <Lang id="usersPage.access.allUrbanProcesses" />,
                  }}
                  userForm
                />
              </TypeWrapper>
              {!!get(values, 'cityProcessId', false) && renderIntegrations(values)}
            </FormWrapper>
          )
        }}
      />
    </Container>
  )
}

AccessSettingsForm.defaultProps = {
  integrationOptions: [],
  saveFormProps: noop,
  initialValues: null,
  selectedType: null,
  disabled: false,
}

AccessSettingsForm.propTypes = {
  zoneProcessOptions: pt.objectOf(pt.object).isRequired,
  integrationOptions: pt.arrayOf(pt.object),
  saveFormProps: pt.func,
  selectedType: pt.string,
  initialValues: pt.shape({
    [pt.string]: pt.string,
    type: pt.oneOfType([pt.string, pt.number]),
  }),
  disabled: pt.bool,
}

export default AccessSettingsForm
