import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReportManagerIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0H2C1.46957 0 0.96086 0.21071 0.58579 0.58579C0.21071 0.96086 0 1.46957 0 2V16C0 16.5304 0.21071 17.0391 0.58579 17.4142C0.96086 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.96086 17.4142 0.58579C17.0391 0.21071 16.5304 0 16 0ZM16 16H2V2H16V16ZM6 14H4V9H6V14ZM10 14H8V4H10V14ZM14 14H12V7H14V14Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ReportManagerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReportManagerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReportManagerIcon)
