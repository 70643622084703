export const getIsTyumen = (node, hasTyumen = false) => {
  if ((node.name || '').toLowerCase().includes('тюмень')) {
    return true
  }
  if (node.children) {
    return node.children.some((element) => {
      if ((element.name || '').toLowerCase().includes('тюмень')) {
        return true
      }
      if (element.children) {
        return getIsTyumen(element)
      }
      return false
    }, hasTyumen)
  }
  return false
}

export default getIsTyumen
