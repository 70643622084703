import React from 'react'

export const UnitPressure = (
  <>
    кгc/см
    <sup>2</sup>
  </>
)

export const UnitvolumetricFlow = (
  <>
    м
    <sup>3</sup>
    /ч
  </>
)

export const UnitVolume = (
  <>
    м
    <sup>3</sup>
  </>
)
