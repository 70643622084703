import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import { MARKS_COLORS, MAPS_ICONS } from '@/constants/styles/mapsMarks'

import {
  PinContainer, OverLay,
} from './styles'

export const PinIcon = ({ status, type, theme }) => {
  const Icon = MAPS_ICONS[type]
  return (
    <PinContainer theme={theme}>
      <OverLay theme={theme} status={status}>
        <Icon color={MARKS_COLORS[status] || theme.colors.colors.default} />
      </OverLay>
    </PinContainer>
  )
}

PinIcon.propTypes = {
  theme: mapTypes.theme.isRequired,
  status: mapTypes.markStatus.isRequired,
  type: mapTypes.markType.isRequired,
}

export default PinIcon
