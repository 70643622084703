import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const BrizIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.5 12C13.5 11.3335 12.5 10.25 12.5 10.25C12.5 10.25 11.5 11.3335 11.5 12C11.5 12.2652 11.6054 12.5196 11.7929 12.7071C11.9804 12.8946 12.2348 13 12.5 13C12.7652 13 13.0196 12.8946 13.2071 12.7071C13.3946 12.5196 13.5 12.2652 13.5 12ZM5.5 7.5V12H5C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13V14H10V13C10 12.7348 9.89464 12.4804 9.70711 12.2929C9.51957 12.1054 9.26522 12 9 12H8.5V7.5H11.5V8.5C11.3674 8.5 11.2402 8.55268 11.1464 8.64645C11.0527 8.74022 11 8.86739 11 9C11 9.13261 11.0527 9.25978 11.1464 9.35355C11.2402 9.44732 11.3674 9.5 11.5 9.5H13.5C13.6326 9.5 13.7598 9.44732 13.8536 9.35355C13.9473 9.25978 14 9.13261 14 9C14 8.86739 13.9473 8.74022 13.8536 8.64645C13.7598 8.55268 13.6326 8.5 13.5 8.5V6.5C13.5 6.23478 13.3946 5.98043 13.2071 5.79289C13.0196 5.60536 12.7652 5.5 12.5 5.5H8.5V5C8.5 4.73478 8.39464 4.48043 8.20711 4.29289C8.01957 4.10536 7.76522 4 7.5 4H6.5C6.23478 4 5.98043 4.10536 5.79289 4.29289C5.60536 4.48043 5.5 4.73478 5.5 5V5.5C5.23478 5.5 4.98043 5.60536 4.79289 5.79289C4.60536 5.98043 4.5 6.23478 4.5 6.5C4.5 6.76522 4.60536 7.01957 4.79289 7.20711C4.98043 7.39464 5.23478 7.5 5.5 7.5Z" fill={fill} className="svgFill" />
    </svg>
  )
}

BrizIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

BrizIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(BrizIcon)
