import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_PASSPORT_AND_CARD_DATA,
  SET_OPERATED_ELEMENT,
  errorGetOperatedElement,
  successGetPassportAndCardData,
  requestGetObjectEquipment,
} from '@/store/actions/passportization'
import {
  requestGetPassportPhoto,
} from '@/store/actions/passportization/getPassportPhoto'
import {
  getOperatedElement,
} from '@/store/selectors/passportization'
import { LOCAL_TO_PASSPORT } from '@/constants/passportization/types'

import {
  GET_PASSPORT_DATA_URL,
} from '@/constants/apiRoutes'
import getQueryParams from '@/helpers/getQueryParams'

function* getPassportAndCardSaga({ payload }) {
  try {
    const operatedElement = yield select(getOperatedElement)
    const { type, name, isParent } = operatedElement
    if (type && name) {
      const response = yield request({
        url: `${
          GET_PASSPORT_DATA_URL
        }/?${
          getQueryParams({ identifier: name, installationType: LOCAL_TO_PASSPORT[type] })
        }`,
        method: 'get',
      })
      if (response && response.data) {
        const data = { ...response.data }
        const { card } = data
        delete data.card
        const passportData = Object.keys(data)
          .reduce((accumulator, element) => {
            if (typeof data[element] !== 'object' || data[element] === null) {
              return {
                ...accumulator,
                generalInfo: {
                  ...accumulator.generalInfo,
                  [element]: data[element],
                },
              }
            }
            return {
              ...accumulator,
              [element]: data[element],
            }
          }, { generalInfo: {} })
        passportData.inner = {
          photo: data.photo,
          file: data.file,
        }
        yield put(successGetPassportAndCardData({
          ...card,
          ...passportData,
        }))
        if (response.data.photo.uid) {
          yield put(requestGetPassportPhoto({ id: response.data.photo.uid }))
        }
      }
      if (isParent) {
        yield put(requestGetObjectEquipment({
          name,
          type: LOCAL_TO_PASSPORT[type],
        }))
      }
    }
  } catch (error) {
    yield put(errorGetOperatedElement(error))
    yield put(successGetPassportAndCardData({}))
  }
}

export default function* root() {
  yield takeLatest(GET_PASSPORT_AND_CARD_DATA.REQUEST, getPassportAndCardSaga)
  yield takeLatest(SET_OPERATED_ELEMENT, getPassportAndCardSaga)
}
