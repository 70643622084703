import { GET_ALARM_MANAGER_TABLE } from './index'

export const requestAlarmManagerTable = (payload) => ({
  type: GET_ALARM_MANAGER_TABLE.REQUEST,
  payload,
})

export const successAlarmManagerTable = (payload) => ({
  type: GET_ALARM_MANAGER_TABLE.SUCCESS,
  payload,
})

export const errorAlarmManagerTable = (payload) => ({
  type: GET_ALARM_MANAGER_TABLE.ERROR,
  payload,
})
