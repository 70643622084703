import { all } from 'redux-saga/effects'
import connectToSocketSaga from './connectToSocket'
import onSocketConnectedSaga from './onSocketConnected'

export default function* root() {
  yield all([
    connectToSocketSaga(),
    onSocketConnectedSaga(),
  ])
}
