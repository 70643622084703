import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ThermometerIcon = ({ onClick }) => {
  return (
    <svg onClick={onClick} width="30" height="135" viewBox="0 0 30 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.80109 107.768C8.23618 107.493 8.5 107.015 8.5 106.5V8C8.5 4.41008 11.41 1.5 15 1.5C18.59 1.5 21.5 4.41008 21.5 8V106.5C21.5 107.015 21.7638 107.493 22.1989 107.768C25.9887 110.162 28.5 114.384 28.5 119.191C28.5 126.647 22.4558 132.691 15 132.691C7.54425 132.691 1.5 126.647 1.5 119.191C1.5 114.384 4.01133 110.162 7.80109 107.768Z" stroke="#3D3D3D" strokeWidth="3" strokeLinejoin="round" />
    </svg>
  )
}

ThermometerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ThermometerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ThermometerIcon)
