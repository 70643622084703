export const NOT_REQUESTED = 'NOT_REQUESTED'
export const IDLE = 'IDLE'
export const PENDING = 'PENDING'
export const ERROR = 'ERROR'

export const REQUEST_STATUSES = {
  [NOT_REQUESTED]: NOT_REQUESTED,
  [IDLE]: IDLE,
  [PENDING]: PENDING,
  [ERROR]: ERROR,
}

export const REQUEST_WAIT = 60000

export default REQUEST_STATUSES
