import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import ReportManagerIcons from '@/components/icons/reportManager'

export const PARAMETERS = 'parameters'
export const TIMETABLE = 'timetable'
export const NEWSLETTER = 'newsletter'

export const TAB_CONFIG = [
  {
    id: PARAMETERS,
    name: <Lang id="reportManager.parameters" />,
    icon: ReportManagerIcons.ParametersIcon,
  },
  {
    id: TIMETABLE,
    name: <Lang id="reportManager.timetable" />,
    icon: ReportManagerIcons.TimetableIcon,
  },
  {
    id: NEWSLETTER,
    name: <Lang id="reportManager.newsletter" />,
    icon: ReportManagerIcons.NewsletterIcon,
  },
]
