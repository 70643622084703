import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TableIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M16.1053 0H1.89474C0.852632 0 0 0.9 0 2V16C0 17.1 0.852632 18 1.89474 18H16.1053C17.1474 18 18 17.1 18 16V2C18 0.9 17.1474 0 16.1053 0ZM16.1053 2V5H1.89474V2H16.1053ZM11.3684 16H6.63158V7H11.3684V16ZM1.89474 7H4.73684V16H1.89474V7ZM13.2632 16V7H16.1053V16H13.2632Z" />
    </svg>
  )
}

TableIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TableIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TableIcon)
