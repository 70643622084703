export default {
  desktop: 'Desktop',
  users: 'Users',
  passportization: 'Passportization',
  alertManager: 'Notifications',
  alarms: 'Fault log',
  individualControl: 'Control',
  groupControl: 'Group control',
  scheduleManager: 'Schedule manager',
  installation: 'Installations',
  reportManager: 'Report manager',
  energyConsumption: 'Energy consumption',
  life: 'Lifetime',
  objects: 'Monitoring center',
  searchWidget: 'Advanced search',
  controlCenter: 'Control cnter',
  equipmentReference: 'References',
  operationAndTechnicalSupport: 'Support',
  settings: 'System settings',
  changeLog: 'Change log',
  foldMenu: 'Collapse description',
  menuTitle: 'Navigation menu',
  inventory: 'Inventory',
  lightingObjects: 'Lighting objects',
  analyticExpenses: 'Resource consumption',
  activityLog: 'Activity log',
  carElectricStations: 'Car charging stations',
  waterSupply: 'Water supply',
  heatSupply: 'Heat supply',
  smartRecycleBin: 'Waste containers monitoring',
  controlObject: 'Control Objects',
}