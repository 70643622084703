import { PUT_PASSPORT_AND_CARD_DATA } from './index'

export const requestUpdatePassport = (payload) => ({
  type: PUT_PASSPORT_AND_CARD_DATA.REQUEST,
  payload,
})

export const responseUpdatePassport = (payload) => ({
  type: PUT_PASSPORT_AND_CARD_DATA.SUCCESS,
  payload,
})

export const errorUpdatePassport = (payload) => ({
  type: PUT_PASSPORT_AND_CARD_DATA.ERROR,
  payload,

})
