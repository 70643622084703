/**
 * Function for creating default table actions
 * @param {Object} actions - object of actions names
 * @returns {Object} Default redux actions for table
 */
const generateDefaultTableActions = (actions = {}) => ({
  setSort: (payload) => ({
    type: actions.SORT,
    payload,
  }),
  setQuery: (payload) => ({
    type: actions.QUERY,
    payload,
  }),
  setRequestQuery: (payload) => ({
    type: actions.REQUEST_QUERY,
    payload,
  }),
  setSelectedFields: (payload) => ({
    type: actions.SELECTED_FIELDS,
    payload,
  }),
  setTotalResults: (payload) => ({
    type: actions.TOTAL_RESULTS,
    payload,
  }),
  setPerPage: (payload) => ({
    type: actions.PER_PAGE,
    payload,
  }),
  setPage: (payload) => ({
    type: actions.PAGE,
    payload,
  }),
})

export default generateDefaultTableActions
