export default {
  title: 'System Settings',
  appearanceTitle: 'Appearance',
  themeTitle: 'Theme',
  darkThemeName: 'Night metropolis',
  lightThemeName: 'Top of Everest',
  colorTitle: 'Color Scheme',
  clearSkyTitle: 'Clear Sky',
  springMeadowTitle: 'Spring Meadow',
  summerEvening: 'Summer Evening',
  fontSizeTitle: 'Font Size',
  fontSizeDefault: 'Standard',
  fontSizeLarge: 'Large',
  notificationsTitle: 'Notifications',
  securityTitle: 'Security',
  syncTitle: 'Sync',
  widgetShop: 'Smart Solutions Store',
  widgetShopApp: 'Apps',
  widgetShopWidget: 'Widgets',
}