import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ParametersIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.95946 7.66419C1.15189 7.66419 0.5 8.2967 0.5 9.08024C0.5 9.86379 1.15189 10.4963 1.95946 10.4963C2.76703 10.4963 3.41892 9.86379 3.41892 9.08024C3.41892 8.2967 2.76703 7.66419 1.95946 7.66419ZM1.95946 2C1.15189 2 0.5 2.6325 0.5 3.41605C0.5 4.1996 1.15189 4.8321 1.95946 4.8321C2.76703 4.8321 3.41892 4.1996 3.41892 3.41605C3.41892 2.6325 2.76703 2 1.95946 2ZM1.95946 13.4889C1.23946 13.4889 0.665405 14.0553 0.665405 14.7444C0.665405 15.4336 1.24919 16 1.95946 16C2.66973 16 3.25351 15.4336 3.25351 14.7444C3.25351 14.0553 2.67946 13.4889 1.95946 13.4889ZM4.87838 15.6885H18.5V13.8004H4.87838V15.6885ZM4.87838 10.0243H18.5V8.13621H4.87838V10.0243ZM4.87838 2.47202V4.36008H18.5V2.47202H4.87838Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

ParametersIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ParametersIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ParametersIcon)
