import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const ControlsContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  svg {
    margin-right: 15px;
    cursor: pointer;
  }
`
export const QRCodeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border: 5px solid ${({ theme }) => theme.colors.white};

  canvas {
    background: ${({ theme }) => theme.colors.white};
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  background: ${({ theme }) => theme.colors.borders.default};
`

export const ImageTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  white-space: pre-wrap;
`

export const StatusCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  ${({ active }) => active && `
  `}
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`
