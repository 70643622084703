import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Left = styled.div`
  min-width: 280px;
  height: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`
export const Right = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.6rem;
  text-align: left;
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px;
`

export const Middle = styled.div`
  min-width: 280px;
  height: 100%;
  border-left: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
`

export const InfoTitle = styled.div`
  padding: 5px 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 2.2rem;
  text-align: center;
`
