import { eventChannel } from 'redux-saga'
import { select, put } from 'redux-saga/effects'
import { getClient, getSubscriptions } from '@/store/selectors/socket'
import { setSocketSubscriptions } from '@/store/actions/socket'

function* createSubscriptionChannel(destination) {
  const client = yield select(getClient)
  const localSubscriptions = yield select(getSubscriptions)
  const subscriptionsCopy = { ...localSubscriptions }
  const subscribe = (emitter) => {
    if (subscriptionsCopy[destination]) {
      subscriptionsCopy[destination].unsubscribe()
    }
    const subscription = client.subscribe(destination, (event) => {
      emitter(JSON.parse(event.body))
    })
    subscriptionsCopy[destination] = subscription
    return () => { subscription.unsubscribe() }
  }
  yield put(setSocketSubscriptions(subscriptionsCopy))
  return eventChannel(subscribe)
}

export default createSubscriptionChannel
