import styled from 'styled-components'

export const CheckBoxWrapper = styled.div`
  width: 38px;
  height: 100%;
  min-width: 38px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

export const AlarmToolTip = styled.span`
  position: absolute;
  min-width: 200px;
  visibility: hidden;
  background-color: ${({ theme }) => theme.colors.colors.tooltip};
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
  left: 0px;
  top: 48px;
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 8%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.colors.tooltip} transparent;
  }
`

export const AlarmButton = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: -5px;
  height: 48px;
  z-index: 5;
  &:hover {
    ${AlarmToolTip} {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const Title = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 2px;
  ${({ reverse }) => reverse && `
    align-items: flex-start;
    margin-top: 2px;
    svg {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  `}

  ${({ active, theme }) => active && `
    .svgStroke {
      fill: ${theme.colors.colors.svg};
    }
  `}
`

export const ButtonsContainer = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
