import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  TYUMEN_OUR_HOME_URL,
  TYUMEN_OUR_HOME_GRAPHS_URL,
} from '@/constants/routes'
import Treatment from './components/Treatment'
import GraphsPage from './components/GraphsPage'
import Container from './styles'

const TyumenOurHome = () => (
  <Container>
    <Switch>
      <Route
        exact
        path={TYUMEN_OUR_HOME_URL}
        component={Treatment}
      />
      <Route
        exact
        path={TYUMEN_OUR_HOME_GRAPHS_URL}
        component={GraphsPage}
      />
    </Switch>
  </Container>
)

TyumenOurHome.propTypes = {
}
TyumenOurHome.defaultProps = {
}

export default TyumenOurHome
