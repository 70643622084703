import {
  take, call, put,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import createNotifications from '@/helpers/notification'
import { selectSystem, setMode } from '@/store/actions/integrations'
import { requestGetIntegrations } from '@/store/actions/integrations/getIntegrations'

import {
  requestGetMapObjects,
} from '@/store/actions/maps/socketEvents'
import {
  INTEGRATION_DELETED,
  DELETE_INTEGRATION_ERROR,
  ERRORS_TO_ERROR_MESSAGES,
  KULON,
  ATM,
  BRIZ,
  MESH,
} from '@/constants/integrations'
import { deleteIntegrationsChannel } from './channels'
import messagesAliasCreator from '../notificationElements'

const MESSAGES_ALIASES = {
  [KULON]: messagesAliasCreator('АСУНО', 'КУЛОН'),
  [ATM]: messagesAliasCreator('АСУ', '1SIM'),
  [BRIZ]: messagesAliasCreator('АСУ', 'БРИЗ'),
  [MESH]: messagesAliasCreator('АСУ', 'МЭШ СЕТЬ'),
}

function* deleteIntegrationSaga() {
  const toast = createNotifications()
  const channel = yield call(deleteIntegrationsChannel)

  while (true) {
    const eventData = yield take(channel)
    console.log('function*deleteIntegrationsSaga -> eventData', eventData);
    switch (eventData.status) {
      case 'ON_FAILED': {
        const errorNotifications = ERRORS_TO_ERROR_MESSAGES[eventData.errors[0].subject] || {}
        const messageConfig = MESSAGES_ALIASES[
          eventData.integrationType
        ][DELETE_INTEGRATION_ERROR] || noop
        toast(messageConfig(null, errorNotifications[eventData.errors[0].message] || `${eventData.description}`))
        break
      }
      case 'COMPLETED': {
        if (eventData.description === INTEGRATION_DELETED) {
          yield put(requestGetMapObjects())
          yield put(requestGetIntegrations())
          yield put(selectSystem(null))
          yield put(setMode('CONNECTED_INTEGRATION'))
          const messageConfig = MESSAGES_ALIASES[
            eventData.integrationType
          ][eventData.description] || noop
          toast(messageConfig(null))
        }
        break
      }
      default: break
    }
  }
}

export default deleteIntegrationSaga
