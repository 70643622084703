import { GET_TENANT_PARENTS } from './index'

export const requestGetTenantParents = (payload) => ({
  type: GET_TENANT_PARENTS.REQUEST,
  payload,
})

export const successGetTenantParents = (payload) => ({
  type: GET_TENANT_PARENTS.SUCCESS,
  payload,
})

export const errorGetTenantParents = (payload) => ({
  type: GET_TENANT_PARENTS.ERROR,
  payload,
})
