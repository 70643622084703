import { connect } from 'react-redux'
import { setLanguage } from '@/store/actions/appSettings'
import { beginResetPassword } from '@/store/actions/user/resetPassword'
import { beginRestoreAccount } from '@/store/actions/user/restoration'
import { loginUser } from '@/store/actions/user/authorization'

import { getLanguageState } from '@/store/selectors/appSettings'
import {
  getUserResetPasswordError,
  getUserRestorationError,
  getUserAuthorizationError,
  getUserMockLoginError,
  getUserResetPasswordStatus,
} from '@/store/selectors/user'


import ForgotPassword from './component'

const mapStateToProps = (state) => ({
  language: getLanguageState(state),
  mockLoginError: getUserMockLoginError(state),
  loginError: getUserAuthorizationError(state),
  resetError: getUserRestorationError(state),
  resetPasswordError: getUserResetPasswordError(state),
  resetPasswordStatus: getUserResetPasswordStatus(state),
})
const mapDispatchToProps = (dispatch) => ({
  onSetLanguage: (language) => dispatch(setLanguage(language)),
  onLoginUser: (...data) => dispatch(loginUser(data)),
  onResetUsersPassword: (...data) => dispatch(beginResetPassword(data)),
  onRestoreUser: (...data) => dispatch(beginRestoreAccount(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
