import { takeLatest, put, select } from 'redux-saga/effects'
import cookie from 'react-cookies'
import qs from 'query-string'
import request from '@/helpers/axios'
import { getClient } from '@/store/selectors/socket'
import { LOGIN_API_URL } from '@/constants/apiRoutes'
import { SERVER_URL } from '@/constants/socket'
import { USER_PING } from '@/store/actions/user'
import {
  connectToSocket,
  setSocketSubscriptions,
} from '@/store/actions/socket'
import {
  successUserPing,
  errorUserPing,
} from '@/store/actions/user/userPing'
import { ACTIVE_PING_ITEM, SLS24_ACCESS_TOKEN } from '@/constants/names'
import emitter from '@/helpers/eventEmitter'

function sleep(sec) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000))
}

function removePingFromLS() {
  window.localStorage.setItem(ACTIVE_PING_ITEM, '0')
  emitter.emit(ACTIVE_PING_ITEM, 0)
}

function* resetLS(token) {
  while (true) {
    if (token === cookie.load(SLS24_ACCESS_TOKEN)) {
      yield sleep(2)
      break
    }
    yield sleep(0.5)
  }
  removePingFromLS()
}

function* userPing() {
  try {
    if (!cookie.load(SLS24_ACCESS_TOKEN)) {
      return null
    }
    const client = yield select(getClient)
    window.localStorage.setItem(ACTIVE_PING_ITEM, '1')
    emitter.emit(ACTIVE_PING_ITEM, 1)

    if (client && client.disconnect) {
      client.disconnect()
      yield put(setSocketSubscriptions({}))
    }

    const body = { grant_type: 'refresh_token' }
    const response = yield request({
      url: LOGIN_API_URL,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${window.btoa('gateway:gateway')}`,
      },
      body: qs.stringify(body),
    })

    if (response) {
      const { error } = response.data

      if (!error) {
        const { access_token: accessToken } = response.data
        yield put(connectToSocket({
          url: SERVER_URL,
          token: accessToken,
        }))
        yield put(successUserPing())
        yield resetLS(accessToken)
      } else {
        removePingFromLS()
        yield put(errorUserPing(error))
      }
    } else {
      removePingFromLS()
      yield put(errorUserPing(response))
    }
    return null
  } catch (error) {
    removePingFromLS()
    yield put(errorUserPing(error))
    return null
  }
}

export default function* root() {
  yield takeLatest(USER_PING.REQUEST, userPing)
}
