import styled from 'styled-components'
import {
  NOT_REPLACED,
  REPLACEMENT,
  NECESSARY_REPLACE,
} from '@/constants/widgetsDashboard'

export const Container = styled.div`
  position: relative;
  width: 290px;
  height: 276px;
  display: flex;
  box-shadow: 2px 3px 15px ${({ theme }) => theme.colors.shadows.widgetPrimary};
  background: ${({ theme }) => theme.colors.backgrounds.drawer};
  padding: 15px;
  flex-direction: column;
  border-radius: 4px;
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const Title = styled.div`
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  ${({ subtitle, theme }) => subtitle && `
    font-size: 1.2rem;
    color: ${theme.colors.colors.dim};
  `};
  ${({ rowTitle }) => rowTitle && `
    font-size: 1.2rem;
    line-height: 1.4rem;
  `};
`

export const RowContainer = styled.div`
  padding: 10px;
  background: #F4F4F4;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const RowTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`

export const IndicatorLine = styled.div`
  position: relative;
  width: 100%;
  min-height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.borders.default};
  margin-top: auto;
  margin-bottom: 4px;
`

export const IndicatorLineActive = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 4px;
  border-radius: 2px;
  ${({ id, theme }) => {
    switch (id) {
      case NOT_REPLACED: {
        return `background: ${theme.colors.backgrounds.redisign.green};`
      }
      case REPLACEMENT: {
        return `background: ${theme.colors.backgrounds.redisign.warning};`
      }
      case NECESSARY_REPLACE: {
        return `background: ${theme.colors.backgrounds.redisign.error};`
      }
      default: {
        break
      }
    }
  }};
  animation: ${({ theme }) => theme.indicatorLineAnimation};
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
