import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ListIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.45946 7.66419C0.651892 7.66419 0 8.2967 0 9.08024C0 9.86379 0.651892 10.4963 1.45946 10.4963C2.26703 10.4963 2.91892 9.86379 2.91892 9.08024C2.91892 8.2967 2.26703 7.66419 1.45946 7.66419ZM1.45946 2C0.651892 2 0 2.6325 0 3.41605C0 4.1996 0.651892 4.8321 1.45946 4.8321C2.26703 4.8321 2.91892 4.1996 2.91892 3.41605C2.91892 2.6325 2.26703 2 1.45946 2ZM1.45946 13.4889C0.73946 13.4889 0.165405 14.0553 0.165405 14.7444C0.165405 15.4336 0.749189 16 1.45946 16C2.16973 16 2.75351 15.4336 2.75351 14.7444C2.75351 14.0553 2.17946 13.4889 1.45946 13.4889ZM4.37838 15.6885H18V13.8004H4.37838V15.6885ZM4.37838 10.0243H18V8.13621H4.37838V10.0243ZM4.37838 2.47202V4.36008H18V2.47202H4.37838Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ListIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ListIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ListIcon)
