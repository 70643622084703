import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InvertPlusIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8 4C8 3.44772 8.44771 3 9 3C9.55229 3 10 3.44772 10 4L10 7.99996L14 7.99998C14.5523 7.99997 15 8.44768 15 9C15 9.55228 14.5523 9.99999 14 10L10 10L10 14C10 14.5522 9.55231 15 9 15C8.44769 15 7.99998 14.5522 7.99998 14L7.99998 10L4 10C3.44772 9.99999 3.00001 9.55227 3 9C3.00004 8.44768 3.44775 7.99997 4.00002 7.99998L7.99998 7.99996L8 4Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

InvertPlusIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InvertPlusIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InvertPlusIcon)
