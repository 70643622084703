import {
  OBJECT_NAME_CONFIG,
} from '@/constants/objectTypes'

export default (name, type) => {
  const newName = name ? name.toLowerCase() : ''
  const typeName = OBJECT_NAME_CONFIG[type] ? OBJECT_NAME_CONFIG[type].toLowerCase() : ''
  if (newName.indexOf(typeName) === -1 && type !== 'trash') {
    return `${OBJECT_NAME_CONFIG[type]} ${name}`
  }
  return name
}
