import React, {
  useCallback,
  useMemo,
} from 'react'
import noop from 'lodash/noop'
import pt from 'prop-types'
import { Formik, Form } from 'formik'
import TextField from '@/components/fields/TextField'
import Button from '@/components/blocks/Button'
import SelectField from '@/components/fields/SelectField'
import {
  ACYWidgetValidationScheme,
  ACYWIDGET_CONFIG,
} from '@/constants/validationFields'
import {
  PERIOD,
  UNIT,
} from '@/constants/forms/integration'

import {
  SecondaryTitle,
  ContentContainer,
  ButtunContainer,
  TextFieldContainer,
  ErrorField,

} from './styles'

const ACYWidgetForm = ({
  data,
  onSubmit,
  onDenie,
  parametrName,
  refreshTime,
}) => {
  const form = React.createRef()

  const onSubmitForm = useCallback((values) => {
    const { multiplier } = ACYWIDGET_CONFIG.filter((element) => element.value === values[UNIT])[0]
    const result = multiplier * values[PERIOD]
    onSubmit(result)
  }, [onSubmit])

  const settings = useMemo(() => {
    const selectedType = ACYWIDGET_CONFIG
      .filter((element) => (refreshTime / (element.multiplier * 60)) <= 1)[0]
    return {
      [PERIOD]: refreshTime / (selectedType.multiplier),
      [UNIT]: selectedType.value,
    }
  }, [refreshTime])


  return (
    <Formik
      initialValues={{
        [PERIOD]: settings[PERIOD] || data[PERIOD],
        [UNIT]: settings[UNIT] || data[UNIT],
      }}
      ref={form}
      validationSchema={ACYWidgetValidationScheme}
      onSubmit={onSubmitForm}
      render={({
        touched, errors, handleSubmit, isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} id="ACY-setting-widjet" name="ACY-setting-widjet">
          <ContentContainer>
            <SecondaryTitle>
              {parametrName}
            </SecondaryTitle>
            <TextFieldContainer>
              <TextField
                error={(touched[PERIOD] && errors[PERIOD])}
                customError
                name={PERIOD}
                fieldProps={{
                  autoComplete: 'off',
                  disabled: isSubmitting,
                }}
              />
              <SelectField
                smallArrowPadding
                name={UNIT}
                options={ACYWIDGET_CONFIG}
              />
            </TextFieldContainer>
          </ContentContainer>
          <ErrorField>
            {(touched[PERIOD] && errors[PERIOD])}
          </ErrorField>
          <ButtunContainer>
            <Button styleType="flat">
              применить
            </Button>
            <Button styleType="flat" onClick={onDenie}>
              отмена
            </Button>
          </ButtunContainer>
        </Form>
      )}
    />
  )
}

ACYWidgetForm.defaultProps = {
  data: {
    [PERIOD]: 0,
    [UNIT]: ACYWIDGET_CONFIG[1].value,
  },
  onSubmit: noop,
  onDenie: noop,
  parametrName: '',
  refreshTime: 3600,

}

ACYWidgetForm.propTypes = {
  data: pt.shape({
    [PERIOD]: pt.number,
    [UNIT]: pt.string,
  }),
  refreshTime: pt.number,
  onSubmit: pt.func,
  onDenie: pt.func,
  parametrName: pt.string,

}

export default React.memo(ACYWidgetForm)
