import { all } from 'redux-saga/effects'

import getServiceDeskCardRoot from './getCard'
import getServiceDeskTableRoot from './getTable'
import getServiceDeskWidgetRoot from './getWidget'
import addServiceDeskIntegrationRoot from './addIntegration'
import deleteServiceDeskIntegrationRoot from './deleteIntegration'
import getServiceDeskInfoCardRoot from './getInfoCard'
import socketEventsRoot from './events'

export default function* root() {
  yield all([
    socketEventsRoot(),
    getServiceDeskCardRoot(),
    getServiceDeskTableRoot(),
    getServiceDeskWidgetRoot(),
    addServiceDeskIntegrationRoot(),
    deleteServiceDeskIntegrationRoot(),
    getServiceDeskInfoCardRoot(),
  ])
}
