import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import Icons from '@/components/icons/sync'

import {
  MESH_CONTEXT_URL,
  MESH_HISTORY_URL,
  MESH_CLIENT_ID,
  MESH_CLIENT_SECRET,
} from '@/constants/forms/integration'
import {
  TEXT
} from '@/constants/semanticNames'

import baseConfig from './base'

export default (formProps) => [
  ...baseConfig(formProps),
  {
    name: MESH_CONTEXT_URL,
    selector: 'connectionSettings',
    form: formProps,
    type: TEXT,
    label: {
      icon: Icons.EnterIcon,
      title: (<Lang id="syncModule.form.contextUrl" />),
    },
  },
  {
    name: MESH_HISTORY_URL,
    selector: 'connectionSettings',
    form: formProps,
    type: TEXT,
    label: {
      icon: Icons.EnterIcon,
      title: (<Lang id="syncModule.form.historyUrl" />),
    },
  },
  {
    name: MESH_CLIENT_ID,
    selector: 'connectionSettings',
    form: formProps,
    type: TEXT,
    label: {
      icon: Icons.EnterIcon,
      title: (<Lang id="syncModule.form.clientId" />),
    },
  },
  {
    name: MESH_CLIENT_SECRET,
    selector: 'connectionSettings',
    form: formProps,
    type: TEXT,
    label: {
      icon: Icons.EnterIcon,
      title: (<Lang id="syncModule.form.clientSecret" />),
    },
  },
]
