import {
  DEFAULT_BORDER_RADIUS,
  UNITS_METERS,
} from '@/constants/maps'
export const bezierCurve = (t, p0, p1, p2, p3) => {
  const cX = 3 * (p1.x - p0.x)
  const bX = 3 * (p2.x - p1.x) - cX
  const aX = p3.x - p0.x - cX - bX
  const cY = 3 * (p1.y - p0.y)
  const bY = 3 * (p2.y - p1.y) - cY
  const aY = p3.y - p0.y - cY - bY

  const x = (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x;
  const y = (aY * Math.pow(t, 3)) + (bY * Math.pow(t, 2)) + (cY * t) + p0.y;
  return [y, x]
}

export const generateCorner = (t, p0, p1, p2, p3) => {
  const accuracy = 0.01
  const coords = []
  for (let i = 0; i < 1; i += accuracy) {
    coords.push(bezierCurve(i, p0, p1, p2, p3))
  }
  return coords
}

export default (pins) => {
  const borders = pins.reduce((accumulator, pin) => {
    const [vertical, horisontal] = pin.location || []
    return {
      top: accumulator.top < vertical ? vertical : accumulator.top,
      bottom: accumulator.bottom > vertical ? vertical : accumulator.bottom,
      left: accumulator.left > horisontal ? horisontal : accumulator.left,
      right: accumulator.right < horisontal ? horisontal : accumulator.right,
    }
  }, {
    left: 1000,
    right: 0,
    top: 0,
    bottom: 1000,
  })
  if (borders.left === 1000 || borders.bottom === 1000) {
    return null
  }
  
  const centerX = (borders.left + borders.right) / 2
  const centerY = (borders.top + borders.bottom) / 2
  const axisxenterToDirection = {
    left: centerX,
    right: centerX,
    top: centerY,
    bottom: centerY,
  }
  const BorderRadius = Object.keys(borders).reduce((accumulator, elementKey) => {
    const radius = Math.abs(Math.abs(axisxenterToDirection[elementKey]) - Math.abs(borders[elementKey]))
    return radius > accumulator ? radius : accumulator
  }, 0)
  return {
    radius: BorderRadius * 1.2 * UNITS_METERS || DEFAULT_BORDER_RADIUS,
    position: [centerY, centerX],
  }
}