const data1 = [
  [
    50.4346886,
    127.3798876,
  ],
  [
    50.4276579,
    127.3832517,
  ],
  [
    50.4247727,
    127.3959164,
  ],
  [
    50.4215809,
    127.3989223,
  ],
  [
    50.4178015,
    127.3966779,
  ],
  [
    50.4114041,
    127.4067144,
  ],
  [
    50.4077465,
    127.4081892,
  ],
  [
    50.4042522,
    127.4114596,
  ],
  [
    50.4020359,
    127.4175057,
  ],
  [
    50.3994813,
    127.4313247,
  ],
  [
    50.3839691,
    127.4605872,
  ],
  [
    50.3808704,
    127.461904,
  ],
  [
    50.3780114,
    127.4612456,
  ],
  [
    50.3735126,
    127.4620921,
  ],
  [
    50.3629138,
    127.4531255,
  ],
  [
    50.3584737,
    127.4512758,
  ],
  [
    50.3518326,
    127.4467612,
  ],
  [
    50.3490119,
    127.4483287,
  ],
  [
    50.3486518,
    127.4467612,
  ],
  [
    50.3468112,
    127.4426227,
  ],
  [
    50.3450906,
    127.4413373,
  ],
  [
    50.3420093,
    127.4395189,
  ],
  [
    50.3364066,
    127.4384843,
  ],
  [
    50.3345255,
    127.4393308,
  ],
  [
    50.3269097,
    127.4381062,
  ],
  [
    50.3099505,
    127.4289211,
  ],
  [
    50.303616,
    127.4248127,
  ],
  [
    50.2969776,
    127.4186814,
  ],
  [
    50.2939664,
    127.415876,
  ],
  [
    50.3115491,
    127.4403706,
  ],
  [
    50.3144057,
    127.4433754,
  ],
  [
    50.3170024,
    127.4489556,
  ],
  [
    50.3213012,
    127.4497237,
  ],
  [
    50.334469,
    127.4558914,
  ],
  [
    50.3408656,
    127.4641123,
  ],
  [
    50.3419396,
    127.4682414,
  ],
  [
    50.3461741,
    127.4656953,
  ],
  [
    50.3512586,
    127.4626378,
  ],
  [
    50.3545318,
    127.4682487,
  ],
  [
    50.3593646,
    127.4552233,
  ],
  [
    50.3630719,
    127.4556241,
  ],
  [
    50.3728131,
    127.4646947,
  ],
  [
    50.3813682,
    127.4647203,
  ],
  [
    50.3840179,
    127.4635404,
  ],
  [
    50.4011717,
    127.4325552,
  ],
  [
    50.4029537,
    127.4260401,
  ],
  [
    50.4042289,
    127.419012,
  ],
  [
    50.4052425,
    127.4152414,
  ],
  [
    50.4068937,
    127.4115991,
  ],
  [
    50.4119775,
    127.4095984,
  ],
  [
    50.4143966,
    127.4097779,
  ],
  [
    50.4136774,
    127.4134972,
  ],
  [
    50.4112256,
    127.4149849,
  ],
  [
    50.4107515,
    127.4207305,
  ],
  [
    50.4087663,
    127.4251517,
  ],
  [
    50.4114102,
    127.4268738,
  ],
  [
    50.4188922,
    127.4236644,
  ],
  [
    50.4278192,
    127.4174022,
  ],
  [
    50.443016,
    127.4110889,
  ],
  [
    50.4433264,
    127.4096781,
  ],
  [
    50.4444371,
    127.4093447,
  ],
  [
    50.443457,
    127.4054459,
  ],
  [
    50.4339699,
    127.3998348,
  ],
  [
    50.4250647,
    127.4022459,
  ],
  [
    50.425713,
    127.3982046,
  ],
  [
    50.4277179,
    127.3977326,
  ],
  [
    50.4297024,
    127.3972653,
  ],
  [
    50.4305002,
    127.3942125,
  ],
  [
    50.4375305,
    127.389281,
  ],
  [
    50.4346886,
    127.3798876,
  ],
]


const data2 = [
  [
    50.2782262,
    127.4082495,
  ],
  [
    50.2677823,
    127.4557003,
  ],
  [
    50.2544337,
    127.4989446,
  ],
  [
    50.2504623,
    127.5361214,
  ],
  [
    50.2418575,
    127.5661277,
  ],
  [
    50.2409805,
    127.5791568,
  ],
  [
    50.2374648,
    127.6015333,
  ],
  [
    50.2347146,
    127.6056331,
  ],
  [
    50.2238997,
    127.606465,
  ],
  [
    50.2113581,
    127.6029202,
  ],
  [
    50.2101112,
    127.609105,
  ],
  [
    50.208694,
    127.6121835,
  ],
  [
    50.2076309,
    127.620221,
  ],
  [
    50.2069451,
    127.6290088,
  ],
  [
    50.2132548,
    127.6271869,
  ],
  [
    50.2201466,
    127.6250436,
  ],
  [
    50.2224093,
    127.6274548,
  ],
  [
    50.2257347,
    127.6337241,
  ],
  [
    50.2316992,
    127.6261688,
  ],
  [
    50.2368062,
    127.6244541,
  ],
  [
    50.2418783,
    127.6159879,
  ],
  [
    50.2469842,
    127.6140053,
  ],
  [
    50.2492799,
    127.618292,
  ],
  [
    50.2623327,
    127.6172203,
  ],
  [
    50.2678816,
    127.6061285,
  ],
  [
    50.2718546,
    127.6044138,
  ],
  [
    50.2734299,
    127.6052712,
  ],
  [
    50.2763066,
    127.6113261,
  ],
  [
    50.2795597,
    127.6115405,
  ],
  [
    50.2887439,
    127.622093,
  ],
  [
    50.290379,
    127.6239719,
  ],
  [
    50.2964381,
    127.62499,
  ],
  [
    50.3041393,
    127.6180241,
  ],
  [
    50.3060558,
    127.6174347,
  ],
  [
    50.3191127,
    127.6150744,
  ],
  [
    50.3200896,
    127.6216568,
  ],
  [
    50.3209665,
    127.6348748,
  ],
  [
    50.3210761,
    127.6578774,
  ],
  [
    50.317459,
    127.6841416,
  ],
  [
    50.3165821,
    127.6970162,
  ],
  [
    50.317459,
    127.7047409,
  ],
  [
    50.3223913,
    127.7136673,
  ],
  [
    50.3261175,
    127.7217354,
  ],
  [
    50.3284536,
    127.722142,
  ],
  [
    50.3310489,
    127.7225937,
  ],
  [
    50.3458399,
    127.7280869,
  ],
  [
    50.3607359,
    127.7279152,
  ],
  [
    50.366768,
    127.73138,
  ],
  [
    50.3697208,
    127.7330763,
  ],
  [
    50.3750797,
    127.736155,
  ],
  [
    50.3917366,
    127.7399299,
  ],
  [
    50.3940157,
    127.7404465,
  ],
  [
    50.4118002,
    127.7419215,
  ],
  [
    50.4188509,
    127.7425064,
  ],
  [
    50.4307716,
    127.7418198,
  ],
  [
    50.4383162,
    127.7363266,
  ],
  [
    50.4415358,
    127.7277715,
  ],
  [
    50.4440011,
    127.7212204,
  ],
  [
    50.4450139,
    127.7199292,
  ],
  [
    50.4424868,
    127.7161464,
  ],
  [
    50.4407727,
    127.7176778,
  ],
  [
    50.4392277,
    127.7185788,
  ],
  [
    50.4383742,
    127.7183478,
  ],
  [
    50.4376238,
    127.7177933,
  ],
  [
    50.437094,
    127.7182323,
  ],
  [
    50.4357107,
    127.7184402,
  ],
  [
    50.4317752,
    127.7189394,
  ],
  [
    50.4327222,
    127.7158681,
  ],
  [
    50.4320936,
    127.7114873,
  ],
  [
    50.4318272,
    127.7101502,
  ],
  [
    50.4323466,
    127.7034394,
  ],
  [
    50.4424413,
    127.6835932,
  ],
  [
    50.4456341,
    127.6885836,
  ],
  [
    50.449085,
    127.6848626,
  ],
  [
    50.4485129,
    127.6789323,
  ],
  [
    50.4577715,
    127.6679035,
  ],
  [
    50.4576311,
    127.667348,
  ],
  [
    50.4583643,
    127.6668089,
  ],
  [
    50.4584216,
    127.6651916,
  ],
  [
    50.4585672,
    127.6651262,
  ],
  [
    50.4587492,
    127.6654775,
  ],
  [
    50.4588584,
    127.666621,
  ],
  [
    50.4706856,
    127.6596192,
  ],
  [
    50.4762584,
    127.6519487,
  ],
  [
    50.4826245,
    127.649777,
  ],
  [
    50.4862408,
    127.6479749,
  ],
  [
    50.4867553,
    127.6440242,
  ],
  [
    50.490342,
    127.6457569,
  ],
  [
    50.4904302,
    127.643539,
  ],
  [
    50.4906066,
    127.6432155,
  ],
  [
    50.4908417,
    127.6414827,
  ],
  [
    50.4914885,
    127.638433,
  ],
  [
    50.4922773,
    127.6358766,
  ],
  [
    50.4943329,
    127.6322834,
  ],
  [
    50.4921677,
    127.6319281,
  ],
  [
    50.4916971,
    127.6305031,
  ],
  [
    50.4185549,
    127.6126484,
  ],
  [
    50.3846383,
    127.5904888,
  ],
  [
    50.3683386,
    127.570395,
  ],
  [
    50.3678488,
    127.5782285,
  ],
  [
    50.364801,
    127.5804388,
  ],
  [
    50.3632756,
    127.581542,
  ],
  [
    50.3630656,
    127.5823448,
  ],
  [
    50.3625976,
    127.5842162,
  ],
  [
    50.3624945,
    127.5855736,
  ],
  [
    50.3623065,
    127.5865324,
  ],
  [
    50.362126,
    127.5864111,
  ],
  [
    50.3610943,
    127.5877146,
  ],
  [
    50.3608253,
    127.5898748,
  ],
  [
    50.3607627,
    127.5912379,
  ],
  [
    50.3605047,
    127.5919195,
  ],
  [
    50.3596646,
    127.5939757,
  ],
  [
    50.3589866,
    127.5954081,
  ],
  [
    50.3584302,
    127.5964305,
  ],
  [
    50.3572731,
    127.5979553,
  ],
  [
    50.356116,
    127.5995148,
  ],
  [
    50.3546529,
    127.5977705,
  ],
  [
    50.3527697,
    127.5955121,
  ],
  [
    50.3514282,
    127.5938833,
  ],
  [
    50.3497771,
    127.5926646,
  ],
  [
    50.3486014,
    127.5889911,
  ],
  [
    50.3482697,
    127.5875297,
  ],
  [
    50.347879,
    127.5864901,
  ],
  [
    50.3455237,
    127.5845667,
  ],
  [
    50.3458739,
    127.5839487,
  ],
  [
    50.3442189,
    127.5826375,
  ],
  [
    50.3426812,
    127.5813362,
  ],
  [
    50.3416906,
    127.5804127,
  ],
  [
    50.3415811,
    127.5806822,
  ],
  [
    50.3409816,
    127.5802085,
  ],
  [
    50.3404498,
    127.5807966,
  ],
  [
    50.3388181,
    127.5825038,
  ],
  [
    50.3358724,
    127.5807721,
  ],
  [
    50.3417449,
    127.5745617,
  ],
  [
    50.3431014,
    127.5728751,
  ],
  [
    50.3431825,
    127.5704838,
  ],
  [
    50.344267,
    127.5704298,
  ],
  [
    50.344326,
    127.5701699,
  ],
  [
    50.3450761,
    127.5701385,
  ],
  [
    50.3457225,
    127.5689786,
  ],
  [
    50.3460848,
    127.5680433,
  ],
  [
    50.3475417,
    127.5700364,
  ],
  [
    50.3482897,
    127.5710819,
  ],
  [
    50.3483809,
    127.5714863,
  ],
  [
    50.3484435,
    127.5720131,
  ],
  [
    50.348519,
    127.5720989,
  ],
  [
    50.3489334,
    127.5722173,
  ],
  [
    50.3491341,
    127.5715353,
  ],
  [
    50.3494964,
    127.5707225,
  ],
  [
    50.3494547,
    127.5706572,
  ],
  [
    50.3497882,
    127.5697301,
  ],
  [
    50.350028,
    127.570016,
  ],
  [
    50.3501948,
    127.5697382,
  ],
  [
    50.3504945,
    127.5690112,
  ],
  [
    50.3507603,
    127.5690031,
  ],
  [
    50.3510427,
    127.568769,
  ],
  [
    50.3511128,
    127.5684629,
  ],
  [
    50.3510612,
    127.5677582,
  ],
  [
    50.3509506,
    127.5664528,
  ],
  [
    50.3515587,
    127.5662969,
  ],
  [
    50.3515071,
    127.566834,
  ],
  [
    50.3516951,
    127.5668052,
  ],
  [
    50.3523584,
    127.5668456,
  ],
  [
    50.3524432,
    127.5666781,
  ],
  [
    50.3525501,
    127.5662102,
  ],
  [
    50.352539,
    127.5658926,
  ],
  [
    50.35241,
    127.5657655,
  ],
  [
    50.3520304,
    127.5659041,
  ],
  [
    50.3520378,
    127.56565,
  ],
  [
    50.3521742,
    127.5651301,
  ],
  [
    50.3523732,
    127.5650146,
  ],
  [
    50.3526754,
    127.5653092,
  ],
  [
    50.3527454,
    127.5651706,
  ],
  [
    50.3528228,
    127.5651244,
  ],
  [
    50.3529334,
    127.5649107,
  ],
  [
    50.3531987,
    127.5648702,
  ],
  [
    50.3532393,
    127.5647316,
  ],
  [
    50.3529776,
    127.5645063,
  ],
  [
    50.3528044,
    127.5648182,
  ],
  [
    50.3526496,
    127.5649626,
  ],
  [
    50.3523879,
    127.5646796,
  ],
  [
    50.3519788,
    127.5645179,
  ],
  [
    50.3519752,
    127.5623635,
  ],
  [
    50.3521447,
    127.5614335,
  ],
  [
    50.3525796,
    127.5623519,
  ],
  [
    50.3527786,
    127.562427,
  ],
  [
    50.3529629,
    127.5623173,
  ],
  [
    50.3532651,
    127.5621267,
  ],
  [
    50.3536668,
    127.5626407,
  ],
  [
    50.3541016,
    127.5627158,
  ],
  [
    50.354168,
    127.5623692,
  ],
  [
    50.3542859,
    127.5614393,
  ],
  [
    50.3546618,
    127.5614971,
  ],
  [
    50.3549087,
    127.5613238,
  ],
  [
    50.3550561,
    127.5609368,
  ],
  [
    50.3551593,
    127.5603708,
  ],
  [
    50.3553657,
    127.5602726,
  ],
  [
    50.3557415,
    127.5609022,
  ],
  [
    50.3561874,
    127.5615722,
  ],
  [
    50.3560585,
    127.5615722,
  ],
  [
    50.3557379,
    127.5615548,
  ],
  [
    50.3556568,
    127.5616877,
  ],
  [
    50.3557563,
    127.5620631,
  ],
  [
    50.3558484,
    127.5622017,
  ],
  [
    50.3559184,
    127.5625368,
  ],
  [
    50.3559848,
    127.5627678,
  ],
  [
    50.3563496,
    127.5628082,
  ],
  [
    50.3565081,
    127.5625368,
  ],
  [
    50.3567623,
    127.5626407,
  ],
  [
    50.3575472,
    127.5627851,
  ],
  [
    50.3585445,
    127.5630291,
  ],
  [
    50.3584455,
    127.5644586,
  ],
  [
    50.3584976,
    127.5647935,
  ],
  [
    50.3591386,
    127.5652264,
  ],
  [
    50.3599046,
    127.5655531,
  ],
  [
    50.359915,
    127.5640665,
  ],
  [
    50.3600453,
    127.5621551,
  ],
  [
    50.3621193,
    127.5624655,
  ],
  [
    50.362187,
    127.5604806,
  ],
  [
    50.365574,
    127.562392,
  ],
  [
    50.3656399,
    127.558436,
  ],
  [
    50.3571792,
    127.5532294,
  ],
  [
    50.35697,
    127.5448865,
  ],
  [
    50.3481938,
    127.5401719,
  ],
  [
    50.3387357,
    127.5398644,
  ],
  [
    50.3386486,
    127.5408351,
  ],
  [
    50.3383063,
    127.5419512,
  ],
  [
    50.3379547,
    127.5429224,
  ],
  [
    50.3375477,
    127.543937,
  ],
  [
    50.3370666,
    127.5448646,
  ],
  [
    50.3367613,
    127.5453574,
  ],
  [
    50.336345,
    127.5450965,
  ],
  [
    50.3364005,
    127.5434007,
  ],
  [
    50.3359842,
    127.5422846,
  ],
  [
    50.3360397,
    127.5414729,
  ],
  [
    50.3362895,
    127.5406177,
  ],
  [
    50.3364325,
    127.5381833,
  ],
  [
    50.318724,
    127.5350017,
  ],
  [
    50.3107678,
    127.5326049,
  ],
  [
    50.3078885,
    127.5227745,
  ],
  [
    50.3075529,
    127.5215838,
  ],
  [
    50.307274,
    127.5207604,
  ],
  [
    50.3068711,
    127.519478,
  ],
  [
    50.3067228,
    127.5190736,
  ],
  [
    50.3065948,
    127.518563,
  ],
  [
    50.3084597,
    127.4981496,
  ],
  [
    50.3055715,
    127.4977346,
  ],
  [
    50.3051436,
    127.4973187,
  ],
  [
    50.304723,
    127.4970645,
  ],
  [
    50.3042508,
    127.4965563,
  ],
  [
    50.3041759,
    127.495717,
  ],
  [
    50.3041394,
    127.4949002,
  ],
  [
    50.3043272,
    127.4938301,
  ],
  [
    50.3047655,
    127.4927927,
  ],
  [
    50.3058543,
    127.4912675,
  ],
  [
    50.3065673,
    127.4901899,
  ],
  [
    50.306789,
    127.4896385,
  ],
  [
    50.3067812,
    127.4887931,
  ],
  [
    50.3067264,
    127.4877761,
  ],
  [
    50.306596,
    127.4876863,
  ],
  [
    50.3066377,
    127.4872329,
  ],
  [
    50.3068803,
    127.4873759,
  ],
  [
    50.3069533,
    127.4870655,
  ],
  [
    50.3075455,
    127.4873514,
  ],
  [
    50.3075768,
    127.4863671,
  ],
  [
    50.3077228,
    127.486171,
  ],
  [
    50.3077307,
    127.4859056,
  ],
  [
    50.3078402,
    127.485734,
  ],
  [
    50.3079002,
    127.4855339,
  ],
  [
    50.3078924,
    127.4849376,
  ],
  [
    50.3080619,
    127.4848192,
  ],
  [
    50.3080932,
    127.4835653,
  ],
  [
    50.3095196,
    127.4814389,
  ],
  [
    50.311696,
    127.4770608,
  ],
  [
    50.3123289,
    127.4774087,
  ],
  [
    50.3100702,
    127.4738881,
  ],
  [
    50.3131114,
    127.4738636,
  ],
  [
    50.3141233,
    127.4736431,
  ],
  [
    50.3141129,
    127.473153,
  ],
  [
    50.3144363,
    127.4725077,
  ],
  [
    50.3148275,
    127.4712252,
  ],
  [
    50.3144311,
    127.4708005,
  ],
  [
    50.3141442,
    127.4701552,
  ],
  [
    50.3142328,
    127.4698938,
  ],
  [
    50.3147336,
    127.4695426,
  ],
  [
    50.3155316,
    127.4689463,
  ],
  [
    50.3158707,
    127.4689054,
  ],
  [
    50.3162306,
    127.469224,
  ],
  [
    50.3164914,
    127.4697958,
  ],
  [
    50.3167157,
    127.4699755,
  ],
  [
    50.3173781,
    127.47,
  ],
  [
    50.3177275,
    127.4707841,
  ],
  [
    50.3180822,
    127.4709802,
  ],
  [
    50.3188072,
    127.4710619,
  ],
  [
    50.3194278,
    127.4707025,
  ],
  [
    50.3197303,
    127.470294,
  ],
  [
    50.3198034,
    127.469077,
  ],
  [
    50.3197929,
    127.4680314,
  ],
  [
    50.3198764,
    127.4675086,
  ],
  [
    50.3202363,
    127.4673698,
  ],
  [
    50.3206378,
    127.4674596,
  ],
  [
    50.3210081,
    127.467917,
  ],
  [
    50.3212898,
    127.4682274,
  ],
  [
    50.3215088,
    127.4684235,
  ],
  [
    50.3217904,
    127.4688727,
  ],
  [
    50.3222859,
    127.4696161,
  ],
  [
    50.3226666,
    127.4701879,
  ],
  [
    50.3231516,
    127.4705554,
  ],
  [
    50.3241425,
    127.4713314,
  ],
  [
    50.324591,
    127.474174,
  ],
  [
    50.3253107,
    127.4744844,
  ],
  [
    50.3266665,
    127.4786258,
  ],
  [
    50.326969,
    127.4783889,
  ],
  [
    50.3278138,
    127.4815665,
  ],
  [
    50.3287524,
    127.480864,
  ],
  [
    50.3291383,
    127.4803003,
  ],
  [
    50.3307391,
    127.4808885,
  ],
  [
    50.3308172,
    127.4837528,
  ],
  [
    50.3317393,
    127.4835394,
  ],
  [
    50.338675,
    127.4749418,
  ],
  [
    50.3402609,
    127.4692507,
  ],
  [
    50.3414308,
    127.4685473,
  ],
  [
    50.3404115,
    127.4646093,
  ],
  [
    50.3341584,
    127.4566223,
  ],
  [
    50.3212379,
    127.4506115,
  ],
  [
    50.3166984,
    127.4497384,
  ],
  [
    50.3140359,
    127.4440513,
  ],
  [
    50.3112815,
    127.4411439,
  ],
  [
    50.291671,
    127.4137377,
  ],
  [
    50.2913176,
    127.4134085,
  ],
  [
    50.2891631,
    127.411937,
  ],
  [
    50.2884776,
    127.4126421,
  ],
  [
    50.2872044,
    127.411753,
  ],
  [
    50.2855003,
    127.4091779,
  ],
  [
    50.283877,
    127.411531,
  ],
  [
    50.2836393,
    127.4118756,
  ],
  [
    50.2817694,
    127.4105119,
  ],
  [
    50.2782262,
    127.4082495,
  ],
]

const data3 = [
  [
    50.3728525,
    127.5480793,
  ],
  [
    50.3729922,
    127.5503049,
  ],
  [
    50.3734229,
    127.5551277,
  ],
  [
    50.3734621,
    127.5572185,
  ],
  [
    50.3730389,
    127.5611915,
  ],
  [
    50.372401,
    127.565105,
  ],
  [
    50.3720589,
    127.5668443,
  ],
  [
    50.3715412,
    127.5695548,
  ],
  [
    50.3711252,
    127.5727726,
  ],
  [
    50.3850013,
    127.5898654,
  ],
  [
    50.4187367,
    127.6117981,
  ],
  [
    50.4913818,
    127.6295485,
  ],
  [
    50.490186,
    127.6259276,
  ],
  [
    50.4923763,
    127.6153693,
  ],
  [
    50.490186,
    127.5951709,
  ],
  [
    50.4881624,
    127.5951382,
  ],
  [
    50.4860344,
    127.5979581,
  ],
  [
    50.475488,
    127.6087178,
  ],
  [
    50.4570394,
    127.6094383,
  ],
  [
    50.4565718,
    127.596994,
  ],
  [
    50.4547948,
    127.5882011,
  ],
  [
    50.4514678,
    127.5756099,
  ],
  [
    50.4508062,
    127.5679491,
  ],
  [
    50.4453958,
    127.5604946,
  ],
  [
    50.4306416,
    127.5568875,
  ],
  [
    50.422701,
    127.5529599,
  ],
  [
    50.4135332,
    127.5520781,
  ],
  [
    50.4077354,
    127.5532805,
  ],
  [
    50.4017275,
    127.5526945,
  ],
  [
    50.3941858,
    127.5513697,
  ],
  [
    50.3787102,
    127.5495524,
  ],
  [
    50.3728525,
    127.5480793,
  ],
]

export default [[...data1], [...data2], [...data3]]
