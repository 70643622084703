import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SearchIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.76923 16.6154C8.76923 12.2821 12.2821 8.76923 16.6154 8.76923C17.3801 8.76923 18 8.14932 18 7.38462C18 6.61991 17.3801 6 16.6154 6C10.7527 6 6 10.7527 6 16.6154C6 17.3801 6.61991 18 7.38462 18C8.14932 18 8.76923 17.3801 8.76923 16.6154Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0186 34.0371C21.1792 34.0371 24.9912 32.5441 27.9478 30.0646C27.974 30.0953 28.0016 30.1252 28.0306 30.1542L35.8171 37.9408C35.7101 38.6935 35.9462 39.4856 36.5252 40.0645L45.7276 49.267C46.705 50.2444 48.2896 50.2444 49.267 49.267C50.2444 48.2896 50.2444 46.7049 49.267 45.7276L40.0645 36.5251C39.4856 35.9462 38.6935 35.7102 37.9408 35.8171L30.1542 28.0306C30.1252 28.0016 30.0953 27.974 30.0646 27.9478C32.5441 24.9912 34.0371 21.1792 34.0371 17.0186C34.0371 7.61948 26.4177 0 17.0186 0C7.61946 0 0 7.61948 0 17.0186C0 26.4177 7.61946 34.0371 17.0186 34.0371ZM17.0186 31.0339C24.759 31.0339 31.0339 24.759 31.0339 17.0186C31.0339 9.27815 24.759 3.00328 17.0186 3.00328C9.27813 3.00328 3.00328 9.27815 3.00328 17.0186C3.00328 24.759 9.27813 31.0339 17.0186 31.0339Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SearchIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SearchIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SearchIcon)
