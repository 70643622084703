import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const FilterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0018 15.6667L21.8018 6.60001C22.2962 5.94077 21.8259 5 21.0018 5H7.0018C6.17776 5 5.7074 5.94077 6.20181 6.60001L13.0018 15.6666L13.0018 23L15.0018 21L15.0018 15.6667Z" fill={fill} className="svgFill" />
    </svg>
  )
}

FilterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

FilterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(FilterIcon)
