import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CollapsedInfoBlock from '@/components/blocks/CollapsedInfoBlock'
import {
  WARNING,
  ERROR,
  OK,
  UNKNOWN,
} from '@/constants/alarms'
import EventsIcons from '@/components/blocks/EventsIcons'
import Users from '@/components/icons/users'
import { elementsToStatus } from './config'
import {
  FormWrapper,
  StyledCheckBox,
  CheckBoxContainer,
  AlarmEvent,
  AlarmRowContainer,
  AlarmTitleContainer,
  SecondaryTitle,
  EventPreview,
  EventPreviewHeader,
  CloseIconContainer,
  Title,
  AlarmElementPropertyContainer,
  AlarmElementTitleContainer,
  EventPreviewContainer,
  HeaderTitle,
  EventContainer,
  BlockTitle,
} from './styles'

const FILTERS = [
  { id: ERROR },
  { id: WARNING },
  { id: OK },
  { id: UNKNOWN },
]

const AlarmSideBar = ({
  selectedNodeName,
  setSideBarFilter,
  checkBoxOption,
  isOpen,
  toggleIsOpen,
  selectedElement,
  setSelectedElement,
}) => {
  const toggleIsOpenHandler = () => {
    toggleIsOpen(!isOpen)
  }

  const onChange = useCallback((id) => (value) => {
    setSideBarFilter({
      ...checkBoxOption,
      [id]: value,
    })
  }, [checkBoxOption, setSideBarFilter])

  const renderLabel = useCallback((type) => (
    <AlarmRowContainer>
      <AlarmTitleContainer>
        <AlarmEvent type={type}>
          {elementsToStatus[type].icon}
        </AlarmEvent>
        <SecondaryTitle>
          {elementsToStatus[type].name}
        </SecondaryTitle>
      </AlarmTitleContainer>
    </AlarmRowContainer>
  ), [])

  const closeSelectedElementHandler = () => {
    setSelectedElement(null)
  }

  const selectedEventElementOptions = () => {
    const selectedElementKeys = Object.keys(selectedElement)
    if (selectedElementKeys.length > 0 && typeof selectedElement.id !== 'number') {
      closeSelectedElementHandler(null)
      return null
    }
    return (
      <>
        {(selectedElement.history || []).map((element) => {
          const {
            id, description, alertType, statePeriod,
          } = element
          return (
            <AlarmElementPropertyContainer key={id}>
              <AlarmElementTitleContainer>
                <Title>{description}</Title>
              </AlarmElementTitleContainer>
              <EventContainer>
                <EventsIcons type={alertType} />
              </EventContainer>
              <Title bold>
                {statePeriod}
              </Title>
            </AlarmElementPropertyContainer>
          )
        })}
      </>
    )
  }

  const renderForm = useCallback(() => (
    <FormWrapper>
      <BlockTitle>
        Состояние объектов:
      </BlockTitle>
      {FILTERS.map(({ id }) => (
        <CheckBoxContainer key={id}>
          <StyledCheckBox
            name={id}
            value={checkBoxOption[id]}
            onChange={onChange(id)}
          />
          {renderLabel(id)}
        </CheckBoxContainer>
      ))}
    </FormWrapper>
  ), [checkBoxOption, onChange, renderLabel])
  return (
    <>
      {!selectedElement
        ? (
          <CollapsedInfoBlock
            name={selectedNodeName}
            isSettingsOpen={isOpen}
            setIsSettingsOpen={toggleIsOpenHandler}
            content={renderForm()}
          />
        )
        : (
          <EventPreview>
            <EventPreviewHeader>
              <HeaderTitle>
                {selectedElement.object}
              </HeaderTitle>
              <CloseIconContainer onClick={closeSelectedElementHandler}>
                <Users.CrossIcon />
              </CloseIconContainer>
            </EventPreviewHeader>
            <EventPreviewContainer>
              {selectedEventElementOptions()}
            </EventPreviewContainer>

          </EventPreview>
        )}
    </>

  )
}

AlarmSideBar.defaultProps = {
  selectedNodeName: '',
  setSideBarFilter: noop,
  checkBoxOption: pt.arrayOf(pt.object),
  isOpen: true,
  toggleIsOpen: noop,
  setSelectedElement: noop,
  selectedElement: {
    history: [],
  },
}

AlarmSideBar.propTypes = {
  selectedElement: pt.objectOf(pt.object),
  selectedNodeName: pt.string,
  setSideBarFilter: pt.func,
  checkBoxOption: {},
  isOpen: pt.bool,
  toggleIsOpen: pt.func,
  setSelectedElement: pt.func,
}

export default AlarmSideBar
