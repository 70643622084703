import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import ViewTree from '@/components/blocks/ViewTree'
import TextControl from '@/components/controls/TextControl'
import YandexMap from '@/components/blocks/YandexMap'
import UsersIcons from '@/components/icons/users'
import ViewTreeConfig from '@/data/mapTree'
import FromControll from '@/forms/ObjectControlForm'
import pinsConfig from './config'
import {
  Container, Left,
  HeaderContentWrapper,
  Title, Right, Middle,
  InfoTitle,
} from './styles'

class Maps extends React.Component {
  constructor(props) {
    super(props)

    const {
      treeData,
      pins,
      regions,
      pinIsSelected,
    } = props

    this.state = {
      treeData,
      pins,
      regions,
      pinIsSelected,
      mapLocation: [55.5, 37.5],
      name: null,
    }
  }

  openController = () => {
    this.setState({ pinIsSelected: true })
  }

  removeInfo = () => {
    this.setState({
      pinIsSelected: false,
      name: null,
    })
  }

  handleOpenPassport = (node) => {
    this.setState({
      pinIsSelected: true,
      mapLocation: node.location,
      name: node.name,
    })
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.pinIsSelected !== this.state.pinIsSelected) {
      return false
    }
    return true
  }


  render() {
    const {
      pinIsSelected,
      name,
      pins,
      mapLocation,
    } = this.state
    return (
      <Container>
        <Left onClick={this.removeInfo}>
          <Title>
            Групповой контроль
          </Title>
          <HeaderContentWrapper>
            <Lang id="mapsPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={this.handleChange}
                />
              )}
            </Lang>
          </HeaderContentWrapper>
          <ViewTree
            treeData={this.state.treeData}
            searchQuery=""
          />
        </Left>
        {pinIsSelected && (
          <Middle>
            <InfoTitle>
              {name}
            </InfoTitle>
            <FromControll />
          </Middle>
        )}
        <Right onClick={this.removeInfo}>
          <YandexMap
            dataSet={{ pins, regions: [] }}
            excludedTypes={[]}
            mapCenter={mapLocation}
            mapZoom={9}
            handleOpenPassport={this.handleOpenPassport}
          />
        </Right>
      </Container>

    )
  }
}

Maps.defaultProps = {
  treeData: ViewTreeConfig,
  regions: [],
  pins: pinsConfig,
  pinIsSelected: false,
}

export default Maps
