import { GET_CONSUMPTION_ANALYSIS_FILE } from './index'

export const requestConsumptionAnalysisFile = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_FILE.REQUEST,
  payload,
})

export const successConsumptionAnalysisFile = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_FILE.SUCCESS,
  payload,
})

export const errorConsumptionAnalysisFile = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_FILE.ERROR,
  payload,
})
