import { GET_AUTOMATIC_REPORTING } from './index'

export const getAutomaticReportingRequest = (payload) => ({
  type: GET_AUTOMATIC_REPORTING.REQUEST,
  payload,
})

export const getAutomaticReportingSuccess = (payload) => ({
  type: GET_AUTOMATIC_REPORTING.SUCCESS,
  payload,
})

export const getAutomaticReportingError = (payload) => ({
  type: GET_AUTOMATIC_REPORTING.ERROR,
  payload,
})
