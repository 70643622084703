import React from 'react'

import {
  OPPERATION_SUCCESS,
  OPPERATION_ERROR,
  SYNCRONIZATION_SUCCESS,
  SYNCRONIZATION_ERROR,
  CONNECTION_SUCCESS,
  CONNECTION_ERROR,

  CREATE_INTEGRATION_ERROR,
  SYNCRONIZATION_INTEGRATION_ERROR,
  OPERATION_INTEGRATION_ERROR,
  DELETE_INTEGRATION_ERROR,

  INTEGRATION_CREATED,
  INTEGRATION_SYNCHONIZE,

  INTEGRATION_UPDATED,
  INTEGRATION_DELETED,

  REPETITION_ISYNCRONIZATION_FAIL,
} from '@/constants/integrations'

// error
export const ConnectionErrorElement = ({
  name,
  type,
  integrationName,
  error,
}) => (
  <>
    Подключение:
    {' '}
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    <br />
    не выполнено.
    <br />
    <b>{error}</b>
    <br />
    Код ошибки: 000
    <br />
  </>
)

export const SynchronizationIntegrationErrorElement = ({
  name,
  type,
  integrationName,
  error,
}) => (
  <>
    Синхронизация:
    {' '}
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    <br />
    не выполнена.
    <br />
    <b>{error}</b>
    <br />
    Код ошибки: 000
    <br />
  </>
)

export const OperationIntegrationErrorElement = ({
  name, type, integrationName, error,
}) => (
  <>
    Изменение параметров:
    <br />
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    &nbsp;
    не выполнено.
    <br />
    <b>{error}</b>
    <br />
    Код ошибки: 000
    <br />
  </>
)

export const DeleteIntegrationErrorElement = ({
  error,
}) => (
  <>
    <b>{error}</b>
    <br />
    Код ошибки: 000
    <br />
  </>
)
export const SynchronizationRepetitionErrorElement = ({
  name, type, integrationName, error,
}) => (
  <>
    Синхронизация с “АСУ
    {' '}
    {name}
    ” не выполнена.
    <br />
    <b>{error}</b>
    <br />
    Код ошибки: 000
    <br />
  </>
)

// success
export const ConnectionIntegrationSuccessElement = ({ name, type, integrationName }) => (
  <>
    Подключение:
    {' '}
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    <br />
    Успешно завершено.
    <br />
    Выполняется процесс синхронизации данных.
    <br />
  </>
)

export const SynchronizationIntegrationSuccessElement = ({ name, type, integrationName }) => (
  <>
    Интеграция:
    {' '}
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    <br />
    Успешно завершена.
    <br />
  </>
)

export const OperationIntegrationSuccessElement = ({ name, type, integrationName }) => (
  <>
    Изменение параметров:
    <br />
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    &nbsp;
    выполнено.
    <br />
  </>
)

export const DeleteIntegrationSuccessElement = ({ name, type, integrationName }) => (
  <>
    Интеграция:
    {' '}
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    <br />
    была полностью удалена из системы.
    <br />
  </>
)

export const DefaultErrorElement = ({ name, type, integrationName }) => (
  <>
    Операция над:
    {' '}
    {type}
    {' '}
    &quot;
    {integrationName}
    &quot;
    &nbsp;
    {name}
    <br />
    не выполнена.
    <br />
    <b>Ошибка системы.</b>
    <br />
    Код ошибки: 000
    <br />
  </>
)

export default (type = 'ACY', integrationName = ' ') => ({
  [INTEGRATION_CREATED]: (name) => ({
    title: CONNECTION_SUCCESS,
    description: (
      <ConnectionIntegrationSuccessElement
        name={name}
        type={type}
        integrationName={integrationName}
      />),
  }),
  [INTEGRATION_SYNCHONIZE]: (name) => ({
    title: SYNCRONIZATION_SUCCESS,
    description: (
      <SynchronizationIntegrationSuccessElement
        name={name}
        type={type}
        integrationName={integrationName}
      />),
  }),
  [INTEGRATION_DELETED]: (name) => ({
    title: OPPERATION_SUCCESS,
    description: (
      <DeleteIntegrationSuccessElement
        name={name}
        type={type}
        integrationName={integrationName}
      />),
  }),
  [INTEGRATION_UPDATED]: (name) => ({
    title: OPPERATION_SUCCESS,
    description: (
      <OperationIntegrationSuccessElement
        name={name}
        type={type}
        integrationName={integrationName}
      />),
  }),

  [OPERATION_INTEGRATION_ERROR]: (name, error) => ({
    title: OPPERATION_ERROR,
    description: <OperationIntegrationErrorElement
      name={name}
      type={type}
      integrationName={integrationName}
      error={error}
    />,
    type: 'error',
  }),
  [DELETE_INTEGRATION_ERROR]: (name, error) => ({
    title: OPPERATION_ERROR,
    description: <DeleteIntegrationErrorElement
      error={error}
    />,
    type: 'error',
  }),
  [CREATE_INTEGRATION_ERROR]: (name, error) => ({
    title: CONNECTION_ERROR,
    description: <ConnectionErrorElement
      name={name}
      type={type}
      integrationName={integrationName}
      error={error}
    />,
    type: 'error',
  }),
  [SYNCRONIZATION_INTEGRATION_ERROR]: (name, error) => ({
    title: SYNCRONIZATION_ERROR,
    description: <SynchronizationIntegrationErrorElement
      name={name}
      type={type}
      integrationName={integrationName}
      error={error}
    />,
    type: 'error',
  }),
  [REPETITION_ISYNCRONIZATION_FAIL]: (name, error) => ({
    title: SYNCRONIZATION_ERROR,
    description: <SynchronizationRepetitionErrorElement
      name={name}
      type={type}
      integrationName={integrationName}
      error={error}
    />,
    type: 'error',
  }),

})
