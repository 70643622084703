import {
  takeEvery, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_TYUMEN_MESSAGES_WIDGET } from '@/store/actions/tyumenOurHome'
import { generateTyumenOurHomeWidgetUrl } from '@/constants/apiRoutes'
import {
  successTyumenMessagesWidget,
  errorTyumenMessagesWidget,
} from '@/store/actions/tyumenOurHome/getMessagesWidget'
import { getWidgets } from '@/store/selectors/appSettings'
import { getWidgetsData } from '@/store/selectors/customWidgetsData'

function* getWidgetInformation({ payload: id }) {
  try {
    const widgets = yield select(getWidgets)
    const widgetById = widgets.find((widget) => widget.id === id)
    if (widgetById && widgetById.settings) {
      const response = yield request({
        url: generateTyumenOurHomeWidgetUrl(widgetById.settings.id),
        method: 'get',
      })

      if (response.data) {
        const widgetsData = yield select(getWidgetsData)
        const {
          inProcessAppealsAmount,
          refusalAppealsAmount,
          solvedAppealsAmount,
        } = response.data
        const total = inProcessAppealsAmount + refusalAppealsAmount + solvedAppealsAmount
        const updatedWidgetsData = ({
          ...widgetsData,
          [id]: {
            ...response.data,
            inProgressPercent: Number.parseInt((
              (inProcessAppealsAmount / total) * 100
            ).toFixed(0), 10),
            donePercent: Number.parseInt((
              (solvedAppealsAmount / total) * 100
            ).toFixed(0), 10),
          },
        })
        yield put(successTyumenMessagesWidget(updatedWidgetsData))
      }
    }
  } catch (error) {
    yield put(errorTyumenMessagesWidget(error))
  }
}

export default function* root() {
  yield takeEvery(GET_TYUMEN_MESSAGES_WIDGET.REQUEST, getWidgetInformation)
}
