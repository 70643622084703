import {
  DEFAULT_THEME,
  PICKLED_BLUEWOOD,
  FUEL_YELLOW,
  CERULEAN,
  MALACHITE,
  RED,
  WHITE,

  MISCHKA,
  SHARK,
  AZURE_RADIANCE,
  SCIENCE_BLUE,
  REGENT_GRAY,
  GHOST,
  TUNA,
  SHARK_DARK,
  ERROR_RED,
  ATLANTIS,
  AMARANTH,
  ORANGE_PEEL,
  MINE_SHAFT,
  RANG_GREY,
  SILVER_SAND,
  PIN_SHADOW,
  MIDDLE_RED,
  BOULDER,
  GRAPH_SHASOW,
  ATLANTIS_LIGHT,
  BLUE_LIGHT,
  DARK_GRAY,
  FORM_INPUT_SHADOW,
  GRAY,
  SILVER_DARK,
  LIGHT_BLACK,
} from '@/constants/styles/defaultTheme'
import signInBackground from '@/assets/images/signin-background.jpg'

export const colors = {
  backgrounds: {
    default: SHARK,
    header: SHARK,
    drawer: SHARK,
    fuelYellow: FUEL_YELLOW,
    extraLight: TUNA,
    dark: SHARK_DARK,
    light: MISCHKA,
    button: AZURE_RADIANCE,
    buttonHover: SCIENCE_BLUE,
    textField: GHOST,
    textFieldActive: WHITE,
    textFieldDisabled: SILVER_SAND,
    disabled: REGENT_GRAY,
    radio: AZURE_RADIANCE,
    select: WHITE,
    selectInput: SHARK,
    redisign: {
      default: SHARK,
      silverDark: SILVER_DARK,
      secondary: SHARK,
      tertiary: SHARK,
      topDownGradient: SHARK,
      defaultReverse: SHARK,
      light: SHARK,
      header: SHARK,
      dark: SHARK,
      hightLighter: TUNA,
      widget: SHARK,
      gray: SHARK_DARK,
      lightGray: SHARK,
      darkGray: DARK_GRAY,
      green: ATLANTIS_LIGHT,
      blue: BLUE_LIGHT,
      warning: ORANGE_PEEL,
      disabled: BOULDER,
      error: MIDDLE_RED,
      table: {
        dark: SHARK,
      },
      input: {
        light: WHITE,
        dark: SHARK,
      },
    },
    chart: {
      default: ATLANTIS,
      defaultReverse: ATLANTIS,
      critical: AMARANTH,
      middle: ORANGE_PEEL,
      normal: REGENT_GRAY,
      low: AZURE_RADIANCE,
    },
    notification: {
      success: ATLANTIS,
      info: AZURE_RADIANCE,
      error: AMARANTH,
      warning: ORANGE_PEEL,
    },
    controls: {
      green: ATLANTIS,
      red: AMARANTH,
      gray: REGENT_GRAY,
    },
  },
  colors: {
    title: GHOST,
    default: MISCHKA,
    dim: BOULDER,
    dark: PICKLED_BLUEWOOD,
    light: WHITE,
    disabled: REGENT_GRAY,
    tab: WHITE,
    tabDefault: REGENT_GRAY,
    logo: WHITE,
    svg: GHOST,
    tooltip: PICKLED_BLUEWOOD,
    treeIcons: GHOST,
    svgReverse: WHITE,
    textField: REGENT_GRAY,
    textFieldActive: SHARK_DARK,
    charts: WHITE,
    online: ATLANTIS,
    notation: WHITE,
    error: ERROR_RED,
    select: SHARK,
    option: WHITE,
    switchButton: {
      default: WHITE,
    },
    redisign: {
      default: MISCHKA,
      secondary: RANG_GREY,
      svg: MISCHKA,
      disabled: REGENT_GRAY,
      dark: MINE_SHAFT,
      error: MIDDLE_RED,
      lightBlack: LIGHT_BLACK,
    },
    notification: {
      title: SHARK_DARK,
      description: TUNA,
      users: WHITE,
    },
    subTitle: GHOST,
  },
  borders: {
    default: TUNA,
    textField: REGENT_GRAY,
    radio: WHITE,
    tree: REGENT_GRAY,
    disabled: REGENT_GRAY,
    redisign: {
      default: TUNA,
      hightLight: TUNA,
      contrast: SHARK,
      radio: WHITE,
      dark: WHITE,
      gray: GRAY,
      lightBlack: LIGHT_BLACK,
    },
  },
  shadows: {
    widget: `${SHARK}17`,
    widgetPrimary: SHARK_DARK,
    widgetSecondary: SHARK_DARK,
    pin: PIN_SHADOW,
    graph: GRAPH_SHASOW,
    field: FORM_INPUT_SHADOW,
  },
  messages: {
    default: REGENT_GRAY,
    error: ERROR_RED,
    ok: MALACHITE,
    warning: ORANGE_PEEL,
    critical: MIDDLE_RED,
    fix: ATLANTIS_LIGHT,
  },
  white: WHITE,
  mapFilter: 'grayscale(100%) brightness(100%) invert(100%)',
}

export const backgroundImage = null

export const sizes = {
  headerHeight: 48,
  menuRowHeight: 48,
  sidebarWidthMax: 260,
  sidebarWidthMin: 48,
  sideBarViewTreeMin: 280,
  viewTreeHeightFix: 158,
  tabsNamesHeight: 48,
  unitCardWidth: 230,
  passportizationWidth: 310,
}

export const mapColors = {
  green: MALACHITE,
  yellow: FUEL_YELLOW,
  blue: CERULEAN,
  red: RED,
  border: PICKLED_BLUEWOOD,
  color: WHITE,
}

export const images = {
  signIn: signInBackground,
}

export default {
  ...DEFAULT_THEME,
  colors,
  images,
  mapColors,
  backgroundImage,
  sizes,
}
