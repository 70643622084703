import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ControlCenterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask-control-center)">
        <path d="M26.5686 18.8338C27.8907 18.8338 28.9626 17.7517 28.9626 16.4169C28.9626 15.0821 27.8907 14 26.5686 14C25.2464 14 24.1745 15.0821 24.1745 16.4169C24.1745 17.7517 25.2464 18.8338 26.5686 18.8338Z" fill={fill} className="svgFill" />
        <path d="M34.9476 28.6283C36.2698 28.6283 37.3417 27.5462 37.3417 26.2113C37.3417 24.8765 36.2698 23.7944 34.9476 23.7944C33.6255 23.7944 32.5536 24.8765 32.5536 26.2113C32.5536 27.5462 33.6255 28.6283 34.9476 28.6283Z" fill={fill} className="svgFill" />
        <path d="M21.7805 38C23.1027 38 24.1745 36.9179 24.1745 35.5831C24.1745 34.2482 23.1027 33.1662 21.7805 33.1662C20.4584 33.1662 19.3865 34.2482 19.3865 35.5831C19.3865 36.9179 20.4584 38 21.7805 38Z" fill={fill} className="svgFill" />
        <path d="M22.9775 16.417C22.9775 16.2357 22.9775 15.994 23.0374 15.8128H14.8978C14.419 15.8128 14 16.2357 14 16.7191C14 17.2025 14.419 17.6255 14.8978 17.6255H23.2169C23.0374 17.2629 22.9775 16.84 22.9775 16.417Z" fill={fill} className="svgFill" />
        <path d="M37.1022 15.8128H30.0998C30.1596 15.994 30.1596 16.2357 30.1596 16.417C30.1596 16.84 30.0998 17.2629 29.9202 17.6255H37.1022C37.581 17.6255 38 17.2025 38 16.7191C38 16.2357 37.6409 15.8128 37.1022 15.8128Z" fill={fill} className="svgFill" />
        <path d="M31.3566 26.2115C31.3566 25.9094 31.4164 25.5469 31.4763 25.2448H14.8978C14.419 25.3052 14 25.6677 14 26.2115C14 26.7553 14.419 27.1179 14.8978 27.1179H31.4763C31.4164 26.8158 31.3566 26.5136 31.3566 26.2115Z" fill={fill} className="svgFill" />
        <path d="M37.1022 34.858H25.3117C25.3716 35.0997 25.3716 35.3414 25.3716 35.5831C25.3716 35.9456 25.3117 36.3081 25.192 36.6707H37.1022C37.581 36.6707 38 36.2477 38 35.7643C38 35.2809 37.6409 34.858 37.1022 34.858Z" fill={fill} className="svgFill" />
        <path d="M18.1895 35.5831C18.1895 35.3414 18.1895 35.0997 18.2494 34.858H14.8978C14.419 34.858 14 35.2809 14 35.7643C14 36.2477 14.419 36.6707 14.8978 36.6707H18.3691C18.2494 36.3081 18.1895 35.9456 18.1895 35.5831Z" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

ControlCenterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ControlCenterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ControlCenterIcon)
