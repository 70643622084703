import styled from 'styled-components'

export default styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  ${({ active, theme }) => active && `
    svg {
      .svgFill {
        fill: ${theme.colors.backgrounds.button};
      }
    }
  `}
`
