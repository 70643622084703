import styled from 'styled-components'

export const Container = styled.div`
  min-width: 690px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
