import widgets from '../widgets'

const {
  ATMOSPHERE_PRESSURE,
  AIR_TEMPERATURE,
} = widgets

export default [
  ATMOSPHERE_PRESSURE({}),
  AIR_TEMPERATURE({}),
]
