import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SynchronizationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C4.02632 0 0 4.04762 0 9.04761C0 10.9502 0.571797 12.781 1.65331 14.2903L0.0473713 15.9047L5.68421 16.6666L4.68948 11.2381L3.01678 12.9196C2.29343 11.7756 1.89474 10.427 1.89474 9.04761C1.89474 5.09523 5.06842 1.90476 9 1.90476C9.42632 1.90476 9.9 1.95238 10.3263 2.04762L10.6579 0.190476C10.1368 0.0476191 9.56842 0 9 0Z" fill={fill} className="svgFill" />
      <path d="M17.9526 2.19047L12.3158 1.42857L13.3105 6.85714L14.9905 5.16833C15.7157 6.31949 16.1053 7.65975 16.1053 9.00001C16.1053 12.9524 12.9316 16.1429 9 16.1429C8.57368 16.1429 8.1 16.0952 7.67368 16L7.34211 17.8571C7.91053 17.9524 8.47895 18 9 18C13.9737 18 18 13.9524 18 8.95239C18 7.15819 17.4282 5.32568 16.3529 3.79863L17.9526 2.19047Z" fill={fill} className="svgFill" />
    </svg>

  )
}

SynchronizationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SynchronizationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SynchronizationIcon)
