import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HeatSupplyIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.4436 10.7787C22.1358 10.7787 21.8861 10.9906 21.8861 11.2514V13.0999H21.263V12.6786C21.263 11.6311 20.2578 10.7787 19.0228 10.7787C18.3518 10.7787 17.7509 11.0315 17.3396 11.4288C16.9284 11.0315 16.3274 10.7787 15.6568 10.7787C14.9858 10.7787 14.3845 11.0315 13.9736 11.4288C13.5625 11.0315 12.9613 10.7787 12.2905 10.7787C11.6198 10.7787 11.0185 11.0315 10.6075 11.4288C10.1965 11.0315 9.59517 10.7787 8.92436 10.7787C7.68918 10.7787 6.684 11.6312 6.684 12.6786V13.0999H5.55737C5.24984 13.0999 5 13.3116 5 13.5725C5 13.8334 5.24984 14.0453 5.55737 14.0453H6.68315V21.1001C6.68315 22.1476 7.6884 23 8.9235 23C9.59432 23 10.1956 22.7473 10.6066 22.3501C11.0177 22.7473 11.6188 23 12.2896 23C12.9605 23 13.5616 22.7473 13.9726 22.3501C14.3836 22.7473 14.985 23 15.6556 23C16.3263 23 16.9279 22.7473 17.3388 22.3501C17.7496 22.7473 18.3512 23 19.0219 23C20.2572 23 21.2624 22.1476 21.2624 21.1001V14.0456H22.4426C22.7503 14.0456 23 13.8337 23 13.5729V11.2517C23.001 10.9906 22.7509 10.7787 22.4436 10.7787ZM20.1482 21.1004C20.1482 21.6269 19.6433 22.0551 19.0228 22.0551C18.4022 22.0551 17.8971 21.627 17.8971 21.1004V12.679C17.8971 12.1526 18.4022 11.7243 19.0228 11.7243C19.6433 11.7243 20.1482 12.1525 20.1482 12.679V21.1004ZM16.7819 21.1001C16.7819 21.6266 16.2767 22.0547 15.6562 22.0547C15.0356 22.0547 14.5304 21.6267 14.5304 21.1001V12.679C14.5304 12.1526 15.0355 11.7243 15.6562 11.7243C16.2767 11.7243 16.7819 12.1525 16.7819 12.679V21.1001ZM13.4158 21.1001C13.4158 21.6266 12.9108 22.0547 12.2903 22.0547C11.6698 22.0547 11.1647 21.6267 11.1647 21.1001V12.679C11.1647 12.1526 11.6698 11.7243 12.2903 11.7243C12.9108 11.7243 13.4158 12.1525 13.4158 12.679V21.1001ZM10.0495 21.1001C10.0495 21.6266 9.54432 22.0547 8.92381 22.0547C8.30323 22.0547 7.79827 21.6267 7.79827 21.1001V12.679C7.79827 12.1526 8.30323 11.7243 8.92381 11.7243C9.54432 11.7243 10.0495 12.1525 10.0495 12.679V21.1001Z" fill={fill} className="svgFill" />
      <path d="M10.2658 5.15856C9.76344 5.6668 9.55668 6.20498 9.80566 6.8511C10.0514 7.48852 10.806 8.07312 10.2038 8.72986C9.7681 9.20467 10.5511 9.87908 10.9919 9.39826C11.4879 8.85738 11.7024 8.30568 11.452 7.63444C11.2261 7.0288 10.4531 6.43458 11.0539 5.82697C11.5126 5.36303 10.7283 4.69067 10.2658 5.15856Z" fill={fill} className="svgFill" />
      <path d="M14.3367 9.3982C14.8332 8.85731 15.0472 8.30561 14.7969 7.63437C14.5711 7.02874 13.7979 6.43451 14.3987 5.8269C14.8574 5.36296 14.0729 4.6906 13.6107 5.15849C13.1083 5.66674 12.9016 6.20492 13.1506 6.85104C13.3963 7.48845 14.1509 8.07305 13.5487 8.72979C13.1126 9.20467 13.8956 9.87901 14.3367 9.3982Z" fill={fill} className="svgFill" />
      <path d="M16.9546 5.15856C16.4524 5.6668 16.2456 6.20498 16.4945 6.8511C16.7405 7.48852 17.495 8.07312 16.893 8.72986C16.4571 9.20467 17.2399 9.87908 17.6808 9.39826C18.1769 8.85738 18.3914 8.30568 18.141 7.63444C17.9149 7.0288 17.1419 6.43458 17.7429 5.82697C18.2016 5.36303 17.4174 4.69067 16.9546 5.15856Z" fill={fill} className="svgFill" />
    </svg>
  )
}

HeatSupplyIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HeatSupplyIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HeatSupplyIcon)
