import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const BLGLogoIcon = ({ onClick }) => {
  const styles = '.class-1,.class-3{fill:#ffffff;}.class-2{fill:#f08412;}.class-3{fillRule:evenodd;}.class-4{fill:#516473;}.class-5{fill:#00639a;}.class-6{fill:#2b2a68;}'

  return (
    <svg onClick={onClick} id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 48.7">
      <defs><style>{styles}</style></defs>
      <path class="class-1" d="M4.85,48.77v.32H3.55v.81a2.3,2.3,0,0,1,.37,0,1.42,1.42,0,0,1,.78.2.82.82,0,0,1,.38.73.91.91,0,0,1-.25.65,1.53,1.53,0,0,1-1.06.33,4,4,0,0,1-.6,0v-3Zm-1.3,2.7.33,0a.9.9,0,0,0,.63-.23.64.64,0,0,0,.16-.44.55.55,0,0,0-.24-.49,1,1,0,0,0-.57-.15l-.31,0Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M8.1,50.24a1.4,1.4,0,0,1-1.39,1.57,1.37,1.37,0,0,1-1.35-1.52,1.42,1.42,0,0,1,1.39-1.57A1.37,1.37,0,0,1,8.1,50.24Zm-2.33,0c0,.64.35,1.22,1,1.22s1-.57,1-1.25S7.38,49,6.73,49,5.77,49.63,5.77,50.28Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M11.16,50.24a1.4,1.4,0,0,1-1.39,1.57,1.37,1.37,0,0,1-1.35-1.52,1.42,1.42,0,0,1,1.39-1.57A1.37,1.37,0,0,1,11.16,50.24Zm-2.32,0c0,.64.34,1.22.95,1.22s1-.57,1-1.25S10.44,49,9.8,49,8.84,49.63,8.84,50.28Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M13.76,51.67a2,2,0,0,1-.79.14,1.4,1.4,0,0,1-1.48-1.52,1.49,1.49,0,0,1,1.56-1.57,1.67,1.67,0,0,1,.71.13l-.09.32a1.45,1.45,0,0,0-.61-.13,1.12,1.12,0,0,0-1.17,1.24,1.09,1.09,0,0,0,1.16,1.2,1.51,1.51,0,0,0,.63-.12Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M17.15,48.77v3h-.39V49.09h-1V50c0,.65,0,1.37-.45,1.66a.89.89,0,0,1-.46.14l0-.31a.65.65,0,0,0,.32-.16,2.11,2.11,0,0,0,.26-1.3V48.77Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M18.31,50.82l-.31.94h-.4l1-3h.47l1,3h-.41l-.32-.94Zm1-.3L19,49.66a5.35,5.35,0,0,1-.16-.55h0a5.44,5.44,0,0,1-.15.54l-.29.87Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M20.91,48.77V50c0,.48,0,.87,0,1.27h0c.15-.31.34-.63.56-1l1-1.54h.39v3h-.36V50.49c0-.49,0-.84,0-1.22h0a9.34,9.34,0,0,1-.56,1L21,51.76h-.42v-3Zm.49-.63c0,.14.06.28.27.28s.25-.13.27-.28h.29c0,.3-.2.48-.57.48s-.54-.18-.56-.48Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M24,49.1h-.91v-.33h2.22v.33h-.91v2.66H24Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M26,48.77V50c0,.48,0,.87,0,1.27h0c.15-.31.34-.63.56-1l1-1.54h.38v3h-.36V50.49c0-.49,0-.84,0-1.22h0a11.27,11.27,0,0,1-.55,1l-.94,1.48h-.42v-3Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M29,48.77V50h1.44V48.77h.39v3h-.39v-1.4H29v1.4H28.6v-3Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M33.09,48.77v.32H31.88v2.67H31.5v-3Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M36,48.77v.32h-1.2v2.67h-.39v-3Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M36.29,48.8a4.67,4.67,0,0,1,.74,0,1.24,1.24,0,0,1,.84.25.81.81,0,0,1,.26.62.88.88,0,0,1-.23.64,1.23,1.23,0,0,1-.92.33,1,1,0,0,1-.31,0v1.2h-.38Zm.38,1.45a1.45,1.45,0,0,0,.32,0c.47,0,.75-.23.75-.64s-.28-.59-.7-.59a2.45,2.45,0,0,0-.37,0Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M38.71,48.77,39.3,50c.08.18.15.36.22.53h0c0-.15.11-.33.19-.54l.46-1.26h.41L40,50.31a6.61,6.61,0,0,1-.56,1.13.85.85,0,0,1-.68.37l-.22,0,0-.32h.14c.31,0,.49-.3.59-.51a.17.17,0,0,0,0-.18l-1-2Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M43.08,48.77v3h-.39V49.09H41.3v2.67h-.39v-3Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M45.92,48.77v3h-.39V49.09H44.14v2.67h-.39v-3Z" transform="translate(-2.99 -3.11)" />
      <rect class="class-2" x="22.53" width="20.47" height="20.47" />
      <path class="class-3" d="M35.63,18.63a5.12,5.12,0,0,0,3.63-1.51l1.36,1.37a7.08,7.08,0,0,1-10-10L32,9.82a5.16,5.16,0,0,0,3.65,8.81M38.57,7.51,37.2,6.14l-5.59,5.57L33,13.07Zm-1.2,9.91L36,16.1l5.59-5.56L43,11.85ZM32.76,15l1.31,1.37L41.72,8.7,40.4,7.34,32.76,15" transform="translate(-2.99 -3.11)" />
      <rect class="class-4" width="20.47" height="20.47" />
      <path class="class-3" d="M15.89,19.59h.27v-.81a.46.46,0,0,0,.43.23c.53,0,.68-.5.68-.88s-.24-.78-.65-.78a.53.53,0,0,0-.48.26v-.22h-.25v2.2Zm.7-2c.38,0,.41.41.41.67,0,.42-.25.53-.42.53s-.42-.27-.42-.61,0-.59.43-.59Zm-2.35-.19v1.13a.47.47,0,0,0,.52.49.51.51,0,0,0,.49-.28h0V19h.25V17.39h-.27v.87c0,.23-.1.52-.43.52-.17,0-.3-.09-.3-.34V17.39Zm-1.48.79c0-.22.08-.61.45-.61s.46.39.46.61-.08.6-.46.6-.45-.39-.45-.6Zm-.28,0a.74.74,0,1,0,1.46,0,.74.74,0,1,0-1.46,0Zm-.9-.79V19h.26v-.91a.4.4,0,0,1,.4-.42h.11v-.28h-.07a.53.53,0,0,0-.45.3h0v-.26Zm-1.36.67h.67a.68.68,0,0,1-.23.57.69.69,0,0,1-.45.14c-.57,0-.71-.45-.71-.89s.32-.87.7-.87a.6.6,0,0,1,.65.45h.28a.87.87,0,0,0-.91-.71,1,1,0,0,0-1,1.12,1,1,0,0,0,1,1.15.87.87,0,0,0,.71-.34L11,19h.19V17.81h-.95v.25Z" transform="translate(-2.99 -3.11)" />
      <path class="class-3" d="M18.92,13.4l.27.11-.67,1.82H13.37V15a1.73,1.73,0,0,0,.51-.06.48.48,0,0,0,.27-.19,1,1,0,0,0,.14-.4,6.37,6.37,0,0,0,0-.71V9.76a4.29,4.29,0,0,0-.06-.85A.54.54,0,0,0,14,8.52a1.39,1.39,0,0,0-.63-.11V8.08h2.88v.33a1.37,1.37,0,0,0-.62.11.56.56,0,0,0-.26.38,4.41,4.41,0,0,0-.06.86v4.08a3.08,3.08,0,0,0,.06.72.48.48,0,0,0,.27.32,2,2,0,0,0,.73.12,3.22,3.22,0,0,0,1.1-.17,2,2,0,0,0,.78-.51,4.88,4.88,0,0,0,.67-.92ZM9.17,11.66v2.43a.93.93,0,0,0,.22.72A.87.87,0,0,0,10,15a4.31,4.31,0,0,0,.54,0,2.57,2.57,0,0,0,.77-.16,1.31,1.31,0,0,0,.51-.33,1.59,1.59,0,0,0,.38-1.16,1.61,1.61,0,0,0-.36-1,1.7,1.7,0,0,0-.87-.49,5.14,5.14,0,0,0-1.26-.13Zm0-.33h.74a3.35,3.35,0,0,0,1-.14,1.39,1.39,0,0,0,.69-.49,1.56,1.56,0,0,0,.26-.94,1.2,1.2,0,0,0-.57-1,2.36,2.36,0,0,0-1.24-.32,1.16,1.16,0,0,0-.54.1.49.49,0,0,0-.26.3,1.62,1.62,0,0,0-.08.56v2Zm-1.89,4V15a1.56,1.56,0,0,0,.55-.08.56.56,0,0,0,.27-.34,2.37,2.37,0,0,0,.09-.76V9.51A1.29,1.29,0,0,0,8,8.61a1,1,0,0,0-.69-.2V8.08H9.89a7.85,7.85,0,0,1,1.4.12,2.43,2.43,0,0,1,1.12.49,1.29,1.29,0,0,1,.47,1.09A1.37,1.37,0,0,1,12.27,11a3.38,3.38,0,0,1-1.49.51v0a4,4,0,0,1,1.18.22,2.4,2.4,0,0,1,1,.61,1.45,1.45,0,0,1,.39,1,1.55,1.55,0,0,1-.26.89,1.86,1.86,0,0,1-.69.61,3.47,3.47,0,0,1-.92.33,5.34,5.34,0,0,1-1,.1Z" transform="translate(-2.99 -3.11)" />
      <rect class="class-5" y="22.67" width="20.47" height="20.47" />
      <path class="class-3" d="M14.81,36.48h1.57V30.13H14.81v6.35Zm2.7-6.35v6.35h1.61V30.13Z" transform="translate(-2.99 -3.11)" />
      <path class="class-3" d="M7.34,41.89H8.93V35.53H7.34v6.36Zm2.72-6.36v6.36h1.6V35.53Z" transform="translate(-2.99 -3.11)" />
      <path class="class-3" d="M12.78,41.87h6.34V40.28H12.78v1.59Zm0-4.32v1.6h6.34v-1.6Z" transform="translate(-2.99 -3.11)" />
      <path class="class-3" d="M7.32,34.45h6.35v-1.6H7.32v1.6Zm0-4.32v1.58h6.35V30.13Z" transform="translate(-2.99 -3.11)" />
      <rect class="class-6" x="22.53" y="22.67" width="20.47" height="20.47" />
      <path class="class-1" d="M40.64,27.87v1a9,9,0,0,0-7.13.46,8.42,8.42,0,0,0-3.89,5.13,7.52,7.52,0,0,1,4.5-6,10.92,10.92,0,0,1,6.52-.51Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M40.76,44.15v-1a9.19,9.19,0,0,1-7.21-.45,8.41,8.41,0,0,1-3.93-5.14,7.5,7.5,0,0,0,4.55,6,11.06,11.06,0,0,0,6.59.51Z" transform="translate(-2.99 -3.11)" />
      <path class="class-1" d="M40.65,29.2s-4.7-1.77-8.44,1.43c-3.47,3-3.08,10.23,2.9,12.19a8.75,8.75,0,0,0,5.64-.2V41c-3.89,2.21-9.1,0-9.1-5.12,0-4.71,4.6-7,9-5V29.2Z" transform="translate(-2.99 -3.11)" />
      <polygon class="class-1" points="34.02 26.93 34.75 32.52 28.61 32.52 33.58 31.65 34.02 26.93 34.02 26.93 34.02 26.93 34.02 26.93" />
      <polygon class="class-1" points="33.4 33 38.9 33 34.57 33.88 34.11 38.72 33.4 33 33.4 33 33.4 33 33.4 33" />
    </svg>
  )
}

BLGLogoIcon.propTypes = {
  onClick: pt.func,
}

BLGLogoIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(BLGLogoIcon)
