import { GET_SERVICE_DESK_WIDGET } from './index'

export const getServiceDeskWidgetRequest = (payload) => ({
  type: GET_SERVICE_DESK_WIDGET.REQUEST,
  payload,
})

export const getServiceDeskWidgetSuccess = (payload) => ({
  type: GET_SERVICE_DESK_WIDGET.SUCCESS,
  payload,
})

export const getServiceDeskWidgetError = (payload) => ({
  type: GET_SERVICE_DESK_WIDGET.ERROR,
  payload,
})
