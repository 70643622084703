import { GET_LIVE_TIME_REPORT_BAR_DATA } from './index'

export const requestLiveTimeReportBarData = (payload) => ({
  type: GET_LIVE_TIME_REPORT_BAR_DATA.REQUEST,
  payload,
})

export const successLiveTimeReportBarData = (payload) => ({
  type: GET_LIVE_TIME_REPORT_BAR_DATA.SUCCESS,
  payload,
})

export const errorLiveTimeReportBarData = (payload) => ({
  type: GET_LIVE_TIME_REPORT_BAR_DATA.ERROR,
  payload,
})
