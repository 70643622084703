import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  successGetObjectValidationScheme,
  errorGetObjectValidationScheme,
} from '@/store/actions/installation/getObjectValidationScheme'
import { generateGetValidationScheme } from '@/constants/apiRoutes'

import { GET_OBJECT_VALIDATION_SCHEME } from '@/store/actions/installation'

function* getRootChildrenSaga({ payload: { integrationType, type } }) {
  try {
    const { data } = yield request({
      url: generateGetValidationScheme(integrationType, type),
      method: 'get',
    })
    yield put(successGetObjectValidationScheme(data))
  } catch (error) {
    yield put(errorGetObjectValidationScheme({}))
    console.log('function*getRootChildrenSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_VALIDATION_SCHEME.REQUEST, getRootChildrenSaga)
}
