import {
  TEXT,
  FILE,
  PHOTO,
} from '@/constants/semanticNames'

export default [
  {
    selector: 'generalInfo.name',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'generalInfo.identifier',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
    immutable: true,
  },
  {
    selector: 'generalInfo.equipmentType',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'generalInfo.modelMark',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'generalInfo.producerSupplier',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'generalInfo.automatedControlSystemIdentifier',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
    immutable: true,
  },
  {
    selector: 'technicalLimitations.workingTemperatureRange',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'technicalLimitations',
    format: 'temperatureRange',
  },
  {
    selector: 'technicalLimitations.powerConsumption',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'technicalLimitations',
  },
  {
    selector: 'exploitation.startDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'exploitation.organization',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'exploitation.owner',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'exploitation.guaranteePeriod',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'exploitation.lifeTime',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'inner.photo',
    title: 'someTitle',
    type: PHOTO,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'inner',
  },
  {
    selector: 'inner.file',
    title: 'someTitle',
    type: FILE,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'inner',
  },
]
