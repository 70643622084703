import { SET_SETTINGS } from '../index'

export const requestSetSettings = () => ({
  type: SET_SETTINGS.REQUEST,
})

export const successSetSettings = (payload) => ({
  type: SET_SETTINGS.SUCCESS,
  payload,
})

export const errorSetSettings = (error) => ({
  type: SET_SETTINGS.ERROR,
  payload: error,
})
