import { GET_ALL_INTEGRATIONS } from './index'

export const requestGetAllIntegrations = (payload) => ({
  type: GET_ALL_INTEGRATIONS.REQUEST,
  payload,
})

export const successGetAllIntegrations = (payload) => ({
  type: GET_ALL_INTEGRATIONS.SUCCESS,
  payload,
})

export const errorGetAllIntegrations = (payload) => ({
  type: GET_ALL_INTEGRATIONS.ERROR,
  payload,
})
