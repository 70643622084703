import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import {
  RadioButtonContainer,
  RadioButtonStyled,
  RadioButtonLabel,
} from './styles'

const RadioButton = ({
  active, label, onChange, type, ...props
}) => (
  <RadioButtonContainer onClick={onChange} {...props}>
    <RadioButtonStyled active={active} type={type} />
    {label && (<RadioButtonLabel>{label}</RadioButtonLabel>)}
  </RadioButtonContainer>
)

RadioButton.propTypes = {
  active: pt.bool,
  label: pt.oneOfType([pt.number, pt.string, pt.object, pt.element]),
  onChange: pt.func,
  type: pt.string,
}
RadioButton.defaultProps = {
  active: false,
  label: null,
  type: null,
  onChange: noop,
}

export default RadioButton
