import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  margin-bottom: 10px;
  @media screen and (max-width: 1648px) {
    justify-content: center;
  }

`

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 1648px) {
    justify-content: center;
  }
`

export const Mark = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  ${({ color }) => color && `
    background: ${color};
  `}
`

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.messages.error};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;

`

export const ItemContainer = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  ${Mark} {
    margin-right: 5px;
  }
`
