import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generatePutAdmin } from '@/constants/apiRoutes'
import { DELETE_TENANT_ADMIN, updateSelectedUserType } from '@/store/actions/users'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { errorDeleteAdminTenant } from '@/store/actions/users/administrator/deleteAdminTenant'
import { requestGetTenant } from '@/store/actions/users/tenant/getTenant'
import createNotifications from '@/helpers/notification'
import getFullName from '@/helpers/fullName'

function* deleteAdminTenantSaga({ payload }) {
  const {
    id,
    tenantId,
    selectedAdmin,
  } = payload
  const toast = createNotifications()
  try {
    yield request({
      url: generatePutAdmin(id),
      method: 'delete',
    })
    const fullName = getFullName(
      selectedAdmin.lastName,
      selectedAdmin.firstName,
      selectedAdmin.middleName,
    )
    toast({
      title: 'Успешная операция!',
      description: `Администратор "${fullName}" был успешно удален!`,
    })
    yield put(requestGetAllTenants())
    yield put(requestGetTenant({ id: tenantId, onlyUpdateSelectedTenant: true }))
    yield put(requestGetAllAdminTenants({ id: tenantId }))
    yield put(updateSelectedUserType(null))
  } catch (error) {
    yield put(errorDeleteAdminTenant())
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка удаления! Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_TENANT_ADMIN.REQUEST, deleteAdminTenantSaga)
}
