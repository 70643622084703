import {
  ALL_PERIOD,
  YEAR,
  QUARTER,
  MONTH,
  WEEK,

  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from '@/constants/tyumenOurHome'

export const periodOptions = [
  { value: ALL_PERIOD, title: 'Весь период' },
  { value: YEAR, title: 'Год' },
  { value: QUARTER, title: 'Квартал' },
  { value: MONTH, title: 'Месяц' },
  { value: WEEK, title: 'Неделя' },
]

export const intervalOptions = [
  { value: ANNUALLY, title: 'Ежегодно' },
  { value: MONTHLY, title: 'Ежемесячно' },
  { value: WEEKLY, title: 'Еженедельно' },
  { value: DAILY, title: 'Ежедневно' },
]
