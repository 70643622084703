import styled from 'styled-components'

export const DateInput = styled.div`
  display: flex;
  padding-left: 5px;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  font-size: 1.4rem;
  line-height: 3rem;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.lightGray};
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  color: ${({ theme }) => theme.colors.colors.select};
  ${({ theme, disabled }) => disabled && `
    cursor: not-allowed;
    background: ${theme.colors.backgrounds.disabled};
  `}
  .svgFill,
  .svgStroke {
    fill: ${({ theme }) => theme.colors.colors.default};
  }
`

export const DateInputText = styled.div`
  margin-right: 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.colors.default};
`
