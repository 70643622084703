import { GET_TYUMEN_MESSAGES_LIST } from './index'

export const requestTyumenMessagesList = (payload) => ({
  type: GET_TYUMEN_MESSAGES_LIST.REQUEST,
  payload,
})

export const successTyumenMessagesList = (payload) => ({
  type: GET_TYUMEN_MESSAGES_LIST.SUCCESS,
  payload,
})

export const errorTyumenMessagesList = (payload) => ({
  type: GET_TYUMEN_MESSAGES_LIST.ERROR,
  payload,
})
