import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ActivityLogIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.6425 7.7C16.6425 8.0159 16.59 8.3183 16.4938 8.6H19.25C20.2169 8.6 21 9.4055 21 10.4V21.2C21 22.1945 20.2169 23 19.25 23H8.75C7.78312 23 7 22.1945 7 21.2V10.4C7 9.4055 7.78312 8.6 8.75 8.6H11.5421C11.445 8.3183 11.3925 8.0159 11.3925 7.7C11.3925 6.2087 12.5676 5 14.0175 5C15.4674 5 16.6425 6.2087 16.6425 7.7ZM8.75 10.85C8.75 10.6016 8.946 10.4 9.1875 10.4H10.5175V11.75C10.5175 11.9984 10.7135 12.2 10.955 12.2H17.08C17.3215 12.2 17.5175 11.9984 17.5175 11.75V10.4H18.8125C19.0549 10.4 19.25 10.6016 19.25 10.85V20.75C19.25 20.9984 19.0549 21.2 18.8125 21.2H9.1875C8.946 21.2 8.75 20.9984 8.75 20.75V10.85ZM14.0175 8.6C13.5345 8.6 13.1425 8.1968 13.1425 7.7C13.1425 7.2032 13.5345 6.8 14.0175 6.8C14.5014 6.8 14.8925 7.2032 14.8925 7.7C14.8925 8.1968 14.5014 8.6 14.0175 8.6Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ActivityLogIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ActivityLogIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ActivityLogIcon)
