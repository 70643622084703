import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LightIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.57132 16.2791C8.24242 16.2791 7.97331 16.007 7.97331 15.6745V15.0698H11.5614V15.6745C11.5614 16.007 11.2923 16.2791 10.9633 16.2791H8.57132ZM5.58129 8.41864C5.58129 6.08469 7.45903 4.18608 9.76733 4.18608C12.0756 4.18608 13.9534 6.08469 13.9534 8.41864C13.9534 9.85771 13.2418 11.1214 12.1594 11.8893V13.2558C12.1594 13.5884 11.8903 13.8605 11.5614 13.8605H7.97331C7.64441 13.8605 7.37531 13.5884 7.37531 13.2558V11.8893C6.29292 11.1214 5.58129 9.85771 5.58129 8.41864Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LightIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LightIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LightIcon)
