import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'

export default {
  main: [
    {
      name: 'Наружное освещение',
      selector: 'outdoorLightningCount',
    },
    {
      name: 'Ремонт дорог',
      selector: 'roadRepairingCount',
    },
    {
      name: 'Остановки общ. транспорта',
      selector: 'transportStationCount',
    },
    {
      name: 'Прорыв теплотрасс',
      selector: 'notAvailable1',
      disabled: true,
    },
    {
      name: 'Подтопления',
      selector: 'notAvailable2',
      disabled: true,
    },
  ],
  view: {
    title: <Lang id="widgetsDashboard.tyumenOurHomeBigWidget" />,
    icon: WidgetsDashboardIcon.TyumenOurHomeBigWidgetIcon,
  },
  total: {
    name: <Lang id="widgetsDashboard.totalCalls" />,
    selector: 'totalAppealsAmount',
  },
  solved: {
    name: <Lang id="widgetsDashboard.decisionWasMadeMany" />,
    selector: 'numberProblemSolved',
  },
  graph: {
    totalSelector: 'totalAppealsAmount',
    solvedSelector: 'numberProblemSolved',
  },
}
