import React from 'react'
import { toast } from 'react-toastify'
import Notifications from '@/components/blocks/NotificationSmall'

const createNotifications = () => ({
  type,
  title,
  description,
}) => toast(
  <Notifications
    title={title}
    type={type}
    description={description}
  />,
)

export default createNotifications
