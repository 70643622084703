import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const CircleChartIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9 5V7.73383C17.951 8.17594 20.3 10.7925 20.3 13.9774C20.3 14.7895 20.138 15.5564 19.868 16.2692L22.208 17.6496C22.712 16.5308 23 15.2857 23 13.9774C23 9.30376 19.445 5.45113 14.9 5ZM14 20.2932C10.517 20.2932 7.7 17.4692 7.7 13.9774C7.7 10.7925 10.049 8.17594 13.1 7.73383V5C8.546 5.45113 5 9.29474 5 13.9774C5 18.9579 9.023 23 13.991 23C16.97 23 19.607 21.5474 21.245 19.3098L18.905 17.9293C18.3179 18.6683 17.5718 19.2648 16.7224 19.6741C15.873 20.0834 14.9424 20.2951 14 20.2932Z" fill={fill} className="svgFill" />
    </svg>
  )
}

CircleChartIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CircleChartIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(CircleChartIcon)
