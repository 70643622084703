import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const PopupGeoZoneIcon = ({ onClick }) => (
  <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <rect width="50" height="50" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0" transform="scale(0.02)" />
      </pattern>
      <image id="image0" width="50" height="50" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAERElEQVRogd1aK7SjMBDdcyKRSGRkJRKJRCKRkchKZCQSiURWIiMjkchIJDISMSv2TEpbvgG6b1eMKb/czJ07n/TXMAzwP9ivqz+gtQat9b8HpK5rYIzB7XYDQsiL3W43YIxBXdc/F0hRFOB53sfi58zzPMjz/OcAUUp97H4cx1CWJTRNA0op6LoOpJRQliXEcQyO47x4SSn1d4EIIV4WlWXZpnjQWkOWZeY5x3FACPF3gEgpDQjbXX33ppTyu0C6rgPXdYEQAr7vH1IlrTX4vg+EEHBdF7qu+x6QKIpMwPZ9f4gSwzBA3/dGKKIo+g4QKaWhwhKvlVLAOYckSSBJEuCcL9Jv63tPAxLH8erOMcZmZZcxNktF9LSNV3YB6ft+ddfCMDT3hGEInHPgnH/8vuaVvZTdBaSuaxOUU9fzPDcLmcredV0bpZtLhigij8fjOiCo/XOux4AtimL2HUVRLG4G0ivLsuuAIPfv9/vHtbZtTXJbk2P0Stu2H9fu9zsQQiBJkuuAYKBP7ZYQwiTHtfdgEpyiH+fcKuB3AUmSZNYjTdMsUmZsGAdN08x6hDF2HZC1jyBllsp09BwhZJKCaZoCIQTSNL0OCKrSnHziIjzPmyw1lFJGEOYWGgTBoqqdAgR3c44+fd8DpdQEPeccpJQgpQTOuaEUpXQ2T+A9e7O7dUKcKze6rpvsDrf0H0qp7yTEYXgqTlmWi/dVVQVhGAKlFCilEEXR6jOYY7Yo32EgGAdxHO/+2JphMtwb6FZAME62JL49NqatTYNl1Y9gQFZVdRqQsiw356HTgGCpMifDNoZdog2trIFgFl9Srz2Gddpc/XUZkGF4qpftDo4NSx/f963fYQ0EOe04zqG+ves64429PcgpQIbhGfScc+t3jMuaI2s5BARrL1spHntjqRm7HIjW+pBXUP2OeuMwkHev7ImVcV111BunABmGZ6zsUTDsNimlh79/GhBUsK15YDz2OaJUpwMZhmdmDoJg9V7MQVvu/QqQpmng8XiAEGLzLmOpjt4TQkBd19bDaysgOM8NguDlXARLFczQnudNynHf9+a5NE1fSh18LkkSK7ptAiKEML302BzHgSAIIE1TqKrqZaFTAwoE6rouaK2hLEtgjBlavoNaa8R2AcHJyXhuWxTFbFBXVTXZV+C4da7811qbg9Sxt7c2cItAxnxOkmQzj3FgTSk1x9N7zz/GR3NbxqeLQPDje1w8DH9KjzHFkFKO4+wK6rWh+eVA3im2RKmvABlTK47j3U0UZu+9w4r3U98tdZxVsOd5Pjm3fTcc2C0N5MaLvyzY0aSUi/LLGIM8z6GqKhBCQNu2oJSCvu9fTCllkmBVVeaMcWqgd7r8jg0TYhiGHwnxDPM8z/q/KodKlLZt4fF4AOccGGMQRREEQQCUUnBd9wWs4zjgui5QSsH3fYiiCNI0hTzPv1+i/GT7b4D8BuIovwjmOtc+AAAAAElFTkSuQmCC" />
    </defs>
  </svg>
)

PopupGeoZoneIcon.propTypes = {
  onClick: pt.func,
}

PopupGeoZoneIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(PopupGeoZoneIcon)
