import styled from 'styled-components'

export const BaseLayoutStyled = styled.div`
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const LayoutContentWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

export const ContentWithoutSidebars = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.sizes.headerHeight}px);
  overflow: auto;
  flex-grow: 1;
`
