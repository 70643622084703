import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'

export default {
  form: {
    graphView: 'Graph View:',
    buildGraph: 'build graph',
    buildTable: 'build table',
    updateGraph: 'update graph',
    updateTable: 'update table',
    interval: 'manual interval',
    fastInterval: 'fast interval',
    dateRange: 'Date range',
    demonstration: 'Demonstration:',
    savings: 'Consumption kWh:',
    consumption: 'Consumption kWh:',
    min: 'Min:',
    average: 'Average:',
    max: 'Max:',
    errorTitle: 'Unable to plot against the selected format. Change the range or select a different interval:',
    errorText: `Monthly: not more than 366 days
Weekly: not more than 92 days.
Daily: not more than 31 days.`,
    calculator: 'Cost Calculator:',
    expenseForPeriod: 'Expense for the period',
    positiveEffect: 'Effect per period (savings)',
    negativeEffect: 'Effect per period (overrun)',
    units: {
      [OUTDOOR_LIGHTING]: 'KWh',
      [HEAT_SUPPLY]: 'Gcal',
    },
    rate: 'Rate',
    total: 'Total:',
    savingValue: 'RUB',
    processType: 'City process type',
    light: {
      saving: 'Savings:',
      overPayment: 'Overpayment',
      lose: 'Overrun:',
    },
    heat: {
      saving: 'Underheating:',
      lose: 'Overheating:',
      economy: 'Economy:',
      overpay: 'Overpay:',
    },
  },
}