import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  min-width: 310px;
  max-width: 310px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const ContentContainer = styled.div`
 width: 100%;
 height: calc(100% - 48px);
 overflow-x: hidden;
 overflow-y: scroll;
`

export const Header = styled.div`
  padding: 15px 10px;
  width: 100%;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  && > div:last-child {
    justify-self: flex-end;
  }
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`
export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  .svgFill { 
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  cursor: pointer;
  ${({ arrow, theme }) => arrow && `
    border-right: 1px solid ${theme.colors.borders.default}
    width: auto;
    padding-right: 5px;
    margin-right: 10px;
    svg {
      width: 18px;
    }
    .svgFill {
      height: 14px;
      width: 14px;

    }
  `}
  ${({ pr }) => pr && `
    margin-right: ${pr}px;
  `}

`
export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  flex: 1;
`
export const PrimaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
`
export const SecondaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  white-space: pre-wrap;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${SecondaryTitle}:nth-child(2) {
    flex: 1;
    text-align: left;
    padding-left: 10px;
  }
  ${({ stack }) => stack && `
    ${SecondaryTitle} {
      flex: 1;
      width: 100%;
      text-align: left;
      padding-left: 0px;
    }
    ${SecondaryTitle}:not(:first-child) {
      margin-top:: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding-left: 0px;
    }
    ${SecondaryTitle}:not(:last-child) {
      margin-bottom: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding-left: 0px;
    }
    flex-direction: column;
    padding: 10px 0px;

  `}
`
export const InfoBlock = styled.div`
  padding: 15px 10px;
  padding-right: 15px;
  ${PrimaryTitle} {
    margin-bottom: 30px;
  }
  ${Row}:not(:first-child) {
    margin-top: 5px;
  }
  ${Row}:not(:last-child) {
    margin-bottom: 5px;
  }

`

export const PassportContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 96px);
`

export const LoaderContainer = styled.div`
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`
