import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InvertMarkIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return ( 
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6.58824C8.26667 6.58824 7.66667 7.17059 7.66667 7.88235C7.66667 8.22557 7.80714 8.55474 8.05719 8.79743C8.30724 9.04013 8.64638 9.17647 9 9.17647C9.35362 9.17647 9.69276 9.04013 9.94281 8.79743C10.1929 8.55474 10.3333 8.22557 10.3333 7.88235C10.3333 7.17059 9.73333 6.58824 9 6.58824Z" fill={fill} className="svgFill"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 4C11.2067 4 13 5.74059 13 7.88235C13 10.7941 9 15 9 15C9 15 5 10.7941 5 7.88235C5 5.74059 6.79333 4 9 4Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

InvertMarkIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InvertMarkIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InvertMarkIcon)
