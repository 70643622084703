import {
  SHUO,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
  BASE_STATION,
  CONTROLLER,
  MULTIRATE_METER,
  LINE,
  SUPPORT,
  LIGHT_MODULE,
  LIGHT,
} from '@/constants/objectTypes'
import { PROCESSES_BY_ID } from '@/constants/widgets'
import {
  KULON,
  BRIZ,
  MESH,
  ATM,
} from '@/constants/integrations'

export const typesByASU = {
  [KULON]: [
    SHUO,
    BASE_STATION,
    CONTROLLER,
    MULTIRATE_METER,
    LINE,
    SUPPORT,
    LIGHT,
    LIGHT_MODULE,
  ],
  [BRIZ]: [
    SHUO,
    BASE_STATION,
    CONTROLLER,
    MULTIRATE_METER,
    LINE,
    SUPPORT,
    LIGHT,
    LIGHT_MODULE,
  ],
  [MESH]: [
    SHUO,
    BASE_STATION,
    CONTROLLER,
    MULTIRATE_METER,
    LINE,
    SUPPORT,
    LIGHT,
    LIGHT_MODULE,
  ],
  [ATM]: [
    HEATING_DEVICE,
    COLD_WATER_DEVICE,
  ],
}

export const processesByASU = {
  [KULON]: [
    PROCESSES_BY_ID.LIGHT,
  ],
  [BRIZ]: [
    PROCESSES_BY_ID.LIGHT,
  ],
  [MESH]: [
    PROCESSES_BY_ID.LIGHT,
  ],
  [ATM]: [
    PROCESSES_BY_ID.HEAT_SUPPLY,
    PROCESSES_BY_ID.WATER_SUPPLY,
  ],
}

export const objectsTypes = [
  {
    title: 'ШУО',
    value: SHUO,
  },
  {
    title: 'Базовая станция',
    value: BASE_STATION,
  },
  {
    title: 'Контроллер',
    value: CONTROLLER,
  },
  {
    title: 'Многофункц. счетчик',
    value: MULTIRATE_METER,
  },
  {
    title: 'Линия',
    value: LINE,
  },
  {
    title: 'Опора',
    value: SUPPORT,
  },
  {
    title: 'Светильник',
    value: LIGHT,
  },
  {
    title: 'Модуль светильника',
    value: LIGHT_MODULE,
  },
  {
    title: 'Узел учета ЦО',
    value: HEATING_DEVICE,
  },
  {
    title: 'Узел учета ХВС',
    value: COLD_WATER_DEVICE,
  },
]

export default objectsTypes
