import createIntegrationSubscriptionChannel from '@/store/sagas/createIntegrationSubscriptionChannel'
import createSubscriptionChannel from '@/store/sagas/createSubscriptionChannel'

import {
  TOPIC_CREATION_NOTIFICATIONS,
  generateUpdateIntegrationTopic,
  generateRepeatIntegrationTopic,
  generateDeleteIntegrationTopic,
  generateVerifyNotificationTopic,
} from '@/constants/socket/events'

export const createIntegrationsChannel = () => (
  createSubscriptionChannel(TOPIC_CREATION_NOTIFICATIONS)
)
export const updateIntegrationsChannel = () => (
  createIntegrationSubscriptionChannel(generateUpdateIntegrationTopic)
)
export const deleteIntegrationsChannel = () => (
  createIntegrationSubscriptionChannel(generateDeleteIntegrationTopic)
)
export const repeatIntegrationsChannel = () => (
  createIntegrationSubscriptionChannel(generateRepeatIntegrationTopic)
)
export const verifyNotificationChannel = () => (
  createIntegrationSubscriptionChannel(generateVerifyNotificationTopic)
)
