import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  TENANT,
  ADMIN,
  USER,
  GROUP,
} from '@/constants/viewTree'

export const superAdminOption = [
  { value: TENANT, title: <Lang id="usersPage.formHeader.addTenant" /> },
  { value: ADMIN, title: <Lang id="usersPage.formHeader.addAdmin" /> },
]

export const adminOption = [
  { value: GROUP, title: <Lang id="usersPage.formHeader.addGroup" /> },
  { value: USER, title: <Lang id="usersPage.formHeader.addUser" /> },
]
