import isArray from 'lodash/isArray'
import {
  OK, INFO, ERROR, WARNING,
} from '@/constants/objectStatuses'

import {
  CONTROLLER,
  SHUO,
  CONTROL_CUPBOARD_SERVER,
  BASE_STATION_SERVER,
  LIGHT,
  GROUP_OBJECT,
  COLD_WATER_DEVICE,
  HEATING_DEVICE,
  TRASH,
  LIGHT_SENSOR,

  FUSE,
  PHASE,
  CONTACTOR,
  CHECK_POINT,
  ELECTRIC_METER,
  CURRENT_SENSOR,
  UNICERSE_METER,
  DMX_DEVICE,
} from '@/constants/objectTypes'

const MAP_CONTROLLER_TELEMTRY = {
  OK,
  WARNING,
  OFF: INFO,
  UNDEFINED: INFO,
  NO_CONNECTION: ERROR,
}

export const mapLightTelemetry = (eventData) => {
  const {
    address: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  const statusValue = telemetry.state
  const enableState = telemetry.turnedOn

  const statusEnabled = enableState ? OK : INFO
  const status = statusValue === OK ? statusEnabled : statusValue

  telemetry.status = status

  return { [`${LIGHT}-${aliasId}-${id}`]: telemetry }
}

export const mapControllerTelemetry = (eventData) => {
  const typeByLocalType = {
    [CONTROL_CUPBOARD_SERVER]: SHUO,
    [BASE_STATION_SERVER]: CONTROLLER,
  }
  const {
    objectId: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  telemetry.status = MAP_CONTROLLER_TELEMTRY[telemetry.state]

  return {
    [`${typeByLocalType[eventData.header.objectIdentifier.type]}-${aliasId}-${id}`]: telemetry,
  }
}

export const mapATMTelemetry = (eventData, type, idSelector) => {
  const {
    [idSelector]: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  const status = Object.keys(telemetry).length > 1 ? OK : INFO
  telemetry.status = status

  return {
    [`${type}-${aliasId}-${id}`]: telemetry,
  }
}

export const mapMeshControllerTelemetry = (eventData) => eventData.controlCabinets
  .reduce((accumulator, element) => {
    const data = {
      ...element,
      status: MAP_CONTROLLER_TELEMTRY[element.status] || INFO,
      state: MAP_CONTROLLER_TELEMTRY[element.status] || INFO,
      type: CONTROLLER,
      timestamp: new Date(),
    }
    return {
      ...accumulator,
      [`${CONTROLLER}-${eventData.id}-${element.id}`]: data,
    }
  }, {})

export const mapMeshLightTelemetry = (eventData) => eventData.streetLights
  .reduce((accumulator, element) => {
    const statusValue = MAP_CONTROLLER_TELEMTRY[element.status] || INFO
    const turnedOn = element.powerState === 'ON' || false
    const statusEnabled = turnedOn ? OK : INFO
    const status = statusValue === OK ? statusEnabled : statusValue
    const data = {
      ...element,
      status,
      turnedOn,
      state: statusValue,
      totalPower: element.activePower,
      timestamp: new Date(),
      type: LIGHT,
    }
    return {
      ...accumulator,
      [`${LIGHT}-${eventData.id}-${element.id}`]: data,
    }
  }, {})

export const mapMeshTrashTelemetry = (eventData) => eventData.wasteContainers
  .reduce((accumulator, element) => {
    const data = {
      ...element,
      status: MAP_CONTROLLER_TELEMTRY[element.status] || INFO,
      state: MAP_CONTROLLER_TELEMTRY[element.status] || INFO,
      timestamp: new Date(),
      type: TRASH,
    }
    return {
      ...accumulator,
      [`${TRASH}-${eventData.id}-${element.id}`]: data,
    }
  }, {})

export const mapMeshLightSensorTelemetry = (eventData) => eventData.ambientLightSensors
  .reduce((accumulator, element) => {
    const data = {
      ...element,
      status: MAP_CONTROLLER_TELEMTRY[element.status] || INFO,
      state: MAP_CONTROLLER_TELEMTRY[element.status] || INFO,
      timestamp: new Date(),
      type: LIGHT_SENSOR,
    }
    return {
      ...accumulator,
      [`${LIGHT_SENSOR}-${eventData.id}-${element.id}`]: data,
    }
  }, {})

export const mapMeshTelemetry = (eventData) => {
  switch (eventData.type) {
    case 'STREET_LIGHT_CONTROL_CABINET': {
      return mapMeshControllerTelemetry(eventData)
    }
    case 'STREET_LIGHT': {
      return mapMeshLightTelemetry(eventData)
    }
    case 'WASTE_CONTAINER': {
      return mapMeshTrashTelemetry(eventData)
    }
    case 'AMBIENT_LIGHT_SENSOR': {
      return mapMeshLightSensorTelemetry(eventData)
    }
    default: {
      return {}
    }
  }
}

export const mapColdWaterTelemetry = (eventData) => mapATMTelemetry(
  eventData,
  COLD_WATER_DEVICE,
  'deviceId',
)
export const mapHeatTelemetry = (eventData) => mapATMTelemetry(
  eventData,
  HEATING_DEVICE,
  'deviceId',
)
export const mapGroupObjectTelemetry = (eventData) => mapATMTelemetry(
  eventData,
  GROUP_OBJECT,
  'objectId',
)

const getTelemetryWithStatus = (item) => ({
  ...item,
  timestamp: new Date(),
  status: (item.active || item.isOn) ? OK : INFO,
})

const getTelemetryForParent = (parent) => Object.keys(parent)
  .reduce((telemetryAccumulator, field) => {
    if (!isArray(parent[field])) {
      return {
        ...telemetryAccumulator,
        [field]: parent[field],
      }
    }
    return telemetryAccumulator
  }, {})

const getTelemetryFromArray = (array = [], aliasId, type) => array
  .reduce((accumulator, item) => ({
    ...accumulator,
    [`${type || item.type}-${aliasId}-${item.brizApiId}`]: getTelemetryWithStatus(item),
  }), {})

const mapBranchCircuitTelemetry = (branchCircuits, aliasId) => branchCircuits
  .reduce((accumulator, branchCircuit) => ({
    ...accumulator,
    [`${FUSE}-${aliasId}-${branchCircuit.fuseTelemetry.brizApiId}`]: getTelemetryWithStatus(
      branchCircuit.fuseTelemetry,
    ),
    [`${CURRENT_SENSOR}-${aliasId}-${branchCircuit.currentSensorTelemetry.brizApiId}`]: getTelemetryWithStatus(
      branchCircuit.currentSensorTelemetry,
    ),
  }), {})

const mapContactorsTelemetry = (contactors, aliasId) => contactors
  .reduce((accumulator, contactor) => {
    const contactorTelemetry = getTelemetryForParent(contactor)
    return {
      ...accumulator,
      ...getTelemetryFromArray(contactor.checkPointsTelemetry, aliasId, CHECK_POINT),
      ...mapBranchCircuitTelemetry(contactor.branchCircuitsTelemetry, aliasId),
      [`${CONTACTOR}-${aliasId}-${contactorTelemetry.brizApiId}`]: getTelemetryWithStatus(contactorTelemetry),
    }
  }, {})

export const mapBrizTelemetry = (eventData) => {
  const { payload: { controllersTelemetry }, aliasId } = eventData

  const telemetry = controllersTelemetry.reduce((accumulatorControllers, controller) => {
    const controllerTelemetry = getTelemetryForParent(controller)
    const result = {
      ...accumulatorControllers,
      ...mapBranchCircuitTelemetry(controller.branchCircuitsTelemetry, aliasId),
      ...mapContactorsTelemetry(controller.contactorsTelemetry, aliasId),
      ...getTelemetryFromArray(controller.lampsTelemetry, aliasId, LIGHT),
      ...getTelemetryFromArray(controller.phasesTelemetry, aliasId, PHASE),
      ...getTelemetryFromArray(controller.electricMetersTelemetry, aliasId, ELECTRIC_METER),
      ...getTelemetryFromArray(controller.sensorsTelemetry, aliasId, UNICERSE_METER),
      [`${CONTROLLER}-${aliasId}-${controllerTelemetry.brizApiId}`]: getTelemetryWithStatus(controllerTelemetry),
    }

    if (controllerTelemetry.dmxDeviceTelemetry) {
      result[`${DMX_DEVICE}-${aliasId}-${controllerTelemetry.dmxDeviceTelemetry.brizApiId}`] = getTelemetryWithStatus(controllerTelemetry.dmxDeviceTelemetry)
    }

    return result
  }, {})
  return telemetry
}