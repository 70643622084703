import React, { useEffect } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useReduxTable from '@/hooks/useReduxTable'
import { FAULT_ANALYTICS_TABLE } from '@/store/actions/faultAnalytics'
import { getTableParameters } from '@/store/selectors/faultAnalytics'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/faultAnalytics'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'

const FaultAnalytics = ({
  data,
  exportFileStatus,
  loading,
  selectedNode,
  setSelectedElement,
  requestSummaryAlarmAnalyticsTable,
  requestSummaryAlarmAnalyticsFile,
}) => {
  useEffect(() => {
    requestSummaryAlarmAnalyticsTable()
  }, [requestSummaryAlarmAnalyticsTable, selectedNode])

  const tableProps = useReduxTable(FAULT_ANALYTICS_TABLE, getTableParameters)
  return loading
    ? <Loader center />
    : (
      <DutyTable
        {...tableProps}
        fields={fields}
        exportOptions={exportOptions}
        data={data}
        rowSelector={setSelectedElement}
        onFileSelect={requestSummaryAlarmAnalyticsFile}
        onUpdateData={requestSummaryAlarmAnalyticsTable}
        fileStatus={exportFileStatus}
      />
    )
}

FaultAnalytics.propTypes = {
  data: pt.arrayOf(pt.object),
  exportFileStatus: pt.string,
  loading: pt.bool,
  selectedNode: pt.objectOf(pt.object),
  updateTableSorting: pt.func,
  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestSummaryAlarmAnalyticsTable: pt.func,
  requestSummaryAlarmAnalyticsFile: pt.func,
}
FaultAnalytics.defaultProps = {
  data: [],
  exportFileStatus: '',
  loading: false,
  selectedNode: {},
  setSelectedElement: noop,
  updateTableSorting: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestSummaryAlarmAnalyticsTable: noop,
  requestSummaryAlarmAnalyticsFile: noop,
}

export default FaultAnalytics
