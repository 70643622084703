import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EcoStationsIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="59.821" height="48.382" viewBox="0 0 59.821 48.382">
      <g id="Group_401" data-name="Group 401" transform="translate(-1004.212 -698)">
        <g id="Group_61" data-name="Group 61" transform="translate(496.967 -22.268)">
          <g id="Path_44" data-name="Path 44" transform="translate(537 734)" fill="none">
            <path d="M15.033-13.23c-.073,0,15.033,18.43,15.033,26.733A15.033,15.033,0,0,1,0,13.5C0,5.2,15.106-13.23,15.033-13.23Z" stroke="none" />
            <path d="M 15.0333251953125 -8.313507080078125 C 13.39463806152344 -6.145940780639648 11.13699531555176 -3.051523208618164 8.989904403686523 0.2895545959472656 C 7.149904251098633 3.15278434753418 5.703014373779297 5.715804100036621 4.689445495605469 7.907444477081299 C 3.293094635009766 10.92672443389893 2.999994277954102 12.61669445037842 2.999994277954102 13.50274467468262 C 2.999994277954102 16.71696472167969 4.251674652099609 19.73879432678223 6.52447509765625 22.01159477233887 C 8.797275543212891 24.28439331054688 11.81910514831543 25.53607559204102 15.0333251953125 25.53607559204102 C 18.24753570556641 25.53607559204102 21.26937484741211 24.28439331054688 23.54216575622559 22.01159477233887 C 25.81496429443359 19.73879432678223 27.0666446685791 16.71696472167969 27.0666446685791 13.50274467468262 C 27.0666446685791 12.61669445037842 26.77354431152344 10.92672443389893 25.37720489501953 7.907444477081299 C 24.36362457275391 5.715804100036621 22.91673469543457 3.152774810791016 21.07673645019531 0.2895545959472656 C 18.92964553833008 -3.051523208618164 16.6720085144043 -6.145936965942383 15.0333251953125 -8.313507080078125 M 15.0333251953125 -13.23034477233887 C 15.03367519378662 -13.23034477233887 15.03367710113525 -13.22992897033691 15.0333251953125 -13.22907447814941 C 15.03297328948975 -13.22992897033691 15.03297519683838 -13.23034477233887 15.0333251953125 -13.23034477233887 Z M 15.0333251953125 -13.22907447814941 C 15.10601425170898 -13.05285835266113 30.0666446685791 5.240108489990234 30.0666446685791 13.50274467468262 C 30.0666446685791 21.80542373657227 23.33599472045898 28.53607559204102 15.0333251953125 28.53607559204102 C 6.730646133422852 28.53607559204102 -5.7220458984375e-06 21.80542373657227 -5.7220458984375e-06 13.50274467468262 C -5.7220458984375e-06 5.240108489990234 14.96063613891602 -13.05285835266113 15.0333251953125 -13.22907447814941 Z" stroke="none" fill={fill} className="svgFill" />
          </g>
          <line id="Line_46" data-name="Line 46" y2="34" transform="translate(552.533 732.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_47" data-name="Line 47" x2="6" y2="7" transform="translate(546.533 739.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_48" data-name="Line 48" x1="6" y2="7" transform="translate(552.533 739.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_49" data-name="Line 49" x1="9" y2="6" transform="translate(552.533 744.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_50" data-name="Line 50" x1="9" y1="6" transform="translate(543.533 744.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_51" data-name="Line 51" x1="9" y1="4" transform="translate(543.533 750.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_52" data-name="Line 52" y1="4" x2="9" transform="translate(552.533 750.768)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeWidth="2" />
        </g>
        <path id="Path_47" data-name="Path 47" d="M3026.192-273.389h-8.75V-289.4l12.381-8.75,12.381,8.75v16.011h-8.75V-284h-7.261Z" transform="translate(-2011.729 1018.271)" fill="none" stroke={stroke} className="svgStroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
        <circle id="Ellipse_32" data-name="Ellipse 32" cx="7" cy="7" r="7" transform="translate(1018 698)" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

EcoStationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EcoStationsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EcoStationsIcon)
