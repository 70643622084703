import { CHECK_ALL_ALARMS } from './index'

export const requestCheckAllAlarms = (payload) => ({
  type: CHECK_ALL_ALARMS.REQUEST,
  payload,
})

export const successCheckAllAlarms = (payload) => ({
  type: CHECK_ALL_ALARMS.SUCCESS,
  payload,
})

export const errorCheckAllAlarms = (payload) => ({
  type: CHECK_ALL_ALARMS.ERROR,
  payload,
})
