import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AttentionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M25 46.16C36.6863 46.16 46.16 36.6863 46.16 25C46.16 13.3137 36.6863 3.84 25 3.84C13.3137 3.84 3.84 13.3137 3.84 25C3.84 36.6863 13.3137 46.16 25 46.16ZM25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48Z" fill={fill} className="svgFill"/>
      <path d="M23 16C23 14.8954 23.8954 14 25 14C26.1046 14 27 14.8954 27 16V27C27 28.1046 26.1046 29 25 29C23.8954 29 23 28.1046 23 27V16Z" fill={fill} className="svgFill"/>
      <path d="M23 33C23 31.8954 23.8954 31 25 31C26.1046 31 27 31.8954 27 33C27 34.1046 26.1046 35 25 35C23.8954 35 23 34.1046 23 33Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

AttentionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AttentionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AttentionIcon)
