import { connect } from 'react-redux'
import {
  getLanguageState,
} from '@/store/selectors/appSettings'
import IntlProviderWrapper from './component'

const mapStateToProps = (state) => ({
  language: getLanguageState(state),
})

export default connect(mapStateToProps)(IntlProviderWrapper)
