import {
  takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_REPORT_MANAGER_USERS,
} from '@/store/actions/reportManager'
import { REPORT_MANAGER_COMMON_USERS_URL } from '@/constants/apiRoutes'
import {
  successReportManagerUsers,
  errorReportManagerUsers,
} from '@/store/actions/reportManager/getCommonUsers'
import getFullName from '@/helpers/fullName'

function* getReportManagerUsersSaga() {
  try {
    const response = yield request({
      url: REPORT_MANAGER_COMMON_USERS_URL,
      method: 'get',
    })
    const sortedResponse = response.data.map((user) => ({
      value: user.id,
      title: getFullName(user.firstName, user.lastName),
      ...user,
    }))
    yield put(successReportManagerUsers(sortedResponse))
  } catch (error) {
    yield put(errorReportManagerUsers(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_REPORT_MANAGER_USERS.REQUEST, getReportManagerUsersSaga)
}
