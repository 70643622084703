import { GET_FAULT_ANALYTICS_TABLE } from './index'

export const requestSummaryAlarmAnalyticsTable = (payload) => ({
  type: GET_FAULT_ANALYTICS_TABLE.REQUEST,
  payload,
})

export const successSummaryAlarmAnalyticsTable = (payload) => ({
  type: GET_FAULT_ANALYTICS_TABLE.SUCCESS,
  payload,
})

export const errorSummaryAlarmAnalyticsTable = (payload) => ({
  type: GET_FAULT_ANALYTICS_TABLE.ERROR,
  payload,
})
