import React from 'react'

import {
  OK,
  STATUS_COLORS,
} from '@/constants/alarms'
import {
  CONNECTED_DEVICES,
  LIGHTS_STATE,
} from '@/constants/widgetsDashboardGov'

import ProgressLine from '../../components/ProgressLine'

import { Row, Column, Title } from './styles'

const OK_DATA = [
  {
    count: 47343,
    doneCount: 37,
    dynamic: 1,
  },
  {
    count: 37,
    doneCount: 37,
    dynamic: -2,
  },
]
const LEFT_DATA = [
  {
    count: 0,
    doneCount: 0,
    dynamic: 0,
  },
  {
    count: 0,
    doneCount: 0,
    dynamic: 0,
  },
]

const Widget = ({ onWidgetClick }) => (
  <Row>
    <Title>Гор. процесс</Title>
    <Title>Подключено</Title>
    <Title>Исправно</Title>
    <Column>
      <i className="fa fa-lightbulb-o fa-3" aria-hidden="true" />
      <span>Наружное освещение</span>
    </Column>
    {OK_DATA.map((data, index) => (
      <Column>
        <ProgressLine
          onClick={onWidgetClick(index === 0 ? CONNECTED_DEVICES : LIGHTS_STATE)}
          color={STATUS_COLORS[OK]}
          {...data}
        />
      </Column>
    ))}
    <Column disabled>
      <i className="fa fa-university fa-3" aria-hidden="true" />
      <span>Архитектурное освещение</span>
    </Column>
    {LEFT_DATA.map((data, index) => (
      <Column>
        <ProgressLine
          onClick={onWidgetClick(index === 0 ? CONNECTED_DEVICES : LIGHTS_STATE)}
          color={STATUS_COLORS[OK]}
          {...data}
        />
      </Column>
    ))}
  </Row>
)

export default Widget
