import {
  put, takeEvery,
} from 'redux-saga/effects'
import moment from 'moment'
import qs from 'query-string'
import request from '@/helpers/axios'
import get from 'lodash/get'
import {
  GET_GRAPH_DATA,
} from '@/store/actions/widgetDashboard'
import {
  getGraphDataSuccess,
  getGraphDataError,
} from '@/store/actions/widgetDashboard/graphData'
import { isZone } from '@/helpers/maps/isDefiniteElement'
import {
  HEAT_DASHBOARD_GRAPH,
  LIGHT_DASHBOARD_GRAPH,
  UNKNOWN_DASHBOARD_ROUT,
} from '@/constants/apiRoutes'
import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'

const routByType = {
  [HEAT_SUPPLY]: HEAT_DASHBOARD_GRAPH,
  [OUTDOOR_LIGHTING]: LIGHT_DASHBOARD_GRAPH,
}

function* getGraphDataByType({ payload }) {
  const { data, type } = payload
  const {
    endDate, startDate, interval, selectedNode,
  } = data
  const todayDate = new Date()
  const url = routByType[type] || UNKNOWN_DASHBOARD_ROUT
  const queryParams = qs.stringify({
    installationId: (selectedNode && selectedNode.internalId) ? selectedNode.internalId : null,
    objectId: (selectedNode && !isZone(selectedNode)) ? selectedNode.id : null,
    startDate: moment(startDate || '12/01/2018').format('DD.MM.YYYY'),
    endDate: moment(endDate || todayDate).format('DD.MM.YYYY'),
    type: interval || 'MONTHLY',
  })
  try {
    const response = yield request({
      url: `${url}?${queryParams}`,
      method: 'GET',
    })
    yield put(getGraphDataSuccess({
      data: get(response, 'data.content', []),
      type,
    }))
  } catch (error) {
    yield put(getGraphDataError({
      data: [],
      type,
    }))
    console.log('TCL: function*objectStateToGeneralPage -> error', error)
  }
  return null
}

export default function* () {
  yield takeEvery(GET_GRAPH_DATA.REQUEST, getGraphDataByType)
}
