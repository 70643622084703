export default [
  {
    id: 2100,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '44.1',
    status: 'info',
    location: [
      56.03276020684353,
      35.9536971572991,
    ],
    path: 'children[0].children[1].children[0].children[0]',
  },
  {
    id: 2101,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '44.1',
    status: 'info',
    location: [
      56.03269412963706,
      35.95399488249975,
    ],
    path: 'children[0].children[1].children[0].children[1]',
  },
  {
    id: 2102,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '44.1',
    status: 'info',
    location: [
      56.03257248720993,
      35.954324794208574,
    ],
    path: 'children[0].children[1].children[0].children[2]',
  },
  {
    id: 2103,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '44.2',
    status: 'info',
    location: [
      56.032883742022285,
      35.953710499074006,
    ],
    path: 'children[0].children[1].children[0].children[3]',
  },
  {
    id: 2104,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '44.456',
    status: 'info',
    location: [
      56.032829679035565,
      35.95382583406165,
    ],
    path: 'children[0].children[1].children[0].children[4]',
  },
  {
    id: 2105,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '4234.3',
    status: 'info',
    location: [
      56.03269301948006,
      35.954370322491684,
    ],
    path: 'children[0].children[1].children[0].children[5]',
  },
  {
    id: 2106,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '455.98',
    status: 'info',
    location: [
      56.03245273776476,
      35.954241576458976,
    ],
    path: 'children[0].children[1].children[0].children[6]',
  },
  {
    id: 2107,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '45.7456',
    status: 'info',
    location: [
      56.032320582181086,
      35.95415038135245,
    ],
    path: 'children[0].children[1].children[0].children[7]',
  },
  {
    id: 2108,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '456.478',
    status: 'info',
    location: [
      56.0320097144144,
      35.95390093591407,
    ],
    path: 'children[0].children[1].children[0].children[8]',
  },
  {
    id: 2109,
    geoZoneId: 86,
    parentId: 186,
    type: 'light',
    name: '4569.478',
    status: 'info',
    location: [
      56.03221545809688,
      35.954056504036934,
    ],
    path: 'children[0].children[1].children[0].children[9]',
  },
  {
    id: 1601,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2708',
    status: 'info',
    location: [
      55.432533264160156,
      65.32170104980469,
    ],
    path: 'children[1].children[0].children[0].children[0]',
  },
  {
    id: 1602,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2716',
    status: 'info',
    location: [
      55.43277359008789,
      65.32218933105469,
    ],
    path: 'children[1].children[0].children[0].children[1]',
  },
  {
    id: 1603,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2727',
    status: 'info',
    location: [
      55.4329833984375,
      65.3226547241211,
    ],
    path: 'children[1].children[0].children[0].children[2]',
  },
  {
    id: 1604,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2749',
    status: 'info',
    location: [
      55.43325424194336,
      65.32331085205078,
    ],
    path: 'children[1].children[0].children[0].children[3]',
  },
  {
    id: 1605,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2799',
    status: 'info',
    location: [
      55.433414459228516,
      65.32633972167969,
    ],
    path: 'children[1].children[0].children[0].children[4]',
  },
  {
    id: 1606,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2800',
    status: 'info',
    location: [
      55.433353424072266,
      65.32574462890625,
    ],
    path: 'children[1].children[0].children[0].children[5]',
  },
  {
    id: 1607,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2891',
    status: 'info',
    location: [
      55.43327713012695,
      65.32512664794922,
    ],
    path: 'children[1].children[0].children[0].children[6]',
  },
  {
    id: 1608,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2921',
    status: 'info',
    location: [
      55.43311309814453,
      65.32368469238281,
    ],
    path: 'children[1].children[0].children[0].children[7]',
  },
  {
    id: 1609,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2931',
    status: 'info',
    location: [
      55.43320083618164,
      65.32441711425781,
    ],
    path: 'children[1].children[0].children[0].children[8]',
  },
  {
    id: 1610,
    geoZoneId: 48,
    parentId: 160,
    type: 'light',
    name: '2940',
    status: 'info',
    location: [
      55.433494567871094,
      65.32378387451172,
    ],
    path: 'children[1].children[0].children[0].children[9]',
  },
  {
    id: 2888,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '10.1',
    status: 'info',
    location: [
      57.10617446899414,
      65.57404327392578,
    ],
    path: 'children[1].children[1].children[1].children[0]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:22.105361Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2970,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '10.2',
    status: 'info',
    location: [
      57.106231689453125,
      65.57412719726562,
    ],
    path: 'children[1].children[1].children[1].children[1]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:33.338054Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2471,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '11.1',
    status: 'ok',
    location: [
      57.10637664794922,
      65.57429504394531,
    ],
    path: 'children[1].children[1].children[1].children[2]',
    telemetry: {
      amperage: 87922,
      timestamp: '2019-10-15T13:58:31.306076Z',
      totalPower: 56.21,
      turnedOn: true,
      voltage: 224.83,
      workTime: 1806,
    },
  },
  {
    id: 2812,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '11.2',
    status: 'ok',
    location: [
      57.10653305053711,
      65.5744857788086,
    ],
    path: 'children[1].children[1].children[1].children[3]',
    telemetry: {
      amperage: 132179,
      timestamp: '2019-10-15T13:57:15.878226Z',
      totalPower: 57.98,
      turnedOn: true,
      voltage: 223,
      workTime: 2666,
    },
  },
  {
    id: 2566,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '12.2',
    status: 'ok',
    location: [
      57.10677719116211,
      65.57477569580078,
    ],
    path: 'children[1].children[1].children[1].children[4]',
    telemetry: {
      amperage: 129841,
      timestamp: '2019-10-15T13:57:12.258844Z',
      totalPower: 56,
      turnedOn: true,
      voltage: 224.01,
      workTime: 2684,
    },
  },
  {
    id: 2627,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '12.1',
    status: 'ok',
    location: [
      57.10663604736328,
      65.57462310791016,
    ],
    path: 'children[1].children[1].children[1].children[5]',
    telemetry: {
      amperage: 128879,
      timestamp: '2019-10-15T14:00:45.232854Z',
      totalPower: 56.03,
      turnedOn: true,
      voltage: 224.1,
      workTime: 2642,
    },
  },
  {
    id: 2562,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '13.2',
    status: 'ok',
    location: [
      57.10709762573242,
      65.5751724243164,
    ],
    path: 'children[1].children[1].children[1].children[6]',
    telemetry: {
      amperage: 129389,
      timestamp: '2019-10-15T13:58:27.757881Z',
      totalPower: 58.45,
      turnedOn: true,
      voltage: 224.8,
      workTime: 2665,
    },
  },
  {
    id: 2714,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '13.1',
    status: 'ok',
    location: [
      57.106990814208984,
      65.57505798339844,
    ],
    path: 'children[1].children[1].children[1].children[7]',
    telemetry: {
      amperage: 95577,
      timestamp: '2019-10-15T13:58:20.071424Z',
      totalPower: 11.18,
      turnedOn: true,
      voltage: 223.56,
      workTime: 2643,
    },
  },
  {
    id: 2747,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '14.1',
    status: 'ok',
    location: [
      57.107242584228516,
      65.57539367675781,
    ],
    path: 'children[1].children[1].children[1].children[8]',
    telemetry: {
      amperage: 130253,
      timestamp: '2019-10-15T14:00:47.290679Z',
      totalPower: 56.21,
      turnedOn: true,
      voltage: 224.83,
      workTime: 2662,
    },
  },
  {
    id: 2883,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '14.2',
    status: 'ok',
    location: [
      57.10727310180664,
      65.575439453125,
    ],
    path: 'children[1].children[1].children[1].children[9]',
    telemetry: {
      amperage: 129134,
      timestamp: '2019-10-15T14:00:49.853384Z',
      totalPower: 56.19,
      turnedOn: true,
      voltage: 224.78,
      workTime: 2648,
    },
  },
  {
    id: 2682,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '15.1',
    status: 'ok',
    location: [
      57.10736846923828,
      65.5755615234375,
    ],
    path: 'children[1].children[1].children[1].children[10]',
    telemetry: {
      amperage: 155300,
      timestamp: '2019-10-15T13:58:24.149898Z',
      totalPower: 58.16,
      turnedOn: true,
      voltage: 223.69,
      workTime: 2659,
    },
  },
  {
    id: 2845,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '15.2',
    status: 'ok',
    location: [
      57.1074104309082,
      65.57561492919922,
    ],
    path: 'children[1].children[1].children[1].children[11]',
    telemetry: {
      amperage: 130418,
      timestamp: '2019-10-15T13:57:17.415760Z',
      totalPower: 58.09,
      turnedOn: true,
      voltage: 223.44,
      workTime: 2672,
    },
  },
  {
    id: 2724,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '16.1',
    status: 'ok',
    location: [
      57.10759353637695,
      65.57585144042969,
    ],
    path: 'children[1].children[1].children[1].children[12]',
    telemetry: {
      amperage: 228521,
      timestamp: '2019-10-15T13:58:28.257891Z',
      totalPower: 88.76,
      turnedOn: true,
      voltage: 221.91,
      workTime: 2663,
    },
  },
  {
    id: 2607,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '34.1',
    status: 'info',
    location: [
      57.109535217285156,
      65.57813262939453,
    ],
    path: 'children[1].children[1].children[1].children[13]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T14:00:47.806447Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2624,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '34.2',
    status: 'info',
    location: [
      57.10948944091797,
      65.57821655273438,
    ],
    path: 'children[1].children[1].children[1].children[14]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:26.728042Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2440,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '35.2',
    status: 'info',
    location: [
      57.10935974121094,
      65.5779800415039,
    ],
    path: 'children[1].children[1].children[1].children[15]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:26.212489Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2848,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '35.1',
    status: 'info',
    location: [
      57.10940933227539,
      65.57785034179688,
    ],
    path: 'children[1].children[1].children[1].children[16]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:42.104967Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2441,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '36.2',
    status: 'info',
    location: [
      57.10922622680664,
      65.57779693603516,
    ],
    path: 'children[1].children[1].children[1].children[17]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:40.555729Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2729,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '36.1',
    status: 'info',
    location: [
      57.10926055908203,
      65.57768249511719,
    ],
    path: 'children[1].children[1].children[1].children[18]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:57:04.040824Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2512,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '37.1',
    status: 'info',
    location: [
      57.10902786254883,
      65.5774154663086,
    ],
    path: 'children[1].children[1].children[1].children[19]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:41.071616Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2641,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '37.2',
    status: 'info',
    location: [
      57.109039306640625,
      65.57760620117188,
    ],
    path: 'children[1].children[1].children[1].children[20]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:40.056321Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2581,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '38.1',
    status: 'info',
    location: [
      57.108821868896484,
      65.57713317871094,
    ],
    path: 'children[1].children[1].children[1].children[21]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:28.759544Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2707,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '38.2',
    status: 'info',
    location: [
      57.10874938964844,
      65.57725524902344,
    ],
    path: 'children[1].children[1].children[1].children[22]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:44.650314Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2571,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '39.2',
    status: 'info',
    location: [
      57.10857009887695,
      65.5770492553711,
    ],
    path: 'children[1].children[1].children[1].children[23]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:31.821224Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2611,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '39.1',
    status: 'info',
    location: [
      57.1086311340332,
      65.57689666748047,
    ],
    path: 'children[1].children[1].children[1].children[24]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:38.509548Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2546,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '40.1',
    status: 'ok',
    location: [
      57.108436584472656,
      65.57670593261719,
    ],
    path: 'children[1].children[1].children[1].children[25]',
    telemetry: {
      amperage: 79492,
      timestamp: '2019-10-15T13:59:39.540555Z',
      totalPower: 83.02,
      turnedOn: true,
      voltage: 224.37,
      workTime: 1673,
    },
  },
  {
    id: 2591,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '40.2',
    status: 'info',
    location: [
      57.1083984375,
      65.57683563232422,
    ],
    path: 'children[1].children[1].children[1].children[26]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:59:37.478903Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2599,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '41.1',
    status: 'info',
    location: [
      57.108272552490234,
      65.57653045654297,
    ],
    path: 'children[1].children[1].children[1].children[27]',
    telemetry: {
      amperage: 0,
      timestamp: '2019-10-15T13:58:21.602291Z',
      totalPower: 0,
      turnedOn: false,
      voltage: 0,
      workTime: 0,
    },
  },
  {
    id: 2791,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '41.2',
    status: 'ok',
    location: [
      57.10822677612305,
      65.57664489746094,
    ],
    path: 'children[1].children[1].children[1].children[28]',
    telemetry: {
      amperage: 151125,
      timestamp: '2019-10-15T13:59:35.962482Z',
      totalPower: 85.12,
      turnedOn: true,
      voltage: 224,
      workTime: 2655,
    },
  },
  {
    id: 2551,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '42.1',
    status: 'ok',
    location: [
      57.10808563232422,
      65.57625579833984,
    ],
    path: 'children[1].children[1].children[1].children[29]',
    telemetry: {
      amperage: 152908,
      timestamp: '2019-10-15T14:00:46.259772Z',
      totalPower: 84.83,
      turnedOn: true,
      voltage: 223.23,
      workTime: 2664,
    },
  },
  {
    id: 2776,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '42.2',
    status: 'ok',
    location: [
      57.1080436706543,
      65.57638549804688,
    ],
    path: 'children[1].children[1].children[1].children[30]',
    telemetry: {
      amperage: 155665,
      timestamp: '2019-10-15T14:00:45.743854Z',
      totalPower: 85.49,
      turnedOn: true,
      voltage: 224.97,
      workTime: 2683,
    },
  },
  {
    id: 2450,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '43.2',
    status: 'ok',
    location: [
      57.107906341552734,
      65.57626342773438,
    ],
    path: 'children[1].children[1].children[1].children[31]',
    telemetry: {
      amperage: 138108,
      timestamp: '2019-10-15T13:59:42.619153Z',
      totalPower: 84.84,
      turnedOn: true,
      voltage: 223.27,
      workTime: 2291,
    },
  },
  {
    id: 2914,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '43.1',
    status: 'ok',
    location: [
      57.107967376708984,
      65.57612609863281,
    ],
    path: 'children[1].children[1].children[1].children[32]',
    telemetry: {
      amperage: 155121,
      timestamp: '2019-10-15T13:59:39.025595Z',
      totalPower: 85.57,
      turnedOn: true,
      voltage: 225.19,
      workTime: 2641,
    },
  },
  {
    id: 2597,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '44.2',
    status: 'ok',
    location: [
      57.1077880859375,
      65.57604217529297,
    ],
    path: 'children[1].children[1].children[1].children[33]',
    telemetry: {
      amperage: 152098,
      timestamp: '2019-10-15T13:59:41.586605Z',
      totalPower: 58.03,
      turnedOn: true,
      voltage: 223.18,
      workTime: 2662,
    },
  },
  {
    id: 2772,
    geoZoneId: 63,
    parentId: 191,
    type: 'light',
    name: '44.1',
    status: 'ok',
    location: [
      57.107852935791016,
      65.57592010498047,
    ],
    path: 'children[1].children[1].children[1].children[34]',
    telemetry: {
      amperage: 155238,
      timestamp: '2019-10-15T13:59:36.962540Z',
      totalPower: 55.54,
      turnedOn: true,
      voltage: 222.18,
      workTime: 2663,
    },
  },
  {
    id: 2200,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '0',
    status: 'info',
    location: [
      50.27907334611882,
      127.53744843454828,
    ],
    path: 'children[2].children[0].children[0].children[0]',
  },
  {
    id: 2201,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '1',
    status: 'info',
    location: [
      50.278970219204375,
      127.5374323412942,
    ],
    path: 'children[2].children[0].children[0].children[1]',
  },
  {
    id: 2202,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '2',
    status: 'info',
    location: [
      50.27910600292823,
      127.5371828958558,
    ],
    path: 'children[2].children[0].children[0].children[2]',
  },
  {
    id: 2203,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '3',
    status: 'info',
    location: [
      50.27896850042057,
      127.5371614381837,
    ],
    path: 'children[2].children[0].children[0].children[3]',
  },
  {
    id: 2204,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '4',
    status: 'info',
    location: [
      50.27913006582603,
      127.53700855226981,
    ],
    path: 'children[2].children[0].children[0].children[4]',
  },
  {
    id: 2205,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '5',
    status: 'info',
    location: [
      50.27902006390772,
      127.53780248613823,
    ],
    path: 'children[2].children[0].children[0].children[5]',
  },
  {
    id: 2206,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '6',
    status: 'info',
    location: [
      50.27892553080501,
      127.53777298183905,
    ],
    path: 'children[2].children[0].children[0].children[6]',
  },
  {
    id: 2207,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '7',
    status: 'info',
    location: [
      50.27898912582217,
      127.53698977680675,
    ],
    path: 'children[2].children[0].children[0].children[7]',
  },
  {
    id: 2208,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '8',
    status: 'info',
    location: [
      50.2791592850426,
      127.53681811542981,
    ],
    path: 'children[2].children[0].children[0].children[8]',
  },
  {
    id: 2209,
    geoZoneId: 108,
    parentId: 204,
    type: 'light',
    name: '9',
    status: 'info',
    location: [
      50.27900459486747,
      127.53676178904045,
    ],
    path: 'children[2].children[0].children[0].children[9]',
  },
  {
    id: 186,
    type: 'controller',
    name: '18/7',
    location: [
      56.03257398897064,
      35.9538071278687,
    ],
    path: 'children[0].children[1].children[0].',
    count: 10,
    statistic: {
      ok: 0,
      warning: 0,
      info: 10,
      error: 0,
    },
  },
  {
    id: 160,
    type: 'controller',
    name: 'Базовая станция №2',
    location: [
      55.43344497680664,
      65.32637023925781,
    ],
    path: 'children[1].children[0].children[0].',
    telemetry: {
      accumulatedEnergyAmountRate1: null,
      accumulatedEnergyAmountRate2: null,
      accumulatedEnergyAmountRate3: null,
      accumulatedEnergyAmountRate4: null,
      accumulatedEnergyAmountTotal: null,
      allPhasesCosPhi: null,
      allPhasesPower: null,
      frequency: null,
      lightLevel: '',
      phase1Amperage: null,
      phase1CosPhi: null,
      phase1Power: null,
      phase1Voltage: null,
      phase1resistance: null,
      phase2Amperage: null,
      phase2CosPhi: null,
      phase2Power: null,
      phase2Voltage: null,
      phase2resistance: null,
      phase3Amperage: null,
      phase3CosPhi: null,
      phase3Power: null,
      phase3Voltage: null,
      phase3resistance: null,
      timestamp: '2019-10-15T13:58:24.655288Z',
      totalAmperage: null,
      totalPower: null,
    },
    count: 10,
    statistic: {
      ok: 0,
      warning: 0,
      info: 10,
      error: 0,
    },
  },
  {
    id: 192,
    type: 'controller',
    name: 'ШУО-1350',
    location: [
      57.10709762573242,
      65.57499694824219,
    ],
    path: 'children[1].children[1].children[0].',
    telemetry: {
      accumulatedEnergyAmountRate1: 2136.94,
      accumulatedEnergyAmountRate2: 3060.72,
      accumulatedEnergyAmountRate3: 0,
      accumulatedEnergyAmountRate4: 0,
      accumulatedEnergyAmountTotal: null,
      allPhasesCosPhi: 0.959,
      allPhasesPower: 1265.2,
      frequency: 50,
      lightLevel: '100',
      phase1Amperage: 1.49,
      phase1CosPhi: 0.967,
      phase1Power: 326.1,
      phase1Voltage: 226.1,
      phase1resistance: null,
      phase2Amperage: 1.91,
      phase2CosPhi: 0.948,
      phase2Power: 411.8,
      phase2Voltage: 226.33,
      phase2resistance: null,
      phase3Amperage: 2.4,
      phase3CosPhi: 0.962,
      phase3Power: 527.3,
      phase3Voltage: 227.66,
      phase3resistance: null,
      timestamp: '2019-10-15T13:58:34.932801Z',
      totalAmperage: null,
      totalPower: null,
    },
    count: 0,
    statistic: {
      ok: 0,
      warning: 0,
      info: 0,
      error: 0,
    },
  },
  {
    id: 191,
    type: 'controller',
    name: 'Базовая станция №1',
    location: [
      57.106937408447266,
      65.5761495361328,
    ],
    path: 'children[1].children[1].children[1].',
    telemetry: {
      accumulatedEnergyAmountRate1: 2617.101,
      accumulatedEnergyAmountRate2: null,
      accumulatedEnergyAmountRate3: null,
      accumulatedEnergyAmountRate4: null,
      accumulatedEnergyAmountTotal: null,
      allPhasesCosPhi: null,
      allPhasesPower: 1242.8024,
      frequency: null,
      lightLevel: '100',
      phase1Amperage: 5.55,
      phase1CosPhi: null,
      phase1Power: 1242.8024,
      phase1Voltage: 223.9284,
      phase1resistance: null,
      phase2Amperage: null,
      phase2CosPhi: null,
      phase2Power: null,
      phase2Voltage: null,
      phase2resistance: null,
      phase3Amperage: null,
      phase3CosPhi: null,
      phase3Power: null,
      phase3Voltage: null,
      phase3resistance: null,
      timestamp: '2019-10-15T13:59:45.163611Z',
      totalAmperage: null,
      totalPower: null,
    },
    count: 35,
    statistic: {
      ok: 19,
      warning: 0,
      info: 16,
      error: 0,
    },
  },
  {
    id: 1,
    type: 'controller',
    name: 'Школа №92',
    location: [
      57.109100341796875,
      65.57539367675781,
    ],
    path: 'children[1].children[1].children[2].',
    count: 0,
    statistic: {
      ok: 0,
      warning: 0,
      info: 0,
      error: 0,
    },
  },
  {
    id: 204,
    type: 'controller',
    name: '7/1',
    location: [
      50.279179910361094,
      127.53748062105645,
    ],
    path: 'children[2].children[0].children[0].',
    count: 10,
    statistic: {
      ok: 0,
      warning: 0,
      info: 10,
      error: 0,
    },
  },
]
