export default {
  username: 'Email',
  password: 'Password',
  signInButton: 'Authorization',
  forgotPasswordButton: 'Forgot your password?',
  forgotPasswordText: 'To reset your password,\nplease enter your email address',
  forgotPasswordReturn: 'Back to login',
  goToAuthorization: 'Go to authorization',
  restore: 'Restore password',
  submittedText: 'Password recovery instructions\nhave been sent to\nthe specified email address',
  errorText: 'Access denied!\nContact your team\nAdmin or try again later.',
}