export const START_DATE = 'startDate'
export const END_DATE = 'endDate'
export const URBAN_PROCESSES = 'type'
export const CHART_TYPE = 'chartType'
export const STATUS = 'status'
export const PERIOD = 'period'
export const INTERVAL = 'interval'

export const APPEAL_EARLIEST_DATE = 'appealEarliestDate'
export const RESPONSE_EARLIEST_DATE = 'responseEarliestDate'
