import cookie from 'react-cookies'
import axios from 'axios'
import { ACTIVE_PING_ITEM, ACTIVE_REQUEST_ITEM, SLS24_ACCESS_TOKEN } from '@/constants/names'
import { requestUserRefreshToken } from '@/store/actions/user/refreshToken'
import { LOGOUT_ERRORS } from '@/constants/values'
import { LOGIN_API_URL } from '@/constants/apiRoutes'
import emitter from '@/helpers/eventEmitter'

export const getHeaders = (headers = {}) => ({
  'Content-Type': 'application/json',
  ...headers,
})

export const getSecureHeaders = (headers = {}) => {
  const token = cookie.load(SLS24_ACCESS_TOKEN)
  return {
    Authorization: `Bearer ${token}`,
    ...getHeaders(headers),
  }
}

const getRequestsCount = (key = ACTIVE_REQUEST_ITEM) => Number.parseInt(window.localStorage.getItem(key) || '0', 10)

const addRequest = (key = ACTIVE_REQUEST_ITEM) => {
  const count = getRequestsCount()
  const value = count + 1

  window.localStorage.setItem(key, `${value}`)
  emitter.emit(key, value)
}
const removeRequest = (key = ACTIVE_REQUEST_ITEM) => {
  const count = getRequestsCount()
  const newCount = count - 1
  const value = newCount >= 0 ? newCount : 0

  window.localStorage.setItem(key, `${value}`)
  emitter.emit(key, value)
}

const getConfigWithValidToken = (config) => {
  const [, requestToken] = (config.headers.Authorization || '').split(' ')
  const accessToken = cookie.load(SLS24_ACCESS_TOKEN)
  const needChangeToken = config.url !== LOGIN_API_URL
    && requestToken
    && accessToken
    && requestToken !== accessToken
  return needChangeToken
    ? {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }
    : config
}

export const instance = axios.create()
window.localStorage.setItem(ACTIVE_REQUEST_ITEM, '0')
window.localStorage.setItem(ACTIVE_PING_ITEM, '0')

export const setupAxios = (store) => {
  instance.interceptors.request.use(
    (config) => {
      if (config && config.url !== LOGIN_API_URL) {
        addRequest()

        const hasPingRequest = Number.parseInt(
          window.localStorage.getItem(ACTIVE_PING_ITEM),
          10,
        ) === 1

        if (hasPingRequest) {
          return new Promise((resolve) => {
            emitter.subscribe(ACTIVE_PING_ITEM, (count) => {
              if (count === 0) {
                resolve(getConfigWithValidToken(config))
              }
            })
          })
        }
      }
      return getConfigWithValidToken(config)
    },
    (error) => error,
  )
  instance.interceptors.response.use(
    (response) => {
      if (response && response.config && response.config.url !== LOGIN_API_URL) {
        removeRequest()
      }

      return response
    },
    (error) => {
      const url = (error && error.response && error.response.config.url)
        || (error && error.config && error.config.url)
      if (url !== LOGIN_API_URL) {
        removeRequest()
      }
      const responseStatus = error && error.response && error.response.status

      if (
        LOGOUT_ERRORS.includes(responseStatus)
        && error.response.config.url !== LOGIN_API_URL
      ) {
        store.dispatch(requestUserRefreshToken(true))
      }
      return Promise.reject(error)
    },
  )
}

export const httpRequest = ({
  url = '/',
  method = 'get',
  headers = {},
  body = {},
  isSecure = true,
  options = {},
}) => instance.request({
  url,
  method,
  headers: isSecure ? getSecureHeaders(headers) : getHeaders(headers),
  data: body,
  ...options,
})

export default httpRequest
