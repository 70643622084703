import { UPLOAD_FILE_NOTIFICATION } from './index'

export const eventsUploadNotificationFile = (payload) => ({
  type: UPLOAD_FILE_NOTIFICATION.EVENTS,
  payload,
})

export const requestUploadNotificationFile = (payload) => ({
  type: UPLOAD_FILE_NOTIFICATION.REQUEST,
  payload,
})

export const successUploadNotificationFile = (payload) => ({
  type: UPLOAD_FILE_NOTIFICATION.SUCCESS,
  payload,
})

export const errorUploadNotificationFile = (payload) => ({
  type: UPLOAD_FILE_NOTIFICATION.ERROR,
  payload,
})
