import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UncknowIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="105" height="134" viewBox="0 0 105 134" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="9" y="20" width="88" height="112" fill={fill} className="svgFill" />
      <rect x="9" y="110" width="88" height="22" fill={fill} className="svgFill" />
      <path d="M6 20V128.154C6 131.383 8.60234 134 11.8125 134H93.1875C96.3977 134 99 131.383 99 128.154V20H93.1875V125.154C93.1875 126.811 91.8444 128.154 90.1875 128.154H14.8125C13.1556 128.154 11.8125 126.811 11.8125 125.154V20H6Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M72.9167 14.2857H105V20H0V14.2857H32.0833V5.71429C32.0833 2.55837 34.695 0 37.9167 0H67.0833C70.305 0 72.9167 2.55837 72.9167 5.71429V14.2857ZM67.0833 5.71429H37.9167V14.2857H67.0833V5.71429Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UncknowIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UncknowIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UncknowIcon)
