import {
  takeLatest, put,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import { DELETE_PASSPORT_FILES_URL } from '@/constants/apiRoutes'
import {
  DELETE_PASSPORT_PHOTO,
  requestGetPassportAndCardData,
} from '@/store/actions/passportization'
import {
  responseDeletePassportPhoto,
  errorDeletePassportPhoto,
} from '@/store/actions/passportization/deletePassportPhoto'
import createNotifications from '@/helpers/notification'

function* deletePassportPhotoSaga({ payload }) {
  const { id, type, passportId } = payload
  const toast = createNotifications()
  try {
    const requestSearch = qs.stringify({
      fileUid: 'null',
      installationType: type,
      passportId,
      photoUid: id,
    })
    yield request({
      url: `${DELETE_PASSPORT_FILES_URL}?${requestSearch}`,
      method: 'delete',
    })
    yield put(responseDeletePassportPhoto())
    yield put(requestGetPassportAndCardData({ id, type }))
  } catch (error) {
    console.log('function*deletePassportPhotoSaga -> error', error);
    yield put(errorDeletePassportPhoto(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка операции! Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_PASSPORT_PHOTO.REQUEST, deletePassportPhotoSaga)
}
