export default {
  parameters: 'Parameters',
  timetable: 'Schedule',
  newsletter: 'Newsletter',
  selectFromList: 'Select from list',
  allObjects: 'All objects',
  addUser: 'Add recipient email',
  deletedUser: 'Recipient deleted',
  cancel: 'Cancel',
  createReport: 'Create Report',
  form: {
    reportTitle: 'Report Title',
    reportType: 'Report type',
    typeObject: 'Type of objects to report',
    repeat: 'Repeat',
    timezone: 'Timezone',
    systemUsers: 'System Users',
    externalRecipients: 'External Recipients',
    userName: 'Name',
    userEmail: 'Email',
    emailNewsletter: 'Email Newsletter',
    geoZone: 'Geozone',
  },
  header: {
    editTitle: 'New report',
    cancelEdit: 'Are you sure you want to cancel adding the report?',
    saveReport: 'You must fill (*) required fields to add a report',
    changeCancelEdit: 'Are you sure you want to cancel editing the report?',
    deleteReport: 'Are you sure you want to delete the report from the system ',
    changeSaveReport: 'You must fill in (*) required fields to edit the report',
    changeEditTitle: 'Report Editing',
    createReport: 'You need to select a geozone in the tree to create a report',
  },
}