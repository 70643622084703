import { GET_REPORT_MANAGER_FILE } from './index'

export const requestReportManagerFile = (payload) => ({
  type: GET_REPORT_MANAGER_FILE.REQUEST,
  payload,
})

export const successReportManagerFile = (payload) => ({
  type: GET_REPORT_MANAGER_FILE.SUCCESS,
  payload,
})

export const errorReportManagerFile = (payload) => ({
  type: GET_REPORT_MANAGER_FILE.ERROR,
  payload,
})
