import {
  DEMONSTRATION_GRAPH,
  DEMONSTRATION_TABLE,
  DIFFERENCE_ANALSIS,
  COMPARATIVE_ANALSIS,
  COMPARATIVE_PLANE_ANALSIS,
  CURRENT_MONTH,
  PREVIOUS_MONTH,
  QUARTER,
  YEAR,
  OUTDOOR_LIGHTING,
  HEAT_SUPPLY,
} from '@/constants/names'

export const demonstrationOptions = [
  {
    value: DEMONSTRATION_GRAPH,
    label: 'График',
  },
  {
    value: DEMONSTRATION_TABLE,
    label: 'Таблица',
  },
]

export const typesConfig = [
  {
    id: OUTDOOR_LIGHTING,
    value: OUTDOOR_LIGHTING,
    title: 'Наружное освещение',
  },
  {
    id: HEAT_SUPPLY,
    value: HEAT_SUPPLY,
    title: 'Теплоснабжение',
  },
]

export const fastIntervalOptions = [
  {
    value: CURRENT_MONTH,
    label: 'Текущий месяц',
  },
  {
    value: PREVIOUS_MONTH,
    label: 'Предыдущий календарный месяц',
  },
  {
    value: QUARTER,
    label: 'Квартал',
  },
  {
    value: YEAR,
    label: 'Год',
  },
]

export const graphViewOptions = {
  [OUTDOOR_LIGHTING]: [
    {
      value: DIFFERENCE_ANALSIS,
      label: 'Анализ разницы в потреблении ресурсов',
    },
    {
      value: COMPARATIVE_ANALSIS,
      label: 'Сравнительный анализ потребления ресурсов',
    },
    {
      value: COMPARATIVE_PLANE_ANALSIS,
      label: 'Анализ потребления ресурсов относительно планового значения',
    },
  ],
  [HEAT_SUPPLY]: [
    {
      value: COMPARATIVE_PLANE_ANALSIS,
      label: 'Анализ потребления ресурсов относительно планового значения',
    },
  ],


}
