import { handleActions } from 'redux-actions'
import {
  GET_ALL_APPLICATIONS,
  GET_ALL_APPLICATIONS_MODELS,
} from '@/store/actions/dictionaries'

const initialState = {
  applications: [],
  applicationsModels: [],
}

export const reducer = handleActions(
  {
    [GET_ALL_APPLICATIONS.SUCCESS]: (state, { payload }) => ({
      ...state,
      applications: payload,
    }),
    [GET_ALL_APPLICATIONS_MODELS.SUCCESS]: (state, { payload }) => ({
      ...state,
      applicationsModels: payload,
    }),
  },
  initialState,
)

export default reducer
