import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const NotificationsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg id="notification" onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 19.625C6 20.2463 6.51166 20.75 7.14286 20.75H11.7143C11.7143 21.9927 12.7376 23 14 23C15.2624 23 16.2857 21.9927 16.2857 20.75H20.8571C21.4883 20.75 22 20.2463 22 19.625C22 19.0037 21.4883 18.5 20.8571 18.5V12.875C20.8571 9.53038 18.3859 6.75388 15.1429 6.21826V6.125C15.1429 5.50366 14.6312 5 14 5C13.3688 5 12.8571 5.50366 12.8571 6.125V6.21826C9.61406 6.75388 7.14286 9.53038 7.14286 12.875V18.5C6.51166 18.5 6 19.0037 6 19.625ZM14 8.31099L14.7647 8.43733C16.925 8.79406 18.5714 10.6475 18.5714 12.875V18.5H9.42857V12.875C9.42857 10.6475 11.075 8.79406 13.2353 8.43733L14 8.31099Z" fill={fill} className="svgFill" />
    </svg>
  )
}

NotificationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NotificationsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(NotificationsIcon)
