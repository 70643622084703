import { connect } from 'react-redux'
import {
  getData,
  getGraphType,
  getConsumptionForm,
  getSavings,
  getStatusTable,
  getStatusGraph,
  getIntegrationType,
  getCalculatorValue,
} from '@/store/selectors/consumptionAnalysis'
import {
  getTreeData,
  getTelemetry,
} from '@/store/selectors/maps/socketEvents'
import {
  requestHeatGraphData,
} from '@/store/actions/consumptionAnalysis/getHeatGraphData'
import {
  requestHeatTableData,
} from '@/store/actions/consumptionAnalysis/getHeatTableData'
import {
  requestHeatFile,
} from '@/store/actions/consumptionAnalysis/getHeatFile'
import {
  requestGraphData,
} from '@/store/actions/consumptionAnalysis/getGraphData'
import {
  requestPlanGraphData,
} from '@/store/actions/consumptionAnalysis/getPlanGraphData'
import {
  requestTableData,
} from '@/store/actions/consumptionAnalysis/getTableData'
import {
  setRange,
  setType,
} from '@/store/actions/consumptionAnalysis/tableConfig'
import {
  requestConsumptionAnalysisFile,
} from '@/store/actions/consumptionAnalysis/getFile'
import { setSelectedNode } from '@/store/actions/appSettings'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  setTenantGraphType,
  setFormValues,
  setClearData,
} from '@/store/actions/consumptionAnalysis'
import ConsumptionAnalysis from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  data: getData(state),
  graphType: getGraphType(state),
  consumptionForm: getConsumptionForm(state),
  savings: getSavings(state),
  selectedNode: getSelectedNode(state),
  statusTable: getStatusTable(state),
  statusGraph: getStatusGraph(state),
  telemetry: getTelemetry(state),
  integrationType: getIntegrationType(state),
  calculatorValue: getCalculatorValue(state),
})

const mapDispatchToProps = (dispatch) => ({
  setClearData: (data) => dispatch(setClearData(data)),
  getHeatTableData: (data) => dispatch(requestHeatTableData(data)),
  getHeatGraphData: (data) => dispatch(requestHeatGraphData(data)),
  getHeatFile: (data) => dispatch(requestHeatFile(data)),
  getTableData: (data) => dispatch(requestTableData(data)),
  getGraphData: (data) => dispatch(requestGraphData(data)),
  getPlanGraphData: (data) => dispatch(requestPlanGraphData(data)),
  setGraphType: (data) => dispatch(setTenantGraphType(data)),
  setRange: (data) => dispatch(setRange(data)),
  setType: (data) => dispatch(setType(data)),
  setNode: (data) => dispatch(setSelectedNode(data)),
  setFormValues: (data) => dispatch(setFormValues(data)),
  getReportFile: (payload) => dispatch(requestConsumptionAnalysisFile(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsumptionAnalysis)
