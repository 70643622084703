import {
  CONTROLLER,
  LIGHT,
  UNICERSE_METER,
  CONTACTOR,
  BRANCH_CIRCUIT,
  CHECK_POINT,
  FUSE,
  CURRENT_SENSOR,
  DMX_DEVICE,
} from '@/constants/objectTypes'
import controller from './controller'
import light from './light'
import unicerseMeter from './unicerseMeter'
import contactor from './contactor'
import branchCircuit from './branchCircuit'
import checkPoint from './checkPoint'
import currentSensor from './currentSensor'
import dmxDevice from './dmxdevice'

export default {
  [CONTROLLER]: controller,
  [LIGHT]: light,
  [UNICERSE_METER]: unicerseMeter,
  [CONTACTOR]: contactor,
  [BRANCH_CIRCUIT]: branchCircuit,
  [CHECK_POINT]: checkPoint,
  [FUSE]: branchCircuit,
  [CURRENT_SENSOR]: currentSensor,
  [DMX_DEVICE]: dmxDevice,
}
