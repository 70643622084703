import styled from 'styled-components'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, type }) => {
    if (type === OK) {
      return theme.colors.messages.ok
    }
    if (type === ERROR) {
      return theme.colors.messages.error
    }
    if (type === WARNING) {
      return theme.colors.messages.warning
    }
    if (type === UNKNOWN) {
      return theme.colors.messages.default
    }
    return theme.colors.colors.default
  }};
`
