import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WaterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.5524 0C48.3646 0 49.023 0.67157 49.023 1.5V48.5C49.023 49.3284 48.3646 50 47.5524 50C46.7403 50 46.0819 49.3284 46.0819 48.5V1.5C46.0819 0.67157 46.7403 0 47.5524 0Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.8262 3C19.0251 3 18.3757 3.67157 18.3757 4.5C18.3757 5.32843 19.0251 6 19.8262 6H21.7601V12.1313C20.5121 12.8268 19.4374 13.812 18.6205 15H13.0574C12.3416 15 11.7166 15.4022 11.3821 16H9.18957C4.1162 16 0.00341797 20.2533 0.00341797 25.5V33.5H8.70607V25.5C8.70607 25.2238 8.92254 25 9.18957 25H11.3821C11.7166 25.5978 12.3416 26 13.0574 26H18.6205C20.2854 28.4208 23.0201 30 26.1114 30C29.6104 30 32.6525 27.9769 34.2036 25H38.2387C39.3217 25 40.1995 25.8954 40.1995 27V48.5C40.1995 49.3284 40.8579 50 41.6701 50C42.4822 50 43.1407 49.3284 43.1407 48.5V26C43.1407 23.7909 41.3849 22 39.2191 22H35.1836C35.2586 21.5114 35.2975 21.0104 35.2975 20.5C35.2975 19.9896 35.2586 19.4886 35.1836 19H39.2191C41.3849 19 43.1407 17.2091 43.1407 15V1.5C43.1407 0.67157 42.4822 0 41.6701 0C40.8579 0 40.1995 0.67157 40.1995 1.5V14C40.1995 15.1046 39.3217 16 38.2387 16H34.2036C33.3504 14.3625 32.0461 13.0136 30.4627 12.1313V6H32.3967C33.1977 6 33.847 5.32843 33.847 4.5C33.847 3.67157 33.1977 3 32.3967 3H19.8262ZM27.5619 6H24.661V11.1177C25.1334 11.0402 25.6178 11 26.1114 11C26.6049 11 27.0894 11.0402 27.5619 11.1177V6ZM11.1235 19H9.18957C5.71831 19 2.90429 21.9102 2.90429 25.5V30.5H5.8052V25.5C5.8052 23.567 7.32042 22 9.18957 22H11.1235V19ZM16.9253 20.5C16.9253 19.264 17.1535 18.0831 17.5688 17H14.0244C13.4903 17 13.0574 17.4477 13.0574 18V23C13.0574 23.5523 13.4903 24 14.0244 24H17.5688C17.1535 22.9169 16.9253 21.736 16.9253 20.5ZM26.1114 27C29.5826 27 32.3967 24.0898 32.3967 20.5C32.3967 16.9102 29.5826 14 26.1114 14C22.6402 14 19.8262 16.9102 19.8262 20.5C19.8262 24.0898 22.6402 27 26.1114 27Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.25788 36.503C2.49392 37.4898 0.00341797 40.8575 0.00341797 42.7602C0.00341797 43.8846 0.46187 44.9631 1.27788 45.7582C2.09393 46.5533 3.20071 47 4.35475 47C5.50878 47 6.61556 46.5533 7.43161 45.7582C8.24762 44.9631 8.70607 43.8846 8.70607 42.7602C8.70607 40.8583 6.21557 37.4898 5.45162 36.503C5.31805 36.3456 5.15048 36.219 4.96088 36.132C4.77126 36.0451 4.5643 36 4.35475 36C4.1452 36 3.93825 36.0451 3.74861 36.132C3.55901 36.219 3.39144 36.3456 3.25788 36.503ZM4.35678 44.3224C3.9318 44.3219 3.52437 44.1572 3.22386 43.8644C2.92329 43.5715 2.75422 43.1744 2.75383 42.7602C3.0929 41.6364 3.63564 40.5809 4.35633 39.6434C5.07379 40.583 5.61671 41.6379 5.96039 42.7602C5.95997 43.1748 5.7906 43.5722 5.48949 43.8652C5.18886 44.1577 4.7816 44.3221 4.35678 44.3224Z" fill={fill} className="svgFill" />
    </svg>
  )
}

WaterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WaterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WaterIcon)
