import {
  take, call, put,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import createNotifications from '@/helpers/notification'
import request from '@/helpers/axios'
import { setRequestStatus } from '@/store/actions/installation'
import { REQUEST_STATUSES } from '@/constants/requests'
import { generateGetDeleteIntegrationCard } from '@/constants/apiRoutes'
import { verifyNotificationChannel } from './channels'

function* verifyIntegrationSaga() {
  try {
    const toast = createNotifications()
    const channel = yield call(verifyNotificationChannel)
    while (true) {
      const eventData = yield take(channel)
      const { data } = yield request({
        url: generateGetDeleteIntegrationCard(eventData.integrationId),
        method: 'get',
      })
      const tree = treeHandlers.trees['installations-tree']
      const parent = tree.instance.getNodeById(data.parentTreeElementId)
      const parentChildren = yield call(parent.data.getChildren, parent)
      tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
      const node = tree.instance.getNodeById(data.treeElementId)
      tree.handlers.setSelected(node, true)
      // updateNodeCount(tree, parent.options.path, 'add', node.data.childrenCount)
      tree.handlers.rerender()
      toast({
        title: 'Успешная операция!',
        type: 'success',
        description: `Верификация дерева АСУ\nинтеграции "${eventData.integrationName}" завершена.`,
      })
      yield put(setRequestStatus({
        telemetryAndStructureRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
        displayElementRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
      }))
    }
  } catch (error) {
    console.log('function*verifyIntegrationSaga -> error', error)
  }
}

export default verifyIntegrationSaga
