import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_CONSUMPTION_ANALYSIS_GRAPH_DATA,
} from '@/store/actions/consumptionAnalysis'
import {
  successConsumptionAnalysisSavings,
} from '@/store/actions/consumptionAnalysis/getAvarage'
import {
  responseGraphData,
  errorGraphData,
} from '@/store/actions/consumptionAnalysis/getGraphData'
import { getSelectedNode } from '@/store/selectors/appSettings'
import createNotifications from '@/helpers/notification'
import {
  getForm,
  getTableParameters,
} from '@/store/selectors/consumptionAnalysis'
import { LIGHT } from '@/constants/objectTypes'
import {
  RESOURCE_ANALYTICS_HISTORY_GRAPH_ENDPOINT,
} from '@/constants/apiRoutes'

function* getGraphDataSaga() {
  const toast = createNotifications()
  try {
    const formValues = yield select(getForm)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const {
      type,
      graphView,
      dateFirst,
      dateSecond,
    } = formValues
    const dataJson = {
      installationId: node.internalId || null,
      addressId: node.type === LIGHT ? node.id : null,
      type,
      range: {
        start: moment(dateFirst).format('DD.MM.YYYY'),
        end: moment(dateSecond).format('DD.MM.YYYY'),
      },
      includedColumns: tableParameters.activeFields,
      query: tableParameters.requestQuery || '',
      sort: {
        type: tableParameters.sort.direction,
        value: tableParameters.sort.field,
      },
      page: tableParameters.page - 1,
      size: tableParameters.perPage,
    }

    const response = yield request({
      url: RESOURCE_ANALYTICS_HISTORY_GRAPH_ENDPOINT,
      method: 'post',
      body: dataJson,
    })

    if (response) {
      const { error, graphStatistic } = response.data

      if (error) {
        yield put(errorGraphData())
      } else {
        yield put(responseGraphData({
          ...response.data,
          graphType: graphView,
        }))
        yield put(successConsumptionAnalysisSavings({
          min: graphStatistic.min.toFixed(2).toLocaleString(),
          max: graphStatistic.max.toFixed(2).toLocaleString(),
          average: graphStatistic.average.toFixed(2).toLocaleString(),
        }))
      }
    } else {
      yield put(errorGraphData())
    }
  } catch (error) {
    const node = yield select(getSelectedNode)
    yield put(errorGraphData(error))
    toast({
      title: 'Ошибка сервиса!',
      description: `Hе удалось получить данные "${node.name}".\nПовторите попытку позже`,
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.REQUEST, getGraphDataSaga)
}
