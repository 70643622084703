import {
  takeLatest, put,
} from 'redux-saga/effects'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import request from '@/helpers/axios'
import { ADD_PASSPORT_PHOTO_URL } from '@/constants/apiRoutes'
import { ADD_PASSPORT_PHOTO, requestGetPassportAndCardData } from '@/store/actions/passportization'
import {
  responseAddPassportPhoto,
  errorAddPassportPhoto,
} from '@/store/actions/passportization/addPassportPhoto'
import createNotifications from '@/helpers/notification'

function* addPassportPhotoSaga({ payload }) {
  const {
    form,
    id,
    type,
    passportId,
  } = payload
  const toast = createNotifications()
  try {
    const formData = new window.FormData()
    formData.append('photo', form.state.values.inner.photo)
    formData.append('title', form.state.values.inner.photo.name)
    formData.append('installationId', id)
    formData.append('installationType', type)
    formData.append('passportId', passportId)
    const response = yield request({
      url: ADD_PASSPORT_PHOTO_URL,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    yield put(responseAddPassportPhoto({ photoId: response.data }))
    yield put(requestGetPassportAndCardData({ id, type }))
  } catch (error) {
    const data = get(error, 'response.data', [])
    const isFileSizeError = isArray(data)
      && (data || []).some((errorRow) => errorRow.message === 'error.wrong.file.size')
    if (isFileSizeError) {
      form.setFieldError('inner.photo', 'Ошибка размера файла. Максимальный размер - 200кб.')
      toast({
        title: 'Ошибка операции!',
        description: 'Ошибка размера фото! Максимальный размер - 200кб.',
        type: 'error',
      })
    } else {
      toast({
        title: 'Ошибка операции!',
        description: 'Ошибка операции! Повторите попытку позже',
        type: 'error',
      })
    }
    yield put(errorAddPassportPhoto(error))

  }
}

export default function* root() {
  yield takeLatest(ADD_PASSPORT_PHOTO.REQUEST, addPassportPhotoSaga)
}
