import {
  takeEvery, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SERVICE_DESK_INTEGRATION_URL } from '@/constants/apiRoutes'
import { getIntegrationStatus, getTenantServiceDeskStatus } from '@/store/selectors/appSettings'
import { DELETE_SERVICE_DESK_INTEGRATION, setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'
import { successDeleteIntegration } from '@/store/actions/serviceDeskMyCity/deleteIntegration'
import {
  TENANT,
  RESPONSE_STATUS_CORRECT,
} from '@/constants/applications'

function* deleteIntegrationSaga({ payload = {} }) {
  const {
    id = null,
    type = null,
    widgetId,
    isUserIntegration = false,
  } = payload
  try {
    const integrationStatus = yield select(getIntegrationStatus)
    const tenantIntegrationStatus = yield select(getTenantServiceDeskStatus)
    const isTenantStatus = isUserIntegration ? tenantIntegrationStatus : integrationStatus
    const currentIntegrationStatus = isTenantStatus.find(
      (status) => widgetId === status.temporaryId,
    )
    const integrationId = type === TENANT ? id : currentIntegrationStatus.id
    const response = yield request({
      url: `${SERVICE_DESK_INTEGRATION_URL}/${integrationId}`,
      method: 'delete',
    })
    if (response.status === RESPONSE_STATUS_CORRECT && type !== TENANT && !isUserIntegration) {
      const newIntegrationStatus = integrationStatus.filter(
        (status) => widgetId !== status.temporaryId,
      )
      yield put(successDeleteIntegration(newIntegrationStatus))
    }
    if (response.status === RESPONSE_STATUS_CORRECT && type !== TENANT && isUserIntegration) {
      const newIntegrationStatus = tenantIntegrationStatus.filter(
        (status) => widgetId !== status.temporaryId,
      )
      yield put(setTenantServiceDesk(newIntegrationStatus))
    }
  } catch (error) {
    console.log('TCL: function*deleteIntegrationSaga -> error', error)
  }
  return null
}

export default function* root() {
  yield takeEvery(DELETE_SERVICE_DESK_INTEGRATION.REQUEST, deleteIntegrationSaga)
}
