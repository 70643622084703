import {
  put, takeLatest, all,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { successGetRootElements, errorGetRootElements } from '@/store/actions/installation/getRootElemenet'
import { INSTALLATION_ROOT_ELEMENT, generateChildCount } from '@/constants/apiRoutes'
import { getChildren } from '@/helpers/getTreeChild'
import { GET_ROOT_ELEMENTS } from '@/store/actions/installation'
import { ROOT } from '@/constants/objectTypes'

function* getRootElementSaga() {
  try {
    const { data } = yield request({
      url: INSTALLATION_ROOT_ELEMENT,
      method: 'get',
    })
    const formattedData = yield all(data.map(async (element) => {
      const { data: childCount } = await request({
        url: generateChildCount(element.id),
        method: 'get',
      })
      return {
        ...element,
        type: ROOT,
        name: element.elementName,
        count: childCount,
        rootGeoZone: element.id,
        childrenCount: childCount,
        getChildren: getChildren(element.id, element.id, element.id),
      }
    }))
    yield put(successGetRootElements(formattedData))
  } catch (error) {
    yield put(errorGetRootElements())
    console.log('function*getRootElementSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GET_ROOT_ELEMENTS.REQUEST, getRootElementSaga)
}
