import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WaitIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0.900001C12 0.402945 11.5971 0 11.1 0H0.9C0.402944 0 0 0.402944 0 0.9V4.99917C0 5.25431 0.10829 5.49746 0.297932 5.66814L3.25671 8.33104C3.65395 8.68855 3.65395 9.31145 3.25671 9.66896L0.297932 12.3319C0.108289 12.5025 0 12.7457 0 13.0008V17.1C0 17.5971 0.402944 18 0.9 18H11.1C11.5971 18 12 17.5971 12 17.1V13.0008C12 12.7457 11.8917 12.5025 11.7021 12.3319L8.74329 9.66896C8.34605 9.31145 8.34605 8.68855 8.74329 8.33103L11.7021 5.66814C11.8917 5.49746 12 5.25431 12 4.99917V0.900001ZM2 13.4508C2 13.1957 2.10829 12.9525 2.29793 12.7819L5.39793 9.99186C5.74021 9.68381 6.25979 9.68381 6.60207 9.99186L9.70207 12.7819C9.89171 12.9525 10 13.1957 10 13.4508V15.3C10 15.7971 9.59706 16.2 9.1 16.2H2.9C2.40294 16.2 2 15.7971 2 15.3V13.4508ZM6.60207 8.00814C6.25979 8.31619 5.74021 8.31618 5.39793 8.00814L2.29793 5.21814C2.10829 5.04746 2 4.80431 2 4.54917V2.7C2 2.20294 2.40294 1.8 2.9 1.8H9.1C9.59706 1.8 10 2.20294 10 2.7V4.54917C10 4.80431 9.89171 5.04746 9.70207 5.21814L6.60207 8.00814ZM8 4.06701C8 3.80909 7.79091 3.6 7.53299 3.6H4.46701C4.20909 3.6 4 3.80909 4 4.06701C4 4.1994 4.05619 4.32557 4.1546 4.41414L5.39793 5.53314C5.74021 5.84119 6.25979 5.84119 6.60207 5.53314L7.8454 4.41414C7.94381 4.32557 8 4.1994 8 4.06701Z" fill={fill} className="svgFill" />
    </svg>
  )
}

WaitIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WaitIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WaitIcon)
