export default [[
  [69.6765, 30.9250],
  [68.1583, 28.7277],
  [66.0370, 29.7824],
  [62.8923, 31.1887],
  [59.9307, 27.1457],
  [56.1600, 28.6399],
  [55.6912, 31.0129],
  [53.2872, 32.7268],
  [53.0098, 31.4963],
  [52.1667, 31.9577],
  [52.3151, 34.0232],
  [51.2384, 34.3967],
  [51.1694, 35.2536],
  [50.4033, 35.7370],
  [50.3611, 37.5388],
  [49.9518, 38.3078],
  [50.4158, 37.4619],
  [49.9289, 38.0551],
  [49.4299, 40.1645],
  [47.8617, 39.7901],
  [47.6914, 38.7135],
  [47.6023, 38.3619],
  [47.1545, 38.2740],
  [47.2594, 39.2079],
  [47.0120, 39.3617],
  [46.6122, 37.8566],
  [45.7191, 37.8895],
  [45.3562, 37.5709],
  [45.3407, 35.3846],
  [45.9418, 34.6705],
  [45.9112, 33.6708],
  [45.3872, 32.5062],
  [45.1078, 33.4730],
  [44.8661, 33.6378],
  [44.5685, 33.5060],
  [44.4112, 34.0443],
  [45.0300, 35.4286],
  [44.9832, 37.2523],
  [43.4481, 40.0194],
  [43.5441, 40.7116],
  [43.1793, 42.1068],
  [43.2316, 42.4309],
  [43.1592, 42.6891],
  [42.7193, 43.8207],
  [42.7193, 43.8207],
  [42.6342, 44.2437],
  [42.7436, 44.5568],
  [42.6950, 45.1555],
  [42.5018, 45.7577],
  [42.2452, 45.6148],
  [41.7246, 46.9470],
  [41.3407, 47.2656],
  [41.3407, 47.2656],
  [41.8192, 48.6279],
  [43.0400, 47.4853],
  [43.9239, 47.7765],
  [43.8524, 47.5293],
  [44.2288, 47.2382],
  [44.2288, 47.1338],
  [44.3709, 47.0294],
  [44.3984, 46.7163],
  [44.6970, 46.7712],
  [45.5784, 47.5846],
  [45.9437, 48.6118],
  [46.3408, 49.1337],
  [46.5686, 48.5569],
  [46.6972, 48.5239],
  [46.7426, 49.0183],
  [47.7119, 48.1107],
  [47.7082, 47.3142],
  [48.2357, 47.1164],
  [48.3889, 46.6329],
  [49.8890, 46.9202],
  [50.4149, 47.6014],
  [49.8322, 48.4309],
  [50.0095, 48.9033],
  [50.6180, 48.5682],
  [51.6287, 50.8134],
  [51.4712, 53.5655],
  [50.8594, 54.4218],
  [50.5454, 54.4657],
  [50.5489, 54.7349],
  [51.0190, 54.5701],
  [50.5629, 55.7346],
  [51.0814, 57.2507],
  [50.9080, 57.5034],
  [50.9184, 57.7561],
  [51.1194, 57.8220],
  [51.0645, 58.5515],
  [50.5389, 59.5458],
  [50.8007, 60.0237],
  [50.7902, 60.2599],
  [50.6665, 60.3341],
  [50.6612, 60.8202],
  [50.8181, 61.4629],
  [51.4093, 61.5041],
  [51.4883, 60.9521],
  [51.9363, 60.0354],
  [52.3587, 61.1120],
  [52.7638, 60.7605],
  [52.9765, 62.1337],
  [53.1288, 62.0568],
  [53.3200, 61.2274],
  [54.0151, 61.3764],
  [54.9654, 68.1957],
  [55.1923, 68.2067],
  [55.2594, 70.8668],
  [54.2384, 71.2448],
  [54.0189, 73.6707],
  [53.5603, 73.4345],
  [54.2930, 76.4832],
  [50.8946, 79.8024],
  [50.8737, 83.4495],
  [49.2041, 87.2508],
  [50.8042, 92.5242],
  [50.0179, 98.0394],
  [52.1622, 98.8084],
  [51.2752, 102.214],
  [49.6272, 117.997],
  [53.2458, 121.344],
  [53.0476, 125.519],
  [47.8496, 131.034],
  [48.3993, 134.514],
  [45.0463, 133.064],
  [44.7966, 131.109],
  [42.3855, 130.911],
  [43.2910, 134.668],
  [48.9653, 140.399],
  [53.2748, 140.616],
  [54.3411, 139.474],
  [53.4981, 137.870],
  [54.8261, 135.255],
  [59.3587, 143.363],
  [57.6336, 157.645],
  [55.1233, 156.019],
  [50.9141, 156.810],
  [56.2650, 163.446],
  [62.4623, 176.637],
  [62.4003, 179.481],
  [64.4658, 177.855],
  [65.3978, -178.366],
  [64.3041, -173.180],
  [66.0310, -170.104],
  [67.0724, -173.444],
  [69.5565, 177.503],
  [70.0949, 170.648],
  [70.0275, 168.209],
  [69.5027, 167.066],
  [69.6561, 160.562],
  [70.6998, 159.705],
  [70.8154, 152.628],
  [72.4561, 148.629],
  [74.7140, 145.465],
  [74.3860, 149.771],
  [75.2829, 152.057],
  [76.0871, 137.291],
  [74.8064, 137.818],
  [71.5876, 139.225],
  [71.3078, 129.820],
  [73.4108, 128.414],
  [74.0028, 114.977],
  [77.3197, 107.682],
  [81.3273, 95.9925],
  [79.8187, 90.3675],
  [76.8881, 99.3324],
  [73.4305, 80.4568],
  [69.6262, 63.3181],
  [67.4344, 47.8738],
  [67.8858, 39.4803],
  [69.4222, 33.7234],
]]