import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import {
  ROLE_SUPER_ADMIN,
  ROLE_TENANT_ADMIN,
  ROLE_USER,
} from '@/constants/names'

const validateId = (id, value) => {
  if (isNumber(value) && isNumber(id)) {
    return id === value
  }
  if (isNumber(value) && isArray(id)) {
    return id.includes(value)
  }
  if (isArray(value) && isNumber(id)) {
    return value.includes(id)
  }
  if (isArray(value) && isArray(id)) {
    return id.some((module) => value.includes(module))
  }

  return false
}

export const isSuperAdmin = (role) => role === ROLE_SUPER_ADMIN
export const isAdmin = (role) => role === ROLE_TENANT_ADMIN
export const isUser = (role) => role === ROLE_USER

export const isUserHasPermission = (user = {}, applicationId = null) => {
  if (isSuperAdmin(user.role)) {
    return true
  }
  if (user && user.applications && applicationId) {
    return validateId(user.applications, applicationId)
  }

  return false
}
