import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  SERVICE_DESK_MY_CITY_APPEALS_URL,
  SERVICE_DESK_MY_CITY_URL,
} from '@/constants/routes'
import Header from './components/Header'
import Appeals from './components/Appeals'
import Statistic from './components/Statistic'
import {
  Container,
} from './styles'

const ServiceDeskMyCity = () => {
  return (
    <Container>
      <Header />
      <Switch>
        <Route
          exact
          path={SERVICE_DESK_MY_CITY_URL}
          component={Statistic}
        />
        <Route
          exact
          path={SERVICE_DESK_MY_CITY_APPEALS_URL}
          component={Appeals}
        />
      </Switch>
    </Container>
  )
}

ServiceDeskMyCity.propTypes = {
}
ServiceDeskMyCity.defaultProps = {
}

export default ServiceDeskMyCity
