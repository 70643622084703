import MapIcons from '@/components/icons/maps'
import SyncModule from '@/components/icons/sync'
import {
  LIGHT,
} from '@/constants/objectTypes'
import {
  KULON,
  BRIZ,
  ATM,
  MESH,
} from '@/constants/integrations'

export const COMMON = 'common'
export const LIGHTNING = 'light'
export const HEAT_SUPPLY = 'heatSupply'
export const WATER_SUPPLY = 'waterSupply'
export const POWER = 'power'

export const INTEGRATION_SCHEMA_ID = 'NEW_INTEGRATION_SYSTEM'
export const INTEGRATION_SCHEMA_CHILD_ID = 'INTEGRATION_SCHEMA_CHILD_ID'

export const syncModuleIcon = {
  [LIGHT]: MapIcons.LightBulbIcon,
  [KULON]: MapIcons.LightBulbIcon,
  [BRIZ]: SyncModule.BrizIcon,
  [ATM]: SyncModule.SimIcon,
  [MESH]: MapIcons.LightBulbIcon,
}

export const ERROR = 'error'
export const ERROR_SIGNAL = 'errorSignal'
export const CONNECTION = 'conncetion'
export const CONNECTION_SIGNAL = 'conncetionSignall'
