import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UpIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.68484 7.48779H8.83176C10.3425 7.48779 11.2636 6.16056 10.4629 5.13736L7.3894 1.20982C6.63604 0.247115 4.88056 0.247117 4.12721 1.20982L1.05375 5.13736C0.253046 6.16056 1.17409 7.48779 2.68484 7.48779Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UpIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UpIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UpIcon)
