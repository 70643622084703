import { connect } from 'react-redux'
import { requestGetInfoCards } from '@/store/actions/serviceDeskMyCity/getInfoCards'
import {
  getLoading,
  getInfoCards,
} from '@/store/selectors/serviceDeskMyCity'
import {
  getIntegrationStatus,
  getUserApplications,
  getUserData,
} from '@/store/selectors/appSettings'
import Statistic from './component'

const mapStateToProps = (state) => ({
  infoCards: getInfoCards(state),
  loading: getLoading(state),
  integrationStatus: getIntegrationStatus(state),
  userApplications: getUserApplications(state),
  currentUser: getUserData(state),
})

const mapDispatchToProps = (dispatch) => ({
  getInfoCards: (payload) => dispatch(requestGetInfoCards(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Statistic)
