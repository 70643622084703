export default {
  desktop: 'Рабочий стол',
  users: 'Пользователи',
  passportization: 'Паспортизация',
  alertManager: 'Уведомления',
  alarms: 'Журнал неисправностей',
  individualControl: 'Контроль',
  groupControl: 'Групповой контроль',
  scheduleManager: 'Менеджер расписаний',
  installation: 'Инсталляции',
  reportManager: 'Менеджер отчетов',
  energyConsumption: 'Энергопотребление',
  life: 'Срок службы',
  objects: 'Центр мониторинга',
  searchWidget: 'Расширенный поиск',
  controlCenter: 'Центр управления',
  equipmentReference: 'Справочники',
  operationAndTechnicalSupport: 'Служба поддержки',
  settings: 'Настройки системы',
  changeLog: 'Журнал изменений',
  foldMenu: 'Свернуть описание',
  menuTitle: 'Меню навигации',
  inventory: 'Инвентаризация',
  lightingObjects: 'Объекты освещения',
  analyticExpenses: 'Потребление ресурсов',
  activityLog: 'Журнал действий',
  carElectricStations: 'Зарядные автостанции',
  waterSupply: 'Водоснабжение',
  heatSupply: 'Теплоснабжение',
  smartRecycleBin: 'Контроль наполняемости мусорных баков',
  controlObject: 'Oбъекты контроля',
}
