import {
  put, takeLatest, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { NAME } from '@/constants/forms/group'
import { GROUP } from '@/constants/viewTree'
import { getSelectedTenant } from '@/store/selectors/users'
import {
  setSelectedTenant,
  CREATE_GROUP,
  setFormIsModifying,
} from '@/store/actions/users'
import {
  errorCreateTenantGroup,
} from '@/store/actions/users/group/createGroup'
import {
  requestGetTenantGroup,
} from '@/store/actions/users/group/getGroup'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import createNotifications from '@/helpers/notification'
import { CREATE_TENANT_GROUP_URL } from '@/constants/apiRoutes'
import { errorObject } from '@/constants/users'
import { setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'

function* createTenanGroupSaga({ payload }) {
  const toast = createNotifications()
  const { groupFrom, permissions, tenantId } = payload
  const groupFromValues = groupFrom.values
  try {
    const selectedTenant = yield select(getSelectedTenant)
    const selectedTenantChildren = selectedTenant.children || {}
    const newSelectedTenantChildren = selectedTenantChildren
      .filter((element) => element.id !== GROUP)
    const newSelectedTenant = {
      ...selectedTenant,
      children: [
        ...newSelectedTenantChildren,
      ],
    }

    const dataJson = {
      permissions,
      name: groupFromValues.organizationName,
    }
    const response = yield request({
      url: CREATE_TENANT_GROUP_URL,
      method: 'post',
      body: dataJson,
    })
    if (response.data) {
      yield put(requestGetTenantGroup({
        tenantId,
        groupId: response.data.id,
      }))
      yield put(setSelectedTenant(newSelectedTenant))
      yield put(requestGetAllAdminTenants({ id: tenantId }))
      yield put(requestGetTenantGroups(tenantId))
      yield put(setFormIsModifying(false))
      toast({
        title: 'Успешная операция!',
        description: `Группа “${response.data.name}” успешно добавлена!`,
      })
      yield put(setTenantServiceDesk([]))
    } else {
      toast({
        title: 'Ошибка операции!',
        description: `Ошибка при создании группы!\nПовторите попытку позже. Код ошибки: ${response.status}`,
        type: 'error',
      })
      groupFrom.setErrors({ [NAME]: 'Ошибка соединения' })
      groupFrom.setSubmitting(false)
    }
  } catch (error) {
    const { response } = error
    const { errorCode } = response.data
    if (errorCode) {
      yield put(errorCreateTenantGroup())
      groupFrom.setErrors({ [NAME]: errorObject[errorCode] || errorCode })
      groupFrom.setSubmitting(false)
      toast({
        title: 'Ошибка операции!',
        description: errorObject[errorCode] || errorCode,
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(CREATE_GROUP.REQUEST, createTenanGroupSaga)
}
