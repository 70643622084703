import { handleActions } from 'redux-actions'

import {
  GET_ALL_CITY_PROCESSES_OPTIONS,
} from '@/store/actions/dictionaries'

const initialState = {
  data: [],
}

export const reducer = handleActions(
  {
    [GET_ALL_CITY_PROCESSES_OPTIONS.EVENTS]: (state, { payload }) => ({
      ...state,
    }),
    [GET_ALL_CITY_PROCESSES_OPTIONS.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [GET_ALL_CITY_PROCESSES_OPTIONS.ERROR]: (state, { payload }) => ({
      ...state,
    }),
  },
  initialState,
)

export default reducer
