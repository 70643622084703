import { GET_CONSUMPTION_ANALYSIS_HEAT_FILE } from './index'

export const requestHeatFile = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_FILE.REQUEST,
  payload,
})

export const successHeatFile = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_FILE.SUCCESS,
  payload,
})

export const errorHeatFile = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_FILE.ERROR,
  payload,
})
