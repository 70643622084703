import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CarElectricStationsIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2068 5.625H8.39137L9.79987 1.06172C9.93207 0.52734 9.55187 0 9.03277 0H5.85187C5.45507 0 5.11787 0.31289 5.06497 0.73125L4.00697 9.1687C3.94413 9.675 4.31447 10.125 4.79387 10.125H7.13827L5.61417 16.9629C5.49507 17.4973 5.87867 18 6.38447 18C6.66217 18 6.92667 17.8453 7.07217 17.5781L12.8912 6.8906C13.1987 6.3316 12.8185 5.625 12.2068 5.625Z" fill="#3D3D3D"/>
    </svg>
  )
}

CarElectricStationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CarElectricStationsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(CarElectricStationsIcon)
