import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import TextField from '@/components/fields/TextField'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import UsersIcons from '@/components/icons/users'
import {
  createAdminSchema,
} from '@/constants/validationFields'
import {
  ADMIN_NAME,
  ADMIN_EMAIL,
} from '@/constants/forms/admin'
import {
  FormWrapper,
  FieldsWrapper,
  InputAndLabelContainer,
  MessageContainer,
  Text,
  MessageText,
} from './styles'


const CreateAdminForm = React.forwardRef(({
  formData,
  blockForm,
  setValidForm,
  adminEditMode,
  selectedAdminTenant,
  isConfirm,
  isFormModifying,
}, ref) => {
  const onSubmit = () => {
    // TODO: add handlers AttentionIcon
  }
  const renderMessage = useCallback((errors, isSubmitting, isDirty) => {
    if (selectedAdminTenant.enabled) {
      return (
        <MessageContainer>
          <UsersIcons.AttentionIcon />
          <MessageText>
            <Lang id="usersPage.createAdmin.messages.confirm" />
          </MessageText>
        </MessageContainer>
      )
    }
    if (formData && formData[ADMIN_NAME] && !isSubmitting && !isDirty && isConfirm) {
      return (
        <MessageContainer>
          <UsersIcons.AttentionIcon />
          <MessageText>
            <Lang id="usersPage.createAdmin.messages.notConfirm" />
          </MessageText>
        </MessageContainer>
      )
    }
    if (errors && (isSubmitting || blockForm)) {
      return (
        <MessageContainer>
          <UsersIcons.AttentionIcon />
          <MessageText>
            <Lang id="usersPage.createAdmin.messages.info" />
          </MessageText>
        </MessageContainer>
      )
    }
    return (
      <MessageContainer>
        <UsersIcons.AttentionIcon />
        <MessageText>
          <Lang id="usersPage.createAdmin.messages.default" />
        </MessageText>
      </MessageContainer>
    )
  }, [selectedAdminTenant.enabled, formData, isConfirm, blockForm])

  const renderTextFiled = useCallback(({
    error,
    name,
    mask,
    fieldProps,
    value,
  }) => {
    if (isFormModifying) {
      return (
        <TextField
          error={error}
          name={name}
          mask={mask}
          fieldProps={fieldProps}
          resetButton
        />
      )
    }
    return (
      <Text>
        {value}
      </Text>
    )
  }, [isFormModifying])

  return (
    <Formik
      ref={ref}
      enableReinitialize
      initialValues={{
        [ADMIN_NAME]: formData[ADMIN_NAME] || '',
        [ADMIN_EMAIL]: formData[ADMIN_EMAIL] || '',
      }}
      validationSchema={createAdminSchema}
      validate={(values) => {
        createAdminSchema.validate(values)
          .then(() => setValidForm(true))
          .catch(() => {
            setValidForm(false)
          })
      }}
      onSubmit={onSubmit}
      render={({
        touched, errors, handleSubmit, isSubmitting, isDirty, values,
      }) => (
        <FormWrapper onSubmit={handleSubmit} id="userData" name="userData">
          <FieldsWrapper>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[ADMIN_NAME] && errors[ADMIN_NAME])}
                title={(<Lang id="usersPage.createAdmin.fields.name" />)}
                userForm
              />
              {renderTextFiled({
                name: ADMIN_NAME,
                fieldProps: {
                  pattern: '[a-zA-Zа-яА-Я]',
                  autoComplete: 'off',
                  disabled: errors ? isSubmitting || blockForm : false,
                },
                value: values[ADMIN_NAME],
              })}
            </InputAndLabelContainer>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[ADMIN_EMAIL] && errors[ADMIN_EMAIL])}
                title={(<Lang id="usersPage.createAdmin.fields.email" />)}
                userForm
              />
              {renderTextFiled({
                name: ADMIN_EMAIL,
                fieldProps: {
                  autoComplete: 'off',
                  disabled: isSubmitting || blockForm,
                },
                value: values[ADMIN_EMAIL],
              })}
            </InputAndLabelContainer>
          </FieldsWrapper>
          {renderMessage(errors, isSubmitting, isDirty)}
        </FormWrapper>
      )}
    />
  )
})

CreateAdminForm.propTypes = {
  formData: pt.shape({
    [ADMIN_NAME]: pt.string,
    [ADMIN_EMAIL]: pt.string,
  }),
  blockForm: pt.bool,
  setValidForm: pt.func,
  onNameChange: pt.func,
  adminEditMode: pt.bool,
}

CreateAdminForm.defaultProps = {
  formData: {
    [ADMIN_NAME]: '',
    [ADMIN_EMAIL]: '',
  },
  blockForm: false,
  setValidForm: noop,
  onNameChange: noop,
  adminEditMode: false,
}

export default React.memo(CreateAdminForm)
