import { all } from 'redux-saga/effects'
import updateUserRoot from './updateUser'
import createUserRoot from './createUser'
import getAllUsersRoot from './getAllUsers'
import deleteUserRoot from './deleteUser'
import getUserRoot from './getUser'
import multipleEditingRoot from './multipleEditing'
import multipleDeleteRoot from './multipleDelete'
import updateUserFromAdmin from './updateUserFromAdmin'

export default function* root() {
  yield all([
    updateUserRoot(),
    createUserRoot(),
    getAllUsersRoot(),
    getUserRoot(),
    deleteUserRoot(),
    multipleEditingRoot(),
    multipleDeleteRoot(),
    updateUserFromAdmin(),
  ])
}
