import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LightningIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" d="M8.2067 5.625H4.39131L5.79981 1.06172C5.932 0.52734 5.55181 0 5.0327 0H1.85181C1.45501 0 1.11781 0.31289 1.06491 0.73125L0.00690496 9.1687C-0.055935 9.675 0.314405 10.125 0.793805 10.125H3.13821L1.61411 16.9629C1.49501 17.4973 1.87861 18 2.38441 18C2.66211 18 2.9266 17.8453 3.0721 17.5781L8.8911 6.8906C9.1986 6.3316 8.8184 5.625 8.2067 5.625Z" />
    </svg>
  )
}

LightningIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LightningIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LightningIcon)
