import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DotsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 2C11 3.10458 10.1046 4 9 4C7.89543 4 7 3.10458 7 2C7 0.895416 7.89543 0 9 0C10.1046 0 11 0.895416 11 2Z" fill={fill} className="svgFill" />
      <path d="M11 16C11 17.1046 10.1046 18 9 18C7.89543 18 7 17.1046 7 16C7 14.8954 7.89543 14 9 14C10.1046 14 11 14.8954 11 16Z" fill={fill} className="svgFill" />
      <path d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89542 10.1046 7 9 7C7.89543 7 7 7.89542 7 9C7 10.1046 7.89543 11 9 11Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DotsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DotsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DotsIcon)
