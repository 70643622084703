import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DepartmentIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.759 4.5H12.5895V5.5H10.759V4.5Z" fill={fill} className="svgFill" />
      <path d="M12.5895 6.18981H10.759V7.18981H12.5895V6.18981Z" fill={fill} className="svgFill" />
      <path d="M10.759 7.87966H12.5895V8.87966H10.759V7.87966Z" fill={fill} className="svgFill" />
      <path d="M12.5895 9.56951H10.759V10.5695H12.5895V9.56951Z" fill={fill} className="svgFill" />
      <path d="M6 9.5H8V10.5H6V9.5Z" fill={fill} className="svgFill" />
      <path d="M8 11.5H6V12.5H8V11.5Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4 15V10L8.5 7.5V12.4947H9.36611V2L13.9754 3.4706V12.5H15.9948L16 15C16 15.7956 15.3679 16.5587 14.2426 17.1213C13.1174 17.6839 11.5913 18 10 18C8.4087 18 6.88258 17.6839 5.75736 17.1213C4.63214 16.5587 4 15.7956 4 15Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DepartmentIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DepartmentIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DepartmentIcon)
