import {
  TEXT
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.LATITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.LONGITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.REGION',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.CITY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.STREET',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.HOME_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.IMPORTANCE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.INSTALLATION_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.CONTROL_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.lightingType',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.WORK_MODE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'SERVICING.INSPECTION_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.BYPASS_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.MAINTENANCE_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.REPAIR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.CAPITAL_REPAIR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.GUARANTEE_EXPIRATION_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
]
