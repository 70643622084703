import React from 'react'

import BarChart from '@/components/charts/BarChart'

const DATA = [
  {
    dateStep: 'Янв 2020',
    done: 5,
    left: 7,
    error: 2,
  },
  {
    dateStep: 'Фев 2020',
    done: 8,
    left: 2,
    error: 3,
  },
  {
    dateStep: 'Мар 2020',
    done: 4,
    left: 5,
    error: 1,
  },
  {
    dateStep: 'Апр 2020',
    done: 3,
    left: 3,
    error: 1,
  },
  {
    dateStep: 'Май 2020',
    done: 6,
    left: 4,
    error: 0,
  },
  {
    dateStep: 'Июн 2020',
    done: 5,
    left: 1,
    error: 1,
  },
]

const SERIES = [
  {
    fill: '#81c13c',
    name: 'done',
    title: 'Соблюден',
  },
  {
    fill: '#f37a24',
    name: 'left',
    title: 'Истекает',
  },
  {
    fill: '#fd351f',
    name: 'error',
    title: 'Нарушен',
  },
]

const Widget = () => (
  <BarChart
    data={DATA}
    seriesNames={SERIES}
    mainAxisName="dateStep"
    customGraphMargin={{
      top: 0,
      right: 10,
      left: -30,
      bottom: -5,
    }}
    legend={{
      align: 'center',
      verticalAlign: 'bottom',
      iconType: 'square',
    }}
  />
)

export default Widget
