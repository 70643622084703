import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generatePutAdmin } from '@/constants/apiRoutes'
import { DELETE_USER, updateSelectedUserType } from '@/store/actions/users'
import { requestGetAllUsers } from '@/store/actions/users/user/getAllUsers'
import { errorDeleteUser } from '@/store/actions/users/user/deleteUser'
import { requestGetTenant } from '@/store/actions/users/tenant/getTenant'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import createNotifications from '@/helpers/notification'
import getFullName from '@/helpers/fullName'

function* deleteUserSaga({ payload }) {
  const {
    id,
    tenantId,
    groupId,
    selectedUser,
  } = payload
  const toast = createNotifications()
  try {
    yield request({
      url: generatePutAdmin(id),
      method: 'delete',
    })
    const fullName = getFullName(
      selectedUser.lastName,
      selectedUser.firstName,
      selectedUser.middleName,
    )
    toast({
      title: 'Успешная операция!',
      description: `Пользователь "${fullName}" был успешно удален!`,
    })
    yield put(requestGetAllUsers({ tenantId, groupId }))
    yield put(requestGetTenant({ id: tenantId, onlyUpdateSelectedTenant: true }))
    yield put(updateSelectedUserType(null))
    yield put(requestGetAllTenants())
    yield put(requestGetTenantGroups(tenantId))
  } catch (error) {
    yield put(errorDeleteUser())
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка удаления! Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_USER.REQUEST, deleteUserSaga)
}
