import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EnergyConsumptionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.458 18.625L25.4735 3H18.0946L15.2094 25.125H24.9582L20.9474 42.4269L34.425 18.625H20.458ZM12.0201 25.4687L15.0765 2.03125C15.2293 0.869141 16.2036 0 17.3497 0H26.5391C28.0387 0 29.1371 1.46484 28.755 2.94922L24.6862 15.625H35.7084C37.4753 15.625 38.5737 17.5879 37.6855 19.1406L20.8752 48.8281C20.4549 49.5703 19.6908 50 18.8885 50C17.4272 50 16.3192 48.6035 16.6631 47.1191L21.0662 28.125H14.2933C12.9084 28.125 11.8386 26.875 12.0201 25.4687Z" fill={fill} className="svgFill" />
    </svg>
  )
}

EnergyConsumptionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EnergyConsumptionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EnergyConsumptionIcon)
