import ToViewIcon from './ToViewIcon'
import InProgressIcon from './InProgressIcon'
import DoneIcon from './DoneIcon'
import GovernmentIcon from './GovernmentIcon'
import UserIcon from './UserIcon'

export default {
  ToViewIcon,
  InProgressIcon,
  DoneIcon,
  GovernmentIcon,
  UserIcon,
}
