import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const NextIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 9L2.45309 17.6981C2.05756 18.1006 1.42442 18.1006 1.02889 17.6981L0.305025 16.9614C-0.101363 16.5479 -0.101722 15.8687 0.304233 15.4546L6.63284 9L0.304234 2.54538C-0.101721 2.13134 -0.101364 1.45215 0.305024 1.03857L1.02889 0.301896C1.42442 -0.100632 2.05756 -0.100632 2.45309 0.301895L11 9Z" fill={fill} className="svgFill" />
    </svg>
  )
}

NextIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NextIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(NextIcon)
