export default {
  createGeoZoneForm: {
    name: 'Название геозоны*',
    parentId: 'Родительская геозона*',
    parentGeoZoneName: 'Родительская геозона',
    parentProjectName: 'Родительский проект',
    lat: 'Географическая широта*',
    lon: 'Географическая долгота*',
    'location[0]': 'Географическая широта',
    'location[1]': 'Географическая долгота',
    'geoLocationDto.latitude': 'Географическая широта',
    'geoLocationDto.longitude': 'Географическая долгота',
    region: 'Регион',
    city: 'Город',
    mark: 'Заметка',
    note: 'Заметка',
    type: 'Тип*',
    geoZoneInstallation: 'Инсталляция геозоны',
    geoZoneInfo: 'Информация о геозоне:',
    projectInfo: 'Информация о проекте:',
    integrationInfo: 'Информация о интеграции:',
    infoStructure: 'Инфраструктура:',
    save: 'Сохранить',
    cancel: 'Отмена',
    projectId: 'Проект*',
    integrationType: 'Тип интеграции*',
  },
  projectForm: {
    name: 'Название проекта*',
    parentId: 'Родительская геозона*',
    parentGeoZoneName: 'Родительская геозона',
    parentProjectName: 'Родительский проект',
    lat: 'Географическая широта*',
    lon: 'Географическая долгота*',
    'location[0]': 'Географическая широта',
    'location[1]': 'Географическая долгота',
    'geoLocationDto.latitude': 'Географическая широта',
    'geoLocationDto.longitude': 'Географическая долгота',
    region: 'Регион',
    city: 'Город',
    mark: 'Заметка',
    note: 'Заметка',
    type: 'Тип*',
    geoZoneInstallation: 'Инсталляция геозоны',
    geoZoneInfo: 'Информация о геозоне:',
    projectInfo: 'Информация о проекте:',
    integrationInfo: 'Информация о интеграции:',
    infoStructure: 'Инфраструктура:',
    save: 'Сохранить',
    cancel: 'Отмена',
    projectId: 'Проект*',
    integrationType: 'Тип интеграции*',
  },
  integrationForm: {
    name: 'Название интеграции*',
    parentId: 'Родительская геозона*',
    parentGeoZoneName: 'Родительская геозона',
    parentProjectName: 'Родительский проект',
    lat: 'Географическая широта*',
    lon: 'Географическая долгота*',
    'location[0]': 'Географическая широта',
    'location[1]': 'Географическая долгота',
    'geoLocationDto.latitude': 'Географическая широта',
    'geoLocationDto.longitude': 'Географическая долгота',
    region: 'Регион',
    city: 'Город',
    mark: 'Заметка',
    note: 'Заметка',
    type: 'Тип*',
    geoZoneInstallation: 'Инсталляция геозоны',
    geoZoneInfo: 'Информация о геозоне:',
    projectInfo: 'Информация о проекте:',
    integrationInfo: 'Информация о интеграции:',
    infoStructure: 'Инфраструктура:',
    save: 'Сохранить',
    cancel: 'Отмена',
    projectId: 'Проект*',
    integrationType: 'Тип интеграции*',
  },
}
