import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 290px;
  height: 498px;
  display: flex;
  box-shadow: 2px 3px 15px ${({ theme }) => theme.colors.shadows.widgetPrimary};
  background: ${({ theme }) => theme.colors.backgrounds.drawer};
  padding: 15px;
  margin-right: 10px;
  flex-direction: column;
  border-radius: 4px;

  &:last-child {
    margin-right: 0;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${({ main }) => main && `
    opacity: 0.3;
  `};
  ${({ selected }) => selected && `
    opacity: 1;
  `};
  ${({ header }) => header && `
    margin-bottom: 16px;
  `};
`

export const Title = styled.div`
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-left: 15px;
  text-align: left;
  ${({ header }) => header && `
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-left: 10px;
  `};
`

export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-left: 5px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.darkGray};
  ${({ selected, theme }) => selected && `
    background-color: ${theme.colors.backgrounds.redisign.green};
  `};
`
