import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ChartIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.9 0V2.73383C12.951 3.17594 15.3 5.79248 15.3 8.97744C15.3 9.78947 15.138 10.5564 14.868 11.2692L17.208 12.6496C17.712 11.5308 18 10.2857 18 8.97744C18 4.30376 14.445 0.451128 9.9 0ZM9 15.2932C5.517 15.2932 2.7 12.4692 2.7 8.97744C2.7 5.79248 5.049 3.17594 8.1 2.73383V0C3.546 0.451128 0 4.29474 0 8.97744C0 13.9579 4.023 18 8.991 18C11.97 18 14.607 16.5474 16.245 14.3098L13.905 12.9293C13.3179 13.6683 12.5718 14.2648 11.7224 14.6741C10.873 15.0834 9.9424 15.2951 9 15.2932Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ChartIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ChartIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ChartIcon)
