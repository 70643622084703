import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import Loader from '@/components/blocks/Loader'
import { getIsNotRegion, getZoomByType } from '@/helpers/maps'
import { getNodePath } from '@/helpers/viewTree/formaters'
import { icons } from '@/constants/maps'
import { filterTypes } from '@/constants/passportization'
import TextControl from '@/components/controls/TextControl'
import YandexMap from '@/components/blocks/YandexMap'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import PassportSidebar from '@/components/regions/sidebars/PassportSidebar'
import UsersIcons from '@/components/icons/users'
import { toFlat } from '@/helpers/getZonesMock'
import { INFO } from '@/constants/objectStatuses'
import {
  GROUP_OBJECT,
  COUNTRY,
  REGION,
  CITY,
} from '@/constants/objectTypes'
import {
  Main,
  MapControls,
  MapControlsContent,
  TitleAndIcon,
} from './styles'
import { MAP_SERVICE } from '@/constants/applications'

const DEFAULT_CENTER = [56.0360129, 35.9583358]

class Passportization extends React.PureComponent {
  handleChange = debounce((value) => {
    this.setState({ search: value })
  }, 300)

  constructor(props) {
    super(props)
    const {
      treeData, regions, pins, selectedNode, userApplications
    } = props
    this.state = {
      mapZoom: (selectedNode && selectedNode.aliasId) ? 19 : 3,
      mapCenter: selectedNode.location || DEFAULT_CENTER,
      search: '',
      hoverZoneByID: null,
      itemsOnSelectedRegion: [],
      hoveredItemByID: null,
      idOfSelectedZone: null,
      typesOfRegionPins: filterTypes,
      treeStructureData: cloneDeep(treeData),
      dataSet: { regions, pins },
      passportIsOpen: false,
      selectedZoneIdentifier: null,
      typesWithoutPassportization: [
        GROUP_OBJECT,
        COUNTRY,
        REGION,
        CITY,
      ],
      userApplications: userApplications
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (!isEqual(props.pins, state.dataSet.pins)) {
      return {
        treeStructureData: props.treeData,
        dataSet: { ...state.dataSet, pins: props.pins },
      }
    }
    return null
  }

  componentDidMount() {
    const {
      requestGetMapObjects,
      operatedElement,
      selectedNode,
    } = this.props
    const zoneId = selectedNode.geoZoneId || selectedNode.id
    this.setState(() => ({
      selectedZoneIdentifier: zoneId,
    }))

    if (
      ((selectedNode && selectedNode.id) && !((operatedElement && operatedElement.id)))
      || (operatedElement && operatedElement.id)
    ) {
      this.handleOpenPassport(selectedNode)
    }
    requestGetMapObjects()
  }

  getObjectTelemetry = (object) => {
    const { telemetry } = this.props
    return telemetry[`${object.type}-${object.aliasId}-${object.id}`] || {}
  }

  getFalseObjectTelemetry = () => ({
    status: INFO,
  })

  getSelectedNodeWithTelemetry = () => {
    const { selectedNode } = this.props
    const telemetry = this.getObjectTelemetry(selectedNode)

    return {
      ...selectedNode,
      status: telemetry.status || selectedNode.status,
    }
  }

  getIDOfSelectedZone = (element) => () => {
    this.setState(() => ({
      idOfSelectedZone: element.id,
      mapCenter: element.location,
      mapZoom: getZoomByType(element.type),
    }))
  }

  setMapCenterForLocationZoom = (element) => () => {
    this.setState(() => ({
      mapCenter: element.location,
      mapZoom: getZoomByType(element.type),
    }))
  }

  setIdOfSelectedPinOnMouseEnter = (element) => {
    this.setState(() => ({ hoveredItemByID: element.id }))
  }

  setIdOfSelectedPinOnMouseLeave = () => {
    this.setState(() => ({ hoveredItemByID: null }))
  }

  listItemMapMouseEnter = (element) => {
    this.setState(() => ({ hoveredItemByID: element.id }))
  }

  listItemMapMouseLeave = () => {
    this.setState(() => ({ hoveredItemByID: null }))
  }

  treeZoneMouseEnter = (element) => {
    this.setState(() => ({ hoverZoneByID: element.id }))
  }

  treeZoneMouseLeave = () => {
    this.setState(() => ({ hoverZoneByID: null }))
  }

  setMapCenterForItem = (item) => () => {
    this.setState({
      mapCenter: item.location,
    })
  }

  getSelectedNode = () => {
    const { treeData, selectedNode } = this.props

    return get(treeData, getNodePath(selectedNode), {})
  }

  updateMapZoom = (zoom) => {
    this.setState(() => ({ mapZoom: zoom }))
  }

  handleOpenPassport = (node, fromTree) => {
    const { typesWithoutPassportization, hoveredItemByID } = this.state
    const { setNode, selectedNode } = this.props

    if (!typesWithoutPassportization.includes(node.type)) {
      this.setState({ passportIsOpen: true })
    } else {
      this.setState({ passportIsOpen: false })
    }
    const zoneId = node.geoZoneId || node.id
    this.setState(() => ({
      selectedZoneIdentifier: zoneId,
      hoveredItemByID: getIsNotRegion(node) ? node.id : hoveredItemByID,
    }))
    if
    (
      node.id !== selectedNode.id
      || node.name !== selectedNode.name
    ) {
      if (fromTree) {
        setNode(node)
        return null
      }
      setNode(node, true)
    }
  }

  handleClosePassport = () => {
    this.setState({ passportIsOpen: false })
  }

  setPassportIsOpen = () => {
    this.setState({ passportIsOpen: false })
  }

  setSelectedZoneIdentifier = (id) => {
    this.setState(() => ({
      selectedZoneIdentifier: id,
    }))
  }

  selectNodeByZone = (node) => {
    const { newZoom, newCenter } = node
    const { treeStructureData } = this.state
    const { setNode } = this.props
    const flatTree = toFlat(treeStructureData) || []
    const selectedNodes = flatTree
      .filter((element) => element.name === node.name || element.geoZoneId === node.pinSelector)
    const selectedNode = selectedNodes[0] || {}
    setNode(selectedNode, true)
    this.setState(() => ({
      mapCenter: newCenter || (selectedNode || {}).location || [60, 90],
      mapZoom: newZoom || getZoomByType((selectedNode || {}).type) || 8,
      passportIsOpen: getIsNotRegion(selectedNode.type),
    }))
  }

  viewTreeOnSelect = (node, actionType) => {
    if (actionType === 'click') {
      this.handleOpenPassport(node, true)
      this.setMapCenterForLocationZoom(node)()
      this.getIDOfSelectedZone(node)()
    }
    if (actionType === 'in') {
      return getIsNotRegion(node.type)
        ? this.listItemMapMouseEnter(node)
        : this.treeZoneMouseEnter(node)
    }
    if (actionType === 'out') {
      return getIsNotRegion(node.type)
        ? this.listItemMapMouseLeave(node)
        : this.treeZoneMouseLeave(node)
    }
    return null
  }

  renderIconAndTitle = (iconName = null, size = 'big', title = null, onClick = noop) => {
    const Icon = icons[iconName]
    return (
      <TitleAndIcon onClick={onClick} size={size}>
        {Icon && <Icon />}
        {title}
      </TitleAndIcon>
    )
  }

  render() {
    const {
      search,
      dataSet,
      mapCenter,
      mapZoom,
      hoveredItemByID,
      hoverZoneByID,
      passportIsOpen,
      typesOfRegionPins,
      selectedZoneIdentifier,
      userApplications
    } = this.state
    const isMapHasPins = (dataSet && dataSet.pins).some((pin) => !!pin.id)
    const mapKey = (dataSet ? dataSet.pins : []).length
    const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0].settings
    const {
      treeData,
      loading,
      requestStatus,
      operatedElement,
      selectedNode,
      selectedElementEquipment,
      data,
      requestAddPassportFile,
      requestAddPassportPhoto,
      passportFile,
      passportPhoto,
      requestDeletePassportPhoto,
      requestDeletePassportFile,
      requestExportPassportFile,
      requestGetPassportFile,
      setOperatedElement,
      requestGetObjectEquipment,
      requestUpdatePassport,
      zones,
      pinnedNode,
    } = this.props

    if (loading) return <Loader center />
    return (
      <Main>
        <PageSidebar
          onIconClick={this.switchFilterMode}
          title={<Lang id="passportization.title" />}
          typesOfRegionPins={typesOfRegionPins}
          treeData={treeData}
          mapKey={mapKey}
          searchQuery={search}
          onSelect={this.viewTreeOnSelect}
          headerContent={(
            <Lang id="mapsPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={this.handleChange}
                />
              )}
            </Lang>
          )}
        />
        <MapControls>
          <MapControlsContent>
            {passportIsOpen && (
              <PassportSidebar
                type={operatedElement.type}
                data={data}
                selectedNode={selectedNode}
                requestStatus={requestStatus}
                operatedElement={operatedElement}
                selectedElementEquipment={selectedElementEquipment}
                setOperatedElement={setOperatedElement}
                requestAddPassportFile={requestAddPassportFile}
                requestAddPassportPhoto={requestAddPassportPhoto}
                passportFile={passportFile}
                passportPhoto={passportPhoto}
                requestDeletePassportFile={requestDeletePassportFile}
                requestDeletePassportPhoto={requestDeletePassportPhoto}
                requestExportPassportFile={requestExportPassportFile}
                requestUpdatePassport={requestUpdatePassport}
                requestGetPassportFile={requestGetPassportFile}
                requestObjectEquipment={requestGetObjectEquipment}
              />
            )}
            {isMapHasPins && (
              <YandexMap
                pinnedNode={pinnedNode}
                globalZoneId={selectedZoneIdentifier}
                setSelectedZoneIdentifier={this.setSelectedZoneIdentifier}
                zones={zones}
                key={mapKey}
                onClick={this.handleClosePassport}
                dataSet={dataSet}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                mash={[]}
                hoveredPinByID={hoveredItemByID}
                hoveredZoneByID={hoverZoneByID}
                getTelemetry={this.getFalseObjectTelemetry}
                updateMapZoom={this.updateMapZoom}
                handleOpenPassport={this.handleOpenPassport}
                pinOnEnterHandler={this.setIdOfSelectedPinOnMouseEnter}
                pinOnLeaveHandler={this.setIdOfSelectedPinOnMouseLeave}
                selectNodeByZone={this.selectNodeByZone}
                yandexKey={yandexKey}
              />
            )}
          </MapControlsContent>
        </MapControls>
      </Main>
    )
  }
}

Passportization.propTypes = {
  loading: pt.bool.isRequired,
  requestGetMapObjects: pt.func.isRequired,
  requestUpdatePassport: pt.func.isRequired,
  setNode: pt.func,
  regions: pt.arrayOf(pt.object).isRequired,
  telemetry: pt.objectOf(pt.object).isRequired,
  pins: pt.arrayOf(pt.object).isRequired,
  treeData: pt.shape({
    id: pt.number,
  }).isRequired,
  selectedNode: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    location: pt.arrayOf(pt.number),
    type: pt.string,
    status: pt.string,
  }).isRequired,
  userApplications: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    code: pt.string,
    defaultType: pt.string,
    name: pt.string,
    placement: pt.string,
    settings: pt.string,
    type: pt.string,
  })),
}
Passportization.defaultProps = {
  setNode: noop,
}

export default Passportization
