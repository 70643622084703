export const TABLE = 'TABLE'
export const GRAPH = 'GRAPH'

export const OBJECT = 'object'
export const GEOZONE = 'geoZone'
export const PROJECT = 'project'
export const ADDRES = 'adress'
export const CURR_PERIUD = 'currPeriud'
export const PLANED = 'planed'
export const PLANED_DELTA = 'planedDelta'
export const PREV_PERIUD = 'prevPeriud'
export const FACT_PERIUD = 'factPeriud'
export const FACT_DELTA_PERIUD = 'factDeltaPeriud'

export const USER_PERIUD = 'userPeriud'
