import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const OkIcon = ({ onClick }) => (
  <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM14.1716 4.1142C13.8812 3.92865 13.4853 3.97337 13.2524 4.21805L7.94688 9.79164C7.68931 10.0622 7.23948 10.084 6.95235 9.83987L4.85566 8.05698C4.58953 7.83067 4.17872 7.83057 3.91246 8.05674L3.22311 8.64228C2.96543 8.86116 2.92589 9.22227 3.13096 9.48393L6.46252 13.7348C6.59305 13.9013 6.80278 14 7.02614 14H7.92108C8.14778 14 8.36021 13.8983 8.49017 13.7277L14.8745 5.34279C15.0949 5.05336 15.0175 4.65448 14.7018 4.45286L14.1716 4.1142Z" fill="#90C737" />
  </svg>
)

OkIcon.propTypes = {
  onClick: pt.func,
}

OkIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(OkIcon)
