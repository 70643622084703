import styled from 'styled-components'

export const Container = styled.div`
  padding: 28px 22px;
  overflow: auto;
`

export const DataWrapper = styled.div`
  margin-top: 15px;
`

export const DataRow = styled.div`
  display: block;
  margin-bottom: 15px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  b {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
`

export const ParameterName = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: 5px;
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`
