import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const CountrieFolderIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" d="M5.91429 7.63636C5.91409 7.37878 5.94675 7.12221 6.01149 6.87273H7.11C7.058 7.38052 7.058 7.89221 7.11 8.4H6.01149C5.94823 8.15564 5.91429 7.89982 5.91429 7.63636Z" fill={fill} />
      <path className="svgFill" d="M7.23189 6.10909H6.327C6.63835 5.57654 7.10428 5.14898 7.66427 4.88193C7.4826 5.22824 7.33449 5.64442 7.23189 6.10909Z" fill={fill} />
      <path className="svgFill" d="M10.89 6.87273H11.9885C12.1184 7.3737 12.1184 7.89902 11.9885 8.4H10.89C10.942 7.89221 10.942 7.38052 10.89 6.87273Z" fill={fill} />
      <path className="svgFill" d="M10.3357 4.88193C10.8957 5.14898 11.3616 5.57654 11.673 6.10909H10.7681C10.6655 5.64442 10.5178 5.22824 10.3357 4.88193Z" fill={fill} />
      <path className="svgFill" d="M7.84286 7.63636C7.84273 7.38122 7.85716 7.12627 7.88606 6.87273H10.1139C10.172 7.38019 10.172 7.89253 10.1139 8.4H7.88606C7.85751 8.15373 7.84286 7.89791 7.84286 7.63636Z" fill={fill} />
      <path className="svgFill" d="M9.67384 5.27787C9.80441 5.54339 9.90566 5.82208 9.97586 6.10909H8.02414C8.10244 5.79982 8.20466 5.51918 8.32616 5.27787C8.56839 4.79869 8.8272 4.58182 9 4.58182C9.17241 4.58182 9.43161 4.79869 9.67384 5.27787Z" fill={fill} />
      <path className="svgFill" d="M7.66427 10.3908C7.10428 10.1237 6.63835 9.69618 6.327 9.16364H7.23189C7.33449 9.62831 7.48221 10.0445 7.66427 10.3908Z" fill={fill} />
      <path className="svgFill" d="M10.7681 9.16364H11.673C11.3616 9.69618 10.8957 10.1237 10.3357 10.3908C10.5174 10.0445 10.6655 9.62831 10.7681 9.16364Z" fill={fill} />
      <path className="svgFill" d="M8.32616 9.99485C8.20466 9.75355 8.10244 9.47291 8.02414 9.16364H9.97586C9.89756 9.47291 9.79534 9.75355 9.67384 9.99485C9.43161 10.474 9.1728 10.6909 9 10.6909C8.82759 10.6909 8.56839 10.474 8.32616 9.99485Z" fill={fill} />
      <path className="svgFill" fillRule="evenodd" clipRule="evenodd" d="M16.9091 1.61538H8.67865L7.56342 0.365199C7.35632 0.133034 7.05808 0 6.74471 0H1.09091C0.488417 0 0 0.482155 0 1.07692V12.9231C0 13.5178 0.488417 14 1.09091 14H16.9091C17.5116 14 18 13.5178 18 12.9231V2.69231C18 2.09754 17.5116 1.61538 16.9091 1.61538ZM5.14286 7.63636C5.14286 9.74514 6.8697 11.4545 9 11.4545C11.1303 11.4545 12.8571 9.74514 12.8571 7.63636C12.8571 5.52758 11.1303 3.81818 9 3.81818C6.8697 3.81818 5.14286 5.52758 5.14286 7.63636Z" fill={fill} />
    </svg>
  )
}

CountrieFolderIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CountrieFolderIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(CountrieFolderIcon)
