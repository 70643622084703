import {
  TRASH,
  LIGHT,
  CONTROLLER,
  LIGHT_SENSOR,
} from '@/constants/objectTypes'
import trash from './trash'
import light from './light'
import controller from './controller'
import lightSensor from './lightSensor'

export default {
  [TRASH]: trash,
  [LIGHT]: light,
  [CONTROLLER]: controller,
  [LIGHT_SENSOR]: lightSensor,
}
