import { connect } from 'react-redux'
import {
  setIntegration,
  selectSystem,
  setMode,
  updatedScheme
} from '@/store/actions/integrations'
import { requestGetIntegrations } from '@/store/actions/integrations/getIntegrations'
import { requestGetIntegration } from '@/store/actions/integrations/getIntegration'
import { requestCreateIntegration, errorCreateIntegration } from '@/store/actions/integrations/createIntegration'
import { requestUpdateIntegration } from '@/store/actions/integrations/updateIntegration'
import { requestRepeatIntegration } from '@/store/actions/integrations/repeatIntegration'
import { requestDeleteIntegration } from '@/store/actions/integrations/deleteIntegration'
import { requestGetAllIntegrations } from '@/store/actions/installation/getAllIntegrations'

import {
  getIntegrationTypes,
  getIntegrationOptions,
  getSystems,
  getSelectedSystemId,
  getSelectedIntegration,
  getSyncMode,
  getSyncInProgress,
  getSyncError,
  getIntegrationSchemeById,
} from '@/store/selectors/integrations'
import {
  getIntegrationByType,
  getIntegrationRequestStatus,
} from '@/store/selectors/installation'
import { getUserData } from '@/store/selectors/appSettings'
import IntegrationList from '@/components/blocks/IntegrationList'

export default connect(
  (state) => ({
    syncInProgress: getSyncInProgress(state),
    integrationTypes: getIntegrationTypes(state),
    integrationOptions: getIntegrationOptions(state),
    connectedIntegrations: getSystems(state),
    selectedSystemId: getSelectedSystemId(state),
    selectedIntegration: getSelectedIntegration(state),
    synchronizationErrors: getSyncError(state),
    mode: getSyncMode(state),
    schemeByID: getIntegrationSchemeById(state),
    user: getUserData(state),
    integration: getIntegrationByType(state),
    integrationRequestStatus: getIntegrationRequestStatus(state),
  }),
  (dispatch) => ({
    requestGetIntegrations: () => dispatch(requestGetIntegrations()),
    requestGetIntegration: (data) => dispatch(requestGetIntegration(data)),
    requestCreateIntegration: (data) => dispatch(requestCreateIntegration(data)),
    requestUpdateIntegration: (data) => dispatch(requestUpdateIntegration(data)),
    requestRepeatIntegration: (data) => dispatch(requestRepeatIntegration(data)),
    requestDeleteIntegration: (data) => dispatch(requestDeleteIntegration(data)),
    errorCreateIntegration: (data) => dispatch(errorCreateIntegration(data)),

    requestGetAllIntegrations: (data) => dispatch(requestGetAllIntegrations(data)),

    onSelectSystem: (id) => dispatch(selectSystem(id)),
    onSetMode: (mode) => dispatch(setMode(mode)),
    onSetIntegration: (integration) => dispatch(setIntegration(integration)),
    updatedScheme: (newScheme) => dispatch(updatedScheme(newScheme)),
  }),
)(IntegrationList)
