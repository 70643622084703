import { all } from 'redux-saga/effects'
import appSettingsSagas from './appSettings'
import mapsSagas from './maps'
import userSagas from './user'
import socketSagas from './socket'
import integrationsSagas from './integrations'
import usersSagas from './users'
import consumptionAnalysisSagas from './consumptionAnalysis'
import dictionariesSagas from './dictionaries'
import lifeTimeReportSagas from './lifeTimeReport'
import alarmManagerSagas from './alarmManager'
import analyticsAlarmsSagas from './analyticsAlarms'
import faultAnalyticsSagas from './faultAnalytics'
import passportizationSagas from './passportization'
import tyumenOurHomeSagas from './tyumenOurHome'
import serviceDeskMyCitySagas from './serviceDeskMyCity'
import widgetDashboardSagas from './widgetDashboard'
import installationSagas from './installation'
import reportManagerSagas from './reportManager'

function* root() {
  yield all([
    appSettingsSagas(),
    socketSagas(),
    mapsSagas(),
    userSagas(),
    integrationsSagas(),
    usersSagas(),
    consumptionAnalysisSagas(),
    dictionariesSagas(),
    lifeTimeReportSagas(),
    alarmManagerSagas(),
    analyticsAlarmsSagas(),
    faultAnalyticsSagas(),
    passportizationSagas(),
    tyumenOurHomeSagas(),
    serviceDeskMyCitySagas(),
    widgetDashboardSagas(),
    installationSagas(),
    reportManagerSagas(),
  ])
}

export default root
