import {
  takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_REPORT_MANAGER_TYPES,
} from '@/store/actions/reportManager'
import { REPORT_MANAGER_TYPES_URL } from '@/constants/apiRoutes'
import {
  successReportManagerTypes,
  errorReportManagerTypes,
} from '@/store/actions/reportManager/getTypes'

function* getReportManagerTypesSaga() {
  try {
    const response = yield request({
      url: REPORT_MANAGER_TYPES_URL,
      method: 'get',
    })

    const sortedResponse = response.data.items.map((element) => ({
      value: element.code,
      ...element,
    }))
    yield put(successReportManagerTypes(sortedResponse))
  } catch (error) {
    yield put(errorReportManagerTypes(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_REPORT_MANAGER_TYPES.REQUEST, getReportManagerTypesSaga)
}
