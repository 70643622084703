import { MULTIPLE_EDITING } from '../index'

export const requestUsersMultipleEditing = (data) => ({
  type: MULTIPLE_EDITING.REQUEST,
  payload: data,
})

export const responseUsersMultipleEditing = (data) => ({
  type: MULTIPLE_EDITING.SUCCESS,
  payload: data,
})

export const errorUsersMultipleEditing = (data) => ({
  type: MULTIPLE_EDITING.ERROR,
  payload: data,
})
