import { handleActions } from 'redux-actions'
import get from 'lodash/get'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'

import {
  GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA,
  GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA,

  GET_CONSUMPTION_ANALYSIS_TABLE_DATA,
  GET_CONSUMPTION_ANALYSIS_GRAPH_DATA,
  GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA,
  CONSUMPTION_ANALYSIS_TABLE,

  CLEAR_DATA,
  SET_TENANT_GRAPH_TYPE,
  SET_RANGE,
  SET_SAVINGS,
  SET_TYPE,
  SET_FORM_VALUES,
  GET_CONSUMPTION_ANALYSIS_SAVING,
} from '@/store/actions/consumptionAnalysis'
import {
  DEMONSTRATION_TABLE,
  OUTDOOR_LIGHTING,
  HEAT_SUPPLY,
} from '@/constants/names'

import {
  consumptionAnalysisFieldsElectricity,
  consumptionAnalysisFieldsHeat,
} from '@/constants/tablesConfig/consumptionAnalysis'

import {
  INTEGRATION_TYPES,
} from '@/constants/forms/analyticExpenses'

const tablesFieldsByType = {
  [OUTDOOR_LIGHTING]: consumptionAnalysisFieldsElectricity,
  [HEAT_SUPPLY]: consumptionAnalysisFieldsHeat,
}

const tablesSortFieldByType = {
  [OUTDOOR_LIGHTING]: {},
  [HEAT_SUPPLY]: {
    field: 'date',
    direction: 'DESC',
  },
}

const defaultStateValues = {
  data: [],
  calculatorValue: null,
  saving: {
    min: null,
    average: null,
    max: null,
  },
}

const initialState = {
  statusTable: REQUEST_STATUSES.NOT_REQUESTED,
  statusGraph: REQUEST_STATUSES.NOT_REQUESTED,
  [INTEGRATION_TYPES]: null,
  graphType: null,
  type: null,
  form: {},
  table: generateDefaultTableState(consumptionAnalysisFieldsElectricity),
  ...defaultStateValues,
}

export const reducer = handleActions(
  {
    [CLEAR_DATA]: (state) => ({
      ...state,
      ...defaultStateValues,
    }),
    [GET_CONSUMPTION_ANALYSIS_TABLE_DATA.REQUEST]: (state) => ({
      ...state,
      ...defaultStateValues,
      statusTable: REQUEST_STATUSES.PENDING,
    }),
    [GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.REQUEST]: (state) => ({
      ...state,
      ...defaultStateValues,
      statusGraph: REQUEST_STATUSES.PENDING,
    }),
    [GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA.REQUEST]: (state) => ({
      ...state,
      ...defaultStateValues,
      statusTable: REQUEST_STATUSES.PENDING,
    }),
    [GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA.REQUEST]: (state) => ({
      ...state,
      ...defaultStateValues,
      statusGraph: REQUEST_STATUSES.PENDING,
    }),
    [GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.REQUEST]: (state) => ({
      ...state,
      ...defaultStateValues,
      statusGraph: REQUEST_STATUSES.PENDING,
    }),
    [GET_CONSUMPTION_ANALYSIS_SAVING.SUCCESS]: (state, { payload }) => ({
      ...state,
      saving: payload,
    }),
    [GET_CONSUMPTION_ANALYSIS_TABLE_DATA.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload.content,
      graphType: DEMONSTRATION_TABLE,
      statusTable: REQUEST_STATUSES.IDLE,
      statusGraph: REQUEST_STATUSES.NOT_REQUESTED,
      ...generateTableParameter(state, 'resultCount', payload.totalElements),
    }),
    [GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: payload.content,
      statusGraph: REQUEST_STATUSES.IDLE,
      statusTable: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: payload.content,
      statusGraph: REQUEST_STATUSES.IDLE,
      statusTable: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.ERROR]: (state) => ({
      ...state,
      graphType: get(state, 'form.demonstration', null),
      statusGraph: REQUEST_STATUSES.ERROR,
    }),
    [GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.ERROR]: (state) => ({
      ...state,
      graphType: get(state, 'form.demonstration', null),
      statusGraph: REQUEST_STATUSES.ERROR,
    }),
    [GET_CONSUMPTION_ANALYSIS_TABLE_DATA.ERROR]: (state) => ({
      ...state,
      graphType: get(state, 'form.demonstration', null),
      statusTable: REQUEST_STATUSES.ERROR,
    }),
    [SET_TENANT_GRAPH_TYPE]: (state, { payload }) => ({
      ...state,
      graphType: payload,
    }),
    [SET_TYPE]: (state, { payload }) => ({
      ...state,
      type: payload,
    }),
    [SET_RANGE]: (state, { payload: { start, end } }) => ({
      ...state,
      range: {
        start,
        end,
      },
    }),
    [SET_SAVINGS]: (state, { payload }) => ({
      ...state,
      saving: payload,
    }),
    [SET_FORM_VALUES]: (state, { payload }) => ({
      ...state,
      ...generateTableParameter(
        {
          ...state,
          form: payload,
          table: generateDefaultTableState(
            tablesFieldsByType[payload[INTEGRATION_TYPES]],
            tablesSortFieldByType[payload[INTEGRATION_TYPES]],
          ),
        },
        'page',
        1,
      ),
      [INTEGRATION_TYPES]: payload[INTEGRATION_TYPES] || null,
      form: payload,
    }),
    ...generateDefaultTableReducer(CONSUMPTION_ANALYSIS_TABLE),
  },
  initialState,
)

export default reducer
