import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SaveIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="mdi-content-save" d="M17 9H3.66667V3.66667H17V9ZM13 22.3333C10.7867 22.3333 9 20.5467 9 18.3333C9 16.12 10.7867 14.3333 13 14.3333C15.2133 14.3333 17 16.12 17 18.3333C17 20.5467 15.2133 22.3333 13 22.3333ZM19.6667 1H3.66667C2.95942 1 2.28115 1.28095 1.78105 1.78105C1.28095 2.28115 1 2.95942 1 3.66667V22.3333C1 23.0406 1.28095 23.7189 1.78105 24.219C2.28115 24.719 2.95942 25 3.66667 25H22.3333C23.8 25 25 23.8 25 22.3333V6.33333L19.6667 1Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

SaveIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SaveIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SaveIcon)
