import LightBulbIcon from '@/components/icons/maps/LightBulbIcon'
import HeatIcon from '@/components/icons/maps/HeatIcon'
import WaterIcon from '@/components/icons/maps/WaterIcon'
import ControllerIcon from '@/components/icons/maps/ControllerIcon'
import TrashpinIcon from '@/components/icons/maps/TrashpinIcon'
import ContractorIcon from '@/components/icons/maps/ContractorIcon'
import LEPIcon from '@/components/icons/maps/LEPIcon'
import PoleIcon from '@/components/icons/maps/PoleIcon'
import ControllerChipIcon from '@/components/icons/maps/ControllerChipIcon'
import SencorIcon from '@/components/icons/maps/SencorIcon'
import syncModuleIcons from '@/components/icons/sync'
import {
  ELECTRIC_METER as ELECTRIC_METER_SERVER,
  MULTIRATE_METER as MULTIRATE_METER_SERVER,
  CONTROLLER as CONTROLLER_SERVER,
  CONTACTOR as CONTACTOR_SERVER,
  BRANCH_CIRCUIT as BRANCH_CIRCUIT_SERVER,
  LIGHT_SENSOR as LIGHT_SENSOR_SERVER,
  PHOTO_SENSOR as PHOTO_SENSOR_SERVER,
  VOLUME_SENSOR as VOLUME_SENSOR_SERVER,
  FIRE_SENSOR as FIRE_SENSOR_SERVER,
  TRANSFER_SWITCH as TRANSFER_SWITCH_SERVER,
  DMX_DEVICE as DMX_DEVICE_SERVER,
  CONTROLLER_FUSE as CONTROLLER_FUSE_SERVER,
  FUSE as FUSE_SERVER,
  CHECKPOINT as CHECKPOINT_SERVER,
  CURRENT_SENSOR as CURRENT_SENSOR_SERVER,
  SHUO as SHUO_SERVER,
  CONTROL_CUPBOARD as CONTROL_CUPBOARD_SERVER,
  MULTI_RATE_METER as MULTI_RATE_METER_SERVER,
  METEO as METEO_SERVER,
  ROAD_TEMPERATURE_SENSOR as ROAD_TEMPERATURE_SENSOR_SERVER,
  DOOR_OPEN_SENSOR as DOOR_OPEN_SENSOR_SERVER,
  SMOKE_SENSOR as SMOKE_SENSOR_SERVER,
  BASE_STATION as BASE_STATION_SERVER,
  LIGHT_FIXTURE_GROUP as LIGHT_FIXTURE_GROUP_SERVER,
  UTILITY_POLE as UTILITY_POLE_SERVER,
  LIGHT_MODULE as LIGHT_MODULE_SERVER,
  LINE as LINE_SERVER,
  COMPANY_OBJECT as COMPANY_OBJECT_SERVER,
  DEVICE as DEVICE_SERVER,
  WATHER_SUPPLY as WATHER_SUPPLY_SERVER,
  CENTRAL_HEATING as CENTRAL_HEATING_SERVER,
  SWITCHING_GROUP as SWITCHING_GROUP_SERVER,
  STREETLIGHT_CONTROL_CABINET as STREETLIGHT_CONTROL_CABINET_SERVER,
  STREETLIGHT_GROUP as STREETLIGHT_GROUP_SERVER,
  STREETLIGHT as STREETLIGHT_SERVER,
  AMBIENT_LIGHT_SENSOR as AMBIENT_LIGHT_SENSOR_SERVER,
  WASTE_CONTAINER as WASTE_CONTAINER_SERVER,
  LIGHT_FIXTURE as LIGHT_FIXTURE_SERVER,
  SENSOR as SENSOR_SERVER,
  LIGHT_POINT as LIGHT_POINT_SERVER,
  LAMP as LAMP_SERVER,
  LAMP_MODULE as LAMP_MODULE_SERVER,
  PHASE_CIRCUIT_BREAKER as PHASE_CIRCUIT_BREAKER_SERVER,
} from '@/constants/instalationPassport/types'

import {
  FUEL_YELLOW, REGENT_GRAY,
  MALACHITE, RED, EBONY_CLAY,
  WHITE, CADET_BLUE,
  MALACHITE_LIGHT, RED_LIGHT,
  SHARK_DARK, BLUE_LIGHT, BLUE_DARK,
  BOULDER, YELLOW_LIGHT, ORANGE_PEEL,
  MIDDLE_RED, ATLANTIS_LIGHT, WHITE_GRAY,
} from './defaultTheme'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from '../objectStatuses'
import {
  CONTROLLER,
  GROUP_OBJECT,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
  LIGHT,
  SHUO,
  LIGHT_SENSOR,
  TRASH,

  ELECTRIC_METER,
  CONTACTORS,
  CONTACTOR,
  FUSE,
  PHASE,
  CURRENT_SENSOR,
  BRANCH_CIRCUIT,
  CHECK_POINT,
  UNICERSE_METER,
  DMX_DEVICE,
} from '../objectTypes'

export const MAPS_ICONS = {
  [LIGHT]: LightBulbIcon,
  [LIGHT_POINT_SERVER]: LightBulbIcon,
  [LIGHT_FIXTURE_SERVER]: LightBulbIcon,
  [LAMP_SERVER]: LightBulbIcon,
  [CONTROLLER]: ControllerIcon,
  [COMPANY_OBJECT_SERVER]: ControllerIcon,
  [SHUO]: ControllerIcon,
  [GROUP_OBJECT]: ControllerIcon,
  [HEATING_DEVICE]: HeatIcon,
  [COLD_WATER_DEVICE]: WaterIcon,
  [LIGHT_SENSOR]: SencorIcon,
  [WASTE_CONTAINER_SERVER]: TrashpinIcon,
  [TRASH]: TrashpinIcon,
  [ELECTRIC_METER]: SencorIcon,
  [AMBIENT_LIGHT_SENSOR_SERVER]: SencorIcon,
  [CONTACTORS]: ContractorIcon,
  [CONTACTOR]: ContractorIcon,
  [FUSE]: SencorIcon,
  [PHASE]: SencorIcon,
  [CURRENT_SENSOR]: SencorIcon,
  [BRANCH_CIRCUIT]: SencorIcon,
  [CHECK_POINT]: SencorIcon,
  [UNICERSE_METER]: SencorIcon,
  [DMX_DEVICE]: SencorIcon,
  [SENSOR_SERVER]: SencorIcon,
  [CONTROLLER_SERVER]: ControllerIcon,
  [SHUO_SERVER]: ControllerIcon,
  [BASE_STATION_SERVER]: ControllerIcon,
  BASE_STATION: ControllerIcon,
  [WATHER_SUPPLY_SERVER]: syncModuleIcons.SimIcon,
  [CENTRAL_HEATING_SERVER]: syncModuleIcons.BrizIcon,
  [ELECTRIC_METER_SERVER]: ContractorIcon,
  [MULTIRATE_METER_SERVER]: ContractorIcon,
  [CONTACTOR_SERVER]: ContractorIcon,
  [BRANCH_CIRCUIT_SERVER]: ContractorIcon,
  [LIGHT_SENSOR_SERVER]: SencorIcon,
  [PHOTO_SENSOR_SERVER]: SencorIcon,
  [VOLUME_SENSOR_SERVER]: SencorIcon,
  [FIRE_SENSOR_SERVER]: SencorIcon,
  [TRANSFER_SWITCH_SERVER]: ControllerChipIcon,
  [DMX_DEVICE_SERVER]: ControllerChipIcon,
  [CONTROLLER_FUSE_SERVER]: ControllerIcon,
  [FUSE_SERVER]: ControllerIcon,
  [CHECKPOINT_SERVER]: ContractorIcon,
  [CURRENT_SENSOR_SERVER]: SencorIcon,
  [CONTROL_CUPBOARD_SERVER]: ContractorIcon,
  [MULTI_RATE_METER_SERVER]: ContractorIcon,
  [METEO_SERVER]: SencorIcon,
  [ROAD_TEMPERATURE_SENSOR_SERVER]: SencorIcon,
  [DOOR_OPEN_SENSOR_SERVER]: SencorIcon,
  [SMOKE_SENSOR_SERVER]: SencorIcon,
  [LIGHT_FIXTURE_GROUP_SERVER]: PoleIcon,
  [LIGHT_MODULE_SERVER]: LightBulbIcon,
  [DEVICE_SERVER]: ControllerChipIcon,
  [SWITCHING_GROUP_SERVER]: PoleIcon,
  [STREETLIGHT_CONTROL_CABINET_SERVER]: ControllerChipIcon,
  [STREETLIGHT_GROUP_SERVER]: PoleIcon,
  [STREETLIGHT_SERVER]: LightBulbIcon,
  [UTILITY_POLE_SERVER]: PoleIcon,
  [LINE_SERVER]: LEPIcon,
  [LAMP_MODULE_SERVER]: LightBulbIcon,
  [PHASE_CIRCUIT_BREAKER_SERVER]: ContractorIcon,
}

export const zoneColor = RED_LIGHT
export const zoneBorder = RED
export const highlightedzoneColor = MALACHITE_LIGHT
export const highlightedzoneBorder = MALACHITE

export const STATUS_COLORS = {
  [OK]: MALACHITE,
  [WARNING]: FUEL_YELLOW,
  [ERROR]: RED,
  [INFO]: REGENT_GRAY,
  [NOT_VERIFIED]: BLUE_LIGHT,
  [VERIFIED]: BLUE_DARK,
  [NEW]: BOULDER,
  [NOT_EXIST]: YELLOW_LIGHT,
  [NOT_INSTALLED]: ORANGE_PEEL,
  [INSTALLED]: ATLANTIS_LIGHT,
  [UNINSTALLED]: MIDDLE_RED,
  null: REGENT_GRAY,
  UNDEFINED: REGENT_GRAY,
  NONE: WHITE_GRAY,
}

export const MARKS_COLORS = {
  ...STATUS_COLORS,
  backdrop: EBONY_CLAY,
  white: WHITE,
  gray: CADET_BLUE,
  background: SHARK_DARK,
}

// new clusters
export const clusterContainerBase = {
  zIndex: 120,
  position: 'relative',
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  background: MARKS_COLORS.backdrop,
  borderRadius: '50%',
  transition: 'all 1s ease-out',
}

export const pieChartContainerBase = {
  position: 'absolute',
  borderRadius: '50%',
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  zIndex: 120,
}

export const pieChartOverlayBase = {
  position: 'absolute',
  borderRadius: '50%',
  background: MARKS_COLORS.backdrop,
  zIndex: 125,
}

export const countTitleBase = {
  position: 'absolute',
  color: MARKS_COLORS.white,
  textAlign: 'center',
  left: '0px',
  zIndex: 130,
}

export const titleHoverBase = {
  position: 'absolute',
  top: '0',
  background: MARKS_COLORS.backdrop,
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  color: MARKS_COLORS.white,
  zIndex: 100,
}

// new clusters

// new icons
export const pinToolTip = {
  position: 'absolute',
  padding: ' 3px 10px',
  background: MARKS_COLORS.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
}

export const pinTip = {
  position: 'absolute',
  background: MARKS_COLORS.white,
  transform: 'rotate(45deg)',
  zIndex: 90,
}

// new icons

export const zoneOptions = {
  hasBallon: false,
  fillColor: zoneColor,
  strokeColor: zoneBorder,
  opacity: 1,
  strokeWidth: 1,
  strokeStyle: 'solid',
}

export const highlightedZoneOptions = {
  ...zoneOptions,
  fillColor: highlightedzoneColor,
  strokeColor: highlightedzoneBorder,
}

export const defaultZoneOptions = {
  ...zoneOptions,
  fillColor: '#00000000',
  strokeWidth: 2,
  strokeStyle: 'shortdash',
  strokeColor: '#7C7C7C',
}

const commonBase = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: MARKS_COLORS.backdrop,
}

export const baseSmall = {
  width: '40px',
  height: '40px',
  ...commonBase,
}
export const baseMedium = {
  width: '40px',
  height: '40px',
  ...commonBase,
}

export const baseBig = {
  width: '50px',
  height: '50px',
  ...commonBase,
}

export const baseLarge = {
  width: '60px',
  height: '60px',
  ...commonBase,
}

export const base = {
  width: '40px',
  height: '40px',
  ...commonBase,
}

export const pieChartHole = {
  borderRadius: '50%',
  fontSize: '1.2rem',
  justifyContent: 'center',
}
export const pieChartHoleHover = {
  ...pieChartHole,
  display: 'flex',
  alignItems: 'center',
}

export const clusterWrapper = {
  backgroundColor: 'transparent',
  border: '3px solid transparent',
  borderRadius: '50%',
  justifyContent: 'center',
}
export const clusterWrapperHover = {
  border: `3px solid ${MARKS_COLORS.backdrop}`,
  borderRadius: '50px',
}

export const clusterContainer = {
  borderRadius: '50%',
  border: `1px solid ${MARKS_COLORS.backdrop}`,
  justifyContent: 'center',
}

export const clusterContainerHover = {
  borderRadius: '50%',
  justifyContent: 'center',
}

export const geoZonePosition = {
  position: 'absolute',
  top: '-15px',
  left: '-15px',
  borderRadius: '50px',
}

export const markTriangle = {
  position: 'absolute',
  border: '10px solid transparent',
  borderTop: `15px solid ${MARKS_COLORS.backdrop}`,
}

export const iconStyles = {
  height: '20px',
  fill: MARKS_COLORS.white,
}

export const pinBase = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '40px',
  height: '45px',
}

export const circleBase = {
  position: 'absolute',
  top: '5px',
  left: '5px',
  width: '30px',
  height: '30px',
  borderRadius: '50px',
  backgroundColor: MARKS_COLORS.backdrop,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 110,
}

export const flagBasic = {
  position: 'absolute',
  top: '0',
  left: '20px',
  color: MARKS_COLORS.white,
  width: '100px',
  height: '40px',
  borderRadius: '0 50px 50px  0',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '20px',
  zIndex: 100,
}

export const pinBaseContainer = {
  position: 'relative',
  top: '-10px',
  left: '-10px',
  width: '40px',
  height: '45px',
  zIndex: 120,
}

export const meshTitle = {
  width: '40px',
  position: 'absolute',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.4rem',
  color: MARKS_COLORS.white,
  textAlign: 'center',
  top: '4px',
  zIndex: 120,
}

export const meshCircle = {
  position: 'absolute',
  borderRadius: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 110,
}

export const meshBase = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '40px',
  height: '61px',
}

export const meshContainer = {
  position: 'relative',
  width: '40px',
  height: '45px',
  zIndex: 120,
}

export const meshIconStyles = {
  height: '20px',
  fill: MARKS_COLORS.white,
}
