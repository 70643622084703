import React from 'react'
import pt from 'prop-types'
import { childrenType } from '@/propTypes/react'
import memoWithName from '@/hocs/memoWithName'
import Container from './styles'

const Sidebar = React.forwardRef(({
  children, onClose, opened, withoutOutsideClick, ...props
}, ref) => (
  <Container ref={ref} opened={opened} {...props}>
    {children}
  </Container>
))

Sidebar.propTypes = {
  children: childrenType.isRequired,
  onClose: pt.func.isRequired,
  opened: pt.bool.isRequired,
  withoutOutsideClick: pt.bool,
}
Sidebar.defaultProps = {
  withoutOutsideClick: false,
}

export default memoWithName(Sidebar)
