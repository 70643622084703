import { USER_PING } from './index'

export const requestUserPing = (form) => ({
  type: USER_PING.REQUEST,
  payload: form,
})

export const successUserPing = (response) => ({
  type: USER_PING.SUCCESS,
  payload: response,
})

export const errorUserPing = (error) => ({
  type: USER_PING.ERROR,
  payload: error,
})
