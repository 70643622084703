import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InfoFrameIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 28 28">
      <path d="M20.4 19.727h-3.2L14 23l-3.2-3.273H7.6c-.88 0-1.6-.736-1.6-1.636V6.636C6 5.736 6.72 5 7.6 5h12.8c.88 0 1.6.736 1.6 1.636v11.455c0 .9-.72 1.636-1.6 1.636zm0-13.09H7.6V18.09h3.616l2.816 2.863 2.784-2.863H20.4V6.636zM9.2 8.272h9.6v8.181H9.2V8.274zm1.6 6.545h6.4V9.91h-6.4v4.91z" fill={fill} className="svgFill" />
    </svg>
  )
}

InfoFrameIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InfoFrameIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InfoFrameIcon)
