import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcons from '@/components/icons/menu'
import AnalyticsAlarmsWidget from '@/components/widgets/AnalyticsAlarmsWidget'
import TyumenOurHomeWidget from '@/components/widgets/TyumenOurHomeWidget'
import WidgetsIcons from '@/components/icons/widgets'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'
import {
  ANALYTICS_ALARMS,
  TYUMEN_OUR_HOME,
  SUPPORT,
} from './names'

const widgetsShop = [
  {
    applicationId: ID_BY_APPLICATIONS.ALARM_ANALYTICS,
    id: ANALYTICS_ALARMS,
    menuIcon: MenuIcons.AlarmsIcon,
    title: 'Аналитика неисправностей',
    description: 'Модуль сбора и анализа статистики неисправностей оборудования в выбранной геозоне.',
    renderComponent: (props) => <AnalyticsAlarmsWidget demo {...props} />,
  },
  {
    applicationId: ID_BY_APPLICATIONS.TYUMEN_PORTAL,
    id: TYUMEN_OUR_HOME,
    menuIcon: MenuIcons.TyumenIcon,
    title: 'Портал Тюмень - НАШ ДОМ',
    description: 'Просмотр обращений граждан по различным проблемам городской среды для получения актуальной информации о проблемах в городе.',
    renderComponent: (props) => (
      <TyumenOurHomeWidget
        demo
        settings={{
          title: 'Ремонт и реконструкция дорог',
        }}
        data={{
          totalAppealsAmount: 19,
          inProcessAppealsAmount: 2,
          solvedAppealsAmount: 17,
          averageCompletionTime: 126.53,
          refusalAppealsAmount: 5,
          inProgressPercent: ((2 / 19) * 100).toFixed(0),
          donePercent: ((17 / 19) * 100).toFixed(0),
        }}
        {...props}
      />
    ),
  },
  {
    applicationId: ID_BY_APPLICATIONS.SERVICE_DESK,
    id: SUPPORT,
    large: true,
    icon: WidgetsIcons.HandicappedIcon,
    menuIcon: MenuIcons.SupportIcon,
    title: (<Lang id="widgets.support" />),
    description: 'Виджет для просмотра истории обращений и формирования новых заявок для устранения аварийных ситуаций',
    renderComponent: () => (null),
  },
]

export default widgetsShop
