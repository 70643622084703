import { USER_RESTORATION } from './index'

export const beginRestoreAccount = ([values, form]) => ({
  type: USER_RESTORATION.BEGIN,
  payload: {
    values,
    form,
  },
})

export const successRestoreAccount = (data) => ({
  type: USER_RESTORATION.SUCCESS,
  payload: { data },
})

export const errorRestoreAccount = (error) => ({
  type: USER_RESTORATION.ERROR,
  payload: { error },
})
