import React from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import SelectField from '@/components/fields/SelectField'

import {
  MultipleUserEditScheme,
  SELECT_GROUP,
  BLOCK_USERS,
} from '@/constants/validationFields'
import {
  FormWrapper,
  InputAndLabelContainer,
  StyledLabelWithIcon,
} from './styles'

const MultipleUserEditForm = React.forwardRef(({
  setValidForm,
  options,
  isAllBlock,
}, ref) => (
  <Formik
    ref={ref}
    enableReinitialize
    initialValues={{
      [BLOCK_USERS]: isAllBlock,
    }}
    validationSchema={MultipleUserEditScheme}
    validate={(values) => {
      if (isAllBlock !== values[BLOCK_USERS] || values[SELECT_GROUP]) {
        setValidForm(true)
      } else {
        setValidForm(false)
      }
    }}
    render={({
      touched, errors, handleSubmit,
    }) => (
      <FormWrapper onSubmit={handleSubmit} id="MultipleUserEditForm" name="MultipleUserEditForm">
        <InputAndLabelContainer>
          <StyledLabelWithIcon>
            <LabelWithIcon
              isError={(touched[SELECT_GROUP] && errors[SELECT_GROUP])}
              title={(<Lang id="usersPage.group.rebase" />)}
            />
          </StyledLabelWithIcon>
          <SelectField
            name={SELECT_GROUP}
            options={options}
            placeholder={(<Lang id="usersPage.group.selectFromList" />)}
            userForm
          />
        </InputAndLabelContainer>
      </FormWrapper>
    )}
  />
))

MultipleUserEditForm.propTypes = {
  setValidForm: pt.func,
  isAllBlock: pt.bool,
  options: pt.arrayOf(pt.object),
}
MultipleUserEditForm.defaultProps = {
  isAllBlock: false,
  setValidForm: noop,
  options: [],
}

export default MultipleUserEditForm
