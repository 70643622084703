import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ClientIDIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.0497 9.5379C2.68005 9.7062 0 12.4825 0 15.8824C0 17.0524 0.95136 18 2.125 18H14.875C16.0486 18 17 17.0524 17 15.8824C17 12.4825 14.32 9.7062 10.9503 9.5379C10.8426 9.5326 10.7341 9.5294 10.625 9.5294H6.375C6.2659 9.5294 6.1574 9.5326 6.0497 9.5379ZM5.6888 7.4118C6.438 8.0704 7.4222 8.4706 8.5 8.4706C9.5778 8.4706 10.562 8.0704 11.3112 7.4118C12.1935 6.6356 12.75 5.5006 12.75 4.23529C12.75 1.89635 10.8472 0 8.5 0C6.1528 0 4.25 1.89635 4.25 4.23529C4.25 5.5006 4.80654 6.6356 5.6888 7.4118ZM14.3438 15.8824C14.6371 15.8824 14.875 15.6452 14.875 15.3529V14.8235C14.875 12.4846 12.9722 11.6471 10.625 11.6471H6.375C4.02783 11.6471 2.125 12.4846 2.125 14.8235V15.3529C2.125 15.6452 2.36289 15.8824 2.65625 15.8824H14.3438ZM8.5 6.3529C9.6736 6.3529 10.625 5.4053 10.625 4.23529C10.625 3.06529 9.6736 2.11765 8.5 2.11765C7.3264 2.11765 6.375 3.06529 6.375 4.23529C6.375 5.4053 7.3264 6.3529 8.5 6.3529Z" className="svgFill" fill={fill} />
    </svg>
  )
}

ClientIDIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ClientIDIcon.defaultProps = {
  color: '#1E2127',
  onClick: noop,
}

export default memoWithName(ClientIDIcon)
