import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const BulletListIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.45946 5.66419C0.651892 5.66419 0 6.2967 0 7.08024C0 7.86379 0.651892 8.49629 1.45946 8.49629C2.26703 8.49629 2.91892 7.86379 2.91892 7.08024C2.91892 6.2967 2.26703 5.66419 1.45946 5.66419ZM1.45946 0C0.651892 0 0 0.632502 0 1.41605C0 2.1996 0.651892 2.8321 1.45946 2.8321C2.26703 2.8321 2.91892 2.1996 2.91892 1.41605C2.91892 0.632502 2.26703 0 1.45946 0ZM1.45946 11.4889C0.73946 11.4889 0.165405 12.0553 0.165405 12.7444C0.165405 13.4336 0.749189 14 1.45946 14C2.16973 14 2.75351 13.4336 2.75351 12.7444C2.75351 12.0553 2.17946 11.4889 1.45946 11.4889ZM4.37838 13.6885H18V11.8004H4.37838V13.6885ZM4.37838 8.02428H18V6.13621H4.37838V8.02428ZM4.37838 0.472016V2.36008H18V0.472016H4.37838Z" fill={fill} className="svgFill" />
    </svg>
  )
}

BulletListIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

BulletListIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(BulletListIcon)
