import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const MapControls = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TitleAndIcon = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontPTSerif};
  font-style: normal;
  font-weight: ${({ theme, size }) => (size === 'small'
    ? theme.fontWeights.light
    : theme.fontWeights.regular)};
  font-size: ${({ size }) => (size === 'small'
    ? '1.3rem'
    : '2rem')};
  line-height: ${({ size }) => (size === 'small'
    ? '1.5rem'
    : '2.3rem')};
  svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
`

export const MapControlsContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`
