import React from 'react'

import WidgetsIcons from '@/components/icons/widgets'
import {
  OK,
  WARNING,
  ERROR,
  STATUS_COLORS,
} from '@/constants/alarms'

import ProgressLine from '../../components/ProgressLine'

import { Row, Column, Title } from './styles'

const OK_DATA = [
  {
    count: 7,
    doneCount: 6,
    dynamic: 12,
  },
  {
    count: 110,
    doneCount: 80,
    dynamic: 1,
  },
]
const LEFT_DATA = [
  {
    count: 7,
    doneCount: 1,
    dynamic: -14,
  },
  {
    count: 110,
    doneCount: 24,
    dynamic: -2,
  },
]
const ERROR_DATA = [
  {
    count: 7,
    doneCount: 1,
    dynamic: 14,
  },
  {
    count: 110,
    doneCount: 6,
    dynamic: 1,
  },
]

const Widget = () => (
  <Row>
    <Title>Регламент работ</Title>
    <Title>В работе</Title>
    <Title>Завершено</Title>
    <Column>
      <WidgetsIcons.HappyIcon />
      Соблюден
    </Column>
    {OK_DATA.map((data) => (
      <Column><ProgressLine color={STATUS_COLORS[OK]} {...data} /></Column>)
    )}
    <Column>
      <WidgetsIcons.IndifferentIcon />
      Истекает
    </Column>
    {LEFT_DATA.map((data) => (
      <Column><ProgressLine color={STATUS_COLORS[WARNING]} {...data} /></Column>)
    )}
    <Column>
      <WidgetsIcons.BadIcon />
      Нарушен
    </Column>
    {ERROR_DATA.map((data) => (
      <Column><ProgressLine color={STATUS_COLORS[ERROR]} {...data} /></Column>)
    )}
  </Row>
)

export default Widget
