import React, {
  useRef, useMemo, useState, useCallback,
} from 'react'
import pt from 'prop-types'
import isFunction from 'lodash/isFunction'
import CheckBox from '@/components/controls/CheckBox'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import {
  WidgetContainer,
  WidgetHeader,
  WidgetTitle,
  WidgetsWrapper,
  TooltipWrapper,
  WidgetRow,
} from './styles'

const WidgetsDashboard = ({
  title,
  children,
  fit,
  row,
  vertical,
  widgets,
}) => {
  const ref = useRef(null)
  const widgetsTypes = useMemo(() => widgets.map(({ type }) => type), [widgets])
  const [enabledWidgets, setEnabledWidgets] = useState(widgetsTypes)
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleOpenTooltip = useCallback(() => setOpenTooltip(true), [])
  const handleCloseTooltip = useCallback(() => setOpenTooltip(false), [])

  useOutsideClickHandler({
    ref,
    callback: handleCloseTooltip,
    opened: openTooltip,
  })

  const handleSelectWidget = useCallback((type) => () => {
    if (enabledWidgets.includes(type)) {
      setEnabledWidgets(enabledWidgets.filter((widgetType) => widgetType !== type))
    } else {
      setEnabledWidgets([...enabledWidgets, type])
    }
  }, [enabledWidgets])

  const renderWidgetsSelector = useCallback(() => (
    <TooltipWrapper ref={ref}>
      {widgets.map(({ name, type }) => (
        <WidgetRow onClick={handleSelectWidget(type)}>
          <CheckBox value={enabledWidgets.includes(type)} />
          {name}
        </WidgetRow>
      ))}
    </TooltipWrapper>
  ), [widgets, enabledWidgets, handleSelectWidget])
  return (
    <WidgetContainer fit={fit}>
      <WidgetHeader>
        <WidgetTitle>
          {title}
        </WidgetTitle>
        <WidgetsDashboardIcon.DotsIcon onClick={handleOpenTooltip} />
        {openTooltip && renderWidgetsSelector()}
      </WidgetHeader>
      <WidgetsWrapper row={row} vertical={vertical}>
        {isFunction(children) ? children(enabledWidgets) : children}
      </WidgetsWrapper>
    </WidgetContainer>
  )
}

WidgetsDashboard.propTypes = {
  vertical: pt.bool,
  fit: pt.bool,
  row: pt.bool,
  children: pt.element,
  title: pt.string,
  widgets: pt.arrayOf(pt.shape({
    name: pt.oneOfType([pt.string, pt.node, pt.children]),
    type: pt.string,
  })),
}
WidgetsDashboard.defaultProps = {
  vertical: false,
  fit: false,
  row: false,
  children: React.Fragment,
  title: '',
  widgets: [],
}

export default WidgetsDashboard
