import React, { useEffect } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import { REQUEST_STATUSES } from '@/constants/requests'
import Loader from '@/components/blocks/Loader'
import config from './config'
import {
  Container,
  Header,
  TitleContainer,
  Title,
  RowContainer,
  Row,
  RowTitle,
  Indicator,
  LoaderContainer,
} from './styles'

const TechnicalCondition = ({ 
  data,
  requestState,
  selectedNode,
  getDataRequest,
  formValues,
}) => {
  useEffect(() => {
    getDataRequest({ selectedNode })
  }, [selectedNode, getDataRequest, formValues])
  const showLoader = requestState === REQUEST_STATUSES.PENDING
  return (
    <Container>
      <Header>
        <WidgetsDashboardIcon.TechnicalConditionIcon />
        <TitleContainer>
          <Title>
            <Lang id="widgetsDashboard.lightingObjects" />
          </Title>
          <Title subtitle>
            <Lang id="widgetsDashboard.allObjects" />
            {' '}
            {numberWithSpaces(data.installationCount || 0)}
          </Title>
        </TitleContainer>
      </Header>
      {showLoader
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <RowContainer>
            {config.map((element) => (
              <Row key={element.id} id={element.id}>
                <RowTitle>
                  <Indicator id={element.id} />
                  <Title rowTitle>{element.name}</Title>
                </RowTitle>
                <Title>{numberWithSpaces(data[element.selector]) || 0}</Title>
              </Row>
            ))}
          </RowContainer>
        )}
    </Container>
  )
}

TechnicalCondition.propTypes = {
  data: pt.objectOf(pt.number),
  requestState: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
  ]),
  selectedNode: pt.objectOf(pt.object),
  getDataRequest: pt.func,
  formValues: pt.objectOf(pt.string),
}
TechnicalCondition.defaultProps = {
  data: {},
  requestState: REQUEST_STATUSES.NOT_REQUESTED,
  selectedNode: {},
  getDataRequest: noop,
  formValues: {},
}

export default TechnicalCondition
