import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SolvedIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0896 1.6732C15.4249 1.28982 15.9948 1.21975 16.4131 1.51047L17.1764 2.0411C17.6309 2.35701 17.7423 2.98198 17.425 3.43548L8.23312 16.5732C8.04601 16.8406 7.74015 16.9999 7.41376 16.9999H6.12525C5.80368 16.9999 5.50172 16.8452 5.31379 16.5843L0.51714 9.92395C0.221884 9.51398 0.278806 8.94817 0.649807 8.60523L1.6423 7.68778C2.02566 7.33341 2.61712 7.33357 3.0003 7.68815L6.01902 10.4817C6.43243 10.8642 7.08007 10.83 7.45091 10.4061L15.0896 1.6732Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SolvedIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SolvedIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SolvedIcon)
