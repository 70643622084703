import {
  takeLatest, put,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import { GET_TYUMEN_MESSAGE } from '@/store/actions/tyumenOurHome'
import { generateTyumenOurHomeUrl } from '@/constants/apiRoutes'
import {
  successTyumenMessage,
  errorTyumenMessage,
} from '@/store/actions/tyumenOurHome/getMessage'

function* getMessagesList({ payload }) {
  try {
    const response = yield request({
      url: `${generateTyumenOurHomeUrl(payload.type)}?${qs.stringify({ id: payload.id })}`,
      method: 'get',
    })

    yield put(successTyumenMessage({
      messageData: response.data,
    }))
  } catch (error) {
    yield put(errorTyumenMessage(error))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_TYUMEN_MESSAGE.REQUEST, getMessagesList)
}
