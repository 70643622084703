import {
  put, takeLatest, select, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import get from 'lodash/get'
import updateNodeCount from '@/helpers/updateNodeCount'
import { INTEGRATION, OBJECT_ELEMENT } from '@/constants/objectTypes'
import {
  getCard,
} from '@/store/selectors/installation'
import { successDeleteObject } from '@/store/actions/installation/deleteObject'
import getNewPath from '@/helpers/getNewPath'
import request from '@/helpers/axios'
import { DELETE_OBJECT } from '@/store/actions/installation'
import {
  generateGetDeleteObject,
  generateGetDeleteObjectCard,
  generateGetTreeIntegrationElement,
} from '@/constants/apiRoutes'

function* deleteObjectSaga({ payload }) {
  const {
    setUrlFormValues, formValues, id, parentId,
  } = payload
  const {
    id: formId,
    parentGeoZoneId: formParentGeoZoneId,
    path,
    rootId,
    FILTER,
  } = formValues
  try {
    const card = yield select(getCard)
    const parentObjectType = yield get(card, 'data.parentObjectType', false)
    const { data: parentData } = yield request({
      url: ((parentObjectType && parentObjectType !== INTEGRATION)
        ? generateGetDeleteObjectCard
        : generateGetTreeIntegrationElement)(parentId),
      method: 'get',
    })

    yield request({
      url: generateGetDeleteObject(id),
      method: 'delete',
    })
    const newPath = getNewPath(path)

    const tree = treeHandlers.trees['installations-tree']
    const node = tree.instance.getNodeById(formId)
    const parent = tree.instance.getNodeById(parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    tree.handlers.setSelected(parent, true)
    updateNodeCount(tree, newPath, 'remove', node.data.childrenCount + 1)
    tree.handlers.rerender()
    setUrlFormValues({
      id: parentData.treeElementId,
      rootId,
      parentGeoZoneId: formParentGeoZoneId,
      parentTreeId: parentData.parentTreeElementId,
      path: newPath,
      type: (parentObjectType && parentObjectType !== INTEGRATION) ? OBJECT_ELEMENT : INTEGRATION,
      FILTER,
    })
    yield put(successDeleteObject())
  } catch (error) {
    setUrlFormValues({ FILTER })
    console.log('function*deleteObjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(DELETE_OBJECT.REQUEST, deleteObjectSaga)
}
