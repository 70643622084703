import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const OperationAndTechnicalSupportIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.1563 32.7291C24.4147 32.9057 24.7185 33 25.0293 33C25.446 33 25.8456 32.8306 26.1403 32.5291C26.435 32.2277 26.6005 31.8188 26.6005 31.3924C26.6005 31.0745 26.5084 30.7636 26.3357 30.4993C26.1631 30.2349 25.9177 30.0289 25.6306 29.9072C25.3434 29.7855 25.0275 29.7537 24.7227 29.8157C24.4179 29.8777 24.138 30.0308 23.9182 30.2557C23.6985 30.4805 23.5488 30.7669 23.4882 31.0788C23.4276 31.3906 23.4587 31.7139 23.5776 32.0076C23.6965 32.3014 23.8979 32.5524 24.1563 32.7291Z" fill={fill} className="svgFill" />
      <path d="M26.2077 27.2127C26.2077 26.4772 26.3829 25.6758 27.6564 24.8013L27.6575 24.8005C29.2245 23.7228 31 22.5018 31 19.7832C31 17.9232 29.9551 16.3887 28.0531 15.4684C27.4176 15.1614 26.4803 14.9885 25.4826 15.0006C24.0732 15.0183 22.8829 15.4186 21.9473 16.1886C20.1427 17.674 20.0099 19.4335 20.0013 19.6288C19.9943 19.7766 20.0159 19.9244 20.0647 20.0636C20.1135 20.2029 20.1887 20.3309 20.2859 20.4405C20.3831 20.55 20.5005 20.6389 20.6313 20.702C20.762 20.7651 20.9037 20.8013 21.0481 20.8084C21.1926 20.8155 21.337 20.7935 21.4731 20.7435C21.6092 20.6936 21.7343 20.6166 21.8414 20.5172C21.9484 20.4177 22.0353 20.2977 22.097 20.1639C22.1587 20.0301 22.1941 19.8851 22.201 19.7374C22.201 19.7237 22.289 18.7929 23.3276 17.9377C23.8783 17.4843 24.5932 17.2593 25.514 17.2472C26.2478 17.2384 26.8441 17.3654 27.112 17.4956C28.2503 18.0494 28.8003 18.7977 28.8003 19.7832C28.8003 21.2324 27.9439 21.8915 26.4292 22.9317C24.7999 24.0513 24.008 25.4515 24.008 27.2127C24.008 27.5111 24.1238 27.7973 24.3301 28.0084C24.5364 28.2194 24.8161 28.338 25.1078 28.338C25.3995 28.338 25.6793 28.2194 25.8856 28.0084C26.0918 27.7973 26.2077 27.5111 26.2077 27.2127Z" fill={fill} className="svgFill" />
      <path d="M45.7241 15.7126C43.2669 6.5344 34.7526 0 25.0006 0C15.3085 0 6.80442 6.53195 4.30312 15.7075C1.85797 16.1549 0 18.2748 0 20.8148V26.6677C0 28.9456 1.49476 30.8857 3.5674 31.5842V34.0321C3.5674 41.3814 9.62954 47.3605 17.0809 47.3605H18.7437C19.4078 48.91 20.9642 50 22.7739 50H27.224C29.6357 50 31.5978 48.0647 31.5978 45.6861C31.5978 43.3068 29.6357 41.3713 27.224 41.3713H22.7739C20.9647 41.3713 19.4085 42.4609 18.7441 44.0099H17.0809C11.5025 44.0099 6.96406 39.5339 6.96406 34.0321V30.1859C6.96406 29.2607 6.20366 28.5107 5.26573 28.5107C4.23518 28.5107 3.39666 27.6838 3.39666 26.6674V20.8148C3.39666 19.7982 4.23518 18.9711 5.25781 18.9711L5.65839 18.9729C5.6611 18.9729 5.66382 18.9729 5.66654 18.9729C6.47268 18.9729 7.16854 18.4133 7.33022 17.6336C9.04735 9.35733 16.4788 3.35052 25.0006 3.35052C33.5774 3.35052 41.0158 9.35308 42.6877 17.6233C42.8459 18.4066 43.5432 18.9709 44.3532 18.9709H44.7343C45.7648 18.9709 46.6033 19.798 46.6033 20.8145V26.6674C46.6033 27.6838 45.7648 28.5107 44.7343 28.5107C43.7963 28.5107 43.0359 29.2607 43.0359 30.1859C43.0359 31.1111 43.7963 31.8612 44.7343 31.8612C47.6377 31.8612 50 29.5312 50 26.6674V20.8148C49.9998 18.2842 48.1558 16.1707 45.7241 15.7126ZM22.7742 44.7218H27.2242C27.763 44.7218 28.2014 45.1543 28.2014 45.6861C28.2014 46.2173 27.763 46.6495 27.2242 46.6495H22.7742C22.2357 46.6495 21.7975 46.2173 21.7975 45.6861C21.7973 45.1543 22.2355 44.7218 22.7742 44.7218Z" fill={fill} className="svgFill" />
      <path d="M24.9994 8.0504C16.2286 8.0504 9.09286 15.0885 9.09286 23.7393C9.09286 32.3915 16.2286 39.4307 24.9994 39.4307C33.7703 39.4307 40.9058 32.3915 40.9058 23.7393C40.9058 15.0885 33.7703 8.0504 24.9994 8.0504ZM24.9994 36.0802C18.1015 36.0802 12.4895 30.544 12.4895 23.7393C12.4895 16.936 18.1015 11.4009 24.9994 11.4009C31.8974 11.4009 37.5091 16.936 37.5091 23.7393C37.5091 30.5442 31.8974 36.0802 24.9994 36.0802Z" fill={fill} className="svgFill" />
    </svg>

  )
}

OperationAndTechnicalSupportIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

OperationAndTechnicalSupportIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(OperationAndTechnicalSupportIcon)
