import styled from 'styled-components'

export const BarLabel = styled.text`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
  fill: ${({ theme }) => theme.colors.white};
  ${({ x, y }) => x && y && `
    transform-origin: ${x}px ${y}px;
    transform: rotate(-90deg);
  `}
`
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  .recharts-responsive-container {
    max-height: calc(100% - 60px);
  }
  ${({ removeVerticalGrid }) => removeVerticalGrid && `
    .recharts-cartesian-grid-vertical {
      display: none;
    }
  `}
  ${({ customTickPosition }) => customTickPosition && `
    .recharts-yAxis {
      .recharts-cartesian-axis-ticks {
        g:nth-last-child(2) {
          text{
            fill: red;
            tspan {
              fill: red;
            }
          }
        }
      }
    }
  `}

`
