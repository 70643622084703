import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { withRouter } from 'react-router-dom'
import pt from 'prop-types'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'
import UsersIcons from '@/components/icons/users'
import YandexMap from '@/components/blocks/YandexMap'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import GeoZoneForm from '@/forms/GeoZoneForm'
import ProjectForm from '@/forms/ProjectForm'
import InstallationForm from '@/forms/InstalationForm'
import { allFilterOption, allFilterOption as INSTALLATION_WIDGET_FILTER_CONFIG } from '@/forms/InstalationForm/config'
import IntegrationForm from '@/forms/IntegrationForm'
import useInstallationParams from '@/hooks/useInstallationParams'
import { REQUEST_STATUSES } from '@/constants/requests'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import { DEBOUNCE_DELAY_MEDIUM } from '@/constants/time'
import { MAP_SERVICE } from '@/constants/applications'

import {
  OK,
  WARNING,
  ERROR,
  INFO,
  UNKNOWN,
} from '@/constants/objectStatuses'
import {
  ACTION_TYPE,
  CREATE_TYPE,
  CREATE,
  PUT,
  FILTER,
} from '@/constants/semanticNames'
import { GEOZONE } from '@/constants/objectTypes'
import Preview from './components/Preview'
import PassportAndCard from './components/PassportAndCard'
import TreeSidebar from './components/TreeSidebar'
import {
  generateAlerts,
  isRenderGeoZoneForm,
  isRenderProjectForm,
  isRenderIntegrationForm,
  allowNewPointCreation,
  newPointWasCreated,
  isCanCreateElement,
  formatGeoZoneCreateValues,
  formatGeoZonePutValues,
  formatProjectCreateValues,
  formatProjectPutValues,
  formatObjectCreateValues,
  formatObjectPutValues,
  formatIntegrationCreateValues,
  formatIntegrationPutValues,
} from './utils'
import {
  Container,
  YandexMapContainer,
  BarContainer,
  MapBarContainer,
  NavContainer,
  AlarmMessageContainer,
  IconContainer,
} from './styles'

const Installation = ({
  requestCreateGeoZone,
  requestEditGeoZone,
  requestDeleteGeoZone,
  requestGetIntegrationTypes,
  requestGetObjectTypes,

  requestCreateObject,
  requestEditObject,
  requestDeleteObject,

  requestCreateIntegration,
  requestDeleteIntegration,
  requestEditIntegration,

  requestGetRootElements,
  requestCreateProject,
  requestGetCard,
  requestEditProject,
  requestDeleteProject,

  requestVerifyElements,
  requestGetTenantParents,
  requestGetScheme,
  requestDownloadMultipleObject,
  requestGetObjectValidationScheme,
  requestGetInstallationObjectPDFFile,
  requestDownloadMultipleObjectScheme,

  setCard,
  roots,
  rootsStatus,
  card,
  integrationTypes,
  objectTypes,
  parentElements,
  displayElements,
  telemetry,
  validationScheme,
  mapCenter,
  mapZoom,
  setMapCenter,
  setMapZoom,
  objectTypesRequestStatus,
  setSelectedElementParams,
  intl,
  setInstallationParams,
  setRequestStatus,
  requestGetPinsAndZonesAndTelemetry,
  displayElementAndTelemetryRequestStatus,
  userApplications
}) => {
  const formRef = useRef(null)
  const selectedNode = useMemo(() => ({ ...get(card, 'data', {}) }), [card])
  const [formValues, setFormValues] = useInstallationParams()
  const [alertConfig, setAlertConfig] = useState({})
  const [newPoints, setNewPoints] = useState([])
  const [newPointsHash, setNewPointsHash] = useState(null)
  const [hoverPinId, setHoverPinId] = useState(null)
  const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0].settings

  useEffect(
    () => () => setInstallationParams({ FILTER: INSTALLATION_WIDGET_FILTER_CONFIG }),
    [setInstallationParams],
  )

  const onPinEnter = (element) => {
    setHoverPinId(element.id)
  }

  const onPinLeave = () => {
    setHoverPinId(null)
  }

  useEffect(() => {
    const { rootId, id, parentGeoZoneId } = formValues
    const ids = roots.map((element) => element.id)
    if (ids.length !== 0 && displayElementAndTelemetryRequestStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      requestGetPinsAndZonesAndTelemetry({
        id,
        ids,
        rootId,
        parentGeoZoneId,
      })
    }
  }, [
    roots,
    formValues,
    requestGetPinsAndZonesAndTelemetry,
    displayElementAndTelemetryRequestStatus,
  ])

  const getInstallationObjectPDF = useCallback(() => {
    requestGetInstallationObjectPDFFile({
      id: formValues.id,
      name: get(card, 'data.name', null),
      fields: get(card, 'data.objectElementFieldsDto', []),
    })
  }, [card, formValues.id, requestGetInstallationObjectPDFFile])

  const createElementType = useMemo(() => {
    if (formValues[CREATE_TYPE] === GEOZONE) {
      return 'city'
    }
    if (newPoints.length !== 0) {
      return get(newPoints, '[0].type', UNKNOWN)
    }
    return UNKNOWN
  }, [formValues, newPoints])

  const changeCreateObjectType = useCallback((type) => {
    setNewPoints([{
      ...get(newPoints, '[0]', {}),
      type,
    }])
  }, [newPoints])

  useEffect(() => {
    requestGetIntegrationTypes()
  }, [requestGetIntegrationTypes])

  useEffect(() => {
    if (rootsStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      requestGetRootElements()
    }
  }, [rootsStatus, requestGetRootElements, roots])

  const canCreateElement = useMemo(
    () => isCanCreateElement(formValues),
    [formValues],
  )

  const displayIntegrationFilter = useMemo(
    () => !(formValues[ACTION_TYPE] === CREATE || formValues[ACTION_TYPE] === PUT),
    [formValues],
  )

  const allowGetCoorsFromMap = useMemo(
    () => allowNewPointCreation(formValues, alertConfig, newPoints),
    [formValues, newPoints, alertConfig],
  )

  const isPointWasCreated = useMemo(() => newPointWasCreated(formValues, alertConfig, newPoints),
    [formValues, newPoints, alertConfig])

  useEffect(() => {
    // temporarily add
    if (!allowGetCoorsFromMap) {
      if (newPoints.length !== 0) {
        setNewPoints([])
      }
      return noop
    }
    // temporarily add
    const lat = get(formRef, 'current.state.values.LOCATION.LATITUDE', null)
      || get(formRef, `current.state.values.${CREATE_REGION_NAMES.LATITUDE}`, null)
    const lon = get(formRef, 'current.state.values.LOCATION.LONGITUDE', null)
      || get(formRef, `current.state.values.${CREATE_REGION_NAMES.LONGITUDE}`, null)
    if (
      (allowGetCoorsFromMap || newPoints.length !== 0)
      && formRef && formRef.current
      && lat && lon
    ) {
      const newPoint = {
        ...get(newPoints, '[0]', {}),
        location: [lat, lon],
        lat,
        lon,
        status: INFO,
        state: INFO,
        type: createElementType,
        count: 0,
        statistic: {
          [OK]: 0,
          [WARNING]: 0,
          [INFO]: 0,
          [ERROR]: 0,
        },
      }
      if (!isEqual(newPoint, newPoints[0] || {})) {
        setNewPoints([newPoint])
      }
    }
    return noop
  }, [newPoints, setNewPoints, formRef, createElementType, newPointsHash, allowGetCoorsFromMap])

  const startEditElement = useCallback(() => {
    setFormValues({
      ...formValues,
      [ACTION_TYPE]: PUT,
    })
  }, [formValues, setFormValues])

  const filterValue = useMemo(() => formValues[FILTER], [formValues])

  useEffect(debounce(() => {
    const {
      id, type, path, parentTreeId, parentGeoZoneId, rootId,
    } = formValues
    setCard({})
    if (id && type && rootsStatus === REQUEST_STATUSES.IDLE) {
      requestGetCard({
        id,
        type,
        path,
        parentTreeId,
        parentGeoZoneId,
        rootId,
      })
    }
  }, DEBOUNCE_DELAY_MEDIUM), [
    formValues,
    requestGetCard,
    setCard,
    rootsStatus,
  ])

  const setSelectedNodeInUrl = useCallback(debounce((node) => {
    const { type: prevType, parentGeoZoneId: prevParentGeozonId, id: prevElementId } = formValues
    const {
      id,
      systemType,
      type,
      rootGeoZone,
      parentGeoZoneId,
      parentTreeId,
      original: { options: { path } },
    } = node
    const localParentGeoZoneId = type === GEOZONE ? id : parentGeoZoneId
    if (prevElementId === id) {
      return null
    }
    setSelectedElementParams({})
    setFormValues({
      id,
      path,
      type: systemType || type,
      rootId: rootGeoZone,
      parentGeoZoneId: localParentGeoZoneId,
      parentTreeId,
      FILTER: filterValue,
    })
    if (prevType !== GEOZONE && prevParentGeozonId !== localParentGeoZoneId) {
      setRequestStatus({ displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED })
    }
    return null
  }, DEBOUNCE_DELAY_MEDIUM), [
    setSelectedElementParams,
    setRequestStatus,
    setFormValues,
    filterValue,
    formValues,
  ])

  const getTelemetry = (node) => {
    const { id, elementType, type } = node
    const filter = typeof formValues[FILTER] === 'string' ? [formValues[FILTER]] : (formValues[FILTER] || [])
    const filterArray = filter || []
    const pinTelemetry = get(telemetry, `${type}-${id}`, null) || get(telemetry, `${elementType}-${id}`, null) || {}
    return {
      ...node,
      ...{
        ...pinTelemetry,
        statistic: Object.keys(pinTelemetry.statistic || {}).reduce((accumulator, name) => ({
          ...accumulator,
          [name]: !filterArray.includes(name)
            ? 0
            : pinTelemetry.statistic[name],
        }), {}),
      },
    }
  }

  const getFilteredPins = (pins) => {
    const filter = formValues[FILTER] || []
    if (filter.length === allFilterOption.length) {
      return pins
    }
    const filterArray = typeof filter === 'string' ? [filter] : (filter || [])
    return pins.filter((pin) => {
      const pinTelemetry = getTelemetry(pin)
      return (pin.elementType !== GEOZONE
        ? filterArray.includes(pinTelemetry.status)
        : true)
    })
  }

  const onPinClickHandler = debounce((node) => {
    const { type: prevType, id: prevElementId } = formValues
    const tree = treeHandlers.trees['installations-tree']
    const {
      id, elementType, rootGeoZone, parentGeoZoneId, parentTreeId, path,
    } = node
    tree.handlers.setSelectedByPath(path)
    const localParentGeoZoneId = elementType === GEOZONE ? id : parentGeoZoneId
    const { parentGeoZoneId: prevParentGeozonId } = formValues
    if (prevElementId === id) {
      return null
    }
    setSelectedElementParams({})
    setFormValues({
      id,
      path,
      type: elementType,
      rootId: rootGeoZone,
      parentGeoZoneId: localParentGeoZoneId,
      parentTreeId,
      FILTER: formValues[FILTER],
    })
    if (prevType !== GEOZONE && prevParentGeozonId !== localParentGeoZoneId) {
      setRequestStatus({ displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED })
    }
    return null
  }, DEBOUNCE_DELAY_MEDIUM)

  // ## ALERT CONTROLL FUNCTION

  const closeAlertHandler = useCallback(() => {
    const { FILTER } = formValues
    setFormValues({ FILTER })
    setAlertConfig({})
    setNewPoints([])
  }, [setFormValues, setAlertConfig, formValues, setNewPoints])

  const closeForm = useCallback(() => {
    const { FILTER } = formValues
    setFormValues({ FILTER })
    setNewPoints([])
  }, [setFormValues, formValues, setNewPoints])

  const closeAlert = useCallback(() => {
    setAlertConfig({})
    setNewPoints([])
  }, [setAlertConfig, setNewPoints])

  const cancelCreation = useCallback(() => {
    setAlertConfig({})
    const {
      id, type, rootId, parentGeoZoneId, parentTreeId, path, FILTER,
    } = formValues
    setFormValues({
      id,
      type,
      rootId,
      parentGeoZoneId,
      parentTreeId,
      path,
      FILTER,
    })
    setNewPoints([])
  }, [formValues, setFormValues, setNewPoints])

  // ## ALERT CONTROLL FUNCTION

  const deleteGeoZoneHandler = useCallback(() => {
    setAlertConfig({})
    requestDeleteGeoZone({
      id: selectedNode.geoZoneId,
      parentId: selectedNode.parentTreeElementId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteGeoZone, setAlertConfig, setFormValues, formValues])

  const deleteProjectHandler = useCallback(() => {
    setAlertConfig({})
    requestDeleteProject({
      id: selectedNode.projectId,
      parentId: selectedNode.parentTreeElementId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteProject, setAlertConfig, setFormValues, formValues])

  const deleteObjectHandler = useCallback(() => {
    setAlertConfig({})
    requestDeleteObject({
      id: selectedNode.objectElementId,
      parentId: selectedNode.parentTreeElementId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteObject, setAlertConfig, setFormValues, formValues])

  const deleteIntegrationHandler = useCallback(() => {
    setAlertConfig({})
    requestDeleteIntegration({
      id: selectedNode.integrationId,
      parentId: selectedNode.parentTreeElementId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteIntegration, setAlertConfig, setFormValues, formValues])

  const {
    deleteElementHandler,
    createEditElementInvalidValuesHandler,
    selectRootElement,
    parentCreateError,
    cancelCreationEdit,
    verifyElement,
  } = useMemo(() => generateAlerts(
    formValues[CREATE_TYPE] || formValues.type,
    formValues[ACTION_TYPE],
    setAlertConfig,
    card,
    closeAlert,
    closeAlertHandler,
    deleteGeoZoneHandler,
    deleteProjectHandler,
    deleteObjectHandler,
    deleteIntegrationHandler,
    cancelCreation,
    requestVerifyElements,
    requestEditObject,
  ), [
    formValues,
    setAlertConfig,
    card,
    closeAlert,
    closeAlertHandler,
    deleteGeoZoneHandler,
    deleteProjectHandler,
    deleteObjectHandler,
    deleteIntegrationHandler,
    cancelCreation,
    requestVerifyElements,
    requestEditObject,
  ])

  useEffect(() => {
    const { id, type } = formValues
    if (!(id && type) && formValues[CREATE_TYPE]) {
      selectRootElement()
    }
  }, [formValues, setFormValues, selectRootElement, selectedNode])

  const viewTreeOnSelect = useCallback((node, actionType) => {
    if (actionType === 'click') {
      setSelectedNodeInUrl(node)
    }
    return null
  }, [setSelectedNodeInUrl])

  const renderSideBar = useCallback(() => (
    <TreeSidebar onSelect={viewTreeOnSelect} hidden={canCreateElement} />
  ), [viewTreeOnSelect, canCreateElement])

  const renderSideBarPreview = useCallback(() => (
    formValues.id && formValues.type
      ? (
        <NavContainer>
          <Preview
            intl={intl}
            selectedNode={{
              ...card.data || {},
            }}
            onClose={closeForm}
            onDelete={deleteElementHandler}
            onEdit={startEditElement}
          />
        </NavContainer>
      )
      : null
  ), [
    deleteElementHandler,
    startEditElement,
    formValues,
    closeForm,
    card,
    intl,
  ])

  const renderGeoZoneForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let ghostMode = true
    let edit = false
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatGeoZoneCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateGeoZone
      title = 'Инсталляция\nгеозоны'
      ghostMode = !isPointWasCreated
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatGeoZonePutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestEditGeoZone
      title = 'Редактирование\nгеозоны'
      ghostMode = false
      edit = true
    }
    return (
      <NavContainer br>
        <GeoZoneForm
          ref={formRef}
          selectedNode={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          onCancel={cancelCreationEdit}
          submit={onSubmitLocal}
          title={title}
          ghostMode={ghostMode}
          formValues={formValues}
          getParentZoneOptions={requestGetTenantParents}
          parentElements={parentElements}
          edit={edit}
          setNewPointsHash={setNewPointsHash}
        />
      </NavContainer>
    )
  }, [
    cancelCreationEdit,
    card,
    createEditElementInvalidValuesHandler,
    formValues,
    isPointWasCreated,
    parentElements,
    requestCreateGeoZone,
    requestEditGeoZone,
    requestGetTenantParents,
    setFormValues,
  ])

  const renderProjectForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let edit = false
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatProjectCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateProject
      title = 'Добавление\nпроекта'
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatProjectPutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestEditProject
      title = 'Редактирование\nпроекта'
      edit = true
    }
    return (
      <NavContainer br>
        <ProjectForm
          selectedNode={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          onCancel={cancelCreationEdit}
          submit={onSubmitLocal}
          title={title}
          formValues={formValues}
          getParentZoneOptions={requestGetTenantParents}
          parentElements={parentElements}
          edit={edit}
        />
      </NavContainer>
    )
  }, [
    cancelCreationEdit,
    card,
    createEditElementInvalidValuesHandler,
    formValues,
    parentElements,
    requestCreateProject,
    requestEditProject,
    requestGetTenantParents,
    setFormValues,
  ])

  const renderObjectForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let ghostMode = true
    let edit = false
    let objectNewPoint = []
    let setObjectNewPoint = noop
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatObjectCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateObject
      title = 'Инсталляция\noбъекта'
      ghostMode = !isPointWasCreated
      objectNewPoint = newPoints
      setObjectNewPoint = setNewPoints
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatObjectPutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = verifyElement
      title = 'Редактирование\nобъекта'
      ghostMode = false
      edit = true
    }
    return (
      <NavContainer br>
        <PassportAndCard
          intl={intl}
          ref={formRef}
          requestGetObjectTypes={requestGetObjectTypes}
          objectTypes={objectTypes}
          data={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          formValues={formValues}
          submit={onSubmitLocal}
          onCancel={cancelCreationEdit}
          title={title}
          ghostMode={ghostMode}
          edit={edit}
          validationScheme={validationScheme}
          requestGetObjectValidationScheme={requestGetObjectValidationScheme}
          requestGetScheme={requestGetScheme}
          changeCreateObjectType={changeCreateObjectType}
          getInstallationObjectPDF={getInstallationObjectPDF}
          objectNewPoint={objectNewPoint}
          setObjectNewPoint={setObjectNewPoint}
          objectTypesRequestStatus={objectTypesRequestStatus}
          setAlertConfig={setAlertConfig}
          setNewPointsHash={setNewPointsHash}
        />
      </NavContainer>
    )
  }, [
    cancelCreationEdit,
    card,
    changeCreateObjectType,
    createEditElementInvalidValuesHandler,
    formValues,
    getInstallationObjectPDF,
    intl,
    isPointWasCreated,
    newPoints,
    objectTypes,
    objectTypesRequestStatus,
    requestCreateObject,
    requestGetObjectTypes,
    requestGetObjectValidationScheme,
    requestGetScheme,
    setFormValues,
    validationScheme,
    verifyElement,
  ])

  const renderIntegrationForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let edit = false
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatIntegrationCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateIntegration
      title = 'Создание\nинтеграции'
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatIntegrationPutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestEditIntegration
      title = 'Редактирование\nинтеграции'
      edit = true
    }
    return (
      <NavContainer br>
        <IntegrationForm
          integrationTypes={integrationTypes}
          selectedNode={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          submit={onSubmitLocal}
          onCancel={cancelCreationEdit}
          title={title}
          formValues={formValues}
          getParentZoneOptions={requestGetTenantParents}
          parentElements={parentElements}
          edit={edit}
        />
      </NavContainer>
    )
  }, [
    formValues,
    integrationTypes,
    setFormValues,
    cancelCreationEdit,
    requestGetTenantParents,
    parentElements,
    card,
    createEditElementInvalidValuesHandler,
    requestCreateIntegration,
    requestEditIntegration,
  ])

  const renderForm = useCallback(() => {
    if (isRenderGeoZoneForm(formValues)) {
      return renderGeoZoneForm()
    }
    if (isRenderProjectForm(formValues)) {
      return renderProjectForm()
    }
    if (isRenderIntegrationForm(formValues)) {
      return renderIntegrationForm()
    }
    return renderObjectForm()
  }, [
    formValues,
    renderGeoZoneForm,
    renderProjectForm,
    renderIntegrationForm,
    renderObjectForm,
  ])

  const renderLeftPart = useCallback(() => (canCreateElement
    ? renderForm()
    : renderSideBarPreview()),
  [canCreateElement, renderForm, renderSideBarPreview])

  return (
    <>
      {!isEmpty(alertConfig) && (
        <GlobalPopup content={<GlobalPopupContent {...alertConfig} shoodBeFixByArtem />} />
      )}
      <Container>
        {renderSideBar()}
        {renderLeftPart()}
        <MapBarContainer>
          <BarContainer empty={!displayIntegrationFilter}>
            {displayIntegrationFilter && (
              <InstallationForm
                setFormValues={setFormValues}
                isCreateBlock={selectedNode.isCreateBlock}
                parentCreateError={parentCreateError}
                requestDownloadMultipleObject={requestDownloadMultipleObject}
                requestDownloadMultipleObjectScheme={requestDownloadMultipleObjectScheme}
                alertConfig={alertConfig}
              />
            )}
          </BarContainer>
          <YandexMapContainer isAlarm={allowGetCoorsFromMap && !isPointWasCreated}>
            {allowGetCoorsFromMap && !isPointWasCreated
              && (
                <AlarmMessageContainer>
                  Отменить добавление
                  <IconContainer onClick={cancelCreation}>
                    <UsersIcons.CrossIcon />
                  </IconContainer>
                </AlarmMessageContainer>
              )}
            <YandexMap
              formRef={formRef}
              displayElements={getFilteredPins(displayElements)}
              pinnedNode={selectedNode}
              zones={[]}
              createPoint={allowGetCoorsFromMap && selectedNode.type === GEOZONE
                ? get(formRef, `current.state.values[${CREATE_REGION_NAMES.PARENT}]`, false) && parentElements.length !== 0
                : allowGetCoorsFromMap}
              createElementType={createElementType}
              handleOpenPassport={onPinClickHandler}
              isDisplayElements
              pointsArray={newPoints}
              dataSet={{ regions: [], pin: [] }}
              mash={[]}
              mapCenter={mapCenter}
              updateMapCenter={setMapCenter}
              mapZoom={mapZoom}
              updateMapZoom={setMapZoom}
              getTelemetry={getTelemetry}
              pinOnEnterHandler={onPinEnter}
              pinOnLeaveHandler={onPinLeave}
              hoveredPinByID={hoverPinId}
              yandexKey={yandexKey}
            />
          </YandexMapContainer>
        </MapBarContainer>
      </Container>
    </>
  )
}

Installation.defaultProps = {
  requestCreateGeoZone: noop,
  requestEditGeoZone: noop,
  requestDeleteGeoZone: noop,
  requestGetIntegrationTypes: noop,
  requestGetObjectTypes: noop,
  requestCreateObject: noop,
  requestEditObject: noop,
  requestDeleteObject: noop,
  requestCreateIntegration: noop,
  requestDeleteIntegration: noop,
  requestEditIntegration: noop,

  requestGetRootElements: noop,
  requestCreateProject: noop,
  requestGetCard: noop,
  requestEditProject: noop,
  requestDeleteProject: noop,
  requestVerifyElements: noop,
  requestGetTenantParents: noop,
  requestGetScheme: noop,
  setCard: noop,
  requestDownloadMultipleObject: noop,
  requestGetObjectValidationScheme: noop,
  requestGetInstallationObjectPDFFile: noop,
  requestDownloadMultipleObjectScheme: noop,
  setSelectedElementParams: noop,
  setMapCenter: noop,
  setMapZoom: noop,
  setInstallationParams: noop,
  setRequestStatus: noop,
  requestGetPinsAndZonesAndTelemetry: noop,

  validationScheme: {},
  integrationTypes: [],
  objectTypes: [],
  parentElements: [],
  roots: [],
  card: {
    data: {},
    requestStatus: null,
  },
  displayElements: [],
  telemetry: {},
  mapCenter: [0, 0],
  mapZoom: 9,
  intl: {},
  objectTypesRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  rootsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  userApplications: []
}

Installation.propTypes = {
  requestEditIntegration: pt.func,
  requestDeleteIntegration: pt.func,
  requestCreateIntegration: pt.func,
  requestDeleteObject: pt.func,
  requestEditObject: pt.func,
  requestCreateObject: pt.func,
  requestGetObjectTypes: pt.func,
  requestCreateGeoZone: pt.func,
  requestGetIntegrationTypes: pt.func,
  requestEditGeoZone: pt.func,
  requestDeleteGeoZone: pt.func,
  requestVerifyElements: pt.func,
  requestGetRootElements: pt.func,
  requestCreateProject: pt.func,
  requestGetCard: pt.func,
  requestEditProject: pt.func,
  requestDeleteProject: pt.func,
  requestGetTenantParents: pt.func,
  requestGetScheme: pt.func,
  setSelectedElementParams: pt.func,
  setCard: pt.func,
  requestDownloadMultipleObject: pt.func,
  requestGetObjectValidationScheme: pt.func,
  requestGetInstallationObjectPDFFile: pt.func,
  requestDownloadMultipleObjectScheme: pt.func,
  setInstallationParams: pt.func,
  setRequestStatus: pt.func,
  requestGetPinsAndZonesAndTelemetry: pt.func,
  setMapCenter: pt.func,
  setMapZoom: pt.func,
  intl: pt.objectOf(pt.object),
  roots: pt.arrayOf(pt.object),
  parentElements: pt.arrayOf(pt.object),
  integrationTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    title: pt.element,
  })),
  objectTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    title: pt.element,
  })),
  card: pt.shape({
    data: pt.object,
    requestStatus: pt.string,
  }),
  displayElements: pt.arrayOf(pt.shape({
    id: pt.string,
    name: pt.string,
    location: pt.arrayOf(pt.number),
  })),
  telemetry: pt.objectOf(pt.object),
  validationScheme: pt.objectOf(pt.object),
  mapCenter: pt.arrayOf(pt.number),
  mapZoom: pt.number,
  objectTypesRequestStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
  displayElementAndTelemetryRequestStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
  rootsStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
  userApplications: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    code: pt.string,
    defaultType: pt.string,
    name: pt.string,
    placement: pt.string,
    settings: pt.string,
    type: pt.string,
  })),
}

function shouldComponentUpdate(props, newProps) {
  const { location: { search } } = props
  const { location: { search: newSearch } } = newProps

  if (search !== newSearch) {
    return true
  }
  return false
}

export default React.memo(withRouter(Installation), shouldComponentUpdate)
