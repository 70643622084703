import React from 'react'

const memoWithName = (Component) => {
  const name = Component.displayName || Component.name
  const memorizedComponent = React.memo(Component)

  memorizedComponent.displayName = name

  return memorizedComponent
}

export default memoWithName
