import generateWidget from './generateWidget'

const DATA_TIME = generateWidget({
  config: {
    id: 'DATA_TIME',
    title: 'Текущий режим:',
    type: 'workTime',
    statusSelector: 'turnedOn',
    workTimeSelector: 'workTime',
  },
})

export default {
  DATA_TIME,
}
