import styled from 'styled-components'

export const ContentWrapper = styled.div`
  width: 100%;
`

export const TitleContainer = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
  padding: 0px 10px;
`

export const CkeckBoxsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`
export const CkeckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TabContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const OptionTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  padding-left: 10px;
`


export const Tab = styled.div`
  width: 50%;
  height: 100%;
  color: ${({ theme }) => theme.colors.colors.default};
  background: ${({ theme }) => theme.colors.backgrounds.default};
  ${({ isActive, theme }) => isActive && `
    color: ${theme.colors.backgrounds.default};
    background: ${theme.colors.colors.tab};
  `}
  text-transform: capitalize;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
`
export const PassportImageContainer = styled.div`
  width: 100%;
  height: auto;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.borders.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const PassportImage = styled.img.attrs(({ base64Url, url }) => ({
  src: base64Url || url,
}))`
  height: 100%;
  max-width: 100%;
`
