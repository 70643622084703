import widgets from '../widgets'

const {
  TRASH_STATUS,
  TRASH,
  TYPE_OF_WASTE,
  LAST_DEVASTATION_SMALL,
  LAST_CLEANING,
  NEXT_CLEANING,
} = widgets

export default [
  TRASH_STATUS({}),
  TRASH({}),
  TYPE_OF_WASTE({}),
  LAST_DEVASTATION_SMALL({}),
  LAST_CLEANING({}),
  NEXT_CLEANING({}),
]
