import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WarningIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="4" height="10" viewBox="0 0 4 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8L2 1.5" stroke={stroke} strokeWidth="3" strokeLinecap="round" className="svgStroke" />
    </svg>
  )
}

WarningIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WarningIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WarningIcon)
