import React, {
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { Formik } from 'formik'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import hashSum from 'hash-sum'
import FileControll from '@/components/controls/FileControll'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import SelectField from '@/components/fields/SelectField'
import useInstallationParams from '@/hooks/useInstallationParams'
import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'
import {
  FILTER,
  CREATE,
  DOWNLOAD_INTALATION_FILE,
  CREATE_TYPE,
  ACTION_TYPE,
} from '@/constants/semanticNames'
import {
  GEOZONE,
  PROJECT,
  ROOT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import {
  filterOptions,
  createOptions,
} from './config'
import {
  FormContainer,
  ContentContainer,
  IconContainer,
  HiddenInputContainer,
} from './styles'

const InstallationForm = ({
  setFormValues,
  parentCreateError,
  requestDownloadMultipleObject,
  isCreateBlock,
  requestDownloadMultipleObjectScheme,
  alertConfig,
}) => {
  const formicForm = useRef(null)
  const hiddenInput = useRef(null)

  const [urlValues] = useInstallationParams()

  const requestDownloadMultipleObjectHandler = (event) => {
    const files = get(event, 'target.files', [])
    if (files.length !== 0) {
      requestDownloadMultipleObject({
        file: files[0],
        id: urlValues.id,
      })
    }
  }

  const parsedInitialValues = useMemo(() => ({
    [FILTER]: typeof urlValues[FILTER] === 'string' ? [urlValues[FILTER]] : urlValues[FILTER],
    formId: hashSum(alertConfig),
  }), [urlValues, alertConfig])

  const handleSetValues = useCallback((formValues) => {
    if (formValues[CREATE] === GEOZONE && urlValues.type !== ROOT && urlValues.type !== GEOZONE) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (formValues[CREATE] === PROJECT && urlValues.type !== GEOZONE) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (formValues[CREATE] === INTEGRATION && urlValues.type !== PROJECT) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (
      formValues[CREATE] === OBJECT_ELEMENT
      && !(urlValues.type === INTEGRATION || urlValues.type === OBJECT_ELEMENT)
    ) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (
      formValues[CREATE] === DOWNLOAD_INTALATION_FILE
      && (urlValues.type !== INTEGRATION && urlValues.type !== OBJECT_ELEMENT)
    ) {
      parentCreateError(formValues[CREATE])
      return null
    }
    if (formValues[CREATE] === DOWNLOAD_INTALATION_FILE) {
      hiddenInput.current.click()
      return null
    }

    const newUrlValues = {
      ...urlValues,
      [FILTER]: formValues[FILTER],
      ...formValues[CREATE]
        ? {
          [ACTION_TYPE]: CREATE,
          [CREATE_TYPE]: formValues[CREATE],
        }
        : {},
    }
    if (!isEqual(newUrlValues, urlValues)) {
      setFormValues({
        ...newUrlValues,
      })
    }
  }, [setFormValues, urlValues, hiddenInput, parentCreateError])

  const onSubmit = useCallback(() => {}, [])
  return (
    <FormContainer>
      <Formik
        ref={formicForm}
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={parsedInitialValues}
        validate={handleSetValues}
        render={({ handleSubmit }) => (
          <>
            <HiddenInputContainer
              type="file"
              accept=".xlsx, .xls"
              ref={hiddenInput}
              onChange={requestDownloadMultipleObjectHandler}
            />
            <ContentContainer>
              <SelectField
                largeOptions
                doNotDisplayValue
                name={CREATE}
                placeholder="Добавить"
                options={createOptions}
                selectIcon={<UsersIcons.PlusIcon />}
                disabled={isCreateBlock}
              />
            </ContentContainer>
            <ContentContainer>
              <SelectField
                multiselect
                largeOptions
                name={FILTER}
                placeholder="Фильтрация объектов"
                options={filterOptions}
                selectAll={{
                  value: 'ALL',
                  color: STATUS_COLORS.NONE,
                  title: 'Все объекты',
                }}
              />
            </ContentContainer>
          </>
        )}
      />
      <ContentContainer file>
        <FileControll
          onClick={requestDownloadMultipleObjectScheme}
          type="file"
          accept="application/pdf"
          gray
          placeholder="Скачать шаблон файла инсталляции"
          lowerCasePlaceholder
          disabled
          disableDefaultColor
          controls={(
            <PortalTooltip
              title="Скачать шаблон файла инсталляции"
              renderChildren={(
                wrapperRef,
                onMouseEnterHandler,
                onMouseLeaveHandler,
              ) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <UsersIcons.UploadIcon />
                </IconContainer>
              )}
            />
          )}
        />
      </ContentContainer>
    </FormContainer>
  )
}

InstallationForm.propTypes = {
  setFormValues: pt.func,
  parentCreateError: pt.func,
  requestDownloadMultipleObject: pt.func,
  requestDownloadMultipleObjectScheme: pt.func,
  isCreateBlock: pt.bool,
}

InstallationForm.defaultProps = {
  setFormValues: noop,
  parentCreateError: noop,
  requestDownloadMultipleObject: noop,
  requestDownloadMultipleObjectScheme: noop,
  isCreateBlock: false,
}

export default React.memo(InstallationForm)
