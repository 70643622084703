import ControlIcon from './ControlIcon'
import DaylightIcon from './DaylightIcon'
import DeleteIcon from './DeleteIcon'
import InformationIcon from './InformationIcon'
import MoonIcon from './MoonIcon'
import ScheduleIcon from './ScheduleIcon'
import SoneIcon from './SoneIcon'
import StateIcon from './StateIcon'
import UpdateIcon from './UpdateIcon'
import ShuoIcon from './ShuoIcon'
import BaseStationIcon from './BaseStationIcon'
import ControllerIcon from './ControllerIcon'
import UniversalMeterIIcon from './UniversalMeterIIcon'
import LEPIcon from './LEPIcon'
import LightIcon from './LightIcon'
import LightModuleIcon from './LightModuleIcon'
import SupportIcon from './SupportIcon'

export default {
  ControlIcon,
  DaylightIcon,
  DeleteIcon,
  InformationIcon,
  MoonIcon,
  ScheduleIcon,
  SoneIcon,
  StateIcon,
  UpdateIcon,
  ShuoIcon,
  BaseStationIcon,
  ControllerIcon,
  UniversalMeterIIcon,
  LEPIcon,
  LightIcon,
  LightModuleIcon,
  SupportIcon,
}
