import React from 'react'
import memoWithName from '@/hocs/memoWithName'
import { childrenType } from '@/propTypes/react'
import AppHeaderContainer from '@/containers/regions/AppHeaderContainer'
import MenuSidebarContainer from '@/containers/regions/MenuSidebarContainer'
import UserSidebarContainer from '@/containers/regions/UserSidebarContainer'
import SettingsSidebarContainer from '@/containers/regions/SettingsSidebarContainer'
import {
  BaseLayoutStyled,
  LayoutContentWrapper,
  ContentWithoutSidebars,
} from './styles'

const BaseLayout = ({ children }) => (
  <BaseLayoutStyled>
    <AppHeaderContainer />
    <LayoutContentWrapper>
      <MenuSidebarContainer />
      <ContentWithoutSidebars>
        {children}
      </ContentWithoutSidebars>
      <UserSidebarContainer />
      <SettingsSidebarContainer />
    </LayoutContentWrapper>
  </BaseLayoutStyled>
)

BaseLayout.propTypes = {
  children: childrenType.isRequired,
}

export default memoWithName(BaseLayout)
