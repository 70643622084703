import { GET_PASSPORT_FILE } from './index'

export const requestGetPassportFile = (payload) => ({
  type: GET_PASSPORT_FILE.REQUEST,
  payload,
})

export const responseGetPassportFile = (payload) => ({
  type: GET_PASSPORT_FILE.SUCCESS,
  payload,
})

export const errorGetPassportFile = (payload) => ({
  type: GET_PASSPORT_FILE.ERROR,
  payload,

})
