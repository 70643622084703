import { GET_REPORT_MANAGER } from './index'

export const requestGetReport = (payload) => ({
  type: GET_REPORT_MANAGER.REQUEST,
  payload,
})

export const successGetReport = (payload) => ({
  type: GET_REPORT_MANAGER.SUCCESS,
  payload,
})

export const errorGetReport = (payload) => ({
  type: GET_REPORT_MANAGER.ERROR,
  payload,
})
