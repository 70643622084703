import { GET_TENANT_ADMINS } from '../index'

export const requestGetAllAdminTenants = (payload) => ({
  type: GET_TENANT_ADMINS.REQUEST,
  payload,
})

export const responseGetAllAdminTenants = (payload) => ({
  type: GET_TENANT_ADMINS.SUCCESS,
  payload,
})

export const errorGetAllAdminTenants = (payload) => ({
  type: GET_TENANT_ADMINS.ERROR,
  payload,
})
