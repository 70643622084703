import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GridIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C12 3.10458 11.1046 4 10 4C8.89543 4 8 3.10458 8 2C8 0.895416 8.89543 0 10 0C11.1046 0 12 0.895416 12 2Z" fill={fill} className="svgFill" />
      <path d="M12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16Z" fill={fill} className="svgFill" />
      <path d="M10 11C11.1046 11 12 10.1046 12 9C12 7.89542 11.1046 7 10 7C8.89543 7 8 7.89542 8 9C8 10.1046 8.89543 11 10 11Z" fill={fill} className="svgFill" />
      <path d="M4 2C4 3.10458 3.10457 4 2 4C0.895432 4 0 3.10458 0 2C0 0.895416 0.895432 0 2 0C3.10457 0 4 0.895416 4 2Z" fill={fill} className="svgFill" />
      <path d="M4 16C4 17.1046 3.10457 18 2 18C0.895432 18 0 17.1046 0 16C0 14.8954 0.895432 14 2 14C3.10457 14 4 14.8954 4 16Z" fill={fill} className="svgFill" />
      <path d="M2 11C3.10457 11 4 10.1046 4 9C4 7.89542 3.10457 7 2 7C0.895432 7 0 7.89542 0 9C0 10.1046 0.895432 11 2 11Z" fill={fill} className="svgFill" />
      <path d="M20 2C20 3.10458 19.1046 4 18 4C16.8954 4 16 3.10458 16 2C16 0.895416 16.8954 0 18 0C19.1046 0 20 0.895416 20 2Z" fill={fill} className="svgFill" />
      <path d="M20 16C20 17.1046 19.1046 18 18 18C16.8954 18 16 17.1046 16 16C16 14.8954 16.8954 14 18 14C19.1046 14 20 14.8954 20 16Z" fill={fill} className="svgFill" />
      <path d="M18 11C19.1046 11 20 10.1046 20 9C20 7.89542 19.1046 7 18 7C16.8954 7 16 7.89542 16 9C16 10.1046 16.8954 11 18 11Z" fill={fill} className="svgFill" />
    </svg>

  )
}

GridIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GridIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GridIcon)
