export default {
  formTitles: {
    ASU: 'ACS:',
    integration: 'Integration:',
    objectTypes: 'Object types:',
    process: 'City processes:',
    objectState: 'Object State:',
  },
  selectPlaceholders: {
    ASU: 'All ACS',
    integration: 'All integrations',
    objectTypes: 'All object types',
    process: 'All city processes',
  },
  buttons: {
    reset: 'Reset',
    accept: 'Apply',
  },
}