import { handleActions } from 'redux-actions'
import {
  SET_WIDGET_SETTINGS,
  SET_ACY_WIDGET_SETTINGS,
} from '@/store/actions/maps'

const initialState = {
  settings: {},
  acsSettings: {},
}

export const reducer = handleActions(
  {
    [SET_WIDGET_SETTINGS]: (state, { payload }) => ({
      ...state,
      settings: payload,
    }),
    [SET_ACY_WIDGET_SETTINGS]: (state, { payload }) => ({
      ...state,
      settings: payload,
      acsSettings: {
        ...state.acsSettings,
        [payload.id]: payload.data,
      },
    }),
  },
  initialState,
)

export default reducer
