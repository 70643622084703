import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useReduxTable from '@/hooks/useReduxTable'
import { APPEALS_TABLE } from '@/store/actions/serviceDeskMyCity'
import { getTableParameters } from '@/store/selectors/serviceDeskMyCity'
import DutyTable from '@/components/blocks/DutyTable'
import ServiceDeskMyCityForm from '@/forms/ServiceDeskMyCityForm'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import Loader from '@/components/blocks/Loader'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/serviceDeskMyCity'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import RowInfoCard from '../RowInfoCard'
import {
  Container,
} from './styles'

const Appeals = ({
  tree,
  data,

  setEventTypes,
  setStatusTypes,
  requestAppealsTable,
  loading,
  typeOfObjects,
  requestGetInfoServiceDeskCard,
  card,
  loadingCard,
  integrationStatus,
  userApplications,
  currentUser,
  resetServiceDeskTable,
}) => {
  const [isDirty, setIsDirty] = useState(false)
  const [isInfoCardOpen, setIsInfoCardOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(false)
  const [values] = useUrlParseAndPush()

  useEffect(() => {
    return () => (
      resetServiceDeskTable()
    )
  }, [resetServiceDeskTable])

  const serviceDeskStatus = useMemo(() => (
    (integrationStatus || []).find(
      (status) => (
        currentUser.roles.isSuperAdmin
          ? values.widgetId === status.temporaryId
          : values.widgetId === status.widgetId
      ),
    )
  ), [integrationStatus, currentUser.roles.isSuperAdmin, values.widgetId])

  const serviceDeskId = useMemo(() => {
    const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
    return (serviceDeskStatus && serviceDeskStatus.id)
      || (serviceDesk && JSON.parse(serviceDesk.settings).id)
  }, [serviceDeskStatus, userApplications])

  const handleRequestAppealsTable = useCallback(() => {
    requestAppealsTable({
      urlParse: values,
      serviceDeskId,
      serviceDeskConfig: serviceDeskStatus && serviceDeskStatus.propertyConfigContent,
    })
  }, [requestAppealsTable, serviceDeskId, serviceDeskStatus, values])

  useEffect(() => {
    if (serviceDeskId) {
      handleRequestAppealsTable()
    }
  }, [handleRequestAppealsTable, serviceDeskId])

  const handleSetIsInfoCardOpen = useCallback((value) => {
    if (typeof value === 'object' && value !== null) {
      setSelectedRow(value)
      requestGetInfoServiceDeskCard({
        id: value.id,
        serviceDeskStatus,
      })
      setIsInfoCardOpen(true)
    } else {
      setIsInfoCardOpen(value)
    }
  }, [requestGetInfoServiceDeskCard, serviceDeskStatus])

  useEffect(() => {
    if (Object.keys(tree).length && !isDirty) {
      setIsDirty(true)
    }
  }, [tree, isDirty])

  const tableProps = useReduxTable(APPEALS_TABLE, getTableParameters)

  return (
    <Container>
      {loading
        ? <Loader center />
        : (
          <>
            <ServiceDeskMyCityForm typeOfObjects={typeOfObjects} />
            <DutyTable
              {...tableProps}
              fields={fields}
              exportOptions={exportOptions}
              scrollAfterUpdating
              data={data}

              rowInfoCard={RowInfoCard}
              isInfoCardOpen={isInfoCardOpen}
              rowSelector={handleSetIsInfoCardOpen}
              customHeader
              setEventTypes={setEventTypes}
              setStatusTypes={setStatusTypes}
              card={card}
              selectedRow={selectedRow}
              loadingCard={loadingCard}
              onUpdateData={handleRequestAppealsTable}
              notToShowFooterPerPage
              serviceDesk
            />
          </>
        )}
    </Container>
  )
}

Appeals.propTypes = {
  tree: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAppealsTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
Appeals.defaultProps = {
  tree: {},
  data: [],

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAppealsTable: noop,
  requestAlarmManagerFile: noop,
}

export default Appeals
