import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LoaderIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="100" height="100" viewBox="0 0 166 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Big-circle" d="M86.6603 41.1621C92.1548 41.6428 97.5009 43.201 102.393 45.7478C107.286 48.2946 111.628 51.78 115.174 56.0052C118.719 60.2303 121.397 65.1124 123.056 70.3726C124.714 75.6329 125.321 81.1683 124.84 86.6628C124.359 92.1573 122.801 97.5034 120.254 102.396C117.707 107.288 114.222 111.631 109.997 115.176C105.772 118.721 100.89 121.4 95.6294 123.058C90.3691 124.717 84.8337 125.323 79.3392 124.842C73.8447 124.362 68.4986 122.803 63.6063 120.257C58.714 117.71 54.3712 114.224 50.8259 109.999C47.2806 105.774 44.6022 100.892 42.9436 95.6319C41.2851 90.3716 40.6789 84.8362 41.1596 79.3417C41.6403 73.8472 43.1985 68.5011 45.7453 63.6088C48.2921 58.7165 51.7775 54.3737 56.0027 50.8284C60.2278 47.2831 65.1099 44.6047 70.3701 42.9461C75.6304 41.2876 81.1658 40.6814 86.6603 41.1621L86.6603 41.1621Z" stroke={stroke} className="svgStroke" strokeWidth="6" strokeLinecap="round" />
      <path id="Small-circle" d="M93.9273 69.9771C92.2172 68.5421 90.2411 67.458 88.1119 66.7867C85.9828 66.1153 83.7423 65.87 81.5183 66.0645C79.2943 66.2591 77.1304 66.8898 75.1502 67.9207C73.17 68.9515 71.4122 70.3623 69.9772 72.0725C68.5422 73.7826 67.4581 75.7587 66.7868 77.8879C66.1154 80.017 65.8701 82.2575 66.0646 84.4815C66.2592 86.7055 66.8899 88.8694 67.9208 90.8496C68.9516 92.8298 70.3624 94.5876 72.0726 96.0226C73.7827 97.4576 75.7588 98.5417 77.888 99.213C80.0171 99.8844 82.2576 100.13 84.4816 99.9352C86.7056 99.7406 88.8695 99.1099 90.8497 98.079C92.8299 97.0482 94.5877 95.6374 96.0227 93.9272C97.4577 92.2171 98.5418 90.241 99.2131 88.1118C99.8845 85.9827 100.13 83.7422 99.9353 81.5182C99.7407 79.2942 99.11 77.1303 98.0791 75.1501C97.0483 73.1699 95.6375 71.4121 93.9273 69.9771L93.9273 69.9771Z" stroke={stroke} className="svgStroke" strokeWidth="6" strokeLinecap="round" />
    </svg>
  )
}

LoaderIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LoaderIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LoaderIcon)
