import React from 'react'
import { withRouter } from 'react-router'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { USER_AGREEMENT } from '@/constants/routes'
import Button from '@/components/blocks/Button'
import {
  ACCEPT_CONDITION,
} from '@/constants/forms/signUp'
import {
  Form,
  Buttons,
  Text,
  StyledCheckBoxField,
  CheckBoxContainer,
  ConditionalText,
} from './styles'

const AcceptConditionForm = ({ handleAccept }) => {
  const renderButton = (values) => {
    if (values[ACCEPT_CONDITION]) {
      return (
        <Buttons>
          <Button onClick={handleAccept} type="button">
            <Lang id="signUp.next" />
          </Button>
        </Buttons>
      )
    }
    return null
  }

  return (
    <Formik
      render={({ values }) => (
        <Form id="userData" name="userData">
          <Text>
            <Lang id="signUp.condition" />
            <Link to={USER_AGREEMENT}>
              <ConditionalText>
                <Lang id="signUp.userAgreement" />
              </ConditionalText>
            </Link>
          </Text>
          <CheckBoxContainer>
            <StyledCheckBoxField
              name={ACCEPT_CONDITION}
            />
            <Text>
              <Lang id="signUp.acceptCondition" />
            </Text>
          </CheckBoxContainer>
          {renderButton(values)}
        </Form>
      )}
    />
  )
}

AcceptConditionForm.propTypes = {
  onFormSubmit: pt.func,
  error: pt.string,
}
AcceptConditionForm.defaultProps = {
  onFormSubmit: noop,
  error: null,
}

export default React.memo(withRouter(AcceptConditionForm))
