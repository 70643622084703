import React, { useState, useMemo } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import DropDown from '@/components/blocks/DropDown'
import SelectControl from '@/components/controls/SelectControl'
import BarChart from '@/components/charts/BarChart'
import LineChartConsumption from '@/components/charts/LineChartConsumption'
import LiveTime from '@/components/icons/livetime'
import {
  seriesNamesComparing,
  seriesNamesDifference,
  exportOptions,
  seriesConsumptionComparing,
} from '@/constants/graph/barChart'
import {
  seriesForLineChartConsumption,
} from '@/constants/graph/lineChart'
import {
  KVmH,
  HEAT,
} from '@/constants/units'
import {
  DEMONSTRATION_TABLE,
  LINEAR_CHART,
  HISTOGRAM_CHART,
  HEAT_SUPPLY,
} from '@/constants/names'
import {
  Container,
  Header,
  CheckBoxContainer,
  SecondaryTitle,
  BulletPoint,
  LegendElement,
} from './styles'

const AnalysisGraph = ({
  graphData, graphType, objectType,
}) => {
  const [isExportOpen, setIsExportOpen] = useState(false)
  const [chartType, setChartType] = useState(HISTOGRAM_CHART)
  const toggleExport = () => {
    setIsExportOpen(!isExportOpen)
  }

  const renderExportTypes = () => (
    <>
      {exportOptions.map(({ icon: Icon, name }) => (
        <CheckBoxContainer>
          <Icon />
          <SecondaryTitle>
            {name}
          </SecondaryTitle>
        </CheckBoxContainer>
      ))}
    </>
  )
  const renderLegend = {
    differenceAnalysis: (
      <>
        <LegendElement>
          <BulletPoint type="default" />
          <Lang id="consumptionAnalysis.legend.saving" />
        </LegendElement>
        <LegendElement>
          <BulletPoint type="critical" />
          <Lang id="consumptionAnalysis.legend.overrun" />
        </LegendElement>
        <LegendElement>
          <BulletPoint type="normal" />
          <Lang id="consumptionAnalysis.legend.previous" />
        </LegendElement>
        <LegendElement>
          <BulletPoint type="middle" />
          <Lang id="consumptionAnalysis.legend.current" />
        </LegendElement>
      </>
    ),
    comparativeAnalysis: (
      <>
        <LegendElement>
          <BulletPoint type="normal" />
          <Lang id="consumptionAnalysis.legend.previous" />
        </LegendElement>
        <LegendElement>
          <BulletPoint type="middle" />
          <Lang id="consumptionAnalysis.legend.current" />
        </LegendElement>
      </>
    ),
    comparativePlaneAnalysis: (
      <>
        <LegendElement>
          <SelectControl
            autoWidth
            largeOptions
            placeholder={<Lang id="consumptionAnalysis.names.linearChart" />}
            options={[
              {
                title: <Lang id="consumptionAnalysis.names.linearChart" />,
                value: LINEAR_CHART,
              },
              {
                title: <Lang id="consumptionAnalysis.names.histogramChart" />,
                value: HISTOGRAM_CHART,
              },
            ]}
            value={chartType}
            onChange={setChartType}
          />
        </LegendElement>
        <LegendElement>
          <BulletPoint type="low" />
          <Lang id="consumptionAnalysis.legend.plannedConsumption" />
        </LegendElement>
        <LegendElement>
          <BulletPoint type="middle" />
          <Lang id="consumptionAnalysis.legend.actualConsumption" />
        </LegendElement>
      </>
    ),
  }

  const unit = useMemo(() => (` (${objectType === HEAT_SUPPLY ? HEAT : KVmH})`), [objectType])
  const renderGraph = {
    differenceAnalysis: (
      <BarChart
        data={graphData}
        seriesNames={seriesNamesDifference}
        mainAxisName="dateStep"
        isStaked
        unit={unit}
        type={objectType}
      />
    ),
    comparativeAnalysis: (
      <BarChart
        data={graphData}
        seriesNames={seriesNamesComparing}
        mainAxisName="dateStep"
        unit={unit}
        type={objectType}
      />
    ),
    [`comparativePlaneAnalysis${LINEAR_CHART}`]: (
      <LineChartConsumption
        data={graphData}
        seriesNames={seriesForLineChartConsumption}
        mainAxisName="dateStep"
        unit={unit}
        type={objectType}
      />
    ),

    [`comparativePlaneAnalysis${HISTOGRAM_CHART}`]: (
      <BarChart
        data={graphData}
        seriesNames={seriesConsumptionComparing}
        mainAxisName="dateStep"
        unit={unit}
        type={objectType}
      />
    ),
  }

  return (
    <Container>
      <Header>
        {graphType === DEMONSTRATION_TABLE
          && (
            <DropDown
              content={renderExportTypes()}
              icon={LiveTime.ExportIcon}
              title={(<Lang id="consumptionAnalysis.names.export" />)}
              isOpen={isExportOpen}
              onClick={toggleExport}
              unit={unit}
            />
          )}
        {renderLegend[graphType]}
      </Header>
      {graphType === 'comparativePlaneAnalysis'
        ? renderGraph[`${graphType}${chartType}`]
        : renderGraph[graphType]}
    </Container>
  )
}

AnalysisGraph.propTypes = {
  graphData: pt.arrayOf(pt.object),
  graphType: pt.string,
}
AnalysisGraph.defaultProps = {
  graphData: [],
  graphType: null,
}

export default AnalysisGraph
