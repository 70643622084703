import { GET_LIVE_TIME_REPORT_FILE } from './index'

export const requestLiveTimeReportFile = (payload) => ({
  type: GET_LIVE_TIME_REPORT_FILE.REQUEST,
  payload,
})

export const successLiveTimeReportFile = (payload) => ({
  type: GET_LIVE_TIME_REPORT_FILE.SUCCESS,
  payload,
})

export const errorLiveTimeReportFile = (payload) => ({
  type: GET_LIVE_TIME_REPORT_FILE.ERROR,
  payload,
})
