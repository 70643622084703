const data = [
  [
    56.051487,
    35.9006159,
  ],
  [
    56.0481864,
    35.9069049,
  ],
  [
    56.0471086,
    35.9015118,
  ],
  [
    56.0448952,
    35.9012706,
  ],
  [
    56.0403637,
    35.9116046,
  ],
  [
    56.0373206,
    35.923084,
  ],
  [
    56.0339418,
    35.927357,
  ],
  [
    56.0304759,
    35.9305078,
  ],
  [
    56.0292598,
    35.9308862,
  ],
  [
    56.0281779,
    35.9282009,
  ],
  [
    56.02756,
    35.9267485,
  ],
  [
    56.0263961,
    35.9282322,
  ],
  [
    56.0260299,
    35.9294987,
  ],
  [
    56.0255595,
    35.9304227,
  ],
  [
    56.0245022,
    35.9309543,
  ],
  [
    56.0237096,
    35.9307003,
  ],
  [
    56.0225763,
    35.9293806,
  ],
  [
    56.0218328,
    35.9271812,
  ],
  [
    56.0220239,
    35.9245859,
  ],
  [
    56.0200127,
    35.9243204,
  ],
  [
    56.0179533,
    35.9198171,
  ],
  [
    56.0165139,
    35.91995,
  ],
  [
    56.0138452,
    35.9184694,
  ],
  [
    56.013326,
    35.9179494,
  ],
  [
    56.0115771,
    35.9165473,
  ],
  [
    56.007937,
    35.9137272,
  ],
  [
    56.0059623,
    35.9130502,
  ],
  [
    56.0032718,
    35.9113218,
  ],
  [
    56.0023372,
    35.9099035,
  ],
  [
    56.0016791,
    35.90944,
  ],
  [
    56.0008902,
    35.9101492,
  ],
  [
    56.0003052,
    35.9124921,
  ],
  [
    55.9991849,
    35.9132981,
  ],
  [
    55.998519,
    35.913105,
  ],
  [
    55.998495,
    35.9125471,
  ],
  [
    55.9977138,
    35.9121221,
  ],
  [
    55.9974822,
    35.9122502,
  ],
  [
    55.9968481,
    35.9122885,
  ],
  [
    55.995897,
    35.9138775,
  ],
  [
    55.995117,
    35.9146821,
  ],
  [
    55.9948697,
    35.9154889,
  ],
  [
    55.9942889,
    35.9160554,
  ],
  [
    55.9930834,
    35.9180751,
  ],
  [
    55.9922832,
    35.9181819,
  ],
  [
    55.9903789,
    35.9167546,
  ],
  [
    55.9891583,
    35.9151703,
  ],
  [
    55.988072,
    35.9132981,
  ],
  [
    55.987005,
    35.9134788,
  ],
  [
    55.9850472,
    35.916388,
  ],
  [
    55.9844711,
    35.9185123,
  ],
  [
    55.9814144,
    35.9203074,
  ],
  [
    55.9815519,
    35.9216347,
  ],
  [
    55.9834221,
    35.9216839,
  ],
  [
    55.9821103,
    35.9250001,
  ],
  [
    55.9814969,
    35.9265507,
  ],
  [
    55.9807543,
    35.9292053,
  ],
  [
    55.9838346,
    35.9295002,
  ],
  [
    55.9865571,
    35.9318107,
  ],
  [
    55.9860346,
    35.940512,
  ],
  [
    55.9871346,
    35.9511305,
  ],
  [
    55.9845221,
    35.9536868,
  ],
  [
    55.9838071,
    35.956833,
  ],
  [
    55.9811119,
    35.9575212,
  ],
  [
    55.9785815,
    35.9591926,
  ],
  [
    55.9793791,
    35.9672548,
  ],
  [
    55.9767661,
    35.9686804,
  ],
  [
    55.9769037,
    35.969762,
  ],
  [
    55.976216,
    35.9699586,
  ],
  [
    55.9760234,
    35.9729573,
  ],
  [
    55.9809193,
    35.9702044,
  ],
  [
    55.9824595,
    35.9739897,
  ],
  [
    55.9840067,
    35.9744751,
  ],
  [
    55.9873234,
    35.970236,
  ],
  [
    55.987845,
    35.9711644,
  ],
  [
    55.988145,
    35.9726083,
  ],
  [
    55.9885344,
    35.9730364,
  ],
  [
    55.9890739,
    35.972696,
  ],
  [
    55.989032,
    35.9733015,
  ],
  [
    55.994861,
    35.9753662,
  ],
  [
    55.996648,
    35.9735472,
  ],
  [
    55.9981042,
    35.9733237,
  ],
  [
    56.0027783,
    35.9751695,
  ],
  [
    56.0093473,
    35.9711876,
  ],
  [
    56.0092923,
    35.980872,
  ],
  [
    56.0181597,
    35.9741991,
  ],
  [
    56.0183251,
    35.9754115,
  ],
  [
    56.0200748,
    35.9758116,
  ],
  [
    56.0206273,
    35.9758351,
  ],
  [
    56.0211469,
    35.975788,
  ],
  [
    56.022186,
    35.9755527,
  ],
  [
    56.0226004,
    35.9757527,
  ],
  [
    56.0229556,
    35.9753526,
  ],
  [
    56.0236132,
    35.9749878,
  ],
  [
    56.0245208,
    35.9749054,
  ],
  [
    56.0260663,
    35.9754115,
  ],
  [
    56.0268841,
    35.9752017,
  ],
  [
    56.028311,
    35.9740328,
  ],
  [
    56.0292809,
    35.977892,
  ],
  [
    56.0296728,
    35.9800111,
  ],
  [
    56.0300322,
    35.9822763,
  ],
  [
    56.0303343,
    35.98517,
  ],
  [
    56.0305384,
    35.9907308,
  ],
  [
    56.0309467,
    35.9910085,
  ],
  [
    56.0331514,
    35.9959409,
  ],
  [
    56.0337401,
    35.9950099,
  ],
  [
    56.0358663,
    35.9913593,
  ],
  [
    56.0355479,
    35.9896859,
  ],
  [
    56.0342592,
    35.9867571,
  ],
  [
    56.0408168,
    35.9758613,
  ],
  [
    56.0422422,
    35.9730814,
  ],
  [
    56.0434561,
    35.9763487,
  ],
  [
    56.0468628,
    35.9754115,
  ],
  [
    56.0475434,
    35.9751718,
  ],
  [
    56.0473641,
    35.9716627,
  ],
  [
    56.0467103,
    35.9697956,
  ],
  [
    56.048816,
    35.9665702,
  ],
  [
    56.0476431,
    35.964088,
  ],
  [
    56.0534396,
    35.9511433,
  ],
  [
    56.0562519,
    35.9519491,
  ],
  [
    56.0589283,
    35.9475143,
  ],
  [
    56.0605204,
    35.9450776,
  ],
  [
    56.0610468,
    35.9413827,
  ],
  [
    56.0645335,
    35.9414954,
  ],
  [
    56.0666519,
    35.9414396,
  ],
  [
    56.0688025,
    35.9413452,
  ],
  [
    56.0692115,
    35.9412411,
  ],
  [
    56.0689723,
    35.9374185,
  ],
  [
    56.0688253,
    35.9350699,
  ],
  [
    56.0697719,
    35.9341912,
  ],
  [
    56.0738373,
    35.9324369,
  ],
  [
    56.0743881,
    35.9361179,
  ],
  [
    56.0770019,
    35.9355227,
  ],
  [
    56.0768096,
    35.9326405,
  ],
  [
    56.0773953,
    35.9324369,
  ],
  [
    56.0774215,
    35.9320766,
  ],
  [
    56.0852096,
    35.9304103,
  ],
  [
    56.0846413,
    35.9223651,
  ],
  [
    56.077261,
    35.9253379,
  ],
  [
    56.0769844,
    35.9237592,
  ],
  [
    56.0765648,
    35.922788,
  ],
  [
    56.0762938,
    35.9218639,
  ],
  [
    56.0763987,
    35.9200625,
  ],
  [
    56.0763987,
    35.9194673,
  ],
  [
    56.076119,
    35.9192637,
  ],
  [
    56.0757518,
    35.9194047,
  ],
  [
    56.0755857,
    35.9197806,
  ],
  [
    56.0754284,
    35.9199529,
  ],
  [
    56.075306,
    35.9196866,
  ],
  [
    56.0752497,
    35.9189074,
  ],
  [
    56.0751661,
    35.9184335,
  ],
  [
    56.0762326,
    35.9154104,
  ],
  [
    56.0762113,
    35.9150989,
  ],
  [
    56.0755099,
    35.9146386,
  ],
  [
    56.0750327,
    35.9141855,
  ],
  [
    56.0747432,
    35.9135425,
  ],
  [
    56.0745629,
    35.9128259,
  ],
  [
    56.0745066,
    35.9124245,
  ],
  [
    56.0745597,
    35.9120518,
  ],
  [
    56.0747432,
    35.9117595,
  ],
  [
    56.0749675,
    35.9117814,
  ],
  [
    56.0752856,
    35.9120664,
  ],
  [
    56.075518,
    35.912081,
  ],
  [
    56.0756812,
    35.9119933,
  ],
  [
    56.075779,
    35.9117887,
  ],
  [
    56.0757872,
    35.9115476,
  ],
  [
    56.0756994,
    35.9112125,
  ],
  [
    56.075236,
    35.9106486,
  ],
  [
    56.0748778,
    35.9104807,
  ],
  [
    56.0746193,
    35.9103148,
  ],
  [
    56.0737905,
    35.9102458,
  ],
  [
    56.0733718,
    35.9106135,
  ],
  [
    56.0732086,
    35.9113456,
  ],
  [
    56.0734201,
    35.9119619,
  ],
  [
    56.0741373,
    35.9123019,
  ],
  [
    56.0742078,
    35.9128943,
  ],
  [
    56.0739188,
    35.9134862,
  ],
  [
    56.0735482,
    35.9134986,
  ],
  [
    56.0732718,
    35.9130848,
  ],
  [
    56.0729182,
    35.9127433,
  ],
  [
    56.072626,
    35.9131792,
  ],
  [
    56.0726112,
    35.9140068,
  ],
  [
    56.0730667,
    35.9144375,
  ],
  [
    56.0734019,
    35.9149093,
  ],
  [
    56.0733871,
    35.9162693,
  ],
  [
    56.0731836,
    35.9168123,
  ],
  [
    56.0729427,
    35.9163488,
  ],
  [
    56.0726633,
    35.9164478,
  ],
  [
    56.0723112,
    35.9156375,
  ],
  [
    56.0719784,
    35.9151063,
  ],
  [
    56.0716838,
    35.9154651,
  ],
  [
    56.0716487,
    35.9161124,
  ],
  [
    56.0719038,
    35.9169345,
  ],
  [
    56.0719005,
    35.9176492,
  ],
  [
    56.0715824,
    35.9179195,
  ],
  [
    56.0706982,
    35.9179196,
  ],
  [
    56.070352,
    35.9180843,
  ],
  [
    56.0700149,
    35.9178977,
  ],
  [
    56.0697959,
    35.9176784,
  ],
  [
    56.0695675,
    35.917408,
  ],
  [
    56.0694248,
    35.9169988,
  ],
  [
    56.0690169,
    35.9169769,
  ],
  [
    56.0686049,
    35.9168234,
  ],
  [
    56.0683806,
    35.9163412,
  ],
  [
    56.0682623,
    35.9156397,
  ],
  [
    56.0680991,
    35.9147628,
  ],
  [
    56.0680461,
    35.9138859,
  ],
  [
    56.068197,
    35.9125852,
  ],
  [
    56.0682174,
    35.9117595,
  ],
  [
    56.0681889,
    35.911438,
  ],
  [
    56.0677646,
    35.9117376,
  ],
  [
    56.0673894,
    35.9122053,
  ],
  [
    56.0673649,
    35.9124829,
  ],
  [
    56.0674913,
    35.9128118,
  ],
  [
    56.0676178,
    35.9130602,
  ],
  [
    56.0676586,
    35.9134329,
  ],
  [
    56.0676178,
    35.9137617,
  ],
  [
    56.067471,
    35.9140394,
  ],
  [
    56.0672752,
    35.9141855,
  ],
  [
    56.0669978,
    35.9141855,
  ],
  [
    56.0667408,
    35.9139736,
  ],
  [
    56.0665939,
    35.913674,
  ],
  [
    56.0665491,
    35.9132575,
  ],
  [
    56.0666062,
    35.912746,
  ],
  [
    56.0668387,
    35.9122126,
  ],
  [
    56.0665491,
    35.9123076,
  ],
  [
    56.066337,
    35.9125487,
  ],
  [
    56.0661085,
    35.9135133,
  ],
  [
    56.06608,
    35.9142367,
  ],
  [
    56.0661126,
    35.9147993,
  ],
  [
    56.0663084,
    35.9153839,
  ],
  [
    56.0663533,
    35.9158077,
  ],
  [
    56.0662954,
    35.9162762,
  ],
  [
    56.0661259,
    35.9166075,
  ],
  [
    56.0657857,
    35.9167942,
  ],
  [
    56.0646272,
    35.9173761,
  ],
  [
    56.0639713,
    35.9175573,
  ],
  [
    56.0638886,
    35.9176341,
  ],
  [
    56.0637813,
    35.9179635,
  ],
  [
    56.0637507,
    35.9181447,
  ],
  [
    56.0637415,
    35.9185016,
  ],
  [
    56.063815,
    35.9186169,
  ],
  [
    56.0639529,
    35.9185729,
  ],
  [
    56.0640817,
    35.9184686,
  ],
  [
    56.0643575,
    35.9181063,
  ],
  [
    56.0646027,
    35.9180624,
  ],
  [
    56.0647376,
    35.9182106,
  ],
  [
    56.0648295,
    35.9188255,
  ],
  [
    56.0647529,
    35.9195117,
  ],
  [
    56.0644801,
    35.9206756,
  ],
  [
    56.0643544,
    35.9210764,
  ],
  [
    56.0642257,
    35.9212191,
  ],
  [
    56.0640725,
    35.9212795,
  ],
  [
    56.0636924,
    35.9212191,
  ],
  [
    56.063533,
    35.921027,
  ],
  [
    56.0631347,
    35.9201336,
  ],
  [
    56.0627635,
    35.9202432,
  ],
  [
    56.062278,
    35.920594,
  ],
  [
    56.0615477,
    35.9214343,
  ],
  [
    56.0622331,
    35.9219751,
  ],
  [
    56.0625962,
    35.9215951,
  ],
  [
    56.0627553,
    35.9216024,
  ],
  [
    56.0628165,
    35.9219531,
  ],
  [
    56.0626003,
    35.9227423,
  ],
  [
    56.0622127,
    35.9235242,
  ],
  [
    56.0618456,
    35.9238969,
  ],
  [
    56.0615837,
    35.9239532,
  ],
  [
    56.0614979,
    35.9237391,
  ],
  [
    56.0613875,
    35.9235963,
  ],
  [
    56.0612527,
    35.9235799,
  ],
  [
    56.0611112,
    35.9236777,
  ],
  [
    56.0609847,
    35.9242038,
  ],
  [
    56.0608297,
    35.9243426,
  ],
  [
    56.0606624,
    35.9243938,
  ],
  [
    56.0603973,
    35.9242184,
  ],
  [
    56.0603197,
    35.9243134,
  ],
  [
    56.0600668,
    35.9248468,
  ],
  [
    56.0598424,
    35.924993,
  ],
  [
    56.0595893,
    35.9249317,
  ],
  [
    56.0593202,
    35.9250368,
  ],
  [
    56.0590999,
    35.9249418,
  ],
  [
    56.0589367,
    35.9247591,
  ],
  [
    56.0587514,
    35.9244308,
  ],
  [
    56.0586319,
    35.9239697,
  ],
  [
    56.0586319,
    35.9231077,
  ],
  [
    56.0584357,
    35.9226795,
  ],
  [
    56.0583437,
    35.9221799,
  ],
  [
    56.0583437,
    35.9213235,
  ],
  [
    56.0582364,
    35.9208074,
  ],
  [
    56.0581513,
    35.9205767,
  ],
  [
    56.0580042,
    35.9203516,
  ],
  [
    56.057995,
    35.9202089,
  ],
  [
    56.0581084,
    35.9202144,
  ],
  [
    56.0582414,
    35.9204187,
  ],
  [
    56.0583964,
    35.9208571,
  ],
  [
    56.058478,
    35.9209229,
  ],
  [
    56.0586412,
    35.9206817,
  ],
  [
    56.058784,
    35.9203091,
  ],
  [
    56.058792,
    35.9200661,
  ],
  [
    56.0584211,
    35.9198081,
  ],
  [
    56.0582402,
    35.9195995,
  ],
  [
    56.0580808,
    35.9193085,
  ],
  [
    56.0579888,
    35.9190121,
  ],
  [
    56.0580275,
    35.9185384,
  ],
  [
    56.0582154,
    35.9178264,
  ],
  [
    56.0585045,
    35.9173862,
  ],
  [
    56.0588009,
    35.917192,
  ],
  [
    56.0592128,
    35.9173086,
  ],
  [
    56.0593429,
    35.9170885,
  ],
  [
    56.059249,
    35.9169202,
  ],
  [
    56.0587647,
    35.9163377,
  ],
  [
    56.0586129,
    35.9158846,
  ],
  [
    56.0585432,
    35.9147549,
  ],
  [
    56.0584177,
    35.914434,
  ],
  [
    56.0583107,
    35.9143213,
  ],
  [
    56.0572081,
    35.9143636,
  ],
  [
    56.057053,
    35.9142609,
  ],
  [
    56.0568304,
    35.9137657,
  ],
  [
    56.0566348,
    35.9133671,
  ],
  [
    56.0563954,
    35.9133067,
  ],
  [
    56.056301,
    35.912884,
  ],
  [
    56.0563044,
    35.9127209,
  ],
  [
    56.056419,
    35.9125216,
  ],
  [
    56.0564527,
    35.912274,
  ],
  [
    56.0564156,
    35.9119842,
  ],
  [
    56.0562335,
    35.9114527,
  ],
  [
    56.056102,
    35.9112776,
  ],
  [
    56.0560245,
    35.9114769,
  ],
  [
    56.056038,
    35.9118332,
  ],
  [
    56.0559503,
    35.9120808,
  ],
  [
    56.0558323,
    35.9122016,
  ],
  [
    56.0556878,
    35.9122331,
  ],
  [
    56.051487,
    35.9006159,
  ],
]

export default [[...data]]
