import React, { useMemo } from 'react'
import moment from 'moment'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import {
  IndicatorWrapper,
} from '@/components/widgets/StateIndicator/styles'
import {
  Container,
  Text,
} from './styles'

const SmallWidgetIndicator = ({
  className,
  title,
  icon,
  value,
  units,
  condition,
}) => {
  const valueNumber = useMemo(() => {
    if (units === 'time') {
      return moment(value).format('DD.MM.YYYY HH:mm')
    }
    if (units === 'string') {
      return value ? `${value}`.toLowerCase() : 'неизвестно'
    }
    if (units === 'text') {
      const newValue = typeof value === 'string'
        ? value.toLowerCase()
        : value
      return condition[newValue]
    }
    return numberWithSpaces(Number.parseFloat(value || 0, 10).toFixed(3))
  }, [condition, units, value])

  const renderValue = useMemo(() => {
    if (units === 'time' || units === 'text' || units === 'string') {
      return valueNumber || <Lang id="mapsPage.widgets.unknown" />
    }
    return `${valueNumber} ${units || ''}`
  }, [units, valueNumber])
  return (
    <IndicatorWrapper
      column
      small
      className={className}
      withoutSvgStyle
    >
      <Container>
        {icon}
        <Text iconTitle={!!icon}>{title}</Text>
      </Container>
      <Text value>
        {renderValue}
      </Text>
    </IndicatorWrapper>
  )
}

SmallWidgetIndicator.propTypes = {
}
SmallWidgetIndicator.defaultProps = {
}

export default memoWithName(SmallWidgetIndicator)
