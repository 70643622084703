import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AlertManagerIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5875 3.4186C21.0372 1.44049 22.8699 0 25 0C27.1301 0 28.9628 1.44049 29.4125 3.4186C38.2649 5.33991 44.9196 12.928 44.9196 22.0519V35.604C46.6928 36.1797 48 37.7918 48 39.7406C48 42.1877 45.9389 44.1038 43.4821 44.1038H32.4567C31.7693 47.4883 28.6633 50 25 50C21.3367 50 18.2306 47.4883 17.5433 44.1038H6.51786C4.06111 44.1038 2 42.1877 2 39.7406C2 37.7918 3.30718 36.1797 5.08036 35.604V22.0519C5.08036 12.928 11.7351 5.33991 20.5875 3.4186ZM25 47.1698C22.8875 47.1698 21.1387 45.8684 20.5184 44.1038H29.4816C28.8613 45.8684 27.1125 47.1698 25 47.1698ZM25 2.83019C24.0542 2.83019 23.3571 3.55381 23.3571 4.36321V4.60761C23.3571 5.30121 22.8465 5.89258 22.1511 6.00424C14.0645 7.30274 7.95536 14.0207 7.95536 22.0519V36.7925C7.95536 37.574 7.31177 38.2075 6.51786 38.2075C5.57204 38.2075 4.875 38.9312 4.875 39.7406C4.875 40.55 5.57204 41.2736 6.51786 41.2736H18.8393H31.1607H43.4821C44.428 41.2736 45.125 40.55 45.125 39.7406C45.125 38.9312 44.428 38.2075 43.4821 38.2075C42.6882 38.2075 42.0446 37.574 42.0446 36.7925V22.0519C42.0446 14.0207 35.9355 7.30274 27.8489 6.00424C27.1535 5.89258 26.6429 5.30121 26.6429 4.60761V4.36321C26.6429 3.55381 25.9458 2.83019 25 2.83019Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AlertManagerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AlertManagerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AlertManagerIcon)
