import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TrashpinIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8903 7.60984C10.8233 7.68016 10.7857 7.77555 10.7857 7.875V13.125C10.7857 13.2245 10.8233 13.3198 10.8903 13.3902C10.9573 13.4605 11.0481 13.5 11.1429 13.5C11.2376 13.5 11.3284 13.4605 11.3954 13.3902C11.4624 13.3198 11.5 13.2245 11.5 13.125V7.875C11.5 7.77555 11.4624 7.68016 11.3954 7.60984C11.3284 7.53951 11.2376 7.5 11.1429 7.5C11.0481 7.5 10.9573 7.53951 10.8903 7.60984Z" fill={fill} className="svgFill" />
      <path d="M8.74746 7.60984C8.68048 7.68016 8.64286 7.77555 8.64286 7.875V13.125C8.64286 13.2245 8.68048 13.3198 8.74746 13.3902C8.81444 13.4605 8.90528 13.5 9 13.5C9.09472 13.5 9.18556 13.4605 9.25254 13.3902C9.31952 13.3198 9.35714 13.2245 9.35714 13.125V7.875C9.35714 7.77555 9.31952 7.68016 9.25254 7.60984C9.18556 7.53951 9.09472 7.5 9 7.5C8.90528 7.5 8.81444 7.53951 8.74746 7.60984Z" fill={fill} className="svgFill" />
      <path d="M6.6046 7.60984C6.53763 7.68016 6.5 7.77555 6.5 7.875V13.125C6.5 13.2245 6.53763 13.3198 6.6046 13.3902C6.67158 13.4605 6.76242 13.5 6.85714 13.5C6.95186 13.5 7.0427 13.4605 7.10968 13.3902C7.17666 13.3198 7.21429 13.2245 7.21429 13.125V7.875C7.21429 7.77555 7.17666 7.68016 7.10968 7.60984C7.0427 7.53951 6.95186 7.5 6.85714 7.5C6.76242 7.5 6.67158 7.53951 6.6046 7.60984Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM5.0281 14.6705C4.82717 14.4595 4.71429 14.1734 4.71429 13.875V6H13.2857V13.875C13.2857 14.1734 13.1728 14.4595 12.9719 14.6705C12.771 14.8815 12.4984 15 12.2143 15H5.78571C5.50155 15 5.22903 14.8815 5.0281 14.6705ZM10.9643 3.75H13.6429C13.7376 3.75 13.8284 3.78951 13.8954 3.85984C13.9624 3.93016 14 4.02555 14 4.125V4.875C14 4.97446 13.9624 5.06984 13.8954 5.14017C13.8284 5.21049 13.7376 5.25 13.6429 5.25H4.35714C4.26242 5.25 4.17158 5.21049 4.1046 5.14017C4.03763 5.06984 4 4.97446 4 4.875V4.125C4 4.02555 4.03763 3.93016 4.1046 3.85984C4.17158 3.78951 4.26242 3.75 4.35714 3.75H7.03571L7.24554 3.31172C7.28923 3.2179 7.35721 3.13889 7.4417 3.08376C7.52618 3.02863 7.62375 2.9996 7.72321 3H10.2746C10.3742 2.99993 10.472 3.02907 10.5568 3.08414C10.6416 3.1392 10.71 3.21802 10.7545 3.31172L10.9643 3.75Z" fill={fill} className="svgFill" />
    </svg>
  )
}

TrashpinIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrashpinIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrashpinIcon)
