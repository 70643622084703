import React, { useEffect } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import TyumenOurHomeFilter from '@/forms/TyumenOurHomeFilter'
import {
  APPEAL_TOPICS,
  APPEAL_STATUSES,
} from '@/constants/tyumenOurHome'

import Graphs from '../Graphs'
import { Container } from './styles'

const GraphsPage = ({
  requestGetTyumenGraphs,
  graphs,
  dateGraph,
  getDateGraph,
}) => {
  useEffect(() => {
    getDateGraph()
  }, [getDateGraph])
  return (
    <Container>
      <TyumenOurHomeFilter
        requestGetTyumenGraphs={requestGetTyumenGraphs}
        dateGraph={dateGraph}
      />
      <Graphs data={graphs} />
    </Container>
  )
}

GraphsPage.propTypes = {
  requestGetTyumenGraphs: pt.func,
  getDateGraph: pt.func,
  graphs: pt.shape({
    [APPEAL_TOPICS]: pt.array,
    [APPEAL_STATUSES]: pt.array,
  }),
}
GraphsPage.defaultProps = {
  requestGetTyumenGraphs: noop,
  getDateGraph: noop,
  graphs: {},
}

export default GraphsPage
