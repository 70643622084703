import React, { useState, useCallback } from 'react'

import WidgetsIcons from '@/components/icons/widgets'
import {
  OK,
  WARNING,
  ERROR,
  STATUS_COLORS,
} from '@/constants/alarms'

import { Container, Header, Content } from './styles'

const ICON_BY_STATUS = {
  [OK]: WidgetsIcons.HappyIcon,
  [WARNING]: WidgetsIcons.IndifferentIcon,
  [ERROR]: WidgetsIcons.BadIcon,
}

const AppealCard = ({
  type,
  number,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setIsOpen((open) => !open)
  }, [])
  const Component = ICON_BY_STATUS[type]
  return (
    <Container>
      <Header onClick={handleToggle} open={isOpen} color={STATUS_COLORS[type]}>
        <Component white={isOpen} />
        №
        {number}
      </Header>
      <Content open={isOpen}>{description}</Content>
    </Container>
  )
}

export default AppealCard
