import {
  COUNTRY,
  REGION,
  CITY,
} from '@/constants/objectTypes'

export const isZone = (node) => node.type === COUNTRY
  || node.type === REGION
  || node.type === CITY

export default isZone
