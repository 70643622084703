import React, { useCallback } from 'react'
import { Legend } from 'recharts'
import BarChart from '@/components/charts/BarChart'
import DoublePieChart from '@/components/charts/DoublePieChart'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import {
  seriesNamesAppeal,
  seriesNameSettings,
  seriesNamesAverage,
  seriesStatusSettings,
} from '@/constants/graph/appealGraph'
import {
  ALL_CHARTS,
  APPEAL_TOPICS,
  APPEAL_STATUSES,
  APPEAL_STATISTIC,
  APPEAL_TIME,
} from '@/constants/tyumenOurHome'
import {
  CHART_TYPE,
} from '@/constants/forms/tyumenOurHomeFilter'

import {
  Container,
  ItemContainer,
  Title,
} from './styles'

const Graphs = ({ data }) => {
  const [urlValues] = useUrlParseAndPush()

  const renderCustomizedLegend = useCallback((props) => {
    return (
      <Title graph>
        {props.payload.value}
      </Title>
    )
  }, [])
  return (
    <Container
      oneGraph={urlValues[CHART_TYPE] !== ALL_CHARTS}
    >
      {(urlValues[CHART_TYPE] === ALL_CHARTS
        || urlValues[CHART_TYPE] === APPEAL_STATISTIC
        || urlValues[CHART_TYPE] === APPEAL_TOPICS)
      && (
        <>
          {(urlValues[CHART_TYPE] === ALL_CHARTS || urlValues[CHART_TYPE] === APPEAL_STATISTIC) && (
            <ItemContainer>
              <BarChart
                data={data[APPEAL_STATISTIC]}
                seriesNames={seriesNamesAppeal}
                mainAxisName="dateStep"
                customGraphMargin={{
                  top: 0,
                  right: 30,
                  left: -20,
                  bottom: 5,
                }}
                legend={{
                  align: 'left',
                  verticalAlign: 'bottom',
                  iconType: 'square',
                  title: 'Статистика по обращениям',
                  pl: 40,
                }}
                graphLegend={(
                  <Legend
                    verticalAlign="top"
                    align="left"
                    wrapperStyle={{
                      paddingBottom: '15px',
                    }}
                    payload={{
                      value: 'Обр.',
                      type: 'line',
                      id: 'statistic',
                    }}
                    content={renderCustomizedLegend}
                  />
                )}
                brush={{
                  height: 18,
                  travellerWidth: 10,
                  dataKey: 'dateStep',
                }}
              />
            </ItemContainer>
          )}
          {(
            urlValues[CHART_TYPE] === ALL_CHARTS
            || urlValues[CHART_TYPE] === APPEAL_TOPICS
          ) && (
            <ItemContainer>
              <DoublePieChart
                data={data[APPEAL_TOPICS]}
                seriesNames={seriesNameSettings}
                elementNames={['обращение', 'обращения', 'обращений']}
                mainAxisName="dateStep"
                unit="Обр."
                legend={{
                  align: 'left',
                  verticalAlign: 'bottom',
                  title: 'Тематика обращений',
                }}
                brush={{
                  height: 18,
                  travellerWidth: 10,
                  dataKey: 'dateStep',
                }}
              />
            </ItemContainer>
          )}
        </>
      )}
      {(urlValues[CHART_TYPE] === ALL_CHARTS
        || urlValues[CHART_TYPE] === APPEAL_TIME
        || urlValues[CHART_TYPE] === APPEAL_STATUSES)
      && (
        <>
          {(urlValues[CHART_TYPE] === ALL_CHARTS || urlValues[CHART_TYPE] === APPEAL_TIME) && (
            <ItemContainer
              show={urlValues[CHART_TYPE] === ALL_CHARTS
                || urlValues[CHART_TYPE] === APPEAL_TIME
                || urlValues[CHART_TYPE] === APPEAL_TOPICS}
            >
              <BarChart
                data={data[APPEAL_TIME]}
                seriesNames={seriesNamesAverage}
                customGraphMargin={{
                  top: 0,
                  right: 30,
                  left: -20,
                  bottom: 5,
                }}
                mainAxisName="dateStep"
                legend={{
                  align: 'left',
                  verticalAlign: 'bottom',
                  iconType: 'square',
                  title: 'Среднее вермя принятия и рассмотрения решений',
                  pl: 40,
                }}
                graphLegend={(
                  <Legend
                    verticalAlign="top"
                    align="left"
                    wrapperStyle={{
                      paddingBottom: '15px',
                    }}
                    payload={{
                      value: 'Дн.',
                      type: 'line',
                      id: 'requests',
                    }}
                    content={renderCustomizedLegend}
                  />
                )}
                brush={{
                  height: 18,
                  travellerWidth: 10,
                  dataKey: 'dateStep',
                }}
              />
            </ItemContainer>
          )}
          {(urlValues[CHART_TYPE] === ALL_CHARTS || urlValues[CHART_TYPE] === APPEAL_STATUSES) && (
            <ItemContainer>
              <DoublePieChart
                data={data[APPEAL_STATUSES]}
                seriesNames={seriesStatusSettings}
                elementNames={['статус', 'статуса', 'статусов']}
                mainAxisName="dateStep"
                legend={{
                  align: 'left',
                  verticalAlign: 'bottom',
                  title: 'Статусы решений по обращениям',
                }}
                brush={{
                  height: 18,
                  travellerWidth: 10,
                  dataKey: 'dateStep',
                }}
              />
            </ItemContainer>
          )}
        </>
      )}
    </Container>
  )
}

Graphs.propTypes = {}
Graphs.defaultProps = {}

export default Graphs
