import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import isEmpty from 'lodash/isEmpty'
import shortId from 'shortid'
import moment from 'moment'
import TextField from '@/components/fields/TextField'
import SelectField from '@/components/fields/SelectField'
import CheckBoxField from '@/components/fields/CheckBoxField'
import UsersIcons from '@/components/icons/users'
import Loader from '@/components/blocks/Loader'
import { validationRecipients } from '@/constants/validationFields'
import {
  REPORT_TITLE,
  REPORT_TYPE,
  TYPE_OBJECT,
  REPLAY,
  HOURLY,
  GEO_ZONE_ID,
  WEEKLY,
  MONTHLY,
  RECIPIENT_ID,
  USER_NAME,
  USER_EMAIL,
  REPLAY_WEEKLY,
  REPLAY_MONTHLY,
  TIMEZONE,
  SYSTEM_USERS,
  EXTERNAL_RECIPIENTS,
  TIME,
  HOURLY_TIME,
  USERS_ID,
  EMAIL,
} from '@/constants/forms/reportManager'
import {
  TIMETABLE,
  PARAMETERS,
  NEWSLETTER,
} from '@/constants/reportManager'
import {
  REPEAT_CONFIG,
  REPEAT_WEEKLY_CONFIG,
  REPEAT_MONTHLY_CONFIG,
  TIMEZONE_CONFIG,
} from './config'
import {
  FormWrapper,
  Container,
  Text,
  FieldContainer,
  SelectFieldStyled,
  UserContainer,
  StyledButton,
  RemoveButton,
  DeleteUserContainer,
  CancelButton,
  LoaderContainer,
  CheckBoxContainer,
  StyledHoursControl,
  TextControlContainer,
  StyledTimeField,
  RecipientContainer,
  RecipientText,
} from './styles'

const ReportManagerForm = ({
  activeTab,
  reportFormOpen,
  getTypes,
  reportTypes,
  isTypesLoading,
  getObjectTypes,
  reportObjectTypes,
  isObjectTypesLoading,
  getCommonUsers,
  commonUsers,
  isCommonUsersLoading,
  setGroupFromProps,
  selectedReport,
  loadingSelectedReport,
  reportViewed,
  geoZoneId,
}) => {
  const formicForm = useRef(null)
  const [recipientIdForm, setRecipientIdForm] = useState([])

  const reportFormIsViewed = useMemo(() => (
    reportViewed && !isEmpty(selectedReport)
  ), [reportViewed, selectedReport])

  useEffect(() => {
    if (reportFormOpen) {
      getTypes()
      getCommonUsers()
    }
  }, [getCommonUsers, getTypes, reportFormOpen])

  const renderField = useCallback(({ component, value }) => {
    if (reportFormOpen) {
      return component
    }
    if (value && !reportFormOpen) {
      return <Text info>{value}</Text>
    }
    return null
  }, [reportFormOpen])

  const renderText = useCallback((textName) => (
    reportFormOpen
      ? (
        <>
          <Lang id={`reportManager.form.${textName}`} />
          *
        </>
      )
      : <Lang id={`reportManager.form.${textName}`} />
  ), [reportFormOpen])

  const handleGetObjectTypes = useCallback((fieldName, value) => {
    getObjectTypes({
      type: value,
      geoZoneId: reportFormIsViewed ? selectedReport.geoZoneId.id : geoZoneId,
      form: formicForm.current,
    })
    get(formicForm, 'current.setFieldValue', noop)(TYPE_OBJECT, undefined)
  }, [
    selectedReport.geoZoneId,
    reportFormIsViewed,
    getObjectTypes,
    formicForm,
    geoZoneId,
  ])

  const renderParameters = useCallback((values) => (
    <Container active={activeTab === PARAMETERS}>
      <Text>
        {renderText(!reportFormOpen ? 'geoZone' : 'reportTitle')}
      </Text>
      {renderField({
        component: (
          <TextField
            name={REPORT_TITLE}
            resetButton
          />
        ),
        value: !reportFormOpen ? values[GEO_ZONE_ID] : values[REPORT_TITLE],
      })}
      <Text>
        {renderText('reportType')}
      </Text>
      {renderField({
        component: (
          <SelectField
            name={REPORT_TYPE}
            options={reportTypes}
            placeholder={<Lang id="reportManager.selectFromList" />}
            onAfterChange={handleGetObjectTypes}
          />
        ),
        value: values[REPORT_TYPE],
      })}
      <Text>
        {renderText('typeObject')}
      </Text>
      {renderField({
        component: (
          <SelectField
            name={TYPE_OBJECT}
            options={reportObjectTypes}
            placeholder={<Lang id="reportManager.selectFromList" />}
            multiselect
            disabled={!values[REPORT_TYPE]}
            selectAll={
              !isEmpty(reportObjectTypes) && values[REPORT_TYPE]
                ? {
                  value: 'ALL',
                  title: <Lang id="reportManager.allObjects" />,
                }
                : null
              }
            multiselectTitleAll={<Lang id="reportManager.allObjects" />}
          />
        ),
        value: values[TYPE_OBJECT],
      })}
    </Container>
  ),
  [
    activeTab,
    handleGetObjectTypes,
    renderField,
    renderText,
    reportObjectTypes,
    reportTypes,
    reportFormOpen,
  ])

  const renderEnumeration = useCallback((values) => {
    if (values[REPLAY] === WEEKLY) {
      return (
        <SelectFieldStyled
          name={REPLAY_WEEKLY}
          options={REPEAT_WEEKLY_CONFIG}
          enumeration
          optionsPosition={{
            left: '-233px',
          }}
        />
      )
    }
    if (values[REPLAY] === MONTHLY) {
      return (
        <SelectFieldStyled
          name={REPLAY_MONTHLY}
          options={REPEAT_MONTHLY_CONFIG}
          datePicker
          optionsPosition={{
            left: '-226px',
          }}
        />
      )
    }
    return null
  }, [])

  const handleOnClickTimeField = useCallback((event) => (
    event.stopPropagation()
  ), [])

  const handleOnChangeTimeField = useCallback((value) => (
    formicForm.current.setFieldValue(HOURLY_TIME, value)
  ), [])

  const renderTimeField = useCallback(() => (
    <TextControlContainer>
      <StyledHoursControl
        onClick={handleOnClickTimeField}
        onChange={handleOnChangeTimeField}
        value={formicForm.current.state.values[HOURLY_TIME]}
      />
    </TextControlContainer>
  ), [handleOnChangeTimeField, handleOnClickTimeField])

  const renderTimeTable = useCallback((values) => (
    <Container active={activeTab === TIMETABLE}>
      <Text>
        {renderText('repeat')}
      </Text>
      <FieldContainer>
        {renderField({
          component: (
            <>
              <SelectFieldStyled
                name={REPLAY}
                options={REPEAT_CONFIG}
                placeholder={<Lang id="reportManager.selectFromList" />}
                mainField
                withRadioButton
                WithFirstExtraComponent={renderTimeField}
                additionalValue={values[REPLAY] === HOURLY && ` ${values[HOURLY_TIME]} ч.`}
                largeOptions={values[REPLAY]}
              />
              {values[REPLAY] && (
              <StyledTimeField
                name={TIME}
              />
              )}
              {renderEnumeration(values)}
            </>
          ),
          value: values[REPLAY],
        })}
      </FieldContainer>
      <Text>
        {renderText('timezone')}
      </Text>
      {renderField({
        component: (
          <SelectField
            name={TIMEZONE}
            options={TIMEZONE_CONFIG}
            placeholder={<Lang id="reportManager.selectFromList" />}
          />
        ),
        value: values[TIMEZONE],
      })}
    </Container>
  ), [activeTab, renderEnumeration, renderField, renderText, renderTimeField])

  const addRecipientEmail = useCallback((setFieldValue, values) => () => {
    if (values[RECIPIENT_ID]) {
      const newValue = [
        ...values[RECIPIENT_ID],
        {
          id: shortId.generate(),
          active: true,
        },
      ]
      setFieldValue(RECIPIENT_ID, newValue)
    } else {
      setFieldValue(RECIPIENT_ID, [{
        id: shortId.generate(),
        active: true,
      }])
    }
  }, [])

  const removeRecipientEmail = useCallback((setFieldValue, values, id) => () => {
    const elementIndex = values[RECIPIENT_ID].findIndex((recipient) => recipient.id === id)
    const newValue = [...values[RECIPIENT_ID]]
    newValue[elementIndex] = {
      id: newValue[elementIndex].id,
      active: !newValue[elementIndex].active,
    }
    setFieldValue(RECIPIENT_ID, newValue)
  }, [])

  const renderNewsLetter = useCallback((setFieldValue, values) => (
    <Container active={activeTab === NEWSLETTER}>
      <Text>
        {renderText('systemUsers')}
      </Text>
      {renderField({
        component: (
          <SelectField
            name={SYSTEM_USERS}
            placeholder={<Lang id="reportManager.selectFromList" />}
            options={commonUsers}
            multiselect
            withSearch
          />
        ),
        value: null,
      })}
      {reportFormIsViewed && !reportFormOpen && (
      <SelectField
        placeholder={<Lang id="reportManager.selectFromList" />}
        options={values[SYSTEM_USERS]}
        value={values[USERS_ID]}
        multiselect
        withSearch
        withoutSelect
      />
      )}
      <Text>
        {renderText(reportFormIsViewed && reportFormOpen ? 'externalRecipients' : 'emailNewsletter')}
      </Text>
      {renderField({
        component: (
          <CheckBoxContainer>
            <CheckBoxField
              name={EXTERNAL_RECIPIENTS}
            />
            <Text withoutMargin text>
              <Lang id="reportManager.form.emailNewsletter" />
            </Text>
          </CheckBoxContainer>
        ),
        value: values[EMAIL],
      })}
      {reportFormIsViewed && !reportFormOpen && (
      <>
        {Array.isArray(values[EXTERNAL_RECIPIENTS]) && values[EXTERNAL_RECIPIENTS].map((recipient) => (
          <RecipientContainer>
            <UsersIcons.UserAvatarIcon />
            <RecipientText>
              <Text withoutMargin>
                {recipient.name}
              </Text>
              <Text withoutMargin>
                {recipient.email}
              </Text>
            </RecipientText>
          </RecipientContainer>
        ))}
      </>
      )}
      {reportFormOpen && values[RECIPIENT_ID] && values[RECIPIENT_ID].map((recipient) => (
        <UserContainer key={recipient.id} active={recipient.active}>
          {recipient.active
            ? (
              <>
                <RemoveButton onClick={removeRecipientEmail(setFieldValue, values, recipient.id)}>
                  <UsersIcons.TrashIcon />
                </RemoveButton>
                <Text>
                  {renderText('userName')}
                </Text>
                <TextField
                  name={`${USER_NAME}-${recipient.id}`}
                  resetButton
                />
                <Text>
                  {renderText('userEmail')}
                </Text>
                <TextField
                  name={`${USER_EMAIL}-${recipient.id}`}
                  resetButton
                />
              </>
            )
            : (
              <DeleteUserContainer>
                <Text withoutMargin>
                  <Lang id="reportManager.deletedUser" />
                </Text>
                <CancelButton onClick={removeRecipientEmail(setFieldValue, values, recipient.id)}>
                  <Lang id="reportManager.cancel" />
                </CancelButton>
              </DeleteUserContainer>
            )}
        </UserContainer>
      ))}
      {reportFormOpen && (
      <StyledButton type="button" onClick={addRecipientEmail(setFieldValue, values)}>
        <Lang id="reportManager.addUser" />
      </StyledButton>
      )}
    </Container>
  ),
  [
    activeTab,
    addRecipientEmail,
    commonUsers,
    removeRecipientEmail,
    renderField,
    renderText,
    reportFormOpen,
    reportFormIsViewed,
  ])

  const loadingValue = useMemo(() => (
    isTypesLoading || isObjectTypesLoading || isCommonUsersLoading || loadingSelectedReport
  ), [isCommonUsersLoading, isObjectTypesLoading, isTypesLoading, loadingSelectedReport])

  const renderValues = useCallback((value) => {
    if (value.length > 1) {
      return `${value[0].title} и еще ${value.length - 1}`
    }
    if (value.length <= 1) {
      return value[0].title
    }
    return value.title || value
  }, [])

  const scheduleValue = useCallback((type, value) => {
    if (type === HOURLY) {
      return {
        [HOURLY_TIME]: value,
        [REPLAY_WEEKLY]: '1',
        [REPLAY_MONTHLY]: '1',
      }
    }
    if (type === WEEKLY) {
      return {
        [REPLAY_WEEKLY]: value,
        [HOURLY_TIME]: '1',
        [REPLAY_MONTHLY]: '1',
      }
    }
    if (type === MONTHLY) {
      return {
        [REPLAY_MONTHLY]: value,
        [HOURLY_TIME]: '1',
        [REPLAY_WEEKLY]: '1',
      }
    }
    return {}
  }, [])

  const initialValues = useMemo(() => {
    if (reportFormIsViewed && reportFormOpen) {
      getObjectTypes({
        type: selectedReport.type.code,
        geoZoneId: selectedReport.geoZoneId.id,
        form: formicForm.current,
      })
      const recipients = selectedReport.externalRecipients.reduce((accumulator, currentValue) => ({
        ...accumulator,
        [`${USER_NAME}-${currentValue.id}`]: currentValue.name,
        [`${USER_EMAIL}-${currentValue.id}`]: currentValue.email,
      }), {})
      return {
        [REPORT_TITLE]: selectedReport.title,
        [REPORT_TYPE]: selectedReport.type.code,
        [TYPE_OBJECT]: selectedReport.objectTypes.map((objectType) => objectType.code),
        [REPLAY]: selectedReport.schedule.type,
        [TIME]: moment.utc(selectedReport.schedule.start).utcOffset((selectedReport.schedule.timeZone || 0) * 60).format('HH:mm'),
        [TIMEZONE]: (selectedReport.schedule.timeZone || 0) * 60,
        [SYSTEM_USERS]: selectedReport.users.map((user) => user.id),
        [EXTERNAL_RECIPIENTS]: selectedReport.externalSendEnabled,
        [RECIPIENT_ID]: selectedReport.externalRecipients.map(
          (externalRecipient) => ({ id: externalRecipient.id, active: true }),
        ),
        ...recipients,
        ...scheduleValue(selectedReport.schedule.type, selectedReport.schedule.timeValue),
      }
    }
    if (reportFormIsViewed && !reportFormOpen) {
      return {
        [REPORT_TITLE]: selectedReport.title,
        [REPORT_TYPE]: selectedReport.type.title,
        [TYPE_OBJECT]: renderValues(selectedReport.objectTypes),
        [GEO_ZONE_ID]: selectedReport.geoZoneId.title,
        [REPLAY]: selectedReport.scheduleView,
        [SYSTEM_USERS]: selectedReport.users,
        [USERS_ID]: selectedReport[USERS_ID],
        [EMAIL]: selectedReport.externalSendEnabled ? 'Включена' : 'Выключена',
        [EXTERNAL_RECIPIENTS]: selectedReport.externalRecipients,
        [TIMEZONE]: (TIMEZONE_CONFIG.find((element) => element.value === selectedReport[TIMEZONE]) || {}).title || '',
        [RECIPIENT_ID]: [],
      }
    }
    return {
      [HOURLY_TIME]: '1',
      [REPLAY_WEEKLY]: '1',
      [REPLAY_MONTHLY]: '1',
      [TIME]: '00:00',
      [REPORT_TITLE]: '',
      [SYSTEM_USERS]: [],
      [USERS_ID]: [],
      [RECIPIENT_ID]: [],
      [REPORT_TYPE]: '',
    }
  }, [
    reportFormIsViewed,
    reportFormOpen,
    getObjectTypes,
    selectedReport,
    scheduleValue,
    renderValues,
  ])

  const onSubmit = (event) => event.preventDefault()

  return (
    <Formik
      ref={formicForm}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationRecipients(recipientIdForm)}
      initialValues={initialValues}
      render={(formProps) => {
        const {
          handleSubmit, setFieldValue, values,
        } = formProps
        setGroupFromProps(formProps)
        setRecipientIdForm(values[RECIPIENT_ID] || [])
        return (
          <>
            {loadingValue
              ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )
              : (
                <FormWrapper
                  withScroll={activeTab === NEWSLETTER}
                  onSubmit={handleSubmit}
                  loading={loadingValue}
                >
                  {renderParameters(values)}
                  {renderTimeTable(values)}
                  {renderNewsLetter(setFieldValue, values)}
                </FormWrapper>
              )}
          </>
        )
      }}
    />
  )
}

ReportManagerForm.propTypes = {
  activeTab: pt.oneOf([TIMETABLE, PARAMETERS, NEWSLETTER]),
  reportFormOpen: pt.bool,
  isTypesLoading: pt.bool,
  isCommonUsersLoading: pt.bool,
  isObjectTypesLoading: pt.bool,
  loadingSelectedReport: pt.bool,
  reportViewed: pt.bool,
  getTypes: pt.func,
  getObjectTypes: pt.func,
  setGroupFromProps: pt.func,
  getCommonUsers: pt.func,
  commonUsers: pt.arrayOf(pt.shape({
    blocked: pt.bool,
    email: pt.string,
    enabled: pt.bool,
    firstName: pt.string,
    lastName: pt.string,
    id: pt.oneOfType([pt.string, pt.number]),
    tenantId: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    value: pt.oneOfType([pt.string, pt.number]),
  })),
  reportObjectTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    code: pt.string,
    title: pt.string,
  })),
  reportTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    code: pt.string,
    title: pt.string,
  })),
  geoZoneId: pt.oneOfType([pt.string, pt.number]),
  selectedReport: pt.shape({
    externalRecipients: pt.arrayOf(pt.shape({
      id: pt.string,
      email: pt.string,
      name: pt.string,
    })),
    externalSendEnabled: pt.bool,
    geoZoneId: pt.shape({
      id: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
    }),
    id: pt.oneOfType([pt.string, pt.number]),
    lastSendDate: pt.string,
    nextSendDate: pt.string,
    objectTypes: pt.arrayOf(pt.shape({
      code: pt.string,
      title: pt.string,
    })),
    schedule: pt.shape({
      start: pt.oneOfType([pt.string, pt.number]),
      timeValue: pt.oneOfType([pt.string, pt.number]),
      timeZone: pt.oneOfType([pt.string, pt.number]),
      type: pt.oneOfType([pt.string, pt.number]),
    }),
    scheduleView: pt.string,
    timeZone: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    type: pt.shape({
      code: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
    }),
    users: pt.arrayOf(pt.shape({
      blocked: pt.bool,
      email: pt.string,
      enabled: pt.bool,
      firstName: pt.string,
      lastName: pt.string,
      id: pt.oneOfType([pt.string, pt.number]),
      tenantId: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
      value: pt.oneOfType([pt.string, pt.number]),
    })),
    usersId: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
  }),

}
ReportManagerForm.defaultProps = {
  activeTab: PARAMETERS,
  reportFormOpen: false,
  isTypesLoading: false,
  isObjectTypesLoading: false,
  isCommonUsersLoading: false,
  loadingSelectedReport: false,
  reportViewed: false,
  getTypes: noop,
  setGroupFromProps: noop,
  getObjectTypes: noop,
  getCommonUsers: noop,
  reportObjectTypes: [],
  reportTypes: [],
  commonUsers: [],
  geoZoneId: {},
  selectedReport: {},
}

export default ReportManagerForm
