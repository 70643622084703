import styled from 'styled-components'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme, type }) => {
    if (type === OK) {
      return theme.colors.messages.ok
    }
    if (type === ERROR) {
      return theme.colors.messages.error
    }
    if (type === WARNING) {
      return theme.colors.messages.warning
    }
    if (type === UNKNOWN) {
      return theme.colors.messages.disabled
    }
    return null
  }};
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${Title} {
    width: 100%;
    padding-left: 10px;
  }
`

export const AlarmEventContainer = styled.div`
  max-height: 18px;
  max-width: 18px;
  width: 18px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`
