import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TimetableIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9546 9.8191H12.1818V12.1287L14.1764 13.2806L13.5628 14.3434L10.9546 12.8372V9.8191ZM14.2272 5.7269H3.13625V14.7263H6.5965C6.0895 13.6584 5.9254 12.4598 6.1267 11.2949C6.3281 10.1301 6.8849 9.056 7.7209 8.2202C8.5569 7.3844 9.631 6.8278 10.7959 6.6268C11.9608 6.4257 13.1594 6.59 14.2272 7.0972V5.7269ZM3.13625 16.3625C2.70229 16.3625 2.2861 16.1901 1.97925 15.8833C1.67239 15.5764 1.5 15.1602 1.5 14.7263L1.50818 3.2725C1.50818 2.36847 2.23059 1.63625 3.13625 1.63625H3.95438V0H5.5906V1.63625H11.7728V0H13.409V1.63625H14.2272C14.6611 1.63625 15.0773 1.80864 15.3842 2.1155C15.691 2.42236 15.8634 2.83854 15.8634 3.2725V8.2647C16.9196 9.3424 17.5077 10.7934 17.5 12.3023C17.4923 13.8113 16.8894 15.2562 15.8223 16.323C14.7552 17.3899 13.3102 17.9925 11.8012 17.9999C10.2923 18.0073 8.8414 17.4189 7.764 16.3625H3.13625ZM11.7728 8.3089C10.7213 8.3089 9.7129 8.7266 8.9694 9.4701C8.2258 10.2136 7.8081 11.222 7.8081 12.2735C7.8081 13.325 8.2258 14.3334 8.9694 15.0769C9.7129 15.8205 10.7213 16.2382 11.7728 16.2382C12.8243 16.2382 13.8327 15.8205 14.5762 15.0769C15.3197 14.3334 15.7374 13.325 15.7374 12.2735C15.7374 11.222 15.3197 10.2136 14.5762 9.4701C13.8327 8.7266 12.8243 8.3089 11.7728 8.3089Z" fill={fill} className="svgFill" />
    </svg>
  )
}

TimetableIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TimetableIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TimetableIcon)
