import { connect } from 'react-redux'
import {
  getTreeData,
} from '@/store/selectors/maps/socketEvents'
import {
  getExportFileStatus,
  getTableData,
  getSelector,
  getEventTypes,
  getEventStatus,
  getSelectedElement,
  getEventData,
  getLoading,
} from '@/store/selectors/faultAnalytics'

import {
  setSelectedElement,
} from '@/store/actions/faultAnalytics'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  requestSummaryAlarmAnalyticsTable,
} from '@/store/actions/faultAnalytics/getTable'

import {
  requestSummaryAlarmAnalyticsFile,
} from '@/store/actions/faultAnalytics/getFile'

import FaultAnalytics from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  data: getTableData(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  eventTypes: getEventTypes(state),
  eventStatus: getEventStatus(state),
  selectedNode: getSelectedNode(state),
  selectedElement: getSelectedElement(state),
  eventData: getEventData(state),
  loading: getLoading(state),

})
const mapDispatchToProps = (dispatch) => ({
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  requestSummaryAlarmAnalyticsTable:
    (payload) => dispatch(requestSummaryAlarmAnalyticsTable(payload)),
  requestSummaryAlarmAnalyticsFile:
    (payload) => dispatch(requestSummaryAlarmAnalyticsFile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FaultAnalytics)
