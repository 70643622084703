import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AdminIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM13 6.11111C13 4.39222 11.6575 3 10 3C8.3425 3 7 4.39222 7 6.11111C7 7.83 8.3425 9.22222 10 9.22222C11.6575 9.22222 13 7.83 13 6.11111ZM11.3545 16.0931C12.7885 13.941 12.9978 12.0183 13.0105 11.2001C14.7963 11.7391 16 12.7393 16 13.8889V17C16 17 14 19 10 19C6 19 4 17 4 17V13.8889C4 12.7393 5.20375 11.7391 6.9895 11.2001C7.00225 12.0183 7.2115 13.941 8.6455 16.0931L9.25 12.3333L8.5465 10.8734C9.01225 10.8136 9.4975 10.7778 10 10.7778C10.4859 10.7786 10.9714 10.8105 11.4535 10.8734L10.75 12.3333L11.3545 16.0931Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AdminIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AdminIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AdminIcon)
