import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ALL_APPLICATIONS_MODELS_URL } from '@/constants/apiRoutes'
import {
  GET_ALL_APPLICATIONS_MODELS,
} from '@/store/actions/dictionaries'
import {
  successGetApplicationsModels,
  errorGetApplicationsModels,
} from '@/store/actions/dictionaries/applicationsModels'

function* getDictionary() {
  try {
    const response = yield request({
      url: GET_ALL_APPLICATIONS_MODELS_URL,
      method: 'get',
    })

    yield put(successGetApplicationsModels(response.data))
  } catch (error) {
    yield put(errorGetApplicationsModels(error))
  }
}

export default function* () {
  yield takeLatest(GET_ALL_APPLICATIONS_MODELS.REQUEST, getDictionary)
}
