import React, { useMemo, useState, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import UserFormHeader from '@/components/blocks/Header'
import ReportManagerForm from '@/forms/ReportManagerForm'
import {
  TAB_CONFIG,
  PARAMETERS,
} from '@/constants/reportManager'
import {
  REPORT_TITLE,
  REPORT_TYPE,
  TYPE_OBJECT,
  REPLAY,
  HOURLY,
  DAILY,
  WEEKLY,
  MONTHLY,
  RECIPIENT_ID,
  USER_NAME,
  USER_EMAIL,
  REPLAY_WEEKLY,
  REPLAY_MONTHLY,
  TIMEZONE,
  SYSTEM_USERS,
  EXTERNAL_RECIPIENTS,
  TIME,
  HOURLY_TIME,
} from '@/constants/forms/reportManager'
import {
  Container,
  TabHeader,
  TabButton,
  Text,
} from './styles'

const ReportSideBar = ({
  reportFormOpen,
  setFormIsModifying,
  getTypes,
  reportTypes,
  isTypesLoading,
  getObjectTypes,
  reportObjectTypes,
  isObjectTypesLoading,
  getCommonUsers,
  commonUsers,
  isCommonUsersLoading,
  createReport,
  setReportViewed,
  selectedReport,
  loadingSelectedReport,
  reportViewed,
  geoZoneId,
  requestChangeReport,
  requestDeleteReport,
}) => {
  const [activeTab, setActiveTab] = useState(PARAMETERS)
  const [formProps, setGroupFromProps] = useState([])

  const time = useMemo(() => {
    if (formProps.values[TIME]) {
      const formattedTime = moment().utcOffset(formProps.values[TIMEZONE] || 0)
      const [hour = '00', minute = '00'] = formProps.values[TIME].split(':')
      formattedTime.set({ hour, minute })
      formattedTime.toISOString()
      return formattedTime
    }
  }, [formProps.values])

  const handleSetActiveTab = useCallback((tab) => () => (
    setActiveTab(tab)
  ), [])

  const selectedRecipients = useMemo(() => {
    const recipients = []
    if (!isEmpty(formProps.values[RECIPIENT_ID])) {
      formProps.values[RECIPIENT_ID].filter((recipient) => {
        if (recipient.active
          && formProps.values[`${USER_NAME}-${recipient.id}`]
          && formProps.values[`${USER_EMAIL}-${recipient.id}`]
        ) {
          recipients.push({
            id: recipient.id,
            name: formProps.values[`${USER_NAME}-${recipient.id}`],
            email: formProps.values[`${USER_EMAIL}-${recipient.id}`],
          })
        }
        return recipients
      })
    }
    return recipients
  }, [formProps.values])

  const scheduleCondition = useMemo(() => {
    if (formProps.values[REPLAY] === WEEKLY && formProps.values[REPLAY_WEEKLY]) {
      return true
    }
    if (formProps.values[REPLAY] === MONTHLY && formProps.values[REPLAY_MONTHLY]) {
      return true
    }
    if (formProps.values[REPLAY] === HOURLY && formProps.values[HOURLY_TIME]) {
      return true
    }
    if (formProps.values[REPLAY] === DAILY && formProps.values[TIME]) {
      return true
    }
    return false
  }, [formProps.values])

  const filteredErrors = useMemo(() => (
    selectedRecipients.some((recipient) => (
      Object.keys(formProps.errors).some((error) => error.indexOf(`${recipient.id}`) >= 0)
    ))
  ), [formProps.errors, selectedRecipients])

  const countOfActiveRecipientId = useMemo(() => (
    (formProps.values[RECIPIENT_ID] || []).filter((element) => element.active === true).length
  ), [formProps.values])

  const recipientCondition = useMemo(() => {
    if (!isEmpty(formProps.values[SYSTEM_USERS]) && isEmpty(formProps.values[RECIPIENT_ID])) {
      return true
    }
    if (!isEmpty(formProps.values[SYSTEM_USERS]) && !isEmpty(formProps.values[RECIPIENT_ID])) {
      return (
        (formProps.values[RECIPIENT_ID].every((recipient) => !recipient.active)
        || !isEmpty(selectedRecipients))
        && countOfActiveRecipientId === selectedRecipients.length
      )
    }
    if (isEmpty(formProps.values[SYSTEM_USERS])
      && formProps.values[EXTERNAL_RECIPIENTS]
      && !isEmpty(selectedRecipients)
      && countOfActiveRecipientId === selectedRecipients.length
    ) {
      return true
    }
    return false
  }, [countOfActiveRecipientId, formProps.values, selectedRecipients])

  const formCondition = useMemo(() => {
    if (formProps.errors && formProps.errors[REPORT_TITLE]) {
      return false
    }
    return true
  }, [formProps.errors])

  const handleConditionsForAdding = useMemo(() => (
    !isEmpty(formProps.values)
    && !filteredErrors
    && formCondition
    && formProps.values[REPORT_TITLE]
    && formProps.values[REPORT_TYPE]
    && !isEmpty(formProps.values[TYPE_OBJECT])
    && recipientCondition
    && formProps.values[REPLAY]
    && formProps.values[TIMEZONE]
    && formProps.values[TIME]
    && scheduleCondition
    && (reportViewed ? formProps.dirty : true)
  ),
  [
    filteredErrors,
    formProps.dirty,
    formProps.values,
    recipientCondition,
    reportViewed,
    scheduleCondition,
    formCondition,
  ])

  const scheduleValue = useMemo(() => {
    if (formProps.values[REPLAY] === WEEKLY) {
      return {
        start: time.utc(),
        timeValue: formProps.values[REPLAY_WEEKLY],
        type: WEEKLY,
        timeZone: (formProps.values[TIMEZONE] / 60),
      }
    }
    if (formProps.values[REPLAY] === MONTHLY) {
      return {
        start: time.utc(),
        timeValue: formProps.values[REPLAY_MONTHLY],
        type: MONTHLY,
        timeZone: (formProps.values[TIMEZONE] / 60),
      }
    }
    if (formProps.values[REPLAY] === HOURLY) {
      return {
        start: time.utc(),
        timeValue: formProps.values[HOURLY_TIME],
        type: HOURLY,
        timeZone: (formProps.values[TIMEZONE] / 60),
      }
    }
    if (formProps.values[REPLAY] === DAILY) {
      return {
        start: time.utc(),
        type: DAILY,
        timeValue: 1,
        timeZone: (formProps.values[TIMEZONE] / 60),
      }
    }
    return null
  }, [formProps.values, time])

  const handleSaveForm = useCallback(() => {
    if (reportViewed) {
      requestChangeReport({
        title: formProps.values[REPORT_TITLE],
        type: formProps.values[REPORT_TYPE],
        objectTypes: formProps.values[TYPE_OBJECT],
        userIds: formProps.values[SYSTEM_USERS],
        externalRecipients: selectedRecipients,
        schedule: scheduleValue,
        id: selectedReport.id,
        externalSendEnabled: formProps.values[EXTERNAL_RECIPIENTS],
        geoZoneId,
        formProps,
      })
      setReportViewed(false)
    } else {
      createReport({
        title: formProps.values[REPORT_TITLE],
        type: formProps.values[REPORT_TYPE],
        objectTypes: formProps.values[TYPE_OBJECT],
        userIds: formProps.values[SYSTEM_USERS],
        externalRecipients: selectedRecipients,
        schedule: scheduleValue,
        externalSendEnabled: formProps.values[EXTERNAL_RECIPIENTS],
        geoZoneId,
        formProps,
      })
      setReportViewed(false)
    }
  },
  [
    createReport,
    formProps,
    geoZoneId,
    reportViewed,
    requestChangeReport,
    scheduleValue,
    selectedRecipients,
    selectedReport.id,
    setReportViewed,
  ])

  const renderForm = useMemo(() => (
    <ReportManagerForm
      activeTab={activeTab}
      reportFormOpen={reportFormOpen}
      getTypes={getTypes}
      reportTypes={reportTypes}
      isTypesLoading={isTypesLoading}
      getObjectTypes={getObjectTypes}
      reportObjectTypes={reportObjectTypes}
      isObjectTypesLoading={isObjectTypesLoading}
      getCommonUsers={getCommonUsers}
      commonUsers={commonUsers}
      isCommonUsersLoading={isCommonUsersLoading}
      setGroupFromProps={setGroupFromProps}
      selectedReport={selectedReport}
      loadingSelectedReport={loadingSelectedReport}
      reportViewed={reportViewed}
      geoZoneId={geoZoneId}
    />
  ),
  [
    activeTab,
    commonUsers,
    getCommonUsers,
    getObjectTypes,
    getTypes,
    isCommonUsersLoading,
    isObjectTypesLoading,
    isTypesLoading,
    reportFormOpen,
    reportObjectTypes,
    reportTypes,
    selectedReport,
    loadingSelectedReport,
    reportViewed,
    geoZoneId,
  ])

  const handleCloseForm = useCallback(() => {
    setFormIsModifying(false)
    setReportViewed(false)
  }, [setFormIsModifying, setReportViewed])

  const handleDeleteReport = useCallback(() => {
    setReportViewed(false)
    requestDeleteReport({ id: selectedReport.id, title: selectedReport.title })
  }, [requestDeleteReport, selectedReport.id, selectedReport.title, setReportViewed])

  const renderErrorText = useMemo(() => {
    if (!reportViewed && reportFormOpen) {
      return <Lang id="reportManager.header.cancelEdit" />
    }
    if (reportViewed && reportFormOpen) {
      return <Lang id="reportManager.header.changeCancelEdit" />
    }
    if (reportViewed && !reportFormOpen) {
      return (
        <>
          <Lang id="reportManager.header.deleteReport" />
          {`"${selectedReport.title}"?`}
        </>
      )
    }
  }, [reportFormOpen, reportViewed, selectedReport.title])

  return (
    <Container>
      <UserFormHeader
        setFormIsModifying={setFormIsModifying}
        closeForm={handleCloseForm}
        editMode={reportFormOpen}
        deleteForm={handleDeleteReport}
        editTitle={reportViewed ? <Lang id="reportManager.header.changeEditTitle" /> : <Lang id="reportManager.header.editTitle" />}
        conditionsForAdding={handleConditionsForAdding}
        saveForm={handleSaveForm}
        title={!isEmpty(selectedReport) ? selectedReport.title : ''}
        popupSettings={{
          error: {
            title: <Lang id="usersPage.popup.attention" />,
            message: renderErrorText,
          },
          warning: {
            title: <Lang id="usersPage.popup.impossibleExecute" />,
            message: reportViewed ? <Lang id="reportManager.header.changeSaveReport" /> : <Lang id="reportManager.header.saveReport" />,
          },
        }}
      />
      <TabHeader>
        {TAB_CONFIG.map((element) => {
          const Icon = element.icon
          return (
            <TabButton
              key={element.id}
              onClick={handleSetActiveTab(element.id)}
              active={activeTab === element.id}
            >
              <Icon />
              <Text active={activeTab === element.id}>{element.name}</Text>
            </TabButton>
          )
        })}
      </TabHeader>
      {renderForm}
    </Container>
  )
}
ReportSideBar.propTypes = {
  setReportViewed: pt.func,
  getTypes: pt.func,
  setFormIsModifying: pt.func,
  requestChangeReport: pt.func,
  requestDeleteReport: pt.func,
  createReport: pt.func,
  getObjectTypes: pt.func,
  getCommonUsers: pt.func,
  reportFormOpen: pt.bool,
  loadingSelectedReport: pt.bool,
  isTypesLoading: pt.bool,
  isObjectTypesLoading: pt.bool,
  isCommonUsersLoading: pt.bool,
  reportViewed: pt.bool,
  commonUsers: pt.arrayOf(pt.shape({
    blocked: pt.bool,
    email: pt.string,
    enabled: pt.bool,
    firstName: pt.string,
    lastName: pt.string,
    id: pt.oneOfType([pt.string, pt.number]),
    tenantId: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    value: pt.oneOfType([pt.string, pt.number]),
  })),
  geoZoneId: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
  }),
  reportObjectTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    code: pt.string,
    title: pt.string,
  })),
  reportTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    code: pt.string,
    title: pt.string,
  })),
  selectedReport: pt.shape({
    externalRecipients: pt.arrayOf(pt.shape({
      id: pt.string,
      email: pt.string,
      name: pt.string,
    })),
    externalSendEnabled: pt.bool,
    geoZoneId: pt.shape({
      id: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
    }),
    id: pt.oneOfType([pt.string, pt.number]),
    lastSendDate: pt.string,
    nextSendDate: pt.string,
    objectTypes: pt.arrayOf(pt.shape({
      code: pt.string,
      title: pt.string,
    })),
    schedule: pt.shape({
      start: pt.oneOfType([pt.string, pt.number]),
      timeValue: pt.oneOfType([pt.string, pt.number]),
      timeZone: pt.oneOfType([pt.string, pt.number]),
      type: pt.oneOfType([pt.string, pt.number]),
    }),
    scheduleView: pt.string,
    timeZone: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    type: pt.shape({
      code: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
    }),
    users: pt.arrayOf(pt.shape({
      blocked: pt.bool,
      email: pt.string,
      enabled: pt.bool,
      firstName: pt.string,
      lastName: pt.string,
      id: pt.oneOfType([pt.string, pt.number]),
      tenantId: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
      value: pt.oneOfType([pt.string, pt.number]),
    })),
    usersId: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
  }),
}
ReportSideBar.defaultProps = {
  setReportViewed: noop,
  getTypes: noop,
  setFormIsModifying: noop,
  requestChangeReport: noop,
  requestDeleteReport: noop,
  createReport: noop,
  getObjectTypes: noop,
  getCommonUsers: noop,
  reportFormOpen: false,
  isTypesLoading: false,
  isObjectTypesLoading: false,
  isCommonUsersLoading: false,
  loadingSelectedReport: false,
  reportViewed: false,
  commonUsers: [],
  geoZoneId: {},
  reportObjectTypes: [],
  reportTypes: [],
  selectedReport: {},
}

export default ReportSideBar
