import {
  takeLatest, all, call, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ALL_INTEGRATIONS } from '@/store/actions/installation'
import {
  INSTALLATION_ROOT_ELEMENT,
  generateGetTree,
  getFreeIntegrations,
  generateGetProjectCard,
  generateGetDeleteIntegrationCard,
} from '@/constants/apiRoutes'
import getGeoZoneFromTree from '@/helpers/getGeoZoneFromTree'
import { PROJECT } from '@/constants/objectTypes'
import {
  ATM,
  BRIZ,
  KULON,
  MESH,
} from '@/constants/integrations'
import {
  KULON as KULON_INSTALLATION,
  BRIZ as BRIZ_INSTALLATION,
  ONE_SIM as ONE_SIM_INSTALLATION,
  MESH as MESH_INSTALLATION,
} from '@/constants/instalationPassport/types'
import {
  successGetAllIntegrations,
  errorGetAllIntegrations,
} from '@/store/actions/installation/getAllIntegrations'

const syncModuleToIntegrationModule = {
  [ATM]: ONE_SIM_INSTALLATION,
  [BRIZ]: BRIZ_INSTALLATION,
  [KULON]: KULON_INSTALLATION,
  [MESH]: MESH_INSTALLATION,
}

function* getIntegrationsByType({ payload: { type, id } }) {
  try {
    if (id) {
      const { data: selectedIntegration } = yield request({
        url: generateGetDeleteIntegrationCard(id),
        method: 'get',
      })
      yield put(successGetAllIntegrations([{
        value: selectedIntegration.integrationId,
        title: selectedIntegration.integrationName,
      }]))
    } else {
      const { data } = yield request({
        url: INSTALLATION_ROOT_ELEMENT,
        method: 'get',
      })
      const trees = yield all(data.map((element) => call(request, {
        url: generateGetTree(element.id),
        method: 'get',
      })))
      const formatTrees = trees.reduce((accumulator, tree) => {
        const elements = getGeoZoneFromTree(tree.data, 'treeElementId')
        const integrations = elements.filter((element) => element.type === PROJECT)
        return [
          ...accumulator,
          ...integrations,
        ]
      }, [])
      const integrationCardsId = yield all(formatTrees.map((element) => call(request, {
        url: generateGetProjectCard(element.value),
        method: 'get',
      })))
      const formatIntegrationCards = integrationCardsId.reduce((accumulator, element) => [
        ...accumulator,
        element.data.projectId,
      ], [])
      const { data: filterIntegration } = yield request({
        url: getFreeIntegrations(syncModuleToIntegrationModule[type], formatIntegrationCards),
        method: 'get',
      })
      const formatFilterIntegration = filterIntegration.map((element) => ({
        value: element.integrationId,
        title: element.integrationName,
      }))
      yield put(successGetAllIntegrations(formatFilterIntegration))
    }
  } catch (error) {
    yield put(errorGetAllIntegrations())
    console.log('TCL: function*exportCardFileSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GET_ALL_INTEGRATIONS.REQUEST, getIntegrationsByType)
}
