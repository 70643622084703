import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import Icons from '@/components/icons/livetime'
import CheckBox from '@/components/controls/CheckBox'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import {
  Container,
  Title,
  ButtonsContainer,
  Button,
  AlarmButton,
  CheckBoxWrapper,
} from './styles'

const { SortIcon, AlarmIcon } = Icons

const SortBullet = ({
  id,
  name,
  onClick,
  selected,
  showSortButtons,
  showAlarmButton,
  handleReadAllAlarms,
  withSelectAll,
  buttonValue,
  showCheckBox,
}) => {
  const { id: selectedId, value: selectedValue } = selected

  const onClickHandler = (value) => () => {
    onClick({
      sortType: id,
      sortValue: value,
    })
  }

  const isActive = (value) => {
    if (id === selectedId && selectedValue === value) {
      return true
    }
    return false
  }

  return (
    <Container>
      {withSelectAll && showCheckBox && (
        <CheckBoxWrapper>
          <CheckBox
            onChange={handleReadAllAlarms}
            value={buttonValue}
          />
        </CheckBoxWrapper>
      )}
      {withSelectAll && showAlarmButton && (
        <PortalTooltip
          title={(<Lang id="alarmManager.alarmToolTip" />)}
          renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
            <AlarmButton
              ref={wrapperRef}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onClick={handleReadAllAlarms}
            >
              <AlarmIcon />
            </AlarmButton>
          )}
        />
      )}
      <Title>
        {name}
      </Title>
      {showSortButtons && (
        <ButtonsContainer>
          <Button
            onClick={onClickHandler('ASC')}
            active={isActive('ASC')}
          >
            <SortIcon />
          </Button>
          <Button
            reverse
            onClick={onClickHandler('DESC')}
            active={isActive('DESC')}
          >
            <SortIcon />
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  )
}


SortBullet.propTypes = {
  id: pt.oneOfType([pt.number, pt.string]),
  name: pt.string,
  onClick: pt.func,
  selected: pt.shape({
    id: pt.oneOfType([pt.number, pt.string]),
    value: pt.oneOfType([pt.number, pt.string]),
  }),
  showSortButtons: pt.bool,
  showAlarmButton: pt.bool,
}

SortBullet.defaultProps = {
  id: null,
  name: null,
  onClick: noop,
  selected: {},
  showSortButtons: true,
  showAlarmButton: false,
}

export default SortBullet
