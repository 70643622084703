import { GET_TYUMEN_MESSAGE } from './index'

export const requestTyumenMessage = (payload) => ({
  type: GET_TYUMEN_MESSAGE.REQUEST,
  payload,
})

export const successTyumenMessage = (payload) => ({
  type: GET_TYUMEN_MESSAGE.SUCCESS,
  payload,
})

export const errorTyumenMessage = (payload) => ({
  type: GET_TYUMEN_MESSAGE.ERROR,
  payload,
})
