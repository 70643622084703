import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import qs from 'query-string'
import moment from 'moment'
import { TUMEN_OUR_HOME_DASHBOARD } from '@/constants/apiRoutes'
import {
  GET_TUMEN_OUR_HOME_DATA,
} from '@/store/actions/widgetDashboard'
import {
  getTyumenOurHomeSuccess,
  getTyumenOurHomeError,
} from '@/store/actions/widgetDashboard/tyumenOurHome'

function* getObjectState({ payload }) {
  const { endDate, startDate } = payload
  const queryParams = qs.stringify({
    startDate: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
    endDate: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
  })
  try {
    const response = yield request({
      url: `${TUMEN_OUR_HOME_DASHBOARD}?${queryParams}`,
      method: 'get',
    })
    yield put(getTyumenOurHomeSuccess(response.data))
  } catch (error) {
    yield put(getTyumenOurHomeError(error))
  }
  return null
}

export default function* () {
  yield takeLatest(GET_TUMEN_OUR_HOME_DATA.REQUEST, getObjectState)
}
