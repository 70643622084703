import {
  take, call, put, select,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import createNotifications from '@/helpers/notification'
import { selectSystem, setMode, updatedScheme } from '@/store/actions/integrations'

import { errorUpdateIntegration } from '@/store/actions/integrations/updateIntegration'
import { requestGetIntegrations } from '@/store/actions/integrations/getIntegrations'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import {
  getForm, getIntegrationSchemeById,
} from '@/store/selectors/integrations'
import {
  ERRORS_TO_ERROR_MESSAGES,
  OPERATION_INTEGRATION_ERROR,
  KULON,
  ATM,
  BRIZ,
  MESH, INTEGRATION_UPDATED
} from '@/constants/integrations'

import { updateIntegrationsChannel } from './channels'
import messagesAliasCreator from '../notificationElements'

const MESSAGES_ALIASES = {
  [KULON]: messagesAliasCreator('АСУНО', 'КУЛОН'),
  [ATM]: messagesAliasCreator('АСУ', '1SIM'),
  [BRIZ]: messagesAliasCreator('АСУ', 'БРИЗ'),
  [MESH]: messagesAliasCreator('АСУ', 'МЭШ СЕТЬ'),
}

function* updateIntegrationSaga() {
  const toast = createNotifications()
  const channel = yield call(updateIntegrationsChannel)

  while (true) {
    const eventData = yield take(channel)
    console.log('function*updateIntegrationsSaga -> eventData', eventData);
    const form = yield select(getForm)
    const schemeByID = yield select(getIntegrationSchemeById)
    switch (eventData.status) {
      case 'ON_FAILED': {
        const newSchemeByID = {
          ...schemeByID,
          children: [{
            ...schemeByID.children[0],
            isSync: false,
            integrationOption: true,
          }],
        }
        yield put(updatedScheme(newSchemeByID))
        if (Object.keys(form).length) {
          form.setSubmitting(false)
        }
        if (eventData.errors.length) {
          const errorsMessage = eventData.errors.map((element) => {
            const theme = ERRORS_TO_ERROR_MESSAGES[element.subject] || {}
            const message = theme[element.message] || `${element.subject}: ${element.message}`
            return message
          }).join('\n')
          const errorNotifications = ERRORS_TO_ERROR_MESSAGES[eventData.errors[0].subject] || {}
          const errorsObject = eventData.errors.reduce((accumulator, error) => {
            const theme = ERRORS_TO_ERROR_MESSAGES[error.subject] || {}
            const message = theme[error.message] || `${error.subject}: ${error.message}`
            return {
              ...accumulator,
              [(error.subject || '').toLowerCase()]: message,
            }
          }, {})
          const messageObject = MESSAGES_ALIASES[eventData.integrationType] || {}
          if (Object.keys(messageObject).length) {
            const messageConfig = messageObject[OPERATION_INTEGRATION_ERROR] || noop
            toast(messageConfig(
              eventData.alias,
              errorNotifications[eventData.errors[0].message]
                || `${eventData.description}`,
            ))
          }
          if (Object.keys(form).length) {
            form.setSubmitting(false)
            form.setErrors(errorsObject)
          }
          yield put(errorUpdateIntegration(errorsMessage))
        }
        break
      }
      case 'IN_PROGRESS': {
        yield put(requestGetIntegrations())
        break
      }
      case 'COMPLETED': {
        if (eventData.description === INTEGRATION_UPDATED) {
          const messageConfig = MESSAGES_ALIASES[
              eventData.integrationType
              ][INTEGRATION_UPDATED] || noop
          yield put(requestGetMapObjects())
          yield put(requestGetIntegrations())
          yield put(selectSystem(null))
          yield put(setMode('CONNECTED_INTEGRATION'))
          toast(messageConfig(eventData.alias))
        }
        break
      }
      default: break
    }
  }
}

export default updateIntegrationSaga
