import styled from 'styled-components'

export default styled.div`
  width: 100%;

  .selected-node {
    position: relative;
    ${({ theme }) => `background: ${theme.colors.backgrounds.redisign.gray};`}

    &::before {
      content: '';
      position: absolute;
      ${({ theme }) => `background: ${theme.colors.backgrounds.redisign.gray};`}
      top: 0;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 100%;
    }
  }
`
