import styled from 'styled-components'

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0, -50%);
  transition: all 200ms;
`

export const SwitchContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 18px;
  background: ${({ theme }) => theme.colors.borders.default};
  border-radius: 12px;
  transition: all 200ms;
`

export const Label = styled.span`
  margin-left: 22px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.colors.switchButton.default};
`

export const Container = styled.label`
  display: flex;
  align-items: center;
  ${({ bottomMargin }) => bottomMargin && `
    margin-bottom: 14px;
  `}
  ${({ darkLabel, theme }) => darkLabel && `
    ${Label} {
      color: ${theme.colors.borders.default};
    }
  `}
  ${({ checked, theme }) => checked && `
    ${Circle} {
      left: 97%;
      transform: translate(-100%, -50%);
    }
    ${SwitchContainer} {
      background: ${theme.colors.scheme.backgrounds.default}
    }
  `}
  ${({ disabled }) => disabled && `
    ${SwitchContainer} {
      opacity: 0.7;
      cursor: not-allowed;
    }
  `}
  ${({ alwaysWhite, theme }) => alwaysWhite && `
    ${Label} {
      color: ${theme.colors.colors.default};
    }
  `}
`
