import { GET_ALL_CITY_PROCESSES_OPTIONS } from './index'

export const beginGetAllCityProcessesOptions = () => ({
  type: GET_ALL_CITY_PROCESSES_OPTIONS.BEGIN,
})

export const endGetAllCityProcessesOptions = () => ({
  type: GET_ALL_CITY_PROCESSES_OPTIONS.END,
})

export const requestGetAllCityProcessesOptions = () => ({
  type: GET_ALL_CITY_PROCESSES_OPTIONS.REQUEST,
})

export const responseGetAllCityProcessesOptions = (data) => ({
  type: GET_ALL_CITY_PROCESSES_OPTIONS.SUCCESS,
  payload: data,
})

export const errorGetAllCityProcessesOptions = (error) => ({
  type: GET_ALL_CITY_PROCESSES_OPTIONS.ERROR,
  payload: error,

})
