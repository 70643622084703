import {
  takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  DELETE_REPORT_MANAGER,
  setFormIsModifying,
} from '@/store/actions/reportManager'
import { GET_REPORT_URL } from '@/constants/apiRoutes'
import {
  errorDeleteReport,
  successDeleteReport,
} from '@/store/actions/reportManager/deleteReport'
import {
  requestReportManagerTable,
} from '@/store/actions/reportManager/getTable'
import { RESPONSE_STATUS_CORRECT } from '@/constants/applications'

function* deleteReportSaga({ payload }) {
  try {
    const response = yield request({
      url: GET_REPORT_URL({ id: payload.id }),
      method: 'delete',
    })
    if (response.status === RESPONSE_STATUS_CORRECT) {
      yield put(setFormIsModifying(false))
      yield put(requestReportManagerTable())
      yield put(successDeleteReport())
    }
  } catch (error) {
    yield put(errorDeleteReport(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(DELETE_REPORT_MANAGER.REQUEST, deleteReportSaga)
}
