import MenuIcon from '@/components/icons/menu'

export default {
  warning: {
    icon: MenuIcon.AttentionIcon,
    buttons: [
      {
        statusType: 'warning',
        onClickSelector: 'primaryAction',
        title: 'Принять',
      },
    ],
  },
  success: {
    icon: MenuIcon.GeoZoneIcon,
    buttons: [
      {
        statusType: 'flat',
        onClickSelector: 'secondaryAction',
        title: 'Сохранить',
      },
      {
        statusType: 'success',
        onClickSelector: 'primaryAction',
        title: 'Верифицировать',
      },
    ],
  },
  critical: {
    icon: MenuIcon.TreshIcon,
    buttons: [
      {
        statusType: 'flat',
        onClickSelector: 'secondaryAction',
        title: 'нет',
      },
      {
        statusType: 'critical',
        onClickSelector: 'primaryAction',
        title: 'да',
      },
    ],
  },
}
