import * as yup from 'yup'
import invariant from 'invariant'
import without from 'lodash/without'
import isNaN from 'lodash/isNaN'
import {
  CLIENT_ID,
  SECRET_ID,
  PASSWORD,
  PORT,
  URL,
  NAME,
  PERIOD,
  UNIT,
  LOGIN,
  SD_NAME,
  FILE,
} from '@/constants/forms/integration'
import {
  USER_NAME,
  USER_EMAIL,
  REPORT_TITLE,
} from '@/constants/forms/reportManager'
import {
  ADMIN_NAME,
  ADMIN_SURNAME,
  ADMIN_PATRONYMIC,
  ADMIN_TELEPHON,
  ADMIN_EMAIL,
  ADMIN_POSITION,
  ADMIN_ABOUT_ME,
  ADMIN_PHOTO,
  ADMIN_PHOTO_RESOLUTION,
} from '@/constants/forms/admin'
import {
  MIN_VALUE,
  MAX_VALUE,
  MIN_CRITICAL_VALUE,
  MAX_CRITICAL_VALUE,
} from '@/constants/forms/objectWidgetSettings'

import {
  NAME as GROUP_NAME,
  PHONE_NUMBER as GROUP_PHONE_NUMBER,
  EMAIL as GROUP_EMAIL,
  SUB_END_DATA as GROUP_SUB_END_DATA,
} from '@/constants/forms/group'

import CREATE_INTEGRATION_NAMES from '@/constants/forms/createIntegration'
import CREATE_GEOZONE_NAMES from '@/constants/forms/createGeoZone'
import CREATE_REGION_NAMES from '@/constants/forms/createProject'

export const SELECT_GROUP = 'SELECT_GROUP'
export const BLOCK_USERS = 'BLOCK_USERS'

export const DEFAULT_PERIOD = '12'
export const DEFAULT_UNIT = 'час'
export const FILE_SIZE = 1024 * 1024
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
]

export const FORM_SCHEMAS_DATA = {
  [LOGIN]: '',
  [PASSWORD]: '',
  [PORT]: '',
  [URL]: '',
  [NAME]: '',
  [PERIOD]: DEFAULT_PERIOD,
  [UNIT]: DEFAULT_UNIT,
}

export const FORM_ACY_WIDGET = {
  [PERIOD]: DEFAULT_PERIOD,
  [UNIT]: DEFAULT_UNIT,
}

export const ORGANIZATION_NAME = 'organizationName'
export const BLOCK_ORGANIZATION = 'blockOrganization'

export const ONLY_NUMBERS = /\d+/g
export const NUMBER_WITHOUT_FIRST_ZERO = /^[1-9][0-9]*$/

const latitude = yup.string('число')
  .transform((value) => ((isNaN(value) || !value) ? undefined : value))
  .required('Обязательное поле')
  .test('maxLimit', 'не более 90°', (value) => parseFloat(value) <= 90)
  .test('minLimit', 'не мение -90°', (value) => parseFloat(value) >= -90)
  .test('decimalLength', 'дробная часть - 6 символов', (value) => ((value || '')
    .toString()
    .split('.')[1] || '').length === 6)

const longitude = yup.string('число')
  .transform((value) => ((isNaN(value) || !value) ? undefined : value))
  .required('Обязательное поле')
  .test('maxLimit', 'не более 180°', (value) => parseFloat(value) <= 180)
  .test('minLimit', 'не мение -180°', (value) => parseFloat(value) >= -180)
  .test('decimalLength', 'дробная часть - 6 символов', (value) => ((value || '')
    .toString()
    .split('.')[1] || '').length === 6)

export const ACYWIDGET_CONFIG = [
  {
    value: 'sec',
    title: 'сек',
    multiplier: 1,
  },
  {
    value: 'min',
    title: 'мин',
    multiplier: 60,
  },
]

yup.addMethod(
  yup.object,
  'atLeastOneRequired',
  function (list, message) {
    invariant(
      list.every((field) => this.fields[field]),
      'All required fields should be defined before calling atLeastOneRequired',
    )
    return this.shape(
      list.reduce(
        (accumulator, field) => ({
          ...accumulator,
          [field]: this.fields[field].when(without(list, field), {
            is: (...values) => !values.some((item) => item),
            then: this.fields[field].required(message),
          }),
        }),
        {},
      ),
      list.reduce(
        (accumulator, item, index, originalList) => [
          ...accumulator,
          ...originalList
            .slice(index + 1)
            .map((originalListItem) => [item, originalListItem]),
        ],
        [],
      ),
    )
  },
)

export const ACYWidgetValidationScheme = yup
  .object()
  .shape({
    [PERIOD]: yup
      .number('Целое число')
      .integer('Целое число')
      .min(1, 'Целое число больше 0')
      .max(60, 'Целое число до 60')
      .required('Обязательное поле'),
    [UNIT]: yup
      .string()
      .required('Обязательное поле'),
  })
export const MultipleUserEditScheme = yup
  .object()
  .shape({
    [SELECT_GROUP]: yup
      .string(),
    [BLOCK_USERS]: yup
      .bool(),
  }).atLeastOneRequired([BLOCK_USERS, SELECT_GROUP])

export const DEFAULT_INTEGRATION_FIELDS = {
  [LOGIN]: yup
    .string()
    .required('Обязательное поле'),
  [PASSWORD]: yup
    .string()
    .min(6, 'Минимальная длина - 6 символов')
    .required('Обязательное поле'),
  [PORT]: yup
    .number()
    .min(1, 'Значение от 1 до 65535')
    .max(65535, 'Значение от 1 до 65535'),
  [NAME]: yup
    .string()
    .required('Обязательное поле')
    .max(25, 'Максимальная длина - 25 символов'),
  [URL]: yup
    .string()
    .url('Не соответсвует формату. Пример: http://example.com/api')
    .required('Обязательное поле'),
  [PERIOD]: yup
    .number()
    .max(24, 'Целое число до 24'),
  [UNIT]: yup
    .string()
    .required('Обязательное поле'),
}

export const getCustomIntegrationFieldValidator = (fields) => fields
  .reduce((accumulator, field) => {
    if (!DEFAULT_INTEGRATION_FIELDS[field]) {
      return {
        ...accumulator,
        [field]: yup
          .string()
          .required('Обязательное поле'),
      }
    }
    return accumulator
  }, {})

export const integrationSchemaCreate = (fields) => yup
  .object()
  .shape({
    ...DEFAULT_INTEGRATION_FIELDS,
    ...getCustomIntegrationFieldValidator(fields),
    [LOGIN]: yup
      .string()
      .required('Обязательное поле'),
    [PASSWORD]: yup
      .string()
      .min(6, 'Минимальная длина - 6 символов')
      .required('Обязательное поле'),
  })

export const integrationSchemaUpdate = (fields) => yup
  .object()
  .shape({
    ...DEFAULT_INTEGRATION_FIELDS,
    ...getCustomIntegrationFieldValidator(fields),
    [LOGIN]: yup
      .string(),
    [PASSWORD]: yup
      .string()
      .min(6, 'Минимальная длина - 6 символов'),
  })

export const userGroupCreate = yup
  .object()
  .shape({
    [ORGANIZATION_NAME]: yup
      .string()
      .required('Обязательное поле'),
  })

export const validationSchemaManySDSettings = (data, allServiceDeskIntegrations) => {
  const reducer = function (accumulator, node) {
    const isConnected = allServiceDeskIntegrations
      .some((serviceDesk) => serviceDesk.temporaryId === node.id)
    return ({
      ...accumulator,
      [`${SD_NAME}-${node.id}`]: yup
        .string()
        .required('Обязательное поле'),
      [`${URL}-${node.id}`]: yup
        .string()
        .required('Обязательное поле'),
      [`${CLIENT_ID}-${node.id}`]: yup
        .string()
        .required('Обязательное поле')
        .test(
          'theSameClientId',
          'такой ClientID уже есть в системе',
          (value) => {
            const clientIdExist = (allServiceDeskIntegrations || []).some(
              (serviceDeskIntegration) => (
                value === serviceDeskIntegration.clientId
              ),
            )
            if (clientIdExist && !isConnected) {
              return false
            }
            return true
          },
        ),
      [`${SECRET_ID}-${node.id}`]: yup
        .string()
        .required('Обязательное поле')
        .test(
          'theSameSecretId',
          'такой SecretID уже есть в системе',
          (value) => {
            const secretIdExist = (allServiceDeskIntegrations || []).some(
              (serviceDeskIntegration) => (
                value === serviceDeskIntegration.secretId
              ),
            )
            if (secretIdExist && !isConnected) {
              return false
            }
            return true
          },
        ),
      [`${FILE}-${node.id}`]: yup
        .mixed()
        .required('Обязательное поле'),
    })
  }
  return yup.object().shape((data || []).reduce(reducer, {}))
}

export const validationRecipients = (data) => {
  const reducer = function (accumulator, node) {
    return ({
      ...accumulator,
      [`${USER_EMAIL}-${node.id}`]: yup
        .string()
        .required('Обязательное поле')
        .max(128, 'Максимальная длина - 128 символов')
        .email('введите коректный email'),
      [`${USER_NAME}-${node.id}`]: yup
        .string()
        .required('Обязательное поле')
        .max(64, 'Максимальная длина - 64 символов'),
    })
  }
  const recipientValidation = data.reduce(reducer, {})
  const allValidation = {
    ...recipientValidation,
    [REPORT_TITLE]: yup
      .string()
      .max(128, 'Максимальная длина - 128 символов'),
  }
  return yup.object().shape(allValidation)
}

export const validationSchemaManyWidgetSettings = (data) => {
  const reducer = function (accumulator, node) {
    return ({
      ...accumulator,
      [`${MIN_CRITICAL_VALUE}${node.title}`]: yup
        .boolean()
        .test('check',
          'Не установлено предельное значение!',
          function (value) {
            if (value
              && (
                this.parent[`${MIN_VALUE}${node.title}`] === undefined
                || this.parent[`${MIN_VALUE}${node.title}`] === '')
            ) {
              return false
            }
            return true
          }),
      [`${MAX_CRITICAL_VALUE}${node.title}`]: yup
        .boolean()
        .test('check',
          'Не установлено предельное значение!',
          function (value) {
            if (value
              && (
                this.parent[`${MAX_VALUE}${node.title}`] === undefined
                || this.parent[`${MAX_VALUE}${node.title}`] === '')
            ) {
              return false
            }
            return true
          }),
      [`${MAX_VALUE}${node.title}`]: yup
        .number()
        .typeError('Только числа'),
      [`${MIN_VALUE}${node.title}`]: yup
        .number()
        .typeError('Только числа')
        .test('compare',
          'Минимальный порог больше максимального',
          function (value) {
            if (Number.parseFloat(value || 0) === 0
              && Number.parseFloat(this.parent[`${MAX_VALUE}${node.title}`] || 0) === 0) {
              return true
            }
            return Number.parseFloat(value || 0) < Number.parseFloat(this.parent[`${MAX_VALUE}${node.title}`] || 0)
          }),
    })
  }
  return yup.object().shape(data.reduce(reducer, {}))
}

export const validationSchemaWidgetSettings = yup.object().shape({
  [MIN_CRITICAL_VALUE]: yup
    .boolean()
    .test('check',
      'Не установлено предельное значение!',
      function (value) {
        if (value && (this.parent[MIN_VALUE] === undefined || this.parent[MIN_VALUE] === '')) {
          return false
        }
        return true
      }),
  [MAX_CRITICAL_VALUE]: yup
    .boolean()
    .test('check',
      'Не установлено предельное значение!',
      function (value) {
        if (value && (this.parent[MAX_VALUE] === undefined || this.parent[MAX_VALUE] === '')) {
          return false
        }
        return true
      }),
  [MAX_VALUE]: yup
    .number()
    .typeError('Только числа'),
  [MIN_VALUE]: yup
    .number()
    .typeError('Только числа')
    .test('compare',
      'Минимальный порог больше максимального',
      function (value) {
        if (
          Number.parseFloat(value || 0) === 0
          && Number.parseFloat(this.parent[MAX_VALUE] || 0) === 0
        ) {
          return true
        }
        return Number.parseFloat(value || 0) < Number.parseFloat(this.parent[MAX_VALUE] || 0)
      }),
})

export const createAdminSchema = yup
  .object()
  .shape({
    [ADMIN_NAME]: yup
      .string()
      .required('Обязательное поле'),
    [ADMIN_EMAIL]: yup
      .string()
      .email('введите коректный email')
      .required('Обязательное поле'),
  })

export const editUserValidationScheme = yup
  .object()
  .shape({
    [ADMIN_SURNAME]: yup
      .string()
      .required('Обязательное поле'),
    [ADMIN_PATRONYMIC]: yup
      .string(),
    [ADMIN_NAME]: yup
      .string()
      .required('Обязательное поле'),
    [ADMIN_TELEPHON]: yup
      .string()
      .matches(
        /^[8|7]-?\(?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
        'Формат телефона 7-111-111-11-11',
      )
      .required('Обязательное поле'),
    [ADMIN_EMAIL]: yup
      .string()
      .email('Введите корректный email'),
    [ADMIN_POSITION]: yup
      .string(),
    [ADMIN_ABOUT_ME]: yup
      .string(),
    [ADMIN_PHOTO]: yup
      .mixed()
      .test(
        'fileSize',
        'файл должен быть до 1Mбайта',
        (value) => {
          if (value) {
            return value.size <= FILE_SIZE
          }
          return true
        },
      )
      .test(
        'fileFormat',
        'формат файла должен быть .png, .jpg, .jpeg',
        (value) => {
          if (value) {
            return SUPPORTED_FORMATS.includes(value.type)
          }
          return true
        },
      ),
    [ADMIN_PHOTO_RESOLUTION]: yup
      .mixed()
      .test(
        'fileResolution',
        'пропорция 1:1 (квадрат), минимальное разрешение 100х100',
        (value) => value,
      ),
  })

export const addGroupValidationScheme = yup
  .object()
  .shape({
    [GROUP_NAME]: yup
      .string()
      .required('Обязательное поле'),
    [GROUP_PHONE_NUMBER]: yup
      .string()
      .matches(
        /^[8|7]-?\(?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
        'Формат телефона 7-111-111-11-11',
      )
      .required('Обязательное поле'),
    [GROUP_EMAIL]: yup
      .string()
      .email('Введите корректный email')
      .required('Обязательное поле'),
    [GROUP_SUB_END_DATA]: yup
      .string()
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.]([2-9][0-9][2-9])\d$/,
        'Дата не соответствует требованиям',
      )
      .required('Обязательное поле'),
  })

export const deskFormValidation = (allServiceDeskIntegrations) => yup
  .object()
  .shape({
    [SD_NAME]: yup
      .string()
      .required('Обязательное поле'),
    [URL]: yup
      .string()
      .required('Обязательное поле'),
    [CLIENT_ID]: yup
      .string()
      .required('Обязательное поле')
      .test(
        'theSameClientId',
        'такой ClientID уже есть в системе',
        (value) => {
          const clientIdExist = (allServiceDeskIntegrations || []).some(
            (serviceDeskIntegration) => (
              value === serviceDeskIntegration.clientId
            ),
          )
          if (clientIdExist) {
            return false
          }
          return true
        },
      ),
    [SECRET_ID]: yup
      .string()
      .required('Обязательное поле')
      .test(
        'theSameSecretId',
        'такой SecretID уже есть в системе',
        (value) => {
          const secretIdExist = (allServiceDeskIntegrations || []).some(
            (serviceDeskIntegration) => (
              value === serviceDeskIntegration.secretId
            ),
          )
          if (secretIdExist) {
            return false
          }
          return true
        },
      ),
    [FILE]: yup
      .mixed()
      .required('Обязательное поле'),
  })

export const createRegionValidator = yup
  .object()
  .shape({
    [CREATE_GEOZONE_NAMES.NAME]: yup
      .string()
      .max(15, 'Максимум 15 символов')
      .required('Обязательное поле'),
    [CREATE_GEOZONE_NAMES.PARENT]: yup
      .string()
      .required('Обязательное поле'),
    [CREATE_GEOZONE_NAMES.LONGITUDE]: longitude,
    [CREATE_GEOZONE_NAMES.LATITUDE]: latitude,
    [CREATE_GEOZONE_NAMES.MARK]: yup
      .string()
      .max(300, 'Максимальный размер 300 символов'),
  })

export const createProjectValidator = yup
  .object()
  .shape({
    [CREATE_REGION_NAMES.NAME]: yup
      .string()
      .max(15, 'Максимум 15 символов')
      .required('Обязательное поле'),
    [CREATE_REGION_NAMES.PARENT]: yup
      .string()
      .required('Обязательное поле'),
    [CREATE_REGION_NAMES.MARK]: yup
      .string()
      .max(300, 'Максимальный размер 300 символов'),
    [CREATE_REGION_NAMES.CUSTOM_FIELD]: yup.array()
      .of(
        yup.object().shape({
          nameField: yup.string().when('remove', {
            is: true,
            otherwise: (validator) => validator
              .max(64, 'Лимит - 64 символа, вкл пробел')
              .required('Обязательное поле'),
          }),
          value: yup.string().when('remove', {
            is: true,
            otherwise: (validator) => validator
              .max(128, 'Лимит - 128 символа, вкл пробел')
              .required('Обязательное поле'),
          }),
        }),
      ),
  })

export const createIntegration = yup
  .object()
  .shape({
    [CREATE_INTEGRATION_NAMES.NAME]: yup
      .string()
      .max(15, 'Максимум 15 символов')
      .required('Обязательное поле'),
    [CREATE_INTEGRATION_NAMES.PARENT_PROJECT]: yup
      .string()
      .required('Обязательное поле'),
    [CREATE_INTEGRATION_NAMES.TYPE]: yup
      .string()
      .required('Обязательное поле'),
  })
