import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CheckedIcon = ({ onClick }) => (
  <svg onClick={onClick} enableBackground="new 0 0 32 32" height="32px" id="Слой_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M27.704,8.397c-0.394-0.391-1.034-0.391-1.428,0  L11.988,22.59l-6.282-6.193c-0.394-0.391-1.034-0.391-1.428,0c-0.394,0.391-0.394,1.024,0,1.414l6.999,6.899  c0.39,0.386,1.039,0.386,1.429,0L27.704,9.811C28.099,9.421,28.099,8.787,27.704,8.397C27.31,8.006,28.099,8.787,27.704,8.397z" id="Check" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)

CheckedIcon.propTypes = {
  onClick: pt.func,
}

CheckedIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(CheckedIcon)
