import styled from 'styled-components'

export const NotificationsStyled = styled.div`
  position: relative;
  min-height: 100px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: -8px 12px 18px rgba(29, 42, 68, 0.128567);
  border-radius: 4px;
  padding: 5px 12px;
`

export const IconContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: ${({ theme, type }) => theme.colors.backgrounds.notification[type || 'success']};

  svg {
    .svgFill {
      fill: ${({ theme }) => theme.colors.white};
    }
    .svgStroke {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const ContentWrapper = styled.div`
  padding-left: 48px;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.colors.notification.title};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 3.2rem;
  text-transform: uppercase;
`

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.colors.notification.description};
  font-size: 1.2rem;
  line-height: 1.7rem;
  white-space: pre-wrap;
`

export const CloseButton = styled.div`
  position: absolute;
  right: 9px;
  top: 9px;

  svg {
    width: 22px;
    height: 22px;
    .svgFill {
      fill: ${({ theme }) => theme.colors.backgrounds.disabled};
    }
    .svgStroke {
      fill: ${({ theme }) => theme.colors.backgrounds.disabled};
    }
  }
`
