import React, { useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import useReduxTable from '@/hooks/useReduxTable'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/analyticsAlarms'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import { ANALYTICS_ALARMS_TABLE } from '@/store/actions/analyticsAlarms'
import { getTableParameters } from '@/store/selectors/analyticsAlarms'

import AlarmSideBar from './components/AlarmSideBar'

const AlarmsAnalytics = ({
  data,
  exportFileStatus,
  selectedElement,
  checkBoxOption,
  selectedNode,
  loading,

  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  requestAnalyticsAlarmsTable,
  requestAlarmManagerFile,
  setSideBarFilter,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const { search } = useLocation()
  const parsedSearch = qs.parse(search)
  useEffect(() => {
    if (search && search.includes('filter')) {
      setSideBarFilter({
        [parsedSearch.filter]: true,
      })
    }
  }, [parsedSearch.filter, requestAnalyticsAlarmsTable, search, setSideBarFilter])

  useEffect(() => {
    requestAnalyticsAlarmsTable()
  }, [selectedNode, requestAnalyticsAlarmsTable])

  const tableProps = useReduxTable(ANALYTICS_ALARMS_TABLE, getTableParameters)

  return (
    <>
      <AlarmSideBar
        isOpen={isOpen}
        toggleIsOpen={setIsOpen}
        selectedNodeName={selectedNode.name}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSideBarFilter={setSideBarFilter}
        checkBoxOption={checkBoxOption}
      />
      {loading
        ? <Loader center />
        : (
          <DutyTable
            {...tableProps}
            fields={fields}
            exportOptions={exportOptions}
            data={data}
            rowSelector={setSelectedElement}
            setEventTypes={setEventTypes}
            setStatusTypes={setStatusTypes}
            onUpdateData={requestAnalyticsAlarmsTable}
            onFileSelect={requestAlarmManagerFile}
            fileStatus={exportFileStatus}
          />
        )}
    </>
  )
}

AlarmsAnalytics.propTypes = {
  data: pt.arrayOf(pt.object),
  checkBoxOption: pt.arrayOf(pt.object),
  selectedNode: pt.objectOf(pt.object),
  exportFileStatus: pt.string,
  selectedElement: pt.objectOf(pt.object),
  loading: pt.bool,

  setSelectedElement: pt.func,
  setSideBarFilter: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAnalyticsAlarmsTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsAnalytics.defaultProps = {
  selectedNode: {},
  data: [],
  loading: false,
  checkBoxOption: [],
  selectedElement: null,
  exportFileStatus: '',

  setSelectedElement: noop,
  setSideBarFilter: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAnalyticsAlarmsTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsAnalytics
