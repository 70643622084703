import React from 'react'
import LiveTime from '@/components/icons/livetime'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import CustomEventCell from '@/components/blocks/DutyTable/customCells/CustomEventCell'

export const fields = [
  {
    id: 'object',
    name: 'Объект',
    value: 'ASC',
  },
  {
    id: 'address',
    name: 'Адрес',
    value: 'ASC',
  },
  {
    id: 'containWarning',
    name: 'Предупреждение',
    value: 'ASC',
    customCell: (props) => {
      const { value } = props
      let valueIcon = value ? WARNING : OK
      if (value === null) {
        valueIcon = UNKNOWN
      }
      return (<CustomEventCell {...props} value={valueIcon} />)
    },
  },
  {
    id: 'containFault',
    name: 'Отказ',
    value: 'ASC',
    customCell: (props) => {
      const { value } = props
      let valueIcon = value ? ERROR : OK
      if (value === null) {
        valueIcon = UNKNOWN
      }
      return (<CustomEventCell {...props} value={valueIcon} />)
    },
  },
  {
    id: 'eventDescriptions',
    name: 'Описание события',
    value: 'ASC',
    customCell: (props) => {
      const { value } = props
      return (
        <>
          {value.map((row) => (
            <div>
              {row}
            </div>
          ))}
        </>
      )
    },
  },
  {
    id: 'currentLifePercent',
    name: '% выработки ресурса',
    value: 'ASC',
  },
  {
    id: 'occurrenceDate',
    name: 'Дата возникновения',
    value: 'ASC',
  },
  {
    id: 'statePeriod',
    name: 'Период состояния',
    value: 'ASC',
  },
]

// TODO: move to one for all tables
export const exportOptions = [
  {
    name: 'EXCEL',
    value: 'xlsx',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]

export default {
  fields,
  exportOptions,
}
