import { handleActions } from 'redux-actions'
import {
  USER_RESTORATION,
} from '@/store/actions/user'

import {
  NOT_REQUESTED,
  IDLE,
  PENDING,
  ERROR,
  REQUEST_STATUSES,
} from '@/constants/requests'

const initialState = {
  error: [],
  data: {},
  status: REQUEST_STATUSES[NOT_REQUESTED],

}

export const reducer = handleActions(
  {
    [USER_RESTORATION.BEGIN]: () => ({
      ...initialState,
      status: REQUEST_STATUSES[PENDING],
    }),
    [USER_RESTORATION.SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      data,
      status: REQUEST_STATUSES[IDLE],
    }),
    [USER_RESTORATION.ERROR]: (state, { payload: { error } }) => ({
      ...state,
      error,
      status: REQUEST_STATUSES[ERROR],

    }),
  },
  initialState,
)

export default reducer
