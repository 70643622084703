import { all } from 'redux-saga/effects'

import getTable from './getTable'
import getFile from './getFile'

export default function* root() {
  yield all([
    getTable(),
    getFile(),
  ])
}
