import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_ANALYTICS_ALARMS_TABLE,
  ANALYTICS_ALARMS_TABLE,
  SET_SIDE_BAR_FILTER,
  SET_STATUS_TYPES,
  SET_EVENTS_TYPES,
} from '@/store/actions/analyticsAlarms'
import { ALARMS_ANALYTICS_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successAnalyticsAlarmsTable,
  errorAnalyticsAlarmsTable,
} from '@/store/actions/analyticsAlarms/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
  getCheckBoxOption,
} from '@/store/selectors/analyticsAlarms'
import { LIGHT } from '@/constants/objectTypes'

function* getAlarmManagerTableSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.isHasInstallations && !node.internalId) {
      yield put(successAnalyticsAlarmsTable({
        data: [],
        page: 1,
        resultCount: 0,
      }))
      return null
    }

    const filterOptions = yield select(getCheckBoxOption)
    const filterOptionsArray = Object.keys(filterOptions)
      .reduce((accumulator, filter) => {
        if (filterOptions[filter]) {
          return [...accumulator, filter]
        }

        return accumulator
      }, [])

    const tableParameters = yield select(getTableParameters)

    const response = yield request({
      url: ALARMS_ANALYTICS_TABLE_ENDPOINT,
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        installationId: (node && node.internalId) ? `${node.internalId}` : null,
        objectId: (node && node.type === LIGHT) ? `${node.id}` : null,
        alertTypes: filterOptionsArray,
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
    })

    if (response.data) {
      const {
        content,
        totalElements,
      } = response.data
      yield put(successAnalyticsAlarmsTable({
        data: content.map((row) => ({
          ...row,
          id: row.objectId,
        })),
        totalElements,
      }))
    }
  } catch (error) {
    yield put(errorAnalyticsAlarmsTable(error))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_ANALYTICS_ALARMS_TABLE.REQUEST, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.SORT, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.PER_PAGE, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.PAGE, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.SELECTED_FIELDS, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.REQUEST_QUERY, getAlarmManagerTableSaga)
  yield takeLatest(SET_STATUS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_EVENTS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_SIDE_BAR_FILTER, getAlarmManagerTableSaga)
}
