import { CREATE_GROUP } from '../index'

export const requestCreateTenantGroup = (payload) => ({
  type: CREATE_GROUP.REQUEST,
  payload,
})

export const responseCreateTenantGroup = (payload) => ({
  type: CREATE_GROUP.SUCCESS,
  payload,
})

export const errorCreateTenantGroup = (payload) => ({
  type: CREATE_GROUP.ERROR,
  payload,
})
