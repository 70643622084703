import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 290px;
  height: 498px;
  display: flex;
  box-shadow: 2px 3px 15px ${({ theme }) => theme.colors.shadows.widgetPrimary};
  background: ${({ theme }) => theme.colors.backgrounds.drawer};
  padding: 15px;
  margin-right: 10px;
  flex-direction: column;
  border-radius: 4px;

  &:last-child {
    margin-right: 0;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`

export const Title = styled.div`
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: left;
  ${({ header }) => header && `
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-left: 10px;
  `};
  ${({ description, theme }) => description && `
    margin-left: 10px;
    color: ${theme.colors.colors.disabled};
  `};
`

export const RowContainer = styled.div`
  padding: 5px;
  background: #F4F4F4;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  margin-bottom: 15px;
  ${({ last }) => last && `
    margin-bottom: 0px;
  `};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  ${({ disabled }) => disabled && `
    opacity: 0.3;
  `}
`

export const RowTitle = styled.div`
  display: flex;
  align-items: center;
`

export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 6px;
  ${({ color }) => `background: ${color};`}
`

export const ChartContainer = styled.div`
  width: 200px;
  height: 200px;
  margin: 45px 30px auto;
`
