import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getUser = createSelector(
  getState,
  (state) => get(state, 'user', {}),
)

export const getUserMockLogin = createSelector(
  getUser,
  (state) => get(state, 'userAuth', {}),
)
export const getUserMockLoginData = createSelector(
  getUserMockLogin,
  (state) => get(state, 'data', {}),
)
export const getUserMockLoginError = createSelector(
  getUserMockLogin,
  (state) => get(state, 'errors', {}),
)

export const getUserAuthorization = createSelector(
  getUser,
  (state) => get(state, 'authorization', {}),
)
export const getUserAuthorizationData = createSelector(
  getUserAuthorization,
  (state) => get(state, 'data', {}),
)
export const getCurrentUserData = createSelector(
  getUserAuthorizationData,
  (state) => get(state, 'user', {}),
)
export const getUserAuthorizationError = createSelector(
  getUserAuthorization,
  (state) => get(state, 'errors', {}),
)
export const getUserAuthorizationStatus = createSelector(
  getUserAuthorization,
  (state) => get(state, 'status', {}),
)

export const getUserAuthorizationLoading = createSelector(
  getUserAuthorization,
  (state) => get(state, 'loading', {}),
)

export const getUserAccessToken = createSelector(
  getUserAuthorizationData,
  (state) => get(state, 'access_token', {}),
)

export const getUserRestoration = createSelector(
  getUser,
  (state) => get(state, 'restoration', {}),
)
export const getUserRestorationData = createSelector(
  getUserRestoration,
  (state) => get(state, 'data', {}),
)
export const getUserRestorationError = createSelector(
  getUserRestoration,
  (state) => get(state, 'errors', {}),
)
export const getUserRestorationStatus = createSelector(
  getUserRestoration,
  (state) => get(state, 'status', {}),
)

export const getUserSetPassword = createSelector(
  getUser,
  (state) => get(state, 'setPassword', {}),
)
export const getUserSetPasswordData = createSelector(
  getUserSetPassword,
  (state) => get(state, 'data', {}),
)
export const getUserSetPasswordError = createSelector(
  getUserSetPassword,
  (state) => get(state, 'errors', {}),
)
export const getUserSetPasswordStatus = createSelector(
  getUserSetPassword,
  (state) => get(state, 'status', {}),
)

export const getUserResetPassword = createSelector(
  getUser,
  (state) => get(state, 'resetPassword', {}),
)
export const getUserResetPasswordData = createSelector(
  getUserResetPassword,
  (state) => get(state, 'data', {}),
)
export const getUserResetPasswordError = createSelector(
  getUserResetPassword,
  (state) => get(state, 'errors', {}),
)
export const getUserResetPasswordStatus = createSelector(
  getUserResetPassword,
  (state) => get(state, 'status', {}),
)

export const getRefreshToken = createSelector(
  getUser,
  (state) => get(state, 'refreshToken', {}),
)
export const getRefreshTokenStatus = createSelector(
  getRefreshToken,
  (state) => get(state, 'status', {}),
)
export const getRefreshTokenRequested = createSelector(
  getRefreshToken,
  (state) => get(state, 'isRequested', false),
)

export const getForgotPassword = createSelector(
  getUser,
  (state) => get(state, 'forgotPassword', {}),
)
export const getForgotPasswordStatus = createSelector(
  getForgotPassword,
  (state) => get(state, 'status', {}),
)
