import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import get from 'lodash/get'
import {
  ROLE_USER,
  ROLE_TENANT_ADMIN,
  ROLE_SUPER_ADMIN,
} from '@/constants/role'

export default (useData, integrationsSystemsData) => {
  const {
    firstName,
    lastName,
    role,
    group,
  } = useData
  const projectCount = Object.keys(integrationsSystemsData).reduce((counter, element) => {
    const systemIntegrations = get(integrationsSystemsData, `${element}.integrations.children`, [])
    return counter + systemIntegrations.length
  }, 0)
  const name = role === ROLE_SUPER_ADMIN
    ? <Lang id={`userProfile.roles.${ROLE_TENANT_ADMIN}`} />
    : `${firstName || ''} ${lastName || ''}`
  let description = ''
  if (role === ROLE_TENANT_ADMIN) {
    description = ''
  }
  if (role === ROLE_USER) {
    description = (group || {}).name || null
  }
  return {
    name,
    description,
    projectCount,
  }
}
