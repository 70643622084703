import {
  TEXT,
  SELECT,
} from '@/constants/semanticNames'
import {
  KULON,
  BRIZ,
  ATM,
  MESH,
} from '@/constants/integrations'
import CREATE_INTEGRATION_NAMES from '@/constants/forms/createIntegration'

export const integrationConfig = [
  {
    type: TEXT,
    selector: CREATE_INTEGRATION_NAMES.NAME,
  },
  {
    type: SELECT,
    selector: CREATE_INTEGRATION_NAMES.PARENT_PROJECT,
  },
  {
    type: SELECT,
    selector: CREATE_INTEGRATION_NAMES.TYPE,
    editBlock: true,
  },
  {
    selector: CREATE_INTEGRATION_NAMES.PARENT,
  },
]

export const typeConfig = [
  {
    value: KULON,
    title: 'АСУ КУЛОН',
  },
  {
    value: BRIZ,
    title: 'АСУ БРИЗ',
  },
  {
    value: ATM,
    title: 'АСУ АТМ',
  },
  {
    value: MESH,
    title: 'АСУ МЕШ',
  },
  {
    value: 'TELUR',
    title: 'АСУ ТЭЛУР',
  },
  {
    value: 'ONE_SIM',
    title: 'АСУ 1СИМ',
  },
]
