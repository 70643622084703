import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInstallationParams } from '@/store/actions/installation/params'

const useInstallationParams = () => {
  const dispatch = useDispatch()

  const values = useSelector((state) => state.installation.params)
  const setValues = useCallback((value) => {
    dispatch(setInstallationParams(value))
  }, [dispatch])

  return [
    values,
    setValues,
  ]
}

export default useInstallationParams
