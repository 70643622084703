import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import MenuIcons from '@/components/icons/menu'
import HeaderIcons from '@/components/icons/header'
import {
  NotificationsStyled,
  IconContainer,
  ContentWrapper,
  Title,
  Description,
  CloseButton,
} from './styles'

const Notifications = ({
  type,
  title,
  description,
  closeToast,
}) => (
  <NotificationsStyled>
    <IconContainer type={type}>
      <MenuIcons.AlarmsIcon />
    </IconContainer>
    <ContentWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </ContentWrapper>
    <CloseButton onClick={closeToast}>
      <HeaderIcons.MenuCloseIcon />
    </CloseButton>
  </NotificationsStyled>
)

Notifications.propTypes = {
  type: pt.string,
  title: pt.oneOfType([pt.string, pt.element]),
  description: pt.oneOfType([pt.string, pt.element]),
  closeToast: pt.func,
}
Notifications.defaultProps = {
  type: 'success',
  title: null,
  description: null,
  closeToast: noop,
}

export default Notifications
