import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MenuCloseIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4388 5.44312C21.0246 4.85734 21.9743 4.85734 22.5601 5.44312C23.1459 6.02891 23.1459 6.97866 22.5601 7.56444L16.123 14.0016L22.5601 20.4387C23.1459 21.0245 23.1459 21.9742 22.5601 22.56C21.9743 23.1458 21.0246 23.1458 20.4388 22.56L14.0016 16.1229L7.56451 22.56C6.97872 23.1458 6.02897 23.1458 5.44319 22.56C4.8574 21.9742 4.8574 21.0245 5.44319 20.4387L11.8803 14.0016L5.44319 7.56444C4.85741 6.97866 4.85741 6.02891 5.44319 5.44312C6.02898 4.85734 6.97873 4.85734 7.56451 5.44312L14.0016 11.8803L20.4388 5.44312Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MenuCloseIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MenuCloseIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MenuCloseIcon)
