import React from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import { Field } from 'formik'
import TimeControl from '@/components/controls/TimeControl'

const TimeField = ({
  className,
  fieldProps,
  isError,
  error,
  customError,
  mask,
  controls,
  errorMessage,
  ...ownProps
}) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const { isSubmitting } = form

      const onChange = (value) => {
        if (!fieldProps.onlyRead) {
          form.setFieldValue(field.name, value)
        }
      }

      return (
        <TimeControl
          {...field}
          {...fieldProps}
          mask={mask}
          customError={customError}
          errorMessage={errorMessage}
          error={get(form, `errors.${field.name}`, false)}
          className={className}
          onChange={onChange}
          controls={controls}
          disabled={fieldProps.disabled || isSubmitting}
        />
      )
    }}
  </Field>
)

TimeField.defaultProps = {
  className: '',
  mask: null,
  fieldProps: {
    icon: null,
    label: null,
    type: 'text',
    placeholder: '',
    autocomplete: '',
    disabled: false,
    onlyRead: false,
  },
  error: pt.string,
  isError: false,
  customError: false,
  controls: null,
  errorMessage: '',
}

TimeField.propTypes = {
  className: pt.string,
  mask: pt.string,
  fieldProps: pt.shape({
    iconName: pt.string,
    label: pt.oneOfType([pt.string, pt.element]),
    type: pt.string,
    placeholder: pt.string,
    autocomplete: pt.string,
    disabled: pt.bool,
    onlyRead: pt.bool,
  }),
  errorMessage: pt.string,
  error: '',
  isError: pt.bool,
  customError: pt.bool,
  controls: pt.element,
}

export default React.memo(TimeField)
