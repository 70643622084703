import React from 'react'
import pt from 'prop-types'
import isString from 'lodash/isString'
import { Link } from 'react-router-dom'
import memoWithName from '@/hocs/memoWithName'

import {
  ContainerLink,
  Title,
  ListContainer,
  Container,
  MainTitle,
  TitleContainer,
  FieldsContainer,
  StyledTooltip,
  RowWrapper,
} from './styles'

const Widget = ({
  type,
  title,
  icon: Icon,
  path,
  valueList,
  descriptionFields,
}) => {
  const LinkComponent = path ? Link : React.Fragment
  const linkProps = path
    ? { to: path }
    : {}
  const isBig = type === 'big'

  return (
    <ContainerLink isBig={isBig} disabled={path}>
      <LinkComponent {...linkProps}>
        <Container isBig={isBig}>
          <Icon />
          {descriptionFields.length > 0
            && (
              <FieldsContainer>
                {descriptionFields.map((element, index) => (
                  <RowWrapper>
                    {element.value && (
                      <Title highLight={element.highLight} bf={index === 0}>
                        <StyledTooltip title={isString(element.value) ? element.value : ''}>
                          {element.value || ''}
                        </StyledTooltip>
                      </Title>
                    )}
                  </RowWrapper>
                ))}
              </FieldsContainer>
            )}
          {valueList.length > 0 && (
            <ListContainer>
              {valueList.map((element) => (
                <RowWrapper>
                  <Title highLight={element.highLight} md>{element.value}</Title>
                  <Title>{element.title}</Title>
                </RowWrapper>
              ))}
            </ListContainer>
          )}
        </Container>
        <TitleContainer>
          {title && <MainTitle invert>{title}</MainTitle>}
        </TitleContainer>
      </LinkComponent>
    </ContainerLink>
  )
}

Widget.defaultProps = {
  type: 'small',
  path: null,
  count: null,
  notification: null,
  iconTitle: null,
  valueList: [],
  descriptionFields: [],
}

Widget.propTypes = {
  type: pt.string,
  iconTitle: pt.string,
  path: pt.string,
  count: pt.number,
  notification: pt.string,
  icon: pt.string.isRequired,
  valueList: pt.arrayOf(pt.object),
  title: pt.oneOfType([pt.string, pt.element, pt.node]).isRequired,
  descriptionFields: pt.arrayOf(pt.string),
}

export default memoWithName(Widget)
