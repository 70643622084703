import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ObjectStatisticIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" d="M36.6667 40.0314H48.3334V41.6993H36.6667V40.0314Z" />
      <path fill={fill} className="svgFill" d="M36.6667 43.3673H48.3334V45.0353H36.6667V43.3673Z" />
      <path fill={fill} className="svgFill" d="M40 46.7032H41.6667V48.3712H40V46.7032Z" />
      <path fill={fill} className="svgFill" d="M43.3333 46.7032H45V48.3712H43.3333V46.7032Z" />
      <path fill={fill} className="svgFill" d="M36.6667 46.7032H38.3334V48.3712H36.6667V46.7032Z" />
      <path fill={fill} className="svgFill" d="M46.6667 46.7032H48.3333V48.3712H46.6667V46.7032Z" />
      <path fill={fill} className="svgFill" d="M36.6666 30.0235H42.5V30.8575C42.5 32.2369 41.3783 33.3595 40 33.3595H33.3333C33.3333 31.4663 33.015 29.6107 32.3866 27.8293L29.2975 29.0928C29.7641 30.4697 30 31.9017 30 33.3595C30 35.2801 29.5816 37.1875 28.785 38.9255L31.7266 40.5067C32.5425 38.7862 33.0541 36.9264 33.2458 35.0283H40C42.2975 35.0283 44.1666 33.1576 44.1666 30.8583V30.0244H50V21.6837H36.6666V30.0235ZM40 25.0196H46.6666V26.6876H40V25.0196Z" />
      <path fill={fill} className="svgFill" d="M31.6667 0V5.83791H26.6667C24.3692 5.83791 22.5 7.70854 22.5 10.0078V17.7481C21.61 17.4161 20.6875 17.1584 19.7392 16.9825L19.2883 20.2876C22.1567 20.8588 24.7292 22.345 26.6667 24.5509L29.3025 22.5076C27.855 20.818 26.1075 19.4677 24.1667 18.4878V10.0078C24.1667 8.62843 25.2883 7.50588 26.6667 7.50588H31.6667V15.0118H50V0H31.6667ZM35 6.67189H36.6667V8.33987H35V6.67189ZM46.6667 11.6758H35V10.0078H46.6667V11.6758ZM46.6667 8.33987H38.3333V6.67189H46.6667V8.33987ZM46.6667 5.00392H35V3.33595H46.6667V5.00392Z" />
      <path fill={fill} className="svgFill" d="M2.5 13.3438C3.88071 13.3438 5 12.2236 5 10.8418C5 9.46004 3.88071 8.33987 2.5 8.33987C1.11929 8.33987 0 9.46004 0 10.8418C0 12.2236 1.11929 13.3438 2.5 13.3438Z" />
      <path fill={fill} className="svgFill" d="M9.16666 13.3438C10.5474 13.3438 11.6667 12.2236 11.6667 10.8418C11.6667 9.46001 10.5474 8.33984 9.16666 8.33984C7.78594 8.33984 6.66666 9.46001 6.66666 10.8418C6.66666 12.2236 7.78594 13.3438 9.16666 13.3438Z" />
      <path fill={fill} className="svgFill" d="M15.8333 13.3438C17.2141 13.3438 18.3333 12.2236 18.3333 10.8418C18.3333 9.46001 17.2141 8.33984 15.8333 8.33984C14.4526 8.33984 13.3333 9.46001 13.3333 10.8418C13.3333 12.2236 14.4526 13.3438 15.8333 13.3438Z" />
      <path fill={fill} className="svgFill" d="M17.5 46.6649V50C18.4058 49.9558 19.2967 49.8449 20.1683 49.6589L19.3058 46.4372C18.7142 46.5556 18.1108 46.6273 17.5 46.6649Z" />
      <path fill={fill} className="svgFill" d="M3.33333 33.3595C3.33333 26.9995 7.8825 21.521 14.045 20.2884L13.5942 16.9833C5.7875 18.4419 0 25.339 0 33.3595C0 37.6587 1.6475 41.5701 4.32583 44.5307L6.68167 42.173C4.605 39.8195 3.33333 36.7388 3.33333 33.3595Z" />
      <path fill={fill} className="svgFill" d="M20.9134 45.9993L21.7775 49.2269C24.1142 48.4729 26.2709 47.2136 28.1025 45.4764L25.6942 43.1671C24.2975 44.4598 22.6709 45.408 20.9134 45.9993Z" />
      <path fill={fill} className="svgFill" d="M13.165 49.6614C14.0308 49.8474 14.9241 49.9516 15.8333 49.9967V46.6607C15.2208 46.6224 14.615 46.5565 14.0275 46.438L13.165 49.6614Z" />
      <path fill={fill} className="svgFill" d="M9.07333 48.1894C9.8675 48.5981 10.6942 48.9508 11.555 49.2286L12.42 45.9969C11.8417 45.8017 11.2825 45.5648 10.7425 45.2955L9.07333 48.1894Z" />
      <path fill={fill} className="svgFill" d="M7.86001 43.3523L5.50418 45.71C6.16835 46.3121 6.87585 46.865 7.63001 47.3546L9.29501 44.469C8.78918 44.1312 8.31501 43.7534 7.86001 43.3523Z" />
    </svg>

  )
}

ObjectStatisticIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ObjectStatisticIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ObjectStatisticIcon)
