import React, { useState, useCallback } from 'react'
import pt from 'prop-types'
import { childrenType } from '@/propTypes/react'
import HeaderIcons from '@/components/icons/header'
import {
  CollapsedBlockStyled,
  CollapsedTitle,
  ArrowWrapper,
  ControlsContainer,
  CountContainer,
  IconAndTitleContainer,
} from './styles'

const CollapsedBlock = ({
  icon: Icon, title, defaultCollapsed, children, count, iconMargin
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  const handleChange = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed, setCollapsed])

  return (
    <CollapsedBlockStyled>
      <CollapsedTitle opened={collapsed} onClick={handleChange} iconMargin={iconMargin}>
        <IconAndTitleContainer>
          {Icon && (<Icon />)}
          {title && (<span>{title}</span>)}
        </IconAndTitleContainer>
        <ControlsContainer count={!!count}>
          {!!count && (
            <CountContainer>
              {`(${count})`}
            </CountContainer>
          )}
          <ArrowWrapper>
            <HeaderIcons.FoldMenuIcon />
          </ArrowWrapper>
        </ControlsContainer>
      </CollapsedTitle>
      {collapsed && children}
    </CollapsedBlockStyled>
  )
}

CollapsedBlock.propTypes = {
  icon: pt.element,
  title: pt.oneOfType([pt.string, pt.element]),
  children: childrenType,
  defaultCollapsed: pt.bool,
  count: pt.number,
  iconMargin: pt.number,
}
CollapsedBlock.defaultProps = {
  icon: null,
  title: null,
  children: null,
  defaultCollapsed: false,
  count: null,
  iconMargin: null,
}

export default CollapsedBlock
