import styled from 'styled-components'

export const TreeNode = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .loader {
    width: 24px;
    height: 24px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`
export const TreeNodeIcon = styled.div`
  margin-right: 10px;
`

export const TreeNodeIconAndInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const TreeNodeInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const TreeNodeObjects = styled.div`
  font-size: 11px;
  font-weight: 500;
  margin-top: 2px;
  text-align: left;
  color: ${({ dark, theme }) => (dark
    ? theme.colors.colors.default
    : theme.colors.colors.disabled)};
`

export const TreeNodeArrow = styled.div`
  margin-right: 0;
  ${({ transform }) => (transform
    ? 'transform: rotate(0deg);'
    : 'transform: rotate(180deg);')}
`

export const TreeNodeTitle = styled.div`
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  text-align: left;
`
