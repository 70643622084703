import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SettingsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 9.67249V8.32688C18.0001 7.73522 17.5998 7.21841 17.0269 7.07041L16.0036 6.80561C15.8755 6.40185 15.7124 6.01004 15.5162 5.63464L16.0613 4.70891C16.3614 4.19927 16.2789 3.55109 15.8606 3.13283L14.9087 2.18093C14.4905 1.76267 13.8423 1.68017 13.3326 1.98034L12.4082 2.52377C12.0315 2.32673 11.6383 2.16305 11.2331 2.03459L10.9592 0.973325C10.8113 0.400268 10.2944 -5.1768e-05 9.70264 5.0213e-09H8.35619C7.7644 0.000194782 7.24783 0.400463 7.09994 0.973256L6.82704 2.02874C6.4179 2.15731 6.02092 2.32177 5.64072 2.5202L4.68827 1.95939C4.17862 1.65924 3.53043 1.74174 3.11218 2.16001L2.16 3.11219C1.74174 3.53043 1.65924 4.17862 1.95941 4.68831L2.52025 5.6406C2.32722 6.01075 2.16637 6.39682 2.03948 6.79453L0.972973 7.07043C0.400268 7.2183 -5.1768e-05 7.73516 5.02092e-09 8.32694V9.6734C0.000194782 10.2652 0.400463 10.7817 0.973256 10.9296L2.02577 11.2022C2.15583 11.6186 2.32303 12.0224 2.52537 12.4089L1.98176 13.3336C1.68185 13.8429 1.76434 14.4911 2.18262 14.9093L3.13479 15.8615C3.55304 16.2797 4.20122 16.3622 4.71092 16.0621L5.63658 15.5168C6.02184 15.7183 6.42438 15.8849 6.83936 16.0145L7.09983 17.0263C7.24783 17.5995 7.7644 17.9998 8.35598 18H9.7027C10.2944 18.0001 10.8113 17.5997 10.9592 17.0267L11.2231 16.0064C11.6338 15.8767 12.0322 15.7108 12.4136 15.5107L13.3117 16.0395C13.8214 16.3396 14.4696 16.2571 14.8878 15.8389L15.84 14.8867C16.2583 14.4684 16.3408 13.8203 16.0406 13.3106L15.5118 12.4126C15.7169 12.0219 15.886 11.6134 16.0171 11.1921L17.0274 10.9307C17.6006 10.7824 18.0009 10.2647 18 9.67249ZM9 12.3488C10.8495 12.3488 12.3488 10.8495 12.3488 9C12.3488 7.15049 10.8495 5.65116 9 5.65116C7.15049 5.65116 5.65116 7.15049 5.65116 9C5.65116 10.8495 7.15049 12.3488 9 12.3488Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SettingsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SettingsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SettingsIcon)
