import React, { useCallback, useMemo } from 'react'
import get from 'lodash/get'
import ImagesSlider from '@/components/blocks/ImagesSlider'
import Loader from '@/components/blocks/Loader'
import * as mapObjectsPassports from '@/constants/mapObjectsPasports'
import placeholderImage from '@/assets/images/placeholder.jpg'
import {
  Container,
  DataWrapper,
  DataRow,
  LoaderContainer,
} from './styles'

const ObjectInformation = (props) => {
  const selectType = useCallback(() => {
    if (props.type) {
      return (mapObjectsPassports.default[props.type] || []).filter(
        (item) => item.showInDashboard === true,
      )
    }
    return []
  }, [props])

  const renderParameters = useCallback(() => {
    const newTelemetry = {
      ...props,
      ...props.telemetry,
      latitude: props.location[0],
      longitude: props.location[1],
    }
    return (
      <>
        {selectType(props).map((row) => (
          <DataRow key={row.selector}>
            <b>
              {row.passportParameter}
              {': '}
            </b>
            {get(newTelemetry, row.selector, '...')}
          </DataRow>
        ))}
      </>
    )
  }, [props, selectType])

  const dataForQr = useMemo(() => (
    selectType()
      .map((row) => `${row.passportParameter}: ${get(props, row.selector, '...')}`)
      .join('\n')
  ), [props, selectType])

  return (
    <Container>
      {props.image.loading
        ? (<LoaderContainer><Loader /></LoaderContainer>)
        : (
          <ImagesSlider
            images={[
              {
                type: 'image',
                url: props.image.url || placeholderImage,
              },
              {
                type: 'qrCode',
                url: dataForQr,
              },
              {
                type: 'video',
                url: 'https://thumbs.gfycat.com/ImpressiveSlushyFlamingo-size_restricted.gif',
                title: 'IP-234-DR-17',
                online: false,
              },
            ]}
          />
        )}
      <DataWrapper>
        {renderParameters(props)}
      </DataWrapper>
    </Container>
  )
}

export default ObjectInformation
