import React from 'react'
import PieChart from '../PieChart'
import * as mapTypes from '@/propTypes/maps'

import {
  Container, PieChartContainer, PieChartOverlay, Title, BackDrop,
} from './styles'

const ControlerIcon = ({ statistic, count, theme }) => (
  <Container theme={theme}>
    <PieChartContainer theme={theme}>
      {count === 0
        ? <BackDrop />
        : <PieChart size="small" statistic={statistic} theme={theme} />}
    </PieChartContainer>
    <PieChartOverlay theme={theme}>
      <Title theme={theme}>
        {count}
      </Title>
    </PieChartOverlay>
  </Container>
)
ControlerIcon.propTypes = {
  theme: mapTypes.theme.isRequired,
  statistic: mapTypes.markStatistic.isRequired,
  count: mapTypes.markCount.isRequired,
}

export default ControlerIcon
