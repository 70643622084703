import { GET_TABLE_STATISTICS } from './index'

export const requestTableStatistics = (payload) => ({
  type: GET_TABLE_STATISTICS.REQUEST,
  payload,
})

export const successTableStatistics = (payload) => ({
  type: GET_TABLE_STATISTICS.SUCCESS,
  payload,
})

export const errorTableStatistics = (payload) => ({
  type: GET_TABLE_STATISTICS.ERROR,
  payload,
})
