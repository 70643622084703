import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ControlCenterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 10.5C16 9.67157 16.6716 9 17.5 9C18.3284 9 19 9.67157 19 10.5V13.689C21.5878 14.3551 23.5 16.7042 23.5 19.5C23.5 22.2958 21.5878 24.6449 19 25.311V39.5C19 40.3284 18.3284 41 17.5 41C16.6716 41 16 40.3284 16 39.5V25.311C13.4122 24.6449 11.5 22.2958 11.5 19.5C11.5 16.7042 13.4122 14.3551 16 13.689V10.5ZM17.5 22.5C19.1569 22.5 20.5 21.1569 20.5 19.5C20.5 17.8431 19.1569 16.5 17.5 16.5C15.8431 16.5 14.5 17.8431 14.5 19.5C14.5 21.1569 15.8431 22.5 17.5 22.5Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.5 41C31.6716 41 31 40.3284 31 39.5V36.311C28.4122 35.6449 26.5 33.2958 26.5 30.5C26.5 27.7042 28.4122 25.3551 31 24.689V10.5C31 9.67157 31.6716 9 32.5 9C33.3284 9 34 9.67157 34 10.5V24.689C36.5878 25.3551 38.5 27.7042 38.5 30.5C38.5 33.2958 36.5878 35.6449 34 36.311V39.5C34 40.3284 33.3284 41 32.5 41ZM32.5 33.5C34.1569 33.5 35.5 32.1569 35.5 30.5C35.5 28.8431 34.1569 27.5 32.5 27.5C30.8431 27.5 29.5 28.8431 29.5 30.5C29.5 32.1569 30.8431 33.5 32.5 33.5Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H46C48.2091 0 50 1.79086 50 4V46C50 48.2091 48.2091 50 46 50H4C1.79086 50 0 48.2091 0 46V4ZM4 3H46C46.5523 3 47 3.44771 47 4V46C47 46.5523 46.5523 47 46 47H4C3.44772 47 3 46.5523 3 46V4C3 3.44772 3.44771 3 4 3Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ControlCenterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ControlCenterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ControlCenterIcon)
