import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SencorIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 18 18">
      <path d="M9 11.964V5.926a3.023 3.023 0 013.02 3.02A3.023 3.023 0 019 11.963z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" d="M9 18A9 9 0 109 0a9 9 0 000 18zm.41-14.113a.41.41 0 11-.82 0V2.41a.41.41 0 11.82 0v1.477zM9 5.106a3.844 3.844 0 00-3.84 3.84A3.844 3.844 0 009 12.784a3.844 3.844 0 003.84-3.84A3.844 3.844 0 009 5.106zm0 8.542a.41.41 0 00-.41.41v1.532a.41.41 0 10.82 0v-1.531a.41.41 0 00-.41-.41zm5.113-5.058h1.477a.41.41 0 010 .82h-1.477a.41.41 0 110-.82zM4.297 9a.41.41 0 00-.41-.41H2.41a.41.41 0 000 .82h1.477a.41.41 0 00.41-.41zm9.36-2.215a.408.408 0 01-.56-.15.41.41 0 01.15-.56l1.255-.725a.41.41 0 11.41.71l-1.255.725zM4.39 11.188l-1.28.739a.41.41 0 00.247.763h.008l.008-.002a.406.406 0 00.148-.051l1.28-.739a.41.41 0 10-.41-.71zm1.721 1.995a.41.41 0 11.71.41l-.738 1.279a.41.41 0 11-.71-.41l.738-1.28zM3.064 6.046l1.279.739a.413.413 0 00.204.055.41.41 0 00.206-.766l-1.28-.738a.41.41 0 10-.41.71zm10.546 5.142l1.302.752a.41.41 0 01-.221.765h-.011l-.006-.001-.012-.001a.408.408 0 01-.16-.053l-1.302-.752a.41.41 0 01.41-.71zm-1.721 1.995a.41.41 0 10-.71.41l.738 1.28a.41.41 0 10.71-.411l-.739-1.279zm-.56-8.27a.408.408 0 00.56-.15l.738-1.28a.41.41 0 00-.71-.41l-.739 1.28a.41.41 0 00.15.56zm-4.862.055a.41.41 0 01-.356-.206l-.738-1.278a.41.41 0 01.71-.41l.739 1.278a.41.41 0 01-.355.616z" clipRule="evenodd" fill={fill} className="svgFill" />
    </svg>
  )
}

SencorIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SencorIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SencorIcon)
