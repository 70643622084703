import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import Button from '@/components/blocks/Button'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import CheckBoxIconField from '@/components/fields/CheckBoxIconField'
import MapsIcons from '@/components/icons/maps'
import { validationSchemaWidgetSettings } from '@/constants/validationFields'
import {
  MIN_VALUE,
  MAX_VALUE,
  MIN_CRITICAL_VALUE,
  MAX_CRITICAL_VALUE,
} from '@/constants/forms/objectWidgetSettings'
import {
  Form,
  FormTitle,
  FieldRow,
  Label,
  TextFieldStyled,
  Buttons,
  Error,
  IconContainer,
} from './styles'

const ObjectWidgetSettings = ({
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const onSubmitForm = (values) => {
    const submitValues = { ...values }
    if (submitValues[MIN_VALUE] === '' || submitValues[MIN_VALUE] === 0) {
      submitValues[MIN_VALUE] = '0'
    }
    if (submitValues[MAX_VALUE] === '' || submitValues[MAX_VALUE] === 0) {
      submitValues[MAX_VALUE] = '0'
    }
    onSubmit(submitValues)
    onCancel()
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchemaWidgetSettings}
      render={({
        handleSubmit, touched, errors, values,
      }) => (
        <Form onSubmit={handleSubmit} id="userData" name="userData">
          <FormTitle>Настройки виджета</FormTitle>
          <FieldRow>
            <Label htmlFor="minValue">Минимальный порог:</Label>
            <TextFieldStyled
              name={MIN_VALUE}
              customError
              error={(touched[MIN_VALUE] && errors[MIN_VALUE])}
              fieldProps={{
                format: 'number',
                autoComplete: 'off',
                placeholder: '0',
              }}
            />
            <PortalTooltip
              title={values[MIN_CRITICAL_VALUE]
                ? (<Lang id="tooltip.warningOff" />)
                : (<Lang id="tooltip.warningOn" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <CheckBoxIconField
                    name={MIN_CRITICAL_VALUE}
                    icon={MapsIcons.WarningIcon}
                  />
                </IconContainer>
              )}
            />
          </FieldRow>
          <Error>{errors[MIN_VALUE] || errors[MIN_CRITICAL_VALUE]}</Error>
          <FieldRow>
            <Label htmlFor="maxValue">Максимальный порог:</Label>
            <TextFieldStyled
              name={MAX_VALUE}
              customError
              error={(touched[MAX_VALUE] && errors[MAX_VALUE])}
              fieldProps={{
                format: 'number',
                autoComplete: 'off',
                placeholder: '0',
              }}
            />
            <PortalTooltip
              title={values[MAX_CRITICAL_VALUE]
                ? (<Lang id="tooltip.warningOff" />)
                : (<Lang id="tooltip.warningOn" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <CheckBoxIconField
                    name={MAX_CRITICAL_VALUE}
                    icon={MapsIcons.WarningIcon}
                  />
                </IconContainer>
              )}
            />
          </FieldRow>
          <Error>{errors[MAX_VALUE] || errors[MAX_CRITICAL_VALUE]}</Error>
          <Buttons>
            <Button type="submit">
              Применить
            </Button>
            <Button styleType="flat" onClick={onCancel}>
              Отмена
            </Button>
          </Buttons>
        </Form>
      )}
    />
  )
}

ObjectWidgetSettings.propTypes = {
  onCancel: pt.func,
  onSubmit: pt.func,
  initialValues: pt.shape({
    min: pt.oneOfType([pt.number, pt.string]),
    max: pt.oneOfType([pt.number, pt.string]),
  }),
}
ObjectWidgetSettings.defaultProps = {
  onCancel: noop,
  onSubmit: noop,
  initialValues: {},
}

export default ObjectWidgetSettings
