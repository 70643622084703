import styled from 'styled-components'
import { Form } from 'formik'
import Button from '@/components/blocks/Button'

export const CustomButton = styled(Button)`
  ${({ disconnect, theme }) => disconnect && `
    background: ${theme.colors.backgrounds.redisign.error};
    &:hover {
      background: ${theme.colors.backgrounds.redisign.error};
    }
  `};
`

export const ServiceDeskButton = styled(Button)`
  text-transform: none;
  svg {
    .svgFill,
    .svgStroke {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const IconContainer = styled.div`
  ${({ nc }) => !nc && `
    svg {
      .svgFill {
        fill: ${({ theme }) => theme.colors.colors.redisign.default};
      }
    }
  `}
  cursor: pointer;
`

export const ServiceDeskContainer = styled.div`
  display: flex;
  width: 290px;
  min-width: 290px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.silverDark};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  flex-direction: column;
  padding: 15px 15px 30px 15px;
  margin-bottom: 10px;
  ${({ remove }) => remove && `
    padding: 15px 10px;
  `}
`

export const Container = styled.div`
  height: 100%;
  width: 100%
  display: flex;
`

export const FormWrapper = styled(Form)`
  display: flex;
`

export const ApplicationMain = styled.div`
  overflow-y: auto;
  padding: 10px;
  ${({ serviceDesk }) => serviceDesk && `
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
  ${({ userServiceDesk }) => userServiceDesk && `
    display: flex;
    flex-direction: column;
  `};
`

export const ApplicationContainer = styled.div`
  width: 310px;
  height: 100%;
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  flex-direction: column;
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  text-align: left;
  white-space: pre-wrap;
  ${({ application }) => application && `
    margin-left: 15px;
  `};
  ${({ header }) => header && `
    font-size: 1.5rem;
    line-height: 1.5rem;
  `};
  ${({ title }) => title && `
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 10px;
  `};
  ${({ serviceDeskCheckBoxContainer }) => serviceDeskCheckBoxContainer && `
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 20px;
  `};
  ${({ checkBox }) => checkBox && `
    margin-left: 10px;
  `};
  ${({ serviceDeskCheckBox }) => serviceDeskCheckBox && `
    margin-left: 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: normal;
  `};
  ${({ serviceDeskCheckBox }) => serviceDeskCheckBox && `
    font-weight: normal;
  `};
`

export const ApplicationHeader = styled.div`
  display: flex;
  min-height: 48px;
  max-height: 48px;
  padding: 15px 20px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ checkBox }) => checkBox && `
    padding: 15px 10px;
  `};
`

export const ModelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const ModelsCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const ApplicationContent = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.borders.redisign.gray};
  margin-bottom: 10px;
  padding: 10px;
  height: 38px;
  min-height: 38px;
  flex-direction: column;
  justify-content: center;
  ${({ isBig }) => isBig && `
    height: 250px;
    justify-content: flex-start;
  `};
`

export const ApplicationLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  width: 180px;
  min-width: 180px;
  margin-right: 10px;
  ${({ header }) => header && `
    width: 100%;
  `};
  svg {
    min-width: 18px;
    min-height: 18px;
  }
`

export const ApplicationModelContainer = styled.div`
  width: 310px;
  height: 100%;
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  flex-direction: column;
  overflow-y: auto;
`

export const ApplicationForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const ApplicationField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const LabelAndIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: space-around;
`

export const RemoveElementContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RemoveTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
`
