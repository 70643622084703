import React, { useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { ONLY_NUMBERS, NUMBER_WITHOUT_FIRST_ZERO } from '@/constants/validationFields'
import UsersIcons from '@/components/icons/users'
import {
  TextFieldStyled,
  Label,
  FieldStyled,
  Error,
  EnumerationContainer,
} from './styles'

const TimeControl = ({
  dark,
  className,
  icon,
  label,
  type,
  format,
  onChange,
  onBlur,
  name,
  disabled,
  fieldIcon,
  placeholder,
  value,
  error,
  pattern,
  mask,
  customError,
  controls: Controls,
  charLimit,
  errorMessage,
  resetButton,
  resetField,
  ...ownProps
}) => {
  const isError = useMemo(() => !!error, [error])

  const handleChangeWithCharsLimit = (fieldValue) => {
    if (!charLimit || fieldValue.length <= charLimit) {
      onChange(fieldValue)
    }
  }

  const handleChange = (fieldType) => (event) => {
    const { value: newValue } = event.target
    const valueByPattern = pattern
      ? ((newValue || '').match(new RegExp(pattern, 'g')) || []).join('')
      : newValue
    const valueForSave = (valueByPattern.match(ONLY_NUMBERS) || []).join('')
    if (fieldType === 'hours' && Number(valueForSave.match(NUMBER_WITHOUT_FIRST_ZERO)) <= 23 && valueForSave.length <= 2) {
      handleChangeWithCharsLimit(`${valueForSave}:${value.split(':')[1] || '00'}`)
    }
    if (fieldType === 'minutes' && Number(valueForSave.match(NUMBER_WITHOUT_FIRST_ZERO)) <= 59 && valueForSave.length <= 2) {
      handleChangeWithCharsLimit(`${value.split(':')[0] || '00'}:${valueForSave}`)
    }
  }

  const changeEnumeration = (enumerationAction, enumerationType) => (e) => {
    e.stopPropagation()
    const maxCondition = enumerationType === 'hours' ? '23' : '59'
    const minCondition = '00'
    let currentValue = enumerationType === 'hours' ? value.split(':')[0] : value.split(':')[1]
    if (currentValue.length === 1) {
      currentValue = `0${currentValue}`
    }
    if (enumerationAction === 'increase') {
      if (currentValue === maxCondition || !currentValue) {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `${minCondition}:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:${minCondition}`,
        )
        return null
      }
      if (currentValue.length === 2 && currentValue[0] === '0' && Number(currentValue[1]) < 9) {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `0${Number(currentValue[1]) + 1}:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:0${Number(currentValue[1]) + 1}`,
        )
        return null
      }
      if (currentValue === '09') {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `10:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:10`,
        )
        return null
      }
      if (Number(currentValue.match(NUMBER_WITHOUT_FIRST_ZERO))) {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `${Number(currentValue) + 1}:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:${Number(currentValue) + 1}`,
        )
        return null
      }
    }
    if (enumerationAction === 'decrease') {
      if (currentValue === minCondition || !currentValue) {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `${maxCondition}:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:${maxCondition}`,
        )
        return null
      }
      if (currentValue.length === 2 && currentValue[0] === '0' && Number(currentValue[1]) <= 9) {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `0${Number(currentValue[1]) - 1}:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:0${Number(currentValue[1]) - 1}`,
        )
        return null
      }
      if (currentValue === '10') {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `09:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:09`,
        )
        return null
      }
      if (Number(currentValue.match(NUMBER_WITHOUT_FIRST_ZERO))) {
        handleChangeWithCharsLimit(
          enumerationType === 'hours'
            ? `${Number(currentValue) - 1}:${value.split(':')[1] || '00'}`
            : `${value.split(':')[0] || '00'}:${Number(currentValue) - 1}`,
        )
        return null
      }
    }
  }

  return (
    <TextFieldStyled
      dark={dark}
      className={className}
      fieldIcon={fieldIcon || !!icon}
      disabled={disabled}
      isError={isError || errorMessage}
      resetButton={resetButton}
    >
      <FieldStyled
        name={name}
        {...ownProps}
        mask={mask}
        type={type}
        value={value.split(':')[0]}
        isError={isError || errorMessage}
        disabled={disabled}
        placeholder={placeholder || label}
        onChange={handleChange('hours')}
        first
      />
      <Label hours>
        <EnumerationContainer>
          <UsersIcons.UpIcon onClick={changeEnumeration('increase', 'hours')} />
          <UsersIcons.DownIcon onClick={changeEnumeration('decrease', 'hours')} />
        </EnumerationContainer>
      </Label>
      <FieldStyled
        name={name}
        {...ownProps}
        mask={mask}
        type={type}
        value={value.split(':')[1]}
        isError={isError || errorMessage}
        disabled={disabled}
        placeholder={placeholder || label}
        onChange={handleChange('minutes')}
      />
      <Label minutes>
        <EnumerationContainer>
          <UsersIcons.UpIcon onClick={changeEnumeration('increase', 'minutes')} />
          <UsersIcons.DownIcon onClick={changeEnumeration('decrease', 'minutes')} />
        </EnumerationContainer>
      </Label>
      {customError
        ? null
        : (!!error || !!errorMessage) && (
          <Error>
            {error || errorMessage}
          </Error>
        )}
    </TextFieldStyled>
  )
}

TimeControl.propTypes = {
  className: pt.string,
  error: pt.string,
  pattern: pt.string,
  errorMessage: pt.string,
  mask: pt.string,
  icon: pt.oneOfType([pt.element, pt.object, pt.string]),
  label: pt.oneOfType([pt.string, pt.element]),
  placeholder: pt.oneOfType([pt.string, pt.element]),
  value: pt.oneOfType([pt.string, pt.number]),
  type: pt.string,
  format: pt.string,
  name: pt.string.isRequired,
  disabled: pt.bool,
  fieldIcon: pt.bool,
  customError: pt.bool,
  dark: pt.bool,
  onChange: pt.func,
  onBlur: pt.func,
  controls: pt.element,
  charLimit: pt.number,
  resetButton: pt.bool,
  resetField: pt.func,
}
TimeControl.defaultProps = {
  className: '',
  pattern: '',
  errorMessage: '',
  mask: null,
  icon: null,
  label: null,
  placeholder: null,
  value: '00:00',
  type: 'text',
  format: 'text',
  disabled: false,
  fieldIcon: false,
  customError: false,
  dark: false,
  error: null,
  onBlur: noop,
  onChange: noop,
  controls: null,
  charLimit: 256,
  resetButton: false,
  resetField: noop,
}

export default React.memo(TimeControl)
