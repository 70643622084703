import styled from 'styled-components'
import { INFO } from '@/constants/objectStatuses'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks'

export const BackDrop = styled.div`
  background: ${STATUS_COLORS[INFO]};
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 50%;
`

export const Container = styled.div`
  z-index: 120;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  border-radius: 50%;
  transition: all 1s ease-out;
  width: 40px;
  height: 40px;
  top: -10px;
  left: -10px;
`

export const PieChartContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  width: 38px;
  height: 38px;
  box-shadow: ${({ theme }) => theme.colors.shadows.pin};
`

export const PieChartOverlay = styled.div`
  position: absolute;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  z-index: 125;
  width: 30px;
  height: 30px;
  top: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`
