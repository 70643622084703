import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_APPEALS_TABLE,
  GET_APPEALS_FILE,
  APPEALS_TABLE,

  SET_SELECTED_ELEMENT,
  SET_EVETS_DATA,

  GET_SERVICE_DESK_CARDS,
  GET_SERVICE_DESK_INFO_WIDGET,
  GET_SERVICE_DESK_INFO_CARD,
  RESET_SERVICE_DESK_TABLE,
} from '@/store/actions/serviceDeskMyCity'
import { fields, REGISTRATION_DATE } from '@/constants/tablesConfig/serviceDeskMyCity'

const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  startDate: null,
  endDate: null,
  selector: 'all',
  eventStatus: ['all'],
  selectedElement: null,
  loading: false,
  table: {
    ...generateDefaultTableState(fields, {
      direction: 'DESC',
      field: REGISTRATION_DATE,
    }),
    perPage: 50,
  },
  infoCard: null,
  infoWidget: null,
  typeOfObjects: [],
  card: [],
  loadingCard: false,
}

export const reducer = handleActions(
  {
    [RESET_SERVICE_DESK_TABLE]: () => ({
      ...initialState,
    }),
    [GET_SERVICE_DESK_INFO_CARD.SUCCESS]: (state, { payload }) => ({
      ...state,
      card: payload,
      loadingCard: false,
    }),
    [GET_SERVICE_DESK_INFO_CARD.REQUEST]: (state) => ({
      ...state,
      loadingCard: true,
    }),
    [GET_SERVICE_DESK_CARDS.SUCCESS]: (state, { payload }) => ({
      ...state,
      infoCard: payload,
      loading: false,
    }),
    [GET_SERVICE_DESK_CARDS.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_SERVICE_DESK_CARDS.ERROR]: (state) => ({
      ...state,
      loading: false,
    }),
    [GET_SERVICE_DESK_INFO_WIDGET]: (state, { payload }) => ({
      ...state,
      infoWidget: payload,
    }),
    [SET_EVETS_DATA]: (state, { payload: { eventsCount, eventData } }) => ({
      ...state,
      eventsCount,
      eventData,
    }),
    [SET_SELECTED_ELEMENT]: (state, { payload }) => ({
      ...state,
      selectedElement: payload,
    }),
    [GET_APPEALS_TABLE.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_APPEALS_TABLE.SUCCESS]: (state, { payload: { data, totalElements, typeOfObjects } }) => ({
      ...state,
      data,
      loading: false,
      typeOfObjects,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_APPEALS_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [GET_APPEALS_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_APPEALS_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_APPEALS_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
    ...generateDefaultTableReducer(APPEALS_TABLE),
  },
  initialState,
)

export default reducer
