import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UploadFileIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 6.246C13.9909 6.16332 13.9733 6.08187 13.9475 6.003V5.922C13.9054 5.82946 13.8493 5.7444 13.7812 5.67L8.53125 0.27C8.45892 0.199995 8.37622 0.142273 8.28625 0.0989999H8.19875C8.11367 0.0522027 8.02223 0.0188298 7.9275 0H2.625C1.92881 0 1.26113 0.284464 0.768845 0.790812C0.276562 1.29716 0 1.98392 0 2.7V15.3C0 16.0161 0.276562 16.7028 0.768845 17.2092C1.26113 17.7155 1.92881 18 2.625 18H11.375C12.0712 18 12.7389 17.7155 13.2312 17.2092C13.7234 16.7028 14 16.0161 14 15.3V6.3V6.246ZM8.75 3.069L11.0163 5.4H9.625C9.39294 5.4 9.17038 5.30518 9.00628 5.1364C8.84219 4.96761 8.75 4.73869 8.75 4.5V3.069ZM12.25 15.3C12.25 15.5387 12.1578 15.7676 11.9937 15.9364C11.8296 16.1052 11.6071 16.2 11.375 16.2H2.625C2.39294 16.2 2.17038 16.1052 2.00628 15.9364C1.84219 15.7676 1.75 15.5387 1.75 15.3V2.7C1.75 2.4613 1.84219 2.23239 2.00628 2.0636C2.17038 1.89482 2.39294 1.8 2.625 1.8H7V4.5C7 5.21608 7.27656 5.90284 7.76884 6.40919C8.26113 6.91554 8.92881 7.2 9.625 7.2H12.25V15.3ZM5.87125 11.4392L6.125 11.1692V13.5002C6.125 13.7389 6.21719 13.9678 6.38128 14.1366C6.54538 14.3054 6.76794 14.4002 7 14.4002C7.23206 14.4002 7.45462 14.3054 7.61872 14.1366C7.78281 13.9678 7.875 13.7389 7.875 13.5002V11.1692L8.12875 11.4392C8.29352 11.6087 8.51699 11.7039 8.75 11.7039C8.98301 11.7039 9.20648 11.6087 9.37125 11.4392C9.53602 11.2697 9.62858 11.0399 9.62858 10.8002C9.62858 10.5605 9.53602 10.3307 9.37125 10.1612L7.62125 8.36121C7.53803 8.27927 7.43991 8.21504 7.3325 8.17221C7.22776 8.12459 7.11451 8.1 7 8.1C6.88549 8.1 6.77224 8.12459 6.6675 8.17221C6.56009 8.21504 6.46197 8.27927 6.37875 8.36121L4.62875 10.1612C4.46398 10.3307 4.37142 10.5605 4.37142 10.8002C4.37142 11.0399 4.46398 11.2697 4.62875 11.4392C4.79352 11.6087 5.01699 11.7039 5.25 11.7039C5.48301 11.7039 5.70648 11.6087 5.87125 11.4392Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UploadFileIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UploadFileIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UploadFileIcon)
