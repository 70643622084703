import { GET_LIVE_TIME_REPORT_TABLE } from './index'

export const requestLiveTimeReportTable = (payload) => ({
  type: GET_LIVE_TIME_REPORT_TABLE.REQUEST,
  payload,
})

export const successLiveTimeReportTable = (payload) => ({
  type: GET_LIVE_TIME_REPORT_TABLE.SUCCESS,
  payload,
})

export const errorLiveTimeReportTable = (payload) => ({
  type: GET_LIVE_TIME_REPORT_TABLE.ERROR,
  payload,
})
