import { connect } from 'react-redux'
import {
  toggleUserProfile,
  toggleSettings,
  setSelectedNode,
  setUnpinNode,
  setGlobalFilterValues,
  resetGlobalFilterValues,
} from '@/store/actions/appSettings'
import { requestGetAlarmsCount, endGetAlarmsCountNotification } from '@/store/actions/alarmManager/getAlarmsCount'
import {
  getProfileOpenState,
  getSettingsOpenState,
  getUserData,
  getNode,
  getPinnedNode,
  getGlobalFilters,
} from '@/store/selectors/appSettings'
import { getSyncInProgress, getSystems } from '@/store/selectors/integrations'
import { getEventsNewCount, getEventsCountNotification } from '@/store/selectors/alarmManager'
import { getCityProcessesOptions } from '@/store/selectors/dictionaries/cityProcesses'
import AppHeader from '@/components/regions/AppHeader'

export default connect(
  (state) => ({
    integrationSystems: getSystems(state),
    isProfileOpen: getProfileOpenState(state),
    isSettingsOpen: getSettingsOpenState(state),
    syncInProgress: getSyncInProgress(state),
    eventsCount: getEventsNewCount(state),
    eventsCountNotification: getEventsCountNotification(state),
    user: getUserData(state),
    selectedNode: getNode(state),
    pinnedNode: getPinnedNode(state),
    cityProcesses: getCityProcessesOptions(state),
    globalFiltersValues: getGlobalFilters(state),
  }),
  (dispatch) => ({
    onToggleProfile: (state) => dispatch(toggleUserProfile(state)),
    onToggleSettings: (state) => dispatch(toggleSettings(state)),
    onGetAlarmsCount: (tenantId) => dispatch(requestGetAlarmsCount(tenantId)),
    endGetAlarmsCountNotification: (tenantId) => dispatch(endGetAlarmsCountNotification(tenantId)),
    onSetSelectedNode: (node) => dispatch(setSelectedNode(node)),
    onSetUnpinNode: (node) => dispatch(setUnpinNode(node)),
    onSetGlobalFilterValues: (values) => dispatch(setGlobalFilterValues(values)),
    onResetGlobalFilterValues: () => dispatch(resetGlobalFilterValues()),
  }),
)(AppHeader)
