import { GET_ANALYTICS_ALARMS_WIDGET } from './index'

export const requestAnalyticsAlarmsWidget = (payload) => ({
  type: GET_ANALYTICS_ALARMS_WIDGET.REQUEST,
  payload,
})

export const successAnalyticsAlarmsWidget = (payload) => ({
  type: GET_ANALYTICS_ALARMS_WIDGET.SUCCESS,
  payload,
})

export const errorAnalyticsAlarmsWidget = (payload) => ({
  type: GET_ANALYTICS_ALARMS_WIDGET.ERROR,
  payload,
})
