import React, {
  useCallback, useMemo, useState
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage as Lang } from 'react-intl'
import TenantForm from '@/forms/TenantForm'
import TenantAccessSettingsForm from '@/forms/TenantAccessSettingsForm'
import UsersApplicationForm from '@/forms/UsersApplicationForm'
import { USER_COLAPSE_BLOCK_TITLE_CHAR_LIMIT } from '@/constants/users'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import useAccessOptions from '@/hooks/useAccessOptions'
import Loader from '@/components/blocks/Loader'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import UserFormHeader from '@/components/blocks/Header'
import {
  LoaderContainer,
  MainContainer,
} from '../../styles'
import {
  Container,
  FormWrapper,
  StyledTooltip,
  FormHeaderContainer,
  InfoContainer,
} from './styles'

const CreateTenant = ({
  editMode,
  closeForm,
  zoneProcessOptions,
  submitTenantForm,
  selectedEntity,
  selectedTenant,
  deleteTenant,
  userRole,
  actionsDisabled,
  systems,
  applications,
  applicationsModels,
  setFormIsModifying,
  isFormModifying,
  serviceDeskStatus,
  addSDIntegration,
  requestDeleteSDIntegration,
  setTenantServiceDesk,
}) => {
  const [groupFormProps, setGroupFromProps] = useState([])
  // const [serviceDeskValue, setServiceDeskValue] = useState(null)
  const [canceledServiceDesk, setCanceledServiceDesk] = useState(false)
  const [serviceDeskIds, setServiceDeskIds] = useState([])

  const {
    selectedAccess,
    setSelectedAccess,
    setNotification,
    applicationAccess,
    setApplicationAccess,
    applicationModelsAccess,
    setApplicationModelsAccess,
  } = useAccessOptions(selectedEntity)

  const chopDownTitleWidthTooltip = (name) => (
    <>
      {((name || '').length > USER_COLAPSE_BLOCK_TITLE_CHAR_LIMIT)
        ? (
          <StyledTooltip title={name}>
            {(name || '').substr(0, USER_COLAPSE_BLOCK_TITLE_CHAR_LIMIT)}
            ...
          </StyledTooltip>
        )
        : (
          <>
            {name}
          </>
        )}
    </>
  )

  const deleteTenantHandler = () => {
    closeForm()
    deleteTenant({
      id: selectedTenant.id,
      name: selectedTenant.name,
    })
  }

  const tenantServiceDeskStatus = useMemo(() => {
    if (selectedTenant.permissions) {
      const serviceDeskSettings = selectedTenant.permissions.applications.find(
        (application) => application.code === SERVICE_DESK,
      )
      return serviceDeskSettings && JSON.parse(serviceDeskSettings.settings)
    }
    return []
  }, [selectedTenant.permissions])

  const handleDebounceDelete = debounce(() => {
    deleteTenantHandler()
  }, DEBOUNCE_DELAY_SMALL)

  const sortedSelectedAccess = useMemo(() => {
    if (selectedAccess) {
      const sortedAccess = selectedAccess.map((integrations) => (
        integrations.children.map((integration) => ({
          id: integration.checkBoxValue === true ? integration.id : null,
        }))
      ))
      return [].concat(...sortedAccess).filter((integration) => integration.id !== null)
    }
    return []
  }, [selectedAccess])

  const serviceDeskId = useMemo(() => (
    applications.find((application) => application.code === SERVICE_DESK).id
  ), [applications])

  const sortedApplications = useCallback((integrations) => (
    integrations.map((integrationId) => {
      if (serviceDeskId === integrationId) {
        if (!isEmpty(serviceDeskStatus)) {
          return {
            id: integrationId,
            settings: JSON.stringify(serviceDeskStatus),
          }
        }
        return {
          id: integrationId,
          settings: JSON.stringify(tenantServiceDeskStatus),
        }
      }
      return { id: integrationId }
    })
  ), [serviceDeskId, serviceDeskStatus, tenantServiceDeskStatus])

  const submitTenantHandler = () => {
    const newServiceDeskStatus = serviceDeskStatus.filter((serviceDesk) => {
      const element = serviceDeskIds.find((element) => element.id === serviceDesk.temporaryId) || {}
      if (element && element.remove) {
        return false
      }
      return true
    })
    submitTenantForm({
      id: selectedTenant.id,
      groupForm: isEmpty(groupFormProps)
        ? { values: selectedEntity }
        : groupFormProps,
      permissions: {
        applications: sortedApplications(applicationAccess),
        applicationModules: sortedApplications(applicationModelsAccess),
        integrations: sortedSelectedAccess,
      },
      selectedEntity,
      serviceDeskStatus: newServiceDeskStatus,
      tenantServiceDeskStatus,
    })
    setNotification(null)
  }

  const submitTenantDebounceHandler = debounce(() => {
    submitTenantHandler()
  }, DEBOUNCE_DELAY_SMALL)

  const initialAccessSettings = useMemo(() => {
    if (isFormModifying && selectedTenant.permissions) {
      const sortedInitialValues = uniqBy(selectedTenant.permissions.integrations, 'type').map((integration) => integration.type)
      const cityProcessId = zoneProcessOptions.filter((options) => (
        options.integrationTypes.some((integrationType) => (
          sortedInitialValues.map((initialValue) => (integrationType.includes(initialValue)))
            .includes(true)
        ))
      ))
      const allIntegrations = selectedTenant.permissions.integrations.map(
        (integration) => integration.id,
      )
      const integrationsWithChildren = cityProcessId.map((integration) => (
        integration.integrationTypes.map((type) => ({
          ...systems[type],
          toggled: true,
          children: systems[type] && systems[type].children && systems[type].children.map((system) => ({
            ...system,
            type: 'checkBox',
            checkBoxValue: allIntegrations.includes(system.id),
            dateOfSynchronization: null,
            status: null,
          })),
        }))
          .filter((integrationType) => integrationType.children !== undefined)
      ))
      return [].concat(...integrationsWithChildren)
    }
    return []
  }, [isFormModifying, selectedTenant.permissions, systems, zoneProcessOptions])

  const renderAccessForm = useMemo(() => (
    <FormWrapper layer={2}>
      <TenantAccessSettingsForm
        isFormModifying={isFormModifying}
        editMode={editMode}
        initialValues={selectedTenant.permissions && selectedTenant.permissions.integrations}
        initialAccessSettings={initialAccessSettings}
        saveFormProps={setSelectedAccess}
        selectedAccess={selectedAccess}
        zoneProcessOptions={zoneProcessOptions}
        disabled={!userRole.isSuperAdmin}
        systems={systems}
      />
    </FormWrapper>
  ),
  [
    initialAccessSettings,
    selectedTenant,
    isFormModifying,
    setSelectedAccess,
    userRole.isSuperAdmin,
    zoneProcessOptions,
    systems,
    selectedAccess,
    editMode,
  ])

  const renderCreateTenantForm = useMemo(() => (
    <TenantForm
      key={JSON.stringify(selectedEntity)}
      edit={editMode}
      initialValues={selectedEntity}
      saveFormProps={setGroupFromProps}
      onSubmitForm={submitTenantForm}
      disabled={!userRole.isSuperAdmin}
      isFormModifying={isFormModifying}
    />
  ),
  [selectedEntity, editMode, submitTenantForm, userRole.isSuperAdmin, isFormModifying])

  const isFormDirty = useMemo(() => {
    let initialApplications = []
    let initialApplicationsModels = []
    const sortedAccessSettings = initialAccessSettings.map((integrations) => (
      integrations.children.map((integration) => ({
        id: integration.checkBoxValue === true ? integration.id : null,
      }))
    ))
    const integrations = [].concat(...sortedAccessSettings).filter(
      (integration) => integration.id !== null,
    )
    if (selectedTenant.permissions) {
      initialApplications = selectedTenant.permissions.applications.map((application) => (
        application.id
      ))
      initialApplicationsModels = selectedTenant.permissions.applicationModules.map(
        (application) => application.id,
      )
    }
    return (
      !isEqual(sortedSelectedAccess.sort(), integrations.sort())
      || groupFormProps.dirty
      || !isEqual(applicationAccess.sort(), initialApplications.sort())
      || !isEqual(applicationModelsAccess.sort(), initialApplicationsModels.sort())
      || serviceDeskStatus
    )
  },
  [
    applicationAccess,
    applicationModelsAccess,
    groupFormProps.dirty,
    initialAccessSettings,
    selectedTenant.permissions,
    sortedSelectedAccess,
    serviceDeskStatus,
  ])

  const renderErrorText = useMemo(() => {
    if (!isFormModifying) {
      return (
        <>
          <Lang id="usersPage.popup.deleteTenant" />
          {`${selectedEntity.name}?`}
        </>
      )
    }
    if (editMode) {
      return <Lang id="usersPage.popup.cancelChangeTenant" />
    }
    if (!editMode) {
      return <Lang id="usersPage.popup.cancelTenant" />
    }
  }, [editMode, isFormModifying, selectedEntity.name])

  const renderUsersApplicationForm = useMemo(() => (
    isFormModifying && (
      <UsersApplicationForm
        userRole={userRole}
        isFormModifying={isFormModifying}
        selectedTenant={selectedTenant}
        applications={applications}
        applicationsModels={applicationsModels}
        applicationAccess={applicationAccess}
        setApplicationAccess={setApplicationAccess}
        applicationModelsAccess={applicationModelsAccess}
        setApplicationModelsAccess={setApplicationModelsAccess}
        serviceDeskStatus={serviceDeskStatus}
        addSDIntegration={addSDIntegration}
        requestDeleteSDIntegration={requestDeleteSDIntegration}
        // setServiceDeskValue={setServiceDeskValue}
        tenantServiceDeskStatus={tenantServiceDeskStatus}
        canceledServiceDesk={canceledServiceDesk}
        setCanceledServiceDesk={setCanceledServiceDesk}
        editMode={editMode}
        setTenantServiceDesk={setTenantServiceDesk}
        serviceDeskIds={serviceDeskIds}
        setServiceDeskIds={setServiceDeskIds}
      />
    )
  ),
  [
    addSDIntegration,
    applicationAccess,
    applicationModelsAccess,
    applications,
    applicationsModels,
    isFormModifying,
    requestDeleteSDIntegration,
    selectedTenant,
    serviceDeskStatus,
    setApplicationAccess,
    setApplicationModelsAccess,
    userRole,
    // setServiceDeskValue,
    tenantServiceDeskStatus,
    canceledServiceDesk,
    setCanceledServiceDesk,
    editMode,
    setTenantServiceDesk,
    serviceDeskIds,
    setServiceDeskIds,
  ])

  const handleCloseForm = useCallback(() => {
    if (editMode) {
      ((Array.isArray(tenantServiceDeskStatus) && tenantServiceDeskStatus) || [])
        .forEach((tenantServiceDesk) => {
          const addingServiceDesk = serviceDeskStatus.some(
            (serviceDesk) => tenantServiceDesk.temporaryId === serviceDesk.temporaryId,
          )
          if (!addingServiceDesk) {
            requestDeleteSDIntegration({
              widgetId: tenantServiceDesk.temporaryId,
              isUserIntegration: true,
            })
          }
        })
    }
    if (!editMode) {
      serviceDeskStatus.forEach((serviceDesk) => (
        requestDeleteSDIntegration({ widgetId: serviceDesk.temporaryId, isUserIntegration: true })
      ))
    }
    setTenantServiceDesk([])
    closeForm()
  },
  [
    closeForm,
    editMode,
    requestDeleteSDIntegration,
    serviceDeskStatus,
    setTenantServiceDesk,
    tenantServiceDeskStatus,
  ])

  const serviceDeskCondition = useMemo(() => {
    const serviceDeskAdded = applicationAccess.includes(serviceDeskId)
    if (serviceDeskAdded) {
      return !isEmpty(serviceDeskStatus)
    }
    return true
  }, [applicationAccess, serviceDeskId, serviceDeskStatus])

  return (
    <Container editMode={!isFormModifying}>
      <InfoContainer>
        <FormHeaderContainer>
          <UserFormHeader
            editTitle={!editMode ? <Lang id="usersPage.formHeader.creatingTenant" /> : <Lang id="usersPage.formHeader.changingTenant" />}
            editMode={isFormModifying}
            deleteForm={handleDebounceDelete}
            saveForm={submitTenantDebounceHandler}
            closeForm={handleCloseForm}
            withoutEditing={!userRole.isSuperAdmin}
            withoutDeleting={!userRole.isSuperAdmin}
            title={chopDownTitleWidthTooltip(selectedTenant.name)}
            setFormIsModifying={setFormIsModifying}
            popupSettings={{
              error: {
                title: <Lang id="usersPage.popup.attention" />,
                message: renderErrorText,
              },
              warning: {
                title: <Lang id="usersPage.popup.impossibleExecute" />,
                message: <Lang id="usersPage.popup.saveTenant" />,
              },
            }}
            conditionsForAdding={
              groupFormProps
              && groupFormProps.isValid
              && (editMode || groupFormProps.dirty)
              && isFormModifying
              && !isEmpty(applicationAccess)
              && !isEmpty(sortedSelectedAccess)
              && (!editMode || isFormDirty)
              && serviceDeskCondition
            }
          />
        </FormHeaderContainer>
        {actionsDisabled && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {editMode && Object.keys(selectedEntity).length === 0 && !actionsDisabled
          ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )
          : (
            <MainContainer actionsDisabled={actionsDisabled} scroll>
              <FormWrapper layer={1}>
                {renderCreateTenantForm}
              </FormWrapper>
              {renderAccessForm}
            </MainContainer>
          )}
      </InfoContainer>
      {renderUsersApplicationForm}
    </Container>
  )
}

CreateTenant.propTypes = {
  editMode: pt.bool,
  actionsDisabled: pt.bool,
  closeForm: pt.func.isRequired,
  submitTenantForm: pt.func,
  deleteTenant: pt.func,
  zoneProcessOptions: pt.objectOf(pt.object).isRequired,
  selectedTenant: pt.objectOf(pt.object),
  systems: pt.arrayOf(pt.object),
  selectedEntity: pt.objectOf(pt.object),
  userRole: pt.shape({
    isSuperAdmin: pt.bool,
  }).isRequired,
}
CreateTenant.defaultProps = {
  editMode: false,
  actionsDisabled: false,
  selectedEntity: {},
  selectedTenant: {},
  systems: [],
  submitTenantForm: noop,
  deleteTenant: noop,
}

export default CreateTenant
