import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import { MARKS_COLORS, MAPS_ICONS } from '@/constants/styles/mapsMarks'
import CoreIcon from '@/components/icons/core'
import {
  UNKNOWN,
} from '@/constants/objectStatuses'
import {
  PinContainer,
  OverLay,
  UnKnowContainer,
} from './styles'

export const PinIcon = ({ status, type, theme }) => {
  const PinTypeIcon = MAPS_ICONS[type] || null
  return (
    <>
      {(type === UNKNOWN || !PinTypeIcon)
        ? (
          <UnKnowContainer theme={theme}>
            <CoreIcon.ICIcon />
            <CoreIcon.UncknowIcon />
          </UnKnowContainer>
        )
        : (
          <PinContainer theme={theme}>
            <OverLay theme={theme}>
              <PinTypeIcon color={MARKS_COLORS[status] || theme.colors.colors.default} />
            </OverLay>
          </PinContainer>
        )}
    </>
  )
}

PinIcon.propTypes = {
  theme: mapTypes.theme.isRequired,
  status: mapTypes.markStatus.isRequired,
  type: mapTypes.markType.isRequired,
}

export default PinIcon
