import { put, takeEvery } from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import moment from 'moment'
import { GET_AUTOMATIC_REPORTING } from '@/store/actions/widgetDashboard'
import { getAutomaticReportingSuccess } from '@/store/actions/widgetDashboard/automaticReporting'
import { ALARM_REGISTER } from '@/constants/apiRoutes'
import { isZone } from '@/helpers/maps/isDefiniteElement'

function* getAutomaticReporting({ payload }) {
  const { formValues, selectedNode } = payload
  const { endDate = null, startDate = null } = formValues || {}
  const queryParams = qs.stringify({
    startDate: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
    endDate: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
    installationId: (selectedNode && selectedNode.internalId) ? selectedNode.internalId : null,
    objectId: (selectedNode && !isZone(selectedNode)) ? selectedNode.id : null,
  })
  try {
    const response = yield request({
      url: `${ALARM_REGISTER}?${queryParams}`,
      method: 'get',
    })
    yield put(getAutomaticReportingSuccess(response.data))
  } catch (error) {
    console.log('TCL: function*depreciationEquipment -> error', error)
  }
  return null
}

export default function* () {
  yield takeEvery(GET_AUTOMATIC_REPORTING.REQUEST, getAutomaticReporting)
}
