import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import {
  SwitchContainer,
  Container,
  Circle,
  Label,
} from './styles'

const SwitchButton = ({
  label,
  enabledLabel,
  onChange,
  value,
  useLocalState,
  disabled,
  customLabel,
  ...ownProps
}) => {
  const handleChange = () => {
    if (!disabled) {
      onChange(!value)
    }
  }

  return (
    <Container
      disabled={disabled}
      checked={value}
      onClick={handleChange}
      {...ownProps}
    >
      <SwitchContainer>
        <Circle />
      </SwitchContainer>
      {(label || enabledLabel) && (
        <Label>{value ? (enabledLabel || label) : label}</Label>
      )}
      {customLabel || null}
    </Container>
  )
}

SwitchButton.defaultProps = {
  label: '',
  enabledLabel: null,
  value: false,
  useLocalState: false,
  onChange: noop,
  disabled: false,
}

SwitchButton.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
  enabledLabel: pt.oneOfType([pt.string, pt.element]),
  onChange: pt.func,
  value: pt.bool,
  useLocalState: pt.bool,
  disabled: pt.bool,
}

export default memoWithName(SwitchButton)
