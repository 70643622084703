import React, { useState, useCallback, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
} from 'recharts'
import getDeclension from '@/helpers/getDeclension'
import { ANDGLE_INCREMENT_SMALL } from '@/constants/graph/pieChart'
import CustomPieLegend from '@/components/charts/CustomPieLegend'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import {
  MainPrimary,
  MainSecondary,
  LabelPrimary,
  LabelSecondary,
  Container,
} from './styles'

const CustomPieChart = ({
  data,
  onClick,
  withHover,
  seriesNames,
  elementNames,
  legend,
  withoutLabel,
}) => {
  const innerRadius = 70 - (withoutLabel ? 25 : 0)
  const firstCircleRadius = 95 - (withoutLabel ? 35 : 0)
  const largeRadius = 140 - (withoutLabel ? 50 : 0)
  const filteredData = useMemo(() => data.filter(({ value }) => value > 0), [data])
  const totalValue = useMemo(
    () => data.reduce((accumulator, element) => accumulator + (element.value || 0), 0), [data],
  )
  const [activeIndex, setActiveIndex] = useState(0)
  const [isUserHover, setIsUserHover] = useState(false)

  const onPieEnter = useCallback((element, index) => {
    setActiveIndex(index)
    setIsUserHover(true)
  }, [])
  const onPieLeave = useCallback(() => {
    setActiveIndex(0)
    setIsUserHover(false)
  }, [])

  const renderActiveShape = useCallback(
    ({
      cx, cy, startAngle, endAngle, fill, percent,
    }) => {
      const offsetY = 15
      const offsetX = 15
      return (
        <g>
          <MainPrimary
            x={cx}
            y={cy - (isUserHover ? (offsetX / 2) : offsetX)}
            dy={offsetY}
            textAnchor="middle"
          >
            {isUserHover
              ? `${Math.floor(percent * 100)} %`
              : numberWithSpaces(Number.parseFloat(totalValue || 0, 10))}
          </MainPrimary>
          <MainSecondary x={cx} y={cy} dy={offsetY} textAnchor="middle">
            {isUserHover ? '' : getDeclension(totalValue, elementNames)}
          </MainSecondary>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={firstCircleRadius}
            startAngle={startAngle}
            endAngle={
              filteredData.length > 1
                ? endAngle
                : endAngle + ANDGLE_INCREMENT_SMALL
            }
            fill={fill}
          />
        </g>
      )
    },
    [
      elementNames,
      filteredData.length,
      totalValue,
      firstCircleRadius,
      isUserHover,
      innerRadius,
    ],
  )

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    fill,
    percent,
    value,
  }) => {
    const RADIAN = Math.PI / 180
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <LabelPrimary
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </LabelPrimary>
        <LabelSecondary
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
        >
          {numberWithSpaces(Number.parseFloat(value || 0, 10))}
        </LabelSecondary>
      </g>
    )
  }

  return (
    <Container withoutLabel={withoutLabel}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={filteredData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={firstCircleRadius}
            outerRadius={largeRadius}
            startAngle={0}
            paddingAngle={0}
            isAnimationActive={false}
            label={withoutLabel ? null : renderCustomizedLabel}
            onMouseEnter={withHover ? onPieEnter : noop}
            onMouseLeave={withHover ? onPieLeave : noop}
          >
            {filteredData.map(({ id }, index) => (
              <Cell
                fill={seriesNames[id].fill}
                stroke={seriesNames[id].fill}
                style={{
                  opacity: isUserHover && activeIndex !== index ? 0.5 : 1,
                }}
              />
            ))}
          </Pie>
          <Pie
            data={filteredData}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={firstCircleRadius}
            startAngle={0}
            paddingAngle={0}
            isAnimationActive={false}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={withHover ? onPieEnter : noop}
            onMouseLeave={withHover ? onPieLeave : noop}
          >
            {filteredData.map(({ id }, index) => (
              <Cell
                fill={seriesNames[id].fill2}
                stroke={seriesNames[id].fill2}
                style={{
                  opacity: isUserHover && activeIndex !== index ? 0.5 : 1,
                }}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {!isEmpty(legend) && (
        <CustomPieLegend
          title={legend.title}
          seriesNames={seriesNames}
          formattedData={data}
        />
      )}
    </Container>
  )
}

CustomPieChart.propTypes = {
  data: pt.arrayOf(pt.object),
  onClick: pt.func,
  withHover: pt.bool,
}

CustomPieChart.defaultProps = {
  data: [],
  onClick: noop,
  withHover: false,
}

export default CustomPieChart
