import React from 'react'
import pt from 'prop-types'
import {
  Container,
  OptionContainer,
  TitleContainer,
  ItemContainer,
  Mark,
} from './styles'

const CustomPieLegend = ({
  seriesNames,
  title,
  formattedData,
}) => {
  const data = formattedData.map((element) => ({
    title: element.name,
    fill: seriesNames[element.id].fill,
  }))
  return (
    <Container>
      <TitleContainer>
        {title}
        {data.length > 1 && ':'}
      </TitleContainer>
      <OptionContainer>
        {data.map((element) => (
          <ItemContainer>
            <Mark color={element.fill} />
            {element.title}
          </ItemContainer>
        ))}
      </OptionContainer>
    </Container>
  )
}

CustomPieLegend.propTypes = {
  seriesNames: pt.arrayOf(pt.object),
  formattedData: pt.arrayOf(pt.object),
  title: pt.string,
}
CustomPieLegend.defaultProps = {
  seriesNames: [],
  formattedData: [],
  title: null,
}

export default CustomPieLegend
