import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_REPORT_MANAGER_TABLE,
  REPORT_MANAGER_TABLE,
  GET_REPORT_MANAGER_TYPES,
  GET_REPORT_MANAGER_OBJECT_TYPES,
  GET_REPORT_MANAGER_USERS,
  IS_FORM_MODIFYING,
  GET_REPORT_MANAGER,
  DELETE_REPORT_MANAGER,
  CHANGE_REPORT_MANAGER,
  GET_REPORT_MANAGER_FILE,
} from '@/store/actions/reportManager'
import { fields } from '@/constants/tablesConfig/reportManager'

const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  selector: 'all',
  graphType: 'graphPercent',
  graphPercent: [],
  graphYear: [],
  selectedGraphPercentOptions: [],
  selectedGraphYearOptions: [],
  loadingTable: false,
  table: generateDefaultTableState(fields),
  reportTypes: [],
  loadingTypes: false,
  reportObjectTypes: [],
  loadingObjectTypes: false,
  commonUsers: [],
  loadingCommonUsers: false,
  isFormModifying: false,
  selectedReport: {},
  loadingSelectedReport: false,
}

export const reducer = handleActions(
  {
    [IS_FORM_MODIFYING]: (state, { payload }) => ({
      ...state,
      isFormModifying: payload,
    }),
    [GET_REPORT_MANAGER_TABLE.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      ...generateTableParameter(state, 'resultCount', payload.totalElements),
      loadingTable: false,
    }),
    [GET_REPORT_MANAGER_TABLE.REQUEST]: (state, { payload }) => ({
      ...state,
      ...payload,
      loadingTable: true,
    }),
    [GET_REPORT_MANAGER_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      loadingTable: false,
    }),
    [GET_REPORT_MANAGER_TYPES.SUCCESS]: (state, { payload }) => ({
      ...state,
      reportTypes: payload,
      loadingTypes: false,
    }),
    [GET_REPORT_MANAGER_TYPES.REQUEST]: (state) => ({
      ...state,
      loadingTypes: true,
    }),
    [GET_REPORT_MANAGER_TYPES.ERROR]: (state) => ({
      ...state,
      loadingTypes: false,
    }),
    [GET_REPORT_MANAGER_OBJECT_TYPES.SUCCESS]: (state, { payload }) => ({
      ...state,
      reportObjectTypes: payload,
      loadingObjectTypes: false,
    }),
    [GET_REPORT_MANAGER_OBJECT_TYPES.REQUEST]: (state) => ({
      ...state,
      loadingObjectTypes: true,
    }),
    [GET_REPORT_MANAGER_OBJECT_TYPES.ERROR]: (state) => ({
      ...state,
      loadingObjectTypes: false,
    }),
    [GET_REPORT_MANAGER_USERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      commonUsers: payload,
      loadingCommonUsers: false,
    }),
    [GET_REPORT_MANAGER_USERS.REQUEST]: (state) => ({
      ...state,
      loadingCommonUsers: true,
    }),
    [GET_REPORT_MANAGER_USERS.ERROR]: (state) => ({
      ...state,
      loadingCommonUsers: false,
    }),
    [GET_REPORT_MANAGER.SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedReport: payload,
      loadingSelectedReport: false,
    }),
    [GET_REPORT_MANAGER.REQUEST]: (state) => ({
      ...state,
      loadingSelectedReport: true,
    }),
    [GET_REPORT_MANAGER.ERROR]: (state) => ({
      ...state,
      loadingSelectedReport: false,
    }),
    [DELETE_REPORT_MANAGER.SUCCESS]: (state) => ({
      ...state,
      selectedReport: {},
      loadingSelectedReport: false,
    }),
    [DELETE_REPORT_MANAGER.REQUEST]: (state) => ({
      ...state,
      loadingSelectedReport: true,
    }),
    [DELETE_REPORT_MANAGER.ERROR]: (state) => ({
      ...state,
      loadingSelectedReport: false,
    }),
    [CHANGE_REPORT_MANAGER.SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedReport: payload,
      loadingSelectedReport: false,
    }),
    [CHANGE_REPORT_MANAGER.REQUEST]: (state) => ({
      ...state,
      loadingSelectedReport: true,
    }),
    [CHANGE_REPORT_MANAGER.ERROR]: (state) => ({
      ...state,
      loadingSelectedReport: false,
    }),
    [GET_REPORT_MANAGER_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_REPORT_MANAGER_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_REPORT_MANAGER_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
    ...generateDefaultTableReducer(REPORT_MANAGER_TABLE),
  },
  initialState,
)

export default reducer
