import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 290px;
  display: flex;
  box-shadow: 2px 3px 15px ${({ theme }) => theme.colors.shadows.widgetPrimary};
  background: ${({ theme }) => theme.colors.backgrounds.drawer};
  padding: 15px;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 10px;
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  height: 32px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const Title = styled.div`
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  ${({ title }) => title && `
    width: 205px;
  `};
  ${({ rowTitle }) => rowTitle && `
    font-size: 1.2rem;
    line-height: 1.4rem;
  `};
`

export const RowContainer = styled.div`
  padding: 5px;
  background: #F4F4F4;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  margin-bottom: 10px;
  ${({ last }) => last && `
    margin-bottom: 0px;
  `};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  ${({ disabled }) => disabled && `
    opacity: 0.3;
  `}
`

export const RowTitle = styled.div`
  display: flex;
  align-items: center;
`

export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 10px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.green};
`

export const GraphContainer = styled.div`
  display: flex;
  max-width: 126px;
  max-height: 126px;
  margin: 25px auto;
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
