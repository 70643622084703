import * as yup from 'yup'
import {
  EMAIL,
} from '@/constants/forms/signIn'

export const forgotPasswordSchema = yup
  .object()
  .shape({
    [EMAIL]: yup
      .string()
      .email('Введите корректный email'),
  })
