import { GROUP_OBJECT, HEATING_DEVICE, COLD_WATER_DEVICE } from '@/constants/objectTypes'
import groupObject from './groupObject'
import heat from './heat'
import water from './water'

export default {
  [HEATING_DEVICE]: heat,
  [COLD_WATER_DEVICE]: water,
  [GROUP_OBJECT]: groupObject,
}
