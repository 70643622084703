import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { eventsExample } from './config'
import {
  CheckBoxContainer,
  Title,
  StyledCheckBox,
  AlarmEvent,
  AlarmTitleContainer,
  SecondaryTitle,
} from './styles'

const AlarmForm = ({
  eventData,
  eventTypes,
  eventsOptions,
  setEventTypes,
}) => {
  const handleEventSelect = useCallback((value) => () => {
    if (eventTypes.includes(value)) {
      const newValues = eventTypes.filter((element) => element !== value)
      setEventTypes(newValues)
      return null
    }
    const newValues = [...eventTypes]
    newValues.push(value)
    setEventTypes(newValues)

    return null
  }, [eventTypes, setEventTypes])

  const renderEventsTypes = () => {
    if (!eventsOptions.length) {
      return null
    }
    return (
      <>
        {eventsOptions.map(({ icon: Icon, name, id }) => (
          <CheckBoxContainer onClick={handleEventSelect(id)}>
            <AlarmTitleContainer>
              <StyledCheckBox value={eventTypes.includes(id)} />
              <AlarmEvent type={id}>
                {Icon && <Icon />}
              </AlarmEvent>
              <SecondaryTitle>
                {name}
              </SecondaryTitle>
            </AlarmTitleContainer>
            <SecondaryTitle value>
              {eventData[id] || 0}
            </SecondaryTitle>
          </CheckBoxContainer>
        ))}
      </>
    )
  }

  return (
    <>
      <Title>Состояние объектов:</Title>
      {renderEventsTypes()}
    </>
  )
}

AlarmForm.propTypes = {
  eventData: pt.arrayOf(pt.object),
  getTableData: pt.func,
  eventCount: pt.number,
  isTableLoaded: pt.bool,
  initialValues: pt.shape({
    start: pt.string,
    end: pt.string,
  }),
}

AlarmForm.defaultProps = {
  getTableData: noop,
  eventData: eventsExample,
  eventCount: 0,
  isTableLoaded: false,
  initialValues: {
    start: null,
    end: null,
  },
}

export default React.memo(AlarmForm)
