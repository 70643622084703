import ServiceIcons from '@/components/icons/serviceDeskMyCity'
import {
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  WAITING_FOR_CONTROL,
  OVERDUE_ON_EXECUTION,
  AVERAGE_LEAD_TIME,
} from '@/constants/serviceDeskMyCity'

export default [
  {
    id: RECEIVED,
    count: 0,
    text: 'Поступило',
    icon: ServiceIcons.ReceivedIcon,
  },
  {
    id: DONE,
    count: 0,
    text: 'Исполнено',
    icon: ServiceIcons.DoneIcon,
  },
  {
    id: TASKS_PERFORMED,
    count: 0,
    textByCount: ['Задача выполняется', 'Задачи выполняется', 'Задач выполняется'],
    icon: ServiceIcons.TasksPerformedIcon,
  },
  {
    id: WAITING_FOR_CONTROL,
    count: 0,
    text: 'Ждет контроля',
    icon: ServiceIcons.WaitingForControlIcon,
  },
  {
    id: OVERDUE_ON_EXECUTION,
    count: 0,
    text: 'Просрочено по исполнению',
    icon: ServiceIcons.OverdueOnExecutionIcon,
  },
  {
    id: AVERAGE_LEAD_TIME,
    count: 0,
    text: 'Среднее время исполнения',
    icon: ServiceIcons.AverageLeadTimeIcon,
  },
]
