import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const IlluminationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.0234 11.7188H38.2812V8.78906C38.2812 8.24966 37.8441 7.8125 37.3047 7.8125H34.705C34.1637 3.42407 30.0182 0 25 0C19.9821 0 15.8363 3.42407 15.295 7.8125H12.6953C12.1559 7.8125 11.7188 8.24966 11.7188 8.78906V11.7188H0.976562C0.437164 11.7188 0 12.1559 0 12.6953V28.3203C0 28.8597 0.437164 29.2969 0.976562 29.2969H49.0234C49.5628 29.2969 50 28.8597 50 28.3203V12.6953C50 12.1559 49.5628 11.7188 49.0234 11.7188ZM11.7188 27.3438H1.95312V25.3906H11.7188V27.3438ZM11.7188 23.4375H1.95312V13.6719H11.7188V23.4375ZM25 1.95312C28.9288 1.95312 32.1899 4.50401 32.7332 7.8125H17.2668C17.8101 4.50401 21.0712 1.95312 25 1.95312ZM32.4219 27.3438H17.5781V22.4609C17.5781 19.7685 19.7685 17.5781 22.4609 17.5781H27.5391C30.2315 17.5781 32.4219 19.7685 32.4219 22.4609V27.3438ZM36.3281 11.7188H28.9062C28.3672 11.7188 27.9297 12.1571 27.9297 12.6953C27.9297 13.2336 28.3672 13.6719 28.9062 13.6719H36.3281V27.3438H34.375V22.4609C34.375 18.6916 31.3084 15.625 27.5391 15.625H22.4609C18.6916 15.625 15.625 18.6916 15.625 22.4609V27.3438H13.6719V13.6719H21.0938C21.632 13.6719 22.0703 13.2336 22.0703 12.6953C22.0703 12.1571 21.632 11.7188 21.0938 11.7188H13.6719V9.76562H36.3281V11.7188ZM48.0469 27.3438H38.2812V25.3906H48.0469V27.3438ZM48.0469 23.4375H38.2812V13.6719H48.0469V23.4375Z" fill={fill} className="svgFill" />
      <path d="M4.88281 21.4844C5.42183 21.4844 5.85938 21.0468 5.85938 20.5078C5.85938 19.9688 5.42183 19.5312 4.88281 19.5312C4.3438 19.5312 3.90625 19.9688 3.90625 20.5078C3.90625 21.0468 4.3438 21.4844 4.88281 21.4844Z" fill={fill} className="svgFill" />
      <path d="M8.78906 21.4844C9.32808 21.4844 9.76562 21.0468 9.76562 20.5078C9.76562 19.9688 9.32808 19.5312 8.78906 19.5312C8.25005 19.5312 7.8125 19.9688 7.8125 20.5078C7.8125 21.0468 8.25005 21.4844 8.78906 21.4844Z" fill={fill} className="svgFill" />
      <path d="M8.78906 17.5781C9.32808 17.5781 9.76562 17.1406 9.76562 16.6016C9.76562 16.0625 9.32808 15.625 8.78906 15.625C8.25005 15.625 7.8125 16.0625 7.8125 16.6016C7.8125 17.1406 8.25005 17.5781 8.78906 17.5781Z" fill={fill} className="svgFill" />
      <path d="M4.88281 17.5781C5.42183 17.5781 5.85938 17.1406 5.85938 16.6016C5.85938 16.0625 5.42183 15.625 4.88281 15.625C4.3438 15.625 3.90625 16.0625 3.90625 16.6016C3.90625 17.1406 4.3438 17.5781 4.88281 17.5781Z" fill={fill} className="svgFill" />
      <path d="M41.2109 21.4844C41.75 21.4844 42.1875 21.0468 42.1875 20.5078C42.1875 19.9688 41.75 19.5312 41.2109 19.5312C40.6719 19.5312 40.2344 19.9688 40.2344 20.5078C40.2344 21.0468 40.6719 21.4844 41.2109 21.4844Z" fill={fill} className="svgFill" />
      <path d="M45.1172 21.4844C45.6562 21.4844 46.0938 21.0468 46.0938 20.5078C46.0938 19.9688 45.6562 19.5312 45.1172 19.5312C44.5782 19.5312 44.1406 19.9688 44.1406 20.5078C44.1406 21.0468 44.5782 21.4844 45.1172 21.4844Z" fill={fill} className="svgFill" />
      <path d="M45.1172 17.5781C45.6562 17.5781 46.0938 17.1406 46.0938 16.6016C46.0938 16.0625 45.6562 15.625 45.1172 15.625C44.5782 15.625 44.1406 16.0625 44.1406 16.6016C44.1406 17.1406 44.5782 17.5781 45.1172 17.5781Z" fill={fill} className="svgFill" />
      <path d="M41.2109 17.5781C41.75 17.5781 42.1875 17.1406 42.1875 16.6016C42.1875 16.0625 41.75 15.625 41.2109 15.625C40.6719 15.625 40.2344 16.0625 40.2344 16.6016C40.2344 17.1406 40.6719 17.5781 41.2109 17.5781Z" fill={fill} className="svgFill" />
      <path d="M25 13.6719C25.5383 13.6719 25.9766 13.2336 25.9766 12.6953C25.9766 12.1571 25.5383 11.7188 25 11.7188C24.4617 11.7188 24.0234 12.1571 24.0234 12.6953C24.0234 13.2336 24.4617 13.6719 25 13.6719Z" fill={fill} className="svgFill" />
      <path d="M47.0001 42.8569C47.0001 42.8569 45.4926 39.2947 45.462 39.2302L41.9464 31.8084C41.7156 31.3208 41.1331 31.1129 40.646 31.3437C40.1585 31.5745 39.9506 32.157 40.1814 32.6445L43.3895 39.4171L39.6797 43.1269L27.079 36.6168L33.8974 33.0939C34.3765 32.8463 34.5642 32.2573 34.3166 31.7782C34.0691 31.2991 33.4801 31.1114 33.0009 31.3586L24.9519 35.5174L16.9029 31.3586C16.4238 31.1114 15.8344 31.2991 15.5869 31.7782C15.3393 32.2573 15.527 32.8463 16.0061 33.0939L22.8241 36.6168L10.2238 43.1269L6.51398 39.4171L9.72214 32.6445C9.95293 32.157 9.74503 31.5745 9.25751 31.3437C8.77037 31.1129 8.18787 31.3208 7.95708 31.8084L4.44145 39.2302C4.41093 39.2947 2.83089 43.0258 2.83089 43.0258L0.286102 45.5706C0.102997 45.7537 0 46.0021 0 46.2611C0 46.5201 0.102997 46.7684 0.286102 46.9516L3.04832 49.7138C3.23906 49.9045 3.48892 49.9999 3.73878 49.9999C3.98865 49.9999 4.23851 49.9045 4.42924 49.7138L7.14645 46.9966C7.14645 46.9966 10.5614 45.1552 10.5877 45.1373L24.9519 37.7158L39.3158 45.1373C39.3421 45.1548 42.9661 47.1091 42.9661 47.1091L45.5708 49.7138C45.7615 49.9045 46.0114 49.9999 46.2612 49.9999C46.5111 49.9999 46.7609 49.9045 46.9517 49.7138L49.7139 46.9516C50.0954 46.5705 50.0954 45.9521 49.7139 45.5706L47.0001 42.8569ZM3.73878 47.6424L2.35748 46.2611L3.71437 44.9042L5.09567 46.2855L3.73878 47.6424ZM6.66084 45.04L4.86298 43.2421L5.67703 41.3424L8.42476 44.0901L6.66084 45.04ZM41.4787 44.0901L44.2265 41.3424L45.055 43.2761L43.2743 45.0568L41.4787 44.0901ZM46.2612 47.6424L44.8803 46.2611L46.2612 44.8802L47.6425 46.2611L46.2612 47.6424Z" fill={fill} className="svgFill" />
    </svg>
  )
}

IlluminationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

IlluminationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(IlluminationIcon)
