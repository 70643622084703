import getYear from 'date-fns/getYear'
import range from 'lodash/range'

export { monthNames as monthsOptions } from '@/constants/datePicker'

const years = range(1990, getYear(new Date()) + 1, 1).reverse()
export const yearsOptions = years.map((value) => ({
  id: value,
  value,
  title: value,
}))
