import {
  put, takeEvery,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import {
  GET_DEPRECIATION_EQUIPMENT,
} from '@/store/actions/widgetDashboard'
import {
  getDepreciationEquipmentSuccess,
} from '@/store/actions/widgetDashboard/depreciationEquipment'
import {
  EQUIPMENT_WEAR,
} from '@/constants/apiRoutes'
import { LIGHT } from '@/constants/objectTypes'
import {
  DEPRECIATION_EQUIPMENT_STRUCTURE,
} from '@/constants/widgetsDashboard'

function* getDepreciationEquipment({ payload }) {
  const queryParams = qs.stringify({
    installationId: (payload && payload.internalId) ? payload.internalId : null,
    objectId: (payload && payload.type === LIGHT) ? payload.id : null,
  })
  try {
    const response = yield request({
      url: `${EQUIPMENT_WEAR}?${queryParams}`,
      method: 'get',
    })
    yield put(getDepreciationEquipmentSuccess({
      count: response.data.count,
      structure: DEPRECIATION_EQUIPMENT_STRUCTURE.map((element) => ({
        ...element,
        value: response.data[element.id],
      })),
    }))
  } catch (error) {
    console.log('TCL: function*depreciationEquipment -> error', error)
  }
  return null
}

export default function* () {
  yield takeEvery(GET_DEPRECIATION_EQUIPMENT.REQUEST, getDepreciationEquipment)
}
