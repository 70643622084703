import React from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import CoreIcons from '@/components/icons/core'
import { forgotPasswordSchema } from '@/constants/validationRegistration/signIn'
import Button from '@/components/blocks/Button'
import TextField from '@/components/fields/TextField'
import {
  EMAIL,
} from '@/constants/forms/signIn'
import {
  Buttons,
  StyledButton,
  Form,
  Text,
} from './styles'

const ForgotPassword = ({ handleForgotPassword, requestUserForgotPassword, handleSubmitted }) => {
  const onForgotPassword = (e) => {
    e.preventDefault()
    handleForgotPassword()
  }

  const renderButton = (errors, values) => {
    if (errors[EMAIL] || values[EMAIL] === '') {
      return React.Fragment
    }
    return (
      <Button type="submit">
        <Lang id="signIn.restore" />
      </Button>
    )
  }

  const onSubmitForm = (values, form) => {
    handleSubmitted(true)
    requestUserForgotPassword({ values, form })
  }

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={{
        [EMAIL]: '',
      }}
      validationSchema={forgotPasswordSchema}
      render={({ handleSubmit, errors, values }) => (
        <Form onSubmit={handleSubmit} id="userData" name="userData">
          <Text>
            <Lang id="signIn.forgotPasswordText" />
          </Text>
          <Lang id="signIn.username">
            {(label) => (
              <TextField
                name={EMAIL}
                fieldProps={{
                  label,
                  icon: CoreIcons.IcUserIcon,
                  placeholder: 'Email',
                }}
              />
            )}
          </Lang>
          <Buttons>
            {renderButton(errors, values)}
            <StyledButton styleType="text" onClick={onForgotPassword}>
              <Lang id="signIn.forgotPasswordReturn" />
            </StyledButton>
          </Buttons>
        </Form>
      )}
    />
  )
}

export default ForgotPassword
