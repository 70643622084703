import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getSocketData = createSelector(
  getState,
  (state) => get(state, 'socket', {}),
)

export const getClient = createSelector(
  getSocketData,
  (socketData) => get(socketData, 'client'),
)

export const getSubscriptions = createSelector(
  getSocketData,
  (socketData) => get(socketData, 'subscriptions', {}),
)
