import pt from 'prop-types'
import noop from 'lodash/noop'

export const routerHistoryType = pt.shape({
  push: pt.func,
})

export const routerHistoryDefault = {
  push: noop,
  location: {
    search: '',
    pathname: '',
  },
}

export default {
  routerHistoryType,
}
