import { GET_INTEGRATIONS } from './index'

export const requestGetIntegrations = () => ({
  type: GET_INTEGRATIONS.REQUEST,
})

export const successGetIntegrations = (data, ids) => ({
  type: GET_INTEGRATIONS.SUCCESS,
  payload: { data, ids },
})

export const errorGetIntegrations = () => ({
  type: GET_INTEGRATIONS.ERROR,
})
