import {
  takeLatest,
} from 'redux-saga/effects'
import createNotifications from '@/helpers/notification'
import { DELETE_INTEGRATION } from '@/store/actions/integrations'
import request from '@/helpers/axios'
import { generateIntegrationTypeWithIdUrl } from '@/constants/apiRoutes'

const HAS_INTEGRATION_IN_USER = 'bad_argument.tenant_id:has_access_to_integration'

function* deleteIntegrationSaga({ payload }) {
  const toast = createNotifications()
  const { id, type, form } = payload
  try {
    const dataJson = {
      aliasId: id,
      integrationType: type,
    }

    yield request({
      url: generateIntegrationTypeWithIdUrl(type.toLowerCase(), id),
      method: 'delete',
      body: dataJson,
    })
  } catch (error) {
    const { errorCode, details } = error.response.data
    if (errorCode === HAS_INTEGRATION_IN_USER) {
      form.setSubmitting(false)
      toast({
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: `Невозможно удалить интеграцию! Права на интеграцию есть у следующих тенантов:
${details.map(({ organizationName }) => organizationName).join(', ')}`,
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(DELETE_INTEGRATION.REQUEST, deleteIntegrationSaga)
}
