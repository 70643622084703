import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InstallationsIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="36" height="52" viewBox="0 0 36 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask-installations)">
        <path d="M30 26C30 19.3726 24.6274 14 18 14C11.3726 14 6 19.3726 6 26C6 32.6274 11.3726 38 18 38C24.6274 38 30 32.6274 30 26Z" stroke={stroke} className="svgStroke" />
        <path d="M16.4031 27.4894L16.4031 27.4894L16.401 27.4855C15.9566 26.6978 15.4746 25.7847 15.105 24.9356C14.7253 24.0632 14.5 23.3361 14.5 22.8901L14.5 22.8887C14.4987 22.4457 14.5878 22.0065 14.7625 21.5965C14.9371 21.1864 15.194 20.8132 15.5191 20.4987L15.5191 20.4987L15.5211 20.4968C15.8444 20.1807 16.2298 19.9293 16.6552 19.7579C17.0807 19.5866 17.5374 19.4988 17.9987 19.5L18.0013 19.5C18.4626 19.4988 18.9193 19.5866 19.3448 19.7579C19.7702 19.9293 20.1556 20.1807 20.4789 20.4968L20.4789 20.4968L20.4809 20.4987C20.806 20.8132 21.0629 21.1864 21.2376 21.5965C21.4122 22.0065 21.5013 22.4457 21.5 22.8887V22.8901C21.5 23.3406 21.2756 24.0716 20.8986 24.9464C20.5315 25.7981 20.0525 26.7137 19.6111 27.5033L19.611 27.5032L19.6061 27.5124C19.1266 28.4135 18.5936 29.2866 18.0098 30.1272C17.4367 29.2702 16.9006 28.3901 16.4031 27.4894ZM15.4867 22.7331L15.4867 22.7342C15.4874 23.376 15.75 23.9884 16.2122 24.4379C16.6739 24.8869 17.297 25.137 17.944 25.1377L17.9451 25.1377C18.5922 25.137 19.2153 24.8869 19.677 24.4379C20.1391 23.9884 20.4017 23.376 20.4025 22.7342L20.4025 22.7331C20.4017 22.0913 20.1391 21.4789 19.677 21.0294C19.2153 20.5804 18.5922 20.3302 17.9451 20.3295L17.9446 20.8295L17.944 20.3295C17.297 20.3302 16.6739 20.5804 16.2122 21.0294C15.75 21.4789 15.4874 22.0913 15.4867 22.7331Z" fill={fill} stroke={stroke} className="svgStroke svgFill" />
        <path d="M18 32C20.2091 32 22 31.3284 22 30.5C22 29.6716 20.2091 29 18 29C15.7909 29 14 29.6716 14 30.5C14 31.3284 15.7909 32 18 32Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" />
      </g>
    </svg>
  )
}

InstallationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InstallationsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InstallationsIcon)
