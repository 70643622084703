import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DesktopIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 7L13.2609 7V10.8261L21 10.8261V7ZM11.5756 12.2803C11.8431 12.6128 12.257 12.8261 12.7216 12.8261L21.5393 12.8261C22.346 12.8261 23 12.1828 23 11.3893V6.43677C23 5.64326 22.346 5 21.5393 5H12.7216C12.257 5 11.8431 5.2133 11.5756 5.54577C11.3785 5.79066 11.2609 6.1002 11.2609 6.43677V11.3893C11.2609 11.7259 11.3785 12.0354 11.5756 12.2803ZM9.38491 12.3074C9.576 12.0587 9.69221 11.7388 9.69558 11.3893C9.69563 11.3841 9.69565 11.3789 9.69565 11.3737L9.69565 6.45237C9.69565 6.44716 9.69563 6.44196 9.69558 6.43677C9.69222 6.08731 9.576 5.76743 9.38491 5.51867C9.14131 5.20156 8.77602 5 8.3677 5H6.32796C5.59455 5 5 5.65025 5 6.45237V11.3737C5 12.1758 5.59455 12.8261 6.32796 12.8261L8.3677 12.8261C8.77602 12.8261 9.14131 12.6245 9.38491 12.3074ZM15.6477 15.6999C15.3976 15.3788 15.0181 15.1739 14.5932 15.1739L6.36335 15.1739C5.61039 15.1739 5 15.8172 5 16.6107V21.5632C5 22.3567 5.61039 23 6.36335 23H14.5932C15.0181 23 15.3976 22.7951 15.6477 22.474C15.8407 22.2261 15.9565 21.9089 15.9565 21.5632V16.6107C15.9565 16.265 15.8407 15.9478 15.6477 15.6999ZM17.8449 22.4355C18.1282 22.7789 18.5721 23 19.071 23H21.4507C22.3064 23 23 22.3498 23 21.5476V16.6263C23 15.8242 22.3064 15.1739 21.4507 15.1739H19.071C18.5721 15.1739 18.1282 15.395 17.8449 15.7384C17.6454 15.9801 17.5255 16.2823 17.5218 16.6107C17.5218 16.6159 17.5217 16.6211 17.5217 16.6263V21.5476C17.5217 21.5528 17.5218 21.558 17.5218 21.5632C17.5255 21.8916 17.6454 22.1938 17.8449 22.4355ZM13.9565 17.1739L7 17.1739L7 21H13.9565V17.1739ZM21 17.1739H19.5217V21H21V17.1739ZM7.69565 7L7.69565 10.8261L7 10.8261V7L7.69565 7Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DesktopIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DesktopIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DesktopIcon)
