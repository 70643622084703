import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ArrIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0L7.4641 6H0.535898L4 0Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ArrIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ArrIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ArrIcon)
