import { GET_INTEGRATION } from './index'

export const requestGetIntegration = (id) => ({
  type: GET_INTEGRATION.REQUEST,
  payload: { id },
})

export const successGetIntegration = (data) => ({
  type: GET_INTEGRATION.SUCCESS,
  payload: data,
})

export const errorGetIntegration = () => ({
  type: GET_INTEGRATION.ERROR,
})
