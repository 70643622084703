import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import { getIsReadByStatus } from '@/helpers/alarmsHelper'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  GET_ALARM_MANAGER_FILE,
} from '@/store/actions/alarmManager'
import { generateAlarmManagerFileUrl } from '@/constants/apiRoutes'
import {
  successAlarmManagerFile,
  errorAlarmManagerFile,
} from '@/store/actions/alarmManager/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getEventTypes,
  getEventStatus,
  getStartDate,
  getEndDate,
  getTableParameters,
} from '@/store/selectors/alarmManager'
import { LIGHT } from '@/constants/objectTypes'

function* getAlarmManagerFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)

    const eventTypes = yield select(getEventTypes)
    const eventStatus = yield select(getEventStatus)

    const startDate = yield select(getStartDate)
    const endDate = yield select(getEndDate)

    const response = yield request({
      url: generateAlarmManagerFileUrl({
        fileType: payload,
      }),
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        installationId: (node && node.internalId) ? node.internalId : null,
        objectId: (node && node.type === LIGHT) ? node.id : null,
        range: {
          start: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
          end: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
        },
        isRead: getIsReadByStatus(eventStatus),
        alertTypes: eventTypes,
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${'all'}_alarm_table_report.${payload}`,
    })
    yield put(successAlarmManagerFile())
  } catch (error) {
    yield put(errorAlarmManagerFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет. Не выбран ни один столбец для формирования отчета',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_ALARM_MANAGER_FILE.REQUEST, getAlarmManagerFileSaga)
}
