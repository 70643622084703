import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ALARM_MANAGER_SET_ALL_READ_ALARM_ENDPOINT } from '@/constants/apiRoutes'
import { SET_SELECTED_ALL_ELEMENTS } from '@/store/actions/alarmManager'
import {
  requestAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import {
  requestCheckAllAlarms,
} from '@/store/actions/alarmManager/checkAllAlarms'
import {
  requestGetAlarmsCount,
} from '@/store/actions/alarmManager/getAlarmsCount'

function* setEventAllRead({ payload }) {
  const { id } = payload
  try {
    yield request({
      url: `${ALARM_MANAGER_SET_ALL_READ_ALARM_ENDPOINT}/${id || ''}`,
      method: 'put',
    })
    yield put(requestGetAlarmsCount())
    yield put(requestAlarmManagerTable())
    yield put(requestCheckAllAlarms({ id }))
  } catch (error) {
    console.log('TCL: function*setEventRead -> error', error)
  }
}

export default function* root() {
  yield takeLatest(SET_SELECTED_ALL_ELEMENTS, setEventAllRead)
}
