import { DELETE_INTEGRATION } from './index'

export const requestDeleteIntegration = (payload) => ({
  type: DELETE_INTEGRATION.REQUEST,
  payload,
})

export const successDeleteIntegration = (data) => ({
  type: DELETE_INTEGRATION.SUCCESS,
  payload: data,
})

export const errorDeleteIntegration = () => ({
  type: DELETE_INTEGRATION.ERROR,
})

export const eventsDeleteIntegration = () => ({
  type: DELETE_INTEGRATION.EVENTS,
})
