import { GET_REPORT_MANAGER_OBJECT_TYPES } from './index'

export const requestReportManagerObjectTypes = (payload) => ({
  type: GET_REPORT_MANAGER_OBJECT_TYPES.REQUEST,
  payload,
})

export const successReportManagerObjectTypes = (payload) => ({
  type: GET_REPORT_MANAGER_OBJECT_TYPES.SUCCESS,
  payload,
})

export const errorReportManagerObjectTypes = (payload) => ({
  type: GET_REPORT_MANAGER_OBJECT_TYPES.ERROR,
  payload,
})
