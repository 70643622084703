import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UsersGroupIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.4285 5.16155C8.4285 5.55227 8.109 5.86855 7.71429 5.86855C7.31957 5.86855 7.00007 5.55227 7.00007 5.16155C7.00007 4.77082 7.31957 4.45455 7.71429 4.45455C8.109 4.45455 8.4285 4.77082 8.4285 5.16155Z" fill={fill} className="svgFill" />
      <path d="M8.35714 8.90909C9.55543 8.90909 10.5621 9.72045 10.8476 10.8182H4.581C4.86643 9.72045 5.87314 8.90909 7.07143 8.90909H8.35714Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9091 1.61538H8.67865L7.56342 0.365199C7.35632 0.133034 7.05808 0 6.74471 0H1.09091C0.488417 0 0 0.482155 0 1.07692V12.9231C0 13.5178 0.488417 14 1.09091 14H16.9091C17.5116 14 18 13.5178 18 12.9231V2.69231C18 2.09754 17.5116 1.61538 16.9091 1.61538ZM7.07143 7.03691C6.72364 6.91982 6.41829 6.71109 6.18621 6.43873C5.89179 6.09382 5.71436 5.64836 5.71436 5.16155C5.71436 4.06827 6.60986 3.18182 7.71429 3.18182C8.81871 3.18182 9.71421 4.06827 9.71421 5.16155C9.71421 5.64836 9.53679 6.09382 9.24236 6.43873C9.01029 6.71109 8.70493 6.91982 8.35714 7.03691C8.15529 7.10436 7.93929 7.14127 7.71429 7.14127C7.48929 7.14127 7.27329 7.10436 7.07143 7.03691ZM3.26764 10.8182C3.50486 9.41882 4.51093 8.27718 5.84164 7.83427C6.228 7.70573 6.64136 7.63636 7.07143 7.63636H8.35714C8.78721 7.63636 9.20057 7.70573 9.58693 7.83427C10.9176 8.27718 11.9237 9.41882 12.1609 10.8182C12.1963 11.025 12.2143 11.2375 12.2143 11.4545V12.0909H3.21429V11.4545C3.21429 11.2375 3.23229 11.025 3.26764 10.8182ZM9.82175 7.14127C9.60961 7.14127 9.58518 6.86064 9.75361 6.73273C10.2293 6.37064 10.536 5.80173 10.536 5.16155C10.536 4.52136 10.2293 3.95245 9.75361 3.59036C9.58518 3.46245 9.60961 3.18182 9.82175 3.18182C10.9262 3.18182 11.8217 4.06827 11.8217 5.16155C11.8217 6.25482 10.9262 7.14127 9.82175 7.14127ZM12.8803 12.0909H14.166V11.4545C14.166 11.2375 14.148 11.025 14.1126 10.8182C13.8066 9.01218 12.2201 7.63636 10.3089 7.63636L10.2857 7.84573C11.7958 8.36309 12.8803 9.78345 12.8803 11.4545V12.0909Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UsersGroupIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UsersGroupIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UsersGroupIcon)
