import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import noop from 'lodash/noop'
import {
  errorUpdateTenantGroup,
} from '@/store/actions/users/group/updateGroup'
import {
  requestGetTenantGroup,
} from '@/store/actions/users/group/getGroup'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import createNotifications from '@/helpers/notification'
import { UPDATE_GROUP, setFormIsModifying } from '@/store/actions/users'
import { generateEditGroups } from '@/constants/apiRoutes'
import { errorObject } from '@/constants/users'
import { NAME } from '@/constants/forms/group'
import { setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'
import toastErrorHelper from '@/helpers/toastHelper'

function* editTenantGroupSaga({ payload }) {
  const toast = createNotifications()
  const {
    groupFrom, permissions, tenantId, groupId,
  } = payload
  const groupFromValues = groupFrom.values
  const {
    setSubmitting = noop,
    setErrors = noop,
  } = groupFrom
  try {
    const dataJson = {
      permissions,
      name: groupFromValues.organizationName,
      blocked: groupFromValues.blocked,
    }
    yield request({
      url: generateEditGroups(groupId),
      method: 'put',
      body: dataJson,
    })
    yield put(requestGetTenantGroup({
      tenantId,
      groupId,
    }))
    yield put(requestGetAllAdminTenants({ id: tenantId }))
    yield put(requestGetTenantGroups(tenantId))
    yield put(setFormIsModifying(false))
    toast({
      title: 'Успешная операция!',
      description: 'Изменения успешно сохранены',
    })
    yield put(setTenantServiceDesk([]))
  } catch (error) {
    console.log('TCL: function*editTenantGroupSaga -> error', error)
    const { response } = error
    const { errorCode } = response.data
    if (errorCode) {
      yield put(errorUpdateTenantGroup())
      setErrors({
        [NAME]: errorObject[errorCode],
      })
      setSubmitting(false)
      toast({
        title: 'Ошибка операции!',
        description: `Ошибка при сохранении изменений! Повторите попытку позже. Код ошибки: ${toastErrorHelper(error)}`,
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(UPDATE_GROUP.REQUEST, editTenantGroupSaga)
}
