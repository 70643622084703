import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import CoreIcons from '@/components/icons/core'
import Button from '@/components/blocks/Button'
import SelectField from '@/components/fields/SelectField'
import DatePickerField from '@/components/fields/DatePickerField'
import RadioGroupField from '@/components/fields/RadioGroupField'
import RadioGroup from '@/components/controls/RadioGroup'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import { REQUEST_STATUSES } from '@/constants/requests'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
  DEMONSTRATION_GRAPH,
  DEMONSTRATION_TABLE,
  DIFFERENCE_ANALSIS,
  COMPARATIVE_PLANE_ANALSIS,
  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
  CURRENT_MONTH,
  PREVIOUS_MONTH,
  QUARTER,
  YEAR,
} from '@/constants/names'

import {
  GRAPH_VIEW,
  DATE_FIRST,
  DATE_SECOND,
  DEMONSTRATION,
  FAST_INTERVAL,
  TYPE,
  INTEGRATION_TYPES,
} from '@/constants/forms/analyticExpenses'
import {
  DAY_TO_MILI_SEC,
  TYPE_TO_MILI_SEC,
} from '@/constants/sizes'
import {
  demonstrationOptions,
  graphViewOptions,
  fastIntervalOptions,
  typesConfig,
} from './config'
import {
  FormContainer,
  Title,
  FieldContainer,
  DecorativeElement,
  ButtonContainer,
  IconDiagramContainer,
  SavingsItemContainer,
  SecondaryTitle,
  CustomTextControl,
} from './styles'

const AnalyticExpensesForm = ({
  integrationTypes,
  intervalOptions,
  getHeatTableData,
  getHeatGraphData,
  getTableData,
  getGraphData,
  getPlanGraphData,
  setFormValues,
  savings,
  statusTable,
  statusGraph,
  setForm,
  calculatorValue,
  consumptionForm,
}) => {
  const formicForm = useRef()

  const [textInputValue, setTextInputValue] = useState(null)

  const [endRangeMin, setEndRangeMin] = useState(null)
  const [endRangeMax, setEndRangeMax] = useState(null)
  const [processOptions, setProcessOptions] = useState([])

  const [fastIntervalValue, setFastIntervalValue] = useState(null)
  const [fastIntervalFormsValues, setFastIntervalValues] = useState({})

  const [submitValues, setSubmitValues] = useState(null)

  useEffect(() => {
    const newProcessOptions = typesConfig.filter(
      (element) => integrationTypes.includes(element.value),
    )
    setProcessOptions(newProcessOptions)
  }, [integrationTypes, setProcessOptions])

  const validateFastInterval = useCallback((values) => {
    if (
      fastIntervalValue
      && (fastIntervalFormsValues[DATE_FIRST] !== values[DATE_FIRST]
      || fastIntervalFormsValues[DATE_SECOND] !== values[DATE_SECOND]
      || fastIntervalFormsValues[TYPE] !== values[TYPE])
    ) {
      setFastIntervalValue(null)
    }
  }, [fastIntervalValue, fastIntervalFormsValues])

  const onRangeChangeHandler = (values) => {
    if (values[DATE_FIRST]) {
      setEndRangeMin(values[DATE_FIRST].getTime() + DAY_TO_MILI_SEC)
      setEndRangeMax(TYPE_TO_MILI_SEC[values.type] && (values[DATE_FIRST]
        .getTime() + (TYPE_TO_MILI_SEC[values.type] * DAY_TO_MILI_SEC)
        || null))
    }
    if (
      values.type !== ANNUALLY
      && (values[DATE_FIRST] && values[DATE_SECOND])
      && ((values[DATE_FIRST]
        .getTime() + TYPE_TO_MILI_SEC[values.type] * DAY_TO_MILI_SEC) < values[DATE_SECOND])
    ) {
      return ({ badRange: 'badRange' })
    }
    return ({})
  }

  const validateHandler = (formValues) => {
    const graphViewTypes = graphViewOptions[
      formValues[INTEGRATION_TYPES]
      || integrationTypes[0]
    ].map(({ value }) => value)
    const hasGraphViewInTypes = graphViewTypes.includes(formValues[GRAPH_VIEW])
    if (
      formValues[GRAPH_VIEW]
      && !hasGraphViewInTypes
      && formValues[INTEGRATION_TYPES] !== consumptionForm[INTEGRATION_TYPES]
    ) {
      formicForm.current.setValues({
        ...formValues,
        [GRAPH_VIEW]: graphViewOptions[
          formValues[INTEGRATION_TYPES]
          || integrationTypes[0]
        ][0].value || null,
      })
    }
    if (formValues[TYPE]) {
      return onRangeChangeHandler(formValues)
    }
    return ({})
  }

  const handleDateCheck = useCallback((values, form) => {
    const dateDifference = Math.ceil(
      Math.abs(values[DATE_SECOND].getTime() - values[DATE_FIRST].getTime()) / (1000 * 3600 * 24),
    )
    if (values[DATE_SECOND] <= values[DATE_FIRST]) {
      form.setSubmitting(false)
      form.setErrors({ badRange: 'badRange' })
      return false
    }
    switch (values.type) {
      case MONTHLY: {
        if (dateDifference > 366) {
          form.setSubmitting(false)
          form.setErrors({ badRange: 'badRange' })
          return false
        }
        return true
      }
      case WEEKLY: {
        if (dateDifference > 92) {
          form.setSubmitting(false)
          form.setErrors({ badRange: 'badRange' })
          return false
        }
        return true
      }
      case DAILY: {
        if (dateDifference > 31) {
          form.setSubmitting(false)
          form.setErrors({ badRange: 'badRange' })
          return false
        }
        return true
      }
      case ANNUALLY: {
        return true
      }
      default: {
        form.setSubmitting(false)
        form.setErrors({ badRange: 'badRange' })
        return false
      }
    }
  }, [])

  const onSubmit = useCallback((values, form) => {
    setTextInputValue('')
    setSubmitValues(values)
    if (formicForm && handleDateCheck(values, form)) {
      setFormValues(values)
      if (values[INTEGRATION_TYPES] === OUTDOOR_LIGHTING) {
        if (values[DEMONSTRATION] === DEMONSTRATION_TABLE) {
          getTableData()
        }
        if (values[DEMONSTRATION] === DEMONSTRATION_GRAPH) {
          if (values[GRAPH_VIEW] === COMPARATIVE_PLANE_ANALSIS) {
            getPlanGraphData()
          } else {
            getGraphData()
          }
        }
      }
      if (values[INTEGRATION_TYPES] === HEAT_SUPPLY) {
        if (values[DEMONSTRATION] === DEMONSTRATION_TABLE) {
          getHeatTableData()
        }
        if (values[DEMONSTRATION] === DEMONSTRATION_GRAPH) {
          getHeatGraphData()
        }
      }
    }
  },
  [
    formicForm,
    handleDateCheck,
    getHeatTableData,
    getHeatGraphData,
    getTableData,
    getGraphData,
    getPlanGraphData,
    setFormValues,
    setTextInputValue,
  ])

  const debounceOnSubmit = debounce(onSubmit, DEBOUNCE_DELAY_SMALL)

  const handleTextInputChange = useCallback((value) => {
    setTextInputValue(value)
  }, [setTextInputValue])

  const handleCalculationSavings = useMemo(() => {
    const calculatorValueNumber = Number.parseFloat(calculatorValue, 10)
    const textInputValueNumber = Number.parseFloat(textInputValue, 10)
    if (textInputValueNumber) {
      return numberWithSpaces(
        Math.ceil(Math.abs(calculatorValueNumber * textInputValueNumber) * 100) / 100,
      )
    }
    return ''
  }, [calculatorValue, textInputValue])

  const isOverValue = useCallback((values) => {
    if (values[INTEGRATION_TYPES] === OUTDOOR_LIGHTING) {
      return calculatorValue > 0
    }
    if (values[INTEGRATION_TYPES] === HEAT_SUPPLY) {
      return calculatorValue > 0
    }
  }, [calculatorValue])

  const renderSavings = useCallback((values) => {
    const { min, max, average } = savings
    if (!isEqual(submitValues, values)) {
      return null
    }
    if (
      values[DEMONSTRATION] === DEMONSTRATION_GRAPH
      && values[GRAPH_VIEW] === COMPARATIVE_PLANE_ANALSIS
      && calculatorValue
    ) {
      const renderTotalTitle = () => {
        if (values[INTEGRATION_TYPES] === OUTDOOR_LIGHTING) {
          return calculatorValue > 0
            ? <Lang id="analyticExpenses.form.light.overPayment" />
            : <Lang id="analyticExpenses.form.light.saving" />
        }
        if (values[INTEGRATION_TYPES] === HEAT_SUPPLY) {
          return calculatorValue > 0
            ? <Lang id="analyticExpenses.form.heat.overpay" />
            : <Lang id="analyticExpenses.form.heat.economy" />
        }
      }
      return (
        <>
          <Title>
            <Lang id="analyticExpenses.form.calculator" />
          </Title>
          <SavingsItemContainer expenseCalculator>
            <SecondaryTitle expenseCalculator saving={isOverValue(values)}>
              <Lang id={`analyticExpenses.form.${isOverValue(values) ? 'negativeEffect' : 'positiveEffect'}`} />
            </SecondaryTitle>
            <CustomTextControl
              disabled
              dark
              value={calculatorValue && numberWithSpaces(Math.abs(calculatorValue))}
            />
            <SecondaryTitle expenseCalculator>
              <Lang id={`analyticExpenses.form.units.${values[INTEGRATION_TYPES]}`} />
            </SecondaryTitle>
          </SavingsItemContainer>

          <SavingsItemContainer expenseCalculator>
            <SecondaryTitle expenseCalculator>
              <Lang id="analyticExpenses.form.rate" />
            </SecondaryTitle>
            <CustomTextControl
              format="float"
              value={textInputValue && numberWithSpaces(textInputValue)}
              onChange={handleTextInputChange}
            />
            <SecondaryTitle expenseCalculator>
              <Lang id="analyticExpenses.form.savingValue" />
              /
              <Lang id={`analyticExpenses.form.units.${values[INTEGRATION_TYPES]}`} />
            </SecondaryTitle>
          </SavingsItemContainer>

          <SavingsItemContainer expenseCalculator>
            <SecondaryTitle expenseCalculator saving={isOverValue(values)}>
              {renderTotalTitle()}
            </SecondaryTitle>
            <CustomTextControl
              disabled
              dark
              value={handleCalculationSavings}
            />
            <SecondaryTitle expenseCalculator>
              {textInputValue ? <Lang id="analyticExpenses.form.savingValue" /> : null}
            </SecondaryTitle>
          </SavingsItemContainer>
        </>
      )
    }
    if (!(min || max || average)) {
      return null
    }
    return (
      <>
        <Title>
          {
            (
              values[DEMONSTRATION] === DEMONSTRATION_GRAPH
              && values[GRAPH_VIEW] === DIFFERENCE_ANALSIS
            )
            || values[DEMONSTRATION] === DEMONSTRATION_TABLE
              ? (<Lang id="analyticExpenses.form.savings" />)
              : (<Lang id="analyticExpenses.form.consumption" />)
          }
        </Title>
        <SavingsItemContainer>
          <SecondaryTitle>
            <Lang id="analyticExpenses.form.min" />
          </SecondaryTitle>
          <SecondaryTitle>
            {numberWithSpaces(min)}
          </SecondaryTitle>
        </SavingsItemContainer>
        <SavingsItemContainer>
          <SecondaryTitle>
            <Lang id="analyticExpenses.form.average" />
          </SecondaryTitle>
          <SecondaryTitle>
            {numberWithSpaces(average)}
          </SecondaryTitle>
        </SavingsItemContainer>
        <SavingsItemContainer>
          <SecondaryTitle>
            <Lang id="analyticExpenses.form.max" />
          </SecondaryTitle>
          <SecondaryTitle>
            {numberWithSpaces(max)}
          </SecondaryTitle>
        </SavingsItemContainer>
      </>
    )
  },
  [
    calculatorValue,
    handleCalculationSavings,
    handleTextInputChange,
    savings,
    submitValues,
    textInputValue,
    isOverValue,
  ])

  const renderGraphField = useCallback((values) => {
    if (values[DEMONSTRATION] === DEMONSTRATION_GRAPH) {
      return (
        <>
          <Title>
            <Lang id="analyticExpenses.form.graphView" />
          </Title>
          <RadioGroupField
            name={GRAPH_VIEW}
            options={graphViewOptions[values[INTEGRATION_TYPES] || OUTDOOR_LIGHTING]}
          />
        </>
      )
    }
    return null
  }, [])

  const renderButtonText = useCallback((values) => {
    if (statusTable === REQUEST_STATUSES.IDLE && values.demonstration === DEMONSTRATION_TABLE) {
      return <Lang id="analyticExpenses.form.updateTable" />
    }
    if (statusGraph === REQUEST_STATUSES.IDLE && values.demonstration === DEMONSTRATION_GRAPH) {
      return <Lang id="analyticExpenses.form.updateGraph" />
    }
    switch (values.demonstration) {
      case DEMONSTRATION_GRAPH: {
        return <Lang id="analyticExpenses.form.buildGraph" />
      }
      case DEMONSTRATION_TABLE: {
        return <Lang id="analyticExpenses.form.buildTable" />
      }
      default: {
        return null
      }
    }
  }, [statusGraph, statusTable])
  const renderButtonIcon = useCallback((values) => {
    if (
      (statusTable === REQUEST_STATUSES.IDLE && values.demonstration === DEMONSTRATION_TABLE)
      || (statusGraph === REQUEST_STATUSES.IDLE && values.demonstration === DEMONSTRATION_GRAPH)
    ) {
      return <CoreIcons.SynchronizationIcon color="white" />
    }
    switch (values.demonstration) {
      case DEMONSTRATION_GRAPH: {
        return <CoreIcons.DiagramIcon color="white" />
      }
      case DEMONSTRATION_TABLE: {
        return <CoreIcons.TableIcon color="white" />
      }
      default: {
        return null
      }
    }
  }, [statusGraph, statusTable])
  const renderButton = useCallback((values, errors) => {
    if (
      (
        values[INTEGRATION_TYPES]
        && values[DATE_FIRST]
        && values[DATE_SECOND]
        && values[DEMONSTRATION] === DEMONSTRATION_TABLE
      )
      || (
        values[INTEGRATION_TYPES]
        && values[DATE_FIRST]
        && values[DATE_SECOND]
        && values[GRAPH_VIEW]
        && values[DEMONSTRATION] === DEMONSTRATION_GRAPH
      )
    ) {
      return (
        <ButtonContainer>
          <Button
            type="submit"
            styleType="primary"
            disabled={errors.badRange}
          >
            <IconDiagramContainer>
              {renderButtonIcon(values)}
            </IconDiagramContainer>
            {renderButtonText(values)}
          </Button>
        </ButtonContainer>
      )
    }
    return null
  }, [renderButtonIcon, renderButtonText])
  const renderError = useCallback((errors) => {
    if (errors.badRange) {
      return (
        <>
          <Title errorTitle>
            <Lang id="analyticExpenses.form.errorTitle" />
          </Title>
          <Title errorText>
            <Lang id="analyticExpenses.form.errorText" />
          </Title>
        </>
      )
    }
    return null
  }, [])

  const handleSetFastInterval = useCallback((formValues) => (value) => {
    setFastIntervalValue(value)
    const todayDate = new Date()
    switch (value) {
      case CURRENT_MONTH: {
        const dateFrom = new Date()
        dateFrom.setDate(dateFrom.getDate() - 30)
        const values = {
          ...formValues,
          [DATE_FIRST]: dateFrom,
          [DATE_SECOND]: todayDate,
          [TYPE]: DAILY,
        }
        formicForm.current.setValues(values)
        setFastIntervalValues(values)
        break
      }
      case PREVIOUS_MONTH: {
        const dateFrom = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1)
        const dateBefore = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)
        const values = {
          ...formValues,
          [DATE_FIRST]: dateFrom,
          [DATE_SECOND]: dateBefore,
          [TYPE]: DAILY,
        }
        formicForm.current.setValues(values)
        setFastIntervalValues(values)
        break
      }
      case QUARTER: {
        const neededQuarter = Math.ceil((todayDate.getMonth() + 1) / 3) - 1
        const values = {
          ...formValues,
          [DATE_FIRST]: null,
          [DATE_SECOND]: null,
          [TYPE]: WEEKLY,
        }
        if (!neededQuarter) {
          const dateFrom = new Date(todayDate.getFullYear() - 1, 9)
          const dateBefore = new Date(todayDate.getFullYear() - 1, 12, 0)
          values[DATE_FIRST] = dateFrom
          values[DATE_SECOND] = dateBefore
        } else {
          const monthBefore = neededQuarter * 3
          const monthFrom = monthBefore - 2
          const dateFrom = new Date(todayDate.getFullYear(), monthFrom - 1)
          const dateBefore = new Date(todayDate.getFullYear(), monthBefore, 0)
          values[DATE_FIRST] = dateFrom
          values[DATE_SECOND] = dateBefore
        }
        formicForm.current.setValues(values)
        setFastIntervalValues(values)
        break
      }
      case YEAR: {
        const dateFrom = new Date()
        dateFrom.setDate(dateFrom.getDate() - 365)
        const values = {
          ...formValues,
          [DATE_FIRST]: dateFrom,
          [DATE_SECOND]: todayDate,
          [TYPE]: MONTHLY,
        }
        formicForm.current.setValues(values)
        setFastIntervalValues(values)
        break
      }
      default: {
        return null
      }
    }
  }, [])

  const getInitialValues = useCallback(() => {
    const integrationType = consumptionForm[INTEGRATION_TYPES] || integrationTypes[0] || null
    return {
      [INTEGRATION_TYPES]: integrationType,
      [TYPE]: consumptionForm[TYPE] || intervalOptions[0].value,
      [DEMONSTRATION]: consumptionForm[DEMONSTRATION] || null,
      [DATE_FIRST]: consumptionForm[DATE_FIRST] || null,
      [DATE_SECOND]: consumptionForm[DATE_SECOND] || null,
      [GRAPH_VIEW]: consumptionForm[GRAPH_VIEW]
        || ((graphViewOptions[integrationType] || [])[0] || {}).value
        || null,
    }
  }, [consumptionForm, integrationTypes, intervalOptions])

  return (
    <Formik
      ref={formicForm}
      validate={validateHandler}
      onSubmit={debounceOnSubmit}
      enableReinitialize
      initialValues={getInitialValues()}
      render={(formProps) => {
        const {
          errors,
          values,
          submitForm,
        } = formProps
        setForm(formProps)
        validateFastInterval(values)
        return (
          <FormContainer>
            {integrationTypes.length > 1 && (
              <>
                <Title>
                  <Lang id="analyticExpenses.form.processType" />
                </Title>
                <SelectField
                  name={INTEGRATION_TYPES}
                  placeholder="Выбрать"
                  options={processOptions}
                />
              </>
            )}
            <Title>
              <Lang id="analyticExpenses.form.fastInterval" />
            </Title>
            <RadioGroup
              name={FAST_INTERVAL}
              options={fastIntervalOptions}
              onChange={handleSetFastInterval(values)}
              value={fastIntervalValue}
            />
            <Title>
              <Lang id="analyticExpenses.form.interval" />
            </Title>
            <SelectField
              name={TYPE}
              placeholder="Выбрать"
              options={intervalOptions}
            />
            <Title>
              <Lang id="analyticExpenses.form.dateRange" />
            </Title>
            <FieldContainer>
              <DatePickerField name={DATE_FIRST} />
              <DecorativeElement />
              <DatePickerField
                name={DATE_SECOND}
                maxDate={endRangeMax}
                minDate={endRangeMin}
              />
            </FieldContainer>
            <Title>
              <Lang id="analyticExpenses.form.demonstration" />
            </Title>
            <RadioGroupField
              name={DEMONSTRATION}
              options={demonstrationOptions}
            />
            {renderGraphField(values)}
            {renderSavings(values)}
            {renderButton(values, errors, submitForm)}
            {renderError(errors)}
          </FormContainer>
        )
      }}
    />
  )
}

AnalyticExpensesForm.propTypes = {
  intervalOptions: pt.arrayOf(pt.object).isRequired,
  getHeatTableData: pt.func,
  getHeatGraphData: pt.func,
  getTableData: pt.func,
  getGraphData: pt.func,
  getPlanGraphData: pt.func,
  setFormValues: pt.func,
  setForm: pt.func,
  calculatorValue: pt.number,
  integrationTypes: pt.arrayOf(pt.string),
  node: pt.shape({
    id: pt.number,
    name: pt.string,
  }),
  savings: pt.shape({
    min: pt.number,
    max: pt.number,
    average: pt.number,
  }),
}

AnalyticExpensesForm.defaultProps = {
  getHeatTableData: noop,
  getHeatGraphData: noop,
  getTableData: noop,
  getGraphData: noop,
  getPlanGraphData: noop,
  setFormValues: noop,
  setForm: noop,
  node: {},
  savings: {},
  calculatorValue: 0,
  integrationTypes: [],
}

export default React.memo(AnalyticExpensesForm)
