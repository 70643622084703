import { REPEAT_INTEGRATION } from './index'

export const requestRepeatIntegration = (payload) => ({
  type: REPEAT_INTEGRATION.REQUEST,
  payload,
})

export const successRepeatIntegration = (data) => ({
  type: REPEAT_INTEGRATION.SUCCESS,
  payload: data,
})

export const errorRepeatIntegration = () => ({
  type: REPEAT_INTEGRATION.ERROR,
})

export const eventsRepeatIntegration = () => ({
  type: REPEAT_INTEGRATION.EVENTS,
})
