import React, {
  useState, useMemo, useEffect,
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PassportImage from '@/components/blocks/PassportImage'
import SelectControl from '@/components/controls/SelectControl'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import Button from '@/components/blocks/Button'
import ObjectCreateForm from '@/forms/ObjectCreateForm'
import cardAndPassportByType from '@/constants/instalationPassport'
import generateValidationSchemeForObject from '@/helpers/generateValidationSchemeForObject'
import Loader from '@/components/blocks/Loader'
import CoreIcons from '@/components/icons/core'
import { REQUEST_STATUSES } from '@/constants/requests'
import { TITLE_TO_STATUS } from '@/constants/maps'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks'
import {
  CARD,
  PASSPORT,
} from '@/constants/passportization'
import { handlePhotoConvertToBase64 } from '../../utils'

import {
  ContentWrapper,
  Container,
  Header,
  HeaderTitle,
  StyledButton,
  InputAndLabelContainer,
  ElementContainer,
  SecondaryTitle,
  LoaderContainer,
  StatusContainer,
} from './styles'

const PassportAndCard = React.forwardRef(({
  data,
  title,
  ghostMode,
  submit,
  setUrlFormValues,
  submitError,
  requestGetObjectTypes,
  objectTypes,
  onCancel,
  edit,
  validationScheme,
  requestGetObjectValidationScheme,
  requestGetScheme,
  changeCreateObjectType,
  getInstallationObjectPDF,
  formValues,
  intl,
  objectNewPoint,
  setObjectNewPoint,
  objectTypesRequestStatus,
  setAlertConfig,
  setNewPointsHash,
}, ref) => {
  const localObjectElement = useMemo(() => {
    if (objectTypesRequestStatus !== REQUEST_STATUSES.PENDING && objectTypes.length === 0 && data.objectType && edit) {
      return [{
        value: data.objectType,
        title: <Lang id={`globalNames.objectTypes.${data.objectType}`} />,
      }]
    }
    return objectTypes
  }, [objectTypes, objectTypesRequestStatus, data, edit])

  const [uploadImage, setUploadImage] = useState()
  useEffect(() => {
    handlePhotoConvertToBase64(get(data, 'ATTACHMENT.PHOTO_TITLE', null), setUploadImage)
  }, [data])
  useEffect(() => {
    if (data.integrationType) {
      requestGetObjectTypes({
        type: edit
          ? data.parentObjectType
          : data.objectType,
        integrationType: data.integrationType,
      })
    }
  }, [
    requestGetObjectTypes,
    data.integrationType,
    data.objectType,
    data.parentObjectType,
    edit,
  ])

  const [isValid, setIsValid] = useState(false)
  const [validateResult, setValidateResult] = useState(false)
  const [tabType, setTabType] = useState(PASSPORT)
  const [equipmentType, setEquipmentType] = useState(null)

  const setEquipmentTypeHandler = (type) => {
    changeCreateObjectType(type)
    setEquipmentType(type)
  }

  const objectType = useMemo(() => (edit
    ? data.objectType : equipmentType), [edit, data.objectType, equipmentType])

  useEffect(() => {
    const setFieldValue = get(ref, 'current.setFieldValue', noop)
    setFieldValue('GENERAL_INFORMATION.EQUIPMENT_TYPE', intl.messages[`globalNames.objectTypes.${objectType}`])
  }, [ref, objectType, data, intl])

  const generatedValidationScheme = useMemo(() => {
    if (isEmpty(validationScheme)) {
      return {}
    }
    return generateValidationSchemeForObject(
      validationScheme,
      [
        ...get(cardAndPassportByType, `${data.integrationType}.card.${objectType}`, []),
        ...get(cardAndPassportByType, `${data.integrationType}.passport.${objectType}`, []),
      ],
    )
  }, [validationScheme, objectType, data])

  useEffect(() => {
    const values = get(ref, 'current.state.values', {})
    const { validate } = generatedValidationScheme
    if (validate) {
      generatedValidationScheme.validate(values)
        .then(() => {
          setValidateResult(true)
        })
        .catch(() => {
          setValidateResult(false)
        })
    }
  }, [generatedValidationScheme, setValidateResult, ref])

  useEffect(() => {
    if (data.integrationType && objectType) {
      requestGetObjectValidationScheme({
        type: objectType,
        integrationType: data.integrationType,
      })
    }
  }, [requestGetObjectValidationScheme, data.integrationType, objectType])

  const formConfig = useMemo(() => {
    if (tabType === CARD) {
      return get(cardAndPassportByType, `${data.integrationType}.card.${objectType}`, [])
    }
    return get(cardAndPassportByType, `${data.integrationType}.passport.${objectType}`, [])
  }, [tabType, data.integrationType, objectType])

  const setTabTypeHandler = (type) => () => { setTabType(type) }

  const isVisibleForm = useMemo(() => !isEmpty(validationScheme) && localObjectElement.length !== 0
  && (edit ? data.objectType : equipmentType),
  [equipmentType, validationScheme, localObjectElement, data, edit])

  const debounceSubmitHandler = () => {
    submit({
      formValues,
      values: get(ref, 'current.state.values', {}),
      setSubmitting: get(ref, 'current.setSubmitting', noop),
      setUrlFormValues,
      setErrors: get(ref, 'current.setErrors', null),
      type: objectType,
      setAlertConfig,
      intl,
    })
  }
  const renderState = () => (
    <>
      <ElementContainer bw={100} bh={28}>
        <StatusContainer>
          <SecondaryTitle>
            Статус:
          </SecondaryTitle>
          <SecondaryTitle status fw color={STATUS_COLORS[data.objectState]}>
            {TITLE_TO_STATUS[data.objectState]}
          </SecondaryTitle>
        </StatusContainer>
      </ElementContainer>
      <ElementContainer bb bw={80} bh={38} bs>
        <SecondaryTitle fw>
          Наряд на монтаж и пуско-наладку объекта
        </SecondaryTitle>
        <Button styleType="ghost" onClick={getInstallationObjectPDF}>
          <CoreIcons.ScrewdriverIcon />
          Сформировать документ
        </Button>
      </ElementContainer>
    </>
  )
  return (
    <Container>
      <Header>
        <HeaderTitle>
          {title}
        </HeaderTitle>
        {!ghostMode && (
          <>
            <StyledButton
              type="green"
              onClick={isValid && objectType && validateResult
                ? debounceSubmitHandler
                : submitError}
            >
              <Lang id="installation.createGeoZoneForm.save" />
            </StyledButton>
            <StyledButton type="red" onClick={onCancel}>
              <Lang id="installation.createGeoZoneForm.cancel" />
            </StyledButton>
          </>
        )}
      </Header>
      <ContentWrapper ghostMode={ghostMode}>
        {((edit && (localObjectElement.length === 0 || !data.objectType))
          || (localObjectElement.length === 0))
          ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )
          : (
            <InputAndLabelContainer select={equipmentType}>
              <LabelWithIcon
                title="Тип оборудования*"
              />
              <SelectControl
                placeholder="Выберите оборудование"
                options={localObjectElement}
                value={edit ? data.objectType : equipmentType}
                disabled={edit}
                withSearch
                onChange={setEquipmentTypeHandler}
                light
              />
            </InputAndLabelContainer>
          )}
        {isVisibleForm && (
          <>
            <PassportImage
              setTabType={setTabTypeHandler}
              tabType={tabType}
              uploadedImage={uploadImage}
              withSearch
            />
            {edit && formConfig.length !== 0 && renderState()}
          </>
        )}
        <ObjectCreateForm
          isVisibleForm={isVisibleForm}
          ref={ref}
          options={formConfig}
          data={data}
          setImage={setUploadImage}
          edit={edit}
          setIsValid={setIsValid}
          validationScheme={generatedValidationScheme}
          objectNewPoint={objectNewPoint}
          setObjectNewPoint={setObjectNewPoint}
          objectTypesRequestStatus={objectTypesRequestStatus}
          setNewPointsHash={setNewPointsHash}
        />
      </ContentWrapper>
    </Container>
  )
})

PassportAndCard.propTypes = {
  data: pt.objectOf(pt.any),
  ghostMode: pt.bool,
  edit: pt.bool,
  title: pt.string,
  submit: pt.func,
  onCancel: pt.func,
  requestGetScheme: pt.func,
  setUrlFormValues: pt.func,
  submitError: pt.func,
  requestGetObjectTypes: pt.func,
  changeCreateObjectType: pt.func,
  getInstallationObjectPDF: pt.func,
  objectTypes: pt.arrayOf(pt.shape({
    value: pt.oneOfType(pt.string, pt.number),
    title: pt.string,
  })),
  requestGetObjectValidationScheme: pt.func,
  setAlertConfig: pt.func,
  validationScheme: pt.objectOf(pt.object),
  formValues: pt.objectOf(pt.string),
  objectNewPoint: pt.objectOf(pt.object),
  setObjectNewPoint: pt.func,
  objectTypesRequestStatus: pt.objectOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
}

PassportAndCard.defaultProps = {
  data: {},
  ghostMode: true,
  edit: false,
  title: '',
  submit: noop,
  setUrlFormValues: noop,
  submitError: noop,
  requestGetObjectTypes: noop,
  onCancel: noop,
  requestGetScheme: noop,
  requestGetObjectValidationScheme: noop,
  changeCreateObjectType: noop,
  getInstallationObjectPDF: noop,
  validationScheme: {},
  formValues: {},
  objectTypes: [],
  objectNewPoint: [],
  setObjectNewPoint: noop,
  objectTypesRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  setAlertConfig: noop,
}

export default PassportAndCard
