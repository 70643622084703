export const NAME = 'name'
export const PARENT = 'parentId'
export const MARK = 'mark'
export const CUSTOM_FIELD = 'customFields'

export default {
  NAME,
  PARENT,
  CUSTOM_FIELD,
  MARK,
}
