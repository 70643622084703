import { GET_SETTINGS } from '../index'

export const requestGetSettings = () => ({
  type: GET_SETTINGS.REQUEST,
})

export const successGetSettings = (payload) => ({
  type: GET_SETTINGS.SUCCESS,
  payload,
})

export const errorGetSettings = (error) => ({
  type: GET_SETTINGS.ERROR,
  payload: error,
})
