import {
  GEOZONE,
  PROJECT,
  ROOT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import MapsIcon from '@/components/icons/maps'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import setSixDigitsAfterDot from '@/helpers/setSixDigitsAfterDot'

export default {
  [GEOZONE]: {
    options: {
      edit: true,
      remove: true,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertGeoZoneIcon,
        title: 'Геозоны',
        selector: GEOZONE,
      },
      {
        icon: MapsIcon.InvertProjectIcon,
        title: 'Проекты',
        selector: PROJECT,
      },
      {
        icon: MapsIcon.InvertProjectIcon,
        title: 'Интеграции',
        selector: INTEGRATION,
      },
      {
        icon: MapsIcon.InvertMarkIcon,
        title: 'Объекты',
        selector: OBJECT_ELEMENT,
      },
    ],
    fields: [
      {
        selector: 'parentGeoZoneName',
      },
      {
        selector: 'geoLocationDto.latitude',
        formatter: setSixDigitsAfterDot,
      },
      {
        selector: 'geoLocationDto.longitude',
        formatter: setSixDigitsAfterDot,
      },
      {
        selector: CREATE_REGION_NAMES.REGION,
      },
      {
        selector: CREATE_REGION_NAMES.CITY,
      },
      {
        selector: 'note',
      },
    ]
  },
  [ROOT]: {
    options: {
      edit: false,
      remove: false,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertGeoZoneIcon,
        title: 'Геозоны',
        selector: GEOZONE,
      },
      {
        icon: MapsIcon.InvertProjectIcon,
        title: 'Проекты',
        selector: PROJECT,
      },
      {
        icon: MapsIcon.InvertProjectIcon,
        title: 'Интеграции',
        selector: INTEGRATION,
      },
      {
        icon: MapsIcon.InvertMarkIcon,
        title: 'Объекты',
        selector: OBJECT_ELEMENT,
      },
    ],
  },
  [PROJECT]: {
    options: {
      edit: true,
      remove: true,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertProjectIcon,
        title: 'Интеграции',
        selector: INTEGRATION,
      },
      {
        icon: MapsIcon.InvertMarkIcon,
        title: 'Объекты',
        selector: OBJECT_ELEMENT,
      },
    ],
    fields: [
      {
        selector: 'parentGeoZoneName',
      },
      {
        selector: 'customFields',
      },
      {
        selector: 'note',
      },
    ],
  },
  [INTEGRATION]: {
    options: {
      edit: true,
      remove: true,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertMarkIcon,
        title: 'Объекты',
        selector: OBJECT_ELEMENT,
      },
    ],
    fields: [
      {
        selector: 'parentProjectName',
      },
      {
        selector: 'integrationType',
      },
    ],
  },
  [OBJECT_ELEMENT]: {
    options: {
      edit: true,
      remove: true,
    },
  },
}
