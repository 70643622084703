import React from 'react'
import pt from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage as Lang } from 'react-intl'
import { SIGN_IN_URL } from '@/constants/routes'
import TabsController from '@/components/blocks/TabsController'
import AcceptConditionForm from '@/forms/AcceptConditionForm'
import SignUpForm from '@/forms/SignUpForm'
import CoreIcons from '@/components/icons/core'
import {
  IDLE,
} from '@/constants/requests'
import {
  RU_LANGUAGE,
  EN_LANGUAGE,
} from '@/constants/names'
import {
  SignUpStyleWrapper,
  FormWrapper,
  Title,
  Text,
  StyledButton,
  SubmittedText,
  SubmittedButton,
} from './styles'

class SignUp extends React.Component {
  state = {
    tabs: [
      {
        title: 'Русский',
        value: RU_LANGUAGE,
      },
      {
        title: 'English',
        value: EN_LANGUAGE,
      },
    ],
    accepted: false,
    formData: null,
    requirements: false,
  };

  handleChangeTab = (value) => {
    const { onSetLanguage } = this.props
    onSetLanguage(value)
  }

  handleAccept = () => {
    this.setState((state) => ({ accepted: !state.accepted }))
  }

  handleRequirements = () => {
    this.setState((state) => ({ requirements: !state.requirements }))
  }

  handleSetFormData = (formData) => {
    this.setState({ formData })
  }

  renderForm = () => {
    const { accepted, formData } = this.state
    const { onSetUsersPassword, setPasswordStatus } = this.props
    if (setPasswordStatus === IDLE) {
      return (
        <>
          <SubmittedText>
            <Lang id="signUp.submittedText" />
          </SubmittedText>
          <Link to={SIGN_IN_URL}>
            <SubmittedButton styleType="text" type="button">
              <Lang id="signUp.goToAuthorization" />
            </SubmittedButton>
          </Link>
        </>
      )
    }
    if (accepted) {
      return (
        <SignUpForm
          handleRequirements={this.handleRequirements}
          onSetUsersPassword={onSetUsersPassword}
          initialValues={formData}
          handleSetFormData={this.handleSetFormData}
        />
      )
    }
    return (
      <AcceptConditionForm
        handleAccept={this.handleAccept}
      />
    )
  }

  renderContainer = () => {
    const { tabs, requirements, accepted } = this.state
    const { language } = this.props
    const disabledSlsLogo = process.env.REACT_APP_DISABLE_SLS_LOGO === 'true'
    if (requirements) {
      return (
        <FormWrapper requirements>
          <Title>
            <Lang id="signUp.requirements" />
          </Title>
          <Text>
            <Lang id="signUp.requirementsText" />
          </Text>
          <StyledButton onClick={this.handleRequirements}>
            <Lang id="signUp.accept" />
          </StyledButton>
        </FormWrapper>
      )
    }
    return (
      <FormWrapper accepted={accepted}>
        {disabledSlsLogo ? (<CoreIcons.BLGLogoIcon />) : (<CoreIcons.LogoIcon />)}
        <TabsController
          nameStyles="fullWidth"
          activeTab={language}
          tabs={tabs}
          onTabChange={this.handleChangeTab}
          withUrlChange={false}
        />
        {this.renderForm()}
      </FormWrapper>
    )
  }

  render() {
    return (
      <SignUpStyleWrapper>
        {this.renderContainer()}
      </SignUpStyleWrapper>
    )
  }
}

SignUp.propTypes = {
  language: pt.string.isRequired,
  mockLoginError: pt.string.isRequired,
  onSetLanguage: pt.func.isRequired,
  onMockUserLogin: pt.func.isRequired,
}

export default SignUp
