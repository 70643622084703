import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
          background: ${theme.colors.backgrounds.redisign.default};
        `
    }
  }}
`

export const WidgetContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  max-width: 1155px;
`
