import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const UnknownIcon = ({ onClick }) => (
  <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM10.1043 9.5342C9.25527 10.1172 9.13847 10.6515 9.13847 11.1418C9.13847 11.3407 9.0612 11.5315 8.92373 11.6723C8.7862 11.8129 8.59967 11.892 8.4052 11.892C8.21073 11.892 8.02427 11.8129 7.88673 11.6723C7.7492 11.5315 7.672 11.3407 7.672 11.1418C7.672 9.96767 8.19993 9.0342 9.28613 8.28773C10.2959 7.59433 10.8669 7.15493 10.8669 6.18879C10.8669 5.53181 10.5002 5.03293 9.74133 4.66371C9.56273 4.57691 9.1652 4.49224 8.676 4.49813C8.06213 4.50617 7.58553 4.65621 7.2184 4.95844C6.526 5.5286 6.46733 6.14913 6.46733 6.15824C6.46273 6.25676 6.43913 6.3534 6.398 6.4426C6.35687 6.5318 6.29896 6.6118 6.22759 6.67813C6.15621 6.7444 6.07278 6.79573 5.98204 6.829C5.8913 6.86233 5.79504 6.877 5.69875 6.87227C5.60245 6.86753 5.50802 6.8434 5.42083 6.80133C5.33365 6.75927 5.25542 6.7 5.19062 6.627C5.12581 6.55393 5.0757 6.4686 5.04314 6.37573C5.01058 6.28291 4.99621 6.18442 5.00085 6.0859C5.00661 5.95569 5.09513 4.78268 6.29818 3.7924C6.92193 3.27905 7.71547 3.01219 8.65507 3.00039C9.3202 2.99235 9.94507 3.10757 10.3687 3.31227C11.6367 3.92583 12.3333 4.94879 12.3333 6.18879C12.3333 8.00114 11.1497 8.81515 10.1051 9.53359L10.1043 9.5342ZM8.35287 15C8.14567 15 7.94313 14.9371 7.77087 14.8194C7.5986 14.7016 7.46433 14.5343 7.38507 14.3384C7.3058 14.1426 7.28507 13.9271 7.32547 13.7192C7.36587 13.5113 7.46567 13.3203 7.61213 13.1705C7.75867 13.0205 7.94527 12.9185 8.14847 12.8771C8.35167 12.8358 8.56227 12.857 8.75373 12.9381C8.94513 13.0193 9.10873 13.1566 9.2238 13.3329C9.33893 13.5091 9.40033 13.7163 9.40033 13.9283C9.40033 14.2125 9.29 14.4851 9.09353 14.6861C8.89707 14.8871 8.63067 15 8.35287 15Z" fill="#7C7C7C" />
  </svg>
)

UnknownIcon.propTypes = {
  onClick: pt.func,
}

UnknownIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(UnknownIcon)
