import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsIcons from '@/components/icons/widgets'
import widgetConfig from '@/constants/tyumenOurHomeProccesses'
import {
  Title,
  WidgetContainer,
} from './styles'

const TyumenOurHome = ({
  onSelectSettings,
  processes,
}) => {
  const handleSelect = useCallback(
    (element) => () => onSelectSettings({ ...element }),
    [onSelectSettings],
  )

  return (
    <>
      <Title main>
        <Lang id="sideBar.urbanProcess" />
      </Title>
      {widgetConfig.map((element) => (
        <WidgetContainer key={element.id} onClick={handleSelect(element)}>
          <WidgetsIcons.CityProcessIcon />
          <Title widget>{element.title}</Title>
        </WidgetContainer>
      ))}
    </>
  )
}

TyumenOurHome.propTypes = {
  onSelectSettings: pt.func,
}
TyumenOurHome.defaultProps = {
  onSelectSettings: noop,
}

export default memoWithName(TyumenOurHome)
