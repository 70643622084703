import React, { useCallback, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash'
import widgetsByTypes from '@/constants/telemetries'
import { MARKS_COLORS } from '@/constants/styles/mapsMarks'
import { OK, INFO } from '@/constants/objectStatuses'
import {
  IndicatorsBlock,
  IndicatorsWrapper,
  StateIndicatorStyled,
  StatusIndicatorStyled,
  WorkTimeIndicatorStyled,
  TrashIndicatorStyled,
  SmallWidgetIndicatorStyled,
  LightIndicatorStyled,
} from './styles'

const ObjectState = ({
  type,
  system,
  status,
  telemetry,
  settings,
  refreshIntegration,
  updateWidgetSettings,
  ...props
}) => {
  const widgets = useMemo(() => {
    if (system) {
      return widgetsByTypes[system][type] || []
    }
    return []
  }, [type, system])
  const handleUpdateWidgetSettings = useCallback((id) => (values) => {
    updateWidgetSettings(id, values)
  }, [updateWidgetSettings])

  const getWidgetByType = useCallback(
    (indicator) => {
      const key = indicator.id || indicator.type
      const settingsId = `${indicator.id}-${props.id}`
      switch (indicator.type) {
        case 'status': {
          return (
            <StatusIndicatorStyled
              key={key}
              {...indicator}
              refreshIntegration={refreshIntegration}
              color={MARKS_COLORS[status]}
              status={status}
              modeStatus={telemetry.turnedOn}
              timestamp={indicator.timeSelector ? telemetry[indicator.timeSelector] : 0}
              {...props}
            />
          )
        }
        case 'trash': {
          return (
            <TrashIndicatorStyled
              key={key}
              telemetry={telemetry}
              {...indicator}
            />
          )
        }
        case 'bigLight': {
          return (
            <LightIndicatorStyled
              key={key}
              value={telemetry.poleAngleDrift || {}}
              valueHeight={telemetry.lanternHeight || 0}
              {...indicator}
            />
          )
        }
        case 'smallWidget': {
          return (
            <SmallWidgetIndicatorStyled
              key={key}
              value={
                (indicator.valueSelector ? telemetry[indicator.valueSelector] : 0)
                || indicator.mockValue
              }
              {...indicator}
            />
          )
        }
        case 'workTime': {
          return (
            <WorkTimeIndicatorStyled
              key={key}
              {...indicator}
              color={telemetry.turnedOn
                ? MARKS_COLORS[OK]
                : MARKS_COLORS[INFO]}
              online={telemetry.turnedOn}
              status={status === 'NO_PROBLEM'}
              workTime={indicator.workTimeSelector ? telemetry[indicator.workTimeSelector] : 0}
            />
          )
        }
        default: {
          return (
            <StateIndicatorStyled
              key={key}
              {...indicator}
              color={MARKS_COLORS[status]}
              status={telemetry.state}
              settings={settings[settingsId]}
              onUpdateSettings={handleUpdateWidgetSettings(settingsId)}
              value={
                (indicator.valueSelector ? telemetry[indicator.valueSelector] : 0)
                || indicator.mockValue
              }
              data={indicator.data && indicator.data.reduce((array, row) => {
                const selectedValue = telemetry[row.selector]
                const value = selectedValue ? selectedValue.toFixed(3) : null
                if (selectedValue) {
                  return [
                    ...array,
                    {
                      title: row.title,
                      value,
                      type: row.type,
                    },
                  ]
                }
                return array
              }, [])}
            />
          )
        }
      }
    },
    [handleUpdateWidgetSettings, props, settings, status, telemetry, refreshIntegration],
  )

  return (
    <IndicatorsBlock>
      <IndicatorsWrapper>
        {widgets.map(getWidgetByType)}
      </IndicatorsWrapper>
    </IndicatorsBlock>
  )
}

ObjectState.propTypes = {
  id: pt.oneOfType([pt.string, pt.number]),
  type: pt.string,
  status: pt.string,
  system: pt.string,
  refreshIntegration: pt.func,
  telemetry: pt.shape({
    timestamp: pt.string,
    state: pt.string,
  }),
  settings: pt.shape({
    [pt.string]: pt.object,
  }),
  updateWidgetSettings: pt.func.isRequired,
}
ObjectState.defaultProps = {
  id: null,
  type: '',
  status: '',
  system: '',
  telemetry: {},
  settings: {},
  refreshIntegration: noop,
}

export default ObjectState
