export const SOCKET_CONNECT = '@/SOCKET_CONNECT'
export const SET_SOCKET_CONNECTION = '@/SET_SOCKET_CONNECTION'
export const SET_SOCKET_SUBSCRIPTIONS = '@/SET_SOCKET_SUBSCRIPTIONS'

export const ON_SOCKET_CONNECTED = '@/ON_SOCKET_CONNECTED'

export const connectToSocket = (data = {}) => ({
  type: SOCKET_CONNECT,
  payload: data,
})

export const setSocketConnection = (client) => ({
  type: SET_SOCKET_CONNECTION,
  payload: { client },
})

export const setSocketSubscriptions = (subscriptions) => ({
  type: SET_SOCKET_SUBSCRIPTIONS,
  payload: { subscriptions },
})

export const onSocketConnected = () => ({
  type: ON_SOCKET_CONNECTED,
})
