export const RECEIVED = 'received'
export const DONE = 'done'
export const TASKS_PERFORMED = 'tasksPerformed'
export const WAITING_FOR_CONTROL = 'waitingForControl'
export const OVERDUE_ON_EXECUTION = 'overdueOnExecution'
export const AVERAGE_LEAD_TIME = 'averageLeadTime'
export const DELAYED = 'delayed'

export const CURRENT_MONTH = 'currentMonth'
export const ALL_TIME = 'allTime'

export const STATUSES = {
  [RECEIVED]: '',
  [DONE]: 'completed',
  [TASKS_PERFORMED]: 'inProgress',
  [WAITING_FOR_CONTROL]: 'waitingControl',
}
