import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ChangeLogIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M42.1724 0C42.5659 0 42.943 0.157716 43.2193 0.437874L49.5764 6.88262C50.1412 7.45525 50.1412 8.37542 49.5764 8.94805L27.9864 30.8359C27.7029 31.1233 27.3138 31.2815 26.9102 31.2735L20.7931 31.1519C19.9924 31.136 19.3517 30.4824 19.3517 29.6816V23.1153C19.3517 22.7287 19.5039 22.3577 19.7753 22.0825L41.1254 0.437874C41.4018 0.157716 41.7789 0 42.1724 0ZM22.2929 23.7185V28.24L26.3365 28.3204L41.1172 13.3357L36.7849 9.02648L22.2929 23.7185ZM38.8504 6.93253L43.1827 11.2417L46.4638 7.91534L42.1724 3.56471L38.8504 6.93253ZM0 14.7248C0 9.18931 4.35775 4.74226 9.8667 4.74226H21.8611C22.6733 4.74226 23.3317 5.40067 23.3317 6.21285C23.3317 7.02503 22.6733 7.68344 21.8611 7.68344H9.8667C6.01998 7.68344 2.94118 10.7755 2.94118 14.7248V40.0175C2.94118 43.9667 6.01998 47.0588 9.8667 47.0588H34.8151C38.6618 47.0588 41.7407 43.9667 41.7407 40.0175V29.0735C41.7407 28.2613 42.3991 27.6029 43.2112 27.6029C44.0234 27.6029 44.6818 28.2613 44.6818 29.0735V40.0175C44.6818 45.553 40.3241 50 34.8151 50H9.8667C4.35775 50 0 45.553 0 40.0175V14.7248Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ChangeLogIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ChangeLogIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ChangeLogIcon)
