import widgets from '../widgets'

const {
  LIGHT_STATUS,
  DATA_TIME,
  WATER_TEMPERATURE,
  WATER_MASS_FLOW,
  WATER_VOLUMETRIC_FLOW,
  WATER_VOLUMETRIC_CONSUMED_FLOW,
} = widgets

export default [
  LIGHT_STATUS({}),
  DATA_TIME({}),
  WATER_TEMPERATURE({}),
  WATER_MASS_FLOW({}),
  WATER_VOLUMETRIC_FLOW({}),
  WATER_VOLUMETRIC_CONSUMED_FLOW({}),
]
