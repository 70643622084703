import { DELETE_REPORT_MANAGER } from './index'

export const requestDeleteReport = (payload) => ({
  type: DELETE_REPORT_MANAGER.REQUEST,
  payload,
})

export const successDeleteReport = (payload) => ({
  type: DELETE_REPORT_MANAGER.SUCCESS,
  payload,
})

export const errorDeleteReport = (payload) => ({
  type: DELETE_REPORT_MANAGER.ERROR,
  payload,
})
