import { handleActions } from 'redux-actions'
import { GET_OBJECT_IMAGE } from '@/store/actions/maps'

const initialState = {
  url: null,
  loading: false,
}

export const reducer = handleActions(
  {
    [GET_OBJECT_IMAGE.REQUEST]: (state) => ({
      ...state,
      url: null,
      loading: true,
    }),
    [GET_OBJECT_IMAGE.SUCCESS]: (state, { payload }) => ({
      ...state,
      url: payload,
      loading: false,
    }),
    [GET_OBJECT_IMAGE.ERROR]: (state) => ({
      ...state,
      url: null,
      loading: false,
    }),
  },
  initialState,
)

export default reducer
