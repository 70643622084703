import styled from 'styled-components'
import { Form } from 'formik'
import {
  Label as FieldLabel,
  TextFieldStyled,
} from '@/components/controls/TextControl/styles'
import {
  Label as AreaLabel,
  TextFieldStyled as AreaTextFieldStyled,
} from '@/components/controls/TextAreaControl/styles'

export const FormWrapper = styled(Form)`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 48px);
  padding: 15px 10px;
`
export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${FieldLabel}, ${AreaLabel} {
    color: ${({ theme }) => theme.colors.colors.default};
  }

  ${TextFieldStyled}, ${AreaTextFieldStyled} {
    margin-bottom: 10px;
  }
`

export const InputAndLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 100%;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  
`
export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.option};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
`

export const ButtonContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 3px solid #FFFFFF;
`

export const MessageContainer = styled.div`
  padding-bottom: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.colors.subTitle};
`

export const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  margin-top: 5px;
  word-break: break-all;
  text-align: start;
`
