import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 9000;
  filter: grayscale(.75), blur(3px), brightness(40%);
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`