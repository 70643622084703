import React, { useCallback } from 'react'
import pt from 'prop-types'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import TextField from '@/components/fields/TextField'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import CheckBoxField from '@/components/fields/CheckBoxField'
import {
  addGroupValidationScheme,
} from '@/constants/validationFields'
import {
  NAME,
  PHONE_NUMBER,
  EMAIL,
  SUB_END_DATA,
  BLOCK_ORGANIZATION,
} from '@/constants/forms/group'
import {
  FormWrapper,
  FieldsWrapper,
  InputAndLabelContainer,
  Label,
  Text,
} from './styles'

const TenantForm = ({
  saveFormProps,
  initialValues,
  onSubmitForm,
  onNameChange,
  edit,
  disabled,
  isFormModifying,
}) => {
  const onSubmit = (values, form) => {
    // TODO: add handlers
    onSubmitForm()
  }
  const renderTextFiled = useCallback(({
    error,
    name,
    mask,
    fieldProps,
    value,
  }) => {
    const regex = /(\d?)(\d{3})(\d{3})(\d{2})(\d{2})/g
    const phoneExample = '$1 ($2) $3-$4-$5'
    if (isFormModifying) {
      return (
        <TextField
          error={error}
          name={name}
          mask={mask}
          fieldProps={fieldProps}
          resetButton
        />
      )
    }
    if (name === PHONE_NUMBER && value) {
      return (
        <Text>
          {value.replace(regex, phoneExample)}
        </Text>
      )
    }
    return (
      <Text>
        {value}
      </Text>
    )
  }, [isFormModifying])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addGroupValidationScheme}
      isInitialValid={addGroupValidationScheme.isValidSync(initialValues)}
      onSubmit={onSubmit}
      enableReinitialize
      render={(formProps) => {
        const {
          touched, errors, handleSubmit, isSubmitting, values,
        } = formProps
        saveFormProps(formProps)
        return (
          <FormWrapper onSubmit={handleSubmit}>
            <FieldsWrapper>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[NAME] && errors[NAME])}
                  title={<Lang id="usersPage.group.name" />}
                  userForm
                />
                {renderTextFiled({
                  error: (touched[NAME] && errors[NAME]),
                  name: NAME,
                  fieldProps: {
                    autoComplete: 'off',
                    disabled: isSubmitting || disabled,
                  },
                  value: values[NAME],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[PHONE_NUMBER] && errors[PHONE_NUMBER])}
                  title={<Lang id="usersPage.group.numbers" />}
                  userForm
                />
                {renderTextFiled({
                  error: (touched[PHONE_NUMBER] && errors[PHONE_NUMBER]),
                  name: PHONE_NUMBER,
                  mask: '7-999-999-99-99',
                  fieldProps: {
                    pattern: '[0-9]',
                    autoComplete: 'off',
                    disabled: isSubmitting || disabled,
                  },
                  value: values[PHONE_NUMBER],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[EMAIL] && errors[EMAIL])}
                  title={<Lang id="usersPage.group.email" />}
                  userForm
                />
                {renderTextFiled({
                  error: (touched[EMAIL] && errors[EMAIL]),
                  name: EMAIL,
                  fieldProps: {
                    autoComplete: 'off',
                    disabled: isSubmitting || disabled,
                  },
                  value: values[EMAIL],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[SUB_END_DATA] && errors[SUB_END_DATA])}
                  title={<Lang id="usersPage.group.subscriptionEnd" />}
                  userForm
                />
                {renderTextFiled({
                  error: (touched[SUB_END_DATA] && errors[SUB_END_DATA]),
                  name: SUB_END_DATA,
                  mask: '99.99.9999',
                  fieldProps: {
                    autoComplete: 'off',
                    disabled: isSubmitting || disabled,
                  },
                  value: values[SUB_END_DATA],
                })}
              </InputAndLabelContainer>
              {edit && isFormModifying && (
                <InputAndLabelContainer row>
                  <CheckBoxField name={BLOCK_ORGANIZATION} marginRight />
                  <Label>
                    <Lang id="usersPage.group.blockOrganization" />
                  </Label>
                </InputAndLabelContainer>
              )}
            </FieldsWrapper>
          </FormWrapper>
        )
      }}
    />
  )
}

TenantForm.propTypes = {
  initialValues: pt.shape({
    [NAME]: pt.string,
    [PHONE_NUMBER]: pt.string,
    [EMAIL]: pt.string,
    [SUB_END_DATA]: pt.string,
  }),
}
TenantForm.defaultProps = {
  initialValues: {},
}

export default TenantForm
