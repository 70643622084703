import styled from 'styled-components'

export const Input = styled.input`
  cursor: pointer;
  appearance: none;
  width: 100%;
  outline: none;
  height: 6px;
  border-radius: 5px;
  margin-top: auto;

  &::-moz-focus-inner {
    border: 0;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.colors.light};
    box-shadow: 0px 4px 4px rgba(38, 45, 65, 0.25);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  &::-moz-range-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.colors.light};
    box-shadow: 0px 4px 4px rgba(38, 45, 65, 0.25);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  ${({ isEmpty, alarm, theme }) => {
    if (!isEmpty) {
      if (alarm) {
        return `background: ${theme.gradients.alarm};`
      }
      return `background: ${theme.gradients.normal};`
    }

    return `background: ${theme.colors.borders.default};`
  }}
`

export const ValueIcon = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 45px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  background: ${({ theme }) => theme.colors.colors.default};
  color: ${({ theme }) => theme.colors.borders.default};
  padding: 2px;
  border-radius: 2px;
  transition: display 200ms ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -2.5px;
    width: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.colors.default};
    transform: rotate(45deg);
  }
`

export const ValueSelectStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 40px;

  &:hover {
    ${ValueIcon} {
      display: block;
    }
  }
`
