import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AlphabetIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6192 8.63372C15.44 8.36628 15.216 8.19476 14.9472 8.11915C14.3898 7.95707 13.7675 7.96807 13.392 8.33717C12.9724 8.8422 12.9618 9.59906 13.392 10.0378C14.0482 10.6005 15.2072 10.3224 15.6192 9.76741V8.63372ZM17.0976 4.11628C17.6992 4.61628 18 5.32558 18 6.24419V11.5465C17.9902 11.693 17.8578 11.8062 17.712 11.8081H15.9073C15.746 11.7993 15.6213 11.679 15.6193 11.5465V11.3895C15.0168 11.8049 14.3196 11.9962 13.6225 12C12.8314 11.979 12.1437 11.7247 11.6065 11.2762C10.9451 10.6748 10.6902 9.93544 10.685 9.17442C10.711 8.34179 10.9757 7.59242 11.6161 7.05525C12.2069 6.60125 12.9057 6.38792 13.6225 6.38374C14.4161 6.38374 15.0817 6.57559 15.6193 6.95932V6.4535C15.6193 5.65117 15.1585 5.25002 14.2369 5.25002C13.5201 5.25002 12.8673 5.52327 12.2785 6.06978C12.1222 6.19546 11.9018 6.14617 11.8177 6.00001L11.1074 4.88373C11.0433 4.76746 11.0626 4.6628 11.165 4.56979C11.6002 4.17443 12.1057 3.87502 12.6817 3.67153C14.1091 3.22164 16.0142 3.24644 17.0976 4.11628ZM5.2898 3.24419L4.02261 7.36046H6.57617L5.2898 3.24419ZM10.5697 11.3546C10.5953 11.4244 10.6081 11.4709 10.6081 11.4942C10.6027 11.6493 10.4717 11.754 10.3201 11.7558H8.05457C7.91376 11.7558 7.82418 11.6918 7.78577 11.5639L7.1522 9.54066H3.44663L2.81304 11.5639C2.77464 11.6918 2.67864 11.7558 2.52504 11.7558H0.297874C0.0584889 11.7377 -0.0320361 11.6042 0.00988891 11.4244L3.77303 0.191863C3.82424 0.0639498 3.92022 0 4.06103 0H6.53781C6.6786 0 6.77457 0.0639498 6.82578 0.191863L10.5697 11.3546Z" fill={fill} className="svgFill" />
    </svg>

  )
}

AlphabetIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AlphabetIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AlphabetIcon)
