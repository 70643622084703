import React, { useCallback } from 'react'
import UsersIcons from '@/components/icons/users'
import Loader from '@/components/blocks/Loader'
import {
  Container,
  Header,
  Content,
  Text,
  CloseIcon,
} from './styles'

const RowInfoCard = ({
  isInfoCardOpen,
  setIsInfoCardOpen,
  card,
  loading,
  selectedRow,
}) => {
  const handleSetInfoCardOpen = useCallback(() => {
    setIsInfoCardOpen(false)
  }, [setIsInfoCardOpen])

  return (
    <Container isInfoCardOpen={isInfoCardOpen}>
      <Header>
        <Text type="header">
          Задача №
          {' '}
          {selectedRow.taskNumber}
          {' '}
          -
          {' '}
          {selectedRow.status && selectedRow.status.toLowerCase()}
        </Text>
        <CloseIcon>
          <UsersIcons.CrossIcon onClick={handleSetInfoCardOpen} />
        </CloseIcon>
      </Header>
      {loading
        ? <Loader center />
        : (
          <Content main>
            {card.map((element) => (
              <Content key={element.id}>
                <Content text>
                  <Text type={element.title ? 'title' : 'paragraph'}>
                    {element.title || element.paragraph}
                  </Text>
                </Content>
                <Content text>
                  <Text>
                    {element.text}
                  </Text>
                </Content>
              </Content>
            ))}
          </Content>
        )}
    </Container>
  )
}

RowInfoCard.propTypes = {
}

export default RowInfoCard
