import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LastIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path onClick={onClick} d="M15 1.19209e-07C15.5523 1.19209e-07 16 0.447716 16 1V17C16 17.5523 15.5523 18 15 18H14C13.4477 18 13 17.5523 13 17V1C13 0.447715 13.4477 1.19209e-07 14 1.19209e-07H15Z" fill={fill} className="svgFill" />
      <path d="M2.45309 17.6981L11 9L2.45309 0.301895C2.05756 -0.100632 1.42442 -0.100632 1.02889 0.301896L0.305023 1.03857C-0.101364 1.45215 -0.101721 2.13134 0.304234 2.54538L6.63284 9L0.304232 15.4546C-0.101721 15.8687 -0.101364 16.5479 0.305025 16.9614L1.02889 17.6981C1.42442 18.1006 2.05756 18.1006 2.45309 17.6981Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LastIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LastIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LastIcon)
