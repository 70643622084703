import React, { useContext, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { ThemeContext } from 'styled-components'
import { FormattedMessage as Lang } from 'react-intl'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import viewTreeIcons, {
  CHAR_BY_DEPTH,
  CHAR_BY_DEPTH_SMALL,
  CHAR_BY_DEPTH_DEFAULT,
  CHAR_BY_FOR_SYNC,
} from '@/constants/viewTree'
import { getPinCount } from '@/helpers/viewTree/getPinCount'
import CoreIcons from '@/components/icons/core'
import { getDepth } from '@/helpers/viewTree/getDepths'
import { getKeyByType } from '@/constants/viewTree'

import {
  StyledElementContainer,
  Title,
  Container,
  Count,
  TitleContainer,
  TitleElement,
  SyncIconContainer,
  StyledTooltip,
  CountContainer,
} from './styles'

const Header = ({
  onSelect, node, getTelemetry, onClick, small, excludedTypes, data,
}) => {
  const count = useMemo(() => getPinCount(node, excludedTypes), [excludedTypes, node])
  const theme = useContext(ThemeContext)
  const nodeStatus = useMemo(() => {
    if (getTelemetry) {
      const telemetry = getTelemetry(node)
      if (telemetry.status) {
        return telemetry.status
      }
    }
    return node.status
  }, [node, getTelemetry])
  const Icon = viewTreeIcons[node.type]
  const { SyncIcon } = CoreIcons

  const chopDownTitleWidthTooltip = () => {
    const depth = getDepth(node.path)
    const preLimit = (small ? CHAR_BY_DEPTH_SMALL : CHAR_BY_DEPTH)[depth] || CHAR_BY_DEPTH_DEFAULT
    const limit = node.isSync ? CHAR_BY_FOR_SYNC : preLimit
    return (
      <>
        {((node.name || '').length > limit)
          ? (
            <StyledTooltip title={node.name}>
              {(node.name || '').substr(0, limit)}
              ...
            </StyledTooltip>
          )
          : (
            <>
              {node.name}
            </>
          )}
      </>
    )
  }

  const renderTitle = () => {
    if (node.dateOfSynchronization) {
      return (
        <TitleContainer>
          <Title>
            {chopDownTitleWidthTooltip()}
          </Title>
          <TitleElement>
            ( синхр.:&nbsp;
            <TitleElement color={STATUS_COLORS[nodeStatus]}>
              {node.dateOfSynchronization}
            </TitleElement>
            )
          </TitleElement>
        </TitleContainer>
      )
    }
    if (node.isSync) {
      return (
        <TitleContainer>
          <Title>
            {chopDownTitleWidthTooltip(node)}
          </Title>
          <TitleElement>
            <Lang id="syncModule.formMessages.sync" />
          </TitleElement>
        </TitleContainer>
      )
    }
    return (
      <TitleContainer>
        <Title>
          {chopDownTitleWidthTooltip(node)}
        </Title>
      </TitleContainer>
    )
  }
  const handleClick = () => {
    if (node.type === 'integrationSystem') {
      onClick()
    }
  }

  return (
    <>
      {node.name && node.type
        ? (
          <PortalTooltip
            key={getKeyByType(node)}
            byClass
            title={(node.dateOfSynchronization
            && node.status === 'ERROR')
              ? `Ошибка синхронизации: ${node.dateOfSynchronization}`
              : null}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <Container
                className="portal-Tooltip"
                onClick={onSelect(node, 'click', data)}
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                <StyledElementContainer syncStatus={node.syncStatus} isLast={!node.children}>
                  {node.status
                    ? (
                      <Icon color={STATUS_COLORS[nodeStatus]} />
                    )
                    : (
                      <Icon
                        color={node.checkBoxValue ? null : theme.colors.colors.default}
                        value={node.checkBoxValue}
                      />
                    )}
                  {node.integrationOption
                    ? renderTitle()
                    : (
                      <TitleContainer onClick={handleClick}>
                        <Title>
                          {chopDownTitleWidthTooltip(node)}
                        </Title>
                      </TitleContainer>
                    )}
                </StyledElementContainer>
                <CountContainer>
                  {!node.children || node.isIntegrationScheme || node.isIntegrationOption
                    ? <Count />
                    : <Count>{`(${count})`}</Count>}
                </CountContainer>
                {node.isSync && node.integrationOption && (
                  <SyncIconContainer>
                    <SyncIcon />
                  </SyncIconContainer>
                )}
              </Container>
            )}
          />
        )
        : (<></>)}
    </>
  )
}

Header.defaultProps = {
  onSelect: noop,
  getTelemetry: null,
  onClick: noop,
  small: false,
  excludedTypes: [],
}

Header.propTypes = {
  onSelect: pt.func,
  getTelemetry: pt.func,
  onClick: pt.func,
  small: pt.bool,
  node: pt.shape({
    id: pt.number.isRequired,
    name: pt.string.isRequired,
    type: pt.string.isRequired,
    children: pt.arrayOf(pt.objectOf),
    active: pt.bool,
    isIntegrationOption: pt.bool,
    isIntegrationScheme: pt.bool,
    status: pt.string,
    dateOfSynchronization: pt.string,
    syncStatus: pt.string,
    isSync: pt.bool,
    integrationOption: pt.bool,
    blocked: pt.bool,
    enabled: pt.bool,
    path: pt.string,
    checkBoxValue: pt.bool,
  }).isRequired,
  excludedTypes: pt.arrayOf(pt.string),
}

export default Header
