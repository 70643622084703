import React from 'react'
import { Field } from 'formik'
import DatePicker from '@/components/controls/DatePicker'

const DatePickerField = (props) => (
  <Field {...props}>
    {({ field, form }) => {
      const onChange = (value) => {
        form.setFieldValue(field.name, value)
        if (props.onAfterChange) {
          props.onAfterChange(field.name, value)
        }
      }
      return (
        <DatePicker
          value={field.value}
          name={field.name}
          {...props}
          onChange={onChange}
        />
      )
    }}
  </Field>
)

export default React.memo(DatePickerField)
