export default [
  {
    passportParameter: 'Идентификатор',
    showInDashboard: true,
    selector: 'id',
  },
  {
    passportParameter: 'Наименование',
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: 'Геозона',
    showInDashboard: true,
    selector: 'geofence',
  },
  {
    passportParameter: 'Версия ПО',
    showInDashboard: false,
    selector: 'softwareVersion',
  },
  {
    passportParameter: 'Дата начала эксплуатации',
    showInDashboard: true,
    selector: 'startDate',
  },
  {
    passportParameter: 'Эксплуатирующая организация',
    showInDashboard: false,
    selector: 'operatingOrganization',
  },
  {
    passportParameter: 'Собственник',
    showInDashboard: false,
    selector: 'owner',
  },
]