import {
  takeLatest, put, select
} from 'redux-saga/effects'
import moment from 'moment'
import get from 'lodash/get'
import request from '@/helpers/axios'
import { INTEGRATIONS_URL, generateIntegrationsInfoUrl } from '@/constants/apiRoutes'
import {
  getIntegrationOptions,
} from '@/store/selectors/integrations'
import {
  GET_INTEGRATIONS,
  setSync,
} from '@/store/actions/integrations'
import {
  successGetIntegrations,
  errorGetIntegrations,
} from '@/store/actions/integrations/getIntegrations'
import {
  INTEGRATION_SYSTEM,
  INTEGRATION_ZONE,
} from '@/constants/viewTree'
import { OK, INFO, ERROR } from '@/constants/objectStatuses'
import { CONNECTION_SIGNAL } from '@/constants/syncModule'

const INTEGRATIONS_STATUSES = {
  SUCCESS: OK,
  UNKNOWN_ERROR: ERROR,
  CONNECTION_ERROR: ERROR,
  TIME_OUT_ERROR: ERROR,
  CREDENTIALS_ERROR: ERROR,
}

const SYNC_STATUSES = {
  STARTED: CONNECTION_SIGNAL,
}

const getDateOfSync = (integration) => {
  switch (integration.synchronizationStatus) {
    case 'STARTED':
      return 'в процессе'
    default:
      return moment(
        (integration.lastUpdateTime || '')
          .slice(0, (integration.lastUpdateTime || '').length - 5),
      )
        .format('DD.MM.YYYY HH:mm')
  }
}

function* getIntegrationsSaga() {
  try {
    const { data: integrations } = yield request({
      url: INTEGRATIONS_URL,
      method: 'get',
    })
    const ids = integrations.map((integration) => integration.id)
    const integrationsFull = ids.length ? yield request({
      url: generateIntegrationsInfoUrl(ids),
      method: 'get',
    }): []
    const filterIntegrations = integrations.filter((integration) => {
      const selectedIntegration = integrationsFull.data.filter((fullIntegration) => fullIntegration.aliasId === integration.id)[0] || {}
      const status = get(selectedIntegration, 'data.status', null)
      if (status === 'INACTIVE') {
        return false
      }
      return true
    })
    const integrationsSystems = yield select(getIntegrationOptions)
    const integrationsIds = filterIntegrations.map((integration) => integration.id)
    const extendedIntegrations = (filterIntegrations || [])
      .map((integration) => ({
        id: integration.id,
        name: integration.alias,
        isSync: integration.synchronizationStatus === 'STARTED',
        syncStatus: SYNC_STATUSES[integration.synchronizationStatus],
        status: INTEGRATIONS_STATUSES[integration.synchronizationStatus] || INFO,
        systemType: integration.type,
        dateOfSynchronization: integration.lastUpdateTime ? getDateOfSync(integration) : null,
        parameters: {
          alias: integration.alias,
          id: integration.aliasId,
        },
        type: INTEGRATION_ZONE,
        parentId: (integrationsSystems.filter((element) => integration.type === element.id)[0] || {}).id,
        integrationOption: true,
      }))
    const hasActiveSync = extendedIntegrations.some((integration) => integration.isSync)
    yield put(setSync(hasActiveSync))
    const integrationsForSave = integrationsSystems.reduce((accumulator, system) => ({
      ...accumulator,
      [system.type]: {
        ...system,
        name: `${system.controlType} "${system.name}"`,
        isSync: false,
        type: INTEGRATION_SYSTEM,
        toggled: false,
        children: extendedIntegrations
          .filter((integration) => integration.systemType === system.type),
      },
    }), {})
    yield put(successGetIntegrations(integrationsForSave, integrationsIds))
  } catch (error) {
    console.log('function*getIntegrationsSaga -> error', error)
    yield put(errorGetIntegrations(error))
  }
}

export default function* root() {
  yield takeLatest(GET_INTEGRATIONS.REQUEST, getIntegrationsSaga)
}
