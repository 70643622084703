import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const NoImageIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg onClick={onClick} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM64.0545 66.7844C57.6789 72.514 49.2466 76 40 76C20.1177 76 4 59.8823 4 40C4 30.7534 7.48605 22.3211 13.2156 15.9455L64.0545 66.7844ZM66.8774 63.9506L16.0494 13.1226C22.4126 7.44835 30.8036 4 40 4C59.8823 4 76 20.1177 76 40C76 49.1964 72.5517 57.5874 66.8774 63.9506Z" />
      <path className="svgFill" fill={fill} d="M18.3494 24.3878C17.515 25.2685 17 26.4648 17 27.7778V57.1111C17 59.8 19.16 62 21.8 62H55.9615L46.9946 53.0331C45.2305 54.072 43.183 54.6667 41 54.6667C34.376 54.6667 29 49.1911 29 42.4444C29 40.301 29.5426 38.2859 30.4955 36.5339L18.3494 24.3878Z" />
      <path className="svgFill" fill={fill} d="M33.7679 39.8063C33.478 40.6306 33.3201 41.5188 33.3201 42.4446C33.3201 46.7647 36.7586 50.2668 41.0001 50.2668C41.9588 50.2668 42.8764 50.0879 43.7226 49.7611L33.7679 39.8063Z" />
      <path className="svgFill" fill={fill} d="M48.6506 43.1356L40.1813 34.6663C40.4503 34.6372 40.7235 34.6223 41.0001 34.6223C45.2417 34.6223 48.6801 38.1245 48.6801 42.4446C48.6801 42.6774 48.6701 42.9079 48.6506 43.1356Z" />
      <path className="svgFill" fill={fill} d="M52.2419 46.7269C52.732 45.394 53 43.9509 53 42.4444C53 35.6978 47.624 30.2222 41 30.2222C39.4432 30.2222 37.9553 30.5247 36.59 31.075L28.4039 22.8889H29.408L33.8 18H48.2L52.592 22.8889H60.2C62.84 22.8889 65 25.0889 65 27.7778V57.1111C65 57.8095 64.8543 58.4749 64.5923 59.0773L52.2419 46.7269Z" />
    </svg>
  )
}

NoImageIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NoImageIcon.defaultProps = {
  color: '#828D99',
  onClick: noop,
}

export default memoWithName(NoImageIcon)
