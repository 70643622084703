import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HeatIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M45.5956 16.052C44.8631 16.052 44.2689 16.6405 44.2689 17.3649V22.4997H42.786V21.3295C42.786 18.4197 40.3935 16.052 37.4541 16.052C35.8572 16.052 34.427 16.7542 33.4482 17.8578C32.4696 16.7542 31.0393 16.052 29.4431 16.052C27.8462 16.052 26.4151 16.7542 25.4372 17.8578C24.4586 16.7542 23.0279 16.052 21.4314 16.052C19.8352 16.052 18.404 16.7542 17.4258 17.8578C16.4476 16.7542 15.0165 16.052 13.4199 16.052C10.4801 16.052 8.08787 18.4199 8.08787 21.3295V22.4997H5.4065C4.67456 22.4997 4.07996 23.0879 4.07996 23.8126C4.07996 24.5372 4.67456 25.1257 5.4065 25.1257H8.08584V44.7224C8.08584 47.6323 10.4783 50 13.4179 50C15.0145 50 16.4455 49.2981 17.4238 48.1946C18.402 49.2981 19.8327 50 21.4293 50C23.0258 50 24.4566 49.2981 25.4348 48.1946C26.4129 49.2981 27.8441 50 29.4403 50C31.0365 50 32.4683 49.2981 33.4462 48.1946C34.4239 49.2981 35.8559 50 37.4521 50C40.3922 50 42.7845 47.6321 42.7845 44.7224V25.1266H45.5934C46.3257 25.1266 46.9199 24.5381 46.9199 23.8137V17.3658C46.9224 16.6405 46.3272 16.052 45.5956 16.052ZM40.1326 44.7234C40.1326 46.1859 38.9311 47.3751 37.4541 47.3751C35.9771 47.3751 34.7749 46.1861 34.7749 44.7234V21.3305C34.7749 19.8683 35.9771 18.6787 37.4541 18.6787C38.9311 18.6787 40.1326 19.8681 40.1326 21.3305V44.7234ZM32.1208 44.7226C32.1208 46.185 30.9186 47.3742 29.4416 47.3742C27.9646 47.3742 26.7623 46.1852 26.7623 44.7226V21.3305C26.7623 19.8683 27.9645 18.6787 29.4416 18.6787C30.9184 18.6787 32.1208 19.8681 32.1208 21.3305V44.7226ZM24.1096 44.7226C24.1096 46.185 22.9077 47.3742 21.4308 47.3742C19.954 47.3742 18.7519 46.1852 18.7519 44.7226V21.3305C18.7519 19.8683 19.954 18.6787 21.4308 18.6787C22.9077 18.6787 24.1096 19.8681 24.1096 21.3305V44.7226ZM16.0978 44.7226C16.0978 46.185 14.8954 47.3742 13.4187 47.3742C11.9416 47.3742 10.7398 46.1852 10.7398 44.7226V21.3305C10.7398 19.8683 11.9416 18.6787 13.4187 18.6787C14.8954 18.6787 16.0978 19.8681 16.0978 21.3305V44.7226Z" fill={fill} className="svgFill" />
        <path d="M16.6126 0.440442C15.4169 1.85223 14.9249 3.34718 15.5174 5.14195C16.1022 6.91255 17.8983 8.53644 16.465 10.3607C15.4281 11.6796 17.2916 13.553 18.3406 12.2174C19.5212 10.7149 20.0316 9.18244 19.4357 7.31788C18.8982 5.63557 17.0584 3.98494 18.4883 2.29712C19.58 1.00842 17.7134 -0.859256 16.6126 0.440442Z" fill={fill} className="svgFill" />
        <path d="M26.3013 12.2172C27.4829 10.7148 27.9924 9.18226 27.3964 7.3177C26.8592 5.63538 25.019 3.98475 26.449 2.29694C27.5407 1.00823 25.6734 -0.859439 24.5734 0.440258C23.3776 1.85205 22.8858 3.34699 23.4783 5.14177C24.0631 6.91237 25.8592 8.53626 24.4259 10.3605C23.388 11.6796 25.2514 13.5528 26.3013 12.2172Z" fill={fill} className="svgFill" />
        <path d="M32.5319 0.440442C31.3367 1.85223 30.8443 3.34718 31.4368 5.14195C32.0223 6.91255 33.8179 8.53644 32.3853 10.3607C31.3479 11.6796 33.2109 13.553 34.2603 12.2174C35.441 10.7149 35.9516 9.18244 35.3555 7.31788C34.8176 5.63557 32.9776 3.98494 34.408 2.29712C35.4996 1.00842 33.6333 -0.859256 32.5319 0.440442Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H51V50H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

HeatIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HeatIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HeatIcon)
