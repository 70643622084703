import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const AlarmsIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M11.52 2.43366L17.551 12.3546C18.4177 13.7795 17.992 15.6542 16.5996 16.5413C16.1324 16.8402 15.5876 16.9994 15.0309 17H2.96821C1.3293 17 0 15.64 0 13.9609C0 13.3929 0.1557 12.8373 0.4482 12.3546L6.48 2.43366C7.3458 1.00876 9.1764 0.57232 10.5687 1.45943C10.9539 1.70477 11.2797 2.0381 11.52 2.43366ZM9.522 3.17945C9.4118 3.10945 9.2884 3.06224 9.1593 3.04067C9.0301 3.0191 8.8978 3.02361 8.7705 3.05393C8.6431 3.08426 8.5233 3.13977 8.4183 3.2171C8.3133 3.29444 8.2252 3.392 8.1594 3.50389L2.12851 13.4266C2.03054 13.5883 1.9789 13.7733 1.97911 13.9618C1.97911 14.5218 2.42191 14.9751 2.96911 14.9751H15.03C15.2154 14.9751 15.3963 14.9218 15.5529 14.8222C15.7777 14.6747 15.9361 14.4473 15.9953 14.1874C16.0545 13.9274 16.0098 13.6549 15.8706 13.4266L9.8397 3.50389C9.7606 3.37325 9.6523 3.26225 9.5229 3.17945H9.522ZM9 13.4444C8.7613 13.4444 8.5324 13.3508 8.3636 13.1841C8.1948 13.0174 8.1 12.7913 8.1 12.5555C8.1 12.3198 8.1948 12.0937 8.3636 11.927C8.5324 11.7603 8.7613 11.6666 9 11.6666C9.2387 11.6666 9.4676 11.7603 9.6364 11.927C9.8052 12.0937 9.9 12.3198 9.9 12.5555C9.9 12.7913 9.8052 13.0174 9.6364 13.1841C9.4676 13.3508 9.2387 13.4444 9 13.4444ZM9 5.4444C9.2387 5.4444 9.4676 5.538 9.6364 5.7047C9.8052 5.8714 9.9 6.0975 9.9 6.3332V9.8888C9.9 10.1246 9.8052 10.3507 9.6364 10.5174C9.4676 10.6841 9.2387 10.7777 9 10.7777C8.7613 10.7777 8.5324 10.6841 8.3636 10.5174C8.1948 10.3507 8.1 10.1246 8.1 9.8888V6.3332C8.1 6.0975 8.1948 5.8714 8.3636 5.7047C8.5324 5.538 8.7613 5.4444 9 5.4444Z" fill="#3D3D3D"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

AlarmsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AlarmsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AlarmsIcon)
