import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DiagramIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M0 6H4V18H0V6Z" />
      <path className="svgFill" fill={fill} d="M7 0H11V18H7V0Z" />
      <path className="svgFill" fill={fill} d="M14 9H18V18H14V9Z" />
    </svg>
  )
}

DiagramIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DiagramIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DiagramIcon)
