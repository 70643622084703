import BRANCH_CIRCUIT from './BRANCH_CIRCUIT'
import CHECKPOINT from './CHECKPOINT'
import CONTACTOR from './CONTACTOR'
import CONTROLLER from './CONTROLLER'
import CONTROLLER_FUSE from './CONTROLLER_FUSE'
import CURRENT_SENSOR from './CURRENT_SENSOR'
import DMX_DEVICE from './DMX_DEVICE'
import ELECTRIC_METER from './ELECTRIC_METER'
import FIRE_SENSOR from './FIRE_SENSOR'
import LIGHT_SENSOR from './LIGHT_SENSOR'
import PHOTO_SENSOR from './PHOTO_SENSOR'
import TRANSFER_SWITCH from './TRANSFER_SWITCH'
import VOLUME_SENSOR from './VOLUME_SENSOR'
import PHASE_CIRCUIT_BREAKER from './PHASE_CIRCUIT_BREAKER'
import FUSE from './FUSE'
import LAMP from './LAMP'
import PHASE from './PHASE'

export default {
  BRANCH_CIRCUIT,
  CHECKPOINT,
  CONTACTOR,
  CONTROLLER,
  CONTROLLER_FUSE,
  CURRENT_SENSOR,
  ELECTRIC_METER,
  DMX_DEVICE,
  FIRE_SENSOR,
  LIGHT_SENSOR,
  PHOTO_SENSOR,
  TRANSFER_SWITCH,
  VOLUME_SENSOR,
  FUSE,
  LAMP,
  PHASE,
  PHASE_CIRCUIT_BREAKER,
}
