import styled from 'styled-components'
import TextControl from '@/components/controls/TextControl'
import Button from '@/components/blocks/Button'

export const CustomTextControl = styled(TextControl)`
  width: 217px;
  margin-right: 10px;
`

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CustomButton = styled(Button)`
  padding: 7px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
`
