import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import MapsIcons from '@/components/icons/maps'
import { FormattedMessage as Lang } from 'react-intl'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import {
  ObjectPassportizationStyled,
  ContentContainer,
  ObjectTitle,
  ObjectNameRow,
  IconContainer,
  CollapsedContainer,
} from './styles'

const { BackIcon } = MapsIcons

const CollapsedInfoBlock = ({
  name,
  isSettingsOpen,
  setIsSettingsOpen,
  content,
  collapsedContent,
}) => {
  const collapseIconHandler = () => {
    setIsSettingsOpen(!isSettingsOpen)
  }

  return (
    <ObjectPassportizationStyled isSettingsOpen={isSettingsOpen}>
      {isSettingsOpen
        ? (
          <>
            <ObjectNameRow>
              <PortalTooltip
                title={(<Lang id="tooltip.collapse" />)}
                renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                  <IconContainer
                    onClick={collapseIconHandler}
                    isSettingsOpen={isSettingsOpen}
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                  >
                    <BackIcon />
                  </IconContainer>
                )}
              />
              <ObjectTitle>
                {name}
              </ObjectTitle>
            </ObjectNameRow>
          </>
        )
        : (
          <CollapsedContainer>
            <PortalTooltip
              title={(<Lang id="tooltip.collapse" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  isSettingsOpen={isSettingsOpen}
                  onClick={collapseIconHandler}
                >
                  <BackIcon />
                </IconContainer>
              )}
            />
            <ObjectTitle>
              {name}
            </ObjectTitle>
            {collapsedContent && collapsedContent}
          </CollapsedContainer>
        )}
      <ContentContainer isSettingsOpen={isSettingsOpen}>
        {content && content}
      </ContentContainer>
    </ObjectPassportizationStyled>
  )
}

CollapsedInfoBlock.propTypes = {
  name: pt.string,
  isSettingsOpen: pt.bool,
  setIsSettingsOpen: pt.func,
  content: pt.element,
  collapsedContent: pt.element,
}

CollapsedInfoBlock.defaultProps = {
  name: '',
  isSettingsOpen: false,
  setIsSettingsOpen: noop,
  content: (<> </>),
  collapsedContent: (<> </>),
}

export default CollapsedInfoBlock
