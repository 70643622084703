export default {
  messages: {
    setEvens: 'Настроить события',
    setStats: 'Настроить статусы',
    setFields: 'Настроить столбцы',
    export: 'Экспортировать',
  },
  alarmTypeTitle: {
    error: 'Неисправно',
    warning: 'Предупреждение',
    ok: 'Исправно',
    info: 'Не определено',
  },
  buttonsTitles: {
    buildTable: 'построить таблицу',
    discardTable: 'сбросить диапазон',
  },
  forms: {
    dateRange: 'Диапазон дат',
    events: 'События',
  },
}
