import {
  DEMONSTRATION_GRAPH,
  DEMONSTRATION_TABLE,
  DIFFERENCE_ANALSIS,
  COMPARATIVE_ANALSIS,
} from '@/constants/names'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'

export const demonstrationOptions = [
  {
    value: DEMONSTRATION_GRAPH,
    label: 'График',
  },
  {
    value: DEMONSTRATION_TABLE,
    label: 'Таблица',
  },
]

export const graphViewOptions = [
  {
    value: DIFFERENCE_ANALSIS,
    label: 'Анализ разницы в потреблении ресурсов',
  },
  {
    value: COMPARATIVE_ANALSIS,
    label: 'Сравнительный анализ потребления ресурсов',
  },
]

export const eventsExample = {
  [ERROR]: 30,
  [WARNING]: 15,
  [OK]: 100,
}
