import {
  ATLANTIS_LIGHT,
  SELESTIAL_BLUE,
} from '@/constants/styles/defaultTheme'

export const seriesNamesDashboardLightning = [
  {
    name: 'actualExpense',
    title: 'фактическое потребление',
    fill: SELESTIAL_BLUE,
  },
  {
    name: 'planExpense',
    title: 'плановое потребление',
    fill: ATLANTIS_LIGHT,
  },
]

export const seriesNamesDashboardHeat = [
  {
    name: 'factQ',
    title: 'фактическое потребление',
    fill: SELESTIAL_BLUE,
  },
  {
    name: 'planQ',
    title: 'плановое потребление',
    fill: ATLANTIS_LIGHT,
  },
]

export const graphLegendOutdoorLighting = {
  value: 'кВт*ч.',
  type: 'line',
  id: 'outdoorLighting',
}

export const graphLegendHeatSupply = {
  value: 'ГКал.',
  type: 'line',
  id: 'heatSupply',
}
