import {
  take, call,
} from 'redux-saga/effects'

import { createMonitoringErrorsChannel } from './channels'

function* monitoringErrors() {
  try {
    const channel = yield call(createMonitoringErrorsChannel)

    while (true) {
      yield take(channel)
    }
  } catch (error) {
    console.log('TCL: function*monitoringErrors -> error', error)
  }
}

export default monitoringErrors
