import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DoneIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0896 1.6732C15.425 1.28982 15.9949 1.21975 16.4131 1.51047L17.1764 2.0411C17.6309 2.35701 17.7423 2.98198 17.425 3.43548L8.23315 16.5732C8.04604 16.8406 7.74018 16.9999 7.41379 16.9999H6.12528C5.80371 16.9999 5.50175 16.8452 5.31382 16.5843L0.51717 9.92395C0.221914 9.51398 0.278836 8.94817 0.649837 8.60523L1.64233 7.68778C2.02569 7.33341 2.61716 7.33357 3.00033 7.68815L6.01905 10.4817C6.43246 10.8642 7.0801 10.83 7.45094 10.4061L15.0896 1.6732Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DoneIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DoneIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DoneIcon)
