import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getUserData } from '@/store/selectors/appSettings'
import { getState } from '@/store/reducers'
import { isSuperAdmin } from '@/helpers/userPermissions'

export const getDictionaries = createSelector(
  getState,
  (state) => get(state, 'dictionaries', {}),
)

export const getCityProcesses = createSelector(
  getDictionaries,
  (dictionaries) => get(dictionaries, 'cityProcesses', []),
)

export const getCityProcessesOptions = createSelector(
  getCityProcesses,
  (dictionaries) => get(dictionaries, 'data', []),
)

export const getCityProcessesForUser = createSelector(
  [getCityProcessesOptions, getUserData],
  (allProcesses, user) => {
    const userProcesses = get(user, 'processes', [])
    return isSuperAdmin(user.role)
      ? allProcesses
      : allProcesses.filter((process) => userProcesses.includes(process.id))
  },
)
