import styled from 'styled-components'

export const SecondaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.notation};
  text-align: left;
`
export const TextFieldContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-left: 2px;
    margin-right: 2px;
  }
`

export const ButtunContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  button {
    width: 88px;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ErrorField = styled.div`
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.colors.disabled};
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  margin-top: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
  word-break: break-word;
`
