import base from './base'
import signIn from './signIn'
import userProfile from './userProfile'
import menu from './menu'
import widgets from './widgets'
import notFound from './notFound'
import usersPage from './usersPage'
import mapsPage from './mapsPage'
import settingsSidebar from './settingsSidebar'
import datePicker from './datePicker'
import dragAndDrop from './dragAndDrop'
import syncModule from './syncModule'
import objectWidget from './objectWidget'
import signUp from './signUp'
import consumptionAnalysis from './consumptionAnalysis'
import analyticExpenses from './analyticExpenses'
import alarmManager from './alarmManager'
import sideBar from './sideBar'
import faultAnalytics from './faultAnalytics'
import passportization from './passportization'
import tooltip from './tooltip'
import serviceDeskMyCity from './serviceDeskMyCity'
import globalFilter from './globalFilter'
import tyumenOurHome from './tyumenOurHome'
import widgetsDashboard from './widgetsDashboard'
import installation from './installation'
import passport from './passport'
import globalNames from './globalNames'
import reportManager from './reportManager'
import widgetsDashboardGov from './widgetsDashboardGov'

export default {
  ...base,
  signIn,
  userProfile,
  globalFilter,
  menu,
  widgets,
  notFound,
  usersPage,
  mapsPage,
  settingsSidebar,
  datePicker,
  dragAndDrop,
  syncModule,
  objectWidget,
  signUp,
  consumptionAnalysis,
  analyticExpenses,
  alarmManager,
  sideBar,
  faultAnalytics,
  passportization,
  tooltip,
  serviceDeskMyCity,
  tyumenOurHome,
  widgetsDashboard,
  installation,
  passport,
  globalNames,
  reportManager,
  widgetsDashboardGov,
}
