import styled from 'styled-components'
import TextControl from '@/components/controls/TextControl'
import Button from '@/components/blocks/Button'

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CustomTextControl = styled(TextControl)`
  width: 217px;
  margin-left: 20px;
  margin-right: 10px;
`

export const IconContainer = styled.div`
  margin-right: 22px;
  cursor: pointer;
`

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CustomButton = styled(Button)`
  padding: 7px;
`

export const Title = styled.div`
  min-width: 103px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ selected, theme }) => selected && `
    color: ${theme.colors.colors.tooltip}
    background: ${theme.colors.colors.default}
    border-radius: 4px;
  `};
  ${({ tab }) => tab && `
    cursor: pointer;
  `};
  ${({ appeals }) => appeals && `
    margin-left: 10px;
  `};
`
