import React, { useCallback, useEffect } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { Legend } from 'recharts'
import { FormattedMessage as Lang } from 'react-intl'
import { REQUEST_STATUSES } from '@/constants/requests'
import Loader from '@/components/blocks/Loader'
import BarChart from '@/components/charts/BarChart'

import {
  GraphContainer,
  Header,
  Title,
  LoaderContainer,
} from './styles'

const GraphPerformance = ({
  icon,
  title,
  data,
  seriesNames,
  graphLegendPayload,
  requestState,
  selectedNode,
  getDataRequest,
  formValues,
  type,
}) => {
  useEffect(() => {
    getDataRequest({
      data: {
        ...formValues,
        selectedNode,
      },
      type,
    })
  }, [selectedNode, getDataRequest, formValues, type])

  const showLoader = requestState === REQUEST_STATUSES.PENDING
  const renderCustomizedLegend = useCallback((props) => {
    return (
      <Title graph>
        {props.payload.value}
      </Title>
    )
  }, [])
  return (
    <GraphContainer>
      <Header>
        {icon}
        <Title>{title}</Title>
      </Header>
      {showLoader
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <BarChart
            data={data}
            seriesNames={seriesNames}
            mainAxisName="dateStep"
            decimalNumbers
            graphLegend={(
              <Legend
                verticalAlign="top"
                align="left"
                wrapperStyle={{
                  paddingBottom: '15px',
                }}
                payload={graphLegendPayload}
                content={renderCustomizedLegend}
              />
            )}
            customGraphMargin={{
              top: 20, right: 30, left: -20, bottom: 5,
            }}
            legend={{
              align: 'left',
              verticalAlign: 'bottom',
              iconType: 'square',
              dashboardWidget: true,
              title: <Lang id="widgetsDashboard.consumtion" />,
              pl: 40,
            }}
            brush={{
              height: 18,
              travellerWidth: 10,
              dataKey: 'dateStep',
            }}
          />
        )}
    </GraphContainer>
  )
}

GraphPerformance.propTypes = {
  data: pt.arrayOf(pt.object),
  requestState: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
  ]),
  icon: pt.element,
  title: pt.string,
  seriesNames: pt.arrayOf(pt.object),
  selectedNode: pt.objectOf(pt.object),
  formValues: pt.objectOf(pt.string),
  getDataRequest: pt.func,
  type: pt.string,
}
GraphPerformance.defaultProps = {
  data: [],
  title: '',
  icon: React.Fragment,
  requestState: REQUEST_STATUSES.NOT_REQUESTED,
  seriesNames: [],
  selectedNode: {},
  getDataRequest: noop,
  formValues: {},
  type: '',
}

export default GraphPerformance
