import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const OverdueOnExecutionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.05">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3308 30.9642L95.9498 1.25727L97.5021 5.52203L95.9621 1.25284C101.086 -0.595913 106.729 -0.355987 111.657 1.9202C116.584 4.19638 120.397 8.3238 122.261 13.4007L122.266 13.4128L140.893 64.5899L140.897 64.6021C142.732 69.6896 142.465 75.302 140.153 80.2132C137.841 85.1243 133.673 88.935 128.559 90.8127L128.547 90.8172L84.9844 106.673L67.8194 135.949C66.9248 137.474 65.2256 138.352 63.4605 138.2C61.6953 138.047 60.1829 136.893 59.5805 135.238L53.3716 118.179L46.928 120.524L46.9157 120.528C41.7913 122.377 36.1489 122.137 31.2211 119.861C26.2933 117.585 22.4808 113.458 20.6165 108.381L20.612 108.368L1.98068 57.1793C0.145476 52.0918 0.413006 46.4793 2.7248 41.5682C5.03659 36.657 9.20474 32.8463 14.3186 30.9686L14.3308 30.9642ZM99.0477 9.78919L17.4419 39.4913C14.5984 40.537 12.2809 42.6568 10.9951 45.3883C9.70933 48.1198 9.5598 51.241 10.579 54.0709L29.2011 105.235C30.2394 108.058 32.3603 110.353 35.101 111.619C37.8418 112.885 40.9799 113.019 43.8304 111.992L54.5628 108.086C56.9353 107.222 59.5535 108.432 60.4108 110.787L64.8568 123.002L77.5126 101.417C78.3219 100.036 79.5936 98.9751 81.1024 98.4261L125.436 82.2902C128.279 81.2445 130.597 79.1246 131.883 76.393C133.168 73.6615 133.318 70.5401 132.299 67.7101L113.677 16.5467C112.638 13.7236 110.518 11.4286 107.777 10.1626C105.036 8.89667 101.898 8.76249 99.0477 9.78919Z" fill={fill} className="svgFill" />
        <path fillRule="evenodd" clipRule="evenodd" d="M44.8883 68.1394C39.7464 54.012 47.1193 38.349 61.3493 33.1697C75.5793 27.9904 91.2952 35.2498 96.4372 49.3772C101.579 63.5046 94.2063 79.1675 79.9762 84.3468C65.7462 89.5261 50.0303 82.2668 44.8883 68.1394ZM62.7821 37.1064C50.7421 41.4887 44.503 54.7429 48.8536 66.6962C53.2043 78.6494 66.5033 84.7923 78.5434 80.4101C90.5834 76.0279 96.8225 62.7736 92.4719 50.8204C88.1213 38.8672 74.8222 32.7242 62.7821 37.1064ZM64.215 41.0431C65.31 40.6446 66.5184 41.2028 66.914 42.2899L72.6454 58.0367L82.5586 54.4285C83.6536 54.03 84.862 54.5882 85.2577 55.6753C85.6534 56.7623 85.0865 57.9667 83.9915 58.3652L72.0956 62.695C71.0006 63.0935 69.7922 62.5353 69.3965 61.4483L62.9487 43.7331C62.5531 42.646 63.12 41.4417 64.215 41.0431Z" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

OverdueOnExecutionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

OverdueOnExecutionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(OverdueOnExecutionIcon)
