import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT } from '@/constants/apiRoutes'
import { SET_SELECTED_ELEMENT } from '@/store/actions/alarmManager'
import {
  requestAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import {
  requestGetAlarmsCount,
} from '@/store/actions/alarmManager/getAlarmsCount'

function* setEventRead({ payload }) {
  try {
    if (payload && payload.id) {
      yield request({
        url: ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT,
        method: 'put',
        body: {
          eventId: payload.id,
        },
      })
      yield put(requestGetAlarmsCount())
      yield put(requestAlarmManagerTable())
    }
  } catch (error) {
    console.log('TCL: function*setEventRead -> error', error)
  }
}

export default function* root() {
  yield takeLatest(SET_SELECTED_ELEMENT, setEventRead)
}
