import React, { useCallback, useEffect, useState } from 'react'
import {
  Route, Switch, useLocation, useRouteMatch, useHistory,
} from 'react-router-dom'
import qs from 'query-string'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import UsersIcons from '@/components/icons/users'
import TextControl from '@/components/controls/TextControl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import withPermissions from '@/hocs/withPermissions'
import {
  ALARMS_ANALYTICS_URL,
  ALARMS_ANALYTICS_URL_WITH_MASK,
  SUMMARY_ALARMS_ANALYTICS_URL,
} from '@/constants/routes'
import {
  COUNTRY,
  REGION,
  CITY,
} from '@/constants/objectTypes'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'
import SummaryAlarmsAnalyticsBlock from './components/SummaryAlarmsAnalytics'
import AlarmsAnalyticsBlock from './components/AlarmsAnalytics'
import { Main } from './styles'

const AlarmsAnalyticsPage = ({
  tree,
  setNode,
}) => {
  const [treeQuery, setTreeQuery] = useState(null)
  const [isDirty, setIsDirty] = useState(false)
  const { params: { id } } = useRouteMatch(ALARMS_ANALYTICS_URL_WITH_MASK)
  const { search } = useLocation()
  const { push } = useHistory()
  const parsedSearch = qs.parse(search)
  useHtmlTitle('Аналитика неисправностей')
  useEffect(() => {
    if (Object.keys(tree).length && !isDirty) {
      const numberId = Number.parseInt(id, 10)
      const isIdNumber = numberId > 0
      const defaultNode = {
        internalId: numberId,
        name: decodeURIComponent(parsedSearch.name),
      }
      setNode(isIdNumber ? defaultNode : tree)
      setIsDirty(true)
    }
  }, [tree, isDirty, setNode, id, parsedSearch.name])
  const onSelectNode = useCallback((node, event) => {
    if (event === 'click') {
      const { internalId = 'all' } = node
      if (node.type === COUNTRY || node.type === REGION) {
        push(SUMMARY_ALARMS_ANALYTICS_URL)
      } else if (node.type === CITY) {
        push({
          pathname: `${ALARMS_ANALYTICS_URL}/${internalId}`,
          search: qs.stringify({
            name: encodeURIComponent(node.name),
          }),
        })
      }
      setNode(node)
    }
  },
  [push, setNode])

  return (
    <Main>
      <PageSidebar
        onIconClick={noop}
        title="Аналитика неисправностей"
        treeData={tree}
        searchQuery={treeQuery}
        onSelect={onSelectNode}
        headerContent={(
          <Lang id="usersPage.titles.search">
            {(placeholder) => (
              <TextControl
                dark
                placeholder={placeholder}
                name="search"
                icon={UsersIcons.MagnifierIcon}
                onChange={setTreeQuery}
              />
            )}
          </Lang>
        )}
      />
      <Switch>
        <Route
          exact
          path={SUMMARY_ALARMS_ANALYTICS_URL}
          component={withPermissions(
            SummaryAlarmsAnalyticsBlock,
            ID_BY_APPLICATIONS.ALARM_ANALYTICS,
          )}
        />
        <Route
          exact
          path={ALARMS_ANALYTICS_URL_WITH_MASK}
          component={withPermissions(
            AlarmsAnalyticsBlock,
            ID_BY_APPLICATIONS.ALARM_ANALYTICS,
          )}
        />
      </Switch>
    </Main>
  )
}

AlarmsAnalyticsPage.propTypes = {
  tree: pt.objectOf(pt.object),
  setNode: pt.func,
}
AlarmsAnalyticsPage.defaultProps = {
  tree: {},
  setNode: noop,
}

export default React.memo(AlarmsAnalyticsPage)
