import {
  takeLatest, put,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import request from '@/helpers/axios'
import { generateTenantEndpoint } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import {
  NAME,
  PHONE_NUMBER,
  EMAIL,
  SUB_END_DATA,
  BLOCK_ORGANIZATION,
} from '@/constants/forms/group'
import { requestGetTenant } from '@/store/actions/users/tenant/getTenant'
import { errorUpdateTenant } from '@/store/actions/users/tenant/updateTenant'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { updateSelectedUserType, UPDATE_TENANT, setFormIsModifying } from '@/store/actions/users'
import { formatErrorCode } from '@/helpers/formatErrors'
import { requestDeleteIntegration } from '@/store/actions/serviceDeskMyCity/deleteIntegration'
import { setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'

const ERRORS_MESSAGES = {
  'validation_failed.tenant_name:not_unique': 'Организация с таким же названием тенанта уже существует в системе',
  'validation_failed.phone:not_unique': 'Организация с таким же номером телефона уже существует в системе',
  'validation_failed.email:not_unique': 'Организация с таким же email-адресом уже существует в системе',
  'validation_failed.email:invalid': 'Неправильный формат данных email',
  'validation_failed.tenant_name:invalid': 'Неправильный формат данных названия организации',
  'validation_failed.subscription_expiration:null': 'Неправильный формат данных дата окончания подписки',
}

const ERRORS = {
  not_unique: 'Значение уже существует',
  invalid: 'Неправильный формат данных',
  null: 'Неправильный формат данных',
}
const NAMES_ALIASES = {
  tenant_name: NAME,
  subscription_expiration: SUB_END_DATA,
}

function* updateTenantSaga({ payload }) {
  const {
    id, groupForm, selectedEntity, permissions, serviceDeskStatus, tenantServiceDeskStatus,
  } = payload
  const isBlocked = selectedEntity.blocked
  const toast = createNotifications()
  const {
    setSubmitting = noop,
    setErrors = noop,
  } = groupForm
  try {
    const dataJson = {
      [NAME]: groupForm.values[NAME],
      [EMAIL]: groupForm.values[EMAIL],
      [PHONE_NUMBER]: groupForm.values[PHONE_NUMBER],
      [SUB_END_DATA]: moment(groupForm.values[SUB_END_DATA], 'DD.MM.YYYY').utcOffset(0, true),
      [BLOCK_ORGANIZATION]: groupForm.values[BLOCK_ORGANIZATION],
      permissions,
    }
    const response = yield request({
      url: generateTenantEndpoint(id),
      method: 'put',
      body: dataJson,
    })

    if (response && response.data) {
      yield put(requestGetAllTenants())
      yield put(requestGetTenant({ id: response.data.id, onlyUpdateSelectedTenant: true }))
      yield put(updateSelectedUserType('tenant_edit'))
      if (isBlocked !== response.data.blocked) {
        yield put(setFormIsModifying(false))
        if (response.data.blocked) {
          toast({
            title: 'Успешная операция!',
            description: `Тенант ${response.data.organizationName} успешно заблокирован`,
          })
        } else {
          toast({
            title: 'Успешная операция!',
            description: `Тенант ${response.data.organizationName} и его пользователи успешно разблокированы`,
          })
        }
      }

      const newResponse = {
        ...response.data,
        access: response.data.permissions,
        [SUB_END_DATA]: moment(response.data[SUB_END_DATA]).utcOffset(0, true).format('DD.MM.YYYY'),
      }
      if (!isEqual(
        pick(newResponse, [NAME, EMAIL, PHONE_NUMBER, SUB_END_DATA, 'access']),
        pick(selectedEntity, [NAME, EMAIL, PHONE_NUMBER, SUB_END_DATA, 'access']),
      )) {
        yield put(setFormIsModifying(false))
        toast({
          title: 'Успешная операция!',
          description: 'Изменения успешно сохранены',
        })
      }
      (tenantServiceDeskStatus || []).forEach(function* (tenantServiceDesk) {
        const addingServiceDesk = serviceDeskStatus.some(
          (serviceDesk) => tenantServiceDesk.temporaryId === serviceDesk.temporaryId,
        )
        if (!addingServiceDesk) {
          yield put(requestDeleteIntegration({
            widgetId: tenantServiceDesk.temporaryId,
            isUserIntegration: true,
          }))
        }
      })
      yield put(setTenantServiceDesk([]))
    }
    setSubmitting(false)
  } catch (error) {
    yield put(errorUpdateTenant())
    const { errorCode = '500', status = '500' } = error.response.data
    const [errorType, field, errorValue] = formatErrorCode(errorCode)
    if (errorType === 'validation_failed') {
      setErrors({
        [NAMES_ALIASES[field] || field]: ERRORS[errorValue],
      })
      toast({
        title: 'Ошибка операции!',
        description: ERRORS_MESSAGES[errorCode],
        type: 'error',
      })
    } else {
      toast({
        title: 'Ошибка операции!',
        description: `Ошибка при сохранении изменений! Повторите попытку позже. Код ошибки: ${status}`,
        type: 'error',
      })
    }
    setSubmitting(false)
  }
}

export default function* root() {
  yield takeLatest(UPDATE_TENANT.REQUEST, updateTenantSaga)
}
