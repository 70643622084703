import React from 'react'
import { renderToString } from 'react-dom/server'

const renderJSXToString = (Element, data, theme, intl) => renderToString(
  <Element {...data} theme={theme} intl={intl} />,
)
  .replace(
    /&[gl]t;|&amp;*/g,
    (match) => match
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&amp;/g, '&'),
  )

export default renderJSXToString
