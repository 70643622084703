import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InfoFrameIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M48.8637 34.0909C49.4919 34.0909 50.0001 33.5827 50.0001 32.9545V5.68179C50.0001 5.05366 49.4919 4.54539 48.8637 4.54539H45.4546V2.27269H46.591C47.2192 2.27269 47.7274 1.76442 47.7274 1.13629C47.7274 0.508168 47.2191 0 46.5909 0H42.0454C41.4174 0 40.9091 0.508274 40.9091 1.1364C40.9091 1.76453 41.4174 2.2728 42.0455 2.2728H43.1819V4.54549H26.1365V2.27269H27.2728C27.9009 2.27269 28.4092 1.76442 28.4092 1.13629C28.4092 0.508168 27.9009 0 27.2728 0H22.7273C22.0991 0 21.5909 0.508274 21.5909 1.1364C21.5909 1.76453 22.0991 2.2728 22.7273 2.2728H23.8637V4.54549H6.81819V2.27269H7.95459C8.58272 2.27269 9.09099 1.76442 9.09099 1.13629C9.09099 0.508168 8.58261 0 7.95459 0H3.4091C2.78097 0 2.27269 0.508274 2.27269 1.1364C2.27269 1.76453 2.78097 2.27269 3.4091 2.27269H4.5455V4.54539H1.1364C0.508275 4.54549 0 5.05366 0 5.68179V32.9545C0 33.5827 0.508275 34.0909 1.1364 34.0909H4.5455V36.3636H1.1364C0.508275 36.3636 0 36.8719 0 37.5C0 38.1281 0.508275 38.6364 1.1364 38.6364H4.5455V48.8637C4.5455 49.4918 5.05377 50.0001 5.6819 50.0001C6.31002 50.0001 6.8183 49.4918 6.8183 48.8637V38.6364H43.1819V48.8637C43.1819 49.4918 43.6902 50.0001 44.3183 50.0001C44.9465 50.0001 45.4547 49.4918 45.4547 48.8637V38.6364H48.8638C49.492 38.6364 50.0002 38.1281 50.0002 37.5C50.0002 36.8719 49.492 36.3636 48.8638 36.3636H45.4547V34.0909H48.8637ZM43.1818 36.3636H6.81819V34.0909H43.1818V36.3636ZM2.27269 31.8182V6.81819H47.7273V31.8182H2.27269Z" fill={fill} className="svgFill" />
        <path d="M42.0455 13.6364H7.95459C7.32646 13.6364 6.81819 14.1446 6.81819 14.7728C6.81819 15.4009 7.32646 15.9092 7.95459 15.9092H42.0455C42.6736 15.9092 43.1819 15.4009 43.1819 14.7728C43.1819 14.1446 42.6736 13.6364 42.0455 13.6364Z" fill={fill} className="svgFill" />
        <path d="M42.0455 22.7272H7.95459C7.32646 22.7272 6.81819 23.2355 6.81819 23.8636C6.81819 24.4918 7.32646 25 7.95459 25H42.0455C42.6736 25 43.1819 24.4918 43.1819 23.8636C43.1818 23.2355 42.6736 22.7272 42.0455 22.7272Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H50V50H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

InfoFrameIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InfoFrameIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InfoFrameIcon)
