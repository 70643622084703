import { UPDATE_INTEGRATION } from './index'

export const requestUpdateIntegration = (payload) => ({
  type: UPDATE_INTEGRATION.REQUEST,
  payload,
})

export const successUpdateIntegration = (data) => ({
  type: UPDATE_INTEGRATION.SUCCESS,
  payload: data,
})

export const errorUpdateIntegration = () => ({
  type: UPDATE_INTEGRATION.ERROR,
})

export const eventsUpdateIntegration = () => ({
  type: UPDATE_INTEGRATION.EVENTS,
})
