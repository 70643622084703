import { DELETE_GROUP } from '../index'

export const requestDeleteTenantGroup = (payload) => ({
  type: DELETE_GROUP.REQUEST,
  payload,
})

export const responseDeleteTenantGroup = (payload) => ({
  type: DELETE_GROUP.SUCCESS,
  payload,
})

export const errorDeleteTenantGroup = (payload) => ({
  type: DELETE_GROUP.ERROR,
  payload,
})
