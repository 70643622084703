import { GET_ALL_APPLICATIONS } from './index'

export const requestGetApplications = (payload) => ({
  type: GET_ALL_APPLICATIONS.REQUEST,
  payload,
})

export const successGetApplications = (payload) => ({
  type: GET_ALL_APPLICATIONS.SUCCESS,
  payload,
})

export const errorGetApplications = (payload) => ({
  type: GET_ALL_APPLICATIONS.ERROR,
  payload,
})
