import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EMailIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="10.532" height="6.629" viewBox="0 0 10.532 6.629">
      <g id="Group_360" data-name="Group 360" transform="translate(-807.57 -182.003)">
        <path id="Path_98" data-name="Path 98" d="M812.843,200.612h-4.475a1.275,1.275,0,0,1-.358-.042.549.549,0,0,1-.409-.455,1.548,1.548,0,0,1-.028-.293q0-2.025,0-4.05a1,1,0,0,1,.018-.184c.014-.075.056-.1.129-.074a1.035,1.035,0,0,1,.156.07q1.089.58,2.178,1.162,1.046.56,2.09,1.124a1.468,1.468,0,0,0,.648.191,1.381,1.381,0,0,0,.709-.176l3.063-1.641,1.24-.663a.84.84,0,0,1,.149-.063c.088-.027.137.009.145.1,0,.014,0,.029,0,.043q0,.466,0,.932,0,.942,0,1.884,0,.743,0,1.487a.6.6,0,0,1-.551.63,3.409,3.409,0,0,1-.41.016q-1.43,0-2.859,0h-1.434Z" transform="translate(0 -11.982)" fill={fill} className="svgFill" />
        <path id="Path_99" data-name="Path 99" d="M810.642,182.009h2.348c.91,0,1.82-.005,2.73-.006q1.15,0,2.3,0a.813.813,0,0,1,.167.005.235.235,0,0,1,.128.36.759.759,0,0,1-.255.248c-.2.119-.409.232-.615.345l-3.63,2a1.525,1.525,0,0,1-.54.194,1.152,1.152,0,0,1-.665-.1c-.207-.1-.407-.215-.609-.326l-3.11-1.707c-.213-.117-.427-.233-.639-.352a.712.712,0,0,1-.327-.317.454.454,0,0,1-.037-.163.134.134,0,0,1,.1-.131.727.727,0,0,1,.2-.046c.106-.006.213,0,.32,0h2.137Z" transform="translate(-0.282 0)" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

EMailIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EMailIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EMailIcon)
