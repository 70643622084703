import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PrevIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 9L8.54691 17.6981C8.94244 18.1006 9.57558 18.1006 9.97111 17.6981L10.695 16.9614C11.1014 16.5479 11.1017 15.8687 10.6958 15.4546L4.36716 9L10.6958 2.54538C11.1017 2.13134 11.1014 1.45215 10.695 1.03857L9.97111 0.301896C9.57558 -0.100632 8.94244 -0.100632 8.54691 0.301895L0 9Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PrevIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PrevIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PrevIcon)
