import React, { useMemo, useContext, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { ThemeContext } from 'styled-components'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import Loader from '@/components/blocks/Loader'
import viewTreeIcons from '@/constants/viewTree'
import ArrowIcon from '@/components/icons/maps/ArrowIcon'

import { getPinCount } from '@/helpers/viewTree/getPinCount'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks'

import {
  TreeNodeArrow,
  TreeNode,
  TreeNodeIcon,
  TreeNodeTitle,
  TreeNodeObjects,
  TreeNodeInfo,
  TreeNodeIconAndInfo,
} from './styles'

const deleteNodeChildren = (node = {}, defaultParameters = {}) => ({
  ...node,
  children: null,
  data: {
    ...(node.data || {}),
    children: null,
  },
  options: {
    ...(node.options || {}),
    parent: null,
  },
  ...defaultParameters,
})

const TreeNodeContainer = ({
  node, onToggle, onSelect, onSelectNode, getTelemetry, telemetryHash,
}) => {
  const Icon = viewTreeIcons[node.data.type] || React.Fragment
  const theme = useContext(ThemeContext)
  const nodeStatus = useMemo(() => {
    if (getTelemetry && telemetryHash) {
      const telemetry = getTelemetry(node.data)
      if (telemetry && telemetry.status) {
        return telemetry.status
      }
    }
    return (node.data || {}).status
  }, [node, getTelemetry, telemetryHash])

  const nodeForSave = useMemo(() => ({
    ...node.data,
    original: deleteNodeChildren(node, {
      options: {
        ...node.options,
        parent: deleteNodeChildren(node.options.parent),
      },
    }),
  }), [node])

  const handleSelect = useCallback((event) => {
    onSelect(event)
    onSelectNode(nodeForSave, 'click')
  }, [onSelect, onSelectNode, nodeForSave])

  const handleToggle = useCallback((event) => {
    onToggle(event)
    onSelectNode(nodeForSave, 'toggle')
  }, [onToggle, onSelectNode, nodeForSave])

  const hasChildren = node.children.length > 0 || node.data.childrenCount > 0

  const returnName = (name) => {
    const limit = 25
    return (
      <>
        {((name || '').length > limit)
          ? (
            <PortalTooltip
              title={name}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <TreeNodeTitle
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  {(name || '').substr(0, limit)}
                  ...
                </TreeNodeTitle>
              )}
            />
          )
          : (
            <TreeNodeTitle>
              {name}
            </TreeNodeTitle>
          )}
      </>
    )
  }

  return (
    <TreeNode isRoot={node.options.root}>
      <TreeNodeIconAndInfo onClick={handleSelect}>
        <TreeNodeIcon>
          {nodeStatus
            ? <Icon color={STATUS_COLORS[nodeStatus]} />
            : <Icon color={theme.colors.colors.default} />}
        </TreeNodeIcon>
        <TreeNodeInfo>
          {returnName(node.data.name)}
          <TreeNodeObjects dark={node.options.selected}>
            {hasChildren && `Объектов: ${node.data.childrenCount || getPinCount(node.data) || 'неизвестно'}`}
          </TreeNodeObjects>
        </TreeNodeInfo>
      </TreeNodeIconAndInfo>
      {hasChildren && !node.options.loading && (
        <TreeNodeArrow transform={node.options.opened} onClick={handleToggle}>
          <ArrowIcon />
        </TreeNodeArrow>
      )}
      {node.options.loading && (
        <Loader fill />
      )}
    </TreeNode>
  )
}

TreeNodeContainer.propTypes = {
  onToggle: pt.func,
  onSelect: pt.func,
  onSelectNode: pt.func,
  getTelemetry: pt.func,
  telemetryHash: pt.string,
  node: pt.shape({
    children: pt.arrayOf([pt.object]),
    data: pt.shape({
      name: pt.string,
      type: pt.string,
      status: pt.string,
      childrenCount: pt.number,
    }),
    options: pt.shape({
      selected: pt.bool,
      loading: pt.bool,
      opened: pt.bool,
      root: pt.bool,
    }),
  }),
}

TreeNodeContainer.defaultProps = {
  onToggle: noop,
  onSelect: noop,
  onSelectNode: noop,
  getTelemetry: noop,
  telemetryHash: '',
  node: {},
}

export default TreeNodeContainer
