import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ResetIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.4853 21.4853C16.799 26.1716 9.20101 26.1716 4.51472 21.4853C-0.171573 16.799 -0.171573 9.20101 4.51472 4.51472M21.4853 21.4853C26.1716 16.799 26.1716 9.20101 21.4853 4.51472C16.799 -0.171573 9.20101 -0.171573 4.51472 4.51472M21.4853 21.4853L4.51472 4.51472" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

ResetIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ResetIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ResetIcon)
