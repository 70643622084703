import React, { useState, useCallback } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import { useTimeCounter } from '@/hooks/useTimeCounter'
import MenuIcons from '@/components/icons/menu'
import {
  RATING_COLUMN,
  APPEALS_COLUMN,
  INFRASTRUCTURE_COLUMN,

  RATING_COLUMN_NAME,
  APPEALS_COLUMN_NAME,
  INFRASTRUCTURE_COLUMN_NAME,

  GOV_WIDGETS_COLORS,
  GOV_WIDGETS_ICONS,
} from '@/constants/widgetsDashboardGov'

import { WIDGETS_COMPONENTS } from './widgets'

import BaseWidget from './components/BaseWidget'

import {
  Container,
  Header,
  Title,
  CityName,
  TimeTitle,
  Time,
  Content,
} from './styles'

const WIDGETS_BY_COLUMNS = {
  [RATING_COLUMN_NAME]: RATING_COLUMN,
  [APPEALS_COLUMN_NAME]: APPEALS_COLUMN,
  [INFRASTRUCTURE_COLUMN_NAME]: INFRASTRUCTURE_COLUMN,
}

const DashboardGovApp = () => {
  const time = useTimeCounter(+5)
  const [selectedWidgets, setSelectedWidgets] = useState({
    [RATING_COLUMN_NAME]: [RATING_COLUMN[0]],
    [APPEALS_COLUMN_NAME]: [APPEALS_COLUMN[0]],
    [INFRASTRUCTURE_COLUMN_NAME]: [INFRASTRUCTURE_COLUMN[0]],
  })

  const handleAddWidget = useCallback((columnName) => (widgetName) => () => {
    const isWidgetSelected = selectedWidgets[columnName].includes(widgetName)
    const newWidgetsConfig = isWidgetSelected
      ? selectedWidgets[columnName].filter((name) => name !== widgetName)
      : [...selectedWidgets[columnName], widgetName]
    setSelectedWidgets({
      ...selectedWidgets,
      [columnName]: newWidgetsConfig,
    })
  }, [selectedWidgets])

  const renderWidget = useCallback((columnName, columnIndex) => (name, index) => {
    const currentIndex = WIDGETS_BY_COLUMNS[columnName].indexOf(name)
    const nextItem = WIDGETS_BY_COLUMNS[columnName][currentIndex + 1]
    const Component = WIDGETS_COMPONENTS[name]
    return (
      <BaseWidget
        style={{ gridArea: `w${columnIndex + 1}${index + 1}` }}
        title={(
          <>
            <i className={`fa fa-2 ${GOV_WIDGETS_ICONS[name]}`} aria-hidden="true" />
            <Lang id={`widgetsDashboardGov.${name}`} />
          </>
        )}
        onWidgetClick={(columnName !== INFRASTRUCTURE_COLUMN_NAME && nextItem)
          ? handleAddWidget(columnName)(nextItem)
          : noop}
        colors={GOV_WIDGETS_COLORS[name]}
      >
        <Component
          onWidgetClick={columnName === INFRASTRUCTURE_COLUMN_NAME
            ? handleAddWidget(columnName)
            : noop}
        />
      </BaseWidget>
    )
  }, [handleAddWidget])
  const renderColumn = useCallback((columnName, index) => (
    <>
      <Title style={{ gridArea: `t${index + 1}` }}><Lang id={`widgetsDashboardGov.${columnName}`} /></Title>
      {selectedWidgets[columnName].map(
        renderWidget(columnName, index),
      )}
    </>
  ), [selectedWidgets, renderWidget])
  return (
    <Container>
      <Header>
        <Title>
          <MenuIcons.TyumenIcon />
          <Lang id="widgetsDashboardGov.myCity" />
          {' '}
          <CityName>Тюмень</CityName>
        </Title>
        <TimeTitle>
          <i className="fa fa-clock-o fa-2" aria-hidden="true" />
          <Lang id="widgetsDashboardGov.time" />
          {' '}
          <Time>{time}</Time>
        </TimeTitle>
      </Header>
      <Content>
        {Object.keys(selectedWidgets).map(renderColumn)}
      </Content>
    </Container>
  )
}

DashboardGovApp.propTypes = {}
DashboardGovApp.defaultProps = {}

export default DashboardGovApp
