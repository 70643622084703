import React, {
  useMemo, useContext,
} from 'react'
import { ThemeContext } from 'styled-components'
import pt from 'prop-types'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import ChartToolTip from '@/components/charts/ChartToolTip'
import { graphOfDifferenceConsumptionFormatter } from '@/helpers/formaters/graphFormaters'

const LineChartConsumption = ({
  isStaked,
  data,
  mainAxisName,
  seriesNames,
  unit,
  tickFormatter,
  type,
}) => {
  const theme = useContext(ThemeContext)
  const formattedData = useMemo(() => {
    if (isStaked) {
      return graphOfDifferenceConsumptionFormatter(data)
    }
    return data
  }, [data, isStaked])

  return (
    <ResponsiveContainer>
      <LineChart
        width={1200}
        height={900}
        maxBarSize={25}
        data={formattedData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <XAxis
          tickLine={false}
          dataKey={mainAxisName}
          stroke={theme.colors.colors.default}
        />
        <YAxis
          domain={[0, (dataMax) => dataMax * 1.2]}
          tickFormatter={(tickValue) => Math.floor(tickValue / tickFormatter)}
          stroke={theme.colors.colors.default}
          unit={unit}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          active={false}
          cursor={false}
          content={(<ChartToolTip type="difference" unit={unit} objectType={type} />)}
        />
        {seriesNames.map((element) => (
          <Line
            dataKey={element.name}
            stackId={isStaked && element.stackGroupId}
            fill={element.fill}
            stroke={element.stroke}
            name={element.title}
            type={element.type}
            activeDot={{ r: 4 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

LineChartConsumption.propTypes = {
  data: pt.arrayOf(pt.object),
  isStaked: pt.bool,
  mainAxisName: pt.string,
  seriesNames: pt.arrayOf(pt.object),
  tickFormatter: pt.number,
  unit: pt.string,
}
LineChartConsumption.defaultProps = {
  data: [],
  isStaked: false,
  mainAxisName: '',
  seriesNames: [],
  unit: '',
  tickFormatter: 1,
}

export default LineChartConsumption
