import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PlusIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 18C9.82843 18 10.5 17.3284 10.5 16.5V10.5L16.5 10.5C17.3284 10.5 18 9.82843 18 9C18 8.17157 17.3284 7.5 16.5 7.5L10.5 7.5L10.5 1.5C10.5 0.671574 9.82843 0 9 0C8.17157 0 7.5 0.671572 7.5 1.5V7.5H1.5C0.671574 7.5 0 8.17157 0 9C0 9.82843 0.671574 10.5 1.5 10.5L7.5 10.5L7.5 16.5C7.5 17.3284 8.17157 18 9 18Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PlusIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PlusIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PlusIcon)
