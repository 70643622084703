import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  GET_CONSUMPTION_ANALYSIS_FILE,
} from '@/store/actions/consumptionAnalysis'
import { generateResourceAnalyticsReportFileUrl } from '@/constants/apiRoutes'
import {
  successConsumptionAnalysisFile,
  errorConsumptionAnalysisFile,
} from '@/store/actions/consumptionAnalysis/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getForm,
  getTableParameters,
} from '@/store/selectors/consumptionAnalysis'
import { LIGHT } from '@/constants/objectTypes'

export const EXTENSION_BY_TYPE = {
  pdf: 'pdf',
  excel: 'xlsx',
}

function* getConsumptionAnalysisFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const formValues = yield select(getForm)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const {
      type,
      dateFirst,
      dateSecond,
    } = formValues
    const dataJson = {
      installationId: node.internalId || null,
      addressId: node.type === LIGHT ? node.id : null,
      type,
      range: {
        start: moment(dateFirst).format('DD.MM.YYYY'),
        end: moment(dateSecond).format('DD.MM.YYYY'),
      },
      includedColumns: tableParameters.activeFields,
      query: tableParameters.requestQuery || '',
      sort: {
        type: tableParameters.sort.direction,
        value: tableParameters.sort.field,
      },
      page: tableParameters.page - 1,
      size: tableParameters.perPage,
    }

    const response = yield request({
      url: generateResourceAnalyticsReportFileUrl({
        fileType: payload,
      }),
      method: 'post',
      body: dataJson,
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${node.internalId || 'all'}_${type}_table_report.${EXTENSION_BY_TYPE[payload]}`,
    })
    yield put(successConsumptionAnalysisFile())
  } catch (error) {
    yield put(errorConsumptionAnalysisFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет. Не выбран ни один столбец для формирования отчета',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_CONSUMPTION_ANALYSIS_FILE.REQUEST, getConsumptionAnalysisFileSaga)
}
