import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const useSvgColors = (color) => {
  const theme = useContext(ThemeContext)
  const themeColor = theme
    ? theme.colors.colors.svg
    : '#FFFFFF'

  return {
    fill: color || themeColor,
    stroke: color || themeColor,
    theme,
  }
}

export default useSvgColors
