import React from 'react'
import LiveTime from '@/components/icons/livetime'
import AlarmsIcons from '@/components/icons/alarms'
import CustomEventCell from '@/components/blocks/DutyTable/customCells/CustomEventCell'
import CustomObjectCell from '@/components/blocks/DutyTable/customCells/CustomObjectCell'
import {
  AlarmEventContainer,
} from '@/components/blocks/DutyTable/customCells/CustomEventCell/styles'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'

const EventError = () => (
  <AlarmEventContainer type={ERROR} sm>
    <AlarmsIcons.ErrorIcon />
  </AlarmEventContainer>
)
const EventWarning = () => (
  <AlarmEventContainer type={WARNING} sm>
    <AlarmsIcons.WarningIcon />
  </AlarmEventContainer>
)
const EventCorrected = () => (
  <AlarmEventContainer type={OK} sm>
    <AlarmsIcons.OkIcon />
  </AlarmEventContainer>
)

const EventUnknown = () => (
  <AlarmEventContainer type={UNKNOWN} sm>
    <AlarmsIcons.UnknownIcon />
  </AlarmEventContainer>
)

export const eventsOptions = [
  {
    name: 'Неисправно',
    icon: EventError,
    id: ERROR,
  },
  {
    name: 'Предупреждение',
    icon: EventWarning,
    id: WARNING,
  },
  {
    name: 'Исправно',
    icon: EventCorrected,
    id: OK,
  },
  {
    name: 'Неопределено',
    icon: EventUnknown,
    id: UNKNOWN,
  },
]

export const fields = [
  {
    id: 'object',
    name: 'Объект',
    value: 'ASC',
    customCell: CustomObjectCell,
    withSelectAll: true,
  },
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
  },
  {
    id: 'address',
    name: 'Адрес',
    value: 'ASC',
  },
  {
    id: 'alertState',
    name: 'Событие',
    value: 'ASC',
    customCell: CustomEventCell,
  },
  {
    id: 'description',
    name: 'Описание события',
    value: 'ASC',
  },
  {
    id: 'occurrenceDate',
    name: 'Дата возникновения',
    value: 'ASC',
  },
  {
    id: 'decisionDate',
    name: 'Дата решения',
    value: 'ASC',
  },
  {
    id: 'statePeriod',
    name: 'Период состояния',
    value: 'ASC',
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    value: 'xlsx',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]
export const settingsOptions = [
  {
    name: 'Все статусы',
    id: 'all',
  },
  {
    name: 'Новые',
    id: 'new',
  },
  {
    name: 'Просмотренные',
    id: 'old',
  },
]

export default {
  fields,
  exportOptions,
  eventsOptions,
  settingsOptions,
}
