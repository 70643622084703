import React, { useMemo } from 'react'
import pt from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { childrenType } from '@/propTypes/react'
import { getTheme } from '@/constants/styles'
import GlobalStyle from './globalStyles/appGeneralStyles'

export const ThemeContext = React.createContext()

const ThemeProviderWrapper = ({
  theme, scheme, isLargeFont, children,
}) => {
  const themeObject = useMemo(() => getTheme({
    themeName: theme,
    schemeName: scheme,
    largeFont: isLargeFont,
  }), [theme, scheme, isLargeFont])
  return (
    <ThemeProvider theme={themeObject}>
      <ThemeContext.Provider value={{ theme }}>
        <GlobalStyle />
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}

ThemeProviderWrapper.propTypes = {
  theme: pt.string.isRequired,
  scheme: pt.string.isRequired,
  isLargeFont: pt.bool,
  children: childrenType.isRequired,
}
ThemeProviderWrapper.defaultProps = {
  isLargeFont: false,
}

export default ThemeProviderWrapper
