import { CREATE_INTEGRATION } from './index'

export const requestCreateIntegration = (payload) => ({
  type: CREATE_INTEGRATION.REQUEST,
  payload,
})

export const successCreateIntegration = (data) => ({
  type: CREATE_INTEGRATION.SUCCESS,
  payload: data,
})

export const errorCreateIntegration = () => ({
  type: CREATE_INTEGRATION.ERROR,
})

export const eventsCreateIntegration = () => ({
  type: CREATE_INTEGRATION.EVENTS,
})
