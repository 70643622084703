import { COUNTRY, REGION, CITY } from '@/constants/objectTypes'

const excludedElements = {
  [COUNTRY]: true,
  [REGION]: true,
  [CITY]: true,
}

export const getPinCount = (node, excludedTypes = [], count = 0) => {
  const current = count
  if (typeof (node.status) !== 'undefined' && !node.children) {
    if (excludedTypes.includes(node.type || '')) {
      return current
    }
    return current + 1
  }
  if (node.children && typeof (node.usersAmount) !== 'number') {
    return node.children.reduce((accumulator, element) => {
      let result = accumulator + getPinCount(element, excludedTypes)
      if (element.children && !excludedElements[element.type]) {
        result += 1
      }
      return result
    }, 0)
  }
  if (!current) {
    return node.usersAmount
  }
  return current
}

export default getPinCount
