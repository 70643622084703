import { handleActions } from 'redux-actions'

import {
  LIGHT_THEME_NAME,
  SKY_SCHEME_NAME,
} from '@/constants/names'
import {
  SELECT_THEME,
  SELECT_FONTSIZE,
  SELECT_SCHEME,
  UPDATE_WIDGETS_LIST,
  UPDATE_APPLICATIONS_LIST,
  SET_SELECTED_NODE,
  UNPIN_NODE,
  MENU_SIDEBAR_PINNED,
  SET_GLOBAL_FILTER_VALUES,
  RESET_GLOBAL_FILTER_VALUES,
  LOCATION_CHANGE,
} from '@/store/actions/appSettings'
import {
  ADD_SERVICE_DESK_INTEGRATION,
  DELETE_SERVICE_DESK_INTEGRATION,
  ADD_TENANT_SERVICE_DESK_INEGRATION,
  USER_CHANGE_SERVICE_DESK,
} from '@/store/actions/serviceDeskMyCity'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'

import { USER_LOGOUT, GET_SETTINGS, GET_WIDGET_SETTINGS } from '@/store/actions/user'

const initialState = {
  appSettings: {
    theme: LIGHT_THEME_NAME,
    scheme: SKY_SCHEME_NAME,
    largeFont: false,
    isMenuSideBarPinned: false,
  },
  generalPage: {
    widgets: [],
    applications: [],
  },
  pinnedNode: {},
  selectedNode: {},
  serviceDeskIntegrationStatus: [],
  tenantServiceDeskIntegrationStatus: [],
  globalFilterValues: {
    [OK]: true,
    [WARNING]: true,
    [ERROR]: true,
    [INFO]: true,
  },
}

export const reducer = handleActions(
  {
    [SELECT_THEME]: (state, { payload: { theme } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        theme,
      },
    }),
    [SELECT_SCHEME]: (state, { payload: { scheme } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        scheme,
      },
    }),
    [SELECT_FONTSIZE]: (state, { payload: { large } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        largeFont: large,
      },
    }),
    [MENU_SIDEBAR_PINNED]: (state, { payload: { isOpen } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        isMenuSideBarPinned: isOpen,
      },
    }),
    [UPDATE_WIDGETS_LIST]: (state, { payload: { widgets } }) => ({
      ...state,
      generalPage: {
        ...state.generalPage,
        widgets,
      },
    }),
    [UPDATE_APPLICATIONS_LIST]: (state, { payload: { applications } }) => ({
      ...state,
      generalPage: {
        ...state.generalPage,
        applications,
      },
    }),
    [SET_SELECTED_NODE]: (state, { payload: { node, withoutPinned } }) => ({
      ...state,
      selectedNode: node,
      ...(withoutPinned ? {} : { pinnedNode: node }),
    }),
    [UNPIN_NODE]: (state) => ({
      ...state,
      pinnedNode: {},
    }),
    [SET_GLOBAL_FILTER_VALUES]: (state, { payload }) => ({
      ...state,
      globalFilterValues: payload,
    }),
    [RESET_GLOBAL_FILTER_VALUES]: (state) => ({
      ...state,
      globalFilterValues: {
        [OK]: true,
        [WARNING]: true,
        [ERROR]: true,
        [INFO]: true,
      },
    }),
    [USER_LOGOUT.SUCCESS]: (state) => ({
      ...state,
      ...initialState,
    }),
    [GET_SETTINGS.SUCCESS]: (state, { payload: { appSettings, generalPage, globalFilterValues, pinnedNode } }) => ({
      ...state,
      appSettings,
      globalFilterValues,
      pinnedNode,
      generalPage: {
        ...state.generalPage,
        applications: generalPage.applications,
      },
    }),
    [GET_WIDGET_SETTINGS.SUCCESS]: (state, { payload: { serviceDeskIntegrationStatus, generalPage } }) => ({
      ...state,
      generalPage: {
        ...state.generalPage,
        widgets: generalPage.widgets,
     },
     serviceDeskIntegrationStatus
    }),
    [ADD_TENANT_SERVICE_DESK_INEGRATION]: (state, { payload }) => ({
      ...state,
      tenantServiceDeskIntegrationStatus: payload,
    }),
    [DELETE_SERVICE_DESK_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      serviceDeskIntegrationStatus: payload,
    }),
    [ADD_SERVICE_DESK_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      serviceDeskIntegrationStatus: payload,
    }),
    [USER_CHANGE_SERVICE_DESK]: (state, { payload }) => ({
      ...state,
      serviceDeskIntegrationStatus: payload,
    }),
    [LOCATION_CHANGE]: (state) => ({
      ...state,
      selectedNode: {},
    }),
  },
  initialState,
)

export default reducer
