import { connect } from 'react-redux'
import {
  getCountOfPins,
} from '@/store/selectors/maps/socketEvents'
import {
  getEventsNewCount,
  getEventsCount,
} from '@/store/selectors/alarmManager'
import {
  getUserData,
  getWidgets,
  getApplications,
  getAlarmsWidgetOpenState,
  getIntegrationStatus,
  getUserApplications,
} from '@/store/selectors/appSettings'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import { requestGetAlarmsCount } from '@/store/actions/alarmManager/getAlarmsCount'
import { toggleAlarmsWidgetSettings, startDeleteWidgetFromGeneralPage } from '@/store/actions/appSettings'
import { requestAnalyticsAlarmsWidget } from '@/store/actions/analyticsAlarms/getWidgetInformation'
import { requestTyumenMessagesWidget } from '@/store/actions/tyumenOurHome/getMessagesWidget'
import generateUserAttributes from '@/helpers/generateUserAttributes'
import { getSystems } from '@/store/selectors/integrations'
import { getWidgetsData } from '@/store/selectors/customWidgetsData'
import { requestGetInfoWidget } from '@/store/actions/serviceDeskMyCity/getInfoWidget'
import { requestGetCurrentUser } from '@/store/actions/users/getCurrentUser'
import Dashboard from './component'
import { requestGetWidgetSettings } from '@/store/actions/user/settings/getWidgetSettings';

const mapStateToProps = (state) => ({
  pins: getCountOfPins(state),
  eventCount: getEventsCount(state),
  newEventCount: getEventsNewCount(state),
  widgets: getWidgets(state),
  applications: getApplications(state),
  isWidgetSettingsOpen: getAlarmsWidgetOpenState(state),
  user: getUserData(state),
  userApplications: getUserApplications(state),
  projectCount: 8,
  serviceDeskStatus: getIntegrationStatus(state),
  widgetsData: getWidgetsData(state),
  ...generateUserAttributes(
    getUserData(state),
    getSystems(state),
  ),
})

const mapDispatchToProps = (dispatch) => ({
  onGetMapObjects: () => dispatch(requestGetMapObjects()),
  onGetAlarmsCount: (tenantId) => dispatch(requestGetAlarmsCount(tenantId)),
  onToggleAlarmsWidgetSettings: (isOpen) => dispatch(toggleAlarmsWidgetSettings(isOpen)),
  onGetAnalyticWidgetData: (payload) => dispatch(requestAnalyticsAlarmsWidget(payload)),
  onGetTyumenWidgetData: (payload) => dispatch(requestTyumenMessagesWidget(payload)),
  requestGetInfoWidget: (payload) => dispatch(requestGetInfoWidget(payload)),
  requestGetCurrentUser: (payload) => dispatch(requestGetCurrentUser(payload)),
  requestGetWidgetSettings: () => dispatch(requestGetWidgetSettings()),
  onDeleteWidget: (id, widgetType, integrationId) => dispatch(
    startDeleteWidgetFromGeneralPage(id, widgetType, integrationId),
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
