import { all } from 'redux-saga/effects'

import socketEventsRoot from './events'
import getTable from './getTable'
import getFile from './getFile'
import getAlarmsCount from './getAlarmsCount'
import getAverage from './getAverage'
import setEventRead from './setEventRead'
import setEventAllRead from './setEventAllRead'
import checkAllAlarms from './checkAllAlarms'

export default function* root() {
  yield all([
    socketEventsRoot(),
    getTable(),
    getFile(),
    getAlarmsCount(),
    getAverage(),
    setEventRead(),
    setEventAllRead(),
    checkAllAlarms(),
  ])
}
