import widgets from '../widgets'

const {
  LIGHT_STATUS,
  LIGHT_LEVEL,
  ENERGY_CONSUMED_ARRAY,
  VOLTAGE_ARRAY,
  AMPERAGE_ARRAY,
  POWER_ARRAY,
  FREQUENCY,
  ELECTRICAL_RESISTANCE_ARRAY,
} = widgets

export default [
  LIGHT_STATUS({}),
  LIGHT_LEVEL({}),
  ENERGY_CONSUMED_ARRAY({ count: 4, number: 1 }),
  VOLTAGE_ARRAY({ count: 3, number: 1 }),
  AMPERAGE_ARRAY({ count: 3, number: 1 }),
  POWER_ARRAY({ count: 3, number: 1 }),
  FREQUENCY({}),
  ELECTRICAL_RESISTANCE_ARRAY({ count: 3, number: 1 }),
]
