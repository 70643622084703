export const OUTDOOR_LIGHTNING = 'OUTDOOR_LIGHTNING'
export const ROAD_REPAIRING = 'ROAD_REPAIRING'
export const TRANSPORT_STATION = 'TRANSPORT_STATION'

const widgetConfig = [
  {
    id: OUTDOOR_LIGHTNING,
    title: 'Наружное освещение',
  },
  {
    id: ROAD_REPAIRING,
    title: 'Ремонт и реконструкция дорог',
  },
  {
    id: TRANSPORT_STATION,
    title: 'Остановки общественного транспорта',
  },
]

export const IN_PROGRESS_STATUSSES = ['Готовится ответ']
export const ERROR_STATUSSES = ['Мотивированный отказ', 'Проблема не обнаружена']
export const DONE_STATUSSES = ['Проблема решена', 'Запланировано']

export default widgetConfig
