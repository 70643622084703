import {
  CONTROLLER as CONTROLLER_SERVER,
  CONTROLLER_FUSE as CONTROLLER_FUSE_SEVER,
  SHUO as SHUO_SEVER,
  BASE_STATION as BASE_STATION_SEVER,
  COMPANY_OBJECT as COMPANY_OBJECT_SEVER,
  CONTROL_CUPBOARD as CONTROL_CUPBOARD_SEVER,
  CONTROLLER as CONTROLLER_SEVER,
} from '@/constants/instalationPassport/types'
import { CONTROLLER } from '@/constants/objectTypes'

export const isController = (type) => {
  return type === CONTROLLER_SERVER
    || type === CONTROLLER
    || type === CONTROLLER_FUSE_SEVER
    || type === SHUO_SEVER
    || type === BASE_STATION_SEVER
    || type === COMPANY_OBJECT_SEVER
    || type === CONTROL_CUPBOARD_SEVER
    || type === CONTROLLER_SEVER
}
export default isController
