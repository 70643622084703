import widgets from '../widgets'

const {
  ACTIVE_LIGHT,
  ALARM_STATE,
} = widgets

export default [
  ACTIVE_LIGHT({}),
  ALARM_STATE({}),
]
