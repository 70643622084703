import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ConstrollIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg className="svgFill" onClick={onClick} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M16.9091 1.61538H8.67865L7.56342 0.365199C7.35632 0.133034 7.05808 0 6.74471 0H1.09091C0.488417 0 0 0.482155 0 1.07692V12.9231C0 13.5178 0.488417 14 1.09091 14H16.9091C17.5116 14 18 13.5178 18 12.9231V2.69231C18 2.09754 17.5116 1.61538 16.9091 1.61538ZM5.78571 7.63636C5.78571 8.33927 5.21008 8.90909 4.5 8.90909C3.78992 8.90909 3.21429 8.33927 3.21429 7.63636C3.21429 6.93346 3.78992 6.36364 4.5 6.36364C5.21008 6.36364 5.78571 6.93346 5.78571 7.63636ZM10.2857 7.63636C10.2857 8.33927 9.71008 8.90909 9 8.90909C8.28992 8.90909 7.71429 8.33927 7.71429 7.63636C7.71429 6.93346 8.28992 6.36364 9 6.36364C9.71008 6.36364 10.2857 6.93346 10.2857 7.63636ZM13.5 8.90909C14.2101 8.90909 14.7857 8.33927 14.7857 7.63636C14.7857 6.93346 14.2101 6.36364 13.5 6.36364C12.7899 6.36364 12.2143 6.93346 12.2143 7.63636C12.2143 8.33927 12.7899 8.90909 13.5 8.90909Z" fill={fill} />
    </svg>
  )
}

ConstrollIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ConstrollIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ConstrollIcon)
