import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import SearchAndFilter from '../SearchAndFilter'

import {
  Container,
} from './styles'

const NewIntegration = ({
  integrationTypes,
  integrationOptions,
  click,
}) => (
  <Container>
    <SearchAndFilter
      integrationTypes={integrationTypes}
      integrationOptions={integrationOptions}
      click={click}
    />
  </Container>
)

NewIntegration.defaultProps = {
  integrationTypes: [],
  integrationOptions: [],
  click: noop,
}
NewIntegration.propTypes = {
  integrationTypes: pt.arrayOf(pt.shape({
    id: pt.number,
    tag: pt.string,
    name: pt.string,
    type: pt.string,
  })),
  integrationOptions: pt.arrayOf(pt.shape({
    id: pt.number,
    tags: pt.arrayOf(pt.string),
    name: pt.string,
    type: pt.string,
  })),
  click: pt.func,
}

export default NewIntegration
