import { GENERATE_INSTALLATION_OBJECT_FILE } from './index'


export const requestGetInstallationObjectPDFFile = (payload) => ({
  type: GENERATE_INSTALLATION_OBJECT_FILE.REQUEST,
  payload,
})

export const successGetInstallationObjectPDFFile = (payload) => ({
  type: GENERATE_INSTALLATION_OBJECT_FILE.SUCCESS,
  payload,
})

export const errorGetInstallationObjectPDFFile = (payload) => ({
  type: GENERATE_INSTALLATION_OBJECT_FILE.ERROR,
  payload,
})
