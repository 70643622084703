import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import CheckBox from '@/components/controls/CheckBox'

const CheckBoxField = ({ label, onAfterChange, ...ownProps }) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const { isSubmitting } = form

      const onChange = (value) => {
        form.setFieldValue(field.name, value)
        if (onAfterChange) {
          onAfterChange(field.name, value)
        }
      }

      return (
        <CheckBox
          {...ownProps}
          {...field}
          onChange={onChange}
          label={label}
          disabled={isSubmitting}
        />
      )
    }}
  </Field>
)

CheckBoxField.defaultProps = {
  label: null,
}

CheckBoxField.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
}

export default React.memo(CheckBoxField)
