import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MagnifierIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0105 14.581C17.6988 12.2124 17.4805 8.90253 15.3554 6.7765C12.9868 4.40783 9.14617 4.40783 6.7765 6.7765C4.40783 9.14617 4.40783 12.9858 6.7765 15.3554C8.90253 17.4805 12.2124 17.6988 14.581 16.0105L15.5465 16.976C15.3666 17.6503 15.5404 18.4004 16.0702 18.9291L19.5489 22.4078C20.3384 23.1974 21.6183 23.1974 22.4078 22.4078C23.1974 21.6183 23.1974 20.3384 22.4078 19.5489L18.9291 16.0702C18.4004 15.5404 17.6503 15.3666 16.976 15.5465L16.0105 14.581ZM13.9249 8.20699C15.504 9.7861 15.5051 12.3458 13.9249 13.9249C12.3458 15.5051 9.7861 15.5051 8.20699 13.9249C6.62789 12.3458 6.62687 9.7861 8.20699 8.20699C9.7861 6.62687 12.3458 6.62687 13.9249 8.20699Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MagnifierIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MagnifierIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MagnifierIcon)
