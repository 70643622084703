import {
  TEXT
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.LATITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.LONGITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.REGION',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.CITY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'SURVEY_DATA.CONNECTED_LAMP_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.LOCATIONType',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'OBJECT_CHARACTERISTIC.IMPORTANCE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'OBJECT_CHARACTERISTIC',
  },
  {
    selector: 'SURVEY_DATA.ROADWAY_WIDTH',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.INSTALLATION_SCHEME',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.STATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.POWER_SUPPLY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SURVEY_DATA.POWER_SUPPLY_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SURVEY_DATA',
  },
  {
    selector: 'SERVICING.INSPECTION_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.BYPASS_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.MAINTENANCE_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.REPAIR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.CAPITAL_REPAIR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
]
