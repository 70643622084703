import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  GET_LIVE_TIME_REPORT_FILE,
} from '@/store/actions/lifeTimeReport'
import { generateLiveTimeReportFileUrl } from '@/constants/apiRoutes'
import {
  successLiveTimeReportFile,
  errorLiveTimeReportFile,
} from '@/store/actions/lifeTimeReport/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getGraphType,
  getTableParameters,
  getSelectedGraphPercentOptions,
  getSelectedGraphYearOptions,
} from '@/store/selectors/lifeTimeReport'
import { LIGHT } from '@/constants/objectTypes'

export const EXTENSION_BY_TYPE = {
  pdf: 'pdf',
  excel: 'xlsx',
}

function* getLiveTimeReportFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const type = yield select(getGraphType)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)

    const selectedGraphPercentOptions = yield select(getSelectedGraphPercentOptions)
    const selectedGraphYearOptions = yield select(getSelectedGraphYearOptions)

    const isTable = payload.type === 'table'

    const nodeParameters = {
      installationId: (node && node.internalId) ? node.internalId : null,
      addressId: (node && node.type === LIGHT) ? node.id : null,
    }

    const response = yield request({
      url: qs.stringifyUrl({
        url: generateLiveTimeReportFileUrl({
          ...payload,
          fileType: EXTENSION_BY_TYPE[payload.fileType],
        }),
        query: !isTable
          ? {
            graphType: type,
            ...nodeParameters,
          }
          : {},
      }, { skipNull: true }),
      method: isTable ? 'post' : 'get',
      body: {
        ...nodeParameters,
        parameter: {
          type,
          options: type === 'graphPercent'
            ? selectedGraphPercentOptions
            : selectedGraphYearOptions,
        },
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${node.internalId || 'all'}_${type}_${payload.type}_report.${EXTENSION_BY_TYPE[payload.fileType]}`,
    })

    yield put(successLiveTimeReportFile())
  } catch (error) {
    yield put(errorLiveTimeReportFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет. Не выбран ни один столбец для формирования отчета',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_LIVE_TIME_REPORT_FILE.REQUEST, getLiveTimeReportFileSaga)
}
