import React, {
  useRef,
  useEffect,
  useState,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'

const BigWidthChildren = ({
  callback,
  renderChildren,
}) => {
  const childrenRef = useRef(null)
  const [isBigWidthChildren, setIsBigWidthChildren] = useState(false)
  useEffect(() => {
    if (childrenRef.current
        && childrenRef.current.offsetWidth < childrenRef.current.firstChild.offsetWidth
    ) {
      setIsBigWidthChildren(true)
    }
  }, [callback])
  return (
    <>
      {renderChildren(childrenRef, isBigWidthChildren)}
    </>
  )
}

BigWidthChildren.propTypes = {
  renderChildren: pt.func.isRequired,
  callback: pt.func,
}
BigWidthChildren.defaultProps = {
  callback: noop,
}

export default BigWidthChildren
