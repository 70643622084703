import { SET_WIDGET_SETTINGS } from '../index'

export const requestSetWidgetSettings = () => ({
    type: SET_WIDGET_SETTINGS.REQUEST,
})

export const successSetWidgetSettings = (payload) => ({
    type: SET_WIDGET_SETTINGS.SUCCESS,
    payload,
})

export const errorSetWidgetSettings = (error) => ({
    type: SET_WIDGET_SETTINGS.ERROR,
    payload: error,
})