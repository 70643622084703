import {
  takeLatest
} from 'redux-saga/effects'
import get from 'lodash/get'
import request from '@/helpers/axios'
import { INSTALLATION_FILE_SCHEME_UPLOAD, INSTALLATION_ERROR_FILE_DOWNLOAD } from '@/constants/apiRoutes'
import { DOWNLOAD_MULTIPLE_OBJECT } from '@/store/actions/installation'
import createNotifications from '@/helpers/notification'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'

const typeToConfig = {
  'error.file.id': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Ошибка валидации файла.',
  },
  'error.wrong.file.size': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Неверный размер файла.',
  },
  'error.wrong.file.media.type': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Неверное расширение файла.',
  },
  'error.file.not.found.required.sheet.names': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'В "xlsx" файле отсутствуют необходимые листы.',
  },
  parentElement: {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Неверный идентификатор елемента.',
  },
  undefined: {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Ошибка загрузки файла.',
  },
}

function* addMultipleObjectFromFile({ payload }) {
  const { id, file } = payload
  const toast = createNotifications()
  try {
    toast({
      type: 'info',
      title: 'НАЧАЛО ОПЕРАЦИИ!',
      description: 'Начата загрузка и обработка файла.',
    })
    const formData = new window.FormData()
    formData.append('file', file)
    formData.append('parentElementId', id)
    yield request({
      url: INSTALLATION_FILE_SCHEME_UPLOAD,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
  } catch (error) {
    const fileId = get(error, 'response.data.details[0].subject', false) === 'error.file.id'
      ? get(error, 'response.data.details[0].message', 'null')
      : false
    const type = get(error, 'response.data.details[0].subject', 'undefined') === 'error.file.id'
      ? get(error, 'response.data.details[0].subject', 'undefined')
      : get(error, 'response.data.details[0].message', 'undefined')
    toast(typeToConfig[type])
    if (fileId) {
      const { data: errorFile } = yield request({
        url: `${INSTALLATION_ERROR_FILE_DOWNLOAD}/${fileId}`,
        method: 'get',
        options: {
          responseType: 'blob',
        },
      })
      downloadFileFromBlob({
        blob: errorFile,
        fileName: 'SLS24_OBJECT_ERROR.xlsx',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(DOWNLOAD_MULTIPLE_OBJECT.REQUEST, addMultipleObjectFromFile)
}
