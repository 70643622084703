import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PlusIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C9.82843 0 10.5 0.671573 10.5 1.5V7.5L16.5 7.5C17.3284 7.5 18 8.17157 18 9C18 9.82843 17.3284 10.5 16.5 10.5H10.5V16.5C10.5 17.3284 9.82843 18 9 18C8.17157 18 7.5 17.3284 7.5 16.5V10.5H1.5C0.671574 10.5 0 9.82843 0 9C0 8.17157 0.671574 7.5 1.5 7.5L7.5 7.5V1.5C7.5 0.671573 8.17157 0 9 0Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

PlusIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PlusIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PlusIcon)
