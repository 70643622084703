import React from 'react'

import BarChart from '@/components/charts/BarChart'

import { ArrowsRow, ArrowBlock } from './styles'

const DATA = [
  {
    dateStep: 'Январь 2020',
    rating: 7,
    changes: -2,
  },
  {
    dateStep: 'Февраль 2020',
    rating: 8,
    changes: 1,
  },
  {
    dateStep: 'Март 2020',
    rating: 7,
    changes: -1,
  },
  {
    dateStep: 'Апрель 2020',
    rating: 7,
    changes: 0,
  },
  {
    dateStep: 'Май 2020',
    rating: 8,
    changes: 1,
  },
  {
    dateStep: 'Июнь 2020',
    rating: 9,
    changes: 1,
  },
]

const SERIES = [
  {
    fill: '#81c13c',
    name: 'rating',
    title: 'Рейтинг',
  },
]

const Widget = () => (
  <>
    <BarChart
      data={DATA}
      barSize={65}
      seriesNames={SERIES}
      mainAxisName="dateStep"
      customGraphMargin={{
        top: 0,
        right: 10,
        left: -30,
        bottom: -5,
      }}
    />
    <ArrowsRow>
      {DATA.map(({ changes }) => {
        if (changes === 0) {
          return <ArrowBlock />
        }
        const positive = changes > 0
        return (
          <ArrowBlock positive={positive}>
            {positive ? '↑' : '↓'}
            {' '}
            {Math.abs(changes)}
          </ArrowBlock>
        )
      })}
    </ArrowsRow>
  </>
)

export default Widget
