import { CREATE_PROJECT } from './index'

export const requestCreateProject = (payload) => ({
  type: CREATE_PROJECT.REQUEST,
  payload,
})

export const successCreateProject = (payload) => ({
  type: CREATE_PROJECT.SUCCESS,
  payload,
})

export const errorCreateProject = (payload) => ({
  type: CREATE_PROJECT.ERROR,
  payload,
})
