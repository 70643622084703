export const ALL_CHARTS = 'ALL_CHARTS'
export const APPEAL_TOPICS = 'APPEAL_TOPICS'
export const APPEAL_STATUSES = 'APPEAL_STATUSES'
export const APPEAL_STATISTIC = 'APPEAL_STATISTIC'
export const APPEAL_TIME = 'APPEAL_TIME'

export const ALL_PERIOD = 'allPeriod'
export const YEAR = 'year'
export const QUARTER = 'quarter'
export const MONTH = 'month'
export const WEEK = 'week'
export const DAY = 'day'

export const ANNUALLY = 'ANNUALLY'
export const MONTHLY = 'MONTHLY'
export const WEEKLY = 'WEEKLY'
export const DAILY = 'DAILY'

export const DATE_INTERVAL = {
  [MONTHLY]: 366,
  [WEEKLY]: 92,
  [DAILY]: 31,
}
