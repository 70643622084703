import { GET_OBJECT_SCHEME } from './index'


export const requestGetScheme = (payload) => ({
  type: GET_OBJECT_SCHEME.REQUEST,
  payload,
})

export const successGetScheme = (payload) => ({
  type: GET_OBJECT_SCHEME.SUCCESS,
  payload,
})

export const errorGetScheme = (payload) => ({
  type: GET_OBJECT_SCHEME.ERROR,
  payload,
})
