import widgets from '../widgets'

const {
  LIGHT_MESH_STATUS,
  LIGHT_MESH,
  VOLTAGE,
  BIG_LIGHT,
  LAST_INCLUSION,
  LAST_SHUTDOWN,
  LIGHT_LOCATION,
  AMPERAGE_ARRAY,
  ACTIVE_POWER,
} = widgets

export default [
  LIGHT_MESH_STATUS({}),
  LIGHT_MESH({}),
  VOLTAGE({}),
  LIGHT_LOCATION({}),
  AMPERAGE_ARRAY({}),
  ACTIVE_POWER({}),
  BIG_LIGHT({}),
  LAST_INCLUSION({}),
  LAST_SHUTDOWN({}),
]
