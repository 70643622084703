import { takeLatest } from 'redux-saga/effects'
import request from '@/helpers/axios'
import get from 'lodash/get'
import { EXPORT_PASSPORT_FILE } from '@/store/actions/passportization'
import { EXPORT_PASSPORT_FILE_URL } from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'

function* exportCardFileSaga({ payload }) {
  const { operatedElement, selectedFields, type } = payload
  const newSelectedFields = selectedFields.map((element) => element.split('.')[1])
  try {
    const dataJson = {
      identifier: operatedElement.name,
      includedFields: newSelectedFields,
      installationType: type,
    }
    const response = yield request({
      url: EXPORT_PASSPORT_FILE_URL,
      method: 'post',
      body: dataJson,
      options: {
        responseType: 'blob',
      },
    })
    downloadFileFromBlob({
      blob: response.data,
      fileName: `${operatedElement.name}-passport.pdf`,
    })
  } catch (error) {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: `Не удалось выполнить загрузку паспорта. Код ошибки: ${get(error, 'response.status', '000')}`,
      type: 'error',
    })
    console.log('TCL: function*exportCardFileSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(EXPORT_PASSPORT_FILE.REQUEST, exportCardFileSaga)
}
