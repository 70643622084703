import { MULTIPLE_DELETE } from '../index'

export const requestUsersMultipleDelete = (data) => ({
  type: MULTIPLE_DELETE.REQUEST,
  payload: data,
})

export const responseUsersMultipleDelete = (data) => ({
  type: MULTIPLE_DELETE.SUCCESS,
  payload: data,
})

export const errorUsersMultipleDelete = (data) => ({
  type: MULTIPLE_DELETE.ERROR,
  payload: data,
})
