import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const ColumnHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 15px 10px;
`

export const ColumnTitle = styled.div`
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.3rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  margin-left: 10px;
`

export const CardsContainer = styled.div`
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        background: ${theme.colors.backgrounds.redisign.default};
        box-shadow: inset 4px 4px 10px ${theme.colors.shadows.grey},
          inset -4px -4px 10px ${theme.colors.shadows.white4};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header}
      border: ${theme.colors.borders.default}
      box-shadow: -8px 12px 18px ${theme.colors.shadows.widget}
    `
  }}
  border-radius: 4px;
  padding: 10px;
  height: 100%;
  max-height: calc(100vh - 165px);
  overflow-y: auto;
  overflow-x: hidden;
`

export const ColumnSmallCard = styled.div`
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border: 1px solid ${theme.colors.borders.redisign.contrast};
        background: ${theme.colors.backgrounds.redisign.default};
        box-shadow:  -1px -1px 2px ${theme.colors.shadows.white2},
          1px 2px 4px ${theme.colors.shadows.black},
          inset 1px 1px 2px ${theme.colors.shadows.white5};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header}
      box-shadow: -8px 12px 18px ${theme.colors.shadows.widgetPrimary}
    `
  }}
  min-width: 290px;
  width: 100%;
  min-height: 91px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
`

export const ColumnLargeCard = styled(ColumnSmallCard)`
  height: auto;
  width: 100%;
  padding: 20px 40px;
  cursor: default;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  top: 12px;
  right: 22px;

  svg {
    width: 14px;
    height: 14px;
  }
`

export const SmallCardTitle = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  text-align: left;
`

export const SmallCardAddress = styled(SmallCardTitle)`
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin: 10px 0 15px;
`

export const SmallCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`

export const SmallCardNumber = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;
`

export const LargeCardTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 0.01em;
  text-align: left;
  display: flex;

  ${({ sub }) => sub && `
    font-size: 1.2rem;
    font-weight: 500;
  `}
`

export const LargeCardDescription = styled(LargeCardTitle)`
  font-size: 1.2rem;
  font-weight: 400;

  ${({ spaced }) => spaced && `
    margin-left: 5px;
  `}
  ${({ color }) => color && `
    color: ${color};
    font-weight: bold;
  `}
`

export const LargeCardChat = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`

export const LargeCardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;

  svg {
    min-width: 50px;
    min-height: 50px;
  }
`

export const LargeCardMessage = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.header};
  ${({ theme }) => {
    if (theme.themeName !== LIGHT_THEME_NAME) {
      return `box-shadow: -8px 12px 18px ${theme.colors.shadows.widgetPrimary};`
    }
  }}
  border-radius: 4px;
  padding: 20px 40px 20px;
  text-align: left;

  ${({ position, theme }) => position && `
    margin-${position}: 25px;

    &::before {
      content: '';
      position: absolute;
      top: 17px;
      ${position}: -15px;
      width: 20px;
      height: 10px;
      border-right: solid 20px ${theme.colors.backgrounds.header};
      border-bottom: solid 10px transparent;
      border-top: solid 10px transparent;
      ${position === 'right' && 'transform: rotate(180deg);'}
    }
  `}
`

export const ColumnContainer = styled.div`
  height: 100%;
  margin: 0 5px;
  min-width: 310px;
  width: 310px;
  ${({ large }) => large && `
    width: 100%;
  `}
`
