import React from 'react'
import pt from 'prop-types'
import IconAndTitleContainer from './styles'

const LabelWithIcon = ({
  icon, isError, title, userForm,
}) => {
  const Icon = icon
  return (
    <IconAndTitleContainer isError={isError} userForm={userForm}>
      {icon && <Icon />}
      {title}
    </IconAndTitleContainer>
  )
}

LabelWithIcon.defaultProps = {
  icon: null,
  isError: false,
  title: '',
  userForm: false,
}

LabelWithIcon.propTypes = {
  icon: pt.element,
  isError: pt.bool,
  userForm: pt.bool,
  title: pt.oneOfType([pt.string, pt.element]),
}

export default LabelWithIcon
