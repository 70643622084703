import createIntegrationSubscriptionChannel from '@/store/sagas/createIntegrationSubscriptionChannel'
import createSubscriptionChannel from '@/store/sagas/createSubscriptionChannel'
import {
  NOTIFICATION_REFRESH_INTEGRATION,
  generateMonitoringNotificationsTopic,
  generateMonitoringErrorsTopic,
} from '@/constants/socket/events'

export const createMonitoringNotificationsChannel = () => (
  createIntegrationSubscriptionChannel(generateMonitoringNotificationsTopic)
)
export const createMonitoringErrorsChannel = () => (
  createIntegrationSubscriptionChannel(generateMonitoringErrorsTopic)
)
export const createRefreshEquipmentChannel = () => (
  createSubscriptionChannel(NOTIFICATION_REFRESH_INTEGRATION)
)
