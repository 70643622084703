import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GeoIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.68198 1.22565C6.83807 0.440879 5.69347 0 4.5 0C3.30653 0 2.16193 0.440879 1.31802 1.22565C0.474106 2.01042 0 3.0748 0 4.18463C0 6.49559 4.37749 11.9693 4.5 11.9999C4.62251 12.0304 9 6.4958 9 4.18463C9 3.0748 8.52589 2.01042 7.68198 1.22565ZM4.49998 6.28094C5.61835 6.28094 6.52498 5.40822 6.52498 4.33168C6.52498 3.25513 5.61835 2.38242 4.49998 2.38242C3.3816 2.38242 2.47498 3.25513 2.47498 4.33168C2.47498 5.40822 3.3816 6.28094 4.49998 6.28094Z" fill={fill} className="svgFill" />
    </svg>
  )
}

GeoIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GeoIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GeoIcon)
