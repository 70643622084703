import pt from 'prop-types'
import {
  OK, WARNING, ERROR, INFO,
} from '@/constants/objectStatuses'

export const markStatus = pt.string
export const markType = pt.string
export const markName = pt.string
export const markStatistic = pt.shape({
  [OK]: pt.number,
  [WARNING]: pt.number,
  [INFO]: pt.number,
  [ERROR]: pt.number,
})
export const markCount = pt.number

export const clusterOptions = pt.shape({
  hasBallon: pt.bool,
  fillColor: pt.string,
  strokeColor: pt.string,
  opacity: pt.number,
  strokeWidth: pt.number,
  strokeStyle: pt.string,
})

export const clusterElement = pt.shape({
  geoZoneId: pt.number,
  location: pt.arrayOf(pt.number),
  name: pt.string,
  statistics: markStatistic,
  count: pt.number,
})

export const telemetryArrayProps = pt.arrayOf(pt.shape)

export const theme = pt.objectOf(pt.oneOfType([pt.object, pt.number, pt.string, pt.func]))

export const markId = pt.number

export const markFlag = pt.bool
