import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InformationIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26 38C32.6274 38 38 32.6274 38 26C38 19.3726 32.6274 14 26 14C19.3726 14 14 19.3726 14 26C14 32.6274 19.3726 38 26 38Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" />
      <path d="M25 24C25 23.4477 25.4477 23 26 23C26.5523 23 27 23.4477 27 24V31C27 31.5523 26.5523 32 26 32C25.4477 32 25 31.5523 25 31V24Z" fill={fill} className="svgFill" />
      <path d="M25 21C25 20.4477 25.4477 20 26 20C26.5523 20 27 20.4477 27 21C27 21.5523 26.5523 22 26 22C25.4477 22 25 21.5523 25 21Z" fill={fill} className="svgFill" />
    </svg>
  )
}

InformationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InformationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InformationIcon)
