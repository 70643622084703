import styled from 'styled-components'

export const MainPrimary = styled.text`
  font-style: normal;
  font-family: Roboto;
  font-size: 1.8rem;
  font-weight: 700;
  fill: ${({ theme }) => theme.colors.colors.default};
`

export const MainSecondary = styled.text`
  font-style: normal;
  font-family: Roboto;
  font-size: 1.8rem;
  font-weight: 300;
  fill: ${({ theme }) => theme.colors.colors.default};
`

export const LabelPrimary = styled.text`
  font-style: normal;
  font-family: Roboto;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 500;
  fill: ${({ theme }) => theme.colors.colors.default};
`

export const LabelSecondary = styled.text`
  font-style: normal;
  font-family: Roboto;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 300;
  fill: ${({ theme }) => theme.colors.colors.dim};
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  ${({ withoutLabel }) => !withoutLabel && `
    .recharts-responsive-container {
      max-height: calc(100% - 60px);
    }
  `}
`
