import styled from 'styled-components'

export const TabName = styled.div`
  cursor: pointer;
  height: 100%;
  position: relative;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 5px 22px;
  color: ${({ theme }) => theme.colors.colors.tabDefault};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  white-space: nowrap;
  transition: color 300ms ease-in-out;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: ${({ theme }) => theme.colors.colors.tabDefault};
    transition: background 300ms ease-in-out;
  }

  &:first-child {
    &::after {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
  &:last-child {
    &::after {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  ${({ space }) => space && `
    width: 100%;
    cursor: default;
  `}

  ${({ active, theme }) => active && `
    color: ${theme.colors.colors.tab};

    &::after {
      background: ${theme.colors.colors.tab};
    }
  `}
  ${({ active, nameStyles, theme }) => (nameStyles === 'filledBackground' && active) && `
    background: ${theme.colors.backgrounds.default};
    border: 1px solid ${theme.colors.borders.default};
    border-bottom: 1px solid ${theme.colors.backgrounds.default};
    margin-bottom: -1px;
  `}
`

export const TabsNames = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  ${({ margins, theme }) => margins && `
    padding: 13px 22px;
    background: ${theme.colors.border}
    height: ${theme.sizes.tabsNamesHeight}px;
  `}
`

export const TabContent = styled.div``

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ nameStyles, theme }) => {
    switch (nameStyles) {
      case 'filledBackground': {
        return `
          ${TabContent} {
            background: ${theme.colors.backgrounds.dark};
            width: 100%;
            height: 100vh;
          }
          ${TabsNames} {
            background: ${theme.colors.backgrounds.default};
            border-bottom: 1px solid ${theme.colors.borders.default};
            padding: 0 8px;
          }
          ${TabName} {
            padding: 0;
            width: 48px;
            height: 48px;

            svg {
              width: 48px;
              height: 48px;
            }

            &::after {
              display: none;
            }
          }
        `
      }
      case 'fullWidth': {
        return `
         ${TabsNames} {
            justify-content: space-around;
            ${TabName} {
              width: 100%;
            }
         }
        `
      }
      default: {
        return ''
      }
    }
  }}
`
