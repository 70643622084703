import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'

export const selectOptions = [
  {
    title: 'Все статусы',
    value: null,
  },
  {
    title: 'В процессе решения',
    value: WARNING,
  },
  {
    title: 'Отказ или проблема не обнаружена',
    value: ERROR,
  },
  {
    title: 'Успешно решены',
    value: OK,
  },
]
export default [
  {
    title: 'Готовится ответ',
    value: 'Готовится ответ',
  },
  {
    title: 'Запланировано',
    value: 'Запланировано',
  },
  {
    title: 'Проблема решена',
    value: 'Проблема решена',
  },
  {
    title: 'Мотивированный отказ',
    value: 'Мотивированный отказ',
  },
  {
    title: 'Проблема не обнаружена',
    value: 'Проблема не обнаружена',
  },
]
