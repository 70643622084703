import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ZoomIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 19L25 25M7 9.57143V7H9.57143M13 7H15.5714V9.57143M7 13V15.5714H9.57143M15.5714 13V15.5714H13M21.5714 11.2857C21.5714 16.9664 16.9664 21.5714 11.2857 21.5714C5.60507 21.5714 1 16.9664 1 11.2857C1 5.60507 5.60507 1 11.2857 1C16.9664 1 21.5714 5.60507 21.5714 11.2857Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

ZoomIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ZoomIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ZoomIcon)
