import { DOWNLOAD_MULTIPLE_OBJECT } from './index'

export const requestDownloadMultipleObject = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT.REQUEST,
  payload,
})

export const successDownloadMultipleObject = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT.SUCCESS,
  payload,
})

export const errorDownloadMultipleObject = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT.ERROR,
  payload,
})
