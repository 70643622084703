import React from 'react'
import pt from 'prop-types'
import {
  LabledFieldStyled,
  Title,
  Field,
} from './styles'

const LabledField = ({ forName, title, field }) => (
  <LabledFieldStyled htmlFor={forName}>
    {title && (<Title>{title}</Title>)}
    {field && (<Field>{field}</Field>)}
  </LabledFieldStyled>
)

LabledField.propTypes = {
  title: pt.node,
  field: pt.node,
  forName: pt.string,
}
LabledField.defaultProps = {
  title: null,
  field: null,
  forName: null,
}

export default LabledField
