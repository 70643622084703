import {
  KULON,
  BRIZ,
  ONE_SIM,
  MESH,
  MULTIRATE_METER,
  ELECTRIC_METER,
  CONTROLLER,
  CONTACTOR,
  BRANCH_CIRCUIT,
  LAMP,
  PHASE,
  LIGHT_SENSOR,
  PHOTO_SENSOR,
  VOLUME_SENSOR,
  FIRE_SENSOR,
  TRANSFER_SWITCH,
  DMX_DEVICE,
  CONTROLLER_FUSE,
  FUSE,
  CHECKPOINT,
  CURRENT_SENSOR,

  SHUO,
  CONTROL_CUPBOARD,
  MULTI_RATE_METER,
  METEO,
  ROAD_TEMPERATURE_SENSOR,
  DOOR_OPEN_SENSOR,
  SMOKE_SENSOR,
  BASE_STATION,
  LIGHT_FIXTURE_GROUP,
  UTILITY_POLE,
  LIGHT_MODULE,
  LINE,
  COMPANY_OBJECT,
  DEVICE,
  WATHER_SUPPLY,
  CENTRAL_HEATING,
  SWITCHING_GROUP,
  STREETLIGHT_CONTROL_CABINET,
  STREETLIGHT_GROUP,
  STREETLIGHT,
  AMBIENT_LIGHT_SENSOR,
  WASTE_CONTAINER,
  WITHOUT_ASU,
  LIGHT_FIXTURE,
  SENSOR,
  LIGHT_POINT,
  LAMP_MODULE,
  PHASE_CIRCUIT_BREAKER,
} from '@/constants/instalationPassport/types'

import {
  LIGHT as LIGHT_LOCAL,
  HEATING_DEVICE as HEATING_DEVICE_LOCAL,
  COLD_WATER_DEVICE as COLD_WATER_DEVICE_LOCAL,
  TRASH as TRASH_LOCAL,
  BASE_STATION as BASE_STATION_LOCAL,
  SHUO as SHUO_LOCAL,
  MULTI_TARIFF_ELECTRICITY_METER as MULTI_TARIFF_ELECTRICITY_METER_LOCAL,
  LET as LET_LOCAL,
  LIGHT_MODULE as LIGHT_MODULE_LOCAL,
  MULTIRATE_METER as MULTIRATE_METER_LOCAL,
  LIGHT_FIXTURE as LIGHT_FIXTURE_LOCAL,
  UTILITY_POLE as UTILITY_POLE_LOCAL,
  LIGHT_SENSOR as LIGHT_SENSOR_LOCAL,
  DMX_DEVICE as DMX_DEVICE_LOCAL,

} from '@/constants/objectTypes'

export default {
  integrationType: {
    [KULON]: 'АСУНО "КУЛОН"',
    [BRIZ]: 'АСУ "БРИЗ"',
    [ONE_SIM]: 'АСУ "1СИМ"',
    [MESH]: 'АСУ "МЕШ"',
    [WITHOUT_ASU]: 'Без АСУ',
  },
  objectTypes: {
    [ELECTRIC_METER]: 'Многотарифный счетчик',
    [MULTIRATE_METER]: 'Многотарифный счетчик',
    [MULTI_TARIFF_ELECTRICITY_METER_LOCAL]: 'Многотарифный счетчик',
    [MULTIRATE_METER_LOCAL]: 'Многотарифный счетчик',
    [CONTROLLER]: 'Контроллер',
    [CONTACTOR]: 'Контактор',
    [BRANCH_CIRCUIT]: 'Отходящая линия',
    [LAMP]: 'Cветильник',
    [LIGHT_LOCAL]: 'Cветильник',
    [LIGHT_POINT]: 'Cветильник',
    [PHASE]: 'Фаза',
    [LIGHT_SENSOR]: 'Датчик освещенности',
    [LIGHT_SENSOR_LOCAL]: 'Датчик освещенности',
    [PHOTO_SENSOR]: ' Фотодатчик',
    [VOLUME_SENSOR]: '  Датчик объема',
    [FIRE_SENSOR]: 'Датчик пожара',
    [TRANSFER_SWITCH]: ' АВР',
    [DMX_DEVICE]: ' DMX',
    [DMX_DEVICE_LOCAL]: ' DMX',
    [CONTROLLER_FUSE]: 'Предохранитель контроллера',
    [FUSE]: 'Предохранитель',
    [CHECKPOINT]: 'Контрольная точка',
    [CURRENT_SENSOR]: 'Датчик тока',
    [SHUO]: 'ШУО',
    [SHUO_LOCAL]: 'ШУО',
    [CONTROL_CUPBOARD]: 'Шкаф управления',
    [MULTI_RATE_METER]: 'Многотарифный счетчик',
    [METEO]: 'Метеодатчик ',
    [ROAD_TEMPERATURE_SENSOR]: 'Датчик температуры дорожного полотна',
    [DOOR_OPEN_SENSOR]: 'Датчик открытия двери',
    [SMOKE_SENSOR]: 'Датчик задымленности',
    [BASE_STATION]: 'Базовая станция',
    [BASE_STATION_LOCAL]: 'Базовая станция',
    [LIGHT_FIXTURE_GROUP]: 'Светоточка',
    [UTILITY_POLE]: 'Опора',
    [UTILITY_POLE_LOCAL]: 'Опора',
    [LIGHT_MODULE]: 'Модуль светильника',
    [LINE]: 'Линия',
    [LET_LOCAL]: 'Линия',
    [COMPANY_OBJECT]: 'Объект',
    [DEVICE]: 'Устройство',
    [WATHER_SUPPLY]: 'Узел учета ХВС',
    [COLD_WATER_DEVICE_LOCAL]: 'Узел учета ХВС',
    [CENTRAL_HEATING]: 'Узел учета ЦО',
    [HEATING_DEVICE_LOCAL]: 'Узел учета ЦО',
    [SWITCHING_GROUP]: 'Группа ШУНО',
    [STREETLIGHT_CONTROL_CABINET]: 'ШУНО',
    [STREETLIGHT_GROUP]: 'Группа светильников',
    [STREETLIGHT]: 'Светильник',
    [AMBIENT_LIGHT_SENSOR]: 'Датчик освещенности',
    [WASTE_CONTAINER]: 'Мусорный бак',
    [LIGHT_FIXTURE]: 'Светильник',
    [LIGHT_FIXTURE_LOCAL]: 'Светильник',
    [SENSOR]: 'Датчик',
    [LAMP_MODULE]: 'Модуль лампы',
    [LIGHT_MODULE_LOCAL]: 'Модуль лампы',
    [PHASE_CIRCUIT_BREAKER]: 'Фазовый выключатель',
    [TRASH_LOCAL]: 'Мусорный бак',
  },
}
