import styled from 'styled-components'

export const ObjectPassportizationStyled = styled.div`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  ${({ isSettingsOpen, theme }) => (!isSettingsOpen
    ? `
      width: 48px;
      min-width: 48px;
    `
    : `
      min-width: ${theme.sizes.passportizationWidth}px;
      max-width: ${theme.sizes.passportizationWidth}px;
    `
  )}
  background: ${({ theme }) => theme.colors.backgrounds.dark};
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  ${({ isSettingsOpen }) => !isSettingsOpen && `
    display: none
  `}
`

export const ObjectTitle = styled.div`
  margin: auto auto auto 0;
  color: ${({ theme }) => theme.colors.colors.default};
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
`

export const ObjectNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  height: 48px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ isSettingsOpen }) => !isSettingsOpen && `
    svg {
      transform: rotate(-180deg);
    }
  `}
`

export const CollapsedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  & > *{
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  ${ObjectTitle} {
    min-height: 300px;
    height: auto;
    width: 100%;
    padding: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`
