export default {
  parameters: 'Параметры',
  timetable: 'Расписание',
  newsletter: 'Рассылка',
  selectFromList: 'Выбрать из списка',
  allObjects: 'Все объекты',
  addUser: 'Добавить email получателя',
  deletedUser: 'Получатель удален',
  cancel: 'Отменить',
  createReport: 'Создать отчет',
  form: {
    reportTitle: 'Название отчета',
    reportType: 'Тип отчета',
    typeObject: 'Тип объектов для отчета',
    repeat: 'Повтор',
    timezone: 'Часовой пояс',
    systemUsers: 'Пользователи системы',
    externalRecipients: 'Внешние получатели',
    userName: 'Имя',
    userEmail: 'E-mail',
    emailNewsletter: 'E-mail рассылка',
    geoZone: 'Геозона',
  },
  header: {
    editTitle: 'Новый отчет',
    cancelEdit: 'Вы действительно хотите отменить добавление отчета?',
    saveReport: 'Для добавления отчета необходимо заполнить (*) обязательные поля',
    changeCancelEdit: 'Вы действительно хотите отменить редактирование отчета?',
    deleteReport: 'Вы действительно хотите удалить из системы отчет ',
    changeSaveReport: 'Для редактирования отчета необходимо заполнить (*) обязательные поля',
    changeEditTitle: 'Редактирование отчета',
    createReport: 'Нужно выбрать геозону в дереве, чтобы создать отчет',
  },
}
