import createSubscriptionChannel from '@/store/sagas/createSubscriptionChannel'
import {
  ADD_INTEGRATION_TOPIC,
} from '@/constants/socket/events'

export const createAddIntegrationChannel = () => (
  createSubscriptionChannel(ADD_INTEGRATION_TOPIC)
)

export default createAddIntegrationChannel
