import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UploadIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 11.246C20.9909 11.1633 20.9733 11.0819 20.9475 11.003V10.922C20.9054 10.8295 20.8493 10.7444 20.7812 10.67L15.5312 5.27C15.4589 5.19999 15.3762 5.14227 15.2863 5.099H15.1987C15.1137 5.0522 15.0222 5.01883 14.9275 5H9.625C8.92881 5 8.26113 5.28446 7.76884 5.79081C7.27656 6.29716 7 6.98392 7 7.7V20.3C7 21.0161 7.27656 21.7028 7.76884 22.2092C8.26113 22.7155 8.92881 23 9.625 23H18.375C19.0712 23 19.7389 22.7155 20.2312 22.2092C20.7234 21.7028 21 21.0161 21 20.3V11.3V11.246ZM15.75 8.069L18.0163 10.4H16.625C16.3929 10.4 16.1704 10.3052 16.0063 10.1364C15.8422 9.96761 15.75 9.73869 15.75 9.5V8.069ZM19.25 20.3C19.25 20.5387 19.1578 20.7676 18.9937 20.9364C18.8296 21.1052 18.6071 21.2 18.375 21.2H9.625C9.39294 21.2 9.17038 21.1052 9.00628 20.9364C8.84219 20.7676 8.75 20.5387 8.75 20.3V7.7C8.75 7.46131 8.84219 7.23239 9.00628 7.0636C9.17038 6.89482 9.39294 6.8 9.625 6.8H14V9.5C14 10.2161 14.2766 10.9028 14.7688 11.4092C15.2611 11.9155 15.9288 12.2 16.625 12.2H19.25V20.3ZM15.1287 16.061L14.875 16.331V14C14.875 13.7613 14.7828 13.5324 14.6187 13.3636C14.4546 13.1948 14.2321 13.1 14 13.1C13.7679 13.1 13.5454 13.1948 13.3813 13.3636C13.2172 13.5324 13.125 13.7613 13.125 14V16.331L12.8713 16.061C12.7065 15.8915 12.483 15.7963 12.25 15.7963C12.017 15.7963 11.7935 15.8915 11.6287 16.061C11.464 16.2305 11.3714 16.4603 11.3714 16.7C11.3714 16.9397 11.464 17.1695 11.6287 17.339L13.3787 19.139C13.462 19.2209 13.5601 19.2852 13.6675 19.328C13.7722 19.3756 13.8855 19.4002 14 19.4002C14.1145 19.4002 14.2278 19.3756 14.3325 19.328C14.4399 19.2852 14.538 19.2209 14.6213 19.139L16.3713 17.339C16.536 17.1695 16.6286 16.9397 16.6286 16.7C16.6286 16.4603 16.536 16.2305 16.3713 16.061C16.2065 15.8915 15.983 15.7963 15.75 15.7963C15.517 15.7963 15.2935 15.8915 15.1287 16.061Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UploadIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UploadIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UploadIcon)
