import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  width: 32%;
`

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #3D3D3D;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.3px;
  height: 30px;
  width: 100%;
`
