export default [
  {
    passportParameter: 'Идентификатор',
    showInDashboard: true,
    selector: 'id',
  },
  {
    passportParameter: 'Идентификатор ШУО',
    showInDashboard: false,
    selector: 'idSHUO',
  },
  {
    passportParameter: 'Идентификатор базовой станции',
    showInDashboard: false,
    selector: 'baseStationIdentifier',
  },
  {
    passportParameter: 'Наименование',
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: 'Модель/марка',
    showInDashboard: true,
    selector: 'model/braND',
  },
  {
    passportParameter: 'Производитель',
    showInDashboard: false,
    selector: 'manufacturer',
  },
  {
    passportParameter: 'Географическая широта',
    showInDashboard: true,
    selector: 'location[0]',
  },
  {
    passportParameter: 'Географическая долгота',
    showInDashboard: true,
    selector: 'location[1]',
  },
  {
    passportParameter: 'Дата начала эксплуатации',
    showInDashboard: true,
    selector: 'startDate',
  },
  {
    passportParameter: 'Эксплуатирующая организация',
    showInDashboard: false,
    selector: 'operatingOrganization',
  },
  {
    passportParameter: 'Собственник',
    showInDashboard: false,
    selector: 'owner',
  },
  {
    passportParameter: 'Гарантийный срок',
    showInDashboard: true,
    selector: 'warrantyPeriod',
  },
  {
    passportParameter: 'Срок службы',
    showInDashboard: true,
    selector: 'lifeTime',
  },
  {
    passportParameter: 'Диапазон рабочей температуры',
    showInDashboard: true,
    selector: 'operatingTemperatureRange',
  },
  {
    passportParameter: 'Потребляемая мощность',
    showInDashboard: true,
    selector: 'powerConsumption',
  },
  {
    passportParameter: 'Диапазон рабочего напряжения',
    showInDashboard: true,
    selector: 'operatingVoltageRange',
  },
  {
    passportParameter: 'Световой поток',
    showInDashboard: true,
    selector: 'lightFlow',
  },
  {
    passportParameter: 'Цветовая температура',
    showInDashboard: true,
    selector: 'colourTemperature',
  },
  {
    passportParameter: 'Индекс цветопередачи',
    showInDashboard: true,
    selector: 'colorRenderingIndex',
  },
  {
    passportParameter: 'Эффективность светильника',
    showInDashboard: true,
    selector: 'lampEfficiency',
  },
  {
    passportParameter: 'Угол рассеивания',
    showInDashboard: true,
    selector: 'dispersionAngle',
  },
  {
    passportParameter: 'Коэффициент пульсации',
    showInDashboard: true,
    selector: 'rippleCoefficient',
  },
  {
    passportParameter: 'Файл паспорта изделия',
    showInDashboard: false,
    selector: 'productDataSheetFile',
  },
]
