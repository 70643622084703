import React from 'react'
import { Treebeard } from 'react-treebeard'
import noop from 'lodash/noop'
import set from 'lodash/set'
import get from 'lodash/get'
import pt from 'prop-types'
import * as filters from '@/helpers/viewTree/filter'
import { getNodePath } from '@/helpers/viewTree/formaters'
import { setTreePath } from '@/data/mapData'
import CustomHeader from './components/CustomHeader'
import CustomContainer from './components/CustomContainer'
import CustomLoading from './components/CustomLoading'
import CustomToggle from './components/CustomToggle'
import StyledTreeBeard from './styles'

const PATH_PREFIX = 'local-'

class ViewTree extends React.Component {
  constructor(props) {
    super(props)
    const { treeData, searchQuery } = props
    this.state = {
      data: setTreePath({ data: treeData }),
      search: searchQuery,
    }
  }

  onToggle = (node, toggled) => {
    const { onIconToggle } = this.props
    onIconToggle()
    const { data } = this.state
    const activeState = node.children
      ? {
        active: true,
        toggled,
      }
      : {
        active: true,
      }

    const currentItem = get(data, getNodePath(node, PATH_PREFIX), {})
    const updatedTree = getNodePath(node, PATH_PREFIX)
      ? set(data, getNodePath(node, PATH_PREFIX), { ...currentItem, ...activeState })
      : { ...data, ...activeState }
    this.setState(() => ({ data: updatedTree }))
  }

  static getDerivedStateFromProps(props, state) {
    const { searchQuery, treeData } = props
    if (searchQuery !== state.search) {
      if (!searchQuery) {
        return {
          ...state,
          search: searchQuery,
          data: setTreePath({ data: treeData }),
        }
      }
      const filtered = filters.filterTree(treeData, searchQuery)
      if (filtered) {
        const expanded = filters.expandFilteredNodes(filtered, searchQuery)
        const expandedWithPath = setTreePath({ data: expanded, path: '', prefix: PATH_PREFIX })
        return {
          ...state,
          data: { ...expandedWithPath },
          search: searchQuery,
        }
      }
      return {
        ...state,
        data: [],
        search: searchQuery,
      }
    }
    return null
  }

  decorator = () => {
    const {
      onSelect, getTelemetry, smallNodes, excludedTypes, treeData,
    } = this.props
    return {
      Loading: CustomLoading,
      Toggle: (toggleProps) => (
        <CustomToggle
          {...toggleProps}
          onSelect={onSelect}
        />
      ),
      Header: (headerProps) => (
        <CustomHeader
          {...headerProps}
          onSelect={onSelect}
          small={smallNodes}
          excludedTypes={excludedTypes}
          getTelemetry={getTelemetry}
          data={treeData}
        />
      ),
      Container: (containerProps) => (
        <CustomContainer
          {...containerProps}
          onSelect={onSelect}
          excludedTypes={excludedTypes}
        />
      ),
    }
  }

  render() {
    const { data } = this.state
    return (
      <StyledTreeBeard>
        <Treebeard
          data={data}
          decorators={this.decorator()}
          onToggle={this.onToggle}
        />
      </StyledTreeBeard>
    )
  }
}

ViewTree.defaultProps = {
  searchQuery: '',
  onSelect: noop,
  treeData: {},
  onIconToggle: noop,
}

ViewTree.propTypes = {
  onSelect: pt.func,
  onIconToggle: pt.func,
  treeData: pt.shape({
    id: pt.number,
  }),
  searchQuery: pt.string,
}

export default ViewTree
