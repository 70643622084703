import React, { useMemo } from 'react'
import pt from 'prop-types'
import memoWithName from '@/hocs/memoWithName'
import {
  CircleChartStyled,
  CircleChartBackground,
  CircleChartCircle,
  CircleChartInfo,
  CircleChartText,
} from './styles'

const DIVISIONS = 100

const CircleChart = ({
  value, maxValue, units, color,
}) => {
  const valueNumber = Number.parseFloat(value, 10) || 0
  const maxValueNumber = Number.parseFloat(maxValue, 10) || 0
  const oneDivisionValue = useMemo(
    () => DIVISIONS / Math.abs(maxValueNumber),
    [maxValueNumber],
  )
  const valueInDivisions = useMemo(
    () => Math.ceil(valueNumber * oneDivisionValue) || 0,
    [valueNumber, oneDivisionValue],
  )

  return (
    <CircleChartStyled
      viewBox="0 0 33.83098862 33.83098862"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <CircleChartBackground
        cx="16.91549431"
        cy="16.91549431"
        r="14.91549431"
      />
      <CircleChartCircle
        color={color}
        strokeDasharray={`${valueInDivisions},${DIVISIONS}`}
        cx="16.91549431"
        cy="16.91549431"
        r="14.91549431"
      />
      <CircleChartInfo>
        <CircleChartText
          x="16.91549431"
          y="16.91549431"
          alignmentBaseline="central"
          textAnchor="middle"
        >
          {value || 0}
          {units}
        </CircleChartText>
      </CircleChartInfo>
    </CircleChartStyled>
  )
}

CircleChart.propTypes = {
  value: pt.oneOfType([pt.string, pt.number]),
  maxValue: pt.oneOfType([pt.string, pt.number]),
  units: pt.string,
  color: pt.string,
}
CircleChart.defaultProps = {
  value: '000',
  maxValue: 0,
  units: '',
  color: '',
}

export default memoWithName(CircleChart)
