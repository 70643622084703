import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SelectIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 10L13 16L7 10M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

SelectIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SelectIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SelectIcon)
