import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TechnicalConditionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7921 8.41764L16.2228 6.54903L16.4421 4.07223C16.4843 3.63152 16.1975 3.22607 15.7925 3.12911L13.4638 2.57382L12.2488 0.431966C12.1449 0.248245 11.9807 0.110265 11.7866 0.0437697C11.5925 -0.0227259 11.382 -0.0131953 11.1941 0.0705833L9.00041 1.06659L6.81515 0.0882117C6.42703 -0.0880725 5.97141 0.0705832 5.76048 0.449594L4.53707 2.58263L2.2168 3.12911C1.80337 3.22607 1.5165 3.62271 1.55869 4.07223L1.77806 6.54021L0.208717 8.41764C-0.0697148 8.75258 -0.0697148 9.24618 0.208717 9.58112L1.77806 11.4497L1.55869 13.9353C1.5165 14.376 1.80337 14.7815 2.20836 14.8785L4.53707 15.4338L5.75204 17.5668C5.97141 17.9458 6.41859 18.1045 6.81515 17.9282L9.00041 16.9322L11.1857 17.9105C11.5738 18.0868 12.0294 17.9282 12.2403 17.5492L13.4553 15.4073L15.784 14.852C16.1975 14.7551 16.4759 14.3496 16.4337 13.9089L16.2143 11.4321L17.7837 9.56349C18.0705 9.24618 18.0705 8.75258 17.7921 8.41764ZM9.84415 13.4065H8.15668V11.6436H9.84415V13.4065ZM9.00041 9.8808C8.53636 9.8808 8.15668 9.48416 8.15668 8.99938V5.47369C8.15668 4.98891 8.53636 4.59227 9.00041 4.59227C9.46447 4.59227 9.84415 4.98891 9.84415 5.47369V8.99938C9.84415 9.48416 9.46447 9.8808 9.00041 9.8808Z" fill={fill} className="svgFill" />
    </svg>
  )
}

TechnicalConditionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TechnicalConditionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TechnicalConditionIcon)
