import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PassportizationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 1.47059C0 0.658405 0.658405 0 1.47059 0H48.5294C49.3415 0 50 0.658405 50 1.47059C50 2.28277 49.3415 2.94118 48.5294 2.94118H1.47059C0.658405 2.94118 0 2.28277 0 1.47059ZM0 13.2356C0 12.4234 0.658405 11.765 1.47059 11.765H31.5C32.3122 11.765 32.9706 12.4234 32.9706 13.2356C32.9706 14.0478 32.3122 14.7062 31.5 14.7062H1.47059C0.658405 14.7062 0 14.0478 0 13.2356ZM37 13.2356C37 12.4234 37.6584 11.765 38.4706 11.765H48.528C49.3401 11.765 49.9985 12.4234 49.9985 13.2356C49.9985 14.0478 49.3401 14.7062 48.528 14.7062H38.4706C37.6584 14.7062 37 14.0478 37 13.2356ZM0 24.9994C0 24.1872 0.658405 23.5288 1.47059 23.5288H15.5C16.3122 23.5288 16.9706 24.1872 16.9706 24.9994C16.9706 25.8115 16.3122 26.4699 15.5 26.4699H1.47059C0.658405 26.4699 0 25.8115 0 24.9994ZM21.0294 24.9994C21.0294 24.1872 21.6878 23.5288 22.5 23.5288H48.5294C49.3416 23.5288 50 24.1872 50 24.9994C50 25.8115 49.3416 26.4699 48.5294 26.4699H22.5C21.6878 26.4699 21.0294 25.8115 21.0294 24.9994ZM0 36.7645C0 35.9523 0.658405 35.2939 1.47059 35.2939H26.5294C27.3416 35.2939 28 35.9523 28 36.7645C28 37.5766 27.3416 38.2351 26.5294 38.2351H1.47059C0.658405 38.2351 0 37.5766 0 36.7645ZM32 36.7645C32 35.9523 32.6584 35.2939 33.4706 35.2939H48.5293C49.3415 35.2939 49.9999 35.9523 49.9999 36.7645C49.9999 37.5766 49.3415 38.2351 48.5293 38.2351H33.4706C32.6584 38.2351 32 37.5766 32 36.7645ZM0 48.5294C0 47.7172 0.658405 47.0588 1.47059 47.0588H11.5C12.3122 47.0588 12.9706 47.7172 12.9706 48.5294C12.9706 49.3416 12.3122 50 11.5 50H1.47059C0.658405 50 0 49.3416 0 48.5294ZM17 48.5294C17 47.7172 17.6584 47.0588 18.4706 47.0588H48.5293C49.3415 47.0588 49.9999 47.7172 49.9999 48.5294C49.9999 49.3416 49.3415 50 48.5293 50H18.4706C17.6584 50 17 49.3416 17 48.5294Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PassportizationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PassportizationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PassportizationIcon)
