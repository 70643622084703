import { put, takeLatest, select } from 'redux-saga/effects'
import get from 'lodash/get'
import installationPassportConfig from '@/constants/instalationPassport'
import request from '@/helpers/axios'
import {
  successGetCard,
  errorGetCard,
} from '@/store/actions/installation/getCard'
import {
  GEOZONE,
  PROJECT,
  ROOT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { getSelectedElementParams, getMapZoom } from '@/store/selectors/installation'
import { calculateElementStructure } from '@/helpers/formatInstalationTree'
import {
  generateGetProjectCard,
  generateGetGeoZoneCard,
  generateGetTreeIntegrationElement,
  generateGetDeleteObjectCard,
  generateGetTypes,
  generateGetTree,
  GET_PASSPORT_FILES_URL,
  generateGetTreeWithStatus,
} from '@/constants/apiRoutes'
import {
  GET_CARD,
  setMapZoom,
  setMapCenter,
  setSelectedElementParams,
} from '@/store/actions/installation'

const isInConfig = (localName, config) => config
  .filter((configElement) => configElement.selector === localName).length !== 0
const isAdditionalFields = (localName) => localName === 'DOCUMENT_CONTENT_TYPE'
|| localName === 'PHOTO_ID'
|| localName === 'PHOTO_CONTENT_TYPE'
|| localName === 'DOCUMENT_ID'

const requestToType = {
  [GEOZONE]: generateGetGeoZoneCard,
  [ROOT]: generateGetGeoZoneCard,
  [PROJECT]: generateGetProjectCard,
  [INTEGRATION]: generateGetTreeIntegrationElement,
  [OBJECT_ELEMENT]: generateGetDeleteObjectCard,
}

function* getCardSaga({
  payload: {
    id, type, parentGeoZoneId, rootId,
  },
}) {
  try {
    let elementsInfo = {}
    const selectedElementParams = yield select(getSelectedElementParams)
    const currentMapZoom = yield select(getMapZoom)
    const { data } = yield request({
      url: (requestToType[type] || generateGetDeleteObjectCard)(id),
      method: 'get',
    })
    let parentElementName = {}
    if (data.parentTreeElementId) {
      const { data: parentData } = yield request({
        url: generateGetTree(data.parentTreeElementId),
        method: 'get',
      })
      parentElementName = {
        parentGeoZoneName: get(parentData, '[0].elementName', null),
        parentProjectName: get(parentData, '[0].elementName', null),
      }
    }
    if (type !== OBJECT_ELEMENT && rootId) {
      const { data: parentTreeData } = yield request({
        url: generateGetTreeWithStatus(rootId),
        method: 'get',
      })
      const calcElementStructureInfo = calculateElementStructure(parentTreeData[0], 'treeElementId', id)
      elementsInfo = {
        elementsInfo: calcElementStructureInfo,
      }
    }
    const name = data.name || data.geoZoneName || data.integrationName || data.objectElementName
    const formattedData = {
      ...data,
      ...parentElementName,
      name,
      id: data.treeElementId,
      ...elementsInfo,
      type,
    }
    if (parentGeoZoneId && type !== OBJECT_ELEMENT) {
      const { data: parentGeoZone } = yield request({
        url: generateGetGeoZoneCard(parentGeoZoneId),
        method: 'get',
      })
      let newZoom = 3
      if (type === GEOZONE || type === INTEGRATION || type === PROJECT) {
        newZoom = 12
      }
      const latitude = get(parentGeoZone, 'geoLocationDto.latitude', null)
      const longitude = get(parentGeoZone, 'geoLocationDto.longitude', null)
      if (latitude && longitude && selectedElementParams.id !== parentGeoZoneId) {
        yield put(setSelectedElementParams({
          id: type === GEOZONE || type === ROOT ? id : parentGeoZoneId,
        }))
        yield put(setMapZoom(newZoom))
        yield put(setMapCenter([
          latitude,
          longitude,
        ]))
      }
    }
    if (type === OBJECT_ELEMENT) {
      const latitude = get(data, 'geoLocationDto.latitude', null)
      const longitude = get(data, 'geoLocationDto.longitude', null)
      if (latitude && longitude && selectedElementParams.id !== id) {
        if (!(currentMapZoom >= 12)) {
          yield put(setMapZoom(12))
        }
        yield put(setSelectedElementParams({ id, parentGeoZoneId }))
        yield put(setMapCenter([
          latitude,
          longitude,
        ]))
      }
      const { data: parentObject } = yield request({
        url: generateGetDeleteObjectCard(data.parentTreeElementId),
        method: 'get',
      })

      const { data: availableObjectTypes } = yield request({
        url: generateGetTypes(data.integrationType, data.objectType),
        method: 'get',
      })

      const integrationType = get(data, 'integrationType', null)
      const objectType = get(data, 'objectType', null)
      const cardAndPassportConfig = get(installationPassportConfig, integrationType, [])
      const cardConfig = get(cardAndPassportConfig, `card.${objectType}`, [])
      const passportConfig = get(cardAndPassportConfig, `passport.${objectType}`, [])
      const config = [...cardConfig, ...passportConfig]

      const formattedObjectElementFieldsDto = (data.objectElementFieldsDto || []).map((element) => {
        const localName = `${element.passportBlock}.${element.fieldName}`
        if (isInConfig(localName, config) || isAdditionalFields(element.fieldName)) {
          return {
            ...element,
            fieldName: localName,
          }
        }
        return element
      })
      const filterElementFields = formattedObjectElementFieldsDto.filter((element) => element.fieldName.split('.')[0] !== 'ATTACHMENT')
      const attachment = formattedObjectElementFieldsDto.filter((element) => element.fieldName.split('.')[0] === 'ATTACHMENT')
      const photoFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_ID')[0] || {}).value || null
      const PDFFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_ID')[0] || {}).value || null
      const photoFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_TITLE')[0] || {}).value || null
      const PDFFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_TITLE')[0] || {}).value || null
      try {
        if (photoFileId) {
          const { data: photoFile } = yield request({
            url: `${GET_PASSPORT_FILES_URL}?id=${photoFileId}`,
            method: 'get',
            options: {
              responseType: 'blob',
            },
          })
          const photoFileFromBlob = new window.File([photoFile], photoFileName, { type: 'image/png' })
          filterElementFields.push({
            passportBlock: 'ATTACHMENT',
            fieldName: 'ATTACHMENT.PHOTO_TITLE',
            value: photoFileFromBlob,
          })
        }
      } catch (photoError) {
        console.log('photoError', photoError)
      }
      try {
        if (PDFFileId) {
          const { data: PDFFile } = yield request({
            url: `${GET_PASSPORT_FILES_URL}?id=${PDFFileId}`,
            method: 'get',
            options: {
              responseType: 'blob',
            },
          })
          const fileFileFromBlob = new window.File([PDFFile], PDFFileName, { type: 'application/pdf' })
          filterElementFields.push({
            passportBlock: 'ATTACHMENT',
            fieldName: 'ATTACHMENT.DOCUMENT_TITLE',
            value: fileFileFromBlob,
          })
        }
      } catch (fileError) {
        console.log('fileError', fileError)
      }
      formattedData.objectElementFieldsDto = filterElementFields
      formattedData.photoId = photoFileId
      formattedData.fileId = PDFFileId
      formattedData.parentObjectType = parentObject.objectType
      formattedData.isCreateBlock = availableObjectTypes.length === 0
    }
    yield put(successGetCard(formattedData))
  } catch (error) {
    yield put(errorGetCard())
    console.log('function*getCardSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GET_CARD.REQUEST, getCardSaga)
}
