import React from 'react'
import {
  ObjectIsCheckedCell,
  CheckedMarker,
} from './styles'

const CustomObjectCell = (cell) => (
  <ObjectIsCheckedCell {...cell.getCellProps()}>
    <CheckedMarker isChecked={!cell.row.original.isRead} />
    {cell.value}
  </ObjectIsCheckedCell>
)

export default CustomObjectCell
