const data1 = [
  [
    57.1635798,
    65.2657866,
  ],
  [
    57.1623186,
    65.2674102,
  ],
  [
    57.1600841,
    65.2675009,
  ],
  [
    57.1614432,
    65.2689204,
  ],
  [
    57.1635863,
    65.2728652,
  ],
  [
    57.1636558,
    65.2734138,
  ],
  [
    57.1651081,
    65.2751849,
  ],
  [
    57.1664134,
    65.2806276,
  ],
  [
    57.16264,
    65.281554,
  ],
  [
    57.159555,
    65.2817375,
  ],
  [
    57.1563948,
    65.2804456,
  ],
  [
    57.1552065,
    65.2802202,
  ],
  [
    57.1543664,
    65.2797998,
  ],
  [
    57.1533357,
    65.2799147,
  ],
  [
    57.1528757,
    65.2805855,
  ],
  [
    57.1524289,
    65.2794113,
  ],
  [
    57.1517675,
    65.2798528,
  ],
  [
    57.150481,
    65.2827461,
  ],
  [
    57.1569852,
    65.2923838,
  ],
  [
    57.1611975,
    65.3030167,
  ],
  [
    57.1627486,
    65.3079619,
  ],
  [
    57.1625194,
    65.3243915,
  ],
  [
    57.1574603,
    65.3232401,
  ],
  [
    57.1574697,
    65.325649,
  ],
  [
    57.1569143,
    65.3258147,
  ],
  [
    57.1569715,
    65.3285405,
  ],
  [
    57.1565141,
    65.3294592,
  ],
  [
    57.1556565,
    65.3295495,
  ],
  [
    57.1542434,
    65.3265376,
  ],
  [
    57.1530672,
    65.3252123,
  ],
  [
    57.1530018,
    65.3239623,
  ],
  [
    57.1524622,
    65.3239035,
  ],
  [
    57.152434,
    65.325507,
  ],
  [
    57.151933,
    65.327187,
  ],
  [
    57.152228,
    65.3283,
  ],
  [
    57.152024,
    65.329543,
  ],
  [
    57.150598,
    65.330479,
  ],
  [
    57.150174,
    65.329983,
  ],
  [
    57.149595,
    65.329935,
  ],
  [
    57.149144,
    65.330508,
  ],
  [
    57.149042,
    65.329865,
  ],
  [
    57.148769,
    65.329159,
  ],
  [
    57.14883,
    65.328637,
  ],
  [
    57.148465,
    65.328026,
  ],
  [
    57.14806,
    65.327875,
  ],
  [
    57.147786,
    65.328102,
  ],
  [
    57.147253,
    65.32819,
  ],
  [
    57.147142,
    65.330659,
  ],
  [
    57.146289,
    65.330608,
  ],
  [
    57.146078,
    65.331504,
  ],
  [
    57.1457512,
    65.3332014,
  ],
  [
    57.1449495,
    65.3332482,
  ],
  [
    57.1432537,
    65.3327066,
  ],
  [
    57.1434769,
    65.3345376,
  ],
  [
    57.1436072,
    65.3356063,
  ],
  [
    57.1436867,
    65.3362757,
  ],
  [
    57.1438995,
    65.3371956,
  ],
  [
    57.1440545,
    65.3384344,
  ],
  [
    57.1427753,
    65.3386538,
  ],
  [
    57.1428204,
    65.3390869,
  ],
  [
    57.1430385,
    65.3395056,
  ],
  [
    57.1426647,
    65.3405133,
  ],
  [
    57.1424767,
    65.341019,
  ],
  [
    57.1419136,
    65.3425137,
  ],
  [
    57.1414487,
    65.3429045,
  ],
  [
    57.1410812,
    65.3436441,
  ],
  [
    57.1407088,
    65.344479,
  ],
  [
    57.1405697,
    65.3449991,
  ],
  [
    57.140324,
    65.3457133,
  ],
  [
    57.1402571,
    65.3464651,
  ],
  [
    57.1406807,
    65.3519818,
  ],
  [
    57.141287,
    65.3519536,
  ],
  [
    57.1422508,
    65.362715,
  ],
  [
    57.141031,
    65.363163,
  ],
  [
    57.140921,
    65.362034,
  ],
  [
    57.140771,
    65.361379,
  ],
  [
    57.139672,
    65.35893,
  ],
  [
    57.13935,
    65.358032,
  ],
  [
    57.139002,
    65.355812,
  ],
  [
    57.138434,
    65.353305,
  ],
  [
    57.138389,
    65.352786,
  ],
  [
    57.138677,
    65.351684,
  ],
  [
    57.138711,
    65.350928,
  ],
  [
    57.139446,
    65.349591,
  ],
  [
    57.139175,
    65.347571,
  ],
  [
    57.13867,
    65.347394,
  ],
  [
    57.137188,
    65.34839,
  ],
  [
    57.136619,
    65.34937,
  ],
  [
    57.135642,
    65.348213,
  ],
  [
    57.135274,
    65.348236,
  ],
  [
    57.134587,
    65.348517,
  ],
  [
    57.134333,
    65.34886,
  ],
  [
    57.133509,
    65.349639,
  ],
  [
    57.133157,
    65.349523,
  ],
  [
    57.133004,
    65.349152,
  ],
  [
    57.132739,
    65.348799,
  ],
  [
    57.132375,
    65.349068,
  ],
  [
    57.13212,
    65.348977,
  ],
  [
    57.131933,
    65.348503,
  ],
  [
    57.131644,
    65.348103,
  ],
  [
    57.131089,
    65.347811,
  ],
  [
    57.130582,
    65.347957,
  ],
  [
    57.130531,
    65.348353,
  ],
  [
    57.130392,
    65.34873,
  ],
  [
    57.129888,
    65.349085,
  ],
  [
    57.129167,
    65.349349,
  ],
  [
    57.127834,
    65.349352,
  ],
  [
    57.126847,
    65.346677,
  ],
  [
    57.126726,
    65.345947,
  ],
  [
    57.126707,
    65.345052,
  ],
  [
    57.126293,
    65.344973,
  ],
  [
    57.125788,
    65.344712,
  ],
  [
    57.125138,
    65.344226,
  ],
  [
    57.124176,
    65.342874,
  ],
  [
    57.123673,
    65.342983,
  ],
  [
    57.123172,
    65.342635,
  ],
  [
    57.122878,
    65.342827,
  ],
  [
    57.122428,
    65.342548,
  ],
  [
    57.122274,
    65.341934,
  ],
  [
    57.122055,
    65.341569,
  ],
  [
    57.121512,
    65.341706,
  ],
  [
    57.121044,
    65.341942,
  ],
  [
    57.120939,
    65.341843,
  ],
  [
    57.12088,
    65.341469,
  ],
  [
    57.12079,
    65.341401,
  ],
  [
    57.120479,
    65.34143,
  ],
  [
    57.119834,
    65.341816,
  ],
  [
    57.119764,
    65.34208,
  ],
  [
    57.11982,
    65.34248,
  ],
  [
    57.12003,
    65.34297,
  ],
  [
    57.119967,
    65.343221,
  ],
  [
    57.119435,
    65.34344,
  ],
  [
    57.119169,
    65.343741,
  ],
  [
    57.118854,
    65.343753,
  ],
  [
    57.118687,
    65.343495,
  ],
  [
    57.117818,
    65.343471,
  ],
  [
    57.115491,
    65.344078,
  ],
  [
    57.114858,
    65.344343,
  ],
  [
    57.114484,
    65.343348,
  ],
  [
    57.114278,
    65.342961,
  ],
  [
    57.113112,
    65.343675,
  ],
  [
    57.11286,
    65.344031,
  ],
  [
    57.112834,
    65.344471,
  ],
  [
    57.112992,
    65.344897,
  ],
  [
    57.113035,
    65.345445,
  ],
  [
    57.112796,
    65.345585,
  ],
  [
    57.112594,
    65.345809,
  ],
  [
    57.112541,
    65.346251,
  ],
  [
    57.112588,
    65.346539,
  ],
  [
    57.113061,
    65.346836,
  ],
  [
    57.113092,
    65.347583,
  ],
  [
    57.11234,
    65.348598,
  ],
  [
    57.112268,
    65.348888,
  ],
  [
    57.112261,
    65.34938,
  ],
  [
    57.112148,
    65.349641,
  ],
  [
    57.110474,
    65.348241,
  ],
  [
    57.109051,
    65.348647,
  ],
  [
    57.105853,
    65.350371,
  ],
  [
    57.109314,
    65.377689,
  ],
  [
    57.111286,
    65.394316,
  ],
  [
    57.1118614,
    65.3984756,
  ],
  [
    57.112123,
    65.400367,
  ],
  [
    57.11323,
    65.409009,
  ],
  [
    57.113746,
    65.412381,
  ],
  [
    57.11974,
    65.444379,
  ],
  [
    57.122938,
    65.456683,
  ],
  [
    57.1253136,
    65.4669685,
  ],
  [
    57.125778,
    65.468979,
  ],
  [
    57.126323,
    65.471131,
  ],
  [
    57.12606,
    65.471498,
  ],
  [
    57.125751,
    65.471792,
  ],
  [
    57.125194,
    65.472126,
  ],
  [
    57.125156,
    65.472028,
  ],
  [
    57.124931,
    65.471911,
  ],
  [
    57.124843,
    65.471632,
  ],
  [
    57.124611,
    65.471468,
  ],
  [
    57.124129,
    65.471427,
  ],
  [
    57.123935,
    65.471272,
  ],
  [
    57.121851,
    65.471761,
  ],
  [
    57.121753,
    65.472033,
  ],
  [
    57.121729,
    65.472326,
  ],
  [
    57.124604,
    65.477011,
  ],
  [
    57.1213095,
    65.4849635,
  ],
  [
    57.1201918,
    65.4871792,
  ],
  [
    57.114772,
    65.480861,
  ],
  [
    57.1103273,
    65.4727755,
  ],
  [
    57.1111474,
    65.4689542,
  ],
  [
    57.1113706,
    65.4675171,
  ],
  [
    57.1118073,
    65.4642515,
  ],
  [
    57.1110997,
    65.4652714,
  ],
  [
    57.110643,
    65.466758,
  ],
  [
    57.110458,
    65.467526,
  ],
  [
    57.110259,
    65.46763,
  ],
  [
    57.1081827,
    65.4644283,
  ],
  [
    57.1080758,
    65.4636742,
  ],
  [
    57.108209,
    65.46242,
  ],
  [
    57.107949,
    65.46155,
  ],
  [
    57.106104,
    65.4594686,
  ],
  [
    57.105545,
    65.458912,
  ],
  [
    57.104574,
    65.458174,
  ],
  [
    57.104112,
    65.457491,
  ],
  [
    57.103764,
    65.456192,
  ],
  [
    57.103797,
    65.454274,
  ],
  [
    57.103197,
    65.452411,
  ],
  [
    57.103265,
    65.450738,
  ],
  [
    57.103043,
    65.44864,
  ],
  [
    57.102793,
    65.447204,
  ],
  [
    57.102143,
    65.445873,
  ],
  [
    57.101163,
    65.441338,
  ],
  [
    57.100739,
    65.440604,
  ],
  [
    57.100679,
    65.439462,
  ],
  [
    57.100398,
    65.438121,
  ],
  [
    57.100011,
    65.437488,
  ],
  [
    57.098637,
    65.43456,
  ],
  [
    57.097485,
    65.433222,
  ],
  [
    57.097239,
    65.434773,
  ],
  [
    57.097256,
    65.482556,
  ],
  [
    57.097069,
    65.483527,
  ],
  [
    57.097163,
    65.486408,
  ],
  [
    57.097251,
    65.487493,
  ],
  [
    57.097242,
    65.489936,
  ],
  [
    57.0971245,
    65.4912044,
  ],
  [
    57.097414,
    65.491444,
  ],
  [
    57.0977272,
    65.4916179,
  ],
  [
    57.0980894,
    65.4918082,
  ],
  [
    57.097282,
    65.495281,
  ],
  [
    57.0972553,
    65.4955279,
  ],
  [
    57.097024,
    65.497661,
  ],
  [
    57.097223,
    65.498931,
  ],
  [
    57.097149,
    65.501392,
  ],
  [
    57.097176,
    65.503496,
  ],
  [
    57.09711,
    65.505483,
  ],
  [
    57.097001,
    65.505976,
  ],
  [
    57.096699,
    65.506778,
  ],
  [
    57.096739,
    65.507507,
  ],
  [
    57.096872,
    65.508506,
  ],
  [
    57.09682,
    65.509271,
  ],
  [
    57.096587,
    65.510943,
  ],
  [
    57.096637,
    65.512192,
  ],
  [
    57.092788,
    65.523097,
  ],
  [
    57.0926399,
    65.5233836,
  ],
  [
    57.0925753,
    65.5239607,
  ],
  [
    57.094429,
    65.5242522,
  ],
  [
    57.0961358,
    65.5245827,
  ],
  [
    57.0975442,
    65.5248845,
  ],
  [
    57.0982039,
    65.524985,
  ],
  [
    57.099777,
    65.5251178,
  ],
  [
    57.1015054,
    65.5251775,
  ],
  [
    57.1027006,
    65.5251261,
  ],
  [
    57.1035617,
    65.5250082,
  ],
  [
    57.1048365,
    65.5247572,
  ],
  [
    57.1048388,
    65.5252026,
  ],
  [
    57.1048518,
    65.5255169,
  ],
  [
    57.104913,
    65.5275607,
  ],
  [
    57.104888,
    65.5284717,
  ],
  [
    57.1048842,
    65.5321145,
  ],
  [
    57.1046098,
    65.5321345,
  ],
  [
    57.1040875,
    65.5287898,
  ],
  [
    57.1038995,
    65.5281868,
  ],
  [
    57.1027475,
    65.5269738,
  ],
  [
    57.1012048,
    65.5264472,
  ],
  [
    57.0980512,
    65.5260085,
  ],
  [
    57.0980269,
    65.5263967,
  ],
  [
    57.097896,
    65.5283863,
  ],
  [
    57.0978487,
    65.5290902,
  ],
  [
    57.097549,
    65.5293883,
  ],
  [
    57.0974883,
    65.5444522,
  ],
  [
    57.0979131,
    65.5461216,
  ],
  [
    57.099253,
    65.5505229,
  ],
  [
    57.1008808,
    65.5555228,
  ],
  [
    57.1076142,
    65.5507012,
  ],
  [
    57.108184,
    65.5523191,
  ],
  [
    57.1071096,
    65.5555078,
  ],
  [
    57.1046069,
    65.5622971,
  ],
  [
    57.1015735,
    65.5579035,
  ],
  [
    57.1014214,
    65.5579586,
  ],
  [
    57.1010889,
    65.5584684,
  ],
  [
    57.1006415,
    65.5590814,
  ],
  [
    57.1000432,
    65.5597511,
  ],
  [
    57.0998932,
    65.5598655,
  ],
  [
    57.0982883,
    65.5613468,
  ],
  [
    57.0980118,
    65.561647,
  ],
  [
    57.0977275,
    65.5617283,
  ],
  [
    57.097605,
    65.561742,
  ],
  [
    57.097063,
    65.5620963,
  ],
  [
    57.0962604,
    65.5630346,
  ],
  [
    57.0926571,
    65.5663107,
  ],
  [
    57.0924709,
    65.5657055,
  ],
  [
    57.0919409,
    65.5658889,
  ],
  [
    57.0902418,
    65.5666569,
  ],
  [
    57.0886711,
    65.5675578,
  ],
  [
    57.0881501,
    65.5677036,
  ],
  [
    57.0875166,
    65.5680324,
  ],
  [
    57.087607,
    65.569626,
  ],
  [
    57.08747,
    65.569666,
  ],
  [
    57.087293,
    65.570957,
  ],
  [
    57.087221,
    65.571948,
  ],
  [
    57.086466,
    65.571551,
  ],
  [
    57.086188,
    65.572645,
  ],
  [
    57.084889,
    65.573769,
  ],
  [
    57.083871,
    65.574283,
  ],
  [
    57.083789,
    65.574893,
  ],
  [
    57.083083,
    65.574755,
  ],
  [
    57.082923,
    65.578557,
  ],
  [
    57.082978,
    65.580415,
  ],
  [
    57.081913,
    65.581689,
  ],
  [
    57.079838,
    65.582289,
  ],
  [
    57.080132,
    65.584146,
  ],
  [
    57.080099,
    65.584355,
  ],
  [
    57.079504,
    65.584979,
  ],
  [
    57.079361,
    65.584842,
  ],
  [
    57.079366,
    65.585327,
  ],
  [
    57.079513,
    65.585831,
  ],
  [
    57.079895,
    65.586763,
  ],
  [
    57.080536,
    65.589727,
  ],
  [
    57.080443,
    65.590242,
  ],
  [
    57.080275,
    65.59036,
  ],
  [
    57.080343,
    65.590694,
  ],
  [
    57.080332,
    65.590934,
  ],
  [
    57.080248,
    65.590982,
  ],
  [
    57.079887,
    65.591864,
  ],
  [
    57.079781,
    65.592743,
  ],
  [
    57.079507,
    65.592906,
  ],
  [
    57.079325,
    65.593549,
  ],
  [
    57.079435,
    65.594844,
  ],
  [
    57.080012,
    65.597695,
  ],
  [
    57.080521,
    65.598668,
  ],
  [
    57.080832,
    65.59957,
  ],
  [
    57.081046,
    65.599861,
  ],
  [
    57.0812215,
    65.5999027,
  ],
  [
    57.079859,
    65.603357,
  ],
  [
    57.0794904,
    65.6044543,
  ],
  [
    57.0805601,
    65.6058502,
  ],
  [
    57.083277,
    65.6094683,
  ],
  [
    57.083757,
    65.6099331,
  ],
  [
    57.0840202,
    65.6092975,
  ],
  [
    57.0841823,
    65.6090158,
  ],
  [
    57.0858833,
    65.6042933,
  ],
  [
    57.0850762,
    65.6031751,
  ],
  [
    57.0843756,
    65.6022655,
  ],
  [
    57.0831777,
    65.6005886,
  ],
  [
    57.0831938,
    65.6001845,
  ],
  [
    57.084381,
    65.599266,
  ],
  [
    57.085828,
    65.596472,
  ],
  [
    57.085629,
    65.595168,
  ],
  [
    57.086652,
    65.594002,
  ],
  [
    57.086777,
    65.593304,
  ],
  [
    57.086983,
    65.593195,
  ],
  [
    57.087435,
    65.592762,
  ],
  [
    57.087488,
    65.593487,
  ],
  [
    57.087414,
    65.596149,
  ],
  [
    57.087093,
    65.603646,
  ],
  [
    57.087271,
    65.605805,
  ],
  [
    57.087765,
    65.607703,
  ],
  [
    57.086735,
    65.610387,
  ],
  [
    57.083222,
    65.619977,
  ],
  [
    57.078542,
    65.632293,
  ],
  [
    57.077617,
    65.634558,
  ],
  [
    57.075918,
    65.639403,
  ],
  [
    57.074611,
    65.642631,
  ],
  [
    57.074346,
    65.643145,
  ],
  [
    57.073779,
    65.644503,
  ],
  [
    57.073413,
    65.645215,
  ],
  [
    57.07226,
    65.647327,
  ],
  [
    57.07192,
    65.647848,
  ],
  [
    57.071628,
    65.648789,
  ],
  [
    57.077775,
    65.649307,
  ],
  [
    57.07785,
    65.650412,
  ],
  [
    57.077635,
    65.68128,
  ],
  [
    57.077548,
    65.68199,
  ],
  [
    57.077352,
    65.682947,
  ],
  [
    57.07696,
    65.68394,
  ],
  [
    57.076714,
    65.684356,
  ],
  [
    57.074122,
    65.687377,
  ],
  [
    57.071014,
    65.69075,
  ],
  [
    57.0701977,
    65.6935345,
  ],
  [
    57.070018,
    65.6946194,
  ],
  [
    57.069995,
    65.695323,
  ],
  [
    57.0699351,
    65.6959163,
  ],
  [
    57.0699674,
    65.6964673,
  ],
  [
    57.0704788,
    65.7008664,
  ],
  [
    57.076229,
    65.703854,
  ],
  [
    57.080056,
    65.704139,
  ],
  [
    57.072251,
    65.715088,
  ],
  [
    57.070861,
    65.716961,
  ],
  [
    57.070155,
    65.717756,
  ],
  [
    57.069479,
    65.718949,
  ],
  [
    57.066002,
    65.723697,
  ],
  [
    57.061431,
    65.730138,
  ],
  [
    57.0614208,
    65.7355154,
  ],
  [
    57.0616194,
    65.73589,
  ],
  [
    57.0618521,
    65.7362634,
  ],
  [
    57.0622722,
    65.7365649,
  ],
  [
    57.0626635,
    65.7366388,
  ],
  [
    57.0628835,
    65.7367484,
  ],
  [
    57.0632052,
    65.7367849,
  ],
  [
    57.0634551,
    65.7369381,
  ],
  [
    57.0636473,
    65.7371855,
  ],
  [
    57.0639548,
    65.7373505,
  ],
  [
    57.0647031,
    65.7377167,
  ],
  [
    57.0649914,
    65.7380348,
  ],
  [
    57.0653388,
    65.738239,
  ],
  [
    57.0658011,
    65.7386997,
  ],
  [
    57.0661132,
    65.7388683,
  ],
  [
    57.0662781,
    65.7389118,
  ],
  [
    57.0664149,
    65.7388733,
  ],
  [
    57.0665254,
    65.7387037,
  ],
  [
    57.0665329,
    65.7383663,
  ],
  [
    57.066475,
    65.7380944,
  ],
  [
    57.0664684,
    65.737622,
  ],
  [
    57.0663985,
    65.7374435,
  ],
  [
    57.06621,
    65.737255,
  ],
  [
    57.0659752,
    65.7369191,
  ],
  [
    57.0658119,
    65.7365915,
  ],
  [
    57.0657409,
    65.7363041,
  ],
  [
    57.0657424,
    65.7359384,
  ],
  [
    57.0658403,
    65.7356391,
  ],
  [
    57.0660812,
    65.7351866,
  ],
  [
    57.066335,
    65.734881,
  ],
  [
    57.0665049,
    65.7346258,
  ],
  [
    57.0665727,
    65.7345549,
  ],
  [
    57.066716,
    65.734399,
  ],
  [
    57.066995,
    65.734243,
  ],
  [
    57.0670837,
    65.7343193,
  ],
  [
    57.0671137,
    65.7343451,
  ],
  [
    57.0672337,
    65.7342167,
  ],
  [
    57.0673861,
    65.7340536,
  ],
  [
    57.0674959,
    65.7336303,
  ],
  [
    57.0678068,
    65.7337443,
  ],
  [
    57.0679787,
    65.7337681,
  ],
  [
    57.0683041,
    65.7341961,
  ],
  [
    57.0684207,
    65.7347326,
  ],
  [
    57.0684226,
    65.735085,
  ],
  [
    57.0683849,
    65.7358182,
  ],
  [
    57.0683832,
    65.7359992,
  ],
  [
    57.0684732,
    65.7362234,
  ],
  [
    57.0687649,
    65.7363521,
  ],
  [
    57.0688621,
    65.7364761,
  ],
  [
    57.0689769,
    65.7368935,
  ],
  [
    57.0690481,
    65.7374051,
  ],
  [
    57.069014,
    65.7375417,
  ],
  [
    57.0687407,
    65.7378866,
  ],
  [
    57.0687118,
    65.7379936,
  ],
  [
    57.0687299,
    65.7381915,
  ],
  [
    57.0692723,
    65.7396893,
  ],
  [
    57.0694086,
    65.740148,
  ],
  [
    57.0694081,
    65.740325,
  ],
  [
    57.0693627,
    65.7404436,
  ],
  [
    57.0690645,
    65.740568,
  ],
  [
    57.0690216,
    65.7406765,
  ],
  [
    57.0690469,
    65.740815,
  ],
  [
    57.0692073,
    65.7410554,
  ],
  [
    57.0692069,
    65.7411335,
  ],
  [
    57.0688717,
    65.7414493,
  ],
  [
    57.068279,
    65.7419252,
  ],
  [
    57.0678917,
    65.7413078,
  ],
  [
    57.0678246,
    65.7412792,
  ],
  [
    57.0677395,
    65.7413174,
  ],
  [
    57.0677197,
    65.7414455,
  ],
  [
    57.0682691,
    65.743895,
  ],
  [
    57.0683486,
    65.7445803,
  ],
  [
    57.0683005,
    65.7449681,
  ],
  [
    57.0681376,
    65.7453386,
  ],
  [
    57.0679898,
    65.7457357,
  ],
  [
    57.0680008,
    65.745912,
  ],
  [
    57.0680967,
    65.7460406,
  ],
  [
    57.0682842,
    65.7461456,
  ],
  [
    57.0684903,
    65.7460818,
  ],
  [
    57.0687232,
    65.7459888,
  ],
  [
    57.0696301,
    65.7449864,
  ],
  [
    57.069949,
    65.7447067,
  ],
  [
    57.0702562,
    65.7447121,
  ],
  [
    57.070642,
    65.745029,
  ],
  [
    57.07073,
    65.745883,
  ],
  [
    57.070557,
    65.746396,
  ],
  [
    57.070192,
    65.74691,
  ],
  [
    57.069809,
    65.74688,
  ],
  [
    57.069422,
    65.747033,
  ],
  [
    57.068991,
    65.747493,
  ],
  [
    57.068523,
    65.74836,
  ],
  [
    57.068332,
    65.748969,
  ],
  [
    57.068358,
    65.749677,
  ],
  [
    57.068491,
    65.74999,
  ],
  [
    57.068822,
    65.749925,
  ],
  [
    57.069379,
    65.749537,
  ],
  [
    57.069762,
    65.749535,
  ],
  [
    57.069867,
    65.749822,
  ],
  [
    57.069676,
    65.750821,
  ],
  [
    57.069139,
    65.751873,
  ],
  [
    57.068923,
    65.752898,
  ],
  [
    57.068866,
    65.754625,
  ],
  [
    57.069233,
    65.75669,
  ],
  [
    57.069276,
    65.758345,
  ],
  [
    57.069748,
    65.760279,
  ],
  [
    57.070431,
    65.762257,
  ],
  [
    57.07085,
    65.763022,
  ],
  [
    57.071705,
    65.76356,
  ],
  [
    57.072227,
    65.763333,
  ],
  [
    57.072261,
    65.763013,
  ],
  [
    57.072155,
    65.762246,
  ],
  [
    57.07198,
    65.7618,
  ],
  [
    57.071736,
    65.761833,
  ],
  [
    57.071423,
    65.761707,
  ],
  [
    57.070969,
    65.761358,
  ],
  [
    57.071054,
    65.760987,
  ],
  [
    57.071264,
    65.760621,
  ],
  [
    57.072012,
    65.760425,
  ],
  [
    57.073373,
    65.761407,
  ],
  [
    57.073915,
    65.76281,
  ],
  [
    57.073936,
    65.764473,
  ],
  [
    57.073834,
    65.76601,
  ],
  [
    57.073849,
    65.766947,
  ],
  [
    57.073784,
    65.767889,
  ],
  [
    57.073605,
    65.768282,
  ],
  [
    57.073579,
    65.769465,
  ],
  [
    57.073443,
    65.771065,
  ],
  [
    57.073497,
    65.77212,
  ],
  [
    57.072954,
    65.777236,
  ],
  [
    57.071968,
    65.784474,
  ],
  [
    57.071264,
    65.787065,
  ],
  [
    57.069626,
    65.79107,
  ],
  [
    57.071769,
    65.794874,
  ],
  [
    57.072926,
    65.795765,
  ],
  [
    57.0747906,
    65.7958497,
  ],
  [
    57.075171,
    65.795867,
  ],
  [
    57.076149,
    65.795536,
  ],
  [
    57.077433,
    65.794467,
  ],
  [
    57.077752,
    65.793776,
  ],
  [
    57.078137,
    65.791897,
  ],
  [
    57.078702,
    65.791194,
  ],
  [
    57.079583,
    65.787967,
  ],
  [
    57.081019,
    65.7864813,
  ],
  [
    57.083465,
    65.78443,
  ],
  [
    57.09687,
    65.782628,
  ],
  [
    57.099732,
    65.782225,
  ],
  [
    57.10297,
    65.782297,
  ],
  [
    57.104084,
    65.781789,
  ],
  [
    57.104849,
    65.779886,
  ],
  [
    57.106222,
    65.778692,
  ],
  [
    57.108521,
    65.777337,
  ],
  [
    57.109512,
    65.775582,
  ],
  [
    57.110681,
    65.772866,
  ],
  [
    57.111368,
    65.769321,
  ],
  [
    57.111247,
    65.764212,
  ],
  [
    57.111328,
    65.756821,
  ],
  [
    57.111217,
    65.755073,
  ],
  [
    57.111534,
    65.752856,
  ],
  [
    57.111662,
    65.751598,
  ],
  [
    57.112085,
    65.749884,
  ],
  [
    57.112675,
    65.748662,
  ],
  [
    57.1141352,
    65.7478985,
  ],
  [
    57.1152203,
    65.7479062,
  ],
  [
    57.1162904,
    65.7484114,
  ],
  [
    57.1174936,
    65.7493074,
  ],
  [
    57.1189098,
    65.7505857,
  ],
  [
    57.1204356,
    65.7522231,
  ],
  [
    57.1219846,
    65.7531538,
  ],
  [
    57.1230932,
    65.7534785,
  ],
  [
    57.126261,
    65.753272,
  ],
  [
    57.127286,
    65.753099,
  ],
  [
    57.128241,
    65.753292,
  ],
  [
    57.129179,
    65.753224,
  ],
  [
    57.130295,
    65.752733,
  ],
  [
    57.131109,
    65.751069,
  ],
  [
    57.131896,
    65.75073,
  ],
  [
    57.132759,
    65.750043,
  ],
  [
    57.135095,
    65.747475,
  ],
  [
    57.135948,
    65.746285,
  ],
  [
    57.136974,
    65.745647,
  ],
  [
    57.137659,
    65.745041,
  ],
  [
    57.138691,
    65.743892,
  ],
  [
    57.139238,
    65.743773,
  ],
  [
    57.141029,
    65.742558,
  ],
  [
    57.142905,
    65.741121,
  ],
  [
    57.143531,
    65.739881,
  ],
  [
    57.143903,
    65.738275,
  ],
  [
    57.144556,
    65.737093,
  ],
  [
    57.144628,
    65.735673,
  ],
  [
    57.144894,
    65.734033,
  ],
  [
    57.144698,
    65.732224,
  ],
  [
    57.144354,
    65.730418,
  ],
  [
    57.143909,
    65.728507,
  ],
  [
    57.143244,
    65.726896,
  ],
  [
    57.1428449,
    65.7262688,
  ],
  [
    57.1419983,
    65.7254635,
  ],
  [
    57.141178,
    65.724698,
  ],
  [
    57.140352,
    65.723362,
  ],
  [
    57.139844,
    65.721664,
  ],
  [
    57.139467,
    65.719608,
  ],
  [
    57.13956,
    65.717969,
  ],
  [
    57.140222,
    65.71623,
  ],
  [
    57.140802,
    65.714916,
  ],
  [
    57.141801,
    65.714323,
  ],
  [
    57.142648,
    65.714584,
  ],
  [
    57.143622,
    65.714767,
  ],
  [
    57.144586,
    65.715233,
  ],
  [
    57.145321,
    65.715256,
  ],
  [
    57.146408,
    65.71592,
  ],
  [
    57.147235,
    65.716189,
  ],
  [
    57.148011,
    65.716717,
  ],
  [
    57.148739,
    65.716942,
  ],
  [
    57.149705,
    65.717456,
  ],
  [
    57.151376,
    65.718091,
  ],
  [
    57.153248,
    65.718312,
  ],
  [
    57.154302,
    65.718333,
  ],
  [
    57.154987,
    65.718186,
  ],
  [
    57.1558673,
    65.7178795,
  ],
  [
    57.156756,
    65.717089,
  ],
  [
    57.157412,
    65.716149,
  ],
  [
    57.158144,
    65.714646,
  ],
  [
    57.158494,
    65.712795,
  ],
  [
    57.158627,
    65.711383,
  ],
  [
    57.158595,
    65.709241,
  ],
  [
    57.158681,
    65.704599,
  ],
  [
    57.158323,
    65.703444,
  ],
  [
    57.158167,
    65.701911,
  ],
  [
    57.158072,
    65.700315,
  ],
  [
    57.158608,
    65.698327,
  ],
  [
    57.159282,
    65.696833,
  ],
  [
    57.16081,
    65.693044,
  ],
  [
    57.161277,
    65.691303,
  ],
  [
    57.162252,
    65.688464,
  ],
  [
    57.162802,
    65.687506,
  ],
  [
    57.163599,
    65.684596,
  ],
  [
    57.163931,
    65.680807,
  ],
  [
    57.165123,
    65.679084,
  ],
  [
    57.17016,
    65.673495,
  ],
  [
    57.171736,
    65.67361,
  ],
  [
    57.1672681,
    65.7253352,
  ],
  [
    57.162889,
    65.776506,
  ],
  [
    57.1597109,
    65.8120486,
  ],
  [
    57.1603178,
    65.8122918,
  ],
  [
    57.165911,
    65.810641,
  ],
  [
    57.1660728,
    65.8026351,
  ],
  [
    57.1690815,
    65.8037644,
  ],
  [
    57.169246,
    65.80415,
  ],
  [
    57.16948,
    65.804618,
  ],
  [
    57.169785,
    65.804734,
  ],
  [
    57.171376,
    65.803924,
  ],
  [
    57.173518,
    65.804526,
  ],
  [
    57.174373,
    65.805556,
  ],
  [
    57.1742733,
    65.8080642,
  ],
  [
    57.1762973,
    65.8074619,
  ],
  [
    57.1766504,
    65.8073874,
  ],
  [
    57.1769807,
    65.8073767,
  ],
  [
    57.1772813,
    65.8074566,
  ],
  [
    57.1775899,
    65.8076292,
  ],
  [
    57.1778476,
    65.807862,
  ],
  [
    57.1795833,
    65.8097609,
  ],
  [
    57.1810058,
    65.8128104,
  ],
  [
    57.1812332,
    65.8135761,
  ],
  [
    57.1813862,
    65.8144406,
  ],
  [
    57.182991,
    65.815082,
  ],
  [
    57.182981,
    65.814146,
  ],
  [
    57.183196,
    65.811055,
  ],
  [
    57.1832981,
    65.810619,
  ],
  [
    57.1836121,
    65.8100939,
  ],
  [
    57.18438,
    65.809076,
  ],
  [
    57.185244,
    65.808478,
  ],
  [
    57.1870965,
    65.8080456,
  ],
  [
    57.18847,
    65.807725,
  ],
  [
    57.189517,
    65.807759,
  ],
  [
    57.189761,
    65.808845,
  ],
  [
    57.191283,
    65.808508,
  ],
  [
    57.193258,
    65.808525,
  ],
  [
    57.196,
    65.808257,
  ],
  [
    57.196896,
    65.808908,
  ],
  [
    57.197485,
    65.810214,
  ],
  [
    57.198383,
    65.81129,
  ],
  [
    57.198731,
    65.811849,
  ],
  [
    57.199642,
    65.8133482,
  ],
  [
    57.196969,
    65.7840042,
  ],
  [
    57.194084,
    65.7584562,
  ],
  [
    57.1952627,
    65.7498094,
  ],
  [
    57.193382,
    65.7414022,
  ],
  [
    57.191495,
    65.7380302,
  ],
  [
    57.190564,
    65.7346912,
  ],
  [
    57.192265,
    65.7330402,
  ],
  [
    57.193826,
    65.7340792,
  ],
  [
    57.195684,
    65.7355142,
  ],
  [
    57.199185,
    65.7355362,
  ],
  [
    57.199093,
    65.7352822,
  ],
  [
    57.199465,
    65.7346132,
  ],
  [
    57.1997879,
    65.7335568,
  ],
  [
    57.2002,
    65.7337272,
  ],
  [
    57.200592,
    65.7344232,
  ],
  [
    57.200636,
    65.7354502,
  ],
  [
    57.201017,
    65.7362992,
  ],
  [
    57.20122,
    65.7362132,
  ],
  [
    57.201787,
    65.7347742,
  ],
  [
    57.201907,
    65.7337722,
  ],
  [
    57.202112,
    65.7332532,
  ],
  [
    57.203207,
    65.7356302,
  ],
  [
    57.204342,
    65.7371532,
  ],
  [
    57.206425,
    65.7381042,
  ],
  [
    57.207712,
    65.7401932,
  ],
  [
    57.208236,
    65.7413822,
  ],
  [
    57.208923,
    65.7406512,
  ],
  [
    57.20956,
    65.7396262,
  ],
  [
    57.209533,
    65.7385942,
  ],
  [
    57.208957,
    65.7371342,
  ],
  [
    57.208822,
    65.7361392,
  ],
  [
    57.208146,
    65.7349992,
  ],
  [
    57.208442,
    65.7338062,
  ],
  [
    57.210433,
    65.7296472,
  ],
  [
    57.211316,
    65.7282112,
  ],
  [
    57.211833,
    65.7281162,
  ],
  [
    57.212258,
    65.7269392,
  ],
  [
    57.212448,
    65.7271582,
  ],
  [
    57.212053,
    65.7285732,
  ],
  [
    57.212611,
    65.7289542,
  ],
  [
    57.213217,
    65.7299712,
  ],
  [
    57.212881,
    65.7311372,
  ],
  [
    57.212236,
    65.7341542,
  ],
  [
    57.217355,
    65.7288652,
  ],
  [
    57.217551,
    65.7275612,
  ],
  [
    57.217539,
    65.7268462,
  ],
  [
    57.219113,
    65.7231122,
  ],
  [
    57.219727,
    65.7196072,
  ],
  [
    57.223057,
    65.7199992,
  ],
  [
    57.232709,
    65.7197282,
  ],
  [
    57.24411,
    65.6969142,
  ],
  [
    57.25162,
    65.6954372,
  ],
  [
    57.261445,
    65.6885172,
  ],
  [
    57.261402,
    65.6891192,
  ],
  [
    57.261734,
    65.6892812,
  ],
  [
    57.262966,
    65.6898822,
  ],
  [
    57.264293,
    65.6909632,
  ],
  [
    57.264762,
    65.6922592,
  ],
  [
    57.2658228,
    65.6925113,
  ],
  [
    57.2665102,
    65.6845597,
  ],
  [
    57.2685346,
    65.6828676,
  ],
  [
    57.2693025,
    65.6787193,
  ],
  [
    57.2672474,
    65.6769937,
  ],
  [
    57.2678161,
    65.6747238,
  ],
  [
    57.2681989,
    65.6725459,
  ],
  [
    57.268344,
    65.6719236,
  ],
  [
    57.269005,
    65.672417,
  ],
  [
    57.269465,
    65.670181,
  ],
  [
    57.26971,
    65.668863,
  ],
  [
    57.269975,
    65.666282,
  ],
  [
    57.269981,
    65.66532,
  ],
  [
    57.269849,
    65.664079,
  ],
  [
    57.269544,
    65.663168,
  ],
  [
    57.268845,
    65.661851,
  ],
  [
    57.268617,
    65.661271,
  ],
  [
    57.268571,
    65.660387,
  ],
  [
    57.268879,
    65.654024,
  ],
  [
    57.269158,
    65.649411,
  ],
  [
    57.269479,
    65.647911,
  ],
  [
    57.269265,
    65.647127,
  ],
  [
    57.269136,
    65.646297,
  ],
  [
    57.269414,
    65.640131,
  ],
  [
    57.269687,
    65.635368,
  ],
  [
    57.26969,
    65.634927,
  ],
  [
    57.269428,
    65.633713,
  ],
  [
    57.269479,
    65.633395,
  ],
  [
    57.268952,
    65.632532,
  ],
  [
    57.268247,
    65.631595,
  ],
  [
    57.265193,
    65.627119,
  ],
  [
    57.262755,
    65.623446,
  ],
  [
    57.261881,
    65.622348,
  ],
  [
    57.261256,
    65.621352,
  ],
  [
    57.261059,
    65.620726,
  ],
  [
    57.26095,
    65.619869,
  ],
  [
    57.261157,
    65.615115,
  ],
  [
    57.260897,
    65.614991,
  ],
  [
    57.261076,
    65.611657,
  ],
  [
    57.261437,
    65.60195,
  ],
  [
    57.261447,
    65.598255,
  ],
  [
    57.261481,
    65.598174,
  ],
  [
    57.261816,
    65.598073,
  ],
  [
    57.262254,
    65.58856,
  ],
  [
    57.262486,
    65.5813635,
  ],
  [
    57.2771404,
    65.5828282,
  ],
  [
    57.277846,
    65.5756,
  ],
  [
    57.277802,
    65.575391,
  ],
  [
    57.277739,
    65.575354,
  ],
  [
    57.26468,
    65.573993,
  ],
  [
    57.264681,
    65.573759,
  ],
  [
    57.265084,
    65.573546,
  ],
  [
    57.265223,
    65.5686378,
  ],
  [
    57.270737,
    65.5687666,
  ],
  [
    57.2710155,
    65.557394,
  ],
  [
    57.265545,
    65.5569595,
  ],
  [
    57.2657596,
    65.5514181,
  ],
  [
    57.2672216,
    65.5517077,
  ],
  [
    57.2673434,
    65.549283,
  ],
  [
    57.2670998,
    65.5476844,
  ],
  [
    57.2671636,
    65.5437684,
  ],
  [
    57.2726744,
    65.5437255,
  ],
  [
    57.2729006,
    65.5358398,
  ],
  [
    57.266054,
    65.534814,
  ],
  [
    57.266564,
    65.516766,
  ],
  [
    57.2666343,
    65.5151938,
  ],
  [
    57.266474,
    65.513931,
  ],
  [
    57.266559,
    65.507719,
  ],
  [
    57.263182,
    65.507333,
  ],
  [
    57.263317,
    65.507103,
  ],
  [
    57.263673,
    65.506776,
  ],
  [
    57.263869,
    65.506338,
  ],
  [
    57.263966,
    65.505555,
  ],
  [
    57.263983,
    65.503754,
  ],
  [
    57.264956,
    65.474147,
  ],
  [
    57.264914,
    65.47118,
  ],
  [
    57.264948,
    65.469525,
  ],
  [
    57.264877,
    65.468379,
  ],
  [
    57.264909,
    65.467108,
  ],
  [
    57.264954,
    65.467096,
  ],
  [
    57.264958,
    65.466637,
  ],
  [
    57.26501,
    65.466477,
  ],
  [
    57.266638,
    65.466604,
  ],
  [
    57.266731,
    65.466584,
  ],
  [
    57.266767,
    65.466357,
  ],
  [
    57.257179,
    65.432707,
  ],
  [
    57.257511,
    65.43183,
  ],
  [
    57.261411,
    65.420311,
  ],
  [
    57.262623,
    65.41647,
  ],
  [
    57.2683399,
    65.3999247,
  ],
  [
    57.2676148,
    65.3990573,
  ],
  [
    57.267256,
    65.398993,
  ],
  [
    57.265436,
    65.400863,
  ],
  [
    57.264256,
    65.401451,
  ],
  [
    57.263357,
    65.401717,
  ],
  [
    57.261747,
    65.4020645,
  ],
  [
    57.2581079,
    65.4032582,
  ],
  [
    57.2570167,
    65.4025609,
  ],
  [
    57.2559906,
    65.401701,
  ],
  [
    57.2558918,
    65.4022153,
  ],
  [
    57.255795,
    65.402725,
  ],
  [
    57.255589,
    65.403345,
  ],
  [
    57.255368,
    65.4037516,
  ],
  [
    57.254954,
    65.404077,
  ],
  [
    57.2542907,
    65.4050904,
  ],
  [
    57.2532689,
    65.4053969,
  ],
  [
    57.253054,
    65.4059384,
  ],
  [
    57.2529732,
    65.4067646,
  ],
  [
    57.252408,
    65.4069019,
  ],
  [
    57.252154,
    65.4068919,
  ],
  [
    57.250958,
    65.4073759,
  ],
  [
    57.250161,
    65.4072209,
  ],
  [
    57.249489,
    65.408101,
  ],
  [
    57.249063,
    65.408935,
  ],
  [
    57.248719,
    65.410827,
  ],
  [
    57.248674,
    65.411461,
  ],
  [
    57.248172,
    65.41402,
  ],
  [
    57.247722,
    65.417137,
  ],
  [
    57.245645,
    65.41603,
  ],
  [
    57.244791,
    65.420928,
  ],
  [
    57.244465,
    65.422215,
  ],
  [
    57.243909,
    65.423533,
  ],
  [
    57.2404272,
    65.4304326,
  ],
  [
    57.2398378,
    65.4293526,
  ],
  [
    57.239572,
    65.428598,
  ],
  [
    57.238988,
    65.427294,
  ],
  [
    57.236713,
    65.426605,
  ],
  [
    57.234815,
    65.424831,
  ],
  [
    57.233972,
    65.423802,
  ],
  [
    57.232764,
    65.422478,
  ],
  [
    57.232376,
    65.422129,
  ],
  [
    57.231539,
    65.421379,
  ],
  [
    57.230298,
    65.420843,
  ],
  [
    57.2293348,
    65.420915,
  ],
  [
    57.229027,
    65.420684,
  ],
  [
    57.2289282,
    65.4202419,
  ],
  [
    57.2294414,
    65.419505,
  ],
  [
    57.2298994,
    65.41861,
  ],
  [
    57.228124,
    65.416136,
  ],
  [
    57.227417,
    65.414901,
  ],
  [
    57.226505,
    65.413012,
  ],
  [
    57.226135,
    65.411836,
  ],
  [
    57.225889,
    65.410159,
  ],
  [
    57.225915,
    65.408475,
  ],
  [
    57.226206,
    65.405794,
  ],
  [
    57.226682,
    65.403049,
  ],
  [
    57.226723,
    65.40188,
  ],
  [
    57.226919,
    65.399415,
  ],
  [
    57.227085,
    65.395283,
  ],
  [
    57.226952,
    65.392729,
  ],
  [
    57.226565,
    65.390774,
  ],
  [
    57.226388,
    65.390339,
  ],
  [
    57.226164,
    65.390108,
  ],
  [
    57.22528,
    65.389807,
  ],
  [
    57.224949,
    65.389298,
  ],
  [
    57.224591,
    65.387861,
  ],
  [
    57.224326,
    65.386515,
  ],
  [
    57.224137,
    65.386223,
  ],
  [
    57.223943,
    65.385569,
  ],
  [
    57.22327,
    65.384246,
  ],
  [
    57.223208,
    65.383291,
  ],
  [
    57.222648,
    65.381728,
  ],
  [
    57.222368,
    65.380569,
  ],
  [
    57.222084,
    65.379744,
  ],
  [
    57.22161,
    65.379246,
  ],
  [
    57.221338,
    65.378825,
  ],
  [
    57.220996,
    65.378439,
  ],
  [
    57.220722,
    65.37672,
  ],
  [
    57.220887,
    65.375699,
  ],
  [
    57.220897,
    65.373345,
  ],
  [
    57.218473,
    65.373945,
  ],
  [
    57.217654,
    65.373653,
  ],
  [
    57.216482,
    65.373444,
  ],
  [
    57.21652,
    65.372756,
  ],
  [
    57.214575,
    65.372979,
  ],
  [
    57.213459,
    65.373531,
  ],
  [
    57.213285,
    65.372731,
  ],
  [
    57.212868,
    65.372053,
  ],
  [
    57.212645,
    65.372029,
  ],
  [
    57.212308,
    65.371456,
  ],
  [
    57.2108218,
    65.3717382,
  ],
  [
    57.2107996,
    65.3699824,
  ],
  [
    57.2108688,
    65.3684581,
  ],
  [
    57.2108793,
    65.3682321,
  ],
  [
    57.2108011,
    65.3643587,
  ],
  [
    57.2108032,
    65.3639961,
  ],
  [
    57.208549,
    65.364089,
  ],
  [
    57.2084671,
    65.3593132,
  ],
  [
    57.2084171,
    65.3554584,
  ],
  [
    57.2084392,
    65.355118,
  ],
  [
    57.2120765,
    65.3550103,
  ],
  [
    57.2120693,
    65.3546889,
  ],
  [
    57.2119971,
    65.351434,
  ],
  [
    57.2119773,
    65.350908,
  ],
  [
    57.2118835,
    65.3505825,
  ],
  [
    57.2114487,
    65.3496639,
  ],
  [
    57.2118183,
    65.3489554,
  ],
  [
    57.2115287,
    65.3484683,
  ],
  [
    57.2113446,
    65.3481934,
  ],
  [
    57.2111347,
    65.348239,
  ],
  [
    57.210722,
    65.347848,
  ],
  [
    57.210589,
    65.3476011,
  ],
  [
    57.2104118,
    65.3477303,
  ],
  [
    57.2102045,
    65.348257,
  ],
  [
    57.209898,
    65.348097,
  ],
  [
    57.209752,
    65.347818,
  ],
  [
    57.209665,
    65.347367,
  ],
  [
    57.209189,
    65.347032,
  ],
  [
    57.20911,
    65.346503,
  ],
  [
    57.208927,
    65.346005,
  ],
  [
    57.208576,
    65.345569,
  ],
  [
    57.208325,
    65.34508,
  ],
  [
    57.207781,
    65.34496,
  ],
  [
    57.207553,
    65.346469,
  ],
  [
    57.207415,
    65.346737,
  ],
  [
    57.206945,
    65.346591,
  ],
  [
    57.206664,
    65.346173,
  ],
  [
    57.206698,
    65.345143,
  ],
  [
    57.20567,
    65.34494,
  ],
  [
    57.2058957,
    65.3440813,
  ],
  [
    57.2047515,
    65.3434446,
  ],
  [
    57.2040084,
    65.3429644,
  ],
  [
    57.2031536,
    65.3420469,
  ],
  [
    57.2021137,
    65.3405878,
  ],
  [
    57.2011521,
    65.3388753,
  ],
  [
    57.2008267,
    65.3369122,
  ],
  [
    57.2007974,
    65.3352287,
  ],
  [
    57.2009637,
    65.3333817,
  ],
  [
    57.2016249,
    65.3325803,
  ],
  [
    57.2017751,
    65.3319047,
  ],
  [
    57.2015871,
    65.3306464,
  ],
  [
    57.2004738,
    65.3300618,
  ],
  [
    57.2004655,
    65.3290505,
  ],
  [
    57.2008835,
    65.3276934,
  ],
  [
    57.2010007,
    65.3265664,
  ],
  [
    57.2010408,
    65.3259934,
  ],
  [
    57.2010628,
    65.3255894,
  ],
  [
    57.2010888,
    65.3251402,
  ],
  [
    57.2011011,
    65.3246581,
  ],
  [
    57.2011647,
    65.3242122,
  ],
  [
    57.2011912,
    65.3236918,
  ],
  [
    57.20106,
    65.323775,
  ],
  [
    57.200684,
    65.323721,
  ],
  [
    57.200245,
    65.323371,
  ],
  [
    57.199982,
    65.322897,
  ],
  [
    57.199338,
    65.32195,
  ],
  [
    57.1997954,
    65.3198491,
  ],
  [
    57.2055351,
    65.3073263,
  ],
  [
    57.2136326,
    65.2910885,
  ],
  [
    57.2148354,
    65.2882273,
  ],
  [
    57.2092822,
    65.284118,
  ],
  [
    57.2042187,
    65.2784293,
  ],
  [
    57.1996521,
    65.2764033,
  ],
  [
    57.1893288,
    65.2727902,
  ],
  [
    57.1856906,
    65.2743053,
  ],
  [
    57.1802289,
    65.2737495,
  ],
  [
    57.1800971,
    65.2737361,
  ],
  [
    57.1745826,
    65.2716914,
  ],
  [
    57.1742428,
    65.2768028,
  ],
  [
    57.1693945,
    65.2754073,
  ],
  [
    57.1683155,
    65.2734734,
  ],
  [
    57.1668385,
    65.2684141,
  ],
  [
    57.1666406,
    65.2683162,
  ],
  [
    57.1656982,
    65.2689385,
  ],
  [
    57.1651979,
    65.2687669,
  ],
  [
    57.1635798,
    65.2657866,
  ],
]

const data2 = [
  [
    57.0125061,
    65.38467,
  ],
  [
    57.0008415,
    65.3849669,
  ],
  [
    57.0010618,
    65.4165491,
  ],
  [
    57.0062212,
    65.4166679,
  ],
  [
    57.0085498,
    65.411702,
  ],
  [
    57.0127934,
    65.4063221,
  ],
  [
    57.0126577,
    65.4013636,
  ],
  [
    57.012575,
    65.3935153,
  ],
  [
    57.0125167,
    65.3860307,
  ],
  [
    57.0125061,
    65.38467,
  ],
]

const data3 = [

  [
    57.0083314,
    65.4393071,
  ],
  [
    57.0030518,
    65.4453982,
  ],
  [
    57.0108434,
    65.4661225,
  ],
  [
    57.0145928,
    65.463155,
  ],
  [
    57.0144196,
    65.4629778,
  ],
  [
    57.0142311,
    65.4623254,
  ],
  [
    57.0142906,
    65.4615339,
  ],
  [
    57.0144356,
    65.4613263,
  ],
  [
    57.0144788,
    65.4612785,
  ],
  [
    57.0145025,
    65.4611512,
  ],
  [
    57.0143081,
    65.4608904,
  ],
  [
    57.0140343,
    65.4605924,
  ],
  [
    57.0138467,
    65.4603565,
  ],
  [
    57.0137582,
    65.4600594,
  ],
  [
    57.0135918,
    65.4600594,
  ],
  [
    57.0134713,
    65.4594642,
  ],
  [
    57.0128901,
    65.4585497,
  ],
  [
    57.012839,
    65.4576987,
  ],
  [
    57.0124729,
    65.4569882,
  ],
  [
    57.0125062,
    65.4558269,
  ],
  [
    57.0124375,
    65.4546637,
  ],
  [
    57.0123669,
    65.4539489,
  ],
  [
    57.0119557,
    65.4528137,
  ],
  [
    57.0120147,
    65.4521438,
  ],
  [
    57.0117488,
    65.4505422,
  ],
  [
    57.0112173,
    65.4490856,
  ],
  [
    57.0105013,
    65.4483017,
  ],
  [
    57.0099793,
    65.4471932,
  ],
  [
    57.009602,
    65.447286,
  ],
  [
    57.0090454,
    65.4451959,
  ],
  [
    57.008568,
    65.4445171,
  ],
  [
    57.0080696,
    65.4434493,
  ],
  [
    57.0076136,
    65.4424719,
  ],
  [
    57.0080426,
    65.4422331,
  ],
  [
    57.0078966,
    65.4416833,
  ],
  [
    57.0083834,
    65.4404199,
  ],
  [
    57.0089916,
    65.4395234,
  ],
  [
    57.0093815,
    65.4393448,
  ],
  [
    57.0083314,
    65.4393071,
  ],
]

export default [[...data1], [...data2], [...data3]]
