import styled from 'styled-components'
import LoaderStyled from '@/components/blocks/Loader/styles'

export const LoaderContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 24px;
  & > ${LoaderStyled} {
    padding-right: 5px;
    height: 18px;
    width: 18px;
    svg,
    .svgFill,
    .svgStroke {
      height: 18px;
      width: 18px;
    }
  }
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
`
