import {
  COUNTRY,
  REGION,
  CITY,
  LIGHT,
} from '@/constants/objectTypes'

export const mash = [
  // first mash
  [
    // line
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107967376708984, 65.57612609863281], // from - 13.2
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107906341552734, 65.57626342773438], // from - 10.2
      [57.107967376708984, 65.57612609863281], // to - 13.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.1080436706543, 65.57638549804688], // from - 12.1
      [57.10822677612305, 65.57664489746094], // to - 12.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.10822677612305, 65.57664489746094], // to - 12.2
    ],
  ],
  [
    // line
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1080436706543, 65.57638549804688], // from - 12.1
    ],
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107906341552734, 65.57626342773438], // from - 10.2
      [57.107967376708984, 65.57612609863281], // to - 13.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10822677612305, 65.57664489746094], // to - 12.2
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.1080436706543, 65.57638549804688], // from - 12.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.107906341552734, 65.57626342773438], // from - 10.2
    ],
  ],
  [
    // line
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107967376708984, 65.57612609863281], // from - 13.2
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107906341552734, 65.57626342773438], // from - 10.2
      [57.107967376708984, 65.57612609863281], // to - 13.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.10822677612305, 65.57664489746094], // to - 12.2
    ],
    [
      [57.1080436706543, 65.57638549804688], // from - 12.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.107906341552734, 65.57626342773438], // from - 10.2
    ],
  ],
]

const config = {
  id: 1,
  name: 'Россия',
  type: COUNTRY,
  location: [
    60,
    90,
  ],
  children: [
    {
      id: 564,
      name: 'Уральский федеральный округ',
      type: REGION,
      location: [
        56.44288053837966,
        65.35090050933302,
      ],
      children: [
        {
          id: 63,
          name: 'Тюмень',
          type: CITY,
          location: [57.153534, 65.542274],
          children: [
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2888,
              name: '44.1',
              status: 'NO_PROBLEM',
              location: [57.107852935791016, 65.57592010498047],
              telemetry: {
                accumulatedEnergyAmountRate1: 119498,
                timestamp: '/Date(1573746880000)/',
                totalPower: 89.61,
                turnedOn: true,
                voltage: 224.03,
                workTime: 1478,
                state: 'NO_PROBLEM',
                errors: [
                ],
              },
            },
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2970,
              name: '43.2',
              status: 'NO_PROBLEM',
              location: [57.107906341552734, 65.57626342773438],
              telemetry: {
                accumulatedEnergyAmountRate1: 121852,
                timestamp: '/Date(1573746880000)/',
                totalPower: 87.06,
                turnedOn: true,
                voltage: 223.24,
                workTime: 1488,
                state: 'NO_PROBLEM',
                errors: [

                ],
              },
            },
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2471,
              name: '42.1',
              status: 'NO_PROBLEM',
              location: [57.10808563232422, 65.57625579833984],
              telemetry: {
                accumulatedEnergyAmountRate1: 107599,
                timestamp: '/Date(1573746880000)/',
                totalPower: 58.54,
                turnedOn: true,
                voltage: 225.15,
                workTime: 2222,
                state: 'NO_PROBLEM',
                errors: [

                ],
              },
            },
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2812,
              name: '44.2',
              status: 'NO_PROBLEM',
              location: [57.1077880859375, 65.57604217529297],
              telemetry: {
                accumulatedEnergyAmountRate1: 152211,
                timestamp: '/Date(1573746880000)/',
                totalPower: 60.26,
                turnedOn: true,
                voltage: 223.18,
                workTime: 3079,
                state: 'NO_PROBLEM',
                errors: [

                ],
              },
            },
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2627,
              name: '42.2',
              status: 'NO_PROBLEM',
              location: [57.1080436706543, 65.57638549804688],
              telemetry: {
                accumulatedEnergyAmountRate1: 148559,
                timestamp: '/Date(1573746880000)/',
                totalPower: 58.29,
                turnedOn: true,
                voltage: 224.19,
                workTime: 3052,
                state: 'NO_PROBLEM',
                errors: [

                ],
              },
            },
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2566,
              name: '41.2',
              status: 'NO_PROBLEM',
              location: [57.10822677612305, 65.57664489746094],
              telemetry: {
                accumulatedEnergyAmountRate1: 149700,
                timestamp: '/Date(1573746880000)/',
                totalPower: 58.17,
                turnedOn: true,
                voltage: 223.72,
                workTime: 3100,
                state: 'NO_PROBLEM',
                errors: [

                ],
              },
            },
            {
              geoZoneId: 63,
              parentId: 191,
              type: LIGHT,
              mash: true,
              id: 2562,
              name: '43.1',
              status: 'NO_PROBLEM',
              location: [57.107967376708984, 65.57612609863281],
              telemetry: {
                accumulatedEnergyAmountRate1: 149202,
                timestamp: '/Date(1573746880000)/',
                totalPower: 58.32,
                turnedOn: true,
                voltage: 224.29,
                workTime: 3078,
                state: 'NO_PROBLEM',
                errors: [],
              },
            },
          ],
        },
      ],
    },
  ],
}

export default config
