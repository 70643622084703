import { GET_TENANT } from '../index'

export const requestGetTenant = (payload) => ({
  type: GET_TENANT.REQUEST,
  payload,
})

export const responseGetTenant = (payload) => ({
  type: GET_TENANT.SUCCESS,
  payload,
})

export const errorGetTenant = (payload) => ({
  type: GET_TENANT.ERROR,
  payload,
})
