import { all } from 'redux-saga/effects'

import getMessagesList from './getMessagesList'
import getMessagesWidget from './getMessagesWidget'
import getMessage from './getMessage'
import getGraphs from './getGraphs'
import getDateGraph from './getDateGraph'

export default function* root() {
  yield all([
    getMessagesList(),
    getMessagesWidget(),
    getMessage(),
    getGraphs(),
    getDateGraph(),
  ])
}
