import * as yup from 'yup'
import isNaN from 'lodash/isNaN'

const SERVER_TO_LOCAL = {
  IDENTIFIER: 'GENERAL_INFORMATION.IDENTIFIER',
  POWER_CONSUMPTION: 'TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION',
  START_DATE: 'EXPLOITATION.START_DATE',
  LIFE_TIME: 'EXPLOITATION.LIFE_TIME',
}

const formatValidator = (validators, customValidatorsArray = [], isWithoutRequired) => {
  const validatorsCopy = [...validators]
  const requiredValidator = validatorsCopy.filter((element) => element.type === 'required')
  const withoutRequired = validatorsCopy.filter((element) => element.type !== 'required')
  return [...customValidatorsArray, ...withoutRequired, ...isWithoutRequired ? [] : requiredValidator]
}

const blocks = {
  GENERAL_INFORMATION: {},
  TECHNICAL_CONSTRAINTS: {},
  EXPLOITATION: {},
  OBJECT_CHARACTERISTIC: {},
  SURVEY_DATA: {},
  SERVICING: {},
  LOCATION: {},
  ATTACHMENT: {},
}

const validationTypes = {
  string: yup.string(),
  long: yup.number('в поле должны быть числовые значения'),
  double: yup.number('в поле должны быть числовые значения'),
  list: yup.string(),
}

const defaultValidation = ({ yupScheme }) => yupScheme || yup.string()

const validatorByType = {
  required: ({ yupScheme }) => yupScheme.required('Обязательное поле'),
  // regExp: ({ yupScheme, message, value }) => {
  //   const localRegEx = value.substring(0, 3) + 0 + value.substring(4)
  //   return yupScheme.matches(localRegEx, regExpErrors[value] || message)
  // },
  // enum: ({ yupScheme, message, oneOf }) => yupScheme.oneOf(oneOf, message),
  minLength: ({ yupScheme, message, value }) => yupScheme.min(value, message),
  lon: ({ yupScheme }) => yupScheme
    .transform((value) => ((isNaN(value) || !value) ? undefined : value))
    .test('maxLimit', 'не более 180°', (value) => parseFloat(value) <= 180)
    .test('minLimit', 'не мение -180°', (value) => parseFloat(value) >= -180)
    .test('decimalLength', 'дробная часть - 6 символов', (value) => ((value || '').toString().split('.')[1] || '').length === 6),
  lat: ({ yupScheme }) => yupScheme
    .transform((value) => ((isNaN(value) || !value) ? undefined : value))
    .test('maxLimit', 'не более 90°', (value) => parseFloat(value) <= 90)
    .test('minLimit', 'не мение -90°', (value) => parseFloat(value) >= -90)
    .test('decimalLength', 'дробная часть - 6 символов', (value) => ((value || '').toString().split('.')[1] || '').length === 6),
}

export default (data, options) => {
  const {
    objectElementName,
    geoLocationDto,
    objectElementFieldsDto,
  } = data

  let objectNameValidation = validationTypes[objectElementName.type] || yup.string()
  formatValidator(objectElementName.validators).map((element) => {
    objectNameValidation = (validatorByType[element.type] || defaultValidation)({
      ...element,
      yupScheme: objectNameValidation,
    })
    return element
  })
  objectNameValidation = yup.string().max(64, 'Лимит - 64 символа, вкл пробел').required('Обязательное поле')

  const objectName = {
    GENERAL_INFORMATION: {
      NAME: objectNameValidation,
    },
  }

  let LATITUDE = yup.string()
  formatValidator(geoLocationDto.properties.latitude.validators, [{ type: 'lat' }, { type: 'required' }]).map((element) => {
    LATITUDE = (validatorByType[element.type] || defaultValidation)({
      ...element,
      yupScheme: LATITUDE,
    })
    return element
  })

  let LONGITUDE = yup.string()
  formatValidator(geoLocationDto.properties.longitude.validators, [{ type: 'lon' }, { type: 'required' }]).map((element) => {
    LONGITUDE = (validatorByType[element.type] || defaultValidation)({
      ...element,
      yupScheme: LONGITUDE,
    })
    return element
  })

  const locationElements = {
    LOCATION: {
      LATITUDE,
      LONGITUDE,
    },
  }

  let fieldNameValidator = validationTypes[objectElementFieldsDto.itemsType.properties.fieldName.type]
  formatValidator(objectElementFieldsDto.itemsType.properties.fieldName.validators).map((element) => {
    fieldNameValidator = (validatorByType[element.type] || defaultValidation)({
      ...element,
      yupScheme: fieldNameValidator,
    })
    return element
  })

  let fieldValueValidator = validationTypes[objectElementFieldsDto.itemsType.properties.value.type]
  formatValidator(objectElementFieldsDto.itemsType.properties.value.validators).map((element) => {
    fieldValueValidator = (validatorByType[element.type] || defaultValidation)({
      ...element,
      yupScheme: fieldValueValidator,
    })
    return element
  })

  let fieldValueValidatorForOptionalField = validationTypes[objectElementFieldsDto.itemsType.properties.value.type]
  formatValidator(objectElementFieldsDto.itemsType.properties.value.validators, [], true).map((element) => {
    fieldValueValidatorForOptionalField = (validatorByType[element.type] || defaultValidation)({
      ...element,
      yupScheme: fieldValueValidatorForOptionalField,
    })
    return element
  })

  const requiredFieldsForInstallationPassportValidator = objectElementFieldsDto.validators.filter(
    (validator) => validator.type === 'requiredFieldsForInstallationPassport',
  )[0] || {}

  const requiredFieldsForInstallationPassport = (requiredFieldsForInstallationPassportValidator.requiredFieldNames || []).reduce((accumulator, elementSlector) => {
    const localFieldName = SERVER_TO_LOCAL[elementSlector] || ''
    const blockAndFieldName = localFieldName.split('.')
    return {
      ...accumulator,
      [blockAndFieldName[0]]: {
        ...accumulator[blockAndFieldName[0]] || {},
        [blockAndFieldName[1]]: fieldValueValidator,
      },
    }
  }, {})
  const customFields = {
    customFields: yup.array().of(yup.object().shape({
      fieldName: yup.string().when('remove', {
        is: true,
        otherwise: () => fieldNameValidator,
      }),
      value: yup.string().when('remove', {
        is: true,
        otherwise: () => fieldValueValidator,
      }),
    })),
  }
  const defaultRequiredFields = {
    ...objectName,
    ...locationElements,
  }
  const formatOption = options.reduce((accumulator, option) => {
    if (option.catalogSelector === 'ATTACHMENT') {
      return accumulator
    }
    return {
      ...accumulator,
      [option.catalogSelector]: {
        ...accumulator[option.catalogSelector],
        [option.selector.split('.')[1]]: fieldValueValidatorForOptionalField,
      },
    }
  }, {})

  const scheme = Object.keys(blocks).reduce((accumulator, blockName) => {
    let block = {}
    if (formatOption[blockName]) {
      block = { ...block, ...formatOption[blockName] }
    }
    if (requiredFieldsForInstallationPassport[blockName]) {
      block = { ...block, ...requiredFieldsForInstallationPassport[blockName] }
    }
    if (defaultRequiredFields[blockName]) {
      block = { ...block, ...defaultRequiredFields[blockName] }
    }
    return {
      ...accumulator,
      [blockName]: yup.object().shape({
        ...block,
        ...customFields,
      }),
    }
  }, {})

  return yup
    .object()
    .shape({
      ...scheme,
    })
}
