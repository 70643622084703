import { DELETE_OBJECT } from './index'

export const requestDeleteObject = (payload) => ({
  type: DELETE_OBJECT.REQUEST,
  payload,
})

export const successDeleteObject = (payload) => ({
  type: DELETE_OBJECT.SUCCESS,
  payload,
})

export const errorDeleteObject = (payload) => ({
  type: DELETE_OBJECT.ERROR,
  payload,
})
