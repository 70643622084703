import React, {
  useCallback, useEffect, useState, useRef,
} from 'react'
import pt from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { FormattedMessage as Lang } from 'react-intl'
import elementCardConfigByType from '@/constants/passportization/models/card'
import elementPassportConfigByType from '@/constants/passportization/models/passport'
import Loader from '@/components/blocks/Loader'
import SelectControl from '@/components/controls/SelectControl'
import { PENDING } from '@/constants/requests'
import { LOCAL_TO_PASSPORT } from '@/constants/passportization/types'
import {
  SHUO,
  LIGHT,
  LAMP,
} from '@/constants/objectTypes'
import {
  PASSPORT,
} from '@/constants/passportization'
import PassportAndCard from './components/PassportAndCard'
import PassportHeader from './components/PassportHeader'
import FieldsSettings from './components/FieldsSettings'

import {
  Container,
  SelectControlContainer,
  LoaderContainer,
  Title,
  ScrollableWrapper,
} from './styles'

const PassportSidebar = ({
  type,
  selectedNode,
  requestStatus,

  operatedElement,
  setOperatedElement,

  requestObjectEquipment,
  selectedElementEquipment,
  data,

  requestAddPassportFile,
  requestAddPassportPhoto,
  requestDeletePassportFile,
  requestDeletePassportPhoto,
  requestExportPassportFile,
  requestGetPassportFile,
  requestUpdatePassport,
}) => {
  const passportFormRef = useRef(null)
  const [selectedFields, setSelectedFields] = useState([])
  const [isParentPassportOpen, setIsParentPassportOpen] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [tabType, setTabType] = useState(PASSPORT)

  useEffect(() => {
    setTabType(PASSPORT)
  }, [operatedElement.type])

  useEffect(() => {
    setEditForm(false)
  }, [data])

  useEffect(() => {
    if (operatedElement.type === LIGHT && selectedElementEquipment.length) {
      setOperatedElement(selectedElementEquipment.find((element) => element.type === LAMP))
    }
    if (operatedElement.type === SHUO && selectedElementEquipment.length) {
      setOperatedElement(selectedNode)
    }
  }, [
    operatedElement,
    operatedElement.type,
    selectedElementEquipment,
    selectedNode,
    setOperatedElement,
  ])

  useEffect(() => {
    if (operatedElement.type === LIGHT && selectedElementEquipment.length) {
      setOperatedElement(selectedElementEquipment.find((element) => element.type === LAMP))
    }
    if (operatedElement.type === SHUO && selectedElementEquipment.length) {
      setOperatedElement(selectedNode)
    }
  }, [
    operatedElement,
    operatedElement.type,
    selectedElementEquipment,
    selectedNode,
    setOperatedElement,
  ])

  useEffect(() => {
    requestObjectEquipment({
      name: selectedNode.name,
      type: LOCAL_TO_PASSPORT[selectedNode.type] || selectedNode.type,
    })
    setUploadedImage(null)
    if (selectedElementEquipment.length === 0) {
      setIsParentPassportOpen(true)
    } else {
      setIsParentPassportOpen(false)
    }
  },
  [
    selectedNode,
    requestObjectEquipment,
    selectedElementEquipment.length,
  ])

  useEffect(() => {
    const selectedFieldByConfig = (elementCardConfigByType[type] || [])
      .concat((elementPassportConfigByType[type] || [])).map((element) => element.selector)
    setSelectedFields(selectedFieldByConfig)
  }, [type])

  const handleResetForm = useCallback(() => {
    setUploadedImage(null)
    passportFormRef.current.resetForm()
  }, [])

  const handleSubmitForm = useCallback(() => {
    if (passportFormRef && passportFormRef.current && dirty) {
      setEditForm(false)
      setDirty(false)
      get(passportFormRef, 'current.setSubmitting', noop)(true)
      get(passportFormRef, 'current.handleResetForm', noop)()
      requestUpdatePassport({
        type: LOCAL_TO_PASSPORT[operatedElement.type],
        values: passportFormRef.current.state.values,
        form: passportFormRef.current,
        name: selectedNode.name,
      })
    }
  }, [
    passportFormRef,
    requestUpdatePassport,
    operatedElement,
    selectedNode,
    dirty,
  ])

  const onSelectedEquipmentChangeHandler = (value) => {
    const selectedElement = selectedElementEquipment
      .filter((element) => element.value === value)[0] || {}
    setOperatedElement({
      ...selectedElement,
      isParent: selectedElement.value === operatedElement.type,
    })
    setUploadedImage(null)
  }

  const handleDownloadFile = useCallback(() => {
    requestGetPassportFile({
      getAndDownload: true,
      id: get(data, 'file.uid', null),
    })
  }, [data, requestGetPassportFile])

  const handleExportFile = useCallback(() => {
    requestExportPassportFile({
      operatedElement,
      selectedFields,
      type: LOCAL_TO_PASSPORT[operatedElement.type],
    })
  }, [operatedElement, selectedFields, requestExportPassportFile])

  const handleRequestAddPassportFile = useCallback(() => {
    const { id } = selectedNode
    const fileName = get(data, 'file.title', null)
    const photoName = get(data, 'photo.title', null)
    const passportId = get(data, 'generalInfo.passportId', null)
    const fileValue = get(passportFormRef, 'current.state.values.inner.file', null)
    const photoValue = get(passportFormRef, 'current.state.values.inner.photo', null)
    if (fileValue && fileValue.name) {
      requestAddPassportFile({
        form: passportFormRef.current,
        id,
        type: LOCAL_TO_PASSPORT[type],
        passportId,
      })
    }
    if (photoValue && photoValue.name) {
      requestAddPassportPhoto({
        form: passportFormRef.current,
        id,
        type: LOCAL_TO_PASSPORT[type],
        passportId,
      })
    }
    if (!fileValue && fileName) {
      requestDeletePassportFile({
        id: get(data, 'file.uid', null),
        type: LOCAL_TO_PASSPORT[type],
        passportId,
      })
    }
    if (!photoValue && photoName) {
      requestDeletePassportPhoto({
        id: get(data, 'photo.uid', null),
        type: LOCAL_TO_PASSPORT[type],
        passportId,
      })
    }
  },
  [
    data,
    requestAddPassportFile,
    requestAddPassportPhoto,
    requestDeletePassportFile,
    requestDeletePassportPhoto,
    selectedNode,
    type,
  ])

  return (
    <Container>
      <PassportHeader
        selectedNode={selectedNode}
        name={selectedNode.name}
        toggleSettings={setIsSettingsOpen}
        isParentPassportOpen={isParentPassportOpen}
        setIsParentPassportOpen={setIsParentPassportOpen}
        isSettingsOpen={isSettingsOpen}
        setOperatedElement={setOperatedElement}
        dirty={dirty}
        handleResetForm={handleResetForm}
        requestAddPassportFile={handleRequestAddPassportFile}
        submitForm={handleSubmitForm}
        noOptions={selectedElementEquipment.length === 0}
        setEditForm={setEditForm}
        passportFormRef={passportFormRef}
      />
      <ScrollableWrapper>
        {!(isSettingsOpen || isParentPassportOpen)
        && selectedElementEquipment.length !== 0 && (
        <SelectControlContainer>
          <Title>
            <Lang id="passportization.passportSidebar.equipment" />
          </Title>
          <SelectControl
            withSearch
            placeholder="Выбрать оборудование"
            value={selectedElementEquipment.find(
              (element) => element.type === operatedElement.type,
            )
              && operatedElement.type}
            options={selectedElementEquipment}
            onChange={onSelectedEquipmentChangeHandler}
          />
        </SelectControlContainer>
        )}
        {!isSettingsOpen && (!isEmpty(operatedElement) || isParentPassportOpen)
        && (
          <>
            {requestStatus !== PENDING
              ? (
                <PassportAndCard
                  elementType={operatedElement.type}
                  cardData={data}
                  cardScheme={elementCardConfigByType[type] || []}
                  passportScheme={elementPassportConfigByType[type] || []}
                  passportData={data}
                  exportPassport={noop}
                  exportCart={noop}
                  selectedFields={selectedFields}
                  setDirty={setDirty}
                  passportRef={passportFormRef}
                  uploadedImage={uploadedImage}
                  setUploadedImage={setUploadedImage}
                  handleExportFile={handleExportFile}
                  handleDownloadFile={handleDownloadFile}
                  block={!editForm}
                  tabType={tabType}
                  setTabType={setTabType}
                />
              )
              : (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>

              )}
          </>
        )}
        {isSettingsOpen && (
          <FieldsSettings
            passportConfig={elementCardConfigByType[type]}
            cardConfig={elementPassportConfigByType[type]}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
          />
        )}
      </ScrollableWrapper>
    </Container>
  )
}


PassportSidebar.propTypes = {
  type: pt.string,
  selectedNode: pt.objectOf(pt.object),
  data: pt.objectOf(pt.object),
  requestStatus: pt.string,
  operatedElement: pt.objectOf(pt.object),
  selectedElementEquipment: pt.objectOf(pt.object),
  setOperatedElement: pt.func,
  requestObjectEquipment: pt.func,
  requestUpdatePassport: pt.func,
}


PassportSidebar.defaultProps = {
  type: '',
  selectedNode: {},
  requestStatus: '',
  operatedElement: {},
  selectedElementEquipment: [],
  setOperatedElement: noop,
  requestObjectEquipment: noop,
  requestUpdatePassport: noop,
  data: {},
}

export default PassportSidebar
