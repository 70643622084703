import React from 'react'
import memoWithName from '@/hocs/memoWithName'
import LoaderIcon from '@/components/icons/core/LoaderIcon'
import LoaderStyled from './styles'

const Loader = (props) => (
  <LoaderStyled {...props} className="loader">
    <LoaderIcon />
  </LoaderStyled>
)

export default memoWithName(Loader)
