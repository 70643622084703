import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TrashCanIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 3V2C11 0.895 10.105 0 9 0H7C5.895 0 5 0.895 5 2V3H1C0.448 3 0 3.448 0 4C0 4.552 0.448 5 1 5V15C1 16.657 2.343 18 4 18H12C13.657 18 15 16.657 15 15V5C15.552 5 16 4.552 16 4C16 3.448 15.552 3 15 3H11ZM9 3H7V2H9V3ZM13 15V5H3V15C3 15.552 3.448 16 4 16H12C12.552 16 13 15.552 13 15Z" fill={fill} className="svgFill" />
    </svg>

  )
}

TrashCanIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrashCanIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrashCanIcon)
