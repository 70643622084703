import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MarketIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.40744 13.3654H9.31485L8.96823 12.4784C8.9269 12.3742 8.90154 12.2989 8.89214 12.2524C8.88463 12.2041 8.87618 12.1334 8.86678 12.0404H8.85551C8.84612 12.1334 8.83672 12.2041 8.82733 12.2524C8.81981 12.2989 8.79539 12.3742 8.75406 12.4784L8.40744 13.3654Z" fill={fill} className="svgFill" />
      <path d="M12.0286 12.8243H12.4541C12.8299 12.8243 13.0854 12.7806 13.2206 12.6932C13.3559 12.6039 13.4235 12.457 13.4235 12.2524C13.4235 12.0479 13.3559 11.9019 13.2206 11.8145C13.0854 11.7252 12.8299 11.6806 12.4541 11.6806H12.0286V12.8243Z" fill={fill} className="svgFill" />
      <path d="M16.1965 12.8243H15.7709V11.6806H16.1965C16.5722 11.6806 16.8277 11.7252 16.963 11.8145C17.0982 11.9019 17.1659 12.0479 17.1659 12.2524C17.1659 12.457 17.0982 12.6039 16.963 12.6932C16.8277 12.7806 16.5722 12.8243 16.1965 12.8243Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5399 7.25H21.8754C22.18 7.25 22.4435 7.13867 22.6661 6.91602C22.8887 6.69336 23 6.42676 23 6.11621C23 5.80566 22.8916 5.54199 22.6749 5.3252C22.4582 5.1084 22.1917 5 21.8754 5H19.591C19.3801 5 19.1781 5.07617 18.9848 5.22852C18.7915 5.38086 18.6655 5.5625 18.607 5.77344L18.2907 7.33789L5.832 9.5C5.5157 9.5 5.28141 9.6582 5.12912 9.97461C4.97683 10.291 4.95925 10.6602 5.0764 11.082L6.79847 17.709C6.85705 17.9316 6.98298 18.1191 7.17627 18.2715C7.36957 18.4238 7.5775 18.5 7.80009 18.5H17.4121C17.6229 18.5 17.825 18.4238 18.0183 18.2715C18.2116 18.1191 18.3375 17.9316 18.3961 17.709L20.5399 7.25ZM7.1506 15C7.01534 15 6.97119 14.9386 7.01816 14.8159L8.39899 11.2259C8.45723 11.0753 8.54553 11 8.66388 11H9.05841C9.17676 11 9.26506 11.0753 9.3233 11.2259L10.7041 14.8159C10.7511 14.9386 10.707 15 10.5717 15H10.1236C9.99963 15 9.91321 14.9358 9.86436 14.8075L9.57692 14.0572H8.14537L7.85793 14.8075C7.80908 14.9358 7.72266 15 7.59867 15H7.1506ZM11.403 15C11.2715 15 11.2057 14.9303 11.2057 14.7908V11.2092C11.2057 11.0697 11.2715 11 11.403 11H12.4569C13.1333 11 13.602 11.1088 13.8631 11.3264C14.1262 11.5439 14.2577 11.8526 14.2577 12.2524C14.2577 12.6523 14.1262 12.9609 13.8631 13.1785C13.602 13.3961 13.1333 13.5049 12.4569 13.5049H12.0286V14.7908C12.0286 14.9303 11.9629 15 11.8313 15H11.403ZM14.9481 14.7908C14.9481 14.9303 15.0138 15 15.1453 15H15.5737C15.7052 15 15.7709 14.9303 15.7709 14.7908V13.5049H16.1993C16.8756 13.5049 17.3443 13.3961 17.6055 13.1785C17.8685 12.9609 18 12.6523 18 12.2524C18 11.8526 17.8685 11.5439 17.6055 11.3264C17.3443 11.1088 16.8756 11 16.1993 11H15.1453C15.0138 11 14.9481 11.0697 14.9481 11.2092V14.7908Z" fill={fill} className="svgFill" />
      <path d="M11.2618 20.1172C10.9338 19.7891 10.5355 19.625 10.0669 19.625C9.5983 19.625 9.2 19.7891 8.87199 20.1172C8.54397 20.4453 8.37997 20.8438 8.37997 21.3125C8.37997 21.7812 8.54397 22.1797 8.87199 22.5078C9.2 22.8359 9.5983 23 10.0669 23C10.5355 23 10.9338 22.8359 11.2618 22.5078C11.5898 22.1797 11.7538 21.7812 11.7538 21.3125C11.7538 20.8438 11.5898 20.4453 11.2618 20.1172Z" fill={fill} className="svgFill" />
      <path d="M16.8849 20.1172C16.5569 19.7891 16.1586 19.625 15.69 19.625C15.2214 19.625 14.8231 19.7891 14.4951 20.1172C14.1671 20.4453 14.0031 20.8438 14.0031 21.3125C14.0031 21.7812 14.1671 22.1797 14.4951 22.5078C14.8231 22.8359 15.2214 23 15.69 23C16.1586 23 16.5569 22.8359 16.8849 22.5078C17.2129 22.1797 17.3769 21.7812 17.3769 21.3125C17.3769 20.8438 17.2129 20.4453 16.8849 20.1172Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MarketIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MarketIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MarketIcon)
