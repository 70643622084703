export const NAME = 'name'
export const PARENT = 'parentId'
export const LONGITUDE = 'lon'
export const LATITUDE = 'lat'
export const REGION = 'region'
export const CITY = 'city'
export const MARK = 'mark'

export default {
  NAME,
  PARENT,
  LONGITUDE,
  LATITUDE,
  REGION,
  CITY,
  MARK,
}
