import DotsIcon from './DotsIcon'
import UrbanProcessesIcon from './UrbanProcessesIcon'
import TechnicalConditionIcon from './TechnicalConditionIcon'
import DepreciationEquipmentIcon from './DepreciationEquipmentIcon'
import OutdoorLightingIcon from './OutdoorLightingIcon'
import HeatSupplyIcon from './HeatSupplyIcon'
import AutomaticFaultReportingIcon from './AutomaticFaultReportingIcon'
import ArrowIcon from './ArrowIcon'
import TyumenOurHomeBigWidgetIcon from './TyumenOurHomeBigWidgetIcon'
import LightsIcon from './LightsIcon'
import HighBuildingIcon from './HighBuildingIcon'
import RoadIcon from './RoadIcon'
import ChartIcon from './ChartIcon'
import GeoPinIcon from './GeoPinIcon'
import ListIcon from './ListIcon'

export default {
  DotsIcon,
  UrbanProcessesIcon,
  TechnicalConditionIcon,
  DepreciationEquipmentIcon,
  OutdoorLightingIcon,
  HeatSupplyIcon,
  AutomaticFaultReportingIcon,
  ArrowIcon,
  TyumenOurHomeBigWidgetIcon,
  LightsIcon,
  HighBuildingIcon,
  RoadIcon,
  ChartIcon,
  GeoPinIcon,
  ListIcon,
}
