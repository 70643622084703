import { connect } from 'react-redux'
import { getTreeData } from '@/store/selectors/maps/socketEvents'
import {
  getExportFileStatus,
  getTableData,
  getSelector,
  getSelectedElement,
  getCheckBoxOption,
  getLoading,
} from '@/store/selectors/analyticsAlarms'
import { setSelectedNode } from '@/store/actions/appSettings'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  setSideBarFilter,
} from '@/store/actions/analyticsAlarms'
import {
  requestAnalyticsAlarmsTable,
} from '@/store/actions/analyticsAlarms/getTable'
import {
  requestAnalyticsAlarmsAlarm,
} from '@/store/actions/analyticsAlarms/getAlarmInformation'
import {
  requestAlarmManagerFile,
} from '@/store/actions/analyticsAlarms/getFile'

import AlarmsAnalytics from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  data: getTableData(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  selectedElement: getSelectedElement(state),
  checkBoxOption: getCheckBoxOption(state),
  selectedNode: getSelectedNode(state),
  loading: getLoading(state),
})
const mapDispatchToProps = (dispatch) => ({
  setEventTypes: (payload) => dispatch(setEventTypes(payload)),
  setStatusTypes: (payload) => dispatch(setStatusTypes(payload)),
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  setSideBarFilter: (payload) => dispatch(setSideBarFilter(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  requestAnalyticsAlarmsTable: (payload) => dispatch(requestAnalyticsAlarmsTable(payload)),
  requestAnalyticsAlarmsAlarm: (payload) => dispatch(requestAnalyticsAlarmsAlarm(payload)),
  requestAlarmManagerFile: (payload) => dispatch(requestAlarmManagerFile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsAnalytics)
