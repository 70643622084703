import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TrashIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 8V7C11 5.895 11.895 5 13 5H15C16.105 5 17 5.895 17 7V8H21C21.552 8 22 8.448 22 9C22 9.552 21.552 10 21 10V20C21 21.657 19.657 23 18 23H10C8.343 23 7 21.657 7 20V10C6.448 10 6 9.552 6 9C6 8.448 6.448 8 7 8H11ZM13 8H15V7H13V8ZM9 20V10H19V20C19 20.552 18.552 21 18 21H10C9.448 21 9 20.552 9 20Z" fill={fill} className="svgFill" />
    </svg>
  )
}

TrashIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrashIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrashIcon)
