import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_FAULT_ANALYTICS_TABLE = createAction('@/GET_FAULT_ANALYTICS_TABLE')
export const GET_FAULT_ANALYTICS_FILE = createAction('@/GET_FAULT_ANALYTICS_FILE')
export const GET_ALARMS_COUNT = createAction('@/GET_ALARMS_COUNT')
export const FAULT_ANALYTICS_TABLE = createTableActions('@/FAULT_ANALYTICS_TABLE')

export const SET_SELECTED_ELEMENT = '@/FAULT_ANALYTICS_SET_SELECTED_ELEMENT'
export const SET_EVETS_DATA = '@/FAULT_ANALYTICS_SET_EVETS_DATA'

export const setEventsData = (payload) => ({
  type: SET_EVETS_DATA,
  payload,
})
export const setSelectedElement = (payload) => ({
  type: SET_SELECTED_ELEMENT,
  payload,
})
