import {
  put, takeLatest, select, take,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import get from 'lodash/get'
import set from 'lodash/set'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import {
  getCard,
} from '@/store/selectors/installation'
import {
  VERIFIED,
} from '@/constants/objectStatuses'
import { successVerifyElements } from '@/store/actions/installation/verifyObject'
import createNotifications from '@/helpers/notificationSmall'
import installationFormatFields from '@/helpers/installationFormatFields'
import errorsMapper from '@/constants/errors/createObjectElement'
import { VERIFY_OBJECT, UPLOAD_INSTALLATION_OBJECT_FILE, DELETE_INSTALLATION_OBJECT_FILE } from '@/store/actions/installation'
import { requestUploadInstallationObjectFile } from '@/store/actions/installation/uploadObjectFile'
import { requestDeleteInstallationObjectFile } from '@/store/actions/installation/deleteObjectFile'
import {
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { INSTALLATIONS_OBJECT_ENDPOINT, generateValidateObject } from '@/constants/apiRoutes'

function* editObjectSaga({ payload }) {
  const {
    formValues,
    setErrors,
    values,
    setUrlFormValues = noop,
    type,
    setAlertConfig,
    intl,
  } = payload
  try {
    setAlertConfig({})
    const cardCopy = yield select(getCard)
    const cardPhotoId = get(cardCopy, 'data.photoId', null)
    const cardFileId = get(cardCopy, 'data.fileId', null)
    const valuesDeepClone = cloneDeep(values)
    set(valuesDeepClone, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', 'DEFAULT_EQUIPMENT_TYPE')
    const objectElementName = get(valuesDeepClone, 'GENERAL_INFORMATION.NAME', null)
    const latitude = get(valuesDeepClone, 'LOCATION.LATITUDE', null)
    const longitude = get(valuesDeepClone, 'LOCATION.LONGITUDE', null)
    const integrationId = get(valuesDeepClone, 'integrationId', null)
    const parentTreeElementId = get(valuesDeepClone, 'parentTreeElementId', null)
    const integrationType = get(valuesDeepClone, 'integrationType', null)
    const objectElementId = get(valuesDeepClone, 'objectElementId', null)
    const prevObjectElementFieldsDto = get(valuesDeepClone, 'prevObjectElementFieldsDto', {})
    const photoFile = get(values, 'ATTACHMENT.PHOTO_TITLE', null)
    const PDFFile = get(values, 'ATTACHMENT.DOCUMENT_TITLE', null)
    delete valuesDeepClone.LOCATION.LATITUDE
    delete valuesDeepClone.LOCATION.LONGITUDE
    delete valuesDeepClone.GENERAL_INFORMATION.NAME
    delete valuesDeepClone.integrationId
    delete valuesDeepClone.parentTreeElementId
    delete valuesDeepClone.integrationType
    delete valuesDeepClone.objectElementId
    delete valuesDeepClone.prevObjectElementFieldsDto
    delete valuesDeepClone.GENERAL_INFORMATION.EQUIPMENT_TYPE
    delete valuesDeepClone.ATTACHMENT
    delete cardCopy.elementsInfo
    delete valuesDeepClone.elementsInfo
    const fields = Object.keys(valuesDeepClone).reduce((accumulator, rootName) => {
      if (typeof valuesDeepClone[rootName] !== 'object') {
        return accumulator
      }
      const childField = Object.keys(valuesDeepClone[rootName] || {}).reduce((buffer, childName) => {
        if (childName === 'customFields') {
          const customFields = get(valuesDeepClone, `${rootName}.customFields`, []).reduce((customFieldsAccumulator, element) => {
            if (element.fieldName && element.value && !element.remove) {
              return [
                ...customFieldsAccumulator,
                {
                  ...element,
                  fieldName: element.fieldName,
                  value: element.value,
                  passportBlock: rootName,
                },
              ]
            }
            return customFieldsAccumulator
          }, [])
          return [
            ...buffer,
            ...customFields,
          ]
        }
        return [
          ...buffer,
          {
            ...prevObjectElementFieldsDto[`${rootName}.${childName}`] || {},
            fieldName: `${rootName}.${childName}`,
            value: get(valuesDeepClone, `${rootName}.${childName}`, null),
            passportBlock: rootName,
          },
        ]
      }, [])
      return [
        ...accumulator,
        ...childField,
      ]
    }, [])
    const formattedFields = installationFormatFields(fields)
    const formattedFieldsWithFilterOutEmptyFields = formattedFields.filter((el) => el.value)
    const body = {
      ...cardCopy.data,
      objectElementName,
      integrationId,
      parentTreeElementId,
      geoLocationDto: {
        latitude,
        longitude,
      },
      objectElementFieldsDto: formattedFieldsWithFilterOutEmptyFields,
      integrationType,
      objectType: type,
      objectState: VERIFIED,
      type: OBJECT_ELEMENT,
      objectElementId,
    }
    const { data } = yield request({
      url: INSTALLATIONS_OBJECT_ENDPOINT,
      method: 'put',
      body,
    })
    if (!photoFile && cardPhotoId) {
      yield put(requestDeleteInstallationObjectFile({
        id: data.treeElementId,
        fileId: cardPhotoId,
      }))
      yield take([DELETE_INSTALLATION_OBJECT_FILE.SUCCESS, DELETE_INSTALLATION_OBJECT_FILE.ERROR])
    }
    if (!PDFFile && cardFileId) {
      yield put(requestDeleteInstallationObjectFile({
        id: data.treeElementId,
        fileId: cardFileId,
      }))
      yield take([DELETE_INSTALLATION_OBJECT_FILE.SUCCESS, DELETE_INSTALLATION_OBJECT_FILE.ERROR])
    }
    if (photoFile) {
      yield put(requestUploadInstallationObjectFile({
        elementId: data.treeElementId,
        file: photoFile,
        title: photoFile.name,
        fileType: 'PHOTO',
      }))
      yield take([UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS, UPLOAD_INSTALLATION_OBJECT_FILE.ERROR])
    }
    if (PDFFile) {
      yield put(requestUploadInstallationObjectFile({
        elementId: data.treeElementId,
        file: PDFFile,
        title: PDFFile.name,
        fileType: 'DOCUMENT',
      }))
      yield take([UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS, UPLOAD_INSTALLATION_OBJECT_FILE.ERROR])
    }
    const toast = createNotifications()

    try {
      const { data: verificationData } = yield request({
        url: generateValidateObject(data.objectElementId),
        method: 'get',
      })
      const { asuActualFieldDtoList } = verificationData
      if (asuActualFieldDtoList.length !== 0) {
        const formattedErrors = asuActualFieldDtoList.map((errorObject) => {
          let localName = ''
          if (errorObject.fieldName === 'LATITUDE' || errorObject.fieldName === 'LONGITUDE') {
            localName = `LOCATION.${errorObject.fieldName}`
          }
          return {
            fieldName: localName || errorObject.fieldName,
            value: errorObject.value,
          }
        })
        let errorMessage = 'Не соответствие по следующим полям\n'
        const formErrorObject = formattedErrors.reduce((accumulator, errorObject) => {
          errorMessage += `\nПоле: ${intl.messages[`passport.fields.${errorObject.fieldName}`]},\nзначение: ${errorObject.value}\n`
          const accumulatorCopy = { ...accumulator }
          set(accumulatorCopy, errorObject.fieldName, `необходимое значение - "${errorObject.value}"`)
          return accumulatorCopy
        }, {})
        setErrors(formErrorObject)
        toast({
          title: 'Ошибка верификации.',
          type: 'error',
          description: errorMessage,
        })
      } else {
        toast({
          title: 'Верификация завершена',
          type: 'success',
          description: 'Объект успешно проверен.',
        })
        const {
          rootId, path, parentGeoZoneId, parentTreeId, FILTER,
        } = formValues
        const newPath = path.replace(parentTreeId, data.parentTreeElementId)
        setUrlFormValues({
          id: data.treeElementId,
          type: data.type,
          rootId,
          parentGeoZoneId,
          parentTreeId: data.parentTreeElementId,
          path: newPath,
          FILTER,
        })
      }
    } catch (error) {
      toast({
        title: 'Ошибка операции.',
        type: 'error',
        description: 'Данные от АСУ будут доступны после синхронизации',
      })
      const {
        rootId, path, parentGeoZoneId, parentTreeId, FILTER,
      } = formValues
      const newPath = path.replace(parentTreeId, data.parentTreeElementId)
      setUrlFormValues({
        id: data.treeElementId,
        type: data.type,
        rootId,
        parentGeoZoneId,
        parentTreeId: data.parentTreeElementId,
        path: newPath,
        FILTER,
      })
    }
    yield put(successVerifyElements())
  } catch (error) {
    setAlertConfig({})
    const errors = get(error, 'response.data.details', []).reduce((accumulator, element) => {
      const errorMessageAndLocation = element.message.split(': ')
      return {
        ...accumulator,
        [errorMessageAndLocation[1]]: errorsMapper[errorMessageAndLocation[0]] || errorMessageAndLocation[0],
      }
    }, {})
    const errorObject = {}
    Object.keys(errors).map((errorLocation) => {
      set(errorObject, errorLocation, errors[errorLocation])
      return errorLocation
    })
    setErrors(errorObject)
    console.log('function*editObjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(VERIFY_OBJECT.REQUEST, editObjectSaga)
}
