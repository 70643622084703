import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ListIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.45946 12.6642C5.65189 12.6642 5 13.2967 5 14.0802C5 14.8638 5.65189 15.4963 6.45946 15.4963C7.26703 15.4963 7.91892 14.8638 7.91892 14.0802C7.91892 13.2967 7.26703 12.6642 6.45946 12.6642ZM6.45946 7C5.65189 7 5 7.6325 5 8.41605C5 9.1996 5.65189 9.8321 6.45946 9.8321C7.26703 9.8321 7.91892 9.1996 7.91892 8.41605C7.91892 7.6325 7.26703 7 6.45946 7ZM6.45946 18.4889C5.73946 18.4889 5.16541 19.0553 5.16541 19.7444C5.16541 20.4336 5.74919 21 6.45946 21C7.16973 21 7.75351 20.4336 7.75351 19.7444C7.75351 19.0553 7.17946 18.4889 6.45946 18.4889ZM9.37838 20.6885H23V18.8004H9.37838V20.6885ZM9.37838 15.0243H23V13.1362H9.37838V15.0243ZM9.37838 7.47202V9.36008H23V7.47202H9.37838Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ListIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ListIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ListIcon)
