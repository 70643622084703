import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UpdateIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26 38C32.6274 38 38 32.6274 38 26C38 19.3726 32.6274 14 26 14C19.3726 14 14 19.3726 14 26C14 32.6274 19.3726 38 26 38Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" />
      <path d="M26 20C23.0874 20 20.6601 22.0756 20.115 24.8281H21.7805C22.2937 22.9776 23.986 21.6172 26 21.6172C27.2107 21.6172 28.3047 22.1091 29.0967 22.9033L27.1719 24.8281H32V20L30.2422 21.7578C29.1565 20.6718 27.657 20 26 20ZM20 27.1719V32L21.7578 30.2422C22.8435 31.3282 24.343 32 26 32C28.9126 32 31.3399 29.9244 31.885 27.1719H30.2195C29.7063 29.0224 28.014 30.3828 26 30.3828C24.7893 30.3828 23.6953 29.8909 22.9033 29.0967L24.8281 27.1719H20Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UpdateIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UpdateIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UpdateIcon)
