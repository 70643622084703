import React, { useCallback, useState, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import CollapsedInfoBlock from '@/components/blocks/CollapsedInfoBlock'
import AnalyticExpensesForm from '@/forms/AnalyticExpensesForm'
import CoreIcons from '@/components/icons/core'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { horizontalPriorityList } from '@/components/blocks/PortalTooltip/config'
import {
  DEMONSTRATION_GRAPH,
  DEMONSTRATION_TABLE,
  COMPARATIVE_ANALSIS,
  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from '@/constants/names'
import { DEMONSTRATION, GRAPH_VIEW } from '@/constants/forms/analyticExpenses'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
  TableContainer,
  IconContainer,
} from './styles'

const intervalOptions = [
  {
    id: ANNUALLY,
    value: ANNUALLY,
    title: (<Lang id="consumptionAnalysis.intervalOptions.annualy" />),
  },
  {
    id: MONTHLY,
    value: MONTHLY,
    title: (<Lang id="consumptionAnalysis.intervalOptions.monthly" />),
  },
  {
    id: WEEKLY,
    value: WEEKLY,
    title: (<Lang id="consumptionAnalysis.intervalOptions.weekly" />),
  },
  {
    id: DAILY,
    value: DAILY,
    title: (<Lang id="consumptionAnalysis.intervalOptions.daily" />),
  },
]

const AnalysisSettings = ({
  name,
  node,
  savings,
  statusTable,
  statusGraph,
  isSettingsOpen,
  setIsSettingsOpen,
  getHeatTableData,
  getHeatGraphData,
  getTableData,
  getGraphData,
  getPlanGraphData,
  setFormValues,
  calculatorValue,
  consumptionForm,
  data,
}) => {
  const [form, setForm] = useState({})
  const submitForm = useCallback(() => {
    if (form) {
      form.submitForm()
    }
  }, [form])

  const renderCollapseButtons = useCallback(() => {
    if (
      (
        statusTable === REQUEST_STATUSES.IDLE
        && form.values
        && form.values[DEMONSTRATION] === DEMONSTRATION_TABLE
      )
      || (
        statusGraph === REQUEST_STATUSES.IDLE
        && form.values
        && form.values[DEMONSTRATION] === DEMONSTRATION_GRAPH
      )
    ) {
      return <CoreIcons.SynchronizationIcon color="white" />
    }

    return <CoreIcons.PlayIcon />
  }, [statusTable, form.values, statusGraph])

  const renderGraphToolTooTitle = useMemo(() => {
    if (form.values && form.values[DEMONSTRATION] === DEMONSTRATION_TABLE && data.length === 0) {
      return <Lang id="tooltip.tableBuild" />
    }
    if (form.values && form.values[DEMONSTRATION] === DEMONSTRATION_GRAPH && data.length === 0) {
      return <Lang id="tooltip.graphBuild" />
    }
    if (form.values && form.values[DEMONSTRATION] === DEMONSTRATION_TABLE) {
      return <Lang id="tooltip.tableRefresh" />
    }
    if (form.values && form.values[DEMONSTRATION] === DEMONSTRATION_GRAPH) {
      return <Lang id="tooltip.graphRefresh" />
    }
    return <Lang id="tooltip.build" />
  }, [data.length, form.values])

  const renderCollapseContent = useCallback(() => {
    if (form && form.values && form.values.type) {
      return (
        <>
          <PortalTooltip
            title={(<Lang id="tooltip.graph" />)}
            priorityList={horizontalPriorityList}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <IconContainer
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                key={`view-${form.values[DEMONSTRATION] === DEMONSTRATION_GRAPH}`}
                isActive={form.values[DEMONSTRATION] === DEMONSTRATION_GRAPH}
                onClick={() => form.setValues({
                  ...form.values,
                  [DEMONSTRATION]: DEMONSTRATION_GRAPH,
                  [GRAPH_VIEW]: form.values[GRAPH_VIEW] || COMPARATIVE_ANALSIS,
                })}
              >
                <CoreIcons.GraphIcon />
              </IconContainer>
            )}
          />
          <PortalTooltip
            title={(<Lang id="tooltip.table" />)}
            priorityList={horizontalPriorityList}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <IconContainer
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                key={`view-${form.values[DEMONSTRATION] === DEMONSTRATION_TABLE}`}
                isActive={form.values[DEMONSTRATION] === DEMONSTRATION_TABLE}
                onClick={() => form.setValues({
                  ...form.values,
                  [DEMONSTRATION]: DEMONSTRATION_TABLE,
                })}
              >
                <CoreIcons.TableIcon />
              </IconContainer>
            )}
          />
          <PortalTooltip
            title={renderGraphToolTooTitle}
            priorityList={horizontalPriorityList}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <IconContainer
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                onClick={submitForm}
                isAction
              >
                {renderCollapseButtons()}
              </IconContainer>
            )}
          />
        </>
      )
    }
    return null
  }, [form, renderCollapseButtons, renderGraphToolTooTitle, submitForm])

  return (
    <CollapsedInfoBlock
      name={name}
      isSettingsOpen={isSettingsOpen}
      setIsSettingsOpen={setIsSettingsOpen}
      content={(
        <TableContainer>
          <AnalyticExpensesForm
            integrationTypes={node.integrationTypes}
            setForm={setForm}
            intervalOptions={intervalOptions}
            getHeatTableData={getHeatTableData}
            getHeatGraphData={getHeatGraphData}
            getTableData={getTableData}
            getGraphData={getGraphData}
            getPlanGraphData={getPlanGraphData}
            setFormValues={setFormValues}
            node={node}
            consumptionForm={consumptionForm}
            savings={savings}
            statusTable={statusTable}
            statusGraph={statusGraph}
            calculatorValue={calculatorValue}
          />
        </TableContainer>
        )}
      collapsedContent={renderCollapseContent()}
    />
  )
}

AnalysisSettings.propTypes = {
  name: pt.string,
  node: pt.shape({
    id: pt.number,
    name: pt.string,
    integrationTypes: pt.arrayOf(pt.string),
  }),
  data: pt.arrayOf(pt.object),
  isSettingsOpen: pt.bool,
  setIsSettingsOpen: pt.func,
  getHeatTableData: pt.func,
  getHeatGraphData: pt.func,
  getTableData: pt.func,
  getGraphData: pt.func,
  getPlanGraphData: pt.func,
  setFormValues: pt.func,
  savings: pt.shape({
    min: pt.number,
    max: pt.number,
    average: pt.number,
  }),
  calculatorValue: pt.number,
}

AnalysisSettings.defaultProps = {
  name: '',
  node: {},
  data: [],
  isSettingsOpen: false,
  setIsSettingsOpen: noop,
  getHeatTableData: noop,
  getHeatGraphData: noop,
  getTableData: noop,
  getGraphData: noop,
  getPlanGraphData: noop,
  setFormValues: noop,
  savings: {},
  calculatorValue: null,
}

export default AnalysisSettings
