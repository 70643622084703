import { GET_TYUMEN_GRAPHS } from './index'

export const requestGetTyumenGraphs = (payload) => ({
  type: GET_TYUMEN_GRAPHS.REQUEST,
  payload,
})

export const successGetTyumenGraphs = (payload) => ({
  type: GET_TYUMEN_GRAPHS.SUCCESS,
  payload,
})

export const errorGetTyumenGraphs = (payload) => ({
  type: GET_TYUMEN_GRAPHS.ERROR,
  payload,
})
