import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ServiceDeskIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.99784 13.6296C7.37354 13.6296 5.72334 12.6631 4.76025 11.3839C0.139676 11.3839 0 18 0 18H17.9946C17.9946 18 18.3573 11.3546 13.1611 11.3546C12.1991 12.6496 10.6221 13.6296 8.99784 13.6296Z" fill="#3D3D3D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.8682 5.69817C12.8682 7.60171 11.1347 11.2417 8.99446 11.2417C6.85764 11.2417 5.12296 7.60058 5.12296 5.69817C5.12296 3.79577 6.85652 2.25127 8.99446 2.25127C11.1347 2.2524 12.8682 3.7969 12.8682 5.69817Z" fill="#3D3D3D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5961 4.05545C14.5961 3.69868 14.1005 3.41078 13.4855 3.40852V2.67352C13.4855 2.56401 13.5272 0 9.00798 0C4.49103 0 4.53271 2.56401 4.53271 2.67352V3.43449C4.52145 3.43449 4.51244 3.4311 4.5023 3.4311C3.89065 3.4311 3.39841 3.71448 3.39841 4.06561V7.17494C3.39841 7.5238 3.89178 7.80832 4.5023 7.80832C5.11282 7.80832 5.60844 7.5238 5.60844 7.17494V4.06561C5.60844 4.01706 5.57803 3.97303 5.56001 3.92787V3.07997C5.56001 3.00207 5.2356 1.06693 9.00798 1.06693C12.7815 1.06693 12.3895 3.00207 12.3895 3.07997V3.97303C12.3827 4.00125 12.3613 4.02609 12.3613 4.05545V7.2359C12.3613 7.5938 12.8603 7.88396 13.4787 7.88396C13.4923 7.88396 13.5024 7.87945 13.5159 7.87945V9.04121H12.4075V10.1364H14.6074L14.5961 4.05545Z" fill="#3D3D3D"/>
    </svg>
  )
}

ServiceDeskIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ServiceDeskIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ServiceDeskIcon)
