import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  successGetObjectTypes,
  errorGetObjectTypes,
} from '@/store/actions/installation/getObjectTypes'

import { generateGetTypes } from '@/constants/apiRoutes'
import { GET_OBJECT_TYPES } from '@/store/actions/installation'

function* getObjectTypesSaga({ payload: { integrationType, type } }) {
  try {
    const { data } = yield request({
      url: generateGetTypes(integrationType, type),
      method: 'get',
    })
    const formattedData = data.map((name) => ({
      value: name,
      title: <Lang id={`globalNames.objectTypes.${name}`} />,
    }))
    yield put(successGetObjectTypes(formattedData))
  } catch (error) {
    console.log('function*getObjectTypesSaga -> error', error)
    yield put(errorGetObjectTypes())
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_TYPES.REQUEST, getObjectTypesSaga)
}
