const createLocalizationFromObject = (object, prevPath = '') => Object
  .keys(object || {})
  .reduce((accumulator, item) => {
    const localPath = `${prevPath}${prevPath.length > 0 ? '.' : ''}${item}`
    if (typeof object[item] === 'string') {
      return { ...accumulator, [localPath]: object[item] }
    }
    return { ...accumulator, ...createLocalizationFromObject(object[item], localPath) }
  }, {})

export default createLocalizationFromObject
