import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EquipmentIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.40378 23H22V21.4883H8.40378C7.85292 21.4883 7.57749 21.2422 7.57749 20.75C7.57749 20.2578 7.85292 20.0117 8.40378 20.0117H22V5H8.40378C7.70268 5 7.12678 5.2168 6.67608 5.65039C6.22538 6.08398 6.00002 6.61719 6.00002 7.25V20.0117C5.99997 20.1875 6.00002 20.5391 6.00002 20.75C6.00002 21.3828 6.22538 21.916 6.67608 22.3496C7.12678 22.7832 7.70268 23 8.40378 23ZM7.57749 7.25C7.57749 6.75781 7.85292 6.51172 8.40378 6.51172H20.4225V18.5H8.40378C8.20346 18.5 7.92804 18.5469 7.57749 18.6406V7.25ZM10.8075 11.75C10.8075 11.3423 11.1381 11.0117 11.5458 11.0117H17.2805C17.6883 11.0117 18.0188 11.3423 18.0188 11.75C18.0188 12.1577 17.6883 12.4883 17.2805 12.4883H11.5458C11.1381 12.4883 10.8075 12.1577 10.8075 11.75ZM10.8075 8.74414C10.8075 8.32669 11.1459 7.98828 11.5634 7.98828H17.2629C17.6804 7.98828 18.0188 8.32669 18.0188 8.74414C18.0188 9.16159 17.6804 9.5 17.2629 9.5H11.5634C11.1459 9.5 10.8075 9.16159 10.8075 8.74414Z" fill={fill} className="svgFill" />
    </svg>
    
  )
}

EquipmentIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EquipmentIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EquipmentIcon)
