export const dropDownObject = [
  { value: 'currentTransformer', title: 'Трансформатор тока' },
  { value: 'baseStation', title: 'Базовая Станция' },
  { value: 'mercuryCounter', title: 'Счетчик Меркурий' },
]

export const dropDownStatus = [
  { value: 'notViewed', title: 'Новая задача (не просмотрена)' },
  { value: 'new', title: 'Новая задача' },
  { value: 'registered', title: 'Зарегистрировано' },
  { value: 'contractorAppointed', title: 'Назначен исполнитель' },
  { value: 'inProgress', title: 'Задача выполняется' },
  { value: 'waitingControl', title: 'Ждет контроля' },
  { value: 'completed', title: 'Исполнено' },
  { value: 'delayed', title: 'Задача отложена' },
  { value: 'rejected', title: 'Задача отклонена ответственным' },
  { value: 'almostExpired', title: 'Задача почти просрочена' },
  { value: 'expired', title: 'Задача просрочена' },
]
