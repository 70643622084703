import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const LifeIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4546 9.8191H11.6818V12.1287L13.6764 13.2806L13.0628 14.3434L10.4546 12.8372V9.8191ZM13.7272 5.7269H2.63625V14.7263H6.0965C5.5895 13.6584 5.4254 12.4598 5.6267 11.2949C5.8281 10.1301 6.3849 9.056 7.2209 8.2202C8.0569 7.3844 9.131 6.8278 10.2959 6.6268C11.4608 6.4257 12.6594 6.59 13.7272 7.0972V5.7269ZM2.63625 16.3625C2.20229 16.3625 1.7861 16.1901 1.47925 15.8833C1.17239 15.5764 1 15.1602 1 14.7263L1.00818 3.2725C1.00818 2.36847 1.73059 1.63625 2.63625 1.63625H3.45438V0H5.0906V1.63625H11.2728V0H12.909V1.63625H13.7272C14.1611 1.63625 14.5773 1.80864 14.8842 2.1155C15.191 2.42236 15.3634 2.83854 15.3634 3.2725V8.2647C16.4196 9.3424 17.0077 10.7934 17 12.3023C16.9923 13.8113 16.3894 15.2562 15.3223 16.323C14.2552 17.3899 12.8102 17.9925 11.3012 17.9999C9.7923 18.0073 8.3414 17.4189 7.264 16.3625H2.63625ZM11.2728 8.3089C10.2213 8.3089 9.2129 8.7266 8.4694 9.4701C7.7258 10.2136 7.3081 11.222 7.3081 12.2735C7.3081 13.325 7.7258 14.3334 8.4694 15.0769C9.2129 15.8205 10.2213 16.2382 11.2728 16.2382C12.3243 16.2382 13.3327 15.8205 14.0762 15.0769C14.8197 14.3334 15.2374 13.325 15.2374 12.2735C15.2374 11.222 14.8197 10.2136 14.0762 9.4701C13.3327 8.7266 12.3243 8.3089 11.2728 8.3089Z" fill="#3D3D3D"/>
    </svg>
  )
}

LifeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LifeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LifeIcon)
