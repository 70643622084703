import {
  call, put, takeLatest, all,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  getGeoZonesDisplayElements,
  calculateDisplayElementsForTreeById,
  getTreeWithTelemetry,
} from '@/helpers/getTelementry'
import {
  successGetPinsAndZonesAndTelemetry,
  errorGetPinsAndZonesAndTelemetry,
} from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { generateGetTreeWithStatus } from '@/constants/apiRoutes'

import { GET_PINS_AND_ZONES_WITH_TELEMENTRY } from '@/store/actions/installation'

function* getTreeStructureSaga({ payload }) {
  const { ids, rootId, parentGeoZoneId } = payload
  try {
    const data = yield all(ids.map((elementId) => call(request, {
      url: generateGetTreeWithStatus(elementId),
      method: 'get',
    })))
    const formattedRequestData = data.map((element) => ({
      ...(element.data || {})[0] || {},
    }))
    const formattedData = formattedRequestData.reduce((accumulator, element) => {
      if (parentGeoZoneId && rootId === element.treeElementId && element.treeElementId !== parentGeoZoneId) {
        return [
          ...accumulator,
          ...calculateDisplayElementsForTreeById(
            element,
            [],
            parentGeoZoneId,
            false,
            element.treeElementId,
            null,
            element.treeElementId,
            `/${element.treeElementId}`,
          ),
        ]
      }
      return [
        ...accumulator,
        ...getGeoZonesDisplayElements(
          element,
          [],
          element.treeElementId,
          null,
          element.treeElementId,
          `/${element.treeElementId}`,
        ),
      ]
    }, [])
    const formattedTelemetry = formattedRequestData.reduce((accumulator, element) => ({
      ...accumulator,
      ...getTreeWithTelemetry(element),
    }), {})
    yield put(successGetPinsAndZonesAndTelemetry({
      displayElements: formattedData,
      telemetry: formattedTelemetry,
    }))
  } catch (error) {
    yield put(errorGetPinsAndZonesAndTelemetry())
    console.log('function*getTreeStructureSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GET_PINS_AND_ZONES_WITH_TELEMENTRY.REQUEST, getTreeStructureSaga)
}
