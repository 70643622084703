import {
  MAP_OBJECTS_NOTIFICATIONS,
  MAP_OBJECTS_ERRORS,
  GET_MAP_OBJECTS,
  SET_ZONES,
  UPDATE_MAP_DATA,
} from './index'

export const updateMapData = (payload) => ({
  type: UPDATE_MAP_DATA,
  payload,
})

export const requestGetMapObjects = () => ({
  type: GET_MAP_OBJECTS.REQUEST,
})

export const successGetMapObjects = (data) => ({
  type: GET_MAP_OBJECTS.SUCCESS,
  payload: data,
})

export const errorGetMapObjects = (data) => ({
  type: GET_MAP_OBJECTS.ERROR,
  payload: data,
})

export const eventsMapObjectsNotifications = (data) => ({
  type: MAP_OBJECTS_NOTIFICATIONS.EVENTS,
  payload: data,
})
export const eventsMapObjectsErrors = (data) => ({
  type: MAP_OBJECTS_ERRORS.EVENTS,
  payload: data,
})

export const setZones = (data) => ({
  type: SET_ZONES,
  payload: data,
})
