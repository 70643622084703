import {
  MIDDLE_RED,
  ATLANTIS_LIGHT,
} from '@/constants/styles/defaultTheme'

export const seriesForObjectControllStatistic = [
  {
    name: 'hier',
    title: 'В пределах нормы',
    fill: ATLANTIS_LIGHT,
    stroke: ATLANTIS_LIGHT,
    type: 'monotone',
  },
  {
    name: 'lower',
    title: 'Ниже нормы',
    fill: MIDDLE_RED,
    stroke: MIDDLE_RED,
    type: 'monotone',
  },
]

export const formatDataForGraph = (data) => data.map((element) => ({
  dateStep: element.dateStep,
  ...element.persents < 95 ? { lower: element.persents } : { hier: element.persents }
}))
