import styled from 'styled-components'
import Sidebar from '@/components/regions/sidebars/Sidebar'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const StyledSidebar = styled(Sidebar)`
  z-index: 4;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return null
    }
    return `
      width: 260px;
      min-width: 260px;
    `
  }}
`

export const Title = styled.div`
  flex: 1;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  ${({ deleteWidget, theme }) => deleteWidget && `
    text-transform: none;
    color: ${theme.colors.colors.logo};
    padding: 0px 10px;
    font-size: 1.2rem;
  `}
`

export const HeaderRow = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.light};
  min-width: 40px;
  min-height: 28px;
  width: 40px;
  height: 28px;
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.backgrounds.controls.green
    if (type === 'red') return theme.colors.backgrounds.controls.red
    return 'transparent'
  }};
  ${({ type, theme }) => type && `
    margin-right: 10px;
    border-radius: 4px;
    box-shadow: 5px 5px 10px ${theme.colors.shadows.widgetPrimary},
          -5px -5px 10px ${theme.colors.shadows.widgetSecondary};
  `}
`

export const TreeContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 96px);
  overflow: auto;
  ${({ isSuperAdmin }) => !isSuperAdmin && `
    overflow: visible;
  `}
  svg,
  .svgFill,
  .svgStroke {
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
`

export const TreeWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 96px);
  overflow: auto;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
`
