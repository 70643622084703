import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import get from 'lodash/get'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import { DEFAULT_CELL_WIDTH } from '@/constants/sizes'
import { REQUEST_STATUSES } from '@/constants/requests'
import UsersIcons from '@/components/icons/users'
import DropDown from '@/components/blocks/DropDown'
import TextControl from '@/components/controls/TextControl'
import CheckBox from '@/components/controls/CheckBox'
import Loader from '@/components/blocks/Loader'
import isEdgeBrowser from '@/helpers/isEdgeBrowser'
import SelectControl from '@/components/controls/SelectControl'
import DatePickerField from '@/components/fields/DatePickerField'
import {
  DATE_FIRST,
  DATE_SECOND,
} from '@/constants/forms/analyticExpenses'
import SortBullet from './components/SortBullet'
import Table from './components/Table'
import TableFooter from './components/TableFooter'

import {
  FormContainer,
  FieldContainer,
  StylesWrapper,
  DecorativeElement,
  Header,
  CheckBoxContainer,
  HeaderContentWrapper,
  FilterContainer,
  SecondaryTitle,
  SearchButton,
  TableContainer,
  FooterContainer,
  CreateReportContainer,
  IconContainer,
} from './styles'

const DutyTable = ({
  isNoData,
  fields,
  settingsOptions,
  exportOptions,

  isAlarms,
  handleReadAllAlarms,

  data,
  values,
  dates,
  actions,

  fileStatus,
  eventStatus,

  setStatusTypes,
  rowSelector,

  onUpdateData,
  onFileSelect,

  withFooter,
  withHeader,
  withDates,
  customHeader,
  headerHeight,
  rowInfoCard: RowInfoCard,
  isInfoCardOpen,
  card,
  loadingCard,
  selectedRow,
  getTableData,
  buttonValue,
  showCheckBox,
  createReport,
  serviceDesk,
  wrapContent,
  scrollAfterUpdating,
}) => {
  const tableRef = useRef(null)

  useEffect(() => {
    if (tableRef && tableRef.current && scrollAfterUpdating) {
      tableRef.current.scroll(0, 0)
    }
  }, [data, scrollAfterUpdating, tableRef])

  const [isStatusSettingsOpen, setIsStatusSettingsOpen] = useState(false)
  const closeAllDropDown = () => {
    setIsStatusSettingsOpen(false)
  }
  const isFileLoading = fileStatus === REQUEST_STATUSES.PENDING
  const selected = useMemo(() => ({
    id: (values.sort || {}).field,
    value: (values.sort || {}).direction,
  }), [values])

  const mappedExportOptions = useMemo(() => exportOptions.map(({
    icon: Icon = React.Fragment,
    name,
    value,
  }) => ({
    title: (
      <>
        <Icon />
        {' '}
        {name}
      </>
    ),
    value,
  })), [exportOptions])
  const mappedFields = useMemo(() => fields.map((field) => ({
    ...field,
    title: field.name,
    value: field.id,
  })), [fields])

  // const handleEventSelect = useCallback((value) => () => {
  //   if (eventTypes.includes(value)) {
  //     const newValues = eventTypes.filter((element) => element !== value)
  //     setEventTypes(newValues)
  //     return null
  //   }
  //   const newValues = [...eventTypes]
  //   newValues.push(value)
  //   setEventTypes(newValues)
  //
  //   return null
  // }, [eventTypes, setEventTypes])

  const handleSearchByTable = useCallback(() => {
    actions.setRequestQuery(values.query)
  }, [actions, values])

  const onSearchSubmit = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      handleSearchByTable()
    }
  }, [handleSearchByTable])

  const handleStatusSettingsSelect = useCallback((value) => () => {
    const isAllSelected = eventStatus.length === settingsOptions.length
    if (
      (value === 'all' && eventStatus.length === 0)
      || (value === 'new' && eventStatus.includes('old') && !isAllSelected)
      || (value === 'old' && eventStatus.includes('new') && !isAllSelected)
    ) {
      setStatusTypes(['all', 'new', 'old'])
      return null
    }
    if (value === 'all' && isAllSelected) {
      setStatusTypes([])
      return null
    }
    if ((value === 'new' || value === 'old') && eventStatus.length === 0) {
      setStatusTypes([value])
      return null
    }
    if (eventStatus.includes(value)) {
      const updatedStatuses = eventStatus
        .filter((status) => status !== value && status !== 'all')
      setStatusTypes(updatedStatuses)
      return null
    }
    return null
  }, [eventStatus, setStatusTypes, settingsOptions])

  const handleFileSelect = useCallback((value) => {
    onFileSelect(value)
  }, [onFileSelect])

  const columns = useMemo(
    () => fields.reduce((accumulator, element) => {
      if ((values.activeFields || []).includes(element.id)) {
        return [
          ...accumulator,
          {
            Header: (
              <SortBullet
                {...element}
                showSortButtons={element.showSortButtons && data.length > 0}
                selected={selected}
                onClick={actions.setSort}
                showAlarmButton={isAlarms}
                handleReadAllAlarms={handleReadAllAlarms}
                buttonValue={buttonValue}
                showCheckBox={showCheckBox}
              />
            ),
            accessor: element.id,
          },
        ]
      }
      return accumulator
    }, []),
    [
      fields,
      values.activeFields,
      data.length,
      selected,
      actions.setSort,
      isAlarms,
      handleReadAllAlarms,
      buttonValue,
      showCheckBox,
    ],
  )

  const onSubmit = useCallback((values) => {
    getTableData({
      start: values[DATE_FIRST],
      end: values[DATE_SECOND],
    })
  }, [getTableData])

  const toggleStatusSettings = () => {
    closeAllDropDown()
    setIsStatusSettingsOpen(!isStatusSettingsOpen)
  }

  const renderSettingsTypes = () => {
    if (!settingsOptions.length) {
      return null
    }
    return (
      <>
        {settingsOptions.map(({ name, id }) => (
          <CheckBoxContainer onClick={handleStatusSettingsSelect(id)}>
            <CheckBox
              value={eventStatus.includes(id)}
              childSelected={id === 'all'
                && !eventStatus.includes('all')
                && eventStatus.length > 0}
            />
            <SecondaryTitle>
              {name}
            </SecondaryTitle>
          </CheckBoxContainer>
        ))}
      </>
    )
  }

  const renderHeader = () => {
    const tableSettingsOption = renderSettingsTypes()
    return (
      <Header>
        <HeaderContentWrapper>
          <FilterContainer>
            <Lang id="mapsPage.titles.tableSearch">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  value={values.query}
                  onChange={actions.setQuery}
                  onKeyDown={onSearchSubmit}
                />
              )}
            </Lang>
            <SearchButton onClick={handleSearchByTable}>
              Найти
            </SearchButton>
          </FilterContainer>
          {createReport && (
            <CreateReportContainer onClick={createReport(true)}>
              <Lang id="reportManager.createReport" />
              <IconContainer>
                <UsersIcons.PlusIcon />
              </IconContainer>
            </CreateReportContainer>
          )}
          <FilterContainer settingsOptions>
            <SelectControl
              autoWidth
              largeOptions
              multiselect
              placeholder={<Lang id="alarmManager.messages.setFields" />}
              multiPlaceholder
              options={mappedFields}
              value={values.activeFields}
              onChange={actions.setSelectedFields}
            />
          </FilterContainer>
          {tableSettingsOption
            && (
              <FilterContainer>
                <DropDown
                  icon
                  content={tableSettingsOption}
                  title={(<Lang id="alarmManager.messages.setStats" />)}
                  isOpen={isStatusSettingsOpen}
                  onClick={toggleStatusSettings}
                />
              </FilterContainer>
            )}
          <FilterContainer>
            <SelectControl
              autoWidth
              largeOptions
              placeholder={(
                <>
                  <Lang id="alarmManager.messages.export" />
                </>
              )}
              options={mappedExportOptions}
              onChange={handleFileSelect}
              disabled={isNoData && data.length === 0}
            />
            {isFileLoading ? (<Loader fill />) : null}
          </FilterContainer>
          {withDates && (
            <FilterContainer>
              <Formik
                initialValues={{
                  [DATE_FIRST]: dates.start,
                  [DATE_SECOND]: dates.end,
                }}
                enableReinitialize
                onSubmit={onSubmit}
                render={({ values: formValues, handleSubmit, dirty }) => (
                  <FormContainer>
                    <FieldContainer>
                      <DatePickerField
                        name={DATE_FIRST}
                      />
                      <DecorativeElement />
                      <DatePickerField
                        name={DATE_SECOND}
                      />
                    </FieldContainer>
                    {(formValues[DATE_FIRST] || formValues[DATE_SECOND]) && dirty && (
                      <SearchButton onClick={handleSubmit}>
                        Построить
                      </SearchButton>
                    )}
                  </FormContainer>
                )}
              />
            </FilterContainer>
          )}
        </HeaderContentWrapper>
      </Header>
    )
  }

  const tableWidthByActiveFields = useMemo(() => get(values, 'activeFields', []).length * DEFAULT_CELL_WIDTH, [values])

  const renderTableFooter = useMemo(() => {
    if (withFooter && data && data.length > 0 && RowInfoCard) {
      return (
        <FooterContainer>
          <TableFooter
            page={values.page}
            perPage={values.perPage}
            resultCount={values.resultCount}
            setPage={actions.setPage}
            setResultPerPage={actions.setPerPage}
            onUpdateData={onUpdateData}
            notToShowFooterPerPage
          />
          <RowInfoCard
            isInfoCardOpen={isInfoCardOpen}
            setIsInfoCardOpen={rowSelector}
            card={card}
            loading={loadingCard}
            selectedRow={selectedRow}
          />
        </FooterContainer>
      )
    }
    if (withFooter && data && data.length > 0) {
      return (
        <TableFooter
          page={values.page}
          perPage={values.perPage}
          resultCount={values.resultCount}
          setPage={actions.setPage}
          setResultPerPage={actions.setPerPage}
          onUpdateData={onUpdateData}
        />
      )
    }
    return null
  }, [
    RowInfoCard,
    actions.setPage,
    actions.setPerPage,
    data, onUpdateData,
    values.page,
    values.perPage,
    values.resultCount,
    withFooter,
    isInfoCardOpen,
    rowSelector,
    card,
    loadingCard,
    selectedRow,
  ])

  return (
    <TableContainer>
      {customHeader || (withHeader && renderHeader())}
      <StylesWrapper
        headerHeight={headerHeight}
        isEdge={isEdgeBrowser()}
        serviceDesk={serviceDesk}
        ref={tableRef}
      >
        <Table
          columns={columns}
          data={data}
          perPage={values.perPage}
          fields={fields}
          rowSelector={rowSelector}
          withFooter={withFooter}
          minWidth={tableWidthByActiveFields}
          wrapContent={wrapContent}
        />
      </StylesWrapper>
      {renderTableFooter}
    </TableContainer>
  )
}

DutyTable.propTypes = {
  isNoData: pt.bool,
  fields: pt.arrayOf(pt.object),
  data: pt.arrayOf(pt.object),
  eventStatus: pt.arrayOf(pt.string),
  eventTypes: pt.arrayOf(pt.string),
  fileStatus: pt.string,
  onUpdateData: pt.func,
  onFileSelect: pt.func,
  rowSelector: pt.func,
  setStatusTypes: pt.func,
  setEventTypes: pt.func,
  exportOptions: pt.arrayOf(pt.shape({
    name: pt.string,
    icon: pt.element,
  }).isRequired),
  eventsOptions: pt.arrayOf(pt.object),
  settingsOptions: pt.arrayOf(pt.object),
  withFooter: pt.bool,
  withHeader: pt.bool,
  values: pt.shape({
    sort: pt.shape({
      field: pt.string,
      direction: pt.string,
    }),
    query: pt.string,
    requestQuery: pt.string,
    page: pt.number,
    perPage: pt.number,
    resultCount: pt.number,
    activeFields: pt.arrayOf(pt.string),
  }),
  actions: pt.shape({
    setSort: pt.func,
    setQuery: pt.func,
    setRequestQuery: pt.func,
    setSelectedFields: pt.func,
    setTotalResults: pt.func,
    setPerPage: pt.func,
    setPage: pt.func,
  }),
}

DutyTable.defaultProps = {
  isNoData: true,
  fields: [],
  data: [],
  eventStatus: [],
  eventTypes: [],
  fileStatus: REQUEST_STATUSES.NOT_REQUESTED,
  onUpdateData: noop,
  onFileSelect: noop,
  rowSelector: noop,
  setStatusTypes: noop,
  setEventTypes: noop,
  eventsOptions: [],
  settingsOptions: [],
  exportOptions: [],
  withFooter: true,
  withHeader: true,
  values: {},
  actions: {},
}

export default DutyTable
