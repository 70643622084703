import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const UserSettingsIcon = ({ onClick }) => (
  <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="26.34" height="26.323" viewBox="0 0 26.34 26.323">
    <defs>
      <linearGradient id="linear-gradient" x1="1" y1="-0.162" x2="0" y2="1.132" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#eea322" />
        <stop offset="1" stopColor="#ee2247" />
      </linearGradient>
    </defs>
    <path id="Path_1" data-name="Path 1" d="M198.073,65.284,194.621,68.7c-.024-.015-.046-.027-.067-.042q-1.157-.843-2.313-1.686a.144.144,0,0,0-.189-.012,7.562,7.562,0,0,1-1.691.8c-.145.043-.291.08-.439.113a.1.1,0,0,0-.094.1c-.157.95-.317,1.9-.479,2.862h-.1q-2.37,0-4.741,0c-.075,0-.105-.016-.118-.1-.152-.921-.309-1.842-.46-2.763a.144.144,0,0,0-.1-.123q-.952-.394-1.9-.791a2.058,2.058,0,0,1-.231-.112.134.134,0,0,0-.161.012c-.762.511-1.528,1.017-2.289,1.53-.084.057-.127.045-.193-.021q-1.647-1.643-3.3-3.281l-.067-.069c.027-.042.053-.082.079-.122.5-.741.992-1.482,1.492-2.22a.184.184,0,0,0,0-.234,7.162,7.162,0,0,1-.816-1.746c-.032-.117-.056-.237-.092-.353a.127.127,0,0,0-.078-.078c-.8-.136-1.6-.267-2.394-.4l-.5-.084v-.111c0-1.5,0-3.009,0-4.513,0-.1.031-.124.125-.134.644-.071,1.287-.15,1.931-.225.218-.025.437-.054.656-.076a.131.131,0,0,0,.126-.1c.155-.416.3-.834.476-1.243.128-.3.285-.592.435-.884a.123.123,0,0,0-.006-.148q-.773-1.146-1.541-2.294c-.018-.027-.035-.054-.038-.058l3.417-3.475.112.078q1.145.834,2.289,1.67a.138.138,0,0,0,.179.011A7.566,7.566,0,0,1,183.2,47.6c.151-.046.3-.084.458-.119a.116.116,0,0,0,.108-.111q.232-1.4.469-2.8c0-.016.006-.031.011-.052h4.77q.04.348.079.694c.077.684.162,1.366.223,2.051.016.173.091.214.235.254a8.78,8.78,0,0,1,2,.862l.1.06,2.471-1.8,3.411,3.47.037-.029c-.02.029-.039.059-.06.087q-.841,1.141-1.685,2.279a.153.153,0,0,0-.015.183c.256.541.511,1.081.757,1.626a3.392,3.392,0,0,1,.162.485.112.112,0,0,0,.1.094q1.033.169,2.063.341l.739.123c.026,0,.05.014.077.021v4.732c-.225.045-.45.092-.675.137-.571.114-1.141.228-1.712.34a.116.116,0,0,0-.1.1,8.537,8.537,0,0,1-.919,2.13.118.118,0,0,0,.009.154q.847,1.142,1.69,2.287Zm-11.684-12.6a8.889,8.889,0,0,0-.919.121,4.685,4.685,0,0,0-3.316,2.5,4.88,4.88,0,0,0-.463,3.174,4.651,4.651,0,0,0,2.344,3.366,4.959,4.959,0,0,0,3.663.526,4.658,4.658,0,0,0,3.257-2.509,4.918,4.918,0,0,0,.5-2.884,4.7,4.7,0,0,0-1.259-2.75A5.014,5.014,0,0,0,186.389,52.686Z" transform="translate(-173.378 -44.515)" fill="url(#linear-gradient)" />
  </svg>
)

UserSettingsIcon.propTypes = {
  onClick: pt.func,
}

UserSettingsIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(UserSettingsIcon)
