import { takeLatest } from 'redux-saga/effects'
import { REFRESH_WIDGET_MAP } from '@/store/actions/maps'
import request from '@/helpers/axios'
import { generateRefreshElementId } from '@/constants/apiRoutes'

function* getKulonMapEquipment({ payload }) {
  const { id } = payload
  try {
    yield request({
      url: generateRefreshElementId(id),
      method: 'post',
    })
  } catch (error) {
    console.log('TCL: function*getKulonMapEquipment -> error', error)
  }
}

export default function* root() {
  yield takeLatest(REFRESH_WIDGET_MAP.REQUEST, getKulonMapEquipment)
}
