import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import { MARKS_COLORS, MAPS_ICONS } from '@/constants/styles/mapsMarks'
import CoreIcon from '@/components/icons/core'
import {
  UNKNOWN,
} from '@/constants/objectStatuses'
import ToolTip from '../ToolTip'
import {
  PinContainer, OverLay, UnKnowContainer,
} from './styles'

const PinIconHover = ({
  status, name, type, id, theme, intl,
}) => {
  const PinTypeIcon = MAPS_ICONS[type] || null
  return (
    <>
      {(type === UNKNOWN || !PinTypeIcon)
        ? (
          <UnKnowContainer theme={theme}>
            <CoreIcon.ICIcon />
            <CoreIcon.UncknowIcon />
          </UnKnowContainer>
        )
        : (
          <PinContainer theme={theme}>
            <OverLay theme={theme}>
              <PinTypeIcon color={MARKS_COLORS[status] || theme.colors.colors.default} />
            </OverLay>
            <ToolTip name={name} id={id} theme={theme} type={type} height={35} intl={intl} />
          </PinContainer>
        )}
    </>
  )
}

PinIconHover.propTypes = {
  theme: mapTypes.theme.isRequired,
  status: mapTypes.markStatus.isRequired,
  type: mapTypes.markType.isRequired,
  name: mapTypes.markName.isRequired,
  id: mapTypes.markId.isRequired,
}

export default PinIconHover
