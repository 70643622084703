import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ThumbIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L22.3864 8.05911C23.2681 8.37653 23.2681 9.62347 22.3864 9.94089L0 18V0Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ThumbIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ThumbIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ThumbIcon)
