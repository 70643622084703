import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ArrowIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6892 8.6617C12.8649 8.853 12.8649 9.147 12.6892 9.3383L6.7841 15.7652C6.4964 16.0783 6.0359 16.0783 5.7483 15.7652L5.2218 15.1922C4.92628 14.8706 4.92602 14.3423 5.2213 14.0203L9.5141 9.3379C9.6894 9.1467 9.6894 8.8533 9.5141 8.6621L5.2213 3.97974C4.92602 3.65771 4.92628 3.12945 5.2218 2.80778L5.7483 2.23481C6.0359 1.92173 6.4964 1.92173 6.7841 2.23481L12.6892 8.6617Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ArrowIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ArrowIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ArrowIcon)
