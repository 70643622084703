import { all } from 'redux-saga/effects'

import groupRoot from './group'
import tenantRoot from './tenant'
import adminTenantRoot from './administrator'
import userRoot from './user'
import getCurrentUser from './getCurrentUser'
import getAllSortedUsers from './getAllSortedUsers'

export default function* root() {
  yield all([
    tenantRoot(),
    groupRoot(),
    adminTenantRoot(),
    userRoot(),
    getCurrentUser(),
    getAllSortedUsers(),
  ])
}
