import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_ANALYTICS_ALARMS_TABLE,
  GET_ANALYTICS_ALARMS_FILE,
  GET_ANALYTICS_ALARMS_ALARM,
  ANALYTICS_ALARMS_TABLE,

  SET_SELECTED_ELEMENT,
  SET_SIDE_BAR_FILTER,
} from '@/store/actions/analyticsAlarms'
import { SET_SELECTED_NODE } from '@/store/actions/appSettings'
import { fields } from '@/constants/tablesConfig/analyticsAlarms'

const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  selector: 'all',
  selectedElement: null,
  checkBoxOptions: {},
  loading: false,
  table: generateDefaultTableState(fields),
}

export const reducer = handleActions(
  {
    [SET_SELECTED_ELEMENT]: (state, { payload }) => ({
      ...state,
      selectedElement: payload,
    }),
    [SET_SELECTED_NODE]: (state) => ({
      ...state,
      searchQuery: null,
      sortType: 'object',
      sortValue: 'ASC',
      page: 1,
      ...generateTableParameter(state, 'page', 1),
      ...generateTableParameter(state, 'query', null),
      ...generateTableParameter(state, 'sort', { direction: 'ASC', field: fields[0].id }),
    }),
    [GET_ANALYTICS_ALARMS_ALARM.SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedElement: payload,
    }),
    [GET_ANALYTICS_ALARMS_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      loading: false,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_ANALYTICS_ALARMS_TABLE.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_ANALYTICS_ALARMS_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [SET_SIDE_BAR_FILTER]: (state, { payload }) => ({
      ...state,
      checkBoxOptions: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [GET_ANALYTICS_ALARMS_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_ANALYTICS_ALARMS_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_ANALYTICS_ALARMS_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
    ...generateDefaultTableReducer(ANALYTICS_ALARMS_TABLE),
  },
  initialState,
)

export default reducer
