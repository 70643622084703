import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ALARM_MANAGER_CHECK_ALL_ALARMS } from '@/constants/apiRoutes'
import { CHECK_ALL_ALARMS } from '@/store/actions/alarmManager'
import {
  successCheckAllAlarms,
  errorCheckAllAlarms,
} from '@/store/actions/alarmManager/checkAllAlarms'

function* checkAllAlarmsSaga({ payload }) {
  const { id } = payload
  try {
    const response = yield request({
      url: `${ALARM_MANAGER_CHECK_ALL_ALARMS}/${id || ''}`,
      method: 'get',
    })
    yield put(successCheckAllAlarms(response.data))
  } catch (error) {
    yield put(errorCheckAllAlarms(error))
  }
}

export default function* root() {
  yield takeLatest(CHECK_ALL_ALARMS.REQUEST, checkAllAlarmsSaga)
}
