export default {
  messages: {
    setEvens: 'Set events',
    setStats: 'Set up statuses',
    setFields: 'Set columns',
    export: 'Export',
  },
  alarmTypeTitle: {
    error: 'Faulty',
    warning: 'Warning',
    ok: 'OK',
    info: 'Not defined',
  },
  buttonsTitles: {
    buildTable: 'Build table',
    discardTable: 'Reset range',
  },
  forms: {
    dateRange: 'Date range',
    events: 'Events',
  },
}