import {
  takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  CREATE_REPORT,
  setFormIsModifying,
} from '@/store/actions/reportManager'
import { CREATE_REPORT_URL } from '@/constants/apiRoutes'
import {
  errorCreateReport,
} from '@/store/actions/reportManager/createReport'
import {
  requestReportManagerTable,
} from '@/store/actions/reportManager/getTable'
import createNotifications from '@/helpers/notification'
import {
  REPORT_TITLE,
} from '@/constants/forms/reportManager'

function* getReportManagerTypesSaga({ payload }) {
  const { formProps } = payload
  const toast = createNotifications()
  try {
    const dataJson = {
      externalRecipients: JSON.stringify(payload.externalRecipients),
      schedule: JSON.stringify(payload.schedule),
      ...payload,
    }
    const response = yield request({
      url: CREATE_REPORT_URL,
      method: 'post',
      body: dataJson,
    })
    if (response.data) {
      yield put(setFormIsModifying(false))
      yield put(requestReportManagerTable())
      toast({
        title: 'Успешная операция!',
        description: `Операция выполнена. Новый отчет для рассылки “${payload.title}” успешно создан`,
      })
    }
  } catch (error) {
    const { details } = error.response.data
    const recipientsErrors = details.reduce((previousValue, currentValue) => {
      const [inWhatError, whereError] = currentValue.subject.split(':')
      let otherError = {}
      if (inWhatError === 'report_title') {
        otherError = {
          [REPORT_TITLE]: 'Название отчета уже существует',
        }
        toast({
          title: 'Ошибка операции!',
          description: 'Отчет с таким названием уже существует в системе',
          type: 'error',
        })
      }
      const errorElement = Object.keys(formProps.values)
        .filter((key) => formProps.values[key] === whereError && inWhatError === 'recipient_email')
        .reduce((previousError, currentError) => ({
          ...previousError,
          [currentError]: 'некорректные данные',
        }), {})
      return {
        ...otherError,
        ...previousValue,
        ...errorElement,
      }
    }, {})
    formProps.setErrors({ ...recipientsErrors })
    if (details.length === 0) {
      toast({
        title: 'Ошибка операции!',
        type: 'error',
        description: 'Невозможно создать отчет!\nПовторите попытку позже.',
      })
    }
    yield put(errorCreateReport(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(CREATE_REPORT.REQUEST, getReportManagerTypesSaga)
}
