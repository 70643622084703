import {
  RATING_DYNAMIC,
  NATIONAL_RATING,
  PUBLIC_APPEALS,
  APPEALS_DYNAMIC,
  APPEALS_TIMING,
  INFRASTRUCTURE,
  CONNECTED_DEVICES,
  LIGHTS_STATE,
} from '@/constants/widgetsDashboardGov'

import DynamicRatingWidget from './DynamicRatingWidget'
import NationalRatingWidget from './NationalRatingWidget'

import PublicAppealsWidget from './PublicAppealsWidget'
import AppealsDynamicWidget from './AppealsDynamicWidget'
import AppealsTimingWidget from './AppealsTimingWidget'

import InfrastructureWidget from './InfrastructureWidget'
import ConnectedDevicesWidget from './ConnectedDevicesWidget'
import LightStateWidget from './LightStateWidget'

export const WIDGETS_COMPONENTS = {
  [RATING_DYNAMIC]: DynamicRatingWidget,
  [NATIONAL_RATING]: NationalRatingWidget,

  [PUBLIC_APPEALS]: PublicAppealsWidget,
  [APPEALS_DYNAMIC]: AppealsDynamicWidget,
  [APPEALS_TIMING]: AppealsTimingWidget,

  [INFRASTRUCTURE]: InfrastructureWidget,
  [CONNECTED_DEVICES]: ConnectedDevicesWidget,
  [LIGHTS_STATE]: LightStateWidget,
}

export default WIDGETS_COMPONENTS
