import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const ItemContainer = styled.div`
  ${({ color }) => color && `
    background: ${color};
  `}
  height: 100%;
  min-height: 460px;
  display: flex;
  box-shadow: ${({ theme }) => theme.colors.shadows.graph};;
  border-radius: 4px;
  padding: 0 20px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `background: ${theme.colors.backgrounds.redisign.default};`
    }
    return ''
  }}
`

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  max-width: 100%;
  height: calc(100% - 48px);
  display: grid;
  grid-template-columns: 60% 39%;
  grid-template-rows: repeat(1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  overflow-y: scroll;
  ${({ oneGraph }) => oneGraph && `
    grid-template-columns: 100%;
    grid-template-rows: repeat(1, 100%);
    height: 100%;
    ${ItemContainer} {
      width: 100%;
      height: calc(100% - 8px);
    }
  `}

  @media screen and (max-width: 1880px) {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    ${ItemContainer} {
      margin: 10px 0px;
      width: calc(100% - 20px);
      min-height: 460px;
    }
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  margin-left: 10px;
  ${({ graph }) => graph && `
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    justify-content: flex-start;
    margin-left: 32px;
  `}
`
