import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import AlarmsIcons from '@/components/icons/alarms'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import {
  Container,
  Title,
  AlarmEventContainer,
} from './styles'

const eventsOptions = {
  [ERROR]: {
    name: (<Lang id="alarmManager.alarmTypeTitle.error" />),
    icon: AlarmsIcons.ErrorIcon,
  },
  [WARNING]: {
    name: (<Lang id="alarmManager.alarmTypeTitle.warning" />),
    icon: AlarmsIcons.WarningIcon,
  },
  [OK]: {
    name: (<Lang id="alarmManager.alarmTypeTitle.fixed" />),
    icon: AlarmsIcons.OkIcon,
  },
}

const EventsIcons = ({
  type,
  description,
}) => {
  const {
    name = (<Lang id="alarmManager.alarmTypeTitle.unknown" />),
    icon: Icon,
  } = eventsOptions[type] || {}
  return (
    <Container>
      <AlarmEventContainer type={type}>
        {Icon && <Icon />}
      </AlarmEventContainer>
      <Title type={type}>
        {description && name }
      </Title>
    </Container>
  )
}

EventsIcons.propTypes = {
  type: pt.string.isRequired,
  description: pt.bool,
}

EventsIcons.defaultProps = {
  description: false,
}

export default EventsIcons
