import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MapServiceIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.41 1.89L14.41 0.0900002H13.59L10 1.7L6.41 0.0900002H6.36L6.24 0H5.64L1.64 1.8C1.44049 1.87645 1.2707 2.01479 1.15553 2.19474C1.04036 2.3747 0.985852 2.58681 0.999997 2.8V15C1.00028 15.1673 1.04253 15.3319 1.1229 15.4786C1.20327 15.6254 1.31917 15.7496 1.46 15.84C1.62095 15.944 1.80839 15.9995 2 16C2.14149 15.9993 2.28124 15.9687 2.41 15.91L6 14.3L9.59 15.91H9.64C9.7527 15.9627 9.87559 15.99 10 15.99C10.1244 15.99 10.2473 15.9627 10.36 15.91H10.41L14 14.3L17.59 15.91C17.7188 15.9687 17.8585 15.9993 18 16C18.1916 15.9995 18.379 15.944 18.54 15.84C18.6808 15.7496 18.7967 15.6254 18.8771 15.4786C18.9575 15.3319 18.9997 15.1673 19 15V2.8C18.9996 2.60764 18.9437 2.41948 18.8391 2.25807C18.7344 2.09667 18.5854 1.96887 18.41 1.89ZM7 2.55L9 3.44V13.44L7 12.55V2.55ZM17 13.45L15 12.56V2.56L17 3.45V13.45Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MapServiceIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MapServiceIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MapServiceIcon)
