import React from 'react'
import LiveTime from '@/components/icons/livetime'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import {
  OUTDOOR_LIGHTING,
  HEAT_SUPPLY,
} from '@/constants/names'

export const consumptionAnalysisFieldsElectricity = [
  {
    id: 'descr',
    name: 'Объект',
    value: 'ASC',
  },
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
  },
  {
    id: 'address',
    name: 'Адрес',
    value: 'ASC',
  },
  {
    id: 'currentPeriod',
    name: 'Текущий период',
    value: 'ASC',
  },
  {
    id: 'planedValue',
    name: 'Плановый расход за текущий период (кВт*ч)',
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'currentValue',
    name: 'Фактический расход за текущий период (кВт*ч)',
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'planedCurrentDifference',
    name: 'Отклонение от планового расхода (кВт*ч) ',
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'prevPeriod',
    name: 'Предыдущий период',
    value: 'ASC',
    customWidth: 248,
  },
  {
    id: 'prevValue',
    name: 'Фактический расход за предыдущий период (кВт*ч)',
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'difference',
    name: 'Разница факт. расхода с пред. периодом (кВт*ч)',
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
]

export const consumptionAnalysisFieldsHeat = [
  {
    id: 'object',
    name: 'Объект',
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'address',
    name: 'Адрес',
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'date',
    name: 'Дата',
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'planT1',
    name: (
      <>
        t
        <sub>1 (план)</sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'planT2',
    name: (
      <>
        t
        <sub>2 (план)</sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'factT1',
    name: (
      <>
        t
        <sub>1 (факт)</sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'factT2',
    name: (
      <>
        t
        <sub>2 (факт)</sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'planQ',
    name: (
      <>
        Q
        <sub>план</sub>
        , Гкал
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'factQ',
    name: (
      <>
        Q
        <sub>факт</sub>
        , Гкал
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'overheat',
    name: 'Недотоп, Гкал',
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'rate',
    name: 'Тариф, руб',
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'overpayment',
    name: 'Сэкономлено, руб',
    value: 'ASC',
    customWidth: 150,
  },
]


export const consumptionAnalysisExportOptions = [
  {
    name: 'EXCEL',
    value: 'excel',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]

export const consumptionAnalysisFieldsByType = {
  [OUTDOOR_LIGHTING]: consumptionAnalysisFieldsElectricity,
  [HEAT_SUPPLY]: consumptionAnalysisFieldsHeat,
}
