import { GET_TYUMEN_MESSAGES_WIDGET } from './index'

export const requestTyumenMessagesWidget = (payload) => ({
  type: GET_TYUMEN_MESSAGES_WIDGET.REQUEST,
  payload,
})

export const successTyumenMessagesWidget = (payload) => ({
  type: GET_TYUMEN_MESSAGES_WIDGET.SUCCESS,
  payload,
})

export const errorTyumenMessagesWidget = (payload) => ({
  type: GET_TYUMEN_MESSAGES_WIDGET.ERROR,
  payload,
})
