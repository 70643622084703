import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateManagementData } from '@/constants/apiRoutes'
import get from 'lodash/get'
import {
  UPDATE_USER_FROM_ADMIN,
  setFormIsModifying,
} from '@/store/actions/users'
import { errorUpdateUserFromAdmin } from '@/store/actions/users/user/updateUserFromAdmin'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import {
  responseGetAllUsers,
} from '@/store/actions/users/user/getAllUsers'
import { requestGetUser } from '@/store/actions/users/user/getUser'
import createNotifications from '@/helpers/notification'
import getFullName from '@/helpers/fullName'
import {
  getAllUsers,
} from '@/store/selectors/users'


function* updateUserFromAdminSaga({ payload }) {
  const {
    forms, tenantId, id, initialBlocked, groupId,
  } = payload
  const toast = createNotifications()
  try {
    const requestBody = {
      groupId: forms.state.values.groupId,
      blocked: forms.state.values.blocked,
    }
    const initialGroupId = forms.initialValues.groupId
    const response = yield request({
      url: generateManagementData(id),
      method: 'put',
      body: requestBody,
    })
    const fullName = getFullName(
      response.data.lastName,
      response.data.firstName,
      response.data.middleName,
    )
    const responseGroupId = get(response, 'data.group.id', null)
    if (responseGroupId !== initialGroupId) {
      toast({
        title: 'Успешная операция!',
        description: 'Пользователь успешно перемещен.',
      })
    }
    if (responseGroupId !== groupId) {
      const users = yield select(getAllUsers)
      const oldPlaceOfLocation = users[`${tenantId}-${groupId}`] || []
      const updatedOldPlaceOfLocation = oldPlaceOfLocation
        .filter((element) => element.id !== response.data.id)
      responseGetAllUsers({
        ...users,
        [`${tenantId}-${groupId}`]: updatedOldPlaceOfLocation,
      })
    }
    yield put(requestGetUser({ tenantId, id: response.data.id }))
    yield put(requestGetAllAdminTenants({ id: tenantId }))
    yield put(requestGetTenantGroups(tenantId))
    if (response.data.blocked && !initialBlocked) {
      toast({
        title: 'Успешная операция!',
        description: `Пользователь "${fullName}" успешно заблокирован`,
      })
    }
    if (!response.data.blocked && initialBlocked) {
      toast({
        title: 'Успешная операция!',
        description: `Пользователь "${fullName}" успешно разблокирован`,
      })
    }
    yield put(setFormIsModifying(false))
  } catch (error) {
    yield put(errorUpdateUserFromAdmin())
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка сохранения изменений! Повторите попытку позже. Код ошибки: ${error}`,
      type: 'error',
    })
    console.log('TCL: function*updateUserFromAdminSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(UPDATE_USER_FROM_ADMIN.REQUEST, updateUserFromAdminSaga)
}
