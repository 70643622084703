import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TrashCanIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path onClick={onClick} d="M4.7568 18.5069C5.03869 18.6835 5.37011 18.7778 5.70917 18.7778C6.16375 18.7778 6.59967 18.6084 6.92117 18.3069C7.24266 18.0055 7.4232 17.5966 7.4232 17.1702C7.4232 16.8523 7.32273 16.5414 7.13433 16.2771C6.94604 16.0127 6.67833 15.8067 6.36513 15.685C6.05182 15.5633 5.7072 15.5315 5.37469 15.5935C5.04218 15.6555 4.73684 15.8086 4.49706 16.0335C4.25738 16.2583 4.09407 16.5447 4.02797 16.8566C3.96186 17.1684 3.99578 17.4917 4.12549 17.7854C4.2552 18.0792 4.47491 18.3302 4.7568 18.5069Z"  fill={fill} className="svgFill"/>
      <path d="M6.99469 12.9905C6.99469 12.255 7.18582 11.4536 8.57509 10.5791L8.57629 10.5783C10.2857 9.50063 12.2227 8.27963 12.2227 5.56101C12.2227 3.70102 11.0828 2.16658 9.00786 1.24623C8.31458 0.939184 7.29207 0.766364 6.20367 0.778424C4.66615 0.796114 3.36764 1.1964 2.34695 1.96643C0.378318 3.45185 0.233477 5.21136 0.224052 5.40668C0.216448 5.55446 0.239968 5.70219 0.293248 5.84143C0.346528 5.98073 0.428532 6.10873 0.534579 6.21833C0.640615 6.32783 0.768634 6.41673 0.911293 6.47983C1.05396 6.54293 1.20849 6.57913 1.36606 6.58623C1.52363 6.59333 1.68115 6.57133 1.82963 6.52133C1.97811 6.47143 2.11464 6.39443 2.23143 6.29503C2.34823 6.19553 2.44298 6.07553 2.51029 5.94173C2.5776 5.80793 2.61622 5.66297 2.62375 5.51519C2.62375 5.50153 2.71975 4.57073 3.85276 3.71549C4.45353 3.26215 5.23342 3.03709 6.23793 3.02503C7.03844 3.01619 7.68895 3.14319 7.9812 3.2734C9.22298 3.82722 9.82298 4.57555 9.82298 5.56101C9.82298 7.01023 8.88873 7.66933 7.23633 8.70943C5.45891 9.82913 4.59502 11.2293 4.59502 12.9905C4.59502 13.2889 4.72135 13.5751 4.9464 13.7862C5.17146 13.9972 5.47658 14.1158 5.7948 14.1158C6.11302 14.1158 6.41826 13.9972 6.64331 13.7862C6.86826 13.5751 6.99469 13.2889 6.99469 12.9905Z"  fill={fill} className="svgFill"/>
    </svg>
  )
}

TrashCanIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrashCanIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrashCanIcon)
