import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const FileUploadIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  // fill="#2F3F4B"

  return (
    <svg onClick={onClick} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" d="M14 6.246C13.9909 6.16332 13.9733 6.08187 13.9475 6.003V5.922C13.9054 5.82946 13.8493 5.7444 13.7812 5.67L8.53125 0.27C8.45892 0.199995 8.37622 0.142273 8.28625 0.0989999H8.19875C8.11367 0.0522027 8.02223 0.0188298 7.9275 0H2.625C1.92881 0 1.26113 0.284464 0.768845 0.790812C0.276562 1.29716 0 1.98392 0 2.7V15.3C0 16.0161 0.276562 16.7028 0.768845 17.2092C1.26113 17.7155 1.92881 18 2.625 18H11.375C12.0712 18 12.7389 17.7155 13.2312 17.2092C13.7234 16.7028 14 16.0161 14 15.3V6.3V6.246ZM8.75 3.069L11.0163 5.4H9.625C9.39294 5.4 9.17038 5.30518 9.00628 5.1364C8.84219 4.96761 8.75 4.73869 8.75 4.5V3.069ZM12.25 15.3C12.25 15.5387 12.1578 15.7676 11.9937 15.9364C11.8296 16.1052 11.6071 16.2 11.375 16.2H2.625C2.39294 16.2 2.17038 16.1052 2.00628 15.9364C1.84219 15.7676 1.75 15.5387 1.75 15.3V2.7C1.75 2.46131 1.84219 2.23239 2.00628 2.0636C2.17038 1.89482 2.39294 1.8 2.625 1.8H7V4.5C7 5.21608 7.27656 5.90284 7.76884 6.40919C8.26113 6.91554 8.92881 7.2 9.625 7.2H12.25V15.3ZM8.12875 11.061L7.875 11.331V9C7.875 8.7613 7.78281 8.53239 7.61872 8.3636C7.45462 8.19482 7.23206 8.1 7 8.1C6.76794 8.1 6.54538 8.19482 6.38128 8.3636C6.21719 8.53239 6.125 8.7613 6.125 9V11.331L5.87125 11.061C5.70648 10.8915 5.48301 10.7963 5.25 10.7963C5.01699 10.7963 4.79352 10.8915 4.62875 11.061C4.46398 11.2305 4.37142 11.4603 4.37142 11.7C4.37142 11.9397 4.46398 12.1695 4.62875 12.339L6.37875 14.139C6.46197 14.2209 6.56009 14.2852 6.6675 14.328C6.77224 14.3756 6.88549 14.4002 7 14.4002C7.11451 14.4002 7.22776 14.3756 7.3325 14.328C7.43991 14.2852 7.53803 14.2209 7.62125 14.139L9.37125 12.339C9.53602 12.1695 9.62858 11.9397 9.62858 11.7C9.62858 11.4603 9.53602 11.2305 9.37125 11.061C9.20648 10.8915 8.98301 10.7963 8.75 10.7963C8.51699 10.7963 8.29352 10.8915 8.12875 11.061Z"/>
    </svg>
  )
}

FileUploadIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

FileUploadIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(FileUploadIcon)
