import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AlarmsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.52 7.43366L22.551 17.3546C23.4177 18.7795 22.992 20.6542 21.5996 21.5413C21.1324 21.8402 20.5876 21.9994 20.0309 22H7.96821C6.3293 22 5 20.64 5 18.9609C5 18.3929 5.1557 17.8373 5.4482 17.3546L11.48 7.43366C12.3458 6.00876 14.1764 5.57232 15.5687 6.45943C15.9539 6.70477 16.2797 7.0381 16.52 7.43366ZM14.522 8.17945C14.4118 8.10945 14.2884 8.06224 14.1593 8.04067C14.0301 8.0191 13.8978 8.02361 13.7705 8.05393C13.6431 8.08426 13.5233 8.13977 13.4183 8.2171C13.3133 8.29444 13.2252 8.392 13.1594 8.50389L7.12851 18.4266C7.03054 18.5883 6.9789 18.7733 6.97911 18.9618C6.97911 19.5218 7.42191 19.9751 7.96911 19.9751H20.03C20.2154 19.9751 20.3963 19.9218 20.5529 19.8222C20.7777 19.6747 20.9361 19.4473 20.9953 19.1874C21.0545 18.9274 21.0098 18.6549 20.8706 18.4266L14.8397 8.50389C14.7606 8.37325 14.6523 8.26225 14.5229 8.17945H14.522ZM14 18.4444C13.7613 18.4444 13.5324 18.3508 13.3636 18.1841C13.1948 18.0174 13.1 17.7913 13.1 17.5555C13.1 17.3198 13.1948 17.0937 13.3636 16.927C13.5324 16.7603 13.7613 16.6666 14 16.6666C14.2387 16.6666 14.4676 16.7603 14.6364 16.927C14.8052 17.0937 14.9 17.3198 14.9 17.5555C14.9 17.7913 14.8052 18.0174 14.6364 18.1841C14.4676 18.3508 14.2387 18.4444 14 18.4444ZM14 10.4444C14.2387 10.4444 14.4676 10.538 14.6364 10.7047C14.8052 10.8714 14.9 11.0975 14.9 11.3332V14.8888C14.9 15.1246 14.8052 15.3507 14.6364 15.5174C14.4676 15.6841 14.2387 15.7777 14 15.7777C13.7613 15.7777 13.5324 15.6841 13.3636 15.5174C13.1948 15.3507 13.1 15.1246 13.1 14.8888V11.3332C13.1 11.0975 13.1948 10.8714 13.3636 10.7047C13.5324 10.538 13.7613 10.4444 14 10.4444Z" fill={fill} className="svgFill" />
    </svg>

  )
}

AlarmsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AlarmsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AlarmsIcon)
