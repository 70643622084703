import { connect } from 'react-redux'
import { requestGetTyumenGraphs } from '@/store/actions/tyumenOurHome/getGraphs'
import { getGraphs, getDateGraph } from '@/store/selectors/tyumenOurHome'
import {
  requestGetTyumenDateGraph,
} from '@/store/actions/tyumenOurHome/getDateGraph'
import GraphsPage from './component'

const mapStateToProps = (state) => ({
  graphs: getGraphs(state),
  dateGraph: getDateGraph(state),
})
const mapDispatchToProps = (dispatch) => ({
  requestGetTyumenGraphs: (payload) => dispatch(requestGetTyumenGraphs(payload)),
  getDateGraph: (payload) => dispatch(requestGetTyumenDateGraph(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GraphsPage)
