import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SelectedCountIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 2H25M9 5H21M9 12H25M9 15H21M9 22H25M9 25H21M5 3C5 4.10457 4.10457 5 3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1C4.10457 1 5 1.89543 5 3ZM5 23C5 24.1046 4.10457 25 3 25C1.89543 25 1 24.1046 1 23C1 21.8954 1.89543 21 3 21C4.10457 21 5 21.8954 5 23ZM5 13C5 14.1046 4.10457 15 3 15C1.89543 15 1 14.1046 1 13C1 11.8954 1.89543 11 3 11C4.10457 11 5 11.8954 5 13Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" />
    </svg>
  )
}

SelectedCountIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SelectedCountIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SelectedCountIcon)
