import MULTIRATE_METER from './MULTIRATE_METER'
import LIGHT_FIXTURE from './LIGHT_FIXTURE'
import CONTROLLER from './CONTROLLER'
import SENSOR from './SENSOR'

export default {
  MULTIRATE_METER,
  LIGHT_FIXTURE,
  CONTROLLER,
  SENSOR,
}
