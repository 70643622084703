import { connect } from 'react-redux'
import { connectToSocket } from '@/store/actions/socket'
import { requestGetSettings } from '@/store/actions/user/settings/getSettings'
import {
  getUserAccessToken,
  getRefreshTokenStatus,
} from '@/store/selectors/user'
import AppContainer from './component'

const mapStateToProps = (state) => ({
  accessToken: getUserAccessToken(state),
  refreshTokenStatus: getRefreshTokenStatus(state),
})
const mapDispatchToProps = (dispatch) => ({
  connectToSocket: (data) => dispatch(connectToSocket(data)),
  requestGetSettings: () => dispatch(requestGetSettings()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainer)
