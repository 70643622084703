import { DELETE_GEOZONE } from './index'

export const requestDeleteGeoZone = (payload) => ({
  type: DELETE_GEOZONE.REQUEST,
  payload,
})

export const successDeleteGeoZone = (payload) => ({
  type: DELETE_GEOZONE.SUCCESS,
  payload,
})

export const errorDeleteGeoZone = (payload) => ({
  type: DELETE_GEOZONE.ERROR,
  payload,
})
