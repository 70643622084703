import { DELETE_PASSPORT_PHOTO } from './index'

export const requestDeletePassportPhoto = (payload) => ({
  type: DELETE_PASSPORT_PHOTO.REQUEST,
  payload,
})

export const responseDeletePassportPhoto = (payload) => ({
  type: DELETE_PASSPORT_PHOTO.SUCCESS,
  payload,
})

export const errorDeletePassportPhoto = (payload) => ({
  type: DELETE_PASSPORT_PHOTO.ERROR,
  payload,
})
