import { EDIT_OBJECT } from './index'

export const requestEditObject = (payload) => ({
  type: EDIT_OBJECT.REQUEST,
  payload,
})

export const successEditObject = (payload) => ({
  type: EDIT_OBJECT.SUCCESS,
  payload,
})

export const errorEditObject = (payload) => ({
  type: EDIT_OBJECT.ERROR,
  payload,
})
