import OkIcon from './OkIcon'
import ErrorIcon from './ErrorIcon'
import WarningIcon from './WarningIcon'
import UnknownIcon from './UnknownIcon'

export default {
  OkIcon,
  ErrorIcon,
  WarningIcon,
  UnknownIcon,
}
