import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'
import {
  ALL_TIME,
} from '@/constants/serviceDeskMyCity'

export const WidgetContainer = styled.div`
  ${({ disabled, theme }) => disabled === null && `
    opacity: 0.3;
    ${theme.themeName === LIGHT_THEME_NAME && 'pointer-events: none;'}
  `}

  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border: 1px solid ${theme.colors.borders.redisign.default};
        background: ${theme.colors.backgrounds.redisign.widgetLight};
        box-shadow: 5px 5px 10px ${theme.colors.shadows.widgetPrimary},
          -5px -5px 10px ${theme.colors.shadows.widgetSecondary};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header}
      box-shadow: -8px 12px 18px ${theme.colors.shadows.widget}
    `
  }}
  min-width: 595px;
  width: 595px;
  height: 275px;
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 1.4rem;
  border-radius: 10px;
  margin: 7.5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  > svg {
    height: 50px;
  }
  * {
    text-decoration: none;
  }

  a {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    padding: 5px 10px;
    svg,
    .svgFill {
    fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const Header = styled.div`
  position: absolute;
  ${({ withData }) => withData && `
    position: relative;
  `}
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px;
  padding-top: 0;
`

export const PrimaryTitle = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  white-space: pre-wrap;
`

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WidgetTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

export const WidgetHeaderIcon = styled.div`
  margin-bottom: auto;
  cursor: pointer;

  ${({ withMargin }) => withMargin && `
    margin: 5px;
    margin-bottom: auto;
  `}
`

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
`

export const ChartDescriptionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ChartDescriptionMarker = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 5px;
`

export const ChartDescription = styled.div`
  width: 50%;
  font-size: 1.2rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
`

export const ShadowElement = styled.div`
  cursor: pointer;
  svg,
  .svgFill {
   fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 275px;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 4px 4px 6px ${({ theme }) => theme.colors.shadows.widgetPrimary},
    -4px -4px 6px ${({ theme }) => theme.colors.shadows.widgetSecondary};
  background: ${({ theme }) => theme.colors.backgrounds.header};
`

export const InfoHeader = styled.div`
  display: flex;
  width: 100%;
  height: 25px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.green};
  color: ${({ theme }) => theme.colors.colors.light};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 10px;
  ${({ theme, type }) => {
    if (type === ALL_TIME) {
      return `
        background: ${theme.colors.backgrounds.redisign.blue};
      `
    }
    return `
      background: ${theme.colors.backgrounds.redisign.green}
    `
  }}
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
`

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  ${({ title }) => title && `
    width: 109px;
    margin-left: 10px;
    margin-right: 20px;
    text-align: left;
  `}
  ${({ numbers }) => numbers && `
    font-size: 1.8rem;
  `}
  ${({ headerTitle, theme }) => headerTitle && `
    margin: 3px 0 0 5px;
    text-align: left;
    line-height: 1.4rem;
    color: ${theme.colors.colors.dim};
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`
