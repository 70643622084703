export default {
  createGeoZoneForm: {
    name: 'Geozone name*',
    parentId: 'Parent geozone*',
    parentGeoZoneName: 'Parent geozone',
    parentProjectName: 'Parent project',
    lat: 'Geographic latitude*',
    lon: 'Geographic longitude*',
    'location[0]': 'Latitude',
    'location[1]': 'Geographic longitude',
    'geoLocationDto.latitude': 'Geographic latitude',
    'geoLocationDto.longitude': 'Geographic longitude',
    region: 'Region',
    city: 'City',
    mark: 'Note',
    note: 'Note',
    type: 'Type*',
    geoZoneInstallation: 'Geozone installation',
    geoZoneInfo: 'Geozone info:',
    projectInfo: 'Project info:',
    integrationInfo: 'Integration info:',
    infoStructure: 'Infrastructure:',
    save: 'Save',
    cancel: 'Cancel',
    projectId: 'Project*',
    integrationType: 'Integration Type*',
  },
  projectForm: {
    name: 'Project name*',
    parentId: 'Parent geozone*',
    parentGeoZoneName: 'Parent geozone',
    parentProjectName: 'Parent project',
    lat: 'Geographic latitude*',
    lon: 'Geographic longitude*',
    'location[0]': 'Latitude',
    'location[1]': 'Geographic longitude',
    'geoLocationDto.latitude': 'Geographic latitude',
    'geoLocationDto.longitude': 'Geographic longitude',
    region: 'Region',
    city: 'City',
    mark: 'Note',
    note: 'Note',
    type: 'Type*',
    geoZoneInstallation: 'Geozone installation',
    geoZoneInfo: 'Geozone info:',
    projectInfo: 'Project info:',
    integrationInfo: 'Integration info:',
    infoStructure: 'Infrastructure:',
    save: 'Save',
    cancel: 'Cancel',
    projectId: 'Project*',
    integrationType: 'Integration Type*',
  },
  integrationForm: {
    name: 'Integration name*',
    parentId: 'Parent geozone*',
    parentGeoZoneName: 'Parent geozone',
    parentProjectName: 'Parent project',
    lat: 'Geographic latitude*',
    lon: 'Geographic longitude*',
    'location[0]': 'Latitude',
    'location[1]': 'Geographic longitude',
    'geoLocationDto.latitude': 'Geographic latitude',
    'geoLocationDto.longitude': 'Geographic longitude',
    region: 'Region',
    city: 'City',
    mark: 'Note',
    note: 'Note',
    type: 'Type*',
    geoZoneInstallation: 'Geozone installation',
    geoZoneInfo: 'Geozone info:',
    projectInfo: 'Project info:',
    integrationInfo: 'Integration info:',
    infoStructure: 'Infrastructure:',
    save: 'Save',
    cancel: 'Cancel',
    projectId: 'Project*',
    integrationType: 'Integration Type*',
  },
}