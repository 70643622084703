import { CREATE_GEOZONE } from './index'

export const requestCreateGeoZone = (payload) => ({
  type: CREATE_GEOZONE.REQUEST,
  payload,
})

export const successCreateGeoZone = (payload) => ({
  type: CREATE_GEOZONE.SUCCESS,
  payload,
})

export const errorCreateGeoZone = (payload) => ({
  type: CREATE_GEOZONE.ERROR,
  payload,
})
