import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getWidgetDashboard = createSelector(
  getState,
  (state) => get(state, 'widgetDashboard', {}),
)

export const getObjectState = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'objectState', {}),
)

export const getServiceDeskWidget = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'serviceDeskWidget', {}),
)

export const getGraphData = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'graphData', {}),
)

export const getTyumenOurHomeData = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'tyumenOurHome', {}),
)

export const getDepreciationEquipment = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'depreciationEquipment', {}),
)

export const getAutomaticReporting = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'automaticReporting', {}),
)

export const getRequestState = createSelector(
  getWidgetDashboard,
  (state) => get(state, 'requestsState', {}),
)
