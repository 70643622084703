export default {
  widgetTitle: 'Widget settings',
  applicationTitle: 'Application settings',
  deleteWidget: 'Delete widget?',
  deleteApplication: 'Do you want to delete the application?',
  yes: 'Yes',
  no: 'No',
  urbanProcess: 'Select an urban process:',
  unpinMenu: 'Unpin menu',
  pinMenu: 'Pin Menu',
}