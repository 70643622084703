import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LightOffIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99991 4.09082C10.3019 4.09082 11.5505 4.60803 12.4712 5.52866C13.3918 6.44929 13.909 7.69794 13.909 8.99991C13.909 10.8163 12.919 12.4035 11.4545 13.2545V14.7272C11.4545 14.9442 11.3683 15.1523 11.2148 15.3057C11.0614 15.4592 10.8533 15.5454 10.6363 15.5454H7.36355C7.14655 15.5454 6.93844 15.4592 6.78501 15.3057C6.63157 15.1523 6.54537 14.9442 6.54537 14.7272V13.2545C5.08082 12.4035 4.09082 10.8163 4.09082 8.99991C4.09082 7.69794 4.60803 6.44929 5.52866 5.52866C6.44929 4.60803 7.69794 4.09082 8.99991 4.09082ZM10.6363 16.3635V17.1817C10.6363 17.3987 10.5501 17.6068 10.3966 17.7603C10.2432 17.9137 10.0351 17.9999 9.81809 17.9999H8.18173C7.96473 17.9999 7.75663 17.9137 7.60319 17.7603C7.44975 17.6068 7.36355 17.3987 7.36355 17.1817V16.3635H10.6363Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LightOffIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LightOffIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LightOffIcon)
