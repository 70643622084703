import React, {
 useMemo,
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import DefaultImage from '@/components/blocks/DefaultImage'
import PassportForm from '@/forms/PassportizationForm'
import CkeckBox from '@/components/controls/CheckBox'

import {
  CARD,
  PASSPORT,
} from '@/constants/passportization'

import {
  ContentWrapper,
  TabContainer,
  PassportImageContainer,
  PassportImage,
  TitleContainer,
  CkeckBoxContainer,
  OptionTitle,
  CkeckBoxsContainer,
} from './styles'

const PassportAndCard = ({
  operatedElement,
  cardData,
  cardScheme,
  passportData,
  passportScheme,
  selectedFields,
  setDirty,
  passportRef,
  setUploadedImage,
  uploadedImage,
  handleExportFile,
  handleDownloadFile,
  block,
  tabType,
  setTabType,
}) => {
  const setTabTypeHandler = (type) => () => { setTabType(type) }
  const initialValues = useMemo(() => ({
    ...cardData,
    ...passportData,
  }), [cardData, passportData])
  const formScheme = useMemo(() => (
    tabType === CARD ? cardScheme : passportScheme
  ), [passportScheme, cardScheme, tabType])
  const photoUrl = get(initialValues, 'inner.photo.value', '')
  return (
    <ContentWrapper>
      <TabContainer>
        <TitleContainer>
          <Lang id="passportization.passportSidebar.view" />
        </TitleContainer>
        <CkeckBoxsContainer>
          <CkeckBoxContainer onClick={setTabTypeHandler(PASSPORT)}>
            <CkeckBox value={tabType === PASSPORT} />
            <OptionTitle>
              <Lang id="passportization.passportSidebar.passport" />
            </OptionTitle>
          </CkeckBoxContainer>
          <CkeckBoxContainer onClick={setTabTypeHandler(CARD)}>
            <CkeckBox value={tabType === CARD} />
            <OptionTitle>
              <Lang id="passportization.passportSidebar.card" />
            </OptionTitle>
          </CkeckBoxContainer>
        </CkeckBoxsContainer>
      </TabContainer>
      <PassportImageContainer>
        {operatedElement.url || uploadedImage || photoUrl
          ? (<PassportImage url={operatedElement.url || photoUrl} base64Url={uploadedImage} />)
          : (<DefaultImage title={(<Lang id="passportization.passportSidebar.noImage" />)} />)}
      </PassportImageContainer>
      <PassportForm
        initialValues={initialValues}
        options={formScheme}
        disabledFields={[]}
        setSelectedPassportFields={noop}
        setImage={setUploadedImage}
        selectedFields={selectedFields}
        setDirty={setDirty}
        ref={passportRef}
        handleExportFile={handleExportFile}
        handleDownloadFile={handleDownloadFile}
        block={block}
      />
    </ContentWrapper>
  )
}

PassportAndCard.propTypes = {
  operatedElement: pt.objectOf(pt.object),
  elementType: pt.string,
  cardData: pt.objectOf(pt.object),
  cardScheme: pt.arrayOf(pt.object),
  passportData: pt.objectOf(pt.object),
  passportScheme: pt.arrayOf(pt.object),
  selectedFields: pt.arrayOf(pt.string),
  exportPassport: pt.func,
  exportCart: pt.func,
  block: pt.bool,
}

PassportAndCard.defaultProps = {
  operatedElement: {},
  elementType: pt.string,
  cardData: {},
  cardScheme: [],
  passportData: {},
  passportScheme: [],
  selectedFields: [],
  exportPassport: noop,
  exportCart: noop,
  block: false,
}
export default PassportAndCard
