import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcons from '@/components/icons/menu'

export default {
  main: [
    {
      name: 'Наружное освещение',
      selector: 'outdoorLightningCount',
    },
    {
      name: 'Ремонт дорог',
      selector: 'roadRepairingCount',
      disabled: true,
    },
    {
      name: 'Остановки общ. транспорта',
      selector: 'transportStationCount',
      disabled: true,
    },
    {
      name: 'Прорыв теплотрасс',
      selector: 'notAvailable1',
      disabled: true,
    },
    {
      name: 'Подтопления',
      selector: 'notAvailable2',
      disabled: true,
    },
  ],
  view: {
    title: <Lang id="widgetsDashboard.serviceDesk" />,
    icon: MenuIcons.SupportIcon,
  },
  total: {
    name: 'Всего обращений',
    selector: 'totalCalls',
  },
  solved: {
    name: 'Принято решений',
    selector: 'decisionWasMade',
  },
  graph: {
    totalSelector: 'totalCalls',
    solvedSelector: 'decisionWasMade',
  },
}
