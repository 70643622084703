import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react'
import get from 'lodash/get'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import pt from 'prop-types'
import { Formik, Form } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import Loader from '@/components/blocks/Loader'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import Icons from '@/components/icons/sync'
import TextField from '@/components/fields/TextField'
import Button from '@/components/blocks/Button'
import SelectField from '@/components/fields/SelectField'
import { REQUEST_STATUSES } from '@/constants/requests'
import createNotifications from '@/helpers/notification'
import {
  integrationSchemaCreate,
  integrationSchemaUpdate,
} from '@/constants/validationFields'
import {
  NAME,
  PERIOD,
  UNIT,
  INTEGRATION_ID,
} from '@/constants/forms/integration'
import {
  TEXT,
  SELECT,
} from '@/constants/semanticNames'
import getFieldsConfigByASU from '@/constants/integrationsConfigs'

import {
  InputAndLabelContainer,
  DoubleInputContainer,
  ButtonContainer,
  MessageTitle,
  LoaderContainer,
} from './styles'

const CreateIntegrationForm = ({
  data,
  selectedSystemId,
  onSubmitForm,
  onDelete,
  onRepeat,
  onNameChange,
  type,
  editing,
  hasGlobalError,
  errorMessage,
  clearError,
  saveFromProps,
  integrationError,
  setIntegration,
  setSelectSystem,
  isSubmitting,
  get1simIntegrations,
  getKulonIntegrations,
  synchronizationErrors,
  integration,
  requestGetAllIntegrations,
  integrationRequestStatus,
}) => {
  useEffect(() => {
    if (selectedSystemId) {
      requestGetAllIntegrations({ type: selectedSystemId, id: data[INTEGRATION_ID] || false })
    }
  }, [selectedSystemId, data, requestGetAllIntegrations])
  const toast = useCallback(createNotifications)
  const getFieldsConfig = getFieldsConfigByASU(selectedSystemId)

  const form = React.createRef()
  const [isDirty, setIsDirty] = useState(false)
  const [isDeletingIntegration, setIsDeletingIntegration] = useState(false)

  useEffect(() => {
    if (form && form.current && form.current.setSubmitting && isSubmitting) {
      form.current.setSubmitting(true)
    }
  }, [form, isSubmitting, integration])

  const initialValues = useMemo(() => {
    const fieldsConfig = getFieldsConfig({})

    return fieldsConfig.reduce((accumulator, { name, selector }) => ({
      ...accumulator,
      [name]: get(data, `${selector ? `${selector}.` : ''}${name}`, ''),
    }), {
      [PERIOD]: data[PERIOD],
      [UNIT]: data[UNIT] || 'час',
    })
  }, [data, getFieldsConfig])

  const validationSchema = useMemo(() => {
    const fieldsNames = getFieldsConfig({}).map(({ name }) => name)
    return (!editing
      ? integrationSchemaCreate(fieldsNames)
      : integrationSchemaUpdate(fieldsNames))
  }, [editing, getFieldsConfig])

  const confirmationOfDeletion = useCallback(() => {
    if (isDeletingIntegration) {
      setIsDeletingIntegration(false)
    } else if (editing) {
      setIsDeletingIntegration(true)
    }
  }, [isDeletingIntegration, setIsDeletingIntegration, editing])

  useEffect(() => () => {
    get1simIntegrations()
    getKulonIntegrations()
    clearError(null)
    setIntegration({})
    setSelectSystem(null)
  },
  [
    clearError,
    get1simIntegrations,
    getKulonIntegrations,
    setIntegration,
    setSelectSystem,
  ])

  const resetForm = useCallback(() => {
    toast({
      title: 'операция прервана!',
      description: 'Изменение параметров не было выполнено!',
      type: 'error',
    })
    form.current.resetForm()
    setIsDirty(false)
  }, [form, setIsDirty, toast])

  const onSubmit = useCallback((values, formData) => {
    const valuesChanged = isEqual(values, initialValues)
    if (!values[PERIOD]) {
      formData.setValues({ ...values, [PERIOD]: '12' })
    }
    if (!valuesChanged) {
      if (editing) {
        onSubmitForm({
          values: {
            ...values,
            id: data.id,
          },
          form: formData,
        })
      } else {
        onSubmitForm({ values, form: formData })
      }
    }
    setIsDeletingIntegration(false)
  }, [editing, onSubmitForm, data.id, initialValues])

  const handleDelete = useCallback(() => {
    onDelete({
      id: data.id,
      form: {
        setSubmitting: get(form, 'current.setSubmitting', noop),
        setErrors: get(form, 'current.setErrors', noop),
      },
    })
  }, [onDelete, data, form])

  const handleRepeat = useCallback(() => {
    onRepeat({
      id: data.id,
      form: {
        setSubmitting: get(form, 'current.setSubmitting', noop),
        setErrors: get(form, 'current.setErrors', noop),
      },
    })
  }, [onRepeat, data.id, form])

  const renderNotDisabledButton = (isValid) => (
    <ButtonContainer>
      <Button
        type={editing ? 'button' : 'submit'}
        styleType={`${type === 'connect' ? 'primary' : 'danger'}`}
        disabled={!editing && !isValid}
        onClick={confirmationOfDeletion}
      >
        {type === 'connect'
          ? (
            <>
              <Icons.ConnectIcon />
              <Lang id="syncModule.buttons.connect" />
            </>
          )
          : (
            <>
              <Icons.TrashBascketIcon />
              <Lang id="syncModule.buttons.delete" />
            </>
          )}
      </Button>
    </ButtonContainer>
  )

  const renderButton = (isSubmitting, isValid, values) => {
    if (isSubmitting) {
      return (
        <ButtonContainer>
          <Button
            type="submit"
            styleType="submitting"
          >
            <Icons.WaitIcon />
            <Lang id="syncModule.buttons.wait" />
          </Button>
        </ButtonContainer>
      )
    }
    if (editing && isDirty && !isEqual(values, initialValues)) {
      return (
        <>
          <MessageTitle type="info">
            <Lang id="syncModule.formMessages.editing" />
          </MessageTitle>
          <ButtonContainer twoButton>
            <Button
              type="submit"
              styleType="flat"
            >
              <Lang id="syncModule.buttons.confirm" />
            </Button>
            <Button
              type="button"
              styleType="danger"
              onClick={resetForm}
            >
              <Lang id="syncModule.buttons.reject" />
            </Button>
          </ButtonContainer>
        </>
      )
    }

    if (isDeletingIntegration) {
      return (
        <>
          <MessageTitle type="info">
            <Lang id="syncModule.formMessages.deleting" />
          </MessageTitle>
          <ButtonContainer twoButton>
            <Button
              styleType="flat"
              onClick={handleDelete}
            >
              <Lang id="syncModule.buttons.confirm" />
            </Button>
            <Button
              type="button"
              styleType="danger"
              onClick={confirmationOfDeletion}
            >
              <Lang id="syncModule.buttons.reject" />
            </Button>
          </ButtonContainer>
        </>
      )
    }

    return renderNotDisabledButton(isValid)
  }

  const renderButtonError = (isSubmitting, values) => {
    if (data.id && integrationError && !isSubmitting && isEqual(values, initialValues)) {
      return (
        <ButtonContainer withErrorMessage>
          <Button
            type="button"
            styleType="primary"
            onClick={handleRepeat}
          >
            <Lang id="syncModule.buttons.repeat" />
          </Button>
        </ButtonContainer>
      )
    }
    return null
  }

  const renderMessage = useCallback((isSubmitting) => {
    if (synchronizationErrors) {
      return (
        <MessageTitle>
          {synchronizationErrors}
        </MessageTitle>
      )
    }

    if (hasGlobalError || errorMessage) {
      return (
        <MessageTitle>
          {errorMessage || <Lang id="syncModule.formMessages.error" />}
        </MessageTitle>
      )
    }

    if (isSubmitting && isDirty) {
      return (
        <MessageTitle type="pending">
          <Lang id="syncModule.formMessages.editingInfo" />
        </MessageTitle>
      )
    }

    if (isSubmitting && isDeletingIntegration) {
      return (
        <MessageTitle type="pending">
          <Lang id="syncModule.formMessages.deletingInfo" />
        </MessageTitle>
      )
    }

    if (isSubmitting && !editing) {
      return (
        <MessageTitle type="pending">
          <Lang id="syncModule.formMessages.connection" />
        </MessageTitle>
      )
    }

    if (!editing) {
      return (
        <MessageTitle type="info">
          <Lang id="syncModule.formMessages.info" />
        </MessageTitle>
      )
    }
    return null
  }, [editing,
    errorMessage,
    hasGlobalError,
    isDeletingIntegration,
    isDirty,
    synchronizationErrors])

  const onIntegrationChange = useCallback((values, setFieldValue) => (fieldName, value) => {
    const selectedIntegration = integration.filter((element) => element.value === value)[0] || {}
    if (selectedIntegration.title) {
      setFieldValue(NAME, selectedIntegration.title || null)
    }
  }, [integration])

  const renderField = useCallback((
    values,
    setFieldValue,
  ) => ({
    name,
    form: { touched, errors, isSubmitting },
    label,
    field,
    type,
    options,
  }) => (
    <InputAndLabelContainer>
      {type === TEXT && (
      <>
        <LabelWithIcon
          isError={hasGlobalError || (touched[name] && errors[name])}
          {...label}
        />
        <TextField
          error={(touched[name] && errors[name])}
          isError={hasGlobalError}
          name={name}
          fieldProps={{
            autoComplete: 'off',
            disabled: isSubmitting,
            ...field,
          }}
        />
      </>
      )}
      {type === SELECT && (
      <>
        <LabelWithIcon
          isError={hasGlobalError || (touched[name] && errors[name])}
          {...label}
        />
        <SelectField
          error={options.length === 0 && REQUEST_STATUSES.IDLE
            ? 'Нет интеграций'
            : errors[name]}
          name={name}
          withSearch
          placeholder="Выбрать"
          options={options}
          onAfterChange={onIntegrationChange(values, setFieldValue)}
          disabled={options.length === 0 && REQUEST_STATUSES.IDLE}
          fieldProps={{
            autoComplete: 'off',
            ...field,
          }}
        />
      </>
      )}
    </InputAndLabelContainer>
  ), [hasGlobalError, onIntegrationChange])

  return (
    <Formik
      initialValues={initialValues}
      ref={form}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={(value) => {
        onNameChange(value[NAME])
      }}
      render={(formProps) => {
        const fieldsConfig = getFieldsConfig({ ...formProps, editing, [INTEGRATION_ID]: integration })
        const {
          touched, errors, handleSubmit, isValid, isSubmitting, dirty, values, setFieldValue,
        } = formProps
        saveFromProps(formProps)
        return (
          <Form onSubmit={handleSubmit} id="integration-form" name="integration-form">
            {integrationRequestStatus === REQUEST_STATUSES.PENDING
              ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )
              : (
                <>
                  {dirty && setIsDirty(true)}
                  {fieldsConfig.map(renderField(values, setFieldValue))}
                  <InputAndLabelContainer>
                    <LabelWithIcon
                      isError={touched[PERIOD] && errors[PERIOD]}
                      icon={Icons.SyncIcon}
                      title={<Lang id="syncModule.form.refresh" />}
                      fieldProps={{
                        disabled: isSubmitting,
                        autoComplete: 'off',
                      }}
                    />
                    <DoubleInputContainer>
                      <TextField
                        error={touched[PERIOD] && errors[PERIOD]}
                        name={PERIOD}
                        fieldProps={{
                          format: 'period',
                          disabled: isSubmitting,
                          autoComplete: 'off',
                        }}
                      />
                      <TextField
                        error={touched[UNIT] && errors[UNIT]}
                        name={UNIT}
                        fieldProps={{
                          disabled: true,
                          autoComplete: 'off',
                        }}
                      />
                    </DoubleInputContainer>
                  </InputAndLabelContainer>
                  {renderButtonError(isSubmitting, values)}
                  {renderButton(isSubmitting, isValid, values)}
                  {renderMessage(isSubmitting)}
                </>
              )}
          </Form>
        )
      }}
    />
  )
}

CreateIntegrationForm.defaultProps = {
  onSubmitForm: noop,
  onDelete: noop,
  onRepeat: noop,
  onNameChange: noop,
  data: {
    [PERIOD]: 12,
    [UNIT]: 'час',
  },
  integration: [],
  editing: false,
  type: 'connect',
  hasGlobalError: false,
  integrationError: false,
  errorMessage: '',
  synchronizationErrors: '',
  clearError: noop,
  saveFromProps: noop,
  get1simIntegrations: noop,
  getKulonIntegrations: noop,
  setIntegration: noop,
  setSelectSystem: noop,
  isSubmitting: false,
  requestGetAllIntegrations: noop,
  integrationRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
}

CreateIntegrationForm.propTypes = {
  onSubmitForm: pt.func,
  onDelete: pt.func,
  onRepeat: pt.func,
  onNameChange: pt.func,
  data: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    [PERIOD]: pt.number,
    [UNIT]: pt.string,
  }),
  integration: pt.shape({
    value: pt.number,
    Title: pt.string,
  }),
  selectedSystemId: pt.string.isRequired,
  editing: pt.bool,
  integrationError: pt.bool,
  type: pt.string,
  synchronizationErrors: pt.string,
  hasGlobalError: pt.bool,
  isSubmitting: pt.bool,
  errorMessage: pt.string,
  clearError: pt.func,
  get1simIntegrations: pt.func,
  getKulonIntegrations: pt.func,
  setIntegration: pt.func,
  setSelectSystem: pt.func,
  saveFromProps: pt.func,
  requestGetAllIntegrations: pt.func,
  integrationRequestStatus: pt.string,
}

export default React.memo(CreateIntegrationForm)
