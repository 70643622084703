import styled from 'styled-components'

export const ObjectIsCheckedCell = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 100%;
  width: 100%;
`

export const CheckedMarker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  ${({ theme, isChecked }) => isChecked && `
    background: ${theme.colors.scheme.backgrounds.defaultGradient};
    margin-right: 10px;
  `}
`
