import {
  FUEL_YELLOW,
  REGENT_GRAY,
  MALACHITE,
  RED,
} from '@/constants/styles/defaultTheme'

export const OK = 'normal'
export const WARNING = 'warning'
export const ERROR = 'error'
export const UNKNOWN = 'undefined'

export const STATUS_COLORS = {
  [OK]: MALACHITE,
  [WARNING]: FUEL_YELLOW,
  [ERROR]: RED,
  [UNKNOWN]: REGENT_GRAY,
}
