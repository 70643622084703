import {
  TEXT
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.LATITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.LONGITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.REGION',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.CITY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.STREET',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.HOME_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'objectCharacteristics.connectedLampNumber',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectCharacteristics',
  },
  {
    selector: 'objectService.inspectionDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.bypassDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.maintenanceDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.repairDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.capitalRepairDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.guaranteeExpirationDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
]
