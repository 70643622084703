import { connect } from 'react-redux'
import { setLanguage } from '@/store/actions/appSettings'
import { beginSetPassword } from '@/store/actions/user/setPassword'
import { beginRestoreAccount } from '@/store/actions/user/restoration'
import { loginUser, loginError } from '@/store/actions/user/authorization'
import { requestUserForgotPassword } from '@/store/actions/user/forgotPassword'

import { getLanguageState } from '@/store/selectors/appSettings'
import {
  getUserSetPasswordError,
  getUserRestorationError,
  getUserAuthorizationError,
  getUserMockLoginError,
  getForgotPasswordStatus,
  getUserAuthorizationLoading,
} from '@/store/selectors/user'

import SignIn from './component'

const mapStateToProps = (state) => ({
  language: getLanguageState(state),
  mockLoginError: getUserMockLoginError(state),
  loginError: getUserAuthorizationError(state),
  resetError: getUserRestorationError(state),
  setPasswordError: getUserSetPasswordError(state),
  forgotPasswordStatus: getForgotPasswordStatus(state),
  loading: getUserAuthorizationLoading(state),
})
const mapDispatchToProps = (dispatch) => ({
  onSetLanguage: (language) => dispatch(setLanguage(language)),
  onLoginUser: (...data) => dispatch(loginUser(data)),
  onSetUsersPassword: (...data) => dispatch(beginSetPassword(data)),
  onRestoreUser: (...data) => dispatch(beginRestoreAccount(data)),
  resetLoginError: (payload) => dispatch(loginError(payload)),
  requestUserForgotPassword: (payload) => dispatch(requestUserForgotPassword(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
