import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DeleteIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5 3V2C5 0.895 5.895 0 7 0H9C10.105 0 11 0.895 11 2V3H15C15.552 3 16 3.448 16 4C16 4.552 15.552 5 15 5V15C15 16.657 13.657 18 12 18H4C2.343 18 1 16.657 1 15V5C0.448 5 0 4.552 0 4C0 3.448 0.448 3 1 3H5ZM7 3H9V2H7V3ZM3 15V5H13V15C13 15.552 12.552 16 12 16H4C3.448 16 3 15.552 3 15Z" className="svgFill" fill={fill} />
    </svg>
  )
}

DeleteIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}

DeleteIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(DeleteIcon)
