import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ErrorIcon = ({ onClick }) => (
  <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.32289 5.88192C3.89237 5.4514 3.89237 4.75341 4.32289 4.32289C4.75341 3.89237 5.4514 3.89237 5.88192 4.32289L8.99999 7.44092L12.1181 4.32289C12.5486 3.89237 13.2466 3.89237 13.6771 4.32289C14.1076 4.75341 14.1076 5.4514 13.6771 5.88192L10.5591 8.99999L13.6771 12.1181C14.1076 12.5486 14.1076 13.2466 13.6771 13.6771C13.2466 14.1076 12.5486 14.1076 12.1181 13.6771L8.99999 10.5591L5.88192 13.6771C5.4514 14.1076 4.7534 14.1076 4.32289 13.6771C3.89237 13.2466 3.89237 12.5486 4.32289 12.1181L7.44092 8.99999L4.32289 5.88192Z" fill="#F83D14" />
  </svg>
)

ErrorIcon.propTypes = {
  onClick: pt.func,
}

ErrorIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(ErrorIcon)
