import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ADD_PASSPORT_FILE_URL } from '@/constants/apiRoutes'
import { ADD_PASSPORT_FILE, requestGetPassportAndCardData } from '@/store/actions/passportization'
import {
  responseAddPassportFile,
  errorAddPassportFile,
} from '@/store/actions/passportization/addPassportFile'
import createNotifications from '@/helpers/notification'

function* addPassportFileSaga({ payload }) {
  const {
    form,
    id,
    type,
    passportId,
  } = payload
  const toast = createNotifications()
  try {
    const formData = new window.FormData()
    formData.append('passport', form.state.values.inner.file)
    formData.append('title', form.state.values.inner.file.name)
    formData.append('installationId', id)
    formData.append('installationType', type)
    formData.append('passportId', passportId)
    const response = yield request({
      url: ADD_PASSPORT_FILE_URL,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    yield put(responseAddPassportFile({ fileId: response.data }))
    yield put(requestGetPassportAndCardData({ id, type }))
  } catch (error) {
    yield put(errorAddPassportFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка операции! Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(ADD_PASSPORT_FILE.REQUEST, addPassportFileSaga)
}
