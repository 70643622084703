import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 0px;
  top: 0px;
  &::before {
    content: '';
    position: absolute;
    border-left: 1px dashed ${({ theme }) => theme.colors.colors.default};
    left: 13px;
    top: 20px;
  }
`

export default Wrapper
