import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getObjectControllStatistic = createSelector(
  getState,
  (state) => get(state, 'objectControllStatistic', {}),
)

export const getTableParameters = createSelector(
  getObjectControllStatistic,
  (alarmsState) => get(alarmsState, 'table', {}),
)

export const getDataRequestStatus = createSelector(
  getObjectControllStatistic,
  (alarmsState) => get(alarmsState, 'dataRequestStatus', {}),
)
