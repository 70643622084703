import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SortIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.21065 1.01488C6.61101 0.500134 7.38899 0.500136 7.78935 1.01488L12.7447 7.38606C13.2556 8.04292 12.7875 9 11.9554 9H2.04464C1.21249 9 0.744398 8.04292 1.25529 7.38606L6.21065 1.01488Z" stroke={stroke} className="svgStroke" />
    </svg>
  )
}

SortIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SortIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SortIcon)
