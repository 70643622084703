import {
  MESH,
  BRIZ,
  ATM,
  KULON,
} from '@/constants/integrations'
import briz from './briz'
import kulon from './kulon'
import atm from './atm'
import mesh from './mesh'

export default {
  [ATM]: atm,
  [KULON]: kulon,
  [BRIZ]: briz,
  [MESH]: mesh,
}
