import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: -0.003em;
  width: 80px;
  text-align: left;
  ${({ iconTitle }) => iconTitle && `
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 0.01em;
    margin-left: 10px;
    width: 100%;
  `}
  ${({ value }) => value && `
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: right;
    width: auto;
  `}
`
