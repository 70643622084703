import {
  takeLatest, put,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import { DELETE_PASSPORT_FILES_URL } from '@/constants/apiRoutes'
import {
  DELETE_PASSPORT_FILE,
  requestGetPassportAndCardData,
} from '@/store/actions/passportization'
import {
  responseDeletePassportFile,
  errorDeletePassportFile,
} from '@/store/actions/passportization/deletePassportFile'
import createNotifications from '@/helpers/notification'

function* deletePassportFileSaga({ payload }) {
  const { id, type, passportId } = payload
  const toast = createNotifications()
  try {
    const requestSearch = qs.stringify({
      fileUid: id,
      installationType: type,
      passportId,
      photoUid: 'null',
    })
    yield request({
      url: `${DELETE_PASSPORT_FILES_URL}?${requestSearch}`,
      method: 'delete',

    })
    yield put(responseDeletePassportFile())
    yield put(requestGetPassportAndCardData({ id, type }))
  } catch (error) {
    console.log('function*deletePassportFileSaga -> error', error);
    yield put(errorDeletePassportFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка операции! Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_PASSPORT_FILE.REQUEST, deletePassportFileSaga)
}
