import styled from 'styled-components'

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  color:  ${({ theme }) => theme.colors.colors.disabled};
  text-align: left;
  ${({ widget }) => widget && `
    margin-left: 10px;
  `}
  ${({ main }) => main && `
    margin-bottom: 10px;
  `}
`

export const WidgetContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;

  svg {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }
`
