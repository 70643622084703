import styled from 'styled-components'
import TextField from '@/components/fields/TextField'
import Button from '@/components/blocks/Button'

export const Form = styled.form`
  width: 100%;
  margin-top: 10px;
`

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.colors.subTitle};
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration-line: underline;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

export const ErrorMessage = styled.p`
  font-size: 1.4rem;
  margin: 15px 0;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 10px;
`

export const Text = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.colors.subTitle};
  margin-bottom: 10px;
`
