import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const FoldMenuIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 14L11.4531 22.6981C11.0576 23.1006 10.4244 23.1006 10.0289 22.6981L9.30503 21.9614C8.89864 21.5479 8.89828 20.8687 9.30423 20.4546L15.6328 14L9.30423 7.54538C8.89828 7.13134 8.89864 6.45215 9.30502 6.03857L10.0289 5.3019C10.4244 4.89937 11.0576 4.89937 11.4531 5.3019L20 14Z" fill={fill} className="svgFill" />
    </svg>
  )
}

FoldMenuIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

FoldMenuIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(FoldMenuIcon)
