import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import PieChart from '../PieChart'

import {
  ClusterContainerSmall, ClusterContainerMedium, ClusterContainerBig,
  PieChartContainerSmall, PieChartContainerMedium, PieChartContainerBig,
  PieCharOverLaySmall, PieCharOverLayMedium, PieCharOverLayBig,
  TitleSmall, TitleMedium, TitleBig,
} from './styles'

const ZoneIcon = ({
  theme,
  count,
  statistic,
}) => {
  return (
    <>
      {count <= 99 && (
        <ClusterContainerSmall theme={theme}>
          <PieChartContainerSmall theme={theme}>
            <PieChart size="small" statistic={statistic} theme={theme} />
          </PieChartContainerSmall>
          <PieCharOverLaySmall theme={theme}>
            <TitleSmall theme={theme}>
              {count}
            </TitleSmall>
          </PieCharOverLaySmall>
        </ClusterContainerSmall>
      )}
      {count > 99 && count <= 999 && (
        <ClusterContainerMedium theme={theme}>
          <PieChartContainerMedium theme={theme}>
            <PieChart size="medium" statistic={statistic} theme={theme} />
          </PieChartContainerMedium>
          <PieCharOverLayMedium theme={theme}>
            <TitleMedium theme={theme}>
              {count}
            </TitleMedium>
          </PieCharOverLayMedium>
        </ClusterContainerMedium>
      )}
      {count > 999 && (
        <ClusterContainerBig theme={theme}>
          <PieChartContainerBig theme={theme}>
            <PieChart size="big" statistic={statistic} theme={theme} />
          </PieChartContainerBig>
          <PieCharOverLayBig theme={theme}>
            <TitleBig theme={theme}>
              {count}
            </TitleBig>
          </PieCharOverLayBig>
        </ClusterContainerBig>
      )}
    </>
  )
}

ZoneIcon.propTypes = {
  theme: mapTypes.theme.isRequired,
}

export default ZoneIcon
