import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import useReduxTable from '@/hooks/useReduxTable'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import { LIVE_TIME_REPORT_TABLE } from '@/store/actions/lifeTimeReport'
import { getTableParameters } from '@/store/selectors/lifeTimeReport'
import UsersIcons from '@/components/icons/users'
import PageSidebarContainer from '@/containers/regions/PageSidebarContainer'
import TextControl from '@/components/controls/TextControl'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import Graph from '@/components/charts/Graph'
import MapIcons from '@/components/icons/maps'
import CollapsedInfoBlock from '@/components/blocks/CollapsedInfoBlock'
import { GRAPH_PERCENT, GRAPH_YEAR } from '@/components/charts/Graph/config'
import {
  liveTimeExportOptions,
  liveTimeFields,
} from '@/constants/tablesConfig/liveTime'
import {
  Main,
  Content,
} from './styles'

const LiveTime = ({
  data,
  tree,
  graphType,
  fileStatus,
  node,
  telemetry,
  loadingTable,

  setGraphType,
  setNode,
  getGraphData,
  getTableData,
  getReportFile,
  graphPercent,
  graphYear,
  selectedGraphPercentOptions,
  selectedGraphYearOptions,
  setNewGraphYearOptions,
  setNewGraphPercentOptions,
}) => {
  useHtmlTitle('Срок службы')
  const [treeQuery, setTreeQuery] = useState(null)
  const [isGraphOpen, setIsGraphOpen] = useState(true)
  const isHasDataForGraph = useMemo(() => {
    const dataByType = {
      [GRAPH_PERCENT]: graphPercent,
      [GRAPH_YEAR]: graphYear,
    }
    const selectedByType = {
      [GRAPH_PERCENT]: selectedGraphPercentOptions,
      [GRAPH_YEAR]: selectedGraphYearOptions,
    }
    const graphData = dataByType[graphType]
    const selectedData = selectedByType[graphType]

    return Object.keys(graphData).some((key) => !!graphData[key].indicatorWidth)
      && selectedData.length === 0
  },
  [graphPercent, graphType, graphYear, selectedGraphPercentOptions, selectedGraphYearOptions])
  const getObjectTelemetry = (object) => telemetry[`${object.type}-${object.aliasId}-${object.id}`] || {}
  const getPageData = useCallback(
    () => {
      getGraphData()
      getTableData()
    },
    [
      getGraphData,
      getTableData,
    ],
  )

  useEffect(() => { getPageData() }, [getPageData])
  useEffect(() => {
    if (!(node && node.id)) {
      setNode(tree)
    }
  }, [node, setNode, tree])

  const tableProps = useReduxTable(LIVE_TIME_REPORT_TABLE, getTableParameters)

  const toggleShowCase = () => {
    setIsGraphOpen(!isGraphOpen)
  }

  const handleFileDownload = useCallback((fileType) => {
    getReportFile({
      fileType,
      type: data.length > 0 ? 'table' : 'indication',
    })
  }, [data, getReportFile])

  const onSelectNode = useCallback((selectedNode, event) => {
    if (event === 'click') {
      setNode(selectedNode)
      getPageData()
    }
  }, [setNode, getPageData])

  return (
    <Main>
      <PageSidebarContainer
        onIconClick={noop}
        title="Срок службы"
        treeData={tree}
        searchQuery={treeQuery}
        onSelect={onSelectNode}
        getTelemetry={getObjectTelemetry}
        headerContent={(
          <Lang id="usersPage.titles.search">
            {(placeholder) => (
              <TextControl
                dark
                placeholder={placeholder}
                name="search"
                icon={UsersIcons.MagnifierIcon}
                onChange={setTreeQuery}
              />
            )}
          </Lang>
        )}
      />
      <Content>
        <CollapsedInfoBlock
          isSettingsOpen={isGraphOpen}
          setIsSettingsOpen={toggleShowCase}
          name={node.name}
          icon={MapIcons.ArrowIcon}
          content={(
            <Graph
              graphType={graphType}
              setGraphType={setGraphType}
              graphPercent={graphPercent}
              graphYear={graphYear}
              selectedGraphPercentOptions={selectedGraphPercentOptions}
              selectedGraphYearOptions={selectedGraphYearOptions}
              setSelectedGraphYearOptions={setNewGraphYearOptions}
              setSelectedGraphPercentOptions={setNewGraphPercentOptions}
            />
          )}
        />
        {loadingTable
          ? <Loader center />
          : (
            <DutyTable
              {...tableProps}
              isNoData={!isHasDataForGraph}
              fields={liveTimeFields}
              exportOptions={liveTimeExportOptions}
              data={data}
              onUpdateData={getPageData}
              onFileSelect={handleFileDownload}
              fileStatus={fileStatus}
              headerHeight={42}
            />
          )}
      </Content>
    </Main>

  )
}


LiveTime.propTypes = {
  data: pt.arrayOf(pt.object),
  tree: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    name: pt.string,
  }),
  graphType: pt.string,
  fileStatus: pt.string,
  node: pt.shape({
    id: pt.oneOfType([pt.number, pt.string]),
    name: pt.string,
  }),
  telemetry: pt.shape({}),
  loadingTable: pt.bool,

  setGraphType: pt.func,
  setNode: pt.func,
  getGraphData: pt.func,
  getTableData: pt.func,
  getReportFile: pt.func,
  graphPercent: pt.arrayOf(pt.object),
  graphYear: pt.arrayOf(pt.object),
  selectedGraphPercentOptions: pt.arrayOf(pt.object),
  selectedGraphYearOptions: pt.arrayOf(pt.object),
  setNewGraphYearOptions: pt.func,
  setNewGraphPercentOptions: pt.func,
}

LiveTime.defaultProps = {
  data: {},
  tree: {},
  graphType: null,
  fileStatus: null,
  node: {},
  telemetry: {},
  loadingTable: false,

  setGraphType: noop,
  setNode: noop,
  getGraphData: noop,
  getTableData: noop,
  getReportFile: noop,
  graphPercent: [],
  graphYear: [],
  selectedGraphPercentOptions: [],
  selectedGraphYearOptions: [],
  setNewGraphYearOptions: noop,
  setNewGraphPercentOptions: noop,
}

export default LiveTime
