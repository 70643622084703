export default {
  title: 'Monitoring Center',
  secondTitle: '“MESH” topology',
  widgets: {
    tiltAngel: 'Tilt angle',
    unknown: 'unknown',
  },
  titles: {
    newObject: 'Objects',
    editUser: 'User Editor',
    newGroup: 'New group',
    editGroup: 'Group Editor',
    users: 'Users',
    addTitle: 'Add',
    addUserButton: 'New User',
    addGroupButton: 'New group',
    search: 'Search',
    tableSearch: 'Table search',
  },
  tabs: {
    allObjects: 'All objects',
    geoZone: 'Geozones',
    installations: 'Installations',
    statistics: 'Statistics',
  },
  mapControl: {
    count: 'Total objects',
    filter: 'Filter',
    list: 'List',
    selectMode: 'Select Mode',
    zoom: 'Zoom',
    select: 'Select all',
    reset: 'Reset selection',
  },
  filterTypes: {
    shuo: 'LCB',
    baseStation: 'Base Station',
    controller: 'Controller',
    universMeter: 'Multifunction counter',
    support: 'Prop',
    let: 'Line',
    ok: 'Active',
    warning: 'Warning',
    info: 'Inactive',
    error: 'Error',
    traffic: 'Traffic light',
    light: 'Light',
    lightModule: 'Light Module',
    trash: 'trash',
    groupObject: 'Group object',
    heatingDevice: 'Group object',
    coldWaterDevice: 'Group object',
    lightElement: 'Lightpoint',
    outdoorLighting: 'Outdoor lighting',
    waterSupply: 'Water Supply',
    heatSupply: 'Heat supply',
    centralHeating: 'CH metering unit',
    coldWater: 'Cold water metering unit',
  },
  titlesByStatus: {
    ok: 'OK',
    warning: 'Possible problems',
    info: 'Inactive',
    error: 'Problems in operation',
  },
  defaultTitles: {
    geozonName: 'Geozone name',
    clusterName: 'Object Cluster',
  },
}