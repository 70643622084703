import { USER_REFRESH_TOKEN } from './index'

export const requestUserRefreshToken = (redirect, callback) => ({
  type: USER_REFRESH_TOKEN.REQUEST,
  payload: { redirect, callback },
})

export const successUserRefreshToken = () => ({
  type: USER_REFRESH_TOKEN.SUCCESS,
})

export const errorUserRefreshToken = (error) => ({
  type: USER_REFRESH_TOKEN.ERROR,
  payload: error,
})

export const beginUserRefreshToken = () => ({
  type: USER_REFRESH_TOKEN.BEGIN,
})

export const endUserRefreshToken = () => ({
  type: USER_REFRESH_TOKEN.END,
})
