import { DELETE_PASSPORT_FILE } from './index'

export const requestDeletePassportFile = (payload) => ({
  type: DELETE_PASSPORT_FILE.REQUEST,
  payload,
})

export const responseDeletePassportFile = (payload) => ({
  type: DELETE_PASSPORT_FILE.SUCCESS,
  payload,
})

export const errorDeletePassportFile = (payload) => ({
  type: DELETE_PASSPORT_FILE.ERROR,
  payload,
})
