import React from 'react'
import qs from 'query-string'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsIcons from '@/components/icons/widgets'
import MenuIcons from '@/components/icons/menu'
import { WIDGETS_DASHBOARD_URL, WIDGETS_DASHBOARD_GOV_URL } from '@/constants/routes'
import { PROCESSES_BY_ID, ID_BY_APPLICATIONS } from '@/constants/widgets'
import {
  START_DATE,
  END_DATE,
  PERIOD,
  INTERVAL,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  ALL_PERIOD,
  MONTHLY,
} from '@/constants/tyumenOurHome'
import { APP_BY_WIDGET_ID } from './names'

const widgetsShop = [
  {
    applicationId: ID_BY_APPLICATIONS.HEATING,
    processId: PROCESSES_BY_ID.HEAT_SUPPLY,
    id: APP_BY_WIDGET_ID.HEAT_SUPPLY,
    icon: WidgetsIcons.HeatIcon,
    menuIcon: MenuIcons.HeatSupplyIcon,
    title: (<Lang id="widgets.heatSupply" />),
    description: 'Подключение к системе объектов теплоснабжения',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.WATER,
    processId: PROCESSES_BY_ID.WATER_SUPPLY,
    id: APP_BY_WIDGET_ID.WATER_SUPPLY,
    icon: WidgetsIcons.WaterIcon,
    menuIcon: MenuIcons.WaterSupplyIcon,
    title: (<Lang id="widgets.waterSupply" />),
    description: 'Подключение к системе объектов водоснабжения',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.CHARGING_STATIONS,
    processId: PROCESSES_BY_ID.CHARGING_STATION,
    id: APP_BY_WIDGET_ID.CHARGING_STATION,
    icon: WidgetsIcons.CarElectricStationsIcon,
    menuIcon: MenuIcons.CarElectricStationsIcon,
    title: (<Lang id="widgets.chargingStation" />),
    description: 'Подключение к системе зарядные автостанции',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.ART_LIGHTING,
    id: APP_BY_WIDGET_ID.ARCHITECTURE_ILLUMINATION,
    icon: WidgetsIcons.IlluminationIcon,
    menuIcon: MenuIcons.IlluminationIcon,
    title: (<Lang id="widgets.architectureIllumination" />),
    description: 'Подключение к системе объектов архитектурно-художественной подсветки',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.WASTE_COLLECTION,
    processId: PROCESSES_BY_ID.GARBAGE_COLLECTION,
    id: APP_BY_WIDGET_ID.GARBAGE_COLLECTION,
    icon: WidgetsIcons.GarbageCollectionIcon,
    menuIcon: MenuIcons.SmartRecycleBinIcon,
    title: (<Lang id="widgets.garbageCollection" />),
    description: 'Подключение к системе объектов раздельного сбора мусора',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.ROAD_MONITORING,
    id: APP_BY_WIDGET_ID.ROAD_INFRASTRUCTURE,
    icon: WidgetsIcons.RoadIcon,
    menuIcon: MenuIcons.RoadIcon,
    title: (<Lang id="widgets.roadInfrastructure" />),
    description: 'Подключение к системе объектов мониторинга дорожной инфраструктуры',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.TRAFFIC_LIGHTS,
    id: APP_BY_WIDGET_ID.TRAFFIC_LIGHT,
    icon: WidgetsIcons.TrafficLightIcon,
    menuIcon: MenuIcons.TrafficLightIcon,
    title: (<Lang id="widgets.trafficLight" />),
    description: 'Подключение к системе объектов управления светофорами',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.INFO_BOARDS,
    id: APP_BY_WIDGET_ID.INFO_FRAME,
    icon: WidgetsIcons.InfoFrameIcon,
    menuIcon: MenuIcons.InfoFrameIcon,
    title: (<Lang id="widgets.infoFrame" />),
    description: 'Подключение к системе размещения инфотабло',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.DISABLED_PARKING_SPACES,
    id: APP_BY_WIDGET_ID.HANDICAPPED_PLACES,
    icon: WidgetsIcons.HandicappedIcon,
    menuIcon: MenuIcons.HandicappedIcon,
    title: (<Lang id="widgets.handicappedPlaces" />),
    description: 'Подключение к системе контроля занятости мест для инвалидов',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.DASHBOARD,
    id: APP_BY_WIDGET_ID.WIDGETS_DASHBOARD,
    maxCount: 1,
    icon: WidgetsIcons.WidgetsDashboard,
    menuIcon: MenuIcons.WidgetsDashboard,
    path: WIDGETS_DASHBOARD_URL,
    params: qs.stringify({
      [START_DATE]: null,
      [END_DATE]: null,
      [PERIOD]: ALL_PERIOD,
      [INTERVAL]: MONTHLY,
    }),
    title: (<Lang id="widgets.widgetsDashboard" />),
    description: 'Объединенный центр сбора и контроля статистических данных из всех подключенных к системе источников данных',
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.DASHBOARD2,
    id: APP_BY_WIDGET_ID.WIDGETS_DASHBOARD_GOV,
    maxCount: 1,
    icon: WidgetsIcons.WidgetsDashboard,
    menuIcon: MenuIcons.WidgetsDashboard,
    path: WIDGETS_DASHBOARD_GOV_URL,
    title: (<Lang id="widgets.widgetsDashboardGov" />),
    description: 'Объединенный центр сбора и контроля статистических данных из всех подключенных к системе источников данных',
    renderComponent: () => (null),
  },
]

export default widgetsShop
