import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ClosedLockIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.25 6H13.375V4.28571C13.375 1.92 11.415 0 9 0C6.585 0 4.625 1.92 4.625 4.28571V6H3.75C2.7875 6 2 6.77143 2 7.71429V16.2857C2 17.2286 2.7875 18 3.75 18H14.25C15.2125 18 16 17.2286 16 16.2857V7.71429C16 6.77143 15.2125 6 14.25 6ZM6.375 4.28571C6.375 2.86286 7.5475 1.71429 9 1.71429C10.4525 1.71429 11.625 2.86286 11.625 4.28571V6H6.375V4.28571ZM14.25 16.2857H3.75V7.71429H14.25V16.2857ZM9 13.7143C9.9625 13.7143 10.75 12.9429 10.75 12C10.75 11.0571 9.9625 10.2857 9 10.2857C8.0375 10.2857 7.25 11.0571 7.25 12C7.25 12.9429 8.0375 13.7143 9 13.7143Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ClosedLockIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ClosedLockIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ClosedLockIcon)
