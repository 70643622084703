import React from 'react'
import pt from 'prop-types'
import CoreIcons from '@/components/icons/core'
import { DateInput, DateInputText } from './styles'

const CustomInput = ({ value, onClick }) => (
  <DateInput onClick={onClick}>
    <CoreIcons.CalendarIcon />
    <DateInputText>
      {value || 'дд.мм.гггг'}
    </DateInputText>
  </DateInput>
)

CustomInput.propTypes = {
  value: pt.string.isRequired,
  onClick: pt.func.isRequired,
}
CustomInput.defaultProps = {}

export default CustomInput
