import { useEffect, useState } from 'react'
import moment from 'moment'

const getTime = (zone = 5) => moment().utcOffset(zone * 60).format('HH:mm:ss')

export const useTimeCounter = (zone = 5) => {
  const [time, setTime] = useState(getTime(zone))
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime(zone))
    }, 1000)

    return () => clearInterval(interval)
  }, [zone])
  return time
}

export default useTimeCounter
