import isEmpty from 'lodash/isEmpty'

export const defaultMatchFunction = (
  filterText = '', node = { name: '' },
) => {
  if (node.name) {
    return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  }
  return false
}

export const findNode = (node, filter, matchFunction) => matchFunction(filter, node)
  || (node.children
      && node.children.length
      && !!node.children.find((child) => findNode(child, filter, matchFunction)))

export const filterTree = (node, filter, matchFunction = defaultMatchFunction) => {
  // If im an exact match then all my children get to stay
  if (matchFunction(filter, node) || !node.children) {
    return node
  }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.children
    .filter((child) => findNode(child, filter, matchFunction))
    .map((child) => filterTree(child, filter, matchFunction))
  if (!isEmpty(filtered)) {
    return { ...node, children: filtered }
  }
}

export const expandFilteredNodes = (node, filter, matchFunction = defaultMatchFunction) => {
  let { children } = node
  if (!children || children.length === 0) {
    return { ...node, toggled: false }
  }
  const childrenWithMatches = node.children
    .filter((child) => findNode(child, filter, matchFunction))
  const shouldExpand = childrenWithMatches.length > 0

  if (shouldExpand) {
    children = childrenWithMatches.map((child) => expandFilteredNodes(child, filter, matchFunction))
  }
  return {
    ...node,
    children,
    toggled: shouldExpand,
  }
}
