import { USER_FORGOT_PASSWORD } from './index'

export const requestUserForgotPassword = (form) => ({
  type: USER_FORGOT_PASSWORD.REQUEST,
  payload: form,
})

export const successUserForgotPassword = (response) => ({
  type: USER_FORGOT_PASSWORD.SUCCESS,
  payload: response,
})

export const errorUserForgotPassword = (error) => ({
  type: USER_FORGOT_PASSWORD.ERROR,
  payload: error,
})
