import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_LIVE_TIME_REPORT_BAR_DATA,
  GET_LIVE_TIME_REPORT_TABLE,
  GET_LIVE_TIME_REPORT_FILE,
  LIVE_TIME_REPORT_TABLE,

  SET_GRAPH_TYPE,
  SET_GRAPH_OPTIONS,
  SET_BAR_GRAPH_YEAR_OPTIONS,
  SET_BAR_GRAPH_PERSENT_OPTIONS,
} from '@/store/actions/lifeTimeReport'
import { liveTimeFields } from '@/constants/tablesConfig/liveTime'

const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  selector: 'all',
  graphType: 'graphPercent',
  graphPercent: [],
  graphYear: [],
  selectedGraphPercentOptions: [],
  selectedGraphYearOptions: [],
  loadingTable: false,
  table: generateDefaultTableState(liveTimeFields),
}

export const reducer = handleActions(
  {
    [GET_LIVE_TIME_REPORT_BAR_DATA.SUCCESS]: (state, { payload: { graphPercent, graphYear } }) => ({
      ...state,
      graphPercent,
      graphYear,
    }),
    [GET_LIVE_TIME_REPORT_TABLE.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      ...generateTableParameter(state, 'resultCount', payload.totalElements),
      loadingTable: false,
    }),
    [GET_LIVE_TIME_REPORT_TABLE.REQUEST]: (state, { payload }) => ({
      ...state,
      ...payload,
      loadingTable: true,
    }),
    [GET_LIVE_TIME_REPORT_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      loadingTable: false,
    }),
    [SET_GRAPH_TYPE]: (state, { payload }) => ({
      ...state,
      graphType: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_GRAPH_OPTIONS]: (state, { payload }) => ({
      ...state,
      graphOptions: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_BAR_GRAPH_YEAR_OPTIONS]: (state, { payload }) => ({
      ...state,
      selectedGraphYearOptions: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_BAR_GRAPH_PERSENT_OPTIONS]: (state, { payload }) => ({
      ...state,
      selectedGraphPercentOptions: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [GET_LIVE_TIME_REPORT_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_LIVE_TIME_REPORT_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_LIVE_TIME_REPORT_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
    ...generateDefaultTableReducer(LIVE_TIME_REPORT_TABLE),
  },
  initialState,
)

export default reducer
