import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AutomaticFaultReportingIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.52 1.43366L17.551 11.3546C18.4177 12.7795 17.992 14.6542 16.5996 15.5413C16.1324 15.8402 15.5876 15.9994 15.0309 16H2.96821C1.3293 16 0 14.64 0 12.9609C0 12.3929 0.1557 11.8373 0.4482 11.3546L6.48 1.43366C7.3458 0.00875938 9.1764 -0.42768 10.5687 0.45943C10.9539 0.70477 11.2797 1.0381 11.52 1.43366ZM9.522 2.17945C9.4118 2.10945 9.2884 2.06224 9.1593 2.04067C9.0301 2.0191 8.8978 2.02361 8.7705 2.05393C8.6431 2.08426 8.5233 2.13977 8.4183 2.2171C8.3133 2.29444 8.2252 2.392 8.1594 2.50389L2.12851 12.4266C2.03054 12.5883 1.9789 12.7733 1.97911 12.9618C1.97911 13.5218 2.42191 13.9751 2.96911 13.9751H15.03C15.2154 13.9751 15.3963 13.9218 15.5529 13.8222C15.7777 13.6747 15.9361 13.4473 15.9953 13.1874C16.0545 12.9274 16.0098 12.6549 15.8706 12.4266L9.8397 2.50389C9.7606 2.37325 9.6523 2.26225 9.5229 2.17945H9.522ZM9 12.4444C8.7613 12.4444 8.5324 12.3508 8.3636 12.1841C8.1948 12.0174 8.1 11.7913 8.1 11.5555C8.1 11.3198 8.1948 11.0937 8.3636 10.927C8.5324 10.7603 8.7613 10.6666 9 10.6666C9.2387 10.6666 9.4676 10.7603 9.6364 10.927C9.8052 11.0937 9.9 11.3198 9.9 11.5555C9.9 11.7913 9.8052 12.0174 9.6364 12.1841C9.4676 12.3508 9.2387 12.4444 9 12.4444ZM9 4.4444C9.2387 4.4444 9.4676 4.538 9.6364 4.7047C9.8052 4.8714 9.9 5.0975 9.9 5.3332V8.8888C9.9 9.1246 9.8052 9.3507 9.6364 9.5174C9.4676 9.6841 9.2387 9.7777 9 9.7777C8.7613 9.7777 8.5324 9.6841 8.3636 9.5174C8.1948 9.3507 8.1 9.1246 8.1 8.8888V5.3332C8.1 5.0975 8.1948 4.8714 8.3636 4.7047C8.5324 4.538 8.7613 4.4444 9 4.4444Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AutomaticFaultReportingIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AutomaticFaultReportingIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AutomaticFaultReportingIcon)
