import {
  NOT_ELEMENTS_TYPES,
  REGIONS_TYPES,
  STATUS_TYPES,
  mapZoomBreakPoints,
  ZOOM_BY_TYPES,
} from '@/constants/maps'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'

export const getIsElementary = (elementType) => NOT_ELEMENTS_TYPES
  .every((type) => elementType !== type)

export const getIsNotRegion = (elementType) => REGIONS_TYPES
  .every((type) => elementType !== type)

export const getZoomByType = (type) => {
  if (ZOOM_BY_TYPES[type]) {
    return ZOOM_BY_TYPES[type]
  }

  return mapZoomBreakPoints.large
}

export const getCountOfStatus = (data) => {
  const colorsCount = data.reduce((accumulator, element) => {
    const isStatusType = STATUS_TYPES[element]
    if (isStatusType) {
      return {
        ...accumulator,
        [element]: accumulator[element] + 1,
      }
    }
    return accumulator
  }, {
    [OK]: 0,
    [WARNING]: 0,
    [INFO]: 0,
    [ERROR]: 0,
  })
  return {
    data: colorsCount,
    length: data.length,
  }
}

export const getStatusOfObjects = (data) => {
  const length = Object.keys(data)
  return length.map((el) => data[el].objectData && data[el].objectData.status)
}
