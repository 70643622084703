import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const NewsletterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M16.7 2H4.1C3.1073 2 2.3 2.78487 2.3 3.75V8.125H4.1V5.5L9.86 9.7C10.0158 9.81346 10.2053 9.87478 10.4 9.87478C10.5947 9.87478 10.7842 9.81346 10.94 9.7L16.7 5.5V13.375H9.5V15.125H16.7C17.6927 15.125 18.5 14.3401 18.5 13.375V3.75C18.5 2.78487 17.6927 2 16.7 2ZM10.4 7.90625L4.6994 3.75H16.1006L10.4 7.90625Z" fill={fill} className="svgFill" />
        <path d="M0.5 9H6.8V10.75H0.5V9ZM2.3 11.625H7.7V13.375H2.3V11.625ZM5 14.25H8.6V16H5V14.25Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

NewsletterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NewsletterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(NewsletterIcon)
