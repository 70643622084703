import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.tertiary};
`
export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const ContentContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 935px 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  overflow-y: scroll;
  @media screen and (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '2100px' : '1860px')}) {
    grid-template-columns: 100%;
  }
  padding-bottom: 10px;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const CombinedWidgets = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  & > div {
    margin-bottom: 10px;
  }
  & > div:not(:first-child) {
    margin-left: 5px;
  }
  & > div:not(:last-child) {
    margin-right: 5px;
  }
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    width: 100%;
    margin: 0 5px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`
