import { VERIFY_INTEGRATION } from './index'

export const requestVerifyIntegration = (payload) => ({
  type: VERIFY_INTEGRATION.REQUEST,
  payload,
})

export const successVerifyIntegration = (data) => ({
  type: VERIFY_INTEGRATION.SUCCESS,
  payload: data,
})

export const errorVerifyIntegration = () => ({
  type: VERIFY_INTEGRATION.ERROR,
})

export const eventsVerifyIntegration = () => ({
  type: VERIFY_INTEGRATION.EVENTS,
})
