import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  DASHBOARD_URL,
  USERS_URL,
  ALARMS_URL,
  SUMMARY_ALARMS_ANALYTICS_URL,
  ACTIVITY_LOG_URL,
  INDIVIDUAL_CONTROL_URL,
  GROUP_CONTROL_URL,
  INSTALLATION_URL,
  OBJECTS_URL,
  CHARTS_URL,
  PASPORTIZATION_URL,
  PERIOD_OF_EXPLOITATION,
  ANALYTICS_EXPENSES_URL,
  REPORT_MANAGER_URL,
  CONTROL_OBJECTS_STATISTICS,
} from '@/constants/routes'
import MenuIcons from '@/components/icons/menu'
import WidgetsIcons from '@/components/icons/widgets'

export const ID_BY_APPLICATIONS = {
  USERS: 1,
  ACTIVITY_LOG: 2,
  PASSPORTIZATION: 3,
  INSTALLATION: 4,
  INVENTORY: 5,
  MONITORING_CENTER: 6,
  REPORT_MANAGER: 7,
  SUPPORT: 8,
  SCHEDULE_MANAGER: 9,
  CONTROL_OBJECTS_STATISTICS: 10,
  ALARM_LOG: 11,
  LIFETIME: 12,
  RESOURCE_ANALYTICS: 13,
  HEATING: 14,
  WATER: 15,
  CHARGING_STATIONS: 16,
  ART_LIGHTING: 17,
  WASTE_COLLECTION: 18,
  ROAD_MONITORING: 19,
  TRAFFIC_LIGHTS: 20,
  INFO_BOARDS: 21,
  DISABLED_PARKING_SPACES: 22,
  DASHBOARD: 23,
  ALARM_ANALYTICS: 24,
  TYUMEN_PORTAL: 25,
  SERVICE_DESK: 26,
  DASHBOARD2: 28,
}

export const FREQUENCY = 'FREQUENCY'

export const PROCESSES_BY_ID = {
  LIGHT: 1,
  WATER_SUPPLY: 2,
  HEAT_SUPPLY: 3,
  GARBAGE_COLLECTION: 4,
  CHARGING_STATION: 5,
  HEAT_AND_WATER: 6,
}

export const HOME_PAGE = {
  menuIcon: MenuIcons.DesktopIcon,
  icon: null,
  name: 'DesktopIcon',
  title: <Lang id="menu.desktop" />,
  path: DASHBOARD_URL,
}

export const USERS_WIDGET = {
  menuIcon: MenuIcons.UsersIcon,
  icon: WidgetsIcons.UsersIcon,
  name: 'UsersIcon',
  title: <Lang id="menu.users" />,
  path: USERS_URL,
  applicationId: ID_BY_APPLICATIONS.USERS,
}

export const STATISTICS_ON_OBJECTS_LIGHTING_WIDGET = {
  icon: WidgetsIcons.ObjectStatisticIcon,
  menuIcon: MenuIcons.ObjectStatisticIcon,
  name: 'ObjectStatisticIcon',
  title: <Lang id="menu.controlObject" />,
  applicationId: ID_BY_APPLICATIONS.CONTROL_OBJECTS_STATISTICS,
  path: CONTROL_OBJECTS_STATISTICS,
}

export const ANALYTICS_EXPENSES_WIDGET = {
  icon: WidgetsIcons.AnalyticsExpensesIcon,
  menuIcon: MenuIcons.AnalyticsExpensesIcon,
  name: 'AnalyticsExpensesIcon',
  title: <Lang id="menu.analyticExpenses" />,
  path: ANALYTICS_EXPENSES_URL,
  applicationId: ID_BY_APPLICATIONS.RESOURCE_ANALYTICS,
}

export const PASPORTIZATION_WIDGET = {
  menuIcon: MenuIcons.PassportizationIcon,
  icon: WidgetsIcons.PassportizationIcon,
  name: 'PassportizationIcon',
  title: <Lang id="menu.passportization" />,
  path: PASPORTIZATION_URL,
  applicationId: ID_BY_APPLICATIONS.PASSPORTIZATION,
}

export const ALERT_MANAGER_WIDGET = {
  menuIcon: MenuIcons.AlertManagerIcon,
  icon: WidgetsIcons.AlertManagerIcon,
  name: 'AlertManagerIcon',
  title: <Lang id="menu.alertManager" />,
  path: SUMMARY_ALARMS_ANALYTICS_URL,
  applicationId: ID_BY_APPLICATIONS.ALARM_ANALYTICS,
}

export const ACTIVITY_LOG_WIDGET = {
  icon: WidgetsIcons.ActivityLogIcon,
  menuIcon: MenuIcons.ActivityLogIcon,
  name: 'ActivityLogIcon',
  title: <Lang id="menu.activityLog" />,
  path: ACTIVITY_LOG_URL,
  applicationId: ID_BY_APPLICATIONS.ACTIVITY_LOG,
}

export const ALARMS_WIDGET = {
  icon: WidgetsIcons.AlarmsIcon,
  menuIcon: MenuIcons.AlarmsIcon,
  name: 'AlarmsIcon',
  title: <Lang id="menu.alarms" />,
  iconTitle: <Lang id="menu.alarms" />,
  path: ALARMS_URL,
  applicationId: ID_BY_APPLICATIONS.ALARM_LOG,
}

export const INDIVUDUAL_CONTROL_WIDGET = {
  menuIcon: MenuIcons.IndividualControlIcon,
  icon: WidgetsIcons.IndividualControlIcon,
  name: 'IndividualControlIcon',
  title: <Lang id="menu.individualControl" />,
  path: INDIVIDUAL_CONTROL_URL,
}

export const GROUP_CONTROL_WIDGET = {
  menuIcon: MenuIcons.GroupControlIcon,
  icon: WidgetsIcons.GroupControlIcon,
  name: 'GroupControlIcon',
  title: <Lang id="menu.groupControl" />,
  path: GROUP_CONTROL_URL,
}

export const SCHEDULE_WIDGET = {
  menuIcon: MenuIcons.ScheduleManagerIcon,
  icon: WidgetsIcons.ScheduleManagerIcon,
  name: 'ScheduleManagerIcon',
  title: <Lang id="menu.scheduleManager" />,
  applicationId: ID_BY_APPLICATIONS.SCHEDULE_MANAGER,
}

export const INSTALLATION_WIDGET = {
  menuIcon: MenuIcons.InstallationIcon,
  icon: WidgetsIcons.InstallationIcon,
  name: 'InstallationIcon',
  title: <Lang id="menu.installation" />,
  path: INSTALLATION_URL,
  applicationId: ID_BY_APPLICATIONS.INSTALLATION,
}

export const INVENTARISATION_WIDGET = {
  menuIcon: MenuIcons.EquipmentIcon,
  icon: WidgetsIcons.InventorisationIcon,
  name: 'InventorisationIcon',
  title: <Lang id="menu.inventory" />,
  applicationId: ID_BY_APPLICATIONS.INVENTORY,
}

export const REPORT_MANAGER_WIDGET = {
  menuIcon: MenuIcons.ReportManagerIcon,
  icon: WidgetsIcons.ReportManagerIcon,
  name: 'ReportManagerIcon',
  title: <Lang id="menu.reportManager" />,
  path: REPORT_MANAGER_URL,
  applicationId: ID_BY_APPLICATIONS.REPORT_MANAGER,
}

export const ENERGY_CONSUMPTION_WIDGET = {
  menuIcon: MenuIcons.EnergyConsumptionIcon,
  icon: WidgetsIcons.EnergyConsumptionIcon,
  name: 'EnergyConsumptionIcon',
  title: <Lang id="menu.energyConsumption" />,
}

export const LIFE_WIDGET = {
  menuIcon: MenuIcons.LifeIcon,
  icon: WidgetsIcons.LifeIcon,
  name: 'LifeIcon',
  title: <Lang id="menu.life" />,
  path: PERIOD_OF_EXPLOITATION,
  applicationId: ID_BY_APPLICATIONS.LIFETIME,
}

export const OBJECTS_WIDGET = {
  menuIcon: MenuIcons.ObjectsIcon,
  icon: WidgetsIcons.ObjectsIcon,
  name: 'ObjectsIcon',
  title: <Lang id="menu.objects" />,
  path: OBJECTS_URL,
  applicationId: ID_BY_APPLICATIONS.MONITORING_CENTER,
}

export const SEARCH_WIDGET = {
  menuIcon: MenuIcons.SearchIcon,
  icon: WidgetsIcons.SearchIcon,
  name: 'SearchIcon',
  title: <Lang id="menu.searchWidget" />,
}

export const CONTROL_CENTER_WIDGET = {
  menuIcon: MenuIcons.ControlCenterIcon,
  icon: WidgetsIcons.ControlCenterIcon,
  name: 'ControlCenterIcon',
  title: <Lang id="menu.controlCenter" />,
  path: CHARTS_URL,
}

export const SUPPORT_WIDGET = {
  menuIcon: MenuIcons.OperationAndTechnicalSupportIcon,
  icon: WidgetsIcons.OperationAndTechnicalSupportIcon,
  name: 'OperationAndTechnicalSupportIcon',
  title: <Lang id="menu.operationAndTechnicalSupport" />,
  applicationId: ID_BY_APPLICATIONS.SUPPORT,
}

export const CHANGE_LOG_WIDGET = {
  menuIcon: MenuIcons.ChangeLogIcon,
  icon: WidgetsIcons.ChangeLogIcon,
  name: 'ChangeLogIcon',
  title: <Lang id="menu.changeLog" />,
}

export const CAR_ELECTRIC_WIDGET = {
  icon: WidgetsIcons.CarElectricStationsIcon,
  name: 'CarElectricStationsIcon',
  menuIcon: MenuIcons.CarElectricStationsIcon,
  title: <Lang id="menu.carElectricStations" />,
}

export const WATER_SUPPLY_WIDGET = {
  icon: WidgetsIcons.WaterSupplyIcon,
  name: 'WaterSupplyIcon',
  menuIcon: MenuIcons.WaterSupplyIcon,
  title: <Lang id="menu.waterSupply" />,
}

export const HEAT_SUPPLY_WIDGET = {
  icon: WidgetsIcons.HeatSupplyIcon,
  name: 'HeatSupplyIcon',
  menuIcon: MenuIcons.HeatSupplyIcon,
  title: <Lang id="menu.heatSupply" />,
}

export const SMART_RECYCLE_WIDGET = {
  icon: WidgetsIcons.SmartRecycleBinIcon,
  name: 'SmartRecycleBinIcon',
  menuIcon: MenuIcons.SmartRecycleBinIcon,
  title: <Lang id="menu.smartRecycleBin" />,
}

export const SETTINGS_WIDGET = {
  menuIcon: MenuIcons.SettingsIcon,
  icon: WidgetsIcons.SettingsIcon,
  name: 'SettingsIcon',
  title: <Lang id="menu.settings" />,
}

export const LIGHTING_OBJECTS_WIDGET = {
  menuIcon: MenuIcons.LightingObjectsIcon,
  name: 'LightingObjectsIcon',
  title: <Lang id="menu.lightingObjects" />,
}

export const INVENTORY_WIDGET = {
  menuIcon: MenuIcons.InventoryIcon,
  name: 'InventoryIcon',
  title: <Lang id="menu.inventory" />,
}

export const WIDGETS_CONFIG = [
  HOME_PAGE,
  USERS_WIDGET,
  ACTIVITY_LOG_WIDGET,
  PASPORTIZATION_WIDGET,
  INSTALLATION_WIDGET,
  INVENTARISATION_WIDGET,
  OBJECTS_WIDGET,
  REPORT_MANAGER_WIDGET,
  SUPPORT_WIDGET,
  SCHEDULE_WIDGET,
  STATISTICS_ON_OBJECTS_LIGHTING_WIDGET,
  ALARMS_WIDGET,
  LIFE_WIDGET,
  ANALYTICS_EXPENSES_WIDGET,
]
