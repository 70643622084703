import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import SidebarTree from '@/components/blocks/SidebarTree'
import { LIGHT } from '@/constants/objectTypes'

const AnalyticsAlarms = ({
  tree, onSelectSettings,
}) => {
  const handleSelect = useCallback((node, event) => {
    if (event === 'click' && node.internalId) {
      onSelectSettings({
        installationId: node.internalId,
        name: node.name,
        objectId: node.type === LIGHT ? node.id : null,
      })
    }
  }, [onSelectSettings])
  return (
    <SidebarTree
      data={tree}
      onSelectNode={handleSelect}
    />
  )
}

AnalyticsAlarms.propTypes = {
  onSelectSettings: pt.func,
  tree: pt.objectOf(pt.object),
}
AnalyticsAlarms.defaultProps = {
  onSelectSettings: noop,
  tree: {},
}

export default memoWithName(AnalyticsAlarms)
