import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InstallationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6475 22.6472C22.4249 22.8701 22.1613 22.9873 21.8566 22.9991C21.552 23.0108 21.2942 22.9111 21.0833 22.7L19.4135 21.0286C18.4058 21.6972 17.2838 21.9669 16.0476 21.8379C14.8114 21.7089 13.7539 21.2045 12.8751 20.3249L12.5939 20.0434L20.0463 12.6013L20.3099 12.8652C21.1887 13.7449 21.6926 14.8034 21.8215 16.0408C21.9504 17.2783 21.6867 18.4072 21.0305 19.4276L22.7003 21.0814C22.9112 21.2925 23.0108 21.5505 22.9991 21.8555C22.9874 22.1604 22.8702 22.4244 22.6475 22.6472ZM14.334 12.5661L12.5412 10.7892L10.7835 12.5485L12.5763 14.3255C12.7872 14.5366 12.8868 14.7946 12.8751 15.0996C12.8634 15.4045 12.7462 15.6684 12.5236 15.8913C12.301 16.1142 12.0373 16.2314 11.7327 16.2432C11.428 16.2549 11.1702 16.1552 10.9593 15.9441L9.16651 14.1671L7.93617 15.3987L7.67253 15.1172C6.79371 14.2375 6.28986 13.179 6.16096 11.9416C6.03207 10.7042 6.29571 9.5811 6.9519 8.57241L5.29972 6.91862C5.08881 6.7075 4.98921 6.44946 5.00093 6.1445C5.01264 5.83955 5.12982 5.57565 5.35245 5.3528C5.57508 5.12994 5.83873 5.01265 6.14339 5.00093C6.44804 4.9892 6.70583 5.08889 6.91674 5.30002L8.56892 6.9538C9.57663 6.28525 10.6986 6.01548 11.9348 6.1445C13.171 6.27352 14.2344 6.77787 15.1249 7.65754L15.3885 7.93904L14.1582 9.17058L15.951 10.9475C16.1619 11.1587 16.2615 11.4167 16.2498 11.7216C16.2381 12.0266 16.1209 12.2905 15.8982 12.5133C15.6756 12.7362 15.412 12.8535 15.1073 12.8652C14.8027 12.8769 14.5449 12.7773 14.334 12.5661Z" fill={fill} className="svgFill" />
    </svg>
  )
}

InstallationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InstallationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InstallationIcon)
