import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import {
  WIDGET_SHOP_APP,
} from '@/constants/names'

export const getAppSettings = createSelector(
  getState,
  (state) => get(state, 'appSettings', {}),
)
export const getUserSettings = createSelector(
  getState,
  (state) => get(state, 'userSettings', {}),
)

export const getSettingsOpenState = createSelector(
  getAppSettings,
  (settings) => get(settings, 'isSettingsOpen'),
)

export const getProfileOpenState = createSelector(
  getAppSettings,
  (settings) => get(settings, 'isProfileOpen'),
)

export const getAlarmsWidgetOpenState = createSelector(
  getAppSettings,
  (settings) => get(settings, 'isAlarmsWidgetSettingsOpen'),
)

export const getLanguageState = createSelector(
  getAppSettings,
  (settings) => get(settings, 'language'),
)

export const getNotificationsCount = createSelector(
  getAppSettings,
  (settings) => get(settings, 'notificationsCount', 0),
)

export const getUserData = createSelector(
  getAppSettings,
  (settings) => get(settings, 'user', { roles: {}, modules: [] }),
)

export const getUserApplications = createSelector(
  getUserData,
  (settings) => get(settings, 'permissions.applications', []),
)

export const getWidgetShopValue = createSelector(
  getAppSettings,
  (settings) => get(settings, 'widgetShopValue', WIDGET_SHOP_APP),
)

export const getIsMenuOpen = createSelector(
  getAppSettings,
  (settings) => get(settings, 'isMenuOpen', {}),
)

// User settings with save

export const getAppSettingsState = createSelector(
  getUserSettings,
  (settings) => get(settings, 'appSettings'),
)

export const getSideBarMenuPinnedState = createSelector(
  getAppSettingsState,
  (settings) => get(settings, 'isMenuSideBarPinned'),
)

export const getThemeState = createSelector(
  getAppSettingsState,
  (settings) => get(settings, 'theme'),
)

export const getSchemeState = createSelector(
  getAppSettingsState,
  (settings) => get(settings, 'scheme'),
)

export const getFontState = createSelector(
  getAppSettingsState,
  (settings) => get(settings, 'largeFont'),
)

export const getWidgets = createSelector(
  getUserSettings,
  (settings) => get(settings, 'generalPage.widgets', []),
)

export const getApplications = createSelector(
  getUserSettings,
  (settings) => get(settings, 'generalPage.applications', []),
)

export const getGlobalFilters = createSelector(
  getUserSettings,
  (settings) => get(settings, 'globalFilterValues', {}),
)

export const getNode = createSelector(
  getUserSettings,
  (settings) => get(settings, 'selectedNode', {}),
)

export const getPinnedNode = createSelector(
  getUserSettings,
  (settings) => get(settings, 'pinnedNode', {}),
)

export const getSelectedNode = createSelector(
  [getNode, getPinnedNode],
  (node, pinnedNode) => {
    if (pinnedNode && pinnedNode.id) {
      return pinnedNode
    }

    return node
  },
)

export const getIntegrationStatus = createSelector(
  getUserSettings,
  (settings) => get(settings, 'serviceDeskIntegrationStatus', []),
)

export const getTenantServiceDeskStatus = createSelector(
  getUserSettings,
  (settings) => get(settings, 'tenantServiceDeskIntegrationStatus', []),
)
