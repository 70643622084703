import {
  take, call, put,
} from 'redux-saga/effects'
import { updateMapData } from '@/store/actions/maps/socketEvents'
import {
  mapLightTelemetry,
  mapControllerTelemetry,
  mapColdWaterTelemetry,
  mapHeatTelemetry,
  mapGroupObjectTelemetry,

  mapBrizTelemetry,

  mapMeshTelemetry,
} from '@/helpers/maps/telemetryMappers'

import { createMonitoringNotificationsChannel } from './channels'

const mapperByType = {
  LIGHT_STATE: mapLightTelemetry,
  OBJECT_STATE: mapControllerTelemetry,
  COLD_WATER_DEVICE_STATUS: mapColdWaterTelemetry,
  CENTRAL_HEATING_DEVICE_STATUS: mapHeatTelemetry,
  GROUP_DEVICE_STATUS: mapGroupObjectTelemetry,

  EQUIPMENT_STATE: mapBrizTelemetry,
}

function* monitoringNotifications() {
  try {
    let actions = {}
    const firstActionDate = new Date()
    let firstActionTime = firstActionDate.getTime()

    const channel = yield call(createMonitoringNotificationsChannel)

    while (true) {
      const eventData = yield take(channel)
      const { eventType } = (eventData || {}).header || {}

      let mapTelemetryFunction = mapperByType[eventType]

      if (!eventType && eventData.id && eventData.type) {
        mapTelemetryFunction = mapMeshTelemetry
      }

      if (mapTelemetryFunction) {
        actions = {
          ...actions,
          ...mapTelemetryFunction(eventData),
        }

        const actionDate = new Date()
        const actionTime = actionDate.getTime()
        const actionsDiff = actionTime - firstActionTime
        const TIME_DIFF = 1 * 60 * 1000

        if (actionsDiff >= TIME_DIFF) {
          yield put(updateMapData(actions))
          actions = {}
          firstActionTime = new Date().getTime()
        }
      }
    }
  } catch (error) {
    console.log('TCL: function*monitoringNotifications -> error', error)
  }
}

export default monitoringNotifications
