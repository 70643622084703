
import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ZoomIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1805 10.0402C9.86908 10.5985 9.53611 11.085 9.24083 11.4176C9.15075 11.5191 9.06803 11.6018 8.99687 11.6634C8.92608 11.6031 8.84388 11.5222 8.75441 11.423C8.4594 11.0957 8.12751 10.6165 7.81796 10.0659C7.14506 8.86901 6.70408 7.57283 6.70408 6.74959C6.70408 5.14859 7.66536 4.125 9 4.125C10.306 4.125 11.2959 5.19519 11.2959 6.74959C11.2959 7.5388 10.8522 8.83614 10.1805 10.0402ZM9 7.40385C9.59173 7.40385 10.0714 6.9303 10.0714 6.34615C10.0714 5.76201 9.59173 5.28846 9 5.28846C8.40827 5.28846 7.92857 5.76201 7.92857 6.34615C7.92857 6.9303 8.40827 7.40385 9 7.40385Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9091 1.61538H8.67865L7.56342 0.365199C7.35632 0.133034 7.05808 0 6.74471 0H1.09091C0.488417 0 0 0.482155 0 1.07692V12.9231C0 13.5178 0.488417 14 1.09091 14H16.9091C17.5116 14 18 13.5178 18 12.9231V2.69231C18 2.09754 17.5116 1.61538 16.9091 1.61538ZM5.78571 6.74959C5.78571 8.9476 8.01768 12.6923 9 12.6923C9.98232 12.6923 12.2143 8.87166 12.2143 6.74959C12.2143 4.62752 10.7752 3.17308 9 3.17308C7.2248 3.17308 5.78571 4.55158 5.78571 6.74959Z" fill={fill} />
    </svg>
  )
}

ZoomIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ZoomIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ZoomIcon)
