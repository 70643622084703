import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AttentionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 8.6C8.76131 8.6 8.53239 8.69482 8.36361 8.8636C8.19482 9.03238 8.1 9.2613 8.1 9.5V13.1C8.1 13.3387 8.19482 13.5676 8.36361 13.7364C8.53239 13.9052 8.76131 14 9 14C9.2387 14 9.46762 13.9052 9.6364 13.7364C9.80518 13.5676 9.9 13.3387 9.9 13.1V9.5C9.9 9.2613 9.80518 9.03238 9.6364 8.8636C9.46762 8.69482 9.2387 8.6 9 8.6ZM9.342 5.072C9.12289 4.98198 8.87712 4.98198 8.658 5.072C8.54753 5.11483 8.4466 5.17906 8.361 5.261C8.2815 5.34848 8.21758 5.44893 8.172 5.558C8.12162 5.66481 8.09696 5.78194 8.1 5.9C8.09932 6.01844 8.12202 6.13586 8.16681 6.24551C8.2116 6.35517 8.27759 6.4549 8.361 6.539C8.44848 6.6185 8.54893 6.68242 8.658 6.728C8.79435 6.78401 8.94237 6.80568 9.08905 6.7911C9.23574 6.77652 9.37659 6.72613 9.49924 6.64436C9.62189 6.56259 9.72258 6.45195 9.79246 6.32216C9.86234 6.19237 9.89927 6.0474 9.9 5.9C9.89669 5.66171 9.80346 5.43347 9.639 5.261C9.55341 5.17906 9.45248 5.11483 9.342 5.072ZM9 0.5C7.21997 0.5 5.47991 1.02784 3.99987 2.01677C2.51983 3.00571 1.36628 4.41131 0.685088 6.05585C0.00389956 7.70038 -0.17433 9.50998 0.172936 11.2558C0.520203 13.0016 1.37737 14.6053 2.63604 15.864C3.89471 17.1226 5.49836 17.9798 7.24419 18.3271C8.99002 18.6743 10.7996 18.4961 12.4442 17.8149C14.0887 17.1337 15.4943 15.9802 16.4832 14.5001C17.4722 13.0201 18 11.28 18 9.5C18 8.3181 17.7672 7.14778 17.3149 6.05585C16.8626 4.96392 16.1997 3.97177 15.364 3.13604C14.5282 2.30031 13.5361 1.63738 12.4442 1.18508C11.3522 0.732792 10.1819 0.5 9 0.5ZM9 16.7C7.57598 16.7 6.18393 16.2777 4.9999 15.4866C3.81586 14.6954 2.89302 13.5709 2.34807 12.2553C1.80312 10.9397 1.66054 9.49201 1.93835 8.09535C2.21616 6.69868 2.9019 5.41577 3.90883 4.40883C4.91577 3.40189 6.19869 2.71616 7.59535 2.43835C8.99201 2.16053 10.4397 2.30312 11.7553 2.84807C13.0709 3.39302 14.1954 4.31586 14.9866 5.49989C15.7777 6.68393 16.2 8.07597 16.2 9.5C16.2 11.4096 15.4414 13.2409 14.0912 14.5912C12.7409 15.9414 10.9096 16.7 9 16.7Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AttentionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AttentionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AttentionIcon)
