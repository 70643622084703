import styled from 'styled-components'

export const Container = styled.div`
  max-width: 290px;
  width: 290px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 3px 15px rgba(61, 61, 61, 0.3);
  background: ${({ theme }) => theme.colors.backgrounds.default};
  ${({ isExpanded }) => isExpanded && `
    max-width: 560px;
    width: 560px;
  `}
`

export const PartContainer = styled.div`
  width: 100%;
  height: 100%;
  ${({ isExpanded }) => isExpanded && `
    width: calc(50% - 7px);
  `}
`

export const PrimaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.colors.default};
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .svgFill,
  .svgStroke {
    height: 18px;
    margin-right: 10px;
  }
`
export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 156px;
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .svgFill,
  .svgStroke {
    height: 18px;
    width: 18px;
    fill: ${({ theme }) => theme.colors.colors.default};
  }
  margin-right: 5px;
  ${({ toggle, isExpanded }) => toggle && `
    transform-origin: 50% 50%;
    transform: ${isExpanded ? 'rotate(270deg)' : 'rotate(90deg)'};
    margin-right: 0px;
    svg {
      width: 14px;
    }
  `}
  cursor: pointer;
`

export const SecondaryTitle = styled.div`
  width: 100%;
  min-width: 100px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ value }) => value && `
    font-size: 1.4rem;
    line-height: 1.6rem;
    justify-content: flex-end;
    text-align: right;
  `}
  ${({ progressBar }) => progressBar && `
    padding-bottom: 0px;
  `}
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ flex }) => !flex && `
    flex: 1;
  `}
`
export const ElementContainer = styled.div`
  width: 100%;
  ${({ pt }) => pt && `
    margin-top: ${pt}px;
  `}
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  border-radius: 4px;
  display: flex;
  align-items: 100%;
  justify-content: space-between;
  ${({ progressBar }) => progressBar && `
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

  `}
  ${({ disabled }) => disabled && `
    ${SecondaryTitle} {
      opacity: 0.3;
    }
  `}
`

export const Block = styled.div`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${SecondaryTitle} {
    padding: 5px;
  }
`

export const ButtunContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  button {
    width: 88px;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Indicator = styled.div`
  position: relative;
  height: 5px;
  margin: 10px;
  width: calc(100% - 20px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.secondary};
  border-radius: 10px;
  &&:after {
    ${({ value, theme, messageType }) => value && `
      border-radius: 10px;
      position: absolute;
      top: 0px;
      left: 0px;
      content: ' ';
      width: ${value || 0}%;
      height: 5px;
      background: ${theme.colors.messages[messageType]};
    `}
  }
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
