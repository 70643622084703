import random from 'lodash/random'
import LiveTime from '@/components/icons/livetime'

export const fields = [
  {
    id: 'action',
    name: 'Действие',
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'object',
    name: 'Объект',
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'date',
    name: 'Дата',
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'time',
    name: 'Время',
    value: 'ASC',
    type: 'number',
  },
  {
    id: 'problem',
    name: 'Сбои в работе',
    value: 'ASC',
    type: 'string',
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    icon: LiveTime.PDFIcon,
  },
]

const actions = [
  'Проверка  состояния',
  'Заказ отчета',
  'Инсталляция',
  'Действия с оповещением',
]

export const generateTableData = (rows = 20) => {
  const data = []

  for (let i = 0; i < rows; i += 1) {
    data.push({
      id: i + 1,
      action: actions[random(0, 3)],
      object: `Осв.0${3451 + i}`,
      geoZone: 'Тюмень',
      date: '10.12.2018',
      time: '10:40',
      problem: 'Нет',
    })
  }

  return data
}

export const data = generateTableData()
export const activeFields = [
  'action',
  'object',
  'geoZone',
  'date',
  'time',
  'problem',
]

export default {
  fields,
  exportOptions,
  activeFields,
}
