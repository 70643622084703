import { DELETE_USER } from '../index'

export const beginDeleteUser = () => ({
  type: DELETE_USER.BEGIN,
})

export const endDeleteUser = () => ({
  type: DELETE_USER.END,
})

export const requestDeleteUser = (forms) => ({
  type: DELETE_USER.REQUEST,
  payload: forms,
})

export const responseDeleteUser = (user) => ({
  type: DELETE_USER.SUCCESS,
  payload: user,
})

export const errorDeleteUser = (error) => ({
  type: DELETE_USER.ERROR,
  payload: error,
})
