import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserProfileIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7436 9.31373C15.7436 4.1699 19.8878 0 25 0C30.1122 0 34.2564 4.1699 34.2564 9.31373V17.6471H36.6923C40.7282 17.6471 44 20.9391 44 25V42.6471C44 46.708 40.7282 50 36.6923 50H13.3077C9.27177 50 6 46.708 6 42.6471V25C6 20.9391 9.27177 17.6471 13.3077 17.6471H15.7436V9.31373ZM18.6667 17.6471H31.3333V9.31373C31.3333 5.79426 28.4978 2.94118 25 2.94118C21.5022 2.94118 18.6667 5.79426 18.6667 9.31373V17.6471ZM13.3077 20.5882C10.8861 20.5882 8.92308 22.5634 8.92308 25V42.6471C8.92308 45.0836 10.8861 47.0588 13.3077 47.0588H36.6923C39.1139 47.0588 41.0769 45.0836 41.0769 42.6471V25C41.0769 22.5634 39.1139 20.5882 36.6923 20.5882H13.3077ZM25 33.3333C24.1928 33.3333 23.5385 33.9917 23.5385 34.8039C23.5385 35.6161 24.1928 36.2745 25 36.2745C25.8072 36.2745 26.4615 35.6161 26.4615 34.8039C26.4615 33.9917 25.8072 33.3333 25 33.3333ZM20.6154 34.8039C20.6154 32.3674 22.5784 30.3922 25 30.3922C27.4216 30.3922 29.3846 32.3674 29.3846 34.8039C29.3846 36.7248 28.1645 38.359 26.4615 38.9646V42.6471C26.4615 43.4592 25.8072 44.1176 25 44.1176C24.1928 44.1176 23.5385 43.4592 23.5385 42.6471V38.9646C21.8355 38.359 20.6154 36.7248 20.6154 34.8039Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UserProfileIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserProfileIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserProfileIcon)
