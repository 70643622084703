import {
  SHUO,
  LIGHT,
  CONTROLLER,
  LINE,
  MULTIRATE_METER,
  LIGHT_FIXTURE,
  UTILITY_POLE,

  CONTROL_CUPBOARD_SERVER,
  BASE_STATION_SERVER,
  LIGHT_FIXTURE_SERVER,
  UTILITY_POLE_SERVER,
  LAMP_SERVER,
  AUTOMATED_CONTROL_SYSTEM_SERVER,
  MULTIRATE_METER_SERVER,
  CONTROLLER_SERVER,
  LINE_SERVER,
  LAMP,
  LAMP_MODULE,
  LAMP_MODULE_SERVER,
  CITY,
} from '@/constants/objectTypes'

export const INSTALLATION_SERVER_TO_LOCAL = {
  CONTROLLER: CONTROLLER,
  LIGHT: LIGHT,
  GEOZONE: CITY,
  OBJECT_ELEMENT: LIGHT,
}

export const LOCAL_TO_PASSPORT = {
  [LIGHT]: LIGHT_FIXTURE_SERVER,
  [CONTROLLER]: BASE_STATION_SERVER,
  [LAMP]: LAMP_SERVER,
  [SHUO]: CONTROL_CUPBOARD_SERVER,
  [UTILITY_POLE]: UTILITY_POLE_SERVER,
  [LIGHT_FIXTURE]: LAMP,
  [MULTIRATE_METER]: MULTIRATE_METER_SERVER,
  [CONTROLLER_SERVER]: CONTROLLER_SERVER,
  [AUTOMATED_CONTROL_SYSTEM_SERVER]: AUTOMATED_CONTROL_SYSTEM_SERVER,
  [LINE]: LINE_SERVER,
  [LAMP_MODULE]: LAMP_MODULE_SERVER,
}

export const SERVER_TO_LOCAL = {
  [LIGHT_FIXTURE_SERVER]: LIGHT,
  [BASE_STATION_SERVER]: CONTROLLER,
  [LAMP_SERVER]: LAMP,
  [CONTROL_CUPBOARD_SERVER]: SHUO,
  [UTILITY_POLE_SERVER]: UTILITY_POLE,
  [MULTIRATE_METER_SERVER]: MULTIRATE_METER,
  [AUTOMATED_CONTROL_SYSTEM_SERVER]: AUTOMATED_CONTROL_SYSTEM_SERVER,
  [CONTROLLER_SERVER]: CONTROLLER_SERVER,
  [LINE_SERVER]: LINE,
  [LAMP_MODULE_SERVER]: LAMP_MODULE,
}

export const KEY_TO_NAME = {
  [LIGHT_FIXTURE_SERVER]: 'Светоточка',
  [BASE_STATION_SERVER]: 'Базовая станция',
  [LAMP_SERVER]: 'Светильник',
  [CONTROL_CUPBOARD_SERVER]: 'ШУО',
  [UTILITY_POLE_SERVER]: 'Опора',
  [MULTIRATE_METER_SERVER]: 'Многотарифный счетчик',
  [CONTROLLER_SERVER]: 'Контроллер',
  [LINE_SERVER]: 'ЛЭП',
  [LAMP_MODULE_SERVER]: 'Модуль светильника',
}

export default LOCAL_TO_PASSPORT
