import {
  ATLANTIS_LIGHT,
  SELESTIAL_BLUE,
  SAFFRON,
  TURMERIC,
  PICTON_BLUE,
  DANUBE,
  ATLANTIS,
  SUSHI,
  SEA_BUCHTORN,
  RAW_SIENNA,
  WISTERIA,
  TRENDY_PINK,
  PHARLAP,
  SQUIRREL,
  RIO_GRANDE,
  LEMON_GINGER,
} from '@/constants/styles/defaultTheme'

export const seriesNamesAppeal = [
  {
    name: 'receivedAppealsAmount',
    title: 'Поступившие',
    fill: SELESTIAL_BLUE,
  },
  {
    name: 'solvedAppealsAmount',
    title: 'Решенные',
    fill: ATLANTIS_LIGHT,
  },
]

export const seriesNamesDashboard = [
  {
    name: 'actualConsumption',
    title: 'фактическое потребление',
    fill: SELESTIAL_BLUE,
  },
  {
    name: 'plannedConsumption',
    title: 'плановое потребление',
    fill: ATLANTIS_LIGHT,
  },
]

export const seriesNamesAverage = [
  {
    name: 'avgDays',
    title: 'Среднее вермя принятия и рассмотрения решений',
    fill: SELESTIAL_BLUE,
  },
]

export const seriesNameSettings = {
  outDoorLightning: {
    fill: SAFFRON,
    fill2: TURMERIC,
  },
  publicTransport: {
    fill: PICTON_BLUE,
    fill2: DANUBE,
  },
  repair: {
    fill: ATLANTIS,
    fill2: SUSHI,
  },
}

export const seriesStatusSettings = {
  planned: {
    fill: SEA_BUCHTORN,
    fill2: RAW_SIENNA,
  },
  solved: {
    fill: WISTERIA,
    fill2: TRENDY_PINK,
  },
  motivation: {
    fill: PHARLAP,
    fill2: SQUIRREL,
  },
  unConfirm: {
    fill: RIO_GRANDE,
    fill2: LEMON_GINGER,
  },
}