import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import { DATE_FORMAT } from '@/constants/forms/analyticExpenses'
import SelectControl from '@/components/controls/SelectControl'
import MapIcons from '@/components/icons/maps'
import 'react-datepicker/dist/react-datepicker.css'

import CustomInput from './components/CustomInput'
import {
  yearsOptions,
  monthsOptions,
} from './config'
import {
  FieldContainer,
  HeaderContainer,
  IconContainer,
  SelectContainer,
} from './styles'

const CustomDatePicker = ({
  format,
  value,
  onChange,
  maxDate,
  minDate,
  ...props
}) => {
  const renderCustomHeader = useCallback(({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
  }) => (
    <HeaderContainer>
      <IconContainer>
        <MapIcons.ArrowIcon onClick={decreaseMonth} />
      </IconContainer>
      <SelectContainer>
        <SelectControl
          value={getYear(date)}
          onChange={(year) => changeYear(year)}
          options={yearsOptions}
        />
      </SelectContainer>
      <SelectContainer>
        <SelectControl
          value={monthsOptions[getMonth(date)].title}
          onChange={(month) => changeMonth(month)}
          options={monthsOptions}
        />
      </SelectContainer>
      <IconContainer rotate>
        <MapIcons.ArrowIcon onClick={increaseMonth} />
      </IconContainer>
    </HeaderContainer>
  ), [])

  return (
    <FieldContainer {...props}>
      <DatePicker
        customInput={<CustomInput />}
        renderCustomHeader={renderCustomHeader}
        showMonthDropdown
        showYearDropdown
        dateFormat={format}
        locale={ru}
        selected={value}
        onChange={onChange}
        maxDate={maxDate}
        minDate={minDate}
      />
    </FieldContainer>
  )
}

CustomDatePicker.propTypes = {
  format: pt.string,
  value: pt.string,
  onChange: pt.func,
}
CustomDatePicker.defaultProps = {
  format: DATE_FORMAT,
  value: null,
  onChange: noop,
}

export default CustomDatePicker
