import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const monthNames = [
  { value: 0, title: <Lang id="datePicker.months.january" /> },
  { value: 1, title: <Lang id="datePicker.months.february" /> },
  { value: 2, title: <Lang id="datePicker.months.march" /> },
  { value: 3, title: <Lang id="datePicker.months.april" /> },
  { value: 4, title: <Lang id="datePicker.months.may" /> },
  { value: 5, title: <Lang id="datePicker.months.june" /> },
  { value: 6, title: <Lang id="datePicker.months.july" /> },
  { value: 7, title: <Lang id="datePicker.months.august" /> },
  { value: 8, title: <Lang id="datePicker.months.september" /> },
  { value: 9, title: <Lang id="datePicker.months.october" /> },
  { value: 10, title: <Lang id="datePicker.months.november" /> },
  { value: 11, title: <Lang id="datePicker.months.december" /> },
]

export const weekLabel = [
  <Lang id="datePicker.days.monday" />,
  <Lang id="datePicker.days.tuesday" />,
  <Lang id="datePicker.days.wednesday" />,
  <Lang id="datePicker.days.thursday" />,
  <Lang id="datePicker.days.friday" />,
  <Lang id="datePicker.days.saturday" />,
  <Lang id="datePicker.days.sunday" />,
]
