import React, { useCallback, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { FormattedMessage as Lang } from 'react-intl'
import UsersIcons from '@/components/icons/users'
import {
  Content,
  CustomTextControl,
  CustomButton,
} from './styles'

const Header = ({ query }) => {
  const { pathname, search } = useLocation()
  const { push } = useHistory()
  const parsedSearch = qs.parse(search)
  const [searchQuery, setSearchQuery] = useState(query || '')

  const handleSetSearch = useCallback(() => {
    push({
      pathname,
      search: qs.stringify({ ...parsedSearch, query: searchQuery }),
    })
  }, [push, searchQuery, pathname, parsedSearch])

  return (
    <Content>
      <CustomTextControl
        dark
        placeholder="Поиск"
        name="search"
        icon={UsersIcons.MagnifierIcon}
        value={searchQuery}
        onChange={setSearchQuery}
      />
      <CustomButton onClick={handleSetSearch}>
        <Lang id="tyumenOurHome.find" />
      </CustomButton>
    </Content>
  )
}

export default Header
