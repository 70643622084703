import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DaylightIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26 38C32.6274 38 38 32.6274 38 26C38 19.3726 32.6274 14 26 14C19.3726 14 14 19.3726 14 26C14 32.6274 19.3726 38 26 38Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" />
      <path d="M26 31.8788C27.5376 30.3095 28.4 28.1992 28.4 26C28.4 23.8008 27.5376 21.6905 26 20.1212V20C27.5913 20 29.1174 20.6321 30.2426 21.7574C31.3679 22.8826 32 24.4087 32 26C32 27.5913 31.3679 29.1174 30.2426 30.2426C29.1174 31.3679 27.5913 32 26 32V31.8788Z" fill={fill} className="svgFill" />
      <path d="M26 29C25.2044 29 24.4413 28.6839 23.8787 28.1213C23.3161 27.5587 23 26.7956 23 26C23 25.2044 23.3161 24.4413 23.8787 23.8787C24.4413 23.3161 25.2043 23 26 23L26 29Z" fill={fill} className="svgFill" />
      <path d="M22 26C22 26.5523 21.5523 27 21 27C20.4477 27 20 26.5523 20 26C20 25.4477 20.4477 25 21 25C21.5523 25 22 25.4477 22 26Z" fill={fill} className="svgFill" />
      <path d="M23 23C23 23.5523 22.5523 24 22 24C21.4477 24 21 23.5523 21 23C21 22.4477 21.4477 22 22 22C22.5523 22 23 22.4477 23 23Z" fill={fill} className="svgFill" />
      <path d="M25 21C25 21.5523 24.5523 22 24 22C23.4477 22 23 21.5523 23 21C23 20.4477 23.4477 20 24 20C24.5523 20 25 20.4477 25 21Z" fill={fill} className="svgFill" />
      <path d="M23 29C23 29.5523 22.5523 30 22 30C21.4477 30 21 29.5523 21 29C21 28.4477 21.4477 28 22 28C22.5523 28 23 28.4477 23 29Z" fill={fill} className="svgFill" />
      <path d="M25 31C25 31.5523 24.5523 32 24 32C23.4477 32 23 31.5523 23 31C23 30.4477 23.4477 30 24 30C24.5523 30 25 30.4477 25 31Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DaylightIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DaylightIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DaylightIcon)
