import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generatePutAdmin } from '@/constants/apiRoutes'
import { MULTIPLE_DELETE, setUsersSort } from '@/store/actions/users'
import {
  responseUsersMultipleDelete,
  errorUsersMultipleDelete,
} from '@/store/actions/users/user/multipleDelete'
import { requestGetAllUsers } from '@/store/actions/users/user/getAllUsers'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { requestGetTenantGroup } from '@/store/actions/users/group/getGroup'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import createNotifications from '@/helpers/notification'
import toastErrorHelper from '@/helpers/toastHelper'
import {
  getUserSortOrder,
  getUserSortBy,
} from '@/store/selectors/users'

function* multipleDeleteSaga({ payload }) {
  const { selectedIds, tenantId, groupId } = payload
  const toast = createNotifications()

  try {
    const usersIds = selectedIds.join(',')
    yield request({
      url: generatePutAdmin(usersIds),
      method: 'delete',
    })

    toast({
      title: 'Успешная операция!',
      description: 'Пользователи успешно удалены.',
    })

    yield put(requestGetTenantGroups(tenantId))
    yield put(requestGetTenantGroup({ tenantId, groupId }))
    yield put(requestGetAllAdminTenants({ id: tenantId }))
    yield put(requestGetAllUsers({ tenantId, groupId }))
    yield put(requestGetAllTenants())

    yield put(responseUsersMultipleDelete())
    const sortValue = yield select(getUserSortOrder)
    const sortType = yield select(getUserSortBy)
    yield put(setUsersSort({
      sortValue,
      sortType,
    }))
  } catch (error) {
    yield put(errorUsersMultipleDelete())
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при удалении выбранных пользователей! Повторите попытку позже. Код ошибки: ${toastErrorHelper(error)}`,
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(MULTIPLE_DELETE.REQUEST, multipleDeleteSaga)
}
