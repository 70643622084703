import styled from 'styled-components'
import RadioButton from '@/components/controls/RadioButton'
import CheckBox from '@/components/controls/CheckBox'
import { GRAPH_YEAR } from './config'

export const CheckBoxStyled = styled(CheckBox)`
  margin-right: 10px;
  cursor: pointer;
`

export const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`

export const RadioButtonStyled = styled(RadioButton)`
  margin-right: 10px;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const Header = styled.div`
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  margin-bottom: 10px;
`

export const HeaderTitle = styled.div`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.colors.disabled};
`

export const HeaderButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme, border }) => `
    border-${border || 'left'}: 1px solid ${theme.colors.borders.default};
  `};
  svg {
    transform: rotate(-90deg);
  }
`
export const Title = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.charts};
  text-align: start;
  width: 300px;
  ${({ main, theme }) => main && `
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.colors.title};
    margin-left: 15px;
  `};
  ${({ graphTitle, theme }) => graphTitle && `
    position: absolute;
    color: ${theme.colors.white};
    width: auto;
    left: 38px;
    z-index: 1;
  `};
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 15px;
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: 25px;
  `};
`

export const GraphLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  opacity: 0.4;
  ${({ color }) => `
    background: ${color};
  `};
  ${({ active }) => active && `
    opacity: 1;
  `};
  ${({ uniqueKey, widthValue }) => `
    animation: changeWidth-${uniqueKey} 1s ease-in-out 1 forwards;

    @keyframes changeWidth-${uniqueKey} {
      0% {
        width: 4px;
      }
      100% {
        width: ${(widthValue ? `${widthValue}%` : '4px')};
      }
    }
  `}
`

export const GraphResult = styled.div`
  display: flex;
  height: 70px;
  width: 100%;
  position: relative;
  align-items: center;
  ${({ color }) => `
    background: ${color};
  `};
  ${({ graphType }) => graphType === GRAPH_YEAR && `
    height: 49px;
  `};
  &:hover {
    ${GraphLine} {
      opacity: 1;
    }
  }
`
