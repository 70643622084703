export const GRAPH_PERCENT = 'graphPercent'
export const GRAPH_YEAR = 'graphYear'

export const graphConfig = {
  [GRAPH_PERCENT]: [
    {
      id: 0,
      mainColor: '#8F3D3C',
      secondaryColor: '#CC0A00',
      text: '> 100% (10)',
      indicatorWidth: 10,
    },
    {
      id: 1,
      mainColor: '#8F523D',
      secondaryColor: '#CC3500',
      text: '90% - 100% (20)',
      indicatorWidth: 20,
    },
    {
      id: 2,
      mainColor: '#8F683D',
      secondaryColor: '#CC6A00',
      text: '80% - 90% (10)',
      indicatorWidth: 15,
    },
    {
      id: 3,
      mainColor: '#8F7D3D',
      secondaryColor: '#CC9F00',
      text: '70% - 80% (30)',
      indicatorWidth: 10,
    },
    {
      id: 4,
      mainColor: '#8C8F3D',
      secondaryColor: '#C4CB01',
      text: '60% - 70% (40)',
      indicatorWidth: 10,
    },
    {
      id: 5,
      mainColor: '#768F3D',
      secondaryColor: '#8FCC00',
      text: '50% - 60% (30)',
      indicatorWidth: 10,
    },
    {
      id: 6,
      mainColor: '#618F3D',
      secondaryColor: '#5ACC00',
      text: '40% - 50% (20)',
      indicatorWidth: 10,
    },
    {
      id: 7,
      mainColor: '#4C8F3D',
      secondaryColor: '#38CC00',
      text: '30% - 40% (10)',
      indicatorWidth: 10,
    },
    {
      id: 8,
      mainColor: '#3D8F44',
      secondaryColor: '#38CC0F',
      text: '20% - 30% (30)',
      indicatorWidth: 10,
    },
    {
      id: 9,
      mainColor: '#3D8F59',
      secondaryColor: '#37CC44',
      text: '10% - 20% (40)',
      indicatorWidth: 10,
    },
    {
      id: 10,
      mainColor: '#3D8F6E',
      secondaryColor: '#36CC7A',
      text: '0% - 10% (50)',
      indicatorWidth: 10,
    },
    {
      id: 11,
      mainColor: '#828D99',
      secondaryColor: '#BEC2CC',
      text: 'Неизвестно (25)',
      indicatorWidth: 10,
    },
  ],
  [GRAPH_YEAR]: [
    {
      id: 12,
      mainColor: '#894947',
      secondaryColor: '#BD2018',
      text: '> 15 (30)',
      indicatorWidth: 10,
    },
    {
      id: 13,
      mainColor: '#8A5147',
      secondaryColor: '#BF3319',
      text: '14 - 15 (10)',
      indicatorWidth: 10,
    },
    {
      id: 14,
      mainColor: '#8B5848',
      secondaryColor: '#C1421A',
      text: '13 - 14 (20)',
      indicatorWidth: 10,
    },
    {
      id: 15,
      mainColor: '#8B5F48',
      secondaryColor: '#C4541B',
      text: '12 - 13 (20)',
      indicatorWidth: 10,
    },
    {
      id: 16,
      mainColor: '#8C6549',
      secondaryColor: '#C6661D',
      text: '11 - 12 (150)',
      indicatorWidth: 10,
    },
    {
      id: 17,
      mainColor: '#8D6C49',
      secondaryColor: '#C8761E',
      text: '10 - 11 (90)',
      indicatorWidth: 10,
    },
    {
      id: 18,
      mainColor: '#8E7349',
      secondaryColor: '#CA851F',
      text: '9 - 10 (80)',
      indicatorWidth: 10,
    },
    {
      id: 19,
      mainColor: '#8F794A',
      secondaryColor: '#CD9720',
      text: '8 - 9 (40)',
      indicatorWidth: 10,
    },
    {
      id: 20,
      mainColor: '#90804A',
      secondaryColor: '#CFA821',
      text: '7 - 8 (30)',
      indicatorWidth: 10,
    },
    {
      id: 21,
      mainColor: '#89824E',
      secondaryColor: '#BFAC2A',
      text: '6 - 7 (80)',
      indicatorWidth: 10,
    },
    {
      id: 22,
      mainColor: '#828452',
      secondaryColor: '#A9B237',
      text: '5 - 6 (50)',
      indicatorWidth: 10,
    },
    {
      id: 23,
      mainColor: '#7B8657',
      secondaryColor: '#94B744',
      text: '4 - 5 (90)',
      indicatorWidth: 10,
    },
    {
      id: 24,
      mainColor: '#73875B',
      secondaryColor: '#83BA4D',
      text: '3 - 4 (100)',
      indicatorWidth: 10,
    },
    {
      id: 25,
      mainColor: '#6C895F',
      secondaryColor: '#75BE55',
      text: '2 - 3 (150)',
      indicatorWidth: 10,
    },
    {
      id: 26,
      mainColor: '#658B64',
      secondaryColor: '#64C260',
      text: '1 - 2 (10)',
      indicatorWidth: 10,
    },
    {
      id: 27,
      mainColor: '#5D8C68',
      secondaryColor: '#50C66B',
      text: '0 - 1 (20)',
      indicatorWidth: 10,
    },
    {
      id: 28,
      mainColor: '#828D99',
      secondaryColor: '#BEC2CC',
      text: 'Неизвестно (25)',
      indicatorWidth: 10,
    },
  ],
}
