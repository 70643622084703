import {
  takeLatest, put, all, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import { GET_TYUMEN_GRAPHS } from '@/store/actions/tyumenOurHome'
import {
  TYUMEN_OUR_HOME_GRAPHS_STATUSES_URL,
  TYUMEN_OUR_HOME_GRAPHS_TOPICS_URL,
  TYUMEN_OUR_HOME_GRAPHS_STATISTIC_URL,
  TYUMEN_OUR_HOME_GRAPHS_TIME_URL,
} from '@/constants/apiRoutes'
import {
  successGetTyumenGraphs,
  errorGetTyumenGraphs,
} from '@/store/actions/tyumenOurHome/getGraphs'
import {
  START_DATE,
  END_DATE,
  URBAN_PROCESSES,
  CHART_TYPE,
  PERIOD,
  INTERVAL,
  APPEAL_EARLIEST_DATE,
  RESPONSE_EARLIEST_DATE,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  ALL_PERIOD,
  APPEAL_STATUSES,
  APPEAL_TOPICS,
  APPEAL_STATISTIC,
  APPEAL_TIME,
} from '@/constants/tyumenOurHome'
import {
  getDateGraph,
} from '@/store/selectors/tyumenOurHome'

const config = {
  'Проблема не обнаружена': 'unConfirm',
  'Мотивированный отказ': 'motivation',
  'Проблема решена': 'solved',
  'Запланировано': 'planned',
  'Ремонт и реконструкция дорог': 'repair',
  'Остановки общественного транспорта': 'publicTransport',
  'Наружное освещение': 'outDoorLightning',
}

const formatValueToNumber = (data, keysArray) => data.map((element) => {
  const formatedValues = keysArray.reduce((accumulator, key) => {
    return {
      ...accumulator,
      ...element[key] ? { [key]: Number.parseFloat(element[key]) } : {},
    }
  },{})
  return {
    ...element,
    ...formatedValues,
  }
})

const sortResponse = (response) => response.map((element) => ({
  name: element.segmentName,
  value: element.appealsAmount,
  id: config[element.segmentName],
}))

const urlGenerator = ({ url, payload, firstDate }) => ({
  url,
  method: 'post',
  body: {
    startDate: payload[PERIOD] === ALL_PERIOD && !payload[START_DATE] ? firstDate : moment(payload[START_DATE]).format('YYYY-MM-DD'),
    endDate: payload[PERIOD] === ALL_PERIOD && !payload[END_DATE] ? moment().format('YYYY-MM-DD') : moment(payload[END_DATE]).format('YYYY-MM-DD'),
    appealType: payload[URBAN_PROCESSES],
    dateStepType: payload[INTERVAL],
  },
})

function* getMessagesList({ payload }) {
  try {
    const date = yield select(getDateGraph)
    const statusGraph = urlGenerator({
      url: TYUMEN_OUR_HOME_GRAPHS_STATUSES_URL,
      payload,
      firstDate: date[payload[URBAN_PROCESSES]][RESPONSE_EARLIEST_DATE],
    })
    const topicsGraph = urlGenerator({
      url: TYUMEN_OUR_HOME_GRAPHS_TOPICS_URL,
      payload,
      firstDate: date[payload[URBAN_PROCESSES]][APPEAL_EARLIEST_DATE],
    })
    const statisticGraph = urlGenerator({
      url: TYUMEN_OUR_HOME_GRAPHS_STATISTIC_URL,
      payload,
      firstDate: date[payload[URBAN_PROCESSES]][APPEAL_EARLIEST_DATE],
    })
    const timeGraph = urlGenerator({
      url: TYUMEN_OUR_HOME_GRAPHS_TIME_URL,
      payload,
      firstDate: date[payload[URBAN_PROCESSES]][RESPONSE_EARLIEST_DATE],
    })
    switch (payload[CHART_TYPE]) {
      case APPEAL_STATUSES: {
        const response = yield request(statusGraph)
        yield put(successGetTyumenGraphs({
          APPEAL_STATUSES: sortResponse(response.data.segments),
        }))
        break
      }
      case APPEAL_TOPICS: {
        const response = yield request(topicsGraph)
        yield put(successGetTyumenGraphs({
          APPEAL_TOPICS: sortResponse(response.data.segments),
        }))
        break
      }
      case APPEAL_STATISTIC: {
        const response = yield request(statisticGraph)
        yield put(successGetTyumenGraphs({
          APPEAL_STATISTIC: response.data.steps,
        }))
        break
      }
      case APPEAL_TIME: {
        const response = yield request(timeGraph)
        yield put(successGetTyumenGraphs({
          APPEAL_TIME: formatValueToNumber(response.data.steps, ['avgDays']),
        }))
        break
      }
      default: {
        const responseAllTime = yield all({
          [APPEAL_STATUSES]: request(statusGraph),
          [APPEAL_TOPICS]: request(topicsGraph),
          [APPEAL_STATISTIC]: request(statisticGraph),
          [APPEAL_TIME]: request(timeGraph),
        })
        yield put(successGetTyumenGraphs({
          [APPEAL_STATUSES]: sortResponse(responseAllTime[APPEAL_STATUSES].data.segments),
          [APPEAL_TOPICS]: sortResponse(responseAllTime[APPEAL_TOPICS].data.segments),
          [APPEAL_STATISTIC]: responseAllTime[APPEAL_STATISTIC].data.steps,
          [APPEAL_TIME]: formatValueToNumber(responseAllTime[APPEAL_TIME].data.steps, ['avgDays']),
        }))
        break
      }
    }
  } catch (error) {
    yield put(errorGetTyumenGraphs(error))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_TYUMEN_GRAPHS.REQUEST, getMessagesList)
}
