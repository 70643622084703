import React, { useCallback } from 'react'
import memoWithName from '@/hocs/memoWithName'
import {
  IndicatorWrapper,
} from '@/components/widgets/StateIndicator/styles'
import CoreIcons from '@/components/icons/core'
import {
  IconContainer,
  Text,
} from './styles'

const TrashIndicator = ({
  className,
  fillingLevel,
  temperature,
  mockValue,
}) => {
  const calculateIndicatorHeight = useCallback((maxHeight, value, maxValue) => (
    (((maxHeight * value) / maxValue) || 0).toFixed(0)
  ), [])
  return (
    <IndicatorWrapper
      column
      small
      className={className}
      withoutSvgStyle
    >
      <Text>
        {temperature || mockValue.temperature}
        °C
      </Text>
      <IconContainer
        thermometer
        height={calculateIndicatorHeight(105, temperature || mockValue.temperature, 50)}
      >
        <CoreIcons.ThermometerIcon />
      </IconContainer>
      <IconContainer
        trash
        value={fillingLevel || mockValue.fillingLevel || 0}
        height={calculateIndicatorHeight(107, fillingLevel || mockValue.fillingLevel || 0, 100)}
      >
        <CoreIcons.TrashCanIcon />
      </IconContainer>
    </IndicatorWrapper>
  )
}

TrashIndicator.propTypes = {
}
TrashIndicator.defaultProps = {
}

export default memoWithName(TrashIndicator)
