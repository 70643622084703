import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import TextField from '@/components/fields/TextField'
import TextAreaField from '@/components/fields/TextAreaField'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import CheckBoxField from '@/components/fields/CheckBoxField'

import { editUserValidationScheme } from '@/constants/validationFields'
import {
  ADMIN_NAME,
  ADMIN_SURNAME,
  ADMIN_PATRONYMIC,
  ADMIN_TELEPHON,
  ADMIN_EMAIL,
  ADMIN_POSITION,
  ADMIN_ABOUT_ME,
  ADMIN_PHOTO,
  ADMIN_BLOCK,
  ADMIN_PHOTO_RESOLUTION,
} from '@/constants/forms/admin'
import {
  FormWrapper,
  FieldsWrapper,
  InputAndLabelContainer,
  Label,
  MessageContainer,
  Text,
} from './styles'

const EditAdminForm = React.forwardRef(({
  formData,
  blockForm,
  disableFields,
  type,
  setValidForm,
  setDirty,
  canBlock,
  isFormModifying,
}, ref) => {
  const renderTextFiled = useCallback(({
    error,
    name,
    mask,
    fieldProps,
    cols,
    rows,
    charLimit,
    disabled,
    value,
  }) => {
    const regex = /(\d?)(\d{3})(\d{3})(\d{2})(\d{2})/g
    const phoneExample = '$1 ($2) $3-$4-$5'
    if (isFormModifying && name !== ADMIN_ABOUT_ME) {
      return (
        <TextField
          error={error}
          name={name}
          mask={mask}
          fieldProps={fieldProps}
          resetButton
        />
      )
    }
    if (name === ADMIN_TELEPHON && value) {
      return (
        <Text>
          {value.replace(regex, phoneExample)}
        </Text>
      )
    }
    if (name === ADMIN_ABOUT_ME && isFormModifying) {
      return (
        <TextAreaField
          name={ADMIN_ABOUT_ME}
          cols={cols}
          rows={rows}
          charLimit={charLimit}
          disabled={disabled}
          fieldProps={fieldProps}
        />
      )
    }
    return (
      <Text>
        {value}
      </Text>
    )
  }, [isFormModifying])

  return (
    <Formik
      ref={ref}
      initialValues={{
        [ADMIN_NAME]: formData[ADMIN_NAME] || '',
        [ADMIN_SURNAME]: formData[ADMIN_SURNAME] || '',
        [ADMIN_PATRONYMIC]: formData[ADMIN_PATRONYMIC] || '',
        [ADMIN_TELEPHON]: formData[ADMIN_TELEPHON] || '',
        [ADMIN_EMAIL]: formData[ADMIN_EMAIL] || '',
        [ADMIN_POSITION]: formData[ADMIN_POSITION] || '',
        [ADMIN_ABOUT_ME]: formData[ADMIN_ABOUT_ME] || '',
        [ADMIN_PHOTO]: formData[ADMIN_PHOTO] || null,
        [ADMIN_BLOCK]: formData[ADMIN_BLOCK] || false,
        [ADMIN_PHOTO_RESOLUTION]: true,
      }}
      validationSchema={formData.isMe ? editUserValidationScheme : null}
      render={({
        touched, errors, handleSubmit, isSubmitting, dirty, values,
      }) => {
        setDirty(dirty)
        setValidForm(errors)
        return (
          <FormWrapper onSubmit={handleSubmit} id="userData" name="userData">
            <FieldsWrapper>
              {/* {formData.isMe && isFormModifying && (
                <ButtonContainer>
                  <InputFileField
                    name={ADMIN_PHOTO}
                    title={values[ADMIN_PHOTO]
                      ? <Lang id="usersPage.buttons.delete" />
                      : <Lang id="usersPage.buttons.download" />}
                    icon={values[ADMIN_PHOTO]
                      ? <Icons.TrashIcon />
                      : <Icons.PlusIcon />}
                    fieldProps={{
                      disabled: isSubmitting || blockForm,
                    }}
                  />
                </ButtonContainer>
              )} */}
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_SURNAME] && errors[ADMIN_SURNAME])}
                  title={(<Lang id="usersPage.editAdmin.surname" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_SURNAME,
                  fieldProps: {
                    pattern: '[a-zA-Zа-яА-Я]',
                    autoComplete: 'off',
                    disabled: isSubmitting || blockForm,
                  },
                  value: values[ADMIN_SURNAME],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_NAME] && errors[ADMIN_NAME])}
                  title={(<Lang id="usersPage.editAdmin.name" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_NAME,
                  fieldProps: {
                    pattern: '[a-zA-Zа-яА-Я]',
                    autoComplete: 'off',
                    disabled: isSubmitting || blockForm,
                  },
                  value: values[ADMIN_NAME],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_PATRONYMIC] && errors[ADMIN_PATRONYMIC])}
                  title={(<Lang id="usersPage.editAdmin.patronymic" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_PATRONYMIC,
                  fieldProps: {
                    pattern: '[a-zA-Zа-яА-Я]',
                    autoComplete: 'off',
                    disabled: isSubmitting || blockForm,
                  },
                  value: values[ADMIN_PATRONYMIC],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_TELEPHON] && errors[ADMIN_TELEPHON])}
                  title={(<Lang id="usersPage.editAdmin.telephone" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_TELEPHON,
                  mask: '7-999-999-99-99',
                  fieldProps: {
                    pattern: '[0-9]',
                    autoComplete: 'off',
                    disabled: isSubmitting || blockForm,
                  },
                  value: values[ADMIN_TELEPHON],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_EMAIL] && errors[ADMIN_EMAIL])}
                  title={(<Lang id="usersPage.editAdmin.email" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_EMAIL,
                  fieldProps: {
                    autoComplete: 'off',
                    disabled: isSubmitting || blockForm || disableFields,
                  },
                  value: values[ADMIN_EMAIL],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_POSITION] && errors[ADMIN_POSITION])}
                  title={(<Lang id="usersPage.editAdmin.position" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_POSITION,
                  fieldProps: {
                    pattern: '[a-zA-Zа-яА-Я ]',
                    autoComplete: 'off',
                    disabled: isSubmitting || blockForm,
                  },
                  value: values[ADMIN_POSITION],
                })}
              </InputAndLabelContainer>
              <InputAndLabelContainer>
                <LabelWithIcon
                  isError={(touched[ADMIN_ABOUT_ME] && errors[ADMIN_ABOUT_ME])}
                  title={(<Lang id="usersPage.editAdmin.aboutMe" />)}
                  userForm
                />
                {renderTextFiled({
                  name: ADMIN_ABOUT_ME,
                  cols: '40',
                  rows: '5',
                  charLimit: 256,
                  disabled: isSubmitting || blockForm,
                  fieldProps: {
                    autoComplete: 'off',
                  },
                  value: values[ADMIN_ABOUT_ME],
                })}
              </InputAndLabelContainer>
              {!formData.isMe && canBlock && isFormModifying && (
                <InputAndLabelContainer row>
                  <CheckBoxField name={ADMIN_BLOCK} marginRight />
                  <Label>
                    <Lang id="usersPage.editAdmin.block" />
                  </Label>
                </InputAndLabelContainer>
              )}
            </FieldsWrapper>
            {formData.isMe && (
              <MessageContainer>
                <Lang id="usersPage.createAdmin.messages.default" />
              </MessageContainer>
            )}
          </FormWrapper>
        )
      }}
    />
  )
})

EditAdminForm.propTypes = {
  formData: pt.shape({
    [ADMIN_NAME]: pt.string,
    [ADMIN_SURNAME]: pt.string,
    [ADMIN_PATRONYMIC]: pt.string,
    [ADMIN_TELEPHON]: pt.string,
    [ADMIN_EMAIL]: pt.string,
    [ADMIN_POSITION]: pt.string,
    [ADMIN_ABOUT_ME]: pt.string,
    isMe: pt.bool,
  }),
  blockForm: pt.bool,
  disableFields: pt.bool,
  isMe: pt.bool,
  setDirty: pt.func,
  type: pt.string,
  canBlock: pt.bool,
  setValidForm: pt.func,
}

EditAdminForm.defaultProps = {
  formData: {
    [ADMIN_NAME]: '',
    [ADMIN_SURNAME]: '',
    [ADMIN_PATRONYMIC]: '',
    [ADMIN_TELEPHON]: '',
    [ADMIN_EMAIL]: '',
    [ADMIN_POSITION]: '',
    [ADMIN_ABOUT_ME]: '',
  },
  blockForm: false,
  disableFields: false,
  setDirty: noop,
  isMe: false,
  type: null,
  canBlock: false,
  setValidForm: noop,
}

export default React.memo(EditAdminForm)
