import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LifeIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4546 14.8191H16.6818V17.1287L18.6764 18.2806L18.0628 19.3434L15.4546 17.8372V14.8191ZM18.7272 10.7269H7.63625V19.7263H11.0965C10.5895 18.6584 10.4254 17.4598 10.6267 16.2949C10.8281 15.1301 11.3849 14.056 12.2209 13.2202C13.0569 12.3844 14.131 11.8278 15.2959 11.6268C16.4608 11.4257 17.6594 11.59 18.7272 12.0972V10.7269ZM7.63625 21.3625C7.20229 21.3625 6.7861 21.1901 6.47925 20.8833C6.17239 20.5764 6 20.1602 6 19.7263L6.00818 8.2725C6.00818 7.36847 6.73059 6.63625 7.63625 6.63625H8.45438V5H10.0906V6.63625H16.2728V5H17.909V6.63625H18.7272C19.1611 6.63625 19.5773 6.80864 19.8842 7.1155C20.191 7.42236 20.3634 7.83854 20.3634 8.2725V13.2647C21.4196 14.3424 22.0077 15.7934 22 17.3023C21.9923 18.8113 21.3894 20.2562 20.3223 21.323C19.2552 22.3899 17.8102 22.9925 16.3012 22.9999C14.7923 23.0073 13.3414 22.4189 12.264 21.3625H7.63625ZM16.2728 13.3089C15.2213 13.3089 14.2129 13.7266 13.4694 14.4701C12.7258 15.2136 12.3081 16.222 12.3081 17.2735C12.3081 18.325 12.7258 19.3334 13.4694 20.0769C14.2129 20.8205 15.2213 21.2382 16.2728 21.2382C17.3243 21.2382 18.3327 20.8205 19.0762 20.0769C19.8197 19.3334 20.2374 18.325 20.2374 17.2735C20.2374 16.222 19.8197 15.2136 19.0762 14.4701C18.3327 13.7266 17.3243 13.3089 16.2728 13.3089Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LifeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LifeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LifeIcon)
