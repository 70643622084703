import * as yup from 'yup'
import {
  PASSWORD,
  PASSWORD_CONFIRMATION,
} from '@/constants/forms/signUp'

export const PASSWORD_CHECK = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.|,:;?!*+%-<>@\[\]{}/_$#]).{8,}/

export const signUpValidation = yup
  .object()
  .shape({
    [PASSWORD]: yup
      .string()
      .required('Введите пароль')
      .min(8, 'Длина пароля не менее 8 символов')
      .matches(
        PASSWORD_CHECK,
        'Пароль не соответствует требованиям',
      ),
    [PASSWORD_CONFIRMATION]: yup
      .string()
      .required('Введите пароль')
      .min(8, 'Длина пароля не менее 8 символов')
      .oneOf([yup.ref(PASSWORD), null], 'Пароли не совпадают'),
  })
