import {
  TEXT,
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.LATITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.LONGITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
    pattern: '[0-9.+-]',
  },
  {
    selector: 'LOCATION.REGION',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.CITY',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.STREET',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'LOCATION.HOME_NUMBER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'LOCATION',
  },
  {
    selector: 'SERVICING.BYPASS_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.MAINTENANCE_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.REPAIR_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
  {
    selector: 'SERVICING.GUARANTEE_EXPIRATION_DATE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'SERVICING',
  },
]
