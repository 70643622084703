import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const AnalyticsExpensesIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M1.63638 10.6666L2.0602 10.7233L5.783 6.9316C5.7181 6.6846 5.7098 6.4258 5.7588 6.1751C5.8078 5.9244 5.9128 5.6886 6.0656 5.4858C6.2184 5.2831 6.415 5.1188 6.6401 5.0058C6.8651 4.89274 7.1127 4.83395 7.3637 4.83395C7.6147 4.83395 7.8623 4.89274 8.0873 5.0058C8.3124 5.1188 8.509 5.2831 8.6618 5.4858C8.8146 5.6886 8.9196 5.9244 8.9686 6.1751C9.0176 6.4258 9.0093 6.6846 8.9444 6.9316L11.0308 9.0566L11.4546 8.9999C11.6019 8.9999 11.7435 9.0199 11.8785 9.0566L14.783 6.0982C14.6898 5.7438 14.7139 5.368 14.8518 5.029C14.9896 4.68999 15.2334 4.4068 15.5454 4.22333C15.8573 4.03986 16.22 3.96637 16.5772 4.01426C16.9343 4.06214 17.266 4.22872 17.5207 4.48817C17.7754 4.74761 17.939 5.0854 17.986 5.4492C18.033 5.813 17.9609 6.1824 17.7807 6.5001C17.6006 6.8178 17.3226 7.0662 16.9897 7.2066C16.6569 7.3469 16.2879 7.3716 15.94 7.2766L13.0354 10.2349C13.0956 10.4641 13.1071 10.7036 13.0691 10.9377C13.0311 11.1717 12.9446 11.3947 12.8152 11.592C12.6858 11.7892 12.5165 11.956 12.3188 12.0812C12.121 12.2065 11.8993 12.2873 11.6684 12.3183C11.4375 12.3492 11.2028 12.3297 10.9799 12.2609C10.757 12.192 10.5512 12.0756 10.376 11.9192C10.2009 11.7629 10.0606 11.5703 9.9644 11.3543C9.8682 11.1382 9.8184 10.9038 9.8183 10.6666L9.8739 10.2349L7.7875 8.1099C7.5098 8.1853 7.2176 8.1853 6.9399 8.1099L3.21712 11.9016L3.27276 12.3333C3.27276 12.6629 3.17678 12.9852 2.99698 13.2593C2.81717 13.5334 2.5616 13.747 2.26259 13.8731C1.96358 13.9993 1.63456 14.0323 1.31714 13.968C0.999713 13.9037 0.708143 13.7449 0.479282 13.5118C0.250432 13.2787 0.0945823 12.9818 0.0314423 12.6585C-0.0316977 12.3352 0.00071238 12 0.124562 11.6955C0.248422 11.3909 0.458153 11.1306 0.727263 10.9475C0.996363 10.7644 1.31273 10.6666 1.63638 10.6666Z" fill="#3D3D3D"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

AnalyticsExpensesIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AnalyticsExpensesIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AnalyticsExpensesIcon)
