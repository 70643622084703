import { GET_OBJECT_IMAGE } from './index'

export const requestObjectImage = (payload) => ({
  type: GET_OBJECT_IMAGE.REQUEST,
  payload,
})

export const successObjectImage = (payload) => ({
  type: GET_OBJECT_IMAGE.SUCCESS,
  payload,
})

export const errorObjectImage = (payload) => ({
  type: GET_OBJECT_IMAGE.ERROR,
  payload,
})
