import styled from 'styled-components'

const StyledTreeBeard = styled.div`
  .css-f91fgu {
    background-color: transparent;
  }

  ul {
    position: relative;
    ::before {
      position: absolute;
      content: '';
      border-left: 1px dashed ${({ theme }) => theme.colors.borders.tree};
      left: 8px;
      top: -12px;
      height: calc(100% - 4px);
    }
    li {
      position: relative;
      ::before {
        position: absolute;
        content: '';
        border-top: 1px dashed ${({ theme }) => theme.colors.borders.tree};
        left: -8px;
        top: 14px;
        width: calc(10px);
      }
    }
    li:last-child {
      position: relative;
      ::before {
        position: absolute;
        content: '';
        border-top: 1px dashed ${({ theme }) => theme.colors.borders.tree};
        left: -11px;
        top: 13px;
        width: calc(12px);
      }
    }
  }

  > ul {
    position: block;
    ::before {
      position: block;
      content: '';
      border-left: none;
    }
    & > li:last-child {
      position: relative;
      ::before {
        position: absolute;
        content: '';
        border-top: none};
      }
    }

    & > li {
      position: none;
      ::before {
        border-top:none;
      }
    }
  }
`
export default StyledTreeBeard
