import {
  SET_WIDGET_SETTINGS,
  SET_ACY_WIDGET_SETTINGS,
  UPDATE_WIDGET_SETTINGS,
  REFRESH_WIDGET_MAP,
} from './index'

export const updateWidgetSettings = (id, data) => ({
  type: UPDATE_WIDGET_SETTINGS,
  payload: {
    id,
    data,
  },
})
export const setACYWidgetSettings = (id, data) => ({
  type: SET_ACY_WIDGET_SETTINGS,
  payload: {
    id,
    data,
  },
})

export const setWidgetSettings = (payload) => ({
  type: SET_WIDGET_SETTINGS,
  payload,
})

export const refreshWidget = (payload) => ({
  type: REFRESH_WIDGET_MAP.REQUEST,
  payload,
})

export const eventsRefreshWidget = (payload) => ({
  type: REFRESH_WIDGET_MAP.EVENTS,
  payload,
})
