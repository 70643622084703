import styled from 'styled-components'
import { Form } from 'formik'
import SelectField from '@/components/fields/SelectField'
import TimeField from '@/components/fields/TimeField'
import HoursControl from '@/components/controls/HoursControl'
import Button from '@/components/blocks/Button'

export const Container = styled.div`
  padding: 0px 10px;
  flex-direction: column;
  display: none;
  ${({ active }) => active && `
    display: flex;
  `}
`

export const FormWrapper = styled(Form)`
  position: relative;
  ${Container} {
    ${({ loading }) => loading && `
      opacity: 0.7;
    `}
  }
  ${({ withScroll }) => withScroll && `
    overflow: auto;
    height: 100%;
  `}
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 2;
`

export const StyledButton = styled(Button)`
  margin-top: 20px;
`

export const StyledTimeField = styled(TimeField)`
  margin-left: 10px;
`

export const StyledHoursControl = styled(HoursControl)`
  min-width: 60px;
  max-width: 60px;
`

export const TextControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const SelectFieldStyled = styled(SelectField)`
  margin-left: 10px;
  ${({ mainField }) => mainField && `
    margin-left: 0px;
  `}
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  letter-spacing: 0.01em;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  ${({ withoutMargin }) => withoutMargin && `
    margin-top: 0px;
    margin-bottom: 0px;
  `}
  ${({ text }) => text && `
    margin-left: 10px;
    font-weight: normal;
  `}
  ${({ info }) => info && `
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    white-space: pre;
  `}
`

export const FieldContainer = styled.div`
  display: flex;
`

export const UserContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  position: relative;
  ${({ active }) => !active && `
    padding-top: 15px;
  `}
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4px;
`

export const RemoveButton = styled.div`
  position: absolute;
  right: 13px;
  top: 11px;
  cursor: pointer;
`

export const DeleteUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
`

export const CancelButton = styled.div`
  min-width: 77px;
  min-height: 25px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.backgrounds.redisign.green};
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.backgrounds.redisign.green};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`

export const RecipientContainer = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.borders.redisign.gray};
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
`

export const RecipientText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`
