import { PASSPORT_BLOCKS } from '@/constants/instalationPassport/types'

export default (fields) => fields.map((element) => {
  const splitFieldName = element.fieldName.split('.')
  return {
    ...element,
    fieldName: PASSPORT_BLOCKS.includes(splitFieldName[0])
      ? splitFieldName[1] || splitFieldName[0]
      : element.fieldName,
  }
})
