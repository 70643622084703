import { GET_CONSUMPTION_ANALYSIS_SAVING } from './index'

export const requestConsumptionAnalysisSavings = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_SAVING.REQUEST,
  payload,
})

export const successConsumptionAnalysisSavings = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_SAVING.SUCCESS,
  payload,
})

export const errorConsumptionAnalysisSavings = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_SAVING.ERROR,
  payload,
})
