import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT } from '@/constants/apiRoutes'
import { GET_ALARMS_COUNT } from '@/store/actions/alarmManager'
import {
  successGetAlarmsCount,
  errorGetAlarmsCount,
} from '@/store/actions/alarmManager/getAlarmsCount'

function* getAlarmsCountSaga() {
  try {
    const response = yield request({
      url: ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT,
      method: 'get',
    })
    yield put(successGetAlarmsCount(response.data))
  } catch (error) {
    yield put(errorGetAlarmsCount(error))
  }
}

export default function* root() {
  yield takeLatest(GET_ALARMS_COUNT.REQUEST, getAlarmsCountSaga)
}
