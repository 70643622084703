import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ObjectsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M31 16.0987C31 19.4328 28.3137 22.1357 25 22.1357C21.6863 22.1357 19 19.4328 19 16.0987C19 12.7645 21.6863 10.0617 25 10.0617C28.3137 10.0617 31 12.7645 31 16.0987ZM28 16.0987C28 17.7658 26.6569 19.1172 25 19.1172C23.3431 19.1172 22 17.7658 22 16.0987C22 14.4316 23.3431 13.0802 25 13.0802C26.6569 13.0802 28 14.4316 28 16.0987Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25 0C20.3723 0 16.3316 1.75789 13.4643 4.92016C10.6087 8.06942 9 12.515 9 17.7833C9 23.2721 11.9307 31.6089 16.2389 39.1544C18.2329 42.6469 20.3945 45.7299 22.3564 47.8732C22.9536 48.5255 23.5198 49.0764 24.0285 49.5033L24.3423 49.7666C24.7156 50.0798 25.2587 50.0776 25.6295 49.7613L25.9408 49.4957C26.4513 49.0602 27.0203 48.4978 27.6207 47.8317C29.5821 45.6561 31.749 42.5285 33.7535 38.9901C35.9199 35.1661 37.7227 31.1878 38.9872 27.5243C40.2439 23.8831 41 20.4637 41 17.7833C41 7.48889 34.2525 0 25 0ZM11.9091 17.7833C11.9091 13.0644 13.3454 9.32368 15.6487 6.78349C17.9403 4.2563 21.1723 2.82154 25 2.82154C32.296 2.82154 38.0909 8.68465 38.0909 17.7833C38.0909 20.026 37.4413 23.1147 36.2285 26.6286C35.0234 30.1202 33.2929 33.944 31.204 37.6314C29.2629 41.0577 27.2108 43.9999 25.4308 45.9745L25.4104 45.997C25.1744 46.2582 24.7695 46.2595 24.5318 45.9999C22.7557 44.0596 20.7121 41.1647 18.7838 37.7875C14.5659 30.4001 11.9091 22.5654 11.9091 17.7833Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ObjectsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ObjectsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ObjectsIcon)
