import styled from 'styled-components'

export const ViewTreeContainer = styled.div`
  padding: 0 10px;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  overflow-y: hidden;
  overflow-x: hidden;
`

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 22px;
`
export const AdminsBarContainer = styled.div`
  max-width: 100%; 
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

`

export const AdminsContainer = styled.div`
  width: 100%; 
  height: 100%;
  padding: 30px 20px;
  display: grid; 
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  overflow-y: scroll;
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  & + div {
    z-index: 3;
  }
`

export const MainContainer = styled.div`
  ${({ actionsDisabled }) => actionsDisabled && 'opacity: 0.3'};
  ${({ scroll }) => scroll && `
    width: 100%;
    height: 100%;
    overflow-y: auto;
  `}
`
