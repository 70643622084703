import { GET_GRAPH_DATA } from './index'

export const getGraphDataRequest = (payload) => ({
  type: GET_GRAPH_DATA.REQUEST,
  payload,
})

export const getGraphDataSuccess = (payload) => ({
  type: GET_GRAPH_DATA.SUCCESS,
  payload,
})

export const getGraphDataError = (payload) => ({
  type: GET_GRAPH_DATA.ERROR,
  payload,
})
