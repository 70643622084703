import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WarningIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg onClick={onClick} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" d="M11.52 1.43366L17.551 11.3546C18.4177 12.7795 17.992 14.6542 16.5996 15.5413C16.1324 15.8402 15.5876 15.9994 15.0309 16H2.96821C1.3293 16 0 14.64 0 12.9609C0 12.3929 0.1557 11.8373 0.448201 11.3546L6.48002 1.43366C7.34582 0.00876296 9.17643 -0.427685 10.5687 0.459433C10.9539 0.704768 11.2797 1.0381 11.52 1.43366ZM9.52203 2.17945C9.41185 2.10945 9.28844 2.06224 9.15927 2.04067C9.0301 2.0191 8.89785 2.02361 8.77049 2.05393C8.64313 2.08426 8.52332 2.13977 8.4183 2.2171C8.31327 2.29444 8.22521 2.392 8.15942 2.50389L2.12851 12.4266C2.03054 12.5883 1.9789 12.7733 1.97911 12.9618C1.97911 13.5218 2.42191 13.9751 2.96911 13.9751H15.03C15.2154 13.9751 15.3963 13.9218 15.5529 13.8222C15.7777 13.6747 15.9361 13.4473 15.9953 13.1874C16.0545 12.9274 16.0098 12.6549 15.8706 12.4266L9.83973 2.50389C9.76063 2.37325 9.65225 2.26225 9.52293 2.17945H9.52203ZM9.00003 12.4444C8.76133 12.4444 8.53241 12.3508 8.36363 12.1841C8.19485 12.0174 8.10002 11.7913 8.10002 11.5555C8.10002 11.3198 8.19485 11.0937 8.36363 10.927C8.53241 10.7603 8.76133 10.6666 9.00003 10.6666C9.23872 10.6666 9.46764 10.7603 9.63642 10.927C9.80521 11.0937 9.90003 11.3198 9.90003 11.5555C9.90003 11.7913 9.80521 12.0174 9.63642 12.1841C9.46764 12.3508 9.23872 12.4444 9.00003 12.4444ZM9.00003 4.44435C9.23872 4.44435 9.46764 4.538 9.63642 4.70471C9.80521 4.87141 9.90003 5.0975 9.90003 5.33325V8.88883C9.90003 9.12458 9.80521 9.35068 9.63642 9.51738C9.46764 9.68408 9.23872 9.77773 9.00003 9.77773C8.76133 9.77773 8.53241 9.68408 8.36363 9.51738C8.19485 9.35068 8.10002 9.12458 8.10002 8.88883V5.33325C8.10002 5.0975 8.19485 4.87141 8.36363 4.70471C8.53241 4.538 8.76133 4.44435 9.00003 4.44435Z" fill={fill} />
    </svg>
  )
}

WarningIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
WarningIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(WarningIcon)
