import generateWidget from './generateWidget'
import { UnitPressure, UnitvolumetricFlow, UnitVolume } from './unitElements'

export const HEAT_SUPPLY_PIPE_TEMPERATURE = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_TEMPERATURE',
    title: 'Температура в подающем трубопроводе',
    units: '°C',
  },
  selectorTemplate: 'temperatureInPipe',
})

export const HEAT_RETURN_PIPE_TEMPERATURE = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_TEMPERATURE',
    title: 'Температура в обратном трубопроводе',
    units: '°C',
  },
  selectorTemplate: 'temperatureInReversePipe',
})

export const HEAT_SUPPLY_PIPE_PRESSURE = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_PRESSURE',
    title: 'Давление в подающем трубопроводе',
    units: UnitPressure,
  },
  selectorTemplate: 'pressureInPipe',
})

export const HEAT_RETURN_PIPE_PRESSURE = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_PRESSURE',
    title: 'Давление в обратном трубопроводе',
    units: UnitPressure,
  },
  selectorTemplate: 'pressureInReversePipe',
})

export const HEAT_SUPPLY_PIPE_MASS = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_MASS',
    title: 'Массовый расход в подающем трубопроводе',
    units: 'т/ч',
  },
  selectorTemplate: 'massFlowInPipe',
})

export const HEAT_RETURN_PIPE_MASS = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_MASS',
    title: 'Массовый расход в обратном трубопроводе',
    units: 'т/ч',
  },
  selectorTemplate: 'massFlowInReversePipe',
})

export const HEAT_SUPPLY_PIPE_FLOW = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_FLOW',
    title: 'Объемный расход в подающем трубопроводе',
    units: UnitvolumetricFlow,
  },
  selectorTemplate: 'volumetricFlowInPipe',
})

export const HEAT_RETURN_PIPE_FLOW = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_FLOW',
    title: 'Объемный расход в обратном трубопроводе',
    units: UnitvolumetricFlow,
  },
  selectorTemplate: 'volumetricFlowInReversePipe',
})

export const HEAT_CONSUMED_RESURCE_VOLUME = generateWidget({
  config: {
    id: 'HEAT_CONSUMED_RESURCE_VOLUME',
    title: 'Объем потребленного ресурса',
    units: UnitVolume,
  },
  selectorTemplate: 'temperature',
})

export const HEAT_TERMAL_ENERGY = generateWidget({
  config: {
    id: 'HEAT_TERMAL_ENERGY',
    title: 'Тепловая энергия',
    units: 'Гкал',
  },
  selectorTemplate: 'energy',
})

export const HEAT_COST = generateWidget({
  config: {
    id: 'HEAT_COST',
    title: 'Расходы на теплоснабжение',
    units: 'руб',
  },
  selectorTemplate: 'heatCosts',
})

export const HEAT_DETECT_UNDERDOSE = generateWidget({
  config: {
    id: 'HEAT_DETECT_UNDERDOSE',
    title: 'Выявление недотопа',
    units: 'Гкал',
  },
  selectorTemplate: 'detectionOfUnderheat',
})

export const HEAT_DETECT_UPPERDOSE = generateWidget({
  config: {
    id: 'HEAT_DETECT_UPPERDOSE',
    title: 'Выявление перетопа',
    units: 'Гкал',
  },
  selectorTemplate: 'detectionOfOverheat',
})
