import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LightBulbIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM7.85714 15C7.54286 15 7.28571 14.73 7.28571 14.4V13.8H10.7143V14.4C10.7143 14.73 10.4571 15 10.1429 15H7.85714ZM5 7.2C5 4.884 6.79429 3 9 3C11.2057 3 13 4.884 13 7.2C13 8.628 12.32 9.882 11.2857 10.644V12C11.2857 12.33 11.0286 12.6 10.7143 12.6H7.28571C6.97143 12.6 6.71429 12.33 6.71429 12V10.644C5.68 9.882 5 8.628 5 7.2Z" />
    </svg>
  )
}

LightBulbIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
LightBulbIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(LightBulbIcon)
