import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ExportIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 8L11.5 4V7H3.5V9H11.5V12L15.5 8ZM-1.90735e-06 14V2C-1.90735e-06 1.46957 0.210712 0.960859 0.585785 0.585786C0.960857 0.210714 1.46957 0 2 0H14C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V5H14V2H2V14H14V11H16V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960857 15.7893 0.585785 15.4142C0.210712 15.0391 -1.90735e-06 14.5304 -1.90735e-06 14Z" fill={fill} className="svgFill" />
    </svg>

  )
}

ExportIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ExportIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ExportIcon)
