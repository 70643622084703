import { COUNTRY, REGION, CITY } from '@/constants/objectTypes'

const excludedElements = {
  [COUNTRY]: true,
  [REGION]: true,
  [CITY]: true,
}

const mergeCountsByType = (initial, current) => {
  const types = Object.keys(current)

  return types.reduce((accumulator, type) => ({
    ...accumulator,
    [type]: (initial[type] || 0) + (current[type] || 0),
  }), [])
}

export const getPinCountByType = (node, excludedTypes = [], count = {}) => {
  const current = count[node.type] || 0
  if (typeof (node.status) !== 'undefined' && !node.children) {
    if (excludedTypes.includes(node.type || '')) {
      return {
        ...count,
        [node.type]: current,
      }
    }
    return {
      ...count,
      [node.type]: current + 1,
    }
  }
  if (node.children && typeof (node.usersAmount) !== 'number') {
    return node.children.reduce((accumulator, element) => {
      let result = {
        ...accumulator,
        ...mergeCountsByType(accumulator, getPinCountByType(element, excludedTypes)),
      }
      if (element.children && !excludedElements[element.type]) {
        result = {
          ...result,
          [element.type]: (result[element.type] || 0) + 1,
        }
      }
      return result
    }, count)
  }
  return {
    ...count,
    [node.type]: current,
  }
}

export default getPinCountByType
