import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PassportizationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5 5.9C5 5.40294 5.40294 5 5.9 5H22.1C22.597 5 23 5.40294 23 5.9C23 6.39706 22.597 6.8 22.1 6.8H5.9C5.40294 6.8 5 6.39706 5 5.9ZM5 9.95011C5 9.45305 5.40294 9.05011 5.9 9.05011H15.8C16.2971 9.05011 16.7 9.45305 16.7 9.95011C16.7 10.4472 16.2971 10.8501 15.8 10.8501H5.9C5.40294 10.8501 5 10.4472 5 9.95011ZM17.6 9.95011C17.6 9.45305 18.0029 9.05011 18.5 9.05011H22.0995C22.5966 9.05011 22.9995 9.45305 22.9995 9.95011C22.9995 10.4472 22.5966 10.8501 22.0995 10.8501H18.5C18.0029 10.8501 17.6 10.4472 17.6 9.95011ZM5 13.9998C5 13.5027 5.40294 13.0998 5.9 13.0998H10.4C10.8971 13.0998 11.3 13.5027 11.3 13.9998C11.3 14.4968 10.8971 14.8998 10.4 14.8998H5.9C5.40294 14.8998 5 14.4968 5 13.9998ZM12.2 13.9998C12.2 13.5027 12.6029 13.0998 13.1 13.0998H22.1C22.5971 13.0998 23 13.5027 23 13.9998C23 14.4968 22.5971 14.8998 22.1 14.8998H13.1C12.6029 14.8998 12.2 14.4968 12.2 13.9998ZM5 18.0499C5 17.5529 5.40294 17.1499 5.9 17.1499H14C14.4971 17.1499 14.9 17.5529 14.9 18.0499C14.9 18.547 14.4971 18.9499 14 18.9499H5.9C5.40294 18.9499 5 18.547 5 18.0499ZM15.7884 18.0499C15.7884 17.5529 16.1913 17.1499 16.6884 17.1499H22.1C22.597 17.1499 23 17.5529 23 18.0499C23 18.547 22.597 18.9499 22.1 18.9499H16.6884C16.1913 18.9499 15.7884 18.547 15.7884 18.0499ZM5 22.1C5 21.6029 5.40294 21.2 5.9 21.2H9.5C9.99706 21.2 10.4 21.6029 10.4 22.1C10.4 22.5971 9.99706 23 9.5 23H5.9C5.40294 23 5 22.5971 5 22.1ZM11.3 22.1C11.3 21.6029 11.7029 21.2 12.2 21.2H22.1C22.597 21.2 23 21.6029 23 22.1C23 22.5971 22.597 23 22.1 23H12.2C11.7029 23 11.3 22.5971 11.3 22.1Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PassportizationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PassportizationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PassportizationIcon)
