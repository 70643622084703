import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HelpIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1563 22.7291C12.4147 22.9057 12.7185 23 13.0293 23C13.446 23 13.8456 22.8306 14.1403 22.5291C14.435 22.2277 14.6005 21.8188 14.6005 21.3924C14.6005 21.0745 14.5084 20.7636 14.3357 20.4993C14.1631 20.2349 13.9177 20.0289 13.6306 19.9072C13.3434 19.7855 13.0275 19.7537 12.7227 19.8157C12.4179 19.8777 12.138 20.0308 11.9182 20.2557C11.6985 20.4805 11.5488 20.7669 11.4882 21.0788C11.4276 21.3906 11.4587 21.7139 11.5776 22.0076C11.6965 22.3014 11.8979 22.5524 12.1563 22.7291Z" fill={fill} className="svgFill" />
      <path d="M14.2077 17.2127C14.2077 16.4772 14.3829 15.6758 15.6564 14.8013L15.6575 14.8005C17.2245 13.7228 19 12.5018 19 9.78318C19 7.92319 17.9551 6.38875 16.0531 5.4684C15.4176 5.16135 14.4803 4.98853 13.4826 5.00059C12.0732 5.01828 10.8829 5.41857 9.94727 6.1886C8.14269 7.67402 8.00992 9.43353 8.00128 9.62885C7.99431 9.77663 8.01587 9.92436 8.06471 10.0636C8.11355 10.2029 8.18872 10.3309 8.28593 10.4405C8.38313 10.55 8.50048 10.6389 8.63125 10.702C8.76203 10.7651 8.90368 10.8013 9.04812 10.8084C9.19256 10.8155 9.33695 10.7935 9.47306 10.7435C9.60917 10.6936 9.73432 10.6166 9.84138 10.5172C9.94844 10.4177 10.0353 10.2977 10.097 10.1639C10.1587 10.0301 10.1941 9.88514 10.201 9.73736C10.201 9.7237 10.289 8.7929 11.3276 7.93766C11.8783 7.48432 12.5932 7.25926 13.514 7.2472C14.2478 7.23836 14.8441 7.36536 15.112 7.49557C16.2503 8.04939 16.8003 8.79772 16.8003 9.78318C16.8003 11.2324 15.9439 11.8915 14.4292 12.9316C12.7999 14.0513 12.008 15.4515 12.008 17.2127C12.008 17.5111 12.1238 17.7973 12.3301 18.0084C12.5364 18.2194 12.8161 18.338 13.1078 18.338C13.3995 18.338 13.6793 18.2194 13.8856 18.0084C14.0918 17.7973 14.2077 17.5111 14.2077 17.2127Z" fill={fill} className="svgFill" />
    </svg>

  )
}

HelpIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HelpIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HelpIcon)
