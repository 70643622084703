import {
  takeLatest, put
} from 'redux-saga/effects'
import {
  GET_OBJECT_SCHEME,
} from '@/store/actions/installation'
import createNotifications from '@/helpers/notification'
import {
  successGetScheme,
  errorGetScheme,
} from '@/store/actions/installation/getScheme'

// export const EXTENSION_BY_TYPE = {
//   pdf: 'pdf',
//   excel: 'xlsx',
// }

function* getObjectSchemeSaga() {
  const toast = createNotifications()
  try {
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось выгрузить документ.',
      type: 'error',
    })
    yield put(successGetScheme())
  } catch (error) {
    yield put(errorGetScheme(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось выгрузить документ.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_SCHEME.REQUEST, getObjectSchemeSaga)
}
