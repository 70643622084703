import {
  takeEvery, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { INSTALLATION_UPLOAD_FILE } from '@/constants/apiRoutes'
import {
  UPLOAD_INSTALLATION_OBJECT_FILE,
} from '@/store/actions/installation'
import {
  successUploadInstallationObjectFile,
  errorUploadInstallationObjectFile,
} from '@/store/actions/installation/uploadObjectFile'

function* uploadPassportPhotoSaga({ payload }) {
  const {
    elementId,
    file,
    title,
    fileType,
  } = payload
  try {
    const formData = new window.FormData()
    formData.append('elementId', elementId)
    formData.append('file', file)
    formData.append('title', title)
    formData.append('fileType', fileType)
    yield request({
      url: INSTALLATION_UPLOAD_FILE,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    yield put(successUploadInstallationObjectFile())
  } catch (error) {
    yield put(errorUploadInstallationObjectFile())
    console.log('function*uploadPassportPhotoSaga -> error', error)
  }
}

export default function* root() {
  yield takeEvery(UPLOAD_INSTALLATION_OBJECT_FILE.REQUEST, uploadPassportPhotoSaga)
}
