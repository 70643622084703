import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 10px;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
`
export const Row = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(center) => center && `
      justify-content: center;
  `}
`

export const Title = styled.div`
  width: 40%;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.colors.default};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: normal;
  text-align: left;
  ${({ color }) => color && `
    color: ${color};
  `}
  ${({ main }) => main && `
    width: 60%;
    margin-right: auto;
  `}
`
