import get from 'lodash/get'
import {
  LIGHT,
  CONTROLLER,
  TRASH,
  CITY,
  LIGHT_SENSOR,
  SHUO,
} from '@/constants/objectTypes'
import {
  MESH,
} from '@/constants/integrations'
import { INFO } from '@/constants/objectStatuses'
import getObjectName from '@/helpers/viewTree/getObjectName'

const GEOZONE_ID = 100500

export default (
  data,
  onlyMoscow = false,
  integrationsIds = [],
  objectTypes = [],
) => {
  const canViewControllers = objectTypes.length > 0 ? objectTypes.includes(CONTROLLER) : true
  const canViewLights = objectTypes.length > 0 ? objectTypes.includes(LIGHT) : true
  const { integrationId } = data
  const sensors = get(data, 'ambientLightSensors.AMBIENT_LIGHT_SENSOR', [])
  const lightControllers = get(data, 'streetlightControlCabinets.CONTROL_CABINET', [])
  const streetlights = get(data, 'streetlights.STREET_LIGHT', [])
  const trashCans = get(data, 'wasteContainers.WASTE_CONTAINER', [])
  const childrenSensors = sensors.reduce((accumulator, sensor) => {
    if (!get(sensor, 'location.coordinates.latitude', false)) {
      return accumulator
    }
    return [
      ...accumulator,
      {
        ...sensor,
        internalId: GEOZONE_ID,
        name: getObjectName(sensor.id, LIGHT_SENSOR),
        system: MESH,
        type: LIGHT_SENSOR,
        status: INFO,
        state: INFO,
        location: [get(sensor, 'location.coordinates.latitude', false), get(sensor, 'location.coordinates.longitude', false)],
        geoZoneId: GEOZONE_ID,
        aliasId: integrationId,
      },
    ]
  }, [])
  const trashChildren = trashCans.reduce((accumulator, trashCan) => [
    ...accumulator,
    {
      ...trashCan,
      internalId: GEOZONE_ID,
      location: [get(trashCan, 'location.coordinates.latitude', false), get(trashCan, 'location.coordinates.longitude', false)],
      system: MESH,
      type: TRASH,
      status: INFO,
      state: INFO,
      name: getObjectName(trashCan.id, TRASH),
      geoZoneId: GEOZONE_ID,
    },
  ], [])
  const controllers = lightControllers.reduce((accumulator, controller) => {
    const controllersChildren = streetlights
      .filter((light) => (light.refStreetlightControlCabinet === controller.id
        && get(light, 'location.coordinates.longitude', false)))
    const formattedControllersChildren = canViewLights
      ? controllersChildren.map((light) => ({
        ...light,
        internalId: GEOZONE_ID,
        geoZoneId: GEOZONE_ID,
        alias: 'mesh',
        system: MESH,
        parentId: controller.id,
        type: LIGHT,
        status: INFO,
        state: INFO,
        name: light.id,
        location: [get(light, 'location.coordinates.latitude', 0), get(light, 'location.coordinates.longitude', 0)],
        aliasId: integrationId,
      }))
      : []
    return [
      ...accumulator,
      ...(canViewControllers
        ? [{
          ...controller,
          internalId: GEOZONE_ID,
          type: CONTROLLER,
          geoZoneId: GEOZONE_ID,
          alias: 'mesh',
          status: INFO,
          state: INFO,
          system: MESH,
          name: getObjectName(controller.id, SHUO),
          location: [get(controller, 'location.coordinates.latitude', 0), get(controller, 'location.coordinates.longitude', 0)],
          children: formattedControllersChildren,
          aliasId: integrationId,
        }]
        : formattedControllersChildren || []),
    ]
  }, [])
  const allChildren = [
    ...(controllers),
    ...childrenSensors,
    ...trashChildren,
  ]
  if (integrationsIds.length > 0 && !integrationsIds.includes(integrationId)) {
    return []
  }
  return [{
    id: GEOZONE_ID,
    internalId: GEOZONE_ID,
    location: onlyMoscow ? [55.767567, 37.62381] : [61.4894, 23.7806],
    name: 'MESH',
    system: MESH,
    type: CITY,
    children: onlyMoscow
      ? allChildren.filter(({ location }) => location[0].toString().startsWith(55) && location[1].toString().startsWith(37))
      : allChildren,
  }]
}
