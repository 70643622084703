import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const InfoFrameIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4 14.7273H12.2L9 18L5.8 14.7273H2.6C1.72 14.7273 1 13.9909 1 13.0909V1.63636C1 0.73636 1.72 0 2.6 0H15.4C16.28 0 17 0.73636 17 1.63636V13.0909C17 13.9909 16.28 14.7273 15.4 14.7273ZM15.4 1.63636H2.6V13.0909H6.216L9.032 15.9545L11.816 13.0909H15.4V1.63636ZM4.2 3.27273H13.8V11.4545H4.2V3.27273ZM5.8 9.8182H12.2V4.90909H5.8V9.8182Z" fill="#3D3D3D"/>
    </svg>
  )
}

InfoFrameIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InfoFrameIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InfoFrameIcon)
