const getDataByType = (type, data, count = 1) => {
  switch (type) {
    case 'add':
      return {
        childrenCount: (data.childrenCount || 0) + count,
        count: (data.count || 0) + count,
      }
    case 'remove':
      return {
        childrenCount: (data.childrenCount || 0) - count,
        count: (data.count || 0) - count,
      }
    case 'refresh':
      return {
        childrenCount: count,
        count: count,
      }
    default:
      return {}
  }
}

const updateNodeCount = (tree, path, type, count = 1) => {
  const pathIds = path.split('/').filter((id) => id.length > 0)

  pathIds.forEach((id) => {
    const node = tree.handlers.getNodeData(id)
    const newNodeParams = getDataByType(type, node, count)
    tree.handlers.setNodeData(id, {
      ...node,
      ...newNodeParams,
    })
  })
}

export default updateNodeCount
