export const DEBOUNCE_DELAY_MEDIUM = 500
export const DEBOUNCE_DELAY_LARGE = 700
export const DEBOUNCE_DELAY_SMALL = 300
export const DEBOUNCE_DELAY_HUDGE = 2000

export const TIME_FORMAT_FULL = 'all'
export const TIME_FORMAT_D_H_M_S = 'd.h.m.s.'
export const TIME_FORMAT_M = 'm.'

export const TIME_OF_ENDING_NOTIFICATION = 4 * 1000

export const HOURS_IN_DAY = 24
export const MINUTES_IN_HOUR = 60
export const SECONDS_IN_MINUTE = 60
export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR
