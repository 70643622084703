import { all } from 'redux-saga/effects'

import objectState from './objectState'
import serviceDeskWidget from './serviceDeskWidget'
import graphData from './graphData'
import tyumenOurHome from './tyumenOurHome'
import depreciationEquipment from './depreciationEquipment'
import automaticReporting from './automaticReporting'

export default function* root() {
  yield all([
    objectState(),
    serviceDeskWidget(),
    graphData(),
    tyumenOurHome(),
    depreciationEquipment(),
    automaticReporting(),
  ])
}
