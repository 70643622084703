import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const WidgetHeaderIcon = styled.div`
  margin-bottom: auto;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  display: none;

  ${({ withMargin }) => withMargin && `
    margin: 5px;
    margin-bottom: auto;
  `}
`

export const ContainerLink = styled.div`
  ${({ disabled, theme }) => disabled === null && `
    opacity: 0.3;
    // ${theme.themeName === LIGHT_THEME_NAME && 'pointer-events: none;'}
  `}

  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border: 1px solid ${theme.colors.borders.redisign.contrast};
        background: ${theme.colors.backgrounds.redisign.default};
        box-shadow: 5px 5px 10px ${theme.colors.shadows.widgetPrimary},
          -5px -5px 10px ${theme.colors.shadows.widgetSecondary};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header}
      box-shadow: -8px 12px 18px ${theme.colors.shadows.widget}
    `
  }}
  position: relative;
  width: 130px;
  height: 130px;
  font-size: 1.4rem;
  border-radius: 10px;
  margin: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 5px 5px 10px ${({ theme }) => theme.colors.shadows.widgetPrimary},
    -5px -5px 10px ${({ theme }) => theme.colors.shadows.widgetSecondary};
  > svg {
    height: 50px;
  }
  * {
    text-decoration: none;
  }

  a > {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background: ${({ theme }) => theme.themeName === LIGHT_THEME_NAME
      && theme.colors.scheme.backgrounds.default};

    ${WidgetHeaderIcon} {
      display: block;
    }
  }
  ${({ isBig }) => isBig && `
    width: 270px;
  `}

`
export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100% - 42px);
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isBig }) => isBig && `
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  `}
  svg {
    margin-top: 20px;
    width: 45%;
  }
`

export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
`

export const Count = styled.span`
  font-size: 4.8rem;
  line-height: 5.6rem;
  text-align: center;
`

export const Notification = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  background: ${({ theme }) => theme.gradients.normal};
  ${ContainerLink}:hover && {
    border-radius: 0px 0px 3px 3px;
  }
  ${({ theme, type }) => type === 'alarm' && `
    background: ${theme.gradients.alarm}
    ${theme.animations.alarm.connect(2500)}
  `}
`
export const MainTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  width: 100%;
  text-align: left;
  ${ContainerLink}:hover && {
    color: ${({ theme }) => (theme.themeName === LIGHT_THEME_NAME)
      && theme.colors.backgrounds.redisign.light};
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  align-items:  flex-end;
  justify-content: flex-start;
  padding: 0 5px 0 10px;
`
