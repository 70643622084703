import {
  LIGHT_THEME_NAME,
  DARK_THEME_NAME,
  SKY_SCHEME_NAME,
} from '@/constants/names'
import lightTheme from './lightTheme'
import darkTheme from './darkTheme'
import { SCHEME_COLORS } from './defaultTheme'

export const THEMES = {
  [LIGHT_THEME_NAME]: {
    themeName: LIGHT_THEME_NAME,
    ...lightTheme,
  },
  [DARK_THEME_NAME]: {
    themeName: DARK_THEME_NAME,
    ...darkTheme,
  },
}

export const getTheme = ({
  themeName = LIGHT_THEME_NAME,
  schemeName = SKY_SCHEME_NAME,
  largeFont = false,
}) => {
  const theme = THEMES[themeName]
  const scheme = SCHEME_COLORS[schemeName]
  return {
    themeName,
    ...theme,
    colors: {
      ...theme.colors,
      scheme,
    },
    htmlFontSize: largeFont ? theme.htmlLargeFontSize : theme.htmlFontSize,
  }
}
