import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import TextAreaControl from '@/components/controls/TextAreaControl'

const TextAreaField = ({
  iconName, label, type, placeholder, disabled, controls, ...ownProps
}) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const { isSubmitting } = form

      return (
        <TextAreaControl
          {...ownProps}
          {...field}
          iconName={iconName}
          label={label}
          placeholder={placeholder}
          type={type}
          disabled={isSubmitting || disabled}
          controls={controls}
        />
      )
    }}
  </Field>
)

TextAreaField.defaultProps = {
  iconName: null,
  label: null,
  type: 'text',
  placeholder: '',
  disabled: false,
  controls: null,
}

TextAreaField.propTypes = {
  iconName: pt.string,
  label: pt.oneOfType([pt.string, pt.element]),
  type: pt.string,
  placeholder: pt.string,
  disabled: pt.bool,
  controls: pt.element,
}

export default React.memo(TextAreaField)
