import { CREATE_OBJECT } from './index'

export const requestCreateObject = (payload) => ({
  type: CREATE_OBJECT.REQUEST,
  payload,
})

export const successCreateObject = (payload) => ({
  type: CREATE_OBJECT.SUCCESS,
  payload,
})

export const errorCreateObject = (payload) => ({
  type: CREATE_OBJECT.ERROR,
  payload,
})
