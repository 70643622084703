import styled from 'styled-components'
import { TextButton } from '@/components/blocks/Button/styles'
import TextField from '@/components/fields/TextField'
import { Label, Error } from '@/components/controls/TextControl/styles'

export const Form = styled.form`
  width: 100%;
  margin-top: 30px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  ${TextButton} {
    margin-top: 10px;
  }
`

export const ErrorMessage = styled.p`
  font-size: 1.4rem;
  margin: 15px 0;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const FieldContainer = styled.div`
  height: auto;
`

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 10px;

  ${Label} {

  }

  ${Error} {
    
  }
`
