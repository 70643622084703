import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SimIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.9818 10.0318C6.06619 9.94741 6.18065 9.9 6.3 9.9C6.41935 9.9 6.53381 9.94741 6.6182 10.0318C6.70259 10.1162 6.75 10.2307 6.75 10.35V11.25C6.75 11.3693 6.70259 11.4838 6.6182 11.5682C6.53381 11.6526 6.41935 11.7 6.3 11.7C6.18065 11.7 6.06619 11.6526 5.9818 11.5682C5.89741 11.4838 5.85 11.3693 5.85 11.25V10.35C5.85 10.2307 5.89741 10.1162 5.9818 10.0318Z" fill={fill} className="svgFill" />
      <path d="M7.7818 10.0318C7.86619 9.94741 7.98065 9.9 8.1 9.9C8.21935 9.9 8.33381 9.94741 8.4182 10.0318C8.50259 10.1162 8.55 10.2307 8.55 10.35V11.25C8.55 11.3693 8.50259 11.4838 8.4182 11.5682C8.33381 11.6526 8.21935 11.7 8.1 11.7C7.98065 11.7 7.86619 11.6526 7.7818 11.5682C7.69741 11.4838 7.65 11.3693 7.65 11.25V10.35C7.65 10.2307 7.69741 10.1162 7.7818 10.0318Z" fill={fill} className="svgFill" />
      <path d="M9.5818 10.0318C9.66619 9.94741 9.78065 9.9 9.9 9.9C10.0193 9.9 10.1338 9.94741 10.2182 10.0318C10.3026 10.1162 10.35 10.2307 10.35 10.35V11.25C10.35 11.3693 10.3026 11.4838 10.2182 11.5682C10.1338 11.6526 10.0193 11.7 9.9 11.7C9.78065 11.7 9.66619 11.6526 9.5818 11.5682C9.49741 11.4838 9.45 11.3693 9.45 11.25V10.35C9.45 10.2307 9.49741 10.1162 9.5818 10.0318Z" fill={fill} className="svgFill" />
      <path d="M11.3818 10.0318C11.4662 9.94741 11.5807 9.9 11.7 9.9C11.8193 9.9 11.9338 9.94741 12.0182 10.0318C12.1026 10.1162 12.15 10.2307 12.15 10.35V11.25C12.15 11.3693 12.1026 11.4838 12.0182 11.5682C11.9338 11.6526 11.8193 11.7 11.7 11.7C11.5807 11.7 11.4662 11.6526 11.3818 11.5682C11.2974 11.4838 11.25 11.3693 11.25 11.25V10.35C11.25 10.2307 11.2974 10.1162 11.3818 10.0318Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM6.5385 5.93685L7.17795 4.94505L6.399 4.5423L5.499 5.9373L6.138 6.9273L5.4972 7.92L6.27615 8.32275L7.1748 6.93045L7.1775 6.9291L6.5385 5.93685ZM9.2385 5.89455L9.87795 4.90275L9.099 4.5L8.199 5.895L8.838 6.885L8.19765 7.8777L8.97705 8.28045L9.8757 6.88815L9.8784 6.8868L9.2385 5.89455ZM11.952 5.89455L12.5928 4.90275L11.8134 4.5L10.9134 5.895L11.5524 6.885L10.9116 7.8777L11.691 8.28045L12.5892 6.88815L12.5924 6.8868L11.952 5.89455ZM4.5 9.9V13.5H5.4V12.6H12.6V13.5H13.5V9.9C13.5 9.66131 13.4052 9.43239 13.2364 9.2636C13.0676 9.09482 12.8387 9 12.6 9H5.4C5.1613 9 4.93239 9.09482 4.7636 9.2636C4.59482 9.43239 4.5 9.66131 4.5 9.9Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SimIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SimIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SimIcon)
