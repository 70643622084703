import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InstallationIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask-installation)">
        <path d="M29.3411 33.8978C27.7944 34.5531 26.0892 34.739 24.4377 34.4326C22.7861 34.1261 21.2612 33.3408 20.0525 32.1743C18.8439 31.0078 18.005 29.5116 17.6401 27.872C17.2753 26.2324 17.4007 24.5216 18.0006 22.9527L24.1858 29.1379C24.9205 29.8725 25.9169 30.2853 26.9559 30.2853C27.9949 30.2853 28.9914 29.8725 29.7261 29.1379C30.4608 28.4032 30.8735 27.4067 30.8735 26.3677C30.8735 25.3287 30.4608 24.3323 29.7261 23.5976L23.8368 17.7108C25.105 17.3716 26.4342 17.3293 27.7213 17.5871C29.0084 17.845 30.2188 18.396 31.2585 19.1975C32.2981 19.999 33.139 21.0293 33.7159 22.2084C34.2928 23.3875 34.5902 24.6838 34.5849 25.9965C34.5845 26.8398 34.4601 27.6785 34.2158 28.4857L36.852 31.1219C38.0742 28.5324 38.3301 25.5913 37.5736 22.8297C36.8171 20.068 35.0981 17.6679 32.7269 16.0628C30.3558 14.4576 27.4888 13.7533 24.6437 14.0769C21.7987 14.4005 19.1632 15.7308 17.2131 17.8275C15.263 19.9242 14.1269 22.6491 14.01 25.5101C13.8931 28.3711 14.8031 31.1796 16.5756 33.4284C18.3481 35.6772 20.8663 37.218 23.6755 37.7727C26.4846 38.3273 29.3995 37.8592 31.8938 36.4529L29.3411 33.8978Z" stroke={stroke} className="svgStroke" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

InstallationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InstallationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InstallationIcon)
