import {
  TEXT
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'objectCharacteristics.tariffOne',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectCharacteristics',
  },
  {
    selector: 'objectCharacteristics.tariffTwo',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectCharacteristics',
  },
  {
    selector: 'objectCharacteristics.tariffThree',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectCharacteristics',
  },
  {
    selector: 'objectCharacteristics.tariffFour',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectCharacteristics',
  },
  {
    selector: 'objectService.checkingDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.bypassDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.maintenanceDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.repairDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.guaranteeExpirationDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
]
