import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getAnalyticsAlarms = createSelector(
  getState,
  (state) => get(state, 'analyticsAlarms', {}),
)

export const getExportFileStatus = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'fileExportStatus', []),
)

export const getTableData = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'data', []),
)

export const getLoading = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'loading'),
)

export const getSelector = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'selector', []),
)

export const getCheckBoxOption = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'checkBoxOptions', {}),
)

export const getSelectedElement = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'selectedElement'),
)

export const getTableParameters = createSelector(
  getAnalyticsAlarms,
  (settings) => get(settings, 'table', {}),
)
