import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PDFIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.62882 10.4198H2.54913C3.02947 10.4198 3.35699 10.3477 3.53166 10.2036C3.70633 10.0572 3.79367 9.82124 3.79367 9.49582C3.79367 9.17038 3.70633 8.93561 3.53166 8.79149C3.35699 8.64505 3.02947 8.57182 2.54913 8.57182H1.62882V10.4198Z" fill={fill} className="svgFill" />
      <path d="M5.91267 12.4282H6.62664C7.34716 12.4282 7.83188 12.2655 8.08079 11.94C8.33188 11.6146 8.45742 11.1323 8.45742 10.493C8.45742 9.85379 8.33188 9.37262 8.08079 9.04951C7.83188 8.72641 7.34716 8.56485 6.62664 8.56485H5.91267V12.4282Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H9L14 5V18H0V0ZM1.16376 13C1.05459 13 1 12.9361 1 12.8082V8.19177C1 8.06392 1.05459 8 1.16376 8H2.5393C3.28166 8 3.78275 8.13365 4.04258 8.40097C4.3024 8.66597 4.43231 9.03091 4.43231 9.49582C4.43231 9.96071 4.3024 10.3268 4.04258 10.5941C3.78275 10.8591 3.28166 10.9916 2.5393 10.9916H1.62882V12.8082C1.62882 12.9361 1.57423 13 1.46507 13H1.16376ZM5.4476 13C5.33842 13 5.28384 12.9361 5.28384 12.8082V8.19177C5.28384 8.06392 5.33842 8 5.4476 8H6.65611C7.58188 8 8.22489 8.21851 8.58515 8.6555C8.9476 9.09019 9.12882 9.7027 9.12882 10.493C9.12882 11.2834 8.9476 11.8994 8.58515 12.341C8.22489 12.7803 7.58188 13 6.65611 13H5.4476ZM9.9869 12.8082C9.9869 12.9361 10.0415 13 10.1507 13H10.452C10.5611 13 10.6157 12.9361 10.6157 12.8082V10.7964H12.5382C12.6583 10.7964 12.7183 10.7441 12.7183 10.6395V10.3815C12.7183 10.2769 12.6583 10.2245 12.5382 10.2245H10.6157V8.57182H12.8199C12.94 8.57182 13 8.51953 13 8.41492V8.15691C13 8.05231 12.94 8 12.8199 8H10.1507C10.0415 8 9.9869 8.06392 9.9869 8.19177V12.8082Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PDFIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PDFIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PDFIcon)
