import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  text-align: left;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    color:${theme.colors.colors.redisign.default};
  `}
`

export const HeaderRow = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  ${({ gradient, theme }) => gradient && `
    background: ${theme.colors.backgrounds.redisign.topDownGradient};
  `}
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px;

  ${({ withoutPadding }) => withoutPadding && `
    padding: 9px 0;
  `}
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
`

export const TreeContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  padding-left: 10px;
  ${({ userTree }) => userTree && `
    padding-left: 0px;
    padding: 10px;
  `}
  ${({ rightPadding }) => rightPadding && 'padding-right: 10px'}
  ${({ isFilterMode }) => (isFilterMode
    && `visibility: visible;
    height: 50px;`)
}
`

export const SidebarWrapper = styled.div`
  height: calc(100vh - 144px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ isFilterMode }) => {
    if (isFilterMode) {
      return `${TreeContainer} {
        height: 50px;
        overflow: hidden;
      }`
    }
  }}
`

export const PageSidebarStyled = styled.div`
  min-width: ${({ theme }) => theme.sizes.sideBarViewTreeMin}px;
  width: 300px;
  flex: 0 0 auto;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  overflow-y: hidden;
  overflow-x: hidden;

  ${({ small, theme }) => small && `
    min-width: 48px;
    width: 48px;

    ${HeaderRow} {
      flex-direction: column;
      height: 100%;
      max-height: 100%;
      justify-content: flex-start;
    }

    ${HeaderContentWrapper} {
      writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
      padding: 25px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    ${IconWrapper} {
      border: none;
      border-bottom: 1px solid ${theme.colors.borders.default}
      svg {
        transform: rotate(180deg);
      }
    }
  `}
`
