import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_APPEALS_TABLE = createAction('@/GET_APPEALS_TABLE')
export const GET_APPEALS_FILE = createAction('@/GET_APPEALS_FILE')
export const GET_ALARMS_COUNT = createAction('@/GET_ALARMS_COUNT')
export const GET_SERVICE_DESK_CARDS = createAction('@/GET_SERVICE_DESK_CARDS')
export const GET_SERVICE_DESK_INFO_WIDGET = createAction('@/GET_SERVICE_DESK_INFO_WIDGET')
export const GET_SERVICE_DESK_INFO_CARD = createAction('@/GET_SERVICE_DESK_INFO_CARD')
export const ADD_SERVICE_DESK_INTEGRATION = createAction('@/ADD_SERVICE_DESK_INTEGRATION')
export const DELETE_SERVICE_DESK_INTEGRATION = createAction('@/DELETE_SERVICE_DESK_INTEGRATION')
export const APPEALS_TABLE = createTableActions('@/APPEALS_TABLE')

export const SET_SELECTED_ELEMENT = '@/APPEALS_SET_SELECTED_ELEMENT'
export const SET_EVETS_DATA = '@/APPEALS_SET_EVETS_DATA'

export const ADD_TENANT_SERVICE_DESK_INEGRATION = '@/ADD_TENANT_SERVICE_DESK_INEGRATION'
export const USER_CHANGE_SERVICE_DESK = '@/USER_CHANGE_SERVICE_DESK'
export const RESET_SERVICE_DESK_TABLE = '@/RESET_SERVICE_DESK_TABLE'

export const setEventsData = (payload) => ({
  type: SET_EVETS_DATA,
  payload,
})
export const setSelectedElement = (payload) => ({
  type: SET_SELECTED_ELEMENT,
  payload,
})

export const setTenantServiceDesk = (payload) => ({
  type: ADD_TENANT_SERVICE_DESK_INEGRATION,
  payload,
})

export const setUserServiceDesk = (payload) => ({
  type: USER_CHANGE_SERVICE_DESK,
  payload,
})

export const resetServiceDeskTable = () => ({
  type: RESET_SERVICE_DESK_TABLE,
})
