import isArray from 'lodash/isArray'

class EventEmitter {
  constructor() {
    this.values = {}
    this.events = {}
  }

  getUnsubscribeFunction(eventName, fn) {
    return () => {
      this.events[eventName] = this.events[eventName].filter((eventFn) => fn !== eventFn)
    }
  }

  emit(eventName, data) {
    const event = this.events[eventName]
    this.values[eventName] = data
    if (event) {
      event.forEach((fn) => {
        fn.call(null, data, this.values)
      })
    }
  }

  subscribe(eventName, fn) {
    const events = isArray(eventName) ? eventName : [eventName]

    events.forEach((event) => {
      if (!this.events[event]) {
        this.events[event] = []
      }

      this.events[event].push(fn)
    })

    return events.length === 1
      ? this.getUnsubscribeFunction(eventName, fn)
      : events.map((event) => this.getUnsubscribeFunction(event, fn))
  }
}

const emitter = new EventEmitter()

export default emitter
