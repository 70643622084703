import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AdminProfilePhotoIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M40 80C45.5229 80 50.7843 78.8807 55.5698 76.8566C56.0319 76.6612 56.4896 76.4573 56.9426 76.2451C57.8591 75.816 58.7567 75.3531 59.6337 74.8581C59.2987 73.8708 59.0887 72.8259 59.0226 71.742C59.0076 71.4966 59 71.2492 59 71C59 69.2396 59.3791 67.5678 60.06 66.0617C60.4805 65.1316 61.0162 64.2646 61.6488 63.4791C63.8483 60.7477 67.2201 59 71 59C71.2492 59 71.4966 59.0076 71.742 59.0226C72.8259 59.0887 73.8708 59.2987 74.8581 59.6337C75.3531 58.7567 75.816 57.8591 76.2451 56.9426C78.6541 51.7979 80 46.0561 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM56 71C56 71.7777 56.0592 72.5416 56.1733 73.2873C51.2873 75.6657 45.7995 77 40 77C30.6214 77 22.0577 73.5106 15.5366 67.7592C14.6876 67.0105 13.8732 66.2234 13.0962 65.4007C6.83723 58.7737 3 49.8349 3 40C3 19.5655 19.5655 3 40 3C60.4345 3 77 19.5655 77 40C77 45.7995 75.6657 51.2873 73.2873 56.1733C72.5416 56.0592 71.7777 56 71 56C66.008 56 61.5856 58.4385 58.859 62.1893C58.2716 62.9974 57.7628 63.8664 57.3441 64.785C56.4809 66.6786 56 68.7831 56 71Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AdminProfilePhotoIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AdminProfilePhotoIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AdminProfilePhotoIcon)
