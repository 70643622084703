import styled from 'styled-components'

export default styled.div`
  color: '';
`

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 144px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`
