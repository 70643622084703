import { all } from 'redux-saga/effects'

import getHeatTableDataSaga from './getHeatTableData'
import getHeatGraphDataSaga from './getHeatGraphData'
import getHeatFileSaga from './getHeatFile'

import getTableDataSaga from './getTableData'
import getGraphDataSaga from './getGraphData'
import getPlanGraphDataSaga from './getPlanGraphData'
import getFileSaga from './getFile'
import getAverageSaga from './getAverage'

export default function* root() {
  yield all([
    getHeatTableDataSaga(),
    getHeatGraphDataSaga(),
    getHeatFileSaga(),

    getTableDataSaga(),
    getGraphDataSaga(),
    getPlanGraphDataSaga(),
    getFileSaga(),
    getAverageSaga(),
  ])
}
