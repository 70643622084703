import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'
import pt from 'prop-types'

import {
  Container,
  ContentContainer,
} from './styles'

const GlobalPopup = ({
  content,
  identifier,
}) => {
  const container = useMemo(() => document.getElementById(identifier), [identifier])

  const renderGlobalPortal = (popupContent) => createPortal(
    (
      <Container>
        <ContentContainer>
          {popupContent}
        </ContentContainer>
      </Container>
    ),
    container,
  )


  return (
    <>
      {renderGlobalPortal(content)}
    </>
  )
}

GlobalPopup.propTypes = {
  content: pt.element,
  identifier: pt.string,
}

GlobalPopup.defaultProps = {
  content: React.fragment,
  identifier: 'globalPopup',
}

export default GlobalPopup
