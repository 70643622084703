import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const RoadIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.0234 0H0.976562C0.437207 0 0 0.437305 0 0.976562V49.0234C0 49.5627 0.437207 50 0.976562 50H49.0234C49.2803 50 49.5322 49.8955 49.7139 49.7138C49.8955 49.5322 50 49.2803 50 49.0234V41.0859C50 40.5469 49.5615 40.1084 49.0234 40.1084C48.4854 40.1084 48.0469 40.5469 48.0469 41.0859V48.0469H37.6953V25.1953C37.6963 23.8915 38.2041 22.666 39.125 21.7422C40.0488 20.8213 41.2744 20.3135 42.5781 20.3125H48.0469V41.0234C48.0469 41.5625 48.4854 42 49.0234 42C49.5615 42 50 41.5625 50 41.0234V0.976562C50 0.437305 49.5628 0 49.0234 0ZM12.3047 48.0469H1.95312V20.3125H7.42188C10.1143 20.3125 12.3047 22.5029 12.3047 25.1953V48.0469ZM48.0469 9.17969H45.5078C44.9685 9.17969 44.5312 9.61699 44.5312 10.1562C44.5312 10.6955 44.9685 11.1328 45.5078 11.1328H48.0469V18.3594H42.5781C41.6553 18.3594 40.7598 18.54 39.917 18.8965C39.1035 19.2412 38.3721 19.7344 37.7441 20.3613C37.1172 20.9893 36.624 21.7207 36.2793 22.5342C35.9229 23.377 35.7422 24.2725 35.7422 25.1953V48.0469H14.2578V25.1953C14.2578 21.426 11.1912 18.3594 7.42188 18.3594H1.95312V11.1328H4.88281C5.42217 11.1328 5.85938 10.6955 5.85938 10.1562C5.85938 9.61699 5.42217 9.17969 4.88281 9.17969H1.95312V1.95312H48.0469V9.17969Z" fill={fill} className="svgFill" />
      <path d="M32.8125 27.1484C33.3519 27.1484 33.7891 26.7111 33.7891 26.1719V19.3359C33.7891 18.7967 33.3519 18.3594 32.8125 18.3594C32.2731 18.3594 31.8359 18.7967 31.8359 19.3359V26.1719C31.8359 26.7111 32.2731 27.1484 32.8125 27.1484Z" fill={fill} className="svgFill" />
      <path d="M27.9297 26.1719C27.9297 26.7111 28.3669 27.1484 28.9062 27.1484C29.4456 27.1484 29.8828 26.7111 29.8828 26.1719V19.3359C29.8828 18.7967 29.4456 18.3594 28.9062 18.3594C28.3669 18.3594 27.9297 18.7967 27.9297 19.3359V26.1719Z" fill={fill} className="svgFill" />
      <path d="M25 29.1016C24.4606 29.1016 24.0234 29.5389 24.0234 30.0781V33.9844C24.0234 34.5236 24.4606 34.9609 25 34.9609C25.5394 34.9609 25.9766 34.5236 25.9766 33.9844V30.0781C25.9766 29.5389 25.5394 29.1016 25 29.1016Z" fill={fill} className="svgFill" />
      <path d="M25 40.2344C24.4606 40.2344 24.0234 40.6717 24.0234 41.2109V45.1172C24.0234 45.6564 24.4606 46.0938 25 46.0938C25.5394 46.0938 25.9766 45.6564 25.9766 45.1172V41.2109C25.9766 40.6717 25.5394 40.2344 25 40.2344Z" fill={fill} className="svgFill" />
      <path d="M25 18.3594C24.4606 18.3594 24.0234 18.7967 24.0234 19.3359V26.1719C24.0234 26.7111 24.4606 27.1484 25 27.1484C25.5394 27.1484 25.9766 26.7111 25.9766 26.1719V19.3359C25.9766 18.7967 25.5394 18.3594 25 18.3594Z" fill={fill} className="svgFill" />
      <path d="M16.2109 19.3359V26.1719C16.2109 26.7111 16.6481 27.1484 17.1875 27.1484C17.7269 27.1484 18.1641 26.7111 18.1641 26.1719V19.3359C18.1641 18.7967 17.7269 18.3594 17.1875 18.3594C16.6481 18.3594 16.2109 18.7967 16.2109 19.3359Z" fill={fill} className="svgFill" />
      <path d="M21.0938 27.1484C21.6331 27.1484 22.0703 26.7111 22.0703 26.1719V19.3359C22.0703 18.7967 21.6331 18.3594 21.0938 18.3594C20.5544 18.3594 20.1172 18.7967 20.1172 19.3359V26.1719C20.1172 26.7111 20.5544 27.1484 21.0938 27.1484Z" fill={fill} className="svgFill" />
      <path d="M12.1094 11.1328H16.0156C16.555 11.1328 16.9922 10.6955 16.9922 10.1562C16.9922 9.61699 16.555 9.17969 16.0156 9.17969H12.1094C11.57 9.17969 11.1328 9.61699 11.1328 10.1562C11.1328 10.6955 11.57 11.1328 12.1094 11.1328Z" fill={fill} className="svgFill" />
      <path d="M23.2422 11.1328H27.1484C27.6878 11.1328 28.125 10.6955 28.125 10.1562C28.125 9.61699 27.6878 9.17969 27.1484 9.17969H23.2422C22.7028 9.17969 22.2656 9.61699 22.2656 10.1562C22.2656 10.6955 22.7028 11.1328 23.2422 11.1328Z" fill={fill} className="svgFill" />
      <path d="M34.375 11.1328H38.2812C38.8206 11.1328 39.2578 10.6955 39.2578 10.1562C39.2578 9.61699 38.8206 9.17969 38.2812 9.17969H34.375C33.8356 9.17969 33.3984 9.61699 33.3984 10.1562C33.3984 10.6955 33.8356 11.1328 34.375 11.1328Z" fill={fill} className="svgFill" />
    </svg>
  )
}

RoadIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

RoadIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(RoadIcon)
