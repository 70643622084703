import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MeshIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7892 8.06203L14.6612 8.55158L6.67862 22.77L5.80664 22.2804L13.7892 8.06203Z" fill={fill} className="svgFill" />
      <path d="M13.6737 2.12366L14.6737 2.11164L14.758 9.12713L13.7581 9.13916L13.6737 2.12366Z" fill={fill} className="svgFill" />
      <path d="M18.5315 11.8713L19.0419 12.7312L10.4424 17.835L9.932 16.9751L18.5315 11.8713Z" fill={fill} className="svgFill" />
      <path d="M14.1256 19.7191L13.636 20.5911L9.27613 18.1433L9.76568 17.2713L14.1256 19.7191Z" fill={fill} className="svgFill" />
      <path d="M15.7779 8.60514C15.3724 9.32751 14.458 9.58434 13.7356 9.17878C13.0133 8.77322 12.7564 7.85886 13.162 7.13649C13.5676 6.41412 14.4819 6.1573 15.2043 6.56285C15.9267 6.96841 16.1835 7.88277 15.7779 8.60514Z" fill={fill} className="svgFill" />
      <path d="M15.6247 21.1342C15.2192 21.8565 14.3048 22.1134 13.5824 21.7078C12.8601 21.3023 12.6033 20.3879 13.0088 19.6655C13.4144 18.9432 14.3287 18.6863 15.0511 19.0919C15.7735 19.4974 16.0303 20.4118 15.6247 21.1342Z" fill={fill} className="svgFill" />
      <path d="M15.6097 2.77661C15.2042 3.49898 14.2898 3.75581 13.5675 3.35025C12.8451 2.9447 12.5883 2.03033 12.9938 1.30797C13.3994 0.585598 14.3137 0.328771 15.0361 0.734326C15.7585 1.13988 16.0153 2.05425 15.6097 2.77661Z" fill={fill} className="svgFill" />
      <path d="M16.8181 14.9232C16.4125 15.6456 15.4982 15.9024 14.7758 15.4969C14.0534 15.0913 13.7966 14.1769 14.2022 13.4546C14.6077 12.7322 15.5221 12.4754 16.2444 12.8809C16.9668 13.2865 17.2236 14.2008 16.8181 14.9232Z" fill={fill} className="svgFill" />
      <path d="M20.0307 13.2864C19.6251 14.0088 18.7108 14.2656 17.9884 13.86C17.266 13.4545 17.0092 12.5401 17.4148 11.8177C17.8203 11.0954 18.7347 10.8385 19.457 11.2441C20.1794 11.6497 20.4362 12.564 20.0307 13.2864Z" fill={fill} className="svgFill" />
      <path d="M10.3929 18.1969C9.98733 18.9192 9.07296 19.1761 8.35059 18.7705C7.62823 18.365 7.3714 17.4506 7.77695 16.7282C8.18251 16.0059 9.09687 15.749 9.81924 16.1546C10.5416 16.5601 10.7984 17.4745 10.3929 18.1969Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MeshIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MeshIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MeshIcon)
