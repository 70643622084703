import React, { useRef, useEffect } from 'react'
import { withRouter } from 'react-router'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage as Lang } from 'react-intl'
import { Formik } from 'formik'
import Button from '@/components/blocks/Button'
import { signUpValidation } from '@/constants/validationRegistration/signUp'
import {
  PASSWORD,
  PASSWORD_CONFIRMATION,
} from '@/constants/forms/signUp'
import {
  Form,
  Buttons,
  TextFieldStyled,
  Text,
  StyledButton,
} from './styles'

const SignUpForm = ({
  handleRequirements,
  onSetUsersPassword,
  initialValues,
  handleSetFormData,
}) => {
  const form = useRef(null)
  useEffect(() => {
    if (!form.current.isDirty && !isEmpty(initialValues)) {
      form.current.validateForm()
    }
  }, [form, initialValues])
  const renderButton = (errors, values) => {
    if (errors[PASSWORD] || errors[PASSWORD_CONFIRMATION] || Object.keys(values).length === 0) {
      return React.Fragment
    }
    return (
      <Buttons>
        <Button type="submit">
          <Lang id="signUp.signUpButton" />
        </Button>
      </Buttons>
    )
  }
  const onSubmitForm = (values, formData) => {
    onSetUsersPassword({ values, form: formData })
  }

  return (
    <Formik
      ref={form}
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validationSchema={signUpValidation}
      enableReinitialize
      render={({
        handleSubmit, errors, values,
      }) => {
        handleSetFormData(values)
        return (
          <Form onSubmit={handleSubmit} id="userData" name="userData">
            <Text>
              <Lang id="signUp.password" />
            </Text>
            <TextFieldStyled
              name={PASSWORD}
              fieldProps={{
                type: 'password',
                autoComplete: 'new-password',
              }}
            />
            <Text>
              <Lang id="signUp.passwordConfirmation" />
            </Text>
            <TextFieldStyled
              name={PASSWORD_CONFIRMATION}
              fieldProps={{
                type: 'password',
              }}
            />
            <StyledButton styleType="text" type="button" onClick={handleRequirements}>
              <Lang id="signUp.requirements" />
            </StyledButton>

            {renderButton(errors, values)}
          </Form>
        )
      }}
    />
  )
}

SignUpForm.propTypes = {
  onFormSubmit: pt.func,
  error: pt.string,
}
SignUpForm.defaultProps = {
  onFormSubmit: noop,
  error: null,
}

export default React.memo(withRouter(SignUpForm))
