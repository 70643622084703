import { connect } from 'react-redux'
import { getProfileOpenState, getUserData } from '@/store/selectors/appSettings'
import { toggleUserProfile } from '@/store/actions/appSettings'
import { requestUserLogout } from '@/store/actions/user/logout'
import { requestGetCurrentUser } from '@/store/actions/users/getCurrentUser'
import UserSidebar from '@/components/regions/sidebars/UserSidebar'

export default connect(
  (state) => ({
    isOpen: getProfileOpenState(state),
    user: getUserData(state),
  }),
  (dispatch) => ({
    onClose: () => dispatch(toggleUserProfile(false)),
    onLogout: () => dispatch(requestUserLogout(false)),
    onGetUser: (data) => dispatch(requestGetCurrentUser(data)),
  }),
)(UserSidebar)
