import React from 'react'
import {
  take, call, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import { ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT } from '@/constants/apiRoutes'
import {
  successGetAlarmsCount,
  errorGetAlarmsCount,
  beginGetAlarmsCountNotification,
} from '@/store/actions/alarmManager/getAlarmsCount'
import { createAlertNotificationChannel } from './channels'

function* alarmsCountEventSaga() {
  try {
    const channel = yield call(createAlertNotificationChannel)
    while (true) {
      yield take(channel)
      const toast = createNotifications()
      const response = yield request({
        url: ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT,
        method: 'get',
      })
      const { unreadCount } = response.data
      if (unreadCount > 0) {
        yield put(beginGetAlarmsCountNotification())
        toast({
          title: 'ОБНАРУЖЕНЫ НЕИСПРАВНОСТИ',
          description: (
            <>
              У вас
              {' '}
              <strong>{unreadCount}</strong>
              {' '}
              новых оповещений о неисправностях
              с оборудованием. Подробная информация в
              разделе: Журнал неисправностей
            </>
          ),
          type: 'error',
        })
      }
      yield put(successGetAlarmsCount(response.data))
    }
  } catch (error) {
    yield put(errorGetAlarmsCount(error))
    console.log('TCL: function*alarmsCountEventSaga1 -> error', error)
  }
}

export default alarmsCountEventSaga
