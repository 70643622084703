import BASE_STATION from './BASE_STATION'
import CONTACTOR from './CONTACTOR'
import CONTROL_CUPBOARD from './CONTROL_CUPBOARD'
import DOOR_OPEN_SENSOR from './DOOR_OPEN_SENSOR'
import LAMP from './LAMP'
import LIGHT_FIXTURE_GROUP from './LIGHT_FIXTURE_GROUP'
import LIGHT_MODULE from './LIGHT_MODULE'
import LINE from './LINE'
import METEO from './METEO'
import MULTI_RATE_METER from './MULTI_RATE_METER'
import ROAD_TEMPERATURE_SENSOR from './ROAD_TEMPERATURE_SENSOR'
import SHUO from './SHUO'
import SMOKE_SENSOR from './SMOKE_SENSOR'
import UTILITY_POLE from './UTILITY_POLE'
import LIGHT_POINT from './LIGHT_POINT'
import LIGHT_FIXTURE from './LIGHT_FIXTURE'
import LAMP_MODULE from './LAMP_MODULE'
import CONTROLLER from './CONTROLLER'
import MULTIRATE_METER from './MULTIRATE_METER'

export default {
  BASE_STATION,
  CONTACTOR,
  CONTROL_CUPBOARD,
  DOOR_OPEN_SENSOR,
  LAMP,
  LIGHT_FIXTURE_GROUP,
  LINE,
  LIGHT_MODULE,
  METEO,
  MULTI_RATE_METER,
  ROAD_TEMPERATURE_SENSOR,
  SHUO,
  SMOKE_SENSOR,
  UTILITY_POLE,
  LIGHT_POINT,
  LIGHT_FIXTURE,
  LAMP_MODULE,
  CONTROLLER,
  MULTIRATE_METER,
}
