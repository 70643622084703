import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LEPIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.95914 8.4152H10.0408L10.2272 9.25346L9.00021 10.1909L7.77286 9.25302L7.95914 8.4152Z" fill={fill} className="svgFill" />
      <path d="M9.57674 6.32736L9.84995 7.55628H8.15003L8.42323 6.32736H9.57674Z" fill={fill} className="svgFill" />
      <path d="M9.38586 5.46855L9.09705 4.16943C9.08822 4.12916 9.0517 4.09922 9.0086 4.09564L8.99994 4.09528C8.95333 4.09528 8.91248 4.12648 8.90292 4.16943L8.61412 5.46855H9.38586Z" fill={fill} className="svgFill" />
      <path d="M8.99994 14.4922L10.4993 15.3412H7.50073L8.99994 14.4922Z" fill={fill} className="svgFill" />
      <path d="M10.6472 12.5566L8.99994 13.4893L7.35286 12.5566L9.00021 11.298L10.6472 12.5566Z" fill={fill} className="svgFill" />
      <path d="M10.4376 10.1997L9.72462 10.7445L10.7294 11.5124L10.4376 10.1997Z" fill={fill} className="svgFill" />
      <path d="M7.5625 10.1993L7.27054 11.5125L8.27581 10.7444L7.5625 10.1993Z" fill={fill} className="svgFill" />
      <path d="M8.11468 13.9908L6.8756 13.2892L6.51872 14.8945L8.11468 13.9908Z" fill={fill} className="svgFill" />
      <path d="M11.1244 13.2892L9.88539 13.9908L11.4813 14.8945L11.1244 13.2892Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9.45709 3.3398V2.22946C9.45709 1.99226 9.25241 1.8 8.99994 1.8C8.74766 1.8 8.54298 1.99226 8.54298 2.22946V3.33978C8.27545 3.46776 8.07239 3.70468 8.0081 3.99388L7.68026 5.46855H6.58355C6.33117 5.46855 6.12649 5.66081 6.12649 5.89801C6.12649 6.1351 6.33117 6.32736 6.58355 6.32736H7.48937L7.21617 7.55628H5.85707C5.6047 7.55628 5.40002 7.74854 5.40002 7.98574V9.00352C5.40002 9.24061 5.6047 9.43287 5.85707 9.43287C6.10945 9.43287 6.31413 9.24061 6.31413 9.00352V8.4152H7.02528L5.40967 15.6828C5.38153 15.8093 5.41553 15.9411 5.50236 16.0414C5.58919 16.1418 5.71948 16.2 5.85707 16.2H12.1428C12.2805 16.2 12.4108 16.1418 12.4976 16.0414C12.584 15.9411 12.6184 15.8091 12.5904 15.6828L10.9748 8.4152H11.6859V9.02978C11.6859 9.26698 11.8905 9.45923 12.1429 9.45923C12.3954 9.45923 12.6 9.26698 12.6 9.02978V7.98574C12.6 7.74854 12.3954 7.55628 12.1429 7.55628H10.7838L10.5106 6.32736H11.4163C11.4321 6.32736 11.4477 6.3266 11.463 6.32514C11.6935 6.30316 11.8734 6.12028 11.8734 5.89801C11.8734 5.66081 11.6688 5.46855 11.4163 5.46855H10.3196L9.99178 3.99375C9.92758 3.70466 9.72462 3.46778 9.45709 3.3398Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LEPIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LEPIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LEPIcon)
