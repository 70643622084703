import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_REPORT_MANAGER_TABLE = createAction('@/REPORT_MANAGER_PAGE_GET_REPORT_MANAGER_TABLE')
export const REPORT_MANAGER_TABLE = createTableActions('@/REPORT_MANAGER_PAGE_REPORT_MANAGER_TABLE')

export const GET_REPORT_MANAGER_TYPES = createAction('@/REPORT_MANAGER_PAGE_GET_REPORT_MANAGER_TYPES')
export const GET_REPORT_MANAGER_OBJECT_TYPES = createAction('@/REPORT_MANAGER_PAGE_GET_REPORT_MANAGER_OBJECT_TYPES')
export const GET_REPORT_MANAGER_USERS = createAction('@/REPORT_MANAGER_PAGE_GET_REPORT_MANAGER_USERS')
export const GET_REPORT_MANAGER_FILE = createAction('@/REPORT_MANAGER_PAGE_GET_REPORT_MANAGER_FILE')
export const CREATE_REPORT = createAction('@/REPORT_MANAGER_PAGE_CREATE_REPORT')
export const GET_REPORT_MANAGER = createAction('@/REPORT_MANAGER_PAGE_GET_REPORT_MANAGER')
export const DELETE_REPORT_MANAGER = createAction('@/REPORT_MANAGER_PAGE_DELETE_REPORT_MANAGER')
export const CHANGE_REPORT_MANAGER = createAction('@/REPORT_MANAGER_PAGE_CHANGE_REPORT_MANAGER')

export const SET_GRAPH_OPTIONS = '@/REPORT_MANAGER_PAGE_LIVE_TIME_REPORT_SET_GRAPH_OPTIONS'
export const IS_FORM_MODIFYING = '@/REPORT_MANAGER_PAGE_IS_FORM_MODIFYING'

export const setTableData = (payload) => ({
  type: SET_GRAPH_OPTIONS,
  payload,
})

export const setFormIsModifying = (payload) => ({
  type: IS_FORM_MODIFYING,
  payload,
})
