export default {
  widgetTitle: 'Настройки виджета',
  applicationTitle: 'настройки приложения',
  deleteWidget: 'Удалить виджет?',
  deleteApplication: 'Удалить приложение?',
  yes: 'да',
  no: 'нет',
  urbanProcess: 'Выберите городской процесс:',
  unpinMenu: 'Открепить меню',
  pinMenu: 'Закрепить меню',
}
