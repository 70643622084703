import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const RoadIcon = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9122 14.6113L13.5438 1.27792C13.3885 0.80375 13.05 0.5 12.6776 0.5H9.6279L9.7044 1.465C9.7201 1.66167 9.6041 1.83333 9.4557 1.83333H8.5445C8.396 1.83333 8.2801 1.66167 8.2957 1.465L8.3723 0.5H5.3226C4.94979 0.5 4.61136 0.80375 4.45605 1.27792L0.0876411 14.6113C-0.201419 15.4942 0.259832 16.5 0.954512 16.5H7.1057L7.4279 12.43C7.4548 12.0908 7.6691 11.8333 7.9251 11.8333H10.0751C10.331 11.8333 10.5454 12.0908 10.5723 12.43L10.8944 16.5H17.0456C17.7403 16.5 18.2016 15.4942 17.9122 14.6113ZM8.1376 3.465C8.1441 3.38308 8.1731 3.30726 8.219 3.25217C8.2649 3.19708 8.3245 3.16662 8.3863 3.16667H9.6141C9.7419 3.16667 9.8494 3.29542 9.8629 3.465L10.0066 5.2808C10.0301 5.5763 9.8563 5.8333 9.6338 5.8333H8.367C8.1441 5.8333 7.9707 5.5763 7.9941 5.2808L8.1376 3.465ZM9.8638 10.5H8.136C7.8391 10.5 7.6076 10.1571 7.6388 9.7633L7.7973 7.7633C7.8241 7.4242 8.0385 7.1667 8.2945 7.1667H9.7054C9.9613 7.1667 10.1757 7.4242 10.2026 7.7633L10.361 9.7633C10.3923 10.1571 10.1607 10.5 9.8638 10.5Z" fill="#3D3D3D"/>
    </svg>
  )
}

RoadIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

RoadIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(RoadIcon)
