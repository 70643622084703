import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import qs from 'query-string'
import request from '@/helpers/axios'
import {
  SET_SELECTED_ELEMENT,
} from '@/store/actions/analyticsAlarms'
import { ALARMS_ANALYTICS_ALARM_ENDPOINT } from '@/constants/apiRoutes'
import {
  successAnalyticsAlarmsAlarm,
  errorAnalyticsAlarmsAlarm,
} from '@/store/actions/analyticsAlarms/getAlarmInformation'
import { getSelectedElement } from '@/store/selectors/analyticsAlarms'

function* getAnalyticsAlarmsAlarmSaga() {
  try {
    const selectedElement = yield select(getSelectedElement)
    const { id, installationId } = selectedElement

    const response = yield request({
      url: `${ALARMS_ANALYTICS_ALARM_ENDPOINT}?${qs.stringify({
        installationId,
        objectId: id,
      })}`,
      method: 'get',
    })

    if (response.data) {
      yield put(successAnalyticsAlarmsAlarm({
        ...selectedElement,
        ...response.data,
      }))
    }
  } catch (error) {
    yield put(errorAnalyticsAlarmsAlarm(error))
  }
}

export default function* root() {
  yield takeLatest(SET_SELECTED_ELEMENT, getAnalyticsAlarmsAlarmSaga)
}
