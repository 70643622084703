import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GraphicIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.63638 15.6666L7.0602 15.7233L10.783 11.9316C10.7181 11.6846 10.7098 11.4258 10.7588 11.1751C10.8078 10.9244 10.9128 10.6886 11.0656 10.4858C11.2184 10.2831 11.415 10.1188 11.6401 10.0058C11.8651 9.89274 12.1127 9.83395 12.3637 9.83395C12.6147 9.83395 12.8623 9.89274 13.0873 10.0058C13.3124 10.1188 13.509 10.2831 13.6618 10.4858C13.8146 10.6886 13.9196 10.9244 13.9686 11.1751C14.0176 11.4258 14.0093 11.6846 13.9444 11.9316L16.0308 14.0566L16.4546 13.9999C16.6019 13.9999 16.7435 14.0199 16.8785 14.0566L19.783 11.0982C19.6898 10.7438 19.7139 10.368 19.8518 10.029C19.9896 9.68999 20.2334 9.4068 20.5454 9.22333C20.8573 9.03986 21.22 8.96637 21.5772 9.01426C21.9343 9.06214 22.266 9.22872 22.5207 9.48817C22.7754 9.74761 22.939 10.0854 22.986 10.4492C23.033 10.813 22.9609 11.1824 22.7807 11.5001C22.6006 11.8178 22.3226 12.0662 21.9897 12.2066C21.6569 12.3469 21.2879 12.3716 20.94 12.2766L18.0354 15.2349C18.0956 15.4641 18.1071 15.7036 18.0691 15.9377C18.0311 16.1717 17.9446 16.3947 17.8152 16.592C17.6858 16.7892 17.5165 16.956 17.3188 17.0812C17.121 17.2065 16.8993 17.2873 16.6684 17.3183C16.4375 17.3492 16.2028 17.3297 15.9799 17.2609C15.757 17.192 15.5512 17.0756 15.376 16.9192C15.2009 16.7629 15.0606 16.5703 14.9644 16.3543C14.8682 16.1382 14.8184 15.9038 14.8183 15.6666L14.8739 15.2349L12.7875 13.1099C12.5098 13.1853 12.2176 13.1853 11.9399 13.1099L8.21712 16.9016L8.27276 17.3333C8.27276 17.6629 8.17678 17.9852 7.99698 18.2593C7.81717 18.5334 7.5616 18.747 7.26259 18.8731C6.96358 18.9993 6.63456 19.0323 6.31714 18.968C5.99971 18.9037 5.70814 18.7449 5.47928 18.5118C5.25043 18.2787 5.09458 17.9818 5.03144 17.6585C4.9683 17.3352 5.00071 17 5.12456 16.6955C5.24842 16.3909 5.45815 16.1306 5.72726 15.9475C5.99636 15.7644 6.31273 15.6666 6.63638 15.6666Z" fill={fill} className="svgFill" />
    </svg>
  )
}

GraphicIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GraphicIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GraphicIcon)
