import widgets from '../widgets'

const {
  CHECK_POINT_STATE,
  ALARM_STATE,
} = widgets

export default [
  CHECK_POINT_STATE({}),
  ALARM_STATE({}),
]
