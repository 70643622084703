import { USER_LOGIN } from './index'

export const loginUser = ([values, form]) => ({
  type: USER_LOGIN.BEGIN,
  payload: {
    values,
    form,
  },
})

export const loginSuccess = (data) => ({
  type: USER_LOGIN.SUCCESS,
  payload: { data },
})

export const loginError = (error) => ({
  type: USER_LOGIN.ERROR,
  payload: { error },
})
