import { GET_REPORT_MANAGER_TABLE } from './index'

export const requestReportManagerTable = (payload) => ({
  type: GET_REPORT_MANAGER_TABLE.REQUEST,
  payload,
})

export const successReportManagerTable = (payload) => ({
  type: GET_REPORT_MANAGER_TABLE.SUCCESS,
  payload,
})

export const errorReportManagerTable = (payload) => ({
  type: GET_REPORT_MANAGER_TABLE.ERROR,
  payload,
})
