import {
  KULON,
  BRIZ,
  ONE_SIM,
  MESH,
  MULTIRATE_METER,
  ELECTRIC_METER,
  CONTROLLER,
  CONTACTOR,
  BRANCH_CIRCUIT,
  LAMP,
  PHASE,
  LIGHT_SENSOR,
  PHOTO_SENSOR,
  VOLUME_SENSOR,
  FIRE_SENSOR,
  TRANSFER_SWITCH,
  DMX_DEVICE,
  CONTROLLER_FUSE,
  FUSE,
  CHECKPOINT,
  CURRENT_SENSOR,

  SHUO,
  CONTROL_CUPBOARD,
  MULTI_RATE_METER,
  METEO,
  ROAD_TEMPERATURE_SENSOR,
  DOOR_OPEN_SENSOR,
  SMOKE_SENSOR,
  BASE_STATION,
  LIGHT_FIXTURE_GROUP,
  UTILITY_POLE,
  LIGHT_MODULE,
  LINE,
  COMPANY_OBJECT,
  DEVICE,
  WATHER_SUPPLY,
  CENTRAL_HEATING,
  SWITCHING_GROUP,
  STREETLIGHT_CONTROL_CABINET,
  STREETLIGHT_GROUP,
  STREETLIGHT,
  AMBIENT_LIGHT_SENSOR,
  WASTE_CONTAINER,
  WITHOUT_ASU,
  LIGHT_FIXTURE,
  SENSOR,
  LIGHT_POINT,
  LAMP_MODULE,
  PHASE_CIRCUIT_BREAKER,
} from '@/constants/instalationPassport/types'

import {
  LIGHT as LIGHT_LOCAL,
  HEATING_DEVICE as HEATING_DEVICE_LOCAL,
  COLD_WATER_DEVICE as COLD_WATER_DEVICE_LOCAL,
  TRASH as TRASH_LOCAL,
  BASE_STATION as BASE_STATION_LOCAL,
  SHUO as SHUO_LOCAL,
  MULTI_TARIFF_ELECTRICITY_METER as MULTI_TARIFF_ELECTRICITY_METER_LOCAL,
  LET as LET_LOCAL,
  LIGHT_MODULE as LIGHT_MODULE_LOCAL,
  MULTIRATE_METER as MULTIRATE_METER_LOCAL,
  LIGHT_FIXTURE as LIGHT_FIXTURE_LOCAL,
  UTILITY_POLE as UTILITY_POLE_LOCAL,
  LIGHT_SENSOR as LIGHT_SENSOR_LOCAL,
  DMX_DEVICE as DMX_DEVICE_LOCAL,

} from '@/constants/objectTypes'

export default {
  integrationType: {
    [KULON]: 'ACS "KULON"',
    [BRIZ]: 'ACS "BRIZ"',
    [ONE_SIM]: 'ACS "1SIM"',
    [MESH]: 'ACS "MESH"',
    [WITHOUT_ASU]: 'Without ACS',
  },
  objectTypes: {
    [ELECTRIC_METER]: 'Multi-tariff meter',
    [MULTIRATE_METER]: 'Multi-tariff meter',
    [MULTI_TARIFF_ELECTRICITY_METER_LOCAL]: 'Multi-tariff meter',
    [MULTIRATE_METER_LOCAL]: 'Multi-tariff meter',
    [CONTROLLER]: 'Controller',
    [CONTACTOR]: 'Contactor',
    [BRANCH_CIRCUIT]: 'Outgoing line',
    [LAMP]: 'Lamp',
    [LIGHT_LOCAL]: 'Light',
    [LIGHT_POINT]: 'Light',
    [PHASE]: 'Phase',
    [LIGHT_SENSOR]: 'Light sensor',
    [LIGHT_SENSOR_LOCAL]: 'Light sensor',
    [PHOTO_SENSOR]: ' Photo sensor',
    [VOLUME_SENSOR]: 'Volume sensor',
    [FIRE_SENSOR]: 'Fire sensor',
    [TRANSFER_SWITCH]: 'ATS',
    [DMX_DEVICE]: 'DMX',
    [DMX_DEVICE_LOCAL]: 'DMX',
    [CONTROLLER_FUSE]: 'Controller Fuse',
    [FUSE]: 'Fuse',
    [CHECKPOINT]: 'Checkpoint',
    [CURRENT_SENSOR]: 'Current sensor',
    [SHUO]: 'LCB',
    [SHUO_LOCAL]: 'LCB',
    [CONTROL_CUPBOARD]: 'Control cabinet',
    [MULTI_RATE_METER]: 'Multi-rate meter',
    [METEO]: 'Weather sensor',
    [ROAD_TEMPERATURE_SENSOR]: 'Road temperature sensor',
    [DOOR_OPEN_SENSOR]: 'Door open sensor',
    [SMOKE_SENSOR]: 'Smoke sensor',
    [BASE_STATION]: 'Base station',
    [BASE_STATION_LOCAL]: 'Base station',
    [LIGHT_FIXTURE_GROUP]: 'Light Spot',
    [UTILITY_POLE]: 'Prop',
    [UTILITY_POLE_LOCAL]: 'Prop',
    [LIGHT_MODULE]: 'Light module',
    [LINE]: 'Line',
    [LET_LOCAL]: 'Line',
    [COMPANY_OBJECT]: 'Object',
    [DEVICE]: 'Device',
    [WATHER_SUPPLY]: 'Cold water metering unit',
    [COLD_WATER_DEVICE_LOCAL]: 'Cold water metering unit',
    [CENTRAL_HEATING]: 'CH metering unit',
    [HEATING_DEVICE_LOCAL]: 'CH metering unit',
    [SWITCHING_GROUP]: 'SHUNO Group',
    [STREETLIGHT_CONTROL_CABINET]: 'SHUNO',
    [STREETLIGHT_GROUP]: 'Lights group',
    [STREETLIGHT]: 'Light',
    [AMBIENT_LIGHT_SENSOR]: 'Light sensor',
    [WASTE_CONTAINER]: 'Trash can',
    [LIGHT_FIXTURE]: 'Light Fixture',
    [LIGHT_FIXTURE_LOCAL]: 'Light Fixture',
    [SENSOR]: 'Sensor',
    [LAMP_MODULE]: 'Lamp module',
    [LIGHT_MODULE_LOCAL]: 'Lamp module',
    [PHASE_CIRCUIT_BREAKER]: 'Phase Breaker',
    [TRASH_LOCAL]: 'Trash Can',
  },
}
