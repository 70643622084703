export const REPORT_TITLE = 'reportTitle'
export const REPORT_TYPE = 'reportType'
export const TYPE_OBJECT = 'typeObject'
export const REPLAY = 'replay'
export const MONTHLY = 'MONTHLY'
export const WEEKLY = 'WEEKLY'
export const DAILY = 'DAILY'
export const HOURLY = 'HOURLY'
export const REPLAY_HOURS = 'replayHours'
export const REPLAY_MINUTES = 'replayMinutes'
export const RECIPIENT_ID = 'recipientId'
export const USER_NAME = 'userName'
export const USER_EMAIL = 'userEmail'
export const REPLAY_WEEKLY = 'replayWeekly'
export const REPLAY_MONTHLY = 'replayMonthly'
export const TIMEZONE = 'timeZone'
export const SYSTEM_USERS = 'systemUsers'
export const EXTERNAL_RECIPIENTS = 'externalRecipients'
export const TIME = 'time'
export const HOURLY_TIME = 'timeValue'
export const GEO_ZONE_ID = 'geoZoneId'
export const USERS_ID = 'usersId'
export const EMAIL = 'email'
