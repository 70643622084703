import get from 'lodash/get'
import {
  INFO,
} from '@/constants/objectStatuses'
import {
  CITY,
  CONTROLLER,
  LIGHT,
  SHUO,
  BASE_STATION,
} from '@/constants/objectTypes'
import { KULON } from '@/constants/integrations'
import {
  OUTDOOR_LIGHTING,
} from '@/constants/names'

const mapController = (
  city = {},
  selectors = {
    controllers: '',
    parameters: '',
  },
  integration = {},
  elementType,
  integrationTypes,
  objectTypes = [],
  onlyChildren = false,
) => {
  const includesLights = objectTypes.length > 0 ? objectTypes.includes(LIGHT) : true
  const controllers = get(city, selectors.controllers, []).map((station) => {
    const stationElements = (station.lightFixtures || []).map((light) => ({
      internalId: city.id,
      geoZoneId: station.groupId,
      parentId: station.objectId,
      type: LIGHT,
      system: KULON,
      id: light.address,
      name: light.description,
      status: INFO,
      location: [light.latitude, light.longitude],
      alias: integration.alias,
      aliasId: integration.aliasId,
      integrationTypes,
    })).sort((first, second) => {
      if (first.name > second.name) {
        return 1
      }
      if (second.name > first.name) {
        return -1
      }
      return 0
    })
    return {
      internalId: city.id,
      id: station.objectId,
      type: elementType,
      integrationTypes,
      geoZoneId: station.groupId,
      system: KULON,
      status: INFO,
      name: station.description || `Контроллер ID${station.objectId}`,
      location: [
        get(station, selectors.parameters, [])[0]?.latitude || null,
        get(station, selectors.parameters, [])[0]?.longitude || null,
      ],
      children: includesLights ? stationElements : [],
      alias: integration.alias,
      aliasId: integration.aliasId,
    }
  })

  if (onlyChildren) {
    return controllers.reduce((accumulator, controller) => ([
      ...accumulator,
      ...controller.children,
    ]), [])
  }
  return controllers
}

const buildKulonTree = (data, integrationsIds = [], objectTypes = []) => {
  const newData = data.reduce((accumulator, integration) => {
    if (integrationsIds.length > 0
      && !integrationsIds.includes(integration.aliasId)
    ) {
      return accumulator
    }
    return [
      ...accumulator,
      ...integration.lightingInstallations.map((city) => {
        const controllersWithLight = mapController(
          city,
          {
            controllers: 'controlCupboards',
            parameters: 'controlCupboardParameters',
          },
          integration,
          SHUO,
          [OUTDOOR_LIGHTING],
          objectTypes,
          objectTypes.length > 0
            ? !objectTypes.includes(SHUO)
              && objectTypes.includes(LIGHT)
            : false,
        )
        const stationsWithLight = mapController(
          city,
          {
            controllers: 'baseStations',
            parameters: 'baseStationParameters',
          },
          integration,
          CONTROLLER,
          [OUTDOOR_LIGHTING],
          objectTypes,
          objectTypes.length > 0
            ? !objectTypes.includes(BASE_STATION)
              && objectTypes.includes(LIGHT)
            : false,
        )
        return ({
          internalId: city.id,
          alias: integration.alias,
          aliasId: integration.aliasId,
          id: city.externalGroupId,
          name: city.description || `Геозона ID${city.externalGroupId}`,
          type: CITY,
          location: [city.latitude || 56.484685, city.longitude || 65.323673],
          children: [...stationsWithLight, ...controllersWithLight],
        })
      }),
    ]
  }, [])

  return newData
}

export default buildKulonTree
