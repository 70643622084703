import { handleActions } from 'redux-actions'
import {
  GET_TABLE,
  GENERATE_TABLE,
} from '@/store/actions/objectControllStatistic'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import { REQUEST_STATUSES } from '@/constants/requests'
import { fields } from '@/constants/tablesConfig/ObjectControllStatistic'

const initialState = {
  table: generateDefaultTableState(
    fields,
    { field: 'data', direction: 'DESC' },
  ),
  dataRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
}

export const reducer = handleActions(
  {
    [GET_TABLE.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [GET_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      status: REQUEST_STATUSES.IDLE,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_TABLE.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.ERROR,
    }),
    ...generateDefaultTableReducer(GENERATE_TABLE),
  },
  initialState,
)

export default reducer
