export const RATING_DYNAMIC = 'RATING_DYNAMIC'
export const NATIONAL_RATING = 'NATIONAL_RATING'

export const PUBLIC_APPEALS = 'PUBLIC_APPEALS'
export const APPEALS_DYNAMIC = 'APPEALS_DYNAMIC'
export const APPEALS_TIMING = 'APPEALS_TIMING'

export const INFRASTRUCTURE = 'INFRASTRUCTURE'
export const CONNECTED_DEVICES = 'CONNECTED_DEVICES'
export const LIGHTS_STATE = 'LIGHTS_STATE'

export const RATING_COLUMN_NAME = 'RATING_COLUMN_NAME'
export const APPEALS_COLUMN_NAME = 'APPEALS_COLUMN_NAME'
export const INFRASTRUCTURE_COLUMN_NAME = 'INFRASTRUCTURE_COLUMN_NAME'

export const RATING_COLUMN = [RATING_DYNAMIC, NATIONAL_RATING]
export const APPEALS_COLUMN = [PUBLIC_APPEALS, APPEALS_DYNAMIC, APPEALS_TIMING]
export const INFRASTRUCTURE_COLUMN = [INFRASTRUCTURE, CONNECTED_DEVICES, LIGHTS_STATE]

export const GOV_WIDGETS_COLORS = {
  [RATING_DYNAMIC]: {
    color: '#407665',
    bgColor: '#C2F3E3',
  },
  [NATIONAL_RATING]: {
    color: '#407665',
    bgColor: '#C2F3E3',
  },

  [PUBLIC_APPEALS]: {
    color: '#D37A1D',
    bgColor: '#FFEFC4',
  },
  [APPEALS_DYNAMIC]: {
    color: '#D37A1D',
    bgColor: '#FFEFC4',
  },
  [APPEALS_TIMING]: {
    color: '#D37A1D',
    bgColor: '#FFEFC4',
  },

  [INFRASTRUCTURE]: {
    color: '#00639A',
    bgColor: '#E2F2FC',
  },
  [CONNECTED_DEVICES]: {
    color: '#00639A',
    bgColor: '#E2F2FC',
  },
  [LIGHTS_STATE]: {
    color: '#00639A',
    bgColor: '#E2F2FC',
  },
}

export const GOV_WIDGETS_ICONS = {
  [RATING_DYNAMIC]: 'fa-bar-chart',
  [NATIONAL_RATING]: 'fa-line-chart',

  [PUBLIC_APPEALS]: 'fa-users',
  [APPEALS_DYNAMIC]: 'fa-comments-o',
  [APPEALS_TIMING]: 'fa-envelope-open',

  [INFRASTRUCTURE]: 'fa-building',
  [CONNECTED_DEVICES]: 'fa-plug',
  [LIGHTS_STATE]: 'fa-wrench',
}
