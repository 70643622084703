import { EDIT_GEOZONE } from './index'

export const requestEditGeoZone = (payload) => ({
  type: EDIT_GEOZONE.REQUEST,
  payload,
})

export const successEditGeoZone = (payload) => ({
  type: EDIT_GEOZONE.SUCCESS,
  payload,
})

export const errorEditGeoZone = (payload) => ({
  type: EDIT_GEOZONE.ERROR,
  payload,
})
