import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { TENANT_ENDPOINT } from '@/constants/apiRoutes'
import {
  GET_CURRENT_USER,
} from '@/store/actions/users'
import { getUserData } from '@/store/selectors/appSettings'
import {
  successGetCurrentUser,
  errorGetCurrentUser,
} from '@/store/actions/users/getCurrentUser'

function* getCurrentUser({ payload }) {
  const { tenantId, id } = payload
  try {
    if (!(tenantId && id)) {
      return null
    }
    const response = yield request({
      url: `${TENANT_ENDPOINT}/${tenantId}/users/${id}`,
      method: 'get',
    })

    const userData = yield select(getUserData)

    yield put(successGetCurrentUser({ ...userData, ...response.data }))
  } catch (error) {
    yield put(errorGetCurrentUser(error))
    console.log('TCL: function*getUserSaga -> error', error)
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_CURRENT_USER.REQUEST, getCurrentUser)
}
