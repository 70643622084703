import React, { useMemo } from 'react'
import get from 'lodash/get'
import pt from 'prop-types'
import viewTreeIcons from '@/constants/viewTree'
import { SYCN } from '@/constants/maps'
import { Container, IconWrapper } from './styles'

const Toggle = ({ onClick, node, onSelect }) => {
  const Icon = useMemo(() => viewTreeIcons.arrow, [])
  const SyncIcon = useMemo(() => viewTreeIcons[SYCN], [])
  const checkUserAmount = useMemo(() => {
    if (typeof (node.usersAmount) === 'number' && node.usersAmount === 0) return false
    return true
  }, [node.usersAmount])

  return (
    <>
      {(get(node, 'children.length', 0) > 0 || checkUserAmount) && !node.isIntegrationScheme
        ? (
          <Container onClick={onSelect(node, 'toggle')}>
            <IconWrapper opened={node.toggled} onClick={onClick}>
              {node.isSync
                ? <SyncIcon />
                : <Icon />}
            </IconWrapper>
          </Container>
        )
        : (<Container />)}
    </>
  )
}

Toggle.propTypes = {
  onClick: pt.func.isRequired,
  node: pt.shape({
    id: pt.number.isRequired,
    usersAmount: pt.number.isRequired,
    name: pt.string.isRequired,
    type: pt.string.isRequired,
    status: pt.string,
    active: pt.bool,
    isIntegrationScheme: pt.bool,
    toggled: pt.bool,
    isSync: pt.bool,
    children: pt.arrayOf(pt.objectOf),
  }).isRequired,
}

export default Toggle
