export const fields = [
  {
    id: 'dateStep',
    name: 'Дата',
    value: 'ASC',
  },
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
  },
  {
    id: 'project',
    name: 'Проект',
    value: 'ASC',
  },
  {
    id: 'objectInstalled',
    name: 'Установлено объектов',
    value: 'ASC',
  },
  {
    id: 'OKObject',
    name: 'Объекты в рабочем состоянии',
    value: 'ASC',
  },
  {
    id: 'persents',
    name: '% объектов в рабочем состоянии',
    value: 'ASC',
  },
]
