import styled from 'styled-components'

export const AlarmCell = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const AlarmEventContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ sm }) => sm && `
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    margin-left: 10px;
  `} 
`
