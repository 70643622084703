import { connect } from 'react-redux'
import {
  setACYWidgetSettings,
} from '@/store/actions/maps/widgetSettings'
import {
  getSystems,
} from '@/store/selectors/integrations'
import {
  getACYWidgetsSettings,
} from '@/store/selectors/maps/widgetSettings'
import {
  getTreeData,
} from '@/store/selectors/maps/socketEvents'

import ObjectPassportization from './component'

export default connect(
  (state) => ({
    treeData: getTreeData(state),
    connectedIntegrations: getSystems(state),
    ACYWidgetSettings: getACYWidgetsSettings(state),
  }),
  (dispatch) => ({
    updateACYWidget: (id, value) => dispatch(setACYWidgetSettings(id, value)),
  }),
)(ObjectPassportization)
