import { GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA } from './index'

export const requestPlanGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.REQUEST,
  payload,
})

export const responsePlanGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.SUCCESS,
  payload,
})
export const errorPlanGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.ERROR,
  payload,
})
