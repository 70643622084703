import React, { useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { TITLE_TO_STATUS } from '@/constants/maps'
import { FormattedMessage as Lang } from 'react-intl'
import { MARKS_COLORS } from '@/constants/styles/mapsMarks'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import memoWithName from '@/hocs/memoWithName'
import {
  Status,
  IndicatorWrapper,
  Title,
  DescriptionWrapper,
} from '@/components/widgets/StateIndicator/styles'
import CoreIcons from '@/components/icons/core'
import {
  DEBOUNCE_DELAY_HUDGE,
} from '@/constants/time'
import {
  IconContainer,
  ModeContainer,
  ModeBlock,
  Text,
} from './styles'

const StatusIndicator = ({
  status,
  timestamp,
  className,
  refreshIntegration,
  id,
  internalId,
  aliasId,
  mode,
  modeStatus,
}) => {
  const [playAnimation, setPlayAnimation] = useState()

  const refreshHandler = () => {
    refreshIntegration({ id: aliasId })
    setPlayAnimation(true)
    debounce(() => {
      setPlayAnimation(false)
    }, DEBOUNCE_DELAY_HUDGE)()
  }


  return (
    <IndicatorWrapper
      column
      small
      className={className}
      mode={mode}
    >
      <DescriptionWrapper>
        <Title status nowrap>
          Статус объекта:
          {' '}
          <Status color={MARKS_COLORS[status]} small={!TITLE_TO_STATUS[status]}>
            {TITLE_TO_STATUS[status] || 'неизвестно'}
          </Status>
          <PortalTooltip
            title={(<Lang id="tooltip.refresh" />)}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <IconContainer
                playAnimation={playAnimation}
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                onClick={refreshHandler}
              >
                <CoreIcons.SyncIcon />
              </IconContainer>
            )}
          />
        </Title>
        {mode && (
          <ModeContainer>
            <ModeBlock>
              <CoreIcons.LightOnIcon />
              <Text>
                Текущий режим:
              </Text>
            </ModeBlock>
            <Text value>
              {modeStatus ? 'Вкл' : 'Выкл'}
            </Text>
          </ModeContainer>
        )}
        <Title disabled>
          {`Обновлено: ${timestamp ? moment(timestamp).format('DD.MM.YYYY HH:mm') : 'неизвестно'}`}
        </Title>
      </DescriptionWrapper>
    </IndicatorWrapper>
  )
}

StatusIndicator.propTypes = {
  status: pt.string,
  timestamp: pt.string,
  className: pt.string,
  refreshIntegration: pt.func,
}
StatusIndicator.defaultProps = {
  status: '',
  timestamp: '',
  className: '',
  refreshIntegration: noop,
}

export default memoWithName(StatusIndicator)
