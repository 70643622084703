import generateWidget from './generateWidget'

export const ATMOSPHERE_PRESSURE = generateWidget({
  config: {
    id: 'ATMOSPHERE_PRESSURE',
    title: 'Атмосферное давление',
    units: 'мм рт. ст.',
  },
  selectorTemplate: 'atmospherePressure',
})

export const AIR_TEMPERATURE = generateWidget({
  config: {
    id: 'AIR_TEMPERATURE',
    title: 'Температура атмосферы',
    units: '°C',
  },
  selectorTemplate: 'atmosphereTemperature',
})
