import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SyncIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C13.9737 0 18 4.04762 18 9.04761C18 10.9502 17.4282 12.781 16.3467 14.2903L17.9526 15.9047L12.3158 16.6666L13.3105 11.2381L14.9832 12.9196C15.7066 11.7756 16.1053 10.427 16.1053 9.04761C16.1053 5.09523 12.9316 1.90476 9 1.90476C8.57368 1.90476 8.1 1.95238 7.67368 2.04762L7.3421 0.190476C7.86316 0.0476191 8.43158 0 9 0Z" fill={fill} className="svgFill" />
      <path d="M0.0473671 2.19047L5.68421 1.42857L4.68947 6.85714L3.00955 5.16833C2.28425 6.31949 1.89474 7.65975 1.89474 9.00001C1.89474 12.9524 5.06842 16.1429 9 16.1429C9.42632 16.1429 9.9 16.0952 10.3263 16L10.6579 17.8571C10.0895 17.9524 9.52105 18 9 18C4.02632 18 0 13.9524 0 8.95239C0 7.15819 0.571819 5.32568 1.64706 3.79863L0.0473671 2.19047Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SyncIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SyncIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SyncIcon)
