import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-width: 290px;
  padding: 10px 15px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  svg {
    cursor: pointer;
  }
`
export const PrimaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.colors.colors.disabled};
  text-transform: uppercase;
  text-align: left;
`
export const SecondaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.notation};
  text-align: left;
`
export const TextFieldContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-left: 2px;
    margin-right: 2px;
  }
`

export const ButtunContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  button {
    width: 88px;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
