import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const WidgetsDashboard = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 2.25C12.735 2.25 15.75 5.265 15.75 9C15.75 12.735 12.735 15.75 9 15.75C5.265 15.75 2.25 12.735 2.25 9C2.25 5.265 5.265 2.25 9 2.25ZM9 4.5C8.37 4.5 7.875 4.995 7.875 5.625C7.875 6.255 8.37 6.75 9 6.75C9.63 6.75 10.125 6.255 10.125 5.625C10.125 4.995 9.63 4.5 9 4.5ZM5.265 6.75C5.07871 6.80982 4.91123 6.91718 4.77912 7.0615C4.647 7.20582 4.55481 7.38211 4.51164 7.57295C4.46848 7.76379 4.47583 7.96258 4.53298 8.14971C4.59012 8.33684 4.69508 8.50583 4.8375 8.64L6.885 10.6875C6.84 10.8675 6.75 11.0475 6.75 11.25C6.75 12.4875 7.7625 13.5 9 13.5C10.2375 13.5 11.25 12.4875 11.25 11.25C11.25 10.0125 10.2375 9 9 9C8.7975 9 8.6175 9.09 8.4375 9.135L6.39 7.0875C6.26689 6.95126 6.11231 6.84722 5.93974 6.78446C5.76717 6.72171 5.58187 6.70216 5.4 6.7275C5.35504 6.7248 5.30996 6.7248 5.265 6.7275V6.75ZM12.375 6.75C11.745 6.75 11.25 7.245 11.25 7.875C11.25 8.505 11.745 9 12.375 9C13.005 9 13.5 8.505 13.5 7.875C13.5 7.245 13.005 6.75 12.375 6.75Z" fill="#3D3D3D"/>
    </svg>
  )
}

WidgetsDashboard.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WidgetsDashboard.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WidgetsDashboard)
