import { GET_APPEALS_TABLE } from './index'

export const requestAppealsTable = (payload) => ({
  type: GET_APPEALS_TABLE.REQUEST,
  payload,
})

export const successAppealsTable = (payload) => ({
  type: GET_APPEALS_TABLE.SUCCESS,
  payload,
})

export const errorAppealsTable = (payload) => ({
  type: GET_APPEALS_TABLE.ERROR,
  payload,
})
