import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ToViewIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M17.7922 8.41764L16.2229 6.54903L16.4422 4.07223C16.4844 3.63152 16.1976 3.22607 15.7926 3.12911L13.4639 2.57382L12.2489 0.431966C12.1451 0.248245 11.9808 0.110265 11.7867 0.0437697C11.5927 -0.0227259 11.3821 -0.0131953 11.1942 0.0705833L9.00052 1.06659L6.81525 0.0882117C6.42714 -0.0880725 5.97152 0.0705832 5.76059 0.449594L4.53717 2.58263L2.21691 3.12911C1.80348 3.22607 1.51661 3.62271 1.5588 4.07223L1.77817 6.54021L0.208824 8.41764C-0.069608 8.75258 -0.069608 9.24618 0.208824 9.58112L1.77817 11.4497L1.5588 13.9353C1.51661 14.376 1.80348 14.7815 2.20847 14.8785L4.53717 15.4338L5.75215 17.5668C5.97152 17.9458 6.4187 18.1045 6.81525 17.9282L9.00052 16.9322L11.1858 17.9105C11.5739 18.0868 12.0295 17.9282 12.2405 17.5492L13.4554 15.4073L15.7841 14.852C16.1976 14.7551 16.476 14.3496 16.4338 13.9089L16.2144 11.4321L17.7838 9.56349C18.0707 9.24618 18.0707 8.75258 17.7922 8.41764ZM9.84425 13.4065H8.15679V11.6436H9.84425V13.4065ZM9.00052 9.8808C8.53647 9.8808 8.15679 9.48416 8.15679 8.99938V5.47369C8.15679 4.98891 8.53647 4.59227 9.00052 4.59227C9.46457 4.59227 9.84425 4.98891 9.84425 5.47369V8.99938C9.84425 9.48416 9.46457 9.8808 9.00052 9.8808Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H18V18H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

ToViewIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ToViewIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ToViewIcon)
