import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PinYellowIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="56" height="63" viewBox="0 0 56 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="pin-2-number">
        <g id="Union" filter="url(#filter0_d)">
          <path d="M35.6598 46.1523C45.1586 42.9966 52 34.1332 52 23.6923C52 10.6074 41.2548 0 28 0C14.7452 0 4 10.6074 4 23.6923C4 34.5023 11.3337 43.6214 21.3576 46.4655L28.416 55L35.6598 46.1523Z" fill={fill} className="svgFill" />
        </g>
        <g id="Ellipse" filter="url(#filter1_i)">
          <circle cx="28" cy="24" r="19" fill={fill} className="svgFill" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="56" height="63" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.176471 0 0 0 0 0.254902 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter1_i" x="9" y="5" width="38" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0552957 0 0 0 0 0.0751579 0 0 0 0 0.122331 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  )
}

PinYellowIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PinYellowIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PinYellowIcon)
