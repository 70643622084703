import { GET_ALARM_MANAGER_FILE } from './index'

export const requestAlarmManagerFile = (payload) => ({
  type: GET_ALARM_MANAGER_FILE.REQUEST,
  payload,
})

export const successAlarmManagerFile = (payload) => ({
  type: GET_ALARM_MANAGER_FILE.SUCCESS,
  payload,
})

export const errorAlarmManagerFile = (payload) => ({
  type: GET_ALARM_MANAGER_FILE.ERROR,
  payload,
})
