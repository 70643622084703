import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import { ValueSelectStyled, ValueIcon, Input } from './styles'

const ValueSelect = ({
  min, max, step, value, onChange, alarm,
}) => (
  <ValueSelectStyled>
    <Input
      id="range"
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
      alarm={alarm}
      isEmpty={value === 0}
    />
    <ValueIcon>
      {value}
      %
    </ValueIcon>
  </ValueSelectStyled>
)

ValueSelect.propTypes = {
  min: pt.number,
  max: pt.number,
  step: pt.number,
  value: pt.number,
  onChange: pt.func,
  alarm: pt.bool,
}
ValueSelect.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: 0,
  onChange: noop,
  alarm: false,
}

export default memoWithName(ValueSelect)
