import { combineReducers } from 'redux'

import socketEvents from './socketEvents'
import widgetSettings from './widgetSettings'
import objectImage from './objectImage'

export default combineReducers({
  data: socketEvents,
  widgets: widgetSettings,
  image: objectImage,
})
