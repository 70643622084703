import generateWidget from './generateWidget'

export const LIGHT_LEVEL = generateWidget({
  config: {
    id: 'LIGHT_LEVEL',
    title: 'Уровень диммирования',
    minValue: 0,
    maxValue: 100,
    units: '%',
  },
  selectorTemplate: 'illuminanceLevel',
})

export const ENERGY_CONSUMED = generateWidget({
  config: {
    id: 'ENERGY_CONSUMED',
    minValue: 0,
    maxValue: 0,
    units: 'кВт⋅ч',
    settingsControll: false,
    chartControll: false,
  },
  selectorTemplate: 'accumulatedEnergyAmountRate{number}',
  titleTemplate: 'Потребление энергии по тарифу T{number}',
})

export const ENERGY_CONSUMED_ARRAY = generateWidget({
  config: {
    id: 'ENERGY_CONSUMED_ARRAY',
    title: 'Потребление энергии',
    minValue: 0,
    maxValue: 0,
    units: 'кВт⋅ч',
  },
  dataTemplate: {
    title: 'T{number}',
    selector: 'accumulatedEnergyAmountRate{number}',
  },
})

export const VOLTAGE = generateWidget({
  config: {
    id: 'VOLTAGE',
    minValue: 187,
    maxValue: 253,
    units: 'В',
  },
  selectorTemplate: 'phase{number}Voltage',
  titleTemplate: 'Напряжение U{number}',
})

export const VOLTAGE_ARRAY = generateWidget({
  config: {
    id: 'VOLTAGE_ARRAY',
    title: 'Напряжение',
    units: 'В',
    minValue: 187,
    maxValue: 253,
    type: 'detailed',
  },
  dataTemplate: {
    selector: 'phase{number}Voltage',
    title: 'U{number}',
  },
})

export const AMPERAGE = generateWidget({
  config: {
    id: 'AMPERAGE',
    minValue: 0,
    maxValue: 0,
    units: 'А',
  },
  selectorTemplate: 'phase{number}Amperage',
  titleTemplate: 'Ток I{number}',
})

export const AMPERAGE_ARRAY = generateWidget({
  config: {
    id: 'AMPERAGE_ARAY',
    title: 'Ток',
    units: 'А',
  },
  dataTemplate: {
    selector: 'phase{number}Amperage',
    title: 'I{number}',
  },
})

export const POWER = generateWidget({
  config: {
    id: 'POWER',
    minValue: 0,
    maxValue: 0,
    units: 'Вт',
  },
  selectorTemplate: 'phase{number}Power',
  titleTemplate: 'Мощность P{number}',
})

export const POWER_ARRAY = generateWidget({
  config: {
    id: 'POWER_ARRAY',
    title: 'Мощность',
    units: 'Вт',
  },
  dataTemplate: {
    selector: 'phase{number}Power',
    title: 'P{number}',
  },
})

export const FREQUENCY = generateWidget({
  config: {
    id: 'FREQUENCY',
    title: 'Частота',
    minValue: 49.5,
    maxValue: 50.5,
    units: 'Гц',
  },
  selectorTemplate: 'frequency',
})

export const ELECTRICAL_RESISTANCE = generateWidget({
  config: {
    id: 'ELECTRICAL_RESISTANCE',
    minValue: 0,
    maxValue: 0,
    units: 'Ом',
  },
  selectorTemplate: 'phase{number}resistance',
  titleTemplate: 'Электрическое сопротивление R{number}',
})

export const ELECTRICAL_RESISTANCE_ARRAY = generateWidget({
  config: {
    id: 'ELECTRICAL_RESISTANCE_ARRAY',
    title: 'Электрическое сопротивление',
    units: 'Ом',
  },
  dataTemplate: {
    selector: 'phase{number}resistance',
    title: 'R{number}',
  },
})

export const LIGHT_STATUS = generateWidget({
  config: {
    id: 'LIGHT_STATUS',
    title: 'Статус объекта:',
    type: 'status',
    timeSelector: 'timestamp',
  },
})
