import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ScheduleManagerIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.5 39C39.8807 39 41 37.8807 41 36.5C41 35.1193 39.8807 34 38.5 34C37.1193 34 36 35.1193 36 36.5C36 37.8807 37.1193 39 38.5 39Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1.5C6 0.67157 6.67157 0 7.5 0C8.32843 0 9 0.67157 9 1.5V5H41V1.5C41 0.67157 41.6716 0 42.5 0C43.3284 0 44 0.67157 44 1.5V5H45C47.7614 5 50 7.23857 50 10V45C50 47.7614 47.7614 50 45 50H5C2.23859 50 0 47.7614 0 45L0 10C0 7.23857 2.23859 5 5 5H6V1.5ZM5 15H45C46.1046 15 47 15.8954 47 17V45C47 46.1046 46.1046 47 45 47H5C3.89542 47 3 46.1046 3 45V17C3 15.8954 3.89542 15 5 15Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ScheduleManagerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ScheduleManagerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ScheduleManagerIcon)
