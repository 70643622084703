import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getAlarmManager = createSelector(
  getState,
  (state) => get(state, 'alarmManager', {}),
)

export const getLoadingStatistic = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'loadingStatistic'),
)

export const getIsAlarms = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'isAlarms'),
)

export const getLoadingTable = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'loadingTable'),
)

export const getExportFileStatus = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'fileExportStatus', ''),
)

export const getTableStatus = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'status', ''),
)

export const getTableData = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'data', []),
)

export const getSelector = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'selector', []),
)

export const getEventTypes = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'eventTypes'),
)

export const getEventStatus = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'eventStatus'),
)

export const getAliasId = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'aliasId'),
)

export const getSelectedElement = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'selectedElement'),
)

export const getEventData = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'eventData'),
)

export const getEventsCount = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'eventsCount'),
)

export const getEventsCountNotification = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'eventsCountNotification'),
)

export const getEventsNewCount = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'eventsNewCount'),
)

export const getDates = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'dates', {}),
)

export const getStartDate = createSelector(
  getDates,
  (alarmsState) => get(alarmsState, 'start', null),
)

export const getEndDate = createSelector(
  getDates,
  (alarmsState) => get(alarmsState, 'end', null),
)

export const getTableParameters = createSelector(
  getAlarmManager,
  (alarmsState) => get(alarmsState, 'table', {}),
)
