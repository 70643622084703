import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 17.2C8.81169 17.2009 7.64169 16.9072 6.59464 16.3453C5.54759 15.7834 4.65613 14.9707 4 13.98C4.026 11.993 8.004 10.9 9.998 10.9C11.992 10.9 15.97 11.993 15.996 13.98C15.3402 14.9702 14.4494 15.7825 13.4031 16.3444C12.3568 16.9063 11.1876 17.2002 10 17.2ZM10 3C10.7822 3.0202 11.5257 3.34513 12.0718 3.90554C12.6179 4.46595 12.9235 5.2175 12.9235 6C12.9235 6.7825 12.6179 7.53405 12.0718 8.09446C11.5257 8.65487 10.7822 8.9798 10 9C9.20435 9 8.44129 8.68393 7.87868 8.12132C7.31607 7.55871 7 6.79565 7 6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3ZM10 0C4.476 0 0 4.476 0 10C0 15.523 4.476 20 10 20C15.522 20 20 15.523 20 10C20 4.476 15.522 0 10 0Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UserIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserIcon)
