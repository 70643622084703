import styled from 'styled-components'
import CheckBox from '@/components/controls/CheckBox'

export const FormWrapper = styled.div`
  width: 100%;
  padding: 15px;
`

export const EventPreview = styled.div`
  min-width: 310px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.dark}; 
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const EventPreviewHeader = styled.div`
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 3rem;
  width: 100%;
  text-align: left;
  margin-left: 10px;
`

export const CloseIconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const EventPreviewContainer = styled.div`
  height: calc(100vh - 96px);
  width: 100%;
  padding: 10px;
  overflow: auto;
`

export const Title = styled.div`
  font-size: 1.2rem;
  line-height: 3rem;
  line-height: 1.4rem;
  display: flex;
  text-align: left;
  max-width: 160px;
  width: 50%;

  ${({ bold, theme }) => bold && `
    font-weight: ${theme.fontWeights.bold};
    justify-content: flex-end;
    text-transform: uppercase;
    width: 40px;
    text-align: right;
  `}
`

export const AlarmElementPropertyContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const AlarmElementTitleContainer = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  ${Title} {
    width: 65%;
    flex: 1;
  }
`

export const BlockTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
`

export const SecondaryTitle = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ value, theme }) => value && `
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.title};
    margin-right: 30px;
  `}
`

export const AlarmEvent = styled.div`
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ sm }) => sm && `
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    margin-left: 10px;
    svg,
    .svgStroke,
    .svgFill {
      height: 8px;
    }
  `} 
`

export const AlarmRowContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AlarmTitleContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${SecondaryTitle} {
    margin-left: 10px;  
  }
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`
export const StyledCheckBox = styled(CheckBox)`
  margin-right: 10px;
`

export const StyledTooltip = styled.abbr`
  text-decoration: none;
`

export const EventContainer = styled.div`
  width: 15%;
  height: 100%;
`
