import { GET_SERVICE_DESK_INFO_CARD } from './index'

export const requestGetInfoServiceDeskCard = (payload) => ({
  type: GET_SERVICE_DESK_INFO_CARD.REQUEST,
  payload,
})

export const successGetInfoServiceDeskCard = (payload) => ({
  type: GET_SERVICE_DESK_INFO_CARD.SUCCESS,
  payload,
})

export const errorGetInfoServiceDeskCard = (payload) => ({
  type: GET_SERVICE_DESK_INFO_CARD.ERROR,
  payload,
})
