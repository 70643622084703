import React, { useMemo, useCallback, useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import DutyTable from '@/components/blocks/DutyTable'
import getFullName from '@/helpers/fullName'
import {
  activeFields,
  fields,
} from '@/constants/tablesConfig/userPage'
import {
  USERS_GROUP,
  COMPANY,
  USER,
  GROUPS_EDIT,
} from '@/constants/viewTree'
import {
  ROLE_USER,
  ASC,
  ROLE_TENANT_ADMIN,
} from '@/constants/names'
import {
  ADMIN_SURNAME,
} from '@/constants/forms/admin'

const UsersPresentation = ({
  type,
  selectedTenant,
  selectedEntity,
  users,
  selectedUsersIdArray,
  setSelectedUsersIdArray,
  loggedInUser,
  sortType,
  sortValue,
  updateTableSorting,
  sortedUsers,
  userRole,
  selectTenantByID,
  setSelectedTenant,
  setEntity,
  setIsConfirm,
  userActions,
  adminActions,
}) => {
  const [isAllSelected, setIsAllSelected] = useState(false)
  const tableCheckBoxToggle = useCallback((id) => () => {
    if (selectedUsersIdArray.findIndex((selectedElementId) => selectedElementId === id) !== -1) {
      const newSelectedUsersIdArray = selectedUsersIdArray
        .filter((selectedElementId) => selectedElementId !== id)
      setSelectedUsersIdArray(newSelectedUsersIdArray)
      return null
    }
    const newSelectedUsersIdArray = selectedUsersIdArray.concat([id])
    setSelectedUsersIdArray(newSelectedUsersIdArray)
    return null
  }, [selectedUsersIdArray, setSelectedUsersIdArray])

  useEffect(() => {
    if (selectedEntity.id) {
      updateTableSorting({
        sortType: ADMIN_SURNAME,
        sortValue: ASC,
        id: selectedEntity.id,
      })
    }
  }, [selectedEntity, selectedEntity.id, updateTableSorting])

  useEffect(() => {
    if (Object.keys(users).length > 0 && selectedEntity.id) {
      const groupUsers = users[`${selectedEntity.tenantId}-${selectedEntity.id}`] || []
      if (selectedUsersIdArray.length === groupUsers.length) {
        setIsAllSelected(true)
        return noop
      }
    }
    setIsAllSelected(false)
    return noop
  }, [selectedEntity, selectedUsersIdArray, users])

  const usersArray = useMemo(() => {
    if (sortedUsers) {
      const sortedGroupUsers = sortedUsers.filter((element) => {
        if (selectedTenant.type === COMPANY && element.tenantId === selectedTenant.id) {
          return element
        }
        if (
          selectedTenant.type === USERS_GROUP
          && !isEmpty(element.group)
          && element.group.id === selectedTenant.id
        ) {
          return element
        }
        return false
      })
      return sortedGroupUsers.map((element) => ({
        ...element,
        groupName: element.role === ROLE_USER
          ? element.group.name
          : selectedTenant.organizationName,
        userStatus: true,
        name: getFullName(element.firstName, element.lastName),
        isMe: loggedInUser.id === element.id,
        withCheckBox: type === GROUPS_EDIT && userRole.isAdmin,
        isChecked: selectedUsersIdArray
          .findIndex((selectedElementId) => element.id === selectedElementId) !== -1,
        onCheckBoxToggle: tableCheckBoxToggle(element.id),
      }))
    }
    return []
  },
  [
    sortedUsers,
    selectedTenant.type,
    selectedTenant.id,
    selectedTenant.organizationName,
    loggedInUser.id,
    type,
    userRole.isAdmin,
    selectedUsersIdArray,
    tableCheckBoxToggle,
  ])

  const toggleSelectAll = () => {
    const newSelectedUsersIdArray = []
    const groupUsers = users[`${selectedEntity.tenantId}-${selectedEntity.id}`] || []
    if (
      Object.keys(users).length > 0
      && selectedEntity.tenantId
      && selectedUsersIdArray.length !== groupUsers.length) {
      groupUsers.map((element) => {
        if (element.type === USER) {
          newSelectedUsersIdArray.push(element.id)
        }
        return element
      })
    }
    setSelectedUsersIdArray(newSelectedUsersIdArray)
  }

  const handleRowSelector = useCallback((user) => {
    if (user.role === ROLE_TENANT_ADMIN) {
      setIsConfirm(true)
      setEntity({})
      const tenantByUsersGroupId = selectTenantByID(user.tenantId)
      setSelectedTenant(tenantByUsersGroupId)
      adminActions.requestGetAdminTenant({
        tenantId: user.tenantId,
        id: user.id,
      })
      return null
    }
    if (user.role === ROLE_USER) {
      setEntity({})
      setIsConfirm(true)
      userActions.requestGetUser({
        tenantId: user.tenantId,
        id: user.id,
      })
      return null
    }
  }, [adminActions, selectTenantByID, setEntity, setIsConfirm, setSelectedTenant, userActions])

  return (
    <DutyTable
      withFooter={false}
      withHeader={false}
      rowSelector={handleRowSelector}
      handleReadAllAlarms={toggleSelectAll}
      buttonValue={isAllSelected && selectedUsersIdArray.length !== 0}
      showCheckBox={type === GROUPS_EDIT && userRole.isAdmin}
      fields={fields}
      actions={{
        setSort: updateTableSorting,
      }}
      values={{
        activeFields,
        perPage: usersArray.length,
        sort: {
          field: sortType,
          direction: sortValue,
        },
      }}
      data={usersArray}
    />
  )
}

UsersPresentation.propTypes = {
  selectedTenant: pt.objectOf(pt.object),
  selectedEntity: pt.objectOf(pt.object),
  loggedInUser: pt.objectOf(pt.object),
  viewType: pt.string,
  type: pt.string,
  setViewType: pt.func,
  sortType: pt.string,
  sortValue: pt.string,
  updateTableSorting: pt.func,
  groups: pt.objectOf(pt.arrayOf(pt.object)),
  admins: pt.objectOf(pt.arrayOf(pt.object)),
  users: pt.objectOf(pt.arrayOf(pt.object)),
  selectedUsersIdArray: pt.arrayOf(pt.number),
  setSelectedUsersIdArray: pt.func,
  multipleEditingFlag: pt.bool,
  sortedUsers: pt.arrayOf(pt.object),
}

UsersPresentation.defaultProps = {
  selectedTenant: {},
  selectedEntity: {},
  loggedInUser: {},
  viewType: '',
  setViewType: noop,
  sortType: '',
  sortValue: '',
  type: '',
  updateTableSorting: noop,
  groups: {},
  users: {},
  admins: {},
  selectedUsersIdArray: [],
  setSelectedUsersIdArray: noop,
  multipleEditingFlag: false,
  sortedUsers: [],
}

export default UsersPresentation
