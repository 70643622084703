import core from './core'
import * as light from './light'
import * as water from './water'
import * as heat from './heat'
import * as groupObject from './groupObject'
import * as mesh from './mesh'
import * as briz from './briz'

export default {
  ...core,
  ...light,
  ...water,
  ...heat,
  ...groupObject,
  ...mesh,
  ...briz,
}
