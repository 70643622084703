export default {  
usersOne: 'User',
usersTwo: 'Users',
usersSome: 'Users',
users: 'Users',
userProfile: 'User Profile',
passportization: 'Passportization',
installation: 'Installation',
inventorisation: 'Inventorisation',
alarmsOne: 'Problem',
alarmsTwo: 'Problem',
alarmsSome: 'Problems',
alarms: 'Trouble Log',
individualControl: 'Individual Control',
groupControl: 'Group control',
operationAndTechnicalSupport: 'Operation and support',
search: 'Advanced system search',
installObjectsOne: 'Installation',
installObjectsTwo: 'Installations',
installObjectsSome: 'Installations',
reportManager: 'Report Manager',
life: 'Lifetime',
scheduleManager: 'Schedule Manager',
controlCenter: 'Installation Manager',
objectsOne: 'Object',
objectsTwo: 'Objects',
objectsSome: 'Objects',
energyConsumption: 'Energy consumption',
alertManager: 'AlertManager',
activityLog: 'ActivityLog',
changeLog: 'ChangeLog',
equipmentReference: 'Equipment directory',
carElectricStationsIcon: 'Charging stations',
waterSupply: 'Water supply',
heatSupply: 'Heat supply',
smartRecycleBin: 'Waste container filling control',
ecoStations: 'EcoStations',
settings: 'System Parameters',
statisticsOnObjectsControl: 'Control object statistics',
analyticExpenses: 'Analytical Expenses',
analyticConsumption: 'Analysis of resource consumption',
eventsCount: 'Events',
eventsNewCount: 'New',
mountOne: 'Installation',
mountTwo: 'Installations',
mountSome: 'Installations',
projectOne: 'Project',
projectTwo: 'Project',
projectSome: 'Projects',
intergationWithService: 'Integration with service:',
connect: 'Connect',
disconnect: 'Disconnect',
chargingStation: 'Charging stations',
architectureIllumination: 'Architectural illumination',
garbageCollection: 'Waste container filling control',
roadInfrastructure: 'Road Infrastructure Monitoring',
trafficLight: 'Traffic light management',
infoFrame: 'Placement of information boards',
handicappedPlaces: 'Monitoring occupancy of places\nfor people with disabilities',
support: 'Desk service "My City"',
widgetsDashboard: 'Dashboard',
widgetsDashboardGov: 'Dashboard 2',
apiUrl: 'API URL',
port: 'Port',
login: 'Login',
password: 'Password',
myDescSettingsTitle: 'To use the application\nyou must configure it',
setup: 'Configure widget',
receiveMessages: 'Received requests',
execution: 'Executed',
clientID: 'ClientID*',
secretID: 'SecretID*',
light: 'Outdoor Lights',
tyumenOurHome: 'Portal Tyumen - OUR HOME',
name: 'Name*',
fileWithSettings: 'File with settings*',
url: 'URL*',
}