export default {
  catalogs: {
    // controller cart
    LOCATION: 'location',
    OBJECT_CHARACTERISTIC: 'object characteristics',
    SURVEY_DATA: 'survey data',
    SERVICING: 'object service',
    // controller passport
    GENERAL_INFORMATION: 'general information',
    TECHNICAL_CONSTRAINTS: 'technical parameters',
    EXPLOITATION: 'exploitation',
    ATTACHMENT: 'attachments',
  },
  fields: {
// controller cart
    'LOCATION.PROJECT': 'Project',
    'LOCATION.LATITUDE': 'Latitude',
    'LOCATION.LONGITUDE': 'Longitude',
    'LOCATION.REGION': 'Region',
    'LOCATION.CITY': 'City',
    'LOCATION.STREET': 'Street',
    'LOCATION.HOME_NUMBER': 'House',
    'OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE': 'Equipment type',
    'OBJECT_CHARACTERISTIC.lampType': 'Lamp type',
    'OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY': 'Lighting object category',
    'OBJECT_CHARACTERISTIC.IMPORTANCE': 'Object importance',
    'OBJECT_CHARACTERISTIC.WORK_MODE': 'Operating mode',
    'OBJECT_CHARACTERISTIC.INSTALLATION_TYPE': 'Installation type',
    'OBJECT_CHARACTERISTIC.CONTROL_TYPE': 'Control Type',
    'OBJECT_CHARACTERISTIC.lightingType': 'Lighting object type',
    'OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER': 'Number of outgoing lines',
    'OBJECT_CHARACTERISTIC.SETUP': 'Setup',
    'OBJECT_CHARACTERISTIC.INDICATORS': 'Indicators',
    'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER': 'Number of connected fixtures',
    'OBJECT_CHARACTERISTIC.locationType': 'Pedestrian Space Feature',
    'SURVEY_DATA.CONNECTED_LAMP_NUMBER': 'Number of connected lights',
    'SURVEY_DATA.LOCATION_TYPE': 'Pedestrian Space Feature',
    'SURVEY_DATA.LAMP_INSTALLATION_HEIGHT': 'Lamp installation height in meters',
    'SURVEY_DATA.ROADWAY_WIDTH': 'Width of the roadway in meters',
    'SURVEY_DATA.INSTALLATION_SCHEME': 'Support Installation Scheme',
    'SURVEY_DATA.STATE': 'Support state',
    'SURVEY_DATA.POWER_SUPPLY': 'Power',
    'SURVEY_DATA.POWER_SUPPLY_TYPE': 'Power type',
    'SURVEY_DATA.OVERHANG_HEIGHT': 'Overhang of light point in meters',
    'SURVEY_DATA.LAMP_ANGLE': 'Inclination of light point in degrees',
    'SURVEY_DATA.OVERHANG_LENGTH': 'Console length in meters',
    'SURVEY_DATA.UTILITY_POLE_LAMP_POSITION': 'Position of the lamp in the plan on the support in degrees',
    'SERVICING.INSPECTION_DATE': 'Inspection date',
    'SERVICING.BYPASS_DATE': 'Date bypass',
    'SERVICING.MAINTENANCE_DATE': 'Maintenance date',
    'SERVICING.REPAIR_DATE': 'Repair Date',
    'SERVICING.CAPITAL_REPAIR_DATE': 'Date of overhaul',
    'SERVICING.GUARANTEE_EXPIRATION_DATE': 'Warranty expiration date',
    'SERVICING.CHECKING_DATE': 'Date of verification',
// controller passport
'GENERAL_INFORMATION.IDENTIFIER': 'Identifier',
     'GENERAL_INFORMATION.NAME': 'Name',
     'GENERAL_INFORMATION.INSTALLATION': 'Installation',
     'GENERAL_INFORMATION.EQUIPMENT_TYPE': 'Equipment type',
     'GENERAL_INFORMATION.VERSION_API': 'Software version (API)',
     'GENERAL_INFORMATION.UTILITY_POLE_TYPE': 'Prop type',
     'GENERAL_INFORMATION.MODEL_MARK': 'Model/Make',
     'GENERAL_INFORMATION.LAMP_TYPE': 'Lamp type',
     'GENERAL_INFORMATION.PROCEDURE_SUPPLIER': 'Manufacturer',
     'GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER': 'LCB ID',
     'GENERAL_INFORMATION.AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'ACS ID',
     'GENERAL_INFORMATION.LAMP_IDENTIFIER': 'Lamp ID',
     'GENERAL_INFORMATION.LINE_IDENTIFIER': 'Line ID',
     'GENERAL_INFORMATION.BASE_STATION_IDENTIFIER': 'Base Station ID',
     'GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER': 'Pole ID',
     'TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE': 'Working temperature range',
     'TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION': 'Power Consumption',
     'TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE': 'Operating voltage range',
     'TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT': 'Light output',
     'TECHNICAL_CONSTRAINTS.CALIBRATION_INTERVAL': 'Calibration interval',
     'TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE': 'Color temperature',
     'TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX': 'Color rendering index',
     'TECHNICAL_CONSTRAINTS.LAMP_EFFICIENCY': 'Lamp Efficiency',
     'TECHNICAL_CONSTRAINTS.SCATTERING_ANGLE': 'Scattering angle',
     'TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'KCC type',
     'TECHNICAL_CONSTRAINTS.FLICKER_PERCENT': 'Ripple factor',
     'TECHNICAL_CONSTRAINTS.RATED_POWER': 'Authorized Power',
     'TECHNICAL_CONSTRAINTS.ELECTRICITY_SUPPLIER': 'Electrical Power Supplier',
     'TECHNICAL_CONSTRAINTS.ACTUAL_CONSUMPTION_POWER': 'Actually Connected Power',
     'TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS': 'Number of measurement channels',
     'TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM ': 'Number of controlled heating systems',
     'TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'Average volume flow measuring range',
     'TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE': 'Temperature measurement range',
     'TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'Measurement range of temperature difference in supply and return pipeline',
     'TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE': 'Pressure measurement range',
     'TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE': 'DC Power Supply Voltage',
     'TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'Relative error limits for temperature measurement',
     'EXPLOITATION.START_DATE': 'Exploitation start date',
     'EXPLOITATION.ORGANIZATION': 'Operating organization',
     'EXPLOITATION.OWNER': 'Owner',
     'EXPLOITATION.GUARANTEE_PERIOD': 'Guarantee period',
     'EXPLOITATION.LIFE_TIME': 'Lifetime',
     'ATTACHMENT.PHOTO_TITLE': 'Photo',
     'ATTACHMENT.DOCUMENT_TITLE': 'Product Passport File',

    'OBJECT_CHARACTERISTIC.TARIFF_ONE': 'Tariff1',
    'OBJECT_CHARACTERISTIC.TARIFF_TWO': 'Tariff2',
    'OBJECT_CHARACTERISTIC.TARIFF_THREE': 'Tariff3',
    'OBJECT_CHARACTERISTIC.TARIFF_FOUR': 'Tariff4',

  },
  title: 'Passportization',
  changeTitle: 'Save changes?',
  passportSidebar: {
    equipment: 'installation equipment',
    discardAll: 'Mark All',
    passport: 'Passport',
    card: 'Card',
    view: 'View:',
    noImage: 'no image',
    passportExport: 'passport export',
    fieldSettings: 'Field display settings:',
    yes: 'Yes',
    no: 'No',
  },
}
