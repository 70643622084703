import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GeoZoneIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M25 9C21.134 9 18 12.134 18 16C18 19.866 21.134 23 25 23C28.866 23 32 19.866 32 16C32 12.134 28.866 9 25 9ZM20 16C20 13.2386 22.2386 11 25 11C27.7614 11 30 13.2386 30 16C30 18.7614 27.7614 21 25 21C22.2386 21 20 18.7614 20 16Z" fill={fill} className="svgFill"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M39 16C39 25.7604 29.7382 38.4977 26.281 42.9123C25.4877 43.9253 25 44.5 25 44.5C25 44.5 24.5123 43.9253 23.719 42.9123C20.2618 38.4977 11 25.7604 11 16C11 8.26801 17.268 2 25 2C32.732 2 39 8.26801 39 16ZM37 16C37 21.3855 33.7908 28.0863 30.3019 33.6934C28.5921 36.4412 26.8791 38.8276 25.5927 40.5275C25.3829 40.8047 25.1847 41.0633 25 41.302C24.8153 41.0633 24.6171 40.8047 24.4073 40.5275C23.1209 38.8276 21.4079 36.4412 19.6981 33.6934C16.2092 28.0863 13 21.3855 13 16C13 9.37258 18.3726 4 25 4C31.6274 4 37 9.37258 37 16Z" fill={fill} className="svgFill"/>
      <path d="M18.2715 36.4883C12.2293 37.4083 8 39.5298 8 42C8 45.3137 15.6112 48 25 48C34.3888 48 42 45.3137 42 42C42 39.5298 37.7707 37.4083 31.7285 36.4883C31.2833 37.1256 30.8418 37.7393 30.4103 38.3249C32.7051 38.6107 34.7309 39.0701 36.3552 39.6433C37.7698 40.1426 38.7724 40.6909 39.3827 41.2001C40.0017 41.7167 40 41.9962 40 41.9999C40 42.0037 40.0017 42.2833 39.3827 42.7999C38.7724 43.3091 37.7698 43.8574 36.3552 44.3566C33.5439 45.3489 29.5294 46 25 46C20.4706 46 16.4561 45.3489 13.6448 44.3566C12.2302 43.8574 11.2276 43.3091 10.6173 42.7999C9.99827 42.2833 9.99998 42.0038 10 42C9.99998 41.9963 9.99827 41.7167 10.6173 41.2001C11.2276 40.6909 12.2302 40.1426 13.6448 39.6433C15.269 39.0701 17.2949 38.6107 19.5897 38.3249C19.1582 37.7393 18.7167 37.1256 18.2715 36.4883Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

GeoZoneIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GeoZoneIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GeoZoneIcon)
