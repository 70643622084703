import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReportManagerIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 31.5C10 30.6716 10.6716 30 11.5 30C12.3284 30 13 30.6716 13 31.5V40.5C13 41.3284 12.3284 42 11.5 42C10.6716 42 10 41.3284 10 40.5V31.5Z" fill={fill} className="svgFill" />
      <path d="M20.5 8C19.6716 8 19 8.67157 19 9.5V40.5C19 41.3284 19.6716 42 20.5 42C21.3284 42 22 41.3284 22 40.5V9.5C22 8.67157 21.3284 8 20.5 8Z" fill={fill} className="svgFill" />
      <path d="M28 26.5C28 25.6716 28.6716 25 29.5 25C30.3284 25 31 25.6716 31 26.5V40.5C31 41.3284 30.3284 42 29.5 42C28.6716 42 28 41.3284 28 40.5V26.5Z" fill={fill} className="svgFill" />
      <path d="M38.5 18C37.6716 18 37 18.6716 37 19.5V40.5C37 41.3284 37.6716 42 38.5 42C39.3284 42 40 41.3284 40 40.5V19.5C40 18.6716 39.3284 18 38.5 18Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 5.18868C0 2.32305 2.32305 0 5.18868 0H44.8113C47.6769 0 50 2.32305 50 5.18868V44.8113C50 47.6769 47.6769 50 44.8113 50H5.18868C2.32305 50 0 47.6769 0 44.8113V5.18868ZM5.18868 2.83019C3.88612 2.83019 2.83019 3.88612 2.83019 5.18868V44.8113C2.83019 46.1139 3.88612 47.1698 5.18868 47.1698H44.8113C46.1139 47.1698 47.1698 46.1139 47.1698 44.8113V5.18868C47.1698 3.88612 46.1139 2.83019 44.8113 2.83019H5.18868Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ReportManagerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReportManagerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReportManagerIcon)
