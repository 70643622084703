import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import UsersIcons from '@/components/icons/users'
import TextControl from '@/components/controls/TextControl'
import { NavContainer } from '../../styles'

const TREE_ID = 'installations-tree'

const TreeSidebar = ({ roots, onSelect, hidden }) => (
  <NavContainer hidden={hidden}>
    <PageSidebar
      withoutRequest
      title="Инсталляция"
      treeId={TREE_ID}
      treeData={roots}
      onSelect={onSelect}
      headerContent={(
        <Lang id="mapsPage.titles.search">
          {(placeholder) => (
            <TextControl
              dark
              placeholder={placeholder}
              name="search"
              icon={UsersIcons.MagnifierIcon}
            />
          )}
        </Lang>
      )}
    />
  </NavContainer>
)

export default React.memo(TreeSidebar)
