import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import Button from '@/components/blocks/Button'
import CheckBoxIconField from '@/components/fields/CheckBoxIconField'
import MapsIcons from '@/components/icons/maps'
import { validationSchemaManyWidgetSettings } from '@/constants/validationFields'
import {
  MIN_VALUE,
  MAX_VALUE,
  MIN_CRITICAL_VALUE,
  MAX_CRITICAL_VALUE,
} from '@/constants/forms/objectWidgetSettings'
import {
  Form,
  FormTitle,
  FieldRow,
  Label,
  TextFieldStyled,
  Buttons,
  Error,
  IconContainer,
} from './styles'

const ManyObjectsWidgetSettings = ({
  onCancel,
  onSubmit,
  initialValues,
  data,
}) => {
  const onSubmitForm = (values) => {
    data.forEach((node) => {
      if (values[MIN_VALUE + node.title] === '' || values[MIN_VALUE + node.title] === 0) {
        values[MIN_VALUE + node.title] = '0'
      }
      if (values[MAX_VALUE + node.title] === '' || values[MAX_VALUE + node.title] === 0) {
        values[MAX_VALUE + node.title] = '0'
      }
    })
    onSubmit(values)
    onCancel()
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchemaManyWidgetSettings(data)}
      render={({
        handleSubmit, touched, errors,
      }) => (
        <Form onSubmit={handleSubmit} id="userData" name="userData">
          <FormTitle>
            <Lang id="objectWidget.title" />
          </FormTitle>
          {data.map((row) => (
            <>
              <FieldRow key={row.title}>
                <Label htmlFor={MIN_VALUE}>
                  <Lang id="objectWidget.minValue" />
                  {' '}
                  {row.title}
                  {':'}
                </Label>
                <TextFieldStyled
                  name={`${MIN_VALUE}${row.title}`}
                  customError
                  error={(touched[`${MIN_VALUE}${row.title}`] && errors[`${MIN_VALUE}${row.title}`])}
                  fieldProps={{
                    format: 'number',
                    autoComplete: 'off',
                    placeholder: '0',
                  }}
                />
                <IconContainer>
                  <CheckBoxIconField
                    name={`${MIN_CRITICAL_VALUE}${row.title}`}
                    icon={MapsIcons.WarningIcon}
                  />
                </IconContainer>
              </FieldRow>
              <Error>{errors[`${MIN_VALUE}${row.title}`] || errors[`${MIN_CRITICAL_VALUE}${row.title}`]}</Error>
              <FieldRow>
                <Label htmlFor={MAX_VALUE}>
                  <Lang id="objectWidget.maxValue" />
                  {' '}
                  {row.title}
                  {':'}
                </Label>
                <TextFieldStyled
                  name={`${MAX_VALUE}${row.title}`}
                  customError
                  error={(touched[`${MAX_VALUE}${row.title}`] && errors[`${MAX_VALUE}${row.title}`])}
                  fieldProps={{
                    format: 'number',
                    autoComplete: 'off',
                    placeholder: '0',
                  }}
                />
                <IconContainer>
                  <CheckBoxIconField
                    name={`${MAX_CRITICAL_VALUE}${row.title}`}
                    icon={MapsIcons.WarningIcon}
                  />
                </IconContainer>
              </FieldRow>
              <Error>{errors[`${MAX_VALUE}${row.title}`] || errors[`${MAX_CRITICAL_VALUE}${row.title}`]}</Error>
            </>
          ))}
          <Buttons>
            <Button type="submit">
              <Lang id="objectWidget.submit" />
            </Button>
            <Button styleType="flat" onClick={onCancel}>
              <Lang id="objectWidget.cancel" />
            </Button>
          </Buttons>
        </Form>
      )}
    />
  )
}

ManyObjectsWidgetSettings.propTypes = {
  onCancel: pt.func,
  onSubmit: pt.func,
  initialValues: pt.shape({
    min: pt.oneOfType([pt.number, pt.string]),
    max: pt.oneOfType([pt.number, pt.string]),
  }),
}
ManyObjectsWidgetSettings.defaultProps = {
  onCancel: noop,
  onSubmit: noop,
  initialValues: {},
}

export default ManyObjectsWidgetSettings
