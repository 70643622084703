import {
  take, call, put, select,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import createNotifications from '@/helpers/notification'
import { selectSystem, setMode, updatedScheme } from '@/store/actions/integrations'
import { errorCreateIntegration } from '@/store/actions/integrations/createIntegration'
import { requestGetIntegrations } from '@/store/actions/integrations/getIntegrations'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import {
  getIntegrationSchemeById,
  getForm,
} from '@/store/selectors/integrations'
import {
  ERRORS_TO_ERROR_MESSAGES,
  CREATE_INTEGRATION_ERROR,
  INTEGRATION_CREATED,
  INTEGRATION_SYNCHONIZE,
  KULON,
  ATM,
  BRIZ,
  MESH, INTEGRATION_UPDATED,
} from '@/constants/integrations'
import messagesAliasCreator from '../notificationElements'
import { createIntegrationsChannel } from './channels'

const MESSAGES_ALIASES = {
  [KULON]: messagesAliasCreator('АСУНО', 'КУЛОН'),
  [ATM]: messagesAliasCreator('АСУ', '1SIM'),
  [BRIZ]: messagesAliasCreator('АСУ', 'БРИЗ'),
  [MESH]: messagesAliasCreator('АСУ', 'МЭШ СЕТЬ'),
}

function* createIntegrationsSaga() {
  const toast = createNotifications()
  const channel = yield call(createIntegrationsChannel)

  while (true) {
    const eventData = yield take(channel)
    console.log('function*createIntegrationsSaga -> eventData', eventData);
    const form = yield select(getForm)
    const schemeByID = yield select(getIntegrationSchemeById)
    switch (eventData.status) {
      case 'ON_FAILED': {
        const newSchemeByID = {
          ...schemeByID,
          children: [{
            ...schemeByID.children[0],
            isSync: false,
            integrationOption: true,
          }],
        }
        yield put(updatedScheme(newSchemeByID))
        if (form) {
          form.setSubmitting(false)
        }
        if (eventData.errors.length) {
          const errorsMessage = eventData.errors.map((element) => {
            const theme = ERRORS_TO_ERROR_MESSAGES[element.subject] || {}
            const message = theme[element.message] || `${element.subject}: ${element.message}`
            return message
          }).join('\n')
          const errorNotifications = ERRORS_TO_ERROR_MESSAGES[eventData.errors[0].subject] || {}
          yield put(errorCreateIntegration(errorsMessage))
          const errorsObject = eventData.errors.reduce((accumulator, error) => {
            const theme = ERRORS_TO_ERROR_MESSAGES[error.subject] || {}
            const message = theme[error.message] || `${error.subject}: ${error.message}`
            return {
              ...accumulator,
              [(error.subject || '').toLowerCase()]: message,
            }
          }, {})
          const messageConfig = MESSAGES_ALIASES[
            eventData.integrationType
          ][CREATE_INTEGRATION_ERROR] || noop
          toast(messageConfig(eventData.alias, errorNotifications[eventData.errors[0].message] || `${eventData.description}`))
          if (form) {
            form.setSubmitting(false)
            form.setErrors(errorsObject)
          }
        }
        break
      }
      case 'IN_PROGRESS': {
        yield put(requestGetIntegrations())
        if (eventData.description === INTEGRATION_CREATED) {
          yield put(selectSystem(null))
          yield put(setMode('CONNECTED_INTEGRATION'))
          const messageConfig = MESSAGES_ALIASES[
            eventData.integrationType
          ][eventData.description] || noop
          toast(messageConfig(eventData.alias))
        }
        break
      }
      case 'COMPLETED': {
        if (eventData.description === INTEGRATION_SYNCHONIZE || INTEGRATION_UPDATED) {
          const messageConfig = MESSAGES_ALIASES[
              eventData.integrationType
          ][eventData.description] || noop
          yield put(selectSystem(null))
          yield put(setMode('CONNECTED_INTEGRATION'))
          yield put(requestGetIntegrations())
          yield put(requestGetMapObjects())
          toast(messageConfig(eventData.alias))
        }
        break
      }
      default: break
    }
  }
}

export default createIntegrationsSaga
