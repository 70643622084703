import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getMapsState = createSelector(
  getState,
  (state) => get(state, 'maps', {}),
)

export const getMapsDataState = createSelector(
  getMapsState,
  (state) => get(state, 'data', {}),
)

export const getTreeData = createSelector(
  getMapsDataState,
  (state) => get(state, 'tree', {}),
)
export const getLoading = createSelector(
  getMapsDataState,
  (state) => get(state, 'loading', {}),
)

export const getRegions = createSelector(
  getMapsDataState,
  (state) => get(state, 'regions', []),
)

export const getPins = createSelector(
  getMapsDataState,
  (state) => get(state, 'pins', []),
)

export const getCountOfPins = createSelector(
  getMapsDataState,
  (state) => get(state, 'countOfPins'),
)

export const getTelemetry = createSelector(
  getMapsDataState,
  (state) => get(state, 'telemetry', {}),
)

export const getZones = createSelector(
  getMapsDataState,
  (state) => get(state, 'zones', {}),
)
