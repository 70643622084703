import styled from 'styled-components'
import PortalTooltip from '@/components/blocks/PortalTooltip'

export const Wrapper = styled.div`
  width: auto;
  height: 48px;
  display: flex;
  align-items: center;
  z-index: 3;
  position: relative;
`

export const StyledPortalTooltip = styled(PortalTooltip)`
  
`

export const Title = styled.p`
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  font-style: normal;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 10px 10px 10px 10px;
`

export const ContentContainer = styled.div`
  width: 100%;
  padding: 10px;
  z-index: 110;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  position: absolute;
  top: 53px;
  width: 250px;
`

export const ButtonContainer = styled.div`
  cursor: pointer;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  ${({ isOpen }) => (isOpen
    ? 'transform: rotate(180deg);'
    : 'transform: rotate(0deg);')}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background:${({ isOpen, theme }) => (isOpen
    ? theme.colors.backgrounds.default
    : theme.colors.backgrounds.redisign.lightGray)};
  height: 28px;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4px;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    ${ButtonContainer} {
      cursor: not-allowed;
    }
    ${Title},
    ${ButtonContainer} > svg {
      opacity: 0.5;
    }
  `}
`
