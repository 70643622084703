import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ScheduleManagerIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12C12 11.448 12.448 11 13 11C13.552 11 14 11.448 14 12C14 12.552 13.552 13 13 13C12.448 13 12 12.552 12 12Z" fill="#3D3D3D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 1C2 0.448 2.448 0 3 0C3.552 0 4 0.448 4 1V2H14V1C14 0.448 14.448 0 15 0C15.552 0 16 0.448 16 1V2C17.105 2 18 2.895 18 4V16C18 17.105 17.105 18 16 18H2C0.895 18 0 17.105 0 16V4C0 2.895 0.895 2 2 2V1ZM2 7C2 6.448 2.448 6 3 6H15C15.552 6 16 6.448 16 7V15C16 15.552 15.552 16 15 16H3C2.448 16 2 15.552 2 15V7Z" fill="#3D3D3D"/>
    </svg>
  )
}

ScheduleManagerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ScheduleManagerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ScheduleManagerIcon)
