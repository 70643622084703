import { all } from 'redux-saga/effects'
import cityProcessesRoot from './cityProcesses'
import applicationsRoot from './applications'
import applicationsModelsRoot from './applicationsModels'

export default function* root() {
  yield all([
    cityProcessesRoot(),
    applicationsRoot(),
    applicationsModelsRoot(),
  ])
}
