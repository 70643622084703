import styled from 'styled-components'

export default styled.div`
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  ${({ date }) => date && `
    text-align: center;
  `}
  ${({ theme, isOverdue }) => isOverdue && `
    color: ${theme.colors.colors.redisign.error};
  `}
`
