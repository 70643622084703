import React, { useMemo, useCallback } from 'react'
import pt from 'prop-types'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import isEqual from 'lodash/isEqual'

import PopupIcons from '@/components/icons/popup'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import {
  INTEGRATION,
  ASU,
  OBJECT_TYPES,
  PROCESSES,
} from '@/constants/forms/globalFilterForm'
import {
  objectsTypes,
  typesByASU,
  processesByASU,
} from './config'
import {
  Title,
  TitleAndSelectWrapper,
  FormWrapper,
  StyledSelectField,
  StyledCheckBox,
  ButtonsWrapper,
  MultipleCheckBoxWrapper,
  StyledButton,
} from './styles'

const GlobalFilterForm = ({
  integrationSystems,
  onSetValues,
  onResetValues,
  cityProcesses,
  globalFiltersValues,
}) => {
  const systems = Object.keys(integrationSystems) || []

  const ASUOptions = systems.map((system) => ({
    title: integrationSystems[system].name,
    value: system,
  }))

  const formattedCityProcesses = useMemo(() => cityProcesses
    .map((process) => ({
      title: process.name,
      value: process.id,
    })), [cityProcesses])

  const integrationOptions = useMemo(() => (systems || [])
    .reduce((accumulator, systemName) => ([
      ...accumulator,
      ...(integrationSystems[systemName].children || [])
        .map((integrations) => ({
          title: integrations.name,
          value: integrations.id,
        })),
    ]), []), [integrationSystems, systems])

  const defaultOptionsByFields = useMemo(() => ({
    [ASU]: ASUOptions,
    [PROCESSES]: formattedCityProcesses,
    [INTEGRATION]: integrationOptions,
    [OBJECT_TYPES]: objectsTypes,
  }), [ASUOptions, formattedCityProcesses, integrationOptions])

  const fullFilters = useMemo(() => systems.map((system) => ({
    [INTEGRATION]: (integrationSystems[system].children || [])
      .map((integrations) => integrations.id),
    [ASU]: [system],
    [OBJECT_TYPES]: typesByASU[system],
    [PROCESSES]: processesByASU[system],
  })), [systems, integrationSystems])

  const getIncludedValues = useCallback(
    (base = [], array = []) => base.filter((value) => array.includes(value)),
    [],
  )

  const handleResetFilters = useCallback((resetForm) => (event) => {
    event.preventDefault()
    onResetValues()
    resetForm()
  }, [onResetValues])

  const getOptionsByValues = useCallback((field, values, setFieldValue) => {
    const currentFieldValue = values[field]
    const localFieldsValues = { ...values }
    delete localFieldsValues[field]

    const localFieldsKeys = Object.keys(localFieldsValues)
    const hasLocalFields = localFieldsKeys.length > 0
      && localFieldsKeys.some((localField) => !!values[localField].length)

    if (!hasLocalFields) {
      return defaultOptionsByFields[field]
    }

    const valuesIdsByFields = localFieldsKeys.reduce((fieldsAccumulator, fieldKey) => {
      const fieldValues = values[fieldKey]
      const optionsIdsForMap = fullFilters.reduce((accumulator, optionsConfig) => {
        const currentFieldOptions = getIncludedValues(optionsConfig[fieldKey], fieldValues)

        if (currentFieldOptions && currentFieldOptions.length) {
          return [...new Set([...(accumulator || []), ...optionsConfig[field]])]
        }
        return accumulator
      }, [])

      return [...new Set([...(fieldsAccumulator), ...optionsIdsForMap])]
    }, [])

    const options = defaultOptionsByFields[field]
      .filter((option) => valuesIdsByFields.includes(option.value))
    const filteredOriginalValues = (currentFieldValue || [])
      .filter((originalValue) => valuesIdsByFields.includes(originalValue))
    if (!isEqual(currentFieldValue, filteredOriginalValues)) {
      setFieldValue(field, filteredOriginalValues)
    }
    return options
  }, [defaultOptionsByFields, fullFilters, getIncludedValues])

  return (
    <Formik
      enableReinitialize
      onSubmit={onSetValues}
      initialValues={globalFiltersValues}
      render={({
        values, handleSubmit, resetForm, setFieldValue,
      }) => (
        <FormWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.ASU" />
            </Title>
            <StyledSelectField
              multiselect
              placeholder={<Lang id="globalFilter.selectPlaceholders.ASU" />}
              withSearch
              name={ASU}
              options={getOptionsByValues(ASU, values, setFieldValue)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.process" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={PROCESSES}
              placeholder={<Lang id="globalFilter.selectPlaceholders.process" />}
              options={getOptionsByValues(PROCESSES, values, setFieldValue)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.integration" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={INTEGRATION}
              placeholder={<Lang id="globalFilter.selectPlaceholders.integration" />}
              options={getOptionsByValues(INTEGRATION, values, setFieldValue)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.objectTypes" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={OBJECT_TYPES}
              placeholder={<Lang id="globalFilter.selectPlaceholders.objectTypes" />}
              options={getOptionsByValues(OBJECT_TYPES, values, setFieldValue)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title><Lang id="globalFilter.formTitles.objectState" /></Title>
            <MultipleCheckBoxWrapper>
              <StyledCheckBox type={OK} name={OK} />
              <StyledCheckBox type={WARNING} name={WARNING} />
              <StyledCheckBox type={ERROR} name={ERROR} />
              <StyledCheckBox type={INFO} name={INFO} />
            </MultipleCheckBoxWrapper>
          </TitleAndSelectWrapper>
          <ButtonsWrapper>
            <StyledButton usage="reset" onClick={handleResetFilters(resetForm)}>
              <PopupIcons.ResetFilterIcon color="#3D3D3D" />
              <Lang id="globalFilter.buttons.reset" />
            </StyledButton>
            <StyledButton usage="accept" onClick={handleSubmit}>
              <PopupIcons.DoneFilterIcon color="#FFFFFF" />
              <Lang id="globalFilter.buttons.accept" />
            </StyledButton>
          </ButtonsWrapper>
        </FormWrapper>
      )}
    />
  )
}

GlobalFilterForm.propTypes = {
  cityProcesses: pt.arrayOf(pt.shape({
    name: pt.string,
    id: pt.oneOfType([pt.string, pt.number]),
  })),
  integrationSystems: pt.shape({}),
  onSetValues: pt.func.isRequired,
  onResetValues: pt.func.isRequired,
  globalFiltersValues: pt.shape({
    [ASU]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    [PROCESSES]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    [INTEGRATION]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    [OBJECT_TYPES]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
  }),
}

GlobalFilterForm.defaultProps = {
  cityProcesses: [],
  integrationSystems: {},
  globalFiltersValues: {},
}

export default GlobalFilterForm
