import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import DASHBOARD_URBAN_PROCESSES from '@/constants/dashboardUrbanProcesses'
import { isUserHasPermission } from '@/helpers/userPermissions'
import {
  Container,
  Content,
  Title,
  Indicator,
} from './styles'

const UrbanProcesses = ({ user }) => (
  <Container>
    <Content header>
      <WidgetsDashboardIcon.UrbanProcessesIcon />
      <Title header>
        <Lang id="widgetsDashboard.urbanProcesses" />
      </Title>
    </Content>
    {DASHBOARD_URBAN_PROCESSES.map((widget) => {
      if (
        (widget.applicationId)
        && !isUserHasPermission(user, widget.applicationId)
      ) {
        return null
      }
      return (
        <Content key={widget.id} main selected={widget.selected}>
          <Indicator selected={widget.selected} />
          <Title>{widget.title}</Title>
        </Content>
      )
    })}
  </Container>
)

UrbanProcesses.propTypes = {
}
UrbanProcesses.defaultProps = {
}

export default UrbanProcesses
