import {
  put, takeLatest, select, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import { successEditProject } from '@/store/actions/installation/editProject'
import { PROJECT } from '@/constants/objectTypes'
import { getCard } from '@/store/selectors/installation'
import { EDIT_PROJECT } from '@/store/actions/installation'
import { INSTALLATIONS_PROJECT_ENDPOINT } from '@/constants/apiRoutes'
import updateNodeCount from '@/helpers/updateNodeCount'

function* editProjectSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    customFields = [], mark, name, parentId,
  } = values
  try {
    const filterFields = (customFields || []).filter((element) => !!element.remove === false)
    const formattedCustomFields = filterFields.map((element) => ({
      ...element,
      nameField: element.nameField,
      value: element.value,
    }))
    const cardCopy = yield select(getCard)
    const body = {
      ...cardCopy.data,
      type: PROJECT,
      name,
      note: mark,
      parentTreeElementId: parentId,
      customFields: formattedCustomFields,
    }
    const { data } = yield request({
      url: INSTALLATIONS_PROJECT_ENDPOINT,
      method: 'put',
      body,
    })
    const {
      rootId, parentTreeId, path, FILTER,
    } = formValues

    const newPath = path.replace(parentTreeId, data.parentTreeElementId)
    const tree = treeHandlers.trees['installations-tree']
    const node = tree.instance.getNodeById(data.treeElementId)
    if (cardCopy.data.parentTreeElementId !== data.parentTreeElementId) {
      const prevParentNode = tree.instance.getNodeById(parentTreeId)
      const prevParentNodePath = prevParentNode.options.path
      const prevParentChildren = yield call(prevParentNode.data.getChildren, prevParentNode)
      const newParentNode = tree.instance.getNodeById(data.parentTreeElementId)
      const newParentNodePath = newParentNode.options.path
      const newParentChildren = yield call(newParentNode.data.getChildren, newParentNode)
      yield tree.handlers.setRawChildren(prevParentNode, prevParentChildren, 'first', true)
      updateNodeCount(tree, prevParentNodePath, 'remove', node.data.childrenCount + 1)
      yield tree.handlers.setRawChildren(newParentNode, newParentChildren, 'first', true)
      updateNodeCount(tree, newParentNodePath, 'add', node.data.childrenCount + 1)
      tree.handlers.setOpen(newParentNode, true)
      tree.handlers.setSelected(node, true)
    } else {
      tree.handlers.setNodeData(data.treeElementId, {
        ...node.data,
        name: data.name,
        elementName: data.name,
      })
    }
    tree.handlers.rerender()
    setUrlFormValues({
      id: data.treeElementId,
      type: data.type,
      parentGeoZoneId: data.parentTreeElementId,
      rootId,
      path: newPath,
      parentTreeId: data.parentTreeElementId,
      FILTER,
    })
    yield put(successEditProject())
  } catch (error) {
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*editProjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(EDIT_PROJECT.REQUEST, editProjectSaga)
}
