import {
  OK, WARNING, ERROR, INFO,
} from '@/constants/objectStatuses'

const defaultGetTelemetry = () => ({ statistic: null, status: null })

const getStatistic = (
  element,
  items,
  elementIdFieldName,
  itemIdFieldName,
  getTelemetry = defaultGetTelemetry,
) => {
  let count = 0
  const filteredItems = items.reduce((accumulator, item) => {
    if (item[itemIdFieldName] === element[elementIdFieldName]) {
      count += 1
      return {
        ...accumulator,
        [getTelemetry(item).status || item.status]: accumulator[getTelemetry(item).status || item.status] + 1,
      }
    }
    return accumulator
  },
  {
    [OK]: 0,
    [WARNING]: 0,
    [INFO]: 0,
    [ERROR]: 0,
  })

  return {
    ...element,
    count,
    statistic: filteredItems,
  }
}

export const getZoneStatistic = (
  element,
  items,
  getTelemetry = defaultGetTelemetry,
) => {
  let count = 0
  const filteredItems = items.reduce((accumulator, item) => {
    if ((element.zoneSelector || []).includes(item.geoZoneId)) {
      if (item.type === 'groupObject' && item.childrenSelector && item.childrenSelector.length !== 0) {
        count += item.childrenSelector.length
        return item.childrenSelector.reduce((buffer, groupObjectChild) => ({
          ...buffer,
          [getTelemetry(groupObjectChild).status || groupObjectChild.status]: buffer[getTelemetry(groupObjectChild).status || groupObjectChild.status] + 1,
        }), accumulator)
      }
      count += 1
      return {
        ...accumulator,
        [getTelemetry(item).status || item.status || INFO]: accumulator[getTelemetry(item).status || item.status || INFO] + 1,
      }
    }
    return accumulator
  },
  {
    [OK]: 0,
    [WARNING]: 0,
    [INFO]: 0,
    [ERROR]: 0,
  })

  return {
    ...element,
    count,
    statistic: filteredItems,
  }
}

export const getRegionsStatistic = (element, items, getTelemetry) => getStatistic(element, items, 'id', 'geoZoneId', getTelemetry)

export const getObjectStatistic = (element, items, getTelemetry) => getStatistic(element, items, 'id', 'parentId', getTelemetry)

export const getTreeStructure = (county, geoZones) => {
  const treeStructure = county.map((element) => ({
    id: element.id,
    title: element.title,
    active: true,
    type: 'CompanyIcon',
    center: element.location,
    zoom: element.zoom,
    children: geoZones.reduce((accumulator, city) => {
      if (city.countyId === element.id) {
        accumulator.push({
          id: city.id,
          title: city.name,
          zoom: city.zoom,
          type: 'AriaIcon',
          center: city.location,
        })
      }
      return accumulator
    }, []),
  }))
  return treeStructure
}
