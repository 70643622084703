import {
  MINUTES_IN_HOUR,
  SECONDS_IN_MINUTE,
  MINUTES_IN_DAY,
} from '@/constants/time'

export const getSplitTime = (value) => {
  const days = Math.floor(value / MINUTES_IN_DAY)
  const hoursAfterDays = value % MINUTES_IN_DAY
  const hours = Math.floor(hoursAfterDays / MINUTES_IN_HOUR)
  const minutesAfterHours = hoursAfterDays % MINUTES_IN_HOUR
  const minutes = Math.floor(minutesAfterHours)
  const seconds = Math.floor((minutesAfterHours - minutes) * SECONDS_IN_MINUTE)
  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export const getStringFromSplit = (splitTime) => {
  const dayPart = splitTime.days ? `${splitTime.days} д.` : ''
  const hoursPart = splitTime.hours ? ` ${splitTime.hours} ч.` : ''
  const minutesPart = splitTime.minutes ? ` ${splitTime.minutes} мин.` : ''
  const secondsPart = splitTime.seconds ? ` ${splitTime.seconds} сек.` : ''

  return `${dayPart}${hoursPart}${minutesPart}${secondsPart}`
}

export const getStringTimeFromNumber = (value) => getStringFromSplit(getSplitTime(value))
