import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DateSortIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33333 8.1H3.55556V9.9H5.33333V8.1ZM8.88889 8.1H7.11111V9.9H8.88889V8.1ZM12.4444 8.1H10.6667V9.9H12.4444V8.1ZM14.2222 1.8H13.3333V0H11.5556V1.8H4.44444V0H2.66667V1.8H1.77778C0.791111 1.8 0.00888888 2.61 0.00888888 3.6L0 16.2C0 16.6774 0.187301 17.1352 0.520699 17.4728C0.854097 17.8104 1.30628 18 1.77778 18H14.2222C15.2 18 16 17.19 16 16.2V3.6C16 2.61 15.2 1.8 14.2222 1.8ZM14.2222 16.2H1.77778V6.3H14.2222V16.2Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DateSortIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DateSortIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DateSortIcon)
