import ApplicationIcons from '@/components/icons/applications'

export const USERS = 'USERS'
export const ACTIVITY_LOG = 'ACTIVITY_LOG'
export const PASSPORTIZATION = 'PASSPORTIZATION'
export const INSTALLATION = 'INSTALLATION'
export const INVENTORY = 'INVENTORY'
export const MONITORING_CENTER = 'MONITORING_CENTER'
export const REPORT_MANAGER = 'REPORT_MANAGER'
export const SUPPORT = 'SUPPORT'
export const SCHEDULE_MANAGER = 'SCHEDULE_MANAGER'
export const CONTROL_OBJECTS_STATISTICS = 'CONTROL_OBJECTS_STATISTICS'
export const ALARM_LOG = 'ALARM_LOG'
export const LIFETIME = 'LIFETIME'
export const RESOURCE_ANALYTICS = 'RESOURCE_ANALYTICS'
export const HEATING = 'HEATING'
export const WATER = 'WATER'
export const CHARGING_STATIONS = 'CHARGING_STATIONS'
export const ART_LIGHTING = 'ART_LIGHTING'
export const WASTE_COLLECTION = 'WASTE_COLLECTION'
export const ROAD_MONITORING = 'ROAD_MONITORING'
export const TRAFFIC_LIGHTS = 'TRAFFIC_LIGHTS'
export const INFO_BOARDS = 'INFO_BOARDS'
export const DISABLED_PARKING_SPACES = 'DISABLED_PARKING_SPACES'
export const DASHBOARD = 'DASHBOARD'
export const DASHBOARD2 = 'DASHBOARD2'
export const ALARM_ANALYTICS = 'ALARM_ANALYTICS'
export const TYUMEN_PORTAL = 'TYUMEN_PORTAL'
export const SERVICE_DESK = 'SERVICE_DESK'
export const ALL = 'ALL'
export const TENANT = 'TENANT'
export const MAP_SERVICE = 'MAP_SERVICE'
export const RESPONSE_STATUS_CORRECT = 204

export const APPLICATION_CONFIG = {
  [PASSPORTIZATION]: {
    icon: ApplicationIcons.PassportizationIcon,
  },
  [MAP_SERVICE]: {
    icon: ApplicationIcons.MapServiceIcon,
  },
  [INSTALLATION]: {
    icon: ApplicationIcons.InstallationIcon,
  },
  [INVENTORY]: {
    icon: ApplicationIcons.EquipmentIcon,
  },
  [MONITORING_CENTER]: {
    icon: ApplicationIcons.ObjectsIcon,
  },
  [REPORT_MANAGER]: {
    icon: ApplicationIcons.ReportManagerIcon,
  },
  [SUPPORT]: {
    icon: ApplicationIcons.SupportIcon,
  },
  [SCHEDULE_MANAGER]: {
    icon: ApplicationIcons.ScheduleManagerIcon,
  },
  [CONTROL_OBJECTS_STATISTICS]: {
    icon: ApplicationIcons.ObjectStatisticIcon,
  },
  [ALARM_LOG]: {
    icon: ApplicationIcons.AlarmsIcon,
  },
  [LIFETIME]: {
    icon: ApplicationIcons.LifeIcon,
  },
  [RESOURCE_ANALYTICS]: {
    icon: ApplicationIcons.AnalyticsExpensesIcon,
  },
  [HEATING]: {
    icon: ApplicationIcons.HeatSupplyIcon,
  },
  [WATER]: {
    icon: ApplicationIcons.WaterSupplyIcon,
  },
  [CHARGING_STATIONS]: {
    icon: ApplicationIcons.CarElectricStationsIcon,
  },
  [ART_LIGHTING]: {
    icon: ApplicationIcons.IlluminationIcon,
  },
  [WASTE_COLLECTION]: {
    icon: ApplicationIcons.SmartRecycleBinIcon,
  },
  [ROAD_MONITORING]: {
    icon: ApplicationIcons.RoadIcon,
  },
  [TRAFFIC_LIGHTS]: {
    icon: ApplicationIcons.TrafficLightIcon,
  },
  [INFO_BOARDS]: {
    icon: ApplicationIcons.InfoFrameIcon,
  },
  [DISABLED_PARKING_SPACES]: {
    icon: ApplicationIcons.HandicappedIcon,
  },
  [DASHBOARD]: {
    icon: ApplicationIcons.WidgetsDashboard,
  },
  [DASHBOARD2]: {
    icon: ApplicationIcons.WidgetsDashboard,
  },
  [ALARM_ANALYTICS]: {
    icon: ApplicationIcons.AlarmsIcon,
  },
  [TYUMEN_PORTAL]: {
    icon: ApplicationIcons.TyumenIcon,
  },
  [SERVICE_DESK]: {
    icon: ApplicationIcons.ServiceDeskIcon,
  },
}
