import styled from 'styled-components'
import CheckBoxField from '@/components/fields/CheckBoxField'

export const Form = styled.form`
  width: 100%;
  margin-top: 15px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`

export const Text = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.colors.charts};
`

export const ConditionalText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.backgrounds.button};
`

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`

export const StyledCheckBoxField = styled(CheckBoxField)`
  margin-right: 15px;
`
