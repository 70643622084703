import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const SmartRecycleBinIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 3V2C6 0.895 6.895 0 8 0H10C11.105 0 12 0.895 12 2V3H16C16.552 3 17 3.448 17 4C17 4.552 16.552 5 16 5V15C16 16.657 14.657 18 13 18H5C3.343 18 2 16.657 2 15V5C1.448 5 1 4.552 1 4C1 3.448 1.448 3 2 3H6ZM8 3H10V2H8V3ZM4 15V5H14V15C14 15.552 13.552 16 13 16H5C4.448 16 4 15.552 4 15Z" fill="#3D3D3D"/>
    </svg>
  )
}

SmartRecycleBinIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SmartRecycleBinIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SmartRecycleBinIcon)
