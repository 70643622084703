import { connect } from 'react-redux'
import { getTreeData } from '@/store/selectors/maps/socketEvents'
import { setSelectedNode } from '@/store/actions/appSettings'

import AlarmsAnalyticsPage from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),

})
const mapDispatchToProps = (dispatch) => ({
  setNode: (payload) => dispatch(setSelectedNode(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsAnalyticsPage)
