import styled from 'styled-components'

export const ObjectPassportizationStyled = styled.div`
  min-width: ${({ theme }) => theme.sizes.passportizationWidth}px;
  max-width: ${({ theme }) => theme.sizes.passportizationWidth}px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const TabRow = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2.4rem;
  min-height: 28px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 6px;
  svg {
    margin-right: 5px;
  }
`

export const ObjectTitle = styled.div`
  margin: auto;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ObjectNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-left: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  width: 48px;
  height: 48px;
  svg {
    width: 28px;
    height: 28px;
  }
`
