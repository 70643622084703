import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  WARNING,
  ERROR,
  OK,
  UNKNOWN,
} from '@/constants/alarms'
import AlarmsIcons from '@/components/icons/alarms'

export const elementsToStatus = {
  [OK]: {
    name: (<Lang id="faultAnalytics.alarmTypeTitle.ok" />),
    icon: (<AlarmsIcons.OkIcon />),
  },
  [WARNING]: {
    name: (<Lang id="faultAnalytics.alarmTypeTitle.warning" />),
    icon: (<AlarmsIcons.WarningIcon />),
  },
  [ERROR]: {
    name: (<Lang id="faultAnalytics.alarmTypeTitle.error" />),
    icon: (<AlarmsIcons.ErrorIcon />),
  },
  [UNKNOWN]: {
    name: (<Lang id="faultAnalytics.alarmTypeTitle.info" />),
    icon: (<AlarmsIcons.UnknownIcon />),
  },
}

export default elementsToStatus
