import { DELETE_TENANT_ADMIN } from '../index'

export const requestDeleteAdminTenant = (payload) => ({
  type: DELETE_TENANT_ADMIN.REQUEST,
  payload,
})

export const responseDeleteAdminTenant = (payload) => ({
  type: DELETE_TENANT_ADMIN.SUCCESS,
  payload,
})

export const errorDeleteAdminTenant = (payload) => ({
  type: DELETE_TENANT_ADMIN.ERROR,
  payload,
})
