import { connect } from 'react-redux'
import {
  setTheme,
  setScheme,
  setFontSize,
  toggleSettings,
  startAddWidgetToGeneralPage,
  setWidgetShopValue,
} from '@/store/actions/appSettings'
import {
  getSettingsOpenState,
  getThemeState,
  getSchemeState,
  getFontState,
  getWidgetShopValue,
  getUserData,
  getWidgets,
  getApplications,
} from '@/store/selectors/appSettings'
import SettingsSidebar from '@/components/regions/sidebars/SettingsSidebar'

export default connect(
  (state) => ({
    isOpen: getSettingsOpenState(state),
    theme: getThemeState(state),
    scheme: getSchemeState(state),
    largeFont: getFontState(state),
    widgetShopValue: getWidgetShopValue(state),
    user: getUserData(state),
    addedWidgets: getWidgets(state),
    addedApplications: getApplications(state),
  }),
  (dispatch) => ({
    setTheme: (theme) => dispatch(setTheme(theme)),
    setScheme: (scheme) => dispatch(setScheme(scheme)),
    setFontSize: (large) => dispatch(setFontSize(large)),
    setWidgetShopValue: (value) => dispatch(setWidgetShopValue(value)),
    onClose: () => dispatch(toggleSettings(false)),
    widgetsActions: {
      onAddWidget: (type, widgetType, large) => dispatch(startAddWidgetToGeneralPage(type, widgetType, large)),
    },
  }),
)(SettingsSidebar)
