export const graphOfDifferenceConsumptionFormatter = (data) => data.map((element) => {
  if (!element.currentExpense) {
    return {
      dateStep: element.dateStep,
      beforePeriod: element.previousExpense,
    }
  }
  if (element.previousExpense > element.currentExpense) {
    return {
      dateStep: element.dateStep,
      base: element.currentExpense,
      minus: Math.floor((element.previousExpense - element.currentExpense) * 100) / 100,
    }
  }
  if (element.previousExpense < element.currentExpense) {
    return {
      dateStep: element.dateStep,
      base: element.previousExpense,
      plus: Math.floor((element.currentExpense - element.previousExpense) * 100) / 100,
    }
  }
  return {
    dateStep: element.dateStep,
    base: element.currentExpense,
  }
})

export default graphOfDifferenceConsumptionFormatter
