import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const BigLightIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="87" height="134" viewBox="0 0 87 134" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M79.017 125.707L76.4582 94.5352H69.0882V27.7754C69.0882 12.7357 57.0354 0.5 42.2206 0.5H0V4.39648C0 15.1391 8.60916 23.8789 19.1912 23.8789C28.459 23.8789 36.2132 17.1744 37.9967 8.29297H42.2206C52.8026 8.29297 61.4118 17.0328 61.4118 27.7754V94.5352H54.0418L51.483 125.707H43.5V133.5H87V125.707H79.017ZM19.1912 16.0859C14.1869 16.0859 9.91826 12.8287 8.33409 8.29297H30.048C28.4641 12.8287 24.1955 16.0859 19.1912 16.0859ZM59.1861 125.707L61.1052 102.328H69.3948L71.3139 125.707H59.1861Z" fill={fill} className="svgFill" />
    </svg>
  )
}

BigLightIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

BigLightIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(BigLightIcon)
