import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InstallationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6475 17.6472C17.4249 17.8701 17.1613 17.9873 16.8566 17.9991C16.552 18.0108 16.2942 17.9111 16.0833 17.7L14.4135 16.0286C13.4058 16.6972 12.2838 16.9669 11.0476 16.8379C9.81139 16.7089 8.7539 16.2045 7.8751 15.3249L7.5939 15.0434L15.0463 7.6013L15.3099 7.8652C16.1887 8.7449 16.6926 9.8034 16.8215 11.0408C16.9504 12.2783 16.6867 13.4072 16.0305 14.4276L17.7003 16.0814C17.9112 16.2925 18.0108 16.5505 17.9991 16.8555C17.9874 17.1605 17.8702 17.4244 17.6475 17.6472ZM9.3339 7.5661L7.5412 5.7892L5.7835 7.5485L7.5763 9.3255C7.7872 9.5366 7.8868 9.7946 7.8751 10.0996C7.8634 10.4045 7.7462 10.6684 7.5236 10.8913C7.301 11.1142 7.0373 11.2314 6.7327 11.2432C6.428 11.2549 6.1702 11.1552 5.9593 10.9441L4.16651 9.1671L2.93617 10.3987L2.67253 10.1172C1.79371 9.2375 1.28986 8.179 1.16096 6.9416C1.03207 5.7042 1.29571 4.5811 1.9519 3.57241L0.299715 1.91862C0.0888054 1.7075 -0.0107946 1.44946 0.000925431 1.1445C0.0126354 0.839546 0.129815 0.575646 0.352445 0.352796C0.575076 0.129936 0.838726 0.0126462 1.14339 0.000926171C1.44804 -0.0108038 1.70583 0.0888866 1.91674 0.300017L3.56892 1.9538C4.57663 1.28525 5.6986 1.01548 6.9348 1.1445C8.171 1.27352 9.2344 1.77787 10.1249 2.65754L10.3885 2.93904L9.1582 4.17058L10.951 5.9475C11.1619 6.1587 11.2615 6.4167 11.2498 6.7216C11.2381 7.0266 11.1209 7.2905 10.8982 7.5133C10.6756 7.7362 10.412 7.8535 10.1073 7.8652C9.8027 7.8769 9.5449 7.7773 9.3339 7.5661Z" fill={fill} className="svgFill" />
    </svg>
  )
}

InstallationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InstallationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InstallationIcon)
