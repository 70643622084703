import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SupportIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 28 28">
      <path fillRule="evenodd" d="M13.998 18.63c-1.624 0-3.275-.967-4.238-2.246C5.14 16.384 5 23 5 23h17.995s.362-6.645-4.834-6.645c-.962 1.295-2.539 2.275-4.163 2.275zM17.868 10.698c0 1.904-1.733 5.544-3.873 5.544-2.137 0-3.872-3.641-3.872-5.544 0-1.902 1.734-3.447 3.872-3.447 2.14.001 3.873 1.546 3.873 3.447z" clipRule="evenodd" fill={fill} className="svgFill" />
      <path fillRule="evenodd" d="M19.596 9.055c0-.356-.495-.644-1.11-.646v-.735c0-.11.041-2.674-4.478-2.674-4.517 0-4.475 2.564-4.475 2.674v.76c-.012 0-.02-.003-.03-.003-.612 0-1.105.283-1.105.635v3.109c0 .349.494.633 1.104.633.61 0 1.106-.284 1.106-.633v-3.11c0-.048-.03-.092-.048-.137V8.08c0-.078-.324-2.013 3.448-2.013 3.774 0 3.382 1.935 3.382 2.013v.893c-.007.028-.029.053-.029.082v3.18c0 .359.5.649 1.118.649.013 0 .023-.005.037-.005v1.162h-1.108v1.095h2.2l-.012-6.08z" clipRule="evenodd" fill={fill} className="svgFill" />
    </svg>
  )
}

SupportIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SupportIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SupportIcon)
