import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const ArrowWrapper = styled.div`
  margin-left: auto;
  svg {
    transform: rotate(90deg);
    transition: transform 200ms linear;
    .svgFill {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
  }
`

export const IconAndTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ControlsContainer = styled.div`
  ${({ count }) => count && `
    width: 65px;
  `}
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CountContainer = styled.div`
  font-family: Istok Web;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
`

export const CollapsedBlockStyled = styled.div`
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return 'cursor: pointer;'
    }
    return 'margin-top: 10px;'
  }}
`

export const CollapsedTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 10px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        height: 38px;
        background: ${theme.colors.backgrounds.redisign.hightLighter};
      `
    }
    return null
  }}
  span {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-left: 5px;
    white-space: nowrap;
    ${({ iconMargin }) => iconMargin && `
      margin-left: ${iconMargin}px;
    `}
  }

  ${({ opened }) => opened && `
    ${ArrowWrapper} {
      svg {
        transform: rotate(270deg);
      }
    }
  `}
`
