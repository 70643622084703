import React from 'react'
import pt from 'prop-types'

import {
  Container,
  Title,
} from './styles'

const UserWithCheckBoxAndPicture = ({
  row,
}) => {
  const {
    isOnline,
    blocked,
    enabled,
  } = row.original
  return (
    <Container>
      <Title isOnline={enabled} blocked={blocked}>
        {blocked
          ? (
            <>
              Заблок.
            </>
          )
          : (
            <>
              {enabled
                ? 'Подтвержден'
                : isOnline ? 'Онлайн' : 'Оффлайн'}
            </>
          )}
      </Title>
    </Container>
  )
}


UserWithCheckBoxAndPicture.propTypes = {
  row: pt.shape({
    original: pt.shape({
      isOnline: pt.bool,
      blocked: pt.bool,
    }),
  }),
}
UserWithCheckBoxAndPicture.defaultProps = {
  row: {
    original: {
      isOnline: false,
      blocked: false,
    },
  },
}

export default UserWithCheckBoxAndPicture
