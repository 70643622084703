import { GET_INTEGRATION_TYPES } from './index'


export const requestGetIntegrationTypes = (payload) => ({
  type: GET_INTEGRATION_TYPES.REQUEST,
  payload,
})

export const successGetIntegrationTypes = (payload) => ({
  type: GET_INTEGRATION_TYPES.SUCCESS,
  payload,
})

export const errorGetIntegrationTypes = (payload) => ({
  type: GET_INTEGRATION_TYPES.ERROR,
  payload,
})
