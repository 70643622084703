const graphPercentScheme = [
  {
    id: 11,
    mainColor: '#8F3D3C',
    secondaryColor: '#CC0A00',
    getText: (value) => `> 100% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 10,
    mainColor: '#8F523D',
    secondaryColor: '#CC3500',
    getText: (value) => `90% - 100% (${value})`,
    indicatorWidth: 20,
  },
  {
    id: 9,
    mainColor: '#8F683D',
    secondaryColor: '#CC6A00',
    getText: (value) => `80% - 90% (${value})`,
    indicatorWidth: 15,
  },
  {
    id: 8,
    mainColor: '#8F7D3D',
    secondaryColor: '#CC9F00',
    getText: (value) => `70% - 80% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 7,
    mainColor: '#8C8F3D',
    secondaryColor: '#C4CB01',
    getText: (value) => `60% - 70% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 6,
    mainColor: '#768F3D',
    secondaryColor: '#8FCC00',
    getText: (value) => `50% - 60% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 5,
    mainColor: '#618F3D',
    secondaryColor: '#5ACC00',
    getText: (value) => `40% - 50% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 4,
    mainColor: '#4C8F3D',
    secondaryColor: '#38CC00',
    getText: (value) => `30% - 40% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 3,
    mainColor: '#3D8F44',
    secondaryColor: '#38CC0F',
    getText: (value) => `20% - 30% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 2,
    mainColor: '#3D8F59',
    secondaryColor: '#37CC44',
    getText: (value) => `10% - 20% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 1,
    mainColor: '#3D8F6E',
    secondaryColor: '#36CC7A',
    getText: (value) => `0% - 10% (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 0,
    mainColor: '#828D99',
    secondaryColor: '#BEC2CC',
    getText: (value) => `Неизвестно (${value})`,
    indicatorWidth: 10,
  },
]
const graphYearScheme = [
  {
    id: 16,
    mainColor: '#894947',
    secondaryColor: '#BD2018',
    getText: (value) => `> 15 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 15,
    mainColor: '#8A5147',
    secondaryColor: '#BF3319',
    getText: (value) => `14 - 15 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 14,
    mainColor: '#8B5848',
    secondaryColor: '#C1421A',
    getText: (value) => `13 - 14 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 13,
    mainColor: '#8B5F48',
    secondaryColor: '#C4541B',
    getText: (value) => `12 - 13 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 12,
    mainColor: '#8C6549',
    secondaryColor: '#C6661D',
    getText: (value) => `11 - 12 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 11,
    mainColor: '#8D6C49',
    secondaryColor: '#C8761E',
    getText: (value) => `10 - 11 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 10,
    mainColor: '#8E7349',
    secondaryColor: '#CA851F',
    getText: (value) => `9 - 10 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 9,
    mainColor: '#8F794A',
    secondaryColor: '#CD9720',
    getText: (value) => `8 - 9 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 8,
    mainColor: '#90804A',
    secondaryColor: '#CFA821',
    getText: (value) => `7 - 8 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 7,
    mainColor: '#89824E',
    secondaryColor: '#BFAC2A',
    getText: (value) => `6 - 7 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 6,
    mainColor: '#828452',
    secondaryColor: '#A9B237',
    getText: (value) => `5 - 6 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 5,
    mainColor: '#7B8657',
    secondaryColor: '#94B744',
    getText: (value) => `4 - 5 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 4,
    mainColor: '#73875B',
    secondaryColor: '#83BA4D',
    getText: (value) => `3 - 4 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 3,
    mainColor: '#6C895F',
    secondaryColor: '#75BE55',
    getText: (value) => `2 - 3 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 2,
    mainColor: '#658B64',
    secondaryColor: '#64C260',
    getText: (value) => `1 - 2 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 1,
    mainColor: '#5D8C68',
    secondaryColor: '#50C66B',
    getText: (value) => `0 - 1 (${value})`,
    indicatorWidth: 10,
  },
  {
    id: 0,
    mainColor: '#828D99',
    secondaryColor: '#BEC2CC',
    getText: (value) => `Неизвестно (${value})`,
    indicatorWidth: 10,
  },
]

const formatGraphYear = (graphYear) => {
  const keys = Object.keys(graphYear)
  let count = 0
  keys.forEach((element) => {
    count += graphYear[element]
  })
  return graphYearScheme.reduce((accumulator, element) => [
    ...accumulator,
    {
      id: element.id,
      mainColor: element.mainColor,
      secondaryColor: element.secondaryColor,
      text: element.getText(graphYear[element.id] || 0),
      indicatorWidth: Math.floor((100 * graphYear[element.id]) / count) || 0,
    },
  ], [])
}

const formatGraphPersentages = (graphPercent) => {
  const keys = Object.keys(graphPercent)
  let count = 0
  keys.forEach((element) => {
    count += graphPercent[element]
  })
  return graphPercentScheme.reduce((accumulator, element) => [
    ...accumulator,
    {
      id: element.id,
      mainColor: element.mainColor,
      secondaryColor: element.secondaryColor,
      text: element.getText(graphPercent[element.id] || 0),
      indicatorWidth: Math.floor((100 * graphPercent[element.id]) / count) || 0,
    },
  ], [])
}


export default (graphPercent, graphYear) => ({
  graphPercent: formatGraphPersentages(graphPercent),
  graphYear: formatGraphYear(graphYear),
})
