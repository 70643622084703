import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_PASSPORT_FILES_URL } from '@/constants/apiRoutes'
import { GET_PASSPORT_PHOTO } from '@/store/actions/passportization'
import {
  responseGetPassportPhoto,
  errorGetPassportPhoto,
} from '@/store/actions/passportization/getPassportPhoto'
import createNotifications from '@/helpers/notification'

function* getPassportFilesSaga({ payload }) {
  const {
    id,
  } = payload
  const toast = createNotifications()
  try {
    const response = yield request({
      url: `${GET_PASSPORT_FILES_URL}?id=${id}`,
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })
    const newBlob = new window.Blob([response.data], { type: 'application/octet-stream' })
    const fileURL = window.URL.createObjectURL(newBlob)
    yield put(responseGetPassportPhoto({ photo: fileURL }))
  } catch (error) {
    console.log('function*getPassportFilesSaga -> error', error);
    yield put(errorGetPassportPhoto(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка операции! Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_PASSPORT_PHOTO.REQUEST, getPassportFilesSaga)
}
