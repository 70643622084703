import ActivityLogIcon from './ActivityLogIcon'
import AlarmsIcon from './AlarmsIcon'
import AlertManagerIcon from './AlertManagerIcon'
import CarElectricStationsIcon from './CarElectricStationsIcon'
import ChangeLogIcon from './ChangeLogIcon'
import ControlCenterIcon from './ControlCenterIcon'
import EcoStationsIcon from './EcoStationsIcon'
import EnergyConsumptionIcon from './EnergyConsumptionIcon'
import EquipmentReferenceIcon from './EquipmentReferenceIcon'
import GroupControlIcon from './GroupControlIcon'
import HeatSupplyIcon from './HeatSupplyIcon'
import IndividualControlIcon from './IndividualControlIcon'
import InstallObjectsIcon from './InstallObjectsIcon'
import InstallationIcon from './InstallationIcon'
import InventorisationIcon from './InventorisationIcon'
import LifeIcon from './LifeIcon'
import ObjectsIcon from './ObjectsIcon'
import OperationAndTechnicalSupportIcon from './OperationAndTechnicalSupportIcon'
import PassportizationIcon from './PassportizationIcon'
import ReportManagerIcon from './ReportManagerIcon'
import ScheduleManagerIcon from './ScheduleManagerIcon'
import SearchIcon from './SearchIcon'
import SmartRecycleBinIcon from './SmartRecycleBinIcon'
import UserProfileIcon from './UserProfileIcon'
import UsersIcon from './UsersIcon'
import WaterSupplyIcon from './WaterSupplyIcon'
import SettingsIcon from './SettingsIcon'
import StatisticsOnObjectsLightingIcon from './StatisticsOnObjectsLightingIcon'
import AnalyticsExpensesIcon from './AnalyticsExpensesIcon'
import InfoIcon from './InfoIcon'
import HandicappedIcon from './HandicappedIcon'
import InfoFrameIcon from './InfoFrameIcon'
import LightningIcon from './LightningIcon'
import RoadIcon from './RoadIcon'
import IlluminationIcon from './IlluminationIcon'
import ObjectStatisticIcon from './ObjectStatisticIcon'
import WaterIcon from './WaterIcon'
import HeatIcon from './HeatIcon'
import TrafficLightIcon from './TrafficLightIcon'
import CityProcessIcon from './CityProcessIcon'
import HappyIcon from './HappyIcon'
import IndifferentIcon from './IndifferentIcon'
import BadIcon from './BadIcon'
import CityIcon from './CityIcon'
import SandClockIcon from './SandClockIcon'
import GarbageCollectionIcon from './GarbageCollectionIcon'
import WidgetsDashboard from './WidgetsDashboard'

export default {
  ActivityLogIcon,
  AlarmsIcon,
  AlertManagerIcon,
  CarElectricStationsIcon,
  ChangeLogIcon,
  ControlCenterIcon,
  EcoStationsIcon,
  EnergyConsumptionIcon,
  EquipmentReferenceIcon,
  GroupControlIcon,
  HeatSupplyIcon,
  IndividualControlIcon,
  InstallObjectsIcon,
  InstallationIcon,
  InventorisationIcon,
  LifeIcon,
  ObjectsIcon,
  OperationAndTechnicalSupportIcon,
  PassportizationIcon,
  ReportManagerIcon,
  ScheduleManagerIcon,
  SearchIcon,
  SmartRecycleBinIcon,
  UserProfileIcon,
  UsersIcon,
  WaterSupplyIcon,
  SettingsIcon,
  StatisticsOnObjectsLightingIcon,
  AnalyticsExpensesIcon,
  InfoIcon,
  HandicappedIcon,
  InfoFrameIcon,
  LightningIcon,
  RoadIcon,
  IlluminationIcon,
  ObjectStatisticIcon,
  WaterIcon,
  HeatIcon,
  TrafficLightIcon,
  CityProcessIcon,
  HappyIcon,
  IndifferentIcon,
  BadIcon,
  CityIcon,
  SandClockIcon,
  GarbageCollectionIcon,
  WidgetsDashboard,
}
