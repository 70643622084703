import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HandicappedIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 28 28">
      <path d="M22.23 19.238h-.183l-1.063-3.586a2.405 2.405 0 00-.899-1.195A2.325 2.325 0 0018.712 14h-2.823c-1.1 0-1.833-.504-2.2-1.512h3.85c.513 0 .77-.246.77-.738s-.257-.738-.77-.738h-4.4l-.55-1.512h.184c.66 0 1.216-.217 1.668-.65a2.13 2.13 0 00.678-1.6 2.13 2.13 0 00-.678-1.6c-.452-.433-1.008-.65-1.668-.65-.684 0-1.247.217-1.687.65-.44.434-.66.967-.66 1.6 0 .07.013.17.037.299.025.129.037.228.037.299v.386l1.613 4.711c.269.75.745 1.365 1.43 1.846.684.48 1.442.72 2.273.72h2.896c.342 0 .599.177.77.528l1.393 4.711h1.356c.22 0 .403-.07.55-.211a.7.7 0 00.22-.527.743.743 0 00-.22-.563.763.763 0 00-.55-.21zM12.772 6.512c.513 0 .77.246.77.738s-.257.738-.77.738c-.538 0-.807-.246-.807-.738 0-.21.08-.387.239-.527a.828.828 0 01.568-.211zM12.039 23c1.76 0 3.317-.568 4.674-1.705 1.356-1.137 2.12-2.549 2.291-4.236l-1.54-.141c-.17 1.313-.776 2.402-1.814 3.27-1.039.867-2.243 1.3-3.611 1.3-1.516 0-2.805-.51-3.868-1.53-1.063-1.018-1.595-2.255-1.595-3.708 0-1.078.306-2.057.917-2.935.61-.88 1.454-1.53 2.53-1.952l-.624-1.336c-1.344.493-2.413 1.307-3.208 2.444A6.45 6.45 0 005 16.25c0 1.875.684 3.469 2.053 4.781C8.422 22.344 10.083 23 12.039 23z" fill={fill} className="svgFill" />
    </svg>
  )
}

HandicappedIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HandicappedIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HandicappedIcon)
