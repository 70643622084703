import styled from 'styled-components'

export const CloseIcon = styled.div`
  display: none;
  cursor: pointer;
  position: fixed;
  right: 22px;
`

export const Container = styled.div`
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 144px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  position: relative;
  z-index: 2;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ isInfoCardOpen }) => isInfoCardOpen && `
    min-width: 640px;
    ${CloseIcon} {
      display: block;
    }
  `};
  transition: ${({ theme }) => theme.animations.slide.connect('width 300ms, min-width 300ms')};
`

export const Header = styled.div`
  width: 100%;
  height: 49px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.default};
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  position: fixed;
  svg {
    width: 14px;
    height: 14px;
  }
`

export const Content = styled.div`
  display: flex;
  ${({ main }) => main && `
    flex-direction: column;
    padding: 49px 20px 0;
  `};
  ${({ text }) => text && `
    min-width: 250px;
    margin-right: 10px;
  `};
`

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: left;
  ${({ type }) => {
    if (type === 'title') {
      return `
        font-weight: bold;
        font-size: 1.8rem;
        margin: 20px 0;
      `
    }
    if (type === 'paragraph') {
      return `
        font-weight: 500;
      `
    }
    if (type === 'header') {
      return `
        font-size: 1.8rem;
      `
    }
  }};
`
