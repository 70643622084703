import { GET_DEPRECIATION_EQUIPMENT } from './index'

export const getDepreciationEquipmentRequest = (payload) => ({
  type: GET_DEPRECIATION_EQUIPMENT.REQUEST,
  payload,
})

export const getDepreciationEquipmentSuccess = (payload) => ({
  type: GET_DEPRECIATION_EQUIPMENT.SUCCESS,
  payload,
})

export const getDepreciationEquipmentError = (payload) => ({
  type: GET_DEPRECIATION_EQUIPMENT.ERROR,
  payload,
})
