import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LifeIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1.5C6 0.67157 6.67157 0 7.5 0C8.32843 0 9 0.67157 9 1.5V5H41V1.5C41 0.67157 41.6716 0 42.5 0C43.3284 0 44 0.67157 44 1.5V5H45C47.7614 5 50 7.23857 50 10V25.5C50 26.3284 49.3284 27 48.5 27C47.6716 27 47 26.3284 47 25.5V17C47 15.8954 46.1046 15 45 15H5C3.89542 15 3 15.8954 3 17V45C3 46.1046 3.89542 47 5 47H23.5C24.3284 47 25 47.6716 25 48.5C25 49.3284 24.3284 50 23.5 50H5C2.23859 50 0 47.7614 0 45V10C0 7.23857 2.23859 5 5 5H6V1.5Z" fill={fill} className="svgFill" />
      <path d="M37.5002 38.866L41.8303 36.366C42.3086 36.0899 42.4725 35.4783 42.1963 35C41.9202 34.5217 41.3086 34.3578 40.8303 34.634L38 36.268V31C38 30.4477 37.5523 30 37 30C36.4477 30 36 30.4477 36 31V38C36 38.5214 36.3991 38.9497 36.9085 38.9959C37.1075 39.0144 37.3139 38.9735 37.5002 38.866Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37 24C29.8203 24 24 29.8203 24 37C24 44.1797 29.8203 50 37 50C44.1797 50 50 44.1797 50 37C50 29.8203 44.1797 24 37 24ZM27 37C27 31.4772 31.4772 27 37 27C42.5229 27 47 31.4772 47 37C47 42.5229 42.5229 47 37 47C31.4772 47 27 42.5229 27 37Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LifeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LifeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LifeIcon)
