import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GlobalFilterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C4.02975 0 0 1.25887 0 2.8125V4.5L6.75 11.25V16.875C6.75 17.496 7.75688 18 9 18C10.2431 18 11.25 17.496 11.25 16.875V11.25L18 4.5V2.8125C18 1.25887 13.9703 0 9 0ZM1.65938 2.44012C2.08013 2.2005 2.67187 1.97212 3.36937 1.78087C4.91512 1.35787 6.91537 1.125 9 1.125C11.0846 1.125 13.0849 1.35787 14.6306 1.78087C15.3281 1.97212 15.9199 2.19937 16.3406 2.44012C16.6185 2.59875 16.7681 2.73263 16.8379 2.8125C16.7681 2.89237 16.6185 3.02625 16.3406 3.18488C15.9199 3.4245 15.3281 3.65287 14.6306 3.84413C13.0849 4.26713 11.0846 4.5 9 4.5C6.91537 4.5 4.91512 4.26713 3.36937 3.84413C2.67187 3.65287 2.08013 3.42563 1.65938 3.18488C1.47487 3.08799 1.30701 2.96229 1.16212 2.8125C1.23187 2.73263 1.3815 2.59875 1.65938 2.44012Z" fill={fill} className="svgFill" />
    </svg>
  )
}

GlobalFilterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GlobalFilterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GlobalFilterIcon)
