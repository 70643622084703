import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getLifeTimeReport = createSelector(
  getState,
  (state) => get(state, 'lifeTimeReport', {}),
)

export const getGraphPercent = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'graphPercent', []),
)

export const getLoadingTable = createSelector(
  getLifeTimeReport,
  (alarmsState) => get(alarmsState, 'loadingTable'),
)

export const getGraphYear = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'graphYear', []),
)

export const getSelectedGraphPercentOptions = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'selectedGraphPercentOptions', []),
)

export const getSelectedGraphYearOptions = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'selectedGraphYearOptions', []),
)

export const getData = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'data'),
)

export const getSelector = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'selector'),
)

export const getGraphType = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'graphType'),
)

export const getGraphOptions = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'graphOptions'),
)

export const getFileExportStatus = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'fileExportStatus'),
)

export const getTableParameters = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'table', {}),
)
