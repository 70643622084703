import React from 'react'
import pt from 'prop-types'
import CoreIcon from '@/components/icons/core'
import {
  Container,
  Title,
} from './styles'

const DefaultImage = ({
  title,
}) => (
  <Container>
    <CoreIcon.NoImageIcon />
    {title
        && (
        <Title>
          {title}
        </Title>
        )}
  </Container>

)

DefaultImage.defaultProps = {
  title: '',
}

DefaultImage.propTypes = {
  title: pt.string,
}

export default DefaultImage
