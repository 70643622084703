import { connect } from 'react-redux'
import {
  getTreeData,
} from '@/store/selectors/maps/socketEvents'
import {
  getDataRequestStatus,
} from '@/store/selectors/objectControllStatistic'
import {
  requestAlarmManagerTable,
} from '@/store/actions/objectControllStatistic/getTable'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import data from './config'
import Maps from './component'

const mapStateToProps = (state) => ({
  treeData: getTreeData(state),
  data,
  dataRequestStatus: getDataRequestStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetMapObjects: () => dispatch(requestGetMapObjects()),
  requestGetTableData: (data) => dispatch(requestAlarmManagerTable(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Maps)
