import { connect } from 'react-redux'
import {
  getThemeState,
  getSchemeState,
  getFontState,
} from '@/store/selectors/appSettings'
import ThemeProviderWrapper from './component'

const mapStateToProps = (state) => ({
  theme: getThemeState(state),
  scheme: getSchemeState(state),
  isLargeFont: getFontState(state),
})

export default connect(mapStateToProps)(ThemeProviderWrapper)
