import UserWithCheckBoxAndPicture from '@/components/blocks/TableSellToType/UserWithCheckBoxAndPicture'
import TableSellToType from '@/components/blocks/TableSellToType'

export const fields = [
  {
    id: 'firstName',
    name: 'Пользователь',
    value: 'ASC',
    customCell: UserWithCheckBoxAndPicture,
    withSelectAll: true,
  },
  {
    id: 'groupName',
    name: 'Группа',
    value: 'ASC',
  },
  {
    id: 'userStatus',
    name: 'Статус',
    value: 'ASC',
    customCell: TableSellToType.status,
    showSortButtons: false,
  },
  {
    id: 'enter',
    name: 'Вход в систему',
    value: 'ASC',
    showSortButtons: false,
  },
  {
    id: 'sessionTime',
    name: 'Время сессии',
    value: 'ASC',
    showSortButtons: false,
  },
]

export const activeFields = [
  'firstName',
  'groupName',
  'userStatus',
  'enter',
  'sessionTime',
]
