import {
  takeLatest, put, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import updateNodeCount from '@/helpers/updateNodeCount'
import { CREATE_GEOZONE } from '@/store/actions/installation'
import { INSTALLATIONS_GEOZONE_ENDPOINT } from '@/constants/apiRoutes'
import { successCreateGeoZone } from '@/store/actions/installation/createGeoZone'

function* createGeoZoneSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    city, lat, lon, mark, name, parentId, region,
  } = values
  try {
    const body = {
      parentTreeElementId: parentId,
      geoZoneName: name,
      city,
      region,
      geoLocationDto: {
        latitude: lat,
        longitude: lon,
      },
      note: mark,
    }
    const { data } = yield request({
      url: INSTALLATIONS_GEOZONE_ENDPOINT,
      method: 'post',
      body,
    })
    const { rootId, path, FILTER } = formValues

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentTreeElementId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.treeElementId)
    tree.handlers.setSelected(node, true)
    updateNodeCount(tree, path, 'add')
    tree.handlers.rerender()
    setUrlFormValues({
      id: data.treeElementId,
      rootId,
      parentGeoZoneId: data.treeElementId,
      parentTreeId: data.parentTreeElementId,
      path: `${path}/${data.treeElementId}`,
      type: data.type,
      FILTER,
    })
    yield put(successCreateGeoZone())
  } catch (error) {
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*createGeoZoneSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(CREATE_GEOZONE.REQUEST, createGeoZoneSaga)
}
