import { connect } from 'react-redux'
import { getRoot } from '@/store/selectors/installation'
import Component from './component'

const mapStateToProps = (state) => ({
  roots: getRoot(state),
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component)
