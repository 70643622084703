import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import transparentRectangleIcon from '@/assets/images/maps/regionBorder.png'
import mapsIcons from '@/components/icons/maps'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
  UNKNOWN,
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from './objectStatuses'
import {
  COUNTRY,
  REGION,
  CITY,
  CONTROLLER,
  TRAFFIC,
  TRASH,
  LIGHT,
  GROUP_OBJECT,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
  SHUO,
  LIGHT_SENSOR,
} from './objectTypes'

export const SYCN = 'syncToggle'

export const REGIONS_TYPES = [COUNTRY, REGION, CITY]
export const NOT_ELEMENTS_TYPES = [
  ...REGIONS_TYPES,
  CONTROLLER,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
]

export const CLUSTER_ANGLE_MARGIN = {
  zero: 0,
  small: 2,
  medium: 3,
  big: 5,
}

export const TITLE_TO_STATUS = {
  [OK]: 'Исправно',
  [WARNING]: 'Возможны проблемы',
  [INFO]: 'Неактивно',
  [ERROR]: 'Проблемы в работе',
  null: '-',
  [NOT_VERIFIED]: 'Не проверен',
  [VERIFIED]: 'Проверен',
  [NEW]: 'Новый',
  [NOT_EXIST]: 'Не существует',
  [NOT_INSTALLED]: 'Не установлен',
  [INSTALLED]: 'Установлен',
  [UNINSTALLED]: 'Демонтирован',

}

export const REVERSE_ICON_TO_TYPE = {
  [OK]: mapsIcons.ReverseOkIcon,
  [WARNING]: mapsIcons.ReverseExclamationIcon,
  [INFO]: mapsIcons.ReverseQuestionIcon,
  [ERROR]: mapsIcons.ReverseCrossIcon,
  [UNKNOWN]: mapsIcons.ReverseCrossIcon,
  [NOT_VERIFIED]: mapsIcons.ReverseCrossIcon,
  [VERIFIED]: mapsIcons.ReverseCrossIcon,
  [NEW]: mapsIcons.ReverseCrossIcon,
  [NOT_EXIST]: mapsIcons.ReverseCrossIcon,
  [NOT_INSTALLED]: mapsIcons.ReverseCrossIcon,
  [INSTALLED]: mapsIcons.ReverseOkIcon,
  [UNINSTALLED]: mapsIcons.ReverseCrossIcon,
  null: mapsIcons.ReverseCrossIcon,
}

export const STATUS_TYPES = {
  [OK]: OK,
  [WARNING]: WARNING,
  [INFO]: INFO,
  [ERROR]: ERROR,
}

export const tooltipSizes = {
  lineHeight: 14,
  delta: 40,
  baseHeight: 24,
}

export const GEOZONE_NAME = 'Название геозоны'
export const CLUSTER_NAME = 'Кластер объектов'

export const PIE_CHART_SIZE = {
  small: {
    height: '38px', width: '38px',
  },
  medium: {
    height: '58px', width: '58px',
  },
  big: {
    height: '78px', width: '78px',
  },
}

export const PIE_CHART_POSITION = {
  position: 'absolute', top: '0px', left: '0px',
}

export const clusterToolTipLamdaBySize = {
  small: {
    container: '-85',
    tip: '11.5',
  },
  medium: {
    container: '-75',
    tip: '21.5',
  },
  big: {
    container: '-65',
    tip: '31.5',
  },
}

export const geoZoneToolTipLamdaBySize = {
  small: {
    container: '-105',
    tip: '11.5',
  },
  medium: {
    container: '-95',
    tip: '21.5',
  },
  big: {
    container: '-85',
    tip: '31.5',
  },
}

export const CHAR_PER_LINE_IN_TOOLTIP = 20
export const CHAR_PER_LINE_IN_TOOLTIP_CLUSTER_TOOLTIP = 18

const clusterPreset = [
  {
    href: transparentRectangleIcon,
    size: [40, 40],
    offset: [-20, -20],
  },
  {
    href: transparentRectangleIcon,
    size: [60, 60],
    offset: [-30, -30],
  },
  {
    href: transparentRectangleIcon,
    size: [80, 80],
    offset: [-40, -40],
  },
]

export const clusterCountBrakePoint = {
  small: 3,
  medium: 9,
  big: 14,
}

export const clusterOptions = {
  groupByCoordinates: false,
  clusterDisableClickZoom: true,
  clusterHideIconOnBalloonOpen: false,
  geoObjectHideIconOnBalloonOpen: false,
  clusterIcons: clusterPreset,
  clusterNumbers: [
    clusterCountBrakePoint.small,
    clusterCountBrakePoint.medium,
    clusterCountBrakePoint.big,
  ],
}

export const gridSize = {
  small: 16,
  middle: 128,
  big: 256,
  large: 512,
  huge: 1024,
}

export const mapZoomBreakPoints = {
  max: 20,
  min: 2,
  micro: 4,
  small: 6,
  middle: 8,
  upper: 10,
  big: 12,
  large: 15,
}

export const ZOOM_BY_TYPES = {
  [COUNTRY]: mapZoomBreakPoints.micro,
  [REGION]: mapZoomBreakPoints.middle,
  [CITY]: mapZoomBreakPoints.max - 2,
  [CONTROLLER]: mapZoomBreakPoints.max - 1,
  [SHUO]: mapZoomBreakPoints.max - 1,
  [GROUP_OBJECT]: mapZoomBreakPoints.max - 1,
  [LIGHT]: mapZoomBreakPoints.max - 1,
  [TRASH]: mapZoomBreakPoints.max - 1,
}

export const icons = {
  geoIcon: mapsIcons.GeoIcon,
  listIcon: mapsIcons.ListIcon,
  filterIcon: mapsIcons.FilterIcon,
}

export const filterTypes = [
  {
    id: 1,
    type: OK,
    name: <Lang id="mapsPage.filterTypes.ok" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 2,
    type: WARNING,
    name: <Lang id="mapsPage.filterTypes.warning" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 3,
    type: INFO,
    name: <Lang id="mapsPage.filterTypes.info" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 4,
    type: ERROR,
    name: <Lang id="mapsPage.filterTypes.error" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 5,
    type: TRAFFIC,
    name: <Lang id="mapsPage.filterTypes.traffic" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 6,
    type: LIGHT,
    name: <Lang id="mapsPage.filterTypes.light" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 7,
    type: TRASH,
    name: <Lang id="mapsPage.filterTypes.trash" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 8,
    type: CONTROLLER,
    name: <Lang id="mapsPage.filterTypes.controler" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 9,
    type: GROUP_OBJECT,
    name: <Lang id="mapsPage.filterTypes.groupObject" />,
    inZone: false,
    isStatus: false,
  },
]

export const YMSP_Z_INDEX = {
  geoObject: {
    small: 1000,
    medium: 3000,
    big: 4000,
    large: 5000,
  },
}

export const TYPE_TO_NAME = {
  shuo: 'ШУО',
  baseStation: 'Базовая станция',
  universMeter: 'Многофункц. счетчик',
  support: 'Опора',
  let: 'Линия',
  ok: 'Активно',
  warning: 'Предупреждение',
  info: 'Неактивно',
  error: 'Ошибка',
  traffic: 'Светофор',
  light: 'Светильник',
  lightModule: 'Модуль светильника',
  trash: 'Мусорка',
  controler: 'Контроллер',
  groupObject: 'Групповой объект',
  heatingDevice: 'Групповой объект',
  coldWaterDevice: 'Групповой объект',
  lightElement: 'Светоточка',
  [LIGHT_SENSOR]: 'Световой датчик',
  [TRASH]: 'Мусорный бак',
}

export const DEFAULT_BORDER_RADIUS = 15000
export const UNITS_METERS = 100000
