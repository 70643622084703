export default [
  {
    passportParameter: 'Температура в трубопроводе',
    showInDashboard: true,
    selector: 'temperature',
  },
  {
    passportParameter: 'Условный проход трубы',
    showInDashboard: true,
    selector: 'pipeDiameter',
  },
  {
    passportParameter: 'Договорное значение температуры холодной воды',
    showInDashboard: true,
    selector: 'dealTemperature',
  },
  {
    passportParameter: 'Договорное значение давления холодной воды',
    showInDashboard: true,
    selector: 'dealPresure',
  },
  {
    passportParameter: 'Договорное значение давления в трубопроводе',
    showInDashboard: true,
    selector: 'tubeDealPresure',
  },
  {
    passportParameter: 'Массовый расход в трубопроводе',
    showInDashboard: true,
    selector: 'massFlow',
  },
  {
    passportParameter: 'Объемный расход в трубопроводе',
    showInDashboard: true,
    selector: 'volumetricFlow',
  },
  {
    passportParameter: 'Объем потребленного ресурса',
    showInDashboard: true,
    selector: 'totalVolume',
  },
  {
    passportParameter: 'Время работы',
    showInDashboard: true,
    selector: 'workTime',
  },
]
