import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserGroupIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="10.808" height="8.909" viewBox="0 0 10.808 8.909">
      <g id="Group_359" data-name="Group 359" transform="translate(-651.68 -136.057)">
        <path id="Path_95" data-name="Path 95" d="M668.9,136.057c.118.015.237.025.354.045a2.473,2.473,0,0,1,.761,4.591l-.042.025a.237.237,0,0,0,.028.018,3.813,3.813,0,0,1,1.825,1.73,4.314,4.314,0,0,1,.5,1.732c.01.124.017.25.017.374a.4.4,0,0,1-.782.089,2.05,2.05,0,0,1-.018-.286,3.575,3.575,0,0,0-.725-2.021,2.754,2.754,0,0,0-1.458-1.024,2.476,2.476,0,0,0-2.149.359,3.224,3.224,0,0,0-1.271,1.788,3.682,3.682,0,0,0-.16,1.075.383.383,0,0,1-.236.378.392.392,0,0,1-.559-.351,4.222,4.222,0,0,1,1.621-3.415,3.23,3.23,0,0,1,.851-.479l.068-.029-.052-.037a2.438,2.438,0,0,1-1.126-1.8,2.465,2.465,0,0,1,1.777-2.665,5.3,5.3,0,0,1,.53-.092l.065-.009Zm-.092,4.192a1.715,1.715,0,1,0-1.716-1.715A1.715,1.715,0,0,0,668.809,140.249Z" transform="translate(-11.73 0)" fill={fill} className="svgFill" />
        <path id="Path_96" data-name="Path 96" d="M718.492,147.248c-.005.015-.011.03-.016.046a.379.379,0,0,1-.742-.11,2.77,2.77,0,0,0-.113-.782,2.273,2.273,0,0,0-1-1.342,1.587,1.587,0,0,0-.95-.246.387.387,0,0,1-.331-.116.361.361,0,0,1-.08-.393.357.357,0,0,1,.318-.244,1.648,1.648,0,0,0,.52-.1,1.243,1.243,0,0,0-.307-2.364,1.51,1.51,0,0,0-.181-.013.379.379,0,1,1,.016-.758,1.933,1.933,0,0,1,1.539.755,1.994,1.994,0,0,1-.236,2.737l-.03.028a.133.133,0,0,0,.018.015,3.009,3.009,0,0,1,1.363,1.629,3.291,3.291,0,0,1,.2.956.5.5,0,0,0,.009.053Z" transform="translate(-56.005 -4.198)" fill={fill} className="svgFill" />
        <path id="Path_97" data-name="Path 97" d="M653.276,144.467c-.118-.143-.245-.274-.347-.423a2.086,2.086,0,0,1,.679-2.972,1.9,1.9,0,0,1,.942-.252.379.379,0,1,1,.01.758,1.251,1.251,0,0,0-1.214,1.111,1.286,1.286,0,0,0,.508,1.243,1.021,1.021,0,0,0,.63.229.7.7,0,0,1,.184.028.374.374,0,0,1,.265.422.379.379,0,0,1-.368.319c-.088,0-.177-.01-.264,0a1.763,1.763,0,0,0-1.066.5,2.49,2.49,0,0,0-.736,1.381,4.975,4.975,0,0,0-.059.591.387.387,0,0,1-.341.373.38.38,0,0,1-.4-.267.354.354,0,0,1-.016-.1,3.472,3.472,0,0,1,.841-2.325,2.736,2.736,0,0,1,.735-.6Z" transform="translate(0 -4.197)" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

UserGroupIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserGroupIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserGroupIcon)
