import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PhoneIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="8.744" height="9.38" viewBox="0 0 8.744 9.38">
      <path id="Path_94" data-name="Path 94" d="M697.056,266.672a3.026,3.026,0,0,1,.239-1.163,2.731,2.731,0,0,1,.7-.988c.185-.164.375-.323.566-.48a.692.692,0,0,1,.323-.153.571.571,0,0,1,.386.073,1.368,1.368,0,0,1,.378.337,5.666,5.666,0,0,1,.4.584,4.931,4.931,0,0,1,.317.544,1.639,1.639,0,0,1,.162.527.829.829,0,0,1-.216.679c-.1.108-.217.2-.325.3a3.329,3.329,0,0,1-.342.263.718.718,0,0,0-.294.4.88.88,0,0,0,.005.43,2.084,2.084,0,0,0,.224.544,5.691,5.691,0,0,0,.607.854,8.317,8.317,0,0,0,.675.728,3.3,3.3,0,0,0,.71.537,1.136,1.136,0,0,0,.462.145.674.674,0,0,0,.5-.178.86.86,0,0,0,.091-.088,3.876,3.876,0,0,1,.413-.388,1.592,1.592,0,0,1,.259-.2.866.866,0,0,1,.721-.069,1.746,1.746,0,0,1,.558.3c.2.161.4.33.6.5a2.882,2.882,0,0,1,.388.388,1.14,1.14,0,0,1,.2.339.558.558,0,0,1-.107.573,2.409,2.409,0,0,1-.225.222c-.133.122-.265.244-.4.361a2.749,2.749,0,0,1-.955.524,2.968,2.968,0,0,1-1.026.135,3.715,3.715,0,0,1-1.335-.318,5.8,5.8,0,0,1-1.222-.733,9.867,9.867,0,0,1-1.31-1.225,11.76,11.76,0,0,1-1.137-1.452,7.183,7.183,0,0,1-.623-1.155,4.733,4.733,0,0,1-.322-1.111A3.816,3.816,0,0,1,697.056,266.672Z" transform="translate(-697.056 -263.879)" fill={fill} className="svgFill" />
    </svg>
  )
}

PhoneIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PhoneIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PhoneIcon)
