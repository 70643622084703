import {
  select, takeLatest, put,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import get from 'lodash/get'
import request from '@/helpers/axios'
import { GET_MAP_OBJECTS } from '@/store/actions/maps'
import {
  SET_GLOBAL_FILTER_VALUES,
  RESET_GLOBAL_FILTER_VALUES,
  setSelectedNode,
  setUnpinNode
} from '@/store/actions/appSettings'
import { getTreeData } from '@/store/selectors/maps/socketEvents'
import {
  getUserData,
  getGlobalFilters,
  getSelectedNode,
} from '@/store/selectors/appSettings'
import {
  successGetMapObjects,
  errorGetMapObjects,
} from '@/store/actions/maps/socketEvents'
import {
  KULON_EQIPMENT_URL,
  ATM_EQIPMENT_URL,
  MESH_EQIPMENT_URL,
  BRIZ_EQIPMENT_URL,
} from '@/constants/apiRoutes'
import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'
import {
  INTEGRATION,
  ASU,
  OBJECT_TYPES,
  PROCESSES,
} from '@/constants/forms/globalFilterForm'
import {
  KULON, ATM, BRIZ, MESH,
} from '@/constants/integrations'
import { CITY } from '@/constants/objectTypes'
import { ID_BY_APPLICATIONS, PROCESSES_BY_ID } from '@/constants/widgets'

import { setTreePath } from '@/data/mapData'
import { mockElements } from '@/data/mapTree'
import zoneConfig, { fakeZones } from '@/data/mapTree/zoneConfig'
import buildKulonTree from '@/helpers/formaters/buildKulonTree'
import buildOneSimTree from '@/helpers/formaters/buildOneSimTree'
import getPinsByTree from '@/helpers/formaters/getPinsByTree'
import getMeshTree from '@/helpers/formaters/getMeshTree'
import getBrizTree from '@/helpers/formaters/getBrizTree'
import { getPinCount } from '@/helpers/viewTree/getPinCount'
import { isUserHasPermission } from '@/helpers/userPermissions'
import generateZoneBorder from '@/helpers/generateZoneBorder'

const REQUEST_TIMEOUT = 5 * 1000 // 5 seconds
// const MESH_GEOZONE_ID = 100500
// const BRIZ_GEOZONE_ID = 466

const validatorForFilter = (field, value) => !field || field.includes(value)
const searchInTreeById = (element = {}, matchingId) => {
  if (element.id === matchingId) {
    return element
  }
  if (element.children != null) {
    let i
    let result = null
    for (i = 0; result == null && i < element.children.length; i += 1) {
      result = searchInTreeById(element.children[i], matchingId)
    }
    return result
  }
  return null
}

function* getMapEquipment() {
  try {
    const currentUser = yield select(getUserData)
    const localTree = yield select(getTreeData)
    const globalFilters = yield select(getGlobalFilters)
    const isHasMockData = process.env.REACT_APP_MOCK_OBJECTS === 'true'

    const userCanViewBrizElements = isUserHasPermission(
      currentUser,
      [
        ID_BY_APPLICATIONS.PASSPORTIZATION,
        ID_BY_APPLICATIONS.INSTALLATION,
        ID_BY_APPLICATIONS.MONITORING_CENTER,
      ],
      PROCESSES_BY_ID.LIGHT,
    )
      && (validatorForFilter(globalFilters[ASU], BRIZ)
        || validatorForFilter(globalFilters[PROCESSES], PROCESSES_BY_ID.LIGHT))
    const userCanViewFireMeshElements = isUserHasPermission(
      currentUser,
      [
        ID_BY_APPLICATIONS.PASSPORTIZATION,
        ID_BY_APPLICATIONS.INSTALLATION,
        ID_BY_APPLICATIONS.MONITORING_CENTER,
      ],
      PROCESSES_BY_ID.LIGHT,
    )
      && (validatorForFilter(globalFilters[ASU], MESH)
        || validatorForFilter(globalFilters[PROCESSES], PROCESSES_BY_ID.LIGHT))

    const userCanViewLights = isUserHasPermission(
      currentUser,
      [
        ID_BY_APPLICATIONS.PASSPORTIZATION,
        ID_BY_APPLICATIONS.INSTALLATION,
        ID_BY_APPLICATIONS.MONITORING_CENTER,
      ],
    )
      && (validatorForFilter(globalFilters[ASU], KULON)
      || validatorForFilter(globalFilters[PROCESSES], PROCESSES_BY_ID.LIGHT))
    const userCanViewWater = isUserHasPermission(
      currentUser,
      [
        ID_BY_APPLICATIONS.PASSPORTIZATION,
        ID_BY_APPLICATIONS.INSTALLATION,
        ID_BY_APPLICATIONS.MONITORING_CENTER,
      ],
    )
      && (validatorForFilter(globalFilters[ASU], ATM)
      || validatorForFilter(globalFilters[PROCESSES], PROCESSES_BY_ID.HEAT_AND_WATER))
    const userCanViewHeat = isUserHasPermission(
      currentUser,
      [
        ID_BY_APPLICATIONS.PASSPORTIZATION,
        ID_BY_APPLICATIONS.INSTALLATION,
        ID_BY_APPLICATIONS.MONITORING_CENTER,
      ],
    )
      && (validatorForFilter(globalFilters[ASU], ATM)
      || validatorForFilter(globalFilters[PROCESSES], PROCESSES_BY_ID.HEAT_AND_WATER))

    let russiaChildren = []
    let newZones = zoneConfig
    if (isHasMockData) {
      russiaChildren = [...mockElements]
      newZones = {
        ...newZones,
        ...fakeZones,
      }
    }
    const addFakeMoscowData = (tree) => {
      const moscowIndex = russiaChildren.findIndex((city) => city.name.includes('Москва'))
      if (moscowIndex !== -1) {
        const moscowElement = { ...russiaChildren[moscowIndex] }
        moscowElement.children.forEach(child => {
          child.geoZoneId = 40048
          child.internalId = 40048
          if (child.children && child.children.length) {
            child.children.forEach(child => {
              child.geoZoneId = 40048
              child.internalId = 40048
            })
          }
        }
        )
        russiaChildren[moscowIndex] = {
          ...moscowElement,
          integrationTypes: [OUTDOOR_LIGHTING, HEAT_SUPPLY],
          children: [
            ...moscowElement.children,
            ...tree,
          ],
        }
      } else {
        const fakeMoscow = {
          id: 40048,
          internalId: 13,
          geoZoneId: 40048,
          name: 'Москва',
          type: CITY,
          location: [55.766067, 37.691003],
        }
        russiaChildren = [
          ...russiaChildren,
          {
            ...fakeMoscow,
            children: [...tree],
          },
        ]
        newZones[40048] = {
          ...fakeMoscow,
          children: [],
          pinSelector: 40048,
          zoneSelector: [40048],
        }
      }
    }
    if (userCanViewFireMeshElements) {
      try {
        const { data: meshResponse } = yield request({
          url: MESH_EQIPMENT_URL,
          method: 'get',
          options: { timeout: REQUEST_TIMEOUT },
        })
        // true for show only Moscow objects
        const onlyMoscowObjects = true
        const formattedMeshTree = getMeshTree(
          meshResponse[0] || {},
          onlyMoscowObjects,
          globalFilters[INTEGRATION] || [],
          globalFilters[OBJECT_TYPES] || [],
        )
        if (meshResponse) {
          addFakeMoscowData(formattedMeshTree[0].children)
          // russiaChildren = [...russiaChildren, ...formattedMeshTree]
          // newZones = {
          //   ...newZones,
          //   [MESH_GEOZONE_ID]: {
          //     id: MESH_GEOZONE_ID,
          //     location: onlyMoscowObjects ? [55.767567, 37.62381] : [61.4894, 23.7806],
          //     name: 'MESH',
          //     type: CITY,
          //     zoneSelector: [MESH_GEOZONE_ID],
          //     pinSelector: MESH_GEOZONE_ID,
          //     border: null,
          //     count: 0,
          //     statistic: {
          //       [OK]: 0,
          //       [WARNING]: 0,
          //       [ERROR]: 0,
          //       [INFO]: 0,
          //     },
          //   },
          // }
        }
      } catch (meshError) {
        console.log('function*getMapEquipment -> meshError', meshError)
      }
    }
    if (userCanViewBrizElements) {
      try {
        const { data: brizResponse } = yield request({
          url: BRIZ_EQIPMENT_URL,
          method: 'get',
          options: { timeout: REQUEST_TIMEOUT },
        })
        const formattedBriz = getBrizTree(
          brizResponse,
          globalFilters[INTEGRATION] || [],
          globalFilters[OBJECT_TYPES] || [],
        )
        if (brizResponse && brizResponse.length > 0) {
          addFakeMoscowData(formattedBriz[0].children)
          // russiaChildren = [...russiaChildren, ...formattedBriz]
          // newZones = {
          //   ...newZones,
          //   [BRIZ_GEOZONE_ID]: {
          //     id: BRIZ_GEOZONE_ID,
          //     location: [55.74, 37.627],
          //     name: 'БРИЗ',
          //     type: CITY,
          //     zoneSelector: [BRIZ_GEOZONE_ID],
          //     pinSelector: BRIZ_GEOZONE_ID,
          //     border: null,
          //     count: 0,
          //     statistic: {
          //       [OK]: 0,
          //       [WARNING]: 0,
          //       [ERROR]: 0,
          //       [INFO]: 0,
          //     },
          //   },
          // }
        }
      } catch (brizError) {
        console.log('function*getMapEquipment -> brizError', brizError)
      }
    }

    if (userCanViewLights) {
      try {
        const { data: kulonResponse } = yield request({
          url: KULON_EQIPMENT_URL,
          method: 'get',
          options: { timeout: REQUEST_TIMEOUT },
        })
        const kulonTree = buildKulonTree(
          kulonResponse,
          globalFilters[INTEGRATION] || [],
          globalFilters[OBJECT_TYPES] || [],
        )

        const newKulonTree = kulonTree.map((node) => {
          newZones[1].zoneSelector.push(node.id)
          newZones[node.id] = {
            zoneSelector: [node.id],
            pinSelector: node.id,
            ...node,
            children: [],
          }
          return {
            ...node,
            integrationTypes: [OUTDOOR_LIGHTING],
          }
        })
        const moscowIndex = russiaChildren.findIndex((city) => city.name.includes('Москва'))
        if (moscowIndex !== -1) {
          const moscowData = newKulonTree.filter(item => item.name === 'Москва')
          const moscowElement = { ...russiaChildren[moscowIndex] }
          russiaChildren[moscowIndex] = {
            ...moscowElement,
            integrationTypes: [OUTDOOR_LIGHTING, HEAT_SUPPLY],
            children: [
              ...moscowElement?.children || [],
              ...moscowData[0]?.children || [],
            ],
          }
          russiaChildren = [...russiaChildren, ...newKulonTree.filter(item => item.name !== 'Москва')]
        } else {
          russiaChildren = [...russiaChildren, ...newKulonTree]
        }
      } catch (kulonError) {
        console.log('function*getMapEquipment -> kulonError', kulonError)
      }
    }
    if ((userCanViewWater || userCanViewHeat)) {
      try {
        const { data: oneSimResponse } = yield request({
          url: ATM_EQIPMENT_URL,
          method: 'get',
          options: { timeout: REQUEST_TIMEOUT },
        })
        const onesimTree = buildOneSimTree(
          oneSimResponse,
          {
            water: userCanViewWater,
            heat: userCanViewHeat,
            integrationsIds: globalFilters[INTEGRATION] || [],
            objectTypes: globalFilters[OBJECT_TYPES] || [],
          },
        )
        const updatedOneSimTree = onesimTree.map((node) => {
          const newChildren = node.children.map((child) => ({
            ...child,
            geoZoneId: 63,
          }))
          const childrenSelector = node.children.map((child) => ({
            id: child.id,
            geoZoneId: 63,
            type: child.type,
            status: child.status,
          }))
          return {
            ...node,
            geoZoneId: 63,
            childrenSelector,
            integrationTypes: [HEAT_SUPPLY],
            children: newChildren,
          }
        })

        const newOneSimTree = onesimTree.map((node) => {
          const newChildren = node.children.map((child) => ({
            ...child,
            geoZoneId: 40048,
          }))
          const childrenSelector = node.children.map((child) => ({
            id: child.id,
            geoZoneId: 40048,
            type: child.type,
            status: child.status,
          }))
          return {
            ...node,
            geoZoneId: 40048,
            childrenSelector,
            integrationTypes: [HEAT_SUPPLY],
            children: newChildren,
          }
        })

        const tyumenIndex = russiaChildren.findIndex((city) => city.name.includes('Тюмень'))
        const tyumenChildren = [...updatedOneSimTree].filter(item => item.location[0].toString().startsWith(57) && item.location[1].toString().startsWith(65))
        if (tyumenIndex !== -1) {
          const tyumenElement = { ...russiaChildren[tyumenIndex] }
          russiaChildren[tyumenIndex] = {
            ...tyumenElement,
            integrationTypes: [OUTDOOR_LIGHTING, HEAT_SUPPLY],
            children: [
              ...tyumenElement.children,
              ...tyumenChildren,
            ],
          }
        } else {
          const fakeTyumen = {
            id: 63,
            internalId: 32,
            geoZoneId: 63,
            alias: updatedOneSimTree[0].alias,
            aliasId: updatedOneSimTree[0].aliasId,
            name: 'Тюмень',
            type: CITY,
            location: [57.10785484313965, 65.57612991333008],
          }
          russiaChildren = [
            ...russiaChildren,
            {
              ...fakeTyumen,
              integrationTypes: [HEAT_SUPPLY],
              children: [...tyumenChildren],
            },
          ]
          newZones[63] = {
            ...fakeTyumen,
            children: [],
            pinSelector: 63,
            zoneSelector: [63],
          }
        }
        const moscowChildren = [...newOneSimTree].filter(item => item.location[0].toString().startsWith(55) && item.location[1].toString().startsWith(37))
        addFakeMoscowData(moscowChildren)
      } catch (simError) {
        console.log('function*getMapEquipment -> simError', simError)
      }
    }
    localTree.children = [...russiaChildren]

    const treeWithPaths = setTreePath({ data: localTree })
    const updatedPins = getPinsByTree(treeWithPaths)
    const countOfPins = getPinCount(localTree)
    const newZonesWithBorders = Object.keys(newZones).reduce((accumulator, zoneKey) => {
      const selectors = (newZones[zoneKey] || {}).zoneSelector || []
      const relatedPins = updatedPins.filter((pin) => selectors.includes(pin.geoZoneId))
      if (newZones[zoneKey].id === 1) {
        return {
          ...accumulator,
          [newZones[zoneKey].id]: {
            ...(newZones[zoneKey] || {}),
          },
        }
      }
      return {
        ...accumulator,
        [newZones[zoneKey].id]: {
          ...(newZones[zoneKey] || {}),
          border: generateZoneBorder(relatedPins),
        },
      }
    }, {})
    yield put(successGetMapObjects({
      tree: treeWithPaths,
      pins: updatedPins,
      countOfPins,
      zones: newZonesWithBorders,
    }))

    // delete node, if tree does't contain current selected
    const selectedNode = yield select(getSelectedNode)
    const selectedNodePath = get(selectedNode, 'original.options.path', null)

    if (selectedNode && selectedNode.id) {
      const result = searchInTreeById(treeWithPaths, (selectedNode || {}).id)

      if (!result) {
        yield put(setSelectedNode({}))
        yield put(setUnpinNode({}))
      }
    }
    const tree = treeHandlers.trees['objects-tree']
    if (selectedNodePath && tree && tree.handlers) {
      tree.handlers.setSelectedByPath(selectedNodePath)
    }
  } catch (error) {
    console.log('error', error)
    yield put(errorGetMapObjects())
  }
}

export default function* root() {
  yield takeLatest(GET_MAP_OBJECTS.REQUEST, getMapEquipment)
  yield takeLatest(SET_GLOBAL_FILTER_VALUES, getMapEquipment)
  yield takeLatest(RESET_GLOBAL_FILTER_VALUES, getMapEquipment)
}
