import { handleActions } from 'redux-actions'
import {
  SET_SOCKET_CONNECTION,
  SET_SOCKET_SUBSCRIPTIONS,
} from '@/store/actions/socket'

const initialState = {
  client: {},
  subscriptions: {},
}

export const reducer = handleActions(
  {
    [SET_SOCKET_CONNECTION]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_SOCKET_SUBSCRIPTIONS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
)

export default reducer
