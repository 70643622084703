import { GET_USER } from '../index'

export const beginGetUser = () => ({
  type: GET_USER.BEGIN,
})

export const endGetUser = () => ({
  type: GET_USER.END,
})

export const requestGetUser = (forms) => ({
  type: GET_USER.REQUEST,
  payload: forms,
})

export const responseGetUser = (user) => ({
  type: GET_USER.SUCCESS,
  payload: user,
})

export const errorGetUser = (error) => ({
  type: GET_USER.ERROR,
  payload: error,
})
