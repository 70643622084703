import styled from 'styled-components'
import { YMaps, Map } from 'react-yandex-maps'

export const StyledYMaps = styled(YMaps)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  ${({ createPoint }) => createPoint && `
    cursor: pointer;
  `}
`

export const StyledMap = styled(Map)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  [class*="-ground-pane"] {
    filter: ${({ theme }) => theme.colors.mapFilter};
  }
`
