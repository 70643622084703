import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InventorisationIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="42.281" height="41" viewBox="0 0 42.281 41">
      <g id="Group_19" data-name="Group 19" transform="translate(-736 -105)">
        <g id="Rectangle_33" data-name="Rectangle 33" transform="translate(736 105)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="3">
          <rect width="10" height="10" stroke="none" />
          <rect x="1.5" y="1.5" width="7" height="7" fill="none" />
        </g>
        <g id="Rectangle_48" data-name="Rectangle 48" transform="translate(758 105)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="3">
          <rect width="10" height="10" stroke="none" />
          <rect x="1.5" y="1.5" width="7" height="7" fill="none" />
        </g>
        <rect id="Rectangle_36" data-name="Rectangle 36" width="8.281" height="4" transform="translate(748 105)" fill={fill} className="svgFill" />
        <rect id="Rectangle_47" data-name="Rectangle 47" width="8.281" height="4" transform="translate(770 105)" fill={fill} className="svgFill" />
        <rect id="Rectangle_39" data-name="Rectangle 39" width="8.281" height="4" transform="translate(748 120)" fill={fill} className="svgFill" />
        <rect id="Rectangle_45" data-name="Rectangle 45" width="8.281" height="4" transform="translate(770 120)" fill={fill} className="svgFill" />
        <rect id="Rectangle_41" data-name="Rectangle 41" width="8.281" height="4" transform="translate(748 136)" fill={fill} className="svgFill" />
        <rect id="Rectangle_43" data-name="Rectangle 43" width="8.281" height="4" transform="translate(770 136)" fill={fill} className="svgFill" />
        <rect id="Rectangle_37" data-name="Rectangle 37" width="8.281" height="4" transform="translate(748 111)" fill={fill} className="svgFill" />
        <rect id="Rectangle_46" data-name="Rectangle 46" width="8.281" height="4" transform="translate(770 111)" fill={fill} className="svgFill" />
        <rect id="Rectangle_38" data-name="Rectangle 38" width="8.281" height="4" transform="translate(748 126)" fill={fill} className="svgFill" />
        <rect id="Rectangle_44" data-name="Rectangle 44" width="8.281" height="4" transform="translate(770 126)" fill={fill} className="svgFill" />
        <rect id="Rectangle_40" data-name="Rectangle 40" width="8.281" height="4" transform="translate(748 142)" fill={fill} className="svgFill" />
        <rect id="Rectangle_42" data-name="Rectangle 42" width="8.281" height="4" transform="translate(770 142)" fill={fill} className="svgFill" />
        <g id="Rectangle_34" data-name="Rectangle 34" transform="translate(736 120)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="3">
          <rect width="10" height="10" stroke="none" />
          <rect x="1.5" y="1.5" width="7" height="7" fill="none" />
        </g>
        <g id="Rectangle_49" data-name="Rectangle 49" transform="translate(758 120)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="3">
          <rect width="10" height="10" stroke="none" />
          <rect x="1.5" y="1.5" width="7" height="7" fill="none" />
        </g>
        <g id="Rectangle_35" data-name="Rectangle 35" transform="translate(736 136)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="3">
          <rect width="10" height="10" stroke="none" />
          <rect x="1.5" y="1.5" width="7" height="7" fill="none" />
        </g>
        <g id="Rectangle_50" data-name="Rectangle 50" transform="translate(758 136)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="3">
          <rect width="10" height="10" stroke="none" />
          <rect x="1.5" y="1.5" width="7" height="7" fill="none" />
        </g>
      </g>
    </svg>
  )
}

InventorisationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InventorisationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InventorisationIcon)
