import {
  COUNTRY,
  REGION,
  CITY,
} from '@/constants/objectTypes'

const types = [COUNTRY, REGION, CITY]

export const toFlat = (node) => {
  if (!types.includes(node.type)) {
    return []
  }
  const { children } = node
  const operatedElement = node
  if (!children || (children && children.length === 0)) {
    return [node]
  }
  let tempContainer = []
  if (children && children.length > 0) {
    const childrenElements = children.reduce((accumulator, element) => {
      const zz = toFlat(element)
      return [
        ...accumulator,
        ...zz,
      ]
    }, [])
    tempContainer = tempContainer.concat(childrenElements)
  }
  return [
    operatedElement,
    ...tempContainer,
  ]
}

export default toFlat
