import {
  ORANGE_PEEL,
  AZURE_RADIANCE,
} from '@/constants/styles/defaultTheme'

export const seriesForLineChartConsumption = [
  {
    name: 'actualExpense',
    title: 'Фактическое потребление',
    fill: ORANGE_PEEL,
    stroke: ORANGE_PEEL,
    type: 'monotone',
  },
  {
    name: 'planExpense',
    title: 'Плановое потребление',
    fill: AZURE_RADIANCE,
    stroke: AZURE_RADIANCE,
    type: 'monotone',
  },
]
