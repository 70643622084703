import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  successGetIntegrationTypes,
  errorGetIntegrationTypes,
} from '@/store/actions/installation/getAvalibleIntegrationTypes'

import { GET_TYPES } from '@/constants/apiRoutes'
import { GET_INTEGRATION_TYPES } from '@/store/actions/installation'

function* getIntegrationTypesSaga() {
  try {
    const { data } = yield request({
      url: GET_TYPES,
      method: 'get',
    })
    const formattedData = data.map((type) => ({
      value: type,
      title: (<Lang id={`globalNames.integrationType.${type}`} />),
    }))
    yield put(successGetIntegrationTypes(formattedData))
  } catch (error) {
    console.log('function*getIntegrationTypesSaga -> error', error)
    yield put(errorGetIntegrationTypes())
  }
}

export default function* root() {
  yield takeLatest(GET_INTEGRATION_TYPES.REQUEST, getIntegrationTypesSaga)
}
