import { GET_ALL_APPLICATIONS_MODELS } from './index'

export const requestGetApplicationsModels = (payload) => ({
  type: GET_ALL_APPLICATIONS_MODELS.REQUEST,
  payload,
})

export const successGetApplicationsModels = (payload) => ({
  type: GET_ALL_APPLICATIONS_MODELS.SUCCESS,
  payload,
})

export const errorGetApplicationsModels = (payload) => ({
  type: GET_ALL_APPLICATIONS_MODELS.ERROR,
  payload,
})
