import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ThemeIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 23C18.9706 23 23 18.9706 23 14C23 9.02944 18.9706 5 14 5C9.02944 5 5 9.02944 5 14C5 18.9706 9.02944 23 14 23ZM9.05025 9.05025C10.363 7.7375 12.1435 7 14 7L14 21C12.1435 21 10.363 20.2625 9.05025 18.9497C7.7375 17.637 7 15.8565 7 14C7 12.1435 7.7375 10.363 9.05025 9.05025Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ThemeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ThemeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ThemeIcon)
