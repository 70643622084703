import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MenuHamburgerIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 20.5C5 19.6716 5.67157 19 6.5 19H21.5C22.3284 19 23 19.6716 23 20.5C23 21.3284 22.3284 22 21.5 22H6.5C5.67157 22 5 21.3284 5 20.5Z" fill={fill} className="svgFill" />
      <path d="M5 13.5C5 12.6716 5.67157 12 6.5 12H21.5C22.3284 12 23 12.6716 23 13.5C23 14.3284 22.3284 15 21.5 15H6.5C5.67157 15 5 14.3284 5 13.5Z" fill={fill} className="svgFill" />
      <path d="M5 6.5C5 5.67157 5.67157 5 6.5 5H21.5C22.3284 5 23 5.67157 23 6.5C23 7.32843 22.3284 8 21.5 8H6.5C5.67157 8 5 7.32843 5 6.5Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MenuHamburgerIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MenuHamburgerIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MenuHamburgerIcon)
