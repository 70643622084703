import styled from 'styled-components'

const LoaderStyled = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  ${({ center }) => center && `
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  `}

  ${({ fill }) => fill && `
    width: auto;
    height: auto;

    svg {
      width: 30px;
      height: 30px;
    }
  `}

  ${({ fullScreen }) => fullScreen && `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  #Big-circle {
    transform: rotate(0deg);
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    transform-origin: center center;
    animation: bigCircleRatation 2s linear infinite;
  }
  #Small-circle {
    transform: rotate(0deg);
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    transform-origin: center center;
    animation: smallCircleRatation 2s linear infinite;
  }

  @keyframes bigCircleRatation {
    0% {
      transform: rotate(0deg);
      stroke-dashoffset: 0;
    }
    50% {
      transform: rotate(360deg);
      stroke-dashoffset: 300;
    }
    100% {
      transform: rotate(720deg);
      stroke-dashoffset: 25;
    }
  }
  @keyframes smallCircleRatation {
    0% {
      transform: rotate(0deg);
      stroke-dashoffset: 0;
    }
    50% {
      transform: rotate(-360deg);
      stroke-dashoffset: 100;
    }
    100% {
      transform: rotate(-720deg);
      stroke-dashoffset: 10;
    }
  }
`

export default LoaderStyled
