import { takeLatest, put } from 'redux-saga/effects'
import get from 'lodash/get'
import cookie from 'react-cookies'
import request from '@/helpers/axios'
import { USER_WIDGET_SETTINGS_ENDPOINT } from '@/constants/apiRoutes'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'
import { GET_WIDGET_SETTINGS } from '@/store/actions/user';
import createNotifications from '@/helpers/notification';
import { errorGetWidgetSettings, successGetWidgetSettings } from '@/store/actions/user/settings/getWidgetSettings';

function* getWidgetSettings() {
    try {
        const token = cookie.load(SLS24_ACCESS_TOKEN)
        const response = token
            ? yield request({
                url: USER_WIDGET_SETTINGS_ENDPOINT,
                method: 'get',
            })
            : null

        if (response) {
            const settings = {
                generalPage: {
                    widgets: get(response, 'data.generalPage.widgets', []),
                },
                serviceDeskIntegrationStatus: get(response, 'data.serviceDeskIntegrationStatus', []),
            }

            yield put(successGetWidgetSettings(settings))
        }
    } catch (error) {
        yield put(errorGetWidgetSettings(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные виджетов.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_WIDGET_SETTINGS.REQUEST, getWidgetSettings)
}
