import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const CompanyIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 3V2.5C21 1.83696 20.7366 1.20107 20.2678 0.732233C19.7989 0.263392 19.163 0 18.5 0C17.837 0 17.2011 0.263392 16.7322 0.732233C16.2634 1.20107 16 1.83696 16 2.5V3C15.45 3 15 3.45 15 4V8C15 8.55 15.45 9 16 9H21C21.55 9 22 8.55 22 8V4C22 3.45 21.55 3 21 3ZM20.2 3H16.8V2.5C16.8 1.56 17.56 0.8 18.5 0.8C19.44 0.8 20.2 1.56 20.2 2.5V3ZM17.92 11C17.96 11.33 18 11.66 18 12C18 14.08 17.2 15.97 15.9 17.39C15.64 16.58 14.9 16 14 16H13V13C13 12.45 12.55 12 12 12H6V10H8C8.55 10 9 9.55 9 9V7H11C12.1 7 13 6.1 13 5V2.46C12.05 2.16 11.05 2 10 2C4.48 2 0 6.48 0 12C0 17.52 4.48 22 10 22C15.52 22 20 17.52 20 12C20 11.66 19.98 11.33 19.95 11H17.92ZM9 19.93C5.05 19.44 2 16.08 2 12C2 11.38 2.08 10.79 2.21 10.21L7 15V16C7 17.1 7.9 18 9 18V19.93Z" fill={fill} className="svgFill" />
    </svg>
  )
}

CompanyIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CompanyIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(CompanyIcon)
