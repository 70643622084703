import React from 'react'
import pt from 'prop-types'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import qs from 'query-string'
import { childrenType } from '@/propTypes/react'
import memoWithName from '@/hocs/memoWithName'
import {
  routerHistoryType,
  routerHistoryDefault,
} from '@/propTypes/router'
import {
  TabsContainer,
  TabsNames,
  TabName,
  TabContent,
} from './styles'

export class TabsController extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      activeTabName: props.activeTab,
    }
  }

  componentDidMount() {
    this.setInitialData()
  }

  setInitialData = () => {
    const { activeTabName } = this.state
    const {
      history: { location: { search } },
      withUrlChange,
      tabs,
    } = this.props
    const query = qs.parse(search)
    if (withUrlChange && query.tab) {
      this.handleChangeTab(query.tab)()
    } else {
      this.handleChangeTab(activeTabName || tabs[0].value)()
    }
  }

  changeUrl = (value) => {
    const { history: { push, location: { pathname, search } } } = this.props
    const query = qs.parse(search)

    if (value !== query.tab) {
      push({
        pathname,
        search: qs.stringify({ ...query, tab: value }),
      })
    }
  }

  handleChangeTab = (value, withoutOpen = false) => () => {
    const { onTabChange, withUrlChange } = this.props

    if (!withoutOpen) {
      this.setState({
        activeTabName: value,
      })
    }
    onTabChange(value)
    if (withUrlChange) {
      this.changeUrl(value)
    }
  }

  render() {
    const { activeTabName } = this.state
    const {
      tabs,
      nameStyles,
      withMargins,
      children,
    } = this.props
    const activeTabObject = activeTabName && tabs.find((tab) => tab.value === activeTabName)
    return (
      <TabsContainer nameStyles={nameStyles}>
        <TabsNames margins={withMargins}>
          {tabs.map((tab) => (
            <TabName
              key={tab.value}
              nameStyles={nameStyles}
              active={tab.value === activeTabName}
              onClick={this.handleChangeTab(tab.value, tab.withoutOpen)}
            >
              {tab.title}
            </TabName>
          ))}
          {!(nameStyles === 'fullWidth' || nameStyles === 'filledBackground') && (
            <TabName key="space" space />
          )}
        </TabsNames>
        {children}
        {(activeTabObject && activeTabObject.content) && (
          <TabContent>
            {activeTabObject.content}
          </TabContent>
        )}
      </TabsContainer>
    )
  }
}

TabsController.defaultProps = {
  activeTab: '',
  tabs: [],
  nameStyles: 'default',
  withMargins: false,
  withUrlChange: true,
  onTabChange: noop,
  children: null,
  history: routerHistoryDefault,
}

TabsController.propTypes = {
  history: routerHistoryType,
  activeTab: pt.string,
  tabs: pt.arrayOf(pt.shape({
    value: pt.string,
    title: pt.oneOfType([pt.string, pt.element]),
  })),
  nameStyles: pt.oneOf(['default', 'fullWidth', 'filledBackground']),
  withMargins: pt.bool,
  withUrlChange: pt.bool,
  onTabChange: pt.func,
  children: childrenType,
}

export default memoWithName(withRouter(TabsController))
