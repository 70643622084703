import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import {
  CONTROLLER,
} from '@/constants/objectTypes'

const DEFAULT_COUNTS = {
  [OK]: 0,
  [WARNING]: 0,
  [ERROR]: 0,
  [INFO]: 0,
}

const concatTwoCountsObjects = (
  defaultCounts = DEFAULT_COUNTS,
  currentCounts = DEFAULT_COUNTS,
) => ({
  [OK]: defaultCounts[OK] + currentCounts[OK],
  [WARNING]: defaultCounts[WARNING] + currentCounts[WARNING],
  [ERROR]: defaultCounts[ERROR] + currentCounts[ERROR],
  [INFO]: defaultCounts[INFO] + currentCounts[INFO],
})

export const getStatusCount = (node, counts = DEFAULT_COUNTS) => {
  const currentCounts = counts
  if (node.children) {
    return node.children.reduce((accumulator, element) => {
      const result = concatTwoCountsObjects(accumulator, getStatusCount(element))
      if (element.type === CONTROLLER || element.status) {
        result[element.status || INFO] += 1
      }
      return result
    }, DEFAULT_COUNTS)
  }
  return currentCounts
}

const getObjectTelemetry = (object, telemetry) => {
  const objectTelemetry = telemetry[`${object.type}-${object.aliasId}-${object.id}`]
  return objectTelemetry
}

export const getStatusCountByAlias = (node, telemetry, alias, counts = DEFAULT_COUNTS) => {
  const currentCounts = counts
  if (node.children) {
    return node.children.reduce((accumulator, element) => {
      const result = concatTwoCountsObjects(
        accumulator,
        getStatusCountByAlias(element, telemetry, alias),
      )
      if (element.system === alias) {
        const objectTelemetry = getObjectTelemetry(element, telemetry)
        result[(objectTelemetry && objectTelemetry.status) || element.status || INFO] += 1
      }
      return result
    }, DEFAULT_COUNTS)
  }
  return currentCounts
}

export default getStatusCount
