import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PassportizationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0.9C0 0.40294 0.40294 0 0.9 0H17.1C17.597 0 18 0.40294 18 0.9C18 1.39706 17.597 1.8 17.1 1.8H0.9C0.40294 1.8 0 1.39706 0 0.9ZM0 4.95011C0 4.45305 0.40294 4.05011 0.9 4.05011H10.8C11.2971 4.05011 11.7 4.45305 11.7 4.95011C11.7 5.4472 11.2971 5.8501 10.8 5.8501H0.9C0.40294 5.8501 0 5.4472 0 4.95011ZM12.6 4.95011C12.6 4.45305 13.0029 4.05011 13.5 4.05011H17.0995C17.5966 4.05011 17.9995 4.45305 17.9995 4.95011C17.9995 5.4472 17.5966 5.8501 17.0995 5.8501H13.5C13.0029 5.8501 12.6 5.4472 12.6 4.95011ZM0 8.9998C0 8.5027 0.40294 8.0998 0.9 8.0998H5.4C5.8971 8.0998 6.3 8.5027 6.3 8.9998C6.3 9.4968 5.8971 9.8998 5.4 9.8998H0.9C0.40294 9.8998 0 9.4968 0 8.9998ZM7.2 8.9998C7.2 8.5027 7.6029 8.0998 8.1 8.0998H17.1C17.5971 8.0998 18 8.5027 18 8.9998C18 9.4968 17.5971 9.8998 17.1 9.8998H8.1C7.6029 9.8998 7.2 9.4968 7.2 8.9998ZM0 13.0499C0 12.5529 0.40294 12.1499 0.9 12.1499H9C9.4971 12.1499 9.9 12.5529 9.9 13.0499C9.9 13.547 9.4971 13.9499 9 13.9499H0.9C0.40294 13.9499 0 13.547 0 13.0499ZM10.7884 13.0499C10.7884 12.5529 11.1913 12.1499 11.6884 12.1499H17.1C17.597 12.1499 18 12.5529 18 13.0499C18 13.547 17.597 13.9499 17.1 13.9499H11.6884C11.1913 13.9499 10.7884 13.547 10.7884 13.0499ZM0 17.1C0 16.6029 0.40294 16.2 0.9 16.2H4.5C4.99706 16.2 5.4 16.6029 5.4 17.1C5.4 17.5971 4.99706 18 4.5 18H0.9C0.40294 18 0 17.5971 0 17.1ZM6.3 17.1C6.3 16.6029 6.7029 16.2 7.2 16.2H17.1C17.597 16.2 18 16.6029 18 17.1C18 17.5971 17.597 18 17.1 18H7.2C6.7029 18 6.3 17.5971 6.3 17.1Z" fill={fill} className="svgFill" />
    </svg>
  )
}

PassportizationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PassportizationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PassportizationIcon)
