import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import ValueSelect from '@/components/controls/ValueSelect'

const ValueSelectField = ({ label, ...ownProps }) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const { isSubmitting } = form

      const onChange = (event) => {
        const { value } = event.target
        form.setFieldValue(field.name, Number.parseInt(value, 10))
      }

      return (
        <ValueSelect
          {...ownProps}
          {...field}
          onChange={onChange}
          disabled={isSubmitting}
        />
      )
    }}
  </Field>
)

ValueSelectField.defaultProps = {
  label: null,
}

ValueSelectField.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
}

export default React.memo(ValueSelectField)
