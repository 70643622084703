import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_FAULT_ANALYTICS_FILE,
} from '@/store/actions/faultAnalytics'
import { generateSummaryAnalyticsAlarmsFileUrl } from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  successSummaryAlarmAnalyticsFile,
  errorSummaryAlarmAnalyticsFile,
} from '@/store/actions/faultAnalytics/getFile'
import {
  getTableParameters,
} from '@/store/selectors/faultAnalytics'

export const EXTENSION_BY_TYPE = {
  pdf: 'pdf',
  excel: 'xlsx',
}

const FIELDS_MAPER = {
  geoZone: 'geo_zone',
}

function* getAlarmManagerFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const tableParameters = yield select(getTableParameters)

    const response = yield request({
      url: generateSummaryAnalyticsAlarmsFileUrl({
        fileType: payload,
      }),
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        includedColumns: tableParameters.activeFields
          .map((field) => FIELDS_MAPER[field] || field),
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: FIELDS_MAPER[tableParameters.sort.field] || tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24_summary-analytics-alarms.${payload}`,
    })
    yield put(successSummaryAlarmAnalyticsFile())
  } catch (error) {
    yield put(errorSummaryAlarmAnalyticsFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет. Не выбран ни один столбец для формирования отчета',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_FAULT_ANALYTICS_FILE.REQUEST, getAlarmManagerFileSaga)
}
