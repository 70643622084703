import LiveTime from '@/components/icons/livetime'
import {
  AMARANTH,
  ATLANTIS,
  REGENT_GRAY,
  ORANGE_PEEL,
  ORANGE_PEEL_TRANSPERAN,
  AZURE_RADIANCE_TRANSPERANT,
} from '@/constants/styles/defaultTheme'

export const CONSUMPTION_ANALYSIS_DIFFERENCE = 'CONSUMPTION_ANALYSIS_DIFFERENCE'

export const seriesNamesComparing = [
  {
    name: 'previousExpense',
    title: 'Предыдущий',
    fill: REGENT_GRAY,
  },
  {
    name: 'currentExpense',
    title: 'Текущий',
    fill: ORANGE_PEEL,
  },
]

export const seriesConsumptionComparing = [
  {
    name: 'planExpense',
    title: 'Плановое потребление',
    fill: AZURE_RADIANCE_TRANSPERANT,
  },
  {
    name: 'actualExpense',
    title: 'Фактическое потребление',
    fill: ORANGE_PEEL_TRANSPERAN,
  },
]

export const seriesNamesDifference = [
  {
    name: 'base',
    title: 'Текущий период',
    fill: ORANGE_PEEL,
    stackGroupId: CONSUMPTION_ANALYSIS_DIFFERENCE,
  },
  {
    name: 'plus',
    title: 'Перерасход',
    fill: AMARANTH,
    stackGroupId: CONSUMPTION_ANALYSIS_DIFFERENCE,
  },
  {
    name: 'minus',
    title: 'Экономия',
    fill: ATLANTIS,
    stackGroupId: CONSUMPTION_ANALYSIS_DIFFERENCE,
  },
  {
    name: 'beforePeriod',
    title: 'Предыдущий период',
    fill: REGENT_GRAY,
    stackGroupId: CONSUMPTION_ANALYSIS_DIFFERENCE,
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    icon: LiveTime.PDFIcon,
  },
]