import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DotsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 7C16 8.10458 15.1046 9 14 9C12.8954 9 12 8.10458 12 7C12 5.89542 12.8954 5 14 5C15.1046 5 16 5.89542 16 7Z" fill={fill} className="svgFill" />
      <path d="M16 21C16 22.1046 15.1046 23 14 23C12.8954 23 12 22.1046 12 21C12 19.8954 12.8954 19 14 19C15.1046 19 16 19.8954 16 21Z" fill={fill} className="svgFill" />
      <path d="M14 16C15.1046 16 16 15.1046 16 14C16 12.8954 15.1046 12 14 12C12.8954 12 12 12.8954 12 14C12 15.1046 12.8954 16 14 16Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DotsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DotsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DotsIcon)
