export const NAME = 'name'
export const PARENT = 'parentId'
export const PARENT_PROJECT = 'projectId'
export const TYPE = 'integrationType'
export const MARK = 'mark'

export default {
  NAME,
  PARENT,
  TYPE,
  MARK,
  PARENT_PROJECT,
}
