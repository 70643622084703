import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const CrossIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.45204 2.45204C1.84932 3.05477 1.84932 4.03197 2.45204 4.63469L6.81729 8.99999L2.45204 13.3653C1.84932 13.968 1.84932 14.9452 2.45204 15.548C3.05476 16.1507 4.03197 16.1507 4.63469 15.548L8.99999 11.1827L13.3653 15.548C13.968 16.1507 14.9452 16.1507 15.548 15.548C16.1507 14.9452 16.1507 13.968 15.548 13.3653L11.1827 8.99999L15.548 4.63469C16.1507 4.03197 16.1507 3.05477 15.548 2.45204C14.9452 1.84932 13.968 1.84932 13.3653 2.45204L8.99999 6.81729L4.63469 2.45204C4.03197 1.84932 3.05477 1.84932 2.45204 2.45204Z" fill={fill} className="svgFill" />
    </svg>
  )
}

CrossIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CrossIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(CrossIcon)
