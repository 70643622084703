import { eventChannel } from 'redux-saga'
import { select, put } from 'redux-saga/effects'
import { getClient, getSubscriptions } from '@/store/selectors/socket'
import { getIntegrationsIds } from '@/store/selectors/integrations'
import { setSocketSubscriptions } from '@/store/actions/socket'

function* createIntegrationSubscriptionChannel(topicGenerator) {
  const client = yield select(getClient)
  const localSubscriptions = yield select(getSubscriptions)
  const ids = yield select(getIntegrationsIds)
  const subscriptionsCopy = { ...localSubscriptions }
  const names = ids.map(topicGenerator)
  const subscribe = (emitter) => {
    const subscriptions = names.map((name) => {
      if (subscriptionsCopy[name]) {
        subscriptionsCopy[name].unsubscribe()
      }
      const subscription = client.subscribe(name, (event) => {
        emitter(JSON.parse(event.body))
      })
      subscriptionsCopy[name] = subscription
      return subscription
    })

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }
  yield put(setSocketSubscriptions(subscriptionsCopy))
  return eventChannel(subscribe)
}

export default createIntegrationSubscriptionChannel
