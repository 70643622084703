import { GET_REPORT_MANAGER_USERS } from './index'

export const requestReportManagerUsers = (payload) => ({
  type: GET_REPORT_MANAGER_USERS.REQUEST,
  payload,
})

export const successReportManagerUsers = (payload) => ({
  type: GET_REPORT_MANAGER_USERS.SUCCESS,
  payload,
})

export const errorReportManagerUsers = (payload) => ({
  type: GET_REPORT_MANAGER_USERS.ERROR,
  payload,
})
