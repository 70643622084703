import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:  center;
  max-height: calc(100vh - 48px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const ItemContainer = styled.div`
  width: calc(100% - 35px);
  height: 100%;
  box-shadow: ${({ theme }) => theme.colors.shadows.graph};
  border-radius: 4px;
  margin: 15px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`
