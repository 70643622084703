import core from '@/components/icons/core'
import {
  ALL_PERIOD,
  YEAR,
  QUARTER,
  MONTH,
  WEEK,

  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from '@/constants/tyumenOurHome'
import {
  TABLE,
  GRAPH,

  OBJECT,
  GEOZONE,
  PROJECT,
  ADDRES,
  CURR_PERIUD,
  PLANED,
  PLANED_DELTA,
  PREV_PERIUD,
  FACT_PERIUD,
  FACT_DELTA_PERIUD
} from '@/constants/ObjectControllStatistic'

export const periodOptions = [
  { value: ALL_PERIOD, title: 'Весь период' },
  { value: YEAR, title: 'Год' },
  { value: QUARTER, title: 'Квартал' },
  { value: MONTH, title: 'Месяц' },
  { value: WEEK, title: 'Неделя' },
]

export const intervalOptions = [
  { value: ANNUALLY, title: 'Ежегодно' },
  { value: MONTHLY, title: 'Ежемесячно' },
  { value: WEEKLY, title: 'Еженедельно' },
  { value: DAILY, title: 'Ежедневно' },
]

export const structureOptions =  [
  {value: TABLE, title: 'Таблица' },
  {value: GRAPH, title: 'График' },
]

export const exportOptions = [
  {value: TABLE, title: 'XLS', icon: core.XMLIcon },
]

export const tableConfig =  [
  {value: OBJECT, title: 'Объект' },
  {value: GEOZONE, title: 'Геозона' },
  {value: PROJECT, title: 'Проект' },
  {value: ADDRES, title: 'Адрес' },
  {value: CURR_PERIUD, title: 'Текущий период' },
  {value: PLANED, title: 'Плановый расход за тек. период (кВт*ч) ' },
  {value: PLANED_DELTA, title: 'Отклонение от планового расхода (кВт*ч) ' },
  {value: PREV_PERIUD, title: 'Предыдущий период' },
  {value: FACT_PERIUD, title: 'Факт. расход за пред. период (кВт*ч) ' },
  {value: FACT_DELTA_PERIUD, title: 'Разница факт. расхода с пред. периодом (кВт*ч)' },
]
