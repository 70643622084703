import styled from 'styled-components'

export const WidgetContainer = styled.div`
  ${({ fit }) => !fit && `
    width: 100%;

  `}
  background: ${({ theme }) => theme.colors.backgrounds.redisign.widget};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4px;
  align-self: flex-start; 
  padding: 10px;
`

export const WidgetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;

  & > svg {
    cursor: pointer;
  }
`

export const WidgetTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
`

export const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ row }) => row && `
    flex-direction: row;
  `}
  ${({ vertical }) => vertical && `
    && > div:not(:first-child) {
      margin-top: 5px;
    }
    && > div:not(:last-child) {
      margin-bottom: 5px;
    }
  `}
`

export const TooltipWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 2px 3px 15px ${({ theme }) => theme.colors.shadows.widgetPrimary};
  background: ${({ theme }) => theme.colors.backgrounds.drawer};
  border-radius: 4px;
  padding: 15px;
`

export const WidgetRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 20px;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    margin-right: 10px;
  }
`
