import { EDIT_INTEGRATION } from './index'

export const requestEditIntegration = (payload) => ({
  type: EDIT_INTEGRATION.REQUEST,
  payload,
})

export const successEditIntegration = (payload) => ({
  type: EDIT_INTEGRATION.SUCCESS,
  payload,
})

export const errorEditIntegration = (payload) => ({
  type: EDIT_INTEGRATION.ERROR,
  payload,
})
