import { DOWNLOAD_MULTIPLE_OBJECT_SCHEME } from './index'

export const requestDownloadMultipleObjectScheme = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT_SCHEME.REQUEST,
  payload,
})

export const successDownloadMultipleObjectScheme = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT_SCHEME.SUCCESS,
  payload,
})

export const errorDownloadMultipleObjectScheme = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT_SCHEME.ERROR,
  payload,
})
