import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'
import {
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from '@/constants/objectStatuses'
import {
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'

const DOWNLOAD_INTALATION_FILE = 'DOWNLOAD_INTALATION_FILE'
export const allFilterOption = [NOT_VERIFIED, VERIFIED, NEW, NOT_EXIST, NOT_INSTALLED, INSTALLED, UNINSTALLED]
export const filterOptions = [
  { value: NOT_VERIFIED, color: STATUS_COLORS[NOT_VERIFIED], title: 'Не проверен' },
  { value: VERIFIED, color: STATUS_COLORS[VERIFIED], title: 'Проверен' },
  { value: NEW, color: STATUS_COLORS[NEW], title: 'Новый' },
  { value: NOT_EXIST, color: STATUS_COLORS[NOT_EXIST], title: 'Не существует' },
  { value: NOT_INSTALLED, color: STATUS_COLORS[NOT_INSTALLED], title: 'Не установлен' },
  { value: INSTALLED, color: STATUS_COLORS[INSTALLED], title: 'Установлен' },
  { value: UNINSTALLED, color: STATUS_COLORS[UNINSTALLED], title: 'Демонтирован' },
]

export const createOptions = [
  { value: GEOZONE, title: 'Геозона' },
  { value: PROJECT, title: 'Проект' },
  { value: INTEGRATION, title: 'Интеграция' },
  { value: OBJECT_ELEMENT, title: 'Объект контроля' },
  { value: DOWNLOAD_INTALATION_FILE, title: 'Загрузить файл инсталляции' },
]
