import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import {
  GET_REPORT_MANAGER_FILE,
} from '@/store/actions/reportManager'
import { GET_REPORT_FILE_URL } from '@/constants/apiRoutes'
import {
  successReportManagerFile,
  errorReportManagerFile,
} from '@/store/actions/reportManager/getFile'
import {
  getTableParameters,
} from '@/store/selectors/reportManager'
import createNotifications from '@/helpers/notification'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  COUNTRY,
} from '@/constants/objectTypes'

const fields = {
  geoZoneId: 'geozoneid',
  title: 'title',
  type: 'type',
  objectTypes: 'objecttypes',
  schedule: 'schedule',
  externalRecipients: 'recipients',
  lastSendDate: 'lastsenddate',
  nextSendDate: 'nextsenddate',
}

function* getReportManagerFileSaga({ payload }) {
  const toast = createNotifications()
  const node = yield select(getSelectedNode)
  try {
    const tableParameters = yield select(getTableParameters)
    const newTableParameters = tableParameters.activeFields.map((field) => fields[field])
    const response = yield request({
      url: GET_REPORT_FILE_URL({
        params: {
          geoZoneIds: (node.type !== COUNTRY && payload.geoZoneId) || null,
          columns: newTableParameters,
          sortOrder: tableParameters.sort.direction,
          sortBy: tableParameters.sort.field,
          filterBy: tableParameters.requestQuery || '',
        },
        name: 'SLS24_report_manager_table',
      }),
      method: 'post',
      options: {
        responseType: 'arraybuffer',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: 'SLS24_report_manager_table.xlsx',
    })
    yield put(successReportManagerFile())
  } catch (error) {
    const { errorMessage } = error.response.data
    yield put(errorReportManagerFile(error))
    if (errorMessage === "MissingServletRequestParameterException: Required List parameter 'columns' is not present") {
      toast({
        title: 'Ошибка операции!',
        description: 'Невозможно выгрузить отчет. Не выбран ни один столбец для формирования отчета',
        type: 'error',
      })
    } else {
      toast({
        title: 'Ошибка операции!',
        description: 'Невозможно выгрузить отчет',
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(GET_REPORT_MANAGER_FILE.REQUEST, getReportManagerFileSaga)
}
