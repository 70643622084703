import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LogoDarkIcon = ({ color, onClick }) => {
  const {
    theme,
  } = useSvgColors(color)
  const fill = theme.colors.colors.logo

  return (
    <svg onClick={onClick} fill="none" height="43" viewBox="0 0 104 43" width="104" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="70.8227" x2="10.126" y1="4.08845" y2="47.4989">
        <stop offset="0" stopColor="#f7d43d" />
        <stop offset="1" stopColor="#e73930" />
      </linearGradient>
      <g mask="url(#b)">
        <path d="m93.8103 11.937h-7.4717l.2761-1.7975 3.917-3.71835.5349-.5463c.8456-.8635 1.2252-1.69176 1.1562-2.44952-.0518-.59917-.3624-.89875-.9146-.91637-.3969-.01762-.7247.1586-.9835.47581-.2761.33482-.4314.77538-.5005 1.30406l-2.4848.01762c.0346-.7049.2416-1.35693.6212-1.93847.3797-.58154.8801-1.03972 1.5185-1.374551.6385-.334827 1.3115-.493429 2.0189-.493429 1.0699.017622 1.9154.317204 2.5021.89875.5867.58154.8455 1.35692.7765 2.32616-.0518.58154-.2243 1.14546-.5522 1.69176-.3278.54629-.7937 1.12783-1.4149 1.727l-1.1562 1.07497-1.7083 1.69175 4.1586.01763z" fill="#f3a53a" />
        <path d="m102.335 7.54893h1.087l-.345 2.00896h-1.088l-.414 2.39661h-2.4845l.4141-2.39661h-4.124l.138-1.70938 5.4184-7.137095v-.052867l2.571.035245zm-4.3661-.01762 1.8808.01762.6733-3.48925z" fill="#f3a53a" />
        <g fill={fill} className="svgFill">
          <path d="m40.1888 14.6315v-7.70684h-8.0522v13.10694c2.822-1.9311 5.5124-3.7193 8.0522-5.4001z" />
          <path d="m10.2187 25.0027 6.8294 1.3947c1.919.3755 2.7091 1.0371 2.9537 2.0206 2.3329-1.6451 4.5905-3.2008 6.7729-4.7207-1.4863-2.0384-4.0637-3.3974-7.469-4.1306l-6.7729-1.4305c-2.0507-.4112-2.63392-1.3411-2.63392-2.5927 0-1.7703 1.71202-3.1472 4.38362-3.1472 2.5774 0 5.1173 1.4305 5.7005 4.3631l7.9017-1.5378c-1.2229-5.6505-6.3966-8.99431-13.5082-8.99431-7.65712 0-12.92493 3.75508-12.92493 9.78111-.01882 5.6683 4.08255 7.975 8.76713 8.9943z" />
          <path d="m7.4343 37.3765c2.40814-1.7523 4.7598-3.4332 7.0551-5.0783-2.8785-.1967-4.92916-1.6093-5.70052-4.5597l-8.296815 1.5378c.564405 3.8624 3.217135 6.5982 6.942235 8.1002z" />
          <path d="m40.1888 21.9987c-2.8408 1.2695-5.5312 2.5749-8.0522 3.8802v12.1235h24.1567v-6.6697h-16.1045z" />
          <path d="m85.9435 15.2216-7.9017 1.5378c-.5832-2.9147-3.123-4.3631-5.7005-4.3631-2.6903 0-4.3836 1.3948-4.3836 3.1471 0 1.2517.5833 2.1816 2.6339 2.5928l6.7729 1.4305c5.6629 1.1981 8.9741 4.1306 8.9741 9.048 0 6.6339-6.1896 10.0671-13.4705 10.0671-7.3749 0-13.4141-3.3438-14.2983-9.4055l8.2968-1.5378c.8278 3.2007 3.1795 4.5955 6.4342 4.5955 3.029 0 4.8351-1.3054 4.8351-3.2008 0-1.3947-.6773-2.2709-3.029-2.7358l-6.8293-1.3947c-4.6846-1.0193-8.786-3.3438-8.786-8.9943 0-6.02603 5.2678-9.78111 12.925-9.78111 7.1303 0 12.3041 3.34381 13.5269 8.99431z" />
          <path d="m11.3852 38.4315c1.0911.1609 2.2388.2503 3.4052.2503 7.2621 0 13.4706-3.4332 13.4706-10.0672 0-.2324 0-.4649-.0188-.6973-7.4879 4.0948-13.2636 7.9392-16.857 10.5142z" />
        </g>
        <path d="m7.00872 42.5c3.87568-2.9766 23.90018-17.6419 52.51788-26.4265.4502-6.27994 5.2314-9.64759 13.0885-9.84626.7037.01565 1.0739.01808 2.627.18778-4.8469-2.54285-5.2041-3.99038-5.2041-3.99038s-5.8332-1.996504-65.73054 39.20416z" fill="url(#a)" />
      </g>
    </svg>
  )
}

LogoDarkIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LogoDarkIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LogoDarkIcon)
