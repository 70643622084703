import React from 'react'

import BarChart from '@/components/charts/BarChart'

const DATA = [
  {
    dateStep: 'Фев 2020',
    done: 37,
    fix: 4,
    error: 2,
  },
  {
    dateStep: 'Мар 2020',
    done: 37,
    fix: 4,
    error: 1,
  },
  {
    dateStep: 'Апр 2020',
    done: 37,
    fix: 5,
    error: 2,
  },
  {
    dateStep: 'Май 2020',
    done: 37,
    fix: 4,
    error: 2,
  },
  {
    dateStep: 'Июн 2020',
    done: 37,
    fix: 5,
    error: 1,
  },
  {
    dateStep: 'Июл 2020',
    done: 37,
    fix: 6,
    error: 2,
  },
]

const SERIES = [
  {
    fill: '#81c13c',
    name: 'done',
    title: 'Исправно',
  },
  {
    fill: '#f37a24',
    name: 'fix',
    title: 'В ремонте',
  },
  {
    fill: '#fd351f',
    name: 'error',
    title: 'Неисправно',
  },
]

const Widget = () => (
  <BarChart
    data={DATA}
    seriesNames={SERIES}
    mainAxisName="dateStep"
    customGraphMargin={{
      top: 0,
      right: 10,
      left: -30,
      bottom: -5,
    }}
    legend={{
      align: 'center',
      verticalAlign: 'bottom',
      iconType: 'square',
    }}
  />
)

export default Widget
