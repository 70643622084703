export default [
  {
    passportParameter: 'Идентификатор',
    showInDashboard: true,
    selector: 'id',
  },
  {
    passportParameter: 'Идентификатор АСУНО',
    showInDashboard: false,
    selector: 'idASUNO',
  },
  {
    passportParameter: 'Наименование',
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: 'Модель/марка',
    showInDashboard: true,
    selector: 'model/brand',
  },
  {
    passportParameter: 'Производитель',
    showInDashboard: false,
    selector: 'manufacturer',
  },
  {
    passportParameter: 'Географическая широта',
    showInDashboard: true,
    selector: 'latitude',
  },
  {
    passportParameter: 'Географическая долгота',
    showInDashboard: true,
    selector: 'longitude',
  },
  {
    passportParameter: 'Дата начала эксплуатации',
    showInDashboard: true,
    selector: 'startDate',
  },
  {
    passportParameter: 'Эксплуатирующая организация',
    showInDashboard: false,
    selector: 'operatingOrganization',
  },
  {
    passportParameter: 'Собственник',
    showInDashboard: false,
    selector: 'owner',
  },
  {
    passportParameter: 'Гарантийный срок',
    showInDashboard: true,
    selector: 'warrantyPeriod',
  },
  {
    passportParameter: 'Срок службы',
    showInDashboard: true,
    selector: 'lifeTime',
  },
  {
    passportParameter: 'Диапазон рабочей температуры',
    showInDashboard: true,
    selector: 'operatingTemperatureRange',
  },
  {
    passportParameter: 'Потребляемая мощность',
    showInDashboard: true,
    selector: 'powerConsumption',
  },
  {
    passportParameter: 'Общее количество подключенных осветительных приборов',
    showInDashboard: true,
    selector: 'totalNumberOfConnectedLightingDevices',
  },
  {
    passportParameter: 'Фото',
    showInDashboard: true,
    selector: 'photo',
  },
  {
    passportParameter: 'Файл паспорта изделия',
    showInDashboard: false,
    selector: 'productDataSheetFile',
  },
]
