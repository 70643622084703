import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ICIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2227 30.7778L11.4919 31.4604L12.2227 32.2428L12.9534 31.4604L12.2227 30.7778ZM12.2227 30.7778C12.9534 31.4604 12.9536 31.4603 12.9538 31.4601L12.9544 31.4595L12.9562 31.4575L12.9626 31.4506L12.9859 31.4254C13.0061 31.4036 13.0355 31.3716 13.0735 31.33C13.1494 31.2469 13.2596 31.1251 13.3993 30.9682C13.6786 30.6544 14.0759 30.1996 14.5518 29.6313C15.5028 28.4957 16.7725 26.9013 18.0442 25.0685C19.314 23.2385 20.5988 21.1524 21.5693 19.0345C22.5339 16.9293 23.2227 14.7189 23.2227 12.6602C23.2227 6.64501 18.2804 1.77783 12.2227 1.77783C6.16489 1.77783 1.22266 6.64501 1.22266 12.6602C1.22266 14.7189 1.91142 16.9293 2.87605 19.0345C3.84654 21.1524 5.1313 23.2385 6.40107 25.0685C7.67283 26.9013 8.94252 28.4957 9.89348 29.6313C10.3694 30.1996 10.7667 30.6544 11.046 30.9682C11.1857 31.1251 11.2959 31.2469 11.3718 31.33C11.4098 31.3716 11.4392 31.4036 11.4594 31.4254L11.4828 31.4506L11.4891 31.4575L11.4909 31.4595L11.4915 31.4601C11.4917 31.4603 11.4919 31.4604 12.2227 30.7778Z" fill={fill} className="svgFill" stroke="white" stroke-width="2"/>
    </svg>
  )
}

ICIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ICIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ICIcon)
