import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_FAULT_ANALYTICS_TABLE,
  FAULT_ANALYTICS_TABLE,
  SET_SELECTED_ELEMENT,
} from '@/store/actions/faultAnalytics'
import { ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successSummaryAlarmAnalyticsTable,
  errorSummaryAlarmAnalyticsTable,
} from '@/store/actions/faultAnalytics/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
} from '@/store/selectors/faultAnalytics'

const FIELDS_MAPER = {
  geoZone: 'geo_zone',
}

function* getAlarmManagerTableSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.isHasInstallations) {
      yield put(successSummaryAlarmAnalyticsTable({
        data: [],
        page: 1,
        resultCount: 0,
      }))
      return null
    }
    const tableParameters = yield select(getTableParameters)

    const response = yield request({
      url: ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT,
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        includedColumns: tableParameters.activeFields
          .map((field) => FIELDS_MAPER[field] || field),
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: FIELDS_MAPER[tableParameters.sort.field] || tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
    })

    const {
      content,
      totalElements,
    } = response.data
    yield put(successSummaryAlarmAnalyticsTable({
      data: content,
      totalElements,
    }))
  } catch (error) {
    yield put(errorSummaryAlarmAnalyticsTable(error))
  }
}

export default function* root() {
  yield takeLatest(GET_FAULT_ANALYTICS_TABLE.REQUEST, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.SORT, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.PER_PAGE, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.PAGE, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.SELECTED_FIELDS, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.REQUEST_QUERY, getAlarmManagerTableSaga)
  yield takeLatest(SET_SELECTED_ELEMENT, getAlarmManagerTableSaga)
}
