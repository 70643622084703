import { CONTROLLER } from '@/constants/objectTypes'
import * as formatDataFunc from '@/helpers/dataFormator'
import { getIsElementary } from '@/helpers/maps'
import { getPlainDataFromTree } from '@/data/mapData'

const getPinsByTree = (tree) => {
  const plainData = getPlainDataFromTree(tree)
  const controllers = plainData
    .filter((item) => item.type === CONTROLLER)
    .map((element) => formatDataFunc.getObjectStatistic(element, plainData))
  const objects = plainData.filter((item) => getIsElementary(item.type))

  return objects.concat(controllers)
}

export default getPinsByTree
