import React from 'react'
import pt from 'prop-types'
import CellContainer from './styles'

const ServiceDeskCell = ({
  value,
  withError,
  date,
  ...cell
}) => (
  <CellContainer date={date} isOverdue={withError && cell.row.original.overdue}>
    {value}
  </CellContainer>
)

ServiceDeskCell.propTypes = {
  value: pt.string,
  date: pt.bool,
  withError: pt.bool,
}
ServiceDeskCell.defaultProps = {
  value: '',
  date: false,
  withError: false,
}

export default ServiceDeskCell
