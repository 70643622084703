import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 5px;
  background: #FFFFFF;
`

export const Header = styled.div`
  height: 48px;
  background: ${({ colors: { bgColor } }) => bgColor || '#C2F3E3'};
  color: ${({ colors: { color } }) => color};
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #DADADA;

  i { margin-right: 10px; }
`

export const Content = styled.div`
  padding: 10px;
  min-height: 400px;

  .recharts-responsive-container {
    min-height: 400px;
  }
`
