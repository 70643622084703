import React, {
  useState,
  useMemo,
  useRef,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import head from 'lodash/head'
import get from 'lodash/get'
import memoWithName from '@/hocs/memoWithName'
import { injectIntl } from 'react-intl'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import TextControl from '@/components/controls/TextControl'
import UsersIcons from '@/components/icons/users'
import ApplicationIcons from '@/components/icons/applications'

import {
  Selection,
  Value,
  Arrow,
  Options,
  OptionsContainer,
  Option,
  Select,
  Error,
  SearchContainer,
  StyledCheckBox,
  EnumerationContainer,
  StyledRadioButton,
} from './styles'

const SelectControl = ({
  autoWidth,
  largeOptions,
  value,
  doNotDisplayValue,
  options,
  placeholder,
  onChange,
  top,
  className,
  error,
  disabled,
  smallArrowPadding,
  withSearch,
  multiselect,
  multiPlaceholder,
  styleType,
  overflow,
  customPadding,
  light,
  optionFontSize,
  userDisabled,
  userForm,
  enumeration,
  withRadioButton,
  intl,
  WithFirstExtraComponent,
  additionalValue,
  datePicker,
  optionsPosition,
  selectIcon,
  withoutSelect,
  selectAll,
  reverseSelect,
  multiselectTitleAll,
}) => {
  const ref = useRef(null)
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [valuePosition, setValuePosition] = useState(-1)
  const isAllSelection = useMemo(() => options.length === (value || []).length, [value, options])
  const selectedOption = useMemo(() => {
    if (multiselect) {
      if (reverseSelect ? (options.length - (value || []).length) === 1 : (value || []).length === 1) {
        return head(options.filter((option) => (value).includes(option.value))) || {}
      }
      if ((value || []).length > 1 && multiPlaceholder) {
        return ({ title: placeholder })
      }
      if (reverseSelect ? ((options.length - 2) >= ((value || []).length)) : ((value || []).length - 1) >= 0) {
        if (multiselectTitleAll) {
          return isAllSelection ? { title: multiselectTitleAll } : { title: 'Выбрано несколько' }
        }
        return ({ title: 'Выбрано несколько' })
      }
    }
    if (value !== undefined) {
      setValuePosition(options.findIndex((option) => `${option.value}` === `${value}`))
      return head(options.filter((option) => `${option.value}` === `${value}`)) || {}
    }
    return {}
  }, [
    multiselectTitleAll,
    multiPlaceholder,
    isAllSelection,
    reverseSelect,
    placeholder,
    multiselect,
    options,
    value,
  ])

  const valueToRender = useMemo(() => (
    (!doNotDisplayValue && selectedOption.title)
    || (!doNotDisplayValue && !multiselect && value) || placeholder
  ), [selectedOption, multiselect, value, placeholder, doNotDisplayValue])
  const handleClick = () => {
    if (!disabled && !userDisabled) {
      setIsOpen(!isOpen)
      setSearch('')
    }
  }

  const handleSelect = (newValue) => () => {
    if (multiselect) {
      const isSelected = (value || []).includes(newValue)
      if (isSelected) {
        const updatedValue = value.filter((option) => option !== newValue)
        onChange(updatedValue)
      } else {
        const updatedValue = [...(value || []), newValue]
        onChange(updatedValue)
      }
    } else {
      onChange(newValue)
      setIsOpen(false)
      setSearch('')
    }
  }

  const setAllSelection = () => {
    if (reverseSelect ? (value || []).length === 0 : options.length === (value || []).length) {
      onChange(reverseSelect ? options.map((option) => option.value) : [])
    } else {
      onChange(reverseSelect ? [] : options.map((option) => option.value))
    }
  }

  const reverseIsAllSelection = useMemo(() => (value || []).length === 0, [value])
  const renderOption = (option, index) => {
    const selected = multiselect
      ? ((value || []).includes(option.value))
      : value === option.value
    if (
      option.disabled
      || (search && typeof option.title === 'object'
        ? (!intl.messages[get(option, 'title.props.id', null)].toLowerCase().includes(search.toLowerCase()))
        : (!(option.title || '').toString().toLowerCase().includes(search.toLowerCase())))
    ) {
      return null
    }

    return (
      <Option
        selected={reverseSelect ? !selected : selected}
        onClick={option.disabled || withoutSelect ? noop : handleSelect(option.value)}
        disabled={option.disabled}
        optionFontSize={optionFontSize}
        enumeration={enumeration || datePicker}
        firstOptionBorder={WithFirstExtraComponent && index === 0}
      >
        {multiselect && (
          <StyledCheckBox value={reverseSelect ? !selected : selected} color={option.color} />
        )}
        {withRadioButton && (
          <StyledRadioButton active={(value || []).includes(option.value)} color={option.color} />
        )}
        {option.title || option.value}
        {WithFirstExtraComponent && index === 0 && <WithFirstExtraComponent />}
      </Option>
    )
  }

  const renderOptions = () => {
    const allSelected = reverseSelect ? reverseIsAllSelection : isAllSelection
    if (!isOpen) {
      return null
    }

    return (
      <Options
        top={top}
        withSearch={withSearch}
        customPadding={customPadding}
        enumeration={enumeration || datePicker}
        optionsPosition={optionsPosition}
      >
        {withSearch && (
          <SearchContainer>
            {multiselect && (
              <StyledCheckBox
                value={allSelected}
                childSelected={(value || []).length > 0 && !allSelected}
                onChange={setAllSelection}
              />
            )}
            <TextControl
              dark
              placeholder="Поиск"
              name="search"
              icon={UsersIcons.MagnifierIcon}
              onChange={setSearch}
            />
          </SearchContainer>
        )}
        <OptionsContainer enumeration={enumeration || datePicker}>
          {selectAll && multiselect && (
            <Option
              selected={allSelected}
              onClick={setAllSelection}
            >
              <StyledCheckBox value={allSelected} color={selectAll.color} />
              {selectAll.title || selectAll.value}
            </Option>
          )}
          {options.map(renderOption)}
        </OptionsContainer>
      </Options>
    )
  }

  useOutsideClickHandler({
    ref,
    callback: handleClick,
    opened: isOpen,
  })

  const changeEnumeration = (type) => (e) => {
    e.stopPropagation()
    if (type === 'increase') {
      if (valuePosition === -1 || (options.length - 1 === valuePosition)) {
        handleSelect(options[0].value)()
      } else {
        handleSelect(options[valuePosition + 1].value)()
      }
    }
    if (type === 'decrease') {
      if (valuePosition === -1 || valuePosition === 0) {
        handleSelect(options[options.length - 1].value)()
      } else {
        handleSelect(options[valuePosition - 1].value)()
      }
    }
  }

  return (
    <Select
      opened={isOpen}
      customIcon={selectIcon}
      disabled={disabled}
      className={className}
      ref={ref}
      withSearch={withSearch}
      styleType={styleType}
      autoWidth={autoWidth}
      largeOptions={largeOptions}
      light={light}
      userDisabled={userDisabled}
      userForm={userForm}
    >
      <Selection
        onClick={handleClick}
        opened={isOpen}
        light={light}
        userDisabled={userDisabled}
      >
        <Value light={light} overflow={overflow} title={overflow ? valueToRender : ''}>
          {valueToRender}
          {additionalValue && additionalValue}
        </Value>
        {!userDisabled && !enumeration && !datePicker && (
          <Arrow small={smallArrowPadding} withSearch={withSearch || styleType} customElement={selectIcon}>
            {selectIcon || <UsersIcons.DownArrow />}
          </Arrow>
        )}
        {datePicker && (
          <EnumerationContainer>
            <ApplicationIcons.ScheduleManagerIcon />
          </EnumerationContainer>
        )}
        {enumeration && (
          <EnumerationContainer>
            <UsersIcons.UpIcon onClick={changeEnumeration('increase')} />
            <UsersIcons.DownIcon onClick={changeEnumeration('decrease')} />
          </EnumerationContainer>
        )}
      </Selection>
      {error && (<Error>{error}</Error>)}
      {renderOptions()}
    </Select>
  )
}

SelectControl.propTypes = {
  options: pt.arrayOf(pt.shape({
    value: pt.oneOfType([pt.number, pt.string]),
    title: pt.oneOfType([pt.number, pt.string, pt.element]),
  })).isRequired,
  value: pt.oneOfType([pt.number, pt.string]),
  placeholder: pt.oneOfType([pt.number, pt.string, pt.element]),
  onChange: pt.func,
  styleType: pt.func,
  top: pt.bool,
  light: pt.bool,
  error: pt.string,
  className: pt.string,
  disabled: pt.bool,
  withSearch: pt.bool,
  autoWidth: pt.bool,
  largeOptions: pt.bool,
  doNotDisplayValue: pt.bool,
  selectIcon: pt.element,
  smallArrowPadding: pt.bool,
  multiselect: pt.bool,
  multiPlaceholder: pt.bool,
  overflow: pt.bool,
  userDisabled: pt.bool,
  userForm: pt.bool,
  enumeration: pt.bool,
  withRadioButton: pt.bool,
  datePicker: pt.bool,
  optionsPosition: pt.bool,
  withoutSelect: pt.bool,
  selectAll: pt.bool,
  reverseSelect: pt.bool,
  multiselectTitleAll: pt.bool,
  intl: pt.objectOf(pt.object),
  customPadding: pt.shape({
    left: pt.oneOfType([pt.number, pt.string]),
    right: pt.oneOfType([pt.number, pt.string]),
    top: pt.oneOfType([pt.number, pt.string]),
    bottom: pt.oneOfType([pt.number, pt.string]),
  }),
  optionFontSize: pt.oneOfType([pt.number, pt.string]),
  WithFirstExtraComponent: pt.element,
  additionalValue: pt.oneOfType([pt.number, pt.string, pt.element]),
}
SelectControl.defaultProps = {
  value: null,
  placeholder: null,
  onChange: noop,
  top: false,
  error: '',
  styleType: null,
  disabled: false,
  doNotDisplayValue: false,
  withSearch: false,
  light: false,
  largeOptions: false,
  autoWidth: false,
  smallArrowPadding: false,
  multiselect: false,
  multiPlaceholder: false,
  overflow: false,
  userDisabled: false,
  userForm: false,
  enumeration: false,
  withRadioButton: false,
  optionsPosition: false,
  datePicker: false,
  withoutSelect: false,
  selectAll: false,
  reverseSelect: false,
  multiselectTitleAll: false,
  selectIcon: null,
  className: null,
  customPadding: {},
  optionFontSize: null,
  intl: {},
  WithFirstExtraComponent: null,
  additionalValue: null,
}

export default injectIntl(memoWithName(SelectControl))
