import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LightModuleIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6719 6.55714C11.6719 6.31749 11.8608 6.12321 12.0938 6.12321C12.3267 6.12321 12.5156 6.31749 12.5156 6.55714C12.5156 6.7968 12.3267 6.99107 12.0938 6.99107C11.8608 6.99107 11.6719 6.7968 11.6719 6.55714Z" fill={fill} className="svgFill" />
      <path d="M10.5469 6.55714C10.5469 6.31749 10.7358 6.12321 10.9688 6.12321C11.2017 6.12321 11.3906 6.31749 11.3906 6.55714C11.3906 6.7968 11.2017 6.99107 10.9688 6.99107C10.7358 6.99107 10.5469 6.7968 10.5469 6.55714Z" fill={fill} className="svgFill" />
      <path d="M11.6719 9.45C11.6719 9.21034 11.8608 9.01607 12.0938 9.01607C12.3267 9.01607 12.5156 9.21034 12.5156 9.45C12.5156 9.68965 12.3267 9.88393 12.0938 9.88393C11.8608 9.88393 11.6719 9.68965 11.6719 9.45Z" fill={fill} className="svgFill" />
      <path d="M10.5469 9.45C10.5469 9.21034 10.7358 9.01607 10.9688 9.01607C11.2017 9.01607 11.3906 9.21034 11.3906 9.45C11.3906 9.68965 11.2017 9.88393 10.9688 9.88393C10.7358 9.88393 10.5469 9.68965 10.5469 9.45Z" fill={fill} className="svgFill" />
      <path d="M11.6719 12.3429C11.6719 12.1032 11.8608 11.9089 12.0938 11.9089C12.3267 11.9089 12.5156 12.1032 12.5156 12.3429C12.5156 12.5825 12.3267 12.7768 12.0938 12.7768C11.8608 12.7768 11.6719 12.5825 11.6719 12.3429Z" fill={fill} className="svgFill" />
      <path d="M10.5469 12.3429C10.5469 12.1032 10.7358 11.9089 10.9688 11.9089C11.2017 11.9089 11.3906 12.1032 11.3906 12.3429C11.3906 12.5825 11.2017 12.7768 10.9688 12.7768C10.7358 12.7768 10.5469 12.5825 10.5469 12.3429Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM4.5 7.13571C4.5 7.45525 4.75184 7.71429 5.0625 7.71429H12.9375C13.2482 7.71429 13.5 7.45525 13.5 7.13571V5.97857C13.5 5.65904 13.2482 5.4 12.9375 5.4H5.0625C4.75184 5.4 4.5 5.65904 4.5 5.97857V7.13571ZM4.5 10.0286C4.5 10.3481 4.75184 10.6071 5.0625 10.6071H12.9375C13.2482 10.6071 13.5 10.3481 13.5 10.0286V8.87143C13.5 8.55189 13.2482 8.29286 12.9375 8.29286H5.0625C4.75184 8.29286 4.5 8.55189 4.5 8.87143V10.0286ZM4.5 12.9214C4.5 13.241 4.75184 13.5 5.0625 13.5H12.9375C13.2482 13.5 13.5 13.241 13.5 12.9214V11.7643C13.5 11.4448 13.2482 11.1857 12.9375 11.1857H5.0625C4.75184 11.1857 4.5 11.4448 4.5 11.7643V12.9214Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LightModuleIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LightModuleIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LightModuleIcon)
