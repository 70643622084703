import SWITCHING_GROUP from './SWITCHING_GROUP'
import STREETLIGHT_CONTROL_CABINET from './STREETLIGHT_CONTROL_CABINET'
import STREETLIGHT_GROUP from './STREETLIGHT_GROUP'
import STREETLIGHT from './STREETLIGHT'
import AMBIENT_LIGHT_SENSOR from './AMBIENT_LIGHT_SENSOR'
import WASTE_CONTAINER from './WASTE_CONTAINER'

export default {
  SWITCHING_GROUP,
  STREETLIGHT_CONTROL_CABINET,
  STREETLIGHT_GROUP,
  STREETLIGHT,
  AMBIENT_LIGHT_SENSOR,
  WASTE_CONTAINER,
}
