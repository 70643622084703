import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const AddButtonContainer = styled.div`
  width: 119px;
  min-width: 119px;
  margin-left: 10px;
`

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0px 15px;
  color: ${({ theme }) => theme.colors.colors.disabled};
    ${({ border, theme }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
`
