import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AnalyticsExpensesIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50 2.5C50 3.88071 48.8807 5 47.5 5C46.1193 5 45 3.88071 45 2.5C45 1.11929 46.1193 0 47.5 0C48.8807 0 50 1.11929 50 2.5Z" fill={fill} className="svgFill" />
      <path d="M20 7.5C20 8.88071 18.8807 10 17.5 10C16.1193 10 15 8.88071 15 7.5C15 6.11929 16.1193 5 17.5 5C18.8807 5 20 6.11929 20 7.5Z" fill={fill} className="svgFill" />
      <path d="M2.5 23C3.88071 23 5 21.8807 5 20.5C5 19.1193 3.88071 18 2.5 18C1.11929 18 0 19.1193 0 20.5C0 21.8807 1.11929 23 2.5 23Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37 23C35.8954 23 35 23.8954 35 25V48C35 49.1046 35.8954 50 37 50H48C49.1046 50 50 49.1046 50 48V25C50 23.8954 49.1046 23 48 23H37ZM38 26V47H47V26H38Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 28C0 26.8954 0.895431 26 2 26H13C14.1046 26 15 26.8954 15 28V48C15 49.1046 14.1046 50 13 50H2C0.89543 50 0 49.1046 0 48V28ZM3 47V29H12V47H3Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18 33C18 31.8954 18.8954 31 20 31H30C31.1046 31 32 31.8954 32 33V48C32 49.1046 31.1046 50 30 50H20C18.8954 50 18 49.1046 18 48V33ZM21 47V34H29V47H21Z" fill={fill} className="svgFill" />
      <path d="M32.5 20C33.8807 20 35 18.8807 35 17.5C35 16.1193 33.8807 15 32.5 15C31.1193 15 30 16.1193 30 17.5C30 18.8807 31.1193 20 32.5 20Z" fill={fill} className="svgFill" />
      <path d="M6.01033 19.2268L15.5103 10.7268L13.5 8.5L4 17L6.01033 19.2268Z" fill={fill} className="svgFill" />
      <path d="M19.7847 10.4613L28.5 16.4953L30.2153 14.034L21.5 8L19.7847 10.4613Z" fill={fill} className="svgFill" />
      <path d="M46.155 6.08711L36.155 16.1211L34 14.034L44 4L46.155 6.08711Z" fill={fill} className="svgFill" />
    </svg>

  )
}

AnalyticsExpensesIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AnalyticsExpensesIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AnalyticsExpensesIcon)
