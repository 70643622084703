import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  .svgFill {
    fill: ${({ theme }) => theme.colors.white};
    height: 24px;
    width: 24px;
  }
  background: ${({ type, theme }) => theme.colors.backgrounds.notification[type]};
`

export const PrimaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 15px;
`

export const SecondaryTitle = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  white-space: pre-wrap;
`

export const Main = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
