import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GeoPinIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.20833 -0.125488C10.9306 -0.125488 12.4028 0.478678 13.625 1.68701C14.8472 2.89535 15.4583 4.36062 15.4583 6.08285C15.4583 6.94396 15.2431 7.93701 14.8125 9.06201C14.3819 10.187 13.8611 11.2287 13.25 12.187C12.6389 13.1453 12.0278 14.0481 11.4167 14.8953C10.8056 15.7426 10.2917 16.4162 9.875 16.9162L9.20833 17.6245C9.04167 17.4578 8.81944 17.2078 8.54167 16.8745C8.26389 16.5412 7.77083 15.8953 7.0625 14.937C6.35417 13.9787 5.72222 13.0481 5.16667 12.1453C4.61111 11.2426 4.11111 10.2217 3.66667 9.08285C3.22222 7.94396 3 6.94396 3 6.08285C3 4.36062 3.60417 2.89535 4.8125 1.68701C6.02083 0.478678 7.48611 -0.125488 9.20833 -0.125488ZM9.20833 8.29118C9.81944 8.29118 10.3472 8.0759 10.7917 7.64535C11.2361 7.21479 11.4583 6.69396 11.4583 6.08285C11.4583 5.47173 11.2361 4.9509 10.7917 4.52035C10.3472 4.08979 9.81944 3.87451 9.20833 3.87451C8.59722 3.87451 8.07639 4.08979 7.64583 4.52035C7.21528 4.9509 7 5.47173 7 6.08285C7 6.69396 7.21528 7.21479 7.64583 7.64535C8.07639 8.0759 8.59722 8.29118 9.20833 8.29118Z" fill={fill} className="svgFill" />
    </svg>
  )
}

GeoPinIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GeoPinIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GeoPinIcon)
