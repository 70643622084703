import { GET_REPORT_MANAGER_TYPES } from './index'

export const requestReportManagerTypes = (payload) => ({
  type: GET_REPORT_MANAGER_TYPES.REQUEST,
  payload,
})

export const successReportManagerTypes = (payload) => ({
  type: GET_REPORT_MANAGER_TYPES.SUCCESS,
  payload,
})

export const errorReportManagerTypes = (payload) => ({
  type: GET_REPORT_MANAGER_TYPES.ERROR,
  payload,
})
