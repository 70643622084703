import React from 'react'
import pt from 'prop-types'
import { CHAR_PER_LINE_IN_TOOLTIP } from '@/constants/maps'
import {
  ToolTipContainer, ToolTipContent,
  PrimaryTitle,
  Container,
  ShadowShalfe,
  Tip,
} from './styles'

const ToolTip = ({
  top, name, id, theme, mash, type, intl, ...onew
}) => {
  const title = !mash ? `${intl.messages[`globalNames.objectTypes.${type}`] || type} ${name}` : `MESH ${name}`
  const heightSecondaryTitle = Math.floor(title.length / CHAR_PER_LINE_IN_TOOLTIP) + 1
  return (
    <Container
      top={top}
      theme={theme}
      aditionalHeight={heightSecondaryTitle}
      mash={mash}
    >
      <ToolTipContainer
        top={top}
        theme={theme}
        aditionalHeight={heightSecondaryTitle}
        mash={mash}
      >
        <ToolTipContent>
          <PrimaryTitle theme={theme}>
            {title}
          </PrimaryTitle>
        </ToolTipContent>
      </ToolTipContainer>
      <ShadowShalfe
        top={top}
        theme={theme}
        aditionalHeight={heightSecondaryTitle}
        mash={mash}
      >
        <ToolTipContent>
          <PrimaryTitle theme={theme}>
            {title}
          </PrimaryTitle>
        </ToolTipContent>
        <Tip theme={theme} type={type} />
      </ShadowShalfe>
    </Container>
  )
}

ToolTip.defaultProps = {
  top: -55,
  name: null,
  id: null,
  type: '',
}

ToolTip.propTypes = {
  theme: pt.objectOf(pt.object).isRequired,
  top: pt.number,
  id: pt.number,
  name: pt.string,
  type: pt.string,
}

export default ToolTip
