import { GET_CURRENT_USER } from './index'

export const beginGetCurrentUser = () => ({
  type: GET_CURRENT_USER.BEGIN,
})

export const endGetCurrentUser = () => ({
  type: GET_CURRENT_USER.END,
})

export const requestGetCurrentUser = (forms) => ({
  type: GET_CURRENT_USER.REQUEST,
  payload: forms,
})

export const successGetCurrentUser = (user) => ({
  type: GET_CURRENT_USER.SUCCESS,
  payload: user,
})

export const errorGetCurrentUser = (error) => ({
  type: GET_CURRENT_USER.ERROR,
  payload: error,
})
