import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
   ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        background: ${theme.colors.backgrounds.redisign.hightLighter};
      `
    }
    return `background: ${theme.colors.backgrounds.extraLight};`
  }}
  border-radius: 4px;
  margin-bottom: 10px;
  transition: width 1s ease-out 0.5s;
`

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-left: 10px;
  margin: 3px;
  cursor: pointer;

  ${({ disabled, theme }) => disabled && `
    cursor: not-allowed;
    ${theme.themeName === LIGHT_THEME_NAME && 'opacity: 0.3;'}
    color: ${theme.colors.colors.disabled};
  `}

  svg,
  .svgFill,
  .svgStroke {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
  }
`

export const WidgetCloseIcon = styled.div`
  padding-left: 6px;
  svg {
    width: 20px;
    height: 20px;
  }
`

export const WidgetContent = styled.div`
  margin: 10px;

  button {
    width: 100%;
  }
`

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: left;
  margin: 5px auto 5px 10px;
`

export const WidgetComponent = styled.div`
  margin: 10px 0;
  zoom: 0.7;

  .recharts-wrapper {
    zoom: 0.8;
  }
`

export const WidgetDescription = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.colors.title};
`
