import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'

export default {
  preview: [
    {
      name: 'Всего обращений',
      selector: 'totalAppealsAmount',
    },
    {
      name: 'Средн. время принятия решения',
      selector: 'averageCompletionTime',
    },
  ],
  main: [
    [
      [
        {
          name: 'В работе',
          valueSelector: 'numberInProgress',
          totalSelector: 'totalAppealsAmount',
          type: 'progressBar',
          message: 'warning',
        },
      ],
      [
        {
          name: 'Запланировано',
          valueSelector: 'numberPlanned',
          totalSelector: 'totalAppealsAmount',
          type: 'progressBar',
          message: 'fix',
        },
        {
          name: 'Успешно решено',
          selector: 'numberProblemSolved',
        },
        {
          name: 'Мотивированный отказ',
          selector: 'numberRefused',
        },
        {
          name: 'Проблема не обнаружена',
          selector: 'numberProblemNotFound',
        },
      ],
      [
        {
          name: 'Средн. время принятия решения',
          selector: 'averageCompletionTime',
        },
      ],
    ],
    [
      [
        {
          name: 'Всего обращений',
          selector: 'totalAppealsAmount',
        },
      ],
      [
        {
          name: 'Наружное освещение',
          selector: 'outdoorLightningCount',
        },
        {
          name: 'Ремонт дорог',
          selector: 'roadRepairingCount',
        },
        {
          name: 'Остановки общ. транспорта',
          selector: 'transportStationCount',
        },
        {
          name: 'Прорыв теплотрасс',
          selector: 'notAvailable1',
          disabled: true,
        },
        {
          name: 'Подтопления',
          selector: 'notAvailable2',
          disabled: true,
        },
      ],
    ],
  ],
  view: {
    title: <Lang id="widgetsDashboard.tyumenOurHomeBigWidget" />,
    icon: WidgetsDashboardIcon.TyumenOurHomeBigWidgetIcon,
  },
}
