import get from 'lodash/get'
import {
  GEOZONE,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import {
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from '@/constants/objectStatuses'

import {
  INSTALLATION_SERVER_TO_LOCAL,
} from '@/constants/passportization/types'

const defaultConfig = {
  [NOT_VERIFIED]: 0,
  [VERIFIED]: 0,
  [NEW]: 0,
  [NOT_EXIST]: 0,
  [NOT_INSTALLED]: 0,
  [INSTALLED]: 0,
  [UNINSTALLED]: 0,
}

const sumTwoObject = (first, second) => Object.keys(defaultConfig)
  .reduce((buffer, elementName) => ({
    ...buffer,
    [elementName]: (first[elementName] || 0) + (second[elementName] || 0),
  }), {})

export const getStatisticTelemetry = (
  node,
  config = defaultConfig,
  doNotCountFirstElement = false,
) => {
  let localConfig = { ...config }
  if (node.type === 'OBJECT_ELEMENT' && node.objectState && !doNotCountFirstElement) {
    localConfig = {
      ...config,
      [node.objectState || null]: config[node.objectState || null]
        ? config[node.objectState || null] + 1
        : 1,
    }
  }

  const childResult = (node.children || []).reduce((accumulator, element) => ({
    ...sumTwoObject(accumulator, getStatisticTelemetry(element)),
  }), {})
  return {
    ...sumTwoObject(localConfig, childResult),
  }
}

export const getTreeWithTelemetry = (node, config = {}) => {
  const nodeSelector = node.objectType || node.type || 'unknown'
  let localConfig = { ...config }
  if (node.type === OBJECT_ELEMENT || node.type === GEOZONE) {
    localConfig = {
      ...localConfig,
      [`${nodeSelector}-${node.treeElementId}`]: {
        status: node.objectState,
        name: node.elementName,
        statistic: getStatisticTelemetry(node, {}, true),
      },
    }
  }

  const childResults = (node.children || []).reduce((accumulator, element) => {
    const childElements = (element.children || []).reduce((buffer, child) => ({
      ...buffer,
      ...getTreeWithTelemetry(child, {}),
    }), { })
    const selector = element.objectType || element.type || 'unknown'
    let localElement = {}
    if (element.type === 'OBJECT_ELEMENT' || element.type === 'GEOZONE') {
      localElement = {
        [`${selector}-${element.treeElementId}`]: {
          status: element.objectState,
          name: element.elementName,
          statistic: getStatisticTelemetry(element, {}, true),
        },
      }
    }
    return {
      ...accumulator,
      ...childElements,
      ...localElement,
    }
  }, {})
  return {
    ...localConfig,
    ...childResults,
  }
}

export const getTreeAndPins = (node, config = []) => {
  if ((node.children || []).length === 0) {
    return [
      ...config,
      {
        id: node.treeElementId,
        name: node.elementName,
        location: [
          get(node, 'geoLocationDto.latitude', null),
          get(node, 'geoLocationDto.longitude', null),
        ],
        ...node,
      },
    ]
  }
  return (node.children || []).reduce((accumulator, element) => {
    const childrenElements = (element.children || []).reduce((buffer, child) => [
      ...buffer,
      ...getTreeAndPins(child),
    ], [])
    return [
      ...accumulator,
      ...childrenElements,
      ...getTreeAndPins(element),
    ]
  }, config)
}

export const getGeoZonesDisplayElements = (
  node,
  config = [],
  rootId,
  parentId,
  parentGeoZoneId,
  path,
) => (node.children || []).reduce((accumulator, element) => [
  ...accumulator,
  {
    id: element.treeElementId,
    name: element.elementName,
    location: [
      get(element, 'geoLocationDto.latitude', null),
      get(element, 'geoLocationDto.longitude', null),
    ],
    type: element.objectType || INSTALLATION_SERVER_TO_LOCAL[element.type] || 'unknow',
    elementType: element.type,
    rootGeoZone: rootId,
    parentGeoZoneId,
    parentTreeId: parentId,
    path: `${path}/${element.treeElementId}`,
  },
], [])

export const calculateNotGeoZoneChild = (node, config = []) => {
  if (node.type === 'GEOZONE') {
    return [
      ...config,
      {
        id: node.treeElementId,
        name: node.elementName,
        location: [
          get(node, 'geoLocationDto.latitude', null),
          get(node, 'geoLocationDto.longitude', null),
        ],
        type: node.objectType || INSTALLATION_SERVER_TO_LOCAL[node.type] || 'unknow',
      },
    ]
  }
}

export const calculateDisplayElementsForTreeById = (
  node,
  config = [],
  selectedElementId,
  isCalcSelectedElementChildren = false,
  rootId,
  parentId,
  parentGeoZoneId,
  path,
) => {
  let localConfig = [...config]
  const isChildSelect = (node.children || []).filter((element) => element.treeElementId === selectedElementId)[0] || false
  if (node.treeElementId === selectedElementId && !isCalcSelectedElementChildren && !isChildSelect) {
    const childElements = (node.children || []).reduce((accumulator, element) => [
      ...accumulator,
      ...calculateDisplayElementsForTreeById(
        element,
        [],
        selectedElementId,
        true,
        rootId,
        node.treeElementId,
        element.type === 'GEOZONE' ? element.treeElementId : parentGeoZoneId,
        `${path}/${element.treeElementId}`,
      ),
    ], [])

    return [...localConfig, ...childElements]
  }

  if (get(node, 'geoLocationDto.latitude', false) && !isChildSelect) {
    localConfig = [
      ...localConfig,
      {
        id: node.treeElementId,
        name: node.elementName,
        location: [
          get(node, 'geoLocationDto.latitude', null),
          get(node, 'geoLocationDto.longitude', null),
        ],
        type: node.objectType || INSTALLATION_SERVER_TO_LOCAL[node.type] || 'unknow',
        elementType: node.type,
        rootGeoZone: rootId,
        parentGeoZoneId: node.type === 'GEOZONE' ? node.treeElementId : parentGeoZoneId,
        parentTreeId: parentId,
        path,
      },
    ]
    if (node.type === 'GEOZONE') {
      return localConfig
    }
  }

  const childResults = (node.children || []).reduce((accumulator, element) => [
    ...accumulator,
    ...calculateDisplayElementsForTreeById(
      element,
      [],
      selectedElementId,
      isCalcSelectedElementChildren,
      rootId,
      node.treeElementId,
      element.type === 'GEOZONE' ? element.treeElementId : parentGeoZoneId,
      `${path}/${element.treeElementId}`,
    ),
  ], [])
  return [
    ...localConfig,
    ...childResults,
  ]
}
