import styled from 'styled-components'
import { Form } from 'formik'
import { LIGHT_THEME_NAME } from '@/constants/names'
import DatePickerField from '@/components/fields/DatePickerField'


export const FormContainer = styled(Form)`
  padding-top: 1px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
          background: ${theme.colors.backgrounds.redisign.topDownGradient};
        `
    }
  }}
  flex-wrap: wrap;
  && > div {
    padding: 6px;
  }
`

export const CustomDatePicker = styled(DatePickerField)`
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  label {
    height: 100%;
    padding-left: 8px;
  }
  && input {
    height: 34px;
    width: 230px;
    padding-left: 40px;
  }
  button {
    padding-left: 10px;
    margin-left: 10px;
    height: 28px;
  }
`

export const ElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  white-space: nowrap;
  margin: 3px 0;
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  ${({ datePicker }) => datePicker && `
    margin-right: 16px;
    margin-left: 16px;
  `};
`
