import styled from 'styled-components'

export const IconContainer = styled.div`
  ${({ playAnimation }) => playAnimation && `
    svg {
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: rotate;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`

export const ModeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

export const ModeBlock = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: -0.003em;
  margin-left: 10px;
  ${({ value }) => value && `
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-left: 0px;
  `}
`
