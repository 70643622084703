import React, { useMemo } from 'react'
import pt from 'prop-types'
import { IntlProvider } from 'react-intl'
import { childrenType } from '@/propTypes/react'
import messages from '@/localization'
import createLocalizationFromObject from '@/helpers/createLocalizationFromObject'

const IntlProviderWrapper = ({ children, language }) => {
  const messagesObject = useMemo(() => createLocalizationFromObject(messages[language]),
    [language])
  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={messagesObject}
      textComponent={React.Fragment}
    >
      {children}
    </IntlProvider>
  )
}

IntlProviderWrapper.propTypes = {
  language: pt.string.isRequired,
  children: childrenType.isRequired,
}

export default IntlProviderWrapper
