import { GET_ALL_TENANTS } from '../index'

export const requestGetAllTenants = (payload) => ({
  type: GET_ALL_TENANTS.REQUEST,
  payload,
})

export const responseGetAllTenants = (payload) => ({
  type: GET_ALL_TENANTS.SUCCESS,
  payload,
})

export const errorGetAllTenants = (payload) => ({
  type: GET_ALL_TENANTS.ERROR,
  payload,
})
