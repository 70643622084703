import { INTEGRATION_SYSTEM, INTEGRATION_ZONE } from '@/constants/viewTree'
import {
  INTEGRATION_SCHEMA_ID,
  INTEGRATION_SCHEMA_CHILD_ID,
  CONNECTION_SIGNAL,
} from '@/constants/syncModule'

export default {
  id: INTEGRATION_SCHEMA_ID,
  name: 'СИСТЕМА',
  isIntegrationScheme: true,
  type: INTEGRATION_SYSTEM,
  toggled: true,
  status: 'ok',
  children: [
    {
      id: INTEGRATION_SCHEMA_CHILD_ID,
      name: 'Новая интеграция',
      syncStatus: CONNECTION_SIGNAL,
      type: INTEGRATION_ZONE,
      status: 'ok',
    },
  ],
}
