import { call } from 'redux-saga/effects'

import { createRefreshEquipmentChannel } from './channels'

function* refreshEquipment() {
  try {
    yield call(createRefreshEquipmentChannel)
  } catch (error) {
    console.log("function*refreshEquipment -> error", error)
  }
}

export default refreshEquipment
