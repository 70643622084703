import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.04966 9.53788C2.68005 9.70624 0 12.4825 0 15.8824C0 17.0524 0.951363 18 2.125 18H14.875C16.0486 18 17 17.0524 17 15.8824C17 12.4825 14.32 9.70624 10.9503 9.53788C10.8426 9.53259 10.7341 9.52941 10.625 9.52941H6.375C6.26588 9.52941 6.1574 9.53259 6.04966 9.53788ZM5.68884 7.41177C6.43801 8.07035 7.4222 8.47059 8.5 8.47059C9.5778 8.47059 10.562 8.07035 11.3112 7.41177C12.1935 6.63565 12.75 5.50059 12.75 4.23529C12.75 1.89635 10.8472 0 8.5 0C6.15283 0 4.25 1.89635 4.25 4.23529C4.25 5.50059 4.80654 6.63565 5.68884 7.41177ZM14.3438 15.8824C14.6371 15.8824 14.875 15.6452 14.875 15.3529V14.8235C14.875 12.4846 12.9722 11.6471 10.625 11.6471H6.375C4.02783 11.6471 2.125 12.4846 2.125 14.8235V15.3529C2.125 15.6452 2.36289 15.8824 2.65625 15.8824H14.3438ZM8.5 6.35294C9.67364 6.35294 10.625 5.40529 10.625 4.23529C10.625 3.06529 9.67364 2.11765 8.5 2.11765C7.32636 2.11765 6.375 3.06529 6.375 4.23529C6.375 5.40529 7.32636 6.35294 8.5 6.35294Z" fill={fill} className="svgFill" />
    </svg>
  )
}


UserIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserIcon)
