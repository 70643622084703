import COMPANY_OBJECT from './COMPANY_OBJECT'
import DEVICE from './DEVICE'
import WATHER_SUPLY from './WATHER_SUPLY'
import CENTRAL_HEATION from './CENTRAL_HEATION'

export default {
  COMPANY_OBJECT,
  DEVICE,
  WATHER_SUPLY,
  CENTRAL_HEATION,
}
