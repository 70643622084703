import { GET_OBJECT_TYPES } from './index'


export const requestGetObjectTypes = (payload) => ({
  type: GET_OBJECT_TYPES.REQUEST,
  payload,
})

export const successGetObjectTypes = (payload) => ({
  type: GET_OBJECT_TYPES.SUCCESS,
  payload,
})

export const errorGetObjectTypes = (payload) => ({
  type: GET_OBJECT_TYPES.ERROR,
  payload,
})
