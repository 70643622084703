import React, { useState, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import {
  TextFieldStyled,
  Label,
  FieldStyled,
  ControlsContainer,
} from './styles'

const TextAreaControl = ({
  icon,
  label,
  type,
  onChange,
  onBlur,
  name,
  disabled,
  fieldIcon,
  charLimit,
  controls: Controls,
  maxlength,
  ...ownProps
}) => {
  const [focused, setFocused] = useState(false)
  const [filled, setFilled] = useState(false)
  const Icon = useMemo(() => icon, [icon])

  const handleChange = (event) => {
    const { value } = event.target
    setFilled(!!value)
    if (!charLimit || value.length <= charLimit) {
      onChange(event)
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur(event)
  }

  return (
    <TextFieldStyled fieldIcon={fieldIcon} focused={focused} filled={filled}>
      <Label htmlFor={name}>
        {icon && <Icon />}
        {(label && !fieldIcon) && (<span>{label}</span>)}
      </Label>
      {Controls
      && (
        <ControlsContainer>
          {Controls}
        </ControlsContainer>
      )}
      <FieldStyled
        name={name}
        {...ownProps}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type}
      />
    </TextFieldStyled>
  )
}

TextAreaControl.defaultProps = {
  icon: null,
  label: null,
  type: 'text',
  disabled: false,
  fieldIcon: false,
  maxlength: 500,
  onBlur: noop,
  onChange: noop,
  charLimit: 256,
}

TextAreaControl.propTypes = {
  icon: pt.element,
  label: pt.oneOfType([pt.string, pt.element]),
  type: pt.string,
  name: pt.string.isRequired,
  disabled: pt.bool,
  fieldIcon: pt.bool,
  onChange: pt.func,
  onBlur: pt.func,
  charLimit: pt.number,
  maxlength: pt.number,
}

export default memoWithName(TextAreaControl)
