import { connect } from 'react-redux'
import { getLoading } from '@/store/selectors/maps/socketEvents'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'

export default connect(
  (state) => ({
    loading: getLoading(state),
  }),
  (dispatch) => ({
    requestGetMapObjects: () => dispatch(requestGetMapObjects()),
  }),
)(PageSidebar)
