import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .svgFill,
  .svgStroke {
    fill: ${({ theme }) => theme.colors.colors.disabled};
    height: 80px;
  }
`

export const Title = styled.div`
  margin-top: 10px;
  width:  100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.disabled};
  text-transform: uppercase;
`
