import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SwitchIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 25H1V1H25V13M10 10H16M10 10V16M10 10L25 25" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

SwitchIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SwitchIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SwitchIcon)
