import noop from 'lodash/noop'
import {
  ALL_PERIOD,
  YEAR,
  QUARTER,
  MONTH,
  WEEK,
  DAY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from '@/constants/tyumenOurHome'
import {
  START_DATE,
  END_DATE,
  PERIOD,
  INTERVAL,
} from '@/constants/forms/tyumenOurHomeFilter'

export default (formValues, value, formicForm, setUrlValues = noop) => {
  const todayDate = new Date()
  switch (value) {
    case ALL_PERIOD: {
      const filterValue = {
        ...formValues,
        [PERIOD]: ALL_PERIOD,
        [START_DATE]: null,
        [END_DATE]: null,
        [INTERVAL]: MONTHLY,
      }
      setUrlValues(filterValue)
      formicForm.current.setValues(filterValue)
      break
    }
    case DAY: {
      const filterValue = {
        ...formValues,
        [PERIOD]: DAY,
        [START_DATE]: todayDate,
        [END_DATE]: todayDate,
        [INTERVAL]: DAILY,
      }
      setUrlValues(filterValue)
      formicForm.current.setValues(filterValue)
      break
    }
    case WEEK: {
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate() - 7)
      const filterValue = {
        ...formValues,
        [PERIOD]: WEEK,
        [START_DATE]: dateFrom,
        [END_DATE]: todayDate,
        [INTERVAL]: DAILY,
      }
      setUrlValues(filterValue)
      formicForm.current.setValues(filterValue)
      break
    }
    case MONTH: {
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate() - 30)
      const filterValue = {
        ...formValues,
        [PERIOD]: MONTH,
        [START_DATE]: dateFrom,
        [END_DATE]: todayDate,
        [INTERVAL]: DAILY,
      }
      setUrlValues(filterValue)
      formicForm.current.setValues(filterValue)
      break
    }
    case QUARTER: {
      const neededQuarter = Math.ceil((todayDate.getMonth() + 1) / 3) - 1
      const filterValue = {
        ...formValues,
        [PERIOD]: QUARTER,
        [START_DATE]: null,
        [END_DATE]: null,
        [INTERVAL]: WEEKLY,
      }
      if (!neededQuarter) {
        const dateFrom = new Date(todayDate.getFullYear() - 1, 9)
        const dateBefore = new Date(todayDate.getFullYear() - 1, 12, 0)
        filterValue[START_DATE] = dateFrom
        filterValue[END_DATE] = dateBefore
      } else {
        const monthBefore = neededQuarter * 3
        const monthFrom = monthBefore - 2
        const dateFrom = new Date(todayDate.getFullYear(), monthFrom - 1)
        const dateBefore = new Date(todayDate.getFullYear(), monthBefore, 0)
        filterValue[START_DATE] = dateFrom
        filterValue[END_DATE] = dateBefore
      }
      setUrlValues(filterValue)
      formicForm.current.setValues(filterValue)
      break
    }
    case YEAR: {
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate() - 365)
      const filterValue = {
        ...formValues,
        [PERIOD]: YEAR,
        [START_DATE]: dateFrom,
        [END_DATE]: todayDate,
        [INTERVAL]: MONTHLY,
      }
      setUrlValues(filterValue)
      formicForm.current.setValues(filterValue)
      break
    }
    default: {
      return null
    }
  }
}
