import { all } from 'redux-saga/effects'
import createTenantRoot from './createTenant'
import updateTenantRoot from './updateTenant'
import deleteTenantRoot from './deleteTenant'
import getTenantRoot from './getTenant'
import getAllTenantsRoot from './getAllTenants'

export default function* root() {
  yield all([
    createTenantRoot(),
    updateTenantRoot(),
    deleteTenantRoot(),
    getTenantRoot(),
    getAllTenantsRoot(),
  ])
}
