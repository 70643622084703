import React, { useRef } from 'react'
import noop from 'lodash/noop'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import UsersIcons from '@/components/icons/users'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import {
  Container,
  Title,
  ButtonContainer,
  ContentContainer,
  Wrapper,
  StyledPortalTooltip,
} from './styles'

const DropDown = ({
  content,
  icon: Icon,
  title,
  isOpen,
  onClick,
  disabled,
  isActive,
}) => {
  const ref = useRef(null)
  useOutsideClickHandler({
    ref,
    callback: onClick,
    opened: isOpen,
  })
  return (
    <Wrapper ref={ref}>
      <Container
        isOpen={isOpen}
        onClick={disabled ? noop : onClick}
        disabled={disabled}
        active={isOpen}
      >
        <StyledPortalTooltip
          title={isOpen
            ? (<Lang id="tooltip.close" />)
            : (<Lang id="tooltip.open" />)}
          renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
            <>
              <Title>
                {title}
              </Title>
              <ButtonContainer
                isOpen={isOpen}
                ref={wrapperRef}
                isActive={isActive}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                {Icon && <UsersIcons.DownArrow />}
              </ButtonContainer>
            </>
          )}
        />
      </Container>
      {isOpen && (
        <ContentContainer>
          {content}
        </ContentContainer>
      )}
    </Wrapper>
  )
}

DropDown.propTypes = {
  content: pt.element,
  icon: pt.element,
  title: pt.oneOfType([
    pt.string,
    pt.element,
  ]),
  isOpen: pt.bool,
  onClick: pt.func,
  disabled: pt.bool,
  isActive: pt.bool,
}

DropDown.defaultProps = {
  isOpen: false,
  onClick: noop,
  content: null,
  title: '',
  icon: null,
  disabled: false,
  isActive: false,
}

export default DropDown
