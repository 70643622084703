import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import Loader from '@/components/blocks/Loader'
import TyumenOurHomeFilter from '@/forms/TyumenOurHomeFilter'
import Header from '../Header'
import NotificationsColumn from '../NotificationsColumn'
import {
  WidgetContainer,
  Container,
} from './styles'

const NOTIFICATTIONS_COLUMNS = [
  {
    title: 'В процессе решения',
    type: WARNING,
  },
  {
    title: 'Отказ или проблема не обнаружена',
    type: ERROR,
  },
  {
    title: 'Успешно решены',
    type: OK,
  },
]

const Treatment = ({
  data,
  selectedMessageId,
  messageData,
  isLoading,

  setSelectedElement,

  getMessage,
  getMessagesList,
}) => {
  const { search } = useLocation()
  const [selectedStatus] = useState(null)
  const parsedSearch = useMemo(() => qs.parse(search), [search])
  const getMessagesListRequest = useCallback(() => {
    getMessagesList(parsedSearch)
  }, [getMessagesList, parsedSearch])

  const onSelectNotification = useCallback((id) => {
    setSelectedElement(id)
    getMessage({ ...parsedSearch, id })
  }, [setSelectedElement, parsedSearch, getMessage])
  const onCloseNotifications = useCallback(() => {
    setSelectedElement(null)
  }, [setSelectedElement])

  const renderNotificationsColumns = () => {
    if (
      isLoading
      && Object.keys(data).every((key) => data[key].length === 0)
    ) {
      return <Loader center />
    }

    return NOTIFICATTIONS_COLUMNS.map((column) => {
      if (!selectedStatus || (selectedStatus && selectedStatus === column.type)) {
        return (
          <NotificationsColumn
            {...column}
            data={data[column.type]}
            onNotificationClick={onSelectNotification}
          />
        )
      }
      return null
    })
  }

  const renderNotificationMessage = () => {
    if (selectedMessageId) {
      if (isLoading && !messageData.key) {
        return <Loader center />
      }

      return (
        <NotificationsColumn
          large
          data={messageData}
          onClose={onCloseNotifications}
        />
      )
    }

    return null
  }

  return (
    <Container>
      <TyumenOurHomeFilter
        getMessagesListRequest={getMessagesListRequest}
        content={(
          <Header
            query={parsedSearch.query}
          />
        )}
      />

      <WidgetContainer>
        {renderNotificationsColumns()}
        {renderNotificationMessage()}
      </WidgetContainer>
    </Container>
  )
}

Treatment.propTypes = {
}
Treatment.defaultProps = {
}

export default Treatment
