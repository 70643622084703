import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import Loader from '@/components/blocks/Loader'
import { LoaderContainer } from './styles'

const Loading = () => (
  <LoaderContainer>
    <Loader />
    <Lang id="viewTree.loading" />
  </LoaderContainer>
)

export default Loading
