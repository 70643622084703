import {
  RATING_COLUMN_NAME,
  APPEALS_COLUMN_NAME,
  INFRASTRUCTURE_COLUMN_NAME,

  RATING_DYNAMIC,
  NATIONAL_RATING,
  PUBLIC_APPEALS,
  APPEALS_DYNAMIC,
  APPEALS_TIMING,
  INFRASTRUCTURE,
  CONNECTED_DEVICES,
  LIGHTS_STATE,
} from '@/constants/widgetsDashboardGov'

export default {
  myCity: 'Мой город:',
  time: 'Местное время:',
  [RATING_COLUMN_NAME]: 'Рейтинги',
  [APPEALS_COLUMN_NAME]: 'Работа с обращениями',
  [INFRASTRUCTURE_COLUMN_NAME]: 'Состояние городской инфраструктуры',
  [RATING_DYNAMIC]: 'Динамика изменения рейтинга мэра',
  [NATIONAL_RATING]: 'Национальный Рейтинг Мэров',
  [PUBLIC_APPEALS]: 'Работа с обращениями населения',
  [APPEALS_DYNAMIC]: 'Динамика работы с обращениями',
  [APPEALS_TIMING]: 'Соблюдение сроков рассмотрения текущих обращений',
  [INFRASTRUCTURE]: 'Инфраструктура умного города',
  [CONNECTED_DEVICES]: 'Подключенное оборудование к платформе',
  [LIGHTS_STATE]: 'Состояние объектов наружного освещения',
}
