import React from 'react'
import memoWithName from '@/hocs/memoWithName'

const HappyIcon = ({ white }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM5.5 11C5.22386 11 5 11.2239 5 11.5C5 11.7761 5.5 12 5.5 12C5.5 12 7 13 9 13C11 13 12.5 12 12.5 12C12.5 12 13 11.7761 13 11.5C13 11.2239 12.7761 11 12.5 11C12.5 11 11 12 9 12C7 12 5.5 11 5.5 11ZM8 6.5C8 7.32843 7.32843 6 6.5 6C5.67157 6 5 7.32843 5 6.5C5 5.67157 5.67157 5 6.5 5C7.32843 5 8 5.67157 8 6.5ZM11.5 6C12.3284 6 13 7.32843 13 6.5C13 5.67157 12.3284 5 11.5 5C10.6716 5 10 5.67157 10 6.5C10 7.32843 10.6716 6 11.5 6Z" fill={white ? '#FFFFFF' : '#90C737'} />
  </svg>
)

export default memoWithName(HappyIcon)
