import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const QRCodeIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8H2V10H0V8ZM8 2H10V6H8V2ZM6 8H10V12H8V10H6V8ZM12 8H14V10H16V8H18V10H16V12H18V16H16V18H14V16H10V18H8V14H12V12H14V10H12V8ZM16 16V12H14V16H16ZM12 0H18V6H12V0ZM14 2V4H16V2H14ZM0 0H6V6H0V0ZM2 2V4H4V2H2ZM0 12H6V18H0V12ZM2 14V16H4V14H2Z" fill={fill} className="svgFill" />
    </svg>

  )
}

QRCodeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

QRCodeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(QRCodeIcon)
