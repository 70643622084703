import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WidgetsDashboard = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.226 11.0116L35.3822 7.38751C31.7231 6.01622 28.2927 5.33058 25.091 5.33058C18.4262 5.33058 12.7089 7.48546 7.93895 11.7952C3.16905 16.105 0.522729 21.6228 0 28.3486C0 28.871 0.196024 29.3445 0.588071 29.7689C0.980118 30.1933 1.40484 30.4056 1.86222 30.4056H2.25427C2.777 30.4056 3.23439 30.226 3.62644 29.8669C4.01848 29.5077 4.21451 29.0669 4.21451 28.5445C4.67189 22.8635 6.87716 18.2762 10.8303 14.7827C14.7834 11.2892 19.537 9.54239 25.091 9.54239C28.2927 9.54239 31.0044 10.0321 33.226 11.0116ZM42.8311 19.1414C44.5954 22.0799 45.5755 25.2143 45.7715 28.5445C45.7715 29.0016 45.9512 29.4261 46.3106 29.8179C46.6699 30.2097 47.111 30.4056 47.6337 30.4056H48.1238C48.6465 30.4056 49.1039 30.177 49.4959 29.7199C49.888 29.2628 50.0513 28.8057 49.986 28.3486C49.594 23.4512 47.9931 19.0435 45.1834 15.1255L42.8311 19.1414ZM43.9093 5.33058C42.7985 4.74288 41.8184 4.93878 40.9689 5.91827L38.9107 9.3465L36.7544 12.8727L27.3453 28.7404C26.5612 28.4792 25.8098 28.3486 25.091 28.3486C22.8041 28.3486 20.8438 29.1649 19.2103 30.7974C17.5768 32.4299 16.76 34.3888 16.76 36.6743C16.76 38.9598 17.5768 40.9188 19.2103 42.5513C20.8438 44.1838 22.8041 45 25.091 45C27.378 45 29.3382 44.1838 30.9717 42.5513C32.6052 40.9188 33.422 38.9598 33.422 36.6743C33.422 34.1929 32.6052 32.234 30.9717 30.7974L40.1848 15.6152L42.2431 11.9911L44.4973 8.26905C45.3468 6.96307 45.1508 5.98357 43.9093 5.33058ZM25.091 40.8861C23.9802 40.8861 23.0001 40.4617 22.1507 39.6128C21.3012 38.7639 20.8765 37.7844 20.8765 36.6743C20.8765 35.5642 21.3012 34.5847 22.1507 33.7358C23.0001 32.887 23.9802 32.4625 25.091 32.4625C26.2018 32.4625 27.1819 32.887 28.0314 33.7358C28.8808 34.5847 29.3055 35.5642 29.3055 36.6743C29.3055 37.7844 28.8808 38.7639 28.0314 39.6128C27.1819 40.4617 26.2018 40.8861 25.091 40.8861Z" fill={fill} className="svgFill" />
    </svg>
  )
}

WidgetsDashboard.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WidgetsDashboard.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WidgetsDashboard)
