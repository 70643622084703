import styled from 'styled-components'

export const Container = styled.div`
  background-color: #F4F4F4;
  min-height: calc(100vh - 48px);
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  border-bottom: 1px solid #BDC3C7;
  padding: 0 10px;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;

  i { margin-right: 5px; }
  svg {
    height: 70px;
    width: auto;
    margin-left: -10px;
  }
`

export const CityName = styled(Title)`
  font-weight: 600;
`

export const TimeTitle = styled(Title)`
  font-weight: 300;
`

export const Time = styled(Title)`
  color: #00639A;
  font-weight: 400;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: 48px repeat(3, 1fr);
  gap: 10px 10px;
  grid-template-areas:
    "t1 t2 t3"
    "w11 w21 w31"
    "w12 w22 w32"
    "w13 w23 w33";
  margin: 10px;
`
