import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import { getIsReadByStatus } from '@/helpers/alarmsHelper'
import {
  GET_ALARM_MANAGER_TABLE,

  ALARM_MANAGER_TABLE,
  SET_STATUS_TYPES,
  SET_EVENTS_TYPES,
} from '@/store/actions/alarmManager'
import { ALARM_MANAGER_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successAlarmManagerTable,
  errorAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import {
  requestTableStatistics,
  successTableStatistics,
} from '@/store/actions/alarmManager/getAvarage'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getEventTypes,
  getEventStatus,
  getStartDate,
  getEndDate,
  getTableParameters,
} from '@/store/selectors/alarmManager'
import { LIGHT } from '@/constants/objectTypes'

function* getAlarmManagerTableSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.isHasInstallations && !node.internalId) {
      yield put(successAlarmManagerTable({
        data: [],
        page: 1,
        resultCount: 0,
      }))
      yield put(successTableStatistics({
        [OK]: 0,
        [WARNING]: 0,
        [ERROR]: 0,
      }))
      return null
    }
    const tableParameters = yield select(getTableParameters)

    const eventTypes = yield select(getEventTypes)
    const eventStatus = yield select(getEventStatus)

    const startDate = yield select(getStartDate)
    const endDate = yield select(getEndDate)

    const response = yield request({
      url: ALARM_MANAGER_TABLE_ENDPOINT,
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        installationId: (node && node.internalId) ? `${node.internalId}` : null,
        objectId: (node && node.type === LIGHT) ? `${node.id}` : null,
        range: {
          start: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
          end: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
        },
        isRead: getIsReadByStatus(eventStatus),
        alertTypes: eventTypes,
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
    })

    const {
      content,
      totalElements,
    } = response.data
    yield put(successAlarmManagerTable({
      data: content.map((row) => ({
        ...row,
        id: row.eventId,
      })),
      totalElements,
    }))
    yield put(requestTableStatistics())
  } catch (error) {
    yield put(errorAlarmManagerTable(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_ALARM_MANAGER_TABLE.REQUEST, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.SORT, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.PER_PAGE, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.PAGE, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.SELECTED_FIELDS, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.REQUEST_QUERY, getAlarmManagerTableSaga)
  yield takeLatest(SET_STATUS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_EVENTS_TYPES, getAlarmManagerTableSaga)
}
