import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import hash from 'hash-sum'
import Loader from '@/components/blocks/Loader'
import { getIsNotRegion, getZoomByType } from '@/helpers/maps'
import { getNodePath } from '@/helpers/viewTree/formaters'
import { icons, filterTypes } from '@/constants/maps'
import {
  tree as treeMash,
  pins as pinsMash,
} from '@/data/mapData'
import { mash } from '@/data/mapTree/mashTreeData'
import TextControl from '@/components/controls/TextControl'
import YandexMap from '@/components/blocks/YandexMap'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import UsersIcons from '@/components/icons/users'
import MapIcons from '@/components/icons/maps'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import { toFlat } from '@/helpers/getZonesMock'
import ObjectPassportization from './components/ObjectPassportization'

import {
  Main,
  MapControls,
  MapControlsContent,
  TitleAndIcon,
} from './styles'
import { MAP_SERVICE } from '@/constants/applications';

const DEFAULT_CENTER = [56.0360129, 35.9583358]

class Maps extends React.PureComponent {
  handleChange = debounce((value) => {
    this.setState({ search: value })
  }, DEBOUNCE_DELAY_SMALL)

  constructor(props) {
    super(props)
    const {
      treeData, regions, pins, selectedNode, userApplications
    } = props
    this.state = {
      mapZoom: (selectedNode && selectedNode.aliasId) ? 19 : 3,
      mapCenter: selectedNode.location || DEFAULT_CENTER,
      search: '',
      hoverZoneByID: null,
      hoveredItemByID: null,
      passportIsOpen: null,
      typesOfRegionPins: filterTypes,
      treeStructureData: cloneDeep(treeData),
      dataSet: { regions, pins },
      isMashMode: false,
      typesWithoutPassportization: [],
      selectedZoneIdentifier: null,
      userApplications: userApplications
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (!isEqual(props.pins, state.dataSet.pins) && !state.isMashMode) {
      return {
        treeStructureData: props.treeData,
        dataSet: { ...state.dataSet, pins: props.pins },
      }
    }
    return null
  }

  // TODO: resolve problem with request before connect
  componentDidMount() {
    const {
      requestGetMapObjects, selectedNode, systems, refreshIntegration,
    } = this.props
    const integrations = Object.keys(systems).reduce((accumulator, element) => {
      const system = systems[element] || {}
      if (isEmpty(system)) {
        return accumulator
      }
      return [
        ...accumulator,
        ...system.children || [],
      ]
    }, [])
    if (integrations.length !== 0) {
      integrations.map((element) => {
        refreshIntegration({ id: element.id, ghost: true })
        return element
      })
    }
    const zoneId = selectedNode.geoZoneId || selectedNode.id
    this.setState({ selectedZoneIdentifier: zoneId })
    requestGetMapObjects()
  }

  handleMashToggle = () => {
    const { isMashMode, dataSet } = this.state
    const updatedValue = !isMashMode
    const data = updatedValue
      ? {
        treeStructureData: cloneDeep(treeMash),
        dataSet: { ...dataSet, pins: pinsMash },
      }
      : {}
    this.setState((state) => ({
      isMashMode: !state.isMashMode,
      ...data,
    }))
  }

  getObjectTelemetry = (object) => {
    const { telemetry } = this.props
    return telemetry[`${object.type}-${object.aliasId}-${object.id}`] || {}
  }

  getSelectedNodeWithTelemetry = () => {
    const { selectedNode } = this.props
    const telemetry = this.getObjectTelemetry(selectedNode)

    return {
      ...selectedNode,
      status: telemetry.status || selectedNode.status,
      // telemetry,
    }
  }

  setSelectedZoneIdentifier = (id) => {
    this.setState(() => ({
      selectedZoneIdentifier: id,
    }))
  }

  getIDOfSelectedZone = (element) => () => {
    this.setState(() => ({
      mapCenter: element.location,
      mapZoom: getZoomByType(element.type),
    }))
  }

  setMapCenterForLocationZoom = (element) => () => {
    this.setState(() => ({
      mapCenter: element.location,
      mapZoom: getZoomByType(element.type),
    }))
  }

  setIdOfSelectedPinOnMouseEnter = (element) => {
    this.setState({ hoveredItemByID: element.id })
  }

  setIdOfSelectedPinOnMouseLeave = () => {
    this.setState({ hoveredItemByID: null })
  }

  listItemMapMouseEnter = (element) => {
    this.setState({ hoveredItemByID: element.id })
  }

  listItemMapMouseLeave = () => {
    this.setState({ hoveredItemByID: null })
  }

  treeZoneMouseEnter = (element) => {
    this.setState({ hoverZoneByID: element.id })
  }

  treeZoneMouseLeave = () => {
    this.setState({ hoverZoneByID: null })
  }

  setMapCenterForItem = (item) => () => {
    this.setState({
      mapCenter: item.location,
    })
  }

  selectNodeByZone = (node) => {
    const { newZoom, newCenter } = node
    const { treeStructureData } = this.state
    const { setNode } = this.props
    const flatTree = toFlat(treeStructureData) || []
    const selectedNodes = flatTree
      .filter((element) => element.name === node.name || element.geoZoneId === node.pinSelector)
    const selectedNode = selectedNodes[0] || {}
    setNode(selectedNode, true)
    this.setState(() => ({
      mapCenter: newCenter || (selectedNode || {}).location || [60, 90],
      mapZoom: newZoom || getZoomByType((selectedNode || {}).type) || 8,
      passportIsOpen: true,
    }))
  }

  getSelectedNode = () => {
    const { treeData, selectedNode } = this.props

    return get(treeData, getNodePath(selectedNode), {})
  }

  updateMapZoom = (zoom) => {
    this.setState(() => ({ mapZoom: zoom }))
  }

  handleOpenPassport = (node, fromTree) => {
    const { setNode, requestObjectImage } = this.props
    const { hoveredItemByID } = this.state
    const zoneId = node.geoZoneId || node.id
    this.setState(() => ({
      selectedZoneIdentifier: zoneId,
      passportIsOpen: true,
      hoveredItemByID: getIsNotRegion(node) ? node.id : hoveredItemByID,
    }))
    if (fromTree) {
      setNode(node)
      requestObjectImage({ node })
      return null
    }
    setNode(node, true)
    requestObjectImage({ node })
  }

  getIsPassportOpen = () => {
    const { typesWithoutPassportization, passportIsOpen } = this.state
    const { selectedNode } = this.props
    if (
      selectedNode
      && passportIsOpen !== false
      && selectedNode.id
      && typesWithoutPassportization
        .findIndex((type) => type === selectedNode.type) === -1
    ) {
      return true
    }
    return false
  }

  handleClosePassport = () => {
    this.setState({
      passportIsOpen: false,
    })
  }

  setPassportIsOpen = () => {
    this.setState({ passportIsOpen: false })
  }

  viewTreeOnSelect = (node, actionType) => {
    if (actionType === 'click') {
      this.setMapCenterForLocationZoom(node)()
      this.handleOpenPassport(node, true)
      this.getIDOfSelectedZone(node)()
    }
    if (actionType === 'in') {
      return getIsNotRegion(node.type)
        ? this.listItemMapMouseEnter(node)
        : this.treeZoneMouseEnter(node)
    }
    if (actionType === 'out') {
      return getIsNotRegion(node.type)
        ? this.listItemMapMouseLeave(node)
        : this.treeZoneMouseLeave(node)
    }
    return null
  }

  renderIconAndTitle = (iconName = null, size = 'big', title = null, onClick = noop) => {
    const Icon = icons[iconName]
    return (
      <TitleAndIcon onClick={onClick} size={size}>
        {Icon && <Icon />}
        {title}
      </TitleAndIcon>
    )
  }

  render() {
    const {
      search,
      dataSet,
      mapCenter,
      mapZoom,
      hoveredItemByID,
      hoverZoneByID,
      typesOfRegionPins,
      treeStructureData,
      isMashMode,
      selectedZoneIdentifier,
      userApplications
    } = this.state
    const isMapHasPins = (dataSet && dataSet.pins).some((pin) => !!pin.id)
    const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0].settings
    const mapKey = (dataSet ? dataSet.pins : []).length
    const {
      treeData,
      telemetry,
      loading,
      zones,
      refreshIntegration,
      pinnedNode,
      objectImage,
    } = this.props

    if (loading) return <Loader center />
    return (
      <Main>
        <PageSidebar
          onIconClick={this.switchFilterMode}
          title={<Lang id={isMashMode ? "mapsPage.secondTitle" : "mapsPage.title"}/>}
          typesOfRegionPins={typesOfRegionPins}
          treeData={isMashMode ? treeStructureData : treeData}
          getTelemetry={this.getObjectTelemetry}
          mapKey={mapKey}
          telemetryHash={hash(telemetry)}
          searchQuery={search}
          onSelect={this.viewTreeOnSelect}
          renderFilterItem={this.renderFilterItem}
          headerIcon={isMashMode ? MapIcons.PinIcon : MapIcons.MeshIcon}
          headerIconDescription={<Lang id={!isMashMode ? "mapsPage.secondTitle" : "mapsPage.titles.newObject"}/>}
          onHeaderIconClick={this.handleMashToggle}
          headerContent={(
            <Lang id="mapsPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={this.handleChange}
                />
              )}
            </Lang>
          )}
        />
        <MapControls>
          <MapControlsContent>
            {this.getIsPassportOpen() && (
              <ObjectPassportization
                onClose={this.handleClosePassport}
                node={this.getSelectedNodeWithTelemetry()}
                telemetry={telemetry}
                refreshIntegration={refreshIntegration}
                image={objectImage}
              />
            )}
            {isMapHasPins && (
              <YandexMap
                pinnedNode={pinnedNode}
                globalZoneId={selectedZoneIdentifier}
                setSelectedZoneIdentifier={this.setSelectedZoneIdentifier}
                zones={zones}
                key={mapKey}
                onClick={this.handleClosePassport}
                dataSet={dataSet}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                mash={isMashMode ? mash : []}
                isMashMode={isMashMode}
                hoveredPinByID={hoveredItemByID}
                hoveredZoneByID={hoverZoneByID}
                getTelemetry={this.getObjectTelemetry}
                updateMapZoom={this.updateMapZoom}
                handleOpenPassport={this.handleOpenPassport}
                pinOnEnterHandler={this.setIdOfSelectedPinOnMouseEnter}
                pinOnLeaveHandler={this.setIdOfSelectedPinOnMouseLeave}
                selectNodeByZone={this.selectNodeByZone}
                yandexKey={yandexKey}
              />
            )}
          </MapControlsContent>
        </MapControls>
      </Main>
    )
  }
}

Maps.propTypes = {
  systems: pt.objectOf(pt.object),
  loading: pt.bool.isRequired,
  objectImage: pt.string.isRequired,
  requestGetMapObjects: pt.func.isRequired,
  setNode: pt.func.isRequired,
  refreshIntegration: pt.func.isRequired,
  requestObjectImage: pt.func.isRequired,
  regions: pt.arrayOf(pt.object).isRequired,
  telemetry: pt.objectOf(pt.object).isRequired,
  pins: pt.arrayOf(pt.object).isRequired,
  treeData: pt.shape({
    id: pt.number,
  }).isRequired,
  selectedNode: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    location: pt.arrayOf(pt.number),
    type: pt.string,
    status: pt.string,
  }).isRequired,
  userApplications: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    code: pt.string,
    defaultType: pt.string,
    name: pt.string,
    placement: pt.string,
    settings: pt.string,
    type: pt.string,
  })),
}

export default Maps
