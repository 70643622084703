import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  GeoObject,
  Polyline,
  Circle,
  Polygon,
  Clusterer,
} from 'react-yandex-maps'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { ThemeContext } from 'styled-components'
import memoWithName from '@/hocs/memoWithName'
import getUniqueIdOfYmap from '@/helpers/maps/getUnicIdOfYmap'
import { injectIntl } from 'react-intl'
import {
  clusterOptions,
  mapZoomBreakPoints,
  YMSP_Z_INDEX,
  gridSize,
} from '@/constants/maps'
import {
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
  UNKNOWN,
} from '@/constants/objectStatuses'
import { CITY } from '@/constants/objectTypes'
import { DEBOUNCE_DELAY_MEDIUM } from '@/constants/time'
import renderJSXToString from '@/helpers/renderJSXToString'
import { defaultZoneOptions } from '@/constants/styles/mapsMarks'
import pinImage from '@/assets/icons/maps/pinBack.png'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import { getZoneStatistic, getObjectStatistic } from '@/helpers/dataFormator'
import { StyledYMaps, StyledMap } from './styles'
import PinIcon from './components/PinIcon'
import PinIconHover from './components/PinIconHover'
import MeshWithStatsHover from './components/MeshWithStatsHover'
import ControllerIcon from './components/ControlerIcon'
import ControllerIconHover from './components/ControlerIconHover'
import ZoneIcon from './components/ZoneIcon'
import ZoneIconHover from './components/ZoneIconHover'
import PinIconSelectedByController from './components/PinIconSelectedByControler'
import ClusterIcon from './components/ClusterIcon'
import ClusterIconHover from './components/ClusterIconHover'
import {
  isController,
} from './utlis'

const returnZoneSize = (element) => {
  if (element.count <= 99) {
    return [40, 40]
  }
  if (element.count > 99 && element.count <= 999) {
    return [60, 60]
  }
  if (element.count > 999) {
    return [80, 80]
  }
  return [40, 40]
}
const returnZoneOffset = (element) => {
  if (element.count <= 99) {
    return [-20, -20]
  }
  if (element.count > 99 && element.count <= 999) {
    return [-30, -30]
  }
  if (element.count > 999) {
    return [-40, -40]
  }
  return [-20, -20]
}

const ALLOW_PIN_SELECTED_BY_CONTROLLER = true

const YandexMap = (props) => {
  const {
    defaultState,
    clustersOptions,
    dataSet,
    mapCenter,
    hoveredPinByID,
    mapZoom,
    updateMapCenter,
    updateMapZoom,
    handleOpenPassport,
    onClick,
    pinOnEnterHandler,
    pinOnLeaveHandler,
    mash,
    getTelemetry,
    globalZoneId,
    setSelectedZoneIdentifier,
    selectNodeByZone,
    zones,
    isMashMode,
    pinnedNode,

    createPoint,
    pointsArray,
    displayElements,
    createElementType,
    isDisplayElements,
    formRef,
    intl,
    yandexKey
  } = props
  const theme = useContext(ThemeContext)
  const { pins } = dataSet
  const [selectedZoneById, setSelectedZoneById] = useState(null)
  const [selectedControllerId, setSelectedControllerId] = useState(null)

  useEffect(() => {
    setSelectedControllerId(hoveredPinByID)
  }, [hoveredPinByID])

  const [activeMash, setActiveMash] = useState(0)
  // ## CODE RELATED TO MAP AND CLUSTERRER INSTANSE ##

  const [map, setMap] = useState(null)
  const [clusterer, setClusterer] = useState(null)
  const [mapSessionId, setMapSessionId] = useState(null)
  const [clusterIdentifier, setClusterIdentifier] = useState(null)
  const [isOnResizeEventWasAdd, setIsOnResizeEventWasAdd] = useState(false)
  const [isCreateNewPointWasAdd, setIsCreateNewPointWasAdd] = useState(false)
  const [mapGridSize, setMapGridSize] = useState(gridSize.small)

  useEffect(() => {
    if (mash.length > 0) {
      const interval = setInterval(() => {
        const mashIndex = activeMash < mash.length - 1
          ? (activeMash + 1)
          : 0
        setActiveMash(mashIndex)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [mash, activeMash, setActiveMash])

  const displayedZonesWithTelemetry = useMemo(() => {
    const arrayOfDisableZonesIds = Object.keys(zones)
      .filter((elementKey) => elementKey !== globalZoneId && elementKey !== 1)
    const disableZones = arrayOfDisableZonesIds.map((element) => zones[element])
    return disableZones
      .map((element) => getZoneStatistic(element, pins, getTelemetry))
  }, [globalZoneId, pins, getTelemetry, zones])

  const displayedPins = useMemo(() => {
    if (!globalZoneId || !(zones[globalZoneId] || {}).pinSelector || globalZoneId === 1) {
      return []
    }
    if (isMashMode && mash.length !== 0) {
      return pins
    }
    const selector = (zones[globalZoneId] || {}).pinSelector || null
    const selectedPinsByZoneSelector = pins.filter((element) => element.geoZoneId === selector)
    return selectedPinsByZoneSelector.map((element) => ({
      ...element,
      ...getTelemetry(element),
      location: element.location,
      name: element.name,
    }))
  }, [globalZoneId, pins, mash, getTelemetry, zones, isMashMode])

  const displayedZonesBorders = useMemo(() => {
    const selectedZoneIds = []
    if (selectedZoneById) {
      selectedZoneIds.push(selectedZoneById)
    }
    return selectedZoneIds.map((elementId) => zones[elementId])
  }, [zones, selectedZoneById])

  const selectZoneHandler = (zone) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedZoneIdentifier(zone.id)
    selectNodeByZone(zone)
    setSelectedZoneById(null)
  }

  const zoneOnEnterHandler = (zone) => () => {
    setSelectedZoneById(zone.id)
  }
  const zoneOnLeaveHandler = () => () => {
    setSelectedZoneById(null)
  }

  const deselectSelectedZone = () => {
    setSelectedZoneById(null)
  }

  const hoverSelectedPins = (element, noHover = false) => {
    const activeElementId = hoveredPinByID || selectedZoneById || selectedControllerId || pinnedNode.id
    if (element.mash && element.id === hoveredPinByID) {
      return MeshWithStatsHover
    }
    if (element.mash) {
      return PinIcon
    }
    if (
      (((element.parentId === activeElementId || element.parentTreeId === activeElementId)
      && element.type !== UNKNOWN) && ALLOW_PIN_SELECTED_BY_CONTROLLER && activeElementId)
      && !createPoint
    ) {
      return PinIconSelectedByController
    }
    if (
      isController(element.type)
      && (
        element.id === activeElementId
        || element.id === activeElementId
      ) && !createPoint
    ) {
      return ControllerIconHover
    }
    if (isController(element.type)) {
      return ControllerIcon
    }
    if (
      (activeElementId === element.id
      || element.id === activeElementId)
      && !noHover && !createPoint
    ) {
      return PinIconHover
    }
    return PinIcon
  }

  const pinOnEnter = (element) => (event) => {
    event.get('target').options
      .set('zIndex', YMSP_Z_INDEX.geoObject.large)
    event.get('target').options
      .set('zIndexHover', YMSP_Z_INDEX.geoObject.large)
    if (element.statistic && isController(element.type)) {
      setSelectedControllerId(element.id)
      pinOnEnterHandler(element)
      event.get('target').properties
        .set('iconContent', renderJSXToString(ControllerIconHover, element, theme, intl))
      return null
    }
    pinOnEnterHandler(element)
    return null
  }

  const pinOnLeave = (element) => (event) => {
    event.get('target').options
      .set('zIndex', YMSP_Z_INDEX.geoObject.small)
    event.get('target').options
      .set('zIndexHover', YMSP_Z_INDEX.geoObject.small)
    if (element.statistic && isController(element.type)) {
      setSelectedControllerId(null)
      event.get('target').properties
        .set('iconContent', renderJSXToString(ControllerIcon, element, theme, intl))
      pinOnLeaveHandler(element)
      return null
    }
    pinOnLeaveHandler(element)
    return null
  }

  const pinOnClick = (node) => () => {
    handleOpenPassport(node)
  }

  const isSelectedPin = ((element) => element.id === hoveredPinByID
    || element.id === selectedZoneById
    || element.id === selectedControllerId
    || element.id === pinnedNode.id
  )

  const clusterIconContentLayout = (children) => {
    if (!map) {
      return null
    }
    return map.templateLayoutFactory.createClass(renderJSXToString(ClusterIcon, children, theme, intl))
  }

  const clusterHoverIconContentLayout = (children) => {
    if (!map) {
      return null
    }
    return map.templateLayoutFactory.createClass(renderJSXToString(
      ClusterIconHover, children, theme, intl,
    ))
  }

  // ## CODE RELATED TO MAP AND CLUSTERRER INSTANSE ##

  const getClusterGridSize = (yandexMapZoom) => {
    if (yandexMapZoom > mapZoomBreakPoints.large && yandexMapZoom <= mapZoomBreakPoints.max) {
      return gridSize.small
    }
    if (yandexMapZoom > mapZoomBreakPoints.big && yandexMapZoom <= mapZoomBreakPoints.large) {
      return gridSize.middle
    }
    if (yandexMapZoom > mapZoomBreakPoints.middle && yandexMapZoom <= mapZoomBreakPoints.big) {
      return gridSize.big
    }
    if (yandexMapZoom > mapZoomBreakPoints.small && yandexMapZoom <= mapZoomBreakPoints.middle) {
      return gridSize.large
    }
    if (yandexMapZoom > mapZoomBreakPoints.small) {
      return gridSize.huge
    }
    return gridSize.huge
  }

  const updateMapZoomHandler = (newZoom, oldZoom, newCenter, selectedZoneId) => {
    if (newZoom <= mapZoomBreakPoints.big && selectedZoneId !== 1 && newZoom < oldZoom) {
      const node = zones[1] || { id: 1 }
      setSelectedZoneIdentifier(node.id)
      selectNodeByZone({ ...node, newZoom: newZoom + 1, newCenter })
      setSelectedZoneById(null)
    }
    setMapGridSize(getClusterGridSize(newZoom))
    updateMapZoom(newZoom)
    updateMapCenter(newCenter)
  }

  const selectZoneByHandler = (
    newZoom,
    oldZoom,
    center,
    bounds,
    displayZones,
    selectedZoneId,
  ) => {
    if (
      displayZones
      && newZoom
      && center
      && bounds
      && newZoom >= mapZoomBreakPoints.big
      && selectedZoneId === 1
      && newZoom > oldZoom
    ) {
      const [leftBottom, topRight] = bounds
      const [bottom, left] = leftBottom
      const [top, right] = topRight
      const [centerY, centerX] = topRight
      const pinsInFrame = displayZones.filter((element) => {
        const [y, x] = element.location
        if (
          y >= left
          && y <= right
          && x >= bottom
          && x <= top
        ) {
          return false
        }
        return true
      })
      const closestZone = pinsInFrame.reduce((accumulator, element) => {
        const [y, x] = element.location
        const deltaY = Math.abs(centerY - y)
        const deltaX = Math.abs(centerX - x)
        const delta = deltaY + deltaX
        if (delta > accumulator.delta) {
          return accumulator
        }
        return {
          ...element,
          delta,
        }
      }, {})
      setSelectedZoneIdentifier(closestZone.id)
      selectNodeByZone({ ...closestZone, newZoom: newZoom - 1, center })
      setSelectedZoneById(null)
    }
  }

  const debounceZoom = debounce(updateMapZoomHandler, DEBOUNCE_DELAY_MEDIUM)
  const debounceZone = debounce(selectZoneByHandler, DEBOUNCE_DELAY_MEDIUM)
  const changeBoundsHandler = function (event) {
    debounceZoom(
      event.originalEvent.newZoom,
      event.originalEvent.oldZoom,
      event.originalEvent.newCenter,
      get(event, 'originalEvent.map.state.zoneId', 1),
    )
    debounceZone(
      event.originalEvent.newZoom,
      event.originalEvent.oldZoom,
      event.originalEvent.newCenter,
      event.originalEvent.newBounds,
      get(event, 'originalEvent.map.state.displayZones', []),
      get(event, 'originalEvent.map.state.zoneId', 1),
    )
    if (clusterer) {
      const clusters = clusterer.getClusters()
      const keys = Object.keys(clusters)
      keys.forEach((element) => {
        clusters[element].options
          .set(
            'clusterIconContentLayout',
            clusterIconContentLayout(clusters[element].properties.get('geoObjects')),
          )
      })
    }
  }

  const setCustomFieldOnGeoObject = (element) => (ref) => {
    if (ref) {
      // eslint-disable-next-line
      ref.objectData = element
    }
  }
  const clusterOnEnter = (event) => {
    const selectedCluster = event.get('target')
    setClusterIdentifier(selectedCluster[mapSessionId])
    let children
    try {
      children = selectedCluster.properties.get('geoObjects')
    } catch {
      return null
    }
    selectedCluster.options.set('clusterIconContentLayout', clusterHoverIconContentLayout(children))
    return null
  }

  const onCreatePointHandler = (event) => {
    const form = get(event, 'originalEvent.map.state.formRef.current', {})
    const localCreateElementType = get(event, 'originalEvent.map.state.createElementType', {})
    // const localIsAllowCreatePoint = get(event, 'originalEvent.map.state.createPoint', false)
    // temporarily removed
    // if (!localIsAllowCreatePoint) {
    //   return null
    // }
    const coordinates = event.get('coords')
    const lat = coordinates[0].toFixed(6)
    const lon = coordinates[1].toFixed(6)
    const setFieldValue = get(form, 'setFieldValue', noop)
    if (localCreateElementType === 'city') {
      setFieldValue(CREATE_REGION_NAMES.LATITUDE, lat)
      setFieldValue(CREATE_REGION_NAMES.LONGITUDE, lon)
    } else {
      setFieldValue('LOCATION.LATITUDE', lat)
      setFieldValue('LOCATION.LONGITUDE', lon)
    }
  }
  const clusterOnLeave = (event) => {
    const selectedCluster = event.get('target')
    setClusterIdentifier(null)
    let children
    try {
      children = selectedCluster.properties.get('geoObjects')
    } catch {
      return null
    }
    selectedCluster.options.set('clusterIconContentLayout', clusterIconContentLayout(children))
    return null
  }

  const clusterOnClick = (event) => {
    const clusterObject = event.get('target')
    const yMap = clusterObject.getMap()
    if (clusterObject.getBounds) {
      yMap.setBounds(clusterObject.getBounds())
    }
  }
  const clusterContentIconOnInit = (ref) => {
    if (!ref) {
      return
    }
    const clusters = ref.getClusters()
    const keys = Object.keys(clusters)
    keys.forEach((element) => {
      if (clusters[element][mapSessionId] === clusterIdentifier) {
        clusters[element].options.set(
          'clusterIconContentLayout',
          clusterHoverIconContentLayout(clusters[element].properties.get('geoObjects')),
        )
      } else {
        clusters[element].options.set(
          'clusterIconContentLayout',
          clusterIconContentLayout(clusters[element].properties.get('geoObjects')),
        )
      }
    })
  }

  const clusterInit = (ref) => {
    if (ref) {
      setClusterer(ref)
      map.ready({
        require: ['geoObject.addon.hint'],
        successCallback: () => {
          clusterContentIconOnInit(ref)
        },
      })
      ref.events.add('optionschange', clusterContentIconOnInit(ref))
    }
    return null
  }

  const mapInitListeners = (ref) => {
    if (ref) {
      // eslint-disable-next-line
      ref.state.createElementType = createElementType
      ref.state.createPoint = createPoint
      ref.state.formRef = formRef
      ref.state.zoneId = globalZoneId
      ref.state.displayZones = displayedZonesWithTelemetry
      if (!mapSessionId) {
        setMapSessionId(getUniqueIdOfYmap(ref))
      }
      const clusterrer = ref.geoObjects.get(4)
      if (clusterrer && typeof clusterrer.getGeoObjects === 'function') {
        // TODO: check this line. Has problems with `getGeoObjects`
        const data = clusterrer.getGeoObjects()
        if (data.length > 0) {
          data.forEach((element) => {
            const IDs = element.properties.get('population')
            // const elementArr = displayedPins.filter((el) => el.id === IDs)[0]
            // eslint-disable-next-line
            element.objectData = displayedPins.filter((el) => el.id === IDs)[0]
          })
        }
      }
      if (ref && !isOnResizeEventWasAdd) {
        ref.events.add('click', onClick)
        ref.events.add(
          'boundschange',
          changeBoundsHandler,
          { zoneId: globalZoneId, displayZones: displayedZonesWithTelemetry },
        )
        setIsOnResizeEventWasAdd(true)
      }
      if (ref && createPoint && !isCreateNewPointWasAdd) {
        ref.events.add('click', onCreatePointHandler)
        setIsCreateNewPointWasAdd(true)
      }
      if (!createPoint) {
        ref.cursors.push('grab')
      } else {
        ref.cursors.push('crosshair')
      }
      return null
    }
  }

  const createTemplateLayoutFactory = (ymaps) => {
    setMap(ymaps)
  }

  return (
    <StyledYMaps query={{ apikey: yandexKey }}>
      <StyledMap
        onLoad={createTemplateLayoutFactory}
        state={{ center: mapCenter, zoom: mapZoom }}
        defaultState={defaultState}
        modules={[
          'layout.ImageWithContent',
          'borders',
          'templateLayoutFactory',
          'geoObject.addon.balloon',
          'geoObject.addon.hint',
        ]}
        instanceRef={mapInitListeners}
        width="100%"
        height="100%"
        id="ymap"
        options={{
          maxAnimationZoomDifference: 5,
          autoFitToViewport: 'always',
          avoidFractionalZoom: false,
          minZoom: mapZoomBreakPoints.min,
          maxZoom: mapZoomBreakPoints.max - 1,
        }}
        onClick={deselectSelectedZone}
      >
        {!isDisplayElements && displayedPins.length > 0 && (
          <Clusterer
            options={{
              ...clustersOptions,
              gridSize: mapGridSize,
            }}
            onMouseenter={clusterOnEnter}
            onMouseleave={clusterOnLeave}
            onClick={clusterOnClick}
            instanceRef={clusterInit}
          >
            {displayedPins.map((pin) => {
              const pinTelemetry = getTelemetry(pin)
              let element = {
                ...pin,
                status: pinTelemetry.status || pin.status,
                telemetry: pinTelemetry,
              }
              if (element.statistic && isController(element.type)) {
                // const ElementWithNewStatistic = getObjectStatistic(pin, displayedPins, getTelemetry)
                element = getObjectStatistic(pin, displayedPins, getTelemetry)
              }
              return (
                <GeoObject
                  key={`${element.id}-point`}
                  instanceRef={setCustomFieldOnGeoObject(element)}
                  geometry={{
                    type: 'Point',
                    coordinates: element.location,
                  }}
                  properties={{
                    iconContent: renderJSXToString(
                      hoverSelectedPins(element),
                      element,
                      theme,
                      intl,
                    ),
                    population: element.id,
                  }}
                  options={{
                    zIndex: isSelectedPin(element)
                      ? YMSP_Z_INDEX.geoObject.medium
                      : YMSP_Z_INDEX.geoObject.small,
                    iconLayout: 'default#imageWithContent',
                    iconImageHref: pinImage,
                    iconImageSize: [18, 18],
                    iconImageOffset: [-9, -9],
                    draggable: false,
                  }}
                  onMouseLeave={pinOnLeave(element)}
                  onMouseEnter={pinOnEnter(element)}
                  onClick={pinOnClick(element)}
                />
              )
            })}
          </Clusterer>
        )}
        {!isDisplayElements && displayedZonesBorders
          && displayedZonesBorders.length > 0
          && displayedZonesBorders.map((border) => {
            if (!border.border) {
              return null
            }
            if (border.id === 1) {
              return (
                <Polygon
                  key={`${border.id}-borderBorder`}
                  geometry={border.border}
                  options={defaultZoneOptions}
                  onClick={deselectSelectedZone}
                />
              )
            }
            return (
              <Circle
                key={`${border.id}-borderBorder`}
                geometry={[border.border.position, border.border.radius]}
                options={defaultZoneOptions}
                onClick={deselectSelectedZone}
              />
            )
          })}
        {!isDisplayElements && displayedZonesWithTelemetry.map((zone) => (
          <>
            {zone.id !== globalZoneId && (
            <GeoObject
              key={`${zone.id}-zone`}
              geometry={{
                type: 'Point',
                coordinates: zone.location,
              }}
              properties={{
                iconContent: renderJSXToString(
                  selectedZoneById === zone.id
                    ? ZoneIconHover
                    : ZoneIcon,
                  zone,
                  theme,
                  intl,
                ),
                population: zone.id,
              }}
              options={{
                iconLayout: 'default#imageWithContent',
                iconImageHref: pinImage,
                draggable: false,
                iconImageSize: returnZoneSize(zone) || [40, 40],
                iconImageOffset: returnZoneOffset(zone) || [-20, -20],
              }}
              onClick={selectZoneHandler(zone)}
              onMouseenter={zoneOnEnterHandler(zone)}
              onMouseleave={zoneOnLeaveHandler(zone)}
            />
            )}
          </>
        ))}
        {!isDisplayElements && mash.length > 0 && mash[activeMash].map((lines) => (
          <Polyline
            key={`border-${lines.id}`}
            geometry={lines}
            options={{
              balloonCloseButton: false,
              strokeColor: '#06AAF2',
              strokeWidth: 4,
              strokeOpacity: 0.5,
            }}
          />
        ))}
        {isDisplayElements && (
          <>
            {pointsArray.map((zone) => (
              <GeoObject
                key={`${zone.id}-zone`}
                geometry={{
                  type: 'Point',
                  coordinates: zone.location,
                }}
                properties={{
                  iconContent: renderJSXToString(
                    zone.type === CITY ? ZoneIcon : hoverSelectedPins(zone, true),
                    zone,
                    theme,
                    intl,
                  ),
                  population: zone.id,
                }}
                options={{
                  iconLayout: 'default#imageWithContent',
                  iconImageHref: pinImage,
                  draggable: false,
                  iconImageSize: zone.type === CITY ? (returnZoneSize(zone) || [40, 40]) : [18, 18],
                  iconImageOffset: zone.type === CITY ? (returnZoneOffset(zone) || [-20, -20]) : [-9, -9],
                }}
              />
            ))}
          </>
        )}
        {isDisplayElements && displayElements.length !== 0 && (
          <>
            {displayElements.map((element) => {
              const activeElementId = hoveredPinByID || selectedZoneById || selectedControllerId
              const telemetry = getTelemetry(element)
              const { statistic = {} } = telemetry
              const count = Object.keys(telemetry.statistic || {}).reduce((accumulator, elementName) => {
                const typeCount = statistic[elementName] || 0
                return accumulator + typeCount
              }, 0)
              const storeStatistic = telemetry.statistic
              const updateElement = {
                ...element,
                ...telemetry,
                statistic: isEmpty(storeStatistic)
                  ? {
                    [NOT_VERIFIED]: 0,
                    [VERIFIED]: 0,
                    [NEW]: 0,
                    [NOT_EXIST]: 0,
                    [NOT_INSTALLED]: 0,
                    [INSTALLED]: 0,
                    [UNINSTALLED]: 0,
                  }
                  : storeStatistic,
                count,
              }
              const properties = element.type !== CITY
                ? {
                  iconContent: renderJSXToString(
                    hoverSelectedPins(updateElement),
                    updateElement,
                    theme,
                    intl,
                  ),
                }
                : {
                  iconContent: renderJSXToString(
                    activeElementId === updateElement.id
                      ? ZoneIconHover
                      : ZoneIcon,
                    updateElement,
                    theme,
                    intl,
                  ),
                }

              const actions = updateElement.type !== CITY
                ? {
                  onMouseLeave: pinOnLeave(updateElement),
                  onMouseEnter: pinOnEnter(updateElement),
                  onClick: pinOnClick(updateElement),
                }
                : {
                  onClick: pinOnClick(updateElement),
                  onMouseenter: zoneOnEnterHandler(updateElement),
                  onMouseleave: zoneOnLeaveHandler(updateElement),
                }

              const options = updateElement.type !== CITY
                ? {
                  zIndex: isSelectedPin(updateElement)
                    ? YMSP_Z_INDEX.geoObject.medium
                    : YMSP_Z_INDEX.geoObject.small,
                  iconLayout: 'default#imageWithContent',
                  iconImageHref: pinImage,
                  iconImageSize: [18, 18],
                  iconImageOffset: [-9, -9],
                  draggable: false,
                }
                : {
                  iconLayout: 'default#imageWithContent',
                  iconImageHref: pinImage,
                  draggable: false,
                  iconImageSize: returnZoneSize(updateElement) || [40, 40],
                  iconImageOffset: returnZoneOffset(updateElement) || [-20, -20],
                }

              return (
                <GeoObject
                  key={`${updateElement.id}-${updateElement.type}`}
                  geometry={{
                    type: 'Point',
                    coordinates: updateElement.location,
                  }}
                  properties={properties}
                  options={options}
                  {...actions}
                />
              )
            })}
          </>
        )}
      </StyledMap>
    </StyledYMaps>
  )
}

YandexMap.defaultProps = {
  pinnedNode: {},
  defaultState: { center: [55.75, 37.57], zoom: 9 },
  clustersOptions: clusterOptions,
  zones: pt.objectOf(pt.object),
  regionsWithPins: false,
  hoveredZoneByID: null,
  hoveredPinByID: null,
  createPoint: false,
  isDisplayElements: false,
  pointsArray: [],
  displayElements: [],
  updateMapZoom: noop,
  updateMapCenter: noop,
  handleOpenPassport: noop,
  setSelectedZoneIdentifier: noop,
  onClick: noop,
  pinOnEnterHandler: noop,
  pinOnLeaveHandler: noop,
  selectNodeByZone: noop,
  yandexKey: ''
}

YandexMap.propTypes = {
  pinnedNode: pt.objectOf(pt.shape({
    treeElementId: pt.oneOfType([pt.string, pt.number]),
  })),
  defaultState: pt.shape({
    zoom: pt.number,
    center: pt.arrayOf(pt.number),
  }),
  dataSet: pt.shape({
    regions: pt.arrayOf(pt.object),
    pins: pt.arrayOf(pt.object),
  }).isRequired,
  clustersOptions: pt.shape({
    groupByCoordinates: pt.bool,
    clusterDisableClickZoom: pt.bool,
    clusterHideIconOnBalloonOpen: pt.bool,
    geoObjectHideIconOnBalloonOpen: pt.bool,
    clusterNumbers: pt.arrayOf(pt.number),
  }),
  zones: {},
  mapCenter: pt.arrayOf(pt.number).isRequired,
  hoveredPinByID: pt.number,
  hoveredZoneByID: pt.number,
  mapZoom: pt.number.isRequired,
  regionsWithPins: pt.bool,
  isDisplayElements: pt.bool,
  updateMapZoom: pt.func,
  updateMapCenter: pt.func,
  handleOpenPassport: pt.func,
  onClick: pt.func,
  pinOnEnterHandler: pt.func,
  pinOnLeaveHandler: pt.func,
  selectNodeByZone: pt.func,
  setSelectedZoneIdentifier: pt.func,
  createPoint: pt.bool,
  pointsArray: pt.arrayOf(pt.object),
  displayElements: pt.arrayOf(pt.object),
  yandexKey: pt.string
}

export default injectIntl(memoWithName(YandexMap))
