import {
  SET_RANGE,
  SET_TYPE,
  SET_SAVINGS,
} from './index'

export const setRange = (payload) => ({
  type: SET_RANGE,
  payload,
})

export const setType = (payload) => ({
  type: SET_TYPE,
  payload,
})

export const setSavings = (payload) => ({
  type: SET_SAVINGS,
  payload,
})
