import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ChangeLogIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.9413 5C20.1712 5 20.3916 5.09216 20.5531 5.25586L22.7525 7.48561C23.0825 7.82021 23.0825 8.35789 22.7525 8.69249L15.2828 16.2653C15.1171 16.4332 14.8897 16.5256 14.6539 16.5209L12.5375 16.4789C12.0697 16.4696 11.6953 16.0877 11.6953 15.6198V13.3479C11.6953 13.1221 11.7842 12.9053 11.9428 12.7445L19.3295 5.25586C19.491 5.09216 19.7114 5 19.9413 5ZM13.4139 13.7004V14.7774L14.3187 14.7953L19.0816 9.96673L18.0797 8.9702L13.4139 13.7004ZM19.2866 7.74667L20.2885 8.7432L20.9337 8.08905L19.9413 7.08294L19.2866 7.74667ZM5 10.445C5 8.34078 6.66011 6.64073 8.76418 6.64073H12.914C13.3886 6.64073 13.7733 7.02545 13.7733 7.50002C13.7733 7.9746 13.3886 8.35932 12.914 8.35932H8.76418C7.63138 8.35932 6.71859 9.26766 6.71859 10.445V19.1957C6.71859 20.3731 7.63138 21.2814 8.76418 21.2814H17.3958C18.5286 21.2814 19.4414 20.3731 19.4414 19.1957V15.4094C19.4414 14.9348 19.8261 14.5501 20.3007 14.5501C20.7753 14.5501 21.16 14.9348 21.16 15.4094V19.1957C21.16 21.2999 19.4999 23 17.3958 23H8.76418C6.66011 23 5 21.2999 5 19.1957V10.445Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ChangeLogIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ChangeLogIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ChangeLogIcon)
