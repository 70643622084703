import { ADD_PASSPORT_FILE } from './index'

export const requestAddPassportFile = (payload) => ({
  type: ADD_PASSPORT_FILE.REQUEST,
  payload,
})

export const responseAddPassportFile = (payload) => ({
  type: ADD_PASSPORT_FILE.SUCCESS,
  payload,
})

export const errorAddPassportFile = (payload) => ({
  type: ADD_PASSPORT_FILE.ERROR,
  payload,

})
