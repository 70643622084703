import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PlayIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M0 1.17272C0 0.973437 0.0493334 0.778009 0.142667 0.603154C0.449336 0.0374444 1.14267 -0.166983 1.69334 0.148015L15.4134 7.97537C15.6001 8.08209 15.7534 8.24023 15.8561 8.4318C16.1628 8.99751 15.9628 9.71107 15.4134 10.0248L1.69334 17.8521C1.52601 17.9484 1.33487 17.9995 1.14001 18C0.51067 18 0 17.4754 0 16.8287V1.17272Z" />
    </svg>
  )
}

PlayIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PlayIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PlayIcon)
