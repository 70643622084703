import styled from 'styled-components'

export const CircleChartStyled = styled.svg``

export const CircleChartBackground = styled.circle`
  stroke: ${({ theme }) => theme.colors.backgrounds.redisign.secondary};
  stroke-width: 4;
  fill: none;
`

export const CircleChartCircle = styled.circle`
  stroke-width: 4;
  fill: none;
  stroke-linecap: round;
  stroke: ${({ color, theme }) => color || theme.colors.backgrounds.disabled};
  animation: circle-chart-fill 1s reverse;
  transform: rotate(90deg) scale(-1,-1);
  transform-origin: center;
  ${({ negative }) => negative && `
    transform: rotate(-90deg);
  `}

  @keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }
`

export const CircleChartInfo = styled.g``

export const CircleChartText = styled.text`
  font-size: 0.5rem;
  fill: ${({ theme }) => theme.colors.colors.default};
  ${({ largeFont, theme }) => {
    if (largeFont) {
      return 'font-size: 1.2rem;'
    }

    return `font-weight: ${theme.fontWeights.bold};`
  }}
`
