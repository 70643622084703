import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AcceptIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0896 6.67332C20.425 6.28995 20.9949 6.21987 21.4131 6.51059L22.1764 7.04122C22.6309 7.35713 22.7423 7.9821 22.425 8.4356L13.2332 21.5733C13.046 21.8407 12.7402 22 12.4138 22H11.1253C10.8037 22 10.5018 21.8454 10.3138 21.5844L5.51717 14.9241C5.22191 14.5141 5.27884 13.9483 5.64984 13.6053L6.64233 12.6879C7.02569 12.3335 7.61716 12.3337 8.00033 12.6883L11.0191 15.4818C11.4325 15.8643 12.0801 15.8301 12.4509 15.4062L20.0896 6.67332Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AcceptIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AcceptIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AcceptIcon)
