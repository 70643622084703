import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PinhoverIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="158" height="53" viewBox="0 0 158 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.3466 45L18.8154 38.4336C10.3085 36.3359 4 28.6547 4 19.5L4.00009 19.4418L4 19.3846C4 17.183 4.37868 15.0671 5.07636 13.0948C7.72635 5.47157 14.9742 0 23.5 0L134.5 0C145.27 0 154 8.73045 154 19.5C154 30.2696 145.27 39 134.5 39H29.35L24.3466 45ZM24 35C32.2843 35 39 28.2843 39 20C39 11.7157 32.2843 5 24 5C15.7157 5 9 11.7157 9 20C9 28.2843 15.7157 35 24 35Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.2375 0 0 0 0 0.2375 0 0 0 0 0.2375 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </defs>
    </svg>
  )
}

PinhoverIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PinhoverIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PinhoverIcon)
