import { CREATE_TENANT } from '../index'

export const requestCreateTenant = (payload) => ({
  type: CREATE_TENANT.REQUEST,
  payload,
})

export const responseCreateTenant = (payload) => ({
  type: CREATE_TENANT.SUCCESS,
  payload,
})

export const errorCreateTenant = (payload) => ({
  type: CREATE_TENANT.ERROR,
  payload,
})
