import { all } from 'redux-saga/effects'

import createGroup from './createGroup'
import deleteGroup from './deleteGroup'
import editGroup from './editGroup'
import getGroup from './getGroup'
import getTenantGroup from './getTenantGroup'

export default function* root() {
  yield all([
    createGroup(),
    deleteGroup(),
    editGroup(),
    getGroup(),
    getTenantGroup(),
  ])
}
