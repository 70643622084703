import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ALL_APPLICATIONS_URL } from '@/constants/apiRoutes'
import {
  GET_ALL_APPLICATIONS,
} from '@/store/actions/dictionaries'
import {
  successGetApplications,
  errorGetApplications,
} from '@/store/actions/dictionaries/applications'

function* getDictionary() {
  try {
    const response = yield request({
      url: GET_ALL_APPLICATIONS_URL,
      method: 'get',
    })

    yield put(successGetApplications(response.data))
  } catch (error) {
    yield put(errorGetApplications(error))
  }
}

export default function* () {
  yield takeLatest(GET_ALL_APPLICATIONS.REQUEST, getDictionary)
}
