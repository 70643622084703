import { MESH } from '@/constants/integrations'
import baseConfig from './base'
import meshConfig from './mesh'

export default (system) => {
  switch (system) {
    case MESH:
      return meshConfig
    default:
      return baseConfig
  }
}
