import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CheckBoxStyled from './styles'

const CheckBoxIcon = ({
  value, icon, onChange, ...ownProps
}) => {
  const handleChange = useCallback(() => {
    onChange(!value)
  }, [value, onChange])

  const Icon = icon || React.Fragment
  return (
    <CheckBoxStyled active={value} onClick={handleChange} {...ownProps}>
      <Icon />
    </CheckBoxStyled>
  )
}

CheckBoxIcon.propTypes = {
  value: pt.bool,
  onChange: pt.func,
}
CheckBoxIcon.defaultProps = {
  value: false,
  onChange: noop,
}

export default CheckBoxIcon
