import { GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA } from './index'

export const requestHeatTableData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA.REQUEST,
  payload,
})

export const responseHeatTableData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA.SUCCESS,
  payload,
})
export const errorHeatTableData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA.ERROR,
  payload,
})
