export default {
  catalogs: {
    // controller cart
    LOCATION: 'местоположение',
    OBJECT_CHARACTERISTIC: 'характеристики объекта',
    SURVEY_DATA: 'данные из обследования',
    SERVICING: 'обслуживание объекта',
    // controller passport
    GENERAL_INFORMATION: 'общая информация',
    TECHNICAL_CONSTRAINTS: 'технические параметры',
    EXPLOITATION: 'эксплуатация',
    ATTACHMENT: 'вложения',
  },
  fields: {
    // controller cart
    'LOCATION.PROJECT': 'Проект',
    'LOCATION.LATITUDE': 'Географическая широта',
    'LOCATION.LONGITUDE': 'Географическая долгота',
    'LOCATION.REGION': 'Регион',
    'LOCATION.CITY': 'Город',
    'LOCATION.STREET': 'Улица',
    'LOCATION.HOME_NUMBER': 'Дом',
    'OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE': 'Тип оборудования',
    'OBJECT_CHARACTERISTIC.lampType': 'Тип светильника',
    'OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY': 'Категория объекта освещения',
    'OBJECT_CHARACTERISTIC.IMPORTANCE': 'Важность объекта',
    'OBJECT_CHARACTERISTIC.WORK_MODE': 'Режим работы',
    'OBJECT_CHARACTERISTIC.INSTALLATION_TYPE': 'Тип установки',
    'OBJECT_CHARACTERISTIC.CONTROL_TYPE': 'Тип управления',
    'OBJECT_CHARACTERISTIC.lightingType': 'Тип объекта освещения',
    'OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER': 'Количество отходящих линий',
    'OBJECT_CHARACTERISTIC.SETUP': 'Настройка',
    'OBJECT_CHARACTERISTIC.INDICATORS': 'Индикаторы',
    'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER': 'Количество подключенных осветительных приборов',
    'OBJECT_CHARACTERISTIC.locationType': 'Признак пешеходного пространства',
    'SURVEY_DATA.CONNECTED_LAMP_NUMBER': 'Количество подключенных осветительных приборов',
    'SURVEY_DATA.LOCATION_TYPE': 'Признак пешеходного пространства',
    'SURVEY_DATA.LAMP_INSTALLATION_HEIGHT': 'Высота установки светильника в метрах',
    'SURVEY_DATA.ROADWAY_WIDTH': 'Ширина проезжей части в метрах',
    'SURVEY_DATA.INSTALLATION_SCHEME': 'Схема установки опоры',
    'SURVEY_DATA.STATE': 'Состояние опоры',
    'SURVEY_DATA.POWER_SUPPLY': 'Питание',
    'SURVEY_DATA.POWER_SUPPLY_TYPE': 'Тип питания',
    'SURVEY_DATA.OVERHANG_HEIGHT': 'Свес световой точки в метрах',
    'SURVEY_DATA.LAMP_ANGLE': 'Наклон световой точки в градусах',
    'SURVEY_DATA.OVERHANG_LENGTH': 'Длина консоли в метрах',
    'SURVEY_DATA.UTILITY_POLE_LAMP_POSITION': 'Позиция светильника в плане на опоре в градусках',
    'SERVICING.INSPECTION_DATE': 'Дата осмотра',
    'SERVICING.BYPASS_DATE': 'Дата объезда',
    'SERVICING.MAINTENANCE_DATE': 'Дата технического обслуживания',
    'SERVICING.REPAIR_DATE': 'Дата ремонта',
    'SERVICING.CAPITAL_REPAIR_DATE': 'Дата капитального ремонта',
    'SERVICING.GUARANTEE_EXPIRATION_DATE': 'Дата окончания гарантии',
    'SERVICING.CHECKING_DATE': 'Дата поверки',

    // controller passport
    'GENERAL_INFORMATION.IDENTIFIER': 'Идентификатор',
    'GENERAL_INFORMATION.NAME': 'Наименование',
    'GENERAL_INFORMATION.INSTALLATION': 'Монтаж',
    'GENERAL_INFORMATION.EQUIPMENT_TYPE': 'Тип оборудования',
    'GENERAL_INFORMATION.VERSION_API': 'Версия ПО (API)',
    'GENERAL_INFORMATION.UTILITY_POLE_TYPE': 'Тип опоры',
    'GENERAL_INFORMATION.MODEL_MARK': 'Модель/марка',
    'GENERAL_INFORMATION.LAMP_TYPE': 'Тип светильника',
    'GENERAL_INFORMATION.PROCEDURE_SUPPLIER': 'Производитель',
    'GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER': 'Идентификатор ШУО',
    'GENERAL_INFORMATION.AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'Идентификатор  АСУ',
    'GENERAL_INFORMATION.LAMP_IDENTIFIER': 'Идентификатор светильника',
    'GENERAL_INFORMATION.LINE_IDENTIFIER': 'Идентификатор линии',
    'GENERAL_INFORMATION.BASE_STATION_IDENTIFIER': 'Индентификатор базовой станции',
    'GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER': 'Индентификатор опоры',
    'TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE': 'Диапазон рабочей температуры',
    'TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION': 'Потребляемая мощность',
    'TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE': 'Диапазон рабочего напряжения',
    'TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT': 'Световой поток',
    'TECHNICAL_CONSTRAINTS.CALIBRATION_INTERVAL': 'Межповерочный интервал',
    'TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE': 'Цветовая температура',
    'TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX': 'Индекс цветопередачи',
    'TECHNICAL_CONSTRAINTS.LAMP_EFFICIENCY': 'Эффективность светильника',
    'TECHNICAL_CONSTRAINTS.SCATTERING_ANGLE': 'Угол рассеивания',
    'TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'Тип КСС',
    'TECHNICAL_CONSTRAINTS.FLICKER_PERCENT': 'Коэффицент пульсации',
    'TECHNICAL_CONSTRAINTS.RATED_POWER': 'Разрешенная мощность',
    'TECHNICAL_CONSTRAINTS.ELECTRICITY_SUPPLIER': 'Поставщик электрической энергии',
    'TECHNICAL_CONSTRAINTS.ACTUAL_CONSUMPTION_POWER': 'Фактически подключенная мощность',
    'TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS': 'Количество каналов измерения',
    'TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM ': 'Количество контролируемых теплосистем',
    'TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'Диапазон измерения среднего объемного расхода',
    'TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE': 'Диапазон измерения температуры',
    'TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'Диапазон измерения разности температур в подающем и обратном трубопроводе',
    'TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE': 'Диапазон измерения давления',
    'TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE': 'Напряжение питания постоянного тока',
    'TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'Пределы допускаемой относительной погрешности при измерении температуры',
    'EXPLOITATION.START_DATE': 'Дата начала эксплуатации',
    'EXPLOITATION.ORGANIZATION': 'Эксплуатирующая организация',
    'EXPLOITATION.OWNER': 'Собственник',
    'EXPLOITATION.GUARANTEE_PERIOD': 'Гарантийный срок',
    'EXPLOITATION.LIFE_TIME': 'Срок службы',
    'ATTACHMENT.PHOTO_TITLE': 'Фото',
    'ATTACHMENT.DOCUMENT_TITLE': 'Файл паспорта изделия',

    'OBJECT_CHARACTERISTIC.TARIFF_ONE': 'Тариф1',
    'OBJECT_CHARACTERISTIC.TARIFF_TWO': 'Тариф2',
    'OBJECT_CHARACTERISTIC.TARIFF_THREE': 'Тариф3',
    'OBJECT_CHARACTERISTIC.TARIFF_FOUR': 'Тариф4',

  },
  title: 'Паспортизация',
  changeTitle: 'Сохранить изменения?',
  passportSidebar: {
    equipment: 'оборудование инсталляции',
    discardAll: 'Отметить все',
    passport: 'Паспорт',
    card: 'Карточка',
    view: 'Просмотр:',
    noImage: 'изображение отсутствует',
    passportExport: 'экспорт паспорта',
    fieldSettings: 'Настройки отображения полей:',
    yes: 'Да',
    no: 'Нет',
  },
}
