import styled from 'styled-components'

export const ArrowsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 50px;
`

export const ArrowBlock = styled.div`
  color: #F83D14;
  font-size: 12px;
  font-weight: bold;

  ${({ positive }) => positive && 'color: #90C737;'}
`
