import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EquipmentIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.40378 18H17V16.4883H3.40378C2.85292 16.4883 2.57749 16.2422 2.57749 15.75C2.57749 15.2578 2.85292 15.0117 3.40378 15.0117H17V0H3.40378C2.70268 0 2.12678 0.2168 1.67608 0.65039C1.22538 1.08398 1.00002 1.61719 1.00002 2.25V15.0117C0.999972 15.1875 1.00002 15.5391 1.00002 15.75C1.00002 16.3828 1.22538 16.916 1.67608 17.3496C2.12678 17.7832 2.70268 18 3.40378 18ZM2.57749 2.25C2.57749 1.75781 2.85292 1.51172 3.40378 1.51172H15.4225V13.5H3.40378C3.20347 13.5 2.92804 13.5469 2.57749 13.6406V2.25ZM5.8075 6.75C5.8075 6.3423 6.1381 6.0117 6.5458 6.0117H12.2805C12.6883 6.0117 13.0188 6.3423 13.0188 6.75C13.0188 7.1577 12.6883 7.4883 12.2805 7.4883H6.5458C6.1381 7.4883 5.8075 7.1577 5.8075 6.75ZM5.8075 3.74414C5.8075 3.32669 6.1459 2.98828 6.5634 2.98828H12.2629C12.6804 2.98828 13.0188 3.32669 13.0188 3.74414C13.0188 4.16159 12.6804 4.5 12.2629 4.5H6.5634C6.1459 4.5 5.8075 4.16159 5.8075 3.74414Z" fill={fill} className="svgFill" />
    </svg>
  )
}

EquipmentIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EquipmentIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EquipmentIcon)
