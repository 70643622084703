import { UPDATE_USER_FROM_ADMIN } from '../index'

export const beginUpdateUserFromAdmin = () => ({
  type: UPDATE_USER_FROM_ADMIN.BEGIN,
})

export const endUpdateUserFromAdmin = () => ({
  type: UPDATE_USER_FROM_ADMIN.END,
})

export const requestUpdateUserFromAdmin = (forms) => ({
  type: UPDATE_USER_FROM_ADMIN.REQUEST,
  payload: forms,
})

export const responseUpdateUserFromAdmin = (user) => ({
  type: UPDATE_USER_FROM_ADMIN.SUCCESS,
  payload: user,
})

export const errorUpdateUserFromAdmin = (error) => ({
  type: UPDATE_USER_FROM_ADMIN.ERROR,
  payload: error,
})
