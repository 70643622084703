import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import getDeclension from '@/helpers/getDeclension'
import {
  GET_LIVE_TIME_REPORT_TABLE,
  LIVE_TIME_REPORT_TABLE,

  SET_BAR_GRAPH_YEAR_OPTIONS,
  SET_BAR_GRAPH_PERSENT_OPTIONS,
  SET_GRAPH_TYPE,
} from '@/store/actions/lifeTimeReport'
import { GET_LIVE_TIME_REPORT_TABLE_URL } from '@/constants/apiRoutes'
import {
  successLiveTimeReportTable,
  errorLiveTimeReportTable,
} from '@/store/actions/lifeTimeReport/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getGraphType,
  getTableParameters,
  getSelectedGraphPercentOptions,
  getSelectedGraphYearOptions,
} from '@/store/selectors/lifeTimeReport'
import { LIGHT } from '@/constants/objectTypes'

const getFormattedCurrentDateExploitation = (value = '') => {
  const [years, months, days] = (value || '').split('-')

  const yearsPhrase = years > 0 ? `${years} ${getDeclension(years, ['год', 'года', 'лет'])} ` : ''
  const monthsPhrase = months > 0 ? `${months} мес. ` : ''
  const daysPhrase = days > 0 ? `${days} дн.` : ''

  return `${yearsPhrase}${monthsPhrase}${daysPhrase}`
}

function* getLiveTimeReportTableSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.isHasInstallations && !node.internalId) {
      yield put(successLiveTimeReportTable({
        data: [],
        page: 1,
        resultCount: 0,
      }))
      return null
    }

    const type = yield select(getGraphType)
    const tableParameters = yield select(getTableParameters)

    const selectedGraphPercentOptions = yield select(getSelectedGraphPercentOptions)
    const selectedGraphYearOptions = yield select(getSelectedGraphYearOptions)

    const response = yield request({
      url: GET_LIVE_TIME_REPORT_TABLE_URL,
      method: 'post',
      body: {
        installationId: (node && node.internalId) ? node.internalId : null,
        addressId: (node && node.type === LIGHT) ? node.id : null,
        parameter: {
          type,
          options: type === 'graphPercent'
            ? selectedGraphPercentOptions
            : selectedGraphYearOptions,
        },
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
    })

    const {
      content,
      totalElements,
    } = response.data
    const formattedData = content.map((row) => ({
      ...row,
      startDateExploitation: moment(row.startDateExploitation, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      dateEndExploitation: moment(row.dateEndExploitation, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      currentDateExploitation: getFormattedCurrentDateExploitation(row.currentDateExploitation),
    }))
    yield put(successLiveTimeReportTable({
      data: formattedData,
      totalElements,
    }))
  } catch (error) {
    yield put(errorLiveTimeReportTable(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_LIVE_TIME_REPORT_TABLE.REQUEST, getLiveTimeReportTableSaga)
  yield takeLatest(LIVE_TIME_REPORT_TABLE.SORT, getLiveTimeReportTableSaga)
  yield takeLatest(LIVE_TIME_REPORT_TABLE.PER_PAGE, getLiveTimeReportTableSaga)
  yield takeLatest(LIVE_TIME_REPORT_TABLE.PAGE, getLiveTimeReportTableSaga)
  yield takeLatest(LIVE_TIME_REPORT_TABLE.SELECTED_FIELDS, getLiveTimeReportTableSaga)
  yield takeLatest(LIVE_TIME_REPORT_TABLE.REQUEST_QUERY, getLiveTimeReportTableSaga)
  yield takeLatest(SET_BAR_GRAPH_PERSENT_OPTIONS, getLiveTimeReportTableSaga)
  yield takeLatest(SET_BAR_GRAPH_YEAR_OPTIONS, getLiveTimeReportTableSaga)
  yield takeLatest(SET_GRAPH_TYPE, getLiveTimeReportTableSaga)
}
