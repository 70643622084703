import { GET_ANALYTICS_ALARMS_ALARM } from './index'

export const requestAnalyticsAlarmsAlarm = (payload) => ({
  type: GET_ANALYTICS_ALARMS_ALARM.REQUEST,
  payload,
})

export const successAnalyticsAlarmsAlarm = (payload) => ({
  type: GET_ANALYTICS_ALARMS_ALARM.SUCCESS,
  payload,
})

export const errorAnalyticsAlarmsAlarm = (payload) => ({
  type: GET_ANALYTICS_ALARMS_ALARM.ERROR,
  payload,
})
