import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import {
  GET_INTEGRATION,
  setIntegration,
} from '@/store/actions/integrations'
import {
  successGetIntegration,
  errorGetIntegration,
} from '@/store/actions/integrations/getIntegration'
import {
  getIntegrationOptions,
  getSystems,
} from '@/store/selectors/integrations'
import createNotifications from '@/helpers/notification'
import request from '@/helpers/axios'
import {
  generateIntegrationUrl,
  generateGetIntegrationById,
} from '@/constants/apiRoutes'
import { INTEGRATION_SYSTEM } from '@/constants/viewTree'
import { SYNCRONIZATION_INTEGRATION_ERROR } from '@/constants/integrations'
import messagesAliasCreator from '@/helpers/integrations/notificationElements'

function* getIntegrationSaga({ payload: { id } }) {
  try {
    const { data: integration } = yield request({
      url: generateIntegrationUrl(id),
      method: 'get',
    })
    let installationIntegrationObject = {}

    try {
      const { data: installationIntegration } = yield request({
        url: generateGetIntegrationById(integration.aliasId),
        method: 'get',
      })
      installationIntegrationObject = {
        installationIntegrationId: installationIntegration.integrationId,
      }
    } catch (error) {
      console.log("function*getIntegrationSaga -> error", error)
    }

    const { integrationType } = integration
    const integrationsSystems = yield select(getIntegrationOptions)
    const integrations = yield select(getSystems)
    const toast = createNotifications()
    if ((integration.errors && integration.errors.length) || integration.status === 'ERROR') {
      const MESSAGES_ALIASES = messagesAliasCreator('АСУНО', 'КУЛОН')
      const messageConfig = MESSAGES_ALIASES[SYNCRONIZATION_INTEGRATION_ERROR] || noop
      toast(messageConfig(integration.alias))
    }
    yield put(setIntegration({
      ...integration,
      id: integration.aliasId,
      ...installationIntegrationObject,
    }))

    const integrationsForSave = (integrations[integrationType].children || [])
      .map((row) => {
        if (integration.aliasId === row.id) {
          return ({
            ...row,
            parameters: integration,
          })
        }

        return row
      })

    const systemsForSave = integrationsSystems
      .reduce((accumulator, system) => {
        if (integrationType === system.type) {
          return ({
            ...accumulator,
            [system.type]: {
              ...system,
              name: `${system.controlType} "${system.name}"`,
              isSync: false,
              type: INTEGRATION_SYSTEM,
              toggled: false,
              children: integrationsForSave,
              ...installationIntegrationObject,
            },
          })
        }
        return accumulator
      }, integrations)

    yield put(successGetIntegration(systemsForSave))
  } catch (error) {
    yield put(errorGetIntegration(error))
  }
}

export default function* root() {
  yield takeLatest(GET_INTEGRATION.REQUEST, getIntegrationSaga)
}
