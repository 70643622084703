import React from 'react'
import pt from 'prop-types'
import {
  CellContainer,
} from './styles'

const ColoredTextCell = ({ type, ...cell }) => (
  <CellContainer {...cell.getCellProps()} type={type}>
    {cell.value.toLocaleString()}
  </CellContainer>
)

ColoredTextCell.propTypes = {
  type: pt.string,
}
ColoredTextCell.defaultProps = {
  type: null,
}

export default ColoredTextCell
