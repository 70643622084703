export default {
  buttons: {
    confirm: 'Yes',
    reject: 'No',
    connect: 'Connect integration',
    delete: 'Delete integration',
    continue: 'Continue',
    wait: 'Please wait',
    repeat: 'Repeat\nsync',
  },
  labels: {
    addNewIntegration: 'Adding\nnew integration',
    rejectNewIntegration: 'Cancel adding\nnew integration?',
    connectedIntegrations: 'Integration\nexternal systems',
    integrationParameters: 'Integration parameters',
    integrationDiscardChanges: 'Do you want to discard changes?',
  },
  messages: {
    noConnectedIntegrations: 'There are no external systems.\nClick "+" to add',
    connectionToSystem: 'Connecting to an external system',
    connectionSuccess: 'The integration was successfully added to the system. Data synchronization may take some time',
  },
  form: {
    installationIntegrationId: 'Existing Integration',
    api: 'API URL*',
    port: 'Port',
    login: 'User*',
    notRequiredLogin: 'User',
    pass: 'Password*',
    notRequiredPass: 'Password',
    name: 'Name*',
    refresh: 'Refresh interval',

    contextUrl: 'Context url*',
    historyUrl: 'History url*',
    clientId: 'Client id*',
    clientSecret: 'Client secret*',
  },
  formMessages: {
    deletingInfo: 'Integration is being completely removed\nfrom the system.',
    deleting: 'Are you sure you want to\ndelete the integration from\nthe system?\nAll data will be lost!',
    editingInfo: 'Processing\nuser request....',
    editing: 'Do you want to apply settings\nchange?',
    info: '*Required\nfields',
    connection: 'Connecting\nto an external system....',
    error: 'This is the text of the error\nthat occurred while connecting\n',
    timeOutError: 'Network access error',
    unknownError: 'Sync error',
    editingError: 'Settings change \nwas not completed.',
    sync: '( sync: in progress )',
  },
}
