import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AlarmsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M25 6.8C24.8727 6.8 24.7514 6.83156 24.6492 6.88687C24.5474 6.94198 24.472 7.01638 24.4237 7.09579L2.95269 42.4324C2.90554 42.51 2.88391 42.5925 2.88463 42.6722C2.88535 42.752 2.90842 42.8343 2.95727 42.9117C3.00649 42.9896 3.08204 43.0621 3.18295 43.1157C3.2842 43.1695 3.40375 43.2 3.52901 43.2H46.4712C46.5964 43.1999 46.7161 43.1694 46.8172 43.1156C46.9181 43.0621 46.9936 42.9895 47.0428 42.9116C47.0916 42.8343 47.1147 42.7519 47.1154 42.6722C47.1161 42.5925 47.0945 42.5101 47.0474 42.4325L25.5763 7.09579C25.528 7.01638 25.4526 6.94197 25.3508 6.88687C25.2486 6.83156 25.1273 6.8 25 6.8ZM23.2442 4.44144C23.7812 4.15078 24.3869 4 25 4C25.6131 4 26.2188 4.15078 26.7558 4.44144C27.2931 4.73231 27.7459 5.15505 28.0607 5.67295L49.5319 41.0099C49.8419 41.5201 50.0052 42.1019 49.9999 42.6966C49.9945 43.2912 49.8209 43.8701 49.5021 44.375C49.1835 44.8793 48.7331 45.2898 48.2021 45.5719C47.6714 45.8538 47.0751 45.9999 46.4718 46H3.52937C2.92602 46 2.32947 45.8541 1.79869 45.5723C1.26751 45.2902 0.816911 44.8799 0.498248 44.3755C0.17923 43.8706 0.00551175 43.2916 0.000128988 42.6968C-0.00525377 42.1021 0.157948 41.5203 0.468035 41.01L21.9392 5.67315C22.254 5.15525 22.7069 4.7323 23.2442 4.44144ZM25 16.6452C25.7966 16.6452 26.4423 17.272 26.4423 18.0452L26.4423 32.5871C26.4423 33.3603 25.7966 33.9871 25 33.9871C24.2034 33.9871 23.5577 33.3603 23.5577 32.5871L23.5577 18.0452C23.5577 17.272 24.2034 16.6452 25 16.6452ZM25 36C25.7966 36 26.4423 36.6268 26.4423 37.4V38.2129C26.4423 38.9861 25.7966 39.6129 25 39.6129C24.2034 39.6129 23.5577 38.9861 23.5577 38.2129V37.4C23.5577 36.6268 24.2034 36 25 36Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AlarmsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AlarmsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AlarmsIcon)
