/**
 * Function for generate new state with changed table parameter
 * @param {Object} state - redux state
 * @param {string} name - parameter name
 * @param {*} value - new parameter value
 * @returns {Object} Updated table state
 */
export const generateTableParameter = (state, name, value) => {
  if (name) {
    return ({
      table: {
        ...state.table,
        [name]: value,
      },
    })
  }

  return ({})
}

/**
 * Function for creating default table reducers
 * @param {Object} actions - object of actions names
 * @returns {Object} Default redux reducers for table
 */
const generateDefaultTableReducers = (actions = {}) => ({
  [actions.SORT]: (state, { payload: { sortType, sortValue } }) => ({
    ...state,
    table: {
      ...state.table,
      sort: {
        direction: sortValue,
        field: sortType,
      },
    },
  }),
  [actions.QUERY]: (state, { payload }) => ({
    ...state,
    table: {
      ...state.table,
      query: payload,
    },
  }),
  [actions.REQUEST_QUERY]: (state, { payload }) => ({
    ...state,
    table: {
      ...state.table,
      requestQuery: payload,
      page: 1,
    },
  }),
  [actions.SELECTED_FIELDS]: (state, { payload }) => ({
    ...state,
    table: {
      ...state.table,
      activeFields: payload,
    },
  }),
  [actions.TOTAL_RESULTS]: (state, { payload }) => ({
    ...state,
    table: {
      ...state.table,
      resultCount: payload,
    },
  }),
  [actions.PER_PAGE]: (state, { payload }) => ({
    ...state,
    table: {
      ...state.table,
      perPage: payload,
      page: 1,
    },
  }),
  [actions.PAGE]: (state, { payload }) => ({
    ...state,
    table: {
      ...state.table,
      page: payload,
    },
  }),
})

export default generateDefaultTableReducers
