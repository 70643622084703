import { USER_SET_PASSWORD } from './index'

export const beginSetPassword = ([form]) => ({
  type: USER_SET_PASSWORD.BEGIN,
  payload: { form },
})

export const successSetPassword = (data) => ({
  type: USER_SET_PASSWORD.SUCCESS,
  payload: { data },
})

export const errorSetPassword = (error) => ({
  type: USER_SET_PASSWORD.ERROR,
  payload: { error },
})
