import {
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'

const getElementFromTree = (node, config = [], selector) => {
  if (node.type === OBJECT_ELEMENT) {
    return config
  }
  return node.children.reduce((accumulator, element) => {
    const childElements = element.children.reduce((buffer, child) => {
      const formattedBuffer = [
        ...buffer,
        ...getElementFromTree(child, [], selector),
      ]
      if (child.type === OBJECT_ELEMENT) {
        return formattedBuffer
      }
      return [
        ...formattedBuffer,
        {
          value: child[selector],
          title: child.elementName,
          type: child.type,
        },
      ]
    }, [])
    const formattedAccumulator = [
      ...accumulator,
      ...childElements,
    ]
    if (element.type === OBJECT_ELEMENT) {
      return formattedAccumulator
    }
    return [
      ...formattedAccumulator,
      {
        value: element[selector],
        title: element.elementName,
        type: element.type,
      },
    ]
  }, config)
}

export default (data, selector) => {
  const node = data[0] || {}
  const children = getElementFromTree(data[0], [], selector)
  return [
    {
      value: node[selector],
      title: node.elementName,
      type: node.type,
    },
    ...children,
  ]
}
