import {
  LIGHT,
  CONTROLLER,
  CITY,
  ELECTRIC_METER,
  CONTACTORS,
  CONTACTOR,
  FUSE,
  CURRENT_SENSOR,
  BRANCH_CIRCUIT,
  CHECK_POINT,
  UNICERSE_METER,
  DMX_DEVICE,
} from '@/constants/objectTypes'
import { BRIZ } from '@/constants/integrations'
import { INFO } from '@/constants/objectStatuses'
import getObjectName from '@/helpers/viewTree/getObjectName'

const BRIZ_GEOZONE_ID = 466

const getDefaultParameters = (object, aliasId, targetObject = {}) => ({
  status: INFO,
  state: INFO,
  system: BRIZ,
  geoZoneId: BRIZ_GEOZONE_ID,
  aliasId: targetObject.aliasId || object.aliasId || aliasId,
  id: targetObject.brizApiId || object.brizApiId,
  brizApiId: targetObject.brizApiId || object.brizApiId,
  brizApiParentId: targetObject.brizApiParentId || object.brizApiParentId,
  location: [
    targetObject.latitude || object.latitude,
    targetObject.longitude || object.longitude,
  ],
  children: [],
})

const mapСheckPoints = (checkPoints, controller, aliasId) => checkPoints.map((checkPoint) => ({
  ...getDefaultParameters(controller, aliasId, checkPoint),
  type: CHECK_POINT,
  name: getObjectName(checkPoint.name, CHECK_POINT),
  children: [],
}))

const mapLamps = (lamps, controller, aliasId) => lamps.map((lamp) => ({
  ...getDefaultParameters(controller, aliasId, lamp),
  location: [lamp.latitude, lamp.longitude],
  type: LIGHT,
  name: lamp.name,
  children: [],
}))
const mapSensors = (sensors, controller, aliasId) => sensors.map((sensor) => ({
  ...getDefaultParameters(controller, aliasId, sensor),
  type: UNICERSE_METER,
  name: getObjectName(sensor.name, UNICERSE_METER),
  children: [],
}))

const mapBranchCircuits = (branchCircuits, controller, aliasId) => branchCircuits
  .map((branchCircuit) => ({
    ...getDefaultParameters(controller, aliasId, branchCircuit),
    type: BRANCH_CIRCUIT,
    name: getObjectName(branchCircuit.name, BRANCH_CIRCUIT),
    children: [
      {
        ...getDefaultParameters(controller, aliasId, branchCircuit.fuse),
        type: FUSE,
        name: getObjectName(branchCircuit.fuse.name, FUSE),
      },
      {
        ...getDefaultParameters(controller, aliasId, branchCircuit.currentSensor),
        type: CURRENT_SENSOR,
        name: getObjectName(branchCircuit.currentSensor.name, CURRENT_SENSOR),
      },
    ],
  }))

const mapControllerChildren = (controller, aliasId) => {
  const children = []

  if (controller[ELECTRIC_METER]) {
    children.push({
      ...getDefaultParameters(controller, aliasId, controller[ELECTRIC_METER]),
      type: ELECTRIC_METER,
      id: controller[ELECTRIC_METER].id,
      name: getObjectName(controller[ELECTRIC_METER].name, ELECTRIC_METER),
    })
  }
  if (controller[DMX_DEVICE]) {
    children.push({
      ...getDefaultParameters(controller, aliasId, controller[DMX_DEVICE]),
      type: DMX_DEVICE,
      id: controller[DMX_DEVICE].id,
      name: getObjectName(controller[DMX_DEVICE].name, DMX_DEVICE),
    })
  }
  if (controller[CONTACTORS]) {
    controller[CONTACTORS].forEach((contact) => {
      children.push({
        ...getDefaultParameters(controller, aliasId, contact),
        type: CONTACTOR,
        name: getObjectName(contact.name, CONTACTOR),
        children: [
          ...mapСheckPoints(contact.checkPoints, controller, aliasId),
          ...mapBranchCircuits(contact.branchCircuits, controller, aliasId),
        ],
      })
    })
  }
  if (controller.lamps) {
    children.push(...mapLamps(controller.lamps, controller, aliasId))
  }
  if (controller.sensors) {
    children.push(...mapSensors(controller.sensors, controller, aliasId))
  }

  return children
}

const mapBrizTelemetry = (data) => {
  const mappedControllers = data.reduce((accumulatorControllers, organization) => {
    const controllers = organization.controllersGroups.reduce((accumulator, group) => {
      const controllersObjects = group.controllers.map((controller) => ({
        ...getDefaultParameters(controller, organization.aliasId),
        type: CONTROLLER,
        name: getObjectName(controller.name, CONTROLLER),
        children: mapControllerChildren(controller, organization.aliasId),
      }))
      return [...accumulator, ...controllersObjects]
    }, [])

    return [...accumulatorControllers, ...controllers]
  }, [])

  return [{
    id: BRIZ_GEOZONE_ID,
    type: CITY,
    name: 'АСУ "БРИЗ"',
    location: [55.74, 37.627],
    system: BRIZ,
    children: mappedControllers,
  }]
}

export default mapBrizTelemetry
