import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
} from 'react'
import pt from 'prop-types'
import { Formik } from 'formik'
import moment from 'moment'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import SelectField from '@/components/fields/SelectField'
import getFastInterval from '@/helpers/getFastInterval'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import createNotifications from '@/helpers/notification'
import {
  START_DATE,
  END_DATE,
  URBAN_PROCESSES,
  CHART_TYPE,
  PERIOD,
  INTERVAL,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  DATE_INTERVAL,
  ANNUALLY,
} from '@/constants/tyumenOurHome'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import {
  periodOptions,
  intervalOptions,
} from './config'
import {
  FormContainer,
  ContentContainer,
  Text, CustomButton,
  CustomDatePicker,
} from './styles'

const TyumenOurHomeFilter = ({
  setFormValues,
}) => {
  const formicForm = useRef(null)
  const [dateCheck, setDateCheck] = useState(true)
  const [initialValues] = useUrlParseAndPush()

  const parsedInitialValues = useMemo(() => {
    const newUrlParse = {
      ...initialValues,
    }
    newUrlParse[START_DATE] = initialValues[START_DATE] ? new Date(initialValues[START_DATE]) : null
    newUrlParse[END_DATE] = initialValues[END_DATE] ? new Date(initialValues[END_DATE]) : null
    return newUrlParse
  }, [initialValues])

  // TODO: fix problem with reload
  // useEffect(() => () => setFormValues({}), [])

  const handleResetFilter = useCallback(() => {
    setFormValues({})
    formicForm.current.resetForm({})
  }, [setFormValues])

  const showNotification = debounce(() => {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Измените диапазон или выберите другой интервал',
      type: 'error',
    })
  }, DEBOUNCE_DELAY_SMALL)

  const datePickerFilter = useCallback((formValues) => {
    const startDate = new Date(formValues[START_DATE])
    const endDate = new Date(formValues[END_DATE])
    const dateDifference = moment(formValues[END_DATE]).diff(moment(formValues[START_DATE]), 'days')
    if (formValues[INTERVAL] !== ANNUALLY) {
      if ((dateDifference > DATE_INTERVAL[formValues[INTERVAL]]
          || startDate > endDate)
          && formValues[START_DATE]
          && formValues[END_DATE]
      ) {
        setDateCheck(false)
        showNotification()
      } else {
        setDateCheck(true)
      }
    } else {
      setDateCheck(true)
    }
  }, [showNotification])

  const handleValidateValues = useCallback((formValues) => {
    datePickerFilter(formValues)
    if (
      formValues[CHART_TYPE] !== initialValues[CHART_TYPE]
      || formValues[URBAN_PROCESSES] !== initialValues[URBAN_PROCESSES]
    ) {
      setFormValues({
        ...initialValues,
        [CHART_TYPE]: formValues[CHART_TYPE],
        [URBAN_PROCESSES]: formValues[URBAN_PROCESSES],
      })
    }
  }, [datePickerFilter, initialValues, setFormValues])

  const handleSetValues = useCallback((formValues) => () => {
    setFormValues(formValues)
  }, [setFormValues])

  const handleSetFastInterval = useCallback((formValues) => (fieldName, value) => {
    getFastInterval(formValues, value, formicForm, setFormValues)
  }, [setFormValues])

  const onSubmit = useCallback(() => {}, [])

  const renderButton = useCallback((values) => {
    const startDateValue = values[START_DATE] ? (values[START_DATE] || '').toString() : null
    const endDateValue = values[END_DATE] ? (values[END_DATE] || '').toString() : null
    if (
      (startDateValue !== initialValues[START_DATE]
      || endDateValue !== initialValues[END_DATE]
      || values[INTERVAL] !== initialValues[INTERVAL])
      && values[INTERVAL] && endDateValue && startDateValue && dateCheck
    ) {
      return (
        <CustomButton onClick={handleSetValues(values)}>
          <Lang id="tyumenOurHome.buildChart" />
        </CustomButton>
      )
    }
    return null
  }, [dateCheck, handleSetValues, initialValues])

  const handleSetGraphValue = useCallback(() => (fieldName, value) => {
    const comparison = Object.prototype.toString.call(value) === '[object Date]'
      ? value.valueOf() !== new Date(initialValues[fieldName]).valueOf()
      : value !== initialValues[fieldName]
    if (comparison) {
      formicForm.current.setFieldValue(PERIOD, null)
    }
  }, [initialValues])

  return (
    <Formik
      ref={formicForm}
      onSubmit={onSubmit}
      validate={handleValidateValues}
      initialValues={parsedInitialValues}
      render={({
        handleSubmit,
        values,
      }) => (
        <FormContainer onSubmit={handleSubmit}>
          <ContentContainer>
            <SelectField
              largeOptions
              name={PERIOD}
              withSearch
              placeholder={<Lang id="widgetsDashboard.period" />}
              options={periodOptions}
              onAfterChange={handleSetFastInterval(values)}
            />
          </ContentContainer>
          <ContentContainer datePicker>
            <CustomDatePicker
              name={START_DATE}
              maxDate={new Date()}
              onAfterChange={handleSetGraphValue()}
            />
          </ContentContainer>
          <Text datePicker>
            -
          </Text>
          <ContentContainer datePicker>
            <CustomDatePicker
              name={END_DATE}
              maxDate={new Date()}
              onAfterChange={handleSetGraphValue()}
            />
          </ContentContainer>
          <ContentContainer interval>
            <SelectField
              largeOptions
              name={INTERVAL}
              placeholder={<Lang id="widgetsDashboard.interval" />}
              withSearch
              options={intervalOptions}
              onAfterChange={handleSetGraphValue()}
            />
          </ContentContainer>
          {renderButton(values)}
          {Object.keys(initialValues).length > 0 && (
            <Text reset onClick={handleResetFilter}>
              <Lang id="serviceDeskMyCity.resetFilter" />
            </Text>
          )}
        </FormContainer>
      )}
    />
  )
}

TyumenOurHomeFilter.propTypes = {
  setFormValues: pt.func,
}

TyumenOurHomeFilter.defaultProps = {
  setFormValues: noop,
}

export default React.memo(TyumenOurHomeFilter)
