import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MailIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 5.05384C6.70588 4.84614 9.45882 3.11538 12 1.38461V0.830768C12 0.346153 11.5765 0 11.0824 0H0.917647C0.423529 0 0 0.346153 0 0.830768V1.24615C2.47059 3.04615 5.36471 4.84614 6 5.05384Z" fill={fill} className="svgFill" />
      <path d="M6 6.16154C5.71765 6.16154 5.15294 6.16154 1.83529 3.94616C1.2 3.53078 0.564706 3.04617 0 2.70001V8.16923C0 8.65385 0.423529 9 0.917647 9H11.0824C11.5765 9 12 8.65385 12 8.16923V2.83847C9.45882 4.50001 6.84706 6.16154 6 6.16154Z" fill={fill} className="svgFill" />
    </svg>
  )
}

MailIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MailIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MailIcon)
