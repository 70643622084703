import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 5px 5px 5px 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #F0F0F0;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.14);
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #FFFFFF;
  color: #3D3D3D;
  padding: 10px 5px 10px 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px 5px 0px 0px;

  svg {
    margin-right: 10px;
  }

  ${({ open, color }) => open && `
    background-color: ${color};
    color: #FFFFFF;
  `}
`

export const Content = styled.div`
  display: none;
  text-align: left;
  background-color: #FFFFFF;
  color: #54595F;
  border-radius: 0px 0px 5px 5px;
  padding: 15px 20px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;

  ${({ open }) => open && 'display: block'};
`
