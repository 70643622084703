import { connect } from 'react-redux'
import {
  getTreeData,
  getTelemetry,
} from '@/store/selectors/maps/socketEvents'
import {
  getExportFileStatus,
  getTableData,
  getSelector,
  getEventTypes,
  getEventStatus,
  getSelectedElement,
  getEventData,
  getEventsCount,
  getTableStatus,
  getDates,
  getLoadingStatistic,
  getLoadingTable,
  getIsAlarms,
} from '@/store/selectors/alarmManager'
import { setSelectedNode } from '@/store/actions/appSettings'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  setDatesRange,
  setSelectedAllElements,
} from '@/store/actions/alarmManager'
import {
  requestAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import {
  requestCheckAllAlarms,
} from '@/store/actions/alarmManager/checkAllAlarms'
import {
  requestAlarmManagerFile,
} from '@/store/actions/alarmManager/getFile'
import AlarmsManager from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  node: getSelectedNode(state),
  data: getTableData(state),
  tableStatus: getTableStatus(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  eventTypes: getEventTypes(state),
  eventStatus: getEventStatus(state),
  selectedElement: getSelectedElement(state),
  eventsCount: getEventsCount(state),
  eventData: getEventData(state),
  dates: getDates(state),
  telemetry: getTelemetry(state),
  loadingStatistic: getLoadingStatistic(state),
  loadingTable: getLoadingTable(state),
  isAlarms: getIsAlarms(state),
})
const mapDispatchToProps = (dispatch) => ({
  setEventTypes: (payload) => dispatch(setEventTypes(payload)),
  setStatusTypes: (payload) => dispatch(setStatusTypes(payload)),
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  setDatesRange: (payload) => dispatch(setDatesRange(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  requestAlarmManagerTable: (payload) => dispatch(requestAlarmManagerTable(payload)),
  requestAlarmManagerFile: (payload) => dispatch(requestAlarmManagerFile(payload)),
  requestCheckAllAlarms: (payload) => dispatch(requestCheckAllAlarms(payload)),
  setSelectedAllElements: (payload) => dispatch(setSelectedAllElements(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsManager)
