import { connect } from 'react-redux'
import { getTreeData, getZones } from '@/store/selectors/maps/socketEvents'
import {
  getExportFileStatus,
  getSelector,
  getSelectedElement,
} from '@/store/selectors/analyticsAlarms'
import {
  requestReportManagerTable,
} from '@/store/actions/reportManager/getTable'
import {
  requestReportManagerTypes,
} from '@/store/actions/reportManager/getTypes'
import {
  requestReportManagerObjectTypes,
} from '@/store/actions/reportManager/getObjectTypes'
import {
  requestReportManagerUsers,
} from '@/store/actions/reportManager/getCommonUsers'
import {
  requestCreateReport,
} from '@/store/actions/reportManager/createReport'
import {
  setFormIsModifying,
} from '@/store/actions/reportManager'
import {
  requestGetReport,
} from '@/store/actions/reportManager/getReport'
import {
  requestDeleteReport,
} from '@/store/actions/reportManager/deleteReport'
import {
  requestChangeReport,
} from '@/store/actions/reportManager/changeReport'
import {
  requestReportManagerFile,
} from '@/store/actions/reportManager/getFile'
import {
  getData,
  getTypes,
  getIsTypesLoading,
  getObjectTypes,
  getIsObjectTypesLoading,
  getCommonUsers,
  getIsCommonUsersLoading,
  getIsFormModifying,
  getSelectedReport,
  getLoadingSelectedReport,
  getTableLoading,
} from '@/store/selectors/reportManager'
import { getSelectedNode, getPinnedNode } from '@/store/selectors/appSettings'
import { setSelectedNode } from '@/store/actions/appSettings'

import ReportManager from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  zones: getZones(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  selectedElement: getSelectedElement(state),
  data: getData(state),
  reportTypes: getTypes(state),
  isTypesLoading: getIsTypesLoading(state),
  reportObjectTypes: getObjectTypes(state),
  isObjectTypesLoading: getIsObjectTypesLoading(state),
  commonUsers: getCommonUsers(state),
  isCommonUsersLoading: getIsCommonUsersLoading(state),
  reportFormOpen: getIsFormModifying(state),
  selectedReport: getSelectedReport(state),
  loadingSelectedReport: getLoadingSelectedReport(state),
  selectedNode: getSelectedNode(state),
  pinnedNode: getPinnedNode(state),
  loadingTable: getTableLoading(state),
})
const mapDispatchToProps = (dispatch) => ({
  getTableData: (payload) => dispatch(requestReportManagerTable(payload)),
  getTypes: (payload) => dispatch(requestReportManagerTypes(payload)),
  getObjectTypes: (payload) => dispatch(requestReportManagerObjectTypes(payload)),
  getCommonUsers: (payload) => dispatch(requestReportManagerUsers(payload)),
  createReport: (payload) => dispatch(requestCreateReport(payload)),
  setFormIsModifying: (payload) => dispatch(setFormIsModifying(payload)),
  requestGetReport: (payload) => dispatch(requestGetReport(payload)),
  requestDeleteReport: (payload) => dispatch(requestDeleteReport(payload)),
  requestChangeReport: (payload) => dispatch(requestChangeReport(payload)),
  requestReportManagerFile: (payload) => dispatch(requestReportManagerFile(payload)),
  setNode: (data, withoutPinned) => dispatch(setSelectedNode(data, withoutPinned)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportManager)
