import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getChild,
  getRoot,
  getRootStatus,
  getCard,
  getIntegrationTypes,
  getObjectTypes,
  getParentElements,
  getDisplayElements,
  getTelemetry,
  getValidationScheme,
  getMapCenter,
  getMapZoom,
  getObjectTypesRequestStatus,
  getSelectedElementParams,
  getDisplayElementAndTelemetryRequestStatus,
} from '@/store/selectors/installation'
import { setInstallationParams } from '@/store/actions/installation/params'
import {
  setTree,
  setNode,
  setPins,
  setMapCenter,
  setMapZoom,
  setSelectedElementParams,
  setRequestStatus,
} from '@/store/actions/installation'

import { requestVerifyElements } from '@/store/actions/installation/verifyObject'
import { requestCreateGeoZone } from '@/store/actions/installation/createGeoZone'
import { requestGetMapObjects } from '@/store/actions/maps/socketEvents'
import { requestEditGeoZone } from '@/store/actions/installation/editGeoZone'
import { requestDeleteGeoZone } from '@/store/actions/installation/deleteGeoZone'
import { requestGetRootElements } from '@/store/actions/installation/getRootElemenet'
import { requestCreateProject } from '@/store/actions/installation/createProject'
import { requestEditProject } from '@/store/actions/installation/editProject'
import { requestDeleteProject } from '@/store/actions/installation/deleteProject'
import { requestGetCard, successGetCard } from '@/store/actions/installation/getCard'
import { requestCreateObject } from '@/store/actions/installation/createObject'
import { requestEditObject } from '@/store/actions/installation/editObject'
import { requestDeleteObject } from '@/store/actions/installation/deleteObject'
import { requestCreateIntegration } from '@/store/actions/installation/createIntegration'
import { requestDeleteIntegration } from '@/store/actions/installation/deleteIntegration'
import { requestEditIntegration } from '@/store/actions/installation/editIntegration'
import { requestGetIntegrationTypes } from '@/store/actions/installation/getAvalibleIntegrationTypes'
import { requestGetObjectTypes } from '@/store/actions/installation/getObjectTypes'
import { requestGetTenantParents } from '@/store/actions/installation/getTenantParrents'
import { requestGetScheme } from '@/store/actions/installation/getScheme'
import { requestDownloadMultipleObject } from '@/store/actions/installation/downloadMultipleObject'
import { requestGetObjectValidationScheme } from '@/store/actions/installation/getObjectValidationScheme'
import { requestGetInstallationObjectPDFFile } from '@/store/actions/installation/exportObjectPDF'
import { requestDownloadMultipleObjectScheme } from '@/store/actions/installation/downloadScheme'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'

import Component from './component'
import { getUserApplications } from '@/store/selectors/appSettings';

const mapStateToProps = (state) => ({
  childs: getChild(state),
  roots: getRoot(state),
  rootsStatus: getRootStatus(state),
  card: getCard(state),
  integrationTypes: getIntegrationTypes(state),
  objectTypes: getObjectTypes(state),
  parentElements: getParentElements(state),
  displayElements: getDisplayElements(state),
  telemetry: getTelemetry(state),
  validationScheme: getValidationScheme(state),
  mapCenter: getMapCenter(state),
  mapZoom: getMapZoom(state),
  objectTypesRequestStatus: getObjectTypesRequestStatus(state),
  selectedElementParams: getSelectedElementParams(state),
  displayElementAndTelemetryRequestStatus: getDisplayElementAndTelemetryRequestStatus(state),
  userApplications: getUserApplications(state),
})

const mapDispatchToProps = (dispatch) => ({
  setInstallationParams: (payload) => dispatch(setInstallationParams(payload)),
  setTree: (payload) => dispatch(setTree(payload)),
  setNode: (payload) => dispatch(setNode(payload)),
  setPins: (payload) => dispatch(setPins(payload)),
  setMapCenter: (payload) => dispatch(setMapCenter(payload)),
  setMapZoom: (payload) => dispatch(setMapZoom(payload)),
  requestGetMapObjects: () => dispatch(requestGetMapObjects()),
  requestGetRootElements: (payload) => dispatch(requestGetRootElements(payload)),
  requestGetCard: (payload) => dispatch(requestGetCard(payload)),
  setCard: (payload) => dispatch(successGetCard(payload)),
  requestGetIntegrationTypes: (payload) => dispatch(requestGetIntegrationTypes(payload)),
  requestGetObjectTypes: (payload) => dispatch(requestGetObjectTypes(payload)),

  requestCreateGeoZone: (payload) => dispatch(requestCreateGeoZone(payload)),
  requestEditGeoZone: (payload) => dispatch(requestEditGeoZone(payload)),
  requestDeleteGeoZone: (payload) => dispatch(requestDeleteGeoZone(payload)),

  requestCreateProject: (payload) => dispatch(requestCreateProject(payload)),
  requestEditProject: (payload) => dispatch(requestEditProject(payload)),
  requestDeleteProject: (payload) => dispatch(requestDeleteProject(payload)),

  requestCreateObject: (payload) => dispatch(requestCreateObject(payload)),
  requestEditObject: (payload) => dispatch(requestEditObject(payload)),
  requestDeleteObject: (payload) => dispatch(requestDeleteObject(payload)),

  requestCreateIntegration: (payload) => dispatch(requestCreateIntegration(payload)),
  requestDeleteIntegration: (payload) => dispatch(requestDeleteIntegration(payload)),
  requestEditIntegration: (payload) => dispatch(requestEditIntegration(payload)),
  requestGetTenantParents: (payload) => dispatch(requestGetTenantParents(payload)),
  requestGetScheme: (payload) => dispatch(requestGetScheme(payload)),
  requestDownloadMultipleObject: (payload) => dispatch(requestDownloadMultipleObject(payload)),
  requestGetObjectValidationScheme: (payload) => dispatch(requestGetObjectValidationScheme(payload)),
  requestGetInstallationObjectPDFFile: (payload) => dispatch(requestGetInstallationObjectPDFFile(payload)),
  requestVerifyElements: (payload) => dispatch(requestVerifyElements(payload)),
  requestDownloadMultipleObjectScheme: (payload) => dispatch(requestDownloadMultipleObjectScheme(payload)),
  setSelectedElementParams: (payload) => dispatch(setSelectedElementParams(payload)),
  setRequestStatus: (payload) => dispatch(setRequestStatus(payload)),
  requestGetPinsAndZonesAndTelemetry: (payload) => dispatch(requestGetPinsAndZonesAndTelemetry(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Component))
