import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import SidebarTree from '@/components/blocks/SidebarTree'
import MapIcons from '@/components/icons/maps'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import Loader from '@/components/blocks/Loader'
import {
  PageSidebarStyled,
  Title,
  HeaderRow,
  IconWrapper,
  HeaderContentWrapper,
  SidebarWrapper,
  TreeContainer,
} from './styles'

const PageSidebar = ({
  treeId,
  onCollapse,
  title,
  headerContent,
  onIconClick,
  icon,
  content,
  treeData,
  mapKey,
  telemetryHash,
  searchQuery,
  onSelect,
  headerIcon,
  headerIconDescription,
  onHeaderIconClick,
  getTelemetry,
  requestGetMapObjects,
  loading,
  rightPadding,
  userTree,
  withoutRequest,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const HeaderIcon = useMemo(() => headerIcon || React.Fragment, [headerIcon])

  const switchCollapseMode = useCallback(() => {
    const newState = !isCollapsed

    setIsCollapsed(newState)
    onCollapse(newState)
  }, [isCollapsed, setIsCollapsed, onCollapse])

  useEffect(() => {
    if (!withoutRequest && requestGetMapObjects) {
      requestGetMapObjects()
    }
  }, [requestGetMapObjects, withoutRequest])

  return (
    <PageSidebarStyled small={isCollapsed}>
      <HeaderRow gradient>
        <PortalTooltip
          title={isCollapsed
            ? (<Lang id="tooltip.deCollapse" />)
            : (<Lang id="tooltip.collapse" />)}
          renderChildren={(
            wrapperRef,
            onMouseEnterHandler,
            onMouseLeaveHandler,
          ) => (
            <IconWrapper
              type="arrow"
              onClick={switchCollapseMode}
              ref={wrapperRef}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
            >
              <MapIcons.BackIcon />
            </IconWrapper>
          )}
        />
        <HeaderContentWrapper withoutPadding>
          <Title>{title}</Title>
        </HeaderContentWrapper>
        {headerIcon && !isCollapsed && (
          <PortalTooltip
            title={headerIconDescription}
            handleIconClick={onHeaderIconClick}
            renderChildren={(
              wrapperRef,
              onMouseEnterHandler,
              onMouseLeaveHandler,
              onClickHandler
            ) => (
              <IconWrapper
                onClick={onClickHandler}
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                <HeaderIcon />
              </IconWrapper>
            )}
          />
        )}
      </HeaderRow>
      {!isCollapsed && (
        <HeaderRow>
          <HeaderContentWrapper>{headerContent}</HeaderContentWrapper>
          {!!icon && <IconWrapper onClick={onIconClick}>{icon}</IconWrapper>}
        </HeaderRow>
      )}
      {!isCollapsed && (
        <SidebarWrapper>
          {loading
            ? (<Loader center />)
            : (
              <TreeContainer rightPadding={rightPadding} userTree={userTree}>
                {!content && treeData && (
                  <SidebarTree
                    withSort
                    id={treeId}
                    data={treeData}
                    searchQuery={searchQuery}
                    onSelectNode={onSelect}
                    getTelemetry={getTelemetry}
                    telemetryHash={telemetryHash}
                  />
                )}
                {content}
              </TreeContainer>
            )}
        </SidebarWrapper>
      )}
    </PageSidebarStyled>
  )
}

PageSidebar.propTypes = {
  onCollapse: pt.func,
  onIconClick: pt.func,
  title: pt.oneOfType([pt.string, pt.number, pt.element]),
  content: pt.oneOfType([pt.string, pt.number, pt.element]),
  headerContent: pt.oneOfType([pt.string, pt.number, pt.element]),
  icon: pt.element,
  isFilterMode: pt.bool,
  typesOfRegionPins: pt.arrayOf(pt.object),
  excludedTypes: pt.arrayOf(pt.string),
  treeData: pt.arrayOf(pt.object),
  mapKey: pt.oneOfType([pt.string, pt.number]),
  searchQuery: pt.string,
  telemetryHash: pt.string,
  getTelemetry: pt.func,
  onSelect: pt.func,
  renderFilterItem: pt.func,
  headerIcon: pt.element,
  headerIconDescription: pt.oneOfType([pt.string, pt.number, pt.element]),
  onHeaderIconClick: pt.func,
  withoutRequest: pt.bool,
}
PageSidebar.defaultProps = {
  onCollapse: noop,
  onIconClick: noop,
  title: null,
  content: null,
  headerContent: null,
  icon: null,
  isFilterMode: false,
  typesOfRegionPins: [],
  excludedTypes: [],
  treeData: [],
  mapKey: '',
  searchQuery: '',
  telemetryHash: '',
  getTelemetry: noop,
  onSelect: noop,
  renderFilterItem: noop,
  headerIcon: null,
  headerIconDescription: null,
  onHeaderIconClick: noop,
  withoutRequest: false,
}

export default React.memo(PageSidebar)
