import { CHANGE_REPORT_MANAGER } from './index'

export const requestChangeReport = (payload) => ({
  type: CHANGE_REPORT_MANAGER.REQUEST,
  payload,
})

export const successChangeReport = (payload) => ({
  type: CHANGE_REPORT_MANAGER.SUCCESS,
  payload,
})

export const errorChangeReport = (payload) => ({
  type: CHANGE_REPORT_MANAGER.ERROR,
  payload,
})
