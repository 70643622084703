import { fork, takeLatest, all } from 'redux-saga/effects'

import {
  UPLOAD_FILE_NOTIFICATION,
} from '@/store/actions/installation'
import installationUploadFileSaga from './installationUploadFile'

function* watchStateRequest() {
  yield takeLatest(UPLOAD_FILE_NOTIFICATION.EVENTS, installationUploadFileSaga)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
