import {
  takeEvery, put, all
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SERVICE_DESK_GET_APPEALS_URL } from '@/constants/apiRoutes'
import { GET_SERVICE_DESK_CARDS } from '@/store/actions/serviceDeskMyCity'
import { successGetInfoCards } from '@/store/actions/serviceDeskMyCity/getInfoCards'
import { getAverageDays } from '@/helpers/serviceDeskHelpers'
import {
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  WAITING_FOR_CONTROL,
  OVERDUE_ON_EXECUTION,
  AVERAGE_LEAD_TIME,
} from '@/constants/serviceDeskMyCity'
import {
  TASK_STATUS,
  EXECUTION_EXPIRED,
  APPEALS_BLOCK,
} from '@/constants/serviceDeskConfig/nameConfig'

const bodyGenerator = (serviceDeskConfig, id, parameters) => {
  return {
    integrationId: id,
    parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${serviceDeskConfig[APPEALS_BLOCK].idInfoBlock}&start=0${parameters || ''}`,
    restMethod: '/lists.element.get.json',
  }
}

const cardsInfo = [
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  WAITING_FOR_CONTROL,
  OVERDUE_ON_EXECUTION,
]

function* getServiceDeskCardSaga({ payload }) {
  const { serviceDeskId, serviceDeskConfig } = payload
  try {
    const response = yield all([
      request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId),
      }),
      request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=completed`),
      }),
      request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=inProgress`),
      }),
      request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[TASK_STATUS].id}]=waitingControl`),
      }),
      request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(serviceDeskConfig, serviceDeskId, `&FILTER[PROPERTY_${serviceDeskConfig[EXECUTION_EXPIRED].id}]=1`),
      }),
    ])
    const averageDays = getAverageDays(response[0].data.result, serviceDeskConfig)
    const sortedResponse = response.map((element, index) => ({
      type: cardsInfo[index],
      value: element.data.total || 0,
    }))
    sortedResponse.push({
      type: AVERAGE_LEAD_TIME,
      value: averageDays,
    })
    yield put(successGetInfoCards(sortedResponse))
  } catch (error) {
    console.log('TCL: function*getServiceDeskCardSaga -> error', error)
  }
}

export default function* root() {
  yield takeEvery(GET_SERVICE_DESK_CARDS.REQUEST, getServiceDeskCardSaga)
}
