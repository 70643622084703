import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const UserAvatarIcon = ({ onClick }) => {
  return (
    <svg onClick={onClick} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="14" fill="#E2F1FE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z" fill="#00639A"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5497 16.5379C10.18 16.7062 7.5 19.4825 7.5 22.8824C7.5 24.0524 8.45136 25 9.625 25H22.375C23.5486 25 24.5 24.0524 24.5 22.8824C24.5 19.4825 21.82 16.7062 18.4503 16.5379C18.3426 16.5326 18.2341 16.5294 18.125 16.5294H13.875C13.7659 16.5294 13.6574 16.5326 13.5497 16.5379ZM13.1888 14.4118C13.938 15.0704 14.9222 15.4706 16 15.4706C17.0778 15.4706 18.062 15.0704 18.8112 14.4118C19.6935 13.6356 20.25 12.5006 20.25 11.2353C20.25 8.89635 18.3472 7 16 7C13.6528 7 11.75 8.89635 11.75 11.2353C11.75 12.5006 12.3065 13.6356 13.1888 14.4118ZM21.8438 22.8824C22.1371 22.8824 22.375 22.6452 22.375 22.3529V21.8235C22.375 19.4846 20.4722 18.6471 18.125 18.6471H13.875C11.5278 18.6471 9.625 19.4846 9.625 21.8235V22.3529C9.625 22.6452 9.86289 22.8824 10.1562 22.8824H21.8438ZM16 13.3529C17.1736 13.3529 18.125 12.4053 18.125 11.2353C18.125 10.0653 17.1736 9.11765 16 9.11765C14.8264 9.11765 13.875 10.0653 13.875 11.2353C13.875 12.4053 14.8264 13.3529 16 13.3529Z" fill="#00639A"/>
    </svg>

  )
}

UserAvatarIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserAvatarIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserAvatarIcon)
