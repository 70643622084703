import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import { FormattedMessage as Lang } from 'react-intl'
import {
  GROUP
} from '@/constants/viewTree'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import MultipleUserEditForm from '@/forms/MultipleUserEditForm'
import {
  ASC,
} from '@/constants/names'
import {
  ADMIN_SURNAME,
} from '@/constants/forms/admin'
import UserFormHeader from '@/components/blocks/Header'
import {
  Container
} from './styles'

const MultipleUserEdit = ({
  selectedUsersGroup,
  selectedTenant,
  onDelete,
  onSubmit,
  users,
  selectedUsersIdArray,
  setSelectedUsersIdArray,
  updateTableSorting,
  closeForm,
}) => {
  const formRef = React.createRef()
  const [isValidForm, setValidForm] = useState(false)
  const [isAllBlock, setIsAllBlock] = useState(false)

  useEffect(() => {
    const allUsers = Object.keys(users).reduce((accumulator, element) => [
      ...accumulator,
      ...users[element],
    ], [])
    const isAllBlocked = selectedUsersIdArray.every(
      (selectedElementId) => allUsers
        .findIndex((user) => ((user.id === selectedElementId) && user.blocked)) !== -1,
    )
    if (selectedUsersIdArray.length > 0) {
      setIsAllBlock(isAllBlocked)
    } else {
      setIsAllBlock(false)
    }
  }, [isAllBlock, setIsAllBlock, selectedUsersIdArray, users])

  useEffect(() => {
    setValidForm(false)
  }, [selectedTenant, selectedUsersGroup])

  const onSubmitHandler = useMemo(() => () => {
    setSelectedUsersIdArray([])
    onSubmit({
      form: formRef.current,
      blocked: !isAllBlock,
      selectedIds: selectedUsersIdArray,
      tenantId: selectedUsersGroup.tenantId,
      groupId: selectedUsersGroup.id,
    })
    updateTableSorting({
      sortType: ADMIN_SURNAME,
      sortValue: ASC,
    })
    if (formRef.current && formRef.current.resetForm) {
      formRef.current.resetForm({})
    }
  },
  [
    setSelectedUsersIdArray,
    onSubmit,
    formRef,
    selectedUsersIdArray,
    selectedUsersGroup.tenantId,
    selectedUsersGroup.id,
    updateTableSorting,
    isAllBlock,
  ])

  const onDeleteHandler = useCallback(() => {
    setSelectedUsersIdArray([])
    onDelete({
      selectedIds: selectedUsersIdArray,
      tenantId: selectedUsersGroup.tenantId,
      groupId: selectedUsersGroup.id,
    })
    if (formRef.current && formRef.current.resetForm) {
      formRef.current.resetForm({})
    }
  },
  [
    setSelectedUsersIdArray,
    onDelete,
    formRef,
    selectedUsersIdArray,
    selectedUsersGroup.tenantId,
    selectedUsersGroup.id,
  ])

  const handleDebounceDelete = debounce(() => {
    onDeleteHandler()
  }, DEBOUNCE_DELAY_SMALL)

  useEffect(() => {
    if (Object.keys(users).length > 0 && selectedUsersGroup.id) {
      const groupUsers = users[`${selectedUsersGroup.tenantId}-${selectedUsersGroup.id}`] || []
      if (selectedUsersIdArray.length === groupUsers.length) {
        return noop
      }
    }
    return noop
  }, [selectedUsersGroup, selectedUsersIdArray, users])

  const selectOptions = selectedTenant.children.reduce((accumulator, element) => {
    if (element.type === GROUP && element.id !== selectedUsersGroup.id) {
      return [
        ...accumulator,
        {
          value: element.id,
          title: element.name,
        },
      ]
    }
    return accumulator
  }, [])

  return (
    <Container>
      <UserFormHeader
        multipleEdit
        editTitle={<Lang id="usersPage.formHeader.changeGroup" />}
        editMode={isValidForm}
        deleteForm={handleDebounceDelete}
        saveForm={onSubmitHandler}
        closeForm={closeForm}
        title={`Выбрано: ${selectedUsersIdArray.length || 0}`}
        setFormIsModifying={onSubmitHandler}
        popupSettings={{
          error: {
            title: <Lang id="usersPage.popup.attention" />,
            message: <Lang id="usersPage.popup.changeUserGroup" />,
          },
          warning: {
            title: <Lang id="usersPage.popup.impossibleExecute" />,
            message: <Lang id="usersPage.popup.saveUser" />,
          },
        }}
        blockCondition={isAllBlock}
        conditionsForAdding
      />
      <MultipleUserEditForm
        ref={formRef}
        isAllBlock={isAllBlock}
        blockForm={false}
        setValidForm={setValidForm}
        options={selectOptions}
      />
    </Container>
  )
}

MultipleUserEdit.propTypes = {
  selectedTenant: pt.objectOf(pt.object),
  selectedUsersGroup: pt.objectOf(pt.object),
  users: pt.objectOf(pt.arrayOf(pt.object)),
  closeForm: pt.func,
  selectedUsersIdArray: pt.arrayOf(pt.number),
  setSelectedUsersIdArray: pt.func,
  onSubmit: pt.func,
  updateTableSorting: pt.func,
}
MultipleUserEdit.defaultProps = {
  selectedUsersGroup: {},
  selectedTenant: {},
  closeForm: noop,
  selectedUsersIdArray: [],
  setSelectedUsersIdArray: noop,
  onSubmit: noop,
  updateTableSorting: noop,
  users: {},
}
export default MultipleUserEdit
