export default {
  usersOne: 'Пользователь',
  usersTwo: 'Пользователя',
  usersSome: 'Пользователей',
  users: 'Пользователи',
  userProfile: 'Профиль пользователя',
  passportization: 'Паспортизация',
  installation: 'Инсталляция',
  inventorisation: 'Инвентаризация',
  alarmsOne: 'Проблема',
  alarmsTwo: 'Проблемы',
  alarmsSome: 'Проблем',
  alarms: 'Журнал неисправностей',
  individualControl: 'Индивидуальный контроль',
  groupControl: 'Групповой контроль',
  operationAndTechnicalSupport: 'Эксплуатация и тех. поддержка',
  search: 'Расширенный поиск в системе',
  installObjectsOne: 'Инсталяция',
  installObjectsTwo: 'Инсталяции',
  installObjectsSome: 'Инсталяций',
  reportManager: 'Менеджер отчетов',
  life: 'Срок службы',
  scheduleManager: 'Менеджер расписаний',
  controlCenter: 'Управление инсталляциямия',
  objectsOne: 'Объект',
  objectsTwo: 'Объекта',
  objectsSome: 'Объектов',
  energyConsumption: 'Потребление энергии',
  alertManager: 'Менеджер оповещений',
  activityLog: 'Журнал действий',
  changeLog: 'Журнал изменений',
  equipmentReference: 'Справочник оборудования',
  carElectricStationsIcon: 'Зарядные автостанции',
  waterSupply: 'Водоснабжение',
  heatSupply: 'Теплоснабжение',
  smartRecycleBin: 'Контроль наполняемости мусорных баков',
  ecoStations: 'Экостанции',
  settings: 'Параметры системы',
  statisticsOnObjectsControl: 'Статистика по объектам контроля',
  analyticExpenses: 'Аналитика расходов',
  analyticConsumption: 'Аналитика\nпотребл. ресурсов',
  eventsCount: 'Событий',
  eventsNewCount: 'Новых',
  mountOne: 'Инсталляция',
  mountTwo: 'Инсталляции',
  mountSome: 'Инсталляций',
  projectOne: 'Проект',
  projectTwo: 'Проекта',
  projectSome: 'Проектов',
  intergationWithService: 'Интеграция с сервисом:',
  connect: 'Подключиться',
  disconnect: 'Отключиться',
  chargingStation: 'Зарядные автостанции',
  architectureIllumination: 'Архитектурное освещение',
  garbageCollection: 'Контроль наполняемости мусорных баков',
  roadInfrastructure: 'Мониторинг дорожной\nинфраструктуры',
  trafficLight: 'Управление светофорами',
  infoFrame: 'Размещение инфотабло',
  handicappedPlaces: 'Контроль занятости\nмест для инвалидов',
  support: 'Сервис Деск «Мой город»',
  widgetsDashboard: 'Дашборд',
  widgetsDashboardGov: 'Дашборд 2',
  apiUrl: 'API URL',
  port: 'Port',
  login: 'Логин',
  password: 'Пароль',
  myDescSettingsTitle: 'Для использования приложения\nего необходимо настроить',
  setup: 'Настроить виджет',
  receiveMessages: 'Получено обращений',
  execution: 'Исполнено',
  clientID: 'ClientID*',
  secretID: 'SecretID*',
  light: 'Наружное освещение',
  tyumenOurHome: 'Портал Тюмень - НАШ ДОМ',
  name: 'Название*',
  fileWithSettings: 'Файл с настройками*',
  url: 'URL*',
}
