import React, { PureComponent } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { getKeyByType } from '@/constants/viewTree'
import Wrapper from './styles'

class Container extends PureComponent {
  renderToggleDecorator() {
    const { decorators, onClick, node } = this.props
    return <decorators.Toggle onClick={onClick} node={node} />
  }

  render() {
    const {
      decorators, terminal,
      node, onSelect, onClick,
      excludedTypes,
    } = this.props
    return (
      <>
        {!excludedTypes.includes(node.type)
          ? (
            <Wrapper
              onMouseEnter={onSelect(node, 'in')}
              onMouseLeave={onSelect(node, 'out')}
              key={getKeyByType(node)}
            >
              <decorators.Header
                node={node}
                onSelect={onSelect}
                onClick={onClick}
              />
              {!terminal ? this.renderToggleDecorator() : null}
            </Wrapper>
          )
          : null }
      </>
    )
  }
}

Container.defaultProps = {
  onSelect: noop,
  excludedTypes: [],
}

Container.propTypes = {
  decorators: pt.shape({}).isRequired,
  terminal: pt.bool.isRequired,
  onClick: pt.func.isRequired,
  onSelect: pt.func,
  node: pt.shape({
    id: pt.number.isRequired,
    name: pt.string.isRequired,
    type: pt.string.isRequired,
    children: pt.arrayOf(pt.objectOf),
    active: pt.bool,
    status: pt.string,
    toggle: pt.bool,
  }).isRequired,
  excludedTypes: pt.arrayOf(pt.string),
}

export default Container
