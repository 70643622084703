import { all } from 'redux-saga/effects'

import getTable from './getTable'
import getTypes from './getTypes'
import getObjectTypes from './getObjectTypes'
import getCommonUsers from './getCommonUsers'
import createReport from './createReport'
import getReport from './getReport'
import deleteReport from './deleteReport'
import changeReport from './changeReport'
import getFile from './getFile'

export default function* root() {
  yield all([
    getTable(),
    getTypes(),
    getObjectTypes(),
    getCommonUsers(),
    createReport(),
    getReport(),
    deleteReport(),
    changeReport(),
    getFile(),
  ])
}
