import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AverageLeadTimeIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.05">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3308 30.9642L95.9498 1.25727L97.5021 5.52203L95.9621 1.25284C101.086 -0.595913 106.729 -0.355987 111.657 1.9202C116.584 4.19638 120.397 8.3238 122.261 13.4007L122.266 13.4128L140.893 64.5899L140.897 64.6021C142.732 69.6896 142.465 75.302 140.153 80.2132C137.841 85.1243 133.673 88.935 128.559 90.8127L128.547 90.8172L84.9844 106.673L67.8194 135.949C66.9248 137.474 65.2256 138.352 63.4605 138.2C61.6953 138.047 60.1829 136.893 59.5805 135.238L53.3716 118.179L46.928 120.524L46.9157 120.528C41.7913 122.377 36.1489 122.137 31.2211 119.861C26.2933 117.585 22.4808 113.458 20.6165 108.381L20.612 108.368L1.98068 57.1793C0.145476 52.0918 0.413006 46.4793 2.7248 41.5682C5.03659 36.657 9.20474 32.8463 14.3186 30.9686L14.3308 30.9642ZM99.0477 9.78919L17.4419 39.4913C14.5984 40.537 12.2809 42.6568 10.9951 45.3883C9.70933 48.1198 9.5598 51.241 10.579 54.0709L29.2011 105.235C30.2394 108.058 32.3603 110.353 35.101 111.619C37.8418 112.885 40.9799 113.019 43.8304 111.992L54.5628 108.086C56.9353 107.222 59.5535 108.432 60.4108 110.787L64.8568 123.002L77.5126 101.417C78.3219 100.036 79.5936 98.9751 81.1024 98.4261L125.436 82.2902C128.279 81.2445 130.597 79.1246 131.883 76.393C133.168 73.6615 133.318 70.5401 132.299 67.7101L113.677 16.5467C112.638 13.7236 110.518 11.4286 107.777 10.1626C105.036 8.89667 101.898 8.76249 99.0477 9.78919Z" fill={fill} className="svgFill" />
        <path d="M88.6647 42.4355C87.9287 41.99 87.6072 41.067 87.9704 40.2871L89.6317 36.7191C90.0049 35.9176 89.658 34.9645 88.8569 34.5904L85.9563 33.2359C85.1554 32.8618 84.2036 33.2081 83.8302 34.0093L81.7684 38.4341C81.4582 39.0998 80.7357 39.4626 80.0084 39.3621C76.5276 38.8809 72.8884 39.1975 69.3617 40.4811C57.7576 44.7047 51.7531 57.5951 55.9825 69.2153C60.2119 80.8355 73.0975 86.8505 84.7015 82.6269C96.3056 78.4034 102.31 65.513 98.0807 53.8928C96.333 49.0978 93.0027 45.0612 88.6647 42.4355ZM82.5101 76.6061C74.2198 79.6236 65.0174 75.3261 61.9965 67.0264C58.9757 58.7266 63.2628 49.5194 71.5531 46.502C79.8435 43.4845 89.0459 47.782 92.0667 56.0817C95.0876 64.3814 90.8005 73.5887 82.5101 76.6061Z" fill={fill} className="svgFill" />
        <path d="M71.2854 55.1225C70.9828 54.2912 71.4107 53.3722 72.241 53.07L75.248 51.9756C76.0784 51.6733 76.9968 52.1022 77.2994 52.9335L80.5865 61.9648C80.8891 62.7961 80.4612 63.715 79.6308 64.0172L76.6238 65.1117C75.7935 65.4139 74.875 64.985 74.5725 64.1537L71.2854 55.1225ZM60.6084 35.144C60.3059 34.3127 60.7337 33.3938 61.5641 33.0916L70.5851 29.8082C71.4155 29.5059 72.3339 29.9348 72.6365 30.7661L73.7322 33.7766C74.0348 34.6079 73.6069 35.5268 72.7765 35.829L63.7555 39.1124C62.9251 39.4146 62.0067 38.9857 61.7041 38.1544L60.6084 35.144ZM36.0168 61.1407C35.7142 60.3094 36.1421 59.3905 36.9724 59.0883L45.9935 55.8049C46.8238 55.5027 47.7423 55.9316 48.0448 56.7629L49.1405 59.7733C49.4431 60.6046 49.0152 61.5235 48.1849 61.8257L39.1638 65.1091C38.3335 65.4114 37.415 64.9825 37.1125 64.1512L36.0168 61.1407ZM44.7824 85.2241C44.4798 84.3928 44.9077 83.4739 45.738 83.1716L54.7591 79.8882C55.5895 79.586 56.5079 80.0149 56.8105 80.8462L57.9062 83.8566C58.2087 84.6879 57.7809 85.6068 56.9505 85.9091L47.9295 89.1925C47.0991 89.4947 46.1807 89.0658 45.8781 88.2345L44.7824 85.2241ZM37.3926 74.2769C37.09 73.4456 37.5179 72.5267 38.3482 72.2244L47.3392 68.952C48.1696 68.6497 49.088 69.0786 49.3906 69.9099L50.4863 72.9204C50.7888 73.7517 50.361 74.6706 49.5306 74.9728L40.5396 78.2453C39.7093 78.5475 38.7908 78.1186 38.4883 77.2873L37.3926 74.2769Z" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

AverageLeadTimeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AverageLeadTimeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AverageLeadTimeIcon)
