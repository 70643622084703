import { UPLOAD_INSTALLATION_OBJECT_FILE } from './index'

export const requestUploadInstallationObjectFile = (payload) => ({
  type: UPLOAD_INSTALLATION_OBJECT_FILE.REQUEST,
  payload,
})

export const successUploadInstallationObjectFile = (payload) => ({
  type: UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS,
  payload,
})

export const errorUploadInstallationObjectFile = (payload) => ({
  type: UPLOAD_INSTALLATION_OBJECT_FILE.ERROR,
  payload,
})
