export const COUNTRY = 'country'
export const REGION = 'region'
export const CITY = 'city'
export const CONTROLLER = 'controller'
export const LIGHT = 'light'
export const GROUP_OBJECT = 'groupObject'
export const HEATING_DEVICE = 'heatingDevice'
export const COLD_WATER_DEVICE = 'coldWaterDevice'
export const TRAFFIC = 'traffic'
export const TRASH = 'trash'
export const ASUNO = 'asuno'
export const BASE_STATION = 'baseStation'
export const SHUO = 'shuo'
export const MULTI_TARIFF_ELECTRICITY_METER = 'multiTariffElectricityMeter'
export const UNICERSE_METER = 'unicerseMeter'
export const LET = 'let'
export const LIGHT_MODULE = 'lightModule'
export const SUPPORT = 'support'
export const LINE = 'line'
export const MULTIRATE_METER = 'universalMeter'
export const LIGHT_FIXTURE = 'powerLine'
export const UTILITY_POLE = 'support'
export const LAMP = 'lamp'
export const LAMP_MODULE = 'lightModule'
export const LIGHT_SENSOR = 'light_sensor'
export const OBJECT = 'object'

export const ELECTRIC_METER = 'electricMeter'
export const CONTACTORS = 'contactors'
export const CONTACTOR = 'contactor'
export const FUSE = 'FUSE'
export const PHASE = 'PHASE'
export const CURRENT_SENSOR = 'currentSensor'
export const BRANCH_CIRCUIT = 'branchCircuit'
export const CHECK_POINT = 'checkPoint'
export const DMX_DEVICE = 'dmxdevice'

export const CONTROL_CUPBOARD_SERVER = 'CONTROL_CUPBOARD'
export const BASE_STATION_SERVER = 'BASE_STATION'
export const LIGHT_FIXTURE_SERVER = 'LIGHT_FIXTURE'
export const LAMP_SERVER = 'LAMP'
export const UTILITY_POLE_SERVER = 'UTILITY_POLE'
export const LINE_SERVER = 'LINE'
export const CONTROLLER_SERVER = 'CONTROLLER'
export const MULTIRATE_METER_SERVER = 'MULTIRATE_METER'
export const AUTOMATED_CONTROL_SYSTEM_SERVER = 'AUTOMATED_CONTROL_SYSTEM'
export const LAMP_MODULE_SERVER = 'LAMP_MODULE'

export const OUTDOOR_LIGHTING = 'OUTDOOR_LIGHTING'
export const HEAT_SUPPLY = 'HEAT_SUPPLY'
export const WATER_SUPPLY = 'WATER_SUPPLY'

export const NODE_CO = 'NODE_CO'

export const INTEGRATION = 'INTEGRATION'
export const GEOZONE = 'GEOZONE'
export const PROJECT = 'PROJECT'
export const ROOT = 'ROOT'
export const CONTROL_OBJECT = 'CONTROL_OBJECT'
export const OBJECT_ELEMENT = 'OBJECT_ELEMENT'
export const DOWNLOAD_INTALATION_FILE = 'DOWNLOAD_INTALATION_FILE'

export const OBJECT_NAME_CONFIG = {
  [CONTROLLER]: 'Контроллер',
  [CONTACTOR]: 'Контактор',
  [BRANCH_CIRCUIT]: 'Отходящая линия',
  [FUSE]: 'Вставка',
  [TRASH]: 'Мусорный бак',
  [LIGHT_SENSOR]: 'Датчик освещенности',
  [SHUO]: 'ШУНО',
}
