import React from 'react'
import memoWithName from '@/hocs/memoWithName'

const SelectedPinIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.71429 0H12.5714C12.7988 0 13.0168 0.0903058 13.1775 0.251051C13.3383 0.411797 13.4286 0.629814 13.4286 0.857143V1.18457C13.4285 1.34371 13.3841 1.49968 13.3004 1.63502C13.2167 1.77036 13.0969 1.87972 12.9546 1.95086L11.7143 2.57143V6.85714L14.0346 9.17743C14.1953 9.33814 14.2857 9.55612 14.2857 9.78343V11.1429C14.2857 11.3702 14.1954 11.5882 14.0347 11.7489C13.8739 11.9097 13.6559 12 13.4286 12H10V15.4286L9.14286 18L8.28571 15.4286V12H4.85714C4.62981 12 4.4118 11.9097 4.25105 11.7489C4.09031 11.5882 4 11.3702 4 11.1429V9.78343C4.00005 9.55612 4.09039 9.33814 4.25114 9.17743L6.57143 6.85714V2.57143L5.33114 1.95086C5.18879 1.87972 5.06905 1.77036 4.98533 1.63502C4.90161 1.49968 4.85723 1.34371 4.85714 1.18457V0.857143C4.85714 0.629814 4.94745 0.411797 5.10819 0.251051C5.26894 0.0903058 5.48696 0 5.71429 0Z" fill="url(#paint0_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="9.14286" y1="0" x2="9.14286" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FBBA58" />
        <stop offset="1" stopColor="#FF9A02" />
      </linearGradient>
    </defs>
  </svg>
)

export default memoWithName(SelectedPinIcon)
