import {
  RATING_COLUMN_NAME,
  APPEALS_COLUMN_NAME,
  INFRASTRUCTURE_COLUMN_NAME,

  RATING_DYNAMIC,
  NATIONAL_RATING,
  PUBLIC_APPEALS,
  APPEALS_DYNAMIC,
  APPEALS_TIMING,
  INFRASTRUCTURE,
  CONNECTED_DEVICES,
  LIGHTS_STATE,
} from '@/constants/widgetsDashboardGov'

export default {
  myCity: 'My City:',
  time: 'Local time:',
  [RATING_COLUMN_NAME]: 'Ratings',
  [APPEALS_COLUMN_NAME]: 'Working with appeals',
  [INFRASTRUCTURE_COLUMN_NAME]: 'City Infrastructure Status',
  [RATING_DYNAMIC]: 'Mayors rating dynamics',
  [NATIONAL_RATING]: 'National Mayors Ranking',
  [PUBLIC_APPEALS]: 'Working with public appeals',
  [APPEALS_DYNAMIC]: 'Dynamics of handling appeals',
  [APPEALS_TIMING]: 'Compliance with the deadlines for consideration of current applications',
  [INFRASTRUCTURE]: 'Smart City Infrastructure',
  [CONNECTED_DEVICES]: 'Connected equipment to the platform',
  [LIGHTS_STATE]: 'Status of outdoor lighting objects',
}