import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'
import PopupIconsWrapper from '../styles'

const ResetFilterIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <PopupIconsWrapper>
      <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.98348 1.46325e-05C6.57856 0.00433318 4.39501 0.952018 2.78292 2.49261L1.48685 1.19654C0.93814 0.647833 0 1.03643 0 1.81239V6.67743C0 7.15846 0.389939 7.5484 0.870967 7.5484H5.73601C6.51197 7.5484 6.90056 6.61025 6.35189 6.06155L4.83677 4.54643C5.95683 3.49767 7.40616 2.91674 8.94592 2.90346C12.2991 2.8745 15.1255 5.5881 15.0965 9.05256C15.069 12.339 12.4046 15.0968 8.99999 15.0968C7.50748 15.0968 6.09688 14.5641 4.9852 13.5887C4.81307 13.4377 4.55312 13.4469 4.3912 13.6088L2.95185 15.0481C2.77505 15.2249 2.78379 15.5132 2.96934 15.6808C4.56525 17.1222 6.6801 18 8.99999 18C13.9705 18 17.9999 13.9706 18 9.00008C18 4.03524 13.9483 -0.00887649 8.98348 1.46325e-05Z" fill={fill} className="svgFill" />
      </svg>
    </PopupIconsWrapper>
  )
}

ResetFilterIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ResetFilterIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ResetFilterIcon)
