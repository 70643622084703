import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_ALARM_MANAGER_TABLE = createAction('@/GET_ALARM_MANAGER_TABLE')
export const GET_ALARM_MANAGER_FILE = createAction('@/GET_ALARM_MANAGER_FILE')
export const GET_ALARMS_COUNT = createAction('@/GET_ALARMS_COUNT')
export const GET_TABLE_STATISTICS = createAction('@/GET_TABLE_STATISTICS')
export const CHECK_ALL_ALARMS = createAction('@/CHECK_ALL_ALARMS')
export const ALARM_MANAGER_TABLE = createTableActions('@/ALARM_MANAGER_TABLE')

export const SET_EVENTS_TYPES = '@/ALARM_MANAGER_SET_EVENTS_TYPES'
export const SET_STATUS_TYPES = '@/ALARM_MANAGER_SET_STATUS_TYPES'
export const SET_SELECTED_ELEMENT = '@/ALARM_MANAGER_SET_SELECTED_ELEMENT'
export const SET_SELECTED_ALL_ELEMENTS = '@/ALARM_MANAGER_SET_SELECTED_ALL_ELEMENTS'
export const SET_EVETS_DATA = '@/ALARM_MANAGER_SET_EVETS_DATA'
export const SET_DATES_RANGE = '@/ALARM_MANAGER_SET_DATES_RANGE'
export const SET_TENANT_ID = '@/ALARM_MANAGER_SET_TENANT_ID'

export const setDatesRange = (payload) => ({
  type: SET_DATES_RANGE,
  payload,
})

export const setTenantId = (payload) => ({
  type: SET_TENANT_ID,
  payload,
})

export const setEventsData = (payload) => ({
  type: SET_EVETS_DATA,
  payload,
})

export const setSelectedElement = (payload) => ({
  type: SET_SELECTED_ELEMENT,
  payload,
})

export const setSelectedAllElements = (payload) => ({
  type: SET_SELECTED_ALL_ELEMENTS,
  payload,
})

export const setEventTypes = (payload) => ({
  type: SET_EVENTS_TYPES,
  payload,
})

export const setStatusTypes = (payload) => ({
  type: SET_STATUS_TYPES,
  payload,
})
