import {
  put, takeLatest, select, call, take,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import updateNodeCount from '@/helpers/updateNodeCount'
import request from '@/helpers/axios'
import { successCreateObject } from '@/store/actions/installation/createObject'
import {
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import {
  NOT_VERIFIED,
} from '@/constants/objectStatuses'
import {
  getCard,
} from '@/store/selectors/installation'
import createNotifications from '@/helpers/notificationSmall'
import installationFormatFields from '@/helpers/installationFormatFields'
import errorsMapper from '@/constants/errors/createObjectElement'
import { CREATE_OBJECT, UPLOAD_INSTALLATION_OBJECT_FILE } from '@/store/actions/installation'
import { INSTALLATIONS_OBJECT_ENDPOINT } from '@/constants/apiRoutes'
import { requestUploadInstallationObjectFile } from '@/store/actions/installation/uploadObjectFile'

function* createObjectSaga({ payload }) {
  const {
    formValues,
    values,
    setUrlFormValues,
    type,
    setErrors,
    setAlertConfig,
  } = payload
  const valuesDeepClone = cloneDeep(values)
  set(valuesDeepClone, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', 'DEFAULT_EQUIPMENT_TYPE')
  const toast = createNotifications()
  const cardCopy = yield select(getCard)
  delete cardCopy.elementsInfo
  try {
    const objectElementName = get(valuesDeepClone, 'GENERAL_INFORMATION.NAME', null)
    const latitude = get(valuesDeepClone, 'LOCATION.LATITUDE', null)
    const longitude = get(valuesDeepClone, 'LOCATION.LONGITUDE', null)
    const integrationType = get(cardCopy, 'data.integrationType', null)
    const integrationId = get(cardCopy, 'data.integrationId', null)
    const parentTreeElementId = get(cardCopy, 'data.id', null)
    const photoFile = get(values, 'ATTACHMENT.PHOTO_TITLE', null)
    const PDFFile = get(values, 'ATTACHMENT.DOCUMENT_TITLE', null)
    delete valuesDeepClone.LOCATION.LATITUDE
    delete valuesDeepClone.LOCATION.LONGITUDE
    delete valuesDeepClone.GENERAL_INFORMATION.NAME
    delete valuesDeepClone.GENERAL_INFORMATION.EQUIPMENT_TYPE
    delete valuesDeepClone.geoLocationDto
    delete valuesDeepClone.ATTACHMENT
    delete valuesDeepClone.objectElementFieldsDto
    delete valuesDeepClone.elementsInfo
    const fields = Object.keys(valuesDeepClone).reduce((accumulator, rootName) => {
      if (typeof valuesDeepClone[rootName] !== 'object') {
        return accumulator
      }
      const childField = Object.keys(valuesDeepClone[rootName] || {}).reduce((buffer, childName) => {
        if (childName === 'customFields') {
          const customFields = get(valuesDeepClone, `${rootName}.customFields`, []).reduce((customFieldsAccumulator, element) => {
            if (element.fieldName && element.value && !element.remove) {
              return [
                ...customFieldsAccumulator,
                {
                  ...element,
                  fieldName: element.fieldName,
                  value: element.value,
                  passportBlock: rootName,
                },
              ]
            }
            return customFieldsAccumulator
          }, [])
          return [
            ...buffer,
            ...customFields,
          ]
        }
        return [
          ...buffer,
          {
            fieldName: `${rootName}.${childName}`,
            value: get(valuesDeepClone, `${rootName}.${childName}`, null),
            passportBlock: rootName,
          },
        ]
      }, [])
      return [
        ...accumulator,
        ...childField,
      ]
    }, [])
    const formattedFields = installationFormatFields(fields)
    const formattedFieldsWithFilterOutEmptyFields = formattedFields.filter((el) => el.value)
    const body = {
      objectElementName,
      integrationId,
      parentTreeElementId,
      geoLocationDto: {
        latitude,
        longitude,
      },
      objectElementFieldsDto: formattedFieldsWithFilterOutEmptyFields,
      integrationType,
      objectType: type,
      objectState: NOT_VERIFIED,
      type: OBJECT_ELEMENT,
    }
    const { data } = yield request({
      url: INSTALLATIONS_OBJECT_ENDPOINT,
      method: 'post',
      body,
    })
    if (photoFile) {
      yield put(requestUploadInstallationObjectFile({
        elementId: data.treeElementId,
        file: photoFile,
        title: photoFile.name,
        fileType: 'PHOTO',
      }))
      yield take([UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS, UPLOAD_INSTALLATION_OBJECT_FILE.ERROR])
    }
    if (PDFFile) {
      yield put(requestUploadInstallationObjectFile({
        elementId: data.treeElementId,
        file: PDFFile,
        title: PDFFile.name,
        fileType: 'DOCUMENT',
      }))
      yield take([UPLOAD_INSTALLATION_OBJECT_FILE.SUCCESS, UPLOAD_INSTALLATION_OBJECT_FILE.ERROR])
    }
    setAlertConfig({})
    const {
      rootId, path, parentGeoZoneId, FILTER,
    } = formValues

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentTreeElementId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.treeElementId)
    tree.handlers.setSelected(node, true)
    updateNodeCount(tree, path, 'add')
    tree.handlers.rerender()
    setUrlFormValues({
      id: data.treeElementId,
      type: data.type,
      rootId,
      parentGeoZoneId,
      parentTreeId: data.parentTreeElementId,
      path: `${path}/${data.treeElementId}`,
      FILTER,
    })
    toast({
      title: 'Инсталляция завершена',
      type: 'success',
      description: 'Объект добавлен в систему.',
    })
    yield put(successCreateObject())
  } catch (error) {
    const errors = get(error, 'response.data.details', []).reduce((accumulator, element) => {
      const errorMessageAndLocation = element.message.split(': ')
      return {
        ...accumulator,
        [errorMessageAndLocation[1]]: errorsMapper[errorMessageAndLocation[0]] || errorMessageAndLocation[0],
      }
    }, {})
    const errorObject = {}
    Object.keys(errors).map((errorLocation) => {
      set(errorObject, errorLocation, errors[errorLocation])
      return errorLocation
    })
    setErrors(errorObject)
    console.log('function*createObjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(CREATE_OBJECT.REQUEST, createObjectSaga)
}
