import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const MoonIcon = ({ onClick }) => (
  <svg onClick={onClick} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7778 1C6.33333 1 1 5.44444 1 12.5556C1 19.6667 6.33333 25 13.4444 25C20.5556 25 25 19.6667 25 15.2222C15.2222 21.4444 4.55556 10.7778 10.7778 1Z" stroke="url(#moon_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="moon_linear" x1="25" y1="25.932" x2="1" y2="25.932" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CA4FE9" />
        <stop offset="1" stopColor="#00C2FF" />
      </linearGradient>
    </defs>
  </svg>
)

MoonIcon.propTypes = {
  onClick: pt.func,
}
MoonIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(MoonIcon)
