export const ADMIN_SURNAME = 'lastName'
export const ADMIN_PATRONYMIC = 'middleName'
export const ADMIN_NAME = 'firstName'
export const ADMIN_TELEPHON = 'phone'
export const ADMIN_EMAIL = 'email'
export const ADMIN_POSITION = 'jobTitle'
export const ADMIN_ABOUT_ME = 'description'
export const ADMIN_PHOTO = 'photoUri'
export const ADMIN_PHOTO_RESOLUTION = 'photoResolution'
export const ADMIN_BLOCK = 'blocked'
