import React, { useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import useReduxTable from '@/hooks/useReduxTable'
import { ACTION_LOG_TABLE } from '@/store/actions/actionLog'
import { getTableParameters } from '@/store/selectors/actionLog'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import UsersIcons from '@/components/icons/users'
import ViewTree from '@/components/blocks/ViewTree'
import DutyTable from '@/components/blocks/DutyTable'
import TextControl from '@/components/controls/TextControl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import {
  fields,
  data as tableData,
  exportOptions,
} from '@/constants/tablesConfig/actionLog'
import {
  Main,
  Content,
} from './styles'

const AlarmsManager = ({
  tree,
  data,

  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  requestAlarmManagerTable,
  requestAlarmManagerFile,
}) => {
  const [treeQuery, setTreeQuery] = useState(null)
  const [isDirty, setIsDirty] = useState(false)

  useHtmlTitle('Журнал действий')
  useEffect(() => {
    if (Object.keys(tree).length && !isDirty) {
      setIsDirty(true)
    }
  }, [tree, isDirty])

  const tableProps = useReduxTable(ACTION_LOG_TABLE, getTableParameters)

  return (
    <Main>
      <Content>
        <PageSidebar
          onIconClick={noop}
          title="ЖУРНАЛ ДЕЙСТВИЙ"
          content={(
            <>
              {tree && tree.id && (
                <ViewTree
                  key={JSON.stringify(tree)}
                  treeData={tree}
                  searchQuery={treeQuery}
                />
              )}
            </>
          )}
          headerContent={(
            <Lang id="usersPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={setTreeQuery}
                />
              )}
            </Lang>
          )}
        />
        <DutyTable
          {...tableProps}
          fields={fields}
          exportOptions={exportOptions}
          data={data}
          rowSelector={setSelectedElement}
          setEventTypes={setEventTypes}
          setStatusTypes={setStatusTypes}
          requestAlarmManagerTable={requestAlarmManagerTable}
          onFileSelect={requestAlarmManagerFile}
        />
      </Content>
    </Main>
  )
}

AlarmsManager.propTypes = {
  tree: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAlarmManagerTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsManager.defaultProps = {
  tree: {},
  data: tableData,

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAlarmManagerTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsManager
