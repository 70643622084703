import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const BackIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.31079 8.6617C5.13509 8.853 5.13509 9.147 5.31079 9.3383L11.2159 15.7652C11.5036 16.0783 11.9641 16.0783 12.2517 15.7652L12.7782 15.1922C13.0737 14.8706 13.074 14.3423 12.7787 14.0203L8.48589 9.3379C8.31059 9.1467 8.31059 8.8533 8.48589 8.6621L12.7787 3.97974C13.074 3.65771 13.0737 3.12945 12.7782 2.80778L12.2517 2.23481C11.9641 1.92173 11.5036 1.92173 11.2159 2.23481L5.31079 8.6617Z" fill={fill} className="svgFill" />
    </svg>
  )
}

BackIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

BackIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(BackIcon)
