import { GET_TENANT_GROUPS } from '../index'

export const requestGetTenantGroups = (payload) => ({
  type: GET_TENANT_GROUPS.REQUEST,
  payload,
})

export const responseGetTenantGroups = (payload) => ({
  type: GET_TENANT_GROUPS.SUCCESS,
  payload,
})

export const errorGetTenantGroups = (payload) => ({
  type: GET_TENANT_GROUPS.ERROR,
  payload,
})
