import { put, takeLatest } from 'redux-saga/effects'
import qs from 'query-string'
import get from 'lodash/get'
import request from '@/helpers/axios'
import { STATE_OF_OBJECT } from '@/constants/apiRoutes'
import { GET_OBJECT_STATE } from '@/store/actions/widgetDashboard'
import { getObjectDataSuccess } from '@/store/actions/widgetDashboard/objectState'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'

function* getObjectState({ payload }) {
  const { selectedNode } = payload
  try {
    const queryParams = qs.stringify({
      installationId: (selectedNode && selectedNode.internalId) ? selectedNode.internalId : null,
    })
    const response = yield request({
      url: `${STATE_OF_OBJECT}?${queryParams}`,
      method: 'GET',
    })
    const data = {
      [OK]: get(response, 'data.serviceableCount', 0),
      [WARNING]: get(response, 'data.warningCount', 0),
      [ERROR]: get(response, 'data.malfunctioningCount', 0),
      [INFO]: get(response, 'data.undefinedCount', 0),
      installationCount: get(response, 'data.installationCount', 0),
    }
    yield put(getObjectDataSuccess(data))
  } catch (error) {
    console.log('TCL: function*objectStateToGeneralPage -> error', error)
  }
  return null
}

export default function* () {
  yield takeLatest(GET_OBJECT_STATE.REQUEST, getObjectState)
}
