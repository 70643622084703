import { EDIT_PROJECT } from './index'

export const requestEditProject = (payload) => ({
  type: EDIT_PROJECT.REQUEST,
  payload,
})

export const successEditProject = (payload) => ({
  type: EDIT_PROJECT.SUCCESS,
  payload,
})

export const errorEditProject = (payload) => ({
  type: EDIT_PROJECT.ERROR,
  payload,
})
