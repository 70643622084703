import ConnectIcon from './ConnectIcon'
import EnterIcon from './EnterIcon'
import LockIcon from './LockIcon'
import TrashCanIcon from './TrashCanIcon'
import UserIcon from './UserIcon'
import WriteIcon from './WriteIcon'
import ConnectionIcon from './ConnectionIcon'
import SyncIcon from './SyncIcon'
import TrashBascketIcon from './TrashBascketIcon'
import BrizIcon from './BrizIcon'
import SimIcon from './SimIcon'
import WaitIcon from './WaitIcon'

export default {
  ConnectIcon,
  EnterIcon,
  LockIcon,
  TrashCanIcon,
  UserIcon,
  WriteIcon,
  ConnectionIcon,
  SyncIcon,
  TrashBascketIcon,
  SimIcon,
  BrizIcon,
  WaitIcon,
}
