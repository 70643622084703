import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import CheckBoxIcon from '@/components/controls/CheckBoxIcon'

const CheckBoxIconField = ({ label, ...ownProps }) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const { isSubmitting } = form

      const onChange = (value) => {
        form.setFieldValue(field.name, value)
      }

      return (
        <CheckBoxIcon
          {...ownProps}
          {...field}
          onChange={onChange}
          label={label}
          disabled={isSubmitting}
        />
      )
    }}
  </Field>
)

CheckBoxIconField.defaultProps = {
  label: null,
}

CheckBoxIconField.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
}

export default React.memo(CheckBoxIconField)
