import { GET_WIDGET_SETTINGS } from '../index'

export const requestGetWidgetSettings = () => ({
    type: GET_WIDGET_SETTINGS.REQUEST,
})

export const successGetWidgetSettings = (payload) => ({
    type: GET_WIDGET_SETTINGS.SUCCESS,
    payload,
})

export const errorGetWidgetSettings = (error) => ({
    type: GET_WIDGET_SETTINGS.ERROR,
    payload: error,
})