import {
  take, call, select
} from 'redux-saga/effects'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import updateNodeCount from '@/helpers/updateNodeCount'
import { getParams } from '@/store/selectors/installation'
import { generateChildCount } from '@/constants/apiRoutes'
import { createInstallationUploadChannel } from './channels'

const config = {
  'parsing.installation.file.was.successful': {
    title: 'УСПЕШНАЯ ОПЕРАЦИЯ!',
    type: 'success',
    description: 'Обработка файла прошла успешно.',
  },
  error: {
    title: 'ОШИБКА ОПЕРАЦИИ!',
    type: 'error',
    description: 'Обработка файла прошла неудачно.\nПовторите попытку позже.',
  },
}

function sleep(sec) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000))
}

function* verifyIntegrationSaga() {
  try {
    const toast = createNotifications()
    const channel = yield call(createInstallationUploadChannel)
    while (true) {
      const eventData = yield take(channel)
      const formValues = yield select(getParams)
      const { id: formId } = formValues
      const description = get(eventData, 'description', 'error')
      if (description === 'parsing.installation.file.was.successful') {
        // its time to get real count of objects. Maybe it can be longer
        yield sleep(10)
        const tree = treeHandlers.trees['installations-tree']
        const node = tree && tree.instance.getNodeById(formId)
        tree.handlers.setOpen(node, true)
        tree.handlers.setSelected(node, true)
        const { data: childCount } = yield request({
          url: generateChildCount(formId),
          method: 'get',
        })
        updateNodeCount(tree, node.options.path, 'refresh', Number.parseInt(childCount, 10))
        tree.handlers.rerender()
      }
      toast(config[description] || config.error)
    }
  } catch (error) {
    console.log('function*verifyIntegrationSaga -> error', error)
  }
}

export default verifyIntegrationSaga
