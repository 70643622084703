export default [
  {
    passportParameter: 'Температура атмосферы',
    showInDashboard: true,
    selector: 'atmosphereTemperature',
  },
  {
    passportParameter: 'Атмосферное давление',
    showInDashboard: true,
    selector: 'atmospherePressure',
  },
]
