import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const OKIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0896 0.67332C15.425 0.289947 15.9949 0.219874 16.4131 0.510594L17.1764 1.04122C17.6309 1.35713 17.7423 1.9821 17.425 2.4356L8.23315 15.5733C8.04604 15.8407 7.74018 16 7.41379 16H6.12528C5.80371 16 5.50175 15.8454 5.31382 15.5844L0.51717 8.92407C0.221914 8.5141 0.278836 7.9483 0.649837 7.60535L1.64233 6.6879C2.02569 6.33353 2.61716 6.33369 3.00033 6.68827L6.01905 9.48177C6.43246 9.86433 7.0801 9.83015 7.45094 9.4062L15.0896 0.67332Z" fill={fill} className="svgFill" />
    </svg>
  )
}

OKIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

OKIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(OKIcon)
