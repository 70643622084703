import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InvertCrossIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37256 18.6274 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24ZM7.32288 8.88196C6.89233 8.45142 6.89233 7.75342 7.32288 7.32288C7.75342 6.89233 8.45142 6.89233 8.88196 7.32288L12 10.4409L15.118 7.32288C15.5486 6.89233 16.2466 6.89233 16.6771 7.32288C17.1077 7.75342 17.1077 8.45142 16.6771 8.88196L13.5591 12L16.6771 15.118C17.1077 15.5486 17.1077 16.2466 16.6771 16.6771C16.2466 17.1077 15.5486 17.1077 15.118 16.6771L12 13.5591L8.88196 16.6771C8.45142 17.1077 7.75342 17.1077 7.32288 16.6771C6.89233 16.2466 6.89233 15.5486 7.32288 15.118L10.4409 12L7.32288 8.88196Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

InvertCrossIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InvertCrossIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InvertCrossIcon)
