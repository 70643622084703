import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import russiaBorders from '@/data/regionCoords/russia'
import {
  COUNTRY,
  CITY,
} from '@/constants/objectTypes'

export const fakeZones = {
  86: {
    id: 86,
    name: 'Волоколамск',
    type: CITY,
    location: [56.0360129, 35.9583358],
    border: null,
    count: 15,
    statistic: {
      [OK]: 0,
      [WARNING]: 0,
      [ERROR]: 0,
      [INFO]: 15,
    },
    zoneSelector: [86],
    pinSelector: 86,
  },
  108: {
    id: 108,
    name: 'Благовещенск',
    type: CITY,
    location: [50.2906, 127.527],
    border: null,
    count: 40,
    statistic: {
      [OK]: 40,
      [WARNING]: 0,
      [ERROR]: 0,
      [INFO]: 0,
    },
    zoneSelector: [108],
    pinSelector: 108,
  },
}

export default {
  1: {
    id: 1,
    name: 'Россия',
    type: COUNTRY,
    location: [60, 90],
    border: russiaBorders,
    count: 0,
    statistic: {
      [OK]: 0,
      [WARNING]: 0,
      [ERROR]: 0,
      [INFO]: 0,
    },
    zoneSelector: [63, 48, 86, 108, 40048, 30048, 30063, 366],
  },
}
