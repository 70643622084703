import { handleActions } from 'redux-actions'

import { WIDGET_SHOP_APP, RU_LANGUAGE } from '@/constants/names'
import {
  SELECT_LANGUAGE,
  USER_PROFILE_TOGGLE,
  SETTINGS_TOGGLE,
  ALARMS_WIDGET_SETTINGS_TOGGLE,
  LOCATION_CHANGE,
  SELECT_WIDGET_SHOP_VALUE,
  TOGGLE_MENU,
} from '@/store/actions/appSettings'
import {
  USER_LOGIN,
  USER_LOGOUT,
} from '@/store/actions/user'
import { GET_CURRENT_USER } from '@/store/actions/users'

const initialState = {
  language: navigator.language.split(/[-_]/)[0] || RU_LANGUAGE,
  widgetShopValue: WIDGET_SHOP_APP,
  isProfileOpen: false,
  isSettingsOpen: false,
  isAlarmsWidgetSettingsOpen: false,
  isMenuOpen: false,
  notificationsCount: 0,
  user: {},
}

export const reducer = handleActions(
  {
    [TOGGLE_MENU]: (state, { payload }) => ({
      ...state,
      isMenuOpen: payload,
    }),
    [SELECT_WIDGET_SHOP_VALUE]: (state, { payload }) => ({
      ...state,
      widgetShopValue: payload,
    }),
    [GET_CURRENT_USER.SUCCESS]: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    [SELECT_LANGUAGE]: (state, { payload: { language } }) => ({
      ...state,
      language,
    }),
    [USER_PROFILE_TOGGLE]: (state, { payload: { isOpen } }) => ({
      ...state,
      isProfileOpen: isOpen,
      isSettingsOpen: false,
      isAlarmsWidgetSettingsOpen: false,
    }),
    [SETTINGS_TOGGLE]: (state, { payload: { isOpen } }) => ({
      ...state,
      isSettingsOpen: isOpen,
      isProfileOpen: false,
      isAlarmsWidgetSettingsOpen: false,
    }),
    [ALARMS_WIDGET_SETTINGS_TOGGLE]: (state, { payload: { isOpen } }) => ({
      ...state,
      isAlarmsWidgetSettingsOpen: isOpen,
      isProfileOpen: false,
      isSettingsOpen: false,
    }),
    [USER_LOGIN.SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      user: data.user,
    }),
    [USER_LOGOUT.SUCCESS]: (state) => ({
      ...state,
      user: {},
    }),
    [LOCATION_CHANGE]: (state) => ({
      ...state,
      isSettingsOpen: false,
      isProfileOpen: false,
      isAlarmsWidgetSettingsOpen: false,
    }),
  },
  initialState,
)

export default reducer
