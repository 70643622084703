import styled from 'styled-components'

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RadioButtonStyled = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  border: 2px solid ${({ theme }) => theme.colors.borders.redisign.dark};
  background: transparent;
  border-radius: 50%;
  ${({ type, theme }) => type === 'square' && `
    border-radius: 4px;
    border: 1px solid ${theme.colors.borders.radio};
  `}
  ${({ active, theme, type }) => active && type === 'square' && `
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 1px;
      left: 0;
      right: 0;
      content: '';
      width: 6px;
      height: 10px;
      border: solid ${theme.colors.scheme.backgrounds.default};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `} 
  ${({ active, theme, type }) => active && type !== 'square' && `
    ::after {
      content: '';
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      right: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${theme.colors.borders.redisign.dark};
    }
  `} 
`

export const RadioButtonLabel = styled.div`
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  margin-left: 10px;
`
