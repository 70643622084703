import React, { useCallback } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import EditAdminForm from '@/forms/EditAdminForm'
import Loader from '@/components/blocks/Loader'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import UserFormHeader from '@/components/blocks/Header'
import {
  Container,
  LoaderContainer,
} from './styles'

const EditAdmin = ({
  type,
  closeForm,
  selectedAdmin,
  setUserImageUrl,
  deleteAdmin,
  updateAdmin,
  userRole,
  isFormModifying,
  setFormIsModifying,
}) => {
  const editAdminForm = React.createRef()
  // const [isDirty, setIsDirty] = useState(false)
  // const [isValidForm, setValidForm] = useState({})

  // const handleIsDirty = useCallback((value) => {
  //   setIsDirty(value)
  // }, [setIsDirty])

  const deleteHandler = () => {
    deleteAdmin({
      id: selectedAdmin.id,
      tenantId: selectedAdmin.tenantId,
      selectedAdmin,
    })
  }

  const handleSubmit = useCallback(() => {
    if (editAdminForm.current && isEmpty(editAdminForm.current.state.errors)) {
      updateAdmin({
        forms: editAdminForm.current,
        tenantId: selectedAdmin.tenantId,
        id: selectedAdmin.id,
        isMe: selectedAdmin.isMe,
      })
    }
  }, [editAdminForm, selectedAdmin.id, selectedAdmin.isMe, selectedAdmin.tenantId, updateAdmin])

  const handleDebounceSubmit = debounce(() => {
    handleSubmit()
  }, DEBOUNCE_DELAY_SMALL)

  const handleDebounceDelete = debounce(() => {
    deleteHandler()
  }, DEBOUNCE_DELAY_SMALL)

  return (
    <Container>
      <UserFormHeader
        editTitle={<Lang id="usersPage.formHeader.changingAdmin" />}
        editMode={isFormModifying}
        deleteForm={handleDebounceDelete}
        saveForm={handleDebounceSubmit}
        closeForm={closeForm}
        title={<Lang id="usersPage.formHeader.admin" />}
        setFormIsModifying={setFormIsModifying}
        withoutDeleting={!userRole.isSuperAdmin}
        popupSettings={{
          error: {
            title: <Lang id="usersPage.popup.attention" />,
            message: isFormModifying
              ? <Lang id="usersPage.popup.cancelChangeAdmin" />
              : (
                <>
                  <Lang id="usersPage.popup.deleteAdmin" />
                  {`${selectedAdmin.name}?`}
                </>
              ),
          },
          warning: {
            title: <Lang id="usersPage.popup.impossibleExecute" />,
            message: <Lang id="usersPage.popup.saveAdmin" />,
          },
        }}
        withoutEditing={userRole.isUser}
        conditionsForAdding
      />
      {Object.keys(selectedAdmin).length === 0
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <EditAdminForm
            ref={editAdminForm}
            // setDirty={handleIsDirty}
            formData={selectedAdmin}
            blockForm={!selectedAdmin.isMe}
            canBlock={userRole.isSuperAdmin}
            setUserImageUrl={setUserImageUrl}
            // setValidForm={setValidForm}
            type={type}
            disableFields
            defaultFields={false}
            isFormModifying={isFormModifying}
          />
        )}
    </Container>
  )
}
EditAdmin.propTypes = {
  type: pt.string,
  setUserImageUrl: pt.func,
  selectedAdmin: pt.objectOf(pt.object),
  deleteAdmin: pt.func,
  updateAdmin: pt.func,
}
EditAdmin.defaultProps = {
  type: '',
  setUserImageUrl: noop,
  deleteAdmin: noop,
  updateAdmin: noop,
  selectedAdmin: {},
}

export default EditAdmin
