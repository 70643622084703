const generateWidget = ({
  config,
  mockValue,
  selectorTemplate = '',
  titleTemplate = '',
  dataTemplate = null,
}) => ({
  number = '',
  count = 0,
  selector = '',
}) => {
  const isDataTemplate = dataTemplate !== null && !!count
  const selectorString = selector || selectorTemplate.replace('{number}', number)
  const titleString = titleTemplate
    ? titleTemplate.replace('{number}', number)
    : config.title
  const data = isDataTemplate
    ? [...Array(count).keys()].map((itemNumber) => ({
      title: dataTemplate.title.replace('{number}', itemNumber + Number.parseFloat(number, 10)),
      selector: selector || dataTemplate.selector.replace('{number}', itemNumber + Number.parseFloat(number, 10)),
    }))
    : []
  return ({
    ...config,
    mockValue,
    title: titleString,
    id: config.id || selectorString,
    valueSelector: selectorString,
    data,
  })
}

export default generateWidget
