import React from 'react'
import pt from 'prop-types'
import * as mapTypes from '@/propTypes/maps'
import { MARKS_COLORS, MAPS_ICONS } from '@/constants/styles/mapsMarks'
import ToolTip from '../ToolTip'
import {
  PinContainer, OverLay,
} from './styles'

export const PinIcon = ({
  status, type, theme, name, id,
}) => {
  const PinTypeIcon = MAPS_ICONS[type]
  return (
    <PinContainer theme={theme}>
      <OverLay theme={theme}>
        <PinTypeIcon color={MARKS_COLORS[status] || theme.colors.colors.default} />
      </OverLay>
      <ToolTip mash name={name} id={id} theme={theme} type={type} />
    </PinContainer>
  )
}

PinIcon.propTypes = {
  theme: mapTypes.theme.isRequired,
  status: mapTypes.markStatus.isRequired,
  type: mapTypes.markType.isRequired,
  name: pt.string.isRequired,
  id: pt.oneOfType([pt.string, pt.number]).isRequired,
}

export default PinIcon
