import styled from 'styled-components'
import { tooltipSizes } from '@/constants/maps'

export const ToolTipContainer = styled.div`
  position: absolute;
  padding:  3px 5px;
  min-height: 35px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 480;
  width: 220px;
  border-radius: 4px;
  top: 0px;
  left: 0px;
`

export const ShadowShalfe = styled.div`
  position: absolute;
  padding:  3px 5px;
  min-height: 35px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 460;
  width: 220px;
  border-radius: 4px;
  top: 0px;
  left: 0px;
  box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.4);
`

export const Container = styled.div`
  position: relative;
  width: 220px;
  left: -100px;
  border-radius: 4px;
  top: -${({ aditionalHeight }) => tooltipSizes.delta + aditionalHeight * tooltipSizes.lineHeight - 10}px;
`

export const ToolTipContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`

export const PrimaryTitle = styled.div`
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SecondaryTitle = styled.div`
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-align: left;
  line-break: loose;
`

export const Tip = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 300;
  bottom: -6px;
  left: calc(50% - 6px);
  background: ${({ theme }) => theme.colors.backgrounds.default};
  transform: rotate(45deg);
  z-index: 95;
  box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.4);
`
