import { GET_TENANT_ADMIN } from '../index'

export const requestGetAdminTenant = (payload) => ({
  type: GET_TENANT_ADMIN.REQUEST,
  payload,
})

export const responseGetAdminTenant = (payload) => ({
  type: GET_TENANT_ADMIN.SUCCESS,
  payload,
})

export const errorGetAdminTenant = (payload) => ({
  type: GET_TENANT_ADMIN.ERROR,
  payload,

})
