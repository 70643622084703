import React, { useCallback, useEffect } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import { REQUEST_STATUSES } from '@/constants/requests'
import Loader from '@/components/blocks/Loader'
import {
  Container,
  Header,
  TitleContainer,
  Title,
  RowContainer,
  RowTitle,
  IndicatorLine,
  IndicatorLineActive,
  LoaderContainer,
} from './styles'

const DepreciationEquipment = ({
  selectedNode,
  getDataRequest,
  data,
  requestState,
  formValues,
}) => {
  useEffect(() => {
    getDataRequest(selectedNode)
  }, [selectedNode, getDataRequest, formValues])
  const showLoader = requestState === REQUEST_STATUSES.PENDING
  const uniqueKey = useCallback((id, value) => (`${id}-${value}`).replace(/\s/g, ''), [])
  const calculateIndicatorWidth = useCallback((value) => (
    ((value / data.count) * 100 || 0).toFixed(0)
  ), [data.count])
  return (
    <Container>
      <Header>
        <WidgetsDashboardIcon.DepreciationEquipmentIcon />
        <TitleContainer>
          <Title>
            <Lang id="widgetsDashboard.depreciationEquipment" />
          </Title>
          <Title subtitle>
            <Lang id="widgetsDashboard.allObjects" />
            {' '}
            {numberWithSpaces(data.count)}
          </Title>
        </TitleContainer>
      </Header>
      {showLoader
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <>
            {data.structure && data.structure.map((element) => (
              <RowContainer key={element.id}>
                <RowTitle>
                  <Title rowTitle>{element.name}</Title>
                  <Title>{element.value}</Title>
                </RowTitle>
                <IndicatorLine>
                  <IndicatorLineActive
                    uniqueKey={uniqueKey(element.id, element.value)}
                    widthValue={calculateIndicatorWidth(element.value)}
                    id={element.id}
                  />
                </IndicatorLine>
              </RowContainer>
            ))}
          </>
        )}
    </Container>
  )
}

DepreciationEquipment.propTypes = {
  data: pt.objectOf(pt.object),
  requestState: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
  ]),
  selectedNode: pt.objectOf(pt.object),
  getDataRequest: pt.func,
  formValues: pt.objectOf(pt.string),
}
DepreciationEquipment.defaultProps = {
  data: {},
  requestState: REQUEST_STATUSES.NOT_REQUESTED,
  selectedNode: {},
  getDataRequest: noop,
  formValues: {},
}

export default DepreciationEquipment
