import { all } from 'redux-saga/effects'
import createAdminTenantRoot from './createAdminTenant'
import getAdminTenantRoot from './getAdminTenant'
import deleteAdminTenantRoot from './deleteAdminTenant'
import getAllAdminTenantsRoot from './getAllAdminTenants'

export default function* root() {
  yield all([
    createAdminTenantRoot(),
    getAdminTenantRoot(),
    deleteAdminTenantRoot(),
    getAllAdminTenantsRoot(),
  ])
}
