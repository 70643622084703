import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import { getCountOfStatus, getStatusOfObjects } from '@/helpers/maps'
import { CLUSTER_NAME } from '@/constants/maps'
import mapIconst from '@/components/icons/maps'
import ClusterToolTip from '../ClusterToolTip'
import PieChart from '../PieChart'

import {
  ClusterContainerSmall, ClusterContainerMedium, ClusterContainerBig,
  PieChartContainerSmall, PieChartContainerMedium, PieChartContainerBig,
  PieCharOverLaySmall, PieCharOverLayMedium, PieCharOverLayBig,
  TitleSmall, TitleMedium, TitleBig,
} from './styles'

const ClusterIconHover = ({ theme, ...ownProps }) => {
  const clusterData = getStatusOfObjects(ownProps)
  const status = getCountOfStatus(clusterData)
  const { data } = status
  const icon = mapIconst.ClusterIcon
  return (
    <>
      {'{% if properties.geoObjects.length <= 99 %}'}
      <ClusterContainerSmall theme={theme}>
        <PieChartContainerSmall theme={theme}>
          <PieChart size="small" statistic={data} theme={theme} />
        </PieChartContainerSmall>
        <PieCharOverLaySmall theme={theme}>
          <TitleSmall theme={theme}>
            {'{{ properties.geoObjects.length }}'}
          </TitleSmall>
        </PieCharOverLaySmall>
      </ClusterContainerSmall>
      <ClusterToolTip data={data} name={CLUSTER_NAME} theme={theme} type="small" icon={icon} />
      {'{% endif %}'}
      {'{% if properties.geoObjects.length > 99 & properties.geoObjects.length <= 999 %}'}
      <ClusterContainerMedium theme={theme}>
        <PieChartContainerMedium theme={theme}>
          <PieChart size="medium" statistic={data} theme={theme} />
        </PieChartContainerMedium>
        <PieCharOverLayMedium theme={theme}>
          <TitleMedium theme={theme}>
            {'{{ properties.geoObjects.length }}'}
          </TitleMedium>
        </PieCharOverLayMedium>
      </ClusterContainerMedium>
      <ClusterToolTip data={data} name={CLUSTER_NAME} theme={theme} type="medium" icon={icon} />
      {'{% endif %}'}
      {'{% if properties.geoObjects.length > 999 %}'}
      <ClusterContainerBig theme={theme}>
        <PieChartContainerBig theme={theme}>
          <PieChart size="big" statistic={data} theme={theme} />
        </PieChartContainerBig>
        <PieCharOverLayBig theme={theme}>
          <TitleBig theme={theme}>
            {'{{ properties.geoObjects.length }}'}
          </TitleBig>
        </PieCharOverLayBig>
      </ClusterContainerBig>
      <ClusterToolTip data={data} name={CLUSTER_NAME} theme={theme} type="big" icon={icon} />
      {'{% endif %}'}
    </>
  )
}

ClusterIconHover.propTypes = {
  theme: mapTypes.theme.isRequired,
}

export default ClusterIconHover
