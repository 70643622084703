import {
  takeEvery, put, all, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SERVICE_DESK_GET_APPEALS_URL } from '@/constants/apiRoutes'
import { GET_SERVICE_DESK_WIDGET } from '@/store/actions/widgetDashboard'
import {
  getServiceDeskWidgetSuccess,
  getServiceDeskWidgetError,
} from '@/store/actions/widgetDashboard/serviceDeskWidget'
import { getIntegrationStatus } from '@/store/selectors/appSettings'
import { getAverageDays } from '@/helpers/serviceDeskHelpers'
import {
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  DELAYED,
  OVERDUE_ON_EXECUTION,
} from '@/constants/serviceDeskMyCity'
import {
  IN_WORK,
  DECISION_MADE,
  TOTAL_CALLS,
  AVERAGE_TIME_TAKEN,
  AVERAGE_DECISION_TIME,
} from '@/constants/widgetsDashboard'

function formatDate(date) {
  let dd = date.getDate()
  if (dd < 10) dd = `0${dd}`
  let mm = date.getMonth() + 1
  if (mm < 10) mm = `0${mm}`
  const yy = date.getFullYear()
  return `${yy}-${mm}-${dd}`
}

const bodyGenerator = (id, payload, parameters) => {
  const endDate = payload.endDate ? `&FILTER[%3C%3DDATE_CREATE]=${formatDate(new Date(payload.endDate))}` : ''
  const startDate = payload.startDate ? `&FILTER[%3E%3DDATE_CREATE]=${formatDate(new Date(payload.startDate))}` : ''
  return {
    integrationId: id,
    parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=65&start=-1${parameters || ''}${endDate}${startDate}`,
    restMethod: '/lists.element.get.json',
  }
}

function* getServiceDeskWidgetSaga({ payload }) {
  try {
    const integrationStatus = yield select(getIntegrationStatus)
    const responseAllTime = yield all({
      [RECEIVED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(integrationStatus.id, payload),
      }),
      [DONE]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(integrationStatus.id, payload, '&FILTER[PROPERTY_339]=completed'),
      }),
      [TASKS_PERFORMED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(integrationStatus.id, payload, '&FILTER[PROPERTY_339]=inProgress'),
      }),
      [DELAYED]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(integrationStatus.id, payload, `&FILTER[PROPERTY_339]=${DELAYED}`),
      }),
      [OVERDUE_ON_EXECUTION]: request({
        url: SERVICE_DESK_GET_APPEALS_URL,
        method: 'post',
        body: bodyGenerator(integrationStatus.id, payload, '&FILTER[PROPERTY_355]=1'),
      }),
    })
    const averageTimeTaken = getAverageDays(responseAllTime[RECEIVED].data.result, 'PROPERTY_347')
    const averageDecisionTime = getAverageDays(responseAllTime[RECEIVED].data.result)
    yield put(getServiceDeskWidgetSuccess({
      [TOTAL_CALLS]: responseAllTime[RECEIVED].data.result.length,
      [IN_WORK]: responseAllTime[TASKS_PERFORMED].data.result.length,
      [DECISION_MADE]: responseAllTime[DONE].data.result.length,
      [AVERAGE_TIME_TAKEN]: `${averageTimeTaken} Дн.`,
      [AVERAGE_DECISION_TIME]: `${averageDecisionTime} Дн.`,
      outdoorLightningCount: responseAllTime[RECEIVED].data.result.length,
    }))
  } catch (error) {
    yield put(getServiceDeskWidgetError(error))
  }
}

export default function* root() {
  yield takeEvery(GET_SERVICE_DESK_WIDGET.REQUEST, getServiceDeskWidgetSaga)
}
