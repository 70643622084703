import styled from 'styled-components'

export const Form = styled.form`
  padding: 28px 22px;
  padding-bottom: 0;
`

export const Title = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.colors.default};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 20px;
`
