import React from 'react'
import pt from 'prop-types'
import memoWithName from '@/hocs/memoWithName'
import { ButtonStyled, TextButton } from './styles'

const Button = ({ styleType, ...props }) => {
  switch (styleType) {
    case 'text': {
      return <TextButton {...props} />
    }
    default: {
      return <ButtonStyled {...props} styleType={styleType} />
    }
  }
}

Button.defaultProps = {
  styleType: 'primary',
}

Button.propTypes = {
  styleType: pt.oneOf([
    'primary',
    'text',
    'flat',
    'danger',
    'submitting',
    'success',
    'warning',
    'error',
    'info',
    'red',
    'ghost',
  ]),
}

export default memoWithName(Button)
