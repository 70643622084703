import styled from 'styled-components'

export const FormHeaderContainer = styled.div`
  display: flex;
`

export const Container = styled.div`
  position: relative;
  width: 930px;
  min-width: 930px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  ${({ editMode }) => editMode && `
    width: 310px;
    min-width: 310px;
  `}
`

export const InfoContainer = styled.div`
  position: relative;
  width: 310px;
  min-width: 310px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  flex-direction: column;
`

export const FormWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.dark};
`
export const IconContainer = styled.div`
`

export const BlockHeader = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 3;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};

  ${({ delimeter, theme }) => delimeter && `
    position: static;
    border-top: 1px solid ${theme.colors.borders.default};
  `}
`
export const HeaderButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme, border }) => `
    border-${border || 'left'}: 1px solid ${theme.colors.borders.default};
  `};
  ${({ theme, color }) => color && `
    background: ${theme.colors.backgrounds.controls[color]}
  `};
  ${({ disabled }) => disabled && `
    pointer-events: none;
    cursor: disabled;
    opacity: 0.5;
  `}
`
export const Title = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.colors.disabled};

  ${({ theme, notification }) => notification && `
    text-transform: none;
    color: ${theme.colors.colors.notification.users};
    line-height: 2.4rem;
  `}
`

export const AccessBlock = styled.div`
  padding: 15px 10px;
`

export const AccessRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`

export const AccessTitle = styled.div`
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.colors.subTitle};
`

export const StyledTooltip = styled.abbr`
  text-decoration: none;
`
