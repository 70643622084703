import { GET_SERVICE_DESK_CARDS } from './index'

export const requestGetInfoCards = (payload) => ({
  type: GET_SERVICE_DESK_CARDS.REQUEST,
  payload,
})

export const successGetInfoCards = (payload) => ({
  type: GET_SERVICE_DESK_CARDS.SUCCESS,
  payload,
})

export const errorGetInfoCards = (payload) => ({
  type: GET_SERVICE_DESK_CARDS.ERROR,
  payload,
})
