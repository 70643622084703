export default {
  infrastructure: 'Инфраструктура: ',
  objectsConnected: ' подключены к системе',
  urbanProcesses: 'Городские процессы',
  technicalCondition: 'Техническое состояние',
  allObjects: 'Всего объектов:',
  correct: 'Исправно:',
  faulty: 'Неисправно:',
  warning: 'Предупреждение:',
  undefined: 'Не определено:',
  notReplaced: 'Не нуждаются в замене:',
  replacement: 'Приближается к замене:',
  necessaryReplace: 'Необходимо заменить:',
  depreciationEquipment: 'Износ оборудования',
  performanceIndicators: 'Показатели эффективности работы',
  outdoorLighting: 'Наружное освещение',
  heatSupply: 'Теплоснабжение',
  automaticFaultReporting: 'Автоматическая регистрация неисправностей',
  totalAlarms: 'Всего алармов:',
  roadObjects: 'Дорожные объекты',
  stopsTotalTransport: 'Остановки общ. транспорта',
  fixed: 'Исправлено:',
  title: 'Дашборд',
  requestsAndAlarms: 'Обращения и алармы',
  consumtion: 'Потребление ресурсов относительно планового значения',
  period: 'Выберите период',
  interval: 'Интервал',
  alarmRegistry: 'Автоматическая регистрация неисправностей',
  tyumenOurHomeBigWidget: 'Портал “Тюмень-НАШ ДОМ”',
  averageDecisionTime: 'Средн. время принятия решения:',
  inWork: 'В работе:',
  decisionWasMade: 'Принято решение:',
  decisionWasMadeMany: 'Принято решений:',
  successfullyResolved: 'Успешно решено:',
  denialDecision: 'Отказ в решении:',
  lightingObjects: 'Состояние объектов',
  serviceDesk: 'Сервис Деск “Мой город”',
  totalCalls: 'Всего обращений:',
  AvgTimeTaken: 'Средн. время взятия в работу:',
  overdue: 'Просрочено',
  delayed: 'Отложено',
  build: 'Построить',
  resetFilter: 'Сбросить фильтер',
}
