import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReportIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V21C5 21.5304 5.21071 22.0391 5.58579 22.4142C5.96086 22.7893 6.46957 23 7 23H21C21.5304 23 22.0391 22.7893 22.4142 22.4142C22.7893 22.0391 23 21.5304 23 21V7C23 6.46957 22.7893 5.96086 22.4142 5.58579C22.0391 5.21071 21.5304 5 21 5ZM21 21H7V7H21V21ZM11 19H9V14H11V19ZM15 19H13V9H15V19ZM19 19H17V12H19V19Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ReportIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReportIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReportIcon)
