import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_ACTION_LOG_TABLE = createAction('@/GET_ACTION_LOG_TABLE')
export const GET_ACTION_LOG_FILE = createAction('@/GET_ACTION_LOG_FILE')
export const GET_ALARMS_COUNT = createAction('@/GET_ALARMS_COUNT')
export const ACTION_LOG_TABLE = createTableActions('@/ACTION_LOG_TABLE')

export const SET_SELECTED_ELEMENT = '@/ACTION_LOG_SET_SELECTED_ELEMENT'
export const SET_EVETS_DATA = '@/ACTION_LOG_SET_EVETS_DATA'

export const setEventsData = (payload) => ({
  type: SET_EVETS_DATA,
  payload,
})
export const setSelectedElement = (payload) => ({
  type: SET_SELECTED_ELEMENT,
  payload,
})
