import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  ${({ widthValue }) => widthValue && `
    margin-bottom: 15px;
  `}
`

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: -0.003em;
  margin-bottom: 5px;
  ${({ value }) => value && `
    font-size: 1.4rem;
    line-height: 1.6rem;
  `}
`
