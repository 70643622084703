export const TOPIC_INNTEGRATIONS = '/topic/integrations'
export const TOPIC_STATUS_PREFIX = '/status'
export const TOPIC_MONNITORING_PREFIX = '/monitoring'
export const TOPIC_ALERT_PREFIX = '/alerts'

export const TOPIC_CREATION_NOTIFICATIONS = `${TOPIC_INNTEGRATIONS}${TOPIC_STATUS_PREFIX}/creation`

export const generateUpdateIntegrationTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}${TOPIC_STATUS_PREFIX}/update`
export const generateRepeatIntegrationTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}${TOPIC_STATUS_PREFIX}/refresh`
export const generateDeleteIntegrationTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}${TOPIC_STATUS_PREFIX}/deletion`
export const generateMonitoringNotificationsTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}${TOPIC_MONNITORING_PREFIX}/notifications`
export const generateMonitoringErrorsTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}${TOPIC_MONNITORING_PREFIX}/errors`
export const generateAlertNotificationTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}${TOPIC_ALERT_PREFIX}/notifications`
export const generateVerifyNotificationTopic = (
  id,
) => `${TOPIC_INNTEGRATIONS}/${id}/status/verification`

export const NOTIFICATION_REFRESH_INTEGRATION = '/topic/pushStatusRefreshIntegrationEquipmentState'

// alarms count
export const NOTIFICATION_ALARMS_COUNT_TOPIC = '/topic/pushNotificationAboutUnreadAlerts'

// service desk
export const ADD_INTEGRATION_TOPIC = '/topic/integration/service-desk'

export const FIRE_MESH_NOTIFICATION = '/topic/pushMeshParseStateEquipment'
export const FIRE_BRIZ_NOTIFICATION = '/topic/pushBrizParseStateEquipment'

export const INSTALLATION_UPLOAD_NOTIFICATION = '/topic/pushParsingInstallationFileState'
