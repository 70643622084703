import ActivityLogIcon from './ActivityLogIcon'
import AlarmsIcon from './AlarmsIcon'
import AlertManagerIcon from './AlertManagerIcon'
import CarElectricStationsIcon from './CarElectricStationsIcon'
import ChangeLogIcon from './ChangeLogIcon'
import ControlCenterIcon from './ControlCenterIcon'
import DesktopIcon from './DesktopIcon'
import EcoStationsIcon from './EcoStationsIcon'
import EnergyConsumptionIcon from './EnergyConsumptionIcon'
import EquipmentReferenceIcon from './EquipmentReferenceIcon'
import GroupControlIcon from './GroupControlIcon'
import HeatSupplyIcon from './HeatSupplyIcon'
import IndividualControlIcon from './IndividualControlIcon'
import InstallObjectsIcon from './InstallObjectsIcon'
import InstallationIcon from './InstallationIcon'
import InventorisationIcon from './InventorisationIcon'
import LifeIcon from './LifeIcon'
import ObjectsIcon from './ObjectsIcon'
import OperationAndTechnicalSupportIcon from './OperationAndTechnicalSupportIcon'
import PassportizationIcon from './PassportizationIcon'
import ReportManagerIcon from './ReportManagerIcon'
import ScheduleManagerIcon from './ScheduleManagerIcon'
import SearchIcon from './SearchIcon'
import SmartRecycleBinIcon from './SmartRecycleBinIcon'
import UserProfileIcon from './UserProfileIcon'
import UsersIcon from './UsersIcon'
import WaterSupplyIcon from './WaterSupplyIcon'
import SettingsIcon from './SettingsIcon'
import InventoryIcon from './InventoryIcon'
import LightingObjectsIcon from './LightingObjectsIcon'
import AnalyticsExpensesIcon from './AnalyticsExpensesIcon'
import EquipmentIcon from './EquipmentIcon'
import ObjectStatisticIcon from './ObjectStatisticIcon'
import TyumenIcon from './TyumenIcon'
import GarbageCollectionIcon from './GarbageCollectionIcon'
import HeatIcon from './HeatIcon'
import WaterIcon from './WaterIcon'
import IlluminationIcon from './IlluminationIcon'
import RoadIcon from './RoadIcon'
import TrafficLightIcon from './TrafficLightIcon'
import InfoFrameIcon from './InfoFrameIcon'
import HandicappedIcon from './HandicappedIcon'
import SupportIcon from './SupportIcon'
import WidgetsDashboard from './WidgetsDashboard'
import AttentionIcon from './AttentionIcon'
import TreshIcon from './TreshIcon'
import GeoZoneIcon from './GeoZoneIcon'
import EditIcon from './EditIcon'
import ClosedLockIcon from './ClosedLockIcon'
import OpenedLockIcon from './OpenedLockIcon'
import ThumbIcon from './ThumbIcon'

export default {
  ActivityLogIcon,
  AlarmsIcon,
  AlertManagerIcon,
  CarElectricStationsIcon,
  ChangeLogIcon,
  ControlCenterIcon,
  DesktopIcon,
  EcoStationsIcon,
  EnergyConsumptionIcon,
  EquipmentReferenceIcon,
  GroupControlIcon,
  HeatSupplyIcon,
  IndividualControlIcon,
  InstallObjectsIcon,
  InstallationIcon,
  InventorisationIcon,
  LifeIcon,
  ObjectsIcon,
  OperationAndTechnicalSupportIcon,
  PassportizationIcon,
  ReportManagerIcon,
  ScheduleManagerIcon,
  SearchIcon,
  SmartRecycleBinIcon,
  UserProfileIcon,
  UsersIcon,
  WaterSupplyIcon,
  SettingsIcon,
  InventoryIcon,
  LightingObjectsIcon,
  AnalyticsExpensesIcon,
  EquipmentIcon,
  ObjectStatisticIcon,
  TyumenIcon,
  GarbageCollectionIcon,
  HeatIcon,
  WaterIcon,
  IlluminationIcon,
  RoadIcon,
  TrafficLightIcon,
  InfoFrameIcon,
  HandicappedIcon,
  SupportIcon,
  WidgetsDashboard,
  AttentionIcon,
  TreshIcon,
  GeoZoneIcon,
  EditIcon,
  ClosedLockIcon,
  OpenedLockIcon,
  ThumbIcon,
}
