import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getLifeTimeReport = createSelector(
  getState,
  (state) => get(state, 'reportManager', {}),
)

export const getData = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'data'),
)

export const getTypes = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'reportTypes'),
)

export const getIsTypesLoading = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'loadingTypes'),
)

export const getObjectTypes = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'reportObjectTypes'),
)

export const getIsObjectTypesLoading = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'loadingObjectTypes'),
)

export const getCommonUsers = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'commonUsers'),
)

export const getIsCommonUsersLoading = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'loadingCommonUsers'),
)

export const getTableParameters = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'table', {}),
)

export const getTableLoading = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'loadingTable', {}),
)

export const getIsFormModifying = createSelector(
  getLifeTimeReport,
  (tenant) => get(tenant, 'isFormModifying'),
)

export const getSelectedReport = createSelector(
  getLifeTimeReport,
  (settings) => get(settings, 'selectedReport', {}),
)

export const getLoadingSelectedReport = createSelector(
  getLifeTimeReport,
  (tenant) => get(tenant, 'loadingSelectedReport'),
)

export const getExportFileStatus = createSelector(
  getLifeTimeReport,
  (alarmsState) => get(alarmsState, 'fileExportStatus', ''),
)
