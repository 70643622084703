import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ConstructionIcon = ({ onClick }) => (
  <svg onClick={onClick} width="10" height="20" viewBox="0 0 10 20" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.53767 3.76356H7.05242V2.51363H5.69321V0H4.36408V2.51363H2.88456V3.76356H1.48238V6.26841H0V20H10V6.26841H8.53767V3.76356ZM5.66743 4.90691H7.09968V6.1606H5.66743V4.90691ZM2.86451 4.90691H4.29676V6.1606H2.86451V4.90691ZM2.86451 17.4839H1.43225V16.2302H2.86451V17.4839ZM2.86451 14.9715H1.43225V13.7178H2.86451V14.9715ZM2.86451 12.4415H1.43225V11.1879H2.86451V12.4415ZM2.86451 9.97054H1.43225V8.71686H2.86451V9.97054ZM5.72902 17.4538H4.29676V16.2001H5.72902V17.4538ZM5.72902 14.9527H4.29676V13.699H5.72902V14.9527ZM5.72902 12.4541H4.29676V11.2004H5.72902V12.4541ZM5.72902 9.9392H4.29676V8.68551H5.72902V9.9392ZM8.59353 17.4412H7.16127V16.1876H8.59353V17.4412ZM8.59353 14.9364H7.16127V13.6827H8.59353V14.9364ZM8.59353 12.4315H7.16127V11.1778H8.59353V12.4315ZM8.59353 9.91412H7.16127V8.66044H8.59353V9.91412Z" />
  </svg>
)

ConstructionIcon.propTypes = {
  onClick: pt.func,
}

ConstructionIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(ConstructionIcon)
