import { VERIFY_OBJECT } from './index'

export const requestVerifyElements = (payload) => ({
  type: VERIFY_OBJECT.REQUEST,
  payload,
})

export const successVerifyElements = (payload) => ({
  type: VERIFY_OBJECT.SUCCESS,
  payload,
})

export const errorVerifyElements = (payload) => ({
  type: VERIFY_OBJECT.ERROR,
  payload,
})
