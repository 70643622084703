import pt from 'prop-types'

export const childrenType = pt.oneOfType([
  pt.element,
  pt.arrayOf(pt.element),
])

export default {
  childrenType,
}
