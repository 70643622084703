import { takeLatest, select, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { USER_WIDGET_SETTINGS_ENDPOINT } from '@/constants/apiRoutes'
import {
    ADD_SERVICE_DESK_INTEGRATION,
    DELETE_SERVICE_DESK_INTEGRATION,
    USER_CHANGE_SERVICE_DESK,
} from '@/store/actions/serviceDeskMyCity'
import {
    getWidgets,
    getIntegrationStatus,
} from '@/store/selectors/appSettings'
import createNotifications from "@/helpers/notification";
import { errorSetWidgetSettings, successSetWidgetSettings } from '@/store/actions/user/settings/putWidgetSettings';
import { UPDATE_WIDGETS_LIST } from '@/store/actions/appSettings';

function* putWidgetSettings() {
    try {
        const widgets = yield select(getWidgets)
        const serviceDeskIntegrationStatus = yield select(getIntegrationStatus)

        const settingsForSave = {
            generalPage: {
                widgets
            },
            serviceDeskIntegrationStatus
        }

        const response = yield request({
            url: USER_WIDGET_SETTINGS_ENDPOINT,
            method: 'put',
            body: JSON.stringify(settingsForSave),
        })

        yield put(successSetWidgetSettings(response.data))
    } catch (error) {
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось сохранить виджеты.\nПовторите попытку позже.',
            type: 'error',
        })
        yield put(errorSetWidgetSettings(error))
    }
}

export default function* root() {
    yield takeLatest(UPDATE_WIDGETS_LIST, putWidgetSettings)
    yield takeLatest(DELETE_SERVICE_DESK_INTEGRATION.SUCCESS, putWidgetSettings)
    yield takeLatest(ADD_SERVICE_DESK_INTEGRATION.SUCCESS, putWidgetSettings)
    yield takeLatest(USER_CHANGE_SERVICE_DESK, putWidgetSettings)
}
