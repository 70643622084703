import React from 'react'
import Tree from 'react-hyper-tree'

import { BOULDER } from '@/constants/styles/defaultTheme'
import TreeNodeContainer from '../TreeNode'

import TreeContainer from './styles'

const lineStyles = {
  stroke: BOULDER,
  strokeWidth: 1,
  strokeDasharray: '1 1',
}

const TreeWrapped = ({
  id,
  node,
  tree: {
    required,
    handlers,
  },
}) => {
  const renderNode = (params) => (
    <TreeNodeContainer
      {...params}
      {...node}
    />
  )

  return (
    <TreeContainer key={`${id}-container`}>
      <Tree
        key={`${id}-tree`}
        renderNode={renderNode}
        disableTransitions
        verticalLineOffset={9}
        verticalLineTopOffset={-9}
        horizontalLineStyles={lineStyles}
        verticalLineStyles={lineStyles}
        gapMode="padding"
        depthGap={20}
        classes={{ selectedNodeWrapper: 'selected-node' }}
        {...required}
        {...handlers}
      />
    </TreeContainer>
  )
}

export default React.memo(TreeWrapped)
