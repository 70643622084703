import { DELETE_INSTALLATION_OBJECT_FILE } from './index'


export const requestDeleteInstallationObjectFile = (payload) => ({
  type: DELETE_INSTALLATION_OBJECT_FILE.REQUEST,
  payload,
})

export const successDeleteInstallationObjectFile = (payload) => ({
  type: DELETE_INSTALLATION_OBJECT_FILE.SUCCESS,
  payload,
})

export const errorDeleteInstallationObjectFile = (payload) => ({
  type: DELETE_INSTALLATION_OBJECT_FILE.ERROR,
  payload,
})
