import widgets from '../widgets'

const {
  LIGHT_STATUS,
  DATA_TIME,
  HEAT_SUPPLY_PIPE_TEMPERATURE,
  HEAT_RETURN_PIPE_TEMPERATURE,
  HEAT_SUPPLY_PIPE_PRESSURE,
  HEAT_RETURN_PIPE_PRESSURE,
  HEAT_SUPPLY_PIPE_MASS,
  HEAT_RETURN_PIPE_MASS,
  HEAT_SUPPLY_PIPE_FLOW,
  HEAT_RETURN_PIPE_FLOW,
  HEAT_CONSUMED_RESURCE_VOLUME,
  HEAT_TERMAL_ENERGY,
  HEAT_COST,
  HEAT_DETECT_UNDERDOSE,
  HEAT_DETECT_UPPERDOSE,
} = widgets

export default [
  LIGHT_STATUS({}),
  DATA_TIME({}),
  HEAT_SUPPLY_PIPE_TEMPERATURE({}),
  HEAT_RETURN_PIPE_TEMPERATURE({}),
  HEAT_SUPPLY_PIPE_PRESSURE({}),
  HEAT_RETURN_PIPE_PRESSURE({}),
  HEAT_SUPPLY_PIPE_MASS({}),
  HEAT_RETURN_PIPE_MASS({}),
  HEAT_SUPPLY_PIPE_FLOW({}),
  HEAT_RETURN_PIPE_FLOW({}),
  HEAT_CONSUMED_RESURCE_VOLUME({}),
  HEAT_TERMAL_ENERGY({}),
  HEAT_COST({}),
  HEAT_DETECT_UNDERDOSE({}),
  HEAT_DETECT_UPPERDOSE({}),
]
