export const formatErrorCode = (errorCode) => {
  const match = errorCode.match(/^([a-zA-Z_]+).([a-zA-Z_]+):([a-zA-Z_]+)$/)

  return (match || []).splice(1) || []
}

export default (errors) => errors.reduce((accumulator, error) => ({
  ...accumulator,
  [error.subject]: error.message,
}), {})
