import { takeLatest, put, select } from 'redux-saga/effects'
import cookie from 'react-cookies'
import qs from 'query-string'
import request from '@/helpers/axios'
import { getRouterLocation } from '@/store/selectors/router'
import { RESET_PASSWORD_API_URL } from '@/constants/apiRoutes'
import {
  USER_RESET_PASSWORD,
} from '@/store/actions/user'
import {
  PASSWORD,
  PASSWORD_CONFIRMATION,
} from '@/constants/forms/signUp'
import {
  successResetPassword,
  errorResetPassword,
} from '@/store/actions/user/resetPassword'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'

const ERRORS = {
  'resource_not_found.user_id': 'Невозможно найти пользователя',
  'resource_not_found.password_token': 'Истекло время установки пароля. Запросите новую ссылку для восстановления пароля',
  'access_denied.user_edit:password_exist': 'Пароль уже установлен',
  'validation_failed.password:invalid': 'Пароль не соответствует требованиям',
  'validation_failed.password:is_dictionary_word': 'Пароль не соответствует требованиям',
  'validation_failed.password:empty': 'Пароль не должен быть пустым',
}

function* resetUserPassword({ payload }) {
  const { form } = payload
  const { search } = yield select(getRouterLocation)
  const { token } = qs.parse(search)
  try {
    const response = yield request({
      url: `${RESET_PASSWORD_API_URL}?token=${token}`,
      method: 'put',
      body: form.values[PASSWORD],
      isSecure: false,
    })
    cookie.remove(
      SLS24_ACCESS_TOKEN,
      { path: '/' },
    )
    yield put(successResetPassword(response.data))
  } catch (error) {
    form.form.setSubmitting(false)
    form.form.setErrors({
      [PASSWORD_CONFIRMATION]: ERRORS[error.response.data.errorCode],
    })
    yield put(errorResetPassword(error))
  }
}

export default function* root() {
  yield takeLatest(USER_RESET_PASSWORD.BEGIN, resetUserPassword)
}
