import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InformationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 13.1C14.2387 13.1 14.4676 13.1948 14.6364 13.3636C14.8052 13.5324 14.9 13.7613 14.9 14V17.6C14.9 17.8387 14.8052 18.0676 14.6364 18.2364C14.4676 18.4052 14.2387 18.5 14 18.5C13.7613 18.5 13.5324 18.4052 13.3636 18.2364C13.1948 18.0676 13.1 17.8387 13.1 17.6V14C13.1 13.7613 13.1948 13.5324 13.3636 13.3636C13.5324 13.1948 13.7613 13.1 14 13.1ZM13.658 9.572C13.8771 9.48198 14.1229 9.48198 14.342 9.572C14.4525 9.61483 14.5534 9.67906 14.639 9.761C14.7185 9.84848 14.7824 9.94893 14.828 10.058C14.8784 10.1648 14.903 10.2819 14.9 10.4C14.9007 10.5184 14.878 10.6359 14.8332 10.7455C14.7884 10.8552 14.7224 10.9549 14.639 11.039C14.5515 11.1185 14.4511 11.1824 14.342 11.228C14.2057 11.284 14.0576 11.3057 13.9109 11.2911C13.7643 11.2765 13.6234 11.2261 13.5008 11.1444C13.3781 11.0626 13.2774 10.952 13.2075 10.8222C13.1377 10.6924 13.1007 10.5474 13.1 10.4C13.1033 10.1617 13.1965 9.93347 13.361 9.761C13.4466 9.67906 13.5475 9.61483 13.658 9.572ZM14 5C15.78 5 17.5201 5.52784 19.0001 6.51677C20.4802 7.50571 21.6337 8.91131 22.3149 10.5558C22.9961 12.2004 23.1743 14.01 22.8271 15.7558C22.4798 17.5016 21.6226 19.1053 20.364 20.364C19.1053 21.6226 17.5016 22.4798 15.7558 22.8271C14.01 23.1743 12.2004 22.9961 10.5558 22.3149C8.91131 21.6337 7.50571 20.4802 6.51678 19.0001C5.52784 17.5201 5 15.78 5 14C5 12.8181 5.23279 11.6478 5.68509 10.5558C6.13738 9.46392 6.80031 8.47177 7.63604 7.63604C8.47177 6.80031 9.46392 6.13738 10.5558 5.68508C11.6478 5.23279 12.8181 5 14 5ZM14 21.2C15.424 21.2 16.8161 20.7777 18.0001 19.9866C19.1841 19.1954 20.107 18.0709 20.6519 16.7553C21.1969 15.4397 21.3395 13.992 21.0617 12.5953C20.7838 11.1987 20.0981 9.91577 19.0912 8.90883C18.0842 7.90189 16.8013 7.21616 15.4046 6.93835C14.008 6.66053 12.5603 6.80312 11.2447 7.34807C9.92905 7.89302 8.80457 8.81586 8.01342 9.99989C7.22227 11.1839 6.8 12.576 6.8 14C6.8 15.9096 7.55857 17.7409 8.90883 19.0912C10.2591 20.4414 12.0904 21.2 14 21.2Z" fill={fill} className="svgFill" />
    </svg>
  )
}

InformationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InformationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InformationIcon)
