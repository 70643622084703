import widgets from '../widgets'

const {
  DMX_ID,
  ACTIVE_SCRIPT,
  SCRIPT_ACTIVATION_TIME,
  CURRENT_SCRIPT_NUMBER,
  DEFERRED_SCRIPT_NUMBER,
  DMX_ERROR_CODE,
  ALARM_STATE,
} = widgets

export default [
  DMX_ID({}),
  ACTIVE_SCRIPT({}),
  SCRIPT_ACTIVATION_TIME({}),
  CURRENT_SCRIPT_NUMBER({}),
  DEFERRED_SCRIPT_NUMBER({}),
  DMX_ERROR_CODE({}),
  ALARM_STATE({}),
]
