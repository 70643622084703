export const TEXT = 'text'
export const RADIO_GROUP = 'radioGroup'
export const PHOTO = 'photo'
export const FILE = 'file'
export const ARIA = 'aria'
export const SELECT = 'select'
export const CUSTOMS_FIELDS = 'customFields'
export const ADD_CUSTOMS_FIELDS = 'addCustomFields'

export const INFO_FIELD = 'info_field'

export const DANGER = 'danger'
export const NORMAL = 'normal'

export const FILTER = 'FILTER'
export const GEOZONE = 'GEOZONE'
export const PROJECT = 'PROJECT'
export const CONTROL_OBJECT = 'CONTROL_OBJECT'
export const DOWNLOAD_INTALATION_FILE = 'DOWNLOAD_INTALATION_FILE'

export const ACTION_TYPE = 'ACTION_TYPE'
export const CREATE_TYPE = 'CREATE_TYPE'

export const CREATE = 'CREATE'
export const PUT = 'PUT'
export const DELETE = 'DELETE'

export const LOADING = 'LOADING'
