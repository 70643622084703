import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import noop from 'lodash/noop'
import setSixDigitsAfterDot from '@/helpers/setSixDigitsAfterDot'
import {
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT,
  DOWNLOAD_INTALATION_FILE,
} from '@/constants/objectTypes'
import {
  ACTION_TYPE,
  CREATE_TYPE,
  CREATE,
  PUT,
} from '@/constants/semanticNames'

const typeToName = {
  [GEOZONE]: 'геозону',
  [PROJECT]: 'проект',
  [OBJECT_ELEMENT]: 'объект',
  [INTEGRATION]: 'интеграцию',
}

export const typeToZoom = {
  [GEOZONE]: 9,
  [OBJECT_ELEMENT]: 12,
}

export const generateAlerts = (
  type,
  actionType,
  setAlertConfig,
  card,
  closeAlert,
  closeAlertHandler,
  deleteGeoZoneHandler,
  deleteProjectHandler,
  deleteObjectHandler,
  deleteIntegrationHandler,
  cancelCreation,
  requestVerifyElements,
  requestEditObject,
) => {
  const typeToAction = {
    [GEOZONE]: deleteGeoZoneHandler,
    [PROJECT]: deleteProjectHandler,
    [OBJECT_ELEMENT]: deleteObjectHandler,
    [INTEGRATION]: deleteIntegrationHandler,
  }
  const actionTypeToAction = {
    [CREATE]: 'добавления',
    [PUT]: 'редактирование',
  }
  const actionTypeToActionForCancel = {
    [`${CREATE}-${GEOZONE}`]: 'инсталляцию',
    [`${PUT}-${GEOZONE}`]: 'редактирование',
    [`${CREATE}-${PROJECT}`]: 'добавление',
    [`${PUT}-${PROJECT}`]: 'редактирование',
    [`${CREATE}-${INTEGRATION}`]: 'добавление',
    [`${PUT}-${INTEGRATION}`]: 'редактирование',
    [`${CREATE}-${OBJECT_ELEMENT}`]: 'инсталляцию',
    [`${PUT}-${OBJECT_ELEMENT}`]: 'редактирование',
  }
  const actionTypeToName = {
    [GEOZONE]: 'геозоны',
    [PROJECT]: 'проекта',
    [OBJECT_ELEMENT]: 'объекта',
    [INTEGRATION]: 'интеграции',
  }
  const parentTypeToName = {
    [GEOZONE]: 'геозону',
    [PROJECT]: 'геозону',
    [INTEGRATION]: 'проект',
    [OBJECT_ELEMENT]: 'интеграцию',
    [DOWNLOAD_INTALATION_FILE]: 'объект или интеграцию',
  }
  return {
    deleteElementHandler: () => {
      setAlertConfig({
        type: 'critical',
        title: 'Внимание!',
        message: `Вы действительно хотите удалить ${typeToName[type]}\n“${get(card, 'data.name', null)}”\nиз системы?`,
        onClose: closeAlert,
        secondaryAction: closeAlert,
        primaryAction: typeToAction[type] || noop,
      })
    },
    createEditElementInvalidValuesHandler: () => {
      setAlertConfig({
        type: 'warning',
        title: 'Невозможно выполнить!',
        message: `Для ${actionTypeToAction[actionType]} ${actionTypeToName[type]} необходимо\nустановить все обязательные параметры`,
        onClose: closeAlert,
        primaryAction: closeAlert,
      })
    },
    selectRootElement: () => {
      setAlertConfig({
        type: 'warning',
        title: 'Невозможно выполнить!',
        message: 'Для продолжения необходимо\nвыбрать геозону',
        onClose: closeAlertHandler,
        primaryAction: closeAlertHandler,
      })
    },
    syncElement: () => {
      setAlertConfig({
        type: 'success',
        title: 'Синхронизация объекта',
        message: 'Пройти верификацию\nили сохранить текущие настройки?',
        onClose: closeAlertHandler,
        primaryAction: noop,
      })
    },
    parentCreateError: (parentType) => {
      setAlertConfig({
        type: 'warning',
        title: 'Невозможно выполнить!',
        message: `Для продолжения необходимо\nвыбрать ${parentTypeToName[parentType]}.`,
        onClose: closeAlertHandler,
        primaryAction: closeAlert,
      })
    },
    cancelCreationEdit: () => {
      setAlertConfig({
        type: 'critical',
        title: 'Внимание!',
        message: `Вы действительно хотите\nотменить ${actionTypeToActionForCancel[`${actionType}-${type}`]} ${actionTypeToName[type]}?`,
        onClose: closeAlert,
        primaryAction: cancelCreation,
        secondaryAction: closeAlert,
      })
    },
    verifyElement: (data) => {
      setAlertConfig({
        type: 'success',
        title: 'Синхронизация объекта',
        message: 'Пройти верификацию\nили сохранить текущие настройки?',
        onClose: closeAlert,
        primaryAction: () => requestVerifyElements(data),
        secondaryAction: () => requestEditObject(data),
      })
    },
  }
}

export const formatDataForObject = (data = {}, intl) => {
  const { objectElementFieldsDto = [] } = data
  const formattedFields = objectElementFieldsDto.reduce((accumulator, element) => {
    const splitName = element.fieldName.split('.')
    if (element.passportBlock === splitName[0]) {
      return {
        ...accumulator,
        [element.passportBlock]: {
          ...accumulator[element.passportBlock] || {},
          [splitName[1]]: (element.value === 'true' || element.value === 'false') ? JSON.parse(element.value) : element.value,
        },
      }
    }
    return {
      ...accumulator,
      [element.passportBlock]: {
        ...accumulator[element.passportBlock] || {},
        customFields: [
          ...((accumulator[element.passportBlock] || {}).customFields || []),
          {
            ...element,
          },
        ],
      },
    }
  }, {})
  set(formattedFields, 'GENERAL_INFORMATION.NAME', data.name)
  if (intl) {
    set(formattedFields, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', intl.messages[`globalNames.objectTypes.${data.objectType}`])
  }
  const prevObjectElementFieldsDto = objectElementFieldsDto.reduce((accumulator, element) => ({
    ...accumulator,
    [element.fieldName]: element,
  }), {})

  return {
    ...formattedFields,
    integrationId: get(data, 'integrationId', null),
    parentTreeElementId: get(data, 'parentTreeElementId', null),
    integrationType: get(data, 'integrationType', null),
    objectElementId: get(data, 'objectElementId', null),
    objectType: get(data, 'objectType', null),
    objectState: get(data, 'objectState', null),
    parentObjectType: get(data, 'parentObjectType', null),
    prevObjectElementFieldsDto,
    location: {
      latitude: get(data, 'geoLocationDto.latitude', 0),
      longitude: get(data, 'geoLocationDto.longitude', 0),
      id: get(data, 'geoLocationDto.id', 0),
    },
  }
}

export const shouldHaveCoorsToType = {
  [GEOZONE]: true,
  [PROJECT]: false,
  [OBJECT_ELEMENT]: true,
}

export const isRenderGeoZoneForm = (formValues) => (formValues[ACTION_TYPE] === CREATE && formValues[CREATE_TYPE] === GEOZONE)
|| (formValues[ACTION_TYPE] === PUT && formValues.type === GEOZONE)

export const isRenderProjectForm = (formValues) => (formValues[ACTION_TYPE] === CREATE && formValues[CREATE_TYPE] === PROJECT)
|| (formValues[ACTION_TYPE] === PUT && formValues.type === PROJECT)

export const isRenderIntegrationForm = (formValues) => (formValues[ACTION_TYPE] === CREATE && formValues[CREATE_TYPE] === INTEGRATION)
|| (formValues[ACTION_TYPE] === PUT && formValues.type === INTEGRATION)

export const allowNewPointCreation = (
  formValues,
  alertConfig,
) => (shouldHaveCoorsToType[formValues[CREATE_TYPE]]
  ? isEmpty(alertConfig) && formValues[ACTION_TYPE] === CREATE
  : false)

export const newPointWasCreated = (
  formValues,
  alertConfig,
  newPoints,
) => (
  shouldHaveCoorsToType[formValues[CREATE_TYPE]]
    ? isEmpty(alertConfig) && newPoints.length !== 0 && formValues[ACTION_TYPE] === CREATE
    : false)

export const isCanCreateElement = (
  formValues,
) => formValues.id
&& formValues.type
&& (
  ((formValues[ACTION_TYPE] === CREATE
    && formValues[CREATE_TYPE] === GEOZONE)
    || formValues[CREATE_TYPE] === PROJECT
    || formValues[CREATE_TYPE] === INTEGRATION
    || formValues[CREATE_TYPE] === OBJECT_ELEMENT)
    || formValues[ACTION_TYPE] === PUT)

export const formatGeoZoneCreateValues = (card) => ({
  parentId: card.data.treeElementId,
})

export const formatGeoZonePutValues = (card) => ({
  ...card.data,
  parentId: card.data.parentTreeElementId,
  lat: setSixDigitsAfterDot(get(card, 'data.geoLocationDto.latitude', null)),
  lon: setSixDigitsAfterDot(get(card, 'data.geoLocationDto.longitude', null)),
  mark: get(card, 'data.note', null),
})

export const formatProjectCreateValues = (card) => ({
  parentId: card.data.treeElementId,
})

export const formatProjectPutValues = (card) => ({
  ...card.data,
  parentId: card.data.parentTreeElementId,
  mark: get(card, 'data.note', null),
})

export const formatObjectCreateValues = (card) => ({
  ...card.data,
  integrationId: get(card, 'data.integrationId', null),
  parentTreeElementId: get(card, 'data.treeElementId', null),
  integrationType: get(card, 'data.integrationType', null),
})

export const formatObjectPutValues = (card) => ({
  ...formatDataForObject(card.data),
})

export const formatIntegrationCreateValues = (card) => ({
  parentId: card.data.treeElementId,
  projectId: card.data.projectId,
})

export const formatIntegrationPutValues = (card) => ({
  ...card.data,
  parentId: card.data.parentTreeElementId,
  projectId: card.data.projectId,
  name: card.data.integrationName,
})

export const toBase64 = (file) => new Promise((resolve, reject) => {
  if (!file) {
    return null
  }
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = (loadError) => reject(loadError)
})

export const handlePhotoConvertToBase64 = async (fileValue, setValue) => {
  const base64 = await toBase64(fileValue)
  setValue(base64)
}
