import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TreshIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 9V46C9 47.1046 9.89543 48 11 48H39C40.1046 48 41 47.1046 41 46V9H39V46H11V9H9Z" fill={fill} className="svgFill"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32 7H43V9H7V7H18V4C18 2.89543 18.8954 2 20 2H30C31.1046 2 32 2.89543 32 4V7ZM30 4H20V7H30V4Z" fill={fill} className="svgFill"/>
      <path d="M31.2929 18.2929C31.6834 17.9024 32.3166 17.9024 32.7071 18.2929C33.0976 18.6834 33.0976 19.3166 32.7071 19.7071L26.4142 26L32.7071 32.2929C33.0976 32.6834 33.0976 33.3166 32.7071 33.7071C32.3166 34.0976 31.6834 34.0976 31.2929 33.7071L25 27.4142L18.7071 33.7071C18.3166 34.0976 17.6834 34.0976 17.2929 33.7071C16.9024 33.3166 16.9024 32.6834 17.2929 32.2929L23.5858 26L17.2929 19.7071C16.9024 19.3166 16.9024 18.6834 17.2929 18.2929C17.6834 17.9024 18.3166 17.9024 18.7071 18.2929L25 24.5858L31.2929 18.2929Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

TreshIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TreshIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TreshIcon)
