import { handleActions } from 'redux-actions'
import {
  USER_REFRESH_TOKEN,
} from '@/store/actions/user'

import {
  NOT_REQUESTED,
  IDLE,
  PENDING,
  ERROR,
  REQUEST_STATUSES,
} from '@/constants/requests'

const initialState = {
  errors: [],
  data: {},
  isRequested: false,
  status: REQUEST_STATUSES[NOT_REQUESTED],
}

export const reducer = handleActions(
  {
    [USER_REFRESH_TOKEN.BEGIN]: () => ({
      ...initialState,
      isRequested: true,
    }),
    [USER_REFRESH_TOKEN.END]: () => ({
      ...initialState,
      isRequested: false,
    }),
    [USER_REFRESH_TOKEN.REQUEST]: () => ({
      ...initialState,
      status: REQUEST_STATUSES[PENDING],
    }),
    [USER_REFRESH_TOKEN.SUCCESS]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[IDLE],
    }),
    [USER_REFRESH_TOKEN.ERROR]: (state, { payload }) => ({
      ...state,
      errors: payload,
      status: REQUEST_STATUSES[ERROR],
    }),
  },
  initialState,
)

export default reducer
