import * as formatDataFunc from '@/helpers/dataFormator'
import { getIsElementary } from '@/helpers/maps'
import treeData from '@/data/mapTree/mashTreeData'
import { regionsById } from '@/data/regionCoords'
import {
  CONTROLLER,
  CITY,
} from '@/constants/objectTypes'

export const getPlainDataFromTree = (data) => {
  const dataWithoutChildren = { ...data, children: null }
  delete dataWithoutChildren.children

  const plainChildren = data.children
    ? data.children.reduce((accumulator, item) => [
      ...accumulator,
      ...getPlainDataFromTree(item),
    ], [])
    : []

  return [
    dataWithoutChildren,
    ...plainChildren,
  ]
}

export const setTreePath = ({
  data,
  path = '',
  prefix = '',
}) => {
  const dataWithPath = {
    ...data,
    [`${prefix}path`]: path,
  }

  const plainChildren = data.children
    ? data.children
      .map((item, index) => setTreePath({
        data: item,
        path: `${path}children[${index}]${item.children ? '.' : ''}`,
        prefix,
      }))
    : []

  const children = data.children
    ? {
      children: plainChildren,
    }
    : {}

  return {
    ...dataWithPath,
    ...children,
  }
}

export const tree = setTreePath({ data: treeData })
const plainData = getPlainDataFromTree(tree)
const controllers = plainData
  .filter((item) => item.type === CONTROLLER)
  .map((element) => formatDataFunc.getObjectStatistic(element, plainData))
const objects = plainData.filter((item) => getIsElementary(item.type))
export const regions = plainData
  .filter((item) => item.type === CITY)
  .map((item) => ({ ...item, border: regionsById[item.id] || [] }))

export const pins = objects.concat(controllers)
