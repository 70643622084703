import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getDictionaries = createSelector(
  getState,
  (state) => get(state, 'dictionaries', {}),
)

export const getAllApplications = createSelector(
  getDictionaries,
  (state) => get(state, 'allApplications', {}),
)

export const getApplications = createSelector(
  getAllApplications,
  (dictionaries) => get(dictionaries, 'applications', []),
)

export const getApplicationsModels = createSelector(
  getAllApplications,
  (dictionaries) => get(dictionaries, 'applicationsModels', []),
)
