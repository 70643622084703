import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import RadioButton from '@/components/controls/RadioButton'
import RadioGroupStyled from './styles'

const RadioGroup = ({
  value, options, onChange, direction, type,
}) => {
  const handleChange = useCallback((currentValue) => () => {
    onChange(currentValue)
  }, [onChange])

  return (
    <RadioGroupStyled direction={direction}>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          {...option}
          active={value === option.value}
          onChange={handleChange(option.value)}
          type={type}
        />
      ))}
    </RadioGroupStyled>
  )
}

RadioGroup.propTypes = {
  value: pt.oneOfType([pt.number, pt.string, pt.bool]),
  options: pt.arrayOf(pt.shape({
    value: pt.oneOfType([pt.number, pt.string, pt.bool]),
    label: pt.oneOfType([pt.number, pt.string, pt.object]),
  })),
  onChange: pt.func,
  direction: pt.oneOf(['row', 'column']),
}
RadioGroup.defaultProps = {
  value: null,
  options: [],
  onChange: noop,
  direction: 'column',
}

export default RadioGroup
