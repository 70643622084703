import React from 'react'

import BarChart from '@/components/charts/BarChart'

const DATA = [
  {
    dateStep: 'Фев 2020',
    all: 46000,
    connected: 37,
  },
  {
    dateStep: 'Мар 2020',
    all: 46100,
    connected: 37,
  },
  {
    dateStep: 'Апр 2020',
    all: 46250,
    connected: 37,
  },
  {
    dateStep: 'Май 2020',
    all: 46300,
    connected: 37,
  },
  {
    dateStep: 'Июн 2020',
    all: 46500,
    connected: 37,
  },
  {
    dateStep: 'Июл 2020',
    all: 47343,
    connected: 37,
  },
]

const SERIES = [
  {
    fill: '#81c13c',
    name: 'all',
    title: 'Всего объектов в городе',
  },
  {
    fill: '#4bafe5',
    name: 'connected',
    title: 'Подключено',
  },
]

const Widget = () => (
  <BarChart
    data={DATA}
    seriesNames={SERIES}
    mainAxisName="dateStep"
    customGraphMargin={{
      top: 0,
      right: 10,
      left: -30,
      bottom: -5,
    }}
    legend={{
      align: 'center',
      verticalAlign: 'bottom',
      iconType: 'square',
    }}
  />
)

export default Widget
