import {
  TEXT,
} from '@/constants/semanticNames'

export default [
  {
    selector: 'generalInfo.name',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'generalInfo.identifier',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
    immutable: true,
  },
  {
    selector: 'generalInfo.equipmentType',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'generalInfo.versionApi',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'generalInfo',
  },
  {
    selector: 'exploitation.startDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'exploitation.organization',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
  {
    selector: 'exploitation.owner',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'exploitation',
  },
]
