import React, {
  useState,
  useMemo,
  useCallback,
} from 'react'
import pt from 'prop-types'
import QRCode from 'qrcode.react'
import coreIcons from '@/components/icons/core'
import memoWithName from '@/hocs/memoWithName'
import { QRCODE_SIZE } from '@/constants/sizes'
import {
  Container,
  ImageContainer,
  Image,
  ImageTitle,
  StatusCircle,
  ControlsContainer,
  QRCodeContainer,
} from './styles'

const ImagesSlider = ({ images }) => {
  const ImageIcon = useMemo(() => coreIcons.ImageIcon, [])
  const QRCodeIcon = useMemo(() => coreIcons.QRCodeIcon, [])
  const PrintIcon = useMemo(() => coreIcons.PrintIcon, [])
  const VideoIcon = useMemo(() => coreIcons.VideoIcon, [])

  const [activeIndex, setActiveIndex] = useState(0)

  const switchToQRCode = useCallback(() => {
    const newIndex = images.findIndex((element) => element.type === 'qrCode')
    if (newIndex === -1) {
      return null
    }
    setActiveIndex(newIndex)
    return null
  }, [images])

  const switchToVideo = useCallback(() => {
    const newIndex = images.findIndex((element) => element.type === 'video')
    if (newIndex === -1) {
      return null
    }
    setActiveIndex(newIndex)
    return null
  }, [images])

  const switchToImage = useCallback(() => {
    const newIndex = images.findIndex((element) => element.type === 'image')
    if (newIndex === -1) {
      return null
    }
    setActiveIndex(newIndex)
    return null
  }, [images])

  const renderVideoTitle = useCallback(() => (
    <>
      <ImageTitle>
        {images[activeIndex].title}
        {' ( '}
        <StatusCircle active={images[activeIndex].online} />
        {'  realtime )'}
      </ImageTitle>
    </>
  ), [images, activeIndex])

  const renderObjectQRCode = useCallback(() => (
    <>
      <ImageIcon onClick={switchToImage} />
      <PrintIcon />
      <VideoIcon onClick={switchToVideo} />
    </>
  ), [switchToImage, switchToVideo])

  const renderObjectVideo = useCallback(() => (
    <>
      <ImageIcon onClick={switchToImage} />
      <QRCodeIcon onClick={switchToQRCode} />
      {renderVideoTitle()}
    </>
  ), [renderVideoTitle, switchToImage, switchToQRCode])

  const renderObjectImage = useCallback(() => (
    <>
      <QRCodeIcon onClick={switchToQRCode} />
      <VideoIcon onClick={switchToVideo} />
    </>
  ), [switchToVideo, switchToQRCode])

  const renderImage = useCallback(() => (
    <>
      { images[activeIndex].type === 'qrCode'
        ? (
          <QRCodeContainer>
            <QRCode value={images[activeIndex].url} size={QRCODE_SIZE} />
          </QRCodeContainer>
        )
        : <Image src={images[activeIndex].url} /> }
    </>
  ), [activeIndex, images])

  const renderControlBar = useCallback(() => (
    <ControlsContainer>
      {images[activeIndex].type === 'image' && renderObjectImage()}
      {images[activeIndex].type === 'qrCode' && renderObjectQRCode()}
      {images[activeIndex].type === 'video' && renderObjectVideo()}
    </ControlsContainer>
  ), [activeIndex, images, renderObjectImage, renderObjectQRCode, renderObjectVideo])

  return (
    <Container>
      <ImageContainer>
        {renderImage()}
      </ImageContainer>
      {images.length > 1 && (
        renderControlBar()
      )}
    </Container>
  )
}

ImagesSlider.propTypes = {
  images: pt.arrayOf(pt.string).isRequired,
}
ImagesSlider.defaultProps = {}

export default memoWithName(ImagesSlider)
