import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_CITY_PROCESSES } from '@/constants/apiRoutes'
import {
  GET_ALL_CITY_PROCESSES_OPTIONS,
} from '@/store/actions/dictionaries'
import {
  responseGetAllCityProcessesOptions,
  errorGetAllCityProcessesOptions,
} from '@/store/actions/dictionaries/cityProcesses'

function* getDictionary() {
  try {
    const response = yield request({
      url: GET_CITY_PROCESSES,
      method: 'get',
    })


    yield put(responseGetAllCityProcessesOptions(response.data))
  } catch (error) {
    yield put(errorGetAllCityProcessesOptions(error))
  }
}

export default function* () {
  yield takeLatest(GET_ALL_CITY_PROCESSES_OPTIONS.REQUEST, getDictionary)
}
