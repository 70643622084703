import { fork, takeLatest, all } from 'redux-saga/effects'
import { ADD_SERVICE_DESK_INTEGRATION } from '@/store/actions/serviceDeskMyCity'
import addIntegrationEventSaga from './addIntegration'

function* watchStateRequest() {
  yield takeLatest(ADD_SERVICE_DESK_INTEGRATION.EVENTS, addIntegrationEventSaga)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
