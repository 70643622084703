import {
  DEFAULT_THEME,
  FUEL_YELLOW,
  CERULEAN,
  MALACHITE,
  RED,
  WHITE,
  PICKLED_BLUEWOOD,

  BLACK,
  BLACK_HAZE,
  AZURE_RADIANCE,
  SCIENCE_BLUE,
  REGENT_GRAY,
  SHARK,
  MISCHKA,
  BLUE_BAYOUX,
  HEARTHER,
  CATSKILL_WHITE,
  ATHENS_GRAY,
  SHARK_DARK,
  ERROR_RED,
  ATLANTIS,
  AMARANTH,
  TUNA,
  GHOST,
  ORANGE_PEEL,

  BLACK_HAZE_GRADIENT,
  BLACK_HAZE_GRADIENT_LIGHT,
  BLACK_HAZE_GRADIENT_REVERSE,
  ALTO,
  WHITE_TRANSPERANT_2,
  WHITE_TRANSPERANT_4,
  WHITE_TRANSPERANT_5,
  GREY_TRANSPERANT,
  BLACK_TRANSPERANT,
  WHITE_SUN,
  SILVER_SAND,
  WILD_SAND,
  BOULDER,
  GRAY,
  MINE_SHAFT,
  PIN_SHADOW,
  MIDDLE_RED,
  GRAPH_SHASOW,
  ATLANTIS_LIGHT,
  BLUE_LIGHT,
  LIGHT_GRAY,
  DARK_GRAY,
  SELESTIAL_GRAY,
  FORM_INPUT_SHADOW,
  SILVER_DARK,
  LIGHT_BLACK,
} from '@/constants/styles/defaultTheme'
import signInBackground from '@/assets/images/signin-background-light.jpg'
import background from '@/assets/backgrounds/lightTheme.jpg'

export const colors = {
  backgrounds: {
    default: BLACK_HAZE,
    header: WHITE,
    extraLight: BLACK_HAZE,
    drawer: WHITE,
    light: BLUE_BAYOUX,
    dark: BLACK_HAZE,
    button: AZURE_RADIANCE,
    buttonHover: SCIENCE_BLUE,
    textField: CATSKILL_WHITE,
    textFieldActive: WHITE,
    textFieldDisabled: SILVER_SAND,
    disabled: REGENT_GRAY,
    radio: AZURE_RADIANCE,
    select: CATSKILL_WHITE,
    selectInput: GRAY,
    redisign: {
      default: BLACK_HAZE,
      secondary: GRAY,
      silverDark: SILVER_DARK,
      tertiary: SELESTIAL_GRAY,
      topDownGradient: BLACK_HAZE_GRADIENT,
      widgetLight: BLACK_HAZE_GRADIENT_LIGHT,
      defaultReverse: BLACK_HAZE_GRADIENT_REVERSE,
      light: BLACK_HAZE,
      header: WHITE,
      dark: BLACK_HAZE_GRADIENT,
      hightLighter: ALTO,
      widget: LIGHT_GRAY,
      gray: GRAY,
      lightGray: GRAY,
      darkGray: DARK_GRAY,
      green: ATLANTIS_LIGHT,
      blue: BLUE_LIGHT,
      warning: ORANGE_PEEL,
      disabled: BOULDER,
      error: MIDDLE_RED,
      table: {
        dark: WILD_SAND,
      },
      input: {
        light: WHITE,
        dark: MINE_SHAFT,
      },
    },
    chart: {
      default: ATLANTIS,
      critical: AMARANTH,
      middle: ORANGE_PEEL,
      normal: REGENT_GRAY,
      low: AZURE_RADIANCE,
    },
    notification: {
      success: ATLANTIS,
      info: AZURE_RADIANCE,
      error: AMARANTH,
      warning: ORANGE_PEEL,
    },
    controls: {
      green: ATLANTIS,
      red: AMARANTH,
      gray: REGENT_GRAY,
    },
  },
  colors: {
    title: BLACK,
    default: MINE_SHAFT,
    dim: BOULDER,
    dark: PICKLED_BLUEWOOD,
    light: WHITE,
    disabled: REGENT_GRAY,
    tab: BLUE_BAYOUX,
    tabDefault: HEARTHER,
    logo: BLACK,
    svg: MINE_SHAFT,
    tooltip: WHITE,
    treeIcons: HEARTHER,
    svgReverse: MISCHKA,
    textField: REGENT_GRAY,
    textFieldActive: SHARK_DARK,
    charts: BLUE_BAYOUX,
    online: ATLANTIS,
    notation: BLUE_BAYOUX,
    error: ERROR_RED,
    select: BLUE_BAYOUX,
    option: BLUE_BAYOUX,
    redisign: {
      default: MINE_SHAFT,
      secondary: MINE_SHAFT,
      svg: MINE_SHAFT,
      disabled: MINE_SHAFT,
      dark: MINE_SHAFT,
      error: MIDDLE_RED,
      lightBlack: LIGHT_BLACK,
    },
    switchButton: {
      default: BLUE_BAYOUX,
    },
    notification: {
      title: SHARK_DARK,
      description: TUNA,
      users: BLUE_BAYOUX,
    },
    subTitle: GHOST,
  },
  borders: {
    default: SILVER_SAND,
    textField: ATHENS_GRAY,
    radio: ATHENS_GRAY,
    tree: BOULDER,
    disabled: REGENT_GRAY,
    redisign: {
      default: SILVER_SAND,
      dark: MINE_SHAFT,
      hightLight: SILVER_SAND,
      contrast: WHITE_SUN,
      radio: SILVER_SAND,
      gray: GRAY,
      lightBlack: LIGHT_BLACK,
    },
  },
  shadows: {
    widget: `${SHARK}17`,
    widgetPrimary: SILVER_SAND,
    widgetSecondary: WILD_SAND,
    white2: WHITE_TRANSPERANT_2,
    white4: WHITE_TRANSPERANT_4,
    white5: WHITE_TRANSPERANT_5,
    grey: GREY_TRANSPERANT,
    black: BLACK_TRANSPERANT,
    pin: PIN_SHADOW,
    graph: GRAPH_SHASOW,
    field: FORM_INPUT_SHADOW,
  },
  messages: {
    default: REGENT_GRAY,
    error: ERROR_RED,
    ok: MALACHITE,
    warning: ORANGE_PEEL,
    critical: MIDDLE_RED,
    fix: ATLANTIS_LIGHT,
  },
  white: WHITE,
  mapFilter: 'grayscale(100%) brightness(100%)',
}

export const images = {
  signIn: signInBackground,
}

export const sizes = {
  headerHeight: 48,
  menuRowHeight: 48,
  sidebarWidthMax: 240,
  sidebarWidthMin: 48,
  sideBarViewTreeMin: 280,
  viewTreeHeightFix: 158,
  tabsNamesHeight: 48,
  unitCardWidth: 230,
  passportizationWidth: 310,
}

export const backgroundImage = background

export const mapColors = {
  green: MALACHITE,
  yellow: FUEL_YELLOW,
  blue: CERULEAN,
  red: RED,
  border: PICKLED_BLUEWOOD,
  color: WHITE,
}

export default {
  ...DEFAULT_THEME,
  colors,
  images,
  mapColors,
  backgroundImage,

}
