import { GET_ANALYTICS_ALARMS_TABLE } from './index'

export const requestAnalyticsAlarmsTable = (payload) => ({
  type: GET_ANALYTICS_ALARMS_TABLE.REQUEST,
  payload,
})

export const successAnalyticsAlarmsTable = (payload) => ({
  type: GET_ANALYTICS_ALARMS_TABLE.SUCCESS,
  payload,
})

export const errorAnalyticsAlarmsTable = (payload) => ({
  type: GET_ANALYTICS_ALARMS_TABLE.ERROR,
  payload,
})
