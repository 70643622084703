import { GET_FAULT_ANALYTICS_FILE } from './index'

export const requestSummaryAlarmAnalyticsFile = (payload) => ({
  type: GET_FAULT_ANALYTICS_FILE.REQUEST,
  payload,
})

export const successSummaryAlarmAnalyticsFile = (payload) => ({
  type: GET_FAULT_ANALYTICS_FILE.SUCCESS,
  payload,
})

export const errorSummaryAlarmAnalyticsFile = (payload) => ({
  type: GET_FAULT_ANALYTICS_FILE.ERROR,
  payload,
})
