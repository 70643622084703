import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  errorDeleteTenantGroup,
} from '@/store/actions/users/group/deleteGroup'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import {
  DELETE_GROUP,
  updateSelectedUserType,
} from '@/store/actions/users'
import { generateDeleteGroup } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'


function* deleteGroupSaga({ payload }) {
  const toast = createNotifications()
  const { tenantId, groupId, selectedUsersGroup } = payload
  try {
    const response = yield request({
      url: generateDeleteGroup(groupId),
      method: 'delete',
    })
    if (response) {
      yield put(requestGetTenantGroups(tenantId))
      yield put(updateSelectedUserType(null))
      toast({
        title: 'Успешная операция!',
        description: `Группа пользователей ${selectedUsersGroup.name} была успешно удалена`,
      })
    }
  } catch (error) {
    yield put(errorDeleteTenantGroup())
    toast({
      title: 'Ошибка операции!',
      description: 'Ошибка',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_GROUP.REQUEST, deleteGroupSaga)
}
