import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import { getIsReadByStatus } from '@/helpers/alarmsHelper'
import {
  GET_TABLE_STATISTICS,
} from '@/store/actions/alarmManager'
import {
  successTableStatistics,
  errorTableStatistics,
} from '@/store/actions/alarmManager/getAvarage'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import {
  ALARM_MANAGER_AVERAGE_ENDPOINT,
} from '@/constants/apiRoutes'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getEventTypes,
  getEventStatus,
  getStartDate,
  getEndDate,
  getTableParameters,
} from '@/store/selectors/alarmManager'
import { LIGHT } from '@/constants/objectTypes'

function* getAverageDataSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.isHasInstallations && !node.internalId) {
      yield put(successTableStatistics({
        [ERROR]: 0,
        [WARNING]: 0,
        [OK]: 0,
      }))
      return null
    }

    const tableParameters = yield select(getTableParameters)

    const eventTypes = yield select(getEventTypes)
    const eventStatus = yield select(getEventStatus)

    const startDate = yield select(getStartDate)
    const endDate = yield select(getEndDate)

    const response = yield request({
      url: ALARM_MANAGER_AVERAGE_ENDPOINT,
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        installationId: (node && node.internalId) ? node.internalId : null,
        objectId: (node && node.type === LIGHT) ? node.id : null,
        range: {
          start: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
          end: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
        },
        isRead: getIsReadByStatus(eventStatus),
        alertTypes: eventTypes,
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
    })
    if (response) {
      const { error, data } = response
      if (error) {
        yield put(errorTableStatistics())
      } else {
        yield put(successTableStatistics({
          [ERROR]: data.countError.toLocaleString(),
          [WARNING]: data.countWarning.toLocaleString(),
          [OK]: data.countNormal.toLocaleString(),
        }))
      }
    } else {
      yield put(errorTableStatistics())
    }
  } catch (error) {
    yield put(errorTableStatistics(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_TABLE_STATISTICS.REQUEST, getAverageDataSaga)
}
