import { GET_CONSUMPTION_ANALYSIS_TABLE_DATA } from './index'

export const requestTableData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_TABLE_DATA.REQUEST,
  payload,
})

export const responseTableData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_TABLE_DATA.SUCCESS,
  payload,
})
export const errorTableData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_TABLE_DATA.ERROR,
  payload,
})
