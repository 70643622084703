import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_ANALYTICS_ALARMS_FILE,
} from '@/store/actions/analyticsAlarms'
import { generateAnalyticsAlarmsFileUrl } from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
// TODO: fix actions name
import {
  successAlarmManagerFile,
  errorAlarmManagerFile,
} from '@/store/actions/analyticsAlarms/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
  getCheckBoxOption,
} from '@/store/selectors/analyticsAlarms'
import { LIGHT } from '@/constants/objectTypes'

function* getAlarmManagerFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const node = yield select(getSelectedNode)
    const filterOptions = yield select(getCheckBoxOption)
    const filterOptionsArray = Object.keys(filterOptions)
      .reduce((accumulator, filter) => {
        if (filterOptions[filter]) {
          return [...accumulator, filter]
        }

        return accumulator
      }, [])

    const tableParameters = yield select(getTableParameters)

    const response = yield request({
      url: generateAnalyticsAlarmsFileUrl({
        fileType: payload,
      }),
      method: 'post',
      body: {
        // TODO change to tenantId from user
        tenantId: 1,
        installationId: (node && node.internalId) ? node.internalId : null,
        objectId: (node && node.type === LIGHT) ? node.id : null,
        alertTypes: filterOptionsArray,
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      },
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${node.internalId || 'all'}_analytics-alarms.${payload}`,
    })
    yield put(successAlarmManagerFile())
  } catch (error) {
    yield put(errorAlarmManagerFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет. Не выбран ни один столбец для формирования отчета',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_ANALYTICS_ALARMS_FILE.REQUEST, getAlarmManagerFileSaga)
}
