const downloadFileFromBlob = ({
  blob,
  fileName = 'filename',
  type = 'application/octet-stream',
}) => {
  const newBlob = new window.Blob([blob], { type })
  const fileURL = window.URL.createObjectURL(newBlob)
  const fileLink = document.createElement('a')
  fileLink.href = fileURL
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName)
  }
  fileLink.setAttribute('download', fileName)
  document.body.appendChild(fileLink)

  fileLink.click()
  fileLink.parentNode.removeChild(fileLink)
}

export default downloadFileFromBlob
