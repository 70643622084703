import { UPDATE_USER } from '../index'

export const beginUpdateUser = () => ({
  type: UPDATE_USER.BEGIN,
})

export const endUpdateUser = () => ({
  type: UPDATE_USER.END,
})

export const requestUpdateUser = (forms) => ({
  type: UPDATE_USER.REQUEST,
  payload: forms,
})

export const responseUpdateUser = (user) => ({
  type: UPDATE_USER.SUCCESS,
  payload: user,
})

export const errorUpdateUser = (error) => ({
  type: UPDATE_USER.ERROR,
  payload: error,
})
