import { useEffect, useState } from 'react'

const useAccessOptions = (
  selectedEntity = {},
) => {
  const [notification, setNotification] = useState(null)
  const [deleteProcess, setDeleteProcess] = useState(false)
  const [operatedAccess, setOperatedAccess] = useState({})
  const [isAccessOpen, setAccessOpen] = useState(false)

  const [selectedAccess, setSelectedAccess] = useState([])
  const [updatedAccess, setUpdatedAccess] = useState([])
  const [displayAccess, setDisplayAccess] = useState([])
  const [applicationAccess, setApplicationAccess] = useState([])
  const [applicationModelsAccess, setApplicationModelsAccess] = useState([])

  useEffect(() => {
    setDisplayAccess(selectedEntity.access || [])
  }, [selectedEntity])

  return {
    updatedAccess,
    setUpdatedAccess,
    displayAccess,
    setDisplayAccess,
    selectedAccess,
    setSelectedAccess,
    isAccessOpen,
    setAccessOpen,
    operatedAccess,
    setOperatedAccess,
    deleteProcess,
    setDeleteProcess,
    notification,
    setNotification,
    applicationAccess,
    setApplicationAccess,
    applicationModelsAccess,
    setApplicationModelsAccess,
  }
}

export default useAccessOptions
