import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ImageIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8125 7.875C12.2602 7.87485 12.6895 7.69686 13.006 7.38018C13.3224 7.0635 13.5002 6.63408 13.5 6.18638C13.4999 5.73867 13.3219 5.30937 13.0052 4.9929C12.6885 4.67643 12.2591 4.49873 11.8114 4.49888C11.5897 4.49895 11.3702 4.54269 11.1654 4.62759C10.9606 4.71249 10.7746 4.83689 10.6179 4.9937C10.4612 5.1505 10.3369 5.33663 10.2522 5.54146C10.1674 5.7463 10.1238 5.96582 10.1239 6.1875C10.1239 6.40918 10.1677 6.62867 10.2526 6.83345C10.3375 7.03823 10.4619 7.22428 10.6187 7.38098C10.7755 7.53768 10.9616 7.66196 11.1665 7.74672C11.3713 7.83149 11.5908 7.87507 11.8125 7.875ZM15.75 11.5133L15.1729 10.872C14.9619 10.637 14.7038 10.4491 14.4153 10.3205C14.1269 10.1919 13.8147 10.1254 13.4989 10.1254C13.1831 10.1254 12.8708 10.1919 12.5824 10.3205C12.294 10.4491 12.0359 10.637 11.8249 10.872L11.088 11.6933L5.625 5.625L2.25 9.37463V2.25H18C18 1.00736 16.9926 0 15.75 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25V15.75C0 16.3467 0.237053 16.919 0.65901 17.341C1.08097 17.7629 1.65326 18 2.25 18H15.75C16.3467 18 16.919 17.7629 17.341 17.341C17.7629 16.919 18 16.3467 18 15.75V2.25H15.75V11.5133Z" fill={fill} className="svgFill" />
    </svg>

  )
}

ImageIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ImageIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ImageIcon)
