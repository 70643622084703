import {
  useDispatch,
  useSelector,
  shallowEqual,
} from 'react-redux'
import { useMemo } from 'react'
import noop from 'lodash/noop'
import generateDefaultTableActions from '@/helpers/table/generateDefaultTableActions'

/**
 * React hook for generate table props
 * @param {Object} actions - object of actions names
 * @param {function} tableParametersSelector - selector for get parameters from store
 * @returns {Object} - actions and values for use in table
 */
const useReduxTable = (
  actions = {},
  tableParametersSelector = noop,
) => {
  const tableValues = useSelector(tableParametersSelector, shallowEqual)
  const dispatch = useDispatch()

  const tableActions = useMemo(() => generateDefaultTableActions(actions), [actions])

  const tableActionsWithDispatch = useMemo(() => Object
    .keys(tableActions)
    .reduce((accumulator, key) => ({
      ...accumulator,
      [key]: (...payload) => dispatch(tableActions[key](...payload)),
    }), {}), [dispatch, tableActions])

  return {
    actions: tableActionsWithDispatch,
    values: tableValues,
  }
}

export default useReduxTable
