import React from 'react'
import pt from 'prop-types'
import {
  OVERDUE_ON_EXECUTION,
  AVERAGE_LEAD_TIME,
} from '@/constants/serviceDeskMyCity'
import getDeclension from '@/helpers/getDeclension'
import {
  Container,
  Count,
  Text,
  IconContainer,
} from './styles'

const Widget = ({
  count,
  text,
  textByCount,
  icon: Icon,
  onClick,
  id,
}) => {
  return (
    <Container onClick={onClick}>
      <Count customColor={id === OVERDUE_ON_EXECUTION}>
        {id === AVERAGE_LEAD_TIME ? `${count.days} д. ${count.hours} ч.` : count}
      </Count>
      <Text>{text || getDeclension(count, textByCount)}</Text>
      <IconContainer>
        <Icon />
      </IconContainer>
    </Container>
  )
}

Widget.propTypes = {
  count: pt.number.isRequired,
  id: pt.string.isRequired,
  text: pt.string,
  textByCount: pt.arrayOf(pt.string),
  icon: pt.string.isRequired,
  onClick: pt.func.isRequired,
}
Widget.defaultProps = {
  text: '',
  textByCount: [],
}

export default Widget
