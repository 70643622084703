import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_PASSPORT_AND_CARD_DATA = createAction('@/OBJECT_CONTROLL_STATISTIC_SOME_ECTION')
export const GET_TABLE = createAction('@/OBJECT_CONTROLL_STATISTIC_GET_TABLE')
export const GENERATE_TABLE = createTableActions('@/OBJECT_CONTROLL_STATISTIC_GENERATE_TABLE')

export const setOperatedElement = (payload) => ({
  type: GET_PASSPORT_AND_CARD_DATA,
  payload,
})
