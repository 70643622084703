import generateWidget from './generateWidget'
import { UnitvolumetricFlow, UnitVolume } from './unitElements'

export const WATER_TEMPERATURE = generateWidget({
  config: {
    id: 'WATER_TEMPERATURE',
    title: 'Температура',
    minValue: 4,
    maxValue: 20,
    units: '°C',
  },
  selectorTemplate: 'temperature',
})

export const WATER_MASS_FLOW = generateWidget({
  config: {
    id: 'WATER_MASS_FLOW',
    title: 'Массовый расход',
    units: 'т/ч',
  },
  selectorTemplate: 'massFlow',
})

export const WATER_VOLUMETRIC_FLOW = generateWidget({
  config: {
    id: 'WATER_VOLUMETRIC_FLOW',
    title: 'Объемный расход',
    units: UnitvolumetricFlow,
  },
  selectorTemplate: 'volumetricFlow',
})

export const WATER_VOLUMETRIC_CONSUMED_FLOW = generateWidget({
  config: {
    id: 'WATER_VOLUMETRIC_CONSUMED_FLOW',
    title: 'Объем потребленного ресурса',
    units: UnitVolume,
  },
  selectorTemplate: 'consumedVolume',
})
