import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const WidgetsDashboard = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ic.min/dashboard-app">
        <path id="ic-object" d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 5.25C15.735 5.25 18.75 8.265 18.75 12C18.75 15.735 15.735 18.75 12 18.75C8.265 18.75 5.25 15.735 5.25 12C5.25 8.265 8.265 5.25 12 5.25ZM12 7.5C11.37 7.5 10.875 7.995 10.875 8.625C10.875 9.255 11.37 9.75 12 9.75C12.63 9.75 13.125 9.255 13.125 8.625C13.125 7.995 12.63 7.5 12 7.5ZM8.265 9.75C8.07871 9.80982 7.91123 9.91718 7.77912 10.0615C7.647 10.2058 7.55481 10.3821 7.51164 10.5729C7.46848 10.7638 7.47583 10.9626 7.53298 11.1497C7.59012 11.3368 7.69508 11.5058 7.8375 11.64L9.885 13.6875C9.84 13.8675 9.75 14.0475 9.75 14.25C9.75 15.4875 10.7625 16.5 12 16.5C13.2375 16.5 14.25 15.4875 14.25 14.25C14.25 13.0125 13.2375 12 12 12C11.7975 12 11.6175 12.09 11.4375 12.135L9.39 10.0875C9.26689 9.95126 9.11231 9.84722 8.93974 9.78446C8.76717 9.72171 8.58187 9.70216 8.4 9.7275C8.35504 9.7248 8.30996 9.7248 8.265 9.7275V9.75ZM15.375 9.75C14.745 9.75 14.25 10.245 14.25 10.875C14.25 11.505 14.745 12 15.375 12C16.005 12 16.5 11.505 16.5 10.875C16.5 10.245 16.005 9.75 15.375 9.75Z" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

WidgetsDashboard.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

WidgetsDashboard.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(WidgetsDashboard)
