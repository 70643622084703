import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_CONSUMPTION_ANALYSIS_SAVING,
} from '@/store/actions/consumptionAnalysis'
import {
  successConsumptionAnalysisSavings,
  errorConsumptionAnalysisSavings,
} from '@/store/actions/consumptionAnalysis/getAvarage'

import {
  RESOURCE_ANALYTICS_AVERAGE_ENDPOINT,
} from '@/constants/apiRoutes'

function* getAverageDataSaga({ payload }) {
  try {
    const response = yield request({
      url: RESOURCE_ANALYTICS_AVERAGE_ENDPOINT,
      method: 'post',
      body: payload,
    })
    if (response) {
      const { error, data } = response

      if (error) {
        yield put(errorConsumptionAnalysisSavings())
      } else {
        yield put(successConsumptionAnalysisSavings({
          min: data.min.toFixed(2).toLocaleString(),
          max: data.max.toFixed(2).toLocaleString(),
          average: data.average.toFixed(2).toLocaleString(),
        }))
      }
    } else {
      yield put(errorConsumptionAnalysisSavings())
    }
  } catch (error) {
    yield put(errorConsumptionAnalysisSavings(error))
  }
}

export default function* root() {
  yield takeLatest(GET_CONSUMPTION_ANALYSIS_SAVING.REQUEST, getAverageDataSaga)
}
