import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_REPORT_MANAGER_OBJECT_TYPES,
} from '@/store/actions/reportManager'
import { REPORT_MANAGER_OBJECT_TYPES_URL } from '@/constants/apiRoutes'
import {
  successReportManagerObjectTypes,
  errorReportManagerObjectTypes,
} from '@/store/actions/reportManager/getObjectTypes'

function* getReportManagerObjectTypesSaga({ payload }) {
  const { type, geoZoneId } = payload
  try {
    const response = yield request({
      url: REPORT_MANAGER_OBJECT_TYPES_URL({
        type,
        params: {
          geoZoneId,
        },
      }),
      method: 'get',
    })
    const sortedResponse = response.data.items.map((element) => ({
      value: element.code,
      ...element,
    }))
    yield put(successReportManagerObjectTypes(sortedResponse))
  } catch (error) {
    yield put(errorReportManagerObjectTypes(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_REPORT_MANAGER_OBJECT_TYPES.REQUEST, getReportManagerObjectTypesSaga)
}
