import { CREATE_USER } from '../index'

export const beginCreateUser = () => ({
  type: CREATE_USER.BEGIN,
})

export const endCreateUser = () => ({
  type: CREATE_USER.END,
})

export const requestCreateUser = (forms) => ({
  type: CREATE_USER.REQUEST,
  payload: forms,
})

export const responseCreateUser = (user) => ({
  type: CREATE_USER.SUCCESS,
  payload: user,
})

export const errorCreateUser = (error) => ({
  type: CREATE_USER.ERROR,
  payload: error,
})
