import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getRouter = createSelector(
  getState,
  (state) => get(state, 'router', {}),
)

export const getRouterLocation = createSelector(
  getRouter,
  (router) => get(router, 'location', {}),
)

export const getRouterUrl = createSelector(
  getRouterLocation,
  (location) => {
    const { pathname, search, hash } = location

    return `${pathname}${search}${hash}`
  },
)
