export const BRIZ = 'BRIZ'
export const ATM = 'ATM'
export const KULON = 'KULON'
export const MESH = 'MESH'

export const SYSTEM_INDEX_BY_TYPE = {
  [KULON]: 0,
  [BRIZ]: 1,
  [ATM]: 2,
  [MESH]: 3,
}

export const ERROR_CATALOG_DELETE_INTEGRATION = 'error.catalog.delete.integration'
export const ERROR_CATALOG_PUT_INTEGRATION = 'error.catalog.put.integration'

export const ALIAS_IN_PROGRESS = 'error.alias.in.progress.yet'
export const ERROR_FIELD_IS_EMPTY = 'error.field.is.empty'
export const ERROR_NOT_VALID = 'error.not.valid'
export const ALIAS_ID_IN_PROGRESS = 'alias.id.in.progress.yet'
export const ERROR_INTEGRATION_REFRESH = 'integration.refresh.error'
export const INTEGRATION_REFRESH_IN_PROGRESS = 'integration.refresh.in.progress'

export const ERROR_INTEGRATION_TYPE_IS_EMPTY = 'integration.type.is.empty'
export const ERROR_ALIAS_ID_IS_EMPTY = 'alias.id.is.empty'
export const ERROR_DISPATCHER_INTEGRATION_NOT_FOUND = 'dispatcher.integration.not.found'
export const ERROR_PAYLOAD_IS_EMPTY = 'payload.is.empty'
export const ERROR_INTEGRATION_HAS_INACTIVE = 'integration.has.inactive'
export const ERROR_INTEGRATION_NOT_FOUND = 'integration.not.found'
export const ERROR_LOGIN_OR_PASSWORD_FAIL = 'incorrect.login.or.password'
export const SEND_TO_PARSING_EQUIPMENT = 'integration.send.to.parsing.equipment'
export const END_PARSING_EQUIPMENT = 'end.parsing.equipment'
export const ERROR_EXECUTE_PARSER = 'error.execute.parser'
export const ERROR_ALIAS_IS_ALREADY_EXIST = 'error.alias.is.already.exist'
export const ERROR_NO_DATA_TO_UPDATE = 'error.no.data.to.update'

export const OPPERATION_SUCCESS = 'УСПЕШНАЯ ОПЕРАЦИЯ!'
export const OPPERATION_ERROR = 'ОШИБКА ОПЕРАЦИИ!'

export const SYNCRONIZATION_SUCCESS = 'СИНХРОНИЗАЦИЯ ЗАВЕРШЕНА!'
export const SYNCRONIZATION_ERROR = 'ОШИБКА СИНХРОНИЗАЦИИ!'

export const CONNECTION_SUCCESS = 'ИНТЕГРАЦИЯ ПОДКЛЮЧЕНА!'
export const CONNECTION_ERROR = 'ОШИБКА ПОДКЛЮЧЕНИЯ!'

export const CREATE_INTEGRATION_ERROR = 'CREATE_INTEGRATION_ERROR'
export const SYNCRONIZATION_INTEGRATION_ERROR = 'SYNCRONIZATION_INTEGRATION_ERROR'
export const OPERATION_INTEGRATION_ERROR = 'OPERATION_INTEGRATION_ERROR'
export const DELETE_INTEGRATION_ERROR = 'DELETE_INTEGRATION_ERROR'

export const REPETITION_ISYNCRONIZATION_FAIL = 'REPETITION_ISYNCRONIZATION_FAIL'

export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS'
export const SYNCRONIZATION_INTEGRATION_SUCCESS = 'SYNCRONIZATION_INTEGRATION_SUCCESS'
export const OPERATION_INTEGRATION_SUCCESS = 'OPERATION_INTEGRATION_SUCCESS'
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS'

// descriptions
export const REQUEST_ATM_ERROR_URL = 'request.to.atm.api.failed.incorrect.url'
export const REQUEST_ATM_ERROR_LOGIN_OR_PASSWORD = 'request.to.atm.api.failed.incorrect.login.or.password'
export const REQUEST_ATM_ERROR_UNKNOWN = 'request.to.atm.api.failed.unknown.error.statusCode'
export const REQUEST_KULON_ERROR_URL = 'request.to.kulon.api.failed.incorrect.url'
export const REQUEST_KULON_ERROR_LOGIN_OR_PASSWORD = 'request.to.kulon.api.failed.incorrect.login.or.password'
export const REQUEST_KULON_ERROR_UNKNOWN = 'request.to.kulon.api.failed.unknown.error.statusCode'

export const INTEGRATION_CREATED = 'integration.send.to.parsing.equipment'
export const INTEGRATION_SYNCHONIZE = 'end.parsing.equipment'

export const INTEGRATION_UPDATE_STARTED = 'integration.updating.started'
export const INTEGRATION_UPDATED = 'integration.updated'
export const INTEGRATION_DELETED = 'integration.mark.as.deleted'

export const SYSTEM_ERROR_MESSAGE = 'Ошибка системы.'
export const UNCORECT_DATA_MESSAGE = 'Неверно указаны учетные данные.'
export const INNER_ERROR_MESSAGE = 'Внутренняя ошибка.'
export const CONNECTION_ERROR_MESSAGE = 'Не удалось установить соединение с сервисом.'

export const ERRORS_TO_ERROR_MESSAGES = {
  alias: {
    [ERROR_ALIAS_IS_ALREADY_EXIST]: 'Название уже существует',
    [ERROR_FIELD_IS_EMPTY]: 'Заполните поля аляса',
    'Not found equipment with this alias': 'Not found equipment with this alias',
    'error.alias.in.progress.yet': 'Название уже существует',
  },
  'validation.error.credential': {
    [ERROR_LOGIN_OR_PASSWORD_FAIL]: 'Неверные логин или пароль',
    'error.incorrect.login.or.password': 'Неверно указаны учетные данные',
    'Host error': 'Ошибка сервера',
  },
  login: {
    [ERROR_FIELD_IS_EMPTY]: 'Пустой логин',
  },
  error: {
    [ERROR_EXECUTE_PARSER]: 'Ошибка парсера',
    [REQUEST_ATM_ERROR_URL]: 'Неверно указан сетевой адрес',
    [REQUEST_ATM_ERROR_LOGIN_OR_PASSWORD]: 'Неверный логин или пароль',
    [REQUEST_KULON_ERROR_URL]: 'Неверно указан сетевой адрес',
    [REQUEST_KULON_ERROR_LOGIN_OR_PASSWORD]: 'Неверный логин или пароль',
    [INTEGRATION_SYNCHONIZE]: 'Ошибка сетевого доступа',
    'Host error': 'Ошибка сервера',
  },
  url: {
    [ERROR_FIELD_IS_EMPTY]: 'Пустой url',
    [ERROR_NOT_VALID]: 'Не валидный url',
  },
  password: {
    [ERROR_FIELD_IS_EMPTY]: 'Пустой пароль',
  },
  integrationName: {
    [ERROR_NOT_VALID]: 'Не валидное имя',
  },
  equipmentRefreshDelay: {
    [ERROR_NOT_VALID]: 'Не валидное значение обновления',
  },
  port: {
    [ERROR_NOT_VALID]: 'Не валидное значение порта',
  },
  GetAllIntegration: {
    [ERROR_DISPATCHER_INTEGRATION_NOT_FOUND]: 'Отсутствует тип интеграции',
    [ERROR_INTEGRATION_NOT_FOUND]: 'Отсутствует интеграция',
  },
  aliasId: {
    [ERROR_INTEGRATION_NOT_FOUND]: 'Интеграция не найдена',
    [ERROR_CATALOG_DELETE_INTEGRATION]: 'Ошибка удаления интеграции',
  },
  AliasId: {
    [ERROR_NOT_VALID]: 'Такой интеграции не существует',
  },
  'catalog.delete.integration.error': {
    [ERROR_INTEGRATION_NOT_FOUND]: 'Интеграция не найдена',
  },
  integration: {
    'error.incorrect.login.or.password': 'Неверно указаны учетные данные',
    [ERROR_INTEGRATION_NOT_FOUND]: 'Интеграция не найдена',
  },
  'integration.put.error': {
    'Integration this alias is not present in DB': 'Аляс интеграции отсутствует',
  },
  'integration.not.found': {
    null: 'Не удалось удалить интеграцию. Попробуйте позже',
  },
}
