import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getMapsState } from '@/store/selectors/maps/socketEvents'

export const getImage = createSelector(
  getMapsState,
  (maps) => get(maps, 'image', {}),
)

export default getImage
