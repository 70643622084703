import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_OBJECT_EQUIPMENT,
  successGetObjectEquipment,
  errorGetObjectEquipment,
} from '@/store/actions/passportization'

import {
  generateGetElementEquipment,
} from '@/constants/apiRoutes'

import {
  KEY_TO_NAME,
  SERVER_TO_LOCAL,
} from '@/constants/passportization/types'

function* getOperatedElementSaga({payload: { name, type } }) {
  try {
    const response = yield request({
      url: generateGetElementEquipment(name, type),
      method: 'get',
    })
    if (response && response.data) {
      const childElements = response.data.map((type) => ({
        id: SERVER_TO_LOCAL[type] || type,
        value: SERVER_TO_LOCAL[type] || type,
        title: KEY_TO_NAME[type] || type,
        parentId: name,
        name,
        type: SERVER_TO_LOCAL[type] || type,
      }))
      yield put(successGetObjectEquipment(childElements))
    }
  } catch (error) {
    yield put(errorGetObjectEquipment(error))
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_EQUIPMENT.REQUEST, getOperatedElementSaga)
}
