import { CREATE_REPORT } from './index'

export const requestCreateReport = (payload) => ({
  type: CREATE_REPORT.REQUEST,
  payload,
})

export const successCreateReport = (payload) => ({
  type: CREATE_REPORT.SUCCESS,
  payload,
})

export const errorCreateReport = (payload) => ({
  type: CREATE_REPORT.ERROR,
  payload,
})
