import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  BASE_URL,
  SIGN_IN_URL,
  SIGN_UP_URL,
  DASHBOARD_URL,
  USER_AGREEMENT,
  FORGOT_PASSWORD_URL,
} from '@/constants/routes'
import SignInPage from '@/containers/pages/SignIn'
import SignUpPage from '@/containers/pages/SignUp'
import ForgotPasswordPage from '@/containers/pages/ForgotPassword'

import PrivateRoutes from '../PrivateRoutes'

const PublicRoutes = () => (
  <>
    <Route
      exact
      path={BASE_URL}
      component={SignInPage}
    />
    <Route
      exact
      path={SIGN_IN_URL}
      component={SignInPage}
    />
    <Route
      exact
      path={SIGN_UP_URL}
      component={SignUpPage}
    />
    <Route
      exact
      path={FORGOT_PASSWORD_URL}
      component={ForgotPasswordPage}
    />
    <Route
      exact
      path={USER_AGREEMENT}
      // component={SignUpPage}
    />
    <Route
      path={DASHBOARD_URL}
      component={PrivateRoutes}
    />
  </>
)

export default connect(() => ({
  isLoggedIn: true,
}))(PublicRoutes)
