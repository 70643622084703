import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import {
  GET_ALARM_MANAGER_TABLE,
  GET_ALARM_MANAGER_FILE,
  GET_ALARMS_COUNT,
  GET_TABLE_STATISTICS,
  CHECK_ALL_ALARMS,

  ALARM_MANAGER_TABLE,
  SET_EVENTS_TYPES,
  SET_STATUS_TYPES,
  SET_SELECTED_ELEMENT,
  SET_EVETS_DATA,
  SET_DATES_RANGE,
} from '@/store/actions/alarmManager'
import { fields } from '@/constants/tablesConfig/alarmManager'

const initialState = {
  status: REQUEST_STATUSES.NOT_REQUESTED,
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  dates: {
    start: null,
    end: null,
  },
  table: generateDefaultTableState(
    fields,
    { field: 'occurrenceDate', direction: 'DESC' },
  ),
  selector: 'all',
  eventTypes: [OK, WARNING, ERROR, UNKNOWN],
  eventStatus: ['all', 'new', 'old'],
  aliasId: null,
  selectedElement: null,
  eventsCount: 0,
  eventsNewCount: 0,
  eventData: {
    [ERROR]: 0,
    [WARNING]: 0,
    [OK]: 0,
    [UNKNOWN]: 0,
  },
  loadingStatistic: false,
  loadingTable: false,
  eventsCountNotification: false,
  isAlarms: false,
}

export const reducer = handleActions(
  {
    [SET_EVETS_DATA]: (state, { payload: { eventsCount, eventData } }) => ({
      ...state,
      eventsCount,
      eventData,
    }),
    [CHECK_ALL_ALARMS.SUCCESS]: (state, { payload }) => ({
      ...state,
      isAlarms: payload,
    }),
    [SET_DATES_RANGE]: (state, { payload }) => ({
      ...state,
      dates: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [GET_ALARMS_COUNT.SUCCESS]: (state, { payload: { count, unreadCount } }) => ({
      ...state,
      eventsCount: count,
      eventsNewCount: unreadCount,
    }),
    [GET_ALARMS_COUNT.BEGIN]: (state) => ({
      ...state,
      eventsCountNotification: true,
    }),
    [GET_ALARMS_COUNT.END]: (state) => ({
      ...state,
      eventsCountNotification: false,
    }),
    [GET_TABLE_STATISTICS.SUCCESS]: (state, { payload }) => ({
      ...state,
      eventData: payload,
      loadingStatistic: false,
    }),
    [GET_TABLE_STATISTICS.REQUEST]: (state) => ({
      ...state,
      loadingStatistic: true,
    }),
    [GET_TABLE_STATISTICS.ERROR]: (state) => ({
      ...state,
      loadingStatistic: false,
    }),
    [SET_SELECTED_ELEMENT]: (state, { payload }) => ({
      ...state,
      selectedElement: payload,
    }),
    [GET_ALARM_MANAGER_TABLE.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
      loadingTable: true,
    }),
    [GET_ALARM_MANAGER_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      status: REQUEST_STATUSES.IDLE,
      loadingTable: false,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_ALARM_MANAGER_TABLE.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.ERROR,
      loadingTable: false,
    }),
    [SET_STATUS_TYPES]: (state, { payload }) => ({
      ...state,
      eventStatus: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_EVENTS_TYPES]: (state, { payload }) => ({
      ...state,
      eventTypes: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [GET_ALARM_MANAGER_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_ALARM_MANAGER_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_ALARM_MANAGER_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
    ...generateDefaultTableReducer(ALARM_MANAGER_TABLE),
  },
  initialState,
)

export default reducer
