import BulletListIcon from './BulletListIcon'
import ExportIcon from './ExportIcon'
import LastIcon from './LastIcon'
import FirstIcon from './FirstIcon'
import PrevIcon from './PrevIcon'
import NextIcon from './NextIcon'
import SortIcon from './SortIcon'
import EXELIcon from './EXELIcon'
import PDFIcon from './PDFIcon'
import AlarmIcon from './AlarmIcon'


export default {
  BulletListIcon,
  ExportIcon,
  LastIcon,
  FirstIcon,
  PrevIcon,
  NextIcon,
  SortIcon,
  EXELIcon,
  PDFIcon,
  AlarmIcon,
}
