import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const AriaIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11.628 6.02216V9.69413H15.3489V15.8141H4.1861V7.24615H7.90703V6.02216L9.76749 4.18617L11.628 6.02216ZM5.42641 14.5901H6.66672V13.3661H5.42641V14.5901ZM5.42641 12.1421H6.66672V10.9181H5.42641V12.1421ZM5.42641 9.69413H6.66672V8.47014H5.42641V9.69413ZM9.14734 13.3661V14.5901H10.3876V13.3661H9.14734ZM9.14734 10.9181V12.1421H10.3876V10.9181H9.14734ZM9.14734 8.47014V9.69413H10.3876V8.47014H9.14734ZM9.14734 6.02216V7.24615H10.3876V6.02216H9.14734ZM12.8683 14.5901H14.1086V13.3661H12.8683V14.5901ZM12.8683 12.1421H14.1086V10.9181H12.8683V12.1421Z" fill={fill} className="svgFill" />
    </svg>
  )
}

AriaIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AriaIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AriaIcon)
