import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserProfileIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0497 14.5379C7.68005 14.7062 5 17.4825 5 20.8824C5 22.0524 5.95136 23 7.125 23H19.875C21.0486 23 22 22.0524 22 20.8824C22 17.4825 19.32 14.7062 15.9503 14.5379C15.8426 14.5326 15.7341 14.5294 15.625 14.5294H11.375C11.2659 14.5294 11.1574 14.5326 11.0497 14.5379ZM10.6888 12.4118C11.438 13.0704 12.4222 13.4706 13.5 13.4706C14.5778 13.4706 15.562 13.0704 16.3112 12.4118C17.1935 11.6356 17.75 10.5006 17.75 9.23529C17.75 6.89635 15.8472 5 13.5 5C11.1528 5 9.25 6.89635 9.25 9.23529C9.25 10.5006 9.80654 11.6356 10.6888 12.4118ZM19.3438 20.8824C19.6371 20.8824 19.875 20.6452 19.875 20.3529V19.8235C19.875 17.4846 17.9722 16.6471 15.625 16.6471H11.375C9.02783 16.6471 7.125 17.4846 7.125 19.8235V20.3529C7.125 20.6452 7.36289 20.8824 7.65625 20.8824H19.3438ZM13.5 11.3529C14.6736 11.3529 15.625 10.4053 15.625 9.23529C15.625 8.06529 14.6736 7.11765 13.5 7.11765C12.3264 7.11765 11.375 8.06529 11.375 9.23529C11.375 10.4053 12.3264 11.3529 13.5 11.3529Z" fill={fill} className="svgFill" />
    </svg>
  )
}

UserProfileIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserProfileIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserProfileIcon)
