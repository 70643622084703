import {
  put, takeLatest, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import updateNodeCount from '@/helpers/updateNodeCount'
import request from '@/helpers/axios'
import {
  successCreateProject,
} from '@/store/actions/installation/createProject'
import {
  PROJECT,
} from '@/constants/objectTypes'
import { CREATE_PROJECT } from '@/store/actions/installation'
import { INSTALLATIONS_PROJECT_ENDPOINT } from '@/constants/apiRoutes'

function* createProjectSaga({ payload }) {
  const { formValues, values, setUrlFormValues } = payload
  const {
    customFields = [], mark, name, parentId,
  } = values
  try {
    const filterFields = (customFields || []).filter((element) => !!element.remove === false)
    const formattedCustomFields = filterFields.map((element) => ({
      nameField: element.nameField,
      value: element.value,
    }))
    const body = {
      type: PROJECT,
      name,
      note: mark,
      parentTreeElementId: parentId,
      customFields: formattedCustomFields,
    }
    const { data } = yield request({
      url: INSTALLATIONS_PROJECT_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      id, rootId, path, FILTER,
    } = formValues
    const newPath = path.replace(id, data.parentTreeElementId)

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentTreeElementId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.treeElementId)
    tree.handlers.setSelected(node, true)
    updateNodeCount(tree, path, 'add')
    tree.handlers.rerender()

    
    setUrlFormValues({
      id: data.treeElementId,
      type: data.type,
      parentGeoZoneId: data.parentTreeElementId,
      rootId,
      path: `${newPath}/${data.treeElementId}`,
      parentTreeId: data.parentTreeElementId,
      FILTER,
    })
    yield put(successCreateProject())
  } catch (error) {
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*createProjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(CREATE_PROJECT.REQUEST, createProjectSaga)
}
