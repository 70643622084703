import styled from 'styled-components'

export const ButtonStyled = styled.button`
  cursor: pointer;
  text-decoration: none;
  outline: none;
  display: inline-block;
  background: ${({ theme }) => theme.colors.scheme.backgrounds.default};
  color: ${({ theme }) => theme.colors.colors.light};
  border-radius: 4px;
  border: none;
  padding: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.colors.light};
    background: ${({ theme }) => theme.colors.scheme.backgrounds.hover};
    border-color: transparent;
  }

  ${({ styleType, theme }) => styleType === 'colorScheme' && `
    background: none;
    border: 1px solid ${theme.colors.scheme.backgrounds.default};
    color: ${theme.colors.scheme.backgrounds.default};
  `}
  ${({ styleType, theme }) => styleType === 'flat' && `
    background: none;
    border: 1px solid ${theme.colors.borders.disabled};
    color: ${theme.colors.colors.default};
  `}
  ${({ styleType, theme }) => styleType === 'danger' && `
    background: ${theme.colors.messages.error};
    border: 1px solid ${theme.colors.messages.error};
    color: ${theme.colors.colors.default};
  `}
  ${({ styleType, theme }) => styleType === 'success' && `
    background: ${theme.colors.backgrounds.notification.success};
    border: 1px solid ${theme.colors.backgrounds.notification.success};
    color: ${theme.colors.colors.default};
  `}
  ${({ styleType, theme }) => styleType === 'warning' && `
    background: ${theme.colors.backgrounds.notification.warning};
    border: 1px solid ${theme.colors.backgrounds.notification.warning};
    color: ${theme.colors.colors.default};
  `}
  ${({ styleType, theme }) => styleType === 'error' && `
    background: ${theme.colors.backgrounds.notification.error};
    border: 1px solid ${theme.colors.backgrounds.notification.error};
    color: ${theme.colors.colors.default};
  `}
  ${({ styleType, theme }) => styleType === 'critical' && `
    background: ${theme.colors.messages.critical};
    border: 1px solid ${theme.colors.messages.critical};
    color: ${theme.colors.white};
  `}
  ${({ styleType, theme }) => styleType === 'info' && `
    background: ${theme.colors.backgrounds.notification.info};
    border: 1px solid ${theme.colors.backgrounds.notification.info};
    color: ${theme.colors.colors.default};
  `}
  ${({ styleType, theme }) => styleType === 'submitting' && `
    background: ${theme.colors.backgrounds.disabled};
    border: 1px solid ${theme.colors.backgrounds.disabled};
    color: ${theme.colors.backgrounds.textField};

    &:hover {
      cursor: not-allowed;
      background: ${theme.colors.backgrounds.disabled};
      color: ${theme.colors.backgrounds.textField};
      border-color: transparent;
    }
  `}
  ${({ styleType, theme }) => styleType === 'ghost' && `
    background: ${theme.colors.shadows.widgetSecondary};
    border: none;
    color: ${theme.colors.colors.redisign.default};
    box-shadow: ${theme.colors.shadows.field}
  `}
  &:disabled {
    color: ${({ theme }) => theme.colors.colors.default};
    background: ${({ theme }) => theme.colors.borders.default};
  }
`

export const TextButton = styled.button`
  cursor: pointer;
  outline: none;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.disabled};
  background: none;
  border: none;

  &:hover {
    color: ${({ theme }) => theme.colors.colors.default};
    transition: color 100ms ease-in-out;
  }
`
