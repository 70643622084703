import React, { useEffect } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import UsersIcons from '@/components/icons/users'
import CoreIcons from '@/components/icons/core'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import {
  HeaderContainer,
  IconContainer,
  Title,
  Container,
  TitleAndIconContainer,
} from './styles'

const PassportHeader = ({
  name,
  isSettingsOpen,
  isParentPassportOpen,
  selectedNode,
  toggleSettings,
  setOperatedElement,
  dirty,
  handleResetForm,
  requestAddPassportFile,
  noOptions,
  setEditForm,
  submitForm,
}) => {
  useEffect(() => {
    if (noOptions && isParentPassportOpen) {
      setOperatedElement(selectedNode)
    }
  }, [selectedNode, setOperatedElement, noOptions, isParentPassportOpen])

  const toggleSettingsHandler = () => {
    toggleSettings(!isSettingsOpen)
  }

  const onSubmitHandler = () => {
    submitForm()
    requestAddPassportFile()
  }

  const cancelEditHandler = () => {
    handleResetForm()
  }

  const startEditHandler = () => {
    setEditForm(true)
  }

  if (dirty) {
    return (
      <HeaderContainer>
        <Title>
          <Lang id="passportization.changeTitle" />
        </Title>
        <Container>
          <IconContainer
            type="green"
            onClick={onSubmitHandler}
            border="right"
          >
            <Lang id="passportization.passportSidebar.yes" />
          </IconContainer>
          <IconContainer
            type="red"
            onClick={cancelEditHandler}
            border="left"
          >
            <Lang id="passportization.passportSidebar.no" />
          </IconContainer>
        </Container>
      </HeaderContainer>
    )
  }
  return (
    <HeaderContainer>
      {!isSettingsOpen
        ? (
          <>
            <TitleAndIconContainer>
              <PortalTooltip
                title={(<Lang id="tooltip.fieldSettings" />)}
                renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                  <IconContainer
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                    onClick={toggleSettingsHandler}
                  >
                    <CoreIcons.GearIcon />
                  </IconContainer>
                )}
              />
              <Title>
                {name}
              </Title>
            </TitleAndIconContainer>
            <PortalTooltip
              title={(<Lang id="tooltip.edit" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={startEditHandler}
                >
                  <CoreIcons.EditIcon />
                </IconContainer>
              )}
            />
          </>
        )
        : (
          <>
            <Title>
              {name}
            </Title>
            <PortalTooltip
              title={(<Lang id="tooltip.close" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={toggleSettingsHandler}
                >
                  <UsersIcons.CrossIcon />
                </IconContainer>
              )}
            />
          </>
        )}
    </HeaderContainer>
  )
}


PassportHeader.propTypes = {
  name: pt.string,
  openedPage: pt.string,
  isSettingsOpen: pt.bool,
  isParentPassportOpen: pt.bool,
  noOptions: pt.bool,
  toggleSettings: pt.func,
  setOperatedElement: pt.func,
  setOpenedPage: pt.func,
  setEditForm: pt.func,
  submitForm: pt.func,
  setIsParentPassportOpen: pt.func,
  selectedNode: pt.objectOf(pt.object),
}


PassportHeader.defaultProps = {
  name: '',
  isSettingsOpen: false,
  isParentPassportOpen: false,
  noOptions: false,
  toggleSettings: noop,
  setOperatedElement: noop,
  setOpenedPage: noop,
  setEditForm: noop,
  setIsParentPassportOpen: noop,
  submitForm: noop,
  selectedNode: {},
  openedPage: '',
}

export default PassportHeader
