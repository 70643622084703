import { GET_GROUP_USERS } from '../index'

export const requestGetAllUsers = (payload) => ({
  type: GET_GROUP_USERS.REQUEST,
  payload,
})

export const responseGetAllUsers = (payload) => ({
  type: GET_GROUP_USERS.SUCCESS,
  payload,
})

export const errorGetAllUsers = (payload) => ({
  type: GET_GROUP_USERS.ERROR,
  payload,

})
