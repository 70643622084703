import styled from 'styled-components'
import { Form } from 'formik'
import CheckBoxField from '@/components/fields/CheckBoxField'
import { LIGHT_THEME_NAME } from '@/constants/names'
import DatePickerField from '@/components/fields/DatePickerField'
import { ControlsContainer } from '@/components/controls/FileControll/styles'

export const CustomDatePicker = styled(DatePickerField)`
  height: 28px;
`
export const TemplateLink = styled.a``

export const HiddenInput = styled.div``

export const FormContainer = styled(Form)`
  padding-top: 1px;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
          background: ${theme.colors.backgrounds.redisign.default};
        `
    }
  }}
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  flex-wrap: wrap;
  && > div {
    padding: 6px;
  }
`

export const CustomCheckBoxField = styled(CheckBoxField)`
  margin-right: 10px;
`

export const HiddenInputContainer = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`

export const ContentContainer = styled.div`
  margin: 3px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  ${({ interval }) => interval && `
    margin-left: 5px;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 0;
    width: 135px;
    .react-datepicker-popper {
      width: 300px;
      z-index: 4;
      top: -30px !important;
    }
  `};
  ${({ file }) => file && `
    input {
      padding-right: 35px;
      width: 320px;
    }
  `}
  ${ControlsContainer} {
    margin-top: 4px;
  }
`
export const FileControllContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ onClick }) => onClick && `
      cursor: pointer;
  `}
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: -4px;
  right: 0;
  ${({ type }) => type && `
    svg, .svgFill {
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }
  `}
`

export const FilterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 4.5px;
  && > div {
    padding: 5px 5px;
  }
`
