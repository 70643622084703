import React, { useCallback, useEffect } from 'react'
import pt from 'prop-types'
import qs from 'query-string'
import noop from 'lodash/noop'
import { withRouter } from 'react-router-dom'
import {
  routerHistoryType,
  routerHistoryDefault,
} from '@/propTypes/router'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcons from '@/components/icons/menu'
import HeaderIcons from '@/components/icons/header'
import WidgetsIcons from '@/components/icons/widgets'
import WidgetSettingsPlaceholder from '@/components/widgets/WidgetSettingsPlaceholder'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { TYUMEN_OUR_HOME_URL } from '@/constants/routes'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
  STATUS_COLORS,
} from '@/constants/alarms'
import {
  TooltipWrapper,
  WidgetContainer,
  WidgetHeader,
  WidgetTitleWrapper,
  WidgetHeaderIcon,
  WidgetTitle,
  Container,
  DescriptionsRow,
  DescriptionsRowCount,
  DescriptionsRowTitle,
  DescriptionIcon,
  DescriptionContent,
  LineContainer,
  LinePart,
  TextPart,
} from './styles'
import { calculateAverageDays } from '@/helpers/serviceDeskHelpers';

const TyumenOurHomeWidget = ({
  id,
  data,
  settings,
  isSettingSet,
  demo,
  onOpenSettings,
  onGetData,
  history,
  type,
  widgetType,
}) => {
  useEffect(() => {
    onGetData(id)
  }, [id, onGetData, settings.id])
  const handleOpenSettings = useCallback(() => {
    onOpenSettings(id, type, widgetType)
  }, [onOpenSettings, id, type, widgetType])

  const handleOpenTable = useCallback(() => {
    if (!demo) {
      history.push({
        pathname: TYUMEN_OUR_HOME_URL,
        search: qs.stringify({
          type: settings.id,
        }),
      })
    }
  }, [history, settings, demo])

  const averageCompletionTime = calculateAverageDays(data.averageCompletionTime)

  if (!isSettingSet && !demo) {
    return (
      <WidgetSettingsPlaceholder onOpenSettings={handleOpenSettings} />
    )
  }

  return (
    <WidgetContainer>
      <WidgetHeader>
        {!demo && (
            <WidgetHeaderIcon withMargin small>
              <MenuIcons.TyumenIcon />
            </WidgetHeaderIcon>
        )}
        <WidgetTitleWrapper>
          <WidgetTitle>Портал Тюмень - НАШ ДОМ</WidgetTitle>
        </WidgetTitleWrapper>
        {!demo && (
            <TooltipWrapper>
              <PortalTooltip
                  title={(<Lang id="tooltip.settings" />)}
                  renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <WidgetHeaderIcon
                          onClick={demo ? noop : handleOpenSettings}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                      >
                        <HeaderIcons.SettingsIcon />
                      </WidgetHeaderIcon>
                  )}
              />
            </TooltipWrapper>
        )}
      </WidgetHeader>
      <Container>
        <TextPart>
          <DescriptionsRow onClick={handleOpenTable}>
            <DescriptionIcon><WidgetsIcons.IndifferentIcon /></DescriptionIcon>
            <DescriptionContent>
              <DescriptionsRowCount color={STATUS_COLORS[WARNING]}>
                {data.inProcessAppealsAmount}
              </DescriptionsRowCount>
              <DescriptionsRowTitle>в процессе решения</DescriptionsRowTitle>
            </DescriptionContent>
          </DescriptionsRow>
          <DescriptionsRow onClick={handleOpenTable}>
            <DescriptionIcon><WidgetsIcons.BadIcon /></DescriptionIcon>
            <DescriptionContent>
              <DescriptionsRowCount color={STATUS_COLORS[ERROR]}>
                {data.refusalAppealsAmount}
              </DescriptionsRowCount>
              <DescriptionsRowTitle>отказ или проблем не обнаружено</DescriptionsRowTitle>
            </DescriptionContent>
          </DescriptionsRow>
          <DescriptionsRow onClick={handleOpenTable}>
            <DescriptionIcon><WidgetsIcons.HappyIcon /></DescriptionIcon>
            <DescriptionContent>
              <DescriptionsRowCount color={STATUS_COLORS[OK]}>
                {data.solvedAppealsAmount}
              </DescriptionsRowCount>
              <DescriptionsRowTitle>успешно решено</DescriptionsRowTitle>
            </DescriptionContent>
          </DescriptionsRow>
          <DescriptionsRow onClick={handleOpenTable}>
            <DescriptionIcon><WidgetsIcons.SandClockIcon /></DescriptionIcon>
            <DescriptionContent>
              <DescriptionsRowCount small>Решение принято за:</DescriptionsRowCount>
              <DescriptionsRowCount>
                {averageCompletionTime ? `${averageCompletionTime.days} д. ${averageCompletionTime.hours} ч.` : null}
              </DescriptionsRowCount>
            </DescriptionContent>
          </DescriptionsRow>
          <DescriptionsRow onClick={handleOpenTable}>
            <DescriptionIcon><WidgetsIcons.CityIcon /></DescriptionIcon>
            <DescriptionContent>
              <DescriptionsRowCount small>Городской процесс:</DescriptionsRowCount>
              <DescriptionsRowTitle>{settings.title}</DescriptionsRowTitle>
            </DescriptionContent>
          </DescriptionsRow>
        </TextPart>
        <LineContainer>
          {!!data.inProgressPercent && (
              <LinePart
                  color={STATUS_COLORS[WARNING]}
                  size={data.inProgressPercent}
                  first
              />
          )}
          <LinePart
              color={STATUS_COLORS[OK]}
              size={data.donePercent}
              last
              first={data.inProgressPercent === 0}
          />
        </LineContainer>
      </Container>
    </WidgetContainer>
  )
}

TyumenOurHomeWidget.propTypes = {
  id: pt.string,
  type: pt.string,
  widgetType: pt.string,
  history: routerHistoryType,
  settings: pt.shape({
    title: pt.string,
    id: pt.string,
  }),
  demo: pt.bool,
  isSettingSet: pt.bool,
  onOpenSettings: pt.func,
  onGetData: pt.func,
  data: pt.shape({
    [OK]: pt.number,
    [WARNING]: pt.number,
    [ERROR]: pt.number,
    [UNKNOWN]: pt.number,
  }),
}
TyumenOurHomeWidget.defaultProps = {
  id: null,
  type: null,
  widgetType: null,
  history: routerHistoryDefault,
  settings: {},
  demo: false,
  isSettingSet: false,
  onOpenSettings: noop,
  onGetData: noop,
  data: {
    [OK]: 25,
    [WARNING]: 25,
    [ERROR]: 25,
    [UNKNOWN]: 25,
  },
}

export default withRouter(TyumenOurHomeWidget)
