import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

// Light actions
export const GET_CONSUMPTION_ANALYSIS_GRAPH_DATA = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_GRAPH_DATA',
)
export const GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA',
)
export const GET_CONSUMPTION_ANALYSIS_TABLE_DATA = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_TABLE_DATA',
)
export const GET_CONSUMPTION_ANALYSIS_FILE = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_FILE',
)
export const GET_CONSUMPTION_ANALYSIS_SAVING = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_SAVING',
)
// Heat actions
export const GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA',
)
export const GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA',
)
export const GET_CONSUMPTION_ANALYSIS_HEAT_FILE = createAction(
  '@/GET_CONSUMPTION_ANALYSIS_HEAT_FILE',
)

export const CONSUMPTION_ANALYSIS_TABLE = createTableActions('@/CONSUMPTION_ANALYSIS_TABLE')

export const CLEAR_DATA = '@/CLEAR_DATA'
export const SET_TENANT_GRAPH_TYPE = '@/SET_TENANT_GRAPH_TYPE'
export const SET_RANGE = '@/CONSUPTION_SET_RANGE'
export const SET_TYPE = '@/CONSUPTION_SET_TYPE'
export const SET_SAVINGS = '@/CONSUPTION_SET_SAVINGS'

export const SET_FORM_VALUES = '@/CONSUPTION_SET_FORM_VALUES'

export const setTenantGraphType = (payload) => ({
  type: SET_TENANT_GRAPH_TYPE,
  payload,
})

export const setFormValues = (payload) => ({
  type: SET_FORM_VALUES,
  payload,
})

export const setClearData = (payload) => ({
  type: CLEAR_DATA,
  payload,
})
