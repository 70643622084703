import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const TrafficLightIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 7H14V5.86C15.72 5.41 17 3.86 17 2H14V1C14 0.45 13.55 0 13 0H5C4.45 0 4 0.45 4 1V2H1C1 3.86 2.28 5.41 4 5.86V7H1C1 8.86 2.28 10.41 4 10.86V12H1C1 13.86 2.28 15.41 4 15.86V17C4 17.55 4.45 18 5 18H13C13.55 18 14 17.55 14 17V15.86C15.72 15.41 17 13.86 17 12H14V10.86C15.72 10.41 17 8.86 17 7ZM9 16C8.7337 16.006 8.4688 15.9588 8.221 15.861C7.9731 15.7633 7.7473 15.617 7.5568 15.4308C7.3663 15.2446 7.2149 15.0222 7.1115 14.7767C7.0081 14.5311 6.9549 14.2674 6.9548 14.001C6.9547 13.7346 7.0079 13.4709 7.1111 13.2253C7.2144 12.9797 7.3657 12.7572 7.5561 12.5709C7.7465 12.3846 7.9723 12.2382 8.22 12.1404C8.4678 12.0425 8.7327 11.9951 8.999 12.001C9.5216 12.0126 10.0188 12.2282 10.3844 12.6018C10.7499 12.9755 10.9547 13.4773 10.9548 14C10.9549 14.5227 10.7504 15.0247 10.3851 15.3985C10.0197 15.7723 9.5226 15.9882 9 16ZM9 11C8.7337 11.006 8.4688 10.9588 8.221 10.861C7.9731 10.7633 7.7473 10.617 7.5568 10.4308C7.3663 10.2446 7.2149 10.0222 7.1115 9.7767C7.0081 9.5311 6.9549 9.2674 6.9548 9.001C6.9547 8.7346 7.0079 8.4709 7.1111 8.2253C7.2144 7.9797 7.3657 7.7572 7.5561 7.5709C7.7465 7.3846 7.9723 7.2382 8.22 7.1404C8.4678 7.0425 8.7327 6.9951 8.999 7.001C9.5216 7.0126 10.0188 7.2282 10.3844 7.6018C10.7499 7.9755 10.9547 8.4773 10.9548 9C10.9549 9.5227 10.7504 10.0247 10.3851 10.3985C10.0197 10.7723 9.5226 10.9882 9 11ZM9 6C8.4696 6 7.9609 5.7893 7.5858 5.4142C7.2107 5.0391 7 4.53043 7 4C7 2.89 7.89 2 9 2C9.5304 2 10.0391 2.21071 10.4142 2.58579C10.7893 2.96086 11 3.46957 11 4C11 4.53043 10.7893 5.0391 10.4142 5.4142C10.0391 5.7893 9.5304 6 9 6Z" fill="#3D3D3D"/>
    </svg>
  )
}

TrafficLightIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TrafficLightIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TrafficLightIcon)
