import React, {
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import SelectField from '@/components/fields/SelectField'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import {
  START_DATE,
  END_DATE,
  DROP_DOWN_OGJECT,
  DROP_DOWN_STATUS,
  OVERDUE,
} from '@/constants/forms/serviceDeskMyCity'
import { dropDownStatus } from './config'
import {
  FormContainer,
  ContentContainer,
  Text,
  CustomCheckBoxField,
  CustomDatePicker,
  StyledButton,
} from './styles'

const ServiceDeskMyCityForm = ({ optionsStatus, typeOfObjects }) => {
  const formicForm = useRef(null)
  const [urlValues, setUrlValues] = useUrlParseAndPush()

  const handleResetFilter = useCallback(() => {
    setUrlValues({ widgetId: urlValues.widgetId })
    formicForm.current.resetForm({})
  }, [setUrlValues, urlValues.widgetId])

  const parsedInitialValues = useMemo(() => {
    let newUrlParse = { ...urlValues }
    if (urlValues[OVERDUE]) {
      newUrlParse = {
        ...urlValues,
        [OVERDUE]: urlValues[OVERDUE] === 'true' || false,
      }
    }
    if (urlValues[START_DATE]) {
      newUrlParse[START_DATE] = new Date(urlValues[START_DATE])
    }
    if (urlValues[END_DATE]) {
      newUrlParse[END_DATE] = new Date(urlValues[END_DATE])
    }
    return newUrlParse
  }, [urlValues])

  const handleSetValues = useCallback((formValues) => {
    const newFormValues = { ...formValues }
    let overdueValue = false
    if (!formValues[OVERDUE]) {
      delete newFormValues[OVERDUE]
    }
    if (urlValues[START_DATE] && formValues[START_DATE]
      && new Date(urlValues[START_DATE]).getTime() !== formValues[START_DATE].getTime()
    ) {
      delete newFormValues[START_DATE]
    }
    if (urlValues[END_DATE] && formValues[END_DATE]
      && new Date(urlValues[END_DATE]).getTime() !== formValues[END_DATE].getTime()
    ) {
      delete newFormValues[END_DATE]
    }
    if (urlValues[OVERDUE] === 'true') {
      overdueValue = true
    } else {
      overdueValue = false
    }
    if (formValues[DROP_DOWN_OGJECT] !== urlValues[DROP_DOWN_OGJECT]
      || formValues[DROP_DOWN_STATUS] !== urlValues[DROP_DOWN_STATUS]
      || Boolean(formValues[OVERDUE]) !== overdueValue
    ) {
      setUrlValues(newFormValues)
    }
  }, [setUrlValues, urlValues])

  const handleSetDatePicker = useCallback((formValues) => () => {
    const newValues = { ...urlValues }
    if (formValues[START_DATE]) {
      newValues[START_DATE] = formValues[START_DATE]
    }
    if (formValues[END_DATE]) {
      newValues[END_DATE] = formValues[END_DATE]
    }
    setUrlValues(newValues)
  }, [setUrlValues, urlValues])

  const datePickerButtonCondition = useCallback((formValues) => {
    if (urlValues[START_DATE] && urlValues[START_DATE]) {
      return (
        formValues[START_DATE]
        && formValues[END_DATE]
        && (
          new Date(urlValues[START_DATE]).getTime() !== formValues[START_DATE].getTime()
          || new Date(urlValues[END_DATE]).getTime() !== formValues[END_DATE].getTime()
        )
      )
    }
    return formValues[START_DATE] && formValues[END_DATE]
  }, [urlValues])

  return (
    <Formik
      ref={formicForm}
      initialValues={parsedInitialValues}
      enableReinitialize
      validate={handleSetValues}
      render={({
        handleSubmit,
        values,
      }) => (
        <FormContainer onSubmit={handleSubmit}>
          <ContentContainer datePicker>
            <CustomDatePicker name={START_DATE} />
          </ContentContainer>
          <Text datePicker>
            -
          </Text>
          <ContentContainer datePicker>
            <CustomDatePicker name={END_DATE} />
          </ContentContainer>
          {datePickerButtonCondition(values) && (
            <ContentContainer buttonDatePicker>
              <StyledButton type="button" onClick={handleSetDatePicker(values)}>
                <Lang id="serviceDeskMyCity.build" />
              </StyledButton>
            </ContentContainer>
          )}
          <ContentContainer dropDownObject>
            <SelectField
              largeOptions
              name={DROP_DOWN_OGJECT}
              placeholder="Тип объекта"
              withSearch
              options={typeOfObjects}
            />
          </ContentContainer>
          <ContentContainer status>
            <SelectField
              largeOptions
              name={DROP_DOWN_STATUS}
              placeholder="Статус"
              withSearch
              options={optionsStatus}
            />
          </ContentContainer>
          <ContentContainer checkBox>
            <CustomCheckBoxField name={OVERDUE} />
            <Text><Lang id="serviceDeskMyCity.overdue" /></Text>
          </ContentContainer>
          {Object.keys(urlValues).length > 1 && (
            <Text reset onClick={handleResetFilter}>
              <Lang id="serviceDeskMyCity.resetFilter" />
            </Text>
          )}
        </FormContainer>
      )}
    />
  )
}

ServiceDeskMyCityForm.propTypes = {
}

ServiceDeskMyCityForm.defaultProps = {
  typeOfObjects: [],
  optionsStatus: dropDownStatus,
}

export default React.memo(ServiceDeskMyCityForm)
