import { CREATE_TENANT_ADMIN } from '../index'

export const requestCreateAdminTenant = (payload) => ({
  type: CREATE_TENANT_ADMIN.REQUEST,
  payload,
})

export const responseCreateAdminTenant = (payload) => ({
  type: CREATE_TENANT_ADMIN.SUCCESS,
  payload,
})

export const errorCreateAdminTenant = (payload) => ({
  type: CREATE_TENANT_ADMIN.ERROR,
  payload,

})
