import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const StateIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26 38C32.6274 38 38 32.6274 38 26C38 19.3726 32.6274 14 26 14C19.3726 14 14 19.3726 14 26C14 32.6274 19.3726 38 26 38Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" />
      <path d="M20.2148 23.1406L25.5002 25.6937C25.6376 25.7594 25.8219 25.7937 26.0031 25.7937C26.1843 25.7937 26.3686 25.7594 26.5061 25.6937L31.7915 23.1406C32.0695 23.0062 32.0695 22.7875 31.7915 22.6531L26.5061 20.1C26.3686 20.0344 26.1843 20 26.0031 20C25.8219 20 25.6376 20.0344 25.5002 20.1L20.2148 22.6531C19.9367 22.7875 19.9367 23.0062 20.2148 23.1406Z" fill={fill} className="svgFill" />
      <path d="M31.7915 25.7562C31.7915 25.7562 30.8262 25.2906 30.6982 25.2281C30.5701 25.1656 30.5357 25.1687 30.4014 25.2312C30.2671 25.2937 26.5029 27.1125 26.5029 27.1125C26.3624 27.1781 26.1812 27.2125 26 27.2125C25.8188 27.2125 25.6345 27.1781 25.4971 27.1125C25.4971 27.1125 21.8329 25.3438 21.6611 25.2594C21.4736 25.1688 21.4205 25.1687 21.2519 25.25L20.2085 25.7531C19.9305 25.8875 19.9305 26.1063 20.2085 26.2406L25.4939 28.7937C25.6314 28.8594 25.8157 28.8938 25.9969 28.8938C26.1781 28.8938 26.3624 28.8594 26.4998 28.7937L31.7852 26.2406C32.0695 26.1094 32.0695 25.8906 31.7915 25.7562Z" fill={fill} className="svgFill" />
      <path d="M31.7915 28.8594C31.7915 28.8594 30.8262 28.3938 30.6982 28.3313C30.5701 28.2688 30.5357 28.2719 30.4014 28.3344C30.2671 28.3969 26.5061 30.2188 26.5061 30.2188C26.3655 30.2844 26.1843 30.3188 26.0031 30.3188C25.8219 30.3188 25.6376 30.2844 25.5002 30.2188C25.5002 30.2188 21.836 28.45 21.6642 28.3656C21.4768 28.275 21.4237 28.275 21.255 28.3562L20.2116 28.8594C19.9336 28.9937 19.9336 29.2125 20.2116 29.3469L25.4971 31.9C25.6345 31.9688 25.8188 32 26 32C26.1812 32 26.3655 31.9656 26.5029 31.9L31.7884 29.3469C32.0695 29.2125 32.0695 28.9937 31.7915 28.8594Z" fill={fill} className="svgFill" />
    </svg>
  )
}

StateIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

StateIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(StateIcon)
