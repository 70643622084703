import { GET_PINS_AND_ZONES_WITH_TELEMENTRY } from './index'

export const requestGetPinsAndZonesAndTelemetry = (payload) => ({
  type: GET_PINS_AND_ZONES_WITH_TELEMENTRY.REQUEST,
  payload,
})

export const successGetPinsAndZonesAndTelemetry = (payload) => ({
  type: GET_PINS_AND_ZONES_WITH_TELEMENTRY.SUCCESS,
  payload,
})

export const errorGetPinsAndZonesAndTelemetry = (payload) => ({
  type: GET_PINS_AND_ZONES_WITH_TELEMENTRY.ERROR,
  payload,
})
