import { GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA } from './index'

export const requestHeatGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA.REQUEST,
  payload,
})

export const responseHeatGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA.SUCCESS,
  payload,
})
export const errorHeatGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_HEAT_GRAPH_DATA.ERROR,
  payload,
})
