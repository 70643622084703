import controller from './controller'
import powerLine from './powerLine'
import baseStation from './baseStation'
import light from './light'
import lightModule from './lightModule'
import support from './support'
import universalMeter from './universalMeter'
import shuo from './shuo'
import lamp from './lamp'

export default {
  universalMeter,
  lightModule,
  baseStation,
  controller: baseStation,
  CONTROLLER: controller,
  powerLine,
  support,
  light,
  shuo,
  lamp,
}
