import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EquipmentReferenceIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9.5C12 8.67157 12.6716 8 13.5 8H38.5C39.3284 8 40 8.67157 40 9.5C40 10.3284 39.3284 11 38.5 11H13.5C12.6716 11 12 10.3284 12 9.5Z" fill={fill} className="svgFill" />
      <path d="M13.5 15C12.6716 15 12 15.6716 12 16.5C12 17.3284 12.6716 18 13.5 18H38.5C39.3284 18 40 17.3284 40 16.5C40 15.6716 39.3284 15 38.5 15H13.5Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50 35H9C8.96164 35 8.9234 35.0004 8.88522 35.0011C8.85159 35.0017 8.81805 35.0026 8.78458 35.0038C5.57065 35.1172 3 37.7584 3 41C3 44.3137 5.68628 47 9 47H50V50H9C4.0304 50 0.0015564 45.9721 0 41.0029V9C0 4.02943 4.02945 0 9 0H50V35ZM3 9V34.2917C3.05054 34.2464 3.10159 34.2018 3.15314 34.1577C3.19351 34.1231 3.23419 34.089 3.27518 34.0552C4.07837 33.3923 4.9985 32.8661 6 32.5121C6.93832 32.1805 7.94809 32 9 32H47V3H9C5.68628 3 3 5.68629 3 9Z" fill={fill} className="svgFill" />
    </svg>
  )
}

EquipmentReferenceIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EquipmentReferenceIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EquipmentReferenceIcon)
