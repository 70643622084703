import widgets from '../widgets'

const {
  LAST_UPDATE,
  ILLUMINANCE,
} = widgets

export default [
  LAST_UPDATE({}),
  ILLUMINANCE({}),
]
