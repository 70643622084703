import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA,
  CONSUMPTION_ANALYSIS_TABLE,
} from '@/store/actions/consumptionAnalysis'
import {
  responseTableData,
  errorTableData,
} from '@/store/actions/consumptionAnalysis/getTableData'
import { getSelectedNode } from '@/store/selectors/appSettings'
import createNotifications from '@/helpers/notification'
import {
  getForm,
  getTableParameters,
} from '@/store/selectors/consumptionAnalysis'
import { HEAT_ANALYTICS_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import { HEAT_SUPPLY } from '@/constants/names'
import {
  getOneSimIntegrationsInformation,
} from '@/helpers/formaters/getOneSimIntegrationsInformation'

const NO_DATA_FOR_PERIOD = 'no.data.was.found.for.the.specified.period'

function* getGraphDataSaga() {
  const toast = createNotifications()
  try {
    const formValues = yield select(getForm)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const {
      type,
      dateFirst,
      dateSecond,
      integrationTypes,
    } = formValues
    if (integrationTypes === HEAT_SUPPLY) {
      const dataJson = {
        heatAnalyticsIntegrations: getOneSimIntegrationsInformation(node),
        type,
        range: {
          startDate: moment(dateFirst).format('DD.MM.YYYY'),
          endDate: moment(dateSecond).format('DD.MM.YYYY'),
        },
        includedColumns: tableParameters.activeFields,
        query: tableParameters.requestQuery || '',
        sort: {
          type: tableParameters.sort.direction,
          value: tableParameters.sort.field,
        },
        page: tableParameters.page - 1,
        size: tableParameters.perPage,
      }

      const response = yield request({
        url: HEAT_ANALYTICS_TABLE_ENDPOINT,
        method: 'post',
        body: dataJson,
      })

      if (response) {
        const { error } = response.data

        if (error) {
          yield put(errorTableData())
        } else {
          yield put(responseTableData({
            content: response.data.content,
            totalElements: response.data.totalElements,
          }))
        }
      } else {
        yield put(errorTableData())
      }
    }
  } catch (error) {
    const { response: { data: { message } } } = error
    const node = yield select(getSelectedNode)
    yield put(errorTableData(error))
    if (message === NO_DATA_FOR_PERIOD) {
      toast({
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'Данных за указанный период не найдено',
        type: 'error',
      })
    } else {
      toast({
        title: 'Ошибка сервиса!',
        description: `Hе удалось получить данные "${node.name}".\nПовторите попытку позже`,
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(GET_CONSUMPTION_ANALYSIS_HEAT_TABLE_DATA.REQUEST, getGraphDataSaga)
  yield takeLatest(CONSUMPTION_ANALYSIS_TABLE.PAGE, getGraphDataSaga)
  yield takeLatest(CONSUMPTION_ANALYSIS_TABLE.PER_PAGE, getGraphDataSaga)
  yield takeLatest(CONSUMPTION_ANALYSIS_TABLE.SORT, getGraphDataSaga)
  yield takeLatest(CONSUMPTION_ANALYSIS_TABLE.SELECTED_FIELDS, getGraphDataSaga)
  yield takeLatest(CONSUMPTION_ANALYSIS_TABLE.REQUEST_QUERY, getGraphDataSaga)
}
