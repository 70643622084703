import React, { useMemo, useCallback, useEffect } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import {
  routerHistoryType,
  routerHistoryDefault,
} from '@/propTypes/router'
import CoreIcons from '@/components/icons/core'
import HeaderIcons from '@/components/icons/header'
import PieChart from '@/components/charts/PieChart'
import WidgetSettingsPlaceholder from '@/components/widgets/WidgetSettingsPlaceholder'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { FormattedMessage as Lang } from 'react-intl'
import {
  ALARMS_ANALYTICS_URL,
  SUMMARY_ALARMS_ANALYTICS_URL,
} from '@/constants/routes'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
  STATUS_COLORS,
} from '@/constants/alarms'
import {
  WidgetContainer,
  TooltipWrapper,
  WidgetHeader,
  WidgetTitleWrapper,
  WidgetHeaderIcon,
  WidgetTitle,
  ChartContainer,
  ChartDescriptionsRow,
  ChartDescriptionMarker,
  ChartDescription,
} from './styles'

const CHART_DESCRIPTIONS = [
  {
    name: 'Исправно',
    status: OK,
  },
  {
    name: 'Неисправно',
    status: ERROR,
  },
  {
    name: 'Предупреждение',
    status: WARNING,
  },
  {
    name: 'Не определено',
    status: UNKNOWN,
  },
]

const AnalyticsAlarmsWidget = ({
  id,
  data,
  settings,
  isSettingSet,
  demo,
  onOpenSettings,
  onGetData,
  history,
  type,
  widgetType,
}) => {
  useEffect(() => {
    onGetData(id)
  }, [id, onGetData, settings.installationId])
  const dataArray = useMemo(() => Object.keys(data).map((key) => ({
    type: key,
    value: data[key],
  })), [data])
  const handleOpenSettings = useCallback(() => {
    onOpenSettings(id, type, widgetType)
  }, [onOpenSettings, id, type, widgetType])
  const handleOpenTable = useCallback((url) => (active) => {
    const hasActive = typeof active === 'string'
    if (url) {
      history.push({
        pathname: url,
        search: hasActive
          ? qs.stringify({ filter: active, name: encodeURIComponent(settings.name) })
          : '',
      })
    }
  }, [history, settings.name])
  const renderChartDescriptions = useMemo(() => (
    <ChartDescriptionsRow>
      {CHART_DESCRIPTIONS.map(({ name, status }) => (
        <ChartDescription key={status}>
          <ChartDescriptionMarker color={STATUS_COLORS[status]} />
          {name}
        </ChartDescription>
      ))}
    </ChartDescriptionsRow>
  ), [])

  if (!isSettingSet && !demo) {
    return (
      <WidgetSettingsPlaceholder onOpenSettings={handleOpenSettings} />
    )
  }

  return (
    <WidgetContainer>
      <WidgetHeader>
        {!demo && (
          <TooltipWrapper>
            <PortalTooltip
              title={(<Lang id="tooltip.table" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <WidgetHeaderIcon
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  withMargin
                  onClick={handleOpenTable(SUMMARY_ALARMS_ANALYTICS_URL)}
                >
                  <CoreIcons.TableIcon />
                </WidgetHeaderIcon>
              )}
            />
          </TooltipWrapper>
        )}
        <WidgetTitleWrapper>
          <WidgetTitle>{settings.name}</WidgetTitle>
          <WidgetTitle small>Аналитика неисправностей</WidgetTitle>
        </WidgetTitleWrapper>
        <TooltipWrapper>
          <PortalTooltip
            title={(<Lang id="tooltip.settings" />)}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <WidgetHeaderIcon
                onClick={demo ? noop : handleOpenSettings}
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                <HeaderIcons.SettingsIcon />
              </WidgetHeaderIcon>
            )}
          />
        </TooltipWrapper>
      </WidgetHeader>
      <ChartContainer>
        <PieChart
          onClick={handleOpenTable(settings.installationId
            ? `${ALARMS_ANALYTICS_URL}/${settings.installationId}`
            : null)}
          disableActions={demo}
          data={dataArray}
        />
      </ChartContainer>
      {renderChartDescriptions}
    </WidgetContainer>
  )
}

AnalyticsAlarmsWidget.propTypes = {
  id: pt.string,
  type: pt.string,
  widgetType: pt.string,
  history: routerHistoryType,
  settings: pt.shape({
    name: pt.string,
    installationId: pt.number,
  }),
  demo: pt.bool,
  isSettingSet: pt.bool,
  onOpenSettings: pt.func,
  onGetData: pt.func,
  data: pt.shape({
    [OK]: pt.number,
    [WARNING]: pt.number,
    [ERROR]: pt.number,
    [UNKNOWN]: pt.number,
  }),
}
AnalyticsAlarmsWidget.defaultProps = {
  id: null,
  type: null,
  widgetType: null,
  history: routerHistoryDefault,
  settings: {
    name: 'Геозона',
  },
  demo: false,
  isSettingSet: false,
  onOpenSettings: noop,
  onGetData: noop,
  data: {
    [OK]: 25,
    [WARNING]: 25,
    [ERROR]: 25,
    [UNKNOWN]: 25,
  },
}

export default withRouter(AnalyticsAlarmsWidget)
