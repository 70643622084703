import { takeLatest, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import request from '@/helpers/axios'
import { RESTORE_PASSWORD } from '@/constants/routes'
import { RESET_PASSWORD_API_URL } from '@/constants/apiRoutes'
import formatErrors from '@/helpers/formatErrors'
import {
  USER_RESTORATION,
} from '@/store/actions/user'
import {
  successRestoreAccount,
} from '@/store/actions/user/restoration'
import {
  EMAIL,
} from '@/constants/forms/signIn'

function* restorationUser({ payload }) {
  const { values, form } = payload
  try {
    const response = yield request({
      url: RESET_PASSWORD_API_URL,
      method: 'post',
      body: values,
    })

    if (response) {
      const { errors } = response.data
      if (errors.length !== 0) {
        form.setErrors(formatErrors(errors))
        form.setSubmitting(false)
      } else {
        yield put(successRestoreAccount(payload))
        yield put(push(RESTORE_PASSWORD))
      }
    } else {
      form.setErrors({ [EMAIL]: 'connection Error' })
      form.setSubmitting(false)
    }
  } catch (error) {
    form.setSubmitting(false)
  }
}

export default function* root() {
  yield takeLatest(USER_RESTORATION.BEGIN, restorationUser)
}
