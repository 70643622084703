import { GET_TUMEN_OUR_HOME_DATA } from './index'

export const getTyumenOurHomeRequest = (payload) => ({
  type: GET_TUMEN_OUR_HOME_DATA.REQUEST,
  payload,
})

export const getTyumenOurHomeSuccess = (payload) => ({
  type: GET_TUMEN_OUR_HOME_DATA.SUCCESS,
  payload,
})

export const getTyumenOurHomeError = (payload) => ({
  type: GET_TUMEN_OUR_HOME_DATA.ERROR,
  payload,
})
