import { GET_CARD } from './index'


export const requestGetCard = (payload) => ({
  type: GET_CARD.REQUEST,
  payload,
})

export const successGetCard = (payload) => ({
  type: GET_CARD.SUCCESS,
  payload,
})

export const errorGetCard = (payload) => ({
  type: GET_CARD.ERROR,
  payload,
})
