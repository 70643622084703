import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import get from 'lodash/get'
import FileControll from '@/components/controls/FileControll'

const FileControllField = ({ label, gray, download, fieldProps, ...ownProps }) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const fieldError = get(form, `errors.${field.name}`, null)
      const onChange = (value) => {
        form.setFieldValue(field.name, value)
      }

      return (
        <FileControll
          {...ownProps}
          error={fieldError}
          {...field}
          {...fieldProps}
          onChange={onChange}
          label={label}
          gray={gray}
          download={download}
        />
      )
    }}
  </Field>
)

FileControllField.defaultProps = {
  label: null,
  fieldProps: {},
}

FileControllField.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
  fieldProps: pt.objectOf(pt.oneOfType([pt.string, pt.bool, pt.number])),
}

export default React.memo(FileControllField)
