import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { PROCESSES_BY_ID } from '@/constants/widgets'
import { DASHBOARD_URBAN_PROCESSES_ID } from './names'

export default [
  {
    processId: PROCESSES_BY_ID.LIGHT,
    id: DASHBOARD_URBAN_PROCESSES_ID.LIGHT,
    title: (<Lang id="widgets.light" />),
    selected: true,
  },
  {
    processId: PROCESSES_BY_ID.HEAT_SUPPLY,
    id: DASHBOARD_URBAN_PROCESSES_ID.HEAT_SUPPLY,
    title: (<Lang id="widgets.heatSupply" />),
    selected: true,
  },
  {
    processId: PROCESSES_BY_ID.WATER_SUPPLY,
    id: DASHBOARD_URBAN_PROCESSES_ID.WATER_SUPPLY,
    title: (<Lang id="widgets.waterSupply" />),
    selected: true,
  },
  {
    id: DASHBOARD_URBAN_PROCESSES_ID.TYUMEN_OUR_HOME,
    title: (<Lang id="widgets.tyumenOurHome" />),
    selected: true,
  },
  {
    id: DASHBOARD_URBAN_PROCESSES_ID.SUPPORT,
    title: (<Lang id="widgets.support" />),
    selected: true,
  },
  {
    processId: PROCESSES_BY_ID.CHARGING_STATION,
    id: DASHBOARD_URBAN_PROCESSES_ID.CHARGING_STATION,
    title: (<Lang id="widgets.chargingStation" />),
    selected: false,
  },
  {
    id: DASHBOARD_URBAN_PROCESSES_ID.ARCHITECTURE_ILLUMINATION,
    title: (<Lang id="widgets.architectureIllumination" />),
    selected: false,
  },
  {
    id: DASHBOARD_URBAN_PROCESSES_ID.TRAFFIC_LIGHT,
    title: (<Lang id="widgets.trafficLight" />),
    selected: false,
  },
  {
    id: DASHBOARD_URBAN_PROCESSES_ID.INFO_FRAME,
    title: (<Lang id="widgets.infoFrame" />),
    selected: false,
  },
]
