import { takeLatest } from 'redux-saga/effects'
import request from '@/helpers/axios'
import get from 'lodash/get'
import { GENERATE_INSTALLATION_OBJECT_FILE } from '@/store/actions/installation'
import { generateStartSettingProcessGetFile } from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'

function* exportCardFileSaga({ payload: { id, name, fields } }) {
  const fieldsNamesWithoutAttachments = (fields || []).reduce((accumulator, element) => {
    if (element.fieldName.split('.')[0] === 'ATTACHMENT') {
      return accumulator
    }
    return `${accumulator}, ${element.fieldName}`
  }, '')
  try {
    const response = yield request({
      url: `${generateStartSettingProcessGetFile(id)}?includedFields=(${fieldsNamesWithoutAttachments})`,
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })
    const date = new Date()
    downloadFileFromBlob({
      blob: response.data,
      fileName: `Наряд на монтаж и пусконаладочные работы объекта ${name} от ${date.getDay()}.${date.getMonth()}.${date.getFullYear()}.pdf`,
    })
  } catch (error) {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: `Не удалось выполнить загрузку паспорта. Код ошибки: ${get(error, 'response.status', '000')}`,
      type: 'error',
    })
    console.log('TCL: function*exportCardFileSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GENERATE_INSTALLATION_OBJECT_FILE.REQUEST, exportCardFileSaga)
}
