// Connect polyfills for support old browsers
import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'
import App from '@/containers/AppContainer'
import * as serviceWorker from './serviceWorker'

import 'font-awesome/css/font-awesome.min.css'

addLocaleData(en)
addLocaleData(ru)

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
