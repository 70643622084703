export const TASK_STATUS = 'taskStatus'
export const EXECUTION_EXPIRED = 'executionExpired'
export const APPEAL_CHANNEL = 'appealChannel'
export const APPEAL_CHANNEL_BLOCK = 'appealChannelBlock'
export const OBJECT_TYPE = 'objectType'
export const OBJECT_TYPE_BLOCK = 'objectTypeBlock'
export const PROJECT = 'project'
export const PROJECT_BLOCK = 'projectBlock'
export const TOWN = 'town'
export const TOWN_BLOCK = 'townBlock'
export const SVT_STATUS = 'SVTStatus'
export const SVT_STATUS_BLOCK = 'SVTStatusBlock'
export const APPEAL_CATEGORY = 'appealCategory'
export const APPEAL_CATEGORY_BLOCK = 'appealCategoryBlock'
export const WORK = 'work'
export const WORK_BLOCK = 'workBlock'
export const OBJECTS_BLOCK = 'objectsBlock'
export const OBJECT = 'object'
export const TYPE_OF_WORK = 'typeOfWork'
export const VIEW_OF_WORK = 'viewOfWork'
export const TYPE_OF_WORK_BLOCK = 'typeOfWorkBlock'
export const VIEW_OF_WORK_BLOCK = 'viewOfWorkBlock'
export const MUNICIPALITY = 'municipality'
export const MUNICIPALITY_BLOCK = 'municipalityBlock'
export const DISTRICT = 'district'
export const DISTRICT_BLOCK = 'districtBlock'
export const REGION = 'region'
export const REGION_BLOCK = 'regionBlock'
export const MODEL = 'model'
export const MAC_ADDRESS = 'MACAddress'
export const PLANNED_DUE_DATE = 'plannedDueDate'
export const ACTUAL_DATE_OF_EXECUTION = 'actualDateOfExecution'
export const TASK = 'task'
export const APPEALS_BLOCK = 'appealsBlock'
export const ENTITY = 'entity'
export const INDIVIDUAL = 'individual'
export const TELEPHONE = 'telephone'
export const CONTACT_NUMBER = 'contactNumber'
export const EMAIL = 'email'
export const DOCUMENT = 'document'
export const INDIVIDUAL_NUMBER = 'individualNumber'
export const COORDINATES = 'coordinates'
export const LOCATION = 'location'
export const ACTUAL_DATE_HIRING = 'actualDateHiring'
export const SLA_BLOCK = 'SLABlock'
export const SLA = 'SLA'
export const REACTION_TIME_HOURS = 'reactionTimeHours'
export const WORKING_HOURS = 'workingHours'
