export const INTEGRATION_FORM = 'integration'
export const CLIENT_ID = 'clientID'
export const SECRET_ID = 'secretID'
export const SD_NAME = 'name'
export const FILE = 'file'
export const ID = 'id'
export const LOGIN = 'login'
export const PASSWORD = 'password'
export const PORT = 'port'
export const URL = 'url'
export const NAME = 'alias'
export const PERIOD = 'equipmentRefreshDelay'
export const UNIT = 'unit'
export const INTEGRATION_ID = 'installationIntegrationId'
export const SERVICE_DESK_INTEGRATIONS = 'serviceDeskIntegrations'
export const SERVICE_DESK_CHECKBOX = 'serviceDeskCheckBox'

export const EMAIL = 'email'

export const MESH_CONTEXT_URL = 'context_url'
export const MESH_HISTORY_URL = 'history_url'
export const MESH_CLIENT_ID = 'client_id'
export const MESH_CLIENT_SECRET = 'client_secret'
