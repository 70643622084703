import { GET_ANALYTICS_ALARMS_FILE } from './index'

export const requestAlarmManagerFile = (payload) => ({
  type: GET_ANALYTICS_ALARMS_FILE.REQUEST,
  payload,
})

export const successAlarmManagerFile = (payload) => ({
  type: GET_ANALYTICS_ALARMS_FILE.SUCCESS,
  payload,
})

export const errorAlarmManagerFile = (payload) => ({
  type: GET_ANALYTICS_ALARMS_FILE.ERROR,
  payload,
})
