import {
  takeEvery,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { INSTALLATION_FILE_SCHEME_DOWNLOAD } from '@/constants/apiRoutes'
import {
  DOWNLOAD_MULTIPLE_OBJECT_SCHEME,
} from '@/store/actions/installation'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'

function* uploadSchemeSaga({ payload }) {
  try {
    const response = yield request({
      url: INSTALLATION_FILE_SCHEME_DOWNLOAD,
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })
    downloadFileFromBlob({
      blob: response.data,
      fileName: 'SLS24_OBJECT_SCHEME.xlsx',
    })
  } catch (error) {
    console.log('function*uploadSchemeSaga -> error', error)
  }
}

export default function* root() {
  yield takeEvery(DOWNLOAD_MULTIPLE_OBJECT_SCHEME.REQUEST, uploadSchemeSaga)
}
