import React from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import CircleChart from '@/components/charts/CircleChart'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import { REQUEST_STATUSES } from '@/constants/requests'
import Loader from '@/components/blocks/Loader'

import {
  Container,
  Header,
  TitleContainer,
  Title,
  RowContainer,
  Row,
  RowTitle,
  Indicator,
  GraphContainer,
  LoaderContainer,
} from './styles'

const TyumenOurHomeSmallWidget = ({
  data,
  main,
  view,
  total,
  solved,
  graph,
  requestState,
}) => {
  const { title, icon: Icon } = view
  const { totalSelector, solvedSelector } = graph
  const graphValue = ((get(data, solvedSelector, 0) / get(data, totalSelector, 0)) || 0) * 100
  const showLoader = requestState === REQUEST_STATUSES.PENDING
  return (
    <Container>
      <Header>
        {Icon && <Icon />}
        <TitleContainer>
          <Title title>
            {title}
          </Title>
        </TitleContainer>
      </Header>
      {showLoader
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <>
            <GraphContainer>
              <CircleChart
                value={graphValue.toFixed(2)}
                maxValue={100}
                units="%"
                color="#90C737"
              />
            </GraphContainer>
            <RowContainer>
              <Row>
                <RowTitle>
                  <Title rowTitle>{total.name}</Title>
                </RowTitle>
                <Title>{numberWithSpaces(get(data, total.selector, null)) || 0}</Title>
              </Row>
            </RowContainer>
            <RowContainer>
              {main.map((element) => (
                <Row key={element.selector} disabled={element.disabled}>
                  <RowTitle>
                    <Title rowTitle>{element.name}</Title>
                  </RowTitle>
                  <Title>{numberWithSpaces(get(data, element.selector, null)) || (element.disabled ? '' : 0)}</Title>
                </Row>
              ))}
            </RowContainer>
            <RowContainer last>
              <Row>
                <RowTitle>
                  <Indicator />
                  <Title rowTitle>{solved.name}</Title>
                </RowTitle>
                <Title>{numberWithSpaces(get(data, solved.selector, null)) || 0}</Title>
              </Row>
            </RowContainer>
          </>
        )}
    </Container>
  )
}

TyumenOurHomeSmallWidget.defaultProps = {
  view: {
    title: 'Настройки виджета',
    icon: React.Fragment,
  },
  main: [],
  total: {},
  solved: {},
  graph: {},
  data: {},
  requestState: REQUEST_STATUSES.NOT_REQUESTED,
}

TyumenOurHomeSmallWidget.propTypes = {
  view: pt.shape({
    name: pt.string,
    selector: pt.string,
  }),
  main: pt.arrayOf(pt.shape({
    name: pt.string,
    selector: pt.string,
    disabled: pt.bool,
  })),
  total: pt.shape({
    name: pt.string,
    selector: pt.string,
  }),
  solved: pt.shape({
    name: pt.string,
    selector: pt.string,
  }),
  graph: pt.shape({
    totalSelector: pt.string,
    solvedSelector: pt.string,
  }),
  data: pt.objectOf(pt.oneOfType(pt.string, pt.number)),
  requestState: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
  ]),
}

export default TyumenOurHomeSmallWidget
