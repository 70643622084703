import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import {
  routerHistoryType,
  routerHistoryDefault,
} from '@/propTypes/router'
import MenuIcons from '@/components/icons/menu'
import HeaderIcons from '@/components/icons/header'
import Button from '@/components/blocks/Button'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import {
  SERVICE_DESK_MY_CITY_URL,
} from '@/constants/routes'
import {
  AVERAGE_LEAD_TIME,
} from '@/constants/serviceDeskMyCity'
import {
  WidgetContainer,
  Container,
  PrimaryTitle,
  ShadowElement,
  Header,
  InfoBlock,
  InfoHeader,
  InfoContainer,
  Text,
  InfoRow,
} from './styles'
import Loader from "@/components/blocks/Loader";

const ServesDescWidget = ({
  id,
  statistics,
  settings,
  isSettingSet,
  demo,
  onOpenSettings,
  onGetData,
  history,
  type,
  widgetType,
  data,
  serviceDeskStatus,
  userApplications,
}) => {
  useEffect(() => {
    if (serviceDeskStatus) {
      onGetData({ id, serviceDeskConfig: serviceDeskStatus.propertyConfigContent })
    }
  }, [id, onGetData, serviceDeskStatus, settings.id])

  const onOpenSettingsHandler = (event) => {
    event.preventDefault()
    onOpenSettings(id, type, widgetType, settings)
  }
  return (
    <WidgetContainer
      isBig
      isHovered={isSettingSet}
    >
      <Header withData={serviceDeskStatus}>
        <MenuIcons.SupportIcon />
        <PrimaryTitle>
          <Lang id="widgets.support" />
          {(serviceDeskStatus) && (
            <Text headerTitle>{serviceDeskStatus.name}</Text>
          )}
        </PrimaryTitle>
        {(serviceDeskStatus) && (
          <ShadowElement
            onClick={onOpenSettingsHandler}
          >
            <HeaderIcons.SettingsIcon />
          </ShadowElement>
        )}
      </Header>
      <Container>
        {serviceDeskStatus
          ? (
            <Link to={{
              pathname: SERVICE_DESK_MY_CITY_URL,
              search: `?widgetId=${id}`,
            }}
            >
              {data ? (data.map((block) => (
                  <InfoBlock key={block.id}>
                    <InfoHeader type={block.id}>{block.title}</InfoHeader>
                    <InfoContainer>
                      {block.content.map((element) => {
                        const Icon = element.icon
                        return (
                            <InfoRow key={element.id}>
                              <Icon />
                              <Text title>{element.text}</Text>
                              <Text numbers>
                                {element.id === AVERAGE_LEAD_TIME ? `${element?.value?.days || 0} д. ${element?.value?.hours || 0} ч.` : element?.value}
                              </Text>
                            </InfoRow>
                        )
                      })}
                    </InfoContainer>
                  </InfoBlock>
              ))) : <Loader center/>
              }
            </Link>
          )
          : (
            <Button onClick={onOpenSettingsHandler}>
              <HeaderIcons.SettingsIcon />
              <Lang id="widgets.setup" />
            </Button>
          )}
      </Container>
    </WidgetContainer>
  )
}

ServesDescWidget.propTypes = {
  id: pt.string,
  type: pt.string,
  widgetType: pt.string,
  history: routerHistoryType,
  settings: pt.shape({
    name: pt.string,
    installationId: pt.number,
  }),
  demo: pt.bool,
  isSettingSet: pt.bool,
  onOpenSettings: pt.func,
  onGetData: pt.func,
  statistics: pt.shape({
    [OK]: pt.number,
    [WARNING]: pt.number,
    [ERROR]: pt.number,
    [UNKNOWN]: pt.number,
  }),
}
ServesDescWidget.defaultProps = {
  id: null,
  type: null,
  widgetType: null,
  history: routerHistoryDefault,
  settings: {},
  demo: false,
  isSettingSet: false,
  onOpenSettings: noop,
  onGetData: noop,
  statistics: {
    [OK]: 25,
    [WARNING]: 25,
    [ERROR]: 25,
    [UNKNOWN]: 25,
  },
}

export default withRouter(ServesDescWidget)
