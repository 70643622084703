import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DoneIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} className="svgFill" d="M1.63638 6.66661L2.0602 6.72328L5.78296 2.93155C5.71808 2.68463 5.70982 2.42582 5.75883 2.17514C5.80785 1.92445 5.91281 1.6886 6.06562 1.48584C6.21842 1.28307 6.41497 1.11882 6.64005 1.00578C6.86514 0.892743 7.11274 0.833951 7.3637 0.833951C7.61466 0.833951 7.86226 0.892743 8.08735 1.00578C8.31243 1.11882 8.50898 1.28307 8.66178 1.48584C8.81459 1.6886 8.91955 1.92445 8.96857 2.17514C9.01758 2.42582 9.00933 2.68463 8.94444 2.93155L11.0308 5.05659L11.4546 4.99992C11.6019 4.99992 11.7435 5.01992 11.8785 5.05659L14.783 2.09821C14.6898 1.74381 14.7139 1.36797 14.8518 1.02898C14.9896 0.689992 15.2334 0.406801 15.5454 0.223333C15.8573 0.0398648 16.22 -0.0336268 16.5772 0.0142564C16.9343 0.0621397 17.266 0.228722 17.5207 0.488165C17.7754 0.747609 17.939 1.08541 17.986 1.44918C18.033 1.81295 17.9609 2.18236 17.7807 2.5001C17.6006 2.81785 17.3226 3.06617 16.9897 3.20656C16.6569 3.34695 16.2879 3.37155 15.94 3.27656L13.0354 6.23494C13.0956 6.46407 13.1071 6.70365 13.0691 6.93766C13.0311 7.17168 12.9446 7.39475 12.8152 7.59195C12.6858 7.78916 12.5165 7.95596 12.3188 8.08121C12.121 8.20647 11.8993 8.28728 11.6684 8.31826C11.4375 8.34925 11.2028 8.32967 10.9799 8.26086C10.757 8.19205 10.5512 8.07558 10.376 7.91923C10.2009 7.76289 10.0606 7.57027 9.96437 7.35426C9.86819 7.13824 9.81837 6.90381 9.81827 6.66661L9.8739 6.23494L7.78752 4.10991C7.50983 4.18535 7.21757 4.18535 6.93988 4.10991L3.21712 7.90163L3.27276 8.33331C3.27276 8.66295 3.17678 8.98519 2.99698 9.25927C2.81717 9.53336 2.5616 9.74698 2.26259 9.87313C1.96358 9.99928 1.63456 10.0323 1.31714 9.96797C0.999711 9.90366 0.708136 9.74493 0.479285 9.51184C0.250433 9.27874 0.0945833 8.98177 0.0314433 8.65846C-0.0316967 8.33515 0.000709011 8.00004 0.124563 7.69549C0.248416 7.39094 0.458155 7.13064 0.727256 6.9475C0.996357 6.76436 1.31273 6.66661 1.63638 6.66661Z" />
    </svg>
  )
}

DoneIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DoneIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DoneIcon)
