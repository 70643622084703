import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 310px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const MessageContainer = styled.div`
  padding: 1.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.colors.subTitle};
`
