import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SandClockIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 0.900001C3 0.402945 3.40294 0 3.9 0H14.1C14.5971 0 15 0.402944 15 0.9V4.99917C15 5.25431 14.8917 5.49746 14.7021 5.66814L11.7433 8.33104C11.3461 8.68855 11.3461 9.31145 11.7433 9.66896L14.7021 12.3319C14.8917 12.5025 15 12.7457 15 13.0008V17.1C15 17.5971 14.5971 18 14.1 18H3.9C3.40294 18 3 17.5971 3 17.1V13.0008C3 12.7457 3.10829 12.5025 3.29793 12.3319L6.25671 9.66896C6.65395 9.31145 6.65395 8.68855 6.25671 8.33103L3.29793 5.66814C3.10829 5.49746 3 5.25431 3 4.99917V0.900001ZM13 13.4508C13 13.1957 12.8917 12.9525 12.7021 12.7819L9.60207 9.99186C9.25979 9.68381 8.74021 9.68381 8.39793 9.99186L5.29793 12.7819C5.10829 12.9525 5 13.1957 5 13.4508V15.3C5 15.7971 5.40294 16.2 5.9 16.2H12.1C12.5971 16.2 13 15.7971 13 15.3V13.4508ZM8.39793 8.00814C8.74021 8.31619 9.25979 8.31618 9.60207 8.00814L12.7021 5.21814C12.8917 5.04746 13 4.80431 13 4.54917V2.7C13 2.20294 12.5971 1.8 12.1 1.8H5.9C5.40294 1.8 5 2.20294 5 2.7V4.54917C5 4.80431 5.10829 5.04746 5.29793 5.21814L8.39793 8.00814ZM7 4.06701C7 3.80909 7.20909 3.6 7.46701 3.6H10.533C10.7909 3.6 11 3.80909 11 4.06701C11 4.1994 10.9438 4.32557 10.8454 4.41414L9.60207 5.53314C9.25979 5.84119 8.74021 5.84119 8.39793 5.53314L7.1546 4.41414C7.05619 4.32557 7 4.1994 7 4.06701Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SandClockIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SandClockIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SandClockIcon)
