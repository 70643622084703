import styled from 'styled-components'
import { STATUS_COLORS } from '@/constants/styles/mapsMarks' 

export default styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 2px solid ${({ theme }) => theme.colors.borders.redisign.dark};
  border-radius: 4px;
  ${({ childSelected, theme }) => childSelected && `
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 10px;
      height: 2px;
      border: solid ${theme.colors.backgrounds.redisign.input.dark};
      border-width: 0 2px 2px 0;
    }
  `}
  ${({ active, theme, childSelected }) => active && !childSelected && `
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 1px;
      left: 0;
      right: 0;
      content: '';
      width: 6px;
      height: 9px;
      border: solid ${theme.colors.borders.redisign.dark};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `}
  ${({ type, theme }) => STATUS_COLORS[type] && type && `
    background: ${STATUS_COLORS[type]};
    ::after {
      border-color: ${theme.colors.backgrounds.redisign.input.light};
    }
  `}
  ${({ marginRight }) => marginRight && `
    margin-right: 10px;
  `}
  ${({ theme, color, active }) => color && active && `
    background: ${color};
    border: solid ${color};
    ::after {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 1px;
      left: 0;
      right: 0;
      content: '';
      width: 6px;
      height: 9px;
      border: solid ${theme.colors.white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `}
`
