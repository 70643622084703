import styled from 'styled-components'

export const Table = styled.table`
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: #CCCCCC;
  margin-bottom: 1px;
  font-size: 12px;
  border-collapse: collapse;

  th {
    text-align: center;
    background-color: #EBEBEB;
    color: #3D3D3D;
    padding: 1em 1em 1em 1em;
    border-style: solid;
    border-width: 1px;
    border-color: #CCCCCC;
  }

  td {
    padding: 8px 10px;
    background: #FAFAFA;
    border-style: solid;
    border-width: 1px;
    border-color: #CCCCCC;
    vertical-align: top;
  }

  tr {
    &.even td {
      background: #F6F6F6
    }
  }
`

export default Table
