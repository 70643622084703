import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EcoStationsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5765 3.09043C10.8777 3.09043 10.2945 3.65091 10.2945 4.36285V15.2097C10.2945 15.9217 10.8777 16.4822 11.5765 16.4822C12.2752 16.4822 12.8584 15.9217 12.8584 15.2097V5.63528H28.1831V15.2097C28.1831 15.9217 28.7663 16.4822 29.465 16.4822C30.1638 16.4822 30.747 15.9217 30.747 15.2097V4.36285C30.747 3.65091 30.1638 3.09043 29.465 3.09043H11.5765Z" fill={fill} className="svgFill" />
      <path d="M19.3548 22.8739C18.6561 22.8739 18.0728 23.4344 18.0728 24.1463C18.0728 24.8583 18.6561 25.4188 19.3548 25.4188H21.8743C22.573 25.4188 23.1562 24.8583 23.1562 24.1463C23.1562 23.4344 22.573 22.8739 21.8743 22.8739H19.3548Z" fill={fill} className="svgFill" />
      <path d="M19.3548 29.1317C18.6561 29.1317 18.0728 29.6922 18.0728 30.4042C18.0728 31.1161 18.6561 31.6766 19.3548 31.6766H21.8743C22.573 31.6766 23.1562 31.1161 23.1562 30.4042C23.1562 29.6922 22.573 29.1317 21.8743 29.1317H19.3548Z" fill={fill} className="svgFill" />
      <path d="M19.3548 32.2606C18.6561 32.2606 18.0728 32.8211 18.0728 33.5331C18.0728 34.245 18.6561 34.8055 19.3548 34.8055H21.8743C22.573 34.8055 23.1562 34.245 23.1562 33.5331C23.1562 32.8211 22.573 32.2606 21.8743 32.2606H19.3548Z" fill={fill} className="svgFill" />
      <path d="M19.3548 35.3896C18.6561 35.3896 18.0728 35.95 18.0728 36.662C18.0728 37.3739 18.6561 37.9344 19.3548 37.9344H21.8743C22.573 37.9344 23.1562 37.3739 23.1562 36.662C23.1562 35.95 22.573 35.3896 21.8743 35.3896H19.3548Z" fill={fill} className="svgFill" />
      <path d="M19.3548 38.5185C18.6561 38.5185 18.0728 39.0789 18.0728 39.7909C18.0728 40.5028 18.6561 41.0633 19.3548 41.0633H21.8743C22.573 41.0633 23.1562 40.5028 23.1562 39.7909C23.1562 39.0789 22.573 38.5185 21.8743 38.5185H19.3548Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1782 26.0028C15.4795 26.0028 14.8962 26.5633 14.8962 27.2752V42.9198C14.8962 43.6317 15.4795 44.1922 16.1782 44.1922H25.051C25.7498 44.1922 26.333 43.6317 26.333 42.9198V27.2752C26.333 26.5633 25.7498 26.0028 25.051 26.0028H16.1782ZM17.4602 41.6474V28.5477H23.7691V41.6474H17.4602Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5765 0C9.12673 0 7.11779 1.96485 7.11779 4.40134V47.4552H6.28198C5.58324 47.4552 5 48.0156 5 48.7276C5 49.4395 5.58324 50 6.28198 50H34.9473C35.646 50 36.2293 49.4395 36.2293 48.7276C36.2293 48.0156 35.646 47.4552 34.9473 47.4552H34.1115V14.3798C34.9067 14.8217 35.4393 15.6595 35.4393 16.6095V35.2651C35.4393 37.0333 36.8961 38.4541 38.6672 38.4541C40.4383 38.4541 41.8951 37.0332 41.8951 35.2651V14.2284C43.6851 13.6872 45 12.0414 45 10.0867V7.97747C45 7.26553 44.4168 6.70505 43.718 6.70505H43.5724V4.40134C43.5724 3.68939 42.9891 3.12891 42.2904 3.12891C41.5917 3.12891 41.0084 3.68939 41.0084 4.40134V6.70505H40.2178V4.40134C40.2178 3.68939 39.6346 3.12891 38.9358 3.12891C38.2371 3.12891 37.6539 3.68939 37.6539 4.40134V6.70505H37.5082C36.8095 6.70505 36.2262 7.26553 36.2262 7.97747V10.0867C36.2262 12.0414 37.5411 13.6872 39.3311 14.2284V35.265C39.3311 35.6106 39.0422 35.9092 38.6672 35.9092C38.2922 35.9092 38.0032 35.6105 38.0032 35.265V16.6094C38.0032 14.2232 36.3401 12.2267 34.1115 11.6637V4.40134C34.1115 1.96485 32.1025 0 29.6528 0H11.5765ZM9.68175 4.40134C9.68175 3.38736 10.5228 2.54485 11.5765 2.54485H29.6528C30.7064 2.54485 31.5475 3.38736 31.5475 4.40134V17.0662H9.68175V4.40134ZM38.7902 10.0867V9.2499H42.436V10.0867C42.436 11.0616 41.6271 11.8724 40.6131 11.8724C39.5991 11.8724 38.7902 11.0617 38.7902 10.0867ZM9.68175 47.4552V19.6111H31.5475V47.4552H9.68175Z" fill={fill} className="svgFill" />
    </svg>
  )
}

EcoStationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EcoStationsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EcoStationsIcon)
