import { connect } from 'react-redux'
import {
  getData,
  getSelector,
  getGraphType,
  getGraphOptions,
  getGraphPercent,
  getGraphYear,
  getSelectedGraphPercentOptions,
  getSelectedGraphYearOptions,
  getFileExportStatus,
  getLoadingTable,
} from '@/store/selectors/lifeTimeReport'
import {
  getTreeData,
  getTelemetry,
} from '@/store/selectors/maps/socketEvents'
import { setSelectedNode } from '@/store/actions/appSettings'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  setGraphType,
  setGraphOptions,
  setNewGraphYearOptions,
  setNewGraphPercentOptions,
} from '@/store/actions/lifeTimeReport'
import {
  requestLiveTimeReportBarData,
} from '@/store/actions/lifeTimeReport/getBar'
import {
  requestLiveTimeReportTable,
} from '@/store/actions/lifeTimeReport/getTable'
import {
  requestLiveTimeReportFile,
} from '@/store/actions/lifeTimeReport/getFile'
import LiveTime from './component'

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  data: getData(state),
  selector: getSelector(state),
  graphType: getGraphType(state),
  graphOptions: getGraphOptions(state),
  graphYear: getGraphYear(state),
  graphPercent: getGraphPercent(state),
  selectedGraphPercentOptions: getSelectedGraphPercentOptions(state),
  selectedGraphYearOptions: getSelectedGraphYearOptions(state),
  node: getSelectedNode(state),
  fileStatus: getFileExportStatus(state),
  telemetry: getTelemetry(state),
  loadingTable: getLoadingTable(state),
})

const mapDispatchToProps = (dispatch) => ({
  setGraphOptions: (payload) => dispatch(setGraphOptions(payload)),
  setGraphType: (payload) => dispatch(setGraphType(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  setNewGraphYearOptions: (payload) => dispatch(setNewGraphYearOptions(payload)),
  setNewGraphPercentOptions: (payload) => dispatch(setNewGraphPercentOptions(payload)),
  getGraphData: (payload) => dispatch(requestLiveTimeReportBarData(payload)),
  getTableData: (payload) => dispatch(requestLiveTimeReportTable(payload)),
  getReportFile: (payload) => dispatch(requestLiveTimeReportFile(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveTime)
