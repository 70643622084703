import { GET_SERVICE_DESK_INFO_WIDGET } from './index'

export const requestGetInfoWidget = (payload) => ({
  type: GET_SERVICE_DESK_INFO_WIDGET.REQUEST,
  payload,
})

export const successGetInfoWidget = (payload) => ({
  type: GET_SERVICE_DESK_INFO_WIDGET.SUCCESS,
  payload,
})

export const errorGetInfoWidget = (payload) => ({
  type: GET_SERVICE_DESK_INFO_WIDGET.ERROR,
  payload,
})
