import { GET_CONSUMPTION_ANALYSIS_GRAPH_DATA } from './index'

export const requestGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.REQUEST,
  payload,
})

export const responseGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.SUCCESS,
  payload,
})
export const errorGraphData = (payload) => ({
  type: GET_CONSUMPTION_ANALYSIS_GRAPH_DATA.ERROR,
  payload,
})
