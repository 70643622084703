import { USER_LOGOUT } from './index'

export const requestUserLogout = () => ({
  type: USER_LOGOUT.REQUEST,
})

export const successUserLogout = () => ({
  type: USER_LOGOUT.SUCCESS,
})

export const errorUserLogout = (error) => ({
  type: USER_LOGOUT.ERROR,
  payload: error,
})
