import {
  DEFAULT_COMMON_TOOLTIP_WIDTH,
  DEFAULT_COMMON_TOOLTIP_HEIGHT,
  DEFAULT_COMMON_TOOLTIP_DELTA,
} from '@/constants/sizes'

export const getCoordsTopRight = ({
  gap,
  elementTop,
  elementLeft,
  elementWidth,
  toolTipHeight = DEFAULT_COMMON_TOOLTIP_HEIGHT,
}) => ({
  tooltipLeft: Math.round(elementLeft + elementWidth / 2),
  tooltipTop: Math.round(elementTop - gap - toolTipHeight),
})
export const getCoordsTopLeft = ({
  gap,
  elementTop,
  elementLeft,
  toolTipWidth = DEFAULT_COMMON_TOOLTIP_WIDTH,
  toolTipHeight = DEFAULT_COMMON_TOOLTIP_HEIGHT,
}) => ({
  tooltipLeft: Math.round(elementLeft - gap - toolTipWidth),
  tooltipTop: Math.round(elementTop - gap - toolTipHeight),
})

export const getCoordsBottomLeft = ({
  gap,
  elementTop,
  elementLeft,
  elementHeight,
  toolTipWidth = DEFAULT_COMMON_TOOLTIP_WIDTH,
}) => ({
  tooltipLeft: Math.round(elementLeft - gap - toolTipWidth),
  tooltipTop: Math.round(elementTop + elementHeight + gap),
})

export const getCoordsBottomRight = ({
  gap,
  elementTop,
  elementLeft,
  elementHeight,
  elementWidth,
}) => ({
  tooltipLeft: Math.round(elementLeft + elementWidth / 2),
  tooltipTop: Math.round(elementTop + elementHeight + gap),
})

export const validateTopRight = ({
  gap,
  frameWidth,
  elementTop,
  elementLeft,
  toolTipWidth = DEFAULT_COMMON_TOOLTIP_WIDTH,
  toolTipHeight = DEFAULT_COMMON_TOOLTIP_HEIGHT,
}) => ((elementTop - (2 * gap) - DEFAULT_COMMON_TOOLTIP_DELTA - toolTipHeight) >= 0)
    && ((elementLeft + frameWidth + (2 * gap) + DEFAULT_COMMON_TOOLTIP_DELTA + toolTipWidth) <= frameWidth)

export const validateTopLeft = ({
  gap,
  elementTop,
  elementLeft,
  toolTipWidth = DEFAULT_COMMON_TOOLTIP_WIDTH,
  toolTipHeight = DEFAULT_COMMON_TOOLTIP_HEIGHT,
}) => ((elementTop - (2 * gap) - DEFAULT_COMMON_TOOLTIP_DELTA - toolTipHeight) >= 0)
    && ((elementLeft - (2 * gap) - DEFAULT_COMMON_TOOLTIP_DELTA - toolTipWidth) <= 0)

export const validateBottomLeft = ({
  gap,
  frameHeight,
  elementTop,
  elementLeft,
  toolTipWidth = DEFAULT_COMMON_TOOLTIP_WIDTH,
  toolTipHeight = DEFAULT_COMMON_TOOLTIP_HEIGHT,
}) => ((elementTop + (2 * gap) + DEFAULT_COMMON_TOOLTIP_DELTA + toolTipHeight) <= frameHeight)
    && ((elementLeft - (2 * gap) - DEFAULT_COMMON_TOOLTIP_DELTA - toolTipWidth) <= 0)

export const validateBottomRight = ({
  gap,
  frameHeight,
  frameWidth,
  elementTop,
  elementLeft,
  elementWidth,
  toolTipWidth = DEFAULT_COMMON_TOOLTIP_WIDTH,
  toolTipHeight = DEFAULT_COMMON_TOOLTIP_HEIGHT,
}) => ((elementTop + (2 * gap) + DEFAULT_COMMON_TOOLTIP_DELTA + toolTipHeight) <= frameHeight)
  && ((elementLeft + elementWidth + (2 * gap) + DEFAULT_COMMON_TOOLTIP_DELTA + toolTipWidth) <= frameWidth)

export const validateTop = ({
  gap,
  frameWidth,
  elementTop,
  elementLeft,
  elementWidth,
  toolTipWidth,
  toolTipHeight,
}) => ((elementTop - (2 * gap) - toolTipHeight) >= 0)
  && (elementLeft - gap + (elementWidth / 2) - (toolTipWidth / 2) >= 0)
  && (elementLeft + elementWidth + gap - (elementWidth / 2) + (toolTipWidth / 2) <= frameWidth)

export const validateBottom = ({
  gap,
  frameHeight,
  frameWidth,
  elementTop,
  elementLeft,
  elementWidth,
  toolTipWidth,
  toolTipHeight,
}) => ((elementTop + (2 * gap) + toolTipHeight) <= frameHeight)
  && (elementLeft - gap + (elementWidth / 2) - (toolTipWidth / 2) >= 0)
  && (elementLeft + elementWidth + gap - (elementWidth / 2) + (toolTipWidth / 2) <= frameWidth)

export const validateLeft = ({
  gap,
  elementLeft,
  toolTipWidth,
}) => (elementLeft - (2 * gap) - toolTipWidth) >= 0

export const validateRight = ({
  gap,
  frameWidth,
  elementLeft,
  elementWidth,
  toolTipWidth,
}) => (elementLeft + elementWidth + (2 * gap) + toolTipWidth) <= frameWidth

export const getCoordsTop = ({
  gap,
  elementTop,
  elementLeft,
  elementWidth,
  toolTipWidth,
  toolTipHeight,
}) => ({
  tooltipLeft: Math.round(elementLeft + elementWidth / 2 - toolTipWidth / 2 + 3),
  tooltipTop: Math.round(elementTop - gap - toolTipHeight),
})

export const getCoordsBottom = ({
  gap,
  elementTop,
  elementLeft,
  elementHeight,
  elementWidth,
  toolTipWidth,
}) => ({
  tooltipLeft: Math.round(elementLeft + elementWidth / 2 - toolTipWidth / 2 + 3),
  tooltipTop: Math.round(elementTop + elementHeight + 1.5 * gap),
})

export const getCoordsLeft = ({
  gap,
  elementTop,
  elementLeft,
  elementHeight,
  toolTipWidth,
  toolTipHeight,
}) => ({
  tooltipLeft: Math.round(elementLeft - gap - toolTipWidth),
  tooltipTop: Math.round(elementTop + (elementHeight / 2 - toolTipHeight / 2)),
})

export const getCoordsRight = ({
  gap,
  elementTop,
  elementLeft,
  elementHeight,
  elementWidth,
  toolTipHeight,
}) => ({
  tooltipLeft: Math.round(elementLeft + elementWidth + gap),
  tooltipTop: Math.round(elementTop + (elementHeight / 2 - toolTipHeight / 2)),
})

export const defaultPriorityList = [
  {
    name: 'top',
    validate: validateTop,
    getCoords: getCoordsTop,
  },
  {
    name: 'bottom',
    validate: validateBottom,
    getCoords: getCoordsBottom,
  },
  {
    name: 'left',
    validate: validateLeft,
    getCoords: getCoordsLeft,
  },
  {
    name: 'right',
    validate: validateRight,
    getCoords: getCoordsRight,
  },
]

export const verticalPriorityList = [
  {
    name: 'bottom',
    validate: validateBottom,
    getCoords: getCoordsBottom,
  },
  {
    name: 'top',
    validate: validateTop,
    getCoords: getCoordsTop,
  },
]

export const horizontalPriorityList = [
  {
    name: 'right',
    validate: validateRight,
    getCoords: getCoordsRight,
  },
  {
    name: 'left',
    validate: validateLeft,
    getCoords: getCoordsLeft,
  },
]
