import asuno from './asuno'
import baseStation from './baseStation'
import controller from './controller'
import light from './lightingFixture'
import multiTariffElectricityMeter from './multiTariffElectricityMeter'
import coldWaterDevice from './coldWaterDevice'
import heatingDevice from './heatingDevice'
import shuo from './shuo'
import groupObject from './groupObject'

export default {
  asuno,
  baseStation,
  controller,
  light,
  multiTariffElectricityMeter,
  shuo,
  coldWaterDevice,
  heatingDevice,
  groupObject,
}
