import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'

export default {
  main: [
    {
      name: <Lang id="widgetsDashboard.outdoorLighting" />,
      selector: 'outdoorLighting',
    },
    {
      name: <Lang id="widgetsDashboard.heatSupply" />,
      selector: 'heatSupply',
      disabled: true,
    },
    {
      name: <Lang id="widgetsDashboard.roadObjects" />,
      selector: 'roadObjects',
      disabled: true,
    },
    {
      name: <Lang id="widgetsDashboard.stopsTotalTransport" />,
      selector: 'publicTransport',
      disabled: true,
    },
  ],
  view: {
    title: <Lang id="widgetsDashboard.alarmRegistry" />,
    icon: WidgetsDashboardIcon.AutomaticFaultReportingIcon,
  },
  total: {
    name: 'Всего алармов',
    selector: 'alarmCount',
  },
  solved: {
    name: 'Исправлено',
    selector: 'serviceableCount',
  },
  graph: {
    totalSelector: 'alarmCount',
    solvedSelector: 'serviceableCount',
  },
}
