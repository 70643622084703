export default (elementPayload) => {
  const {
    base = 0,
    minus = 0,
    plus = 0,
  } = elementPayload
  if (minus) {
    return base
  }
  if (plus) {
    return base + plus
  }
  return base
}
