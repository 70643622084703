import {
  put, takeLatest, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import get from 'lodash/get'
import updateNodeCount from '@/helpers/updateNodeCount'
import { successDeleteGeoZone } from '@/store/actions/installation/deleteGeoZone'
import {
  GEOZONE,
  ROOT,
} from '@/constants/objectTypes'
import getNewPath from '@/helpers/getNewPath'
import request from '@/helpers/axios'
import { DELETE_GEOZONE } from '@/store/actions/installation'
import { generateGetPutGeoZoneCard } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notificationSmall'

function* deleteGeoZoneSaga({ payload }) {
  const {
    setUrlFormValues, formValues, id, parentId,
  } = payload
  const {
    id: treeId,
    path,
    rootId,
    FILTER,
  } = formValues

  try {
    yield request({
      url: generateGetPutGeoZoneCard(id),
      method: 'delete',
    })
    const newPath = getNewPath(path)

    const tree = treeHandlers.trees['installations-tree']
    const node = tree.instance.getNodeById(treeId)
    const parent = tree.instance.getNodeById(parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    tree.handlers.setSelected(parent, true)
    updateNodeCount(tree, newPath, 'remove', node.data.childrenCount + 1)
    tree.handlers.rerender()
    setUrlFormValues({
      id: parentId,
      rootId,
      parentGeoZoneId: parentId,
      parentTreeId: parentId,
      path: newPath,
      type: rootId === parentId ? ROOT : GEOZONE,
      FILTER,
    })
    yield put(successDeleteGeoZone())
  } catch (error) {
    const toast = createNotifications()
    const errorMessage = get(error, 'response.data.errorMessage', null)
    if (errorMessage === 'One.or.more.integrations.contains.asuno.that.should.be.deleted.firstly') {
      toast({
        title: 'ОШИБКА ОПЕРАЦИИ!',
        type: 'error',
        description: 'Одна или несколько интеграций подключена к асу,\nперед удалением отключите их.',
      })
    }
    setUrlFormValues({ FILTER })
    console.log('function*deleteGeoZoneSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(DELETE_GEOZONE.REQUEST, deleteGeoZoneSaga)
}
