export default {
  condition: 'Для продолжения, необходимо подтвердить\n условия ',
  userAgreement: 'пользовательского соглашения',
  acceptCondition: 'Принять условия',
  next: 'Продолжить',
  password: 'Придумайте надежный пароль',
  passwordConfirmation: 'Подтверждение пароля',
  requirements: 'Требования к паролю',
  requirementsText: `• Длина пароля должна быть не менее 8 символов.
• Пароль должен состоять из букв латинского алфавита (A-z), арабских цифр (0-9) и специальных символов (см пункт 4)
• Буквенная часть пароля должна содержать как строчные, так и прописные (заглавные) буквы.
• Пароль должен содержать не менее одного из следующих символов:
. , : ; ? ! * + % - < > @ [ ] { } / _ {} $ #
• Пароль не должен совпадать с логином.
• Пароль не должен являться словарным словом.`,
  accept: 'Принять',
  goToAuthorization: 'Перейти к авторизации',
  submittedText: 'Пароль успешно установлен!',
  signUpButton: 'задать пароль',
}
