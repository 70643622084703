import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const RoadIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 28 28">
      <path d="M22.912 20.111L18.544 6.778C18.389 6.304 18.05 6 17.678 6h-3.05l.076.965c.016.197-.1.368-.248.368h-.912c-.148 0-.264-.171-.248-.368L13.372 6h-3.05c-.372 0-.71.304-.866.778L5.088 20.11c-.29.883.172 1.889.867 1.889h6.15l.323-4.07c.027-.34.241-.597.497-.597h2.15c.256 0 .47.258.497.597l.322 4.07h6.152c.694 0 1.156-1.006.866-1.889zM13.138 8.965a.381.381 0 01.081-.213.22.22 0 01.167-.085h1.228c.128 0 .235.128.249.298l.144 1.816c.023.295-.15.552-.373.552h-1.267c-.223 0-.396-.257-.373-.552l.144-1.816zM14.864 16h-1.728c-.297 0-.528-.343-.497-.737l.158-2c.027-.339.242-.596.497-.596h1.411c.256 0 .47.257.498.596l.158 2c.031.394-.2.737-.497.737z" fill={fill} className="svgFill" />
    </svg>
  )
}

RoadIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

RoadIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(RoadIcon)
