import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_PASSPORT_FILES_URL } from '@/constants/apiRoutes'
import { GET_PASSPORT_FILE } from '@/store/actions/passportization'
import {
  responseGetPassportFile,
  errorGetPassportFile,
} from '@/store/actions/passportization/getPassportFile'
import createNotifications from '@/helpers/notification'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import {
  getData,
} from '@/store/selectors/passportization'

function* getPassportFilesSaga({ payload }) {
  const {
    id,
    getAndDownload = false,
  } = payload
  const toast = createNotifications()
  try {
    const response = yield request({
      url: `${GET_PASSPORT_FILES_URL}?id=${id}`,
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })
    yield put(responseGetPassportFile({ file: response.data }))
    if (getAndDownload) {
      const passportData = yield select(getData)
      downloadFileFromBlob({
        blob: response.data,
        fileName: passportData.file.title,
      })
    }
  } catch (error) {
    yield put(errorGetPassportFile(error))
    if (getAndDownload) {
      toast({
        title: 'Ошибка операции!',
        description: 'Ошибка операции! Повторите попытку позже',
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(GET_PASSPORT_FILE.REQUEST, getPassportFilesSaga)
}
