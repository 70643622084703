import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 48px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  min-height: 48px;
`

export const GroupedContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  padding-right: 10px;
  cursor: pointer;
  ${({ editIcon }) => editIcon && `
    margin-left: 10px;
    border-right: none;
  `}
  ${({ deleteIcon }) => deleteIcon && `
    border-right: none;
    padding-right: 0px;
  `}
`

export const CustomButton = styled.div`
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  justify-content: center;
  padding: 5px 10px;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.light};
  min-height: 28px;
  height: 28px;
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.backgrounds.controls.green
    if (type === 'red') return theme.colors.backgrounds.controls.red
    return 'transparent'
  }};
  ${({ type }) => type === 'green' && `
    margin-right: 10px;
  `}
  ${({ type, theme }) => type && `
    border-radius: 4px;
    box-shadow: 5px 5px 10px ${theme.colors.shadows.widgetPrimary},
          -5px -5px 10px ${theme.colors.shadows.widgetSecondary};
  `}
`

export const Text = styled.div`
  flex: 1;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  letter-spacing: 0.01em;
  width: 114px;
  max-width: 114px;
  margin-right: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  ${({ withoutEditing }) => withoutEditing && `
    margin-left: 10px;
  `}
  ${({ editTitle }) => editTitle && `
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
    max-width: 170px;
  `}
`
