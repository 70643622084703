import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const Placemark2Icon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="155" height="63" viewBox="0 0 155 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M35.6598 46.1523C45.1586 42.9966 52 34.1332 52 23.6923C52 10.6074 41.2548 0 28 0C14.7452 0 4 10.6074 4 23.6923C4 34.5023 11.3337 43.6214 21.3576 46.4655L28.416 55L35.6598 46.1523Z" fill={fill} className="svgFill" />
        <path d="M4 24C4 10.7452 14.7452 0 28 0H127C140.255 0 151 10.7452 151 24C151 37.2548 140.255 48 127 48H28C14.7452 48 4 37.2548 4 24Z" fill={fill} className="svgFill" />
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="155" height="63" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.176471 0 0 0 0 0.254902 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

Placemark2Icon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

Placemark2Icon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(Placemark2Icon)
