import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const PopupHeaderIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="400" height="93" viewBox="0 0 400 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <path d="M200 80C222.091 80 240 62.0914 240 40C240 17.9086 222.091 0 200 0C177.909 0 160 17.9086 160 40C160 62.0914 177.909 80 200 80Z" fill={fill} className="svgFill"/> */}
      <path d="M249.842 43.9994C247.805 69.7437 226.268 90 200 90C173.732 90 152.195 69.7437 150.158 43.9994C149.983 41.7972 148.209 40 146 40H4C1.79086 40 0 41.7909 0 44V93H400V44C400 41.7909 398.209 40 396 40H254C251.791 40 250.017 41.7972 249.842 43.9994Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

PopupHeaderIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PopupHeaderIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PopupHeaderIcon)
