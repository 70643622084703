import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EditIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M17.095 9.00261C16.8564 9.00261 16.6276 9.0974 16.4588 9.26614C16.2901 9.43487 16.1953 9.66372 16.1953 9.90235V15.3008C16.1953 15.5394 16.1005 15.7683 15.9318 15.937C15.763 16.1057 15.5342 16.2005 15.2956 16.2005H2.69922C2.46059 16.2005 2.23174 16.1057 2.06301 15.937C1.89427 15.7683 1.79948 15.5394 1.79948 15.3008V2.70443C1.79948 2.46581 1.89427 2.23696 2.06301 2.06822C2.23174 1.89949 2.46059 1.8047 2.69922 1.8047H8.09765C8.33628 1.8047 8.56513 1.7099 8.73386 1.54117C8.9026 1.37243 8.99739 1.14358 8.99739 0.904956C8.99739 0.66633 8.9026 0.437478 8.73386 0.268744C8.56513 0.100011 8.33628 0.00521699 8.09765 0.00521699H2.69922C1.98334 0.00521699 1.29678 0.289598 0.790583 0.7958C0.284381 1.302 0 1.98856 0 2.70443V15.3008C0 16.0167 0.284381 16.7032 0.790583 17.2094C1.29678 17.7156 1.98334 18 2.69922 18H15.2956C16.0114 18 16.698 17.7156 17.2042 17.2094C17.7104 16.7032 17.9948 16.0167 17.9948 15.3008V9.90235C17.9948 9.66372 17.9 9.43487 17.7313 9.26614C17.5625 9.0974 17.3337 9.00261 17.095 9.00261ZM3.59896 9.68641V13.5013C3.59896 13.7399 3.69375 13.9688 3.86248 14.1375C4.03122 14.3063 4.26007 14.401 4.4987 14.401H8.31359C8.432 14.4017 8.54938 14.379 8.65901 14.3343C8.76863 14.2895 8.86833 14.2235 8.9524 14.1401L15.1786 7.90493L17.7339 5.40365C17.8182 5.32001 17.8851 5.2205 17.9308 5.11086C17.9765 5.00121 18 4.88361 18 4.76484C18 4.64606 17.9765 4.52846 17.9308 4.41882C17.8851 4.30918 17.8182 4.20966 17.7339 4.12602L13.919 0.266141C13.8353 0.18181 13.7358 0.114875 13.6262 0.0691962C13.5165 0.0235176 13.3989 0 13.2802 0C13.1614 0 13.0438 0.0235176 12.9341 0.0691962C12.8245 0.114875 12.725 0.18181 12.6413 0.266141L10.1041 2.8124L3.85988 9.0476C3.77649 9.13167 3.71052 9.23137 3.66574 9.34099C3.62097 9.45062 3.59827 9.568 3.59896 9.68641ZM13.2802 2.17359L15.8264 4.71985L14.5488 5.99748L12.0025 3.45122L13.2802 2.17359ZM5.39843 10.0553L10.7339 4.71985L13.2802 7.26611L7.9447 12.6016H5.39843V10.0553Z" />
    </svg>
  )
}

EditIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EditIcon.defaultProps = {
  color: '#1E2127',
  onClick: noop,
}

export default memoWithName(EditIcon)
