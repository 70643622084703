import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { FORGOT_PASSWORD } from '@/constants/apiRoutes'
import {
  USER_FORGOT_PASSWORD,
} from '@/store/actions/user'
import {
  EMAIL,
} from '@/constants/forms/signIn'
import {
  successUserForgotPassword,
  errorUserForgotPassword,
} from '@/store/actions/user/forgotPassword'

const ERRORS = {
  'resource_not_found.user_email': 'Пользователь с данным логином не зарегистрирован в системе',
}

function* forgotPassword({ payload }) {
  const { form, values } = payload
  try {
    const response = yield request({
      url: FORGOT_PASSWORD,
      method: 'post',
      body: values[EMAIL],
      isSecure: false,
    })

    yield put(successUserForgotPassword(response.data))
  } catch (error) {
    form.setSubmitting(false)
    form.setErrors({
      [EMAIL]: ERRORS[error.response.data.errorCode],
    })
    yield put(errorUserForgotPassword(error))
  }
}

export default function* root() {
  yield takeLatest(USER_FORGOT_PASSWORD.REQUEST, forgotPassword)
}
