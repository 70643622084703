import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import appSettingsReducer from './appSettings'
import userSettingsReducer from './userSettings'
import userReducer from './user'
import mapsReducer from './maps'
import socketReducer from './socket'
import integrationsReducer from './integrations'
import dictionariesReducer from './dictionaries'
import customWidgetsDataReducer from './customWidgetsData'
import usersReducer from './users'
import lifeTimeReportReducer from './lifeTimeReport'
import consumptionAnalysisReducer from './consumptionAnalysis'
import alarmManagerReducer from './alarmManager'
import analyticsAlarmsReducer from './analyticsAlarms'
import faultAnalyticsReducer from './faultAnalytics'
import actionLogReducer from './actionLog'
import passportizationReducer from './passportization'
import serviceDeskMyCityReducer from './serviceDeskMyCity'
import tyumenOurHomeReducer from './tyumenOurHome'
import widgetDashboardReducer from './widgetDashboard'
import installationReducer from './installation'
import reportManagerReducer from './reportManager'
import objectControllStatisticReducer from './objectControllStatistic'

export const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  appSettings: appSettingsReducer,
  userSettings: userSettingsReducer,
  dictionaries: dictionariesReducer,
  customWidgetsData: customWidgetsDataReducer,
  user: userReducer,
  maps: mapsReducer,
  socket: socketReducer,
  integrations: integrationsReducer,
  users: usersReducer,
  lifeTimeReport: lifeTimeReportReducer,
  consumptionAnalysis: consumptionAnalysisReducer,
  alarmManager: alarmManagerReducer,
  analyticsAlarms: analyticsAlarmsReducer,
  faultAnalytics: faultAnalyticsReducer,
  actionLog: actionLogReducer,
  passport: passportizationReducer,
  serviceDeskMyCity: serviceDeskMyCityReducer,
  tyumenOurHome: tyumenOurHomeReducer,
  widgetDashboard: widgetDashboardReducer,
  installation: installationReducer,
  reportManager: reportManagerReducer,
  objectControllStatistic: objectControllStatisticReducer,
})

export const getState = (state) => state
