import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import GroupOfUsersForm from '@/forms/GroupOfUsersForm'
import TenantAccessSettingsForm from '@/forms/TenantAccessSettingsForm'
import Loader from '@/components/blocks/Loader'
import UsersApplicationForm from '@/forms/UsersApplicationForm'
import useAccessOptions from '@/hooks/useAccessOptions'
import { DEBOUNCE_DELAY_MEDIUM } from '@/constants/time'
import { USER_COLAPSE_BLOCK_TITLE_CHAR_LIMIT } from '@/constants/users'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import UserFormHeader from '@/components/blocks/Header'
import {
  LoaderContainer,
  MainContainer,
} from '../../styles'
import {
  Container,
  FormWrapper,
  StyledTooltip,
  InfoContainer,
} from './styles'

const CreateGroupOfUsers = ({
  edit,
  closeForm,
  zoneProcessOptions,
  submitFormGroup,
  deleteGroup,
  selectedTenant,
  selectedUsersGroup,
  userRole,
  actionsDisabled,
  systems,
  setFormIsModifying,
  isFormModifying,
  applications,
  serviceDeskStatus,
  setTenantServiceDesk,
}) => {
  const [groupFromProps, setGroupFromProps] = useState({})
  const {
    selectedAccess,
    setSelectedAccess,
    setNotification,
    applicationAccess,
    setApplicationAccess,
    applicationModelsAccess,
    setApplicationModelsAccess,
  } = useAccessOptions(selectedUsersGroup)

  const chopDownTitleWidthTooltip = (name) => (
    <>
      {((name || '').length > USER_COLAPSE_BLOCK_TITLE_CHAR_LIMIT)
        ? (
          <StyledTooltip title={name}>
            {(name || '').substr(0, USER_COLAPSE_BLOCK_TITLE_CHAR_LIMIT)}
            ...
          </StyledTooltip>
        )
        : (
          <>
            {name}
          </>
        )}
    </>
  )

  const tenantServiceDeskStatus = useMemo(() => {
    if (selectedTenant.permissions) {
      const serviceDeskSettings = selectedTenant.permissions.applications.find(
        (application) => application.code === SERVICE_DESK,
      )
      return serviceDeskSettings && JSON.parse(serviceDeskSettings.settings)
    }
    return null
  }, [selectedTenant.permissions])

  const sortedSelectedAccess = useMemo(() => {
    if (selectedAccess) {
      const sortedAccess = selectedAccess.map((integrations) => (
        integrations.children.map((integration) => ({
          id: integration.checkBoxValue === true ? integration.id : null,
        }))
      ))
      return [].concat(...sortedAccess).filter((integration) => integration.id !== null)
    }
    return []
  }, [selectedAccess])

  const deleteGroupHandler = () => {
    closeForm()
    deleteGroup({
      tenantId: selectedUsersGroup.tenantId,
      groupId: selectedUsersGroup.id,
      selectedUsersGroup,
    })
  }

  const deleteGroupDebounceHandler = debounce(() => {
    deleteGroupHandler()
  }, DEBOUNCE_DELAY_MEDIUM)

  const serviceDeskId = useMemo(() => (
    applications.find((application) => application.code === SERVICE_DESK).id
  ), [applications])

  const sortedApplications = useCallback((integrations) => (
    integrations.map((integrationId) => {
      if (serviceDeskId === integrationId) {
        return {
          id: integrationId,
          settings: JSON.stringify(serviceDeskStatus),
        }
      }
      return { id: integrationId }
    })
  ), [serviceDeskId, serviceDeskStatus])

  const createTenantGroupHandler = () => {
    submitFormGroup({
      id: selectedTenant.id,
      groupId: selectedUsersGroup.id,
      tenantId: selectedTenant.id,
      groupFrom: isEmpty(groupFromProps)
        ? { values: selectedUsersGroup }
        : groupFromProps,
      permissions: {
        applications: sortedApplications(applicationAccess),
        applicationModules: sortedApplications(applicationModelsAccess),
        integrations: sortedSelectedAccess,
      },
    })
    setNotification(null)
  }

  const createTenantGroupDebounceHandler = debounce(() => {
    createTenantGroupHandler()
  }, DEBOUNCE_DELAY_MEDIUM)

  const initialAccessSettings = useMemo(() => {
    if (isFormModifying && selectedUsersGroup.permissions) {
      const sortedInitialValues = uniqBy(selectedUsersGroup.permissions.integrations, 'type').map((integration) => integration.type)
      const cityProcessId = zoneProcessOptions.filter((options) => (
        options.integrationTypes.some((integrationType) => (
          sortedInitialValues.map((initialValue) => (integrationType.includes(initialValue)))
            .includes(true)
        ))
      ))
      const allIntegrations = selectedUsersGroup.permissions.integrations.map(
        (integration) => integration.id,
      )
      const integrationsWithChildren = cityProcessId.map((integration) => (
        integration.integrationTypes.map((type) => ({
          ...systems[type],
          toggled: true,
          children: systems[type] && systems[type].children.map((system) => ({
            ...system,
            type: 'checkBox',
            checkBoxValue: allIntegrations.includes(system.id),
            dateOfSynchronization: null,
            status: null,
          })),
        }))
          .filter((integrationType) => integrationType.children !== undefined)
      ))
      return ([].concat(...integrationsWithChildren).filter((integration) => (
        integration.children.length > 0
      )))
    }
    return []
  }, [isFormModifying, selectedUsersGroup.permissions, systems, zoneProcessOptions])

  const groupZoneProcessOptions = useMemo(() => {
    let newZoneProcessOptions = zoneProcessOptions
    if (isFormModifying && selectedTenant.permissions) {
      const sortedInitialValues = uniqBy(selectedTenant.permissions.integrations, 'type').map((integration) => integration.type)
      newZoneProcessOptions = zoneProcessOptions.filter((options) => (
        options.integrationTypes.some((integrationType) => (
          sortedInitialValues.map((initialValue) => (integrationType.includes(initialValue)))
            .includes(true)
        ))
      )).map((zoneProcess) => ({
        ...zoneProcess,
        integrationTypes: zoneProcess.integrationTypes.filter((integrationType) => (
          sortedInitialValues.includes(integrationType)
        )),
      }))
    }
    return newZoneProcessOptions
  }, [isFormModifying, selectedTenant.permissions, zoneProcessOptions])

  const renderAccessForm = useMemo(() => (
    <FormWrapper>
      <TenantAccessSettingsForm
        initialValues={(
            selectedUsersGroup.permissions && selectedUsersGroup.permissions.integrations
          )}
        isFormModifying={isFormModifying}
        editMode={edit}
        initialAccessSettings={initialAccessSettings}
        saveFormProps={setSelectedAccess}
        selectedAccess={selectedAccess}
        zoneProcessOptions={groupZoneProcessOptions}
        allProcesses={zoneProcessOptions}
        disabled={!userRole.isAdmin}
        systems={systems}
      />
    </FormWrapper>
  ),
  [
    zoneProcessOptions,
    setSelectedAccess,
    userRole,
    systems,
    selectedAccess,
    initialAccessSettings,
    isFormModifying,
    groupZoneProcessOptions,
    selectedUsersGroup.permissions,
    edit,
  ])

  const renderCreateGroupForm = useMemo(() => (
    <GroupOfUsersForm
      key={JSON.stringify(selectedUsersGroup)}
      edit={edit}
      initialValues={edit ? selectedUsersGroup : null}
      saveFormProps={setGroupFromProps}
      onSubmitForm={submitFormGroup}
      disabled={!userRole.isAdmin}
      isFormModifying={isFormModifying}
    />
  ),
  [edit, setGroupFromProps, submitFormGroup, selectedUsersGroup, userRole, isFormModifying])

  const isFormDirty = useMemo(() => {
    let initialApplications = []
    let initialApplicationsModels = []
    const sortedAccessSettings = initialAccessSettings.map((integrations) => (
      integrations.children.map((integration) => ({
        id: integration.checkBoxValue === true ? integration.id : null,
      }))
    ))
    const integrations = [].concat(...sortedAccessSettings).filter(
      (integration) => integration.id !== null,
    )
    if (selectedUsersGroup.permissions) {
      initialApplications = selectedUsersGroup.permissions.applications.map((application) => (
        application.id
      ))
      initialApplicationsModels = selectedUsersGroup.permissions.applicationModules.map(
        (application) => application.id,
      )
    }
    return (
      !isEqual(sortedSelectedAccess.sort(), integrations.sort())
      || groupFromProps.dirty
      || !isEqual(applicationAccess.sort(), initialApplications.sort())
      || !isEqual(applicationModelsAccess.sort(), initialApplicationsModels.sort())
    )
  },
  [
    applicationAccess,
    applicationModelsAccess,
    groupFromProps.dirty,
    initialAccessSettings,
    selectedUsersGroup.permissions,
    sortedSelectedAccess,
  ])

  const renderErrorText = useMemo(() => {
    if (!isFormModifying) {
      return (
        <>
          <Lang id="usersPage.popup.deleteGroup" />
          {`${selectedUsersGroup.name}?`}
        </>
      )
    }
    if (edit) {
      return <Lang id="usersPage.popup.cancelChangeGroup" />
    }
    if (!edit) {
      return <Lang id="usersPage.popup.cancelGroup" />
    }
  }, [edit, isFormModifying, selectedUsersGroup.name])

  const renderUsersApplicationForm = useMemo(() => (
    isFormModifying && (
      <UsersApplicationForm
        userRole={userRole}
        isFormModifying={isFormModifying}
        selectedTenant={selectedUsersGroup}
        applications={selectedTenant.permissions && selectedTenant.permissions.applications}
        applicationsModels={(
          selectedTenant.permissions && selectedTenant.permissions.applicationModules
        )}
        applicationAccess={applicationAccess}
        setApplicationAccess={setApplicationAccess}
        applicationModelsAccess={applicationModelsAccess}
        setApplicationModelsAccess={setApplicationModelsAccess}
        tenantServiceDeskStatus={tenantServiceDeskStatus}
        serviceDeskStatus={serviceDeskStatus}
        setTenantServiceDesk={setTenantServiceDesk}
        editMode={edit}
      />
    )
  ),
  [
    isFormModifying,
    userRole,
    selectedUsersGroup,
    selectedTenant.permissions,
    applicationAccess,
    setApplicationAccess,
    applicationModelsAccess,
    setApplicationModelsAccess,
    tenantServiceDeskStatus,
    serviceDeskStatus,
    setTenantServiceDesk,
    edit,
  ])

  const serviceDeskCondition = useMemo(() => {
    const serviceDeskAdded = applicationAccess.includes(serviceDeskId)
    if (serviceDeskAdded) {
      return !isEmpty(serviceDeskStatus)
    }
    return true
  }, [applicationAccess, serviceDeskId, serviceDeskStatus])

  const handleCloseForm = useCallback(() => {
    setTenantServiceDesk([])
    closeForm()
  }, [closeForm, setTenantServiceDesk])

  return (
    <Container editMode={!isFormModifying}>
      <InfoContainer>
        <UserFormHeader
          editTitle={!edit ? <Lang id="usersPage.formHeader.creatingGroup" /> : <Lang id="usersPage.formHeader.changingGroup" />}
          editMode={isFormModifying}
          deleteForm={deleteGroupDebounceHandler}
          saveForm={createTenantGroupDebounceHandler}
          closeForm={handleCloseForm}
          withoutEditing={!userRole.isAdmin}
          withoutDeleting={!userRole.isAdmin}
          title={chopDownTitleWidthTooltip(selectedUsersGroup.name)}
          setFormIsModifying={setFormIsModifying}
          popupSettings={{
            error: {
              title: <Lang id="usersPage.popup.attention" />,
              message: renderErrorText,
            },
            warning: {
              title: <Lang id="usersPage.popup.impossibleExecute" />,
              message: <Lang id="usersPage.popup.saveGroup" />,
            },
          }}
          conditionsForAdding={
            groupFromProps
            && groupFromProps.isValid
            && (edit || groupFromProps.dirty)
            && isFormModifying
            && !isEmpty(applicationAccess)
            && !isEmpty(sortedSelectedAccess)
            && (!edit || isFormDirty)
            && serviceDeskCondition
          }
        />
        {actionsDisabled && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {Object.keys(selectedUsersGroup).length === 0 && edit
          ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )
          : (
            <MainContainer actionsDisabled={actionsDisabled}>
              <FormWrapper layer={1}>
                {renderCreateGroupForm}
              </FormWrapper>
              {renderAccessForm}
            </MainContainer>
          )}
      </InfoContainer>
      {renderUsersApplicationForm}
    </Container>
  )
}

CreateGroupOfUsers.propTypes = {
  selectedUsersGroup: pt.objectOf(pt.object),
  selectedTenant: pt.objectOf(pt.object),
  zoneProcessOptions: pt.objectOf(pt.object).isRequired,
  submitFormGroup: pt.func,
  deleteGroup: pt.func,
  closeForm: pt.func,
  edit: pt.bool,
  userRole: pt.shape({
    isAdmin: pt.bool,
  }).isRequired,
  integrationOptions: pt.arrayOf(pt.object),
  systems: pt.arrayOf(pt.object),
}
CreateGroupOfUsers.defaultProps = {
  selectedUsersGroup: {},
  selectedTenant: {},
  integrationOptions: [],
  systems: [],
  edit: false,
  deleteGroup: noop,
  submitFormGroup: noop,
  closeForm: noop,
}

export default React.memo(CreateGroupOfUsers)
