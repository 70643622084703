import { GET_OBJECT_VALIDATION_SCHEME } from './index'


export const requestGetObjectValidationScheme = (payload) => ({
  type: GET_OBJECT_VALIDATION_SCHEME.REQUEST,
  payload,
})

export const successGetObjectValidationScheme = (payload) => ({
  type: GET_OBJECT_VALIDATION_SCHEME.SUCCESS,
  payload,
})

export const errorGetObjectValidationScheme = (payload) => ({
  type: GET_OBJECT_VALIDATION_SCHEME.ERROR,
  payload,
})
