import styled from 'styled-components'

export const PopupContainer = styled.div`
  padding: 20px 25px;
  background: ${({ theme }) => theme.colors.backgrounds.dark}; 
  border-radius: 4px;
  width: fit-content;
  opacity: 0;
  box-shadow: 2px 3px 15px rgba(61, 61, 61, 0.3);
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ shadow, theme }) => shadow && `
    box-shadow: 2px 3px 15px ${theme.colors.shadows.widget};
  `};
  position: absolute;
  top: 0px;
  right: -140px;
  z-index: -11;
  transition: 0.4s;
  pointer-events: none;
  ${({ opened }) => opened && `
    top: calc(100% + 25px);
    opacity: 1;
    pointer-events: auto;
  `}
`
export const Container = styled.div`
  position: relative;
  &::before{
    content: '';
    opacity: 0;
    position: absolute;
    width: 15px;
    height: 10px;
    left: 50%;
    margin: 0;
    padding: 0;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    top: 0px;
    z-index: -10;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    background: ${({ theme }) => theme.colors.backgrounds.dark};
    ${({ opened }) => opened && `
      top: calc(100% + 22px);
      opacity: 1;
    `}
  }
`

export const PopupWrapper = styled.div`
  position: relative;
  width: fit-content;
`
