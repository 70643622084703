import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ObjectsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 18C7.1663 18 3 10.9191 3 6.7629C3 2.60662 5.6863 0 9 0C12.3137 0 15 2.75021 15 6.7629C15 10.7755 10.8337 18 9 18ZM9.4496 15.5897C10.0007 14.9607 10.6223 14.0408 11.2036 12.9852C12.4574 10.7083 13.2857 8.2552 13.2857 6.7629C13.2857 3.82364 11.4379 1.8 9 1.8C6.5087 1.8 4.71429 3.73553 4.71429 6.7629C4.71429 8.3195 5.5374 10.7705 6.7935 13.0337C7.3714 14.0749 7.9909 14.9809 8.5416 15.5998C8.7086 15.7875 8.862 15.9404 8.9942 16.0545C9.127 15.9379 9.2814 15.7816 9.4496 15.5897Z" fill={fill} className="svgFill" />
      <path d="M11 6C11 7.1046 10.1046 8 9 8C7.8954 8 7 7.1046 7 6C7 4.89543 7.8954 4 9 4C10.1046 4 11 4.89543 11 6Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ObjectsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ObjectsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ObjectsIcon)
