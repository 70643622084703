import React from 'react'
import MapsIcons from '@/components/icons/maps'
import generateWidget from './generateWidget'

export const TRASH_STATUS = generateWidget({
  config: {
    id: 'TRASH_STATUS',
    title: 'Статус объекта:',
    type: 'status',
    timeSelector: 'timestamp',
  },
})

export const LIGHT_MESH = generateWidget({
  config: {
    id: 'LIGHT_MESH',
    title: 'Уровень диммирования',
    minValue: 0,
    maxValue: 100,
    units: '%',
    percentValue: true,
  },
  selectorTemplate: 'illuminanceLevel',
})

export const TRASH = generateWidget({
  config: {
    id: 'TRASH',
    type: 'trash',
  },
  mockValue: {
    temperature: 5,
    fillingLevel: 54,
  },
})

export const LAST_DEVASTATION_SMALL = generateWidget({
  config: {
    id: 'LAST_DEVASTATION_SMALL',
    title: 'Последнее опустошение',
    type: 'smallWidget',
  },
})

export const TYPE_OF_WASTE = generateWidget({
  config: {
    id: 'TYPE_OF_WASTE',
    title: 'Вид отходов',
    type: 'smallWidget',
    units: 'string',
    icon: <MapsIcons.TypeOfWasteIcon />,
  },
  mockValue: 'organic',
})

export const LAST_CLEANING = generateWidget({
  config: {
    id: 'LAST_CLEANING',
    title: 'Последняя уборка',
    type: 'smallWidget',
  },
})

export const NEXT_CLEANING = generateWidget({
  config: {
    id: 'NEXT_CLEANING',
    title: 'Следующая уборка',
    type: 'smallWidget',
  },
})

export const LAST_DEVASTATION_BIG = generateWidget({
  config: {
    id: 'LAST_DEVASTATION_BIG',
    title: 'Последнее опустошение',
    units: 'ч.',
  },
})

export const LIGHT_MESH_STATUS = generateWidget({
  config: {
    id: 'TRASH_STATUS',
    title: 'Статус объекта:',
    type: 'status',
    timeSelector: 'timestamp',
    mode: true,
  },
})

export const LIGHT_LEVEL_SMALL = generateWidget({
  config: {
    id: 'LIGHT_LEVEL_SMALL',
    title: 'Уровень диммирования',
    type: 'smallWidget',
    icon: <MapsIcons.LightLevelIcon />,
    units: '%',
  },
  selectorTemplate: 'illuminanceLevel',
})

export const VOLTAGE_SMALL = generateWidget({
  config: {
    id: 'VOLTAGE_SMALL',
    title: 'Напряжение',
    type: 'smallWidget',
    icon: <MapsIcons.VoltageIcon />,
    units: 'В',
  },
  selectorTemplate: 'phase{number}Voltage',
})

export const BIG_LIGHT = generateWidget({
  config: {
    id: 'BIG_LIGHT',
    type: 'bigLight',
  },
})

export const LAST_INCLUSION = generateWidget({
  config: {
    id: 'LAST_INCLUSION',
    title: 'Последнее включение',
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateLastSwitchingOn',
})

export const LAST_SHUTDOWN = generateWidget({
  config: {
    id: 'LAST_SHUTDOWN',
    title: 'Последнее выключение',
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateLastSwitchingOff',
})

export const WORKING_MODE = generateWidget({
  config: {
    id: 'WORKING_MODE',
    title: 'Доступные режимы',
    type: 'smallWidget',
    units: 'text',
    condition: {
      semiautomatic: 'Полуавтоматический',
    },
  },
  selectorTemplate: 'workingMode',
})

export const LAST_UPDATE = generateWidget({
  config: {
    id: 'LAST_UPDATE',
    title: 'Последнее обновление',
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateModified',
})

export const ILLUMINANCE = generateWidget({
  config: {
    id: 'ILLUMINANCE',
    title: 'Освещенность',
    units: 'Лк',
  },
  selectorTemplate: 'illuminance',
})

export const LIGHT_LOCATION = generateWidget({
  config: {
    id: 'LIGHT_LOCATION',
    title: 'Категория местоположения',
    type: 'smallWidget',
    units: 'text',
    condition: {
      sidewalk: 'тротуар',
    },
  },
  selectorTemplate: 'locationCategor',
})

export const ACTIVE_POWER = generateWidget({
  config: {
    id: 'ACTIVE_POWER',
    title: 'Потребляемая мощность',
    units: 'Вт',
    percentValue: true,
  },
  selectorTemplate: 'activePower',
})
