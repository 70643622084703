import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DownIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.68484 0.487793H8.83176C10.3425 0.487793 11.2636 1.81502 10.4629 2.83823L7.3894 6.76576C6.63604 7.72847 4.88056 7.72847 4.12721 6.76576L1.05375 2.83823C0.253046 1.81502 1.17409 0.487793 2.68484 0.487793Z" fill={fill} className="svgFill" />
    </svg>
  )
}

DownIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DownIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DownIcon)
