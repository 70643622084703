import React from 'react'
import pt from 'prop-types'
import cookie from 'react-cookies'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'
import { getUserData } from '@/store/selectors/appSettings'
import { DASHBOARD_URL } from '@/constants/routes'
import createNotifications from '@/helpers/notification'
import {
  isSuperAdmin,
  isAdmin,
  isUser,
} from '@/helpers/userPermissions'

const withPermissions = (Component, applicationId) => {
  const ComponentWithAuthorization = (props) => {
    const toast = createNotifications()
    const {
      user: { applications, role },
    } = props
    if (isSuperAdmin(role) || (applications && applications.includes(applicationId))) {
      const userRole = {
        isSuperAdmin: isSuperAdmin(role),
        isAdmin: isAdmin(role),
        isUser: isUser(role),
      }
      return <Component {...props} userRole={userRole} />
    }

    if (cookie.load(SLS24_ACCESS_TOKEN)) {
      toast({
        title: 'Ошибка доступа!',
        description: (
          <>
            Недостаточно прав для просмотра страницы.
            <br />
            <strong>Обратитесь к администартору</strong>
          </>
        ),
        type: 'error',
      })
      return (<Redirect to={DASHBOARD_URL} />)
    }
    return (<></>)
  }

  ComponentWithAuthorization.propTypes = {
    user: pt.shape({
      role: pt.string,
      applications: pt.arrayOf(pt.number),
    }).isRequired,
  }

  return connect(
    (state) => ({
      user: getUserData(state),
    }),
    () => ({}),
  )(ComponentWithAuthorization)
}

export default withPermissions
