import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ScheduleIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26 38C32.6274 38 38 32.6274 38 26C38 19.3726 32.6274 14 26 14C19.3726 14 14 19.3726 14 26C14 32.6274 19.3726 38 26 38Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" />
      <path d="M26.5 25.5V23C26.5 22.8674 26.4473 22.7402 26.3536 22.6464C26.2598 22.5527 26.1326 22.5 26 22.5C25.8674 22.5 25.7402 22.5527 25.6464 22.6464C25.5527 22.7402 25.5 22.8674 25.5 23V26C25.5 26.1326 25.5527 26.2598 25.6464 26.3536C25.7402 26.4473 25.8674 26.5 26 26.5H28.5C28.6326 26.5 28.7598 26.4473 28.8536 26.3536C28.9473 26.2598 29 26.1326 29 26C29 25.8674 28.9473 25.7402 28.8536 25.6464C28.7598 25.5527 28.6326 25.5 28.5 25.5H26.5ZM26 31C23.2385 31 21 28.7615 21 26C21 23.2385 23.2385 21 26 21C28.7615 21 31 23.2385 31 26C31 28.7615 28.7615 31 26 31Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ScheduleIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ScheduleIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ScheduleIcon)
