import {
  TEXT
} from '@/constants/semanticNames'

export default [
  {
    selector: 'LOCATION.PROJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  // TODO: delete affter adding new config
  {
    selector: 'LOCATION.LATITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  {
    selector: 'LOCATION.LONGITUDE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'location',
  },
  // TODO: delete affter adding new config
  {
    selector: 'objectService.inspectionDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.bypassDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.maintenanceDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.repairDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
  {
    selector: 'objectService.guaranteeExpirationDate',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'objectService',
  },
]
