import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GraphIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M18 6H14V18H18V6Z" />
      <path className="svgFill" fill={fill} d="M11 0H7V18H11V0Z" />
      <path className="svgFill" fill={fill} d="M4 9H0V18H4V9Z" />
    </svg>
  )
}

GraphIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GraphIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GraphIcon)
