import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const TypeOfWasteIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.48883 9.20706C6.60245 9.69914 6.06263 10.0731 5.64269 9.81062L4.21056 8.91572L2.42227 11.7775C1.95525 12.5248 2.49504 13.4995 3.37578 13.4995H5.20348C5.43646 13.4995 5.62535 13.6884 5.62535 13.9214V15.3277C5.62535 15.5606 5.43646 15.7495 5.20348 15.7495H3.3794C0.730937 15.7495 -0.885126 12.8299 0.517398 10.5858L2.30411 7.72448L0.872371 6.82983C0.447648 6.5645 0.55716 5.91714 1.0439 5.80474L4.91942 4.91001C5.22215 4.84012 5.52414 5.02888 5.59403 5.33154L6.48883 9.20706ZM9.95386 2.77645L11.4054 5.09944L9.97316 5.99441C9.54949 6.25907 9.65676 6.90682 10.1447 7.0195L14.0202 7.91422C14.3251 7.9845 14.6254 7.79335 14.6948 7.4927L15.5896 3.61718C15.7019 3.13048 15.168 2.74815 14.7434 3.01362L13.313 3.90743L11.8624 1.58423C10.5433 -0.526302 7.46048 -0.531154 6.13835 1.58423L5.50684 2.59554C5.38344 2.79312 5.44356 3.05331 5.64114 3.17678L6.8336 3.92191C7.03118 4.04538 7.29148 3.9853 7.41495 3.78772L8.04639 2.77723C8.4933 2.06211 9.522 2.08549 9.95386 2.77645ZM17.4831 10.5858L16.5158 9.03662C16.3924 8.83894 16.132 8.77878 15.9344 8.90229L14.744 9.64616C14.5464 9.76963 14.4863 10.0299 14.6097 10.2275L15.5788 11.7783C16.0449 12.524 15.5065 13.4996 14.6248 13.4996H11.2504V11.8127C11.2504 11.3131 10.6442 11.061 10.2901 11.415L7.47763 14.2268C7.25794 14.4465 7.25794 14.8027 7.47763 15.0223L10.2901 17.8344C10.6418 18.1861 11.2504 17.9398 11.2504 17.4366V15.7495H14.6211C17.2675 15.7495 18.8868 12.8317 17.4831 10.5858Z" fill={fill} className="svgFill" />
    </svg>
  )
}

TypeOfWasteIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

TypeOfWasteIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(TypeOfWasteIcon)
