import {
  takeLatest, put, all, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment'
import { SERVICE_DESK_GET_APPEALS_URL } from '@/constants/apiRoutes'
import { GET_SERVICE_DESK_INFO_CARD } from '@/store/actions/serviceDeskMyCity'
import { successGetInfoServiceDeskCard } from '@/store/actions/serviceDeskMyCity/getInfoCard'
import { getUserApplications } from '@/store/selectors/appSettings'
import { convertDate } from '@/helpers/serviceDeskHelpers'
import serviceDeskInfoCard from '@/constants/serviceDeskInfoCard'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import {
  APPEALS_BLOCK,
  APPEAL_CHANNEL,
  APPEAL_CHANNEL_BLOCK,
  OBJECT_TYPE,
  OBJECT_TYPE_BLOCK,
  PROJECT,
  PROJECT_BLOCK,
  TOWN,
  TOWN_BLOCK,
  SVT_STATUS,
  SVT_STATUS_BLOCK,
  APPEAL_CATEGORY,
  APPEAL_CATEGORY_BLOCK,
  WORK,
  WORK_BLOCK,
  OBJECTS_BLOCK,
  OBJECT,
  TYPE_OF_WORK,
  TYPE_OF_WORK_BLOCK,
  VIEW_OF_WORK,
  VIEW_OF_WORK_BLOCK,
  MUNICIPALITY,
  MUNICIPALITY_BLOCK,
  DISTRICT,
  DISTRICT_BLOCK,
  REGION,
  REGION_BLOCK,
  MODEL,
  MAC_ADDRESS,
  SLA,
  SLA_BLOCK,
  WORKING_HOURS,
  REACTION_TIME_HOURS,
} from '@/constants/serviceDeskConfig/nameConfig'

const valueGenerator = (value) => (value ? Object.values(value)[0] : null)

const requestGenerator = (id, blockId, filterValue) => (
  {
    body: {
      integrationId: id,
      parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${blockId}&FILTER[id][0]=${filterValue}`,
      restMethod: '/lists.element.get.json',
    },
    url: SERVICE_DESK_GET_APPEALS_URL,
    method: 'post',
  }
)

function* getInfoCardSaga({ payload }) {
  const { id, serviceDeskStatus } = payload
  const serviceDeskConfig = serviceDeskStatus.propertyConfigContent
  try {
    const userApplications = yield select(getUserApplications)
    const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
    const serviceDeskId = serviceDeskStatus
      ? serviceDeskStatus.id
      : JSON.parse(serviceDesk.settings).id
    const card = yield request(requestGenerator(
      serviceDeskId, serviceDeskConfig[APPEALS_BLOCK].idInfoBlock, id,
    ))
    const cardInfo = yield all({
      [`PROPERTY_${serviceDeskConfig[APPEAL_CHANNEL].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[APPEAL_CHANNEL].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[APPEAL_CHANNEL_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[APPEAL_CHANNEL].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[OBJECT_TYPE].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[OBJECT_TYPE].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[OBJECT_TYPE_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[OBJECT_TYPE].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[PROJECT].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[PROJECT].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[PROJECT_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[PROJECT].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[TOWN].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[TOWN].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[TOWN_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[TOWN].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[SVT_STATUS].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[SVT_STATUS].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[SVT_STATUS_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[SVT_STATUS].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[APPEAL_CATEGORY].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[APPEAL_CATEGORY].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[APPEAL_CATEGORY_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[APPEAL_CATEGORY].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[WORK].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[WORK].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[WORK_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[WORK].id}`]),
        ))).data.result[0]
        : null,
      [`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[OBJECT].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[OBJECT].id}`]),
        ))).data.result[0]
        : null,
      [`PROPERTY_${serviceDeskConfig[SLA].id}`]: valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[SLA].id}`])
        ? (yield request(requestGenerator(
          serviceDeskId, serviceDeskConfig[SLA_BLOCK].idInfoBlock, valueGenerator(card.data.result[0][`PROPERTY_${serviceDeskConfig[SLA].id}`]),
        ))).data.result[0]
        : null,
    })

    const TYPE_OF_WORK_PROPERTY = cardInfo[`PROPERTY_${serviceDeskConfig[WORK].id}`]
      && valueGenerator(cardInfo[`PROPERTY_${serviceDeskConfig[WORK].id}`][`PROPERTY_${serviceDeskConfig[TYPE_OF_WORK].id}`])
      ? (yield request(requestGenerator(
        serviceDeskId, serviceDeskConfig[TYPE_OF_WORK_BLOCK].idInfoBlock, valueGenerator(cardInfo[`PROPERTY_${serviceDeskConfig[WORK].id}`][`PROPERTY_${serviceDeskConfig[TYPE_OF_WORK].id}`]),
      ))).data.result[0]
      : null
    const VIEW_OF_WORK_PROPERTY = TYPE_OF_WORK_PROPERTY && valueGenerator(TYPE_OF_WORK_PROPERTY[`PROPERTY_${serviceDeskConfig[VIEW_OF_WORK].id}`])
      ? (yield request(requestGenerator(
        serviceDeskId, serviceDeskConfig[VIEW_OF_WORK_BLOCK].idInfoBlock, valueGenerator(TYPE_OF_WORK_PROPERTY[`PROPERTY_${serviceDeskConfig[VIEW_OF_WORK].id}`]),
      ))).data.result[0]
      : null
    const MUNICIPALITY_PROPERTY = (cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`]
      && valueGenerator(cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`][`PROPERTY_${serviceDeskConfig[MUNICIPALITY].id}`]))
      ? (yield request(requestGenerator(
        serviceDeskId, serviceDeskConfig[MUNICIPALITY_BLOCK].idInfoBlock, valueGenerator(cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`][`PROPERTY_${serviceDeskConfig[MUNICIPALITY].id}`]),
      ))).data.result[0]
      : null
    const DISTRICT_PROPERTY = cardInfo[`PROPERTY_${serviceDeskConfig[TOWN].id}`]
      && valueGenerator(cardInfo[`PROPERTY_${serviceDeskConfig[TOWN].id}`][`PROPERTY_${serviceDeskConfig[DISTRICT].id}`])
      ? (yield request(requestGenerator(
        serviceDeskId, serviceDeskConfig[DISTRICT_BLOCK].idInfoBlock, valueGenerator(cardInfo.PROPERTY_30[`PROPERTY_${serviceDeskConfig[DISTRICT].id}`]),
      ))).data.result[0]
      : null
    const REGION_PROPERTY = DISTRICT_PROPERTY && valueGenerator(DISTRICT_PROPERTY[`PROPERTY_${serviceDeskConfig[REGION].id}`])
      ? (yield request(requestGenerator(
        serviceDeskId, serviceDeskConfig[REGION_BLOCK].idInfoBlock, valueGenerator(DISTRICT_PROPERTY[`PROPERTY_${serviceDeskConfig[REGION].id}`]),
      ))).data.result[0]
      : null

    const response = {
      ...card.data.result[0],
      ...cardInfo,
      ...cardInfo[`PROPERTY_${serviceDeskConfig[SLA].id}`],
      [`PROPERTY_${serviceDeskConfig[TYPE_OF_WORK].id}`]: TYPE_OF_WORK_PROPERTY,
      [`PROPERTY_${serviceDeskConfig[VIEW_OF_WORK].id}`]: VIEW_OF_WORK_PROPERTY,
      [`PROPERTY_${serviceDeskConfig[MUNICIPALITY].id}`]: MUNICIPALITY_PROPERTY,
      [`PROPERTY_${serviceDeskConfig[DISTRICT].id}`]: DISTRICT_PROPERTY,
      [`PROPERTY_${serviceDeskConfig[REGION].id}`]: REGION_PROPERTY,
      [`PROPERTY_${serviceDeskConfig[MODEL].id}`]: (
        cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`]
        && cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`][`PROPERTY_${serviceDeskConfig[MODEL].id}`]
      ),
      [`PROPERTY_${serviceDeskConfig[MAC_ADDRESS].id}`]: (
        cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`]
        && cardInfo[`IDBLOCK_${serviceDeskConfig[OBJECTS_BLOCK].idInfoBlock}`][`PROPERTY_${serviceDeskConfig[MAC_ADDRESS].id}`]
      ),
    }
    const sortedResponse = serviceDeskInfoCard(serviceDeskConfig).map((element) => {
      if (element.id === 'DETAIL_TEXT') {
        return {
          ...element,
          text: response[element.id],
        }
      }
      if ((element.id === `PROPERTY_${serviceDeskConfig[REACTION_TIME_HOURS].id}`
        || element.id === `PROPERTY_${serviceDeskConfig[WORKING_HOURS].id}`) && response.DATE_CREATE) {
        const date = convertDate(response.DATE_CREATE)
        date.setHours(date.getHours() + Number(valueGenerator(response[element.id])))
        const timeValue = `${valueGenerator(response[element.id])} часов (${moment(date).utcOffset(5 * 60).format('DD.MM.YYYY HH:mm:ss')})`
        return {
          ...element,
          text: valueGenerator(response[element.id]) ? timeValue : '',
        }
      }
      return {
        ...element,
        text: response[element.id] && response[element.id].NAME
          ? response[element.id].NAME : valueGenerator(response[element.id]),
      }
    })
    yield put(successGetInfoServiceDeskCard(sortedResponse))
  } catch (error) {
    console.log('TCL: function*getInfoCardSaga -> error', error)
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_SERVICE_DESK_INFO_CARD.REQUEST, getInfoCardSaga)
}
