import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcons from '@/components/icons/menu'

export default {
  preview: [
    {
      name: 'Всего обращений',
      selector: 'totalCalls',
    },
    {
      name: 'Средн. время взятия в работу',
      selector: 'averageDecisionExecutionTime',
    },
    {
      name: 'Средн. время принятия решения',
      selector: 'averageDecisionTime',
    },
  ],
  main: [
    [
      [
        {
          name: 'В работе',
          valueSelector: 'inWork',
          totalSelector: 'totalCalls',
          type: 'progressBar',
          message: 'warning',
        },
      ],
      [
        {
          name: 'Принято решение',
          valueSelector: 'decisionWasMade',
          totalSelector: 'totalCalls',
          type: 'progressBar',
          message: 'fix',
        },
      ],
      [
        {
          name: 'Средн. время взятия в работу',
          selector: 'averageDecisionExecutionTime',
        },
      ],
      [
        {
          name: 'Средн. время принятия решения',
          selector: 'averageDecisionTime',
        },
      ],
    ],
    [
      [
        {
          name: 'Всего обращений',
          selector: 'totalCalls',
        },
      ],
      [
        {
          name: 'Наружное освещение',
          selector: 'outdoorLightningCount',
        },
        {
          name: 'Ремонт дорог',
          selector: 'roadRepairingCount',
          disabled: true,
        },
        {
          name: 'Остановки общ. транспорта',
          selector: 'transportStationCount',
          disabled: true,
        },
        {
          name: 'Прорыв теплотрасс',
          selector: 'notAvailable1',
          disabled: true,
        },
        {
          name: 'Подтопления',
          selector: 'notAvailable2',
          disabled: true,
        },
      ],
    ],
  ],
  view: {
    title: <Lang id="widgetsDashboard.serviceDesk" />,
    icon: MenuIcons.SupportIcon,
  },
}
