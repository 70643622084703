import {
  put, takeLatest, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import get from 'lodash/get'
import updateNodeCount from '@/helpers/updateNodeCount'
import { GEOZONE } from '@/constants/objectTypes'
import { successDeleteProject } from '@/store/actions/installation/deleteProject'
import getNewPath from '@/helpers/getNewPath'
import request from '@/helpers/axios'
import { DELETE_PROJECT } from '@/store/actions/installation'
import { generateGetDeleteProjectCard } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notificationSmall'

function* deleteProjectSaga({ payload }) {
  const {
    setUrlFormValues, formValues, id, parentId,
  } = payload
  const {
    id: formId,
    path,
    rootId,
    FILTER,
  } = formValues
  try {
    yield request({
      url: generateGetDeleteProjectCard(id),
      method: 'delete',
    })
    const newPath = getNewPath(path)

    const tree = treeHandlers.trees['installations-tree']
    const node = tree.instance.getNodeById(formId)
    const parent = tree.instance.getNodeById(parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    tree.handlers.setSelected(parent, true)
    updateNodeCount(tree, newPath, 'remove', node.data.childrenCount + 1)
    tree.handlers.rerender()

    yield put(successDeleteProject())

    setUrlFormValues({
      id: parentId,
      rootId,
      parentGeoZoneId: parentId,
      parentTreeId: parentId,
      path: newPath,
      type: GEOZONE,
      FILTER,
    })
  } catch (error) {
    const toast = createNotifications()
    const errorMessage = get(error, 'response.data.errorMessage', null)
    if (errorMessage === 'One.or.more.integrations.contains.asuno.that.should.be.deleted.firstly') {
      toast({
        title: 'ОШИБКА ОПЕРАЦИИ!',
        type: 'error',
        description: 'Одна или несколько интеграций подключена к асу,\nперед удалением отключите их.',
      })
    }
    setUrlFormValues({ FILTER })
  }
}

export default function* root() {
  yield takeLatest(DELETE_PROJECT.REQUEST, deleteProjectSaga)
}
