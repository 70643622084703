import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const CalendarIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M11.3333 13.1H9.55556V14.9H11.3333V13.1ZM14.8889 13.1H13.1111V14.9H14.8889V13.1ZM18.4444 13.1H16.6667V14.9H18.4444V13.1ZM20.2222 6.8H19.3333V5H17.5556V6.8H10.4444V5H8.66667V6.8H7.77778C6.79111 6.8 6.00889 7.61 6.00889 8.6L6 21.2C6 21.6774 6.1873 22.1352 6.5207 22.4728C6.8541 22.8104 7.30628 23 7.77778 23H20.2222C21.2 23 22 22.19 22 21.2V8.6C22 7.61 21.2 6.8 20.2222 6.8ZM20.2222 21.2H7.77778V11.3H20.2222V21.2Z" />
    </svg>
  )
}

CalendarIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CalendarIcon.defaultProps = {
  color: '#1E2127',
  onClick: noop,
}

export default memoWithName(CalendarIcon)
