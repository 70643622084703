export const getNodePath = (node, prefix = '') => {
  const path = node[`${prefix}path`] || node.path
  if (path) {
    if (path[path.length - 1] === '.') {
      return path.slice(0, path.length - 1)
    }
    return path
  }

  return null
}

export default getNodePath
