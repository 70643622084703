import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LifeIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.45946 12.6642C5.65189 12.6642 5 13.2967 5 14.0802C5 14.8638 5.65189 15.4963 6.45946 15.4963C7.26703 15.4963 7.91892 14.8638 7.91892 14.0802C7.91892 13.2967 7.26703 12.6642 6.45946 12.6642ZM6.45946 7C5.65189 7 5 7.6325 5 8.41605C5 9.1996 5.65189 9.8321 6.45946 9.8321C7.26703 9.8321 7.91892 9.1996 7.91892 8.41605C7.91892 7.6325 7.26703 7 6.45946 7ZM6.45946 18.4889C5.73946 18.4889 5.16541 19.0553 5.16541 19.7444C5.16541 20.4336 5.74919 21 6.45946 21C7.16973 21 7.75351 20.4336 7.75351 19.7444C7.75351 19.0553 7.17946 18.4889 6.45946 18.4889ZM9.37838 20.6885H13V18.8004H9.37838V20.6885ZM9.37838 15.0243H13V13.1362H9.37838V15.0243ZM9.37838 7.47202V9.36008H13V7.47202H9.37838Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9595 12.6642C15.1519 12.6642 14.5 13.2967 14.5 14.0802C14.5 14.8638 15.1519 15.4963 15.9595 15.4963C16.767 15.4963 17.4189 14.8638 17.4189 14.0802C17.4189 13.2967 16.767 12.6642 15.9595 12.6642ZM15.9595 7C15.1519 7 14.5 7.6325 14.5 8.41605C14.5 9.1996 15.1519 9.8321 15.9595 9.8321C16.767 9.8321 17.4189 9.1996 17.4189 8.41605C17.4189 7.6325 16.767 7 15.9595 7ZM15.9595 18.4889C15.2395 18.4889 14.6654 19.0553 14.6654 19.7444C14.6654 20.4336 15.2492 21 15.9595 21C16.6697 21 17.2535 20.4336 17.2535 19.7444C17.2535 19.0553 16.6795 18.4889 15.9595 18.4889ZM18.8784 20.6885H22.5V18.8004H18.8784V20.6885ZM18.8784 15.0243H22.5V13.1362H18.8784V15.0243ZM18.8784 7.47202V9.36008H22.5V7.47202H18.8784Z" fill={fill} className="svgFill" />
    </svg>
  )
}

LifeIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LifeIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LifeIcon)
