import { all } from 'redux-saga/effects'

import getIntegrationsRoot from './getIntegrations'
import getIntegrationRoot from './getIntegration'
import createIntegrationSaga from './createIntegration'
import updateIntegrationSaga from './updateIntegration'
import deleteIntegrationSaga from './deleteIntegration'
import repeatIntegrationSaga from './repeatIntegration'

import integrationsEventsRoot from './events'

export default function* root() {
  yield all([
    getIntegrationsRoot(),
    getIntegrationRoot(),
    createIntegrationSaga(),
    updateIntegrationSaga(),
    deleteIntegrationSaga(),
    repeatIntegrationSaga(),

    integrationsEventsRoot(),
  ])
}
