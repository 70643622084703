import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CoreIcons from '@/components/icons/core'
import {
  Container,
  PrimaryTitle,
  SecondaryTitle,
  IconContainer,
  Main,
} from './styles'

const NotificationSmall = ({
  type,
  title,
  description,
  closeToast,
}) => (
  <Container type={type}>
    <Main>
      <PrimaryTitle>
        {title}
      </PrimaryTitle>
      <SecondaryTitle>
        {description}
      </SecondaryTitle>
    </Main>
    <IconContainer>
      <CoreIcons.InvertCrossIcon />
    </IconContainer>
  </Container>
)

NotificationSmall.propTypes = {
  type: pt.string,
  title: pt.oneOfType([pt.string, pt.element]),
  description: pt.oneOfType([pt.string, pt.element]),
  closeToast: pt.func,
}
NotificationSmall.defaultProps = {
  type: 'success',
  title: null,
  description: null,
  closeToast: noop,
}

export default NotificationSmall
