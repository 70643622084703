export default {
  formTitles: {
    ASU: 'АСУ:',
    integration: 'Интеграция:',
    objectTypes: 'Типы объектов:',
    process: 'Городские процессы:',
    objectState: 'Состояние объекта:',
  },
  selectPlaceholders: {
    ASU: 'Все АСУ',
    integration: 'Все интеграции',
    objectTypes: 'Все типы объектов',
    process: 'Все городские процессы',
  },
  buttons: {
    reset: 'Сбросить',
    accept: 'Применить',
  },
}
