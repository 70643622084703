import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import { getCountOfStatus, getStatusOfObjects } from '@/helpers/maps'
import PieChart from '../PieChart'

import {
  ClusterContainerSmall, ClusterContainerMedium, ClusterContainerBig,
  PieChartContainerSmall, PieChartContainerMedium, PieChartContainerBig,
  PieCharOverLaySmall, PieCharOverLayMedium, PieCharOverLayBig,
  TitleSmall, TitleMedium, TitleBig,
} from './styles'

const ClusterIcon = ({ theme, ...ownProps }) => {
  const clusterData = getStatusOfObjects(ownProps)
  const status = getCountOfStatus(clusterData)
  let visibility = true
  if (Object.keys(ownProps).length === 0) {
    visibility = false
  }
  const { data } = status
  return (
    <>
      {'{% if properties.geoObjects.length <= 99 %}'}
      <ClusterContainerSmall theme={theme} isVisible={visibility}>
        <PieChartContainerSmall theme={theme}>
          <PieChart size="small" statistic={data} theme={theme} />
        </PieChartContainerSmall>
        <PieCharOverLaySmall theme={theme}>
          <TitleSmall theme={theme}>
            {'{{ properties.geoObjects.length }}'}
          </TitleSmall>
        </PieCharOverLaySmall>
      </ClusterContainerSmall>
      {'{% endif %}'}
      {'{% if properties.geoObjects.length > 99 & properties.geoObjects.length <= 999 %}'}
      <ClusterContainerMedium theme={theme} isVisible={visibility}>
        <PieChartContainerMedium theme={theme}>
          <PieChart size="medium" statistic={data} theme={theme} />
        </PieChartContainerMedium>
        <PieCharOverLayMedium theme={theme}>
          <TitleMedium theme={theme}>
            {'{{ properties.geoObjects.length }}'}
          </TitleMedium>
        </PieCharOverLayMedium>
      </ClusterContainerMedium>
      {'{% endif %}'}
      {'{% if properties.geoObjects.length > 999 %}'}
      <ClusterContainerBig theme={theme} isVisible={visibility}>
        <PieChartContainerBig theme={theme}>
          <PieChart size="big" statistic={data} theme={theme} />
        </PieChartContainerBig>
        <PieCharOverLayBig theme={theme}>
          <TitleBig theme={theme}>
            {'{{ properties.geoObjects.length }}'}
          </TitleBig>
        </PieCharOverLayBig>
      </ClusterContainerBig>
      {'{% endif %}'}
    </>
  )
}

ClusterIcon.propTypes = {
  theme: mapTypes.theme.isRequired,
}

export default ClusterIcon
