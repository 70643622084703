import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const HeatSupplyIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.7016 16.052C43.9835 16.052 43.4009 16.6405 43.4009 17.3649V22.4997H41.9471V21.3295C41.9471 18.4197 39.6015 16.052 36.7198 16.052C35.1542 16.052 33.752 16.7542 32.7924 17.8578C31.833 16.7542 30.4307 16.052 28.8658 16.052C27.3002 16.052 25.8972 16.7542 24.9385 17.8578C23.9791 16.7542 22.5764 16.052 21.0112 16.052C19.4463 16.052 18.0432 16.7542 17.0842 17.8578C16.1251 16.7542 14.7221 16.052 13.1568 16.052C10.2747 16.052 7.92933 18.4199 7.92933 21.3295V22.4997H5.30053C4.58295 22.4997 4 23.0879 4 23.8126C4 24.5372 4.58295 25.1257 5.30053 25.1257H7.92734V44.7224C7.92734 47.6323 10.2729 50 13.1548 50C14.7201 50 16.1231 49.2981 17.0822 48.1946C18.0412 49.2981 19.4439 50 21.0092 50C22.5744 50 23.9771 49.2981 24.9361 48.1946C25.895 49.2981 27.2982 50 28.8631 50C30.428 50 31.8317 49.2981 32.7904 48.1946C33.749 49.2981 35.1529 50 36.7178 50C39.6002 50 41.9456 47.6321 41.9456 44.7224V25.1266H44.6995C45.4174 25.1266 46 24.5381 46 23.8137V17.3658C46.0023 16.6405 45.4189 16.052 44.7016 16.052ZM39.3457 44.7234C39.3457 46.1859 38.1678 47.3751 36.7198 47.3751C35.2717 47.3751 34.0931 46.1861 34.0931 44.7234V21.3305C34.0931 19.8683 35.2717 18.6787 36.7198 18.6787C38.1678 18.6787 39.3457 19.8681 39.3457 21.3305V44.7234ZM31.491 44.7226C31.491 46.185 30.3124 47.3742 28.8644 47.3742C27.4163 47.3742 26.2376 46.1852 26.2376 44.7226V21.3305C26.2376 19.8683 27.4162 18.6787 28.8644 18.6787C30.3122 18.6787 31.491 19.8681 31.491 21.3305V44.7226ZM23.6369 44.7226C23.6369 46.185 22.4586 47.3742 21.0106 47.3742C19.5628 47.3742 18.3843 46.1852 18.3843 44.7226V21.3305C18.3843 19.8683 19.5628 18.6787 21.0106 18.6787C22.4586 18.6787 23.6369 19.8681 23.6369 21.3305V44.7226ZM15.7822 44.7226C15.7822 46.185 14.6034 47.3742 13.1556 47.3742C11.7075 47.3742 10.5293 46.1852 10.5293 44.7226V21.3305C10.5293 19.8683 11.7075 18.6787 13.1556 18.6787C14.6034 18.6787 15.7822 19.8681 15.7822 21.3305V44.7226Z" fill={fill} className="svgFill" />
      <path d="M16.2869 0.440441C15.1147 1.85223 14.6323 3.34718 15.2132 5.14195C15.7865 6.91255 17.5474 8.53644 16.1422 10.3607C15.1256 11.6796 16.9526 13.553 17.981 12.2174C19.1385 10.7149 19.6389 9.18244 19.0547 7.31788C18.5277 5.63557 16.724 3.98494 18.1258 2.29712C19.1961 1.00842 17.3661 -0.859257 16.2869 0.440441Z" fill={fill} className="svgFill" />
      <path d="M25.7856 12.2172C26.9441 10.7148 27.4436 9.18226 26.8593 7.3177C26.3326 5.63538 24.5285 3.98475 25.9304 2.29694C27.0007 1.00823 25.17 -0.85944 24.0916 0.440257C22.9193 1.85205 22.4371 3.34699 23.018 5.14177C23.5913 6.91237 25.3522 8.53626 23.947 10.3605C22.9295 11.6796 24.7563 13.5528 25.7856 12.2172Z" fill={fill} className="svgFill" />
      <path d="M31.8941 0.440441C30.7223 1.85223 30.2396 3.34718 30.8204 5.14195C31.3945 6.91255 33.1549 8.53644 31.7503 10.3607C30.7333 11.6796 32.5598 13.553 33.5886 12.2174C34.7461 10.7149 35.2467 9.18244 34.6623 7.31788C34.1349 5.63557 32.331 3.98494 33.7334 2.29712C34.8036 1.00842 32.9739 -0.859257 31.8941 0.440441Z" fill={fill} className="svgFill" />
    </svg>
  )
}

HeatSupplyIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

HeatSupplyIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(HeatSupplyIcon)
