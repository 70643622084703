import { fork, takeLatest, all } from 'redux-saga/effects'
import {
  MAP_OBJECTS_ERRORS,
  MAP_OBJECTS_NOTIFICATIONS,
  REFRESH_WIDGET_MAP,
} from '@/store/actions/maps'

import refreshEquipment from './refreshEquipment'
import monitoringNotifications from './monitoringNotifications'
import monitoringErrors from './monitoringErrors'

function* watchStateRequest() {
  yield takeLatest(MAP_OBJECTS_ERRORS.EVENTS, monitoringErrors)
  yield takeLatest(REFRESH_WIDGET_MAP.EVENTS, refreshEquipment)
  yield takeLatest(MAP_OBJECTS_NOTIFICATIONS.EVENTS, monitoringNotifications)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
