import {
  OK,
  ERROR,
} from '@/constants/objectStatuses'

export default (enabled, blocked) => {
  if (blocked) {
    return ERROR
  }
  if (enabled && !blocked) {
    return OK
  }
  return null
}
