import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`
export const Container = styled.div`
  && > div {
    margin: 0px 5px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.white};
  }
  height: 100%;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const IconContainer = styled.div`
  min-width: 48px;
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg,
  .svgFill,
  .svgStroke {
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.backgrounds.controls.green
    if (type === 'red') return theme.colors.backgrounds.controls.red
    return 'transparent'
  }};
  cursor: pointer;
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
`
export const Title = styled.div`
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  flex: 1;
`

export const TitleAndIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 10px;
  }
  ${Title} {
    padding-left: 0px;
  }
`
