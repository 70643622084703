import React from 'react'
import { withRouter } from 'react-router'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import { Formik } from 'formik'
import Button from '@/components/blocks/Button'
import CoreIcons from '@/components/icons/core'
import {
  USERNAME,
  PASSWORD,
} from '@/constants/forms/signIn'
import {
  ErrorMessage,
  Form,
  Buttons,
  TextFieldStyled,
  FieldContainer,
} from './styles'

const SignInForm = ({
  globalMessage,
  onFormSubmit,
  handleForgotPassword,
}) => {
  const form = React.createRef()
  const onForgotPassword = (e) => {
    e.preventDefault()
    handleForgotPassword()
  }

  return (
    <Formik
      ref={form}
      onSubmit={onFormSubmit}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} id="userData" name="userData">
          {globalMessage && (
            <ErrorMessage>
              {globalMessage}
            </ErrorMessage>
          )}
          <Lang id="signIn.username">
            {(label) => (
              <TextFieldStyled
                name={USERNAME}
                fieldProps={{
                  label,
                  icon: CoreIcons.IcUserIcon,
                  autoComplete: 'username',
                }}
              />
            )}
          </Lang>
          <FieldContainer>
            <Lang id="signIn.password">
              {(label) => (
                <TextFieldStyled
                  name={PASSWORD}
                  fieldProps={{
                    label,
                    icon: CoreIcons.IcLockIcon,
                    type: 'password',
                    autoComplete: 'current-password',
                  }}
                />
              )}
            </Lang>
          </FieldContainer>

          <Buttons>
            <Button type="submit">
              <Lang id="signIn.signInButton" />
            </Button>
            <Button styleType="text" onClick={onForgotPassword}>
              <Lang id="signIn.forgotPasswordButton" />
            </Button>
          </Buttons>
        </Form>
      )}
    />
  )
}

SignInForm.propTypes = {
  onFormSubmit: pt.func,
  handleForgotPassword: pt.func,
  globalMessage: pt.string,
}
SignInForm.defaultProps = {
  onFormSubmit: noop,
  handleForgotPassword: noop,
  globalMessage: '',
}

export default React.memo(withRouter(SignInForm))
