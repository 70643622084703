import React, { useEffect } from 'react'
import pt from 'prop-types'
import { TIME_OF_ENDING_NOTIFICATION } from '@/constants/time'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import Popup from '@/components/blocks/Popup'
import {
  Container,
  IconContainer,
  NotificationsCount,
  Image,
  IconWrapper,
} from './styles'

const ControlsBlock = ({
  controls,
  eventsCountNotification,
  endGetAlarmsCountNotification,
  withDelimiter,
  spaced,
}) => {
  useEffect(() => {
    if (eventsCountNotification) {
      const timer = setTimeout(() => endGetAlarmsCountNotification(), TIME_OF_ENDING_NOTIFICATION)
      return () => clearTimeout(timer)
    }
  }, [endGetAlarmsCountNotification, eventsCountNotification])
  const renderItem = (item) => {
    const syncInProgress = item.syncInProgress || false
    const isHasCount = item.count > 0

    const renderIconWithTooltip = (
      <PortalTooltip
        title={item.title}
        renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
          <IconWrapper
            disableAnimation
            ref={wrapperRef}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={item.onClick}
          >
            {item.icon}
            {isHasCount && (
              <NotificationsCount>{item.count}</NotificationsCount>
            )}
          </IconWrapper>
        )}
      />
    )

    if (syncInProgress) {
      return (
        <IconWrapper onClick={item.onClick}>
          {item.icon}
        </IconWrapper>
      )
    }
    if (item.popupContent) {
      return (
        <Popup onClick={item.onClick} popupContent={item.popupContent} opened={item.opened}>
          {renderIconWithTooltip}
        </Popup>
      )
    }
    return (
      <>
        {renderIconWithTooltip}
      </>
    )
  }

  return (
    <Container withDelimiter={withDelimiter}>
      {controls.map((item) => {
        if (item.noAccess) {
          return null
        }
        return (
          <IconContainer animated={eventsCountNotification} key={item.name} spaced={spaced}>
            {item.isImage
              ? (
                <Image
                  alt="User"
                  src={item.icon}
                  onClick={item.onClick}
                  className={item.className}
                />
              )
              : (
                <>
                  {renderItem(item)}
                </>
              )}
          </IconContainer>
        )
      })}
    </Container>
  )
}

ControlsBlock.defaultProps = {
  controls: [],
  eventsCountNotification: false,
  withDelimiter: false,
}

ControlsBlock.propTypes = {
  endGetAlarmsCountNotification: pt.func.isRequired,
  controls: pt.arrayOf(pt.shape({
    icon: pt.oneOfType([pt.object, pt.string, pt.element]),
    isImage: pt.bool,
    onClick: pt.func,
  })),
  eventsCountNotification: pt.bool,
  withDelimiter: pt.bool,
}

export default React.memo(ControlsBlock)
