import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isString from 'lodash/isString'
import {
  StyledTable,
  StyledRow,
  StyledCell,
  StyledHeadRow,
  StyledHeadCell,
} from './styles'

const MOCK_ROW_PREFIX = 'mock_table_row'
const MIN_PAGE_ROWS = 10

const Table = ({
  columns,
  data,
  perPage,
  fields,
  rowSelector,
  minWidth,
  wrapContent,
}) => {
  const minRows = perPage > MIN_PAGE_ROWS ? perPage : MIN_PAGE_ROWS
  const rowSelectorHandler = (value) => () => {
    const isMockRow = isString(value.id) && value.id.includes(MOCK_ROW_PREFIX)
    if (rowSelector && !isMockRow) {
      rowSelector(value)
    } else {
      rowSelector(null)
    }
  }

  const tableData = useMemo(() => {
    if (data.length < minRows) {
      const newEmptyElementCount = minRows - data.length
      const newData = data.concat([])
      const newScheme = fields.reduce((accumulator, element) => ({
        ...accumulator,
        [element.id]: undefined,
      }), {})
      for (let i = 0; i < newEmptyElementCount; i += 1) {
        newData.push({
          ...newScheme,
          id: `${MOCK_ROW_PREFIX}-${i}`,
        })
      }
      return newData
    }
    return data
  }, [data, fields, minRows])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  })

  return (
    <>
      <StyledTable minWidth={minWidth} {...getTableProps()} isRowSelector={!!rowSelector}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <StyledHeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledHeadCell
                  customWidth={column.Header.props.customWidth}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </StyledHeadCell>
              ))}
            </StyledHeadRow>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length > 0 && rows.map((row) => {
            prepareRow(row)
            const notEmpty = !!row.original.id
            return (
              <StyledRow
                {...row.getRowProps()}
                onClick={notEmpty
                  ? rowSelectorHandler(row.original)
                  : noop}
                isSelectable={notEmpty}
              >
                {row.cells.map((cell) => {
                  if (cell.column.Header.props.customCell && cell.value !== undefined) {
                    return (
                      <StyledCell
                        wrap={wrapContent}
                        width={cell.column.Header.props.customWidth}
                        type={cell.column.Header.props.type}
                      >
                        {cell.column.Header.props.customCell(cell)}
                      </StyledCell>
                    )
                  }
                  return (
                    <StyledCell
                      {...cell.getCellProps()}
                      wrap={wrapContent}
                      width={cell.column.Header.props.customWidth}
                      type={cell.column.Header.props.type}
                    >
                      {cell.render('Cell')}
                    </StyledCell>
                  )
                })}
              </StyledRow>
            )
          })}
        </tbody>
      </StyledTable>
    </>
  )
}

Table.propTypes = {
  columns: pt.arrayOf(pt.object),
  data: pt.arrayOf(pt.object),
  fields: pt.arrayOf(pt.object),
  perPage: pt.number,
  rowSelector: pt.func,
  minWidth: pt.number,
  wrapContent: pt.bool,
}
Table.defaultProps = {
  columns: [],
  data: [],
  fields: [],
  perPage: 50,
  rowSelector: noop,
  minWidth: null,
  wrapContent: false,
}

export default Table
