import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ConnectionIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.352451 17.6472C0.575085 17.8701 0.83873 17.9873 1.14339 17.9991C1.44804 18.0108 1.70583 17.9111 1.91674 17.7L3.58649 16.0286C4.5942 16.6972 5.71616 16.9669 6.95236 16.8379C8.18856 16.7089 9.24607 16.2045 10.1249 15.3249L10.4061 15.0434L2.95375 7.60132L2.6901 7.86522C1.81129 8.74489 1.30743 9.80344 1.17854 11.0408C1.04965 12.2783 1.31329 13.4072 1.96947 14.4276L0.299723 16.0814C0.0888062 16.2925 -0.0107918 16.5505 0.000925064 16.8555C0.0126438 17.1604 0.129818 17.4244 0.352451 17.6472ZM8.66605 7.56613L10.4588 5.78919L12.2165 7.54854L10.4237 9.32548C10.2128 9.5366 10.1132 9.79464 10.1249 10.0996C10.1366 10.4045 10.2538 10.6684 10.4764 10.8913C10.699 11.1142 10.9627 11.2314 11.2673 11.2432C11.572 11.2549 11.8298 11.1552 12.0407 10.9441L13.8335 9.16714L15.0638 10.3987L15.3275 10.1172C16.2063 9.23751 16.7101 8.17897 16.839 6.94156C16.9679 5.70415 16.7043 4.5811 16.0481 3.57241L17.7003 1.91862C17.9112 1.7075 18.0108 1.44946 17.9991 1.1445C17.9874 0.839549 17.8702 0.575647 17.6475 0.352796C17.4249 0.129945 17.1613 0.012655 16.8566 0.000925974C16.552 -0.010803 16.2942 0.0888935 16.0833 0.300015L14.4311 1.9538C13.4234 1.28525 12.3014 1.01548 11.0652 1.1445C9.82902 1.27352 8.76565 1.77787 7.87512 2.65754L7.61147 2.93904L8.84181 4.17058L7.04903 5.94753C6.83811 6.15865 6.73851 6.41669 6.75023 6.72164C6.76195 7.0266 6.87912 7.2905 7.10176 7.51335C7.32439 7.7362 7.58804 7.85349 7.89269 7.86522C8.19735 7.87695 8.45513 7.77725 8.66605 7.56613Z" stroke={stroke} className="svgStroke" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

ConnectionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ConnectionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ConnectionIcon)
