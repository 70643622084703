import { handleActions } from 'redux-actions'
import {
  GET_MAP_OBJECTS,
  UPDATE_MAP_DATA,
  SET_ZONES,
} from '@/store/actions/maps'
import { regions } from '@/data/mapData'
import config from '@/data/mapTree/zoneConfig'
import {
  USER_LOGIN,
  USER_LOGOUT,
} from '@/store/actions/user'
import { getDefaultTree } from '@/data/mapTree'

const treeData = getDefaultTree()

const initialState = {
  tree: treeData,
  regions,
  pins: [],
  zones: config,
  telemetry: {},
  countOfPins: 0,
  loading: false,
}

export const reducer = handleActions(
  {
    [UPDATE_MAP_DATA]: (state, { payload }) => ({
      ...state,
      telemetry: {
        ...state.telemetry,
        ...payload,
      },
    }),
    [GET_MAP_OBJECTS.BEGIN]: (state) => ({
      ...state,
    }),
    [SET_ZONES]: (state, { payload }) => ({
      ...state,
      zones: payload,
    }),
    [GET_MAP_OBJECTS.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_MAP_OBJECTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      tree: payload.tree,
      pins: payload.pins,
      countOfPins: payload.countOfPins,
      zones: payload.zones || config,
      loading: false,
    }),
    [GET_MAP_OBJECTS.ERROR]: (state) => ({
      ...state,
      loading: false,
    }),
    [USER_LOGIN.SUCCESS]: (state) => ({
      ...state,
      tree: treeData,
      pins: [],
      countOfPins: 0,
    }),
    [USER_LOGOUT.SUCCESS]: (state) => ({
      ...state,
      tree: treeData,
      pins: [],
      countOfPins: 0,
    }),
  },
  initialState,
)

export default reducer
