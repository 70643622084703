import { GET_OBJECT_STATE } from './index'

export const getObjectDataRequest = (payload) => ({
  type: GET_OBJECT_STATE.REQUEST,
  payload,
})

export const getObjectDataSuccess = (payload) => ({
  type: GET_OBJECT_STATE.SUCCESS,
  payload,
})

export const getObjectDataError = (payload) => ({
  type: GET_OBJECT_STATE.ERROR,
  payload,
})
