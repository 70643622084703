import { all } from 'redux-saga/effects'

import addWidget from './addWidget'
import deleteWidget from './deleteWidget'
import setWidgetSettings from './setSettings'

export default function* root() {
  yield all([
    addWidget(),
    deleteWidget(),
    setWidgetSettings(),
  ])
}
