import request from '@/helpers/axios'
import { GEOZONE } from '@/constants/objectTypes'
import get from 'lodash/get'
import { generateChildCount, generateGetRootChildren } from '@/constants/apiRoutes'

export const getChildren = (rootId, closestGeoZoneId, parentTreeId) => async (node) => {
  const { data } = await request({
    url: generateGetRootChildren(
      get(node, 'id', null)
      || get(node, 'node.id', null)
      || get(node, 'node.data.id', null)
      || get(node, 'node', null),
    ),
    method: 'get',
  })
  const childWithCount = await Promise.all(data.map(async (element) => {
    const parentGeoZoneId = element.type === GEOZONE
      ? (element.id || element.treeElementId)
      : closestGeoZoneId
    const { data: childCount } = await request({
      url: generateChildCount(element.id),
      method: 'get',
    })
    return {
      ...element,
      type: element.objectType || element.type,
      systemType: element.type,
      name: element.elementName,
      parentGeoZoneId: closestGeoZoneId,
      parentTreeId,
      rootGeoZone: rootId,
      childrenCount: childCount,
      getChildren: getChildren(rootId, parentGeoZoneId, element.id),
    }
  }))
  return childWithCount
}

export default getChildren
