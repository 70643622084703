import ServiceIcons from '@/components/icons/serviceDeskMyCity'
import {
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  WAITING_FOR_CONTROL,
  OVERDUE_ON_EXECUTION,
  AVERAGE_LEAD_TIME,
  CURRENT_MONTH,
  ALL_TIME,
} from '@/constants/serviceDeskMyCity'

export const contentStructure = [
  {
    id: RECEIVED,
    icon: ServiceIcons.RegisteredIcon,
    text: 'Зарегистрировано',
    value: '',
  },
  {
    id: TASKS_PERFORMED,
    icon: ServiceIcons.InWorkIcon,
    text: 'В работе',
    value: '',
  },
  {
    id: DONE,
    icon: ServiceIcons.SolvedIcon,
    text: 'Решено',
    value: '',
  },
  {
    id: OVERDUE_ON_EXECUTION,
    icon: ServiceIcons.OverdueIcon,
    text: 'Просрочено',
    value: '',
  },
  {
    id: WAITING_FOR_CONTROL,
    icon: ServiceIcons.DelayedIcon,
    text: 'Отложено',
    value: '',
  },
  {
    id: AVERAGE_LEAD_TIME,
    icon: ServiceIcons.AverageTimeDecisionIcon,
    text: 'Решение принято за',
    value: '',
  },
]

export default [
  {
    id: CURRENT_MONTH,
    title: 'За текущий месяц',
    content: contentStructure,
  },
  {
    id: ALL_TIME,
    title: 'За весь период',
    content: contentStructure,
  },
]
