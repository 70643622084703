import { handleActions } from 'redux-actions'
import {
  USER_FORGOT_PASSWORD,
} from '@/store/actions/user'
import {
  NOT_REQUESTED,
  IDLE,
  PENDING,
  ERROR,
  REQUEST_STATUSES,
} from '@/constants/requests'


const initialState = {
  status: REQUEST_STATUSES[NOT_REQUESTED],
}

export const reducer = handleActions(
  {
    [USER_FORGOT_PASSWORD.REQUEST]: () => ({
      ...initialState,
      status: REQUEST_STATUSES[PENDING],
    }),
    [USER_FORGOT_PASSWORD.SUCCESS]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[IDLE],
    }),
    [USER_FORGOT_PASSWORD.ERROR]: (state, { payload }) => ({
      ...state,
      errors: payload,
      status: REQUEST_STATUSES[ERROR],
    }),
  },
  initialState,
)

export default reducer
