import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const EnergyConsumptionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2067 10.625H14.3913L15.7998 6.06172C15.932 5.52734 15.5518 5 15.0327 5H11.8518C11.455 5 11.1178 5.31289 11.0649 5.73125L10.0069 14.1687C9.94406 14.675 10.3144 15.125 10.7938 15.125H13.1382L11.6141 21.9629C11.495 22.4973 11.8786 23 12.3844 23C12.6621 23 12.9266 22.8453 13.0721 22.5781L18.8911 11.8906C19.1986 11.3316 18.8184 10.625 18.2067 10.625Z" fill={fill} className="svgFill" />
    </svg>
  )
}

EnergyConsumptionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

EnergyConsumptionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(EnergyConsumptionIcon)
