import blagoveshinsk from './blagoveshinsk'
import kyrgan from './kyrgan'
import tumen from './tumen'
import volokolamsk from './volokolamsk'

export const regionsById = {
  48: kyrgan,
  63: tumen,
  86: volokolamsk,
  108: blagoveshinsk,
}

export default {
  blagoveshinsk,
  kyrgan,
  tumen,
  volokolamsk,
}
