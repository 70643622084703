import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  min-width: 310px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  flex-direction: column;
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.colors.dim};
  ${({ active, theme }) => active && `
    color: ${theme.colors.colors.default};
  `}
`

export const TabHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;
  max-height: 48px;
`

export const TabButton = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.dim};
  }
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  padding: 5px 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ active, theme }) => active && `
    background: ${theme.colors.backgrounds.header};
    .svgFill {
      fill: ${theme.colors.colors.default};
    }
    border-bottom: none;
  `}
  min-width: 103px;
  &:last-child {
    border-right: none;
  }
`
