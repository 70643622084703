import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const MinusIcon = ({ color, onClick }) => {
  const {
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="26" height="2" viewBox="0 0 26 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H13H25" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" />
    </svg>
  )
}

MinusIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

MinusIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(MinusIcon)
