import { handleActions } from 'redux-actions'
import {
  USER_LOGIN,
  USER_LOGOUT,
} from '@/store/actions/user'

import {
  NOT_REQUESTED,
  IDLE,
  PENDING,
  ERROR,
  REQUEST_STATUSES,
} from '@/constants/requests'

const initialState = {
  errors: {},
  data: {},
  status: REQUEST_STATUSES[NOT_REQUESTED],
  loading: false,
}

export const reducer = handleActions(
  {
    [USER_LOGIN.BEGIN]: () => ({
      ...initialState,
      status: REQUEST_STATUSES[PENDING],
      loading: true,
    }),
    [USER_LOGIN.SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      data,
      status: REQUEST_STATUSES[IDLE],
      loading: false,
    }),
    [USER_LOGIN.ERROR]: (state, { payload }) => ({
      ...state,
      errors: payload,
      status: REQUEST_STATUSES[ERROR],
      loading: false,
    }),
    [USER_LOGOUT.SUCCESS]: () => ({
      ...initialState,
    }),
  },
  initialState,
)

export default reducer
