import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ResetInputIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9521 11.7872L7.04695 7.58428L1.84037 13.1879L3.79341 15.2888C4.3112 15.8459 5.01338 16.1589 5.74554 16.1589C6.47769 16.1589 7.17987 15.8459 7.69766 15.2888L10.9521 11.7872ZM9.6231 16.0189H12.1228C12.3669 16.0189 12.6011 16.1232 12.7737 16.309C12.9463 16.4948 13.0432 16.7467 13.0432 17.0094C13.0432 17.2722 12.9463 17.5241 12.7737 17.7099C12.6011 17.8956 12.3669 18 12.1228 18H1.99867C1.76533 17.9998 1.54077 17.9042 1.37043 17.7325C1.20009 17.5609 1.09668 17.326 1.08113 17.0755C1.06558 16.8249 1.13904 16.5773 1.28665 16.3828C1.43427 16.1883 1.64502 16.0614 1.87626 16.0278L0.538949 14.5885C0.19386 14.217 0 13.7132 0 13.1879C0 12.6625 0.19386 12.1587 0.538949 11.7872L10.9521 0.580044C11.2973 0.208642 11.7654 0 12.2535 0C12.7416 0 13.2098 0.208642 13.555 0.580044L17.4611 4.78298C17.8061 5.1545 18 5.65831 18 6.18363C18 6.70895 17.8061 7.21277 17.4611 7.58428L9.6231 16.0189Z" fill={fill} className="svgFill" />
    </svg>
  )
}

ResetInputIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ResetInputIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ResetInputIcon)
