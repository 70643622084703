import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const VideoIcon = ({ onClick }) => (
  <svg onClick={onClick} width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.72064 5.64854H7.94692V3.36123H8.72064C9.40384 3.36123 9.86842 3.45049 10.1144 3.62901C10.3603 3.80381 10.4833 4.09577 10.4833 4.50488C10.4833 4.91399 10.3603 5.20781 10.1144 5.38633C9.86842 5.56113 9.40384 5.64854 8.72064 5.64854Z" fill="#8AC135" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14 1V4.5L16.29 2.2C16.92 1.57 18 2.02 18 2.91V9.08C18 9.97 16.92 10.42 16.29 9.79L14 7.5V11C14 11.55 13.55 12 13 12H1C0.45 12 0 11.55 0 11V1C0 0.45 0.45 0 1 0H13C13.55 0 14 0.45 14 1ZM3 9.58159C3 9.86053 3.11956 10 3.35868 10H4.13753C4.37665 10 4.49621 9.86053 4.49621 9.58159V2.41841C4.49621 2.13947 4.37665 2 4.13753 2H3.35868C3.11956 2 3 2.13947 3 2.41841V9.58159ZM6.45071 9.58159C6.45071 9.86053 6.57027 10 6.80939 10H7.58824C7.82736 10 7.94692 9.86053 7.94692 9.58159V7.00976H8.72576C9.95552 7.00976 10.8078 6.79219 11.2826 6.35704C11.7609 5.9219 12 5.30451 12 4.50488C12 3.70525 11.7609 3.08787 11.2826 2.65272C10.8078 2.21757 9.95552 2 8.72576 2H6.80939C6.57027 2 6.45071 2.13947 6.45071 2.41841V9.58159Z" fill="#8AC135" />
  </svg>
)

VideoIcon.propTypes = {
  onClick: pt.func,
}

VideoIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(VideoIcon)
