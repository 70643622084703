import getQueryParams from '@/helpers/getQueryParams'
import qs from 'query-string'
import {
  ROLE_SUPER_ADMIN,
} from '@/constants/role'
import {
  USERS_GROUP,
} from '@/constants/viewTree'

export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.sls24.net'
export const API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api'
export const AUTH_API_PREFIX = process.env.REACT_APP_AUTH_API_PREFIX || '/auth'

export const API = `${API_PREFIX}`
export const AUTH_API = `${AUTH_API_PREFIX}`

// Dictionaries endpoints
export const CITY_PROCESSES_ENPOINT = `${API}/allCityProcesses`
export const FUNCTIONAL_MODULES_ENPOINT = `${API}/allFunctionalModules`
export const GET_CITY_PROCESSES = `${API}/city-processes`
export const GET_ALL_APPLICATIONS_URL = `${API}/applications`
export const GET_ALL_APPLICATIONS_MODELS_URL = `${API}/applications/modules`
// Auth endpoints
export const API_AUTH = `${API}/oauth`
export const AUTH_API_AUTH = `${AUTH_API}/oauth`
export const LOGIN_API_URL = `${AUTH_API_AUTH}/token`
export const LOGOUT_API_URL = `${API_AUTH}/token`
export const RESET_PASSWORD_API_URL = `${API}/password`
export const FORGOT_PASSWORD = `${AUTH_API}/forgot-password`
export const ACTIVITY_PING_URL = `${API}/active-session/ping`

// Users endpoints
export const USER_SETTINGS_ENDPOINT = `${API}/user-settings`
export const USER_WIDGET_SETTINGS_ENDPOINT = `${API}/user-widget-settings`
export const TENANT_ENDPOINT = `${API}/tenants`
export const DELETE_TENANT_GROUP = `${API}/groups`
export const UPDATE_TENANT_GROUP = `${API}/groups`
export const CREAT_USER_URL = `${API}/users`
export const CREATE_TENANT_GROUP_URL = `${API}/groups`
export const GET_TENANT_GROUPS_URL = `${API}/groups`
export const generateTenantEndpoint = (id) => `${API}/tenants/${id}`
export const generateAllUsers = (groupId) => `${API}/groups/${groupId}/users`
export const generateDeleteGroup = (groupId) => `${API}/groups/${groupId}`
export const generateGetTenantGroup = (groupId) => `${API}/groups/${groupId}`
export const generatePutAdmin = (id) => `${API}/users/${id}`
export const generateManagementData = (userIds) => `${API}/users/${userIds}/management-data`
export const generateSortedUsers = ({
  currentRole, entityId, params, type,
}) => {
  if (currentRole === ROLE_SUPER_ADMIN) {
    return `${TENANT_ENDPOINT}/${entityId}/users?${getQueryParams(params)}`
  }
  if (type === USERS_GROUP) {
    return `${API}/groups/${entityId}/users?${getQueryParams(params)}`
  }
  return `${API}/users?${getQueryParams(params)}`
}

export const generateAllAdminTenants = ({ currentRole, tenantId, params }) => (
  currentRole === ROLE_SUPER_ADMIN
    ? `${TENANT_ENDPOINT}/${tenantId}/users?${getQueryParams(params)}`
    : `${API}/users?${getQueryParams(params)}`
)

// Live time reports endpoints
export const REPORT_URL = `${API}/report`
export const GET_LIVE_TIME_REPORT_BAR_DATA_URL = `${REPORT_URL}/statistic-bar`
export const GET_LIVE_TIME_REPORT_TABLE_URL = `${REPORT_URL}/table-state`
export const REPORT_FILE_URL = `${API}/report-file`
export const generateEditGroups = (groupId) => `${API}/groups/${groupId}`
export const generateLiveTimeReportFileUrl = (
  {
    fileType = 'pdf',
    type = 'indication',
  },
) => `${REPORT_FILE_URL}/download-${type}-state-report/${fileType}`

// Resource analytics endpoints
export const RESOURCE_ANALYTICS_ENDPOINT = `${REPORT_URL}/resource-analytics`
export const HEAT_ANALYTICS_ENDPOINT = `${REPORT_URL}/heat-analytics`
export const RESOURCE_ANALYTICS_TABLE_ENDPOINT = `${RESOURCE_ANALYTICS_ENDPOINT}/table`
export const RESOURCE_ANALYTICS_GRAPH_ENDPOINT = `${RESOURCE_ANALYTICS_ENDPOINT}/graph`
export const RESOURCE_ANALYTICS_HISTORY_GRAPH_ENDPOINT = `${RESOURCE_ANALYTICS_GRAPH_ENDPOINT}/history-actual`
export const RESOURCE_ANALYTICS_PLAN_GRAPH_ENDPOINT = `${RESOURCE_ANALYTICS_GRAPH_ENDPOINT}/plan-actual`
export const RESOURCE_ANALYTICS_AVERAGE_ENDPOINT = `${RESOURCE_ANALYTICS_ENDPOINT}/average`
export const HEAT_ANALYTICS_TABLE_ENDPOINT = `${HEAT_ANALYTICS_ENDPOINT}/table`
export const HEAT_ANALYTICS_GRAPH_ENDPOINT = `${HEAT_ANALYTICS_ENDPOINT}/graph`
export const generateResourceAnalyticsReportFileUrl = (
  {
    fileType = 'pdf',
  },
) => `${RESOURCE_ANALYTICS_ENDPOINT}/download-${fileType}-table-state-report`
export const generateHeatAnalyticsReportFileUrl = (
  {
    fileType = 'pdf',
  },
) => `${REPORT_FILE_URL}/download-heat-supply-table-report/${fileType}`

// Alarm reports
export const ALARMS_URL = `${API}/alarm`
export const ALARM_MANAGER_GRAPH_ENDPOINT = `${ALARMS_URL}/graph`
export const ALARM_MANAGER_TABLE_ENDPOINT = `${ALARMS_URL}/table`
export const ALARM_MANAGER_AVERAGE_ENDPOINT = `${ALARMS_URL}/statistic-by-type`
export const ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT = `${ALARMS_URL}/unread-alarm`
export const ALARM_MANAGER_SET_ALL_READ_ALARM_ENDPOINT = `${ALARMS_URL}/unread-alarm/all`
export const ALARM_MANAGER_CHECK_ALL_ALARMS = `${ALARMS_URL}/unread-alarm/check`
export const generateAlarmManagerFileUrl = (
  {
    fileType = 'pdf',
  },
) => `${REPORT_FILE_URL}/download-alarm-table-report/${fileType}`

export const ALARMS_ANALYTICS_ENDPOINT = `${ALARMS_URL}/analytics`
export const ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT = `${ALARMS_ANALYTICS_ENDPOINT}/summary-table`
export const ALARMS_ANALYTICS_TABLE_ENDPOINT = `${ALARMS_ANALYTICS_ENDPOINT}/table`
export const ALARMS_ANALYTICS_ALARM_ENDPOINT = `${ALARMS_ANALYTICS_ENDPOINT}/object-alarm-history`
export const ALARMS_ANALYTICS_WIDGET_ENDPOINT = `${ALARMS_URL}/geozone-summary-statistic`
export const generateAnalyticsAlarmsFileUrl = (
  {
    fileType = 'pdf',
  },
) => `${REPORT_FILE_URL}/download-alarm-analytics-table-report/${fileType}`
export const generateSummaryAnalyticsAlarmsFileUrl = (
  {
    fileType = 'pdf',
  },
) => `${REPORT_FILE_URL}/download-summary-alarm-analytics-table-report/${fileType}`

export const ANALYTICS_URL = `${API}/analyticsAlarms`
export const ANALYTICS_MANAGER_TABLE_ENDPOINT = `${ANALYTICS_URL}/table`

//  Passportization
export const DELETE_PASSPORT_FILES_URL = REPORT_URL
export const GET_PASSPORT_FILES_URL = `${REPORT_URL}/download`
export const UPLOAD_URL = `${REPORT_URL}/upload`
export const ADD_PASSPORT_PHOTO_URL = `${UPLOAD_URL}/photo`
export const ADD_PASSPORT_FILE_URL = `${UPLOAD_URL}/passport`
export const generateGetPassportDataURL = `${API}/passport-card`
export const GET_PASSPORT_DATA_URL = `${API}/passport-system`
export const GET_ELEMENT_EQUIPMENT = `${GET_PASSPORT_DATA_URL}/child-elements`
export const EXPORT_PASSPORT_FILE_URL = `${API}/report-file/download-passportization-file`
export const generateGetElementEquipment = (name, type) => `${GET_ELEMENT_EQUIPMENT}?identifier=${name}&installationType=${type}`
export const generatePutPassport = (type) => `${GET_PASSPORT_DATA_URL}?installationType=${type}`

// Map equipment
export const EQIPMENT_URL = `${API}/equipment`
export const KULON_EQIPMENT_URL = `${EQIPMENT_URL}/kulon`
export const ATM_EQIPMENT_URL = `${EQIPMENT_URL}/one-sim`
export const MESH_EQIPMENT_URL = `${EQIPMENT_URL}/mesh`
export const BRIZ_EQIPMENT_URL = `${EQIPMENT_URL}/briz`

// Tyumen our home
export const CITY_PORTAL_URL = `${API}/city-portal-analyzer`
export const TYUMEN_OUR_HOME_URL = `${CITY_PORTAL_URL}/appeal-card`
export const TYUMEN_OUR_HOME_WIDGET_URL = `${CITY_PORTAL_URL}/widget-info`
export const generateTyumenOurHomeUrl = (type) => `${TYUMEN_OUR_HOME_URL}/${type}`
export const generateTyumenOurHomeWidgetUrl = (type) => `${TYUMEN_OUR_HOME_WIDGET_URL}/${type}`
export const TYUMEN_OUR_HOME_GRAPHS_STATUSES_URL = `${API}/city-portal-analyzer-graph/appeal-statuses`
export const TYUMEN_OUR_HOME_GRAPHS_TOPICS_URL = `${API}/city-portal-analyzer-graph/appeal-topics`
export const TYUMEN_OUR_HOME_GRAPHS_STATISTIC_URL = `${API}/city-portal-analyzer-graph/appeal-statistic`
export const TYUMEN_OUR_HOME_GRAPHS_TIME_URL = `${API}/city-portal-analyzer-graph/appeal-avg-resolve-time`
export const generateTyumenOurHomeDateGraphUrl = (appealType) => `${API}/city-portal-analyzer-graph/appeal-earliest-dates/${appealType}`

// refresh elements
export const generateRefreshElementId = (id) => `${API}/integrations/${id}/equipment/state/refresh`

// service desk
export const SERVICE_DESK_GET_APPEALS_URL = `${API}/service-desk/appeals-list`
export const SERVICE_DESK_INTEGRATION_URL = `${API}/service-desk/integration`

// Integrations
export const INTEGRATIONS_URL = `${API}/integrations`
export const generateIntegrationUrl = (id = '') => `${INTEGRATIONS_URL}/${id}`
export const generateIntegrationsInfoUrl = (ids = []) => `${INTEGRATIONS_URL}/${ids}/info`
export const generateIntegrationTypeUrl = (type = '') => `${INTEGRATIONS_URL}/${type}`
export const generateIntegrationTypeWithIdUrl = (type = '', id = '') => `${generateIntegrationTypeUrl(type)}/${id}`
export const generateRefreshIntegrationUrl = (type = '', id = '') => `${generateIntegrationTypeWithIdUrl(type, id)}/refresh`

// DASHBORD APP

export const UNKNOWN_DASHBOARD_ROUT = `${API_URL}/UNKNOWN_DASHBOARD_ROUT`
export const STATE_OF_OBJECT = `${ALARMS_URL}/analytics/state-of-objects`
export const ALARM_REGISTER = `${ALARMS_URL}/automatic-register-malfunctions`
export const EQUIPMENT_WEAR = `${REPORT_URL}/analytics/deprecation-of-equipment`
export const TUMEN_PORTAL_INFO = `${CITY_PORTAL_URL}/widget-info/dashboard`
export const HEAT_DASHBOARD_GRAPH = `${REPORT_URL}/heat-analytics/graph`
export const LIGHT_DASHBOARD_GRAPH = `${REPORT_URL}/resource-analytics/graph/plan-actual`
export const TUMEN_OUR_HOME_DASHBOARD = `${CITY_PORTAL_URL}/widget-info/dashboard`

// INSTALLATION
export const INSTALLATION_URL = `${API}/installation`
export const INSTALLATION_FILE_SCHEME_UPLOAD = `${INSTALLATION_URL}/file`
export const INSTALLATION_ERROR_FILE_DOWNLOAD = `${INSTALLATION_FILE_SCHEME_UPLOAD}/validated-file`
export const INSTALLATION_FILE_SCHEME_DOWNLOAD = `${INSTALLATION_FILE_SCHEME_UPLOAD}/download`
export const INSTALLATION_ROOT_ELEMENT = `${INSTALLATION_URL}/installation-tree`
export const generateGetRootChildren = (id) => `${INSTALLATION_ROOT_ELEMENT}/${id}/child-elements`
export const generateGetDeleteChildren = (id) => `${INSTALLATION_ROOT_ELEMENT}/${id}`
export const generateGetPutGeoZoneCard = (id) => `${INSTALLATION_URL}/geozone/${id}`
export const generateGetGeoZoneCard = (id) => `${INSTALLATION_URL}/geozone/tree-element/${id}`
export const generateGetProjectCard = (id) => `${INSTALLATION_URL}/project/tree-element/${id}`
export const INSTALLATIONS_GEOZONE_ENDPOINT = `${INSTALLATION_URL}/geozone`
export const INSTALLATIONS_PROJECT_ENDPOINT = `${INSTALLATION_URL}/project`
export const generateGetDeleteProjectCard = (id) => `${INSTALLATION_URL}/project/${id}`

export const INSTALLATIONS_INTEGRATION_ENDPOINT = `${INSTALLATION_URL}/integration`
export const getFreeIntegrations = (type, ids) => `${INSTALLATIONS_INTEGRATION_ENDPOINT}/all?integrationType=${type}&${qs.stringify({ projectIds: ids })}`
export const generateGetDeleteIntegrationCard = (id) => `${INSTALLATION_URL}/integration/${id}`
export const generateGetTreeIntegration = (id) => `${INSTALLATION_ROOT_ELEMENT}/integration/tree-element/${id}`
export const generateGetTreeIntegrationElement = (id) => `${INSTALLATION_URL}/integration/tree-element/${id}`

export const INSTALLATIONS_OBJECT_ENDPOINT = `${INSTALLATION_URL}/object-element`
export const generateGetDeleteObjectCard = (id) => `${INSTALLATION_URL}/object-element/tree-element/${id}`
export const generateGetDeleteObject = (id) => `${INSTALLATION_URL}/object-element/${id}`
export const generateGetTreeObject = (id) => `${INSTALLATION_ROOT_ELEMENT}/object-element/tree-element/${id}`

export const GET_TYPES = `${INSTALLATION_URL}/child-element/available-children-type`
export const generateGetTypes = (integrationType, type) => `${INSTALLATION_URL}/child-element/available-children-type/${integrationType}${type ? `?currentEquipmentType=${type}` : ''}`

export const generateGetTreeElementChildren = (id) => `${INSTALLATION_URL}/installation-tree/${id}/child-elements`
export const generateGetTree = (id) => `${INSTALLATION_URL}/installation-tree/tree/${id}`
export const generateChildCount = (id) => `${INSTALLATION_URL}/installation-tree/count-children/${id}`

export const generateGetTreeWithStatus = (id) => `${INSTALLATION_URL}/installation-tree/filtered-tree/${id}`
export const getScheme = (id) => `${INSTALLATION_URL}/installation-tree/count-children/${id}`
export const generateDownloadMultipleObject = (id) => `${INSTALLATION_URL}/installation-tree/count-children/${id}`

export const generateGetValidationScheme = (integrationType, type) => `${INSTALLATION_URL}/validation-schema/${integrationType}${type && `?currentEquipmentType=${type}`}`
export const generateValidateObject = (id) => `${INSTALLATION_URL}/object-element-verification/${id}`

// INSTALLATION UPLOAD DOWNLOAD GENERATE FILE AND PHOTO
export const generateDownloadGeneratedFile = (id) => `${REPORT_FILE_URL}/download-passport-installation/${id}`
export const INSTALLATION_UPLOAD_FILE = `${REPORT_URL}/upload`
export const generateInstallationDeleteFile = (id, fileId) => `${REPORT_URL}/${id}/${fileId}`
export const generateStartSettingProcessGetFile = (id) => `${REPORT_FILE_URL}/download/order-for-commission-of-the-object/${id}`

// REPORT MANAGER
export const REPORT_MANAGER_URL = `${API}/report-manager`
export const REPORT_MANAGER_TABLE_URL = ({ params }) => `${REPORT_MANAGER_URL}/reports?${getQueryParams(params)}`
export const REPORT_MANAGER_TYPES_URL = `${REPORT_MANAGER_URL}/report-types`
export const REPORT_MANAGER_OBJECT_TYPES_URL = ({ params, type }) => `${REPORT_MANAGER_URL}/report-types/${type}/object-types?${getQueryParams(params)}`
export const REPORT_MANAGER_COMMON_USERS_URL = `${API}/common/users`
export const CREATE_REPORT_URL = `${REPORT_MANAGER_URL}/reports`
export const GET_REPORT_URL = ({ id }) => `${REPORT_MANAGER_URL}/reports/${id}`
export const GET_REPORT_FILE_URL = ({ name, params }) => `${CREATE_REPORT_URL}/table/export/${name}?${getQueryParams(params)}`

// asu controller
// /installation/asuno
export const generateGetIntegrationById = (id) => `${INSTALLATION_URL}/asuno?asunoId=${id}`
