import styled from 'styled-components'
import { TextButton, ButtonStyled } from '@/components/blocks/Button/styles'
import Button from '@/components/blocks/Button'

export const Form = styled.form`
  width: 100%;
`

export const Text = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.colors.subTitle};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${TextButton} {
    margin-top: 10px;
  }

  ${ButtonStyled} {
    margin-top: 15px;
  }
`

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.colors.subTitle};
  text-decoration-line: underline;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
`
