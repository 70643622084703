import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CheckBoxStyled from './styles'

const CheckBox = ({
  value, onChange, type, color, ...ownProps
}) => {
  const handleChange = useCallback((event) => {
    onChange(!value, event)
  }, [value, onChange])
  return (
    <CheckBoxStyled
      active={value}
      onClick={handleChange}
      type={type}
      color={color}
      {...ownProps}
    />
  )
}

CheckBox.propTypes = {
  value: pt.bool,
  onChange: pt.func,
  childSelected: pt.bool,
  type: pt.bool,
  color: pt.string,
}
CheckBox.defaultProps = {
  value: false,
  childSelected: false,
  type: false,
  onChange: noop,
  color: null,
}

export default CheckBox
