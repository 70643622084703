import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SupportIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9.10156 3H12.9688V3.70312H9.10156C8.1323 3.70312 7.34375 4.49168 7.34375 5.46094V10.7812H6.64062V5.46094C6.64062 4.10398 7.7446 3 9.10156 3ZM5.7312 14.2969L5.96558 11.4844H8.0188L8.25317 14.2969H8.98438V15H5V14.2969H5.7312ZM11.2109 5.46094C11.9303 5.46094 12.5497 5.02643 12.8215 4.40625H9.60034C9.87214 5.02643 10.4916 5.46094 11.2109 5.46094Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SupportIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SupportIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SupportIcon)
