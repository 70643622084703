import { EXPORT_PASSPORT_FILE } from './index'

export const requestExportPassportFile = (payload) => ({
  type: EXPORT_PASSPORT_FILE.REQUEST,
  payload,
})

export const responseExportPassportFile = (payload) => ({
  type: EXPORT_PASSPORT_FILE.SUCCESS,
  payload,
})

export const errorExportPassportFile = (payload) => ({
  type: EXPORT_PASSPORT_FILE.ERROR,
  payload,

})
