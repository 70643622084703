export default {
  condition: 'You must confirm\n the conditions to continue ',
  userAgreement: 'user agreement',
  acceptCondition: 'Accept conditions',
  next: 'Continue',
  password: 'Create a strong password',
  passwordConfirmation: 'Password confirmation',
  requirements: 'Password requirements',
  requirementsText: `• The password must be at least 8 characters long.
• The password must consist of Latin letters (A-z), Arabic numerals (0-9) and special characters (see point 4)
• The literal part of the password must contain both lowercase and uppercase (capital) letters.
• The password must contain at least one of the following characters:
. , : ; ? ! * + % - < > @ [ ] { } / _ {} $ #
• The password must not match the login.
• The password must not be a dictionary word.`,
  accept: 'Accept',
  goToAuthorization: 'Go to authorization',
  submittedText: 'Password set successfully!',
  signUpButton: 'Set password',
}