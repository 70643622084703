import {
  take, call, put, select,
} from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty'
import { successAddIntegration } from '@/store/actions/serviceDeskMyCity/addIntegration'
import { requestGetInfoWidget } from '@/store/actions/serviceDeskMyCity/getInfoWidget'
import { setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'
import { getIntegrationStatus, getTenantServiceDeskStatus } from '@/store/selectors/appSettings'
import { createAddIntegrationChannel } from './channels'

function* addIntegrationEventSaga() {
  try {
    const channel = yield call(createAddIntegrationChannel)
    while (true) {
      const event = yield take(channel)
      const integrationStatus = yield select(getIntegrationStatus)
      const tenantIntegrationStatus = yield select(getTenantServiceDeskStatus)
      if (!isEmpty(event) && event.status === 'ACTIVE' && !event.isUserIntegration) {
        const newEvent = {
          ...event,
          propertyConfigContent: JSON.parse(event.propertyConfigContent),
        }
        if (!isEmpty(integrationStatus)) {
          yield put(successAddIntegration([...integrationStatus, newEvent]))
          yield put(requestGetInfoWidget({
            id: event.temporaryId, serviceDeskConfig: newEvent.propertyConfigContent,
          }))
        } else {
          yield put(successAddIntegration([newEvent]))
          yield put(requestGetInfoWidget({
            id: event.temporaryId, serviceDeskConfig: newEvent.propertyConfigContent,
          }))
        }
      }
      if (!isEmpty(event) && event.status === 'ACTIVE' && event.isUserIntegration) {
        const newEvent = {
          ...event,
          propertyConfigContent: JSON.parse(event.propertyConfigContent),
        }
        if (!isEmpty(tenantIntegrationStatus)) {
          yield put(setTenantServiceDesk([...tenantIntegrationStatus, newEvent]))
        } else {
          yield put(setTenantServiceDesk([newEvent]))
        }
      }
    }
  } catch (error) {
    console.log('TCL: function*addIntegrationEventSaga -> error', error)
  }
}

export default addIntegrationEventSaga
