const kyrgan = [
  [
    55.4483877,
    65.1176161,
  ],
  [
    55.4451439,
    65.1177816,
  ],
  [
    55.4433076,
    65.1314093,
  ],
  [
    55.4427408,
    65.1356152,
  ],
  [
    55.4413958,
    65.1405404,
  ],
  [
    55.4344198,
    65.1550865,
  ],
  [
    55.4309796,
    65.168436,
  ],
  [
    55.4299145,
    65.1750544,
  ],
  [
    55.4287248,
    65.1832463,
  ],
  [
    55.4268767,
    65.1826616,
  ],
  [
    55.4196933,
    65.1768649,
  ],
  [
    55.4182104,
    65.1756684,
  ],
  [
    55.4138798,
    65.1691867,
  ],
  [
    55.3965583,
    65.177948,
  ],
  [
    55.38547,
    65.1715877,
  ],
  [
    55.3865281,
    65.1907854,
  ],
  [
    55.3766216,
    65.171583,
  ],
  [
    55.3727923,
    65.1601142,
  ],
  [
    55.3685769,
    65.1584771,
  ],
  [
    55.3680911,
    65.1617931,
  ],
  [
    55.3642951,
    65.1759181,
  ],
  [
    55.3593846,
    65.1734971,
  ],
  [
    55.3548164,
    65.1708184,
  ],
  [
    55.3470128,
    65.1536223,
  ],
  [
    55.3418634,
    65.155964,
  ],
  [
    55.3393104,
    65.1557254,
  ],
  [
    55.3364999,
    65.160282,
  ],
  [
    55.335749,
    65.1443932,
  ],
  [
    55.3318634,
    65.146949,
  ],
  [
    55.3224779,
    65.1701224,
  ],
  [
    55.3253276,
    65.1848589,
  ],
  [
    55.3268417,
    65.1900627,
  ],
  [
    55.3290978,
    65.1973297,
  ],
  [
    55.3280196,
    65.209367,
  ],
  [
    55.3441234,
    65.2150546,
  ],
  [
    55.3468514,
    65.2148427,
  ],
  [
    55.3502635,
    65.2133546,
  ],
  [
    55.3502418,
    65.228267,
  ],
  [
    55.347811,
    65.2283983,
  ],
  [
    55.346789,
    65.2292676,
  ],
  [
    55.3444963,
    65.2301808,
  ],
  [
    55.3444064,
    65.2397435,
  ],
  [
    55.3451257,
    65.2401768,
  ],
  [
    55.345219,
    65.2481437,
  ],
  [
    55.3447095,
    65.2588273,
  ],
  [
    55.3384668,
    65.2616454,
  ],
  [
    55.3353843,
    65.2646823,
  ],
  [
    55.3291045,
    65.2704918,
  ],
  [
    55.3212078,
    65.2744591,
  ],
  [
    55.3130527,
    65.2743777,
  ],
  [
    55.3124012,
    65.2752284,
  ],
  [
    55.3110855,
    65.278609,
  ],
  [
    55.3090733,
    65.2810322,
  ],
  [
    55.3086114,
    65.2820476,
  ],
  [
    55.3067129,
    65.2886537,
  ],
  [
    55.3052225,
    65.2925383,
  ],
  [
    55.302336,
    65.2977153,
  ],
  [
    55.2959546,
    65.2983072,
  ],
  [
    55.295577,
    65.3021674,
  ],
  [
    55.294338,
    65.3027411,
  ],
  [
    55.2939729,
    65.3006784,
  ],
  [
    55.2908578,
    65.3015589,
  ],
  [
    55.290749,
    65.3020173,
  ],
  [
    55.2919854,
    65.3025602,
  ],
  [
    55.2940976,
    65.307446,
  ],
  [
    55.2938801,
    65.3116903,
  ],
  [
    55.2938335,
    65.3125515,
  ],
  [
    55.2956755,
    65.3120575,
  ],
  [
    55.2979382,
    65.3109205,
  ],
  [
    55.3017444,
    65.3085259,
  ],
  [
    55.3077258,
    65.3063384,
  ],
  [
    55.3107955,
    65.3056264,
  ],
  [
    55.3154443,
    65.3050625,
  ],
  [
    55.3174965,
    65.3044754,
  ],
  [
    55.3196389,
    65.3026648,
  ],
  [
    55.3207058,
    65.3011631,
  ],
  [
    55.3217585,
    65.3015726,
  ],
  [
    55.3220918,
    65.3034808,
  ],
  [
    55.3221207,
    65.303646,
  ],
  [
    55.3225425,
    65.3060606,
  ],
  [
    55.326283,
    65.3083739,
  ],
  [
    55.3265174,
    65.3066987,
  ],
  [
    55.3286151,
    65.3064575,
  ],
  [
    55.3301314,
    65.3077702,
  ],
  [
    55.3315326,
    65.3102598,
  ],
  [
    55.3324484,
    65.3089486,
  ],
  [
    55.335028,
    65.3117503,
  ],
  [
    55.338999,
    65.3029522,
  ],
  [
    55.340847,
    65.2987769,
  ],
  [
    55.3415906,
    65.2972327,
  ],
  [
    55.341996,
    65.2980337,
  ],
  [
    55.3482205,
    65.3061959,
  ],
  [
    55.3503943,
    65.3016579,
  ],
  [
    55.354362,
    65.3072024,
  ],
  [
    55.3570603,
    65.310475,
  ],
  [
    55.3613295,
    65.3065774,
  ],
  [
    55.3615634,
    65.3070197,
  ],
  [
    55.361855,
    65.307781,
  ],
  [
    55.3617007,
    65.3093742,
  ],
  [
    55.3617952,
    65.3110157,
  ],
  [
    55.3621489,
    65.3125822,
  ],
  [
    55.3626594,
    65.3138522,
  ],
  [
    55.3581122,
    65.3207878,
  ],
  [
    55.3580245,
    65.3212438,
  ],
  [
    55.3619506,
    65.3214373,
  ],
  [
    55.3623954,
    65.3228743,
  ],
  [
    55.3612246,
    65.3255085,
  ],
  [
    55.3607657,
    65.3265442,
  ],
  [
    55.3596586,
    65.3255013,
  ],
  [
    55.3592717,
    65.32587,
  ],
  [
    55.3594381,
    65.3263348,
  ],
  [
    55.3602373,
    65.328502,
  ],
  [
    55.3601108,
    65.3296722,
  ],
  [
    55.3611346,
    65.3383527,
  ],
  [
    55.3600692,
    65.3424345,
  ],
  [
    55.3593459,
    65.3453628,
  ],
  [
    55.3589372,
    65.3468441,
  ],
  [
    55.3584909,
    65.3473493,
  ],
  [
    55.357009,
    65.3482873,
  ],
  [
    55.3566429,
    65.3489157,
  ],
  [
    55.3565354,
    65.3494757,
  ],
  [
    55.3564419,
    65.3527272,
  ],
  [
    55.3569073,
    65.3533415,
  ],
  [
    55.3591013,
    65.3563566,
  ],
  [
    55.3612183,
    65.3597503,
  ],
  [
    55.3653281,
    65.3666001,
  ],
  [
    55.3658,
    65.3673708,
  ],
  [
    55.3662577,
    65.3681465,
  ],
  [
    55.3672347,
    65.3696024,
  ],
  [
    55.3780022,
    65.3874112,
  ],
  [
    55.3812032,
    65.3923554,
  ],
  [
    55.3817333,
    65.392812,
  ],
  [
    55.382862,
    65.3927339,
  ],
  [
    55.3822794,
    65.4011814,
  ],
  [
    55.3822959,
    65.4014445,
  ],
  [
    55.3819237,
    65.4071726,
  ],
  [
    55.3815877,
    65.4118876,
  ],
  [
    55.3814018,
    65.4144972,
  ],
  [
    55.3812086,
    65.4172088,
  ],
  [
    55.3809796,
    65.4204496,
  ],
  [
    55.3804652,
    65.4277284,
  ],
  [
    55.3866648,
    65.4292632,
  ],
  [
    55.387056,
    65.4293545,
  ],
  [
    55.3895175,
    65.4299291,
  ],
  [
    55.3903761,
    65.4288215,
  ],
  [
    55.3918688,
    65.4302824,
  ],
  [
    55.3934866,
    65.4413929,
  ],
  [
    55.3970914,
    65.4488306,
  ],
  [
    55.398814,
    65.4526055,
  ],
  [
    55.399313,
    65.4519768,
  ],
  [
    55.4007721,
    65.4561698,
  ],
  [
    55.4014652,
    65.4646628,
  ],
  [
    55.4013234,
    65.4693625,
  ],
  [
    55.4028804,
    65.4739807,
  ],
  [
    55.4064627,
    65.4807371,
  ],
  [
    55.4077482,
    65.4863338,
  ],
  [
    55.4101982,
    65.5083126,
  ],
  [
    55.4083684,
    65.5233222,
  ],
  [
    55.4070831,
    65.5261397,
  ],
  [
    55.4069231,
    65.5271599,
  ],
  [
    55.4061668,
    65.5319839,
  ],
  [
    55.3994185,
    65.5348348,
  ],
  [
    55.3946066,
    65.5210273,
  ],
  [
    55.3908059,
    65.5198177,
  ],
  [
    55.3870943,
    65.5270545,
  ],
  [
    55.3853116,
    65.5290182,
  ],
  [
    55.3811558,
    65.5332823,
  ],
  [
    55.3858469,
    65.5507762,
  ],
  [
    55.3865902,
    65.5556893,
  ],
  [
    55.3882184,
    65.5709899,
  ],
  [
    55.3885681,
    65.5727786,
  ],
  [
    55.39175,
    65.5708503,
  ],
  [
    55.3918398,
    65.5712893,
  ],
  [
    55.3939556,
    65.5703025,
  ],
  [
    55.3945065,
    65.5705132,
  ],
  [
    55.3984233,
    65.5786413,
  ],
  [
    55.3986797,
    65.5790499,
  ],
  [
    55.3992734,
    65.5797491,
  ],
  [
    55.4005778,
    65.5806207,
  ],
  [
    55.4030878,
    65.580191,
  ],
  [
    55.4061312,
    65.5758852,
  ],
  [
    55.4066092,
    65.5767179,
  ],
  [
    55.4087105,
    65.5838443,
  ],
  [
    55.4118157,
    65.596021,
  ],
  [
    55.4148414,
    65.6098098,
  ],
  [
    55.4149425,
    65.6103865,
  ],
  [
    55.4166982,
    65.6093681,
  ],
  [
    55.4245437,
    65.6181364,
  ],
  [
    55.4276832,
    65.6199545,
  ],
  [
    55.4287226,
    65.6253169,
  ],
  [
    55.4298252,
    65.62872,
  ],
  [
    55.4296187,
    65.6329999,
  ],
  [
    55.4289813,
    65.6432611,
  ],
  [
    55.4292703,
    65.6652388,
  ],
  [
    55.4291561,
    65.6654083,
  ],
  [
    55.4290105,
    65.6682771,
  ],
  [
    55.4286714,
    65.6731133,
  ],
  [
    55.4301816,
    65.6688758,
  ],
  [
    55.4525066,
    65.5845103,
  ],
  [
    55.4532984,
    65.5810003,
  ],
  [
    55.4537651,
    65.5766945,
  ],
  [
    55.4544404,
    65.5623825,
  ],
  [
    55.4568401,
    65.5222646,
  ],
  [
    55.4576328,
    65.5098802,
  ],
  [
    55.4579409,
    65.5071232,
  ],
  [
    55.4588725,
    65.5025291,
  ],
  [
    55.4595737,
    65.4999254,
  ],
  [
    55.4732739,
    65.4603833,
  ],
  [
    55.476308,
    65.4520853,
  ],
  [
    55.4786557,
    65.4468277,
  ],
  [
    55.4816113,
    65.4422288,
  ],
  [
    55.4842347,
    65.4403202,
  ],
  [
    55.4843576,
    65.4421071,
  ],
  [
    55.4834134,
    65.4448987,
  ],
  [
    55.4833925,
    65.4452973,
  ],
  [
    55.4835879,
    65.4467987,
  ],
  [
    55.4843266,
    65.4473396,
  ],
  [
    55.4858782,
    65.4464895,
  ],
  [
    55.4877226,
    65.4464768,
  ],
  [
    55.488275,
    65.4485974,
  ],
  [
    55.4877618,
    65.4502212,
  ],
  [
    55.486581,
    65.4516807,
  ],
  [
    55.4856196,
    65.4538094,
  ],
  [
    55.4857507,
    65.454488,
  ],
  [
    55.4875626,
    65.4554286,
  ],
  [
    55.4880888,
    65.4553814,
  ],
  [
    55.4893593,
    65.4545891,
  ],
  [
    55.4900436,
    65.4519666,
  ],
  [
    55.4904755,
    65.4508552,
  ],
  [
    55.4908795,
    65.4506039,
  ],
  [
    55.4923652,
    65.4523382,
  ],
  [
    55.4965194,
    65.4408975,
  ],
  [
    55.4949221,
    65.4388425,
  ],
  [
    55.4975531,
    65.4323288,
  ],
  [
    55.4962167,
    65.4216182,
  ],
  [
    55.49412,
    65.4176951,
  ],
  [
    55.4925437,
    65.4101253,
  ],
  [
    55.4927903,
    65.405721,
  ],
  [
    55.4938177,
    65.4011457,
  ],
  [
    55.4972865,
    65.3909674,
  ],
  [
    55.4980691,
    65.3902662,
  ],
  [
    55.4987901,
    65.3904201,
  ],
  [
    55.4989153,
    65.3899421,
  ],
  [
    55.4983757,
    65.3888833,
  ],
  [
    55.4983467,
    65.3879317,
  ],
  [
    55.4997152,
    65.3830246,
  ],
  [
    55.5084935,
    65.3535928,
  ],
  [
    55.5086842,
    65.353345,
  ],
  [
    55.5118264,
    65.3677346,
  ],
  [
    55.5124434,
    65.3694187,
  ],
  [
    55.515962,
    65.3732166,
  ],
  [
    55.5296617,
    65.3685822,
  ],
  [
    55.5318766,
    65.3627448,
  ],
  [
    55.5321041,
    65.3417272,
  ],
  [
    55.5327948,
    65.3216391,
  ],
  [
    55.5334332,
    65.3123209,
  ],
  [
    55.525536,
    65.312401,
  ],
  [
    55.5237044,
    65.3125043,
  ],
  [
    55.5206917,
    65.3131336,
  ],
  [
    55.5171289,
    65.3121885,
  ],
  [
    55.517069,
    65.3087099,
  ],
  [
    55.5168495,
    65.3057657,
  ],
  [
    55.5162468,
    65.3006677,
  ],
  [
    55.5146082,
    65.2925935,
  ],
  [
    55.5111088,
    65.2812179,
  ],
  [
    55.502499,
    65.2538095,
  ],
  [
    55.5023656,
    65.2527488,
  ],
  [
    55.5029247,
    65.251248,
  ],
  [
    55.5027681,
    65.2507822,
  ],
  [
    55.5020656,
    65.2510001,
  ],
  [
    55.5013989,
    65.2502324,
  ],
  [
    55.499778,
    65.2451545,
  ],
  [
    55.499469,
    65.2429611,
  ],
  [
    55.4995033,
    65.2384754,
  ],
  [
    55.5002318,
    65.2331647,
  ],
  [
    55.5001908,
    65.2316287,
  ],
  [
    55.4986874,
    65.2307831,
  ],
  [
    55.4965848,
    65.2038956,
  ],
  [
    55.4970217,
    65.2025303,
  ],
  [
    55.4993942,
    65.1989056,
  ],
  [
    55.4985879,
    65.1964716,
  ],
  [
    55.4977313,
    65.1966028,
  ],
  [
    55.4962337,
    65.1996272,
  ],
  [
    55.4963095,
    65.196933,
  ],
  [
    55.4953002,
    65.1855092,
  ],
  [
    55.4946211,
    65.1506896,
  ],
  [
    55.4848297,
    65.1323782,
  ],
  [
    55.4825787,
    65.1280629,
  ],
  [
    55.472424,
    65.1311564,
  ],
  [
    55.4716754,
    65.121312,
  ],
  [
    55.465613,
    65.1358562,
  ],
  [
    55.4653196,
    65.1365601,
  ],
  [
    55.4632858,
    65.1309438,
  ],
  [
    55.4609423,
    65.1336174,
  ],
  [
    55.4574618,
    65.1371916,
  ],
  [
    55.4519947,
    65.1373342,
  ],
  [
    55.4483877,
    65.1176161,
  ],
]

export default [[...kyrgan]]
