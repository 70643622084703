import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LEPIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.5332 7.19792C6.67773 7.32986 6.75 7.48611 6.75 7.66667V10.3333C6.75 10.5208 6.67773 10.6788 6.5332 10.8073C6.38867 10.9358 6.21094 11 6 11H5.25C5.03906 11 4.86133 10.9358 4.7168 10.8073C4.57227 10.6788 4.5 10.5208 4.5 10.3333V7.66667C4.5 7.48611 4.57422 7.32986 4.72266 7.19792C4.87109 7.06597 5.04688 7 5.25 7H6C6.21094 7 6.38867 7.06597 6.5332 7.19792ZM5.36133 10.2344C5.43555 10.3003 5.52344 10.3333 5.625 10.3333C5.72656 10.3333 5.81445 10.3003 5.88867 10.2344C5.96289 10.1684 6 10.0903 6 10V8C6 7.90972 5.96289 7.8316 5.88867 7.76562C5.81445 7.69965 5.72656 7.66667 5.625 7.66667C5.52344 7.66667 5.43555 7.69965 5.36133 7.76562C5.28711 7.8316 5.25 7.90972 5.25 8V10C5.25 10.0903 5.28711 10.1684 5.36133 10.2344Z" fill={fill} className="svgFill"/>
      <path d="M9.63867 7.09896C9.71289 7.16493 9.75 7.24306 9.75 7.33333V10.6667C9.75 10.7569 9.71289 10.8351 9.63867 10.901C9.56445 10.967 9.47656 11 9.375 11C9.27344 11 9.18555 10.967 9.11133 10.901C9.03711 10.8351 9 10.7569 9 10.6667V9.66667H8.25C8.03906 9.66667 7.86133 9.60069 7.7168 9.46875C7.57227 9.33681 7.5 9.18056 7.5 9V7.33333C7.5 7.24306 7.53711 7.16493 7.61133 7.09896C7.68555 7.03299 7.77344 7 7.875 7C7.97656 7 8.06445 7.03299 8.13867 7.09896C8.21289 7.16493 8.25 7.24306 8.25 7.33333V8.66667C8.25 8.75694 8.28711 8.83507 8.36133 8.90104C8.43555 8.96701 8.52344 9 8.625 9C8.72656 9 8.81445 8.96701 8.88867 8.90104C8.96289 8.83507 9 8.75694 9 8.66667V7.33333C9 7.24306 9.03711 7.16493 9.11133 7.09896C9.18555 7.03299 9.27344 7 9.375 7C9.47656 7 9.56445 7.03299 9.63867 7.09896Z" fill={fill} className="svgFill"/>
      <path d="M12 7H12.75V7.66667C12.75 7.85417 12.6777 8.01215 12.5332 8.14062C12.3887 8.2691 12.2109 8.33333 12 8.33333H11.25C11.0391 8.33333 10.8613 8.2691 10.7168 8.14062C10.5723 8.01215 10.5 7.85417 10.5 7.66667V7H11.25V7.33333C11.25 7.42361 11.2871 7.50174 11.3613 7.56771C11.4355 7.63368 11.5234 7.66667 11.625 7.66667C11.7266 7.66667 11.8145 7.63368 11.8887 7.56771C11.9629 7.50174 12 7.42361 12 7.33333V7Z" fill={fill} className="svgFill"/>
      <path d="M12.5332 9.19271C12.6777 9.32118 12.75 9.47917 12.75 9.66667V11H11.625C11.7266 11 11.8145 10.967 11.8887 10.901C11.9629 10.8351 12 10.7569 12 10.6667V10C12 9.90972 11.9629 9.8316 11.8887 9.76562C11.8145 9.69965 11.7266 9.66667 11.625 9.66667C11.5234 9.66667 11.4355 9.69965 11.3613 9.76562C11.2871 9.8316 11.25 9.90972 11.25 10V10.6667C11.25 10.7569 11.2871 10.8351 11.3613 10.901C11.4355 10.967 11.5234 11 11.625 11H10.5V9.66667C10.5 9.47917 10.5723 9.32118 10.7168 9.19271C10.8613 9.06424 11.0391 9 11.25 9H12C12.2109 9 12.3887 9.06424 12.5332 9.19271Z" fill={fill} className="svgFill"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM14.8887 9.76562C14.8145 9.69965 14.7266 9.66667 14.625 9.66667H14.25V8.33333H14.625C14.7266 8.33333 14.8145 8.30035 14.8887 8.23438C14.9629 8.1684 15 8.09028 15 8V7.33333C15 7.24306 14.9629 7.16493 14.8887 7.09896C14.8145 7.03299 14.7266 7 14.625 7H14.25V5.66667C14.25 5.47917 14.1777 5.32118 14.0332 5.19271C13.8887 5.06424 13.7109 5 13.5 5H3.75C3.53906 5 3.36133 5.06424 3.2168 5.19271C3.07227 5.32118 3 5.47917 3 5.66667V12.3333C3 12.5208 3.07227 12.6788 3.2168 12.8073C3.36133 12.9358 3.53906 13 3.75 13H13.5C13.7109 13 13.8887 12.9358 14.0332 12.8073C14.1777 12.6788 14.25 12.5208 14.25 12.3333V11H14.625C14.7266 11 14.8145 10.967 14.8887 10.901C14.9629 10.8351 15 10.7569 15 10.6667V10C15 9.90972 14.9629 9.8316 14.8887 9.76562Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

LEPIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LEPIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LEPIcon)
