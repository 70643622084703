import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import Icons from '@/components/icons/sync'

import {
  PASSWORD,
  PORT,
  URL,
  NAME,
  LOGIN,
  INTEGRATION_ID,
} from '@/constants/forms/integration'
import {
  TEXT,
  SELECT
} from '@/constants/semanticNames'
export default (formProps) => {
  const { isSubmitting, editing } = formProps
  return [
    {
      name: INTEGRATION_ID,
      form: formProps,
      type: SELECT,
      label: {
        icon: Icons.EnterIcon,
        title: (<Lang id={`syncModule.form.${INTEGRATION_ID}`} />),
      },
      field: { disabled: isSubmitting || editing },
      options: formProps[INTEGRATION_ID] || [],
    },
    {
      name: URL,
      selector: 'connectionSettings',
      form: formProps,
      type: TEXT,
      label: {
        icon: Icons.EnterIcon,
        title: (<Lang id="syncModule.form.api" />),
      },
      field: { disabled: isSubmitting || editing },
    },
    {
      name: PORT,
      selector: 'connectionSettings',
      form: formProps,
      type: TEXT,
      field: { format: 'number', disabled: isSubmitting || editing },
      label: {
        icon: Icons.EnterIcon,
        title: (<Lang id="syncModule.form.port" />),
      },
    },
    {
      name: LOGIN,
      selector: 'connectionSettings',
      form: formProps,
      type: TEXT,
      label: {
        icon: Icons.UserIcon,
        title: (!editing
          ? <Lang id="syncModule.form.login" />
          : <Lang id="syncModule.form.notRequiredLogin" />),
      },
      field: { autoComplete: 'nickname' },
    },
    {
      name: PASSWORD,
      selector: 'connectionSettings',
      form: formProps,
      type: TEXT,
      label: {
        icon: Icons.LockIcon,
        title: (!editing
          ? <Lang id="syncModule.form.pass" />
          : <Lang id="syncModule.form.notRequiredPass" />),
      },
      field: {
        type: 'password',
        autoComplete: 'new-password',
      },
    },
    {
      name: NAME,
      form: formProps,
      type: TEXT,
      label: {
        icon: Icons.WriteIcon,
        title: (<Lang id="syncModule.form.name" />),
      },
      field: { pattern: '[a-zA-Zа-яА-Я0-9 ]', disabled: true },
    },
  ]
}
