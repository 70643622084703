import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InvertProjectIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return ( 
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9.78571 10.75C10.1714 10.4375 10.5571 10.25 11.0714 10.25C12.1643 10.25 13 11.0625 13 12.125C13 13.1875 12.1643 14 11.0714 14C9.97857 14 9.14286 13.1875 9.14286 12.125V11.8125L6.89286 10.625C6.63571 10.75 6.31429 10.875 5.92857 10.875C4.9 10.875 4 10 4 9C4 7.9375 4.9 7.125 5.92857 7.125C6.31429 7.125 6.63571 7.25 6.89286 7.375L9.14286 6.1875V5.875C9.14286 4.8125 9.97857 4 11.0714 4C12.1643 4 13 4.8125 13 5.875C13 6.9375 12.1643 7.75 11.0714 7.75C10.5571 7.75 10.1714 7.5625 9.78571 7.25L7.72857 8.375C7.79286 8.5625 7.85714 8.8125 7.85714 9C7.85714 9.1875 7.79286 9.4375 7.72857 9.625L9.78571 10.75Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

InvertProjectIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InvertProjectIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InvertProjectIcon)
