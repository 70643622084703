import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'

export default {
  form: {
    graphView: 'Вид графика:',
    buildGraph: 'построить график',
    buildTable: 'построить таблицу',
    updateGraph: 'обновить график',
    updateTable: 'обновить таблицу',
    interval: 'ручной интервал',
    fastInterval: 'быстрый интервал',
    dateRange: 'Диапазон дат',
    demonstration: 'Демонстрация:',
    savings: 'Величина потребления кВт*ч:',
    consumption: 'Величина потребления кВт*ч:',
    min: 'Минимальная:',
    average: 'Средняя:',
    max: 'Максимальная:',
    errorTitle: 'Невозможно построить график относительно выбранного формата. Измените диапазон или выберите другой интервал:',
    errorText: `Ежемесячно: не более 366 дней
Еженедельно: не более 92 дней.
Ежедневно: не более 31 дня.`,
    calculator: 'Калькулятор расходов:',
    expenseForPeriod: 'Расход за период',
    positiveEffect: 'Эффект за период (экономия)',
    negativeEffect: 'Эффект за период (перерасход)',
    units: {
      [OUTDOOR_LIGHTING]: 'Квт*ч',
      [HEAT_SUPPLY]: 'Гкал',
    },
    rate: 'Тариф',
    total: 'Итог:',
    savingValue: 'руб.',
    processType: 'Тип городского процесса',
    light: {
      saving: 'Экономия:',
      overPayment: 'Переплата',
      lose: 'Перерасход:',
    },
    heat: {
      saving: 'Недотоп:',
      lose: 'Перетоп:',
      economy: 'Экономия:',
      overpay: 'Переплата:',
    },
  },
}
