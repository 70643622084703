import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_OBJECT_IMAGE } from '@/store/actions/maps'
import { LOCAL_TO_PASSPORT } from '@/constants/passportization/types'
import { GET_PASSPORT_DATA_URL, GET_PASSPORT_FILES_URL } from '@/constants/apiRoutes'
import { errorObjectImage, successObjectImage } from '@/store/actions/maps/getObjectImage'
import getQueryParams from '@/helpers/getQueryParams'

function* getObjectImage({ payload: { node } }) {
  try {
    const { type, name } = node
    if (type && name && LOCAL_TO_PASSPORT[type]) {
      const response = yield request({
        url: `${
          GET_PASSPORT_DATA_URL
        }/?${
          getQueryParams({ identifier: name, installationType: LOCAL_TO_PASSPORT[type] })
        }`,
        method: 'get',
      })
      if (response && response.data && response.data.photo) {
        const responsePhoto = yield request({
          url: `${GET_PASSPORT_FILES_URL}?id=${response.data.photo.uid}`,
          method: 'get',
          options: {
            responseType: 'blob',
          },
        })
        const newBlob = new window.Blob([responsePhoto.data], { type: 'application/octet-stream' })
        const fileURL = window.URL.createObjectURL(newBlob)
        yield put(successObjectImage(fileURL))
      }
    }
  } catch (error) {
    yield put(errorObjectImage(error))
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_IMAGE.REQUEST, getObjectImage)
}
