import { GET_PASSPORT_PHOTO } from './index'

export const requestGetPassportPhoto = (payload) => ({
  type: GET_PASSPORT_PHOTO.REQUEST,
  payload,
})

export const responseGetPassportPhoto = (payload) => ({
  type: GET_PASSPORT_PHOTO.SUCCESS,
  payload,
})

export const errorGetPassportPhoto = (payload) => ({
  type: GET_PASSPORT_PHOTO.ERROR,
  payload,

})
