import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SyncIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 5C18.9737 5 23 9.04762 23 14.0476C23 15.9502 22.4282 17.781 21.3467 19.2903L22.9526 20.9047L17.3158 21.6666L18.3105 16.2381L19.9832 17.9196C20.7066 16.7756 21.1053 15.427 21.1053 14.0476C21.1053 10.0952 17.9316 6.90476 14 6.90476C13.5737 6.90476 13.1 6.95238 12.6737 7.04762L12.3421 5.19048C12.8632 5.04762 13.4316 5 14 5Z" fill={fill} className="svgFill" />
      <path d="M5.04737 7.19047L10.6842 6.42857L9.68947 11.8571L8.00955 10.1683C7.28425 11.3195 6.89474 12.6597 6.89474 14C6.89474 17.9524 10.0684 21.1429 14 21.1429C14.4263 21.1429 14.9 21.0952 15.3263 21L15.6579 22.8571C15.0895 22.9524 14.5211 23 14 23C9.02632 23 5 18.9524 5 13.9524C5 12.1582 5.57182 10.3257 6.64706 8.79863L5.04737 7.19047Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SyncIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SyncIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SyncIcon)
