import { CREATE_INTEGRATION } from './index'

export const requestCreateIntegration = (payload) => ({
  type: CREATE_INTEGRATION.REQUEST,
  payload,
})

export const successCreateIntegration = (payload) => ({
  type: CREATE_INTEGRATION.SUCCESS,
  payload,
})

export const errorCreateIntegration = (payload) => ({
  type: CREATE_INTEGRATION.ERROR,
  payload,
})
