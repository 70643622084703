import styled from 'styled-components'
import { Form } from 'formik'
import { Container as SwitchButton } from '@/components/controls/SwitchButton/styles'
import LabelWithIcon from '@/components/blocks/LabelWithIcon/styles'
import { CollapsedTitle, CollapsedBlockStyled } from '@/components/blocks/CollapsedBlock/styles'

export const Container = styled.div`
  width: 100%;
`

export const IntegrationViewContainer = styled.div`
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 27px;
`

export const CollapsedBlockOptions = styled.div`
  width: 100%;
  && ${CollapsedTitle} {
    height: 30px;
    color: ${({ theme }) => theme.colors.colors.redisign.secondary};
  }
  && > div {
    padding: 10px 0px;
  }
`

export const IntegrationContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  border-top: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  margin-top: 15px;
`
export const SearchContainer = styled.div`
  padding: 10px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
`
export const ElementContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
  color: ${({ theme }) => theme.colors.colors.title};
`
export const OptionName = styled.div`
  font-family: Istok Web;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  padding-left: 10px;
`

export const TypeWrapper = styled.div`
  padding: 0px 10px;
`

export const TitleAndIcon = styled.div`
  svg {
    margin-left: -5px;
    margin-right: 10px;
  }
`

export const FormWrapper = styled(Form)`
  ${CollapsedBlockStyled} {
    margin-top: 0px;
  }
  ${CollapsedTitle} {
    height: 48px;
  }
  padding-bottom: 20px;
  height: auto;

  ${SwitchButton} {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${LabelWithIcon} {
    margin-bottom: 10px;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`
