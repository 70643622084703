import React from 'react'
import LiveTime from '@/components/icons/livetime'
import ColoredTextCell from '@/components/blocks/DutyTable/customCells/ColoredTextCell'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'

export const fields = [
  {
    id: 'geoZone',
    name: 'Геозона',
    value: 'ASC',
  },
  {
    id: 'installationCount',
    name: 'Инсталляция',
    value: 'ASC',
  },
  {
    id: 'warningCount',
    name: 'Предупреждение',
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={WARNING} />,
  },
  {
    id: 'warningRate',
    name: 'Коэффициент предупреждений',
    value: 'ASC',
  },
  {
    id: 'malfunctioningCount',
    name: 'Неисправно',
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={ERROR} />,
  },
  {
    id: 'malfunctioningRate',
    name: 'Коэффициент неисправностей',
    value: 'ASC',
  },
  {
    id: 'serviceableCount',
    name: 'Исправно',
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={OK} />,
  },
  {
    id: 'serviceableRate',
    name: 'Коэффициент исправных',
    value: 'ASC',
  },
  {
    id: 'undefinedCount',
    name: 'Не определено',
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={UNKNOWN} />,
  },
  {
    id: 'undefinedRate',
    name: 'Коэффициент неопределенных',
    value: 'ASC',
  },
]

// TODO: move to one for all tables
export const exportOptions = [
  {
    name: 'EXCEL',
    value: 'xlsx',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]

export default {
  fields,
  exportOptions,
}
