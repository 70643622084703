import styled from 'styled-components'
import TextField from '@/components/fields/TextField'

export const Form = styled.form`
  width: 100%;
`
export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.colors.disabled};
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-bottom: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
`
export const IconContainer = styled.div`
  margin-left: 10px;
`

export const FormTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.colors.disabled};
  text-align: left;
  margin-bottom: 10px;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;

  > button {
    margin-left: 5px;
  }
`

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const Label = styled.label`
  min-width: 170px;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.colors.charts};
`

export const TextFieldStyled = styled(TextField)`
  width: 60px;
`
