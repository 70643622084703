import React from 'react'
import { Formik } from 'formik'
import LabledField from '@/components/blocks/LabledField'
import ValueSelectField from '@/components/fields/ValueSelectField'
import SelectField from '@/components/fields/SelectField'
import SwitchButton from '@/components/fields/SwitchButtonField'
import {
  Form,
  Title,
} from './styles'

const ObjectControlForm = () => {
  const onSubmit = (values, form) => {
    // TODO: add handlers
  }
  return (
    <Formik
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} id="userData" name="userData">
          <LabledField
            title={<Title>Управление (яркость)</Title>}
            field={(
              <ValueSelectField
                name="light"
                min={0}
                max={100}
                step={1}
              />
            )}
          />
          <LabledField
            title={<Title>Управление (цветовая темп.)</Title>}
            field={(
              <ValueSelectField
                name="temp"
                min={0}
                max={100}
                step={1}
              />
            )}
          />
          <LabledField
            title={<Title>Управление (показатели)</Title>}
            field={(
              <>
                <SwitchButton
                  darkLabel
                  bottomMargin
                  name="param-0"
                  label="Напряжение"
                  enabledLabel="Напряжение"
                />
                <SwitchButton
                  darkLabel
                  bottomMargin
                  name="param-1"
                  label="Ток"
                  enabledLabel="Ток"
                />
                <SwitchButton
                  darkLabel
                  bottomMargin
                  name="param-2"
                  label="Мощность"
                  enabledLabel="Мощность"
                />
                <SwitchButton
                  darkLabel
                  bottomMargin
                  name="param-3"
                  label="Сопротивление"
                  enabledLabel="Сопротивление"
                />
              </>
            )}
          />
          <LabledField
            title={<Title>Назначить контроллер</Title>}
            field={(
              <SelectField
                name="controller"
                placeholder="Выберите контроллер"
                options={[
                  { value: 'Controller-Z900', title: 'Controller-Z900' },
                  { value: 'Controller-Z901', title: 'Controller-Z901' },
                  { value: 'Controller-Z902', title: 'Controller-Z902' },
                  { value: 'Controller-Z903', title: 'Controller-Z903' },
                  { value: 'Controller-Z904', title: 'Controller-Z904' },
                  { value: 'Controller-Z905', title: 'Controller-Z905' },
                  { value: 'Controller-Z906', title: 'Controller-Z906' },
                  { value: 'Controller-Z907', title: 'Controller-Z907' },
                  { value: 'Controller-Z908', title: 'Controller-Z908' },
                  { value: 'Controller-Z909', title: 'Controller-Z909' },
                  { value: 'Controller-Z910', title: 'Controller-Z910' },
                  { value: 'Controller-Z911', title: 'Controller-Z911' },
                  { value: 'Controller-Z912', title: 'Controller-Z912' },
                  { value: 'Controller-Z913', title: 'Controller-Z913' },
                  { value: 'Controller-Z914', title: 'Controller-Z914' },
                  { value: 'Controller-Z915', title: 'Controller-Z915' },
                  { value: 'Controller-Z916', title: 'Controller-Z916' },
                  { value: 'Controller-Z917', title: 'Controller-Z917' },
                ]}
              />
            )}
          />
        </Form>
      )}
    />
  )
}

export default ObjectControlForm
