import {
  takeLatest, select, put, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import updateNodeCount from '@/helpers/updateNodeCount'
import updatePath from '@/helpers/updatePath'
import {
  getCard,
} from '@/store/selectors/installation'
import { successEditGeoZone } from '@/store/actions/installation/editGeoZone'
import { EDIT_GEOZONE } from '@/store/actions/installation'
import { INSTALLATIONS_GEOZONE_ENDPOINT } from '@/constants/apiRoutes'

function* editGeoZoneSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    city, lat, lon, mark, name, parentId, region,
  } = values
  const cardCopy = yield select(getCard)
  try {
    const body = {
      ...cardCopy.data,
      geoZoneName: name,
      city,
      region,
      geoLocationDto: {
        ...cardCopy.data.geoLocationDto,
        latitude: lat,
        longitude: lon,
      },
      note: mark,
      parentTreeElementId: parentId,
    }
    const { data } = yield request({
      url: INSTALLATIONS_GEOZONE_ENDPOINT,
      method: 'put',
      body,
    })
    data.name = data.geoZoneName
    const {
      id,
      rootId,
      path,
      type,
      FILTER,
    } = formValues
    const newPath = updatePath(path, parentId, `${data.parentTreeElementId}/${data.treeElementId}`)
    const tree = treeHandlers.trees['installations-tree']
    const node = tree.instance.getNodeById(data.treeElementId)
    if (cardCopy.data.parentTreeElementId !== data.parentTreeElementId) {
      const prevParentNode = tree.instance.getNodeById(cardCopy.data.parentTreeElementId)
      const prevParentNodePath = prevParentNode.options.path
      const prevParentChildren = yield call(prevParentNode.data.getChildren, prevParentNode)
      const newParentNode = tree.instance.getNodeById(data.parentTreeElementId)
      const newParentNodePath = newParentNode.options.path
      const newParentChildren = yield call(newParentNode.data.getChildren, newParentNode)
      yield tree.handlers.setRawChildren(prevParentNode, prevParentChildren, 'first', true)
      updateNodeCount(tree, prevParentNodePath, 'remove', node.data.childrenCount + 1)
      yield tree.handlers.setRawChildren(newParentNode, newParentChildren, 'first', true)
      updateNodeCount(tree, newParentNodePath, 'add', node.data.childrenCount + 1)
      tree.handlers.setOpen(newParentNode, true)
      tree.handlers.setSelected(node, true)
    } else {
      tree.handlers.setNodeData(data.treeElementId, {
        ...node.data,
        name: data.name,
        elementName: data.name,
      })
    }
    tree.handlers.rerender()
    setUrlFormValues({
      id,
      rootId,
      parentGeoZoneId: data.parentTreeElementId,
      parentTreeId: data.parentTreeElementId,
      path: newPath,
      type,
      FILTER,
    })
    yield put(successEditGeoZone())
  } catch (error) {
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*editGeoZoneSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(EDIT_GEOZONE.REQUEST, editGeoZoneSaga)
}
