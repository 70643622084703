import React, { useEffect } from 'react'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import SidebarTree from '@/components/blocks/SidebarTree'
import TextControl from '@/components/controls/TextControl'
import ObjectControllStatisticFilter from '@/forms/ObjectControllStatisticFilter'
import DutyTable from '@/components/blocks/DutyTable'
import BarChart from '@/components/charts/BarChart'
import noop from 'lodash/noop'
import UsersIcons from '@/components/icons/users'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import useReduxTable from '@/hooks/useReduxTable'
import { REQUEST_STATUSES } from '@/constants/requests'
import { fields } from '@/constants/tablesConfig/ObjectControllStatistic'
import { GENERATE_TABLE } from '@/store/actions/objectControllStatistic'
import { getTableParameters } from '@/store/selectors/objectControllStatistic'
import { seriesForObjectControllStatistic, formatDataForGraph } from '@/constants/graph/ObjectControllStatistic'
import {
  MIDDLE_RED,
} from '@/constants/styles/defaultTheme'
import {
  TABLE,
  GRAPH,
} from '@/constants/ObjectControllStatistic'
import {
  PageContainer,
  Container,
  ItemContainer,
} from './styles'

const TREE_ID = 'ObjectControllStatistic-tree'

const ObjectControllStatistic = ({
  requestGetMapObjects,
  requestGetTableData,
  treeData,
  data,
}) => {
  const tableProps = useReduxTable(GENERATE_TABLE, getTableParameters)
  useEffect(() => {
    requestGetMapObjects()
  }, [requestGetMapObjects])

  const [formValues, setFormValues] = useUrlParseAndPush()
  // const [filterFormValues, setFilterFormValues] = useState({})

  const setSelectedNodeInUrl = (node) => {
    const { id } = node
    setFormValues({
      id,
    })
    return null
  }

  const viewTreeOnSelect = (node, actionType) => {
    if (actionType === 'click') {
      setSelectedNodeInUrl(node)
    }
    return null
  }

  return (
    <PageContainer>
      <PageSidebar
        title={'Статистика по\nобъектам контроля'}
        content={(
          <SidebarTree
            withSort
            id={TREE_ID}
            data={treeData}
            onSelectNode={viewTreeOnSelect}
          />
        )}
        headerContent={(
          <Lang id="mapsPage.titles.search">
            {(placeholder) => (
              <TextControl
                dark
                placeholder={placeholder}
                name="search"
                icon={UsersIcons.MagnifierIcon}
              />
            )}
          </Lang>
        )}
      />
      <Container>
        <ObjectControllStatisticFilter
          getData={requestGetTableData}
          // setFormValues={setFilterFormValues}
        />
        {formValues.structure === TABLE && (
        <DutyTable
          {...tableProps}
          withHeader={false}
          fields={fields}
          data={data}
          onUpdateData={requestGetTableData}
        />
        )}
        {formValues.structure === GRAPH && (
        <ItemContainer>
          <BarChart
            data={formatDataForGraph(data)}
            seriesNames={seriesForObjectControllStatistic}
            removeVerticalGrid
            mainAxisName="dateStep"
            tickFormatter={1}
            customDomain={() => 100}
            unit="%"
            customGraphMargin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 20,
            }}
            legend={{
              align: 'center',
              verticalAlign: 'bottom',
              iconType: 'square',
              title: 'Число работающих объектов:',
            }}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 100]}
            customTickPosition={2}
            ReferenceLineConfig={{
              y: 95,
              stroke: MIDDLE_RED,
              strokeDasharray: '3 3',
              alwaysShow: true,
            }}
            CartesianGridconfig={{
              strokeDasharray: '3 3',
              horizontalPoints: true,
            }}
            brush={{
              height: 18,
              travellerWidth: 10,
              dataKey: 'dateStep',
            }}
          />
        </ItemContainer>
        )}
      </Container>
    </PageContainer>
  )
}

ObjectControllStatistic.propTypes = {
  dataRequestStatus: pt.oneOfType([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.ERROR,
  ]),
  requestGetMapObjects: pt.func,
  requestGetTableData: pt.func,
  treeData: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),

}
ObjectControllStatistic.defaultProps = {
  requestGetMapObjects: noop,
  requestGetTableData: noop,
  dataRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  treeData: {},
  data: [],
}

export default ObjectControllStatistic
