import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InstallObjectsIcon = ({ color, onClick }) => {
  const {
    fill,
    stroke,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g id="Group_394" data-name="Group 394" transform="translate(205 -410)">
        <path id="Subtraction_10" data-name="Subtraction 10" d="M35,64H29a2,2,0,0,1-2-2V55.907a24.118,24.118,0,0,1-7.845-2.946l-4.812,4.812a2,2,0,0,1-2.829,0L6.226,52.486a2,2,0,0,1,0-2.828l4.692-4.692A24.113,24.113,0,0,1,7.692,37H2a2,2,0,0,1-2-2V29a2,2,0,0,1,2-2H7.692a24.111,24.111,0,0,1,3.226-7.966L6.226,14.342a2,2,0,0,1,0-2.829l5.287-5.287a2,2,0,0,1,2.829,0l4.812,4.812A24.118,24.118,0,0,1,27,8.093V2a2,2,0,0,1,2-2h6a2,2,0,0,1,2,2V8.3a24.114,24.114,0,0,1,7.466,3.117l5.192-5.192a2,2,0,0,1,2.828,0l5.287,5.287a2,2,0,0,1,0,2.829l-5.313,5.313A24.117,24.117,0,0,1,55.308,27H62a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H55.308a24.125,24.125,0,0,1-2.847,7.346l5.313,5.313a2,2,0,0,1,0,2.828l-5.287,5.287a2,2,0,0,1-2.828,0l-5.192-5.192A24.114,24.114,0,0,1,37,55.7V62A2,2,0,0,1,35,64ZM31.5,11.678A20.322,20.322,0,1,0,51.823,32,20.346,20.346,0,0,0,31.5,11.678Z" transform="translate(-205 410)" fill={fill} className="svgFill" />
        <g id="Ellipse_17" data-name="Ellipse 17" transform="translate(-185 431)" fill="none" stroke={stroke} className="svgStroke" strokeWidth="4">
          <circle cx="11.212" cy="11.212" r="11.212" stroke="none" />
          <circle cx="11.212" cy="11.212" r="9.212" fill="none" />
        </g>
      </g>
    </svg>
  )
}

InstallObjectsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InstallObjectsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InstallObjectsIcon)
