import styled from 'styled-components'

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: ${({ theme }) => theme.colors.backgrounds.header};
  box-shadow: -8px 12px 18px ${({ theme }) => theme.colors.shadows.widget};
  border-radius: 4px;
  width: 100%;
  height: 100%;

  button {
    padding: 5px;
  }
`

export const WidgetDescription = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 40px;
`
