import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ActivityLogIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 23.5C14 22.6716 14.6716 22 15.5 22H29.5C30.3284 22 31 22.6716 31 23.5C31 24.3284 30.3284 25 29.5 25H15.5C14.6716 25 14 24.3284 14 23.5Z" fill={fill} className="svgFill" />
      <path d="M15.5 29C14.6716 29 14 29.6716 14 30.5C14 31.3284 14.6716 32 15.5 32H25.5C26.3284 32 27 31.3284 27 30.5C27 29.6716 26.3284 29 25.5 29H15.5Z" fill={fill} className="svgFill" />
      <path d="M15.5 36C14.6716 36 14 36.6716 14 37.5C14 38.3284 14.6716 39 15.5 39H33.5C34.3284 39 35 38.3284 35 37.5C35 36.6716 34.3284 36 33.5 36H15.5Z" fill={fill} className="svgFill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 6C19 7.18951 19.3462 8.29819 19.9432 9.23077H11C8.79086 9.23077 7 11.0216 7 13.2308V46C7 48.2091 8.79086 50 11 50H39C41.2091 50 43 48.2091 43 46V13.2308C43 11.0216 41.2091 9.23077 39 9.23077H30.0568C30.6538 8.29819 31 7.18951 31 6C31 2.68629 28.3137 0 25 0C21.6863 0 19 2.68629 19 6ZM11 12.2308C10.4477 12.2308 10 12.6785 10 13.2308V46C10 46.5523 10.4477 47 11 47H39C39.5523 47 40 46.5523 40 46V13.2308C40 12.6785 39.5523 12.2308 39 12.2308H36V14C36 15.1046 35.1046 16 34 16H16C14.8954 16 14 15.1046 14 14V12.2308H11ZM24.9983 9.00001C26.6551 9.00001 27.9983 7.65686 27.9983 6.00001C27.9983 4.34316 26.6551 3.00001 24.9983 3.00001C23.3414 3.00001 21.9983 4.34316 21.9983 6.00001C21.9983 7.65686 23.3414 9.00001 24.9983 9.00001Z" fill={fill} className="svgFill" />
    </svg>

  )
}

ActivityLogIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ActivityLogIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ActivityLogIcon)
