import styled from 'styled-components'

export const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 7.5px;
`

export const StaticWidgetsContainer = styled.div`
  max-width: 1051px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
`

export const StaticWidgetsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const CustomWidgetsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 50%;
`

export const CustomWidgetWrapper = styled.div`
  max-width: 290px;
  max-height: 275px;
  width: 290px;
  height: 275px;
  margin: 7.5px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  box-shadow: 5px 5px 10px ${({ theme }) => theme.colors.shadows.widgetPrimary},
    -5px -5px 10px ${({ theme }) => theme.colors.shadows.widgetSecondary};
`
