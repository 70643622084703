import { DELETE_PROJECT } from './index'

export const requestDeleteProject = (payload) => ({
  type: DELETE_PROJECT.REQUEST,
  payload,
})

export const successDeleteProject = (payload) => ({
  type: DELETE_PROJECT.SUCCESS,
  payload,
})

export const errorDeleteProject = (payload) => ({
  type: DELETE_PROJECT.ERROR,
  payload,
})
