import React, { useCallback, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import SelectControl from '@/components/controls/SelectControl'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import UsersIcons from '@/components/icons/users'
import {
  TENANT,
  TENANT_EDIT,
  ADMIN,
  USER,
  GROUP,
  GROUPS_EDIT,
} from '@/constants/viewTree'
import {
  superAdminOption,
  adminOption,
} from './config'
import {
  Container,
  AddButtonContainer,
} from './styles'

const AdminsSortPanel = ({
  type,
  startCreateAdmin,
  startCreateUser,
  userRole,
  createTenantHandler,
  createGroupHandler,
  setFormIsModifying,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleSetPopup = useCallback((value) => () => {
    setIsPopupOpen(value)
  }, [])
  const handleAddButton = useCallback((value) => {
    if (value === USER) {
      if (type === GROUP || type === GROUPS_EDIT) {
        setFormIsModifying(true)
        startCreateUser()
      } else {
        setIsPopupOpen(true)
      }
    }
    if (value === ADMIN) {
      if (type === TENANT || type === TENANT_EDIT) {
        setFormIsModifying(true)
        startCreateAdmin()
      } else {
        setIsPopupOpen(true)
      }
    }
    if (value === GROUP && userRole.isAdmin) {
      setFormIsModifying(true)
      createGroupHandler()
    }
    if (value === TENANT && userRole.isSuperAdmin) {
      setFormIsModifying(true)
      createTenantHandler()
    }
  },
  [
    createGroupHandler,
    createTenantHandler,
    startCreateAdmin,
    startCreateUser,
    type,
    userRole.isAdmin,
    userRole.isSuperAdmin,
    setFormIsModifying,
  ])

  return (
    <Container>
      {isPopupOpen && (
        <GlobalPopup
          content={(
            <GlobalPopupContent
              type="warning"
              onClose={handleSetPopup(false)}
              title={<Lang id="usersPage.popup.impossibleExecute" />}
              message={
                userRole.isSuperAdmin
                  ? <Lang id="usersPage.popup.warningOrganization" />
                  : <Lang id="usersPage.popup.warningGroup" />
              }
              config={{
                warning: {
                  icon: MenuIcon.AttentionIcon,
                  buttons: [
                    {
                      statusType: 'warning',
                      onClickSelector: handleSetPopup(false),
                      title: <Lang id="usersPage.popup.accept" />,
                    },
                  ],
                },
              }}
            />
            )}
        />
      )}
      <AddButtonContainer>
        <SelectControl
          placeholder={<Lang id="usersPage.formHeader.addButton" />}
          options={userRole.isSuperAdmin ? superAdminOption : adminOption}
          customPadding={{
            left: '10px',
            right: '10px',
            top: '15px',
            bottom: '15px',
          }}
          disabled={userRole.isUser}
          optionFontSize="1.2rem"
          onChange={handleAddButton}
          selectIcon={<UsersIcons.PlusIcon />}
        />
      </AddButtonContainer>
    </Container>
  )
}

AdminsSortPanel.defaultProps = {
  type: '',
  startCreateAdmin: noop,
  startCreateUser: noop,
}

AdminsSortPanel.propTypes = {
  type: pt.string,
  startCreateAdmin: pt.func,
  startCreateUser: pt.func,
}

export default AdminsSortPanel
