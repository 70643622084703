import createAction from '@/helpers/redux/createAction'

export const GET_TYUMEN_MESSAGES_LIST = createAction('@/GET_TYUMEN_MESSAGES_LIST')
export const GET_TYUMEN_MESSAGES_WIDGET = createAction('@/GET_TYUMEN_MESSAGES_WIDGET')
export const GET_TYUMEN_MESSAGE = createAction('@/GET_TYUMEN_MESSAGE')

export const GET_TYUMEN_GRAPHS = createAction('@/GET_TYUMEN_GRAPHS')
export const GET_TYUMEN_DATE_GRAPH = createAction('@/GET_TYUMEN_DATE_GRAPH')

export const TYUMEN_MESSAGES_SET_SELECTED_ELEMENT = '@/TYUMEN_MESSAGES_SET_SELECTED_ELEMENT'
export const TYUMEN_MESSAGES_SET_SEARCH = '@/TYUMEN_MESSAGES_SET_SEARCH'
export const TYUMEN_MESSAGES_SET_STATUS = '@/TYUMEN_MESSAGES_SET_STATUS'
export const TYUMEN_MESSAGES_SET_DATES = '@/TYUMEN_MESSAGES_SET_DATES'

export const setSelectedElement = (id) => ({
  type: TYUMEN_MESSAGES_SET_SELECTED_ELEMENT,
  payload: id,
})

export const setSearch = (search) => ({
  type: TYUMEN_MESSAGES_SET_SEARCH,
  payload: search,
})

export const setStatus = (status) => ({
  type: TYUMEN_MESSAGES_SET_STATUS,
  payload: status,
})

export const setDates = (dates) => ({
  type: TYUMEN_MESSAGES_SET_DATES,
  payload: dates,
})
