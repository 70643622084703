import { all } from 'redux-saga/effects'
import authorizationRoot from './authorization'
import restorationRoot from './restoration'
import setPasswordRoot from './setPassword'
import logoutRoot from './logout'
import forgotPasswordRoot from './forgotPassword'
import resetPasswordRoot from './resetPassword'
import settingsRoot from './settings'
import validateRefreshRoot from './validateRefresh'
import userPingRoot from './userPing'

export default function* root() {
  yield all([
    authorizationRoot(),
    restorationRoot(),
    setPasswordRoot(),
    logoutRoot(),
    forgotPasswordRoot(),
    resetPasswordRoot(),
    settingsRoot(),
    validateRefreshRoot(),
    userPingRoot(),
  ])
}
