import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generatePutAdmin } from '@/constants/apiRoutes'
import {
  USER,
  USER_EDIT,
  USER_EDIT_FROM_ADMIN,
} from '@/constants/viewTree'
import { isAdmin } from '@/helpers/userPermissions'
import { getUserData } from '@/store/selectors/appSettings'
import {
  GET_USER,
  setEntity,
  updateSelectedUserType,
} from '@/store/actions/users'

function* getUserSaga({ payload }) {
  const { id } = payload
  try {
    const loginUserData = yield select(getUserData)
    const { id: authorizeUserId, role } = loginUserData
    const response = yield request({
      url: generatePutAdmin(id),
      method: 'get',
    })
    const responseData = {
      ...response.data,
      name: response.data.firstName,
      type: USER,
      isMe: response.data.id === authorizeUserId,
      children: [],
    }
    if (responseData.enabled && (responseData.lastName || responseData.id === authorizeUserId)) {
      yield put(setEntity(responseData))
      yield put(updateSelectedUserType(
        (isAdmin(role) || responseData.id !== authorizeUserId)
          ? USER_EDIT_FROM_ADMIN
          : USER_EDIT,
      ))
    } else {
      yield put(setEntity(responseData))
      yield put(updateSelectedUserType(USER))
    }
  } catch (error) {
    console.log('TCL: function*getUserSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(GET_USER.REQUEST, getUserSaga)
}
