import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckBoxWrapper = styled.div`
  width: 48px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ImageContainer = styled.div`
  background-image: url(${({ url }) => url || 'http://imagesadd.com/fileupload/source/anhtulieuthietke/album01-25032017/6qUwnS.jpg'});
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-left: 15px;
  margin-right: 10px;
`
export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.disabled}
  ${({ isOnline, theme }) => isOnline && `
    color: ${theme.colors.messages.ok}
  `}
  ${({ blocked, theme }) => blocked && `
    color: ${theme.colors.messages.error}
  `}
`
