import { handleActions } from 'redux-actions'
import get from 'lodash/get'
import { REQUEST_STATUSES } from '@/constants/requests'
import { SET_SELECTED_NODE } from '@/store/actions/appSettings'
import {
  GET_PASSPORT_FILE,
  GET_PASSPORT_PHOTO,
  ADD_PASSPORT_PHOTO,
  ADD_PASSPORT_FILE,
  DELETE_PASSPORT_PHOTO,
  DELETE_PASSPORT_FILE,

  GET_PASSPORT_AND_CARD_DATA,
  GET_OBJECT_EQUIPMENT,
  GET_OPERATED_ELEMENT,
  SET_SELECTED_FIELDS,
  SET_OPERATED_ELEMENT,

  PUT_PASSPORT_AND_CARD_DATA,
} from '@/store/actions/passportization'

const initialState = {
  requestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  operatedElement: {},
  selectedElementEquipment: [],
  passportData: {},
  cardData: {},
  selectedFields: [],
  data: {},
}

export const reducer = handleActions(
  {
    [GET_PASSPORT_FILE.SUCCESS]: (state, { payload: { file } }) => ({
      ...state,
      file,
    }),
    [GET_PASSPORT_PHOTO.SUCCESS]: (state, { payload: { photo } }) => ({
      ...state,
      data: {
        ...state.data,
        inner: {
          ...get(state, 'data.inner', {}),
          photo: {
            ...get(state, 'data.inner.photo', {}),
            value: photo,
          },
        },
      },
    }),
    [DELETE_PASSPORT_FILE.SUCCESS]: (state) => ({
      ...state,
      file: null,
    }),
    [DELETE_PASSPORT_PHOTO.SUCCESS]: (state) => ({
      ...state,
      photo: null,
    }),
    [ADD_PASSPORT_PHOTO.SUCCESS]: (state) => ({
      ...state,
    }),
    [ADD_PASSPORT_FILE.SUCCESS]: (state) => ({
      ...state,
    }),
    [SET_SELECTED_NODE]: (state) => ({
      ...state,
      operatedElement: {},
      data: {},
      selectedElementEquipment: [],
    }),
    [SET_OPERATED_ELEMENT]: (state, { payload }) => ({
      ...state,
      operatedElement: payload,
      data: {},
    }),
    [SET_SELECTED_FIELDS]: (state, { payload }) => ({
      ...state,
      selectedFields: payload,
    }),
    [GET_PASSPORT_AND_CARD_DATA.REQUEST]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_PASSPORT_AND_CARD_DATA.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      requestStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_PASSPORT_AND_CARD_DATA.ERROR]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.ERROR,
    }),
    [GET_OBJECT_EQUIPMENT.REQUEST]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_OBJECT_EQUIPMENT.SUCCESS]: (state, { payload }) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.IDLE,
      selectedElementEquipment: payload,
    }),
    [GET_OBJECT_EQUIPMENT.ERROR]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.ERROR,
    }),
    [GET_OPERATED_ELEMENT.REQUEST]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.PENDING,
      data: {},
    }),
    [GET_OPERATED_ELEMENT.SUCCESS]: (state, { payload }) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.IDLE,
      operatedElement: payload,
      data: {},
    }),
    [GET_OPERATED_ELEMENT.ERROR]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.ERROR,
    }),
    [PUT_PASSPORT_AND_CARD_DATA.REQUEST]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.PENDING,
    }),
    [PUT_PASSPORT_AND_CARD_DATA.SUCCESS]: (state, { payload }) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.IDLE,
      data: payload,
    }),
    [PUT_PASSPORT_AND_CARD_DATA.ERROR]: (state) => ({
      ...state,
      requestStatus: REQUEST_STATUSES.ERROR,
    }),
  },
  initialState,
)

export default reducer
