/**
 * Function for creating an object of action names
 *
 * @param {string} actionName - prefix of the action name
 */
const createTableActions = (actionName) => ({
  SORT: `${actionName}_SORT`,
  QUERY: `${actionName}_QUERY`,
  REQUEST_QUERY: `${actionName}_REQUEST_QUERY`,
  SELECTED_FIELDS: `${actionName}_SELECTED_FIELDS`,
  TOTAL_RESULTS: `${actionName}_TOTAL_RESULTS`,
  PER_PAGE: `${actionName}_PER_PAGE`,
  PAGE: `${actionName}_PAGE`,
})

export default createTableActions
