import React from 'react'

import {
  Container,
  Statistic,
  Counts,
  Dynamic,
  Percent,
  Line,
  ActiveLine,
} from './styles'

const ProgressLine = ({
  count,
  doneCount,
  dynamic,
  color,
  onClick,
}) => {
  const percent = Math.ceil((doneCount / count) * 100) || 0
  return (
    <Container onClick={onClick}>
      <Statistic>
        <Counts>
          <div>
            {'('}
            {doneCount}
            {' / '}
            {count}
            {')'}
          </div>
          {!!dynamic && (
            <Dynamic positive={dynamic > 0} color={color}>
              {dynamic > 0
                ? (<i className="fa fa-arrow-up fa-2" aria-hidden="true" />)
                : (<i className="fa fa-arrow-down fa-2" aria-hidden="true" />)}
              {' '}
              {Math.abs(dynamic)}
              {'%'}
            </Dynamic>
          )}
        </Counts>
        <Percent>
          {percent}
          {' %'}
        </Percent>
      </Statistic>
      <Line>
        <ActiveLine color={color} width={percent} />
      </Line>
    </Container>
  )
}

export default ProgressLine
