import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SecurityIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.25 11H18.375V9.28571C18.375 6.92 16.415 5 14 5C11.585 5 9.625 6.92 9.625 9.28571V11H8.75C7.7875 11 7 11.7714 7 12.7143V21.2857C7 22.2286 7.7875 23 8.75 23H19.25C20.2125 23 21 22.2286 21 21.2857V12.7143C21 11.7714 20.2125 11 19.25 11ZM11.375 9.28571C11.375 7.86286 12.5475 6.71429 14 6.71429C15.4525 6.71429 16.625 7.86286 16.625 9.28571V11H11.375V9.28571ZM19.25 21.2857H8.75V12.7143H19.25V21.2857ZM14 18.7143C14.9625 18.7143 15.75 17.9429 15.75 17C15.75 16.0571 14.9625 15.2857 14 15.2857C13.0375 15.2857 12.25 16.0571 12.25 17C12.25 17.9429 13.0375 18.7143 14 18.7143Z" fill={fill} className="svgFill" />
    </svg>
  )
}

SecurityIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SecurityIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SecurityIcon)
