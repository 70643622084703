import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getSynchronization = createSelector(
  getState,
  (state) => get(state, 'integrations', {}),
)

export const getSyncInProgress = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'syncInProgress'),
)

export const getSyncError = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'error'),
)

export const getIntegrationScheme = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'integrationScheme'),
)

export const getIntegrationTypes = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'integrationTypes'),
)

export const getIntegrationOptions = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'integrationOptions'),
)

export const getSelectedSystemId = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'selectedSystemId'),
)

export const getSyncMode = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'mode'),
)

export const getSelectedIntegration = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'selectedIntegration'),
)

export const getIntegrationSchemeById = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'integrationSchemeById'),
)

export const getForm = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'form.form'),
)

export const getSyncForm = createSelector(
  getSynchronization,
  (data) => get(data, 'form'),
)

export const getSystems = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'integrations'),
)

export const getIntegrationsIds = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'integrationsIds', []),
)

export const getRequestStatus = createSelector(
  getSynchronization,
  (integrations) => get(integrations, 'status'),
)
