import React, { useMemo } from 'react'
import pt from 'prop-types'
import getFullName from '@/helpers/fullName'
import CellContainer from './styles'

const ReportManagerCell = ({
  value,
  manyValues,
  text,
  date,
}) => {
  const renderValues = useMemo(() => {
    if (value.length === 0) {
      return ''
    }
    if (manyValues && value.length > 1) {
      return `${value[0].title || value[0].name || getFullName(value[0].firstName, value[0].lastName)} и еще ${value.length - 1}`
    }
    if (manyValues && value.length <= 1) {
      return value[0].title || value[0].name || getFullName(value[0].firstName, value[0].lastName)
    }
    if (typeof value === 'object' && value !== null) {
      return value.title || ''
    }
    return value
  }, [manyValues, value])

  return (
    <CellContainer date={date} text={text && renderValues.length > 60}>
      {renderValues}
    </CellContainer>
  )
}

ReportManagerCell.propTypes = {
  value: pt.string,
  manyValues: pt.bool,
  text: pt.bool,
  date: pt.bool,
}
ReportManagerCell.defaultProps = {
  value: '',
  manyValues: false,
  text: pt.bool,
  date: false,
}

export default ReportManagerCell
